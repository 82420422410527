import React, {Component} from 'react';
import {
    Image,
    Keyboard,
    Platform,
    SafeAreaView,
    ScrollView,
    StatusBar,
    StatusBarStyle,
    Text,
    TouchableOpacity,
    View
} from 'react-native';
import ScreenSizeUtil from "../../util/ScreenSizeUtil";
import {SearchBar} from "react-native-elements";
import {appColors, appFont, appS} from "../../../resources/styles/style";
import {Keyword, NewsApiFactory} from "../../data/network/swagger-gen";
import ValidateUtil from "../../util/ValidateUtil";
import {ListCellComponent} from "../ListCellComponent";
import CustomHeaderFunc from "../func/CustomHeaderFunc";
import AppG from "../../util/AppG";
import {MenuComponent} from "../MenuComponent";
import {SearchScreenModel} from "../../data/models/screen/SearchScreenModel";
import {IconWithBadgeComponent} from "../IconWithBadgeComponent";
import ExpoUtil from "../../util/ExpoUtil";
import ShareUtil from "../../util/ShareUtil";
import SearchDataDao from "../../data/dao/local/SearchDataDao";
import {Link} from "@react-navigation/native";
import {SearchScreenComponent} from "../SearchScreenComponent";
// @ts-ignore
import {getEnvVars, log} from '../../../../environment';
import UrlUtil from '../../util/UrlUtil';
import LinkUtil from "../../util/LinkUtil";


interface Props {
    navigation: any,
    leftButton: any | 'back' | 'menu' | 'none' | 'logo',
    rightButton: any | 'alert' | 'none' | 'share' | 'menu',
    title: string,
    searchBar: boolean, // trueの時titleを無視してサーチバーを置く
    onSearchSubmit: (text: string) => void, // 検索をサブミットしたときの動作
    searchValue: string,
    textColor: string,
    barColor: string,
    borderBottomColor: string,
    statusBarHidden: boolean,   // ステータスバー消すときのフラグ、このコンポーネントを内包するSafeAreaViewに「statusBarHeightでステータスバー分上に上げる」必要がある
    statusBarType: StatusBarStyle | null,
    backButtonColor: 'gray' | 'white',
    // onSearchLinkIcon: (text: string) => void | null,
    shareMessage: string,
    shareAnalytics: () => void | null,
    shadow: boolean,
    secondHeader: boolean,  // 2段目のヘッダーの場合
    zIndex: number,
}

interface State {
    isShowMenu: boolean,
    searchValue: string,
    keywords: Keyword[],
    showSearchPage: boolean,
    // searchHistory: Keyword[],   // 検索履歴

    // popularKeywords: Keyword[], // 人気のキーワード
    newNewsCount: number,   // 運営からのお知らせ
}

export class CustomHeaderComponentSmall extends Component<Props, State> {
    _height = appS.header.height;

    /**
     * トピック毎のマージン
     */
    _topicMargin = appS.margins.betweenTopic;

    // Propsのデフォルト値
    static defaultProps = {
        leftButton: 'menu',
        rightButton: null,
        searchBar: false,
        onSearchSubmit: (text: string) => {},
        searchValue: '',
        borderBottomColor: appColors.transparent,
        statusBarHidden: false,
        statusBarType: null,
        backButtonColor: 'gray',
        // onSearchLinkIcon: null,
        shareMessage: '',
        shareAnalytics: null,
        shadow: true,
        secondHeader: false,
        zIndex: 99,
    };

    // @ts-ignore
    constructor(props) {
        super(props);
        this.state = {
            isShowMenu: false,
            searchValue: '',
            keywords: [],
            showSearchPage: false,
            // searchHistory: [],

            // popularKeywords: [], // 人気のキーワード
            newNewsCount: 0,
        };
    }

    componentDidMount() {

        // 検索履歴
        this._getKeywordHistory();

        // // 人気のキーワード
        // KeywordApiFactory(AppG.getConfiguration(), AppG.getBasePath())
        //     .getPopularKeyword(20).then((popularKeywordsRes: AxiosResponse<Keyword[]>) => {
        //     if (popularKeywordsRes != null && popularKeywordsRes.data != null) {
        //         this.setState({popularKeywords: popularKeywordsRes.data});
        //     }
        // });

        this.setState({searchValue: this.props.searchValue});

        if (AppG.user != null) {
            NewsApiFactory(AppG.getConfiguration(), AppG.getBasePath())
                .getAmountOfUnreadNews('all')
                .then(res => {
                    if (res != null && res.data != null && res.data.unread != null) {
                        this.setState({newNewsCount: res.data.unread});
                    }
                })
        }
    }

    _getKeywordHistory = () => {
        // 検索履歴を取得
        SearchDataDao.get().then(searchData => {
            if (searchData != null) {
                let _searchHistory: Keyword[] = [];
                const _searchWord = searchData.searchWord?.split('|');
                if (_searchWord != null) {
                    _searchWord.forEach((word, index) => {
                        if (index < 6) {
                            _searchHistory.push({value: word, indexName: ''});
                        }
                    })
                    AppG.searchHistory = _searchHistory;
                    // this.setState({searchHistory: _searchHistory});
                }
            }
        });
    }

    /**
     * 左のボタンの描画。 leftButton == 'back' で戻るボタンの描画
     * @private
     */
    _renderLeftBackButton = () => {
        if (this.props.leftButton == 'back') {
            let _source = require('../../../resources/images/02.menu_general/arrow_W_left.png');
            if (this.props.backButtonColor !== 'white') {
                _source = require('../../../resources/images/02.menu_general/arrow_B_left.png');
            }
            return (
                <View>
                    <TouchableOpacity
                        style={[{marginLeft: 8, width: this._height, height: this._height, marginTop: 4}]}
                        onPress={() => {
                            ExpoUtil.goBack(this.props.navigation);
                        }}
                    >
                        <Image source={_source}
                               style={[{
                                   marginTop: 4,
                                   width: appS.header.backIconSize,
                                   height: appS.header.backIconSize,
                               }]}
                               resizeMode='cover'/>
                    </TouchableOpacity>
                </View>
            );
        } else {
            return this.props.leftButton;
        }
    }

    render() {
        if (ScreenSizeUtil.isWebSize()) {
            return null;
        }

        const {
            navigation,
            leftButton,
            rightButton,
            title,
            searchBar,
            onSearchSubmit,
            textColor,
            barColor,
            borderBottomColor,
            statusBarHidden,
            statusBarType,
            shadow,
            secondHeader,
            zIndex,
        } = this.props;

        this._height = secondHeader? 40 : this._height;

        let _headerStyle: any = {
            flex: 1,
            height: this._height,
            flexDirection: 'row',
            borderBottomWidth: 2,
            backgroundColor: barColor,
            borderBottomColor: borderBottomColor,
        };

        if (shadow) {
            if (Platform.OS != 'android') {
                _headerStyle = {
                    flex: 1,
                    height: this._height,
                    flexDirection: 'row',
                    borderBottomWidth: 2,
                    backgroundColor: barColor,
                    borderBottomColor: borderBottomColor,
                    shadowColor: '#000',
                    shadowOffset: { width: 0, height: 4 },
                    shadowOpacity: 0.2,
                    shadowRadius: 2,
                };
            } else if (Platform.OS == 'android') {
                _headerStyle = {
                    flex: 1,
                    height: this._height,
                    flexDirection: 'row',
                    borderBottomWidth: 2,
                    backgroundColor: barColor,
                    borderBottomColor: borderBottomColor,
                    elevation: 2,
                };
            }
        }

        const _logo = appS.logo;

        const _buttonAllowStyle: any = {
            alignSelf: 'center',
            width: 14,
            height: 14,
            marginTop: Platform.OS == 'web'? 2 : 4,
        }

        // アプリで開くの高さ
        const _announcementAppHeaderHeight = 40;

        // スマホ
        return (
            <SafeAreaView style={{
                zIndex: zIndex,
                position: 'relative',
                top: 0,
            }}>
                <View
                    style={{
                        height: this._height + ((statusBarHidden && !secondHeader) ? appS.header.statusBarHeight : 0) + ((Platform.OS == 'web' && !secondHeader)? _announcementAppHeaderHeight : 0),
                        flexDirection: "column",
                    }}>

                    {statusBarType != null && (
                        <StatusBar barStyle={statusBarType}/>
                    )}
                    {statusBarHidden && !secondHeader && (
                        // ステータスバーがない時はステータスバー分の高さのViewをついか
                        <View style={[{width: AppG.window.width, backgroundColor: barColor}, appS.statusBarHidden]} />
                    )}
                    {Platform.OS == 'web' && !secondHeader && (
                        <View style={{flexDirection: 'row', height: _announcementAppHeaderHeight, width: AppG.window.width, backgroundColor: '#F2F2F2'}}>
                            <Image source={require('../../../resources/images/app_icon.png')}
                                   style={[{
                                       margin: 5,
                                       marginLeft: 10,
                                       width: 30,
                                       height: 30,
                                   }]}
                                   resizeMode='contain'/>
                            <View style={{marginLeft: 13, marginTop: 5,}}>
                                <Text style={{fontFamily:appFont.family.default, fontSize: 9, color: appColors.fontGray}}>iOSアプリ「Reci BASE」</Text>
                                <Text style={{fontFamily:appFont.family.default, fontSize: 10, color: appColors.fontBlack}}>アプリでもっとレシピ検索を簡単に</Text>
                            </View>
                            <View style={{flex: 1}}/>
                            <TouchableOpacity
                                style={{
                                    backgroundColor: appColors.actionColor,
                                    height: 30,
                                    width: 88,
                                    marginTop: 5,
                                    marginRight: 10,
                                    borderRadius: 5,
                                }}
                                onPress={() => {
                                    // @ts-ignore
                                    const {REACT_APP_STORE_URL_IOS, REACT_APP_STORE_URL_ANDROID} = getEnvVars();
                                    if (UrlUtil.isAndroidByUa()) {
                                        LinkUtil.openLinkWithDefaultBrowser(REACT_APP_STORE_URL_ANDROID);
                                    } else {
                                        LinkUtil.openLinkWithDefaultBrowser(REACT_APP_STORE_URL_IOS);
                                    }
                                }}
                            >
                                <Text style={{width: 88, fontFamily:appFont.family.bold, fontSize: 13, color: appColors.white, marginTop: 4, textAlign: 'center', fontWeight: "bold"}}>アプリで開く</Text>
                            </TouchableOpacity>
                        </View>
                    )}
                    <View
                        style={_headerStyle}
                    >
                        <View style={{flexDirection: 'row', flex: 1}}>
                            {leftButton === 'back' && (
                                <View style={{flex:1, alignItems: 'center', marginTop: secondHeader? 4 : 8}}>
                                    {this._renderLeftBackButton()}
                                </View>
                            )}
                            {leftButton === 'logo' && Platform.OS == 'web' && (
                                <Link
                                    style={{marginTop: 10, marginLeft: 15}}
                                    to={'/'}
                                    onPress={() => {
                                        const { routeName } = navigation.state;
                                        if (routeName != 'HomeScreen') {
                                            navigation.navigate('HomeScreen');
                                        }
                                    }}
                                >
                                    <Image source={require('../../../resources/images/logo_icon_web.png')}
                                           style={[{
                                               margin: 6,
                                               marginLeft: -6,
                                               width: _logo.width,
                                               height: _logo.height,
                                           }]}
                                           resizeMode='contain'/>
                                </Link>
                            )}
                            {leftButton === 'menu' && (
                                <TouchableOpacity
                                    style={{flex:1, alignItems: 'center', marginTop: Platform.OS == "ios"? 2: 5, marginLeft: appS.margins.side}}
                                    onPress={() => {
                                        this.setState({isShowMenu: !this.state.isShowMenu});
                                    }}>
                                    <View
                                        style={{flexDirection: "row"}}
                                    >
                                        <View style={{}}>
                                            <Image source={require('../../../resources/images/02.menu_general/i_menu.png')}
                                                   style={[{
                                                       marginTop: 6,
                                                       width: appS.header.menuIconSize,
                                                       height: appS.header.menuIconSize,
                                                   }]}
                                                   resizeMode='contain'/>
                                            <Text style={{fontSize: appFont.size.headerIconFontSize, fontFamily: appFont.family.default, marginTop: -1}}>メニュー</Text>
                                        </View>
                                    </View>
                                </TouchableOpacity>
                            )}
                            {leftButton === 'none' && (
                                <TouchableOpacity
                                    style={{flex:1, alignItems: 'center', marginTop: Platform.OS == "ios"? 2: 10, marginLeft: appS.margins.side}}
                                    disabled={true}
                                    onPress={() => {}}>
                                    <View
                                        style={{flexDirection: "row"}}
                                    >
                                        <View>
                                            <View
                                                style={[{
                                                    marginTop: -2,
                                                    width: appS.header.menuIconSize,
                                                    height: appS.header.menuIconSize,
                                                }]}
                                            />
                                            <Text style={{fontSize: appFont.size.headerIconFontSize, fontFamily: appFont.family.default, marginTop: -1}}>　</Text>
                                        </View>
                                    </View>
                                </TouchableOpacity>
                            )}
                            {searchBar && (
                                <SearchBar
                                    placeholder="キーワードで検索"
                                    autoCapitalize={"none"}
                                    onFocus={() => {
                                        AppG.screenHistory.unshift(`検索ヘッダー`);
                                        this.setState({showSearchPage: true});

                                        this._getKeywordHistory();
                                    }}
                                    onChangeText={(text?: string) => {
                                        if (text == null) {
                                            return;
                                        }
                                        this.setState({searchValue: text});
                                        setTimeout(() => {
                                            CustomHeaderFunc.getKeyword(this, text);
                                        }, 300);
                                    }}
                                    value={this.state.searchValue}
                                    containerStyle={{
                                        marginTop: Platform.OS == 'ios'? 0 : 4,
                                        flex: 5,
                                        backgroundColor: barColor,
                                        borderBottomColor: appColors.transparent,
                                        borderTopColor: appColors.transparent
                                    }}
                                    returnKeyType={'search'}
                                    onSubmitEditing={() => {
                                        // 検索履歴に追加
                                        SearchDataDao.addNew(this.state.searchValue);

                                        this.setState({keywords: []});
                                        const { routeName } = navigation.state;
                                        if (routeName != 'SearchResultScreen') {
                                            let searchScreenModel: SearchScreenModel = new SearchScreenModel();
                                            searchScreenModel.searchType = 'all';
                                            searchScreenModel.headerTitle = this.state.searchValue;
                                            searchScreenModel.searchValue = this.state.searchValue;
                                            if (Platform.OS == 'web') {
                                                navigation.navigate('SearchResultScreen', searchScreenModel);
                                            } else {
                                                navigation.push('SearchResultScreen', searchScreenModel);
                                            }
                                        }
                                        // else if (this.props.onSearchLinkIcon != null) {
                                        //     this.props.onSearchLinkIcon(this.state.searchValue);
                                        // }

                                        // setTimeout(() => {
                                        //     this.setState({searchValue: ''});
                                        // }, 100);
                                    }}
                                    inputContainerStyle={{
                                        // flexDirection: 'row-reverse',   // 検索とリセットアイコンの位置を逆にする
                                        backgroundColor: appColors.searchBarBackground,
                                        height: this._height - 8,
                                        borderRadius: 10,
                                        marginTop: -8,
                                        borderWidth: 1,
                                        borderBottomWidth: 1,
                                        borderColor: appColors.lightGray,
                                    }}
                                    // @ts-ignore
                                    inputStyle={{fontSize: appFont.size.searchArea, outline: 'none'}}
                                    //@ts-ignore
                                    clearIcon={
                                        <TouchableOpacity
                                            onPress={() => {
                                                this.setState({searchValue: ''});
                                                setTimeout(() => {
                                                    this.setState({keywords: []});
                                                }, 300);
                                            }}
                                        >
                                            <Image source={require('../../../resources/images/05.search/i_search_cancel.png')}
                                                   style={[{
                                                       marginLeft: 8,
                                                       width: 16,
                                                       height: 16,
                                                   }]}
                                                   resizeMode='contain'
                                            />
                                        </TouchableOpacity>
                                    }
                                    //@ts-ignore
                                    searchIcon={
                                        <TouchableOpacity
                                            onPress={() => {
                                                const { routeName } = navigation.state;
                                                if (routeName != 'SearchResultScreen') {
                                                    let searchScreenModel: SearchScreenModel = new SearchScreenModel();
                                                    searchScreenModel.searchType = 'all';
                                                    searchScreenModel.headerTitle = this.state.searchValue;
                                                    searchScreenModel.searchValue = this.state.searchValue;
                                                    navigation.navigate('SearchResultScreen', searchScreenModel);
                                                }
                                                // else if (this.props.onSearchLinkIcon != null) {
                                                //     this.props.onSearchLinkIcon(this.state.searchValue);
                                                // }

                                                setTimeout(() => {
                                                    this.setState({keywords: []});
                                                }, 300);
                                                // setTimeout(() => {
                                                //     this.setState({searchValue: ''});
                                                // }, 100);
                                            }}
                                        >
                                            <Image source={require('../../../resources/images/search_bar_icon.png')}
                                                   style={[{
                                                       marginRight: 8,
                                                       width: 16,
                                                       height: 16,
                                                   }]}
                                                   resizeMode='contain'
                                            />
                                        </TouchableOpacity>
                                    }
                                    platform={'default'}
                                />
                            )}
                            {!searchBar && (
                                <View style={{flex:3, alignItems: 'center', marginTop: 8}}>
                                    <Text
                                        style={{
                                            marginLeft: (Platform.OS == 'web' && leftButton === 'menu')? -_logo.width : 0,
                                            fontFamily: appFont.family.bold,
                                            fontSize: appFont.size.headerTitle,
                                            marginTop: Platform.OS == 'android'? (secondHeader? 0 : 4): (secondHeader? -2 : 0),
                                            fontWeight: 'bold',
                                            textAlign: 'center',
                                            color: textColor
                                        }}
                                        numberOfLines={1}
                                    >
                                        {title}
                                    </Text>
                                </View>
                            )}
                            {searchBar && this.state.showSearchPage && (
                                <TouchableOpacity
                                    style={{
                                        flex:1,
                                        alignItems: 'center',
                                        marginTop: Platform.OS == "ios"? 2: 10,
                                        marginLeft: -16,
                                        marginRight: 16
                                    }}
                                    onPress={() => {
                                        this.setState({showSearchPage: false});
                                        this.setState({searchValue: ''});
                                        this.setState({keywords: []});
                                        Keyboard.dismiss();
                                    }}>
                                    <Text style={{
                                        width: 60,
                                        fontSize: 10,
                                        fontFamily: appFont.family.default,
                                        textAlign: 'center',
                                        textAlignVertical: 'center',
                                        marginTop: Platform.OS == 'web'? 8 : (Platform.OS == 'android'? 2 : 12),
                                        marginLeft: 8,
                                        marginRight: -16
                                    }}>キャンセル</Text>
                                </TouchableOpacity>
                            )}
                            {rightButton !== 'none' && rightButton !== 'alert' && rightButton !== 'share' && rightButton !== 'menu' && (
                                <View style={{flex:1, alignItems: 'center', marginTop: 8}}>
                                    {rightButton}
                                </View>
                            )}
                            {rightButton === 'none' && (
                                <TouchableOpacity
                                    style={{flex:1, alignItems: 'center', marginTop: Platform.OS == "ios"? 2: 10, marginLeft: -16, marginRight: 16}}
                                    disabled={true}
                                    onPress={() => {}}>
                                    <View style={[{
                                        width: appS.header.menuIconSize,
                                        height: appS.header.menuIconSize,
                                    }]}/>
                                </TouchableOpacity>
                            )}
                            {rightButton === 'alert' && !this.state.showSearchPage && (
                                <TouchableOpacity
                                    style={{flex:1, alignItems: 'center', marginTop: Platform.OS == "ios"? 2: 10, marginLeft: -16, marginRight: 16}}
                                    onPress={() => {
                                        const { routeName } = this.props.navigation.state;
                                        if (routeName != 'NewsScreen') {
                                            this.setState({isShowMenu: false});
                                            this.props.navigation.navigate('NewsScreen');
                                        } else {
                                            this.setState({isShowMenu: false});
                                        }
                                    }}>
                                    <View
                                        style={{flexDirection: "row"}}
                                    >
                                        <View>
                                            <Image source={require('../../../resources/images/01.header/i_header_bell.png')}
                                                   style={[{
                                                       marginTop: 8,
                                                       marginLeft: -4,
                                                       width: appS.header.bellIconSize,
                                                       height: appS.header.bellIconSize,
                                                   }]}
                                                   resizeMode='contain'/>
                                        </View>
                                        <View style={{marginLeft: -38, marginTop: 32}}>
                                            <IconWithBadgeComponent
                                                iconSize={30}
                                                multipleSize={1.4}
                                                isShow={true}
                                                isWrittenNumber={true}
                                                // badgeCount={3}
                                                // badgeCount={(AppG.badgeChat==null?0:AppG.badgeChat) + (AppG.badgeCart==null?0:AppG.badgeCart)}
                                                badgeCount={this.state.newNewsCount}
                                            />
                                        </View>
                                    </View>
                                </TouchableOpacity>
                            )}
                            {rightButton === 'menu' && !this.state.showSearchPage && (
                                <TouchableOpacity
                                    style={{flex:1, alignItems: 'center', marginTop:  5, marginRight: 10}}
                                    onPress={() => {
                                        this.setState({isShowMenu: !this.state.isShowMenu});
                                    }}>
                                    <View
                                        style={{flexDirection: "row", marginLeft: -4}}
                                    >
                                        <View style={{}}>
                                            <Image source={require('../../../resources/images/02.menu_general/i_menu_right.png')}
                                                   style={[{
                                                       marginTop: 6,
                                                       width: appS.header.menuIconSize,
                                                       height: appS.header.menuIconSize,
                                                   }]}
                                                   resizeMode='contain'/>
                                            <Text style={{fontSize: appFont.size.headerIconFontSize, fontFamily: appFont.family.default, marginTop: -1, marginLeft: - 6}}>メニュー</Text>
                                        </View>
                                        <View style={{marginLeft: -43, marginTop: 32}}>
                                            <IconWithBadgeComponent
                                                iconSize={30}
                                                multipleSize={1.4}
                                                isShow={true}
                                                isWrittenNumber={true}
                                                // badgeCount={3}
                                                // badgeCount={(AppG.badgeChat==null?0:AppG.badgeChat) + (AppG.badgeCart==null?0:AppG.badgeCart)}
                                                badgeCount={this.state.newNewsCount + (AppG.badgeChat==null?0:AppG.badgeChat) + (AppG.badgeHistory==null?0:AppG.badgeHistory)}
                                            />
                                        </View>
                                    </View>
                                </TouchableOpacity>
                            )}
                            {rightButton === 'share' && (Platform.OS == 'ios' || Platform.OS == 'android') && (
                                <TouchableOpacity
                                    style={{flex:1, alignItems: 'center', marginTop: Platform.OS == "ios"? (secondHeader? -2 : 2): (secondHeader? -2 : 10), marginLeft: -16, marginRight: 16}}
                                    onPress={() => {
                                        if (this.props.shareAnalytics != null) {
                                            this.props.shareAnalytics();
                                        }
                                        ShareUtil.onShare(this.props.shareMessage);
                                    }}>
                                    <View
                                        style={{flexDirection: "row"}}
                                    >
                                        <View style={{flex: 1}}/>
                                        <View>
                                            {this.props.barColor == appColors.white && (
                                                <Image source={require('../../../resources/images/03.item_recipe/i_share_Bk.png')}
                                                       style={[{
                                                           marginTop: 10,
                                                           width: appS.header.shareIconSize,
                                                           height: appS.header.shareIconSize,
                                                       }]}
                                                       resizeMode='contain'/>
                                            )}
                                            {this.props.barColor == appColors.recipeHeader && (
                                                <Image source={require('../../../resources/images/03.item_recipe/i_share_Y.png')}
                                                       style={[{
                                                           marginTop: 10,
                                                           width: appS.header.shareIconSize,
                                                           height: appS.header.shareIconSize,
                                                       }]}
                                                       resizeMode='contain'/>
                                            )}
                                            {this.props.barColor == appColors.productHeader && (
                                                <Image source={require('../../../resources/images/03.item_recipe/i_share_G.png')}
                                                       style={[{
                                                           marginTop: 10,
                                                           width: appS.header.shareIconSize,
                                                           height: appS.header.shareIconSize,
                                                       }]}
                                                       resizeMode='contain'/>
                                            )}
                                        </View>
                                    </View>
                                </TouchableOpacity>
                            )}
                            {rightButton === 'share' && Platform.OS == 'web' && (
                                <TouchableOpacity
                                    style={{flex:1, alignItems: 'center', marginTop: 10, marginLeft: -16, marginRight: 16}}
                                    onPress={() => {}}
                                    disabled={true}
                                >
                                    <View
                                        style={{flexDirection: "row"}}
                                    >
                                        <View style={{flex: 1}}/>
                                        <View>
                                            <View
                                                style={[{
                                                    marginTop: 10,
                                                    width: appS.header.shareIconSize,
                                                    height: appS.header.shareIconSize,
                                                }]}
                                            />
                                        </View>
                                    </View>
                                </TouchableOpacity>
                            )}
                        </View>
                    </View>
                </View>

                {/*新検索画面*/}
                {searchBar && this.state.showSearchPage && ValidateUtil.isEmptyArray(this.state.keywords) && (
                    <SearchScreenComponent navigation={navigation} searchValue={this.state.searchValue} />
                )}

                {/*キーワードエリア*/}
                {searchBar && !ValidateUtil.isEmptyArray(this.state.keywords) && (
                    <ScrollView
                        style={{height: AppG.window.height, backgroundColor: appColors.white}}
                    >
                        <View style={{margin: appS.margins.side}}>
                            {this.state.keywords.map((keyword: Keyword, index: number) => {
                                return (
                                    <ListCellComponent
                                        key={`keyword_list_${index}`}
                                        navigation={navigation}
                                        title={keyword.value}
                                        onPress={() => {
                                            // 検索履歴に追加
                                            SearchDataDao.addNew(this.state.searchValue);
                                            CustomHeaderFunc.selectKeyword(this, keyword, this.state.searchValue, onSearchSubmit);
                                            // setTimeout(() => {
                                            //     this.setState({searchValue: ''});
                                            // }, 100);
                                        }}/>
                                )
                            })}
                        </View>
                    </ScrollView>
                )}

                {/*メニュー*/}
                <MenuComponent navigation={navigation} isShowMenu={this.state.isShowMenu} closeMenu={() => {this.setState({isShowMenu: false})}}/>
            </SafeAreaView>
        );
    }
}

