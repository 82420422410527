import React, {Component} from 'react';
import {Image, Linking, Platform, ScrollView, Text, TouchableOpacity, View} from 'react-native';
import {appColors, appFont, appS} from "../../resources/styles/style";
import {Keyword, KeywordApiFactory, NewsApiFactory} from "../data/network/swagger-gen";
import AppG from "../util/AppG";
import {SearchScreenModel} from "../data/models/screen/SearchScreenModel";
import SearchDataDao from "../data/dao/local/SearchDataDao";
import {AxiosResponse} from "axios";
import ArrayUtil from "../util/ArrayUtil";
import {SpacerComponent} from "./SpacerComponent";
import {TopicTitleComponent} from "./TopicTitleComponent";
import {SearchWordsComponent} from "./SearchWordsComponent";
import {SmallScreenAdComponent} from "./small/SmallScreenAdComponent";
import ScreenSizeUtil from "../util/ScreenSizeUtil";
import {getEnvVars} from "../../../environment";


interface Props {
    navigation: any,
    searchValue: string,
    onClose: (() => void) | null,    // このコンポーネントを閉じる
    // searchHistory: Keyword[]    // 検索履歴
}

interface State {
    isShowMenu: boolean,
    searchValue: string,
    keywords: Keyword[],
    showSearchPage: boolean,
    // searchHistory: Keyword[],   // 検索履歴

    popularKeywords: Keyword[], // 人気のキーワード
    popularProductKeywords: Keyword[], // 人気の商品キーワード
    newNewsCount: number,   // 運営からのお知らせ
}

export class SearchScreenComponent extends Component<Props, State> {
    _height = appS.header.height;

    /**
     * トピック毎のマージン
     */
    _topicMargin = appS.margins.betweenTopic;

    // Propsのデフォルト値
    static defaultProps = {
        searchValue: '',
        onClose: null,
    };

    // @ts-ignore
    constructor(props) {
        super(props);
        this.state = {
            isShowMenu: false,
            searchValue: '',
            keywords: [],
            showSearchPage: false,
            // searchHistory: [],

            popularKeywords: [], // 人気のキーワード
            popularProductKeywords: [],
            newNewsCount: 0,
        };
    }

    componentDidMount() {

        // this.setState({searchHistory: this.props.searchHistory});

        // 人気のキーワード
        KeywordApiFactory(AppG.getConfiguration(), AppG.getBasePath())
            .getPopularKeyword(
                20,
                'recipe'
            ).then((popularKeywordsRes: AxiosResponse<Keyword[]>) => {
            if (popularKeywordsRes != null && popularKeywordsRes.data != null) {
                this.setState({popularKeywords: popularKeywordsRes.data});
            }
        });
        KeywordApiFactory(AppG.getConfiguration(), AppG.getBasePath())
            .getPopularKeyword(
                20,
                'product'
            ).then((popularProductKeywordsRes: AxiosResponse<Keyword[]>) => {
            if (popularProductKeywordsRes != null && popularProductKeywordsRes.data != null) {
                this.setState({popularProductKeywords: popularProductKeywordsRes.data});
            }
        });

        this.setState({searchValue: this.props.searchValue});

        if (AppG.user != null) {
            NewsApiFactory(AppG.getConfiguration(), AppG.getBasePath())
                .getAmountOfUnreadNews('all')
                .then(res => {
                    if (res != null && res.data != null && res.data.unread != null) {
                        this.setState({newNewsCount: res.data.unread});
                    }
                })
        }
    }

    render() {
        const {
            navigation,
        } = this.props;

        const _buttonAllowStyle: any = {
            alignSelf: 'center',
            width: 14,
            height: 14,
            marginTop: Platform.OS == 'web'? 2 : (Platform.OS == 'ios'? 3 : 4),
        }

        return (
            <ScrollView
                style={{height: ScreenSizeUtil.isWebSize() ? AppG.window.height - appS.header.webUpsideHeight : AppG.window.height, backgroundColor: appColors.white}}
            >
                <View style={{margin: appS.margins.side}}>
                    <View style={{
                        width: appS.keywordAreaSize.width,
                        alignItems: 'center',
                        alignSelf: 'center',
                    }}>
                        {/*<SpacerComponent height={appS.header.bottomMargin} />*/}

                        <TopicTitleComponent
                            title={'検索履歴'}
                            subTopic={true}
                        />

                        <SearchWordsComponent
                            showCount={9}
                            keywords={AppG.searchHistory != null ? AppG.searchHistory : []}
                            onPress={(keyword) => {
                                // @ts-ignore
                                const {REACT_APP_DOMAIN_URL} = getEnvVars();
                                const { routeName } = this.props.navigation.state;
                                let searchScreenModel: SearchScreenModel = new SearchScreenModel();
                                searchScreenModel.headerTitle = `${keyword.value}`;
                                searchScreenModel.searchType = 'all';
                                searchScreenModel.searchValue = `${keyword.value}`;
                                searchScreenModel.screenNameFrom = routeName;
                                if (routeName == 'SearchResultScreen' && Platform.OS == 'web') {
                                    location.href = `${REACT_APP_DOMAIN_URL}/SearchResultScreen?cookingTime&costFrom&costTo&headerTitle=${keyword.value}&keyword&makerId&makerIds&menuCategoryId&menuCategoryName&productId&productLargeCategoryId&productLargeCategoryIds&productLargeCategoryName&productMediumCategoryId&productMediumCategoryName&productSortType&recipeCategoryIds&recipeRestaurantLargeFormatIds&recipeSortType&screenNameFrom=SearchResultScreen&searchType=all&searchValue=${keyword.value}&seasonIds&tabHidden`;
                                } else if (Platform.OS == 'web') {
                                    this.props.navigation.navigate('SearchResultScreen', searchScreenModel);
                                } else {
                                    this.props.navigation.push('SearchResultScreen', searchScreenModel);
                                }
                            }}/>

                        <SpacerComponent height={appS.margins.betweenContentSmall}/>

                        <TouchableOpacity
                            style={{
                                borderRadius: 10,
                                borderColor: appColors.pickerBorderGray,
                                borderWidth: 1,
                                width: 150,
                                height: 40,
                            }}
                        >
                            <Text
                                style={{
                                    padding: Platform.OS == 'android'? 0 : 10,
                                    fontFamily: appFont.family.default,
                                    fontSize: appFont.size.buttonSmallText,
                                    color: appColors.fontBlack,
                                    textAlign: 'center',
                                    textAlignVertical: 'center',
                                }}
                                onPress={() => {
                                    AppG.searchHistory = [];
                                    SearchDataDao.delete();
                                }}
                            >検索履歴をクリア</Text>
                        </TouchableOpacity>

                        <TopicTitleComponent title={'詳細検索'} subTopic={true} />
                        <View
                            style={{
                                flexDirection: 'row',
                                width: appS.keywordAreaSize.width,
                                alignSelf: 'center',
                                alignItems: 'center',
                            }}
                        >
                            {/*レシピから探す*/}
                            <TouchableOpacity
                                style={{
                                    marginRight: 4,
                                    height: 40,
                                    width: appS.keywordAreaSize.width/2,
                                    backgroundColor: appColors.white,
                                    borderColor: appColors.recipeHeader,
                                    borderWidth: 1,
                                    alignSelf: 'center',
                                    alignItems: 'center',
                                }}
                                onPress={() => {
                                    let searchScreenModel: SearchScreenModel = new SearchScreenModel();
                                    searchScreenModel.searchType = 'recipe';
                                    if (Platform.OS == 'web') {
                                        const { routeName } = this.props.navigation.state;
                                        if (routeName == 'SearchScreen' && this.props.onClose != null) {
                                            this.props.onClose();
                                        } else {
                                            navigation.navigate('SearchScreen', searchScreenModel);
                                        }
                                    } else {
                                        navigation.push('SearchScreen', searchScreenModel);
                                    }
                                }}
                            >
                                <View style={{marginTop: -2, flexDirection: 'row', alignSelf: 'center'}}>
                                    <Text
                                        style={{
                                            marginTop: Platform.OS == 'android'? 2 : 0,
                                            lineHeight: 40,
                                            textAlign: 'center',
                                            textAlignVertical: 'center',
                                            color: appColors.recipeHeader,
                                            fontFamily: appFont.family.bold,
                                            fontWeight: "bold",
                                            fontSize: 14,
                                        }}
                                    >
                                        {`レシピから探す`}
                                    </Text>
                                    <Image source={require('../../resources/images/02.menu_general/arrow_Y_right.png')}
                                           style={[_buttonAllowStyle]}
                                    />
                                </View>
                            </TouchableOpacity>
                            {/*商品から探す*/}
                            <TouchableOpacity
                                style={{
                                    marginLeft: 4,
                                    height: 40,
                                    width: appS.keywordAreaSize.width/2,
                                    backgroundColor: appColors.white,
                                    borderColor: appColors.productHeader,
                                    borderWidth: 1,
                                    alignSelf: 'center',
                                    alignItems: 'center',
                                }}
                                onPress={() => {
                                    let searchScreenModel: SearchScreenModel = new SearchScreenModel();
                                    searchScreenModel.searchType = 'product';
                                    if (Platform.OS == 'web') {
                                        const { routeName } = this.props.navigation.state;
                                        if (routeName == 'SearchScreen' && this.props.onClose != null) {
                                            this.props.onClose();
                                        } else {
                                            navigation.navigate('SearchScreen', searchScreenModel);
                                        }
                                    } else {
                                        navigation.push('SearchScreen', searchScreenModel);
                                    }
                                }}
                            >
                                <View style={{marginTop: -2, flexDirection: 'row', alignSelf: 'center'}}>
                                    <Text
                                        style={{
                                            marginTop: Platform.OS == 'android'? 2 : 0,
                                            lineHeight: 40,
                                            textAlign: 'center',
                                            textAlignVertical: 'center',
                                            color: appColors.productHeader,
                                            fontFamily: appFont.family.default,
                                            fontWeight: "bold",
                                            fontSize: 14,
                                        }}
                                    >
                                        {`商品から探す`}
                                    </Text>
                                    <Image source={require('../../resources/images/02.menu_general/arrow_G_right.png')}
                                           style={[_buttonAllowStyle]}
                                    />
                                </View>
                            </TouchableOpacity>
                        </View>
                        <SpacerComponent height={this._topicMargin}/>

                        <TopicTitleComponent title={'レシピ人気キーワード'} subTopic={true} />
                        <SearchWordsComponent
                            showCount={9}
                            keywords={this.state.popularKeywords}
                            onPress={(keyword) => {
                                // @ts-ignore
                                const {REACT_APP_DOMAIN_URL} = getEnvVars();
                                const { routeName } = this.props.navigation.state;
                                let searchScreenModel: SearchScreenModel = new SearchScreenModel();
                                searchScreenModel.headerTitle = `${keyword.value}`;
                                searchScreenModel.searchType = 'recipe';
                                searchScreenModel.keyword = `${keyword.value}`;
                                searchScreenModel.searchValue = `${keyword.value}`;
                                searchScreenModel.screenNameFrom = routeName;
                                searchScreenModel.tabHidden = true;

                                if (Platform.OS == 'web') {
                                    this.props.navigation.navigate('SearchResultScreen', searchScreenModel);
                                } else {
                                    this.props.navigation.push('SearchResultScreen', searchScreenModel);
                                }
                            }}/>
                        <SpacerComponent height={this._topicMargin}/>

                        <TopicTitleComponent title={'商品人気キーワード'} subTopic={true} />
                        <SearchWordsComponent
                            showCount={9}
                            keywords={this.state.popularProductKeywords}
                            onPress={(keyword) => {
                                // @ts-ignore
                                const {REACT_APP_DOMAIN_URL} = getEnvVars();
                                const { routeName } = this.props.navigation.state;
                                let searchScreenModel: SearchScreenModel = new SearchScreenModel();
                                searchScreenModel.headerTitle = `${keyword.value}`;
                                searchScreenModel.searchType = 'product';
                                searchScreenModel.keyword = `${keyword.value}`;
                                searchScreenModel.searchValue = `${keyword.value}`;
                                searchScreenModel.screenNameFrom = routeName;
                                searchScreenModel.tabHidden = true;
                                if (Platform.OS == 'web') {
                                    this.props.navigation.navigate('SearchResultScreen', searchScreenModel);
                                } else {
                                    this.props.navigation.push('SearchResultScreen', searchScreenModel);
                                }
                            }}/>
                        <SpacerComponent height={this._topicMargin}/>

                        <TouchableOpacity
                            style={{
                                borderRadius: 10,
                                borderColor: appColors.pickerBorderGray,
                                borderWidth: 1,
                                width: 200,
                                height: 40,
                            }}
                        >
                            <View style={{flexDirection: 'row', alignSelf: 'center'}}>
                                <Image
                                    source={require('../../resources/images/05.search/i_search_refresh.png')}
                                    style={{
                                        alignSelf: 'center',
                                        width: 14,
                                        height: 14,
                                    }}
                                />
                                <Text
                                    style={{
                                        marginLeft: Platform.OS == 'android'? 10 : 0,
                                        padding: Platform.OS == 'android'? 0 : 10,
                                        fontFamily: appFont.family.default,
                                        fontSize: appFont.size.buttonSmallText,
                                        color: appColors.fontBlack,
                                        textAlign: 'center',
                                        textAlignVertical: 'center',
                                    }}
                                    onPress={() => {
                                        this.setState({popularKeywords: ArrayUtil.shuffle(this.state.popularKeywords)});
                                        this.setState({popularProductKeywords: ArrayUtil.shuffle(this.state.popularProductKeywords)});
                                        SearchDataDao.delete();
                                    }}
                                >キーワードをシャッフル</Text>
                            </View>

                        </TouchableOpacity>
                        <SpacerComponent height={this._topicMargin}/>

                        <SmallScreenAdComponent navigation={navigation}/>
                        <SpacerComponent height={this._topicMargin}/>

                    </View>
                </View>
                <SpacerComponent height={appS.footer.scrollBottom}/>
            </ScrollView>
        );
    }
}

