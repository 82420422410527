import React from 'react'
import {Dimensions, Platform, SafeAreaView, Text, TouchableOpacity, View,} from 'react-native'
import {BaseScreen} from "./BaseScreen";
import LoginUtil from "../util/LoginUtil";
// @ts-ignore
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scrollview';
import UrlUtil from "../util/UrlUtil";
// @ts-ignore
import {log} from '../../../environment';
import ScreenSizeUtil from "../util/ScreenSizeUtil";
import {appColors, appFont, appS} from "../../resources/styles/style";
import AppG from "../util/AppG";
import {CustomHeaderComponentWide} from "../components/wide/CustomHeaderComponentWide";
import WebView from "react-native-webview";
import {WebViewScreenModel} from "../data/models/screen/WebViewScreenModel";
import ValidateUtil from "../util/ValidateUtil";
import {CustomHeaderComponentSmall} from "../components/small/CustomHeaderComponentSmall";
import LinkUtil from "../util/LinkUtil";
import AnalyticsUtil, {AnalyticsEventName, DOpenEventName} from "../util/firebase/AnalyticsUtil";
import {UserUserPlanEnum} from "../data/network/swagger-gen";
import {LinkComponent} from "../components/LinkComponent";
import {NoUserImageComponent} from "../components/NoUserImageComponent";


///////////////////////////////////
/// ログイン無しのページ（WEB VIEW）
///////////////////////////////////

interface Props {
    navigation: any, // ナビゲーション用
}

interface State {
    resizeWindow: boolean,  // 値に意味はなし。windowサイズの拡大縮小をrenderに送るためのもの
    url: string | null,
    title: string | null,
    recommendSubscription: boolean,
}

export class WebViewScreen extends BaseScreen<Props, State> {

    _resizeTimer = setInterval(() => {
        // window 拡大縮小を反映させる
        AppG.window = Dimensions.get('window');
        this.setState({'resizeWindow': true});
        LoginUtil.setRefreshedScreenFlg(false);
    }, 100);

    _flameSize = appS.flame.size;    // 左右の margin
    _marginSize = ScreenSizeUtil.isWebSize()? 0 : appS.margin.size;

    // このScreenへ渡すパラム
    _param: WebViewScreenModel = new WebViewScreenModel();

    _browserBackListener = (e: any) => {

    };

    constructor(props: any) {
        super(props);
        this.state = {
            resizeWindow: true,  // 値に意味はなし。windowサイズの拡大縮小をrenderに送るためのもの
            url: null,
            title: '',
            recommendSubscription: false,
        };
    }

    /**
     * 画面開いたときのアナリティクス送信
     */
    _sendPvAnalytics = () => {
        this._param = this._getParams();
        AnalyticsUtil.sendAnalytics(
            DOpenEventName.openPage,
            AnalyticsEventName.openPage,  // アナリティクスのイベント名
            'WebViewScreen',
            null,
            null,
            null,
            this._param.title,
            this._param.url
        );
    }

    componentDidMount() {
        if (Platform.OS == 'web') {
            window.addEventListener('popstate', this._browserBackListener);
        }

        // Analytics
        this._sendPvAnalytics();

        // URL Scheme で外部リンクから起動したときの処理
        LoginUtil.setDefaultListener(this.props.navigation);

        this._loadContents();
    }

    componentWillUnmount () {
        clearTimeout(this._resizeTimer);
        if (Platform.OS === 'web') {
            window.removeEventListener('popstate', this._browserBackListener);
        }
        LoginUtil.removeDefaultListener(this.props.navigation);
    };

    componentDidUpdate () {
        // レンダー完了時に呼ばれる
    }

    /**
     * パラムの取得
     * @private
     */
    _getParams = () => {
        let webViewScreenModel: WebViewScreenModel = UrlUtil.getParamModel(new WebViewScreenModel(), this.props.navigation);
        return webViewScreenModel;
    };

    _loadContents = async () => {
        this._param = this._getParams();

        if (ValidateUtil.isEmptyExact(this._param.url)) {
            return;
        }

        this.setState({url: this._param.url});
        this.setState({title: this._param.title});
    };

    /**
     * WebViewのここでメッセージを受け取り実行する
     * window.ReactNativeWebView.postMessage('https://app.recibase.net/...');
     */
    onMessage = (event: any) => {
        if (event == null || event.nativeEvent == null || event.nativeEvent.data == null) {
            return;
        }
        const message = event.nativeEvent.data;
        if (message == "") {
            // 空文字の時は抜ける
            return;
        }

        if (message == "premiumCheck") {
            // プレミアム会員かチェックして、プレミアム会員でないなら専用の表示を入れる
            if (AppG.user == null || (AppG.user.userPlan != UserUserPlanEnum.Premium)) {
                this.setState({recommendSubscription: true});
            }
        } else if (message == "freeCheck") {
            // 会員かチェックして、会員でないなら専用の表示を入れる
            if (AppG.user == null) {
                this.setState({recommendSubscription: true});
            }
        } else if (ValidateUtil.isMyDomain(message)) {
            // ドメイン一緒ならアプリ内で開く
            LinkUtil.openLink(message, this.props.navigation);
        } else if (ValidateUtil.isWpDomain(message)) {
            // WPドメイン一緒ならアプリ内で開く
            this.setState({url: message});
        } else {
            // ドメイン別ならアプリ外で開く
            LinkUtil.openLinkWithDefaultBrowser(message);
        }
    };

    /**
     * 未ログイン時の表示
     */
    _renderNoLogin = () => {
        return (
            <View style={{flex: 1, backgroundColor: appColors.backBaseColor}}>
                <NoUserImageComponent navigation={this.props.navigation} />
            </View>
        );
    }

    _renderMain = () => {
        if (this.state.url == null) {
            return;
        }
        let _url: string = this.state.url;

        if (this.state.recommendSubscription) {
            // 無料会員登録の薦め
            return this._renderNoLogin();
        }

        return (
            <WebView
                style={{flex: 1}}
                bounces={false}
                showsHorizontalScrollIndicator={false}
                showsVerticalScrollIndicator={false}
                javaScriptEnabled={true}
                originWhitelist={['*']}
                mixedContentMode={'never'} // security
                startInLoadingState={true} // when starting this component
                onMessage={this.onMessage}
                javaScriptEnabledAndroid={true}
                source={{uri: _url}}
            />
        );
    }

    _renderWide = () => {
        return (
            <SafeAreaView
                style={[{height: AppG.window.height, flex: 1, flexDirection: 'column', backgroundColor: appColors.white}]}
                onLayout={(event) => {}}
            >
                <CustomHeaderComponentWide logoOnly={true} navigation={this.props.navigation}/>

                <View style={{flex: 1, flexDirection: 'row'}}>

                    <View style={[{flex: 1, width: 700, alignSelf: 'center'}]}>
                        {this._renderMain()}
                    </View>
                </View>

            </SafeAreaView>
        );
    }

    _renderSmall = () => {
        return (
            <SafeAreaView
                style={[{height: AppG.window.height, flex: 1, flexDirection: 'column', backgroundColor: appColors.white}]}
                onLayout={(event) => {}}
            >
                <CustomHeaderComponentSmall
                    navigation={this.props.navigation}
                    leftButton={'back'}
                    rightButton={null}
                    title={ValidateUtil.isEmptyExact(this.state.title)? 'Reci BASE' : this.state.title!}
                    searchBar={false}
                    textColor={appColors.black}
                    barColor={appColors.white}
                    borderBottomColor={appColors.transparent}
                    statusBarType={"dark-content"}
                />
                <View style={{flex: 1, backgroundColor: appColors.backBaseColor}}>
                    {this._renderMain()}
                </View>
            </SafeAreaView>
        );
    }

    /** 描画 */
    render() {
        if (ScreenSizeUtil.isWebSize()) {
            // WEBサイズ
            return this._renderWide();
        } else {
            // SPサイズ
            return this._renderSmall();
        }


    };


}
