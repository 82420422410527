import React, {Component} from 'react';
import {Image, Platform, Text, TouchableOpacity, View} from 'react-native';
import {appColors, appFont, appS} from "../../resources/styles/style";
import ValidateUtil from "../util/ValidateUtil";
// @ts-ignore
import isURL from "validator/lib/isURL";
import {ProductDetailScreenModel} from "../data/models/screen/ProductDetailScreenModel";
import MyAppUtil from "../util/MyAppUtil";
import {ProductReviewScreenModel} from "../data/models/screen/ProductReviewScreenModel";
import {ATagLinkComponent} from "./ATagLinkComponent";
import AppG from "../util/AppG";
import ScreenSizeUtil from "../util/ScreenSizeUtil";
import DateUtil, {ViewDateFormatEnum} from "../util/DateUtil";

interface Props {
    navigation: any,
    productsInHistory: any[], // 依頼履歴の商品
    showNeedReview: boolean,    // レビューするを表示する場合
}

interface State { }

export class RequestHistoryListComponent extends Component<Props, State> {
    // Propsのデフォルト値
    static defaultProps = {
        showNeedReview: false,
    };

    /**
     * ラベルの色を取得
     * @param label
     */
    _getReviewStatusLabelColor = (label: '依頼中' | '出荷キャンセル' | 'レビューする' | 'レビュー済み' | null) => {
        if (label == '依頼中') {
            return appColors.sampleReviewRequestedLabel;
        }
        if (label == '出荷キャンセル') {
            return appColors.sampleReviewCanceledLabel;
        }
        if (label == 'レビューする') {
            return appColors.actionColor;
        }
        if (label == 'レビュー済み') {
            return appColors.sampleReviewFinishedLabel;
        }
        return appColors.transparent;
    }

    /**
     * 依頼履歴のセル
     * @param review
     * @param index
     */
    _renderProductInHistory = (review: any, index: number) => {
        const _label = MyAppUtil._getReviewStatusLabel(review);

        if (this.props.showNeedReview) {
            if (_label != 'レビューする') {
                return null;
            }
        } else {
            if (_label == 'レビューする') {
                return null;
            }
        }

        const _imageSize = 60;
        const _cellHeight = _imageSize + appS.margins.side * 2;

        let source = require('../../resources/images/no_image.png');
        if (!ValidateUtil.isEmptyArray(review.product!.images) && isURL(review.product!.images[0].url)) {
            source = {uri: review.product!.images[0].url};
        }

        let _width = AppG.window.width;
        if (ScreenSizeUtil.isWebSize()) {
            _width = appS.webMainContentsSize.width;
        }
        _width = _width - appS.margins.side * 2;

        return (
            <View style={{flex: 1, height: _cellHeight}}>
                <View
                    style={{
                        height: _cellHeight,
                        flexDirection: "row",
                    }}
                >
                    <View style={{
                        flexDirection: "row",
                        flex: 1,
                    }}>
                        <ATagLinkComponent
                            enableATag={false}
                            href={`/ProductReviewScreen?productId=${review.product.id}&reviewSetId=${review.product.reviewSetId}/`}
                            style={{
                                flex: 1,
                                height: _cellHeight,
                                flexDirection: "row",
                            }}
                            onPress={() => {
                                if (this.props.showNeedReview) {
                                    // レビュー画面へ遷移
                                    let productReviewScreenModel: ProductReviewScreenModel = new ProductReviewScreenModel();
                                    productReviewScreenModel.productId = review.product.id.toString();
                                    productReviewScreenModel.reviewSetId = review.product.reviewSetId.toString();
                                    this.props.navigation.navigate('ProductReviewScreen', productReviewScreenModel);
                                } else {
                                    // 商品詳細画面へ遷移
                                    let productDetailScreenModel: ProductDetailScreenModel = new ProductDetailScreenModel();
                                    productDetailScreenModel.productId = review.product!.id.toString();
                                    if (Platform.OS == 'web') {
                                        this.props.navigation.navigate('ProductDetailScreen', productDetailScreenModel);
                                    } else {
                                        this.props.navigation.push('ProductDetailScreen', productDetailScreenModel);
                                    }
                                }
                            }}
                            view={
                                <View style={{flexDirection: 'row', width: _width }}>
                                    <Image source={source}
                                           style={[{
                                               backgroundColor: 'white',
                                               borderRadius: 5,
                                               borderColor: appColors.transparent,
                                               width: _imageSize,
                                               height: _imageSize,
                                               margin: appS.margins.side,
                                           }]}
                                           resizeMode={'cover'}
                                    />
                                    <View style={{flex: 1, flexDirection: "column", height: _cellHeight}}>
                                        <View style={{flexDirection: 'row-reverse'}}>
                                            {_label == 'レビューする' && (
                                                <Image source={require('../../resources/images/02.menu_general/arrow_W_right.png')}
                                                       style={[{
                                                           zIndex: 2,
                                                           marginTop: Platform.OS == 'ios'? 20 : 19,
                                                           marginLeft: -13,
                                                           width: 10,
                                                           height: 10,
                                                       }]}
                                                       resizeMode='contain'
                                                />
                                            )}
                                            <Text
                                                style={{
                                                    marginTop: 15,
                                                    marginBottom: Platform.OS == 'android'? -5: 0,
                                                    lineHeight: appS.sampleReviewStatusLabel.height,
                                                    textAlign: 'center',
                                                    textAlignVertical: 'center',
                                                    width: appS.sampleReviewStatusLabel.width,
                                                    height: appS.sampleReviewStatusLabel.height,
                                                    fontFamily: appFont.family.default,
                                                    fontSize: appFont.size.sampleReviewStatusLabel,
                                                    color: _label == null? appColors.transparent : (this.props.showNeedReview? appColors.white : appColors.fontBlack),
                                                    backgroundColor: this._getReviewStatusLabelColor(_label),
                                                }}
                                            >{_label}</Text>
                                        </View>
                                        <View style={{padding: 4, paddingTop: 0}}>
                                            <Text
                                                style={{
                                                    fontFamily: appFont.family.default,
                                                    fontSize: appFont.size.default,
                                                    color: appColors.black,
                                                }}
                                                numberOfLines={1}
                                                ellipsizeMode='tail'
                                            >
                                                {review.product!.name}</Text>

                                            <View style={{flexDirection: 'row-reverse'}}>
                                                <Text
                                                    style={{
                                                        fontFamily: appFont.family.default,
                                                        fontSize: appFont.size.small,
                                                        color: appColors.fontGray,
                                                        marginTop: Platform.OS == 'android'? -15 : 2,
                                                    }}
                                                    numberOfLines={1}
                                                    ellipsizeMode='tail'
                                                >
                                                    {`依頼日：${DateUtil.getViewDateFromDate(review.date, ViewDateFormatEnum.YYYY_MM_DD)}`}</Text>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                            }
                        />

                    </View>
                </View>
            </View>
        );
    }

    render() {
        const {} = this.props;
        return (
            <View>
                {!ValidateUtil.isEmptyArray(this.props.productsInHistory) && this.props.productsInHistory.map((review: any, index: number) => {
                    return (
                        <View key={`history_view_${index}`}>
                            {this._renderProductInHistory(review, index)}
                        </View>
                    );
                })}
            </View>
        );
    }
}
