import React from 'react'
import {
    Dimensions,
    Image,
    Platform,
    SafeAreaView,
    ScrollView,
    StyleProp,
    Text, TextStyle,
    TouchableOpacity,
    View
} from 'react-native'
import {appColors, appFont, appS} from '../../resources/styles/style'
import {BaseScreen} from "./BaseScreen";
import LoginUtil from "../util/LoginUtil";
import {IndicatorComponent} from "../components/IndicatorComponent";
// @ts-ignore
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scrollview';
import LoginDataDao from "../data/dao/local/LoginDataDao";
import {LoginDataEntityModel} from "../data/entityModels/LoginDataEntityModel";
import {
    ChatApiFactory, Ec,
    FollowApiFactory,
    ImageFile,
    Product,
    ProductApiFactory,
    Recipe,
    RecipeApiFactory,
    UserUserAttributeEnum,
} from "../data/network/swagger-gen";
import AppGlobalDataUtil from "../util/AppG";
import AppG from "../util/AppG";
import {AxiosResponse} from "axios";
import {CustomHeaderComponentSmall} from "../components/small/CustomHeaderComponentSmall";
import UrlUtil from "../util/UrlUtil";
import AlertUtil from "../util/AlertUtil";
// @ts-ignore
import isURL from 'validator/lib/isURL';
import Carousel from 'react-native-reanimated-carousel';
import AnimatedDotsCarousel from 'react-native-animated-dots-carousel';
import {BorderComponent} from "../components/BorderComponent";
import {TopicTitleComponent} from "../components/TopicTitleComponent";
import {ProductDetailScreenModel} from "../data/models/screen/ProductDetailScreenModel";
import ValidateUtil from "../util/ValidateUtil";
import StrUtil from "../util/StrUtil";
import {MakerDetailScreenModel} from "../data/models/screen/MakerDetailScreenModel";
// @ts-ignore
import {getEnvVars, log} from '../../../environment';
import MyAppUtil from "../util/MyAppUtil";
import {SampleRequestScreenModel} from "../data/models/screen/SampleRequestScreenModel";
import I18n from "../../resources/language/i18n";
import ExpoUtil from "../util/ExpoUtil";
import ScreenSizeUtil from "../util/ScreenSizeUtil";
import {CustomHeaderComponentWide} from "../components/wide/CustomHeaderComponentWide";
import {SpacerComponent} from "../components/SpacerComponent";
import {WideScreenAdComponent} from "../components/wide/WideScreenAdComponent";
import AnalyticsUtil, {AnalyticsEventName, DOpenEventName, ShareBtnName} from "../util/firebase/AnalyticsUtil";
import {SearchScreenModel} from "../data/models/screen/SearchScreenModel";
import LinkUtil from "../util/LinkUtil";
import Hyperlink from "react-native-hyperlink";
import {LinkComponent} from "../components/LinkComponent";
import {ModalComponent} from "../components/ModalComponent";
import {ChatScreenModel, InquiryStatusEnum} from "../data/models/screen/ChatScreenModel";

import {ShareComponent} from "../components/ShareComponent";
import WebTagUtil from "../util/WebTagUtil";
import SearchDataDao from "../data/dao/local/SearchDataDao";
import {
    RecipeSameProductListHorizontalScrollComponent
} from "../components/RecipeSameProductListHorizontalScrollComponent";
import {ATagLinkComponent} from "../components/ATagLinkComponent";
import {WideSubTitleComponent} from "../components/wide/WideSubTitleComponent";
import {WhatIsSampleRequestModalComponent} from "../components/WhatIsSampleRequestModalComponent";
import {NoUserImageComponent} from "../components/NoUserImageComponent";
import {WideScreenSNSComponent} from "../components/wide/WideScreenSNSComponent";
import {SearchWordsComponent} from "../components/SearchWordsComponent";
import {ImageModalComponent} from "../components/ImageModalComponent";
import PopupUtil from "../util/PopupUtil";
import {EcSiteModalComponent} from "../components/EcSiteModalComponent";

interface Props {
    navigation: any, // ナビゲーション用
}

interface State {
    resizeWindow: boolean,  // 値に意味はなし。windowサイズの拡大縮小をrenderに送るためのもの
    isLoaded: boolean,
    isShowErrorModal: boolean,

    // コンテンツ
    product: Product | null, // この商品
    sameProductRecipes: Recipe[] | undefined, // この商品を使った他のレシピ
    popularSetRecipes: Recipe[] | null, // よく一緒見られているレシピ
    activeProductSlide: number,    // 商品SlideShowの現在のSlide
    followed: boolean,  // 商品をフォローしてるかどうか

    showBigImage: boolean,  // 画像の拡大表示
    productBigImage: ImageFile | null,  // 拡大表示される画像

    isShowSentModal: boolean,   // サンプルかごに追加しました

    isShowEcButton: boolean,    // 購入先を選択するボタンの表示
}

export class ProductDetailScreen extends BaseScreen<Props, State> {
    _resizeTimer = setInterval(() => {
        // window 拡大縮小を反映させる
        AppG.window = Dimensions.get('window');
        if (AppG.window.width != AppG.windowBefore.width || AppG.window.height != AppG.windowBefore.height) {
            this.setState({'resizeWindow': true});
            AppG.windowBefore = Dimensions.get('window');
        }
        LoginUtil.setRefreshedScreenFlg(false);
    }, 100);

    _loginData = new LoginDataEntityModel();

    _flameSize = ScreenSizeUtil.isWebSize()? AppG.window.width / 10 : 16;    // 左右の margin
    _marginSize = ScreenSizeUtil.isWebSize()? 0 : appS.margin.size;

    // このScreenへ渡すパラム
    _param: ProductDetailScreenModel = new ProductDetailScreenModel();

    _ecSiteModalRef: any = null;
    _whatIsSampleRequestModalRef: any = null;

    _ecs: Ec[] = [];

    /** 画像の dots の Index */
    _dotsProgressIndex: number = 0;

    _browserBackListener = (e: any) => {

    };

    constructor(props: any) {
        super(props);
        this.state = {
            resizeWindow: true,  // 値に意味はなし。windowサイズの拡大縮小をrenderに送るためのもの
            isLoaded: false,
            isShowErrorModal: false,
            product: null,
            sameProductRecipes: [],
            popularSetRecipes: [],
            activeProductSlide: 0,
            followed: false,
            showBigImage: false,
            productBigImage: null,
            isShowSentModal: false,
            isShowEcButton: false
        };
    }

    /** データロードされるまで再送信を繰り返すPvAnalytics */
    _sentPvAnalyticsTimer: any;
    /**
     * 画面開いたときのアナリティクス送信
     */
    _sendPvAnalytics = () => {

        if (!ValidateUtil.isEmptyExact(this._param.isPreview) && this._param.isPreview == '1') {
            // isPreview だったら送信しない
            return;
        }

        this._sentPvAnalyticsTimer = setInterval(() => {
            if (this.state.product != null) {
                clearInterval(this._sentPvAnalyticsTimer);
                AnalyticsUtil.sendAnalytics(
                    DOpenEventName.openPage,
                    AnalyticsEventName.openPage,  // アナリティクスのイベント名
                    'ProductDetailScreen',
                    null,
                    this.state.product,
                    this.state.product.maker != null ? this.state.product.maker: null,
                    null,
                );
            }
        },  100);
    }

    /** データロードされるまで再送信を繰り返すPvAnalytics */
    _sentProductAnalyticsTimer: any;
    /**
     * 商品のアナリティクス送信
     */
    _sendProductAnalytics = () => {

        if (!ValidateUtil.isEmptyExact(this._param.isPreview) && this._param.isPreview == '1') {
            // isPreview だったら送信しない
            return;
        }

        this._sentProductAnalyticsTimer = setInterval(() => {
            if (this.state.product != null) {
                clearInterval(this._sentProductAnalyticsTimer);
                AnalyticsUtil.sendAnalytics(
                    null,
                    `maker_${this.state.product.maker?.id}_product_${this.state.product.id}`,  // アナリティクスのイベント名
                    'ProductDetailScreen',
                    null,
                    this.state.product,
                    this.state.product.maker != null ? this.state.product.maker: null,
                    null,
                );
            }
        },  100);
    }

    componentDidMount() {
        /** 初回表示フラグ */
        let _initLoadFlg = true;
        this._param = this._getParams();

        if (Platform.OS == 'web') {
            window.addEventListener('popstate', this._browserBackListener);
        }

        LoginUtil.interruptOpenScreen(this.props.navigation,
            () => {
                LoginDataDao.get().then(loginData => {
                    // Web 戻るでも呼ばれる
                    if (Platform.OS == 'web') {
                        // Analytics
                        this._sendPvAnalytics();
                        this._sendProductAnalytics();
                    }
                    AppG.getFooterBadge();

                    // URL Scheme で外部リンクから起動したときの処理と、画面遷移でこの画面を開いたときの処理
                    LoginUtil.setDefaultListener(
                        this.props.navigation,
                        () => {
                            // Webはここにこない
                            if (Platform.OS != 'web') {
                                // Analytics
                                this._sendPvAnalytics();
                                this._sendProductAnalytics();
                            }
                            //
                            // if (_initLoadFlg) {
                            //     _initLoadFlg = false;
                            //     return;
                            // }
                            // this.setState({'isLoaded': false});
                            // this._loadContents().then(() => {
                            //     this.setState({'isLoaded': true});
                            // });
                            //
                            // 画面遷移時のリロード
                            ExpoUtil.doReloadAppTab(() => {
                                AppG.getFooterBadge();
                            });
                        },
                        () => {
                            // this.setState({'isLoaded': false});
                        });

                    if (loginData != null) {
                        this._loginData = loginData;
                    }
                    this._loadMasters().then(() => {});
                    this._loadContents().then(() => {
                        this.setState({'isLoaded': true});
                    });
                });
            });
    }

    componentWillUnmount () {
        clearTimeout(this._resizeTimer);
        if (Platform.OS === 'web') {
            window.removeEventListener('popstate', this._browserBackListener);
        }
        LoginUtil.removeDefaultListener(this.props.navigation);
    };

    componentDidUpdate () {
        // レンダー完了時に呼ばれる
    }

    /**
     * パラムの取得
     * @private
     */
    _getParams = () => {
        let productDetailScreenModel: ProductDetailScreenModel = UrlUtil.getParamModel(new ProductDetailScreenModel(), this.props.navigation);
        return productDetailScreenModel;
    };

    /**
     * マスターのロード
     * @private
     */
    _loadMasters = async () => {

    };

    /**
     * コンテンツのロード
     * @private
     */
    _loadContents = async () => {
        this._param = this._getParams();
        if (this._param.productId == null) {
            return;
        }

        // 他のレシピ、よく一緒に見られているレシピの表示個数
        const numOfRecipe = 10;

        let _pv = 1;
        if (!ValidateUtil.isEmptyExact(this._param.isPreview) && this._param.isPreview == '1') {
            // プレビューでpv除外
            _pv = 0;
        }

        if (AppG.isTestAccounts()) {
            // テストアカウントでpv除外
            _pv = 0;
        }

        // 商品
        const getProduct = ProductApiFactory(AppGlobalDataUtil.getConfiguration(), AppGlobalDataUtil.getBasePath())
            .getProduct(
                this._param.productId,
                _pv,
                5,
                !ValidateUtil.isEmptyExact(this._param.isPreview)? parseInt(this._param.isPreview!) : undefined,
            ).then((productRes: AxiosResponse<Product>) => {
                if (productRes != null && productRes.data != null) {
                    // console.log('#####################-----------------------##############################');
                    // console.log(productRes.data);
                    let _price = '';
                    if (!ValidateUtil.isEmptyExact(productRes.data.sampleAmount)) {
                        if (!ValidateUtil.isEmptyExact(productRes.data.samplePrice)) {
                            _price = `サンプル価格:${productRes.data.samplePrice}円`;
                        } else {
                            _price = `サンプル価格:0円`;
                        }
                    }

                    // meta 更新
                    WebTagUtil.setOgType('article');
                    WebTagUtil.setTags(`${productRes.data.name}|${productRes.data.description}|${productRes.data.maker?.name}|Reci BASE`, `${productRes.data.description},${productRes.data.otherInformation},${_price}`, productRes.data.images[0].url);

                    this.setState({product: productRes.data});

                    // ECサイト取得
                    ProductApiFactory(AppGlobalDataUtil.getConfiguration(), AppGlobalDataUtil.getBasePath())
                        .getEc(
                            parseInt(this._param.productId!)
                        ).then((ecRes: AxiosResponse<Ec[]>) => {
                            // @ts-ignore
                        if (ecRes != null && ecRes.data != null && ecRes.data != '') {
                            this._ecs = ecRes.data;
                            this.setState({isShowEcButton: true});
                        }
                    })

                    // この商品を使った他のレシピ取得
                    RecipeApiFactory(AppGlobalDataUtil.getConfiguration(), AppGlobalDataUtil.getBasePath())
                        .findRecipes(
                            0,
                            numOfRecipe,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            productRes.data.id,
                            undefined,
                            4,
                        )
                        .then((sameProductRecipeRes: AxiosResponse<Recipe[]>) => {
                            if (sameProductRecipeRes != null && sameProductRecipeRes.data != null) {
                                this.setState({sameProductRecipes: sameProductRecipeRes.data});
                            } else {
                                this.setState({sameProductRecipes: []});
                            }
                        });

                    // TODO よく一緒に見られているレシピ

                } else {
                    this.setState({product: null});
                }
            });

        const getProductFollow = ProductApiFactory(AppGlobalDataUtil.getConfiguration(), AppGlobalDataUtil.getBasePath())
            .getProductFollow(
                parseInt(this._param.productId)
            ).then((productFollowRes) => {
                // お気に入り
                if (productFollowRes != null && productFollowRes.data != null && productFollowRes.data.followed != null && productFollowRes.data.followed === 1) {
                    this.setState({followed: true});
                } else {
                    this.setState({followed: false});
                }
            });

        // 実行
        await Promise.all([getProduct]);
    }

    /**
     * お問い合わせチャット開設（既にある場合はIDを取得）
     */
    _createInquiryChat = async () => {

        if (this.state.product == null || this.state.product?.maker == null) {
            return null;
        }

        AnalyticsUtil.sendAnalytics(
            DOpenEventName.inquiry,
            AnalyticsEventName.inquiry,  // アナリティクスのイベント名
            'ProductDetailScreen',
            null,
            this.state.product,
            this.state.product?.maker != null ? this.state.product.maker : null,
            null,
            null,
            null,
            null,
            null,
        );

        // チャット開設（既にある場合はIDを取得）
        const getChats = ChatApiFactory(AppGlobalDataUtil.getConfiguration(), AppGlobalDataUtil.getBasePath())
            .createChat(`${this.state.product?.maker.id}`).then((chatsRes: AxiosResponse) => {
                log.debug(`--------------- chatsRes --------------`);
                log.debug(chatsRes.data);

                if (chatsRes == null || chatsRes.data != null) {
                    let chatScreenModel = new ChatScreenModel();
                    chatScreenModel.chatId = chatsRes.data;
                    chatScreenModel.makerId = this.state.product!.maker!.id.toString();
                    chatScreenModel.inquiryStatus = InquiryStatusEnum.PRODUCT;
                    chatScreenModel.inquiryProductId = this.state.product!.id.toString();
                    this.props.navigation.navigate('ChatScreen', chatScreenModel);
                    return;
                }

            });

        // 実行
        await Promise.all([getChats]);
    }

    /**
     * 未ログイン時の表示
     */
    _renderNoLogin = () => {
        return (
            <View style={{flex: 1, backgroundColor: appColors.backBaseColor}}>
                <NoUserImageComponent navigation={this.props.navigation} />
            </View>
        );
    }

    /**
     * キーワードの表示
     */
    _renderKeywords = () => {

        if (this.state.product == null) {
            return null;
        }

        if (ValidateUtil.isEmptyArray(this.state.product!.keywords)) {
            return null;
        }

        let _width = AppG.window.width;
        if (ScreenSizeUtil.isWebSize()) {
            _width = appS.webSideContentsSize.width;
        }
        _width = _width - appS.margins.side * 2;

        //キーワードを6個までにして変数で渡す
        let recipeKeywords = this.state.product!.keywords!
        if(recipeKeywords.length <= 6) {
            recipeKeywords;
        } else {
            recipeKeywords = this.state.product!.keywords!.slice( 0, 6);
        }

        return (
            <View style={{margin: appS.margins.side}}>
                {/*{ScreenSizeUtil.isWebSize() && (<BorderComponent borderWidth={1} borderColor={appColors.spacerGray} width={_width}/>)}*/}
                <TopicTitleComponent title={'キーワード'} subTopic={true}/>
                <SpacerComponent height={appS.margins.betweenContentSmall}/>
                <View>
                    <SearchWordsComponent
                        width={ScreenSizeUtil.isWebSize()? appS.webSideContentsSize.width : null}
                        noMargin={ScreenSizeUtil.isWebSize()}
                        keywords={recipeKeywords}
                        onPress={(keyword) => {
                            const { routeName } = this.props.navigation.state;
                            let searchScreenModel: SearchScreenModel = new SearchScreenModel();
                            searchScreenModel.headerTitle = `${keyword.value}`;
                            searchScreenModel.searchType = 'product';
                            searchScreenModel.keyword = `${keyword.value}`;
                            searchScreenModel.searchValue = `${keyword.value}`;
                            searchScreenModel.screenNameFrom = routeName;
                            if (Platform.OS == 'web') {
                                this.props.navigation.navigate('SearchResultScreen', searchScreenModel);
                            } else {
                                this.props.navigation.push('SearchResultScreen', searchScreenModel);
                            }
                        }}/>
                </View>
                {!ScreenSizeUtil.isWebSize() && <BorderComponent borderWidth={1} borderColor={appColors.spacerGray} width={_width}/>}
            </View>
        );
    }

    /**
     * 画像の拡大表示
     */
    _renderBigImages = () => {
        if (!this.state.showBigImage) {
            return null;
        }

        if (this.state.productBigImage == null) {
            return null;
        }

        let source: any = null;
        if (this.state.productBigImage != null && this.state.productBigImage.url != null && this.state.productBigImage.url != '' && isURL(this.state.productBigImage.url)) {
            source = {uri: this.state.productBigImage.url};
        }

        if (source == null) {
            return null;
        }

        if (ScreenSizeUtil.isWebSize()) {
            let _imageSize = {width: 200, height: 200/1.8};

            return (
                <TouchableOpacity
                    style={{zIndex: 101, position: 'absolute', backgroundColor: appColors.opacityGray, height: AppG.window.height + 60, width: AppG.window.width,}}
                    onPress={() => {
                        this.setState({showBigImage: false});
                        this.setState({productBigImage: null});
                    }}>
                    <View style={{
                        position: 'relative',
                        padding: 32,
                        width: AppG.window.width * 3 / 5,
                        top: 0,
                        left: AppG.window.width / 5,
                        backgroundColor: appColors.transparent,
                        alignItems: 'center',
                    }}>
                        <TouchableOpacity
                            style={{marginBottom: 8, backgroundColor: appColors.white, paddingTop: 8, paddingBottom: 8, paddingLeft: 8, paddingRight: 8}}
                            onPress={() => {
                                // press を吸収
                            }}>
                            <Image
                                source={source}
                                style={[{
                                    backgroundColor: appColors.white,
                                    width: 800,
                                    height: 800 / 1.8,
                                }]}
                                resizeMode={'cover'}
                                accessibilityLabel={this.state.product?.name}
                            />
                        </TouchableOpacity>
                        {!ValidateUtil.isEmptyArray(this.state.product?.images) && this.state.product!.images!.length > 1 && (
                            <ScrollView
                                style={{width: 816, backgroundColor: appColors.white}}
                                horizontal={true}
                            >
                                <TouchableOpacity style={{flex: 1, flexDirection: 'row', padding: 16, backgroundColor: appColors.white}}>
                                    {!ValidateUtil.isEmptyArray(this.state.product?.images) && this.state.product?.images.map( (_image: ImageFile, index) => {
                                        let _source = null;
                                        if (_image != null && _image.url != null && _image.url != '' && isURL(_image.url)) {
                                            _source = {uri: _image.url};
                                        }

                                        if (_source == null) {
                                            return null;
                                        }
                                        return (
                                            <TouchableOpacity
                                                key={`images_${index}`}
                                                style={{marginLeft: 4, marginRight: 4}}
                                                onPress={() => {
                                                    this.setState({productBigImage: _image});
                                                }}
                                            >
                                                <Image
                                                    key={`images_2_${index}`}
                                                    source={_source}
                                                       style={[{
                                                           backgroundColor: 'white',
                                                           width: _imageSize.width,
                                                           height: _imageSize.height,
                                                       }]}
                                                       resizeMode={'cover'}
                                                       accessibilityLabel={this.state.product?.name}
                                                />
                                            </TouchableOpacity>
                                        );
                                    })}
                                </TouchableOpacity>
                            </ScrollView>
                        )}
                    </View>
                </TouchableOpacity>
            );
        } else {
            // スマホ
            let _imageSize = {width: AppG.window.width/1.8, height: (AppG.window.width/1.8)/1.8};
            let _topHeight = Platform.OS == 'web'? 150: 250;

            return (
                <View
                    style={{zIndex: 101, position: 'absolute', backgroundColor: appColors.opacityGray, height: AppG.window.height + 60, width: AppG.window.width,}}
                >
                    <ScrollView
                        minimumZoomScale={1}
                        maximumZoomScale={2}
                    >
                        <TouchableOpacity
                            style={{
                                height: _topHeight,
                                width: AppG.window.width,
                                backgroundColor: appColors.transparent,
                                alignItems: 'center',
                            }}
                            onPress={() => {
                                this.setState({showBigImage: false});
                                this.setState({productBigImage: null});
                            }}>
                        </TouchableOpacity>

                        <View
                            style={{
                                marginBottom: 8,
                                backgroundColor: appColors.white,
                                paddingTop: 8,
                                paddingBottom: 8,
                                paddingLeft: 0,
                                paddingRight: 0,
                            }}
                        >
                            <Image
                                source={source}
                                style={[{
                                    backgroundColor: appColors.white,
                                    width: AppG.window.width,
                                    height: AppG.window.width / 1.8,
                                }]}
                                resizeMode={'cover'}
                                accessibilityLabel={this.state.product?.name}
                            />
                        </View>
                        {!ValidateUtil.isEmptyArray(this.state.product?.images) && this.state.product!.images!.length > 1 && (
                            <ScrollView
                                style={{width: AppG.window.width, backgroundColor: appColors.white}}
                                horizontal={true}
                            >
                                <TouchableOpacity style={{flex: 1, flexDirection: 'row', padding: 16, backgroundColor: appColors.white}}>
                                    {!ValidateUtil.isEmptyArray(this.state.product?.images) && this.state.product?.images.map( (_image: ImageFile, index) => {
                                        let _source = null;
                                        if (_image != null && _image.url != null && _image.url != '' && isURL(_image.url)) {
                                            _source = {uri: _image.url};
                                        }

                                        if (_source == null) {
                                            return null;
                                        }
                                        return (
                                            <TouchableOpacity
                                                style={{marginLeft: 4, marginRight: 4}}
                                                onPress={() => {
                                                    this.setState({productBigImage: _image});
                                                }}
                                                key={`image_btn_${index}`}
                                            >
                                                <Image source={_source}
                                                       style={[{
                                                           backgroundColor: 'white',
                                                           width: _imageSize.width,
                                                           height: _imageSize.height,
                                                       }]}
                                                       resizeMode={'cover'}
                                                       key={`image_${index}`}
                                                       accessibilityLabel={this.state.product?.name}
                                                />
                                            </TouchableOpacity>
                                        );
                                    })}
                                </TouchableOpacity>
                            </ScrollView>
                        )}
                        <TouchableOpacity
                            style={{
                                height: AppG.window.height - (AppG.window.width / 1.8 + _topHeight + 100),
                                width: AppG.window.width,
                                backgroundColor: appColors.transparent,
                                alignItems: 'center',
                            }}
                            onPress={() => {
                                this.setState({showBigImage: false});
                                this.setState({productBigImage: null});
                            }}>
                        </TouchableOpacity>
                    </ScrollView>
                </View>
            );
        }
    }

    /**
     * 商品画像のスライドショーのItem作成
     * @param recipeImage
     * @private
     */
        // @ts-ignore
    _renderProductImage = ({item}) => {
        let productImage: ImageFile = item;
        let source = require('../../resources/images/no_image.png');
        if (productImage != null && productImage.url != null && productImage.url != '' && isURL(productImage.url)) {
            source = {uri: productImage.url};
        }

        let _width = AppG.window.width;
        if (ScreenSizeUtil.isWebSize()) {
            _width = appS.webMainContentsSize.width;
        }

        const _imageSize = {
            width: _width,
            height: _width / 1.8
        };
        const _followIconSize = appS.button.followIconSizeLarge;

        return (
            <View>
                <TouchableOpacity
                    onPress={() => {
                        this.setState({productBigImage: productImage});
                        this.setState({showBigImage: true});
                    }}>
                    <Image
                        source={source}
                        style={[{
                            backgroundColor: 'white',
                            width: _imageSize.width,
                            height: _imageSize.height,
                        }]}
                        resizeMode={'cover'}
                        accessibilityLabel={this.state.product?.name}
                    />
                </TouchableOpacity>

                {/*お気に入りボタン*/}
                <TouchableOpacity
                    style={{
                        zIndex: 1,
                        position: 'relative',
                        top: -_imageSize.height + 20,
                        left: 25,
                        width: _followIconSize,
                        height: _followIconSize,
                        borderRadius: 50,
                        backgroundColor: appColors.transparent,
                        alignItems: 'center',
                        justifyContent: 'center',
                        margin: 0
                    }}
                    disabled={AppG.user == null}
                    onPress={() => {
                        if (AppG.user == null) {
                            return;
                        }

                        if (!ValidateUtil.isEmptyExact(this._param.isPreview) && this._param.isPreview == '1') {
                            // isPreview だったら送信しない
                            return;
                        }

                        let type = 'follow';
                        if (this.state.followed) {
                            type = 'unfollow'
                        }
                        FollowApiFactory(AppGlobalDataUtil.getConfiguration(), AppGlobalDataUtil.getBasePath())
                            .followProduct(this.state.product!.id.toString(), type).then(() => {
                            // Analytics
                            AnalyticsUtil.sendAnalytics(
                                type == 'follow'? DOpenEventName.follow : DOpenEventName.unfollow,
                                type == 'follow'? AnalyticsEventName.follow : AnalyticsEventName.unfollow,  // アナリティクスのイベント名
                                'ProductDetailScreen',
                                null,
                                this.state.product,
                                this.state.product?.maker,
                                null,
                            );

                            ExpoUtil.setShouldReloadAppTab();   // 別タブで必要ならリロードするようにセット
                            this.setState({followed: !this.state.followed});
                        });
                    }}
                >
                    {/*フォロー中*/}
                    {this.state.followed && (
                        <Image source={AppG.user == null ? require('../../resources/images/skelton.png') : require('../../resources/images/03.item_recipe/i_item_like_on.png')}
                               style={[{
                                   backgroundColor: appColors.transparent,
                                   width: _followIconSize,
                                   height: _followIconSize,
                               }]}
                               resizeMode={'cover'}
                        />
                    )}
                    {/*フォローしてない*/}
                    {!this.state.followed && (
                        <Image source={AppG.user == null ? require('../../resources/images/skelton.png') : require('../../resources/images/03.item_recipe/i_item_like_off.png')}
                               style={[{
                                   backgroundColor: appColors.transparent,
                                   width: _followIconSize,
                                   height: _followIconSize,
                               }]}
                               resizeMode={'cover'}
                        />
                    )}

                </TouchableOpacity>
            </View>
        );
    };

    /**
     * 商品画像
     */
    _renderProductImages = () => {
        if (this.state.product == null) {
            return null;
        }

        let _width = AppG.window.width;
        if (ScreenSizeUtil.isWebSize()) {
            _width = appS.webMainContentsSize.width;
        }

        return (
            <View>
                <Carousel
                    data={this.state.product?.images}
                    renderItem={this._renderProductImage}
                    onSnapToItem={(index: number) => {
                        this.setState({activeProductSlide: index})
                    }}
                    width={_width}
                    height={_width / 1.8}
                    autoPlay={this.state.product?.images != null && this.state.product?.images.length > 1}
                    autoPlayInterval={7000}
                    loop={true}
                    style={{margin: 0}}
                    onProgressChange={(_, absoluteProgress) => {
                        if (absoluteProgress % 1 === 0) {
                            this._dotsProgressIndex = absoluteProgress;
                        }
                    }}
                    enabled={this.state.product?.images != null && this.state.product?.images.length > 1}
                />
                {this.state.product?.images.length >= 2 && (
                    <View style={{
                        marginTop: 8,
                        marginBottom: 8,
                        alignItems: 'center'
                    }}>
                        <AnimatedDotsCarousel
                            length={this.state.product?.images.length}
                            currentIndex={this._dotsProgressIndex}
                            maxIndicators={this.state.product?.images.length}
                            interpolateOpacityAndColor={false}
                            activeIndicatorConfig={{
                                color: appColors.black,
                                margin: 3,
                                opacity: 1,
                                size: 8,
                            }}
                            inactiveIndicatorConfig={{
                                color: appColors.dotsGray,
                                margin: 3,
                                opacity: 0.5,
                                size: 8,
                            }}
                            decreasingDots={[
                                {
                                    config: { color: appColors.dotsGray, margin: 3, opacity: 0.5, size: 8 },
                                    quantity: 1,
                                },
                            ]}
                        />
                    </View>
                )}

            </View>
        );
    }

    /**
     * 商品名など
     */
    _renderProductName = () => {
        if (this.state.product == null) {
            return null;
        }

        let _width = AppG.window.width;
        if (ScreenSizeUtil.isWebSize()) {
            _width = appS.webMainContentsSize.width;
        }
        _width = _width - appS.margins.side * 2;

        return (
            <View style={{margin: appS.margins.side}}>
                <SpacerComponent height={appS.margins.side} />
                {/*商品名*/}
                {this.state.product?.samplePaid == 1 && (
                    <View
                        style={{
                            marginRight: 8,
                            marginTop: -15,
                            width: 50,
                            height: 20,
                            borderRadius: 50,
                            backgroundColor: this.state.product?.samplePaid == 1? appColors.goldYellow: appColors.transparent,
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Text style={{marginTop: 0, fontFamily: appFont.family.bold, fontSize: 14, color: this.state.product?.samplePaid == 1? appColors.white: appColors.transparent, fontWeight: "bold"}}>有償</Text>
                    </View>
                )}
                <Text
                    style={{
                        fontFamily: appFont.family.bold,
                        marginTop: 0,
                        fontSize: appFont.size.productName,
                        fontWeight: "bold",
                        color: appColors.fontBlack,
                    }}>
                    {this.state.product?.name}
                </Text>



                {/*商品コード*/}
                <View style={{flexDirection: "row", marginTop: appS.margins.betweenContentSmall}}>
                    <Text
                        style={{
                            fontFamily: appFont.family.bold,
                            marginTop: 0,
                            fontSize: appFont.size.productCode,
                            fontWeight: "bold",
                            color: appColors.fontBlack,
                        }}>
                        {`　`}
                        {/*{`商品コード　`}*/}
                    </Text>
                    <Text
                        style={{
                            fontFamily: appFont.family.bold,
                            marginTop: 0,
                            fontSize: appFont.size.productCode,
                            fontWeight: "bold",
                            color: appColors.fontBlack,
                        }}>
                        {`　`}
                        {/*{`${this.state.product?.code}`}*/}
                    </Text>

                    <View style={{flex:1}}/>

                    {/*サンプル価格*/}
                    {!ValidateUtil.isEmptyExact(this.state.product.sampleAmount) && (
                        <View style={{flexDirection: 'row'}}>
                            <Text
                                style={{
                                    fontFamily: appFont.family.default,
                                    marginTop: 0,
                                    fontSize: appFont.size.productCode,
                                    fontWeight: "bold",
                                    color: appColors.limeGreen,
                                }}>
                                {`サンプル価格　`}
                            </Text>
                            <Text
                                style={{
                                    fontFamily: appFont.family.bold,
                                    marginTop: -4,
                                    fontSize: appFont.size.productCode + 4,
                                    fontWeight: "bold",
                                    color: appColors.limeGreen,
                                }}>
                                {`${ValidateUtil.isEmptyExact(this.state.product?.samplePrice)? 0 : this.state.product?.samplePrice}`}
                            </Text>
                            <Text
                                style={{
                                    fontFamily: appFont.family.bold,
                                    marginTop: 0,
                                    fontSize: appFont.size.productCode,
                                    fontWeight: "bold",
                                    color: appColors.limeGreen,
                                }}>
                                {`円`}
                            </Text>
                        </View>
                    )}
                </View>

                <SpacerComponent height={appS.margins.betweenContentSmall} />

                {/*商品説明*/}
                <Text
                    style={{
                        fontFamily: appFont.family.default,
                        fontSize: appFont.size.productDescription,
                        color: appColors.fontGray,
                    }}>
                    {this.state.product?.description}
                </Text>

                {/*商品カテゴリー*/}
                {!ValidateUtil.isEmptyArray(this.state.product?.productCategory) && (
                    <View style={{
                        flexDirection: "row",
                        marginTop: appS.margins.betweenContentSmall
                    }}>
                        <Text
                            style={{
                                fontFamily: appFont.family.bold,
                                marginTop: 0,
                                fontSize: appFont.size.productCode,
                                color: appColors.fontBlack,
                                width: Platform.OS == 'web'? 110 : 100,
                                fontWeight: "bold"
                            }}>
                            {`商品カテゴリー　`}
                        </Text>
                        <View style={{width: _width - 100, marginTop: 1}}>
                            <LinkComponent
                                title={`${MyAppUtil.lineProductCategory(this.state.product?.productCategory)}`}
                                // ellipsizeMode={'tail'}
                                // numberOfLines={1}
                                fontSize={appFont.size.productCode}
                                color={appColors.linkBlue}
                                textDecorationLine={'underline'}
                                onPress={() => {
                                    let ids: string[] = [];
                                    this.state.product?.productCategory.forEach(cat => {
                                        ids.push(cat.large.id!.toString());
                                    });
                                    let searchScreenModel: SearchScreenModel = new SearchScreenModel();
                                    searchScreenModel.headerTitle = `${MyAppUtil.lineProductCategory(this.state.product?.productCategory)}`;
                                    searchScreenModel.productLargeCategoryIds = StrUtil.arrayToParamStr(ids);
                                    searchScreenModel.searchType = 'product';
                                    searchScreenModel.tabHidden = true;
                                    if (Platform.OS == 'web') {
                                        this.props.navigation.navigate('SearchResultScreen', searchScreenModel);
                                    } else {
                                        this.props.navigation.push('SearchResultScreen', searchScreenModel);
                                    }
                                }}
                            />
                        </View>

                    </View>
                )}
            </View>
        );
    }

    /**
     * サンプル依頼エリア
     */
    _renderCartArea = () => {
        if (this.state.product == null) {
            return null;
        }

        const {navigation} = this.props;

        return (
            <View style={{margin: appS.margins.side * 2, marginBottom: appS.line.space}}>
                {/*購入先を選択するボタン*/}
                {this._renderShopsButton()}

                {/*サンプル依頼ボタン*/}
                {this._renderCartButton()}
            </View>
        );
    }

    /**
     * 購入先を選択するボタン
     */
    _renderShopsButton = () => {

        if (this.state.product == null) {
            return null;
        }

        const {navigation} = this.props;

        return (
            <View>
                {/*購入先を選択するボタン*/}
                {this.state.isShowEcButton && (
                    <TouchableOpacity
                        style={[
                            {
                                height: appS.button.height,
                                backgroundColor: appColors.ecButton,
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginBottom: 0,
                                borderRadius: appS.buttonSize.radius
                            }
                        ]}
                        onPress={() => {
                            // ECモーダルの表示
                            if (this._ecSiteModalRef != null) {
                                this._ecSiteModalRef.showModal();
                            }
                        }}
                    >
                        <View style={{flexDirection: 'row'}}>
                            <Text style={{
                                fontFamily: appFont.family.default,
                                color: appColors.white,
                                fontWeight: 'bold',
                                fontSize: appS.button.text
                            }}>購入先を選択する</Text>
                        </View>
                    </TouchableOpacity>
                )}
                {this.state.isShowEcButton && (
                    <View style={{flex: 1, flexDirection: 'row', marginTop: 8}}>
                        <View style={{flex: 1}}/>
                        <LinkComponent
                            title={'購入先を選択するとは？'}
                            color={appColors.linkBlue}
                            textDecorationLine={'underline'}
                            fontSize={14}
                            onPress={() => {
                                LinkUtil.openUrlInWebView('https://wp.recibase.net/new-function-purchase/', this.props.navigation, '購入先を選択するとは？');
                            }}/>
                    </View>
                )}
            </View>
        );
    }

    /**
     * サンプル依頼ボタン
     */
    _renderCartButton = () => {
        if (AppG.user != null && AppG.user.userAttribute == UserUserAttributeEnum.Normal) {
            return null;
        }

        if (this.state.product == null) {
            return null;
        }

        if (ValidateUtil.isEmptyExact(this.state.product.sampleAmount)) {
            return null;
        }


        const {navigation} = this.props;

        return (
            <View style={{marginTop: this.state.isShowEcButton ? 8 : 0}}>
                {/*サンプル依頼ボタン*/}
                <TouchableOpacity
                    style={[
                        {
                            height: appS.button.height,
                            backgroundColor: appColors.productRequestLight,
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginBottom: 10,
                            borderRadius: appS.buttonSize.radius
                        }
                    ]}
                    onPress={() => {
                        if (AppG.user == null) {
                            ValidateUtil.showNoUserAlertAtSampleRequest(this.props.navigation);
                            return;
                        }

                        PopupUtil.showWhatIsSampleRequestModal(
                            () => {
                                // サンプル依頼とはの表示
                                if (this._whatIsSampleRequestModalRef != null) {
                                    this._whatIsSampleRequestModalRef.showModal();
                                }
                            },
                            () => {
                                // 通常の処理
                                if (!ValidateUtil.isEnableSampleRequest()) {
                                    this.setState({isShowErrorModal: true});
                                    return;
                                }

                                let sampleRequestScreenModel: SampleRequestScreenModel = new SampleRequestScreenModel();
                                sampleRequestScreenModel.productIds = `|${this.state.product!.id.toString()}|`;
                                navigation.navigate('SampleRequestScreen', sampleRequestScreenModel);
                            }
                        );
                    }}
                >
                    <View style={{flexDirection: 'row'}}>
                        <Text style={{
                            fontFamily: appFont.family.default,
                            color: appColors.white,
                            fontWeight: 'bold',
                            fontSize: appS.button.text
                        }}>今すぐサンプル依頼</Text>
                        <Image
                            source={require('../../resources/images/02.menu_general/arrow_W_right.png')}
                            style={[appS.buttonAllow]}
                        />
                    </View>
                </TouchableOpacity>
                {/*サンプルかごに入れるボタン*/}
                <TouchableOpacity
                    style={[
                        {
                            height: appS.button.height,
                            backgroundColor: appColors.productRequestDark,
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: appS.buttonSize.radius
                        }
                    ]}
                    onPress={() => {
                        if (AppG.user == null) {
                            ValidateUtil.showNoUserAlertAtSampleRequest(this.props.navigation);
                            return;
                        }

                        if (!ValidateUtil.isEmptyExact(this._param.isPreview) && this._param.isPreview == '1') {
                            // isPreview だったら送信しない
                            return;
                        }

                        PopupUtil.showWhatIsSampleRequestModal(
                            () => {
                                // サンプル依頼とはの表示
                                if (this._whatIsSampleRequestModalRef != null) {
                                    this._whatIsSampleRequestModalRef.showModal();
                                }
                            },
                            () => {
                                // 通常の処理
                                ProductApiFactory(AppGlobalDataUtil.getConfiguration(), AppGlobalDataUtil.getBasePath())
                                    .requestProductIntoCart(this.state.product!.id.toString(), 'in')
                                    .then(() => {
                                        // Analytics
                                        AnalyticsUtil.sendAnalytics(
                                            DOpenEventName.cartIn,
                                            AnalyticsEventName.cartIn,  // アナリティクスのイベント名
                                            'ProductDetailScreen',
                                            null,
                                            this.state.product,
                                            null,
                                            null,
                                        );

                                        this.setState({isShowSentModal: true});
                                    })
                                    .catch((e) => {
                                        // エラー
                                        AlertUtil.alert(`${I18n.t('errorNetworkTitle')}`, `${I18n.t('errorNetwork')}`);
                                        log.debug(e);
                                    });
                            }
                        );

                    }}
                >
                    <View style={{flexDirection: 'row'}}>
                        <Text style={{
                            fontFamily: appFont.family.default,
                            color: appColors.white,
                            fontWeight: 'bold',
                            fontSize: appS.button.text
                        }}>サンプルかごに入れる</Text>
                        <Image
                            source={require('../../resources/images/02.menu_general/arrow_W_right.png')}
                            style={[appS.buttonAllow]}
                        />
                    </View>
                </TouchableOpacity>

                {/*サンプル依頼とは*/}
                <View style={{flex: 1, flexDirection: 'row', marginTop: 8}}>
                    <View style={{flex: 1}}/>
                    <LinkComponent
                        title={'サンプル依頼とは？'}
                        color={appColors.linkBlue}
                        textDecorationLine={'underline'}
                        fontSize={14}
                        onPress={() => {
                            LinkUtil.openUrlInWebView('https://wp.recibase.net/introduction-sample/', this.props.navigation, 'サンプル依頼とは？');
                        }}/>
                </View>
            </View>
        );
    }

    /**
     * メーカー
     */
    _renderMaker = () => {

        if (this.state.product == null) {
            return null;
        }

        if (this.state.product?.maker == null) {
            return null;
        }

        // 未ログインで会員限定(PCサイズ)
        if (AppG.user == null && this.state.product.userPlanOnly == 1 && ScreenSizeUtil.isWebSize()) {
            return (
                <View>
                    <SpacerComponent height={32}/>
                    <Image
                        source={require('../../resources/images/product_userOnly_image_right_pc.png')}
                        style={[{
                            backgroundColor: appColors.white,
                            width: 365,
                            height: 280,
                        }]}
                        resizeMode={'cover'}
                    />
                </View>
            );
        }

        let _width = AppG.window.width;
        if (ScreenSizeUtil.isWebSize()) {
            _width = appS.webMainContentsSize.width;
        }
        _width = _width - appS.margins.side * 2;

        const {navigation} = this.props;

        return (
            <View>
                <TopicTitleComponent title={'提供メーカー'} subTopic={true} />
                <ATagLinkComponent
                    href={`/MakerDetailScreen/${this.state.product.maker.id}`}
                    onPress={() => {
                        // メーカー詳細画面へ遷移
                        let makerDetailScreenModel: MakerDetailScreenModel = new MakerDetailScreenModel();
                        makerDetailScreenModel.makerId = this.state.product!.maker!.id.toString();
                        if (Platform.OS == 'web') {
                            navigation.navigate('MakerDetailScreen', makerDetailScreenModel);
                        } else {
                            navigation.push('MakerDetailScreen', makerDetailScreenModel);
                        }
                    }}
                    view={
                        <View style={{flexDirection: "row", marginLeft: this._marginSize, marginRight: this._marginSize}}>
                            {!ValidateUtil.isEmptyExact(this.state.product?.maker.logo)
                                && isURL(this.state.product?.maker.logo) && (
                                    <Image
                                        source={{uri: this.state.product?.maker.logo}}
                                        style={[{
                                            marginTop: 0,
                                            borderRadius: 5,
                                            borderColor: appColors.transparent,
                                            width: 40,
                                            height: 40,
                                        }]}
                                        resizeMode={'cover'}
                                        accessibilityLabel={this.state.product?.maker.name}
                                    />
                                )}

                            <View
                                style={{
                                    flex: 1,
                                    marginLeft: 8,
                                    marginTop: 9,
                                }}
                            >
                                <LinkComponent
                                    title={this.state.product?.maker.name}
                                    color={appColors.linkBlue}
                                    textDecorationLine={'underline'}
                                    fontSize={appFont.size.recipeProductName}
                                    ellipsizeMode={"tail"}
                                    onPress={() => {
                                        let makerDetailScreenModel: MakerDetailScreenModel = new MakerDetailScreenModel();
                                        makerDetailScreenModel.makerId = this.state.product!.maker!.id.toString();
                                        if (Platform.OS == 'web') {
                                            navigation.navigate('MakerDetailScreen', makerDetailScreenModel);
                                        } else {
                                            navigation.push('MakerDetailScreen', makerDetailScreenModel);
                                        }
                                    }}/>
                            </View>
                        </View>
                    }
                />
            </View>
        );
    }

    /**
     * メーカーに質問ボタン
     */
    _renderInquiry = () => {
        if (this.state.product == null) {
            return null;
        }

        if (ValidateUtil.isEmptyExact(this.state.product.canInquiry) || this.state.product.canInquiry == 0) {
            return null;
        }

        return (
            <View style={{margin: appS.margins.side * 2, marginBottom: appS.line.space}}>
                {/*お問い合わせボタン*/}
                <TouchableOpacity
                    style={[
                        {
                            height: appS.button.height,
                            backgroundColor: appColors.recipeDownloadButton,
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginBottom: 10,
                            borderRadius: appS.buttonSize.radius
                        }
                    ]}
                    onPress={() => {
                        if (AppG.user == null) {
                            ValidateUtil.showNoUserAlertAtMakerChat(this.props.navigation);
                            return;
                        }

                        this._createInquiryChat();
                    }}
                >
                    <View style={{flexDirection: 'row'}}>
                        <Text style={{
                            fontFamily: appFont.family.default,
                            color: appColors.white,
                            fontWeight: 'bold',
                            fontSize: appS.button.text
                        }}>商品のお問い合わせ</Text>
                        <Image
                            source={require('../../resources/images/02.menu_general/arrow_W_right.png')}
                            style={[appS.buttonAllow]}
                        />
                    </View>
                </TouchableOpacity>
            </View>
        );
    }

    /**
     * 基本情報など
     */
    _renderBasicData = () => {
        if (this.state.product == null) {
            return null;
        }

        let _width = AppG.window.width;
        if (ScreenSizeUtil.isWebSize()) {
            _width = appS.webMainContentsSize.width;
        }
        _width = _width - appS.margins.side * 2;

        let seasonName = '';
        if (this.state.product.seasonId != null) {
            switch (this.state.product.seasonId) {
                case 1:
                    seasonName = '春';
                    break;
                case 2:
                    seasonName = '夏';
                    break;
                case 3:
                    seasonName = '秋';
                    break;
                case 4:
                    seasonName = '冬';
                    break;
                case 5:
                    seasonName = 'オールシーズン';
                    break;
            }
        }


        return (
            <View>
                <View>
                    <View style={{margin: appS.margins.side}}>
                        <BorderComponent width={_width} />
                    </View>
                    <TopicTitleComponent title={'基本情報'} />
                    <View style={{margin: appS.margins.side * 2, marginTop: 0, marginBottom: 0}}>
                        {/*地産品*/}
                        {!ValidateUtil.isEmptyExact(this.state.product.state) && (
                            <View style={{marginTop: Platform.OS == 'android'? -15 : 1, }}>
                                <Text  style={{fontFamily: appFont.family.default, color: appColors.fontGray, fontSize: appFont.size.productDescription}}>都道府県：{this.state.product.state}</Text>
                            </View>
                        )}
                        {!ValidateUtil.isEmptyExact(this.state.product.bestSeason) && (
                            <View style={{marginTop: Platform.OS == 'android'? -15 : 1, }}>
                                <Text style={{fontFamily: appFont.family.default, color: appColors.fontGray, fontSize: appFont.size.productDescription}}>時期：{this.state.product.bestSeason}</Text>
                            </View>
                        )}
                        {!ValidateUtil.isEmptyExact(this.state.product.seasonId) && (
                            <View style={{marginTop: Platform.OS == 'android'? -15 : 1,}}>
                                <Text style={{fontFamily: appFont.family.default, color: appColors.fontGray, fontSize: appFont.size.productDescription}}>旬の時期：{seasonName}</Text>
                            </View>
                        )}

                        {/*共通*/}
                        {!ValidateUtil.isEmptyExact(this.state.product.capacity) && (
                            <View style={{marginTop: Platform.OS == 'android'? -15 : 1, }}>
                                <Text style={{fontFamily: appFont.family.default, color: appColors.fontGray, fontSize: appFont.size.productDescription}}>内容量：{this.state.product.capacity}</Text>
                            </View>
                        )}
                        {!ValidateUtil.isEmptyExact(this.state.product.container) && (
                            <View style={{marginTop: Platform.OS == 'android'? -15 : 1, }}>
                                <Text  style={{fontFamily: appFont.family.default, color: appColors.fontGray, fontSize: appFont.size.productDescription}}>容器（包装形態）：{this.state.product.container}</Text>
                            </View>
                        )}
                        {!ValidateUtil.isEmptyExact(this.state.product.bestBeforeDate) && (
                            <View style={{marginTop: Platform.OS == 'android'? -15 : 1, }}>
                                <Text style={{fontFamily: appFont.family.default, color: appColors.fontGray, fontSize: appFont.size.productDescription}}>賞味期限：{this.state.product.bestBeforeDate}</Text>
                            </View>
                        )}
                        {!ValidateUtil.isEmptyExact(this.state.product.temperature) && (
                            <View style={{marginTop: Platform.OS == 'android'? -15 : 1, }}>
                                <Text style={{fontFamily: appFont.family.default, color: appColors.fontGray, fontSize: appFont.size.productDescription}}>温度帯：{this.state.product.temperature}</Text>
                            </View>
                        )}
                        {!ValidateUtil.isEmptyArray(this.state.product.standards) && (
                            <View style={{marginTop: Platform.OS == 'android'? -15 : 1, }}>
                                <Text style={{fontFamily: appFont.family.default, color: appColors.fontGray, fontSize: appFont.size.productDescription}}>規格一覧：{StrUtil.arrayToStr(this.state.product.standards)}</Text>
                            </View>
                        )}
                        {!ValidateUtil.isEmptyArray(this.state.product.allergies) && (
                            <View style={{marginTop: Platform.OS == 'android'? -15 : 1, }}>
                                <Text style={{fontFamily: appFont.family.default, color: appColors.fontGray, fontSize: appFont.size.productDescription}}>アレルギー情報：{(this.state.product.allergies.length === 1 && this.state.product.allergies[0] === '')? '無し' : StrUtil.arrayToStr(this.state.product.allergies)}</Text>
                            </View>
                        )}
                        {!ValidateUtil.isEmptyArray(this.state.product.nutrition) && (
                            <View style={{marginTop: Platform.OS == 'android'? -15 : 1, }}>
                                <Text style={{fontFamily: appFont.family.default, color: appColors.fontGray, fontSize: appFont.size.productDescription}}>栄養成分：{StrUtil.arrayToStr(this.state.product.nutrition)}</Text>
                            </View>
                        )}
                        {!ValidateUtil.isEmptyArray(this.state.product.placeOfOrigin) && (
                            <View style={{marginTop: Platform.OS == 'android'? -15 : 1, }}>
                                <Text style={{fontFamily: appFont.family.default, color: appColors.fontGray, fontSize: appFont.size.productDescription}}>原料原産地：{StrUtil.arrayToStr(this.state.product.placeOfOrigin)}</Text>
                            </View>
                        )}
                    </View>

                    {(!ValidateUtil.isEmptyExact(this.state.product.otherInformation) || !ValidateUtil.isEmptyArray(this.state.product.webSites)) && (
                        <View>
                            <SpacerComponent height={appS.margins.betweenTopic}/>
                            <TopicTitleComponent title={'その他情報'} subTopic={true}/>
                            <View style={{
                                // flexDirection: 'row',
                                margin: appS.margins.side * 2,
                                marginTop: 0,
                            }}>
                                {!ValidateUtil.isEmptyArray(this.state.product.webSites) && this.state.product.webSites?.map((webSite, index) => {
                                    if (ValidateUtil.isEmptyExact(webSite.url)
                                    || ValidateUtil.isEmptyExact(webSite.name)) {
                                        return null;
                                    }
                                    return (
                                        <View
                                            key={`webSites_${index}`}
                                            style={{flexDirection: 'row', marginTop: Platform.OS == 'android' ? -6 : 0,}}>
                                            <LinkComponent
                                                key={`webSites_link_${index}`}
                                                title={`${ValidateUtil.isEmptyExact(webSite.name)? webSite.url : webSite.name}`}
                                                color={appColors.linkBlue}
                                                textDecorationLine={'underline'}
                                                fontSize={appFont.size.productDescription}
                                                ellipsizeMode={"tail"}
                                                onPress={() => {
                                                    LinkUtil.openLinkWithDefaultBrowser(webSite.url!);
                                                }}/>
                                            <Text
                                                key={`webSites_text_${index}`}
                                                style={{marginTop: Platform.OS == 'android' ? -10 : 0, fontFamily: appFont.family.default, color: appColors.fontGray, fontSize: appFont.size.productDescription}}>　(外部サイト)</Text>
                                        </View>
                                    );
                                })}
                                {!ValidateUtil.isEmptyExact(this.state.product.otherInformation) && (
                                    <Hyperlink
                                        linkStyle={{fontFamily: appFont.family.bold, color: appColors.linkBlue, fontWeight: "bold"}}
                                        onPress={(url: string, text: string) => {
                                            LinkUtil.openLinkWithDefaultBrowser(url);
                                        }}
                                    >
                                        <Text
                                            style={{
                                                marginTop: Platform.OS == 'android' ? -12 : 0,
                                                fontFamily: appFont.family.default,
                                                fontSize: appFont.size.productDescription,
                                                color: appColors.fontGray,
                                            }}
                                        >{this.state.product.otherInformation}</Text>
                                    </Hyperlink>
                                )}
                            </View>
                        </View>
                    )}

                    <View style={{margin: appS.margins.side}}>
                        <BorderComponent width={_width} />
                    </View>
                </View>
            </View>
        );
    }

    /**
     * シェアボタン用のURL作成
     */
    _getShareUrl = (clipboard: boolean): string => {
        // @ts-ignore
        const {REACT_APP_DOMAIN_URL} = getEnvVars();
        const { routeName } = this.props.navigation.state;
        if (clipboard) {
            return `${REACT_APP_DOMAIN_URL}/${routeName}/${this.state.product?.id}`;
        } else {
            return encodeURI(`${REACT_APP_DOMAIN_URL}/${routeName}/${this.state.product?.id}`);
        }
    }

    /**
     * シェアボタン
     */
    _renderShareBtn = () => {
        if (Platform.OS != 'web') {
            return null;
        }

        return (
            <ShareComponent
                shareUrl={this._getShareUrl(false)}
                copyUrl={this._getShareUrl(true)}
                hashtags={'ReciBASE,レシベース,メニュー開発,レシピ,業務用,商品,飲食店,料理人'}
                image_url={encodeURIComponent(this.state.product?.images[0].url!)}
                description={this.state.product?.name!}
                screenName={'ProductDetailScreen'}
                recipe={null}
                product={this.state.product}
                maker={this.state.product?.maker != null ? this.state.product.maker : null}
            />
        )
    }

    /**
     * この商品を使った他のレシピ
     */
    _renderOtherRecipes = () => {
        if (this.state.product == null) {
            return null;
        }

        const {navigation} = this.props;

        return (
            <View>
                {/*この商品を使った他のレシピ*/}
                {!ValidateUtil.isEmptyArray(this.state.sameProductRecipes) && (
                    <View>
                        <View style={{flexDirection: "row"}}>
                            <TopicTitleComponent
                                subTopic={true}
                                title={'この商品を使ったレシピ'}
                                more={true}
                                onPress={() => {
                                    if (this.state.product != null && this.state.product.id != null) {
                                        let searchScreenModel: SearchScreenModel = new SearchScreenModel();
                                        searchScreenModel.screenNameFrom = ProductDetailScreen.name;
                                        searchScreenModel.headerTitle = this.state.product.name;
                                        searchScreenModel.searchType = 'recipe';
                                        searchScreenModel.tabHidden = true;
                                        searchScreenModel.productId = this.state.product.id.toString();
                                        if (Platform.OS == 'web') {
                                            navigation.navigate('SearchResultScreen', searchScreenModel);
                                        } else {
                                            navigation.push('SearchResultScreen', searchScreenModel);
                                        }
                                    }
                                }}
                            />
                        </View>
                        <View style={{margin: appS.margins.side / 2, marginTop: -appS.margins.side / 2}}>
                            <RecipeSameProductListHorizontalScrollComponent
                                navigation={this.props.navigation}
                                recipes={this.state.sameProductRecipes!}
                                border={false}
                                analyticsBtnName={'この商品を使った他のレシピ_ボタン'}
                                onPress={null}
                            />
                        </View>
                    </View>
                )}


                {/*TODO よく一緒に見られているレシピ*/}
                {/*<TopicTitleComponent title={'よく一緒に見られているレシピ'}/>*/}
            </View>
        );
    }

    _renderWide = () => {
        const {navigation} = this.props;

        let _width = AppG.window.width;
        if (ScreenSizeUtil.isWebSize()) {
            _width = appS.webMainContentsSize.width;
        }

        return (
            <SafeAreaView
                style={[{height: AppG.window.height, flex: 1, flexDirection: 'column', backgroundColor: appColors.white}]}
                onLayout={(event) => {}}
            >
                <CustomHeaderComponentWide
                    navigation={navigation}
                    searchBar={true}
                />

                <View style={{
                    flex: 1,
                    flexDirection: 'row',
                    marginTop: this._marginSize
                }}>
                    <ScrollView
                        horizontal  // 下階層のスクロールイベントを拾えるようにしている
                        style={{
                            width: AppG.window.width,
                            height: AppG.window.height - appS.header.webHeight,
                        }}
                    >
                        <View style={[{flex: 1, flexDirection: 'column', width: AppG.window.width}]}>
                            <KeyboardAwareScrollView style={[{flex: 1, flexDirection: 'column'}]}>
                                <View style={[{flex: 1, flexDirection: ScreenSizeUtil.isWebSize()? 'row': 'column', justifyContent: 'center'}]}>
                                    <View style={[{flexDirection: 'column'}]}>
                                        <View style={{width: ScreenSizeUtil.isWebSize()? appS.webMainContentsSize.width : AppG.window.width}}>
                                            {/*未ログイン*/}
                                            {AppG.user == null && this.state.product != null && this.state.product.userPlanOnly == 1 && this._renderNoLogin()}

                                            {!(AppG.user == null && this.state.product != null && this.state.product.userPlanOnly == 1) && (
                                                <View>
                                                    {ScreenSizeUtil.isWebSize() && <WideSubTitleComponent title={'商品詳細'} width={_width} shadow={false} type={'product'} />}

                                                    {/* 画像カルーセル */}
                                                    {this._renderProductImages()}
                                                    {/*商品名など*/}
                                                    {this._renderProductName()}
                                                    {/*基本情報など*/}
                                                    {this._renderBasicData()}
                                                    {/*他のレシピ*/}
                                                    {this._renderOtherRecipes()}
                                                    <SpacerComponent height={appS.footer.height} />
                                                </View>
                                            )}
                                        </View>
                                    </View>
                                    <View style={{marginLeft: appS.margins.webBetweenMargin, width: appS.webSideContentsSize.width}}>
                                        {/*メーカーと商品*/}
                                        {this._renderMaker()}
                                        {/*サンプル依頼ボタン*/}
                                        {this._renderCartArea()}
                                        {/*メーカーに質問ボタン*/}
                                        {this._renderInquiry()}
                                        {/*キーワード*/}
                                        {this._renderKeywords()}
                                        {ScreenSizeUtil.isWebSize() && (
                                            <View style={{}}>
                                                <SpacerComponent height={appS.margins.betweenTopic}/>
                                                <WideScreenAdComponent navigation={this.props.navigation}/>
                                                <WideScreenSNSComponent navigation={this.props.navigation}/>
                                            </View>
                                        )}
                                        {/*シェアボタン*/}
                                        {this._renderShareBtn()}
                                    </View>
                                </View>
                            </KeyboardAwareScrollView>
                        </View>
                    </ScrollView>
                </View>
                <ModalComponent
                    title={'必要な情報が未登録です'}
                    isShowModal={this.state.isShowErrorModal}
                    onButtonPress={() => {
                        this.setState({isShowErrorModal: false});
                        this.props.navigation.navigate('ProfileScreen');
                    }}
                    height={180}
                    buttonTitle={'プロフィールを編集'}
                    text={'プロフィール画面よりサンプル依頼に必要な情報を入力してください'}
                />
                {this._renderBigImages()}
                {/*ECサイトモーダル*/}
                <EcSiteModalComponent
                    ref={(ecSiteModalRef: any) => { this._ecSiteModalRef = ecSiteModalRef; }}
                    navigation={this.props.navigation}
                    ecs={this._ecs}
                    product={this.state.product}
                    maker={this.state.product?.maker != null ? this.state.product.maker : null}
                />
                {/*サンプル依頼とは？*/}
                <WhatIsSampleRequestModalComponent
                    ref={(whatIsSampleRequestModalRef: any) => { this._whatIsSampleRequestModalRef = whatIsSampleRequestModalRef; }}
                    navigation={this.props.navigation}
                />
                {/*送信したよModal*/}
                <ImageModalComponent
                    imageSource={require('../../resources/images/cart_added.png')}
                    marginTop={(AppG.window.height - ((AppG.window.height / 2) * 1.3)) / 2}
                    width={(AppG.window.height / 2) * 1.3}
                    height={(AppG.window.height / 2)}
                    isShowModal={this.state.isShowSentModal}
                    onClosePress={() => {
                        // 閉じて戻る
                        this.setState({'isShowSentModal': false});
                    }}/>
            </SafeAreaView>
        );
    }

    _renderSmall = () => {
        // @ts-ignore
        const {REACT_APP_DOMAIN_URL} = getEnvVars();
        const { routeName } = this.props.navigation.state;

        let _width = AppG.window.width;
        if (ScreenSizeUtil.isWebSize()) {
            _width = appS.webMainContentsSize.width;
        }
        _width = _width - appS.margins.side * 2;

        return (
            // appStyles.statusBarHiddenSafeAreaでステータスバー分上に上げる
            <SafeAreaView
                // style={[{flex: 1, backgroundColor: appColors.white}, appS.statusBarHiddenSafeArea]}
                style={[{height: AppG.window.height, flex: 1, flexDirection: 'column', backgroundColor: appColors.white}]}
                onLayout={(event) => {}}
            >
                <CustomHeaderComponentSmall
                    navigation={this.props.navigation}
                    leftButton={MyAppUtil.selectWebLogoSp()}
                    rightButton={MyAppUtil.selectWebMenuSp()}
                    title={'PRODUCT'}
                    statusBarHidden={false}
                    searchBar={true}
                    textColor={appColors.black}
                    barColor={appColors.white}
                    borderBottomColor={appColors.transparent}
                    statusBarType={"dark-content"}
                    onSearchSubmit={(text: string) => {
                        // 検索
                        if (!ValidateUtil.isEmptyExact(text)) {
                            // 検索履歴に追加
                            SearchDataDao.addNew(text);

                            let searchScreenModel: SearchScreenModel = new SearchScreenModel();
                            searchScreenModel.searchType = 'all';
                            searchScreenModel.searchValue = text;

                            if (Platform.OS == 'web') {
                                this.props.navigation.navigate('SearchResultScreen', searchScreenModel);
                            } else {
                                this.props.navigation.push('SearchResultScreen', searchScreenModel);
                            }
                        }
                    }}
                />
                <SpacerComponent height={2}/>
                <CustomHeaderComponentSmall
                    zIndex={50}
                    navigation={this.props.navigation}
                    title={'商品詳細'}
                    textColor={appColors.productHeader}
                    barColor={appColors.white}
                    borderBottomColor={appColors.productHeader}
                    statusBarHidden={false}
                    leftButton={'back'}
                    rightButton={'share'}
                    shareMessage={`${REACT_APP_DOMAIN_URL}/${routeName}/${this.state.product?.id}`}
                    shadow={false}
                    secondHeader={true}
                    shareAnalytics={() => {
                        AnalyticsUtil.sendAnalytics(
                            DOpenEventName.share,
                            AnalyticsEventName.share,  // アナリティクスのイベント名
                            'ProductDetailScreen',
                            null,
                            this.state.product,
                            this.state.product?.maker != null ? this.state.product.maker : null,
                            null,
                            null,
                            null,
                            null,
                            ShareBtnName.appShare,
                        );
                    }}
                />
                <KeyboardAwareScrollView style={[{flex: 1, flexDirection: 'column', backgroundColor: appColors.backBaseColor}]}>

                    {/*未ログイン*/}
                    {AppG.user == null && this.state.product != null && this.state.product.userPlanOnly == 1 && this._renderNoLogin()}

                    {!(AppG.user == null && this.state.product != null && this.state.product.userPlanOnly == 1) && (
                        <View>
                            {/* 画像カルーセル */}
                            {this._renderProductImages()}
                            {/*商品名など*/}
                            {this._renderProductName()}
                            {/*基本情報など*/}
                            {this._renderBasicData()}
                            {/*提供メーカー*/}
                            {this._renderMaker()}
                            {/*サンプル依頼ボタン*/}
                            {this._renderCartArea()}
                            {/*メーカーに質問ボタン*/}
                            {this._renderInquiry()}
                            {/*キーワード*/}
                            {this._renderKeywords()}
                            {/*シェアボタン*/}
                            {this._renderShareBtn()}
                            <View style={{margin: appS.margins.side}}>
                                <BorderComponent width={_width} />
                            </View>
                            {/*他のレシピ*/}
                            {this._renderOtherRecipes()}
                            {/*<SpacerComponent height={appStyles.footer.height} />*/}
                        </View>
                    )}
                </KeyboardAwareScrollView>
                <ModalComponent
                    title={'必要な情報が未登録です'}
                    isShowModal={this.state.isShowErrorModal}
                    onButtonPress={() => {
                        this.setState({isShowErrorModal: false});
                        this.props.navigation.navigate('ProfileScreen');
                    }}
                    height={250}
                    buttonTitle={'プロフィールを編集'}
                    text={'プロフィール画面よりサンプル依頼に必要な情報を入力してください'}
                />
                {this._renderBigImages()}
                {/*ECサイトモーダル*/}
                <EcSiteModalComponent
                    ref={(ecSiteModalRef: any) => { this._ecSiteModalRef = ecSiteModalRef; }}
                    navigation={this.props.navigation}
                    ecs={this._ecs}
                    product={this.state.product}
                    maker={this.state.product?.maker != null ? this.state.product.maker : null}
                />
                {/*サンプル依頼とは？*/}
                <WhatIsSampleRequestModalComponent
                    ref={(whatIsSampleRequestModalRef: any) => { this._whatIsSampleRequestModalRef = whatIsSampleRequestModalRef; }}
                    navigation={this.props.navigation}
                />
                {/*送信したよModal*/}
                <ImageModalComponent
                    imageSource={require('../../resources/images/cart_added.png')}
                    marginTop={(AppG.window.height - ((AppG.window.height / 2) * 1.3)) / 2}
                    width={(AppG.window.height / 2) * 1.3}
                    height={(AppG.window.height / 2)}
                    isShowModal={this.state.isShowSentModal}
                    onClosePress={() => {
                        // 閉じて戻る
                        this.setState({'isShowSentModal': false});
                    }}/>
            </SafeAreaView>
        );
    }

    /** 描画 */
    render() {
        const {navigation} = this.props;
        if (!this.state.isLoaded) {
            return (
                <View style={{flex: 1}}>
                    <IndicatorComponent
                        failTime={60}
                        isLoading={true}
                        reloadFunc={() => {
                            this.componentWillUnmount();
                            this.componentDidMount();
                        }}/>
                </View>
            );
        }

        LinkUtil.addReturnFalseToATag();
        if (ScreenSizeUtil.isWebSize()) {
            // WEBサイズ
            return this._renderWide();
        } else {
            // SPサイズ
            return this._renderSmall();
        }
    };


}
