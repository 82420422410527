import React, {Component} from 'react';
import {Image, Platform, Text, TouchableOpacity, View} from 'react-native';
import {appColors, appFont, appS} from "../../resources/styles/style";
import AppG from "../util/AppG";
import {SpacerComponent} from "./SpacerComponent";
import LoginUtil from "../util/LoginUtil";
// @ts-ignore
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scrollview';
// @ts-ignore
import isURL from 'validator/lib/isURL';
import Modal from "react-native-modal";
import ScreenSizeUtil from "../util/ScreenSizeUtil";
import {NewsApiFactory, UserUserAttributeEnum, UserUserPlanEnum} from "../data/network/swagger-gen";
import moment from "moment";
import LinkUtil from "../util/LinkUtil";
// @ts-ignore
import {getEnvVars} from '../../../environment';
import ExpoUtil from "../util/ExpoUtil";
import ValidateUtil from "../util/ValidateUtil";
import {TutorialDataEntityModel} from "../data/entityModels/TutorialDataEntityModel";
import TutorialDataDao from "../data/dao/local/TutorialDataDao";
import {TutorialModalComponent} from "./TutorialModalComponent";
import WhatIsMemberShipModalDataDao from "../data/dao/local/WhatIsMemberShipModalDataDao";
import {WhatIsSampleRequestModalComponent} from "./WhatIsSampleRequestModalComponent";
import WhatIsSampleRequestModalDataDao from "../data/dao/local/WhatIsSampleRequestModalDataDao";
import ReceivedNewMessageModalDataDao from "../data/dao/local/ReceivedNewMessageModalDataDao";
import {ProductReviewReminderModalComponent} from "./ProductReviewReminderModalComponent";
import ProductReviewReminderModalDataDao from "../data/dao/local/ProductReviewReminderModalDataDao";
import {PopupModalComponent} from "./PopupModalComponent";

interface Props {
    navigation: any,
    isShowMenu: boolean,
    closeMenu: () => void,
}

interface State {
    newNewsCount: number,
    /**
     * チャットバッヂ 0 で非表示
     */
    badgeChat: number | null,

    /**
     * カートのサンプル依頼履歴のバッヂ 0 で非表示
     */
    badgeCart: number | null,
}

export class MenuComponent extends Component<Props, State> {

    // 1秒ごとにバッヂの更新を行う
    _updateBadgeTimer = setInterval(() => {
        const { routeName } = this.props.navigation.state;
        if( routeName == 'HomeScreen'){
            if(this.state.badgeChat != AppG.badgeChat){
                this.setState({'badgeChat': AppG.badgeChat});
            }
            if(this.state.badgeCart != AppG.badgeHistory){
                this.setState({'badgeCart': AppG.badgeHistory});
            }
            if(LoginUtil.getRefreshedScreenFlg() && Platform.OS != 'web'){
                this._loadContents();
                LoginUtil.setRefreshedScreenFlg(false);
            }
        }
    },1000);

    _marginSize = appS.margin.size;

    _campaign = true;

    // メニュー
    _menuCellHeight = 50;

    // チュートリアルRef
    _tutorialModalRef: any = null;
    // 無料会員登録でできることとは？Ref
    _whatIsMemberShipModalRef: any = null;
    // 管理画面から入力ポップアップ
    _popupModalRef: any = null;
    // レビュー催促
    _productReviewReminderModalRef: any = null;

    constructor(props: any) {
        super(props);
        this.state = {
            newNewsCount: 0,
            badgeChat: 0,
            badgeCart: 0,
        };
    }

    componentDidMount() {
        const _campaignDate = moment('9999-05-31 23:59:59');
        const _now = moment();

        this._campaign = _now.isBefore(_campaignDate);
        this._loadContents();
    }

    componentWillUnmount () {
        clearTimeout(this._updateBadgeTimer);
    };

    _loadContents = () => {
        if (AppG.user != null) {
            NewsApiFactory(AppG.getConfiguration(), AppG.getBasePath())
                .getAmountOfUnreadNews('all')
                .then(res => {
                    if (res != null && res.data != null && res.data.unread != null) {
                        this.setState({newNewsCount: res.data.unread});
                    }
                })
        }
    }

    /**
     * モーダルメニューの中
     */
    _renderMenu = () => {

        // @ts-ignore
        const {REACT_APP_FAQ_URL, REACT_APP_FAQ_IOS_URL, REACT_APP_PRIVACY_URL, REACT_APP_TERMS_OF_SERVICE_URL} = getEnvVars();
        const _fontSizeBlack = 16;

        const _borderWidth = ScreenSizeUtil.isWebSize()? AppG.window.width * 2 / 5 - this._marginSize * 2 : AppG.window.width * 4 / 5 - this._marginSize * 2;

        let profileImageSource = require('../../resources/images/no_image.png');
        if (AppG.user != null && AppG.user.image != null && AppG.user.image != '' && isURL(AppG.user.image)) {
            profileImageSource = {uri: AppG.user.image};
        }

        return (
            <View style={{
                zIndex: 100,
                width: ScreenSizeUtil.isWebSize()? AppG.window.width * 2 / 5 : AppG.window.width * 4 / 5,
                height: AppG.window.height,
                backgroundColor: appColors.white}}>
                <KeyboardAwareScrollView>
                    <View style={{flex:1, height: AppG.window.height + 60, marginLeft: appS.margins.side}}>
                        <View style={{flexDirection: "row", marginTop: 40, marginLeft: this._marginSize, marginRight: this._marginSize}}>
                            <View style={{flex: 1}}/>
                            <TouchableOpacity
                                onPress={() => {
                                    // メニューを閉じる
                                    this.props.closeMenu();
                                }}
                            >
                                <Image source={require('../../resources/images/close_icon.png')}
                                       style={[{
                                           backgroundColor: 'white',
                                           width: 24,
                                           height: 24,
                                       }]}
                                       resizeMode={'cover'}
                                />
                            </TouchableOpacity>
                        </View>

                        {/*ユーザー画像*/}
                        {AppG.user != null && (
                            <View
                                style={{flexDirection: 'row', margin: appS.margins.side}}
                            >
                                <Image source={profileImageSource}
                                       style={[{
                                           backgroundColor: 'white',
                                           width: 55,
                                           height: 55,
                                           borderWidth: 1,
                                           borderRadius: 50,
                                           borderColor: appColors.transparent,
                                           marginBottom: 8,
                                       }]}
                                       resizeMode={'cover'}
                                />
                                <View style={{flex: 1, margin: appS.margins.side}}>
                                    <Text style={{
                                        marginTop: 0,
                                        fontFamily: appFont.family.bold,
                                        color: appColors.fontBlack,
                                        fontSize: appFont.size.menuProfileName,
                                        fontWeight: "bold"
                                    }}>{AppG.user.name}</Text>
                                    {!ValidateUtil.isEmptyExact(AppG.user.nameRestaurant) && (
                                        <Text style={{
                                            marginTop: 0,
                                            fontFamily: appFont.family.bold,
                                            color: appColors.fontGray,
                                            fontSize: appFont.size.menuRestaurant,
                                            fontWeight: "bold"
                                        }}>{AppG.user.nameRestaurant}</Text>
                                    )}
                                    {/*所持コイン*/}
                                    {!ValidateUtil.isEmptyExact(AppG.user.userFreeCoin) && (
                                        <View style={{flexDirection: "row", marginTop: appS.margins.side}}>
                                            <Text
                                                style={{
                                                    fontFamily: appFont.family.bold,
                                                    fontSize: appFont.size.coin,
                                                    color: appColors.coin,
                                                    fontWeight: "bold"
                                                }}>
                                                {`所持コイン`}
                                            </Text>
                                            <Image
                                                source={require('../../resources/images/02.menu_general/i_coin.png')}
                                                style={{
                                                    margin: 2,
                                                    width: appS.coin.iconSize,
                                                    height: appS.coin.iconSize,
                                                }}
                                            />
                                            <Text
                                                style={{
                                                    fontFamily: appFont.family.bold,
                                                    fontSize: appFont.size.coin,
                                                    color: appColors.coin,
                                                    fontWeight: "bold"
                                                }}>
                                                {`${AppG.user?.userFreeCoin}`}
                                            </Text>
                                        </View>
                                    )}
                                </View>
                            </View>
                        )}

                        {AppG.user == null && (
                            <View style={{marginTop: 16, marginBottom: 16}}>
                                <Text style={{fontFamily: appFont.family.bold, color: appColors.fontBlack, fontSize: _fontSizeBlack, alignSelf: 'center', fontWeight: "bold"}}>すでに会員の方はこちら</Text>
                                <SpacerComponent height={appS.margins.side} />
                                {/*ログインボタン*/}
                                <TouchableOpacity
                                    style={[
                                        appS.marginCenter,
                                        {
                                            height: appS.buttonSize.height,
                                            backgroundColor: appColors.buttonOrange,
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }
                                    ]}
                                    onPress={() => {
                                        const { routeName } = this.props.navigation.state;
                                        if (routeName != 'LoginScreen') {
                                            this.props.closeMenu();
                                            this.props.navigation.navigate('LoginScreen');
                                        } else {
                                            this.props.closeMenu();
                                        }
                                    }}
                                >
                                    <Text style={{fontFamily: appFont.family.bold, color: appColors.white, fontSize: appS.button.text, fontWeight: "bold"}}>ログイン</Text>
                                </TouchableOpacity>


                                <SpacerComponent height={appS.margins.betweenTopic}/>

                                <SpacerComponent height={appS.margins.side} />
                                {/*無料会員登録ボタン*/}
                                <TouchableOpacity
                                    style={[
                                        appS.marginCenter,
                                        {
                                            height: appS.buttonSize.height,
                                            backgroundColor: appColors.buttonOrange,
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }
                                    ]}
                                    onPress={() => {
                                        // 無料会員登録画面へ
                                        const { routeName } = this.props.navigation.state;
                                        if (routeName != 'SignUpScreen') {
                                            this.props.closeMenu();
                                            this.props.navigation.navigate('SignUpScreen');
                                        } else {
                                            this.props.closeMenu();
                                        }

                                    }}
                                >
                                    <Text style={{fontFamily: appFont.family.bold, color: appColors.white, fontSize: appS.button.text, fontWeight: "bold"}}>無料会員登録</Text>
                                </TouchableOpacity>

                                <SpacerComponent height={appS.margins.side} />

                                <View style={{flex: 1, height: this._menuCellHeight, marginLeft: this._marginSize, marginRight: this._marginSize}}>
                                    {/*FAQ*/}
                                    <View style={{height: this._menuCellHeight}}>
                                        <View style={{backgroundColor: appColors.spacerGray, height: 1, width: _borderWidth}}/>
                                        <TouchableOpacity
                                            style={{
                                                height: this._menuCellHeight,
                                                flexDirection: "row",
                                                marginBottom: 0,
                                            }}
                                            onPress={() => {
                                                this.props.closeMenu();
                                                LinkUtil.openUrlInWebView(Platform.OS == 'ios'? REACT_APP_FAQ_IOS_URL : REACT_APP_FAQ_URL, this.props.navigation, 'FAQ');
                                            }}
                                        >
                                            <View style={{flexDirection: "row", height: this._menuCellHeight}}>
                                                <Text style={{
                                                    marginTop: 15,
                                                    color: appColors.fontGray,
                                                    fontFamily: appFont.family.bold,
                                                    fontSize: appFont.size.menu,
                                                    fontWeight: "bold"
                                                }}>FAQ</Text>
                                            </View>
                                        </TouchableOpacity>
                                        <View style={{backgroundColor: appColors.spacerGray, height: 1, width: _borderWidth}}/>
                                    </View>

                                    {/*利用規約*/}
                                    <View style={{height: this._menuCellHeight}}>
                                        <TouchableOpacity
                                            style={{
                                                height: this._menuCellHeight,
                                                flexDirection: "row",
                                                marginBottom: 0,
                                            }}
                                            onPress={() => {
                                                this.props.closeMenu();
                                                LinkUtil.openUrlInWebView(REACT_APP_TERMS_OF_SERVICE_URL, this.props.navigation, '利用規約');
                                            }}
                                        >
                                            <View style={{flexDirection: "row", height: this._menuCellHeight}}>
                                                <Text style={{
                                                    marginTop: 15,
                                                    color: appColors.fontGray,
                                                    fontFamily: appFont.family.bold,
                                                    fontWeight: "bold",
                                                    fontSize: appFont.size.menu}}>利用規約</Text>
                                            </View>
                                        </TouchableOpacity>
                                        <View style={{backgroundColor: appColors.spacerGray, height: 1, width: _borderWidth}}/>
                                    </View>

                                    {/*プライバシーポリシー*/}
                                    <View style={{height: this._menuCellHeight}}>
                                        <TouchableOpacity
                                            style={{
                                                height: this._menuCellHeight,
                                                flexDirection: "row",
                                                marginBottom: 0,
                                            }}
                                            onPress={() => {
                                                this.props.closeMenu();
                                                LinkUtil.openUrlInWebView(REACT_APP_PRIVACY_URL, this.props.navigation, 'プライバシーポリシー');
                                            }}
                                        >
                                            <View style={{flexDirection: "row", height: this._menuCellHeight}}>
                                                <Text style={{
                                                    marginTop: 15,
                                                    color: appColors.fontGray,
                                                    fontFamily: appFont.family.bold,
                                                    fontWeight: "bold",
                                                    fontSize: appFont.size.menu
                                                }}>プライバシーポリシー</Text>
                                            </View>
                                        </TouchableOpacity>
                                        <View style={{backgroundColor: appColors.spacerGray, height: 1, width: _borderWidth}}/>
                                    </View>

                                    {/*Reci BASEとは？*/}
                                    <View style={{height: this._menuCellHeight}}>
                                        <TouchableOpacity
                                            style={{
                                                height: this._menuCellHeight,
                                                flexDirection: "row",
                                                marginBottom: 0,
                                            }}
                                            onPress={() => {
                                                this.props.closeMenu();
                                                LinkUtil.openUrlInWebView('https://wp.recibase.net/introduction-recibase/', this.props.navigation, 'Reci BASEとは？');
                                            }}
                                        >
                                            <View style={{flexDirection: "row", height: this._menuCellHeight}}>
                                                <Text style={{
                                                    marginTop: 15,
                                                    color: appColors.fontGray,
                                                    fontFamily: appFont.family.bold,
                                                    fontWeight: "bold",
                                                    fontSize: appFont.size.menu
                                                }}>Reci BASEとは？</Text>
                                            </View>
                                        </TouchableOpacity>
                                        <View style={{backgroundColor: appColors.spacerGray, height: 1, width: _borderWidth}}/>
                                    </View>

                                    {/*運営会社*/}
                                    <View style={{height: this._menuCellHeight}}>
                                        <TouchableOpacity
                                            style={{
                                                height: this._menuCellHeight,
                                                flexDirection: "row",
                                                marginBottom: 0,
                                            }}
                                            onPress={() => {
                                                LinkUtil.openUrlInWebView('https://rbecho.jp/about/', this.props.navigation, '運営会社');
                                            }}
                                        >
                                            <View style={{flexDirection: "row", height: this._menuCellHeight}}>
                                                <Text style={{
                                                    marginTop: Platform.OS == 'android'? 17 : 15,
                                                    color: appColors.fontGray,
                                                    fontFamily: appFont.family.bold,
                                                    fontSize: appFont.size.menu,
                                                    fontWeight: "bold"
                                                }}>運営会社</Text>
                                            </View>
                                        </TouchableOpacity>
                                        <View style={{backgroundColor: appColors.spacerGray, height: 1, width: _borderWidth}}/>
                                    </View>

                                    {!ExpoUtil.isProduction() && (
                                        <View style={{height: this._menuCellHeight}}>
                                            <TouchableOpacity
                                                style={{
                                                    height: this._menuCellHeight,
                                                    flexDirection: "row",
                                                    marginBottom: 0,
                                                }}
                                                onPress={() => {
                                                    this.props.closeMenu();
                                                    TutorialDataDao.delete();
                                                    WhatIsMemberShipModalDataDao.delete();
                                                    WhatIsSampleRequestModalDataDao.delete();
                                                    ReceivedNewMessageModalDataDao.delete();
                                                    ProductReviewReminderModalDataDao.delete();
                                                }}
                                            >
                                                <View style={{flexDirection: "row", height: this._menuCellHeight}}>
                                                    <Text style={{
                                                        marginTop: 15,
                                                        color: appColors.fontGray,
                                                        fontFamily: appFont.family.bold,
                                                        fontWeight: "bold",
                                                        fontSize: appFont.size.menu
                                                    }}>Popupリセット</Text>
                                                </View>
                                            </TouchableOpacity>
                                        </View>
                                    )}


                                </View>

                            </View>
                        )}


                        {/*メニュー項目*/}
                        {AppG.user != null && (
                            <View style={{flex: 1, height: this._menuCellHeight, marginLeft: this._marginSize, marginRight: this._marginSize}}>
                                {Platform.OS == 'web' && (
                                    <View>
                                        <View style={{backgroundColor: appColors.spacerGray, height: 1, width: _borderWidth}}/>
                                        {/*お気にいり*/}
                                        <View style={{height: this._menuCellHeight}}>
                                            <TouchableOpacity
                                                style={{
                                                    height: this._menuCellHeight,
                                                    flexDirection: "row",
                                                    marginBottom: 0,
                                                }}
                                                onPress={() => {
                                                    const { routeName } = this.props.navigation.state;
                                                    if (routeName != 'FollowScreen') {
                                                        this.props.closeMenu();
                                                        this.props.navigation.navigate('FollowScreen');
                                                    } else {
                                                        this.props.closeMenu();
                                                    }
                                                }}
                                            >
                                                <View style={{flexDirection: "row", height: this._menuCellHeight}}>
                                                    <Image source={require('../../resources/images/footer_follow_black.png')}
                                                           style={[{
                                                               marginTop: 17,
                                                               marginRight: 8,
                                                               backgroundColor: 'white',
                                                               width: appS.menu.iconSize,
                                                               height: appS.menu.iconSize,
                                                           }]}
                                                           resizeMode={'cover'}
                                                    />
                                                    <Text style={{
                                                        marginTop: 15,
                                                        color: appColors.fontGray,
                                                        fontFamily: appFont.family.bold,
                                                        fontWeight: "bold",
                                                        fontSize: appFont.size.menu
                                                    }}>お気に入り</Text>
                                                </View>
                                            </TouchableOpacity>
                                            <View style={{backgroundColor: appColors.spacerGray, height: 1, width: _borderWidth}}/>
                                        </View>

                                        {/*メッセージ*/}
                                        {AppG.user != null && AppG.user.userAttribute == UserUserAttributeEnum.Cook && (
                                            <View style={{height: this._menuCellHeight}}>
                                                <TouchableOpacity
                                                    style={{
                                                        height: this._menuCellHeight,
                                                        flexDirection: "row",
                                                        marginBottom: 0,
                                                    }}
                                                    onPress={() => {
                                                        // 画面遷移前にリロード対象にセットする
                                                        ExpoUtil.setShouldReloadAppTab('self');
                                                        const { routeName } = this.props.navigation.state;
                                                        if (routeName != 'ChatsScreen') {
                                                            this.props.closeMenu();
                                                            this.props.navigation.navigate('ChatsScreen');
                                                        } else {
                                                            this.props.closeMenu();
                                                        }
                                                    }}
                                                >
                                                    <View style={{flexDirection: "row", height: this._menuCellHeight}}>
                                                        <Image source={require('../../resources/images/footer_chat_black.png')}
                                                               style={[{
                                                                   marginTop: 17,
                                                                   marginRight: 8,
                                                                   backgroundColor: 'white',
                                                                   width: appS.menu.iconSize,
                                                                   height: appS.menu.iconSize,
                                                               }]}
                                                               resizeMode={'cover'}
                                                        />
                                                        <Text style={{
                                                            marginTop: 15,
                                                            color: appColors.fontGray,
                                                            fontFamily: appFont.family.bold,
                                                            fontWeight: "bold",
                                                            fontSize: appFont.size.menu
                                                        }}>メッセージ</Text>
                                                        {AppG.user != null && this.state.badgeChat != null && this.state.badgeChat != 0 && (
                                                            <View style={{marginTop: Platform.OS == 'web'? 18:14, marginLeft: 2, backgroundColor: appColors.red, width: 8, height: 8, borderRadius: 50, borderWidth: 1, borderColor: appColors.red, alignItems: 'center', justifyContent: 'center',}}>
                                                                <Text style={{fontFamily: appFont.family.bold, color: appColors.white, fontSize: 10, fontWeight: "bold"}}>{' '}</Text>
                                                            </View>
                                                        )}
                                                    </View>
                                                </TouchableOpacity>
                                                <View style={{backgroundColor: appColors.spacerGray, height: 1, width: _borderWidth}}/>
                                            </View>
                                        )}



                                        {/*サンプルかご*/}
                                        {AppG.user != null && AppG.user.userAttribute == UserUserAttributeEnum.Cook && (
                                            <View style={{height: this._menuCellHeight}}>
                                                <TouchableOpacity
                                                    style={{
                                                        height: this._menuCellHeight,
                                                        flexDirection: "row",
                                                        marginBottom: 0,
                                                    }}
                                                    onPress={() => {
                                                        const { routeName } = this.props.navigation.state;
                                                        if (routeName != 'CartScreen') {
                                                            this.props.closeMenu();
                                                            this.props.navigation.navigate('CartScreen');
                                                        } else {
                                                            this.props.closeMenu();
                                                        }
                                                    }}
                                                >
                                                    <View style={{flexDirection: "row", height: this._menuCellHeight}}>
                                                        <Image source={require('../../resources/images/footer_cart_black.png')}
                                                               style={[{
                                                                   marginTop: 17,
                                                                   marginRight: 8,
                                                                   backgroundColor: 'white',
                                                                   width: appS.menu.iconSize,
                                                                   height: appS.menu.iconSize,
                                                               }]}
                                                               resizeMode={'cover'}
                                                        />
                                                        <Text style={{
                                                            // marginTop: Platform.OS == 'android'? 17 : 15,
                                                            marginTop: 15,
                                                            color: appColors.fontGray,
                                                            fontFamily: appFont.family.bold,
                                                            fontSize: appFont.size.menu,
                                                            fontWeight: "bold"
                                                        }}>サンプルかご</Text>
                                                    </View>
                                                </TouchableOpacity>
                                                <View style={{backgroundColor: appColors.spacerGray, height: 1, width: _borderWidth}}/>
                                            </View>
                                        )}

                                        {/*サンプル依頼履歴*/}
                                        {AppG.user != null && AppG.user.userAttribute == UserUserAttributeEnum.Cook && (
                                            <View style={{height: this._menuCellHeight}}>
                                                <TouchableOpacity
                                                    style={{
                                                        height: this._menuCellHeight,
                                                        flexDirection: "row",
                                                        marginBottom: 0,
                                                    }}
                                                    onPress={() => {
                                                        const { routeName } = this.props.navigation.state;
                                                        if (routeName != 'SampleRequestHistoryScreen') {
                                                            this.props.closeMenu();
                                                            this.props.navigation.navigate('SampleRequestHistoryScreen');
                                                        } else {
                                                            this.props.closeMenu();
                                                        }
                                                    }}
                                                >
                                                    <View style={{flexDirection: "row", height: this._menuCellHeight}}>
                                                        <Image source={require('../../resources/images/02.menu_general/i_menu_list.png')}
                                                               style={[{
                                                                   marginTop: 17,
                                                                   marginRight: 8,
                                                                   backgroundColor: 'white',
                                                                   width: appS.menu.iconSize,
                                                                   height: appS.menu.iconSize,
                                                               }]}
                                                               resizeMode={'cover'}
                                                        />
                                                        <Text style={{
                                                            // marginTop: Platform.OS == 'android'? 17 : 15,
                                                            marginTop: 15,
                                                            color: appColors.fontGray,
                                                            fontFamily: appFont.family.bold,
                                                            fontSize: appFont.size.menu,
                                                            fontWeight: "bold"
                                                        }}>サンプル依頼履歴</Text>
                                                        {AppG.user != null && this.state.badgeCart != null && this.state.badgeCart != 0 && (
                                                            <View style={{marginTop: Platform.OS == 'web'? 18:14, marginLeft: 0, backgroundColor: appColors.red, width: 8, height: 8, borderRadius: 50, borderWidth: 1, borderColor: appColors.red, alignItems: 'center', justifyContent: 'center',}}>
                                                                <Text style={{fontFamily: appFont.family.bold, color: appColors.white, fontSize: 10, fontWeight: "bold"}}>{' '}</Text>
                                                            </View>
                                                        )}
                                                    </View>
                                                </TouchableOpacity>
                                                <View style={{backgroundColor: appColors.spacerGray, height: 1, width: _borderWidth}}/>
                                            </View>
                                        )}
                                    </View>
                                )}
                                {/*運営からのお知らせ*/}
                                <View style={{height: this._menuCellHeight}}>
                                    <TouchableOpacity
                                        style={{
                                            height: this._menuCellHeight,
                                            flexDirection: "row",
                                            marginBottom: 0,
                                        }}
                                        onPress={() => {
                                            const { routeName } = this.props.navigation.state;
                                            if (routeName != 'NewsScreen') {
                                                this.props.closeMenu();
                                                this.props.navigation.navigate('NewsScreen');
                                            } else {
                                                this.props.closeMenu();
                                            }
                                        }}
                                    >
                                        <View style={{flexDirection: "row", height: this._menuCellHeight}}>
                                            <Image source={require('../../resources/images/news_icon_black.png')}
                                                   style={[{
                                                       marginTop: 17,
                                                       marginRight: 8,
                                                       backgroundColor: 'white',
                                                       width: appS.menu.iconSize,
                                                       height: appS.menu.iconSize,
                                                   }]}
                                                   resizeMode={'cover'}
                                            />
                                            <Text style={{
                                                marginTop: Platform.OS == 'android'? 17 : 15,
                                                color: appColors.fontGray,
                                                fontFamily: appFont.family.bold,
                                                fontSize: appFont.size.menu,
                                                fontWeight: "bold"
                                            }}>運営からのお知らせ</Text>
                                            {AppG.user != null && this.state.newNewsCount != null && this.state.newNewsCount != 0 && (
                                                <View style={{marginTop: 18, marginLeft: 8, backgroundColor: appColors.red, width: 35, height: 15, borderRadius: 50, borderWidth: 1, borderColor: appColors.red, alignItems: 'center', justifyContent: 'center',}}>
                                                    <Text style={{
                                                        marginTop: -1,
                                                        fontFamily: appFont.family.bold,
                                                        color: appColors.white,
                                                        fontSize: 10,
                                                        fontWeight: "bold"
                                                    }}>NEW</Text>
                                                </View>
                                            )}
                                        </View>
                                    </TouchableOpacity>
                                    <View style={{backgroundColor: appColors.spacerGray, height: 1, width: _borderWidth}}/>
                                </View>

                                {/*紹介コード*/}
                                {/*{AppG.user != null && AppG.user.userAttribute == UserUserAttributeEnum.Cook && (*/}
                                {/*    <View style={{height: this._menuCellHeight}}>*/}
                                {/*        <TouchableOpacity*/}
                                {/*            style={{*/}
                                {/*                height: this._menuCellHeight,*/}
                                {/*                flexDirection: "row",*/}
                                {/*                marginBottom: 0,*/}
                                {/*            }}*/}
                                {/*            onPress={() => {*/}
                                {/*                const { routeName } = this.props.navigation.state;*/}
                                {/*                if (routeName != 'ReferralCodeScreen') {*/}
                                {/*                    this.props.closeMenu();*/}
                                {/*                    this.props.navigation.navigate('ReferralCodeScreen');*/}
                                {/*                } else {*/}
                                {/*                    this.props.closeMenu();*/}
                                {/*                }*/}
                                {/*            }}*/}
                                {/*        >*/}
                                {/*            <View style={{flexDirection: "row", height: this._menuCellHeight}}>*/}
                                {/*                <Image source={require('../../resources/images/icon_referral_code.png')}*/}
                                {/*                       style={[{*/}
                                {/*                           marginTop: 17,*/}
                                {/*                           marginRight: 8,*/}
                                {/*                           backgroundColor: 'white',*/}
                                {/*                           width: appS.menu.iconSize,*/}
                                {/*                           height: appS.menu.iconSize,*/}
                                {/*                       }]}*/}
                                {/*                       resizeMode={'cover'}*/}
                                {/*                />*/}
                                {/*                <Text style={{*/}
                                {/*                    marginTop: Platform.OS == 'android'? 17 : 15,*/}
                                {/*                    color: appColors.fontGray,*/}
                                {/*                    fontFamily: appFont.family.bold,*/}
                                {/*                    fontSize: appFont.size.menu}}>紹介コード</Text>*/}
                                {/*            </View>*/}
                                {/*        </TouchableOpacity>*/}
                                {/*        <View style={{backgroundColor: appColors.spacerGray, height: 1, width: _borderWidth}}/>*/}
                                {/*    </View>*/}
                                {/*)}*/}

                                {/*Reci BASEとは？*/}
                                <View style={{height: this._menuCellHeight}}>
                                    <TouchableOpacity
                                        style={{
                                            height: this._menuCellHeight,
                                            flexDirection: "row",
                                            marginBottom: 0,
                                        }}
                                        onPress={() => {
                                            this.props.closeMenu();
                                            LinkUtil.openUrlInWebView('https://wp.recibase.net/introduction-recibase/', this.props.navigation, 'Reci BASEとは？');
                                        }}
                                    >
                                        <View style={{flexDirection: "row", height: this._menuCellHeight}}>
                                            <Image source={require('../../resources/images/02.menu_general/i_menu_tutorial.png')}
                                                   style={[{
                                                       marginTop: 17,
                                                       marginRight: 8,
                                                       backgroundColor: 'white',
                                                       width: appS.menu.iconSize,
                                                       height: appS.menu.iconSize,
                                                   }]}
                                                   resizeMode={'cover'}
                                            />
                                            <Text style={{
                                                marginTop: Platform.OS == 'android'? 17 : 15,
                                                color: appColors.fontGray,
                                                fontFamily: appFont.family.bold,
                                                fontSize: appFont.size.menu,
                                                fontWeight: "bold"
                                            }}>Reci BASEとは？</Text>
                                        </View>
                                    </TouchableOpacity>
                                    <View style={{backgroundColor: appColors.spacerGray, height: 1, width: _borderWidth}}/>
                                </View>

                                {/*設定・その他*/}
                                <View style={{height: this._menuCellHeight}}>
                                    <TouchableOpacity
                                        style={{
                                            height: this._menuCellHeight,
                                            flexDirection: "row",
                                            marginBottom: 0,
                                        }}
                                        onPress={() => {
                                            const { routeName } = this.props.navigation.state;
                                            if (routeName != 'SettingMenuScreen') {
                                                this.props.closeMenu();
                                                this.props.navigation.navigate('SettingMenuScreen');
                                            } else {
                                                this.props.closeMenu();
                                            }
                                        }}
                                    >
                                        <View style={{flexDirection: "row", height: this._menuCellHeight}}>
                                            <Image source={require('../../resources/images/setting_icon_black.png')}
                                                   style={[{
                                                       marginTop: 17,
                                                       marginRight: 8,
                                                       backgroundColor: 'white',
                                                       width: appS.menu.iconSize,
                                                       height: appS.menu.iconSize,
                                                   }]}
                                                   resizeMode={'cover'}
                                            />
                                            <Text style={{
                                                marginTop: Platform.OS == 'android'? 17 : 15,
                                                color: appColors.fontGray,
                                                fontFamily: appFont.family.bold,
                                                fontSize: appFont.size.menu,
                                                fontWeight: "bold"
                                            }}>設定・その他</Text>
                                        </View>
                                    </TouchableOpacity>
                                    <View style={{backgroundColor: appColors.spacerGray, height: 1, width: _borderWidth}}/>
                                </View>

                                {/*運営会社*/}
                                <View style={{height: this._menuCellHeight}}>
                                    <TouchableOpacity
                                        style={{
                                            height: this._menuCellHeight,
                                            flexDirection: "row",
                                            marginBottom: 0,
                                        }}
                                        onPress={() => {
                                            LinkUtil.openUrlInWebView('https://rbecho.jp/about/', this.props.navigation, '運営会社');
                                        }}
                                    >
                                        <View style={{flexDirection: "row", height: this._menuCellHeight}}>
                                            <Image source={require('../../resources/images/company_icon_black.png')}
                                                   style={[{
                                                       marginTop: 17,
                                                       marginRight: 8,
                                                       backgroundColor: 'white',
                                                       width: appS.menu.iconSize,
                                                       height: appS.menu.iconSize,
                                                   }]}
                                                   resizeMode={'cover'}
                                            />
                                            <Text style={{
                                                marginTop: Platform.OS == 'android'? 17 : 15,
                                                color: appColors.fontGray,
                                                fontFamily: appFont.family.bold,
                                                fontSize: appFont.size.menu,
                                                fontWeight: "bold"
                                            }}>運営会社</Text>
                                        </View>
                                    </TouchableOpacity>
                                    <View style={{backgroundColor: appColors.spacerGray, height: 1, width: _borderWidth}}/>
                                </View>

                                {/*会員ステータス*/}
                                {AppG.user != null && !ExpoUtil.isProduction() && (
                                    <View style={{marginTop: 8, marginBottom: 8, backgroundColor: appColors.gray, borderWidth: 1, borderRadius: 10, borderColor: appColors.transparent, height: 100, marginLeft: this._marginSize, marginRight: this._marginSize}}>
                                        <View style={{marginLeft: 12, marginRight: 12, marginTop: 8, marginBottom: 16}}>
                                            <View style={{flexDirection: "row", marginTop: 10}}>
                                                <Text style={{color: appColors.fontBlack, fontFamily: appFont.family.default, fontSize: appFont.size.default, fontWeight: "bold"}}>会員ステータス</Text>
                                                {!ExpoUtil.isProduction() && (
                                                    <TouchableOpacity
                                                        style={{
                                                            flex: 1,
                                                            height: 40,
                                                            flexDirection: "row",
                                                            marginBottom: 0,
                                                            marginRight: 0}}
                                                        onPress={() => {
                                                            // TODO 会員情報更新画面
                                                            const { routeName } = this.props.navigation.state;
                                                            if (routeName != 'BuySubscriptionScreen') {
                                                                this.props.closeMenu();
                                                                this.props.navigation.navigate('BuySubscriptionScreen');
                                                            } else {
                                                                this.props.closeMenu();
                                                            }
                                                        }}
                                                    >
                                                        <View style={{flexDirection: "row", flex: 1, justifyContent: 'flex-end', marginTop: 2}}>
                                                            {/*appColors.gray*/}
                                                            <Text style={{
                                                                color: appColors.fontBlack,
                                                                fontFamily: appFont.family.default,
                                                                fontSize: appFont.size.default,
                                                                marginTop: 0}}>変更</Text>
                                                            {/*<Image source={require('../../resources/images/next_icon_gray.png')}*/}
                                                            {/*       style={[{*/}
                                                            {/*           marginTop: Platform.OS == 'web'? 2 : -2,*/}
                                                            {/*           marginRight: 0,*/}
                                                            {/*           width: 20,*/}
                                                            {/*           height: 20,*/}
                                                            {/*       }]}*/}
                                                            {/*       resizeMode={'cover'}*/}
                                                            {/*/>*/}
                                                        </View>
                                                    </TouchableOpacity>
                                                )}
                                            </View>

                                            {AppG.user.userPlan == UserUserPlanEnum.Premium && (
                                                <View style={{flexDirection: "row", marginTop: 0}}>
                                                    <Image source={require('../../resources/images/premium_icon.png')}
                                                           style={[{
                                                               marginTop: Platform.OS == 'web'? 4 :-1,
                                                               marginLeft: 2,
                                                               marginRight: 11,
                                                               width: 12,
                                                               height: 18,
                                                           }]}
                                                           resizeMode={'cover'}
                                                    />
                                                    <Text style={{
                                                        marginTop: Platform.OS == 'android'? -14 :-4,
                                                        color: appColors.fontBlack,
                                                        fontFamily: appFont.family.default,
                                                        fontSize: appFont.size.default}}>{
                                                        `${this._campaign? 'Pキャンペーン会員' : 'プレミアム会員'}`
                                                    }</Text>
                                                </View>
                                            )}
                                            {AppG.user.userPlan == UserUserPlanEnum.Free && (
                                                <View style={{flexDirection: "row", marginTop: 0}}>
                                                    <Image source={require('../../resources/images/free_icon.png')}
                                                           style={[{
                                                               marginTop: Platform.OS == 'web'? 4 : -1,
                                                               marginLeft: 2,
                                                               marginRight: 11,
                                                               width: 23,
                                                               height: 18,
                                                           }]}
                                                           resizeMode={'cover'}
                                                    />
                                                    <Text style={{
                                                        color: appColors.fontBlack,
                                                        fontFamily: appFont.family.default,
                                                        fontSize: appFont.size.default}}>無料会員</Text>
                                                </View>
                                            )}
                                        </View>

                                    </View>
                                )}
                            </View>
                        )}
                    </View>
                </KeyboardAwareScrollView>

                {/*ログアウトボタン*/}
                {AppG.user != null && (
                    <TouchableOpacity
                        style={[
                            {
                                height: appS.button.height,
                                backgroundColor: appColors.white,
                                borderColor: appColors.black,
                                borderWidth: 2,
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginBottom: 15,
                                marginLeft: ScreenSizeUtil.isWebSize()? 15 : 25,
                                marginRight: ScreenSizeUtil.isWebSize()? 15 : 15,
                            }
                        ]}
                        onPress={() => {
                            const successFunc = () => {
                                this.props.closeMenu();
                                this.props.navigation.navigate('LoginScreen');
                                this.props.closeMenu();
                            };
                            const failFunc = () => {};
                            LoginUtil.logout(successFunc, failFunc);
                        }}
                    >
                        <Text style={{
                            color: appColors.fontBlack,
                            fontFamily: appFont.family.bold,
                            fontSize: appS.button.text,
                            fontWeight: "bold"
                        }}>ログアウト</Text>
                    </TouchableOpacity>
                )}
            </View>
        );
    }


    render() {
        return (
            <View style={{position: 'absolute'}}>
                {/*メニューApp*/}
                {Platform.OS != 'web' && (
                    <Modal
                        isVisible={this.props.isShowMenu}
                        animationIn={'slideInLeft'}
                        animationOut={'slideOutLeft'}
                        deviceWidth={AppG.window.width}
                        deviceHeight={AppG.window.height}
                        style={{marginLeft: -appS.margins.side}}
                        onBackdropPress={() => {this.props.closeMenu()}}
                    >
                        {this._renderMenu()}
                    </Modal>
                )}

                {/*メニューWEB・SP*/}
                {Platform.OS == 'web' && !ScreenSizeUtil.isWebSize() && this.props.isShowMenu && (
                    <View style={{position: 'absolute', backgroundColor: appColors.opacityGray, flexDirection: 'row'}}>
                        <TouchableOpacity
                            style={{width: AppG.window.width / 5,
                                height: AppG.window.height}}
                            onPress={() => this.props.closeMenu()}
                        />
                        <View style={{width: AppG.window.width * 4 / 5}}>
                            {this._renderMenu()}
                        </View>
                    </View>
                )}

                {/*メニューWEB*/}
                {Platform.OS == 'web' && ScreenSizeUtil.isWebSize() && this.props.isShowMenu && (
                    <View style={{position: 'absolute', backgroundColor: appColors.opacityGray, flexDirection: 'row'}}>
                        <TouchableOpacity
                            style={{
                                width: AppG.window.width * 3 / 5,
                                height: AppG.window.height
                            }}
                            onPress={() => this.props.closeMenu()}
                        />
                        <View style={{width: AppG.window.width * 2 / 5}}>
                            {this._renderMenu()}
                        </View>
                    </View>
                )}

                {/*チュートリアル*/}
                <TutorialModalComponent
                    ref={(tutorialModalRef: any) => { this._tutorialModalRef = tutorialModalRef; }}
                    navigation={this.props.navigation}
                />

                {/*管理画面から入力*/}
                <PopupModalComponent
                    ref={(popupModalRef: any) => { this._popupModalRef = popupModalRef; }}
                    navigation={this.props.navigation}
                />

                {/*レビュー催促*/}
                <ProductReviewReminderModalComponent
                    ref={(productReviewReminderModalRef: any) => { this._productReviewReminderModalRef = productReviewReminderModalRef; }}
                    navigation={this.props.navigation}
                />

            </View>
        );
    }
}
