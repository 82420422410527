import {LoginDataEntityModel} from "../../entityModels/LoginDataEntityModel";
import {BaseDao} from "./BaseDao";
import AsyncStorage from '@react-native-async-storage/async-storage';
// @ts-ignore
import {log} from '../../../../../environment';

class LoginDataDao extends BaseDao<LoginDataEntityModel> {
    _getModel(): LoginDataEntityModel {
        return new LoginDataEntityModel();
    }

    /**
     * ログインデータはなりすましログインの時と場合分けしたいので override
     */
    get = async (): Promise<LoginDataEntityModel | null> => {
        try {
            let t = this._getModel();
            const _ = await AsyncStorage.multiGet(Object.keys(t), (error, stores) => {
                // @ts-ignore
                stores.map((result, i, store) => {
                    // @ts-ignore
                    t[result[0]] = result[1];
                });
            });
            return t;
        } catch (e) {
            log.debug(`Login Data Load Error : ${e}`);
            return null;
        }
    };
}

// new 付なのでシングルトン
export default new LoginDataDao();
