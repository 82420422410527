import React from 'react'
import {Dimensions, Image, Platform, SafeAreaView, ScrollView, Text, TouchableOpacity, View} from 'react-native'
import {appColors, appFont, appS} from '../../resources/styles/style'
import {BaseScreen} from "./BaseScreen";
import LoginUtil from "../util/LoginUtil";
import {IndicatorComponent} from "../components/IndicatorComponent";
import * as Clipboard from 'expo-clipboard';

// @ts-ignore
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scrollview';
import LoginDataDao from "../data/dao/local/LoginDataDao";
import {LoginDataEntityModel} from "../data/entityModels/LoginDataEntityModel";
import {CustomHeaderComponentSmall} from "../components/small/CustomHeaderComponentSmall";
// @ts-ignore
// @ts-ignore
import {getEnvVars, log} from '../../../environment';
import {ProfileInitScreenModel} from "../data/models/screen/ProfileInitScreenModel";
import AppG from "../util/AppG";
import AppGlobalDataUtil from "../util/AppG";
import ScreenSizeUtil from "../util/ScreenSizeUtil";
import {CustomHeaderComponentWide} from "../components/wide/CustomHeaderComponentWide";
import AnalyticsUtil, {AnalyticsEventName, DOpenEventName} from "../util/firebase/AnalyticsUtil";
import AlertUtil from "../util/AlertUtil";

import {ReferralApiFactory} from "../data/network/swagger-gen";
import {WideSubTitleComponent} from "../components/wide/WideSubTitleComponent";
import {WideScreenAdComponent} from "../components/wide/WideScreenAdComponent";
import {WideScreenSNSComponent} from "../components/wide/WideScreenSNSComponent";
import {NoUserImageComponent} from "../components/NoUserImageComponent";
import LinkUtil from "../util/LinkUtil";

interface Props {
    navigation: any, // ナビゲーション用
}

interface State {
    resizeWindow: boolean,  // 値に意味はなし。windowサイズの拡大縮小をrenderに送るためのもの
    isLoaded: boolean,

    referralCode: string,   // 紹介コード
}

export class ReferralCodeScreen extends BaseScreen<Props, State> {
    _resizeTimer = setInterval(() => {
        // window 拡大縮小を反映させる
        AppG.window = Dimensions.get('window');
        if (AppG.window.width != AppG.windowBefore.width || AppG.window.height != AppG.windowBefore.height) {
            this.setState({'resizeWindow': true});
            AppG.windowBefore = Dimensions.get('window');
        }
        LoginUtil.setRefreshedScreenFlg(false);
    }, 100);

    _loginData = new LoginDataEntityModel();

    _flameSize = appS.flame.size;    // 左右の margin
    _marginSize = ScreenSizeUtil.isWebSize()? 0 : appS.margin.size;
    _menuCellHeight = 50;

    // このScreenへ渡すパラム
    _param: ProfileInitScreenModel = new ProfileInitScreenModel();

    _browserBackListener = (e: any) => {

    };

    constructor(props: any) {
        super(props);
        this.state = {
            resizeWindow: true,  // 値に意味はなし。windowサイズの拡大縮小をrenderに送るためのもの
            isLoaded: false,
            referralCode: '',
        };
    }

    /**
     * 画面開いたときのアナリティクス送信
     */
    _sendPvAnalytics = () => {
        AnalyticsUtil.sendAnalytics(
            DOpenEventName.openPage,
            AnalyticsEventName.openPage,  // アナリティクスのイベント名
            'ReferralCodeScreen',
            null,
            null,
            null,
            null,
        );
    }

    componentDidMount() {
        if (Platform.OS == 'web') {
            window.addEventListener('popstate', this._browserBackListener);
        }

        LoginUtil.interruptOpenScreen(this.props.navigation,
            () => {
                LoginDataDao.get().then(loginData => {
                    // Web 戻るでも呼ばれる
                    if (Platform.OS == 'web') {
                        // Analytics
                        this._sendPvAnalytics();
                    }

                    // URL Scheme で外部リンクから起動したときの処理と、画面遷移でこの画面を開いたときの処理
                    LoginUtil.setDefaultListener(this.props.navigation, () => {
                        // Webはここにこない
                        if (Platform.OS != 'web') {
                            // Analytics
                            this._sendPvAnalytics();
                        }
                    });
                    if (loginData != null) {
                        this._loginData = loginData;
                        this._loadMasters().then(() => {});
                        this._loadContents().then(() => {});
                        this.setState({'isLoaded': true});
                    }
                });
            });

        this.setState({isLoaded: true});
    }

    componentWillUnmount () {
        clearTimeout(this._resizeTimer);

        if (Platform.OS === 'web') {
            window.removeEventListener('popstate', this._browserBackListener);
        }
        LoginUtil.removeDefaultListener(this.props.navigation);
    };

    componentDidUpdate () {
        // レンダー完了時に呼ばれる
    }

    /**
      * パラムの取得
      * @private
      */
    _getParams = () => {
    };

    /**
     * マスターのロード
     * @private
     */
    _loadMasters = async () => {
    };

    /**
     * コンテンツのロード
     * @private
     */
    _loadContents = async () => {
        ReferralApiFactory(AppGlobalDataUtil.getConfiguration(), AppGlobalDataUtil.getBasePath())
            .getReferralCode()
            .then((res) => {
                this.setState({referralCode: res.data.referralCode});
            });
    };

    /**
     * 未ログイン時の表示
     */
    _renderNoLogin = () => {
        return (
            <View style={{flex: 1, backgroundColor: appColors.backBaseColor}}>
                <NoUserImageComponent navigation={this.props.navigation} />
            </View>
        );
    }

    _renderMain = () => {
        return (
            <View style={[{flex: 1, flexDirection: 'column', margin: this._marginSize, alignSelf: 'center'}]}>

                {/*紹介コード*/}
                <View style={{marginTop: 32, height: this._menuCellHeight, flexDirection: "row", alignSelf: 'center'}}>
                    <Text style={{color: appColors.black, fontWeight: "bold", fontSize: 40}}>{this.state.referralCode}</Text>
                    <View style={{height: 40, width: 8}}/>
                    <TouchableOpacity
                        onPress={() => {
                            Clipboard.setString(this.state.referralCode);
                            AlertUtil.alert('', '紹介コードをコピーしました。');
                        }}>
                        <Image source={require('../../resources/images/link-copy-icon.png')}
                               style={[{
                                   marginTop: 6,
                                   width: 40,
                                   height: 40,
                                   borderRadius: 5,
                               }]}
                               resizeMode={'contain'}
                        />
                    </TouchableOpacity>
                </View>
                <Text style={{marginTop: 32, color: appColors.gray, fontWeight: "bold", fontSize: appFont.size.default}}>※紹介先は飲食店の方が対象になります。{"\n"}※キャンペーン期間終了後1~2週間を目途にギフト券をお送り致します。{"\n"}※最大5名まで紹介ギフトをお送り致します。</Text>
            </View>
        );
    }

    _renderWide = () => {
        let _width = AppG.window.width;
        if (ScreenSizeUtil.isWebSize()) {
            _width = appS.webMainContentsSize.width;
        }

        return (
            <SafeAreaView
                style={[{height: AppG.window.height, flex: 1, flexDirection: 'column', backgroundColor: appColors.white}]}
                onLayout={(event) => {}}
            >
                <CustomHeaderComponentWide
                    navigation={this.props.navigation}
                    searchBar={true}
                />

                <ScrollView
                    horizontal  // 下階層のスクロールイベントを拾えるようにしている
                    style={{
                        width: AppG.window.width,
                        height: AppG.window.height - appS.header.webHeight,
                    }}
                >
                    <View style={[{flex: 1, flexDirection: 'column', width: AppG.window.width}]}>
                        <KeyboardAwareScrollView style={[{flex: 1, flexDirection: 'column'}]}>
                            <View style={[{flex: 1, flexDirection: ScreenSizeUtil.isWebSize()? 'row': 'column', justifyContent: 'center'}]}>
                                <View style={[{flexDirection: 'column'}]}>
                                    <View style={{width: _width}}>
                                        {ScreenSizeUtil.isWebSize() && <WideSubTitleComponent title={'紹介コード'} width={_width} />}

                                        {/*未ログイン*/}
                                        {AppG.user == null && this._renderNoLogin()}

                                        {this._renderMain()}
                                    </View>
                                </View>
                                <View style={{marginLeft: appS.margins.webBetweenMargin, width: appS.webSideContentsSize.width}}>
                                    {ScreenSizeUtil.isWebSize() && (
                                        <View style={{}}>
                                            <WideScreenAdComponent navigation={this.props.navigation}/>
                                            <WideScreenSNSComponent navigation={this.props.navigation}/>
                                        </View>
                                    )}
                                </View>
                            </View>
                        </KeyboardAwareScrollView>
                    </View>
                </ScrollView>

                <View style={{flex: 1, flexDirection: 'row'}}>
                    <KeyboardAwareScrollView style={[{flex: 1, flexDirection: 'column'}]}>
                        <View style={[{flex: 1, width: 700, alignSelf: 'center'}]}>
                            {this._renderMain()}
                        </View>
                    </KeyboardAwareScrollView>
                </View>
            </SafeAreaView>
        );
    }

    _renderSmall = () => {
        return (
            // appStyles.statusBarHiddenSafeAreaでステータスバー分上に上げる
            <SafeAreaView
                style={[{flex: 1, backgroundColor: appColors.white}]}
                onLayout={(event) => {}}
            >
                <CustomHeaderComponentSmall
                    navigation={this.props.navigation}
                    title={'紹介コード'}
                    textColor={appColors.black}
                    barColor={appColors.white}
                    borderBottomColor={appColors.white}
                    leftButton={'back'}
                    rightButton={null}
                />
                <View style={{flex: 1, backgroundColor: appColors.backBaseColor}}>
                    {this._renderMain()}
                </View>
            </SafeAreaView>
        );
    }


    /** 描画 */
    render() {
        const {navigation} = this.props;
        if (!this.state.isLoaded) {
            return (
                <View style={{flex: 1}}>
                    <IndicatorComponent
                        failTime={60}
                        isLoading={true}
                        reloadFunc={() => {
                            this.componentWillUnmount();
                            this.componentDidMount();
                        }}/>
                </View>
            );
        }

        LinkUtil.addReturnFalseToATag();
        if (ScreenSizeUtil.isWebSize()) {
            // WEBサイズ
            return this._renderWide();
        } else {
            // SPサイズ
            return this._renderSmall();
        }

    };


}

