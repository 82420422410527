import {Platform} from "react-native";
import { logger } from 'react-native-logs';
// import { colorConsoleAfterInteractions } from 'react-native-logs/dist/transports/colorConsoleAfterInteractions';
import ExpoUtil from "./src/tsx/util/ExpoUtil";
import Constants from "expo-constants";

/*****************************
 * environment.js
 * path: '/environment.js' (root of your project)
 ******************************/

// expo の環境ファイル
// React Native は .env で両方に記述が必要なので注意
// import Config from "react-native-config";
// import ExpoUtil from "./src/tsx/util/ExpoUtil";

// export const log = logger.createLogger({transport: colorConsoleAfterInteractions,});
export const log = logger.createLogger();

const ENV = {
    dev: {
        // CONST
        REACT_APP_ENV_SCHEME: "E_DEV",
        REACT_APP_NAVIGATION_TITLE: "ReciBASE-dev",
        REACT_APP_HTTP: "https://",  // staging
        // REACT_APP_HTTP: "http://",  // local
        // REACT_APP_DOMAIN_IOS: "localhost:8083",
        // REACT_APP_DOMAIN_IOS: "192.168.1.4:8083",
        // REACT_APP_DOMAIN_ANDROID: "10.0.2.2:8083",
        // REACT_APP_DOMAIN_IOS: "18.183.224.79/api/api",
        // REACT_APP_DOMAIN_ANDROID: "18.183.224.79/api/api",
        REACT_APP_DOMAIN_ANALYTICS: "a.recibase.net/api/api",
        REACT_APP_DOMAIN_IOS: "api-dev.recibase.net/api/api",    // staging
        REACT_APP_DOMAIN_ANDROID: "api-dev.recibase.net/api/api",    // staging
        // REACT_APP_DOMAIN_IOS: "localhost/api/api",  // local
        // REACT_APP_DOMAIN_ANDROID: "localhost/api/api",  // local
        REACT_APP_DOMAIN_URL: "http://localhost:19006",
        // REACT_APP_DOMAIN_URL: "https://app-dev.recibase.net",    // POPUPのテストの時などに
        REACT_APP_API_VERSION: "",
        REACT_APP_WP_URL: "https://wp.recibase.net/",
        REACT_APP_FAQ_URL: "https://wp.recibase.net/faq/",
        REACT_APP_FAQ_IOS_URL: "https://wp.recibase.net/faq-ios/",
        REACT_APP_PRIVACY_URL: "https://wp.recibase.net/privacy/",
        REACT_APP_TERMS_OF_SERVICE_URL: "https://wp.recibase.net/terms/",
        REACT_APP_COOKIE_DOMAIN: "recibase.net",
        REACT_APP_WITHDRAW_URL: "",
        REACT_APP_INSTAGRAM_URL: "https://www.instagram.com/reci_base/?hl=ja\n",
        REACT_APP_NOTE_URL: "https://note.com/rb_echo",
        REACT_APP_STORE_URL_IOS: "https://itunes.apple.com/jp/app/id1552833343?mt=8",
        REACT_APP_STORE_URL_ANDROID: "https://play.google.com/store/apps/details?id=net.recibase.app.android",
        // http://18.178.111.50/api/
        // Google Fire Store
        REACT_APP_FIRE_STORE_API_KEY: "AIzaSyDV9rU2hKdpTSvkCSAaiDa4P8tvd2YQ0-w",
        REACT_APP_FIRE_STORE_AUTH_DOMAIN: "reci-base-dev.firebaseapp.com",
        REACT_APP_FIRE_STORE_DATA_BASE_URL: "https://reci-base-dev-default-rtdb.firebaseio.com",
        REACT_APP_FIRE_STORE_PROJECT_ID: "reci-base-dev",
        REACT_APP_FIRE_STORE_STORAGE_BUCKET: "reci-base-dev.appspot.com",
        REACT_APP_FIRE_STORE_MESSAGING_SENDER_ID: "811003279601",
        REACT_APP_FIRE_STORE_APP_ID: "1:811003279601:web:131833f10feeaccc53d150",
        REACT_APP_FIRE_STORE_MEASUREMENT_ID: "G-DGDVX41PED",
        // FACEBOOK
        REACT_APP_FACEBOOK_URL: "https://www.facebook.com/recibase.jp/",
        REACT_APP_FACEBOOK_APP_ID: "535261191253112"
    },
    adhoc: {
        // CONST
        REACT_APP_ENV_SCHEME: "E_DEV",
        REACT_APP_NAVIGATION_TITLE: "ReciBASE-dev",
        REACT_APP_HTTP: "https://",
        REACT_APP_DOMAIN_ANALYTICS: "a.recibase.net/api/api",
        REACT_APP_DOMAIN_IOS: "api-dev.recibase.net/api/api",
        REACT_APP_DOMAIN_ANDROID: "api-dev.recibase.net/api/api",
        REACT_APP_DOMAIN_URL: "https://app-dev.recibase.net",
        REACT_APP_API_VERSION: "",
        REACT_APP_WP_URL: "https://wp.recibase.net/",
        REACT_APP_FAQ_URL: "https://wp.recibase.net/faq/",
        REACT_APP_FAQ_IOS_URL: "https://wp.recibase.net/faq-ios/",
        REACT_APP_PRIVACY_URL: "https://wp.recibase.net/privacy/",
        REACT_APP_TERMS_OF_SERVICE_URL: "https://wp.recibase.net/terms/",
        REACT_APP_COOKIE_DOMAIN: "recibase.net",
        REACT_APP_WITHDRAW_URL: "",
        REACT_APP_INSTAGRAM_URL: "https://www.instagram.com/reci_base/?hl=ja\n",
        REACT_APP_NOTE_URL: "https://note.com/rb_echo",
        REACT_APP_STORE_URL_IOS: "https://itunes.apple.com/jp/app/id1552833343?mt=8",
        REACT_APP_STORE_URL_ANDROID: "https://play.google.com/store/apps/details?id=net.recibase.app.android",
        // Google Fire Store
        REACT_APP_FIRE_STORE_API_KEY: "AIzaSyDV9rU2hKdpTSvkCSAaiDa4P8tvd2YQ0-w",
        REACT_APP_FIRE_STORE_AUTH_DOMAIN: "reci-base-dev.firebaseapp.com",
        REACT_APP_FIRE_STORE_DATA_BASE_URL: "https://reci-base-dev-default-rtdb.firebaseio.com",
        REACT_APP_FIRE_STORE_PROJECT_ID: "reci-base-dev",
        REACT_APP_FIRE_STORE_STORAGE_BUCKET: "reci-base-dev.appspot.com",
        REACT_APP_FIRE_STORE_MESSAGING_SENDER_ID: "811003279601",
        REACT_APP_FIRE_STORE_APP_ID: "1:811003279601:web:131833f10feeaccc53d150",
        REACT_APP_FIRE_STORE_MEASUREMENT_ID: "G-DGDVX41PED",
        // FACEBOOK
        REACT_APP_FACEBOOK_URL: "https://www.facebook.com/recibase.jp/",
        REACT_APP_FACEBOOK_APP_ID: "535261191253112"
    },
    production: {
        // CONST
        REACT_APP_ENV_SCHEME: "E_DEV",
        REACT_APP_NAVIGATION_TITLE: "ReciBASE",
        REACT_APP_HTTP: "https://",
        REACT_APP_DOMAIN_ANALYTICS: "a.recibase.net/api/api",
        REACT_APP_DOMAIN_IOS: "api.recibase.net/api/api",
        REACT_APP_DOMAIN_ANDROID: "api.recibase.net/api/api",
        REACT_APP_DOMAIN_URL: "https://app.recibase.net",
        REACT_APP_API_VERSION: "",
        REACT_APP_WP_URL: "https://wp.recibase.net/",
        REACT_APP_FAQ_URL: "https://wp.recibase.net/faq/",
        REACT_APP_FAQ_IOS_URL: "https://wp.recibase.net/faq-ios/",
        REACT_APP_PRIVACY_URL: "https://wp.recibase.net/privacy/",
        REACT_APP_TERMS_OF_SERVICE_URL: "https://wp.recibase.net/terms/",
        REACT_APP_COOKIE_DOMAIN: "recibase.net",
        REACT_APP_WITHDRAW_URL: "",
        REACT_APP_INSTAGRAM_URL: "https://www.instagram.com/reci_base/?hl=ja\n",
        REACT_APP_NOTE_URL: "https://note.com/rb_echo",
        REACT_APP_STORE_URL_IOS: "https://itunes.apple.com/jp/app/id1552833343?mt=8",
        REACT_APP_STORE_URL_ANDROID: "https://play.google.com/store/apps/details?id=net.recibase.app.android",
        // Google Fire Store
        REACT_APP_FIRE_STORE_API_KEY: "AIzaSyDjYx5Comoy7BzM_CHnNvS3JZOMoytRCU0",
        REACT_APP_FIRE_STORE_AUTH_DOMAIN: "reci-base.firebaseapp.com",
        REACT_APP_FIRE_STORE_DATA_BASE_URL: "https://reci-base-dev-default-rtdb.firebaseio.com",
        REACT_APP_FIRE_STORE_PROJECT_ID: "reci-base",
        REACT_APP_FIRE_STORE_STORAGE_BUCKET: "reci-base.appspot.com",
        REACT_APP_FIRE_STORE_MESSAGING_SENDER_ID: "994371742139",
        REACT_APP_FIRE_STORE_APP_ID: "1:994371742139:web:e82a43604097857c463c9e",
        REACT_APP_FIRE_STORE_MEASUREMENT_ID: "G-YZM3Q0VZ5X",
        // FACEBOOK
        REACT_APP_FACEBOOK_URL: "https://www.facebook.com/recibase.jp/",
        REACT_APP_FACEBOOK_APP_ID: "535261191253112"
    }
};

export const getEnvVars = (env = null) => {
    // What is __DEV__ ?
    // This variable is set to true when react-native is running in Dev mode.
    // __DEV__ is true when run locally, but false when published.

    // release チャンネルを取得（manifestからの取得は非推奨になったっぽい）
    env = Constants.expoConfig.extra.release_channel;   // app.config.ts and eas.json

    if (__DEV__ || env === 'dev' || env === 'development') {
        ExpoUtil.setProduction(false);
        log.setSeverity('debug');
        return ENV.dev;
    } else if (env === 'adhoc') {
        ExpoUtil.setProduction(false);
        log.setSeverity('error');
        return ENV.adhoc;
    } else if (env === 'preview') {
        ExpoUtil.setProduction(false);
        log.setSeverity('error');
        return ENV.adhoc;
    } else if (env === 'production') {
        ExpoUtil.setProduction(true);
        log.setSeverity('error');
        return ENV.production;
    } else if (Platform.OS === 'web') {
        // Amplify だとここに来る
        if (document.domain === 'app-dev.recibase.net') {
            // テスト環境
            ExpoUtil.setProduction(false);
            log.setSeverity('error');
            return ENV.adhoc;
        } else if (document.domain === 'app.recibase.net') {
            // 本番
            ExpoUtil.setProduction(true);
            log.setSeverity('error');
            return ENV.production;
        }
    }

    // テスト環境
    ExpoUtil.setProduction(false);
    log.setSeverity('debug');
    return ENV.adhoc;
};

