import React, {Component} from 'react';
import {Image, SafeAreaView, ScrollView, Text, TouchableOpacity, View} from 'react-native';
import {SearchBar} from "react-native-elements";
import {appColors, appFont, appS} from "../../../resources/styles/style";
import {Keyword} from "../../data/network/swagger-gen";
import ValidateUtil from "../../util/ValidateUtil";
import {ListCellComponent} from "../ListCellComponent";
import CustomHeaderFunc from "../func/CustomHeaderFunc";
import {SearchScreenModel} from "../../data/models/screen/SearchScreenModel";
// somewhere in your app
import AppG from "../../util/AppG";
import {MenuComponent} from "../MenuComponent";
import ScreenSizeUtil from "../../util/ScreenSizeUtil";
import {IconWithBadgeComponent} from "../IconWithBadgeComponent";
import SearchDataDao from "../../data/dao/local/SearchDataDao";
import LinkUtil from "../../util/LinkUtil";
import {ATagLinkComponent} from "../ATagLinkComponent";
import {Menu, MenuOption, MenuOptions, MenuTrigger} from "react-native-popup-menu";
import {PopupListCellComponent} from "./PopupListCellComponent";
import {SpacerComponent} from "../SpacerComponent";
import {SearchScreenComponent} from "../SearchScreenComponent";
import NavigationUtil from "../../util/NavigationUtil";

interface Props {
    navigation: any,
    onSearchSubmit: ((text: string) => void) | null, // 検索をサブミットしたときの動作
    onTabClicked: () => void,
    logoOnly: boolean,
    isLoadingLogin: boolean,    // ログインのとこの表示
    searchValue: string,
}

interface State {
    searchValue: string,
    keywords: Keyword[],
    tabIndex: number,
    onSearchSubmit: (text: string) => void,
    isShowMenu: boolean,
    // searchHistory: Keyword[],   // 検索履歴
}

export class CustomHeaderComponentWide extends Component<Props, State> {
    _heightUp = appS.header.webUpsideHeight;
    _heightBottom = appS.header.webBottomHeight;
    _searchMenu: any = null;

    // Propsのデフォルト値
    static defaultProps = {
        searchBar: false,
        onSearchSubmit: null,
        onTabClicked: () => {},
        onSearchMenuClicked: null,
        logoOnly: false,
        isLoadingLogin: false,
        searchValue: '',
    };

    // @ts-ignore
    constructor(props) {
        super(props);
        this.state = {
            searchValue: '',
            keywords: [],
            tabIndex: AppG.tabIndex,
            onSearchSubmit: (text: string) => {},
            isShowMenu: false,
            // searchHistory: [],
        };
    }

    componentDidMount() {
        NavigationUtil.setWideHeaderRefresh(this.componentDidMount, this);

        this.setState({tabIndex: AppG.tabIndex});

        if (!ValidateUtil.isEmptyExact(this.props.searchValue)) {
            // 検索文字引き継ぎ
            this.setState({searchValue: this.props.searchValue});
        }

        this.setState({onSearchSubmit:
                (text: string) => {
                    // 検索
                    if (!ValidateUtil.isEmptyExact(text)) {
                        const { routeName } = this.props.navigation.state;
                        if (routeName != 'SearchResultScreen') {
                            let searchScreenModel: SearchScreenModel = new SearchScreenModel();
                            searchScreenModel.searchValue = text;
                            searchScreenModel.searchType = 'all';
                            this.props.navigation.navigate('SearchResultScreen', searchScreenModel);
                        } else if (this.props.onSearchSubmit != null) {
                            this.props.onSearchSubmit(text);
                        }
                    }
                }
        });
    }

    /**
     * ポップアップメニューのトピック
     * @param title
     */
    _renderMenuTopic = (title: string) => {
        let _marginLeft = ScreenSizeUtil.isWebSize() ? 0: 16;

        return (
            <View style={{flexDirection: "row", marginLeft: _marginLeft}}>
                <Text style={{fontFamily: appFont.family.bold, fontSize: 16, color: appColors.black, lineHeight: 26, fontWeight: "bold"}}>{title}</Text>
            </View>
        );
    }

    render() {
        const {
            navigation,
            onTabClicked,
            logoOnly,
        } = this.props;

        const _searchBarHeight = 40;
        const _tabButtonSize = {width: 111, height: 44, margin: 10};

        // ロゴだけケース
        if (logoOnly) {
            return (
                <SafeAreaView style={{
                    zIndex: 99,
                }}>
                    <View
                        style={{
                            flex: 1,
                            flexDirection: 'row',
                            backgroundColor: appColors.black,
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <View
                            style={[

                                {
                                    backgroundColor:  appColors.black,
                                    height: this._heightUp,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }
                            ]}

                        >
                            <Image source={require('../../../resources/images/logo_icon_web_white.png')}
                                   style={[{
                                       width: 176,
                                       height: 48,
                                   }]}
                                   resizeMode='contain'
                                   accessibilityLabel={'ReciBASE(レシベース)'}
                            />
                        </View>
                    </View>
                </SafeAreaView>
            );
        }

        return (
            <SafeAreaView
                style={{
                    zIndex: 99,
                    width: AppG.window.width,
                }}>
                <View
                    style={{
                        width: appS.webAllContentsSize.width,
                        alignSelf: 'center',
                    }}
                >
                    <View
                        style={{
                            height: this._heightUp + this._heightBottom,
                            flexDirection: "column",
                        }}>

                        <View
                            style={{
                                flex: 1,
                                height: this._heightUp,
                                flexDirection: 'row',
                                backgroundColor: appColors.white,
                            }}
                        >
                            <View style={{flexDirection: 'row', flex: 2}}>
                                <ATagLinkComponent
                                    href={'/'}
                                    onPress={() => {
                                        const { routeName } = navigation.state;
                                        if (routeName != 'HomeScreen') {
                                            navigation.navigate('HomeScreen');
                                        }}
                                    }
                                    view={
                                        <Image source={require('../../../resources/images/logo_icon_web.png')}
                                               style={[{
                                                   width: 172,
                                                   height: 44,
                                               }]}
                                               resizeMode='contain'
                                               accessibilityLabel={'ReciBASE(レシベース)'}
                                        />
                                    }
                                    style={{
                                        height: this._heightUp,
                                        marginTop: 4}}
                                />
                                <View style={{flexDirection: 'column', flex: 1}}>
                                    <View style={{flexDirection: 'row', flex: 1}}>
                                        {/*<TouchableOpacity*/}
                                        {/*    onPress={() => {*/}
                                        {/*        const { routeName } = navigation.state;*/}
                                        {/*        if (routeName != 'SearchResultScreen') {*/}
                                        {/*            let searchScreenModel: SearchScreenModel = new SearchScreenModel();*/}
                                        {/*            searchScreenModel.searchType = 'all';*/}
                                        {/*            searchScreenModel.headerTitle = this.state.searchValue;*/}
                                        {/*            searchScreenModel.searchValue = this.state.searchValue;*/}
                                        {/*            navigation.navigate('SearchResultScreen', searchScreenModel);*/}
                                        {/*        }*/}

                                        {/*        setTimeout(() => {*/}
                                        {/*            this.setState({keywords: []});*/}
                                        {/*        }, 300);*/}
                                        {/*    }}*/}
                                        {/*    style={{*/}
                                        {/*        zIndex: 100,*/}
                                        {/*        alignSelf: 'center',*/}
                                        {/*        width: _searchBarHeight,*/}
                                        {/*        height: _searchBarHeight,*/}
                                        {/*        marginRight: -20,*/}
                                        {/*    }}*/}
                                        {/*>*/}
                                        {/*    <Image source={require('../../../resources/images/search_bar_icon.png')}*/}
                                        {/*           style={[{*/}
                                        {/*               marginTop: 5,*/}
                                        {/*               alignSelf: 'center',*/}
                                        {/*               width: 14,*/}
                                        {/*               height: 14,*/}
                                        {/*           }]}*/}
                                        {/*           resizeMode='contain'*/}
                                        {/*    />*/}
                                        {/*</TouchableOpacity>*/}
                                        <SearchBar
                                            placeholder="キーワードで検索"
                                            autoCapitalize={"none"}
                                            onChangeText={(text?: string) => {
                                                if (text == null) {
                                                    return;
                                                }
                                                this.setState({searchValue: text});
                                                setTimeout(() => {
                                                    CustomHeaderFunc.getKeyword(this, text);
                                                    if (ValidateUtil.isEmptyExact(this.state.searchValue)) {
                                                        this._searchMenu.open();
                                                    } else {
                                                        this._searchMenu.close();
                                                    }
                                                }, 300);
                                            }}
                                            value={this.state.searchValue}
                                            containerStyle={{
                                                width: 330,
                                                backgroundColor: appColors.white,
                                                borderBottomColor: appColors.transparent,
                                                borderTopColor: appColors.transparent,
                                            }}
                                            inputContainerStyle={{
                                                backgroundColor: appColors.white,
                                                width: 330,
                                                height: _searchBarHeight,
                                                borderRadius: 0,
                                                marginLeft: 10,
                                                marginTop: 0,
                                                borderWidth: 1,
                                                borderBottomWidth: 1,
                                                borderColor: appColors.lightGray,
                                            }}
                                            returnKeyType={'search'}
                                            onSubmitEditing={() => {
                                                // 検索履歴に追加
                                                SearchDataDao.addNew(this.state.searchValue);

                                                this.setState({keywords: []});
                                                const { routeName } = navigation.state;
                                                if (routeName != 'SearchResultScreen') {
                                                    let searchScreenModel: SearchScreenModel = new SearchScreenModel();
                                                    searchScreenModel.headerTitle = this.state.searchValue;
                                                    searchScreenModel.searchValue = this.state.searchValue;
                                                    searchScreenModel.searchType = 'all';
                                                    navigation.navigate('SearchResultScreen', searchScreenModel);
                                                } else {
                                                    this.state.onSearchSubmit(this.state.searchValue);
                                                }

                                                // setTimeout(() => {
                                                //     this.setState({searchValue: ''});
                                                // }, 100);
                                            }}
                                            onFocus={() => {
                                                AppG.screenHistory.unshift(`検索ヘッダー`);

                                                // 検索履歴を取得
                                                SearchDataDao.get().then(searchData => {
                                                    if (searchData != null) {
                                                        let _searchHistory: Keyword[] = [];
                                                        const _searchWord = searchData.searchWord?.split('|');
                                                        if (_searchWord != null) {
                                                            _searchWord.forEach((word, index) => {
                                                                if (index < 6) {
                                                                    _searchHistory.push({value: word, indexName: ''});
                                                                }
                                                            })
                                                            AppG.searchHistory = _searchHistory;
                                                            // this.setState({searchHistory: _searchHistory});
                                                        }
                                                    }
                                                });
                                                if (ValidateUtil.isEmptyExact(this.state.searchValue)) {
                                                    this._searchMenu.open();
                                                } else {
                                                    this._searchMenu.close();
                                                }
                                            }}
                                            // @ts-ignore
                                            inputStyle={{outline: 'none'}}
                                            //@ts-ignore
                                            searchIcon={
                                                <View/>
                                            }
                                        />
                                        <TouchableOpacity
                                            style={[

                                                {
                                                    backgroundColor:  appColors.buttonOrange,
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    width: 92,
                                                    height: _searchBarHeight,
                                                    marginTop: 9,
                                                    marginLeft: 16,
                                                }
                                            ]}
                                            onPress={() => {
                                                this.setState({keywords: []});

                                                const { routeName } = navigation.state;
                                                if (routeName != 'SearchResultScreen') {
                                                    let searchScreenModel: SearchScreenModel = new SearchScreenModel();
                                                    searchScreenModel.headerTitle = this.state.searchValue;
                                                    searchScreenModel.searchValue = this.state.searchValue;
                                                    searchScreenModel.searchType = 'all';
                                                    navigation.navigate('SearchResultScreen', searchScreenModel);
                                                } else {
                                                    this.state.onSearchSubmit(this.state.searchValue);
                                                }

                                                // setTimeout(() => {
                                                //     this.setState({searchValue: ''});
                                                // }, 100);
                                            }}
                                        >
                                            <Text style={{fontFamily: appFont.family.bold, color: appColors.white, fontSize: 14, fontWeight: "bold"}}>検索</Text>
                                        </TouchableOpacity>
                                    </View>
                                    {/*検索ダイアログ*/}
                                    <View style={{marginLeft: 16}}>
                                        <Menu ref={(ref) => (this._searchMenu = ref)}>
                                            <MenuTrigger text='' />
                                            <MenuOptions
                                                customStyles={{optionsContainer: {
                                                        padding: 8,
                                                        width: 900,
                                                        marginTop: 50,
                                                    },
                                                    optionsWrapper: {},
                                                    optionWrapper: {},
                                                    optionTouchable: {},
                                                    optionText: {},}}>

                                                <SearchScreenComponent
                                                    navigation={navigation}
                                                    searchValue={this.state.searchValue}
                                                    onClose={() => {this._searchMenu.close();}}
                                                />
                                            </MenuOptions>
                                        </Menu>


                                    </View>
                                </View>
                            </View>
                            <View style={{flex: 1}}/>
                            <View style={{flexDirection: 'row'}}>
                                {/*無料会員登録*/}
                                {!this.props.isLoadingLogin && AppG.user == null && (
                                    <TouchableOpacity
                                        style={[

                                            {
                                                marginTop: -15,
                                                backgroundColor:  appColors.white,
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                marginRight: 20,
                                            }
                                        ]}
                                        onPress={() => {
                                            const { routeName } = navigation.state;
                                            if (routeName != 'SignUpScreen') {
                                                navigation.navigate('SignUpScreen');
                                            }
                                        }}
                                    >
                                        <Text style={{fontFamily: appFont.family.bold, color: appColors.black, fontSize: 14, fontWeight: "bold"}}>無料会員登録</Text>
                                    </TouchableOpacity>
                                )}

                                {/*ログイン*/}
                                {!this.props.isLoadingLogin && AppG.user == null && (
                                    <TouchableOpacity
                                        style={[

                                            {
                                                marginRight: 20,
                                                backgroundColor:  appColors.white,
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }
                                        ]}
                                        onPress={() => {
                                            const { routeName } = navigation.state;
                                            if (routeName != 'LoginScreen') {
                                                navigation.navigate('LoginScreen');
                                            }
                                        }}
                                    >
                                        <Image source={require('../../../resources/images/01.header/wide_login_icon.png')}
                                               style={[{
                                                   marginTop: -4,
                                                   width: appS.header.wideMenuIconSize - 4,
                                                   height: appS.header.wideMenuIconSize - 4,
                                               }]}
                                               resizeMode='contain'/>
                                    </TouchableOpacity>
                                )}

                                {/*メニュー*/}
                                <TouchableOpacity
                                    style={{flexDirection: "row"}}
                                    onPress={() => {
                                        this.setState({isShowMenu: true});
                                    }}
                                >
                                    <Image source={require('../../../resources/images/01.header/wide_menu_icon.png')}
                                           style={[{
                                               marginTop: 12,
                                               width: appS.header.wideMenuIconSize,
                                               height: appS.header.wideMenuIconSize,
                                           }]}
                                           resizeMode='contain'/>
                                    <View style={{marginLeft: -26, marginRight: 50, marginTop: 21}}>
                                        <IconWithBadgeComponent
                                            iconSize={12}
                                            multipleSize={1.4}
                                            isShow={true}
                                            isWrittenNumber={false}
                                            badgeCount={(AppG.badgeChat==null?0:AppG.badgeChat) + (AppG.badgeHistory==null?0:AppG.badgeHistory)}/>
                                    </View>
                                </TouchableOpacity>
                            </View>
                        </View>

                        {/*下*/}
                        <View
                            style={{
                                flex: 1,
                                height: this._heightBottom,
                                flexDirection: 'row',
                                backgroundColor: appColors.white,
                                alignItems: 'center',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <View style={{flexDirection: 'row'}}>
                                {/*TOP*/}
                                <TouchableOpacity
                                    style={[
                                        {
                                            width: _tabButtonSize.width,
                                            height: _tabButtonSize.height,
                                            margin: _tabButtonSize.margin,
                                            marginLeft: 32,
                                            alignSelf: 'center',
                                            backgroundColor: this.state.tabIndex == 0? appColors.actionColor : appColors.white,
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }
                                    ]}
                                    onPress={() => {
                                        AppG.tabIndex = 0;
                                        const { routeName } = navigation.state;
                                        if (routeName == 'HomeScreen') {
                                            this.setState({tabIndex: AppG.tabIndex});
                                            onTabClicked();
                                        } else {
                                            const { routeName } = navigation.state;
                                            if (routeName != 'HomeScreen') {
                                                navigation.navigate('HomeScreen');
                                            }
                                        }
                                    }}
                                >
                                    <Text style={{fontFamily: appFont.family.bold, color: this.state.tabIndex == 0? appColors.white: appColors.fontBlack, fontSize: 16, fontWeight: 'bold'}}>TOP</Text>
                                </TouchableOpacity>

                                {/*レシピ*/}
                                <TouchableOpacity
                                    style={[

                                        {
                                            width: _tabButtonSize.width,
                                            height: _tabButtonSize.height,
                                            margin: _tabButtonSize.margin,
                                            alignSelf: 'center',
                                            backgroundColor: this.state.tabIndex == 1? appColors.actionColor : appColors.white,
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }
                                    ]}
                                    onPress={() => {
                                        AppG.tabIndex = 1;
                                        const { routeName } = navigation.state;
                                        if (routeName == 'HomeScreen') {
                                            this.setState({tabIndex: AppG.tabIndex});
                                            onTabClicked();
                                        } else {
                                            const { routeName } = navigation.state;
                                            if (routeName != 'HomeScreen') {
                                                navigation.navigate('HomeScreen');
                                            }
                                        }
                                    }}
                                >
                                    <Text style={{fontFamily: appFont.family.bold, color: this.state.tabIndex == 1? appColors.white: appColors.fontBlack, fontSize: 16, fontWeight: 'bold'}}>レシピ</Text>
                                </TouchableOpacity>

                                {/*商品*/}
                                <TouchableOpacity
                                    style={[

                                        {
                                            width: _tabButtonSize.width,
                                            height: _tabButtonSize.height,
                                            margin: _tabButtonSize.margin,
                                            alignSelf: 'center',
                                            backgroundColor: this.state.tabIndex == 2? appColors.actionColor : appColors.white,
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }
                                    ]}
                                    onPress={() => {
                                        AppG.tabIndex = 2;
                                        const { routeName } = navigation.state;
                                        if (routeName == 'HomeScreen') {
                                            this.setState({tabIndex: AppG.tabIndex});
                                            onTabClicked();
                                        } else {
                                            const { routeName } = navigation.state;
                                            if (routeName != 'HomeScreen') {
                                                navigation.navigate('HomeScreen');
                                            }
                                        }
                                    }}
                                >
                                    <Text style={{fontFamily: appFont.family.bold, color: this.state.tabIndex == 2? appColors.white: appColors.fontBlack, fontSize: 16, fontWeight: 'bold'}}>商品</Text>
                                </TouchableOpacity>

                                {/*メーカー別*/}
                                <TouchableOpacity
                                    style={[

                                        {
                                            width: _tabButtonSize.width,
                                            height: _tabButtonSize.height,
                                            margin: _tabButtonSize.margin,
                                            alignSelf: 'center',
                                            backgroundColor: this.state.tabIndex == 3? appColors.actionColor : appColors.white,
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }
                                    ]}
                                    onPress={() => {
                                        AppG.tabIndex = 3;
                                        const { routeName } = navigation.state;
                                        if (routeName == 'HomeScreen') {
                                            this.setState({tabIndex: AppG.tabIndex});
                                            onTabClicked();
                                        } else {
                                            const { routeName } = navigation.state;
                                            if (routeName != 'HomeScreen') {
                                                navigation.navigate('HomeScreen');
                                            }
                                        }
                                    }}
                                >
                                    <Text style={{fontFamily: appFont.family.bold, color: this.state.tabIndex == 3? appColors.white: appColors.fontBlack, fontSize: 16, fontWeight: 'bold'}}>メーカー別</Text>
                                </TouchableOpacity>

                                {/*ランキング*/}
                                <TouchableOpacity
                                    style={[

                                        {
                                            width: _tabButtonSize.width,
                                            height: _tabButtonSize.height,
                                            margin: _tabButtonSize.margin,
                                            alignSelf: 'center',
                                            backgroundColor: this.state.tabIndex == 4? appColors.actionColor : appColors.white,
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }
                                    ]}
                                    onPress={() => {
                                        AppG.tabIndex = 4;
                                        const { routeName } = navigation.state;
                                        if (routeName == 'HomeScreen') {
                                            this.setState({tabIndex: AppG.tabIndex});
                                            onTabClicked();
                                        } else {
                                            const { routeName } = navigation.state;
                                            if (routeName != 'HomeScreen') {
                                                navigation.navigate('HomeScreen');
                                            }
                                        }
                                    }}
                                >
                                    <Text style={{fontFamily: appFont.family.bold, color: this.state.tabIndex == 4? appColors.white: appColors.fontBlack, fontSize: 16, fontWeight: 'bold'}}>ランキング</Text>
                                </TouchableOpacity>
                            </View>

                            <View style={{flex: 2}} />


                            {/*お気に入りボタン*/}
                            <View style={{flexDirection: "row", marginRight: 32}}>
                                <TouchableOpacity
                                    style={{
                                        height: 36,
                                        backgroundColor: appColors.transparent,
                                        alignItems: "flex-end",
                                        justifyContent: 'center',
                                    }}
                                    onPress={() => {
                                        const { routeName } = navigation.state;
                                        if (routeName != 'FollowScreen') {
                                            navigation.navigate('FollowScreen');
                                        }
                                    }}
                                >
                                    <View style={{
                                        flexDirection: "row",
                                    }}>
                                        <Image source={require('../../../resources/images/01.header/i_like_B.png')}
                                               style={[{
                                                   backgroundColor: appColors.transparent,
                                                   width: 20,
                                                   height: 20,
                                                   marginTop: 8,
                                                   marginBottom: 8,
                                                   marginLeft: 4,
                                               }]}
                                               resizeMode={'cover'}
                                        />
                                        <Text style={{
                                            fontFamily: appFont.family.bold,
                                            height: 36,
                                            color: appColors.fontBlack,
                                            fontWeight: 'bold',
                                            fontSize: appS.button.text,
                                            paddingTop: 6,
                                            paddingBottom: 10,
                                        }}>お気に入り</Text>
                                    </View>
                                </TouchableOpacity>
                            </View>
                        </View>

                    </View>

                    {/*キーワードエリア*/}
                    {!ValidateUtil.isEmptyArray(this.state.keywords) && (
                        <ScrollView
                            style={{height: AppG.window.height, backgroundColor: appColors.white}}
                        >
                            <View style={{margin: 8}}>
                                {this.state.keywords.map((keyword: Keyword, index: number) => {
                                    return (
                                        <View>

                                            <ListCellComponent
                                                key={`keyword_list_${index}`}
                                                navigation={navigation}
                                                title={keyword.value}
                                                borderWidth={0}
                                                onPress={() => {
                                                    // 検索履歴に追加
                                                    SearchDataDao.addNew(this.state.searchValue);
                                                    CustomHeaderFunc.selectKeyword(this, keyword, this.state.searchValue, this.state.onSearchSubmit);
                                                    // setTimeout(() => {
                                                    //     this.setState({searchValue: ''});
                                                    // }, 100);
                                                }}/>
                                            <View style={{
                                                backgroundColor: appColors.spacerGray,
                                                height: 1,
                                                width: AppG.window.width,
                                                marginTop: -30,
                                                marginBottom: 5,
                                            }}/>
                                        </View>
                                    )
                                })}
                            </View>
                        </ScrollView>
                    )}
                </View>
                {/*メニュー*/}
                <MenuComponent navigation={navigation} isShowMenu={this.state.isShowMenu} closeMenu={() => {this.setState({isShowMenu: false})}}/>
            </SafeAreaView>
        );
    }
}

