import moment from "moment";

class PickerUtil {

    /**
     * マスターを RNPickerSelect の Items の形に変換
     * @param masters
     */
    mastersToPickerItems = (masters: {id: number, name: string}[]): {label: string, value: any, key: number}[] => {
        let pickerItems: {label: string, value: any, key: number}[] = [];
        masters.forEach((master: {id: number, name: string}) => {
            pickerItems.push({label: master.name, value: master.id, key: master.id});
        });
        return pickerItems;
    };

    /**
     * 一番始めに RNPickerSelect の Items のを追加
     * @param addItem
     * @param items
     */
    addFirstMasterToPickerItem = (addItem: {label: string, value: any, key: number} , items: {label: string, value: any, key: number}[]): {label: string, value: any, key: number}[] => {
        let pickerItems: {label: string, value: any, key: number}[] = [addItem];
        return pickerItems.concat(items);
    }

    /**
     * 選択する西暦のマスター取得
     */
    getYyyyMaster = (): {label: string, value: any, key: string}[] => {
        const nowYyyy = parseInt(moment().format('YYYY'));
        let yyyy: {label: string, value: any, key: string}[] = [];
        for (let i = 1900; i <= nowYyyy; i++) {
            yyyy.push({label: i.toString(), value: i.toString(), key: i.toString()});
        }
        return yyyy;
    }

    /**
     * 選択する月のマスター取得
     */
    getMmMaster = (): {label: string, value: any, key: string}[] => {
        let mm: {label: string, value: any, key: string}[] = [];
        for (let i = 1; i <= 12; i++) {
            mm.push({label: i.toString(), value: i.toString(), key: i.toString()});
        }
        return mm;
    }

    /**
     * 選択する日のマスター取得
     * @param year  対象の西暦
     * @param month 対象の月
     */
    getDdMaster = (year: string, month: string): {label: string, value: any, key: string}[] => {
        let lastDate = moment(`${year}-${month}`, 'YYYY-MM').endOf('month').format("DD");
        let dd: {label: string, value: any, key: string}[] = [];
        for (let i = 1; i <= parseInt(lastDate); i++) {
            dd.push({label: i.toString(), value: i.toString(), key: i.toString()});
        }
        return dd;
    };

    /**
     * 都道府県のPickerItemを取得
     */
    getPrefCodeMaster = (): {label: string, value: any, key: string}[] => {
        return [
            {label:'北海道', value:'北海道', key:'1'},
            {label:'青森県', value:'青森県', key:'2'},
            {label:'岩手県', value:'岩手県', key:'3'},
            {label:'宮城県', value:'宮城県', key:'4'},
            {label:'秋田県', value:'秋田県', key:'5'},
            {label:'山形県', value:'山形県', key:'6'},
            {label:'福島県', value:'福島県', key:'7'},
            {label:'茨城県', value:'茨城県', key:'8'},
            {label:'栃木県', value:'栃木県', key:'9'},
            {label:'群馬県', value:'群馬県', key:'10'},
            {label:'埼玉県', value:'埼玉県', key:'11'},
            {label:'千葉県', value:'千葉県', key:'12'},
            {label:'東京都', value:'東京都', key:'13'},
            {label:'神奈川県', value:'神奈川県', key:'14'},
            {label:'新潟県', value:'新潟県', key:'15'},
            {label:'富山県', value:'富山県', key:'16'},
            {label:'石川県', value:'石川県', key:'17'},
            {label:'福井県', value:'福井県', key:'18'},
            {label:'山梨県', value:'山梨県', key:'19'},
            {label:'長野県', value:'長野県', key:'20'},
            {label:'岐阜県', value:'岐阜県', key:'21'},
            {label:'静岡県', value:'静岡県', key:'22'},
            {label:'愛知県', value:'愛知県', key:'23'},
            {label:'三重県', value:'三重県', key:'24'},
            {label:'滋賀県', value:'滋賀県', key:'25'},
            {label:'京都府', value:'京都府', key:'26'},
            {label:'大阪府', value:'大阪府', key:'27'},
            {label:'兵庫県', value:'兵庫県', key:'28'},
            {label:'奈良県', value:'奈良県', key:'29'},
            {label:'和歌山県', value:'和歌山県', key:'30'},
            {label:'鳥取県', value:'鳥取県', key:'31'},
            {label:'島根県', value:'島根県', key:'32'},
            {label:'岡山県', value:'岡山県', key:'33'},
            {label:'広島県', value:'広島県', key:'34'},
            {label:'山口県', value:'山口県', key:'35'},
            {label:'徳島県', value:'徳島県', key:'36'},
            {label:'香川県', value:'香川県', key:'37'},
            {label:'愛媛県', value:'愛媛県', key:'38'},
            {label:'高知県', value:'高知県', key:'39'},
            {label:'福岡県', value:'福岡県', key:'40'},
            {label:'佐賀県', value:'佐賀県', key:'41'},
            {label:'長崎県', value:'長崎県', key:'42'},
            {label:'熊本県', value:'熊本県', key:'43'},
            {label:'大分県', value:'大分県', key:'44'},
            {label:'宮崎県', value:'宮崎県', key:'45'},
            {label:'鹿児島県', value:'鹿児島県', key:'46'},
            {label:'沖縄県', value:'沖縄県', key:'47'},
        ]
    };

}

export default new PickerUtil();
