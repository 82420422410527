// import firebase from 'firebase';
import { initializeApp } from 'firebase/app';
// @ts-ignore
import {getEnvVars, log} from '../../../../environment';
// import Config from 'react-native-config'
import {getAuth} from 'firebase/auth';
import {initializeFirestore} from 'firebase/firestore';

export class FirebaseConfig {

    /**
     * 初期化済みフラグ
     * 外部クラスからアクセスしないこと
     */
    static isInitialized: Boolean = false;

    /**
     * Firebase 初期化
     */
    static initializeFirebaseConfig = () => {
        if (!FirebaseConfig.isInitialized) {

            // @ts-ignore
            const {REACT_APP_FIRE_STORE_API_KEY, REACT_APP_FIRE_STORE_AUTH_DOMAIN, REACT_APP_FIRE_STORE_DATA_BASE_URL, REACT_APP_FIRE_STORE_PROJECT_ID, REACT_APP_FIRE_STORE_STORAGE_BUCKET, REACT_APP_FIRE_STORE_MESSAGING_SENDER_ID, REACT_APP_FIRE_STORE_APP_ID, REACT_APP_FIRE_STORE_MEASUREMENT_ID,
            } = getEnvVars();
            // Firebase 初期化設定
            const config = {
                apiKey: `${REACT_APP_FIRE_STORE_API_KEY}`,
                authDomain: `${REACT_APP_FIRE_STORE_AUTH_DOMAIN}`,
                databaseURL: `${REACT_APP_FIRE_STORE_DATA_BASE_URL}`,
                projectId: `${REACT_APP_FIRE_STORE_PROJECT_ID}`,
                storageBucket: `${REACT_APP_FIRE_STORE_STORAGE_BUCKET}`,
                messagingSenderId: `${REACT_APP_FIRE_STORE_MESSAGING_SENDER_ID}`,
                appId: `${REACT_APP_FIRE_STORE_APP_ID}`,
                measurementId: `${REACT_APP_FIRE_STORE_MEASUREMENT_ID}`,
            };
            const app = initializeApp(config);
            const auth = getAuth(app);
            const db = initializeFirestore(app, {
                experimentalForceLongPolling: true,
            });

            FirebaseConfig.isInitialized = true;
        }
    };
}
