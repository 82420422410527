import React, {Component} from 'react';
import {Image, Linking, Platform, TouchableOpacity, View} from 'react-native';
import {getEnvVars, log} from "../../../../environment";
import LinkUtil from "../../util/LinkUtil";
import {appS} from "../../../resources/styles/style";
import {Keyword, KeywordApiFactory, NewsApiFactory} from "../../data/network/swagger-gen";
import AppG from "../../util/AppG";
import {AxiosResponse} from "axios";
import {TopicTitleComponent} from "../TopicTitleComponent";
import {SearchWordsComponent} from "../SearchWordsComponent";
import {SearchScreenModel} from "../../data/models/screen/SearchScreenModel";
import {SpacerComponent} from "../SpacerComponent";

interface Props {
    navigation: any,
}

interface State {
    popularKeywords: Keyword[], // 人気のキーワード
    popularProductKeywords: Keyword[], // 人気の商品キーワード
}

export class WideScreenAdComponent extends Component<Props, State> {

    /**
     * トピック毎のマージン
     */
    _topicMargin = appS.margins.betweenTopic;

    // @ts-ignore
    constructor(props) {
        super(props);
        this.state = {
            popularKeywords: [], // 人気のキーワード
            popularProductKeywords: [],
        };
    }

    componentDidMount() {
        // const { routeName } = this.props.navigation.state;
        // if (routeName == 'SearchResultScreen') {
        //     return;
        // }

        // 人気のキーワード
        KeywordApiFactory(AppG.getConfiguration(), AppG.getBasePath())
            .getPopularKeyword(
                20,
                'recipe'
            ).then((popularKeywordsRes: AxiosResponse<Keyword[]>) => {
            if (popularKeywordsRes != null && popularKeywordsRes.data != null) {
                this.setState({popularKeywords: popularKeywordsRes.data});
            }
        });
        KeywordApiFactory(AppG.getConfiguration(), AppG.getBasePath())
            .getPopularKeyword(
                20,
                'product'
            ).then((popularProductKeywordsRes: AxiosResponse<Keyword[]>) => {
            if (popularProductKeywordsRes != null && popularProductKeywordsRes.data != null) {
                this.setState({popularProductKeywords: popularProductKeywordsRes.data});
            }
        });

    }

    render() {

        if (Platform.OS != 'web') {
            return null;
        }

        // @ts-ignore
        const {REACT_APP_DOMAIN_URL} = getEnvVars();
        const { routeName } = this.props.navigation.state;

        const _width = appS.webSideContentsSize.width;

        return (
            <View style={{
                width: _width,
                alignItems: 'center',
                justifyContent: 'center',
            }}>
                <Image source={require('../../../resources/images/download_app_ad.png')}
                       style={[{
                           width: _width,
                           height: _width / 1.1,
                       }]}
                       resizeMode={'cover'}
                />
                <TouchableOpacity
                    onPress={() => {LinkUtil.openUrlInWebView('https://docs.google.com/forms/d/e/1FAIpQLSe2aj-zD5haEHVApSZCs4uveuJ3iXMmlJoV4T9fZ6xRSfmgSA/viewform', this.props.navigation)}}>
                    <Image source={require('../../../resources/images/recipo_ad.png')}
                           style={[{
                               width: _width,
                               height: _width / 2,
                               marginTop: 16,
                           }]}
                           resizeMode={'cover'}
                    />
                </TouchableOpacity>

                {/*<TouchableOpacity*/}
                {/*    onPress={() => {LinkUtil.openUrlInWebView('https://www.jfex.jp/ja-jp/lp/vis.html?utm_source=RB&utm_medium=banner&utm_campaign=1020', this.props.navigation)}}>*/}
                {/*    <Image source={require('../../resources/images/food_opportunity_week_202111.png')}*/}
                {/*           style={[{*/}
                {/*               width: _width,*/}
                {/*               height: _width / 2,*/}
                {/*               marginTop: 16,*/}
                {/*           }]}*/}
                {/*           resizeMode={'cover'}*/}
                {/*    />*/}
                {/*</TouchableOpacity>*/}

                <SpacerComponent height={this._topicMargin}/>
                <View>
                    <TopicTitleComponent
                        title={'レシピ人気キーワード'}
                        subTopic={true}
                        width={_width}
                        more={true}
                        onPress={() => {

                            let searchScreenModel: SearchScreenModel = new SearchScreenModel();
                            searchScreenModel.searchType = 'recipe';
                            searchScreenModel.screenNameFrom = routeName;
                            searchScreenModel.headerTitle = 'レシピ人気キーワード';
                            searchScreenModel.tabHidden = true;

                            if (routeName == 'SearchKeywordScreen' && Platform.OS == 'web') {
                                location.href = `${REACT_APP_DOMAIN_URL}/SearchKeywordScreen/?cookingTime&costFrom&costTo&headerTitle=レシピ人気キーワード&keyword&makerId&makerIds&menuCategoryId&menuCategoryName&productId&productLargeCategoryId&productLargeCategoryIds&productLargeCategoryName&productMediumCategoryId&productMediumCategoryName&productSortType&recipeCategoryIds&recipeRestaurantLargeFormatIds&recipeSortType&screenNameFrom=SearchKeywordScreen&searchType=recipe&searchValue&seasonIds&tabHidden=true`;
                            } else {
                                this.props.navigation.navigate('SearchKeywordScreen', searchScreenModel);
                            }
                        }} />
                    <SearchWordsComponent
                        showCount={9}
                        keywords={this.state.popularKeywords}
                        width={_width}
                        onPress={(keyword) => {
                            let searchScreenModel: SearchScreenModel = new SearchScreenModel();
                            searchScreenModel.headerTitle = `${keyword.value}`;
                            searchScreenModel.searchType = 'recipe';
                            searchScreenModel.keyword = `${keyword.value}`;
                            searchScreenModel.searchValue = `${keyword.value}`;
                            searchScreenModel.screenNameFrom = routeName;
                            searchScreenModel.tabHidden = true;
                            if (Platform.OS == 'web') {
                                if (routeName == 'SearchResultScreen') {
                                    location.href = `${REACT_APP_DOMAIN_URL}/SearchResultScreen/?cookingTime&costFrom&costTo&headerTitle=${keyword.value}&keyword=${keyword.value}&makerId&makerIds&menuCategoryId&menuCategoryName&productId&productLargeCategoryId&productLargeCategoryIds&productLargeCategoryName&productMediumCategoryId&productMediumCategoryName&productSortType&recipeCategoryIds&recipeRestaurantLargeFormatIds&recipeSortType&screenNameFrom=SearchResultScreen&searchType=recipe&searchValue=${keyword.value}&seasonIds&tabHidden=true`;
                                } else {
                                    this.props.navigation.navigate('SearchResultScreen', searchScreenModel);
                                }
                            } else {
                                this.props.navigation.push('SearchResultScreen', searchScreenModel);
                            }
                        }}/>
                    <SpacerComponent height={this._topicMargin}/>

                    <TopicTitleComponent
                        title={'商品人気キーワード'}
                        subTopic={true}
                        width={_width}
                        more={true}
                        onPress={() => {

                            let searchScreenModel: SearchScreenModel = new SearchScreenModel();
                            searchScreenModel.searchType = 'product';
                            searchScreenModel.screenNameFrom = routeName;
                            searchScreenModel.headerTitle = '商品人気キーワード';
                            searchScreenModel.tabHidden = true;

                            if (routeName == 'SearchKeywordScreen' && Platform.OS == 'web') {
                                location.href = `${REACT_APP_DOMAIN_URL}/SearchKeywordScreen/?cookingTime&costFrom&costTo&headerTitle=商品人気キーワード&keyword&makerId&makerIds&menuCategoryId&menuCategoryName&productId&productLargeCategoryId&productLargeCategoryIds&productLargeCategoryName&productMediumCategoryId&productMediumCategoryName&productSortType&recipeCategoryIds&recipeRestaurantLargeFormatIds&recipeSortType&screenNameFrom=SearchKeywordScreen&searchType=product&searchValue&seasonIds&tabHidden=true`;
                            } else {
                                this.props.navigation.navigate('SearchKeywordScreen', searchScreenModel);
                            }
                        }}
                    />
                    <SearchWordsComponent
                        showCount={9}
                        keywords={this.state.popularProductKeywords}
                        width={_width}
                        onPress={(keyword) => {

                            let searchScreenModel: SearchScreenModel = new SearchScreenModel();
                            searchScreenModel.headerTitle = `${keyword.value}`;
                            searchScreenModel.searchType = 'product';
                            searchScreenModel.keyword = `${keyword.value}`;
                            searchScreenModel.searchValue = `${keyword.value}`;
                            searchScreenModel.screenNameFrom = routeName;
                            searchScreenModel.tabHidden = true;
                            if (Platform.OS == 'web') {
                                if (routeName == 'SearchResultScreen') {
                                    location.href = `${REACT_APP_DOMAIN_URL}/SearchResultScreen/?cookingTime&costFrom&costTo&headerTitle=${keyword.value}&keyword=${keyword.value}&makerId&makerIds&menuCategoryId&menuCategoryName&productId&productLargeCategoryId&productLargeCategoryIds&productLargeCategoryName&productMediumCategoryId&productMediumCategoryName&productSortType&recipeCategoryIds&recipeRestaurantLargeFormatIds&recipeSortType&screenNameFrom=SearchResultScreen&searchType=product&searchValue=${keyword.value}&seasonIds&tabHidden=true`;
                                } else {
                                    this.props.navigation.navigate('SearchResultScreen', searchScreenModel);
                                }
                            } else {
                                this.props.navigation.push('SearchResultScreen', searchScreenModel);
                            }
                        }}/>
                    <SpacerComponent height={this._topicMargin}/>
                </View>
            </View>
        )

    }
}
