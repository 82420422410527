import {Platform} from "react-native";

// @ts-ignore
import {log} from '../../../environment';

/**
 * このアプリ専用のUtil
 */
class CsvUtil {

    /**
     * Csvを取得(Webのみ)
     * @param navigation
     * @param fileName  sample.csv
     * @param csvData   CSVデータ(\nで行変更, カンマでセル区切り)
     */
    createCsv = (navigation: any, fileName: string, csvData: string) => {
        if (Platform.OS == 'web') {
            //ダウンロードするCSVファイル名を指定する
            const _filename = fileName;
            const url = encodeURI(csvData);

            // その他WEBブラウザ
            // ダウンロード用にリンクを作成する
            const ele = document.createElement('a'); // a要素を作成する

            ele.setAttribute('href', url);  // a要素に出力データを追加
            ele.setAttribute('download', _filename); // a要素に出力情報を追加。
            ele.style.visibility = 'hidden';
            document.body.appendChild(ele);
            ele.click(); // HTMLドキュメントに追加したa要素を実行(clickイベント発火)
            document.body.removeChild(ele);
        }
    }

}

export default new CsvUtil();
