import React, {Component} from 'react';
import {Text, TouchableOpacity, View} from 'react-native';
import {appColors, appFont, appS} from "../../resources/styles/style";
import AppG from "../util/AppG";
import ScreenSizeUtil from "../util/ScreenSizeUtil";
import {IconWithBadgeComponent} from "./IconWithBadgeComponent";

interface Props {
    tabs : {
        index: number,    // 0 〜
        title: string,
        onPress: () => void,
        color: string,
        unselectColor: string,
        backgroundColor: string,
        unselectBackgroundColor: string,
        badge: number | null,
    }[],
    subTitles: string[] | null,
    focusTabNumber: number, // フォーカスするtab(初期値)
    unselectBorder: boolean,
    button: boolean,
}

interface State {
    focusTabNumber: number
}

export class TabBorderComponent extends Component<Props, State> {

    // Propsのデフォルト値
    static defaultProps = {
        unselectBorder: false,
        button: false,
        subTitles: null,
    };

    constructor(props: any) {
        super(props);
        this.state = {
            focusTabNumber: 0
        };
    }

    _tabs: {
        index: number,    // 0 〜
        title: string,
        onPress: () => void,
        color: string,
        unselectColor: string,
        backgroundColor: string,
        unselectBackgroundColor: string,
        badge: number | null,
    }[] = [];

    componentDidMount() {
        this.setState({focusTabNumber: this.props.focusTabNumber});
        this._tabs = this.props.tabs;
    }

    redrawView = (_focusTabNumber: number) => {
        this.setState({focusTabNumber: _focusTabNumber});
    }

    _drawBorder = (tab: { index: number; }, numOfTab: number, color: string) => {
        let _width = AppG.window.width;
        if (ScreenSizeUtil.isWebSize()) {
            _width = appS.webMainContentsSize.width;
        }
        let widthStyle: any = {width: _width / this._tabs.length - 1};

        if (tab.index == this.state.focusTabNumber) {
            return (
                <View
                    key={`tab_button_button_text_under_line_${tab.index}`}
                    style={[
                        {
                            height: 1,
                            borderColor: color,
                            borderWidth: 1,
                        },
                        widthStyle
                    ]}/>
            );
        } else if (this.props.unselectBorder) {
            return (
                <View
                    key={`tab_button_button_text_under_line_${tab.index}`}
                    style={[
                        {
                            height: 1,
                            borderColor: color,
                            borderWidth: 1,
                        },
                        widthStyle
                    ]}/>
            );
        } else {
            return null;
        }
    };

    /**
     *
     * @param index
     * @param selectColor
     * @param unselectColor
     * @private
     */
    _getTabColor = (index: number, selectColor: string, unselectColor: string) => {
        if (this.state.focusTabNumber == index) {
            return selectColor;
        } else {
            return unselectColor;
        }
    };

    /**
     *
     * @param index
     * @param selectColor
     * @param unselectColor
     */
    _getTextColor = (index: number, selectColor: string, unselectColor: string) => {
        if (this.state.focusTabNumber == index) {
            return selectColor;
        } else {
            return unselectColor;
        }
    };

    _renderTabArea = () => {
        const _tabHeight = appS.header.tabHeight;
        let _width = AppG.window.width;
        if (ScreenSizeUtil.isWebSize()) {
            _width = appS.webMainContentsSize.width;
        }
        let widthStyle: any = {width: _width / this._tabs.length - 1};

        return (
            <View style={{height: _tabHeight + 8, marginTop: 4,  flexDirection: 'row', backgroundColor: appColors.white}}>
                {this._tabs.map((map, index) => (
                    <View key={`tab_button_view_${map.index}`} style={{flexDirection: 'row'}}>
                        <TouchableOpacity
                            key={`tab_button_button_${map.index}`}
                            style={[
                                {
                                    height: _tabHeight,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                },
                                widthStyle
                            ]}
                            onPress={() => {
                                setTimeout(() => {
                                    map.onPress();
                                }, 10);
                            }}
                        >
                            {(map.badge == null || map.badge == 0) && (
                                <View style={{
                                    flexDirection: "column",
                                    backgroundColor: this._getTabColor(map.index, map.backgroundColor, map.unselectBackgroundColor),
                                    borderRadius: 50,
                                    borderWidth: 1,
                                    borderColor: appColors.transparent,
                                    height: _tabHeight,
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                    <Text key={`tab_button_button_text_${map.index}`}
                                          style={{
                                              fontFamily: appFont.family.bold,
                                              marginTop: this.props.subTitles == null? 0 : -16,
                                              padding: 4,
                                              fontWeight: 'bold',
                                              color: this._getTextColor(map.index, map.color, map.unselectColor),
                                              fontSize: this.props.button? appFont.size.tabButton : appFont.size.tab}}>{map.title}</Text>
                                    {this.props.subTitles != null && (
                                        <View key={`tab_button_button_text_sub_view_${map.index}`}
                                            style={{flexDirection: 'row'}}>
                                            <Text key={`tab_button_button_text_sub_${map.index}`}
                                                  style={{
                                                      fontFamily: appFont.family.bold,
                                                      marginTop: -8,
                                                      fontWeight: 'bold',
                                                      color: appColors.fontBlack,
                                                      fontSize: this.props.button? appFont.size.tabButton : appFont.size.tab}}>{this.props.subTitles[index]}</Text>
                                            <Text key={`tab_button_button_text_sub_2_${map.index}`}
                                                  style={{
                                                      fontFamily: appFont.family.bold,
                                                      marginTop: -4,
                                                      marginLeft: 4,
                                                      fontWeight: 'bold',
                                                      color: appColors.fontBlack,
                                                      fontSize: 12}}>件</Text>

                                        </View>

                                    )}
                                </View>
                            )}
                            {(map.badge != null && map.badge != 0) && (
                                <View style={{
                                    flexDirection: "row",
                                    backgroundColor: this._getTabColor(map.index, map.backgroundColor, map.unselectBackgroundColor),
                                    borderRadius: 50,
                                    borderWidth: 1,
                                    borderColor: appColors.transparent,
                                    height: _tabHeight,
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                    <Text key={`tab_button_button_text_${map.index}`}
                                          style={{
                                              fontFamily: appFont.family.default,
                                              fontWeight: this.state.focusTabNumber == map.index? 'bold': 'normal',
                                              color: this._getTextColor(map.index, map.color, map.unselectColor),
                                              fontSize: this.props.button? appFont.size.tabButton : appFont.size.tab,
                                          }}>{`${map.title}`}</Text>
                                    <View style={{marginLeft: 0, marginTop: 8}}>
                                        <IconWithBadgeComponent
                                            iconSize={12}
                                            isShow={true}
                                            isWrittenNumber={false}
                                            badgeCount={2}/>
                                    </View>
                                </View>
                            )}
                            {this._drawBorder(map, this._tabs.length, this._getTextColor(map.index, map.color, map.unselectColor))}
                        </TouchableOpacity>
                    </View>
                ))}
            </View>
        );
    };

    render() {
        return this._renderTabArea();
    }
}
