import React, {Component} from 'react';
import {Image, TouchableOpacity, View} from 'react-native';
import {appS} from "../../resources/styles/style";
import {SearchScreenModel} from "../data/models/screen/SearchScreenModel";
import StrUtil from "../util/StrUtil";
import ScreenSizeUtil from "../util/ScreenSizeUtil";
import AppG from "../util/AppG";
import ExpoUtil from "../util/ExpoUtil";

interface Props {
    navigation: any,
    onPress: ((id: string, name: string) => void) | null,
}

interface State { }

export class ProductCategoryListComponent extends Component<Props, State> {

    // Propsのデフォルト値
    static defaultProps = {
        onPress: null,
    };

    // メニューカテゴリから探すのデータ
    _productCategoryIdsRow1 = [{id: 26, name: '水産', image: require('../../resources/images/04.item_product/product_category_26.png')}, {id: 27, name: '畜産', image: require('../../resources/images/04.item_product/product_category_27.png')}, {id: 28, name: '農産', image: require('../../resources/images/04.item_product/product_category_28.png')}];   // 画像を表示するレシピカテゴリ一覧1行目
    _productCategoryIdsRow2 = [{id: 29, name: '調味料、乾物', image: require('../../resources/images/04.item_product/product_category_29.png')}, {id: 30, name: '加工品', image: require('../../resources/images/04.item_product/product_category_30.png')}, {id: 31, name: '飲料・原料', image: require('../../resources/images/04.item_product/product_category_31.png')}];   // 画像を表示するレシピカテゴリ一覧2行目
    _productCategoryIdsRow3 = [{id: 32, name: '製菓・製パン', image: require('../../resources/images/04.item_product/product_category_32.png')}, {id: 33, name: '介護食品', image: require('../../resources/images/04.item_product/product_category_33.png')}, {id: 25, name: 'その他', image: require('../../resources/images/04.item_product/product_category_25.png')}];   // 画像を表示するレシピカテゴリ一覧3行目

    render() {
        const {navigation} = this.props;

        let _width = AppG.window.width - appS.margins.side * 2;

        const _rowMargin = appS.margins.side / 3;

        let _cellSizeWidth = (AppG.window.width - appS.margins.side) / 3;
        let _areaHeight = _cellSizeWidth / 1.7 * 3;

        let _style: any = {
            width: AppG.window.width,
            height: _areaHeight,
        };
        if (ScreenSizeUtil.isWebSize()) {
            _width = appS.webMainContentsSize.width -  - appS.margins.side * 2;
            _cellSizeWidth = (appS.webMainContentsSize.width - appS.margins.side) / 3;
            _style = {
                flex: 1,
                width: appS.webMainContentsSize.width,
                height: _areaHeight / 2,
            };
        }

        // 未会員
        if (AppG.user == null) {
            return (
                <TouchableOpacity
                    style={_style}
                    onPress={() => {
                        this.props.navigation.navigate('SignUpScreen');
                    }}
                >
                    <Image
                        source={require('../../resources/images/blur/blur_product_category.jpg')}
                        style={{
                            alignSelf: 'center',
                            width: _width,
                            height: _width * 0.58,
                        }}
                           />
                </TouchableOpacity>
            )
        }



        /**
         * メニューカテゴリから探すのセルを作成
         * @param cellWidth
         * @param productCategory: {id: number, name: string, image: any} 画像を表示するカテゴリを渡す
         */
        const _renderProductSearchCell = (cellWidth: number, productCategory: {id: number, name: string, image: any}) => {
            const _width = cellWidth;
            const _height = cellWidth / 1.7;

            return (
                <View>
                    <TouchableOpacity
                        onPress={() => {
                            if (this.props.onPress == null) {
                                // 画面遷移前にリロード対象にセットする
                                ExpoUtil.setShouldReloadAppTab('self');
                                const { routeName } = navigation.state;
                                let searchScreenModel: SearchScreenModel = new SearchScreenModel();
                                searchScreenModel.productLargeCategoryIds=`|${productCategory.id}|`;
                                searchScreenModel.menuCategoryName = `${StrUtil.removeNewLine(productCategory.name)}`;
                                searchScreenModel.headerTitle = `${StrUtil.removeNewLine(productCategory.name)}`;
                                searchScreenModel.screenNameFrom = routeName;
                                searchScreenModel.searchType = 'product';
                                searchScreenModel.tabHidden = true;
                                this.props.navigation.navigate('SearchResultScreen', searchScreenModel);
                            } else {
                                this.props.onPress(`${productCategory.id}`, `${StrUtil.removeNewLine(productCategory.name)}`);
                            }
                        }}
                    >
                        <Image
                            source={productCategory.image}
                            style={[{
                                backgroundColor: 'white',
                                width: _width - appS.margins.side,
                                height: _height - appS.margins.side,
                                margin: appS.margins.side / 2,
                            }]}
                            resizeMode={'cover'}
                            accessibilityLabel={productCategory.name}
                        />
                    </TouchableOpacity>
                </View>
            );
        };


        // レシピセルを並べる
        return (
            <View style={_style}>
                <View style={{flexDirection: 'row', marginLeft: _rowMargin, marginRight: _rowMargin}}>
                    {this._productCategoryIdsRow1.map((productCategory: {id: number, name: string, image: any}, index: number) => {
                        return (<View key={`productSearchCell_${index}`}>{_renderProductSearchCell(_cellSizeWidth, productCategory)}</View>);
                    })}
                </View>
                <View style={{flexDirection: 'row', marginLeft: _rowMargin, marginRight: _rowMargin}}>
                    {this._productCategoryIdsRow2.map((productCategory: {id: number, name: string, image: any}, index: number) => {
                        return (<View key={`productSearchCell_${index}`}>{_renderProductSearchCell(_cellSizeWidth, productCategory)}</View>);
                    })}
                </View>
                <View style={{flexDirection: 'row', marginLeft: _rowMargin, marginRight: _rowMargin}}>
                    {this._productCategoryIdsRow3.map((productCategory: {id: number, name: string, image: any}, index: number) => {
                        return (<View key={`productSearchCell_${index}`}>{_renderProductSearchCell(_cellSizeWidth, productCategory)}</View>);
                    })}
                </View>
            </View>
        );
    }
}
