import {Share} from "react-native";
import AlertUtil from "./AlertUtil";
import I18n from "../../resources/language/i18n";

class ShareUtil {

    /**
     * シェアボタンを押したときの挙動
     */
    onShare = async (message: string) => {
        try {
            const result = await Share.share({
                message: message,
            });
            if (result.action === Share.sharedAction) {
                if (result.activityType) {
                    // shared with activity type of result.activityType
                } else {
                    // shared
                }
            } else if (result.action === Share.dismissedAction) {
                // dismissed
            }
        } catch (error) {
            AlertUtil.alert(`${I18n.t('error')}`, message);
        }
    }
}

export default new ShareUtil();
