// import firebase from 'firebase';
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword , updatePassword, sendEmailVerification, sendPasswordResetEmail, signOut } from "firebase/auth";
import { FirebaseConfig } from './FirebaseConfig';
import AlertUtil from "../AlertUtil";
// @ts-ignore
import {log} from '../../../../environment';
import I18n from "../../../resources/language/i18n";
import AppGlobalDataUtil from "../AppG";
import LoginDataDao from "../../data/dao/local/LoginDataDao";
import {Platform} from "react-native";
import LoginUtil from "../LoginUtil";
import ExpoUtil from "../ExpoUtil";

// firebase の初期化をしてなかったら初期化
FirebaseConfig.initializeFirebaseConfig();

export class AuthUtil {

    /**
     * サインインしているかどうかの判定(ログインしてたらユーザー取得)
     */
    static getCurrentUser = () => {
        const auth = getAuth();
        if (auth.currentUser !== null && auth.currentUser!.emailVerified) {
            return auth.currentUser;
        }
        return null;
    }

    /**
     * メール&パスワードログイン
     * @param email メルアド
     * @param password  パスワード
     * @param callback 登録成功時に実行するFunc
     * @param errorCallback 失敗時に実行するFunc
     */
    static login(email: string, password: string, callback: () => void, errorCallback: () => void) {
        const auth = getAuth();
        signInWithEmailAndPassword(auth, email, password)
            .then(() => {
                if (callback != null) {
                    if (this.getCurrentUser() == null) {
                        // 通信エラー
                        AlertUtil.alert(`認証エラー`, `入力されたメールに送信されている承認メールをご確認ください`);
                        log.debug(`---------------Network Error (Login.login)--------------------`);
                        log.debug(`firebase user is null`);
                        errorCallback();
                        return;
                    }

                    this.getCurrentUser()?.getIdToken().then((firebaseToken: string) => {
                        log.debug(`-------------------- Firebase Token -----------------`);
                        log.debug(firebaseToken);

                        if (firebaseToken != null) {
                            // アクセストークンをグローバル変数にセット
                            AppGlobalDataUtil.apiKey = firebaseToken;
                            callback();
                        } else {
                            // 通信エラー
                            AlertUtil.alert(`${I18n.t('errorNetworkTitle')}`, `${I18n.t('errorNetwork')}`);
                            log.debug(`---------------Network Error (Login.login)--------------------`);
                            log.debug(`firebase token is null`);
                        }
                    })
                        .catch(e => {
                            // 通信エラー
                            AlertUtil.alert(`${I18n.t('errorNetworkTitle')}`, `${I18n.t('errorNetwork')}`);
                            log.debug(`---------------Network Error (Login.login2)--------------------`);
                            log.debug(`firebase token is null`);
                        });
                }
            })
            .catch(() => {
                // mailのバリデーションも自動でやってくれる
                if (errorCallback != null) {
                    errorCallback();
                }
            });
    };

    /**
     * ユーザー登録
     * @param email メルアド
     * @param password  パスワード
     * @param confirmPassword 確認用パスワード
     * @param callback 登録成功時に実行するFunc
     * @param errorCallback
     */
    static signUp(email: string, password: string, confirmPassword: string, callback: () => void, errorCallback: () => void) {
        if (password != confirmPassword) {
            AlertUtil.alert(`${I18n.t('error')}`, "確認用パスワードが違います。再度入力してください。" + password + '/' + confirmPassword);
            return;
        }

        const auth = getAuth();

        // firebase に認証前のアカウント作成
        createUserWithEmailAndPassword(auth, email, password)
            .then(user => {
                if (user) {
                    if (callback != null) {
                        callback();
                    }
                } else {
                    // 登録済み
                    AlertUtil.alert(`${I18n.t('error')}`, `${I18n.t('errorDuplication')}`);
                    errorCallback();
                    log.debug(`---------------Network Error (Login.signUp.fb1)--------------------`);
                }
            })
            .catch(e => {
                if (e.code == 'auth/email-already-in-use') {
                    // 登録済み
                    AlertUtil.alert(`${I18n.t('error')}`, `${I18n.t('errorDuplication')}`);
                    log.debug(`---------------Network Error (Login.signUp.fb3)--------------------`);
                } else {
                    // 通信エラー
                    AlertUtil.alert(`${I18n.t('errorNetworkTitle')}`, `${I18n.t('errorNetwork')}`);
                    log.debug(`---------------Network Error (Login.signUp.fb2)--------------------`);
                    log.debug(e);
                }
                errorCallback();
            })
    };

    /**
     * 認証メールの送信
     * まだ認証して無くて、currentUserがあるときだけ送信する
     */
    static sendVerificationMail = () => {
        const auth = getAuth();
        if (auth.currentUser !== null && !auth.currentUser!.emailVerified) {
            sendEmailVerification(auth.currentUser!).then(() => {});
        }
    }

    /**
     * パスワード変更
     * @param nowPassword   現在のパスワード
     * @param newPassword   新しいパスワード
     * @param callback
     */
    static changePassword = (nowPassword: string, newPassword: string, callback: () => void, failCallBack: () => void) => {
        LoginDataDao.get().then(loginData => {
            if (nowPassword != loginData?.password) {
                failCallBack();
                AlertUtil.alert(`${I18n.t('error')}`, "現在のパスワードが違います。再度入力してください。");
                return;
            }

            const auth = getAuth();

            if (auth.currentUser !== null && auth.currentUser!.emailVerified) {
                updatePassword(auth.currentUser!, newPassword).then(function() {
                    // Update successful.
                    if (callback != null) {
                        callback();
                    }
                }).catch(function(error) {
                    if (failCallBack != null) {
                        failCallBack();
                    }
                    // 通信エラー
                    AlertUtil.alert(`${I18n.t('errorNetworkTitle')}`, `${I18n.t('errorNetwork')}`);
                    log.debug(`---------------Network Error (Login.signUp.changePassword)--------------------`);
                    log.debug(error);
                });
            }
        });
    }


    /**
     * パスワードリセットメールの送信
     * @param email 送信先メールアドレス
     */
    static resetPassword = (email: string, callback: () => void, failCallBack: () => void) => {
        const auth = getAuth();

        // パスワード変更メールを送信
        sendPasswordResetEmail(auth, email).then(() => {
            if (callback != null) {
                callback();
            }
        }).catch(() => {
            if (failCallBack != null) {
                failCallBack();
            }
            AlertUtil.alert(`${I18n.t('error')}`, `${I18n.t('errorNetwork')}`);
        });
    }



// Facebookログイン
// export const FBLogin = async () => {
//   const { type, token } =
//     await Expo.Facebook.logInWithReadPermissionsAsync('********',
//       { permissions: ['public_profile']
//     })
//   if (type === 'success') {
//     // Build Firebase credential with the Facebook access token.
//     const credential = firebase.auth.FacebookAuthProvider.credential(token)
//     // Sign in with credential from the Facebook user.
//     firebase
//       .auth().signInAndRetrieveDataWithCredential(credential)
//       .then(user => {
//         log.debug('connected to firebase')
//       })
//       .catch(error => {
//         console.error(error)
//         // Handle Errors here.
//       })
//   }
// }

    /**
     * ログアウト処理
     */
    static logout = (callback: () => void) => {
        const auth = getAuth();
        signOut(auth)
            .then(() => {
                log.debug('logout' + callback);
                if (callback != null) {
                    callback();
                }
            })
            .catch(error => {
                AlertUtil.alert('エラー', error.toString())
            });
    };

    /**
     * 自分の uid を取得 ログインしてないなら {} を返す
     */
    static getUid = () => {
        const auth = getAuth();
        return (auth.currentUser || {}).uid;
    };
}
