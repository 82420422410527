import React, {Component} from 'react';
import {Image, TouchableOpacity, View} from 'react-native';
import {appColors, appFont} from "../../resources/styles/style";
import ScreenSizeUtil from "../util/ScreenSizeUtil";
import AppG from "../util/AppG";
import {SpacerComponent} from "./SpacerComponent";

interface Props {
    imageSource: any,
    isShowModal: boolean,
    onClosePress: () => void,
    marginTop: number,
    width: number,
    height: number,
}

interface State { }

export class ImageModalComponent extends Component<Props, State> {

    /**
     * ボタン無しModal
     */
    _renderModal = () => {
        const _modal = () => {
            let _width = ScreenSizeUtil.isWebSize()? AppG.window.width *2 / 5 : AppG.window.width *4 / 5;

            return (
                <View style={{
                    height: 150,
                    width: _width,
                    backgroundColor: appColors.transparent,
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    <SpacerComponent height={40}/>
                    <View style={{alignItems: 'center'}}>
                        <Image source={this.props.imageSource}
                               style={[{
                                   marginTop: this.props.marginTop,
                                   width: this.props.width,
                                   height: this.props.height,
                               }]}
                               resizeMode={'cover'}
                        />
                    </View>
                    <SpacerComponent height={40}/>
                </View>
            );
        }

        if (this.props.isShowModal) {
            return (
                <View
                    style={{
                        zIndex: 101,
                        position: 'absolute',
                        height: AppG.window.height,
                        width: AppG.window.width
                    }}
                >
                    <TouchableOpacity
                        style={{
                            zIndex: 102,
                            position: 'absolute',
                            backgroundColor: appColors.opacityGray,
                            height: AppG.window.height + 60,
                            width: AppG.window.width
                        }}
                        onPress={() => {
                            this.props.onClosePress();
                        }}
                    />
                    <View style={{
                        position: 'absolute',
                        zIndex: 103,
                        padding: 32,
                        width: AppG.window.width * 3 / 5,
                        top: AppG.window.height / 2 - 230,
                        left: AppG.window.width / 5,
                        backgroundColor: appColors.transparent,
                        alignItems: 'center',
                    }}>
                        {_modal()}
                    </View>
                </View>
            );
        }

        return null;

    }

    render() {
        return this._renderModal();
    }
}
