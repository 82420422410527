import React, {Component} from 'react';
import {Image, Platform, Text, TouchableOpacity, View} from 'react-native';
import {appColors, appFont, appS} from "../../resources/styles/style";
import AppG from "../util/AppG";
import ScreenSizeUtil from "../util/ScreenSizeUtil";
import ValidateUtil from "../util/ValidateUtil";
import {SampleRequestScreenModel} from "../data/models/screen/SampleRequestScreenModel";
import AlertUtil from "../util/AlertUtil";
import {SpacerComponent} from "./SpacerComponent";
import LinkUtil from "../util/LinkUtil";

interface Props {
    navigation: any,
    noButton: boolean,
}

interface State { }

export class NothingCartImageComponent extends Component<Props, State> {

    // Propsのデフォルト値
    static defaultProps = {
        noButton: false,
    };

    render() {
        const { routeName } = this.props.navigation.state;
        let _width = AppG.window.width;
        if (ScreenSizeUtil.isWebSize()) {
            _width = appS.webMainContentsSize.width;
        }
        _width = (_width - appS.margins.side * 2);
        if (ScreenSizeUtil.isWebSize()) {
            _width = _width / 2;
        }

        const noUserImage1 = require('../../resources/images/member_sample_basket_01.jpg');
        const noUserImage2 = require('../../resources/images/member_sample_basket_02.jpg');
        const noUserImage1Height = _width*1.22;
        const noUserImage2Height = _width*0.363;

        return (
            <View style={{alignItems: "center", backgroundColor: appS.noUserBackground}}>
                <Image source={ noUserImage1 }
                       style={[{
                           backgroundColor: 'white',
                           width: _width,
                           height: noUserImage1Height,
                       }]}
                       resizeMode={'stretch'}
                />
                <TouchableOpacity
                    onPress={() => {
                        this.props.navigation.navigate('HomeScreen');
                    }}
                >
                    <Image source={ noUserImage2 }
                           style={[{
                               backgroundColor: 'white',
                               width: _width,
                               height: noUserImage2Height,
                           }]}
                           resizeMode={'stretch'}
                    />
                </TouchableOpacity>

            </View>
        );
    }
}
