import React from 'react'
import {
    Dimensions,
    KeyboardAvoidingView,
    Platform,
    SafeAreaView,
    Text,
    TextInput,
    TouchableOpacity,
    View
} from 'react-native'
import {appColors, appFont, appS} from '../../resources/styles/style'
import {BaseScreen} from "./BaseScreen";
import LoginUtil from "../util/LoginUtil";
// @ts-ignore
import isEmpty from 'validator/lib/isEmpty';
// @ts-ignore
import isEmail from 'validator/lib/isEmail';
// @ts-ignore
import isMultibyte from 'validator/lib/isMultibyte';
import {SpacerComponent} from "../components/SpacerComponent";
import I18n from '../../resources/language/i18n';
import {IndicatorComponent} from "../components/IndicatorComponent";
// @ts-ignore
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scrollview';
// @ts-ignore
import {getEnvVars, log} from '../../../environment';
import ValidateUtil from "../util/ValidateUtil";
import {ValidationErrorMessageComponent} from "../components/ValidattionErrorMessageComponent";
import {CustomHeaderComponentSmall} from "../components/small/CustomHeaderComponentSmall";
import {TopicTitleComponent} from "../components/TopicTitleComponent";
import ExpoUtil from "../util/ExpoUtil";
import AppG from "../util/AppG";
import ScreenSizeUtil from "../util/ScreenSizeUtil";
import {CustomHeaderComponentWide} from "../components/wide/CustomHeaderComponentWide";
import {ModalComponent} from "../components/ModalComponent";
import AnalyticsUtil, {AnalyticsEventName, DOpenEventName} from "../util/firebase/AnalyticsUtil";
import {LoadingFullPageComponent} from "../components/LoadingFullPageComponent";
import {BorderComponent} from "../components/BorderComponent";
import {InquiryApiFactory, Maker, MakerApiFactory} from "../data/network/swagger-gen";
import {AxiosResponse} from "axios";
import {ReportScreenModel} from "../data/models/screen/ReportScreenModel";
import UrlUtil from "../util/UrlUtil";
import LinkUtil from "../util/LinkUtil";

interface Props {
    navigation: any, // ナビゲーション用
}

interface State {
    resizeWindow: boolean,  // 値に意味はなし。windowサイズの拡大縮小をrenderに送るためのもの
    isLoaded: boolean,
    isSending: boolean,

    // コンテンツ
    isShowSentModal: boolean,
    maker: Maker | null,

    //-------------- Input
    // 報告
    report: {
        text: string,
        errorText: string,
        isError: boolean,
    },

}

export class ReportScreen extends BaseScreen<Props, State> {
    _resizeTimer = setInterval(() => {
        // window 拡大縮小を反映させる
        AppG.window = Dimensions.get('window');
        if (AppG.window.width != AppG.windowBefore.width || AppG.window.height != AppG.windowBefore.height) {
            this.setState({'resizeWindow': true});
            AppG.windowBefore = Dimensions.get('window');
        }
        LoginUtil.setRefreshedScreenFlg(false);
    }, 100);

    _flameSize = appS.flame.size;    // 左右の margin
    _marginSize = ScreenSizeUtil.isWebSize()? 0 : appS.margin.size;

    _param = new ReportScreenModel();

    _browserBackListener = (e: any) => {

    };

    constructor(props: any) {
        super(props);
        this.state = {
            resizeWindow: true,  // 値に意味はなし。windowサイズの拡大縮小をrenderに送るためのもの
            isLoaded: false,
            isSending: false,
            isShowSentModal: false,
            maker: null,
            report: {
                text: '',
                errorText: '',
                isError: false,
            },
        };
    }

    /**
     * 画面開いたときのアナリティクス送信
     */
    _sendPvAnalytics = () => {
        AnalyticsUtil.sendAnalytics(
            DOpenEventName.openPage,
            AnalyticsEventName.openPage,  // アナリティクスのイベント名
            'ReportScreen',
            null,
            null,
            null,
            null,
        );
    }

    componentDidMount() {
        if (Platform.OS == 'web') {
            window.addEventListener('popstate', this._browserBackListener);
        }

        // Web 戻るでも呼ばれる
        if (Platform.OS == 'web') {
            // Analytics
            this._sendPvAnalytics();
        }

        // URL Scheme で外部リンクから起動したときの処理
        LoginUtil.setDefaultListener(
            this.props.navigation,
            () => {
                // Webはここにこない
                if (Platform.OS != 'web') {
                    // Analytics
                    this._sendPvAnalytics();
                }

                ExpoUtil.doReloadAppTab(() => {

                });
            });

        this._param = this._getParams();

        MakerApiFactory(AppG.getConfiguration(), AppG.getBasePath())
            .getMaker(this._param.makerId!, 2).then((makerRes: AxiosResponse<Maker>) => {
            if (makerRes != null && makerRes.data != null) {
                this.setState({'maker': makerRes.data});
            }
            this.setState({isLoaded: true});
        });
    }

    componentWillUnmount () {
        clearTimeout(this._resizeTimer);
        if (Platform.OS === 'web') {
            window.removeEventListener('popstate', this._browserBackListener);
        }
        LoginUtil.removeDefaultListener(this.props.navigation);
    };

    componentDidUpdate () {
        // レンダー完了時に呼ばれる
    }

    /**
     * パラムの取得
     * @private
     */
    _getParams = () => {
        let reportScreenModel: ReportScreenModel = UrlUtil.getParamModel(new ReportScreenModel(), this.props.navigation);
        if (ValidateUtil.isEmptyExact(reportScreenModel.makerId)) {
            reportScreenModel.makerId = JSON.stringify(this.props.navigation.getParam('makerId', ''));
        }
        return reportScreenModel;
    };

    /**
     * ログイン処理
     * @param text
     * @private
     */
    _send = (text: string) => {
        let hasError: boolean = false;
        // Mail
        if(ValidateUtil.isEmptyExact(text)) {
            this.setState({report: {text: this.state.report.text, errorText: `${I18n.t('pleasEnterAnItem', {item: `${I18n.t('text')}`})}`, isError: true}});
            hasError = true;
        }

        if (hasError) {
            // エラー
            return;
        }

        this.setState({isSending: true});
        const successFunc = () => {
            this.setState({'isShowSentModal': true});
            this.setState({isSending: false});
        };

        // お問い合わせ
        InquiryApiFactory(AppG.getConfiguration(), AppG.getBasePath())
            .inquiry('report', this.state.report.text, parseInt(this._param.makerId!))
            .then((res: AxiosResponse<void>) => {
                successFunc();
            });
    };

    _renderMain = () => {
        if (this.state.maker == null) {
            return null;
        }

        let _width = AppG.window.width;
        if (ScreenSizeUtil.isWebSize()) {
            _width = appS.webMainContentsSize.width;
        }
        _width = _width - appS.margins.side * 2;

        return (
            <View style={[{flex: 1, backgroundColor: appColors.backBaseColor}]}>
                <KeyboardAvoidingView
                    style={[{
                        flex: 1,
                        flexDirection: 'column',
                    }]}
                    behavior="position"
                    contentContainerStyle={{ flex: 1 }}
                    keyboardVerticalOffset={Platform.OS == 'android'? -180 : -50}
                >
                    <View style={[{flex: 1, flexDirection: 'column'}]}>
                        {ScreenSizeUtil.isWebSize() && (
                            <View style={{alignSelf: 'center', marginTop: 8}}>
                                <Text style={{fontSize: 24, marginTop: 24, fontFamily: appFont.family.bold, textAlign: 'center', color: appColors.black, fontWeight: "bold"}} numberOfLines={1}>お問い合わせ</Text>
                            </View>
                        )}

                        <View style={[{}]}>
                            {!ScreenSizeUtil.isWebSize() && (<SpacerComponent height={appS.header.bottomMargin} />)}
                            <TopicTitleComponent title={'メーカー名'}/>
                            <View style={{margin: appS.margins.side}}>
                                <Text style={{fontFamily: appFont.family.bold, color: appColors.black, fontWeight: "bold"}}>{this.state.maker.name}</Text>
                                <BorderComponent width={_width}/>
                            </View>

                            {/*報告内容*/}
                            <TopicTitleComponent title={'お問い合わせ内容'}/>
                            <View style={{
                                marginLeft: ScreenSizeUtil.isWebSize()? 0 : 16,
                                marginRight: ScreenSizeUtil.isWebSize()? 0 : 16,
                            }}>
                                <TextInput
                                    style={[appS.inputText, {height: 200}]}
                                    autoCapitalize={"none"}
                                    multiline={true}
                                    placeholder={'　お問い合わせ内容を記入してください'}
                                    placeholderTextColor={appColors.gray}
                                    value={this.state.report.text}
                                    onChangeText={text => this.setState({report: {text: text, errorText: this.state.report.errorText, isError: this.state.report.isError}})}
                                />
                                <ValidationErrorMessageComponent
                                    text={this.state.report.errorText}
                                    isError={this.state.report.isError}
                                />
                            </View>
                            <SpacerComponent height={appS.spacer.height.small*2}/>

                            {ScreenSizeUtil.isWebSize() && (<SpacerComponent height={appS.spacer.height.small * 4}/>)}

                            {/*お問い合わせボタン*/}
                            <TouchableOpacity
                                style={[
                                    {
                                        marginLeft: ScreenSizeUtil.isWebSize()? 0 : 16,
                                        marginRight: ScreenSizeUtil.isWebSize()? 0 : 16,
                                        height: appS.button.height,
                                        backgroundColor:  ValidateUtil.isEmptyExact(this.state.report.text) ? appColors.gray : appColors.black,
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        borderRadius: appS.buttonSize.radius
                                    }
                                ]}
                                disabled={ValidateUtil.isEmptyExact(this.state.report.text)}
                                onPress={() => {
                                    // 送信
                                    this._send(this.state.report.text);
                                }}
                            >
                                <Text style={{fontFamily: appFont.family.bold, color: appColors.white, fontSize: appS.button.text, fontWeight: "bold"}}>お問い合わせする</Text>
                            </TouchableOpacity>
                        </View>

                    </View>

                </KeyboardAvoidingView>
            </View>
        );
    }

    _renderWide = () => {
        return (
            <SafeAreaView
                style={[{height: AppG.window.height, flex: 1, flexDirection: 'column', backgroundColor: appColors.white}]}
                onLayout={(event) => {}}
            >
                <CustomHeaderComponentWide logoOnly={true} navigation={this.props.navigation}/>

                <View style={{flex: 1, flexDirection: 'row'}}>
                    <KeyboardAwareScrollView style={[{flex: 1, flexDirection: 'column'}]}>
                        <View style={[{flex: 1, width: 700, alignSelf: 'center'}]}>
                            {/*メイン*/}
                            {this._renderMain()}
                        </View>
                    </KeyboardAwareScrollView>

                    {/*お問い合わせが完了しましたModal*/}
                    <ModalComponent
                        title={'お問い合わせ完了'}
                        text={'貴重なご意見をいただきありがとうございました。\n' +
                            '\n' +
                            'ご指摘いただきました点を改善し、\n' +
                            '品質向上に努めてまいります。'}
                        buttonTitle={'閉じる'}
                        isShowModal={this.state.isShowSentModal}
                        onButtonPress={() => {
                            // 閉じて戻る
                            this.setState({'isShowSentModal': false});
                            ExpoUtil.goBack(this.props.navigation);
                        }}
                        height={250}
                    />
                </View>

                {this.state.isSending && (
                    <LoadingFullPageComponent/>
                )}
            </SafeAreaView>
        );
    }

    _renderSmall = () => {
        return (
            <SafeAreaView
                style={[{flex: 1, backgroundColor: appColors.white}]}
                onLayout={(event) => {}}
            >
                <CustomHeaderComponentSmall
                    navigation={this.props.navigation}
                    title={'お問い合わせ'}
                    textColor={appColors.black}
                    barColor={appColors.white}
                    leftButton={'back'}
                    backButtonColor={'gray'}
                />
                {/*メイン*/}
                {this._renderMain()}
                {/*お問い合わせが完了しましたModal*/}
                <ModalComponent
                    title={'お問い合わせ完了'}
                    text={'貴重なご意見をいただきありがとうございました。\n' +
                        '\n' +
                        'ご指摘いただきました点を改善し、\n' +
                        '品質向上に努めてまいります。'}
                    buttonTitle={'閉じる'}
                    isShowModal={this.state.isShowSentModal}
                    onButtonPress={() => {
                        // 閉じて戻る
                        this.setState({'isShowSentModal': false});
                        ExpoUtil.goBack(this.props.navigation);
                    }}
                    height={Platform.OS == 'android'? 350 : 300}
                />

                {this.state.isSending && (
                    <LoadingFullPageComponent/>
                )}
            </SafeAreaView>
        );
    }

    /** 描画 */
    render() {
        // const {navigation} = this.props;
        if (!this.state.isLoaded) {
            return (
                <View style={{flex: 1}}>
                    <IndicatorComponent
                        failTime={60}
                        isLoading={true}
                        reloadFunc={() => {
                            this.componentWillUnmount();
                            this.componentDidMount();
                        }}/>
                </View>
            );
        }

        LinkUtil.addReturnFalseToATag();
        if (ScreenSizeUtil.isWebSize()) {
            // WEBサイズ
            return this._renderWide();
        } else {
            // SPサイズ
            return this._renderSmall();
        }



    };


}
