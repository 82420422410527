import React, {Component} from 'react';
import {Image, ImageStyle, Platform, StyleProp, Text, TouchableOpacity, View} from 'react-native';
import {appColors, appFont, appS} from "../../resources/styles/style";
import {Product} from "../data/network/swagger-gen";
// @ts-ignore
import isURL from 'validator/lib/isURL';
import ValidateUtil from "../util/ValidateUtil";
import ExpoUtil from "../util/ExpoUtil";
import AppG from "../util/AppG";
import ScreenSizeUtil from "../util/ScreenSizeUtil";
import {ProductDetailScreenModel} from "../data/models/screen/ProductDetailScreenModel";
import {ATagLinkComponent} from "./ATagLinkComponent";

interface Props {
    navigation: any,
    products: Product[],
    analyticsBtnName: string | null,
    top3: boolean,  // トップ3だけ表示
}

interface State {}

export class ProductListRankingComponent extends Component<Props, State> {


    // Propsのデフォルト値
    static defaultProps = {
        analyticsBtnName: null,
        top3: false,  // トップ3だけ表示
    };

    _renderMain = () => {
        let {navigation, products} = this.props;
        let _width = AppG.window.width;
        if (ScreenSizeUtil.isWebSize()) {
            _width = appS.webMainContentsSize.width;
        }

        return (
            <View style={{
                flex: 1,
                flexDirection: "column",
                width: _width,
                marginLeft: 0,
                marginRight: 0,
                margin: appS.margins.side,
                backgroundColor: appColors.recipeSearchResultBackground,
            }}>
                {!ValidateUtil.isEmptyArray(products) &&
                    products.map((recipe, index) => {
                        if (this.props.top3 && index > 2) {
                            return null;
                        }
                        return (
                            <ProductCellSearchResultComponent
                                navigation={navigation}
                                product={recipe}
                                key={`recipe_${index}`}
                                index={index}
                                analyticsBtnName={this.props.analyticsBtnName}
                            />
                        );
                    })}
            </View>
        );
    }


    render() {
        return this._renderMain();
    }
}







interface ProductCellRankingProps {
    navigation: any,
    product: Product,
    index: number,
    analyticsBtnName: string | null,
}

interface ProductCellRankingState {
    followed: boolean,
}


class ProductCellSearchResultComponent extends Component<ProductCellRankingProps, ProductCellRankingState> {
    _productIconSize = 37;

    constructor(props: any) {
        super(props);
        this.state = {
            followed: this.props.product.followed == 1,  // 1ならお気に入り
        };
    };

    _goToProduct = (product: Product) => {
        if (this.props.analyticsBtnName != null) {
            AppG.screenHistory.unshift(`${this.props.analyticsBtnName}`);
        }

        // 画面遷移前にリロード対象にセットする
        ExpoUtil.setShouldReloadAppTab('self');
        let productDetailScreenModel = new ProductDetailScreenModel();
        productDetailScreenModel.productId = product.id.toString();
        if (Platform.OS == 'web') {
            this.props.navigation.navigate('ProductDetailScreen', productDetailScreenModel);
        } else {
            this.props.navigation.push('ProductDetailScreen', productDetailScreenModel);
        }
    }


    /**
     * 有償、会員限定アイコンの作成
     * @param product
     * @param imageSize {width: number, height: number}
     * @private
     */
    _renderIcon = (product: Product, imageSize: {width: number, height: number}, index: number) => {

        let _userPlanOnly = product.userPlanOnly;
        let _samplePaid = product.samplePaid;
        // _userPlanOnly = 1;   // テスト用
        // _samplePaid = 1;    // テスト用

        const _userPlanHeight = 16;
        const _productIconMargin = 4;
        const _style: StyleProp<ImageStyle> = {
            zIndex: 1,
            position: 'relative',
            top: 0,
            left: -imageSize.width,
            width: imageSize.width,
            height: this._productIconSize,
            backgroundColor: appColors.transparent,
        };

        return (
            <View style={_style}>
                <View style={{flexDirection: 'row'}}>
                    <View style={{width: imageSize.width - 83}}/>
                    <View
                        style={{
                            flexDirection: 'row-reverse',
                            height: _userPlanHeight,
                            borderRadius: 5,
                            marginLeft: Platform.OS == 'ios'? 4 : 0,
                            backgroundColor: _userPlanOnly == 1? appColors.limeGreen: appColors.transparent,
                        }}
                    >
                        <Text style={{marginTop: Platform.OS == 'android'? -6 : 0, fontFamily: appFont.family.default, fontSize: appFont.size.iconFontSize, color: _userPlanOnly == 1? appColors.white: appColors.transparent, marginLeft: 8, marginRight: 8, width: Platform.OS == 'android'? 43 : 40}}>会員限定</Text>
                    </View>
                </View>

                <View style={{
                    zIndex: 1,
                    position: 'relative',
                    top: imageSize.height - this._productIconSize - _userPlanHeight,
                    width: imageSize.width,
                    height: this._productIconSize,
                    backgroundColor: appColors.transparent,
                    flexDirection: "row",
                }}>
                    <View
                        style={{
                            marginLeft: -27,
                            marginTop: 21,
                            width: 30,
                            height: _userPlanHeight,
                            borderRadius: 5,
                            backgroundColor: _samplePaid == 1? appColors.goldYellow: appColors.transparent,
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: "row"
                        }}
                    >
                        <Text style={{marginTop: 0, fontFamily: appFont.family.bold, fontSize: 9, color: _samplePaid == 1? appColors.white: appColors.transparent, fontWeight: "bold"}}>有償</Text>
                    </View>
                    <View style={{flex:1}}/>
                    <Image source={require('../../resources/images/skelton.png')}
                           style={{
                               width: this._productIconSize,
                               height: this._productIconSize,
                               borderRadius: 50,
                               alignItems: 'center',
                               justifyContent: 'center',
                               marginRight: _productIconMargin,
                               marginTop: -_productIconMargin,
                               borderColor: appColors.transparent,
                               backgroundColor: appColors.transparent,
                           }}
                           resizeMode={'cover'}
                    />
                </View>
            </View>
        );
    }

    _renderMain = () => {
        const {product, index} = this.props;

        let _lineNumTitle = 2;
        let _lineNumDescription = 1;
        let _margin = appS.margins.side / 2;
        let _imageSize = 90;

        let _textWidth = AppG.window.width;
        if (ScreenSizeUtil.isWebSize()) {
            _textWidth = appS.webMainContentsSize.width;
        }

        if (index == 0) {
            _imageSize = 140;
            _lineNumTitle = 3;
            _lineNumDescription = 2;
            _margin = _margin * 2;
        }

        _textWidth = _textWidth - appS.margins.side * 7 - _imageSize;

        let _nameMarginLeft = -appS.margins.side - _imageSize;

        let _source = require('../../resources/images/06.ranking/i_rank_1.png');
        switch (index) {
            case 0:
                _source = require('../../resources/images/06.ranking/i_rank_1.png');
                break;
            case 1:
                _source = require('../../resources/images/06.ranking/i_rank_2.png');
                break;
            case 2:
                _source = require('../../resources/images/06.ranking/i_rank_3.png');
                break;
            default:
                break;
        }


        return (
            <View style={{
                margin: appS.margins.side,
                backgroundColor: appColors.white,
                marginTop: appS.margins.side / 2,
                marginBottom: appS.margins.side / 2
            }}>
                <ATagLinkComponent
                    href={`/ProductDetailScreen/${product.id}`}
                    style={{flexDirection: 'row', margin: appS.margins.side}}
                    onPress={() => this._goToProduct(product)}
                    view={
                        <View style={{flexDirection: 'row'}}>
                            <Image
                                style={{
                                    borderRadius: 5,
                                    width: _imageSize,
                                    height: _imageSize,
                                }}
                                source={{uri: product.images[0].url}}
                            />

                            {index < 3 && (
                                <View style={{
                                    position: 'relative',
                                    top: -10,
                                    left: -_imageSize - appS.rankingIconSize.top / 2,
                                    borderRadius: 50,
                                    width: appS.rankingIconSize.top,
                                    height: appS.rankingIconSize.top,
                                }}>
                                    <Image
                                        source={_source}
                                        style={{
                                            width: appS.rankingIconSize.top,
                                            height: appS.rankingIconSize.top,
                                        }}
                                    />
                                </View>
                            )}
                            {index >=3 && (
                                <View style={{
                                    position: 'relative',
                                    top: -10,
                                    left: -_imageSize - appS.rankingIconSize.normal / 2,
                                    borderRadius: 50,
                                    backgroundColor: appColors.rankingIconGray,
                                    width: appS.rankingIconSize.normal,
                                    height: appS.rankingIconSize.normal,
                                }}>
                                    <Text
                                        style={{
                                            marginTop: Platform.OS == 'android'? 3: 2,
                                            alignSelf: 'center',
                                            fontFamily: appFont.family.bold,
                                            fontSize: appFont.size.rankingIcon,
                                            color: appColors.white,
                                            fontWeight: "bold"
                                        }}
                                    >{index + 1}</Text>
                                </View>
                            )}

                            {this._renderIcon(product, {width: _imageSize, height: _imageSize}, index)}

                            <View style={{flexDirection: 'column', marginLeft: _nameMarginLeft, marginTop: _margin}}>
                                <Text
                                    style={{
                                        lineHeight: appFont.size.searchResultLineHeight,
                                        height: appFont.size.searchResultLineHeight * _lineNumTitle,
                                        width: _textWidth,
                                        fontFamily: appFont.family.bold,
                                        fontSize: appFont.size.searchResultCellTitle,
                                        color: appColors.fontBlack,
                                        fontWeight: "bold"
                                    }}
                                    numberOfLines={_lineNumTitle}
                                >{product.name}</Text>
                                <Text
                                    style={{
                                        marginTop: _margin / 2,
                                        lineHeight: appFont.size.searchResultLineHeight,
                                        height: appFont.size.searchResultLineHeight,
                                        width: _textWidth,
                                        fontFamily: appFont.family.default,
                                        fontSize: appFont.size.searchProductCellDescription,
                                        color: appColors.fontGray,
                                    }}
                                    numberOfLines={1}
                                >{`内容量：${product.capacity}`}</Text>
                                <Text
                                    style={{
                                        marginTop: _margin / 2,
                                        lineHeight: appFont.size.searchResultLineHeight,
                                        height: appFont.size.searchResultLineHeight * _lineNumDescription,
                                        width: _textWidth,
                                        fontFamily: appFont.family.default,
                                        fontSize: appFont.size.searchResultCellDescription,
                                        color: appColors.fontBlack,
                                    }}
                                    numberOfLines={_lineNumDescription}
                                >{product.description}</Text>
                            </View>
                        </View>
                    }
                />

            </View>
        );
    }

    render() {
        return this._renderMain();
    }
}






