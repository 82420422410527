import {BaseDao} from "./BaseDao";
// @ts-ignore
import {log} from '../../../../../environment';
import {ReceivedNewMessageModalDataEntityModel} from "../../entityModels/ReceivedNewMessageModalDataEntityModel";

class ReceivedNewMessageModalDataDao extends BaseDao<ReceivedNewMessageModalDataEntityModel> {
    _getModel(): ReceivedNewMessageModalDataEntityModel {
        return new ReceivedNewMessageModalDataEntityModel();
    }
}

// new 付なのでシングルトン
export default new ReceivedNewMessageModalDataDao();
