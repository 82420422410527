import React from 'react'
import {
    ActivityIndicator,
    Dimensions,
    Platform,
    SafeAreaView,
    ScrollView,
    Text,
    TouchableOpacity,
    View
} from 'react-native'
import {appColors, appFont, appS} from '../../resources/styles/style'
import {BaseScreen} from "./BaseScreen";
import LoginUtil from "../util/LoginUtil";
import {IndicatorComponent} from "../components/IndicatorComponent";
// @ts-ignore
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scrollview';
import LoginDataDao from "../data/dao/local/LoginDataDao";
import {LoginDataEntityModel} from "../data/entityModels/LoginDataEntityModel";
import {News, NewsApiFactory} from "../data/network/swagger-gen";
// @ts-ignore
import isURL from 'validator/lib/isURL';
// @ts-ignore
import {log} from '../../../environment';
import {SpacerComponent} from "../components/SpacerComponent";
import ValidateUtil from "../util/ValidateUtil";
import ExpoUtil from "../util/ExpoUtil";
import AppG from "../util/AppG";
import ScreenSizeUtil from "../util/ScreenSizeUtil";
import {CustomHeaderComponentWide} from "../components/wide/CustomHeaderComponentWide";
import {NoItemsIconComponent} from "../components/NoItemsIconComponent";
import {WideScreenAdComponent} from "../components/wide/WideScreenAdComponent";
import AnalyticsUtil, {AnalyticsEventName, DOpenEventName} from "../util/firebase/AnalyticsUtil";
import {CustomHeaderComponentSmall} from "../components/small/CustomHeaderComponentSmall";
import DateUtil from "../util/DateUtil";
import {AxiosResponse} from "axios";
import {TabBorderComponent} from "../components/TabBorderComponent";
import {WideSubTitleComponent} from "../components/wide/WideSubTitleComponent";
import {NoUserImageComponent} from "../components/NoUserImageComponent";
import {WideScreenSNSComponent} from "../components/wide/WideScreenSNSComponent";
import LinkUtil from "../util/LinkUtil";
import Carousel from "react-native-reanimated-carousel";

interface Props {
    navigation: any, // ナビゲーション用
}

interface State {
    resizeWindow: boolean,  // 値に意味はなし。windowサイズの拡大縮小をrenderに送るためのもの
    isLoaded: boolean,

    // view
    activeTabSlide: number,    // 現在のTabSlide

    // コンテンツ
    news: News[], // お知らせ
    reminders: News[],
    isLoadNews: boolean,
    isLoadReminders: boolean,
}

export class NewsScreen extends BaseScreen<Props, State> {
    _resizeTimer = setInterval(() => {
        // window 拡大縮小を反映させる
        AppG.window = Dimensions.get('window');
        if (AppG.window.width != AppG.windowBefore.width || AppG.window.height != AppG.windowBefore.height) {
            this.setState({'resizeWindow': true});
            AppG.windowBefore = Dimensions.get('window');
        }
        LoginUtil.setRefreshedScreenFlg(false);
    }, 100);

    _loginData = new LoginDataEntityModel();

    _flameSize = appS.flame.size;    // 左右の margin
    _marginSize = ScreenSizeUtil.isWebSize()? 0 : appS.margin.size;

    // このScreenへ渡すパラム
    _contentsLoadCount = 20; // コンテンツの一度の読み込み数

    // タブ
    _tabClicked = false;
    _tabIndex = 0;
    _tabPage: any = null;
    /** tab 本体 */
    _tabRef: any = null;
    _tabPress = (tabNumber: number) => {
        if (this._tabIndex == tabNumber) {
            return;
        }
        this._tabClicked = true;
        this._tabIndex = tabNumber;
        if (this._tabPage != null) {
            if (this._tabRef != null) {
                this._tabRef.redrawView(tabNumber);
            }
            this._tabPage.scrollTo({index: tabNumber, animated: false});
        }
        setTimeout(() => {this.setState({resizeWindow: true});}, 10);
        setTimeout(() => {this.setState({resizeWindow: true});}, 10);
    };
    _tabs: Tab[] = [
        {
            index: 0,    // 0 〜
            title: 'お知らせ',
            onPress: () => {this._tabPress(0)},
            color: appColors.actionColor,
            unselectColor: appColors.tabGray,
            backgroundColor: appColors.white,
            unselectBackgroundColor: appColors.white,
            badge: 0,
        },
        {
            index: 1,    // 0 〜
            title: 'レビュー依頼',
            onPress: () => {this._tabPress(1)},
            color: appColors.actionColor,
            unselectColor: appColors.tabGray,
            backgroundColor: appColors.white,
            unselectBackgroundColor: appColors.white,
            badge: 0,
        },
    ];


    _browserBackListener = (e: any) => {

    };

    constructor(props: any) {
        super(props);
        this.state = {
            resizeWindow: true,  // 値に意味はなし。windowサイズの拡大縮小をrenderに送るためのもの
            isLoaded: true,
            activeTabSlide: 0,
            news: [], // お知らせ
            reminders: [],
            isLoadNews: true,
            isLoadReminders: true,
        };
    }

    /**
     * 画面開いたときのアナリティクス送信
     */
    _sendPvAnalytics = () => {
        AnalyticsUtil.sendAnalytics(
            DOpenEventName.openPage,
            AnalyticsEventName.openPage,  // アナリティクスのイベント名
            'NewsScreen',
            null,
            null,
            null,
            null,
        );
    }

    componentDidMount() {
        if (Platform.OS == 'web') {
            window.addEventListener('popstate', this._browserBackListener);
        }

        // URL Scheme で外部リンクから起動したときの処理
        // LoginUtil.setDefaultListener(this.props.navigation);

        LoginUtil.interruptOpenScreen(this.props.navigation,
            () => {
                LoginDataDao.get().then(loginData => {
                    // Web 戻るでも呼ばれる
                    if (Platform.OS == 'web') {
                        // Analytics
                        this._sendPvAnalytics();
                    }
                    AppG.getFooterBadge();

                    // URL Scheme で外部リンクから起動したときの処理と、画面遷移でこの画面を開いたときの処理
                    LoginUtil.setDefaultListener(
                        this.props.navigation,
                        () => {
                            // Webはここにこない
                            if (Platform.OS != 'web') {
                                // Analytics
                                this._sendPvAnalytics();
                            }

                            ExpoUtil.doReloadAppTab(() => {
                                this.setState({'isLoaded': false});
                                this._loadContents().then(() => {
                                    this.setState({'isLoaded': true});
                                });
                                AppG.getFooterBadge();
                            });
                        });
                    if (loginData != null && !ValidateUtil.isEmptyExact(loginData?.user_id)) {
                        this._loginData = loginData;
                        this._loadMasters().then(() => {});
                        this._loadContents().then(() => {});
                        this.setState({'isLoaded': true});
                    }
                });
            });

        this.setState({isLoaded: true});
    }

    componentWillUnmount () {
        clearTimeout(this._resizeTimer);
        if (Platform.OS === 'web') {
            window.removeEventListener('popstate', this._browserBackListener);
        }
        LoginUtil.removeDefaultListener(this.props.navigation);
    };

    componentDidUpdate () {
        // レンダー完了時に呼ばれる
    }

    /**
     * マスターのロード
     * @private
     */
    _loadMasters = async () => {

    };

    /**
     * コンテンツのロード
     * @private
     */
    _loadContents = async () => {

        // お知らせ
        this._loadNews(0, this._contentsLoadCount, true);

        if (AppG.user != null) {
            // 催促
            this._loadReminder(0, this._contentsLoadCount, true);
        }

        // 実行
        // await Promise.all([getNews, getReminders]);
    };

    /**
     * お知らせの取得
     */
    _loadNews = async (from: number, to: number, resetFlg: boolean) => {
        // お知らせ
        this.setState({isLoadNews: true});
        const getNews = NewsApiFactory(AppG.getConfiguration(), AppG.getBasePath())
            .getNews(from, to, 'news')
            .then((newsRes: AxiosResponse<News[]>) => {
                if (newsRes != null && !ValidateUtil.isEmptyArray(newsRes.data)) {
                    let _news: News[] = [];
                    if (!resetFlg) {
                        _news = this.state.news;
                    }
                    newsRes.data.forEach((news: News) => {
                        _news.push(news);
                    });
                    this.setState({news: _news});
                } else if (resetFlg) {
                    this.setState({news: []});
                }
                this.setState({isLoadNews: false});
            })

        // 実行
        await Promise.all([getNews]);

        // // TODO -------------- sample --------------
        // this.setState({isLoadNews: false});
        // const _n: News = {
        //     id: 1,
        //     /**
        //      *
        //      * @type {string}
        //      * @memberof News
        //      */
        //     title: 'タイトルあいうえおかきくけこさしすせそたちつてとあいうえおかきくけこさしすせそたちつてとあいうえおかきくけこさしすせそたちつてとあいうえおかきくけこさしすせそたちつてと',
        //     /**
        //      * 本文(一覧取得時には空)
        //      * @type {string}
        //      * @memberof News
        //      */
        //     text: 'あいうえおかきくけこさしすせそたち\nhttp://localhost:19006/ProductDetailScreen?productId=65\nつてとあいうえおかきくけこさしすせそたちつてとあいうえおかきくけこさしすせそたちつてとあいうえおかきくけこさしすせそたちつてとあいうえおかきくけこさしすせそたちつてとあいうえおかきくけこさしすせそたちつてとあいうえおかきくけこさしすせそたちつてと',
        //     /**
        //      * 0/1, 読んでたら1
        //      * @type {number}
        //      * @memberof News
        //      */
        //     read: 1,
        //     /**
        //      * 更新（作成）日時 yyyy-MM-dd hh:mm
        //      * @type {string}
        //      * @memberof News
        //      */
        //     date: '2021-01-11 14:11',
        // }
        // this.state.news.push(_n);
        // this.state.news.push(_n);
        // this.state.news.push(_n);
        // this.state.news.push(_n);
        // this.state.news.push(_n);
        // this.state.news.push(_n);
        // this.state.news.push(_n);
        // this.state.news.push(_n);
        // this.state.news.push(_n);
        // this.state.news.push(_n);
        // this.state.news.push(_n);
        // this.state.news.push(_n);
        //
        // // -------------- sample --------------
    }

    /**
     * 催促の取得
     */
    _loadReminder = async (from: number, to: number, resetFlg: boolean) => {
        // 催促
        this.setState({isLoadReminders: true});
        const getReminders = NewsApiFactory(AppG.getConfiguration(), AppG.getBasePath())
            .getNews(from, to, 'reminder')
            .then((reminderRes: AxiosResponse<News[]>) => {
                if (reminderRes != null && !ValidateUtil.isEmptyArray(reminderRes.data)) {
                    let _reminders: News[] = [];
                    if (!resetFlg) {
                        _reminders = this.state.reminders;
                    }
                    reminderRes.data.forEach((reminders: News) => {
                        _reminders.push(reminders);
                    });
                    this.setState({reminders: _reminders});
                } else if (resetFlg) {
                    this.setState({reminders: []});
                }
                this.setState({isLoadReminders: false});

            })

        // 実行
        await Promise.all([getReminders]);


        // // TODO -------------- sample --------------
        // this.setState({isLoadReminders: false});
        // const _n: News = {
        //     id: 1,
        //     /**
        //      *
        //      * @type {string}
        //      * @memberof News
        //      */
        //     title: '商品レビューをお願いいたします',
        //     /**
        //      * 本文(一覧取得時には空)
        //      * @type {string}
        //      * @memberof News
        //      */
        //     text: 'あいうえおかき https://www.google.com こさしすせそたちつてとあいうえおかきくけこさしすせそたちつてとあいうえおかきくけこさしすせそたちつてとあいうえおかきくけこさしすせそたちつてとあいうえおかきくけこさしすせそたちつてとあいうえおかきくけこさしすせそたちつてとあいうえおかきくけこさしすせそたちつてと',
        //     /**
        //      * 0/1, 読んでたら1
        //      * @type {number}
        //      * @memberof News
        //      */
        //     read: 1,
        //     /**
        //      * 更新（作成）日時 yyyy-MM-dd hh:mm
        //      * @type {string}
        //      * @memberof News
        //      */
        //     date: '2021-01-11 14:11',
        // }
        //
        // this.state.reminders.push(_n);
        // this.state.reminders.push(_n);
        // this.state.reminders.push(_n);
        // this.state.reminders.push(_n);
        // this.state.reminders.push(_n);
        // this.state.reminders.push(_n);
        // this.state.reminders.push(_n);
        // this.state.reminders.push(_n);
        // this.state.reminders.push(_n);
        // this.state.reminders.push(_n);
        // this.state.reminders.push(_n);
        // this.state.reminders.push(_n);
        // this.state.reminders.push(_n);
        // this.state.reminders.push(_n);
        //
        // // -------------- sample --------------
    }

    /**
     * タブページの中身
     * @param index
     */
        // @ts-ignore
    _renderPageItems = ({item}) => {
        let index: number = item;
        switch (index) {
            case 0:
                return this._renderNews();
            case 1:
                return this._renderReminders();
            default:
                return this._renderNews()
        }
    }


    /**
     * お知らせ、催促のセル
     * @param news
     * @param index
     */
    _renderCell = (news: News, type: 'news' | 'reminder', index: number) => {
        return (
            <View style={{
                flex: 1,
                borderBottomWidth: 1,
                borderBottomColor: appColors.borderGray,
            }}>
                <View
                    style={{
                        flexDirection: "row",
                        marginBottom: 0,
                        marginLeft: this._marginSize,
                        marginRight: this._marginSize,
                    }}
                >
                    <View style={{flexDirection: "row", flex: 1}}>
                        <TouchableOpacity
                            style={{
                                flexDirection: "row",
                                marginTop: 8,
                                marginBottom: 8,
                                marginLeft: 0,
                                marginRight: 0,
                            }}
                            onPress={() => {
                                // TODO API作る時間がないので。いつか直す。
                                // 画面遷移前にリロード対象にセットする
                                ExpoUtil.setShouldReloadAppTab('self');
                                AppG.news = news;
                                this.props.navigation.navigate('NewsDetailScreen');
                            }}
                        >
                            <View style={{flexDirection: "column"}}>
                                <Text
                                    numberOfLines={1}
                                    ellipsizeMode="tail"
                                    style={{
                                        width: ScreenSizeUtil.isWebSize()? AppG.window.width / 2 - 32: AppG.window.width - 32,
                                        fontSize: appFont.size.default,
                                        color: appColors.black,
                                        marginLeft: 8,
                                        marginTop: Platform.OS == 'web' ? -2 : 0,
                                    }}
                                >
                                    {news.title}</Text>

                                <View style={{flexDirection: 'row'}}>
                                    <Text
                                        style={{
                                            fontSize: appFont.size.small,
                                            color: appColors.gray,
                                            marginLeft: 8,
                                            marginTop: 8,
                                        }}
                                    >
                                        {DateUtil.getViewDateFromApiDateStr(news.date)}</Text>
                                    <View style={{flex: 1}}/>
                                    <View
                                        style={{
                                            backgroundColor: news.read == 1? appColors.transparent: appColors.red,
                                            height: 8,
                                            width: 8,
                                            borderRadius: 50,
                                            borderColor: news.read == 1? appColors.transparent: appColors.red,
                                            borderWidth: 1,
                                            marginTop: 12,
                                            marginRight: 16,
                                        }}
                                    />
                                </View>

                            </View>
                        </TouchableOpacity>

                    </View>
                </View>
            </View>
        );
    }

    /**
     * お知らせの描画
     */
    _renderNews = () => {
        let _width = AppG.window.width;
        if (ScreenSizeUtil.isWebSize()) {
            _width = appS.webMainContentsSize.width;
        }

        return (
            <View style={[{flex: 1, flexDirection: 'column', width: AppG.window.width}]}>
                <KeyboardAwareScrollView
                    style={[{flex: 1, flexDirection: 'column'}]}
                    onScroll={(event: any) => {
                        // 無限スクロール
                        // スクロールViewの高さ
                        const scrollViewHeight = event.nativeEvent.contentSize.height - event.nativeEvent.layoutMeasurement.height;
                        // スクロールView上の現在位置
                        const nowPositionHeight = event.nativeEvent.contentOffset.y;
                        // 現在の位置がスクロールView + activityIndicator の高さよりも下の場合
                        const indicatorHeight = Platform.OS == 'web'? -10: -appS.activityIndicator.height;
                        if (!this.state.isLoadNews && this.state.news.length >= this._contentsLoadCount - 1 && nowPositionHeight - scrollViewHeight >  indicatorHeight) {
                            this._loadNews(this.state.news.length, this.state.news.length + this._contentsLoadCount, false);
                        }
                    }}
                >
                    <SpacerComponent height={32}/>
                    <TouchableOpacity
                        activeOpacity={1}
                        style={[{flex: 1, flexDirection: ScreenSizeUtil.isWebSize()? 'row': 'column', justifyContent: 'center'}]}>
                        <View style={[{flexDirection: 'column'}]}>
                            <View style={{width: _width}}>
                                {ScreenSizeUtil.isWebSize() && <WideSubTitleComponent title={'お知らせ'} width={_width} />}

                                {/*タブ*/}
                                {ScreenSizeUtil.isWebSize() && <TabBorderComponent
                                    ref={(tabRef: any) => { this._tabRef = tabRef; }}
                                    tabs={this._tabs}
                                    focusTabNumber={this._tabIndex}
                                    unselectBorder={true}
                                    button={true}
                                />}

                                {ScreenSizeUtil.isWebSize() && (<SpacerComponent height={appS.margins.side}/>)}

                                {/*お知らせ無し*/}
                                {!this.state.isLoadNews && ValidateUtil.isEmptyArray(this.state.news) && (
                                    <NoItemsIconComponent text={'現在お知らせはありません。'}/>
                                )}
                                {/*<SpacerComponent height={16} />*/}
                                {!ValidateUtil.isEmptyArray(this.state.news) && this.state.news.map((news: News, index: number) => {
                                    return (
                                        <View key={`news_view_${index}`}>
                                            {this._renderCell(news, 'news', index)}
                                        </View>
                                    );
                                })}

                                {/*ローディングインジケーター*/}
                                <View style={{flex: 1, height: appS.activityIndicator.height}}>
                                    <ActivityIndicator
                                        animating={this.state.isLoadNews}
                                        color = {appColors.indicator}
                                        size = "large"
                                        style={[{flex: 1}]}/>
                                </View>
                            </View>
                        </View>
                        {ScreenSizeUtil.isWebSize() && (
                            <View style={{marginLeft: appS.margins.webBetweenMargin, width: appS.webSideContentsSize.width}}>
                                <WideScreenAdComponent navigation={this.props.navigation}/>
                                <WideScreenSNSComponent navigation={this.props.navigation}/>
                            </View>
                        )}
                    </TouchableOpacity>
                    <SpacerComponent height={196}/>
                </KeyboardAwareScrollView>
            </View>
        );
    }


    /**
     * 催促の描画
     */
    _renderReminders = () => {

        let _width = AppG.window.width;
        if (ScreenSizeUtil.isWebSize()) {
            _width = appS.webMainContentsSize.width;
        }

        return (
            <View style={[{flex: 1, flexDirection: 'column', width: AppG.window.width}]}>
                <KeyboardAwareScrollView
                    style={[{flex: 1, flexDirection: 'column'}]}
                    onScroll={(event: any) => {
                        // 無限スクロール
                        // スクロールViewの高さ
                        const scrollViewHeight = event.nativeEvent.contentSize.height - event.nativeEvent.layoutMeasurement.height;
                        // スクロールView上の現在位置
                        const nowPositionHeight = event.nativeEvent.contentOffset.y;
                        // 現在の位置がスクロールView + activityIndicator の高さよりも下の場合
                        const indicatorHeight = Platform.OS == 'web'? -10: -appS.activityIndicator.height;
                        if (!this.state.isLoadReminders && this.state.reminders.length >= this._contentsLoadCount - 1 && nowPositionHeight - scrollViewHeight >  indicatorHeight) {
                            this._loadReminder(this.state.reminders.length, this.state.reminders.length + this._contentsLoadCount, false);
                        }
                    }}
                >
                    <SpacerComponent height={32}/>
                    <TouchableOpacity
                        activeOpacity={1}
                        style={[{flex: 1, flexDirection: ScreenSizeUtil.isWebSize()? 'row': 'column', justifyContent: 'center'}]}>
                        <View style={[{flexDirection: 'column'}]}>
                            <View style={{width: _width}}>
                                {ScreenSizeUtil.isWebSize() && <WideSubTitleComponent title={'レビュー依頼'} width={_width} />}

                                {/*タブ*/}
                                {ScreenSizeUtil.isWebSize() && <TabBorderComponent
                                    ref={(tabRef: any) => { this._tabRef = tabRef; }}
                                    tabs={this._tabs}
                                    focusTabNumber={this._tabIndex}
                                    unselectBorder={true}
                                    button={true}
                                />}

                                {ScreenSizeUtil.isWebSize() && (<SpacerComponent height={appS.margins.side}/>)}

                                {AppG.user == null && (<NoUserImageComponent navigation={this.props.navigation} />)}

                                {AppG.user != null && (
                                    <View>
                                        {/*無し*/}
                                        {!this.state.isLoadReminders && ValidateUtil.isEmptyArray(this.state.reminders) && (
                                            <NoItemsIconComponent text={'まだレビューやアンケートはありません。'}/>
                                        )}
                                        {/*<SpacerComponent height={16} />*/}
                                        {!ValidateUtil.isEmptyArray(this.state.reminders) && this.state.reminders.map((reminder: News, index: number) => {
                                            return (
                                                <View key={`news_view_${index}`}>
                                                    {this._renderCell(reminder, 'reminder', index)}
                                                </View>
                                            );
                                        })}

                                        {/*ローディングインジケーター*/}
                                        <View style={{flex: 1, height: appS.activityIndicator.height}}>
                                            <ActivityIndicator
                                                animating={this.state.isLoadReminders}
                                                color = {appColors.indicator}
                                                size = "large"
                                                style={[{flex: 1}]}/>
                                        </View>
                                    </View>
                                )}
                            </View>
                        </View>
                        {ScreenSizeUtil.isWebSize() && (
                            <View style={{marginLeft: appS.margins.webBetweenMargin, width: appS.webSideContentsSize.width}}>
                                <WideScreenAdComponent navigation={this.props.navigation}/>
                                <WideScreenSNSComponent navigation={this.props.navigation}/>
                            </View>
                        )}
                    </TouchableOpacity>
                    <SpacerComponent height={196}/>
                </KeyboardAwareScrollView>
            </View>
        );
    }

    /**
     * 未ログイン時の表示
     */
    _renderNoLogin = () => {
        return (
            <View style={{flex: 1, backgroundColor: appColors.backBaseColor}}>
                <NoUserImageComponent navigation={this.props.navigation} />
            </View>
        );
    }


    _renderSmall = () => {
        return (
            <SafeAreaView
                style={[{height: AppG.window.height, flex: 1, flexDirection: 'column', backgroundColor: appColors.white}]}
                onLayout={(event) => {}}
            >
                <CustomHeaderComponentSmall
                    navigation={this.props.navigation}
                    leftButton={'back'}
                    rightButton={null}
                    title={'お知らせ・レビュー依頼'}
                    searchBar={false}
                    textColor={appColors.black}
                    barColor={appColors.white}
                    borderBottomColor={appColors.transparent}
                    statusBarType={"dark-content"}
                />

                {/*タブ*/}
                <TabBorderComponent
                    ref={(tabRef: any) => { this._tabRef = tabRef; }}
                    tabs={this._tabs}
                    focusTabNumber={this._tabIndex}
                    unselectBorder={true}
                    button={true}
                />


                <View style={{
                    flex: 1,
                    flexDirection: 'row',
                    alignSelf: 'center',
                    backgroundColor: appColors.backBaseColor,
                }}>

                    {/*タブコンテンツ*/}
                    <Carousel
                        ref={(pageRef: any) => { this._tabPage = pageRef; }}
                        enabled={Platform.OS == 'ios'}
                        data={[0, 1]}
                        renderItem={this._renderPageItems}
                        onSnapToItem={(index: number) => {
                            this._tabPress(index);
                        }}
                        width={AppG.window.width}
                        autoPlay={false}
                        loop={false}
                        style={{height: appS.screenSize.fullHeight}}
                    />
                </View>
            </SafeAreaView>
        );
    }


    _renderWide = () => {
        const {navigation} = this.props;

        return (
            <SafeAreaView
                style={[{height: AppG.window.height, flex: 1, flexDirection: 'column', backgroundColor: appColors.white}]}
                onLayout={(event) => {}}
            >
                <CustomHeaderComponentWide
                    navigation={navigation}
                    searchBar={true}
                />

                <View style={{
                    flex: 1,
                    flexDirection: 'row',
                    alignSelf: 'center',
                    backgroundColor: appColors.backBaseColor,
                }}>

                    {/*タブコンテンツ*/}
                    <ScrollView
                        horizontal  // 下階層のスクロールイベントを拾えるようにしている
                        style={{width: AppG.window.width, height: AppG.window.height - appS.header.webHeight}}
                    >
                        {/*タブ*/}
                        {this._tabIndex == 0 && this._renderNews()}
                        {this._tabIndex == 1 && this._renderReminders()}
                    </ScrollView>
                </View>
            </SafeAreaView>
        );
    }



    /** 描画 */
    render() {
        const {navigation} = this.props;

        if (!this.state.isLoaded) {
            return (
                <View style={{flex: 1}}>
                    <IndicatorComponent
                        failTime={60}
                        isLoading={true}
                        reloadFunc={() => {
                            this.componentWillUnmount();
                            this.componentDidMount();
                        }}/>
                </View>
            );
        }

        LinkUtil.addReturnFalseToATag();
        if (ScreenSizeUtil.isWebSize()) {
            // WEBサイズ
            return this._renderWide();
        } else {
            // SPサイズ
            return this._renderSmall();
        }


    }


}
