// @ts-ignore
import {getEnvVars, log} from '../../../environment';
import {Platform} from "react-native";
import FormData from 'form-data';

class UrlUtil {
    /**
     * iOSかどうかをユーザーエージェントで判別
     */
    isIosByUa = () => {
        const ua = window.navigator.userAgent.toLowerCase();
        const isiPad = ua.indexOf('ipad') > -1 || ua.indexOf('macintosh') > -1 && 'ontouchend' in document;
        const isiOS = ua.indexOf('iphone') > -1 || ua.indexOf('ipad') > -1 || ua.indexOf('macintosh') > -1 && 'ontouchend' in document;

        return isiPad || isiOS;

        // const isMac = ua.indexOf('macintosh') > -1 && !('ontouchend' in document);
    }

    /**
     * androidかどうかをユーザーエージェントで判別
     */
    isAndroidByUa = () => {
        const ua = window.navigator.userAgent.toLowerCase();
        const isAndroid = ua.indexOf('android') > -1 && 'ontouchend' in document;

        return isAndroid;
    }

    /**
     * ここまでを取得 => http://localhost:8083/v1
     */
    getApiUrlRoot = () => {
        // @ts-ignore
        const {REACT_APP_HTTP, REACT_APP_DOMAIN_IOS, REACT_APP_DOMAIN_ANDROID, REACT_APP_API_VERSION,
        } = getEnvVars();

        if (Platform.OS === 'android') {
            return `${REACT_APP_HTTP}${REACT_APP_DOMAIN_ANDROID}/${REACT_APP_API_VERSION}`;
        } else {
            return `${REACT_APP_HTTP}${REACT_APP_DOMAIN_IOS}/${REACT_APP_API_VERSION}`;
        }

    };

    /**
     * Get の Param を作る(application/x-www-form-urlencoded形式)
     * @param model model or entityModel
     */
    // @ts-ignore
    createGetUrlParams = (model): {} => {
        // get形式でParamを作る
        let params = {};
        for(let item in model) {
            if (model[item] != null && model[item] !== '') {
                // null or 空文字 でなければ query セット
                // @ts-ignore
                params[item] = model[item];
            }
        }
        return params;
    };

    /**
     * POST の Param を作る(application/x-www-form-urlencoded形式)
     * @param model model or entityModel
     */
    // @ts-ignore
    createPostUrlParams = (model): URLSearchParams => {
        // application/x-www-form-urlencoded形式でParamを作る
        let params = new URLSearchParams();
        for(let item in model) {
            if (model[item] != null) {
                // nullでなければ query セット
                params.append(`${item}`, model[item]);
            }
        }
        return params;
    };

    /**
     * POST の Param を作る(multipart/form-data形式)
     * @param model model or entityModel
     */
    // @ts-ignore
    createPostFileParams = (model) => {
        // multipart/form-data形式でParamを作る
        let form = new FormData();
        for(let item in model) {
            if (model[item] != null) {
                // nullでなければ query セット
                form.append(`${item}`, model[item]);
            }
        }
        return form;
    };

    /**
     * model の field と同じパラムを  navigation から取得
     * @param model 返り値のモデル
     * @param navigation
     */
    // @ts-ignore
    getParamModel = (model, navigation): any => {
        let params = {};
        for(let item in model) {
            // model の field をパラムとしてしていし、navigation からセット
            if (navigation.getParam(item) != null) {
                // @ts-ignore
                params[item] = (JSON.stringify(navigation.getParam(item, '')).slice(1).slice(0, -1) as any);
            }
        }
        return params;
    };

    // /**
    //  * DynamicLink で短縮URLを取得
    //  * （Analytics にのみ使用。<Image> タグには利用不可）
    //  * @param url
    //  */
    // getShortUrl = async (url: string) => {
    //
    //     const {
    //         REACT_APP_FIRE_STORE_API_KEY,
    //     } = getEnvVars();
    //
    //     let responseData: any = null;
    //     const _ = await axios.post(`https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${REACT_APP_FIRE_STORE_API_KEY}`,
    //         {
    //             "longDynamicLink": `https://recibasedev.page.link/?link=${url}`,
    //             "suffix": {
    //                 "option": "SHORT"
    //             }
    //         },
    //         {
    //         headers: {
    //             'Content-Type': 'application/json'
    //         }
    //     })
    //         .then(
    //             response => {
    //                 responseData = response.data;
    //             })
    //         .catch(
    //             err => {
    //                 log.debug(err)
    //             }
    //         );
    //
    //     return responseData;
    // }

}

export default new UrlUtil();
