import React, {Component} from 'react';
import {Image, ImageStyle, Platform, ScrollView, StyleProp, Text, View} from 'react-native';
import {appColors, appFont, appS} from "../../resources/styles/style";
import {Recipe} from "../data/network/swagger-gen";
// @ts-ignore
import isURL from 'validator/lib/isURL';
import ValidateUtil from "../util/ValidateUtil";
import {RecipeDetailScreen} from "../screens/RecipeDetailScreen";
import {RecipeDetailScreenModel} from "../data/models/screen/RecipeDetailScreenModel";
import AppG from "../util/AppG";
import ScreenSizeUtil from "../util/ScreenSizeUtil";
import ExpoUtil from "../util/ExpoUtil";
import {ATagLinkComponent} from "./ATagLinkComponent";

interface Props {
    navigation: any,
    recipes: Recipe[],
    onPress: (recipeId: string) => void | null,
    analyticsBtnName: string,
    border: boolean,
}

interface State {}

/**
 * 新着レシピで使う横スクロールのコンポーネント
 */
export class RecipeNewListHorizontalScrollComponent extends Component<Props, State> {

    // Propsのデフォルト値
    static defaultProps = {
        onPress: null,
        analyticsBtnName: '',
        border: true,
    };

    render() {
        // 行の作成
        const {navigation, recipes, onPress, analyticsBtnName, border} = this.props;
        if (ValidateUtil.isEmptyArray(recipes)) {
            return null;
        }
        let _style = {
            // flex: 1,
            borderWidth: border? 1 : 0,
            borderColor: appColors.borderGray,
            backgroundColor: appColors.white,
            width: AppG.window.width,
            height: 200,
        };
        if (ScreenSizeUtil.isWebSize()) {
            _style = {
                // flex: 1,
                borderWidth: border? 1 : 0,
                borderColor: appColors.borderGray,
                backgroundColor: appColors.white,
                width: appS.webMainContentsSize.width,
                height: 200,
            };
        }

        const _marginVertical = 10;

        return ExpoUtil._wrap(
            <View style={_style}>
                <ScrollView
                    style={{flex: 1, marginTop: _marginVertical}}
                    horizontal={true}
                    showsVerticalScrollIndicator={false}
                    showsHorizontalScrollIndicator={false}
                >
                    <View style={{
                        flex: 1,
                        flexDirection: 'row',
                        marginRight: appS.margins.side,
                    }}>

                        {recipes.map((recipe, index) => {
                            return (
                                <RecipeCellComponent
                                    navigation={navigation}
                                    recipe={recipe}
                                    onPress={onPress}
                                    analyticsBtnName={analyticsBtnName}
                                    key={`recipe_${analyticsBtnName}_${index}`}/>
                            );
                        })}
                    </View>
                </ScrollView>
            </View>
        );

    }
}












interface RecipeCellSmallProps {
    navigation: any,
    recipe: Recipe,
    onPress: (recipeId: string) => void | null,
    analyticsBtnName: string
}

interface RecipeCellSmallState {
}


class RecipeCellComponent extends Component<RecipeCellSmallProps, RecipeCellSmallState> {
    _productIconSize = 30;

    constructor(props: any) {
        super(props);
        this.state = {
        };
    };

    /**
     * 商品アイコンの作成
     * @param recipe
     * @param imageSize {width: number, height: number}
     * @private
     */
    _renderProductIcon = (recipe: Recipe, imageSize: {width: number, height: number}) => {

        let _userPlanOnly = recipe.userPlanOnly;
        // _userPlanOnly = 1;   // テスト用

        const _userPlanHeight = 16;
        const _productIconMargin = 4;
        const _style: StyleProp<ImageStyle> = {
            zIndex: 1,
            position: 'relative',
            top: -imageSize.width,
            width: imageSize.width,
            height: this._productIconSize,
            backgroundColor: appColors.transparent,
        };
        // 画像有り
        if (recipe.productMain != null && !ValidateUtil.isEmpty(recipe.productMain.image) && isURL(recipe.productMain.image)) {
            return (
                <View style={_style}>
                    <View style={{flexDirection: 'row'}}>
                        <View style={{width: imageSize.width - (Platform.OS == 'web'? 60 : 57)}}/>
                        <View
                            style={{
                                flexDirection: 'row-reverse',
                                height: _userPlanHeight,
                                borderRadius: 5,
                                marginLeft: 4,
                                backgroundColor: _userPlanOnly == 1? appColors.limeGreen: appColors.transparent,
                            }}
                        >
                            <Text style={{fontFamily: appFont.family.default, fontSize: appFont.size.iconFontSize, color: _userPlanOnly == 1? appColors.white: appColors.transparent, marginLeft: 8, marginRight: 8, marginTop: Platform.OS == 'android'? -6 : 0}}>会員限定</Text>
                        </View>
                    </View>

                    <View style={{
                        zIndex: 1,
                        position: 'relative',
                        top: imageSize.height - this._productIconSize - _userPlanHeight,
                        width: imageSize.width,
                        height: this._productIconSize,
                        backgroundColor: appColors.transparent,
                        flexDirection: "row",
                    }}>
                        <View style={{flex:1}}/>
                        <Image source={{uri: recipe.productMain.image}}
                               style={{
                                   width: this._productIconSize,
                                   height: this._productIconSize,
                                   borderRadius: 50,
                                   alignItems: 'center',
                                   justifyContent: 'center',
                                   marginRight: _productIconMargin,
                                   marginTop: -_productIconMargin,
                                   // borderColor: appColors.borderGray,
                                   // borderWidth: 1,
                               }}
                               resizeMode={'cover'}
                        />
                    </View>
                </View>
            );
        }

        // 画像無し（スペース確保）
        if (recipe.productMain == null || ValidateUtil.isEmpty(recipe.productMain.image) || !isURL(recipe.productMain.image)) {
            return (
                <View
                    style={_style}
                />
            );
        }
    }

    render() {
        const {navigation, recipe, onPress, analyticsBtnName} = this.props;

        let _paddingCell = appS.margins.side;
        let _cellSize = ScreenSizeUtil.isWebSize()? {width: 108 + _paddingCell * 2, height: 160 + _paddingCell * 2}: {width: 98 + _paddingCell * 2, height: 130 + _paddingCell * 2};
        let _imageSize = ScreenSizeUtil.isWebSize()? {width: 100, height: 100}: {width: 100, height: 100};
        let image: string = '';
        let source = require('../../resources/images/no_image.png');
        if (recipe.images != null && recipe.images.length != 0) {
            image = recipe.images[0].url;
        }
        if (image != '' && isURL(image)) {
            source = {uri: image};
        }

        return (
            <View style={{width: _cellSize.width, height: _cellSize.height}}>
                <ATagLinkComponent
                    href={`/RecipeDetailScreen/${recipe.id}`}
                    onPress={() => {
                        AppG.screenHistory.unshift(analyticsBtnName);
                        if (onPress == null) {
                            // 画面遷移前にリロード対象にセットする
                            // ExpoUtil.setShouldReloadAppTab('self');
                            let recipeDetailScreenModel = new RecipeDetailScreenModel();
                            recipeDetailScreenModel.recipeId = recipe.id.toString();
                            if (Platform.OS == 'web') {
                                navigation.navigate('RecipeDetailScreen', recipeDetailScreenModel);
                            } else {
                                // 戻る対策
                                navigation.push('RecipeDetailScreen', recipeDetailScreenModel);
                            }
                        } else {
                            onPress(recipe.id.toString());
                        }
                    }}
                    view={
                        <View>
                            <Image source={source}
                                   style={[{
                                       backgroundColor: 'white',
                                       width: _imageSize.width,
                                       height: _imageSize.height,
                                       borderRadius: 8,
                                   }]}
                                   resizeMode={'cover'}
                                   accessibilityLabel={recipe.name}
                            />

                            {/*商品アイコン*/}
                            {this._renderProductIcon(recipe, _imageSize)}

                            {/*レシピ名*/}
                            <View style={{marginTop: -this._productIconSize}}>
                                <Text
                                    style={{
                                        marginTop: 4,
                                        color: appColors.fontBlack,
                                        fontFamily: appFont.family.bold,
                                        height: Platform.OS == 'android'? 45 : 36,
                                        fontSize: appFont.size.listDescription,
                                        fontWeight: "bold"
                                        // letterSpacing: appStyles.line.space
                                    }}
                                    numberOfLines={2}
                                >
                                    {recipe.name}
                                </Text>
                            </View>
                        </View>
                    }
                    style={
                        {marginLeft: 0, marginTop: 10, backgroundColor: appColors.white, padding: _paddingCell, borderRadius: 5}
                    }
                />
            </View>
        );

    }
}

