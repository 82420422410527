import React, {Component} from 'react';
import {Image, Platform, TouchableOpacity, View} from 'react-native';
import {appS} from "../../resources/styles/style";
import ScreenSizeUtil from "../util/ScreenSizeUtil";
import LinkUtil from "../util/LinkUtil";
import AlertUtil from "../util/AlertUtil";
import AnalyticsUtil, {AnalyticsEventName, DOpenEventName, ShareBtnName} from "../util/firebase/AnalyticsUtil";
import {Maker, Product, Recipe} from "../data/network/swagger-gen";

interface Props {
    shareUrl: string,
    copyUrl: string,
    hashtags: string,   // カンマ区切り(twitter)
    image_url: string,  // pinterest
    description: string,    // pinterest
    showPinterest: boolean, // pinterest の表示
    // analytics用
    screenName: string,
    recipe: Recipe | null,
    product: Product | null,
    maker: Maker | null,
}

interface State { }

export class ShareComponent extends Component<Props, State> {

    _marginSize = ScreenSizeUtil.isWebSize()? 0 : appS.margin.size;

    // Propsのデフォルト値
    static defaultProps = {
        showPinterest: true,
    };

    _sendAnalytics = (btnName: string) => {
        AnalyticsUtil.sendAnalytics(
            DOpenEventName.share,
            AnalyticsEventName.share,  // アナリティクスのイベント名
            this.props.screenName,
            this.props.recipe,
            this.props.product,
            this.props.maker,
            null,
            null,
            null,
            null,
            btnName,
        );
    }

    /**
     * シェアボタン
     */
    _renderShareBtn = () => {
        if (Platform.OS != 'web') {
            return null;
        }

        const _snsIconSize = 40;
        const _snsIconRadius = 5;
        const shareUrl = this.props.shareUrl;
        const copyUrl = this.props.copyUrl;
        return (
            <View>
                {/*{!ScreenSizeUtil.isWebSize() && (<BorderComponent height={20} borderWidth={1} borderColor={appColors.spacerGray}/>)}*/}
                <View style={{flexDirection: "row", marginTop: 16, marginLeft: ScreenSizeUtil.isWebSize()?0:this._marginSize}}>
                    <TouchableOpacity
                        onPress={() => {
                            this._sendAnalytics(ShareBtnName.line);
                            LinkUtil.openLinkWithDefaultBrowser(`https://social-plugins.line.me/lineit/share?url=${shareUrl}`)}
                        }>
                        <Image source={require('../../resources/images/line_btn.png')}
                               style={[{
                                   width: _snsIconSize,
                                   height: _snsIconSize,
                                   borderRadius: _snsIconRadius,
                               }]}
                               resizeMode={'contain'}
                        />
                    </TouchableOpacity>
                    <View style={{height: 40, width: 8}}/>
                    <TouchableOpacity
                        onPress={() => {
                            this._sendAnalytics(ShareBtnName.twitter);
                            LinkUtil.openLinkWithDefaultBrowser(`https://twitter.com/share?url=${shareUrl}&hashtags=${this.props.hashtags}`)}
                        }>
                        <Image source={require('../../resources/images/twitter_share_icon.png')}
                               style={[{
                                   width: _snsIconSize,
                                   height: _snsIconSize,
                                   borderRadius: _snsIconRadius,
                               }]}
                               resizeMode={'contain'}
                        />
                    </TouchableOpacity>
                    <View style={{height: 40, width: 8}}/>
                    <TouchableOpacity
                        onPress={() => {
                            this._sendAnalytics(ShareBtnName.facebook);
                            LinkUtil.openLinkWithDefaultBrowser(`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}&amp;src=sdkpreparse`)}
                        }>
                        <Image source={require('../../resources/images/facebook_share_icon.png')}
                               style={[{
                                   width: _snsIconSize,
                                   height: _snsIconSize,
                                   borderRadius: _snsIconRadius,
                               }]}
                               resizeMode={'contain'}
                        />
                    </TouchableOpacity>
                    <View style={{height: 40, width: 8}}/>
                    {this.props.showPinterest && (
                        <TouchableOpacity
                            onPress={() => {
                                this._sendAnalytics(ShareBtnName.pinterest);
                                LinkUtil.openLinkWithDefaultBrowser(`http://www.pinterest.com/pin/create/button/?url=${shareUrl}&media=${this.props.image_url}&image_url=${this.props.image_url}&description=${encodeURIComponent(this.props.description)}`)}
                            }>
                            <Image source={require('../../resources/images/pinterest-share-icon.png')}
                                   style={[{
                                       width: _snsIconSize,
                                       height: _snsIconSize,
                                       borderRadius: _snsIconRadius,
                                   }]}
                                   resizeMode={'contain'}
                            />
                        </TouchableOpacity>
                    )}
                    {this.props.showPinterest && (
                        <View style={{height: 40, width: 8}}/>
                    )}
                    <TouchableOpacity
                        onPress={() => {
                            this._sendAnalytics(ShareBtnName.linkCopy);
                            navigator.clipboard.writeText(copyUrl).then(() => {
                                AlertUtil.alert('', 'リンクをコピーしました。');
                            });
                        }}>
                        <Image source={require('../../resources/images/link-copy-icon.png')}
                               style={[{
                                   width: _snsIconSize,
                                   height: _snsIconSize,
                                   borderRadius: _snsIconRadius,
                               }]}
                               resizeMode={'contain'}
                        />
                    </TouchableOpacity>
                    <View style={{height: 40, width: 8}}/>
                </View>
            </View>
        )
    }

    render() {
        return this._renderShareBtn();
    }
}
