import React, {Component} from 'react';
import {Image, Linking, Platform, ScrollView, Text, TouchableOpacity, View} from 'react-native';
import {appColors, appFont, appS} from "../../resources/styles/style";
import AppG from "../util/AppG";
import ScreenSizeUtil from "../util/ScreenSizeUtil";
import moment from "moment";
import PopupUtil from "../util/PopupUtil";
import Modal from "react-native-modal";
import AnalyticsUtil, {AnalyticsEventName, DOpenEventName} from "../util/firebase/AnalyticsUtil";
import {
    ProductReviewReminderModalDataEntityModel
} from "../data/entityModels/ProductReviewReminderModalDataEntityModel";
import ProductReviewReminderModalDataDao from "../data/dao/local/ProductReviewReminderModalDataDao";
import {POPUPApiFactory, Special, SpecialApiFactory} from "../data/network/swagger-gen";
import ValidateUtil from "../util/ValidateUtil";
import LinkUtil from "../util/LinkUtil";
import {AxiosResponse} from "axios";
import {getEnvVars} from "../../../environment";
import SpecialUtil from "../util/SpecialUtil";
import NavigationUtil from "../util/NavigationUtil";
import LoginUtil from "../util/LoginUtil";
import {SpacerComponent} from "./SpacerComponent";

interface Props {
    navigation: any,
}

interface State {
    showModal: boolean,
    showAnswer: boolean,
}

export class PopupModalComponent extends Component<Props, State> {

    _modalName = '';
    _title = '';

    constructor(props: any) {
        super(props);
        this.state = {
            showModal: false,
            showAnswer: false
        };
    }

    // Propsのデフォルト値
    static defaultProps = {
    };

    // 通常POPUP
    _popupType: number | null = null;   // popup の種類(0: 広告用ポップアップ, 1: 画像なしのアンケートポップアップ, 2: 画像ありのアンケートポップアップ)
    _tutorialUrlSource: any[] = [];
    _linkUrl: string[] = [];
    _popupId: number | null = null;
    // アンケート
    _questionId: number[] = [];
    _question: string[] = [];
    _answerCount: number[] = [];
    _questionImage: any = null;
    _answerAllCount = 0;

    componentDidMount() {
        const { routeName } = this.props.navigation.state;
        if (routeName != 'HomeScreen') {
            // Homeじゃなかったら読み込まない
            return;
        }

        // 表示したいものがあるか確認
        const func = () => POPUPApiFactory(AppG.getConfiguration(), AppG.getBasePath())
            .getPopups(routeName, AppG.analyticsHash!).then((popup) => {
                if (
                    popup != null
                    && popup.data != null
                    && (!ValidateUtil.isEmptyArray(popup.data.container) || popup.data.questionnaires != null))
                {
                    this._title = popup.data.name;
                    this._modalName = popup.data.name;
                    this._popupId = popup.data.id;

                    if (!ValidateUtil.isEmptyExact(popup.data.type_id)) {
                        this._popupType = popup.data.type_id!;
                    }

                    if (this._popupType == null || this._popupType == 0) {
                        // 通常POPUP _popupType == 0
                        popup.data.container.forEach(p => {
                            this._tutorialUrlSource.push({uri: p.image!});
                            this._linkUrl.push(p.link!);
                        })
                    } else if ((this._popupType == 1 || this._popupType == 2) && popup.data.questionnaires != null) {
                        // アンケート（3つ以上選択肢）(_popupType == 1)or （選択肢2つ）(_popupType == 2)
                        if (!ValidateUtil.isEmptyExact(popup.data.questionnaires.image)) {
                            this._questionImage = {uri: popup.data.questionnaires.image!};
                        }
                        popup.data.questionnaires.question?.forEach(q => {
                            this._questionId.push(q.id!);
                            this._question.push(q.question!);
                            this._answerCount.push(q.answer_count!);
                        })
                    }

                    // 表示するか確認
                    PopupUtil.showModalWidthPriority(
                        this.props.navigation,
                        'PopupModalComponent',
                        () => {
                            this.showModal();
                        }
                    );
                }
            });

        if (AppG.analyticsHash != null) {
            func()
        } else {
            LoginUtil.setAnalyticsHash(func);
        }
    }

    /**
     * 表示
     */
    showModal = () => {
        const { routeName } = this.props.navigation.state;
        AnalyticsUtil.sendAnalytics(
            DOpenEventName.modalShow,
            AnalyticsEventName.modalShow,  // アナリティクスのイベント名
            `${routeName}`,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            this._modalName,
        );
        this.setState({showModal: true});
    }

    /**
     * 閉じる
     */
    closeModal = () => {
        this._postRead();
        const productReviewReminderModalDataEntityModel = new ProductReviewReminderModalDataEntityModel();
        productReviewReminderModalDataEntityModel.hide_flg_product_review_reminder = '1';
        productReviewReminderModalDataEntityModel.last_show_date_product_review_reminder = moment().toString();
        ProductReviewReminderModalDataDao.addOrUpdate(productReviewReminderModalDataEntityModel);
        this.setState({showModal: false});
    }

    /**
     * 既読の送信
     */
    _postRead = () => {
        const func = () => POPUPApiFactory(AppG.getConfiguration(), AppG.getBasePath())
            .readPopup(this._popupId!, AppG.analyticsHash!).then(() => {
                // alert(`popupId = ${this._popupId} / a_id = ${AppG.analyticsHash}`);
            });

        // ここをコメントアウトすると何回でも表示される
        if (AppG.analyticsHash != null) {
            func()
        } else {
            LoginUtil.setAnalyticsHash(func);
        }
    }

    // 画像ありの回答の場合（回答が2つの場合）多いIDはどっちか
    _manyAnswerId: number | null = null;
    // 画像ありの回答の場合（回答が2つの場合）の多い回答数
    _manyAnswer: number | null = null;

    /**
     * 回答の送信
     * @param id
     */
    _postQuestion = (id: number) => {

        this._questionId.forEach((i, index) => {
            if (i == id) {
                this._answerCount[index] = this._answerCount[index] + 1;
            }
            this._answerAllCount = this._answerAllCount + this._answerCount[index];

            if (this._manyAnswer == null || this._manyAnswer < this._answerCount[index]) {
                this._manyAnswer = this._answerCount[index];
                this._manyAnswerId = i;
            }
        })

        POPUPApiFactory(AppG.getConfiguration(), AppG.getBasePath())
            .answerPopup(id).then(() => {
            // 回答の表示
            this.setState({showAnswer: true});
        })
    }

    _renderQuestionnaires = () => {
        let _answerBtnHeight = 76;
        if (this._popupType == 1 || this._popupType == 2) {
            return (
                <View style={{
                    marginLeft: appS.margins.side / 2,
                    width: appS.popup.width - 1,
                    backgroundColor: appColors.white,
                }}>
                    {/*アンケート（画像なし）*/}
                    {this._popupType == 1 && !this.state.showAnswer && (
                        <View
                            style={{
                                width: appS.popup.width - 1,
                                backgroundColor: appColors.white,
                                height: appS.popup.width - 10,
                            }}
                        >
                            <View style={{flex: 1}} />
                            <View style={{}}>
                                {this._questionId.map((questionId, index) => {
                                    return (
                                        <TouchableOpacity
                                            style={{
                                                height: appS.buttonSize.height,
                                                paddingLeft: 10,
                                                paddingRight: 10,
                                                backgroundColor: '#F2F0EE',
                                                borderRadius: 15,
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                margin: 3,
                                                marginBottom: ScreenSizeUtil.isWebSize()? 32 : 10,
                                            }}
                                            onPress={() => {
                                                this._postRead();
                                                const { routeName } = this.props.navigation.state;
                                                AnalyticsUtil.sendAnalytics(
                                                    DOpenEventName.modalTap,
                                                    AnalyticsEventName.modalTap,  // アナリティクスのイベント名
                                                    `${routeName}`,
                                                    null,
                                                    null,
                                                    null,
                                                    null,
                                                    null,
                                                    null,
                                                    null,
                                                    `${this._modalName}`,
                                                );
                                                // 回答の送信
                                                this._postQuestion(questionId);
                                            }}
                                            disabled={this.state.showAnswer}
                                        >
                                            <Text style={{
                                                fontSize: appFont.size.touch,
                                                fontFamily: appFont.family.bold,
                                                textAlign: 'center',
                                                color: appColors.black,
                                                fontWeight: "bold"
                                            }}>{this._question[index]}</Text>
                                        </TouchableOpacity>
                                    )
                                })}
                            </View>
                            <View style={{flex: 1}} />
                        </View>
                    )}

                    {/*回答*/}
                    {this._popupType == 1 && this.state.showAnswer && (
                        <View
                            style={{
                                width: appS.popup.width - 1,
                                backgroundColor: appColors.white,
                                height: appS.popup.width - 10,
                            }}
                        >
                            <View style={{flex: 1}} />
                            <View>
                                {this._questionId.map((questionId, index) => {
                                    return (
                                        <View style={{flexDirection: 'row'}}>
                                            <View
                                                style={{
                                                    height: appS.buttonSize.height,
                                                    backgroundColor: this._manyAnswerId == questionId ? '#FFB482' : '#F2F0EE',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    borderRadius: 15,
                                                    margin: 3,
                                                    marginBottom: ScreenSizeUtil.isWebSize()? 32 : 10,
                                                    width: (this._answerCount[index] / this._answerAllCount) * (ScreenSizeUtil.isWebSize() ? 420 : 260),
                                                }}
                                            />
                                            <View
                                                style={{
                                                    position: 'absolute',
                                                    left: 0,
                                                    flexDirection: 'row',
                                                    height: appS.buttonSize.height,
                                                    paddingLeft: 5,
                                                    paddingRight: 10,
                                                    backgroundColor: appColors.transparent,
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    margin: 3
                                                }}
                                            >
                                                <Text style={{
                                                    fontSize: appFont.size.default,
                                                    fontFamily: appFont.family.bold,
                                                    textAlign: 'center',
                                                    color: appColors.black,
                                                    fontWeight: "bold"
                                                }}>{this._question[index]}</Text>
                                            </View>
                                            <View
                                                style={{
                                                    position: 'absolute',
                                                    right: 0,
                                                    flexDirection: 'row',
                                                    height: appS.buttonSize.height,
                                                    paddingLeft: 10,
                                                    paddingRight: 5,
                                                    backgroundColor: appColors.transparent,
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    margin: 3
                                                }}
                                            >
                                                <View style={{
                                                    marginRight: -10,
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                    alignSelf: 'center',
                                                }} >
                                                    <Text style={{
                                                        lineHeight: 20,
                                                        paddingTop: Platform.OS == 'web' ? 0 : 17,
                                                        fontSize: 27,
                                                        fontFamily: appFont.family.bold,
                                                        textAlign: 'center',
                                                        color: appColors.black,
                                                        fontWeight: "bold"
                                                    }}>{Math.round(this._answerCount[index] * 100 / this._answerAllCount)}</Text>
                                                    <Text style={{
                                                        paddingTop: 8,
                                                        lineHeight: 20,
                                                        fontSize: 12,
                                                        fontFamily: appFont.family.bold,
                                                        textAlign: 'center',
                                                        color: appColors.black,
                                                        fontWeight: "bold"
                                                    }}>%</Text>
                                                </View>
                                            </View>
                                        </View>
                                    )
                                })}
                            </View>
                            <View style={{flex: 1}} />
                        </View>
                    )}
                    {/*アンケート（画像なし）ここまで*/}

                    {/*アンケート（画像あり）*/}
                    {this._popupType == 2 && !this.state.showAnswer  && (
                        <View
                            style={{
                                marginLeft: 5,
                            }}
                        >
                            {this._questionImage != null && (
                                <Image source={this._questionImage}
                                       key={`popup_image_question`}
                                       style={[{
                                           borderRadius: Platform.OS == 'android'? 3 : 15,
                                           width: appS.popup.width - 10,
                                           height: appS.popup.width - 10,
                                       }]}
                                       resizeMode={'stretch'}
                                />
                            )}
                            <View
                                style={{
                                    position: 'absolute',
                                    left: 0,
                                    width: appS.popup.width - 10,
                                    height: appS.popup.width - 10,
                                }}>
                                <View style={{
                                    width: appS.popup.width - 10,
                                    height: appS.popup.width - 96,
                                }} />
                                <View style={{flexDirection: 'row', marginLeft: 10,}}>
                                    {this._questionId.map((questionId, index) => {
                                        return (
                                            <TouchableOpacity
                                                style={{
                                                    height: _answerBtnHeight,
                                                    paddingLeft: 5,
                                                    paddingRight: 5,
                                                    backgroundColor: appColors.white,
                                                    borderColor: appColors.borderGray,
                                                    borderTopLeftRadius: index % 2 == 1 ? 0 : 20,
                                                    borderBottomLeftRadius: index % 2 == 1 ? 0 : 20,
                                                    borderTopRightRadius: index % 2 == 1 ? 20 : 0,
                                                    borderBottomRightRadius: index % 2 == 1 ? 20 : 0,
                                                    borderWidth: 2,
                                                    borderLeftWidth: index % 2 == 1 ? 0 : 2,
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    marginBottom: ScreenSizeUtil.isWebSize()? 32 : 3,
                                                    width: (appS.popup.width - 30)/this._questionId.length,
                                                }}
                                                onPress={() => {
                                                    this._postRead();
                                                    const { routeName } = this.props.navigation.state;
                                                    AnalyticsUtil.sendAnalytics(
                                                        DOpenEventName.modalTap,
                                                        AnalyticsEventName.modalTap,  // アナリティクスのイベント名
                                                        `${routeName}`,
                                                        null,
                                                        null,
                                                        null,
                                                        null,
                                                        null,
                                                        null,
                                                        null,
                                                        `${this._modalName}`,
                                                    );
                                                    // 回答の送信
                                                    this._postQuestion(questionId);
                                                }}
                                                disabled={this.state.showAnswer}
                                            >
                                                <Text
                                                    numberOfLines={2}
                                                    style={{
                                                        fontSize: appFont.size.popupAnswerBtn,
                                                        fontFamily: appFont.family.bold,
                                                        textAlign: 'center',
                                                        color: index % 2 == 1 ? '#FE9E40' : '#E84523',
                                                        fontWeight: "bold"
                                                    }}
                                                >{this._question[index]}</Text>
                                            </TouchableOpacity>
                                        )
                                    })}
                                </View>
                            </View>
                            <View style={{
                                width: appS.popup.width - 10,
                                height: 15,
                            }} />
                        </View>
                    )}
                    {this._popupType == 2 && this.state.showAnswer  && (
                        <View
                            style={{
                                marginLeft: 5,
                            }}
                        >
                            {this._questionImage != null && (
                                <Image source={this._questionImage}
                                       key={`popup_image_question`}
                                       style={[{
                                           width: appS.popup.width - 10,
                                           height: appS.popup.width - 10,
                                       }]}
                                       resizeMode={'stretch'}
                                />
                            )}
                            <View
                                style={{
                                    position: 'absolute',
                                    left: 0,
                                    width: appS.popup.width - 10,
                                    height: appS.popup.width - 10,
                                }}>
                                <View style={{
                                    width: appS.popup.width - 10,
                                    height: appS.popup.width - 96,
                                }} />
                                <View style={{flexDirection: 'row', marginLeft: 10,}}>
                                    {this._questionId.map((questionId, index) => {
                                        return (
                                            <View
                                                style={{
                                                    height: _answerBtnHeight,
                                                    backgroundColor: this._manyAnswerId == questionId ? '#FFB482' : '#FEF5D4',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    marginBottom: ScreenSizeUtil.isWebSize()? 32 : 3,
                                                    width: (this._answerCount[index] / this._answerAllCount) * (appS.popup.width - 30),
                                                    borderColor: appColors.borderGray,
                                                    borderTopLeftRadius: index % 2 == 1 ? 0 : 20,
                                                    borderBottomLeftRadius: index % 2 == 1 ? 0 : 20,
                                                    borderTopRightRadius: index % 2 == 1 ? 20 : 0,
                                                    borderBottomRightRadius: index % 2 == 1 ? 20 : 0,
                                                    borderWidth: 2,
                                                    borderLeftWidth: index % 2 == 1 ? 0 : 2,
                                                    borderRightWidth: index % 2 == 1 ? 2 : 0,
                                                }}
                                            />
                                        )
                                    })}

                                    <View
                                        style={{
                                            position: 'absolute',
                                            left: 10,
                                            flexDirection: 'row',
                                        }}>
                                        {this._questionId.map((questionId, index) => {
                                            return (
                                                <View
                                                    style={{
                                                        height: _answerBtnHeight,
                                                        backgroundColor: appColors.transparent,
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        margin: 3,
                                                        marginLeft: 10,
                                                        marginBottom: ScreenSizeUtil.isWebSize()? 32 : 3,
                                                        width: (ScreenSizeUtil.isWebSize() ? 420 : 240) / this._questionId.length,
                                                    }}
                                                >
                                                    <View
                                                        style={{
                                                            marginLeft: (ScreenSizeUtil.isWebSize() && index % 2 == 0) ? 10 : 0,
                                                            marginRight: (ScreenSizeUtil.isWebSize() && index % 2 == 1) ? 10 : 0,
                                                            alignSelf: index % 2 == 0 ? 'flex-start' : 'flex-end',
                                                            marginTop: -3,
                                                        }}
                                                    >
                                                        <Text
                                                            numberOfLines={2}
                                                            style={{
                                                                marginTop: Platform.OS == 'web' ? -4 : 0,
                                                                lineHeight: 20,
                                                                fontSize: appFont.size.popupAnswer,
                                                                fontFamily: appFont.family.bold,
                                                                textAlign: 'center',
                                                                color: appColors.black,
                                                                fontWeight: "bold"
                                                            }}
                                                        >{this._question[index]}</Text>
                                                        <View style={{
                                                            flexDirection: 'row',
                                                            alignItems: 'center',
                                                            alignSelf: 'center',
                                                        }} >
                                                            <Text style={{
                                                                lineHeight: 20,
                                                                paddingTop: Platform.OS == 'web' ? 0 : 17,
                                                                fontSize: 30,
                                                                fontFamily: appFont.family.bold,
                                                                textAlign: 'center',
                                                                color: appColors.black,
                                                                fontWeight: "bold"
                                                            }}>{Math.round(this._answerCount[index] * 100 / this._answerAllCount)}</Text>
                                                            <Text style={{
                                                                paddingTop: 8,
                                                                lineHeight: 20,
                                                                fontSize: appFont.size.touch,
                                                                fontFamily: appFont.family.bold,
                                                                textAlign: 'center',
                                                                color: appColors.black,
                                                                fontWeight: "bold"
                                                            }}>%</Text>
                                                        </View>
                                                    </View>
                                                </View>
                                            )
                                        })}
                                    </View>
                                </View>
                            </View>
                            <View style={{
                                width: appS.popup.width - 10,
                                height: 15,
                            }} />
                        </View>
                    )}
                    {/*アンケート（画像あり）ここまで*/}
                </View>
            )}
    }

    /**
     * Android用暫定対応
     */
    _renderModalForAndroid = () => {

        let _viewStyle: any = {
            borderRadius: 10,
            backgroundColor: appColors.white,
            paddingLeft: appS.margins.side / 2,
            width: appS.popup.width + appS.margins.side * 2,
        };

        if (this._popupType == null || this._popupType == 0) {
            _viewStyle = {
                borderRadius: 10,
                backgroundColor: appColors.white,
                paddingLeft: appS.margins.side / 2,
                width: appS.popup.width + appS.margins.side * 2,
                height: Platform.OS == 'android'? appS.popup.width + 80 : appS.popup.width + 40,
            };
        }

        return (
            <Modal
                isVisible={this.state.showModal}
                deviceWidth={AppG.window.width}
                deviceHeight={AppG.window.height}
                style={{
                    marginTop: -AppG.window.height / 4,
                    alignSelf: 'center',
                }}
                onBackdropPress={() => {
                    this.closeModal();
                }}
            >
                <View style={{
                    position: 'relative',
                    width: appS.popup.width,
                    height: appS.popup.height,
                    top: ScreenSizeUtil.isWebSize()? 50 : (Platform.OS == 'web'? 40 : 0),
                    left: 0,
                    backgroundColor: appColors.transparent,
                    alignItems: 'center',
                    alignSelf: 'center',
                    alignContent: 'center',
                    marginTop: (AppG.window.height - appS.popup.height) / 2 - appS.footer.height + (ScreenSizeUtil.isWebSize()? 0 : appS.header.height),
                }}>


                    <View style={_viewStyle}>
                        <Text
                            style={{
                                alignSelf: 'center',
                                marginTop: 10,
                                marginBottom: 10,
                                fontFamily: appFont.family.bold,
                                width: appS.popup.width + appS.margins.side * 2 - 80,
                                fontSize: appFont.size.popupTitle,
                                fontWeight: "bold"
                            }}
                        >{this._title}</Text>
                        {(this._popupType == null || this._popupType == 0) && (
                            <View style={{
                                marginLeft: appS.margins.side / 2,
                                width: appS.popup.width - 1,
                                backgroundColor: appColors.white,
                            }}>
                                <ScrollView style={{
                                    marginLeft: appS.margins.side,
                                    height: appS.popup.width,
                                    width: appS.popup.width - 10,
                                    backgroundColor: appColors.white,
                                }}>
                                    {/*通常のPOPUP*/}
                                    {this._tutorialUrlSource.map((source, index) => {
                                        // 画像複数
                                        return (
                                            <TouchableOpacity
                                                onPress={() => {
                                                    this._postRead();
                                                    if (this._linkUrl == null || this._linkUrl.length < index || ValidateUtil.isEmptyExact(this._linkUrl[index])) {
                                                        // 空文字はリターン
                                                        return;
                                                    }
                                                    const { routeName } = this.props.navigation.state;
                                                    AnalyticsUtil.sendAnalytics(
                                                        DOpenEventName.modalTap,
                                                        AnalyticsEventName.modalTap,  // アナリティクスのイベント名
                                                        `${routeName}`,
                                                        null,
                                                        null,
                                                        null,
                                                        null,
                                                        null,
                                                        null,
                                                        null,
                                                        `${this._modalName}_${this._linkUrl[index]}`,
                                                    );
                                                    if (ValidateUtil.isMyDomain(this._linkUrl[index]) || ValidateUtil.isWpDomain(this._linkUrl[index])) {
                                                        // ドメイン一緒ならアプリ内で開く
                                                        LinkUtil.openLink(this._linkUrl[index], this.props.navigation);
                                                    } else {
                                                        // ドメイン別ならアプリ外で開く
                                                        LinkUtil.openLinkWithDefaultBrowser(this._linkUrl[index]);
                                                    }
                                                    this.closeModal();
                                                }}
                                                disabled={(this._linkUrl == null || this._linkUrl.length < index || ValidateUtil.isEmptyExact(this._linkUrl[index]))}
                                            >
                                                <Image source={source}
                                                       key={`popup_image_${index}`}
                                                       style={[{
                                                           width: appS.popup.width - 10,
                                                           height: appS.popup.width - 10,
                                                       }]}
                                                       resizeMode={'stretch'}
                                                />
                                            </TouchableOpacity>
                                        )
                                    })}
                                    {/*通常のPOPUPここまで*/}

                                </ScrollView>
                            </View>
                        )}

                        {this._renderQuestionnaires()}

                        {/*閉じるボタン*/}
                        <TouchableOpacity
                            style={{
                                zIndex: 202,
                                position: 'absolute',
                                backgroundColor: appColors.white,
                                right: 5,
                                top: 5,
                                borderRadius: 50,
                            }}
                            onPress={() => {
                                this.closeModal();
                            }}
                        >
                            <Image source={require('../../resources/images/close_icon.png')}
                                   style={[{
                                       backgroundColor: appColors.transparent,
                                       width: 40,
                                       height: 40,
                                   }]}
                                   resizeMode={'cover'}
                            />
                        </TouchableOpacity>
                    </View>


                </View>
            </Modal>
        );
    }

    /**
     * ボタン一個ありModal
     */
    _renderModal = () => {
        if (!this.state.showModal) {
            return null;
        }

        let _viewStyle: any = {
            borderRadius: 10,
            backgroundColor: appColors.white,
            paddingLeft: appS.margins.side / 2,
            width: appS.popup.width + appS.margins.side * 2,
        };

        if (this._popupType == null || this._popupType == 0) {
            _viewStyle = {
                borderRadius: 10,
                backgroundColor: appColors.white,
                paddingLeft: appS.margins.side / 2,
                width: appS.popup.width + appS.margins.side * 2,
                height: Platform.OS == 'android'? appS.popup.width + 80 : appS.popup.width + 40,
            };
        }


        return (
            <View
                style={{zIndex: 201, position: 'absolute', backgroundColor: appColors.opacityGray, height: AppG.window.height + 60, width: AppG.window.width}}
            >
                <View style={{
                    position: 'relative',
                    width: appS.popup.width,
                    height: appS.popup.height,
                    top: ScreenSizeUtil.isWebSize()? 50 : (Platform.OS == 'web'? 40 : 0),
                    left: 0,
                    backgroundColor: appColors.transparent,
                    alignItems: 'center',
                    alignSelf: 'center',
                    alignContent: 'center',
                    marginTop: (AppG.window.height - appS.popup.height) / 2 - appS.footer.height + (ScreenSizeUtil.isWebSize()? 0 : appS.header.height),
                }}>


                    <View style={_viewStyle}>
                        <Text
                            style={{
                                alignSelf: 'center',
                                marginTop: 10,
                                marginBottom: 10,
                                fontFamily: appFont.family.bold,
                                width: appS.popup.width + appS.margins.side * 2 - 80,
                                fontSize: appFont.size.popupTitle,
                                fontWeight: "bold"
                            }}
                        >{this._title}</Text>

                        {(this._popupType == null || this._popupType == 0) && (
                            <ScrollView style={{
                                marginLeft: appS.margins.side,
                                height: appS.popup.height,
                                width: appS.popup.width - 1,
                                backgroundColor: appColors.white,
                            }}>
                                {/*通常のPOPUP*/}
                                {this._tutorialUrlSource.map((source, index) => {
                                    // 画像複数
                                    return (
                                        <TouchableOpacity
                                            onPress={() => {
                                                this._postRead();
                                                if (this._linkUrl == null || this._linkUrl.length < index || ValidateUtil.isEmptyExact(this._linkUrl[index])) {
                                                    // 空文字はリターン
                                                    return;
                                                }

                                                const { routeName } = this.props.navigation.state;
                                                AnalyticsUtil.sendAnalytics(
                                                    DOpenEventName.modalTap,
                                                    AnalyticsEventName.modalTap,  // アナリティクスのイベント名
                                                    `${routeName}`,
                                                    null,
                                                    null,
                                                    null,
                                                    null,
                                                    null,
                                                    null,
                                                    null,
                                                    `${this._modalName}_${this._linkUrl[index]}`,
                                                );

                                                // alert(`${(ValidateUtil.isMyDomain(this._linkUrl[index]) || ValidateUtil.isWpDomain(this._linkUrl[index]))} / ${this._linkUrl[index]}`);

                                                if (Platform.OS =='web' && routeName == 'HomeScreen' && ValidateUtil.isRankingUrl(this._linkUrl[index])) {
                                                    this.closeModal();
                                                    AppG.tabIndex = 4;
                                                    // location.href = 'http://localhost:19006/#/HomeScreen_Ranking';
                                                    location.href = `${this._linkUrl[index]}`;
                                                    NavigationUtil.homeRefresh();
                                                    NavigationUtil.wideHeaderRefresh();
                                                } else if (Platform.OS =='web' && routeName == 'HomeScreen' && ValidateUtil.isSpecialUrl(this._linkUrl[index])) {
                                                    SpecialApiFactory(AppG.getConfiguration(), AppG.getBasePath())
                                                        .getSpecialFindAll().then((specialsRes: AxiosResponse<Special[]>) => {
                                                        if (specialsRes != null && !ValidateUtil.isEmptyArray(specialsRes.data)) {
                                                            specialsRes.data.forEach(special => {
                                                                // WEBで特集のリンクだったら特集へ
                                                                // @ts-ignore
                                                                const {REACT_APP_DOMAIN_URL} = getEnvVars();
                                                                if (Platform.OS == 'web' && this._linkUrl[index] == `${REACT_APP_DOMAIN_URL}/#/SpecialDetailScreen?specialId=${special.id}`) {
                                                                    SpecialUtil.openSpecial(special, this.props.navigation);
                                                                }
                                                            });
                                                        }
                                                    });
                                                } else if (ValidateUtil.isMyDomain(this._linkUrl[index]) || ValidateUtil.isWpDomain(this._linkUrl[index])) {
                                                    // ドメイン一緒ならアプリ内で開く
                                                    LinkUtil.openLink(this._linkUrl[index], this.props.navigation);
                                                } else {
                                                    // ドメイン別ならアプリ外で開く
                                                    LinkUtil.openLinkWithDefaultBrowser(this._linkUrl[index]);
                                                }
                                                this.closeModal();
                                            }}
                                            disabled={(this._linkUrl == null || this._linkUrl.length < index || this._linkUrl[index] == null)}
                                        >
                                            <Image source={source}
                                                   key={`popup_image_${index}`}
                                                   style={[{
                                                       width: appS.popup.width - 10,
                                                       height: appS.popup.width - 10,
                                                   }]}
                                                   resizeMode={'stretch'}
                                            />
                                        </TouchableOpacity>
                                    )
                                })}
                                {/*通常のPOPUPここまで*/}

                            </ScrollView>
                        )}


                        {this._renderQuestionnaires()}

                        {/*閉じるボタン*/}
                        <TouchableOpacity
                            style={{
                                zIndex: 202,
                                position: 'absolute',
                                backgroundColor: appColors.white,
                                right: 5,
                                top: 5,
                                borderRadius: 50,
                            }}
                            onPress={() => {
                                this.closeModal();
                            }}
                        >
                            <Image source={require('../../resources/images/close_icon.png')}
                                   style={[{
                                       backgroundColor: appColors.transparent,
                                       width: 40,
                                       height: 40,
                                   }]}
                                   resizeMode={'cover'}
                            />
                        </TouchableOpacity>
                    </View>


                </View>
            </View>
        );

    }

    render() {
        if (Platform.OS == 'android') {
            return this._renderModalForAndroid();
        }
        return this._renderModal();
    }
}
