import * as React from 'react';
import * as Localization from 'expo-localization';
import {I18n} from 'i18n-js';
import en from './locales/en';
import ja from './locales/ja';
import {Platform} from "react-native";
import ValidateUtil from "../../tsx/util/ValidateUtil";

async function setup() {
    // When a value is missing from a language it'll fallback to another language with the key present.
    I18n.fallbacks = true;  // システム言語に対応した者が無かったら en を使う(true)

    // Set the key-value pairs for the different languages you want to support.
    I18n.translations = {
        en,
        ja
    };

    const isEmptyArray = (value: any[] | undefined | null) => {
        if (value === undefined || value === null || value.length === 0) {
            return true;
        }
        return false;
    }

    // Set the locale once at the beginning of your app.
    // I18n.region = Localization.region;
    if (Platform.OS == 'android') {
        let localization = await Localization.getLocalizationAsync();
        let sAray = localization.locale.split("-");
        if (isEmptyArray(sAray)) {
            I18n.locale = 'ja';
            return;
        }
        if (sAray[0] == "ja" || sAray[0] == "en") {
            I18n.locale = localization.locale.split("-")[0];
            return;
        }
        I18n.locale = 'ja';
    } else if (Platform.OS == 'web') {
        if (Localization.locale == null) {
            I18n.locale = 'ja';
            return;
        }
        if (Localization.locale == "ja" || Localization.locale == "en") {
            I18n.locale = Localization.locale;
            return;
        }
        I18n.locale = 'ja';
    } else {
        let sAray = Localization.locale.split("-");
        if (isEmptyArray(sAray)) {
            I18n.locale = 'ja';
            return;
        }
        if (sAray[0] == "ja" || sAray[0] == "en") {
            I18n.locale = sAray[0];
            return;
        }
        I18n.locale = 'ja';

        // I18n.locale = 'ja'; // テスト用
    }

    // return { t }
}

/**
 * 言語設定 ja-JP の ja 部分を取得
 */
export const getLocalization = async () => {
    const isEmptyArray = (value: any[] | undefined | null) => {
        if (value === undefined || value === null || value.length === 0) {
            return true;
        }
        return false;
    }

    if (Platform.OS == 'android') {
        let localization = await Localization.getLocalizationAsync();
        let sAray = localization.locale.split("-");
        if (isEmptyArray(sAray)) {
            return 'ja';
        }
        return sAray[0];
    } else if (Platform.OS == 'web') {
        if (Localization.locale == null) {
            I18n.locale = 'ja';
            return;
        }
        if (Localization.locale == "ja" || Localization.locale == "en") {
            I18n.locale = Localization.locale;
            return;
        }
        I18n.locale = 'ja';
    } else {
        let sAray = Localization.locale.split("-");
        if (isEmptyArray(sAray)) {
            return 'ja';
        }
        return sAray[0];
    }
};

/**
 * 言語設定が日本語になってるかチェック
 */
export const isJapanese = async () => {
    // return true; // テスト用
    if (Platform.OS == 'android') {
        let localization = await Localization.getLocalizationAsync();
        let sAray = localization.locale.split("-");
        return sAray[0] != 'en';
    } else {
        let sAray = Localization.locale.split("-");
        return sAray[0] != 'en';
    }
};

setup().then();

export default new I18n({
    en: en,
    ja: ja
});
