import {
    Chat,
    ChatApiFactory,
    Configuration,
    CustomerUnitPriceId, Keyword,
    MajorRestaurantAreaId,
    MasterApiFactory,
    News,
    NumOfRestaurantId,
    ProductApiFactory,
    RestaurantFormat,
    RestaurantLocation,
    User,
    UserGenderEnum,
    UserUserAttributeEnum
} from "../data/network/swagger-gen";
// @ts-ignore
import {getEnvVars, log} from '../../../environment';
import {Dimensions, Platform} from "react-native";
import {SearchScreenModel} from "../data/models/screen/SearchScreenModel";
import {AnalyticsUserJpModel} from "../data/models/analytics/AnalyticsUserJpModel";
import {AxiosResponse} from "axios";
import ValidateUtil from "./ValidateUtil";
import DateUtil from "./DateUtil";
import {TutorialDataEntityModel} from "../data/entityModels/TutorialDataEntityModel";
import moment from "moment";
import TutorialDataDao from "../data/dao/local/TutorialDataDao";
import {FooterDataEntityModel} from "../data/entityModels/FooterDataEntityModel";
import FooterDataDao from "../data/dao/local/FooterDataDao";

/**
 * アプリのグローバルData格納
 */
class AppG {
    test: string = 'hoge';

    // テストユーザー
    testUserId: number = 1;
    testUserName: String = 'て';
    // testUserId: number = 65;  // 65
    // testUserName: String = 'テスト飲食人';  // テスト飲食人

    /**
     * window size
     */
    window: { width: number, height: any } = Dimensions.get('window');
    /**
     * 更新があるかチェック用
     */
    windowBefore: { width: number, height: any } = Dimensions.get('window');

    /**
     * 画面名履歴
     */
    screenHistory: string[] = [];

    /**
     * 特集の履歴を残すかどうか(Webの時ドメイン変わると true になる)
     */
    isWriteReferrer: boolean = true;

    /**
     * アナリティクストラッキングOKフラグ
     */
    enableTrackingFlg: boolean = false;

    /**
     * アプリ起動済みか否か
     */
    startedApp: boolean = false;

    /**
     * ログインしたかどうかアナリティクス用
     */
    loggedIn: boolean = false;

    /**
     * HomeやWebHeaderのTabIndex
     */
    tabIndex: number = 0;
    /**
     * 開いてるタブ名
     */
    tabName: string = 'HomeTabNav';
    /**
     * ユーザーは起動中のみ保持するデータ
     */
    user: User | null = null;
    /**
     * アナリティクス送信用日本語データ作成
     */
    userAnalyticsJp: AnalyticsUserJpModel | null = null;
    /**
     * Header の Authorization に書く Barear token、 firebase から受け取る
     */
    apiKey: string | null = null;

    /** 通知からやってきて、アナリティクスをもう送信したかどうか */
    sentAnalyticsFromNotificationAlready: boolean = true;

    /** 通知から入ってきたときのログインリトライ回数。この回数失敗したらAnalyticsをログインせずに送信する */
    retryNumLoginForAnalyticsNotification: number = 4;

    /**
     * TODO 時間内のでこれで。いつか修正したい。
     * お知らせ
     */
    news: News | null = null;

    /**
     * 検索条件。Web戻る用
     */
    searchParam: SearchScreenModel | null = null;

    /**
     * footer のバッヂ 0 で非表示
     */
    badgeHome: number = 0;

    /**
     * footer のバッヂ 0 で非表示
     */
    badgeSearch: number = 0;

    /**
     * footer のバッヂ 0 で非表示
     */
    badgeFollow: number = 0;

    /**
     * チャットのバッヂ 0 で非表示
     */
    badgeChat: number | null = null;

    /**
     * カートのバッヂ 0 で非表示
     */
    badgeCart: number | null = null;

    /**
     * サンプル依頼履歴のバッヂ 0 で非表示
     */
    badgeHistory: number | null = null;

    /**
     * アナリティクス用ハッシュ
     */
    analyticsHash: string | undefined | null = null;

    /**
     * 検索履歴
     */
    searchHistory: Keyword[] | undefined | null = null;

    /**
     * テストアカウントかどうか
     */
    isTestAccounts = (): boolean => {
        if (
            this.user?.mail == 'seroritl@gmail.com'
            || this.user?.mail == 'kanno-m@retail-branding.co.jp'
            || this.user?.mail == 'chrome-no.5@docomo.ne.jp'
        ) {
            // テストアカウント
            return true;
        }
        return false;
    }

    /**
     * BasePathの作成
     */
    getBasePath = (): string => {
        // @ts-ignore
        const {REACT_APP_HTTP, REACT_APP_DOMAIN_IOS, REACT_APP_DOMAIN_ANDROID} = getEnvVars();

        let basePath = REACT_APP_HTTP;
        if (Platform.OS == 'android') {
            basePath = basePath + REACT_APP_DOMAIN_ANDROID;
        } else {
            basePath = basePath + REACT_APP_DOMAIN_IOS;
        }
        return basePath;
    }

    /**
     * Configuration作成
     */
    getConfiguration = (): any => {
        if (this.apiKey === null) {
            return {'basePath': this.getBasePath()};
        }

        // log.debug(`basePath = ${this.getBasePath()}`);
        // log.debug(`HeaderToken = ${this.apiKey}`);
        // Barear は付け足す
        return {'basePath': this.getBasePath(), 'apiKey': `Bearer ${this.apiKey}` };
    }

    /**
     * Analytics BasePathの作成
     */
    getAnalyticsBasePath = (): string => {
        // @ts-ignore
        const {REACT_APP_HTTP, REACT_APP_DOMAIN_ANALYTICS} = getEnvVars();

        let basePath = REACT_APP_HTTP + REACT_APP_DOMAIN_ANALYTICS;
        return basePath;
    }

    /**
     * Analytics Configuration作成
     */
    getAnalyticsConfiguration = (): any => {
        if (this.apiKey === null) {
            return {'basePath': this.getAnalyticsBasePath()};
        }

        // log.debug(`basePath = ${this.getBasePath()}`);
        // log.debug(`HeaderToken = ${this.apiKey}`);
        // Barear は付け足す
        return {'basePath': this.getAnalyticsBasePath(), 'apiKey': `Bearer ${this.apiKey}` };
    }

    /**
     * フッターバッジの取得
     */
    getFooterBadge = (callback: (() => void) = () => {}) => {
        if (this.user == null) {
            this.badgeChat = 0;
            this.badgeHistory = 0;
            return;
        }

        FooterDataDao.get().then(footerData => {
            let footerDataEntityModel = new FooterDataEntityModel();
            if (footerData != null) {
                footerDataEntityModel = footerData;
                if (footerDataEntityModel.footer_home_badge == null) {
                    footerDataEntityModel.footer_home_badge = '';
                }
                if (footerDataEntityModel.footer_follow_badge == null) {
                    footerDataEntityModel.footer_follow_badge = '';
                }
                if (footerDataEntityModel.footer_chat_badge == null) {
                    footerDataEntityModel.footer_chat_badge = '';
                }
                if (footerDataEntityModel.footer_cart_badge == null) {
                    footerDataEntityModel.footer_cart_badge = '';
                }
                if (footerDataEntityModel.footer_history_badge == null) {
                    footerDataEntityModel.footer_history_badge = '';
                }
            } else {
                footerDataEntityModel.footer_home_badge = '';
                footerDataEntityModel.footer_follow_badge = '';
                footerDataEntityModel.footer_chat_badge = '';
                footerDataEntityModel.footer_cart_badge = '';
                footerDataEntityModel.footer_history_badge = '';

            }

            // チャットのバッヂの表示非表示
            ChatApiFactory(this.getConfiguration(), this.getBasePath())
                .getChats(0, 100).then((chatsRes: AxiosResponse<Chat[]>) => {
                if (chatsRes != null && !ValidateUtil.isEmptyArray(chatsRes.data)) {
                    // console.log(chatsRes.data);
                    const _chats = chatsRes.data.filter(_chat => !_chat.isRead);

                    if (_chats != null) {
                        // 未読あり
                        this.badgeChat = _chats.length;
                    } else {
                        this.badgeChat = 0;
                    }
                } else {
                    this.badgeChat = 0;
                }

                if (this.badgeChat == 0) {
                    footerDataEntityModel.footer_chat_badge = '0';
                } else {
                    footerDataEntityModel.footer_chat_badge = '1';
                }
                FooterDataDao.addOrUpdate(footerDataEntityModel);
                callback();
            }).catch(e => {
                if (this.badgeChat == null) {
                    this.badgeChat = 0;
                    footerDataEntityModel.footer_chat_badge = '0';
                    FooterDataDao.addOrUpdate(footerDataEntityModel);
                }
            })

            log.debug(`--------------- badgeChat --------------`)
            log.debug(this.badgeChat)

            // カートのサンプル依頼履歴のバッヂの表示非表示
            ProductApiFactory(this.getConfiguration(), this.getBasePath())
                .requeetedProductReviews().then((reviewsRes) => {
                if (reviewsRes != null && !ValidateUtil.isEmptyArray(reviewsRes.data)) {
                    const _history = reviewsRes.data.filter(value => (value.responseStatus == 2 && value.reviewed == 0));
                    if (_history != null) {
                        // 未レビューあり
                        this.badgeHistory = _history.length;
                    } else {
                        this.badgeHistory = 0;
                    }
                } else {
                    this.badgeHistory = 0;
                }

                if (this.badgeHistory == 0) {
                    footerDataEntityModel.footer_history_badge = '0';
                } else {
                    footerDataEntityModel.footer_history_badge = '1';
                }
                FooterDataDao.addOrUpdate(footerDataEntityModel);
                callback();
            }).catch(e => {
                if (this.badgeHistory == null) {
                    this.badgeHistory = 0;
                    footerDataEntityModel.footer_history_badge = '0';
                    FooterDataDao.addOrUpdate(footerDataEntityModel);
                }
            })
        });



    }

    /**
     * user から user 情報の日本語名を取得
     */
    setAnalyticJpData = () => {
        if (this.user == null) {
            return;
        }

        if (this.userAnalyticsJp == null) {
            this.userAnalyticsJp = new AnalyticsUserJpModel();
        }

        // 属性
        if (
            this.userAnalyticsJp != null
            && this.user.userAttribute != null
            && this.userAnalyticsJp.userAttribute == null
        ) {
            switch (this.user.userAttribute) {
                case UserUserAttributeEnum.Normal:
                    this.userAnalyticsJp.userAttribute = '一般ユーザー';
                    break;
                case UserUserAttributeEnum.Cook:
                    this.userAnalyticsJp.userAttribute = '飲食人';
                    break;
                default:
                    break;
            }
        }

        // 性別
        if (
            this.userAnalyticsJp != null
            && this.user.gender != null
            && this.userAnalyticsJp.gender == null
        ) {
            switch (this.user.gender) {
                case UserGenderEnum.Male:
                    this.userAnalyticsJp.gender = '男性';
                    break;
                case UserGenderEnum.Female:
                    this.userAnalyticsJp.gender = '女性';
                    break;
                default:
                    break;
            }
        }

        // 年代
        if (
            this.userAnalyticsJp != null
            && this.user.birthday != null
            && this.userAnalyticsJp.age == null
        ) {
            // 生年月日
            if (ValidateUtil.isYYYYMMDD(this.user.birthday)) {
                const _age = DateUtil.calcAge(this.user.birthday);
                this.userAnalyticsJp.age = `${_age}`;
                if (_age <= 9) {
                    this.userAnalyticsJp.ages = '0代';
                } else if (10 <= _age && _age <= 19) {
                    this.userAnalyticsJp.ages = '10代';
                } else if (20 <= _age && _age <= 29) {
                    this.userAnalyticsJp.ages = '20代';
                } else if (30 <= _age && _age <= 39) {
                    this.userAnalyticsJp.ages = '30代';
                } else if (40 <= _age && _age <= 49) {
                    this.userAnalyticsJp.ages = '40代';
                } else if (50 <= _age && _age <= 59) {
                    this.userAnalyticsJp.ages = '50代';
                } else if (60 <= _age && _age <= 69) {
                    this.userAnalyticsJp.ages = '60代';
                } else if (70 <= _age && _age <= 79) {
                    this.userAnalyticsJp.ages = '70代';
                } else if (80 <= _age && _age <= 89) {
                    this.userAnalyticsJp.ages = '80代';
                } else if (90 <= _age && _age <= 99) {
                    this.userAnalyticsJp.ages = '90代';
                } else if (100 <= _age && _age <= 109) {
                    this.userAnalyticsJp.ages = '100代';
                } else if (110 <= _age && _age <= 119) {
                    this.userAnalyticsJp.ages = '110代';
                } else if (120 <= _age && _age <= 129) {
                    this.userAnalyticsJp.ages = '120代';
                } else if (130 <= _age && _age <= 139) {
                    this.userAnalyticsJp.ages = '130代';
                } else if (140 <= _age && _age <= 149) {
                    this.userAnalyticsJp.ages = '140代';
                } else if (150 <= _age && _age <= 159) {
                    this.userAnalyticsJp.ages = '150代';
                }
            }
        }

        // 店舗所在地
        if (
            this.userAnalyticsJp != null
            && this.user.restaurantLargeLocationId != null
            && this.userAnalyticsJp!.restaurantLargeLocation == null
        ) {
            MasterApiFactory(this.getConfiguration(), this.getBasePath())
                .getAllRestaurantLocationMaster().then((restaurantLocationRes: AxiosResponse<RestaurantLocation[]>) => {
                if (restaurantLocationRes != null && restaurantLocationRes.data != null) {
                    restaurantLocationRes.data.forEach((restaurantLocation: RestaurantLocation) => {
                        if (this.user?.restaurantLargeLocationId == restaurantLocation.large.id) {
                            this.userAnalyticsJp!.restaurantLargeLocation = restaurantLocation.large.name;

                            if (this.user?.restaurantMediumLocationId != null) {
                                restaurantLocation.large.medium?.forEach((_medium: any) => {
                                    if (this.user?.restaurantMediumLocationId == _medium.id) {
                                        this.userAnalyticsJp!.restaurantMediumLocation = _medium.name;
                                    }
                                })
                            }
                        }
                    });
                }
            });
        }

        // 店舗業態
        if (
            this.userAnalyticsJp != null
            && this.user.restaurantLargeFormatId != null
            && this.userAnalyticsJp!.restaurantLargeFormat == null
        ) {
            MasterApiFactory(this.getConfiguration(), this.getBasePath())
                .getAllRestaurantFormatMaster().then((restaurantFormatRes: AxiosResponse<RestaurantFormat[]>) => {
                if (restaurantFormatRes != null && restaurantFormatRes.data != null) {
                    restaurantFormatRes.data.forEach((restaurantFormat: RestaurantFormat) => {
                        if (this.user?.restaurantLargeFormatId == restaurantFormat.large.id) {
                            this.userAnalyticsJp!.restaurantLargeFormat = restaurantFormat.large.name;

                            if (this.user?.restaurantMediumFormatId != null) {
                                restaurantFormat.large.medium?.forEach((_medium: any) => {
                                    if (this.user?.restaurantMediumFormatId == _medium.id) {
                                        this.userAnalyticsJp!.restaurantMediumFormat = _medium.name;
                                    }
                                })
                            }

                        }
                    });
                }
            });
        }

        // 客単価
        if (
            this.userAnalyticsJp != null
            && this.user.customerUnitPriceId != null
            && this.userAnalyticsJp!.customerUnitPrice == null
        ) {
            MasterApiFactory(this.getConfiguration(), this.getBasePath())
                .getAllCustomerUnitPriceIdsMaster().then((customerUnitPriceIdRes: AxiosResponse<CustomerUnitPriceId[]>) => {
                if (customerUnitPriceIdRes != null && customerUnitPriceIdRes.data != null) {
                    customerUnitPriceIdRes.data.forEach((customerUnitPriceId: CustomerUnitPriceId) => {
                        if (this.user?.customerUnitPriceId == customerUnitPriceId.id) {
                            this.userAnalyticsJp!.customerUnitPrice = customerUnitPriceId.name;
                        }
                    });
                }
            });
        }

        // 店舗規模
        if (
            this.userAnalyticsJp != null
            && this.user.numOfRestaurantId != null
            && this.userAnalyticsJp!.numOfRestaurant == null
        ) {
            MasterApiFactory(this.getConfiguration(), this.getBasePath())
                .getAllNumOfRestaurantIdMaster().then((numOfRestaurantIdRes: AxiosResponse<NumOfRestaurantId[]>) => {
                if (numOfRestaurantIdRes != null && numOfRestaurantIdRes.data != null) {
                    numOfRestaurantIdRes.data.forEach((numOfRestaurantId: NumOfRestaurantId) => {
                        if (this.user?.numOfRestaurantId == numOfRestaurantId.id) {
                            this.userAnalyticsJp!.numOfRestaurant = numOfRestaurantId.name;
                        }
                    });
                }
            });
        }

        // 店舗展開エリア
        if (
            this.userAnalyticsJp != null
            && this.user.majorRestaurantAreaId != null
            && this.userAnalyticsJp!.majorRestaurantArea == null
        ) {
            MasterApiFactory(this.getConfiguration(), this.getBasePath())
                .getAllMajorRestaurantAreaIdMaster().then((majorRestaurantAreaIdRes: AxiosResponse<MajorRestaurantAreaId[]>) => {
                if (majorRestaurantAreaIdRes != null && majorRestaurantAreaIdRes.data != null) {
                    majorRestaurantAreaIdRes.data.forEach((majorRestaurantAreaId: MajorRestaurantAreaId) => {
                        if (this.user?.majorRestaurantAreaId == majorRestaurantAreaId.id) {
                            this.userAnalyticsJp!.majorRestaurantArea = majorRestaurantAreaId.name;
                        }
                    });
                }
            });
        }


    }
}

export default new AppG();
