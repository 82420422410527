import React from 'react';
import {Image, Text, TextStyle, View,} from 'react-native';
import {appColors, appFont} from "../../resources/styles/style";
import ExpoUtil from "../util/ExpoUtil";
import AppG from "../util/AppG";

let _selectTabNumber = 9;

// @ts-ignore
export const getTabBarIcon = (navigation, focused, tintColor: 'activeTintColor' | 'inactiveTintColor') => {
    const { routeName } = navigation.state;
    let selected = true;
    if (tintColor == 'inactiveTintColor') {
        selected = false;
    }
    const _iconSize = 45;
    let resource;
    let _badge = false;
    let _badgeCount: number | null = null;
    if (routeName === 'HomeTabNav') {
        _badge = false;
        _badgeCount = AppG.badgeHome;
        if(selected) {
            resource = require('../../resources/images/09.footer/i_footer_home_on.png');
        } else {
            resource = require('../../resources/images/09.footer/i_footer_home.png');
        }
    } else if (routeName == 'SearchTabNav') {
        _badge = false;
        _badgeCount = AppG.badgeSearch;
        if(selected) {
            resource = require('../../resources/images/footer_search_black.png');
        } else {
            resource = require('../../resources/images/footer_search_gray.png');
        }
    } else if (routeName === 'FollowTabNav') {
        _badge = false;
        _badgeCount = AppG.badgeFollow;
        if(selected) {
            resource = require('../../resources/images/09.footer/i_footer_like_on.png');
        } else {
            resource = require('../../resources/images/09.footer/i_footer_like.png');
        }
    } else if (routeName === 'ChatTabNav') {
        _badge = true;
        _badgeCount = AppG.badgeChat;
        if(selected) {
            resource = require('../../resources/images/09.footer/i_footer_chat_on.png');
        } else {
            resource = require('../../resources/images/09.footer/i_footer_chat.png');
        }
    } else if (routeName === 'CartTabNav') {
        _badge = false;
        _badgeCount = AppG.badgeCart;
        if(selected) {
            resource = require('../../resources/images/09.footer/i_footer_cart_on.png');
        } else {
            resource = require('../../resources/images/09.footer/i_footer_cart.png');
        }
    } else if (routeName === 'SampleRequestHistoryNav') {
        _badge = true;
        _badgeCount = AppG.badgeHistory;
        if(selected) {
            resource = require('../../resources/images/09.footer/ico_footer_list_on.png');
        } else {
            resource = require('../../resources/images/09.footer/ico_footer_list.png');
        }
    } else {
        return null;
    }

    return (
        <View>
            {/*バッチがない時*/}
            {(_badgeCount == null || !_badge || _badgeCount == 0) && (
                <Image source={resource}
                       style={[{
                           marginTop: 5,
                           width: _iconSize,
                           height: _iconSize,
                           marginBottom: -2,
                       }]}
                       resizeMode='cover'/>
            )}
            {/*バッチがあるとき*/}
            {_badge && _badgeCount != null && _badgeCount != 0 && (
                <Image source={resource}
                       style={[{
                           marginTop: 5 + 8,
                           width: _iconSize,
                           height: _iconSize,
                           marginBottom: -2,
                       }]}
                       resizeMode='cover'/>
            )}
            {_badge && _badgeCount != null && _badgeCount != 0 && (
                <View
                    style={{
                        position: "relative",
                        top: -_iconSize + 4 ,
                        left: _iconSize - 12,
                        backgroundColor: appColors.red,
                        width: 8,
                        height: 8,
                        borderRadius: 50,
                        borderWidth: 1,
                        borderColor: appColors.red,
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                    <Text style={{color: appColors.white, fontSize: 10}}>{' '}</Text>
                </View>
            )}
        </View>
    );
};

// @ts-ignore
export const getTabBarLabel = (navigation) => {
    const { routeName } = navigation.state;

    return null;

    // const _style: TextStyle = {
    //     fontSize: 9,
    //     fontFamily: appFont.family.default,
    //     color: appColors.fontBlack,
    //     marginTop: ExpoUtil.isIPad()? -18 : 0,
    //     marginBottom: ExpoUtil.isIPad()? 20 : 5,
    //     textAlign: 'center'
    // }

    // if (routeName === 'HomeTabNav') {
    //     return (<Text style={_style}>ホーム</Text>);
    // } else if (routeName == 'SearchTabNav') {
    //     return (<Text style={_style}>検索</Text>);
    // } else if (routeName === 'FollowTabNav') {
    //     return (<Text style={_style}>お気に入り</Text>);
    // } else if (routeName === 'ChatTabNav') {
    //     return (<Text style={_style}>メッセージ</Text>);
    // } else if (routeName === 'CartTabNav') {
    //     return (<Text style={_style}>サンプルかご</Text>);
    // } else if (routeName === 'SampleRequestHistoryNav') {
    //     return (<Text style={_style}>依頼履歴</Text>);
    // } else {
    //     return null;
    // }
};
