import axios from 'axios';
// @ts-ignore
import axiosJsonpAdapter from 'axios-jsonp';
import I18n from "../../../../resources/language/i18n";
import AlertUtil from "../../../util/AlertUtil";
// @ts-ignore
import {log} from '../../../../../environment';
import {AddressModel} from "../../models/vender/AddressModel";
import ValidateUtil from "../../../util/ValidateUtil";
import {Platform} from "react-native";

class AddressApiNetwork {

    /**
     * 郵便番号から住所取得
     * http://zipcloud.ibsnet.co.jp/doc/api
     */
    getAddressByZipcode = async (zipcode: string): Promise<AddressModel[] | null> => {
        let url =`https://zipcloud.ibsnet.co.jp/api/search`;
        let params = {
            zipcode: zipcode
        };
        let response: AddressModel[] | null = null;
        let _config = Platform.OS == 'web' ?
            {
                // Web
                adapter: axiosJsonpAdapter, // jsonp
                headers: { "Content-Type": "application/json" },
                params: params
            } : {
                // App
                headers: { "Content-Type": "application/json" },
                params: params
            }
        const _ = await axios.get(
            url,
            _config
        ).then(res => {
            if (!ValidateUtil.isEmptyArray(res.data) && res.data.results != null) {
                const addressModels: AddressModel[] = [];
                // @ts-ignore
                res.data.results.forEach(result => {
                    const addressModel = new AddressModel();
                    for (let item in result) {
                        // @ts-ignore
                        addressModel[item] = result[item];
                    }
                    addressModels.push(addressModel);
                });
                response = addressModels;
            }
        }).catch(e => {
            AlertUtil.alert(`${I18n.t('errorNetworkTitle')}：MASTER_GL`, `${I18n.t('errorNetwork')}`);
            log.debug(`---------------Network Error (Vendor.API.Address.get)--------------------`);
            log.debug(`url is ${url}`);
            log.debug(`param is `);
            log.debug(params);
            log.debug(e);
        });
        return response;
    };


    // 利用不可
    // /**
    //  * 都道府県番号から住所取得
    //  * https://www.land.mlit.go.jp/webland/api.html#todofukenlist
    //  */
    // getAddressByStateCode = async (stateCode: string): Promise<CityModel[] | null> => {
    //     let url =`https://www.land.mlit.go.jp/webland/api/CitySearch`;
    //     let params = {
    //         area: stateCode,    // 都道府県コード
    //     };
    //     let response: CityModel[] | null = null;
    //     const _ = await axios.get(
    //         url,
    //         {
    //             // headers: { "Content-Type": "application/json" },
    //             params: params
    //         }).then(res => {
    //         if (res.data != null && !ValidateUtil.isEmptyArray(res.data.data)) {
    //             const cityModels: CityModel[] = [];
    //             // @ts-ignore
    //             res.data.data.forEach(result => {
    //                 const cityModel = new CityModel();
    //                 for (let item in result) {
    //                     // @ts-ignore
    //                     cityModel[item] = result[item];
    //                 }
    //                 cityModels.push(cityModel);
    //             });
    //             response = cityModels;
    //         }
    //     }).catch(e => {
    //         AlertUtil.alert(`${I18n.t('errorNetworkTitle')}：MASTER_GL`, `${I18n.t('errorNetwork')}`);
    //         log.debug(`---------------Network Error (Vendor.API.City.get)--------------------`);
    //         log.debug(`url is ${url}`);
    //         log.debug(`param is `);
    //         log.debug(params);
    //         log.debug(e);
    //     });
    //     return response;
    // };


}

export default new AddressApiNetwork();
