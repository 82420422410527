import React from 'react'
import {
    ActivityIndicator,
    Alert,
    Dimensions,
    Image,
    Keyboard,
    KeyboardAvoidingView,
    Platform,
    RefreshControl,
    SafeAreaView,
    ScrollView,
    Text,
    TextInput,
    TouchableOpacity,
    View
} from 'react-native'
// @ts-ignore
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scrollview';
import {BaseScreen} from "./BaseScreen";
import LoginUtil from "../util/LoginUtil";
import LoginDataDao from "../data/dao/local/LoginDataDao";
import {CustomHeaderComponentSmall} from "../components/small/CustomHeaderComponentSmall";
import ChatConstant from "../constants/ChatConstant";
import {MessageViewComponent} from "../components/MessageViewComponent";
// @ts-ignore
import KeyboardSpacer from 'react-native-keyboard-spacer';
import UrlUtil from "../util/UrlUtil";
import {MessagesDao} from "../data/dao/firestore/MessagesDao";
import {Messages} from "../data/firestoreEntities/Messages";
import ScreenSizeUtil from "../util/ScreenSizeUtil";
import {LoginDataEntityModel} from "../data/entityModels/LoginDataEntityModel";
import {IndicatorComponent} from "../components/IndicatorComponent";
import * as ImagePicker from "expo-image-picker";
import ImageUtil from "../util/ImageUtil";
import AlertUtil from "../util/AlertUtil";
import {ChatsDao} from "../data/dao/firestore/ChatsDao";
import {Chats} from "../data/firestoreEntities/Chats";
import {
    ChatApiFactory,
    ImageApiFactory,
    ImageFile,
    Maker,
    MakerApiFactory,
    MessageApiFactory,
    Product,
    ProductApiFactory,
    User,
    UserApiFactory
} from "../data/network/swagger-gen";
import {ChatScreenModel, InquiryStatusEnum, InquiryWordsEnum} from "../data/models/screen/ChatScreenModel";
import ValidateUtil from "../util/ValidateUtil";
import AppG from "../util/AppG";
import AppGlobalDataUtil from "../util/AppG";
import {AxiosResponse} from "axios";
import {appColors, appFont, appS} from "../../resources/styles/style";
// @ts-ignore
import isURL from 'validator/lib/isURL';
import ExpoUtil from "../util/ExpoUtil";
import {AuthUtil} from "../util/firebase/AuthUtil";
import AnalyticsUtil, {AnalyticsEventName, DOpenEventName} from "../util/firebase/AnalyticsUtil";
import I18n from "../../resources/language/i18n";
import {SpacerComponent} from "../components/SpacerComponent";
import Constants from "expo-constants";
import * as MediaLibrary from 'expo-media-library';
import {ReportScreenModel} from "../data/models/screen/ReportScreenModel";
import {CustomHeaderComponentWide} from "../components/wide/CustomHeaderComponentWide";
import {WideScreenAdComponent} from "../components/wide/WideScreenAdComponent";
import * as Notifications from 'expo-notifications'
import {WideSubTitleComponent} from "../components/wide/WideSubTitleComponent";
import {WideScreenSNSComponent} from "../components/wide/WideScreenSNSComponent";
import {Camera} from "expo-camera";
import LinkUtil from "../util/LinkUtil";


interface Props {
    navigation: any, // ナビゲーション用
}

interface State {
    resizeWindow: boolean,  // 値に意味はなし。windowサイズの拡大縮小をrenderに送るためのもの
    isLoaded: boolean,
    isShowPhotoBottomModal: boolean,

    // チャット
    isChatLoaded: boolean,
    myUser: User | null,    // 自分のユーザー情報
    opponentUser: Maker | null,  // 相手のユーザー情報
    isDeletedChat: boolean,
    isMessagesLoading: boolean,
    messages: {
        id: string,
        userId: string,
        name: string,
        file: string,  // url
        date: Date,
        messageType: string, // ChatConstant.MESSAGE_TYPE()
        text: string,
        unread: boolean,
    }[],
    tabStatus: 'info' | 'message',
    inputText: string,  // メッセージ本体
    inputUrl: string,   // 画像やFILEのURL
    inputMessageType: string,   // ChatConstant.MESSAGE_TYPE()
    messageScrollArea: {
        scrollView: ScrollView | null, // 議事録エリアのスクロールView
        autoScrollEnable: boolean,  // 自動スクロールがONかどうか
    },
    inputTextHeight: number,
}

export class ChatScreen extends BaseScreen<Props, State> {
    _resizeTimer = setInterval(() => {
        // window 拡大縮小を反映させる
        AppG.window = Dimensions.get('window');
        if (AppG.window.width != AppG.windowBefore.width || AppG.window.height != AppG.windowBefore.height) {
            this.setState({'resizeWindow': true});
            AppG.windowBefore = Dimensions.get('window');
        }
        LoginUtil.setRefreshedScreenFlg(false);
    }, 100);

    _flameSize = appS.flame.size;    // 左右の margin
    _marginSize = ScreenSizeUtil.isWebSize()? 0 : appS.margin.size;

    _loginData = new LoginDataEntityModel();
    _date = new Date();

    _param = new ChatScreenModel();
    _numOfMessagesOneLoad = 20;

    _unsubMessageSnapshot: any = null; // firebase snap デタッチ用

    _browserBackListener = (e: any) => {

    };

    // @ts-ignore
    constructor(props) {
        super(props);
        this.state = {
            resizeWindow: true,  // 値に意味はなし。windowサイズの拡大縮小をrenderに送るためのもの
            isLoaded: false,
            isShowPhotoBottomModal: false,
            isChatLoaded: false,
            myUser: null,    // 自分のユーザー情報
            opponentUser: null,
            isDeletedChat: false,
            isMessagesLoading: false,
            messages: [],
            tabStatus: 'message',
            inputText: '',
            inputUrl: '',
            inputMessageType: ChatConstant.MESSAGE_TYPE().TEXT,
            messageScrollArea: {scrollView: null, autoScrollEnable: true},
            inputTextHeight: 50,
        };
    }

    /** データロードされるまで再送信を繰り返すPvAnalytics */
    _sentPvAnalyticsTimer: any;
    /**
     * 画面開いたときのアナリティクス送信
     */
    _sendPvAnalytics = () => {
        this._sentPvAnalyticsTimer = setInterval(() => {
            if (this.state.opponentUser != null) {
                clearInterval(this._sentPvAnalyticsTimer);
                AnalyticsUtil.sendAnalytics(
                    DOpenEventName.openPage,
                    AnalyticsEventName.openPage,  // アナリティクスのイベント名
                    'ChatScreen',
                    null,
                    null,
                    this.state.opponentUser != null ? this.state.opponentUser: null,
                    null,
                );
            }
        },  100);
    }

    componentDidMount() {
        this._param = this._getParams();

        if (Platform.OS == 'web') {
            window.addEventListener('popstate', this._browserBackListener);
        }

        LoginUtil.interruptOpenScreen(this.props.navigation,
            () => {
                LoginDataDao.get().then(loginData => {
                    // Web 戻るでも呼ばれる
                    if (Platform.OS == 'web') {
                        // Analytics
                        this._sendPvAnalytics();
                    }

                    if (Platform.OS != 'web') {
                        // アイコンバッヂの更新
                        Notifications.setBadgeCountAsync((AppG.badgeChat == null? 0 : AppG.badgeChat) + (AppG.badgeHistory == null? 0 : AppG.badgeHistory));
                    }
                    AppG.getFooterBadge();

                    // URL Scheme で外部リンクから起動したときの処理と、画面遷移でこの画面を開いたときの処理
                    LoginUtil.setDefaultListener(
                        this.props.navigation,
                        () => {
                            // Webはここにこない
                            if (Platform.OS != 'web') {
                                // Analytics
                                this._sendPvAnalytics();
                            }

                            ExpoUtil.doReloadAppTab(() => {
                                this.setState({'isLoaded': false});
                                this._loadContents().then(() => {
                                    this.setState({'isLoaded': true});
                                });
                                AppG.getFooterBadge();
                            });
                        });
                    if (loginData != null) {
                        this._loginData = loginData;
                        if (this._param.chatId == '') {
                            this.setState({'isDeletedChat': true});
                        } else {
                            this._loadContents();
                        }
                        this.setState({'isLoaded': true});
                    }
                });
            });

        // // ヘッダー左ボタンの機能設定
        // HeaderUtil.setLeftOnPress(() => {
        //     this.props.navigation.navigate('EventSearchSelectScreen');
        // });

    }

    componentWillUnmount () {
        clearTimeout(this._resizeTimer);

        if (this._unsubMessageSnapshot != null) {
            this._unsubMessageSnapshot();
        }

        if (Platform.OS === 'web') {
            window.removeEventListener('popstate', this._browserBackListener);
        }

        LoginUtil.removeDefaultListener(this.props.navigation);
    };

    componentDidUpdate () {
        // レンダー完了時に呼ばれる
    }

    /**
     * パラムの取得
     * @private
     */
    _getParams = () => {
        let chatScreenModel: ChatScreenModel = UrlUtil.getParamModel(new ChatScreenModel(), this.props.navigation);
        if (ValidateUtil.isEmptyExact(chatScreenModel.makerId)) {
            chatScreenModel.makerId = JSON.stringify(this.props.navigation.getParam('makerId', ''));
        }
        return chatScreenModel;
    };

    /**
     * コンテンツのロード
     * @private
     */
    _loadContents = async () => {
        this._param = this._getParams();

        if (this._param == null || ValidateUtil.isEmptyExact(this._param.makerId)) {
            return;
        }

        // ユーザー
        const getUser = UserApiFactory(AppG.getConfiguration(), AppG.getBasePath())
            .getUser().then((userRes: AxiosResponse<User>) => {
                if (userRes != null && userRes.data != null) {
                    this.setState({'myUser': userRes.data});
                }
            });

        // メーカー
        const getMaker = MakerApiFactory(AppG.getConfiguration(), AppG.getBasePath())
            .getMaker(this._param.makerId!, 2).then((makerRes: AxiosResponse<Maker>) => {
                if (makerRes != null && makerRes.data != null) {
                    this.setState({'opponentUser': makerRes.data});

                    if (this._param.inquiryStatus != null) {
                        this.state.inputText;
                        switch (this._param.inquiryStatus) {
                            case InquiryStatusEnum.RECIPE:
                                this.setState({inputText: InquiryWordsEnum.RECIPE});
                                break;
                            case InquiryStatusEnum.PRODUCT:
                                if (this._param.inquiryProductId == null) {
                                    break;
                                }
                                ProductApiFactory(AppGlobalDataUtil.getConfiguration(), AppGlobalDataUtil.getBasePath())
                                    .getProduct(
                                        this._param.inquiryProductId,
                                        0,
                                        3
                                    ).then((productRes: AxiosResponse<Product>) => {
                                    if (productRes != null && productRes.data != null) {
                                        const product: Product = productRes.data;
                                        const text = `商品情報：ID ${product.id}【${product.name}】\n${InquiryWordsEnum.PRODUCT}`
                                        this.setState({inputText: text});
                                    }
                                });
                                break;
                            case InquiryStatusEnum.MAKER:
                                if (this._param.inquiryMakerId == null) {
                                    break;
                                }
                                const text = `${InquiryWordsEnum.MAKER}`
                                this.setState({inputText: text});
                                break;
                            default:
                                break;
                        }
                    }
                }
            });



        // 実行
        await Promise.all([
            getUser,
            getMaker,
        ]).then(() => {
            // メッセージロード
            this._loadMessages();
        });

    };

    /**
     * スクロールViewのRefをセット(ScrollViewのrefで呼び出す)
     * @param element
     */
    _setMessageScrollAreaRef = (element: any) => {
        this.setState({messageScrollArea: {scrollView: element, autoScrollEnable: this.state.messageScrollArea.autoScrollEnable}});
    };

    /**
     * 議事録エリアを一番下にスクロールさせる
     * @param animated アニメーションありなし
     * @private
     */
    _scrollToEndMessageArea = (animated: boolean = true) => {
        this.setState({isLoaded: true});
        if (this.state.messageScrollArea.autoScrollEnable) {
            // スクロールで iOS は setTimeout が必要
            setTimeout(() => {
                // scrollTo は入れ子の scroll では動かないので注意
                if (this.state.messageScrollArea.scrollView != null) {
                    this.state.messageScrollArea.scrollView?.scrollToEnd({animated: animated});
                }
            }, 50);
        }
    };

    /**
     * インジケーターの下にスクロール
     * @param animated
     * @private
     */
    _scrollToEndOfActiveIndicator = (animated: boolean = true) => {
        if (this.state.messageScrollArea.autoScrollEnable) {
            // スクロールで iOS は setTimeout が必要
            setTimeout(() => {
                // scrollTo は入れ子の scroll では動かないので注意
                if (this.state.messageScrollArea.scrollView != null) {
                    this.state.messageScrollArea.scrollView?.scrollTo({
                        y: appS.activityIndicator.height,
                        animated: animated
                    });
                }
            }, 1000);
        }
    };

    _loadOldMessages = (date: Date, scroll: boolean) => {
        const successFunc = (doc: any) => {
            // 既読にする
            ChatApiFactory(AppG.getConfiguration(), AppG.getBasePath())
                .readChat(this._param.chatId!).then(
                // 特に何もしない
            );

            if (doc == null) {
                // 過去分が無い場合
                this.setState({'isChatLoaded': true});
                return;
            }

            if (this.state.messages.some(val => val.id == doc.id)) {
                // 既に取得済みなら抜ける
                return;
            }
            this.state.messages.unshift({
                id: doc.id,
                userId: doc.data().userId,
                name: doc.data().userId == AuthUtil.getUid() ? `${this.state.myUser?.name}`: `${this.state.opponentUser?.name}`,
                file: doc.data().url,
                date: doc.data().createdAt,
                messageType: doc.data().messageType, // ChatConstant.MESSAGE_TYPE()
                text: doc.data().text,
                unread: false,
            });

            this.setState({'messages': this.state.messages});
            if (scroll) {
                this._scrollToEndMessageArea(false);
            }

            let updateChatFunc = (data: any) => {
                // 既読にする(firebase)
                let chatModel = new Chats();
                chatModel.data = ChatsDao.instance.margeModelForUnreadMessages(data, [], `${AuthUtil.getUid()}`)  // 自分に送られた分を既読にする
                ChatsDao.instance.addOrUpdate(chatModel, {chat: this._param.chatId}, () => {});
            };
            ChatsDao.instance.get(updateChatFunc, this._param.chatId!);

            this.setState({'isChatLoaded': true});
        };

        MessagesDao.instance.getBeforeDate(
            successFunc,
            {chat: this._param.chatId!},
            date,
            this._numOfMessagesOneLoad // 読み込むメッセージ数
        );
    };

    /**
     * メッセージのロード
     * @private
     */
    _loadMessages = () => {
        // 画面を開いたときの過去のメッセージを取得
        this._loadOldMessages(this._date, true);

        // 画面開いてから追加されたメッセージを取得するためにスナップショットを取得
        const addFunc = (doc: any) => {
            if (this.state.messages.some(val => val.id == doc.id)) {
                // 既に取得済みなら抜ける
                return;
            }
            this.state.messages.push({
                id: doc.id,
                userId: doc.data().userId,
                name: doc.data().userId == AuthUtil.getUid() ? `${this.state.myUser?.name}`: `${this.state.opponentUser?.name}`,
                file: doc.data().url,
                date: doc.data().createdAt,
                messageType: doc.data().messageType, // ChatConstant.MESSAGE_TYPE()
                text: doc.data().text,
                unread: false,
            });
            this.setState({'messages': this.state.messages});
            // 既読にする
            ChatApiFactory(AppG.getConfiguration(), AppG.getBasePath())
                .readChat(this._param.chatId!).then(
                // 特に何もしない
            );

            let updateChatFunc = (data: any) => {
                // 既読にする(firebase)
                let chatModel = new Chats();
                chatModel.data = ChatsDao.instance.margeModelForUnreadMessages(data, [], `${AuthUtil.getUid()}`)  // 自分に送られた分を既読にする
                ChatsDao.instance.addOrUpdate(chatModel, {chat: this._param.chatId}, () => {});
            };
            ChatsDao.instance.get(updateChatFunc, this._param.chatId!);


            this._scrollToEndMessageArea();
        };
        const updateFunc= (doc: any) => {
            this.state.messages.forEach((message, index, array) => {
                if (message.id == doc.id) {
                    array[index] = {
                        id: doc.id,
                        userId: doc.data().userId,
                        name: doc.data().userId == AuthUtil.getUid() ? `${this.state.myUser?.name}`: `${this.state.opponentUser?.name}`,
                        file: doc.data().url,
                        date: doc.data().createdAt,
                        messageType: doc.data().messageType, // ChatConstant.MESSAGE_TYPE()
                        text: doc.data().text,
                        unread: false,
                    };
                }
            });

            this.setState({'messages': this.state.messages});
        };
        const deleteFunc= (doc: any) => {
            this.state.messages.forEach((message, index, array) => {
                if (message.id == doc.id) {
                    array.splice(index, 1); // 削除
                }
            });
            this.setState({'messages': this.state.messages});
        };
        this._unsubMessageSnapshot = MessagesDao.instance.snapshot(addFunc, updateFunc, deleteFunc, {chat: this._param.chatId!}, this._date);
    };



    /**
     * カメラかフォトライブラリを選択するモーダルの描画
     * @private
     */
    _renderImagePickerModal = () => {
        if (!this.state.isShowPhotoBottomModal) {
            return null;
        }

        const list = [
            {
                title: 'カメラ',
                onPress: () => {
                    this._getPermissionCameraAsync();
                    this.setState({'isShowPhotoBottomModal': false});
                },
                titleStyle: {
                    textAlign:'center',
                },
            },
            {
                title: 'フォトライブラリ',
                onPress: () => {
                    this._getPermissionPhotoAsync();
                    this.setState({'isShowPhotoBottomModal': false});
                },
                titleStyle: {
                    textAlign:'center',
                },
            },
            {
                title: `キャンセル`,
                onPress: () => {
                    this.setState({'isShowPhotoBottomModal': false});
                },
                titleStyle: {
                    textAlign:'center',
                    color: 'red',
                },
            }
        ];

        let _width = ScreenSizeUtil.isWebSize()? AppG.window.width *2 / 5 : AppG.window.width *4 / 5;

        if (Platform.OS == 'web' && !ScreenSizeUtil.isWebSize()) {
            // Webのスマホサイズ
            _width = AppG.window.width * 2 / 5;
        }

        return (
            <TouchableOpacity
                style={{zIndex: 99, position: 'absolute', backgroundColor: appColors.opacityGray, height: AppG.window.height, width: AppG.window.width}}
                onPress={() => {
                    this.setState({'isShowPhotoBottomModal': false});
                }}
            >
                <View style={{
                    position: 'relative',
                    height: 230,
                    width: _width,
                    top: AppG.window.height / 2 -165,
                    left: AppG.window.width - _width - (AppG.window.width - _width)/2,
                    backgroundColor: appColors.red,
                    alignItems: 'center',
                }}>
                    <View style={{
                        height: 230,
                        width: _width,
                        marginRight: -this._marginSize,
                        marginLeft: -this._marginSize,
                        backgroundColor: appColors.white,
                    }}>
                        <SpacerComponent height={20}/>

                        {list.map((l, index) => {
                            return (
                                <TouchableOpacity
                                    key={`camera_button_${index}`}
                                    style={[
                                        {
                                            marginLeft: ScreenSizeUtil.isWebSize()? 0 : 16,
                                            marginRight: ScreenSizeUtil.isWebSize()? 0 : 16,
                                            marginBottom: index == 1? 32 : 16,
                                            height: appS.button.height,
                                            backgroundColor: index == 2? appColors.gray: appColors.black,
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }
                                    ]}
                                    onPress={() => {
                                        l.onPress();
                                    }}
                                >
                                    <Text
                                        key={`camera_button_text_${index}`}
                                        style={{
                                            color: appColors.white,
                                            fontWeight: 'bold',
                                            fontSize: appS.button.text}}>{l.title}</Text>
                                </TouchableOpacity>
                            );
                        })}

                    </View>
                </View>
            </TouchableOpacity>
        );
    };

    /**
     * カメラパーミッション取得
     */
    _getPermissionCameraAsync = async () => {
        if (Platform.OS != 'web') {
            const permission = await Camera.getCameraPermissionsAsync();
            const _status = permission.status;
            let finalStatus = _status;
            if (permission.canAskAgain && _status !== 'granted') {
                // 通知不許可で再確認OKなら
                const { status } = await Camera.requestCameraPermissionsAsync();
                finalStatus = status;
            }
            if (finalStatus !== 'granted') {
                // 通知不許可なら
                Alert.alert(I18n.t('permissionDeniedCameraRoll'));
                return null;
            }
            this._takePhoto().then();
        } else {
            this._takePhoto().then();
        }
    };

    /**
     * フォトライブラリパーミッション取得
     */
    _getPermissionPhotoAsync = async () => {
        if (Platform.OS != 'web') {
            const permission = await MediaLibrary.getPermissionsAsync();
            const _status = permission.status;
            let finalStatus = _status;
            if (permission.canAskAgain && _status !== 'granted') {
                // 通知不許可で再確認OKなら
                const { status } = await MediaLibrary.requestPermissionsAsync();
                finalStatus = status;
            }
            if (finalStatus !== 'granted') {
                // 通知不許可なら
                Alert.alert(I18n.t('permissionDeniedCameraRoll'));
                return null;
            }
            this._pickImage().then();
        } else {
            this._pickImage().then();
        }
    };

    /**
     * 画像保存
     * @param imageFile
     */
    _saveImage = (imageFile: {viewImage: string, postImage: string, imageType: string}) => {
        switch (imageFile.imageType) {
            case 'jpg':
                imageFile.imageType = 'jpeg';
                break;
            case 'jpeg':
                break;
            case 'png':
                break;
            case 'pdf':
                AlertUtil.alert('エラー', '画像ファイル(jpeg/png)を選択してください。');
                return;
            default:
                AlertUtil.alert('エラー', '画像ファイル(jpeg/png)を選択してください。');
                return;
        }

        // 画像をS3に保存
        ImageApiFactory(AppG.getConfiguration(), AppG.getBasePath())
            .uploadImage(imageFile.postImage)
            .then((imageFileRes: AxiosResponse<ImageFile>) => {
                if (imageFileRes != null
                    && imageFileRes.data != null
                    && !ValidateUtil.isEmptyExact(imageFileRes.data.url)
                    && isURL(imageFileRes.data.url)
                ) {
                    this.setState({'inputMessageType': ChatConstant.MESSAGE_TYPE().IMAGE});
                    this.setState({'inputUrl': imageFileRes.data.url});
                    this.setState({'inputText': ''});
                    setTimeout(() => {this._addFile()}, 100);
                }
            })
    }

    /**
     * カメラの撮影
     * @private
     */
    _takePhoto = async () => {
        let result = await ImagePicker.launchCameraAsync({
            base64: true,
            quality: 0.8,
            allowsEditing: true,
        });

        const imageFile = ImageUtil.setImage({ result: result });

        if (imageFile == null) {
            return;
        }

        this._saveImage(imageFile);
    };

    /**
     * ピッカーからイメージの選択
     * @private
     */
    _pickImage = async () => {
        let result = await ImagePicker.launchImageLibraryAsync({
            quality: 0.8,
            base64: true,
            mediaTypes: ImagePicker.MediaTypeOptions.Images,
            allowsEditing: true,
            // mediaTypes: ImagePicker.MediaTypeOptions.All,
            // aspect: [4, 3],
        });

        const imageFile = ImageUtil.setImage({ result: result });

        if (imageFile == null) {
            return;
        }

        // 確認
        AlertUtil.confirm(
            '送信確認',
            '選択した画像を送信しますか？',
            () => {this._saveImage(imageFile);}, () => {});

        // this._saveImage(imageFile);
    };

    /**
     * ファイルの追加
     * @private
     */
    _addFile = () => {
        let message = new  Messages();
        message.data = {
            userId: AuthUtil.getUid()!,
            messageType: this.state.inputMessageType,
            text: this.state.inputText,
            url: this.state.inputUrl, // image や pdf の時利用
            isShow: true,
        };

        MessagesDao.instance.addOrUpdate(
            message,
            {chat: this._param.chatId!, message: null},
            (result) => {
                // 未読にさせる
                MessageApiFactory(AppG.getConfiguration(), AppG.getBasePath())
                    .sendMessage(this._param.chatId!, 'image', this.state.inputUrl).then(() => {
                    // 特に何もしない
                });

                let updateChatFunc = (data: any) => {
                    // 未読にする(firebase)
                    let chatModel = new Chats();
                    chatModel.data = ChatsDao.instance.margeModelForUnreadMessages(data, [result.id], `${this.state.opponentUser?.id}`)  // 相手の分を未読にする
                    ChatsDao.instance.addOrUpdate(chatModel, {chat: this._param.chatId}, () => {});
                };
                ChatsDao.instance.get(updateChatFunc, this._param.chatId!);

                this.setState({'inputText': ''});   // 空にする
                this._scrollToEndMessageArea();
            }
        );
    };

    /**
     * メッセージの追加
     * @private
     */
    _addMessage = () => {
        if (this.state.inputText == null || this.state.inputText == '') {
            return;
        }
        let message = new  Messages();
        message.data = {
            userId: AuthUtil.getUid()!,
            messageType: ChatConstant.MESSAGE_TYPE().TEXT,
            text: this.state.inputText,
            url: '', // image や pdf の時利用
            isShow: true,
        };

        MessagesDao.instance.addOrUpdate(
            message,
            {chat: this._param.chatId!, message: null},
            (result) => {
                // 未読にさせる
                MessageApiFactory(AppG.getConfiguration(), AppG.getBasePath())
                    .sendMessage(this._param.chatId!, 'text', this.state.inputText).then(() => {
                    // 特に何もしない
                });
                let updateChatFunc = (data: any) => {
                    // 未読にする(firebase)
                    let chatModel = new Chats();
                    chatModel.data = ChatsDao.instance.margeModelForUnreadMessages(data, [result.id], `${this.state.opponentUser?.id}`)  // 相手の分を未読にする
                    ChatsDao.instance.addOrUpdate(chatModel, {chat: this._param.chatId}, () => {});
                };
                ChatsDao.instance.get(updateChatFunc, this._param.chatId!);

                this.setState({'inputText': ''});   // 空にする
                this._scrollToEndMessageArea();
            }
        );
    };

    /**
     * 引っ張って更新
     * @private
     */
    _refresh = () => {
        if (this.state.messages.length < this._numOfMessagesOneLoad) {
            // 過去メッセージ数が一回で取得する数字より少ないなら読み込まない
            if (Platform.OS == 'web') {
                this._scrollToEndOfActiveIndicator();
            }
            return;
        }

        if (this.state.messages.length != 0) {
            this._date = this.state.messages[0].date;
        }
        this._loadOldMessages(this._date, false);
    };

    /** 描画 */
    _renderMain = () => {
        let _width = AppG.window.width;
        if (ScreenSizeUtil.isWebSize()) {
            _width = appS.webMainContentsSize.width;
        }
        _width = _width;

        if (this.state.isDeletedChat) {
            return (
                <View style={{backgroundColor: appColors.white, flex: 1, flexDirection: 'row',}}>
                    <Text style={{fontSize: 20, margin: 30}}>このメッセージは存在しません。</Text>
                </View>
            );
        }


        return (
            <View style={[{flex: 1, flexDirection: 'column'}]}>
                {ScreenSizeUtil.isWebSize() && <WideSubTitleComponent title={`${this.state.opponentUser?.name == null? '' : this.state.opponentUser?.name}`} width={_width} />}
                {ScreenSizeUtil.isWebSize() && (
                    <View style={{marginTop: -15, marginBottom: 5}}>
                        {this._renderInquiry()}
                    </View>
                )}
                <ScrollView
                    horizontal  // 下階層のスクロールイベントを拾えるようにしている
                    style={{width: AppG.window.width, height: AppG.window.height - appS.header.webHeight}}
                >
                    <KeyboardAvoidingView
                        style={[{
                            flex: 1,
                            flexDirection: 'column',
                            backgroundColor: appColors.white,
                            borderColor: appColors.borderGray,
                            borderWidth: ScreenSizeUtil.isWebSize()? 1 : 0,
                            borderTopWidth: 0,
                            width: _width
                        }]}
                        behavior="position"
                        contentContainerStyle={{ flex: 1 }}
                        keyboardVerticalOffset={Platform.OS == 'android'? -180 : 100}
                    >
                        <ScrollView
                            scrollEventThrottle={160}
                            onScroll={event => {
                                if (Platform.OS == 'web') {
                                    if (event.nativeEvent.contentOffset.y < appS.activityIndicator.height) {
                                        this._refresh();
                                    }
                                }
                            }}
                            refreshControl={
                                <RefreshControl
                                    refreshing={this.state.isMessagesLoading}
                                    onRefresh={() => {
                                        this._refresh();
                                    }}
                                />
                            }
                            ref={this._setMessageScrollAreaRef}
                            style={{
                                backgroundColor: appColors.white,
                                // marginRight: ScreenSizeUtil.isWebSize() ? 10 : 0,
                                // marginLeft: ScreenSizeUtil.isWebSize() ? 10 : 0
                                width: _width - 2
                            }}
                            contentContainerStyle={{ flexGrow: 1 }}>

                            {!this.state.isChatLoaded && (<ActivityIndicator animating={!this.state.isChatLoaded} size="large" color={appColors.gray} />)}
                            {((this.state.messages == null || this.state.messages.length == 0) && this.state.isChatLoaded) && (
                                <Text style={{margin: 30, fontSize: 20}}>まだメッセージはありません。</Text>
                            )}
                            {Platform.OS == 'web' && (
                                <View style={{flex: 1, justifyContent: 'center', height: appS.activityIndicator.height}}>
                                    <ActivityIndicator animating={(Platform.OS == 'web' && this.state.messages != null && this.state.messages.length >= this._numOfMessagesOneLoad)} size="large" color={appColors.gray} />
                                </View>
                            )}
                            {this.state.messages && this.state.messages.map((map, index) => (
                                    <MessageViewComponent
                                        key={`message_view_${index}`}
                                        image={map.userId == AuthUtil.getUid() ? `${this.state.myUser?.image}` : `${this.state.opponentUser?.logo}`}  // ユーザー画像
                                        name={map.name}
                                        date={map.date}
                                        text={map.text}
                                        file={map.file}
                                        messageType={map.messageType}
                                        unread={map.unread}
                                        position={map.userId == AuthUtil.getUid() ? `right` : `left`}
                                    />
                                )
                            )}
                        </ScrollView>


                        {/*送信エリア*/}
                        <View style={{backgroundColor: appColors.white, marginBottom: Platform.OS == 'android'? 20 : 0}}>
                            <View style={{
                                width: _width,
                                height: 1,
                                backgroundColor: appColors.borderGray
                            }}/>
                            <View style={{flexDirection: 'row'}}>
                                <View style={{margin: 8}}>
                                    <TouchableOpacity
                                        key={'minute_image_add_button'}
                                        style={[{
                                            flex: 1,
                                            height: 40,
                                            marginBottom: 10,
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }]}
                                        onPress={() => {
                                            if (Platform.OS == 'web') {
                                                this._pickImage();
                                            } else {
                                                Keyboard.dismiss();
                                                this.setState({isShowPhotoBottomModal: true});
                                            }
                                        }}
                                    >
                                        <Image source={require('../../resources/images/07.chat/i_chat_plus.png')}
                                               style={[{
                                                   marginTop: 20,
                                                   width: 25,
                                                   height: 25,
                                               }]}
                                               resizeMode='contain'/>
                                    </TouchableOpacity>
                                </View>
                                <TextInput autoCapitalize={"none"}
                                           style={[{
                                               flex: 4,
                                               marginTop: 8,
                                               paddingTop: 16,
                                               paddingBottom: 16,
                                               backgroundColor: appColors.chatInputMessageBackGround,
                                               borderRadius: 5,
                                               fontSize: 13,
                                               height: this.state.inputTextHeight,
                                           }]}
                                           placeholder={' メッセージを入力してください'}
                                           placeholderTextColor={appColors.fontGray}
                                           multiline={true}
                                           value={this.state.inputText}
                                           onChangeText={text => this.setState({'inputText': text})}
                                           onFocus={() => {
                                               this.setState({inputTextHeight: 150});
                                               this.setState({
                                                   messageScrollArea: {
                                                       scrollView: this.state.messageScrollArea.scrollView,
                                                       autoScrollEnable: false
                                                   },
                                               })
                                           }}
                                           onBlur={() => {
                                               this.setState({inputTextHeight: 50});
                                               this.setState({
                                                   messageScrollArea: {
                                                       scrollView: this.state.messageScrollArea.scrollView,
                                                       autoScrollEnable: true
                                                   },
                                               })
                                           }}
                                />
                                <View style={{margin: 8}}>
                                    <TouchableOpacity
                                        key={'send_message_button'}
                                        style={[{
                                            flex: 1,
                                            height: 40,
                                            marginBottom: 10,
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }]}
                                        onPress={() => {
                                            Keyboard.dismiss();
                                            this._addMessage();
                                        }}
                                    >
                                        {ValidateUtil.isEmptyExact(this.state.inputText) && (
                                            <Image source={require('../../resources/images/send_message_icon.png')}
                                                   style={[{
                                                       marginTop: 20,
                                                       width: 38,
                                                       height: 38,
                                                   }]}
                                                   resizeMode='contain'/>
                                        )}
                                        {!ValidateUtil.isEmptyExact(this.state.inputText) && (
                                            <Image source={require('../../resources/images/send-message-icon_black.png')}
                                                   style={[{
                                                       marginTop: 5,
                                                       width: 38,
                                                       height: 38,
                                                   }]}
                                                   resizeMode='contain'/>
                                        )}
                                    </TouchableOpacity>
                                </View>
                            </View>
                        </View>

                        {/*image 選択ピッカー*/}
                        {this._renderImagePickerModal()}
                    </KeyboardAvoidingView>
                </ScrollView>
            </View>
        );
    }

    /**
     * お問い合わせを描画
     */
    _renderInquiry = () => {
        return (
            <View style={{flexDirection: 'row'}}>
                <View style={{flex: 1}} />
                <TouchableOpacity
                    onPress={() => {
                        if (this.state.opponentUser != null) {
                            let _reportScreenModel: ReportScreenModel = new ReportScreenModel();
                            _reportScreenModel.makerId = this.state.opponentUser.id.toString();
                            this.props.navigation.navigate('ReportScreen' , _reportScreenModel);
                        }
                    }}
                >
                    <View style={{flexDirection: 'row'}}>
                        <Text style={{fontFamily: appFont.family.bold, fontSize: 10, color: appColors.fontBlack, marginTop: appS.margins.side, fontWeight: "bold"}}>運営会社へ問い合わせる</Text>
                        <Image
                            source={require('../../resources/images/02.menu_general/arrow_B_right.png')}
                            style={{
                                marginTop: Platform.OS == 'android'? 13 : 14,
                                marginRight: 10,
                                width: 8,
                                height: 8,
                            }}
                        />
                    </View>
                </TouchableOpacity>
            </View>
        );
    }

    /**
     * SPサイズ
     */
    _renderSmall = () => {
        const {navigation} = this.props;
        return (
            <SafeAreaView
                style={[{height: AppG.window.height, flex: 1, flexDirection: 'column', backgroundColor: appColors.white}]}
                onLayout={(event) => {
                    // window 拡大縮小を反映させる
                    AppG.window = Dimensions.get('window');
                    this.setState({'resizeWindow': true});
                }}
            >
                <CustomHeaderComponentSmall
                    navigation={this.props.navigation}
                    title={`${this.state.opponentUser?.name == null ? '': this.state.opponentUser?.name}`}
                    textColor={appColors.black}
                    barColor={appColors.white}
                    borderBottomColor={appColors.transparent}
                    leftButton={'back'}
                    rightButton={'none'}
                />
                {this._renderInquiry()}
                {this._renderMain()}
            </SafeAreaView>
        );
    }

    /**
     * WEBサイズ
     */
    _renderWide = () => {
        const {navigation} = this.props;
        return (
            <SafeAreaView
                style={[{height: AppG.window.height, flex: 1, flexDirection: 'column', backgroundColor: appColors.white}]}
                onLayout={(event) => {
                    // window 拡大縮小を反映させる
                    AppG.window = Dimensions.get('window');
                    this.setState({'resizeWindow': true});
                }}
            >
                <CustomHeaderComponentWide
                    navigation={navigation}
                    searchBar={true}
                />

                {/*<View style={{*/}
                {/*    flex: 1,*/}
                {/*    flexDirection: 'row',*/}
                {/*    alignSelf: 'center',*/}
                {/*    backgroundColor: appColors.backBaseColor,*/}
                {/*}}>*/}
                {/*    <ScrollView*/}
                {/*        horizontal  // 下階層のスクロールイベントを拾えるようにしている*/}
                {/*        style={{*/}
                {/*            width: AppG.window.width,*/}
                {/*            height: AppG.window.height,*/}
                {/*        }}*/}
                {/*    >*/}
                {/*        <View style={[{flex: 1, flexDirection: 'column', width: AppG.window.width}]}>*/}
                {/*            <KeyboardAwareScrollView style={[{flex: 1, flexDirection: 'column'}]}>*/}
                {/*                <View style={[{flex: 1, flexDirection: ScreenSizeUtil.isWebSize()? 'row': 'column', justifyContent: 'center'}]}>*/}
                {/*                    <View style={[{flexDirection: 'column'}]}>*/}
                {/*                        <View style={{width: ScreenSizeUtil.isWebSize()? appS.webMainContentsSize.width : AppG.window.width}}>*/}
                {/*                            {this._renderMain()}*/}
                {/*                        </View>*/}
                {/*                    </View>*/}
                {/*                    {ScreenSizeUtil.isWebSize() && (*/}
                {/*                        <View style={{marginLeft: appS.margins.webBetweenMargin, width: appS.webSideContentsSize.width}}>*/}
                {/*                            <WideScreenAdComponent navigation={this.props.navigation}/>*/}
                {/*                        </View>*/}
                {/*                    )}*/}
                {/*                </View>*/}
                {/*            </KeyboardAwareScrollView>*/}
                {/*        </View>*/}
                {/*    </ScrollView>*/}
                {/*</View>*/}

                <View style={{
                    flexDirection: 'row',
                    backgroundColor: appColors.backBaseColor,
                    width: AppG.window.width
                }}>
                    <View style={{flex:1}}/>
                    <View style={{flex:7}}>
                        <ScrollView
                            horizontal  // 下階層のスクロールイベントを拾えるようにしている
                            style={{
                                width: AppG.window.width,
                                height: AppG.window.height - appS.header.webHeight,
                            }}
                        >

                            <View style={{width: appS.webMainContentsSize.width}}>
                                {this._renderMain()}
                            </View>
                            {/*広告*/}
                            {ScreenSizeUtil.isWebSize() && (
                                <View style={{marginLeft: appS.margins.webBetweenMargin, width: appS.webSideContentsSize.width}}>
                                    <WideScreenAdComponent navigation={this.props.navigation}/>
                                    <WideScreenSNSComponent navigation={this.props.navigation}/>
                                </View>
                            )}

                        </ScrollView>
                    </View>
                    {/*<View style={{flex:1}}/>*/}
                </View>

            </SafeAreaView>
        );
    }


    /** 描画 */
    render() {
        if (!this.state.isLoaded) {
            return (
                <View style={{flex: 1}}>
                    <IndicatorComponent
                        failTime={60}
                        isLoading={true}
                        reloadFunc={() => {
                            this.componentWillUnmount();
                            this.componentDidMount();
                        }}/>
                </View>
            );
        }

        LinkUtil.addReturnFalseToATag();
        if (ScreenSizeUtil.isWebSize()) {
            // WEBサイズ
            return this._renderWide();
        } else {
            // SPサイズ
            return this._renderSmall();
        }

    }


}
