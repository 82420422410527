import React, {Component} from 'react';
import {Image, Platform, ScrollView, Text, TouchableOpacity, View} from 'react-native';
import {appColors, appFont, appS} from "../../resources/styles/style";
import AppG from "../util/AppG";
import ScreenSizeUtil from "../util/ScreenSizeUtil";
import Modal from "react-native-modal";
import {WhatIsSampleRequestModalDataEntityModel} from "../data/entityModels/WhatIsSampleRequestModalDataEntityModel";
import WhatIsSampleRequestModalDataDao from "../data/dao/local/WhatIsSampleRequestModalDataDao";
import AnalyticsUtil, {AnalyticsEventName, DOpenEventName} from "../util/firebase/AnalyticsUtil";
import {Ec, Maker, Product} from "../data/network/swagger-gen";
import LinkUtil from "../util/LinkUtil";
import ValidateUtil from "../util/ValidateUtil";
import * as Clipboard from "expo-clipboard";
import AlertUtil from "../util/AlertUtil";

interface Props {
    navigation: any,
    ecs: Ec[] | null,
    product: Product | null
    maker: Maker | null
}

interface State {
    showModal: boolean,
}

export class EcSiteModalComponent extends Component<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            showModal: false
        };
    }

    // Propsのデフォルト値
    static defaultProps = {
    };


    componentDidMount() {
    }

    /**
     * 表示
     */
    showModal = () => {
        // console.log(this.props.ecs);
        this.setState({showModal: true});
    }

    /**
     * 閉じる
     */
    closeModal = () => {
        this.setState({showModal: false});
    }


    _renderCard = (ec: Ec, index: number) => {
        let _resource = {uri: ec.shop_image};
        if (!ValidateUtil.isEmptyExact(ec.provider) && ec.provider == 'FOODS FRIDGE') {
            _resource = require('../../resources/images/ec/FF_Logo.jpg');
        }

        return (
            <View
                key={`pc_v_${index}`}
                style={{
                    backgroundColor: appColors.white,
                    margin: appS.margins.side,
                    borderRadius: 10,
                    width: 300
                }}>
                <View
                    key={`pc_v_2_${index}`}
                    style={{padding: 20}}
                >
                    <Image source={_resource}
                           key={`popup_image_${index}`}
                           style={[{
                               alignSelf: 'center',
                               width: 150,
                               height: 60,
                               margin: 8,
                               backgroundColor: appColors.transparent
                           }]}
                           resizeMode={'cover'}
                    />
                    <View style={{
                        flexDirection: 'row',
                        marginTop: 8
                    }}>
                        {/* クーポンコードがあったら「クーポン対象商品」 */}
                        {/*{!ValidateUtil.isEmptyArray(ec.coupon_code) && (*/}
                        {/*    <Text style={{*/}
                        {/*        borderWidth: 1,*/}
                        {/*        borderColor: appColors.ecButton,*/}
                        {/*        borderRadius: 4,*/}
                        {/*        padding: Platform.OS == 'android' ? 0 : 2,*/}
                        {/*        paddingLeft: 10,*/}
                        {/*        paddingRight: 10,*/}
                        {/*        fontFamily: appFont.family.bold,*/}
                        {/*        color: appColors.ecButton,*/}
                        {/*        fontWeight: 'bold',*/}
                        {/*        fontSize: 10*/}
                        {/*    }}>クーポン対象商品</Text>*/}
                        {/*)}*/}
                        {/*{ValidateUtil.isEmptyArray(ec.coupon_code) && (*/}
                        {/*    <View style={{*/}
                        {/*        margin: Platform.OS == 'android' ? 0 : 2,*/}
                        {/*        height: 10*/}
                        {/*    }}/>*/}
                        {/*)}*/}
                        <View style={{flex: 1}}/>
                    </View>
                    {/*<Text style={{*/}
                    {/*    marginTop: 16,*/}
                    {/*    marginBottom: ScreenSizeUtil.isWebSize()? -8 : (Platform.OS == 'web'? 0 : -8),*/}
                    {/*    fontFamily: appFont.family.default,*/}
                    {/*    color: appColors.fontBlack,*/}
                    {/*    fontSize: 14*/}
                    {/*}}>価格</Text>*/}
                    {!ValidateUtil.isEmptyExact(ec.price) && (
                        <View style={{flexDirection: 'row'}}>
                            {ValidateUtil.isEmptyExact(ec.label) && (
                                <View
                                    style={{
                                        flexDirection: 'row',
                                        marginBottom: Platform.OS == 'android' ? -20 : 0,
                                    }}
                                >
                                    <Text style={{
                                        marginTop: 16,
                                        marginBottom: ScreenSizeUtil.isWebSize()? -8 : (Platform.OS == 'web'? 0 : -8),
                                        fontFamily: appFont.family.default,
                                        color: appColors.fontBlack,
                                        fontSize: 14
                                    }}>価格</Text>
                                    <Text style={{
                                        marginTop: Platform.OS == 'android' ? 10 : 0,
                                        fontFamily: appFont.family.bold,
                                        color: appColors.fontBlack,
                                        fontSize: 32,
                                        fontWeight: "bold"
                                    }}>￥{ec.price!.toLocaleString()}</Text>
                                </View>
                            )}
                            {!ValidateUtil.isEmptyExact(ec.label) && (
                                <View
                                    style={{
                                        flexDirection: 'row',
                                        marginBottom: Platform.OS == 'android' ? -20 : 0,
                                    }}
                                >
                                    <Text style={{
                                        marginTop: 16,
                                        marginBottom: ScreenSizeUtil.isWebSize()? -8 : (Platform.OS == 'web'? 0 : -8),
                                        fontFamily: appFont.family.default,
                                        color: appColors.fontBlack,
                                        fontSize: 14
                                    }}>価格</Text>
                                    <Text
                                        style={{
                                            marginTop: Platform.OS == 'android' ? 10 : 0,
                                            marginBottom: Platform.OS == 'android' ? -20 : 0,
                                            fontFamily: appFont.family.bold,
                                            color: appColors.fontBlack,
                                            fontSize: 32,
                                            fontWeight: "bold"
                                        }}
                                    >{ec.label}</Text>
                                </View>
                            )}
                        </View>
                    )}
                    {/* クーポンコード */}
                    {/*{!ValidateUtil.isEmptyArray(ec.coupon_code) && ec.coupon_code?.map((coupon, index) => {*/}
                    {/*    if (coupon.coupon_code == null) {*/}
                    {/*        return null;*/}
                    {/*    }*/}

                    {/*    return (*/}
                    {/*        <View key={`popup_coupon_${index}`}>*/}
                    {/*            <Text*/}
                    {/*                key={`popup_coupon_text_${index}`}*/}
                    {/*                style={{*/}
                    {/*                    marginTop: Platform.OS == 'android' ? 0 : 8,*/}
                    {/*                    fontFamily: appFont.family.default,*/}
                    {/*                    color: appColors.fontBlack,*/}
                    {/*                    fontSize: 15*/}
                    {/*                }}*/}
                    {/*                numberOfLines={2}*/}
                    {/*            >{coupon.label}</Text>*/}

                    {/*            <View*/}
                    {/*                key={`popup_coupon_2_${index}`}*/}
                    {/*                style={{flexDirection: 'row'}}>*/}
                    {/*                <Text*/}
                    {/*                    key={`popup_coupon_text_2_${index}`}*/}
                    {/*                    style={{*/}
                    {/*                        marginTop: Platform.OS == 'android' ? -8 : 0,*/}
                    {/*                        color: appColors.black,*/}
                    {/*                        fontWeight: "bold",*/}
                    {/*                        fontSize: ScreenSizeUtil.isWebSize()? 23 : 28,*/}
                    {/*                        width: 220}}*/}
                    {/*                >{coupon.coupon_code}</Text>*/}
                    {/*                <View*/}
                    {/*                    key={`popup_coupon_3_${index}`}*/}
                    {/*                    style={{height: ScreenSizeUtil.isWebSize()? 23 : 28, width: 8}}/>*/}
                    {/*                <TouchableOpacity*/}
                    {/*                    key={`popup_coupon_to_${index}`}*/}
                    {/*                    onPress={() => {*/}
                    {/*                        Clipboard.setString(coupon.coupon_code!);*/}
                    {/*                        AlertUtil.alert('', 'クーポンをコピーしました。');*/}
                    {/*                    }}>*/}
                    {/*                    <Image*/}
                    {/*                        key={`popup_coupon_image_${index}`}*/}
                    {/*                        source={require('../../resources/images/link-copy-icon.png')}*/}
                    {/*                        style={[{*/}
                    {/*                            marginTop: 6,*/}
                    {/*                            width: ScreenSizeUtil.isWebSize()? 23 : 28,*/}
                    {/*                            height: ScreenSizeUtil.isWebSize()? 23 : 28,*/}
                    {/*                            borderRadius: 5,*/}
                    {/*                        }]}*/}
                    {/*                        resizeMode={'contain'}*/}
                    {/*                    />*/}
                    {/*                </TouchableOpacity>*/}
                    {/*            </View>*/}

                    {/*        </View>*/}
                    {/*    );*/}
                    {/*})}*/}
                    <Text
                        style={{
                            marginTop: 8,
                            fontFamily: appFont.family.default,
                            color: appColors.fontBlack,
                            fontSize: 14
                        }}
                        numberOfLines={2}
                    >{ec.description}</Text>

                    <TouchableOpacity
                        style={[
                            {
                                marginTop: 16,
                                height: appS.button.height,
                                backgroundColor: appColors.ecButton,
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: appS.buttonSize.radius,
                            }
                        ]}
                        onPress={() => {
                            if (!ValidateUtil.isEmptyExact(ec.url)) {
                                const { routeName } = this.props.navigation.state;
                                AnalyticsUtil.sendAnalytics(
                                    DOpenEventName.openEc,
                                    AnalyticsEventName.openEc,  // アナリティクスのイベント名
                                    `${routeName}`,
                                    null,
                                    this.props.product,
                                    this.props.maker,
                                    null,
                                    null,
                                    null,
                                    null,
                                    ec.url!,
                                );
                                let _url = `${ec.url!}&aId=${AppG.analyticsHash}&uId=`;
                                if (AppG.user != null) {
                                    _url = `${ec.url!}&aId=${AppG.analyticsHash}&uId=${AppG.user!.id}`;
                                }

                                LinkUtil.openLinkWithDefaultBrowser(_url);
                            }
                        }}
                    >
                        <View style={{flexDirection: 'row'}}>
                            <Text style={{
                                fontFamily: appFont.family.bold,
                                color: appColors.white,
                                fontWeight: 'bold',
                                fontSize: 12
                            }}>　{ec.provider == 'own' ? '購入サイト' : ec.provider}へ（外部サイト）　</Text>
                        </View>
                    </TouchableOpacity>
                </View>
            </View>
        );
    }

    _renderPcInner = () => {
        return (
            <View style={{
                position: 'relative',
                top: 0,
                left: 0,
                backgroundColor: appColors.transparent,
                alignItems: 'center',
                alignSelf: 'center',
                alignContent: 'center',
                marginTop: 0,
            }}>


                <View style={{
                    flexDirection: 'row-reverse',
                    backgroundColor: appColors.transparent,
                    // paddingLeft: appS.margins.side / 2,
                    width: 970
                }}>
                    {/*閉じるボタン*/}
                    <TouchableOpacity
                        style={{
                            zIndex: 202,
                            backgroundColor: appColors.transparent,
                        }}
                        onPress={() => {
                            this.closeModal();
                        }}
                    >
                        <Image source={require('../../resources/images/close_icon_white.png')}
                               style={[{
                                   backgroundColor: appColors.transparent,
                                   width: 40,
                                   height: 40,
                               }]}
                               resizeMode={'cover'}
                        />
                    </TouchableOpacity>
                </View>
                <View style={{
                    borderRadius: 10,
                    backgroundColor: appColors.transparent,
                    paddingLeft: appS.margins.side / 2,
                    height: 700,
                }}>
                    <ScrollView style={{
                        marginLeft: appS.margins.side,
                        backgroundColor: appColors.transparent,
                    }}>
                        {this.props.ecs!.map((ec, index) => {
                            return (
                                <View
                                    key={`ecs_${index}`}
                                >
                                    {index % 3 == 0 && (
                                        <View
                                            key={`ecs_2_${index}`}
                                            style={{flexDirection: 'row'}}>
                                            {this._renderCard(this.props.ecs![index], index)}
                                            {(this.props.ecs?.length! > index+1) && this._renderCard(this.props.ecs![index+1], index+1)}
                                            {(this.props.ecs?.length! > index+2) && this._renderCard(this.props.ecs![index+2], index+2)}
                                        </View>
                                    )}
                                </View>
                            );
                        })}
                    </ScrollView>

                </View>


            </View>
        );
    }

    _renderSpInner = () => {
        return (
            <View style={{
                position: 'relative',
                width: appS.popup.width,
                height: appS.popup.height,
                top: ScreenSizeUtil.isWebSize()? 50 : (Platform.OS == 'web'? 40 : 0),
                left: 0,
                backgroundColor: appColors.transparent,
                alignItems: 'center',
                alignSelf: 'center',
                alignContent: 'center',
                marginTop: (AppG.window.height - appS.popup.height) / 2 - appS.footer.height + (ScreenSizeUtil.isWebSize()? 0 : appS.header.height),
            }}>


                <View style={{
                    flexDirection: 'row-reverse',
                    backgroundColor: appColors.transparent,
                    paddingLeft: appS.margins.side / 2,
                    width: appS.popup.width + appS.margins.side * 2,
                }}>
                    {/*閉じるボタン*/}
                    <TouchableOpacity
                        style={{
                            zIndex: 202,
                            backgroundColor: appColors.transparent,
                        }}
                        onPress={() => {
                            this.closeModal();
                        }}
                    >
                        <Image source={require('../../resources/images/close_icon_white.png')}
                               style={[{
                                   backgroundColor: appColors.transparent,
                                   width: 40,
                                   height: 40,
                               }]}
                               resizeMode={'cover'}
                        />
                    </TouchableOpacity>
                </View>
                <View style={{
                    borderRadius: 10,
                    backgroundColor: appColors.transparent,
                    paddingLeft: appS.margins.side / 2,
                    width: appS.popup.width + appS.margins.side * 2,
                    height: Platform.OS == 'android'? appS.popup.width + 180 : appS.popup.width + 140,
                }}>
                    <ScrollView style={{
                        marginLeft: appS.margins.side,
                        width: appS.popup.width - 1,
                        backgroundColor: appColors.transparent,
                    }}>
                        {this.props.ecs!.map((ec, index) => {
                            return this._renderCard(ec, index);
                        })}

                    </ScrollView>

                </View>


            </View>
        );
    }

    /**
     * Android用対応
     */
    _renderModalForAndroid = () => {
        return (
            <Modal
                isVisible={this.state.showModal}
                deviceWidth={AppG.window.width}
                deviceHeight={AppG.window.height}
                style={{
                    marginTop: -AppG.window.height / 4,
                    alignSelf: 'center',
                }}
                onBackdropPress={() => {
                    this.closeModal();
                }}
            >
                {this._renderSpInner()}
            </Modal>
        );
    }

    /**
     * web 用
     */
    _renderPcModal = () => {
        if (!this.state.showModal) {
            return null;
        }


        return (
            <View
                style={{zIndex: 201, position: 'absolute', backgroundColor: appColors.opacityGray, height: AppG.window.height, width: AppG.window.width}}
            >
                {this._renderPcInner()}
            </View>
        );

    }


    /**
     * iPhone, SP web 用
     */
    _renderSpModal = () => {
        if (!this.state.showModal) {
            return null;
        }


        return (
            <View
                style={{zIndex: 201, position: 'absolute', backgroundColor: appColors.opacityGray, height: AppG.window.height + 60, width: AppG.window.width}}
            >
                {this._renderSpInner()}
            </View>
        );

    }

    render() {
        if (this.props.ecs == null) {
            return null;
        }
        if (ScreenSizeUtil.isWebSize()) {
            return this._renderPcModal();
        }
        if (Platform.OS == 'android') {
            return this._renderModalForAndroid();
        }
        return this._renderSpModal();
    }
}
