import React, {Component} from 'react';
import {Image, Text, View} from 'react-native';
import {appColors, appFont, appS} from "../../resources/styles/style";

interface Props {
    text: string,
}

interface State { }

export class NoItemsIconComponent extends Component<Props, State> {
    render() {
        return (
            <View style={{alignItems: "center"}}>
                <Image source={require('../../resources/images/no_items_icon.png')}
                       style={[{
                           backgroundColor: appColors.transparent,
                           width: appS.noItemIcon.width,
                           height: appS.noItemIcon.height,
                       }]}
                       resizeMode={'cover'}
                />
                <Text style={{fontFamily: appFont.family.default, textAlign: 'center', marginTop: 16, color: appColors.fontBlack}}>{this.props.text}</Text>
            </View>
        );
    }
}
