export class ProductReviewReminderModalDataEntityModel {
    /**
     * 0, 1 => 1 なら表示しない
     */
    hide_flg_product_review_reminder: string | undefined = undefined;
    /**
     * 最終表示時
     */
    last_show_date_product_review_reminder: string | undefined = undefined;

}
