import React from 'react'
import {Dimensions, Image, Platform, SafeAreaView, ScrollView, TouchableOpacity, View} from 'react-native'
import {appColors, appS} from '../../resources/styles/style'
import {BaseScreen} from "./BaseScreen";
import LoginUtil from "../util/LoginUtil";
import {IndicatorComponent} from "../components/IndicatorComponent";
// @ts-ignore
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scrollview';
import LoginDataDao from "../data/dao/local/LoginDataDao";
import {LoginDataEntityModel} from "../data/entityModels/LoginDataEntityModel";
// @ts-ignore
import {log} from '../../../environment';
import AppG from "../util/AppG";
import AnalyticsUtil, {AnalyticsEventName, DOpenEventName} from "../util/firebase/AnalyticsUtil";
import ExpoUtil from "../util/ExpoUtil";
import LinkUtil from "../util/LinkUtil";

interface Props {
    navigation: any, // ナビゲーション用
}

interface State {
    resizeWindow: boolean,  // 値に意味はなし。windowサイズの拡大縮小をrenderに送るためのもの
    isLoaded: boolean,
}

export class WhatIsRecipeDownloadScreen extends BaseScreen<Props, State> {
    _resizeTimer = setInterval(() => {
        // window 拡大縮小を反映させる
        AppG.window = Dimensions.get('window');
        if (AppG.window.width != AppG.windowBefore.width || AppG.window.height != AppG.windowBefore.height) {
            this.setState({'resizeWindow': true});
            AppG.windowBefore = Dimensions.get('window');
        }
        LoginUtil.setRefreshedScreenFlg(false);
    }, 100);

    _loginData = new LoginDataEntityModel();

    _flameSize = appS.flame.size;    // 左右の margin
    _marginSize = 32;

    _browserBackListener = (e: any) => {

    };

    constructor(props: any) {
        super(props);
        this.state = {
            resizeWindow: true,  // 値に意味はなし。windowサイズの拡大縮小をrenderに送るためのもの
            isLoaded: false,
        };
    }

    /**
     * 画面開いたときのアナリティクス送信
     */
    _sendPvAnalytics = () => {
        AnalyticsUtil.sendAnalytics(
            DOpenEventName.openPage,
            AnalyticsEventName.openPage,  // アナリティクスのイベント名
            'WhatIsRecipeDownloadScreen',
            null,
            null,
            null,
            null,
        );
    }

    componentDidMount() {
        if (Platform.OS == 'web') {
            window.addEventListener('popstate', this._browserBackListener);
        }

        LoginUtil.interruptOpenScreen(this.props.navigation,
            () => {
                LoginDataDao.get().then(loginData => {
                    // Web 戻るでも呼ばれる
                    if (Platform.OS == 'web') {
                        // Analytics
                        this._sendPvAnalytics();
                    }

                    // URL Scheme で外部リンクから起動したときの処理と、画面遷移でこの画面を開いたときの処理
                    LoginUtil.setDefaultListener(this.props.navigation, () => {
                        // Webはここにこない
                        if (Platform.OS != 'web') {
                            // Analytics
                            this._sendPvAnalytics();
                        }
                    });
                    if (loginData != null) {
                        this._loginData = loginData;
                        this._loadMasters().then(() => {});
                        this._loadContents().then(() => {});
                        this.setState({'isLoaded': true});
                    }
                });
            });

        this.setState({isLoaded: true});
    }

    componentWillUnmount () {
        clearTimeout(this._resizeTimer);

        if (Platform.OS === 'web') {
            window.removeEventListener('popstate', this._browserBackListener);
        }
        LoginUtil.removeDefaultListener(this.props.navigation);
    };

    componentDidUpdate () {
        // レンダー完了時に呼ばれる
    }

    /**
      * パラムの取得
      * @private
      */
    _getParams = () => {
    };

    /**
     * マスターのロード
     * @private
     */
    _loadMasters = async () => {
    };

    /**
     * コンテンツのロード
     * @private
     */
    _loadContents = async () => {
    };

    _renderMain = () => {
        return (
            // appStyles.statusBarHiddenSafeAreaでステータスバー分上に上げる
            <SafeAreaView
                style={[{flex: 1, backgroundColor: '#A79B1B'}]}
                onLayout={(event) => {}}
            >
                <ScrollView style={[{flex: 1, flexDirection: 'column', backgroundColor: '#A79B1B', width: AppG.window.width}]}>
                    <View style={{flex: 1, alignItems: 'center', justifyContent: 'center',}}>
                        <Image source={require('../../resources/images/tutorial_04_no_dots.png')}
                               style={[{
                                   width: 375,
                                   height: 812,
                               }]}
                               resizeMode={'cover'}
                        />
                    </View>
                </ScrollView>

                <View style={{
                    position: 'relative',
                    flexDirection: "row",
                    height: 24,
                    top:  -AppG.window.height + 80,
                    right: 0,
                    marginLeft: this._marginSize,
                    marginRight: this._marginSize * 2
                }}>
                    <View style={{flex: 1}}/>
                    <TouchableOpacity
                        onPress={() => {
                            // メニューを閉じる
                            ExpoUtil.goBack(this.props.navigation);
                        }}
                    >
                        <Image source={require('../../resources/images/close_icon_white.png')}
                               style={[{
                                   backgroundColor: appColors.transparent,
                                   width: 24,
                                   height: 24,
                               }]}
                               resizeMode={'cover'}
                        />
                    </TouchableOpacity>
                </View>

            </SafeAreaView>
        );
    }

    _renderWide = () => {
        return null;
    }

    _renderSmall = () => {
        return (
            // appStyles.statusBarHiddenSafeAreaでステータスバー分上に上げる
            <SafeAreaView
                style={[{flex: 1, backgroundColor: appColors.white}]}
                onLayout={(event) => {}}
            >
                <View style={{flex: 1, backgroundColor: appColors.backBaseColor}}>
                    {this._renderMain()}
                </View>
            </SafeAreaView>
        );
    }


    /** 描画 */
    render() {
        const {navigation} = this.props;
        if (!this.state.isLoaded) {
            return (
                <View style={{flex: 1}}>
                    <IndicatorComponent
                        failTime={60}
                        isLoading={true}
                        reloadFunc={() => {
                            this.componentWillUnmount();
                            this.componentDidMount();
                        }}/>
                </View>
            );
        }

        LinkUtil.addReturnFalseToATag();
        // SPサイズ
        return this._renderSmall();

    };


}

