import {LoginDataEntityModel} from "../../entityModels/LoginDataEntityModel";
import {BaseDao} from "./BaseDao";
import AsyncStorage from '@react-native-async-storage/async-storage';
// @ts-ignore
import {log} from '../../../../../environment';
import {TutorialDataEntityModel} from "../../entityModels/TutorialDataEntityModel";

class TutorialDataDao extends BaseDao<TutorialDataEntityModel> {
    _getModel(): TutorialDataEntityModel {
        return new TutorialDataEntityModel();
    }
}

// new 付なのでシングルトン
export default new TutorialDataDao();
