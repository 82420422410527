import AsyncStorage from '@react-native-async-storage/async-storage';
// @ts-ignore
import {log} from '../../../../../environment';

export abstract class BaseDao<T> {
    /**
     * モデルの取得
     * @private
     */
    abstract _getModel(): T;

    /**
     * モデルの全データをローカルStorageから読み込む
     */
    get = async (): Promise<T | null> => {
        try {
            let t = this._getModel();
            const _ = await AsyncStorage.multiGet(Object.keys(t), (error, stores) => {
                // @ts-ignore
                stores.map((result, i, store) => {
                    // @ts-ignore
                    t[result[0]] = result[1];
                });
            });
            return t;
        } catch(e) {
            log.debug(`Login Data Load Error : ${e}`);
            return null;
        }
    };

    /**
     * モデルのデータをローカルStorageに保存
     * @param t
     */
    addOrUpdate = (t: T) => {
        try {
            let items = [];
            for(let item in t) {
                items.push([item, t[item]]);
            }
            // @ts-ignore
            AsyncStorage.multiSet(items).then();
        } catch (e) {
            log.debug(e);
        }
    }

    delete = () => {
        try {
            let t = this._getModel();
            for(let item in t) {
                AsyncStorage.removeItem(item).then();
            }
        } catch (e) {
            log.debug(e);
        }
    };
}
