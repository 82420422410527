import React from 'react'
import {Dimensions, Image, Platform, SafeAreaView, ScrollView, Text, TouchableOpacity, View} from 'react-native'
import {appColors, appFont, appS} from '../../resources/styles/style'
import {BaseScreen} from "./BaseScreen";
import LoginUtil from "../util/LoginUtil";
import {IndicatorComponent} from "../components/IndicatorComponent";
// @ts-ignore
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scrollview';
import LoginDataDao from "../data/dao/local/LoginDataDao";
import {LoginDataEntityModel} from "../data/entityModels/LoginDataEntityModel";
import {Product, ProductApiFactory,} from "../data/network/swagger-gen";
import AppGlobalDataUtil from "../util/AppG";
import AppG from "../util/AppG";
import UrlUtil from "../util/UrlUtil";
// @ts-ignore
import isURL from 'validator/lib/isURL';
// @ts-ignore
import {log} from '../../../environment';
// @ts-ignore
import {Pages} from 'react-native-pages';
import {AxiosResponse} from "axios";
import ValidateUtil from "../util/ValidateUtil";
import {BorderComponent} from "../components/BorderComponent";
import {CheckBox} from "react-native-elements";
import AlertUtil from "../util/AlertUtil";
import ExpoUtil from "../util/ExpoUtil";
import ScreenSizeUtil from "../util/ScreenSizeUtil";
import {CustomHeaderComponentWide} from "../components/wide/CustomHeaderComponentWide";
import {NoItemsIconComponent} from "../components/NoItemsIconComponent";
import {SelectSampleScreenModel} from "../data/models/screen/SelectSampleScreenModel";
import {CustomHeaderComponentSmall} from "../components/small/CustomHeaderComponentSmall";
import {SpacerComponent} from "../components/SpacerComponent";
import {WideScreenAdComponent} from "../components/wide/WideScreenAdComponent";
import I18n from '../../resources/language/i18n';
import {NoButtonModalComponent} from "../components/NoButtonModalComponent";
import AnalyticsUtil, {AnalyticsEventName, DOpenEventName} from "../util/firebase/AnalyticsUtil";
import {SampleRequestScreenModel} from "../data/models/screen/SampleRequestScreenModel";
import {LinkComponent} from "../components/LinkComponent";
import {ProductDetailScreenModel} from "../data/models/screen/ProductDetailScreenModel";
import {ATagLinkComponent} from "../components/ATagLinkComponent";
import {NoUserImageComponent} from "../components/NoUserImageComponent";
import {WideScreenSNSComponent} from "../components/wide/WideScreenSNSComponent";
import {ImageModalComponent} from "../components/ImageModalComponent";
import LinkUtil from "../util/LinkUtil";

interface Props {
    navigation: any, // ナビゲーション用
}

interface State {
    resizeWindow: boolean,  // 値に意味はなし。windowサイズの拡大縮小をrenderに送るためのもの
    isLoaded: boolean,

    // view
    activeTabSlide: number,    // 現在のTabSlide

    // コンテンツ
    isShowSentModal: boolean,
    products: Product[], // 商品一覧
    productsCheckList: {
        product: Product
        isChecked: boolean
    }[] // 商品の SelectBox
    isLoadProducts: boolean,
}

export class SelectSampleScreen extends BaseScreen<Props, State> {
    _resizeTimer = setInterval(() => {
        // window 拡大縮小を反映させる
        AppG.window = Dimensions.get('window');
        if (AppG.window.width != AppG.windowBefore.width || AppG.window.height != AppG.windowBefore.height) {
            this.setState({'resizeWindow': true});
            AppG.windowBefore = Dimensions.get('window');
        }
        LoginUtil.setRefreshedScreenFlg(false);
    }, 100);

    _loginData = new LoginDataEntityModel();

    _flameSize = ScreenSizeUtil.isWebSize()? AppG.window.width / 10 : 16;    // 左右の margin
    _marginSize = ScreenSizeUtil.isWebSize()? 32 : appS.margin.size;

    // このScreenへ渡すパラム
    _param: SelectSampleScreenModel = new SelectSampleScreenModel();
    _contentsLoadCount = 9; // コンテンツの一度の読み込み数

    _browserBackListener = (e: any) => {

    };

    constructor(props: any) {
        super(props);
        this.state = {
            resizeWindow: true,  // 値に意味はなし。windowサイズの拡大縮小をrenderに送るためのもの
            isLoaded: true,
            activeTabSlide: 0,
            isShowSentModal: false,
            products: [], // 商品
            productsCheckList: [],
            isLoadProducts: true,
        };
    }

    /**
     * 画面開いたときのアナリティクス送信
     */
    _sendPvAnalytics = () => {
        AnalyticsUtil.sendAnalytics(
            DOpenEventName.openPage,
            AnalyticsEventName.openPage,  // アナリティクスのイベント名
            'SelectSampleScreen',
            null,
            null,
            null,
            null,
        );
    }

    componentDidMount() {
        if (Platform.OS == 'web') {
            window.addEventListener('popstate', this._browserBackListener);
        }

        LoginUtil.interruptOpenScreen(this.props.navigation,
            () => {
                LoginDataDao.get().then(loginData => {
                    // Web 戻るでも呼ばれる
                    if (Platform.OS == 'web') {
                        // Analytics
                        this._sendPvAnalytics();
                    }
                    AppG.getFooterBadge();

                    // URL Scheme で外部リンクから起動したときの処理と、画面遷移でこの画面を開いたときの処理
                    LoginUtil.setDefaultListener(
                        this.props.navigation,
                        () => {
                            // Webはここにこない
                            if (Platform.OS != 'web') {
                                // Analytics
                                this._sendPvAnalytics();
                            }

                            ExpoUtil.doReloadAppTab(() => {
                                this.setState({productsCheckList: []});
                                this._loadContents().then(() => {});
                                AppG.getFooterBadge();
                            });
                        });
                    if (loginData != null && !ValidateUtil.isEmptyExact(loginData?.user_id)) {
                        this._loginData = loginData;
                        this._loadMasters().then(() => {});
                        this._loadContents().then(() => {});
                        this.setState({'isLoaded': true});
                    }
                });
            });

        this.setState({isLoaded: true});
    }

    componentWillUnmount () {
        clearTimeout(this._resizeTimer);
        if (Platform.OS === 'web') {
            window.removeEventListener('popstate', this._browserBackListener);
        }
        LoginUtil.removeDefaultListener(this.props.navigation);
    };

    componentDidUpdate () {
        // レンダー完了時に呼ばれる
    }

    /**
     * パラムの取得
     * @private
     */
    _getParams = () => {
        let selectSampleScreenModel: SelectSampleScreenModel = UrlUtil.getParamModel(new SelectSampleScreenModel(), this.props.navigation);
        return selectSampleScreenModel;
    };

    /**
     * マスターのロード
     * @private
     */
    _loadMasters = async () => {

    };

    /**
     * コンテンツのロード
     * @private
     */
    _loadContents = async () => {
        this._param = this._getParams();

        if (ValidateUtil.isEmptyExact(this._param.productIds) || this._param.productIds == '||') {
            this.setState({isLoadProducts: false});
            return;
        }

        let _productIds : string[] = [];
        _productIds = this._param.productIds!.split('|');

        // 商品一覧
        this.setState({isLoadProducts: true});
        _productIds.forEach((productId: string) => {
            ProductApiFactory(AppGlobalDataUtil.getConfiguration(), AppGlobalDataUtil.getBasePath())
                .getProduct(
                    productId,
                    0,
                    4,
                ).then((resultProductsRes: AxiosResponse<Product>) => {
                if (resultProductsRes != null && resultProductsRes.data != null && !ValidateUtil.isEmptyExact(resultProductsRes.data.sampleAmount)) {
                    this.state.products.push(resultProductsRes.data);
                    this.setState({products: this.state.products});

                    let _productIdsInCartCheck = {
                        product: resultProductsRes.data,
                        isChecked: true,
                    };

                    this.state.productsCheckList.push(_productIdsInCartCheck);
                    this.setState({productsCheckList: this.state.productsCheckList});
                }
                this.setState({isLoadProducts: false});
            });
        })


        // 実行
        // await Promise.all([]);
    };

    /**
     * 選択されてるかどうかチェック
     */
    _checkSelected = () => {
        let _checked = false;
        this.state.productsCheckList.forEach((value) => {
            if (value.isChecked) {
                _checked = true;
            }
        })

        return _checked;
    }

    /**
     * セル
     * @param checkList
     * @param index
     */
    _renderProductCell = (checkList: {product: Product, isChecked: boolean}, index: number) => {
        let source = require('../../resources/images/no_image.png');
        if (!ValidateUtil.isEmptyArray(checkList.product.images) && isURL(checkList.product.images[0].url)) {
            source = {uri: checkList.product.images[0].url};
        }

        let _width = AppG.window.width;
        if (ScreenSizeUtil.isWebSize()) {
            _width = appS.webMainContentsSize.width;
        }
        _width = _width - appS.margins.side * 2;

        const _imageSize = 80;
        const _checkBoxSize = 15;

        return (
            <View
                key={`cell_${index}`}
                style={{
                    flexDirection: "row",
                    flex: 1,
                    margin: appS.margins.side,
                }}>
                <View
                    key={`cell_view_${index}`}
                    style={{alignSelf: 'center'}}>
                    <CheckBox
                        center
                        key={`checkbox_${index}`}
                        containerStyle={{height: 30, marginLeft:0}}
                        checkedIcon={(
                            <Image source={require('../../resources/images/02.menu_general/checkbox_on.png')}
                                   style={[{
                                       marginTop: -5,
                                       width: _checkBoxSize,
                                       height: _checkBoxSize,
                                   }]}
                                   resizeMode={'cover'}
                            />
                        )}
                        uncheckedIcon={(
                            <Image source={require('../../resources/images/02.menu_general/checkbox_off.png')}
                                   style={[{
                                       marginTop: -5,
                                       width: _checkBoxSize,
                                       height: _checkBoxSize,
                                   }]}
                                   resizeMode={'cover'}
                            />
                        )}
                        checked={checkList.isChecked}
                        onPress={() => {
                            checkList.isChecked = !checkList.isChecked;
                            this.setState({productsCheckList: this.state.productsCheckList});
                        }}
                    />
                </View>
                <ATagLinkComponent
                    key={`a_tag_link_component_${index}`}
                    href={`/ProductDetailScreen/${checkList.product!.id}`}
                    style={{
                        flexDirection: "row",
                        flex: 1,
                    }}
                    onPress={() => {
                        alert(checkList.product!.id)
                        // 商品詳細画面へ遷移
                        let productDetailScreenModel: ProductDetailScreenModel = new ProductDetailScreenModel();
                        productDetailScreenModel.productId = checkList.product!.id.toString();
                        this.props.navigation.navigate('ProductDetailScreen', productDetailScreenModel);
                    }}
                    view={
                        <View style={{flexDirection: 'row'}}>
                            <Image source={source}
                                   style={[{
                                       backgroundColor: 'white',
                                       borderRadius: 5,
                                       borderColor: appColors.transparent,
                                       width: _imageSize,
                                       height: _imageSize,
                                   }]}
                                   resizeMode={'cover'}
                            />

                            <View style={{flexDirection: "column", height: _imageSize, marginLeft: appS.margins.side}}>
                                <Text
                                    style={{
                                        marginTop: 0,
                                        fontFamily: appFont.family.bold,
                                        width: _width - _imageSize - _checkBoxSize * 2 - appS.margins.side * 3,
                                        fontSize: appFont.size.listDescription,
                                        color: appColors.fontBlack,
                                        fontWeight: "bold"
                                    }}
                                    numberOfLines={1}
                                    ellipsizeMode='tail'
                                >
                                    {checkList.product.name}</Text>

                                <Text
                                    style={{
                                        fontFamily: appFont.family.default,
                                        width: _width - _imageSize - _checkBoxSize * 2 - appS.margins.side * 3,
                                        fontSize: appFont.size.listDescription,
                                        color: appColors.fontGray,
                                        marginTop: Platform.OS == 'android'? 0 : appS.margins.side / 2,
                                    }}
                                    numberOfLines={1}
                                    ellipsizeMode='tail'
                                >
                                    {checkList.product.maker?.name}</Text>
                                <Text
                                    style={{
                                        fontFamily: appFont.family.default,
                                        width: _width - _imageSize - _checkBoxSize * 2 - appS.margins.side * 3,
                                        fontSize: appFont.size.listDescription,
                                        color: appColors.fontGray,
                                        marginTop: Platform.OS == 'android'? -appS.margins.side : appS.margins.side / 2,
                                    }}
                                    numberOfLines={1}
                                    ellipsizeMode='tail'
                                >出荷単位　{checkList.product.capacity}</Text>
                            </View>
                        </View>
                    }
                />

            </View>

        );
    }

    /**
     * サンプルかごに商品を送る
     */
    _productIntoCart = () => {
        this.state.productsCheckList.forEach(productCheckList => {
            if (productCheckList.isChecked) {
                let _isError = false;
                ProductApiFactory(AppGlobalDataUtil.getConfiguration(), AppGlobalDataUtil.getBasePath())
                    .requestProductIntoCart(productCheckList.product.id.toString(), 'in')
                    .then(() => {
                        // Analytics
                        AnalyticsUtil.sendAnalytics(
                            DOpenEventName.cartIn,
                            AnalyticsEventName.cartIn,  // アナリティクスのイベント名
                            'SelectSampleScreen',
                            null,
                            productCheckList.product,
                            null,
                            null,
                        );

                        this.setState({isShowSentModal: true});
                    })
                    .catch((e) => {
                        // エラー
                        if (!_isError) {
                            AlertUtil.alert(`${I18n.t('errorNetworkTitle')}`, `${I18n.t('errorNetwork')}`);
                            log.debug(e);
                        }
                        _isError = true;
                    });
            }
        });
    }

    /**
     * 未ログイン時の表示
     */
    _renderNoLogin = () => {
        return (
            <View style={{flex: 1, backgroundColor: appColors.backBaseColor}}>
                <NoUserImageComponent navigation={this.props.navigation} />
            </View>
        );
    }

    /**
     * Topの文言追加
     */
    _renderTopText = () => {
        return (
            <View style={{margin: this._marginSize, marginLeft: this._marginSize, marginRight: this._marginSize}}>
                <Text style={{fontFamily: appFont.family.default, fontSize: appFont.size.subTopic, marginBottom: 16}}>
                    {this._param.type == 'request' ? 'サンプル依頼する商品を選択してください。' : 'サンプルかごに入れる商品を選択してください。'}
                </Text>
                {/*所持コイン*/}
                {AppG.user?.userFreeCoin != null && (
                    <View style={{flexDirection: "row-reverse", marginTop: appS.line.space}}>
                        <Text
                            style={{
                                fontFamily: appFont.family.bold,
                                fontSize: appFont.size.default,
                                color: appColors.coin,
                                fontWeight: "bold"
                            }}>
                            {`${AppG.user?.userFreeCoin}`}
                        </Text>
                        <Image
                            source={require('../../resources/images/02.menu_general/i_coin.png')}
                            style={{
                                margin: 2,
                                width: appS.coin.iconSize,
                                height: appS.coin.iconSize,
                            }}
                        />
                        <Text style={{fontFamily: appFont.family.bold, fontSize: appFont.size.default, color: appColors.coin, fontWeight: "bold"}}>所持コイン</Text>
                    </View>
                )}
            </View>
        );
    }

    _renderSmall = () => {
        let _width = AppG.window.width;
        if (ScreenSizeUtil.isWebSize()) {
            _width = appS.webMainContentsSize.width;
        }
        _width = _width - appS.margins.side * 2;

        return (
            <SafeAreaView
                style={[{height: AppG.window.height, flex: 1, flexDirection: 'column', backgroundColor: appColors.white}]}
                onLayout={(event) => {}}
            >
                <CustomHeaderComponentSmall
                    navigation={this.props.navigation}
                    leftButton={'back'}
                    rightButton={null}
                    title={'商品選択'}
                    searchBar={false}
                    textColor={appColors.black}
                    barColor={appColors.white}
                    borderBottomColor={appColors.transparent}
                    statusBarType={"dark-content"}
                />

                {/*未ログイン*/}
                {AppG.user == null && this._renderNoLogin()}

                {/*一覧*/}
                {AppG.user != null && (
                    <KeyboardAwareScrollView style={[{flex: 1, flexDirection: 'column', backgroundColor: appColors.backBaseColor}]}>
                        {this._renderTopText()}

                        {/*一覧*/}
                        {!ValidateUtil.isEmptyArray(this.state.productsCheckList) && this.state.productsCheckList.map((checkList: {product: Product, isChecked: boolean}, index: number) => {
                            return (
                                <View key={`cart_view_${index}`}>
                                    {this._renderProductCell(checkList, index)}
                                    <SpacerComponent height={appS.margins.side} />
                                </View>
                            );
                        })}

                        {/*依頼ボタン*/}
                        {!ValidateUtil.isEmptyArray(this.state.productsCheckList) && (
                            <TouchableOpacity
                                style={[
                                    {
                                        height: appS.buttonSize.height,
                                        width: appS.buttonSize.width,
                                        backgroundColor: this._checkSelected()? appColors.buttonOrange : appColors.gray,
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        alignSelf: 'center'
                                    }
                                ]}
                                disabled={!this._checkSelected()}
                                onPress={() => {
                                    if (this._param.type == 'request') {
                                        let sampleRequestScreenModel: SampleRequestScreenModel = new SampleRequestScreenModel();
                                        sampleRequestScreenModel.productIds = '|';
                                        let _checked = false;
                                        this.state.productsCheckList.forEach(productsCheck => {
                                            if (productsCheck.isChecked) {
                                                _checked = true;
                                                sampleRequestScreenModel.productIds = `${sampleRequestScreenModel.productIds}|${productsCheck.product.id}`;
                                            }
                                        })
                                        sampleRequestScreenModel.productIds = `${sampleRequestScreenModel.productIds}|`;

                                        if (!_checked) {
                                            AlertUtil.alert('エラー', 'サンプルを選択してください');
                                        }

                                        this.props.navigation.navigate('SampleRequestScreen', sampleRequestScreenModel);
                                    } else {
                                        this._productIntoCart();
                                    }
                                }}
                            >
                                <Text style={{fontFamily: appFont.family.bold, color: appColors.white, fontSize: appS.button.text, fontWeight: "bold"}}>
                                    {this._param.type == 'request' ? 'サンプル依頼画面へ進む' : 'サンプルかごに入れる'}
                                </Text>
                            </TouchableOpacity>
                        )}

                        {!this.state.isLoadProducts && ValidateUtil.isEmptyArray(this.state.productsCheckList) && (
                            <NoItemsIconComponent text={'商品を選択してください'}/>
                        )}

                    </KeyboardAwareScrollView>
                )}

                {/*送信したよModal*/}
                <ImageModalComponent
                    imageSource={require('../../resources/images/cart_added.png')}
                    marginTop={(AppG.window.height - ((AppG.window.height / 2) * 1.3)) / 2}
                    width={(AppG.window.height / 2) * 1.3}
                    height={(AppG.window.height / 2)}
                    isShowModal={this.state.isShowSentModal}
                    onClosePress={() => {
                        // 閉じて戻る
                        this.setState({'isShowSentModal': false});
                        ExpoUtil.goBack(this.props.navigation);
                    }}/>
            </SafeAreaView>
        );
    }

    _renderWide = () => {
        const {navigation} = this.props;

        let _width = AppG.window.width;
        if (ScreenSizeUtil.isWebSize()) {
            _width = appS.webMainContentsSize.width;
        }
        _width = _width - appS.margins.side * 2;

        return (
            <SafeAreaView
                style={[{height: AppG.window.height, flex: 1, flexDirection: 'column', backgroundColor: appColors.white}]}
                onLayout={(event) => {}}
            >
                <CustomHeaderComponentWide
                    navigation={navigation}
                    searchBar={true}
                />

                <View
                    style={{
                        flex: 1,
                        flexDirection: 'row',
                        backgroundColor: appColors.backBaseColor,
                        alignSelf: 'center',
                    }}>
                    <ScrollView
                        horizontal  // 下階層のスクロールイベントを拾えるようにしている
                        style={{
                            width: AppG.window.width,
                            height: AppG.window.height - appS.header.webHeight,
                        }}
                    >
                        <View style={[{flex: 1, flexDirection: 'column', width: AppG.window.width}]}>

                            <KeyboardAwareScrollView style={[{flex: 1, flexDirection: 'column'}]}>
                                <View style={[{flex: 1, flexDirection: ScreenSizeUtil.isWebSize()? 'row': 'column', justifyContent: 'center'}]}>
                                    <View style={[{flexDirection: 'column'}]}>
                                        <View style={{width: ScreenSizeUtil.isWebSize()? appS.webMainContentsSize.width : AppG.window.width}}>
                                            {/*未ログイン*/}
                                            {AppG.user == null && this._renderNoLogin()}

                                            {/*一覧*/}
                                            {AppG.user != null && (
                                                <View style={[{flex: 1, flexDirection: 'column'}]}>
                                                    {this._renderTopText()}
                                                    <View style={{height: 1, backgroundColor: appColors.spacerGray, marginTop: 8, marginBottom: 8}} />
                                                    {/*一覧*/}
                                                    {!ValidateUtil.isEmptyArray(this.state.productsCheckList) && this.state.productsCheckList.map((checkList: {product: Product, isChecked: boolean}, index: number) => {
                                                        return (
                                                            <View key={`cart_view_${index}`}>
                                                                {this._renderProductCell(checkList, index)}
                                                                <BorderComponent width={_width} />
                                                            </View>
                                                        );
                                                    })}

                                                    {/*商品無し*/}
                                                    {ValidateUtil.isEmptyArray(this.state.productsCheckList) && (
                                                        <NoItemsIconComponent text={'商品を選択してください'}/>
                                                    )}
                                                    {!ValidateUtil.isEmptyArray(this.state.productsCheckList) && (
                                                        <TouchableOpacity
                                                            style={[
                                                                {
                                                                    marginLeft: ScreenSizeUtil.isWebSize()? 0 : 16,
                                                                    marginRight: ScreenSizeUtil.isWebSize()? 0 : 16,
                                                                    height: appS.button.height,
                                                                    backgroundColor: this._checkSelected()? appColors.buttonOrange : appColors.gray,
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                }
                                                            ]}
                                                            disabled={!this._checkSelected()}
                                                            onPress={() => {
                                                                if (this._param.type == 'request') {
                                                                    let sampleRequestScreenModel: SampleRequestScreenModel = new SampleRequestScreenModel();
                                                                    sampleRequestScreenModel.productIds = '|';
                                                                    let _checked = false;
                                                                    this.state.productsCheckList.forEach(productsCheck => {
                                                                        if (productsCheck.isChecked) {
                                                                            _checked = true;
                                                                            sampleRequestScreenModel.productIds = `${sampleRequestScreenModel.productIds}|${productsCheck.product.id}`;
                                                                        }
                                                                    })
                                                                    sampleRequestScreenModel.productIds = `${sampleRequestScreenModel.productIds}|`;

                                                                    if (!_checked) {
                                                                        AlertUtil.alert('エラー', 'サンプルを選択してください');
                                                                    }

                                                                    this.props.navigation.navigate('SampleRequestScreen', sampleRequestScreenModel);
                                                                } else {
                                                                    this._productIntoCart();
                                                                }
                                                            }}
                                                        >
                                                            <Text style={{fontFamily: appFont.family.bold, color: appColors.white, fontSize: appS.button.text, fontWeight: "bold"}}>
                                                                {this._param.type == 'request' ? 'サンプル依頼画面へ進む' : 'サンプルかごに入れる'}
                                                            </Text>
                                                        </TouchableOpacity>
                                                    )}

                                                </View>
                                            )}
                                            <SpacerComponent height={appS.footer.height} />
                                        </View>
                                    </View>
                                    <View style={{marginLeft: appS.margins.webBetweenMargin, width: appS.webSideContentsSize.width}}>
                                        {ScreenSizeUtil.isWebSize() && (
                                            <View style={{}}>
                                                <WideScreenAdComponent navigation={this.props.navigation}/>
                                                <WideScreenSNSComponent navigation={this.props.navigation}/>
                                            </View>
                                        )}
                                    </View>
                                </View>
                            </KeyboardAwareScrollView>
                        </View>
                    </ScrollView>
                </View>
                {/*送信したよModal*/}
                <ImageModalComponent
                    imageSource={require('../../resources/images/cart_added.png')}
                    marginTop={(AppG.window.height - ((AppG.window.height / 2) * 1.3)) / 2}
                    width={(AppG.window.height / 2) * 1.3}
                    height={(AppG.window.height / 2)}
                    isShowModal={this.state.isShowSentModal}
                    onClosePress={() => {
                        // 閉じて戻る
                        this.setState({'isShowSentModal': false});
                        ExpoUtil.goBack(this.props.navigation);
                    }}/>
            </SafeAreaView>
        );
    }



    /** 描画 */
    render() {
        const {navigation} = this.props;
        if (!this.state.isLoaded) {
            return (
                <View style={{flex: 1}}>
                    <IndicatorComponent
                        failTime={60}
                        isLoading={true}
                        reloadFunc={() => {
                            this.componentWillUnmount();
                            this.componentDidMount();
                        }}/>
                </View>
            );
        }


        LinkUtil.addReturnFalseToATag();
        if (ScreenSizeUtil.isWebSize()) {
            // WEBサイズ
            return this._renderWide();
        } else {
            // SPサイズ
            return this._renderSmall();
        }


    };


}
