import AppG from "./AppG";
// @ts-ignore
import {getEnvVars, log} from '../../../environment';
import moment from "moment";
import TutorialDataDao from "../data/dao/local/TutorialDataDao";
import WhatIsMemberShipModalDataDao from "../data/dao/local/WhatIsMemberShipModalDataDao";
import LoginDataDao from "../data/dao/local/LoginDataDao";
import {
    Chat,
    ChatApiFactory,
    POPUPApiFactory,
    ProductApiFactory,
    UserUserAttributeEnum
} from "../data/network/swagger-gen";
import {AxiosResponse} from "axios";
import WhatIsSampleRequestModalDataDao from "../data/dao/local/WhatIsSampleRequestModalDataDao";
import ReceivedNewMessageModalDataDao from "../data/dao/local/ReceivedNewMessageModalDataDao";
import ValidateUtil from "./ValidateUtil";
import ProductReviewReminderModalDataDao from "../data/dao/local/ProductReviewReminderModalDataDao";
import LoginUtil from "./LoginUtil";
import {ReceivedNewMessageModalDataEntityModel} from "../data/entityModels/ReceivedNewMessageModalDataEntityModel";
import {
    ProductReviewReminderModalDataEntityModel
} from "../data/entityModels/ProductReviewReminderModalDataEntityModel";

/**
 * POPUP
 * 参考
 * https://docs.google.com/spreadsheets/d/1dBMm3hZG6TWx3dK_2Bd2ls8aQ4QtJLYgAXD0GPuNY7Y/edit#gid=0
 */
class PopupUtil {

    /**
     *
     * @param navigation
     * @param modalName 渡したコンポーネントを表示する場合は true を返す
     * @param showFunc モーダルの表示処理
     */
    showModalWidthPriority = (
        navigation: any,
        modalName: 'TutorialModalComponent' | 'ReceivedNewMessageModalComponent' | 'ProductReviewReminderModalComponent' | 'PopupModalComponent',
        showFunc: () => void
    ) => {
        // テスト用
        // TutorialDataDao.delete();
        // WhatIsMemberShipModalDataDao.delete();

        /** 既に表示したかどうか */
        let shownFlg = false;

        const { routeName } = navigation.state;
        // 表示チェック変数一覧
        let showTutorialModalComponent = false;
        let receivedNewMessageModalComponent = false;
        let productReviewReminderModalComponent = false;
        let popupModalComponent = false;

        /** 新着メッセージ */
        const newMessageFunc = () => ChatApiFactory(AppG.getConfiguration(), AppG.getBasePath())
            .getChats(0, 100).then((chatsRes: AxiosResponse<Chat[]>) => {
                // console.log('++++++++++newMessageFunc+++++++++++');
                // alert('chat')
                if (chatsRes != null && !ValidateUtil.isEmptyArray(chatsRes.data)) {
                    // console.log(chatsRes.data);
                    const _chats = chatsRes.data.filter(_chat => !_chat.isRead);
                    if (_chats != null && !ValidateUtil.isEmptyArray(_chats)) {
                        // 未読ありで通知必要なら出す
                        ReceivedNewMessageModalDataDao.get().then(receivedNewMessageModalData => {
                            // alert(JSON.stringify(whatIsMemberShipModalData))
                            if (receivedNewMessageModalData == null || receivedNewMessageModalData.hide_flg_received_new_message == null) {
                                // 初回は既読にする
                                const receivedNewMessageModalDataEntityModel = new ReceivedNewMessageModalDataEntityModel();
                                receivedNewMessageModalDataEntityModel.hide_flg_received_new_message = '1';
                                receivedNewMessageModalDataEntityModel.last_show_date_received_new_message = moment().toString();
                                ReceivedNewMessageModalDataDao.addOrUpdate(receivedNewMessageModalDataEntityModel);
                                receivedNewMessageModalComponent = false;
                            } else if (receivedNewMessageModalData.hide_flg_received_new_message != '1') {
                                receivedNewMessageModalComponent = true;
                            } else if (
                                receivedNewMessageModalData?.last_show_date_received_new_message != null
                                && moment(receivedNewMessageModalData?.last_show_date_received_new_message).diff(moment(), 'hours') < -24  // 最後に開かれて（閉じられて）から1日以上経過してたら
                            ) {
                                receivedNewMessageModalComponent = true;
                            } else if (receivedNewMessageModalData?.last_show_date_received_new_message == null) {
                                receivedNewMessageModalComponent = true;
                            }

                            if (routeName != 'HomeScreen') {
                                receivedNewMessageModalComponent = false;
                            }

                            if (modalName == 'ReceivedNewMessageModalComponent' && receivedNewMessageModalComponent) {
                                shownFlg = true;
                                showFunc();
                                return;
                            } else if (receivedNewMessageModalComponent) {
                                shownFlg = true;
                            }

                            if (shownFlg) {
                                return;
                            }

                            // レビュー催促
                            Promise.all([productReviewReminderFunc()]).then(() => {});
                        }); // ReceivedNewMessageModalDataDao
                    } else {
                        // レビュー催促
                        Promise.all([productReviewReminderFunc()]).then(() => {});
                    }
                }
            })  // ChatApiFactory

        /** サンプル依頼レビュー催促 */
        const productReviewReminderFunc = () => ProductApiFactory(AppG.getConfiguration(), AppG.getBasePath())
            .requeetedProductReviews(4, 1).then((reviewsRes) => {
                // console.log('++++++++++productReviewReminderFunc+++++++++++');
                // alert('review');
                if (reviewsRes != null && !ValidateUtil.isEmptyArray(reviewsRes.data)) {
                    const _reviews = reviewsRes.data.filter(_review => _review.reviewed == 0 && _review.responseStatus == 2);   // レビュー必要一覧
                    if (_reviews != null && !ValidateUtil.isEmptyArray(_reviews)) {
                        ProductReviewReminderModalDataDao.get().then(productReviewReminderModalData => {
                            if (productReviewReminderModalData == null || productReviewReminderModalData.hide_flg_product_review_reminder == null) {
                                // 初回は既読にする
                                const productReviewReminderModalDataEntityModel = new ProductReviewReminderModalDataEntityModel();
                                productReviewReminderModalDataEntityModel.hide_flg_product_review_reminder = '1';
                                productReviewReminderModalDataEntityModel.last_show_date_product_review_reminder = moment().toString();
                                ProductReviewReminderModalDataDao.addOrUpdate(productReviewReminderModalDataEntityModel);
                                productReviewReminderModalComponent = false;
                            } else if (productReviewReminderModalData.hide_flg_product_review_reminder != '1') {
                                productReviewReminderModalComponent = true;
                            } else if (
                                productReviewReminderModalData?.last_show_date_product_review_reminder != null
                                && moment(productReviewReminderModalData?.last_show_date_product_review_reminder).diff(moment(), 'hours') < -1  // 最後に開かれて（閉じられて）から1時間以上経過してたら
                            ) {
                                productReviewReminderModalComponent = true;
                            } else if (productReviewReminderModalData?.last_show_date_product_review_reminder == null) {
                                productReviewReminderModalComponent = true;
                            }

                            if (routeName != 'HomeScreen') {
                                productReviewReminderModalComponent = false;
                            }

                            if (modalName == 'ProductReviewReminderModalComponent' && productReviewReminderModalComponent) {
                                shownFlg = true;
                                showFunc();
                                return;
                            } else if (productReviewReminderModalComponent) {
                                shownFlg = true;
                            }
                        })  // ProductReviewReminderModalDataDao
                    }
                }
            })  // ProductApiFactory


        /** チュートリアルモーダル */
        const tutorialFunc = (logedin: boolean) => TutorialDataDao.get().then(tutorialData => {
            // console.log('++++++++++tutorialFunc+++++++++++');
            // alert(JSON.stringify(tutorialData))

            if (!logedin) {
                if (tutorialData == null || tutorialData.hide_flg_tutorial != '1') {
                    showTutorialModalComponent = true;
                } else if (
                    tutorialData?.last_show_date_tutorial != null
                    && moment(tutorialData?.last_show_date_tutorial).diff(moment(), 'days') < -100  // 最後に開かれて（閉じられて）から100日以上経過してたら
                ) {
                    showTutorialModalComponent = true;
                } else if (tutorialData?.last_show_date_tutorial == null) {
                    showTutorialModalComponent = true;
                }

                if (routeName != 'HomeScreen') {
                    showTutorialModalComponent = false;
                }

                if (modalName == 'TutorialModalComponent' && showTutorialModalComponent) {
                    shownFlg = true;
                    showFunc();
                    return;
                } else if (showTutorialModalComponent) {
                    // チュートリアルが表示されるときは他のを表示しない
                    shownFlg = true;
                }

                if (shownFlg) {
                    return;
                }
            }

            // 管理画面POPUPの取得
            const func = () => POPUPApiFactory(AppG.getConfiguration(), AppG.getBasePath())
                .getPopups(routeName, AppG.analyticsHash!).then((popup) => {
                    // console.log('++++++++++func+++++++++++');
                if (
                    popup != null
                    && popup.data != null
                    && (!ValidateUtil.isEmptyArray(popup.data.container) || popup.data.questionnaires != null)
                ) {
                    popupModalComponent = true;
                }
                if (modalName == 'PopupModalComponent' && popupModalComponent) {
                    shownFlg = true;
                    showFunc();
                    return;
                } else if (popupModalComponent) {
                    shownFlg = true;
                }

                if (shownFlg) {
                    return;
                }

                LoginDataDao.get().then(loginData => {
                    if (loginData != null
                        && loginData?.user_id != null
                        && loginData?.user_hash != null
                        && loginData?.mail != null
                        && loginData?.password != null
                    ) {
                        // ログインデータがある
                        // 新着メッセージありモーダル
                        Promise.all([newMessageFunc()]).then(() => {});
                    } else {
                        // ローカルStorageからデータ読み込みに失敗した場合(故意にログアウトしていた場合)
                        // なにもしない
                    }
                });
            });

            if (AppG.analyticsHash != null) {
                func()
            } else {
                LoginUtil.setAnalyticsHash(func);
            }

        }); // TutorialDataDao

        //////////////
        // 処理開始
        //////////////
        LoginDataDao.get().then(loginData => {
            if (loginData != null
                && loginData?.user_id != null
                && loginData?.user_hash != null
                && loginData?.mail != null
                && loginData?.password != null
            ) {
                // 新着メッセージありモーダル
                Promise.all([tutorialFunc(true)]).then(() => {});
            } else {
                // ローカルStorageからデータ読み込みに失敗した場合(故意にログアウトしていた場合)
                // 会員訴求モーダル
                // Promise.all([whatIsMemberShipFunc()]).then(() => {});
                // チュートリアル
                Promise.all([tutorialFunc(false)]).then(() => {});
            }
        });


    }

    /**
     * サンプルリクエストとは？の表示
     * @param showFunc 表示func
     * @param noShowFunc 表示でない時の処理のfunc
     */
    showWhatIsSampleRequestModal = (showFunc: () => void, noShowFunc: () => void) => {
        let showWhatIsMemberShipModalComponent = false;
        if (AppG.user?.userAttribute == UserUserAttributeEnum.Cook) {
            WhatIsSampleRequestModalDataDao.get().then(whatIsSampleRequestModalDataDao => {
                // alert(JSON.stringify(whatIsMemberShipModalData))
                if (whatIsSampleRequestModalDataDao == null || whatIsSampleRequestModalDataDao.hide_flg_what_is_sample_request != '1') {
                    showWhatIsMemberShipModalComponent = true;
                }

                if (showWhatIsMemberShipModalComponent) {
                    showFunc();
                    return;
                } else {
                    noShowFunc();
                    return;
                }
            }); // WhatIsSampleRequestModalDataDao
        }
    }


}

export default new PopupUtil();
