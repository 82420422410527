import React, {Component} from 'react';
import {Image, Platform, Text, TouchableOpacity, View} from 'react-native';
import {appColors, appFont, appS} from "../../../resources/styles/style";
import AppG from "../../util/AppG";
import ExpoUtil from "../../util/ExpoUtil";
import ScreenSizeUtil from "../../util/ScreenSizeUtil";

interface Props {
    title: string,
    width: number,
    shadow: boolean,
    type: 'recipe' | 'product' | 'black',
}


interface State { }

export class WideSubTitleComponent extends Component<Props, State> {

    // Propsのデフォルト値
    static defaultProps = {
        shadow: true,
        type: 'black',
    };



    render() {
        const {title, width, shadow, type} = this.props;
        let _style: any = {
            width: width,
            height: 40,
            backgroundColor: appColors.white,
            borderLeftWidth: 1,
            borderRightWidth: 1,
            borderColor: appColors.borderGray,
            marginBottom: 10,
            shadowColor: '#000',
            shadowOffset: {width: 0, height: 4},
            shadowOpacity: 0.2,
            shadowRadius: 2,
        };

        if (!shadow) {
            _style = {
                width: width,
                height: 40,
                backgroundColor: appColors.white,
                marginBottom: 10,
            };
        }

        let _color = appColors.fontBlack;
        switch (type) {
            case "recipe":
                _color = appColors.recipeHeader;
                break;
            case "product":
                _color = appColors.productHeader;
                break;
            case "black":
                _color = appColors.fontBlack;
                break;
        }

        return (
            <View style={_style}>
                <Text style={{
                    textAlign: 'center',
                    alignSelf: 'center',
                    fontFamily: appFont.family.bold,
                    fontSize: appFont.size.topic,
                    color: _color,
                    fontWeight: "bold"
                }}>{title}</Text>
            </View>
        );
    }
}
