import {LoginDataEntityModel} from "../entityModels/LoginDataEntityModel";
import I18n from "../../../resources/language/i18n";
import AlertUtil from "../../util/AlertUtil";
// @ts-ignore
import {log} from '../../../../environment';
// @ts-ignore
import {AccountApiFactory, User, UserApiFactory} from "./swagger-gen";
import AppGlobalDataUtil from "../../util/AppG";
import {AuthUtil} from "../../util/firebase/AuthUtil";
import {AxiosResponse} from "axios";
import {Platform} from "react-native";

/**
 * ログインアカウント関係のネットワーク。
 * LoginUtil経由で利用
 */
class LoginDataNetwork {

    /**
     * 新規登録処理。
     * @param mail  メールアドレス
     * @param password  パスワード
     * @param passwordConfirm   確認用パスワード
     * @param sCallback 成功時のコールバック(確認メール送信しましたページへ遷移させる)
     * @param eCallback
     */
    signUp = async (mail: string, password: string, passwordConfirm: string, sCallback: () => void, eCallback: () => void) => {
        const signUp = () => {
            AuthUtil.sendVerificationMail();    // 認証メール送信
            sCallback();
        }

        // 先にfirebase登録
        AuthUtil.signUp(mail, password, passwordConfirm, signUp, eCallback);
    };

    /**
     * Login 処理。Login チェックも兼ねる
     * @param mail  メールアドレス
     * @param password  パスワード
     */
    login = async (mail: string, password: string): Promise<LoginDataEntityModel | null> => {
        let loginDataEntityModel: LoginDataEntityModel | null = null;
        const _ = await AccountApiFactory(AppGlobalDataUtil.getConfiguration(), AppGlobalDataUtil.getBasePath())
            .loginAccount(Platform.OS)
            .then(async () => {
                // Userの取得
                const _ = await UserApiFactory(AppGlobalDataUtil.getConfiguration(), AppGlobalDataUtil.getBasePath())
                    .getUser()
                    .then((userRes: AxiosResponse<User>) => {
                        // log.debug('------------user--------------');
                        // log.debug(userRes.data);
                        if (userRes.data != null && userRes.data.id != null && userRes.data.userHash != null) {
                            let ldem: LoginDataEntityModel | null = new LoginDataEntityModel();
                            ldem.mail = mail;
                            ldem.password = password;
                            ldem.user_id = userRes.data.id.toString();
                            ldem.user_hash = userRes.data.userHash;

                            AppGlobalDataUtil.user = userRes.data;

                            log.debug(`----------- UserPlan is ${AppGlobalDataUtil.user.userPlan} ----------------`);
                            log.debug('------------loginDataEntityModel from user--------------');
                            log.debug(ldem);

                            loginDataEntityModel = ldem;
                        } else {
                            AlertUtil.alert(`${I18n.t('errorNetworkTitle')}：LOGIN_L_U`, `${I18n.t('errorNoUser')}`);
                            log.debug(`---------------Network Error (Login.logIn)--------------------`);
                        }
                    })
            })
            .catch((e: any) => {
                if (e.toString() == 'Error: Request failed with status code 405') {
                    log.debug(`---------------Network Error (Login.logIn.status.code.405)--------------------`);
                    log.debug(e);
                } else if (e.toString() == 'Error: Request failed with status code 400') {
                    // まだユーザー登録していない場合エラー表示しない（新規登録直後）
                    log.debug(`---------------Network Error (Login.logIn.status.code.400)--------------------`);
                    log.debug(e);
                } else {
                    // AlertUtil.alert(`${I18n.t('errorNetworkTitle')}：LOGIN_L`, `${I18n.t('errorNetwork')}`);
                    log.debug(`---------------Network Error (Login.logIn.status.code.network)--------------------`);
                    log.debug(e);
                }
            });

        return loginDataEntityModel;

    };

    /**
     * パスワード変更
     * @param nowPassword   現在のパスワード
     * @param newPassword   新しいパスワード
     * @param callback
     */
    changePassword = (nowPassword: string, newPassword: string, callback: () => void, failCallBack: () => void) => {
        AuthUtil.changePassword(nowPassword, newPassword, callback, failCallBack);
    };

    /**
     * パスワードリセットメールの送信
     * @param email 送信先メールアドレス
     */
    resetPassword = (email: string, callback: () => void, failCallBack: () => void) => {
        AuthUtil.resetPassword(email, callback, failCallBack);
    };

}

export default new LoginDataNetwork();
