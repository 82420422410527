import React, {Component} from 'react';
import {Image, Platform, Text, TouchableOpacity, View} from 'react-native';
import {appColors, appFont, appS} from "../../resources/styles/style";
import {BorderComponent} from "./BorderComponent";
import {Maker} from "../data/network/swagger-gen";
// @ts-ignore
import isURL from 'validator/lib/isURL';
import {MakerDetailScreenModel} from "../data/models/screen/MakerDetailScreenModel";
import AppG from "../util/AppG";
import ExpoUtil from "../util/ExpoUtil";
import ValidateUtil from "../util/ValidateUtil";
import ScreenSizeUtil from "../util/ScreenSizeUtil";
import {ATagLinkComponent} from "./ATagLinkComponent";

interface Props {
    navigation: any,
    makers: Maker[],
    noRightButton: boolean,
    onPress: ((id: string, name: string) => void) | null,
}

interface State { }

export class MakerListComponent extends Component<Props, State> {

    // Propsのデフォルト値
    static defaultProps = {
        noRightButton: false,
        onPress: null,
    };

    render() {
        const {navigation, makers, noRightButton} = this.props;
        return (
            <View>
                {makers.map((maker: Maker, index: number) => {
                    return (
                        <MakerCellComponent
                            key={`maker_cell_${index}`}
                            navigation={navigation}
                            maker={maker}
                            noRightButton={noRightButton}
                            onPress={this.props.onPress}/>
                    );
                })}
            </View>
        );
    }
}







interface MakerCellSmallProps {
    navigation: any,
    maker: Maker,
    noRightButton: boolean,
    onPress: ((id: string, name: string) => void) | null,
}

interface MakerCellSmallState {
    followed: boolean,
}


class MakerCellComponent extends Component<MakerCellSmallProps, MakerCellSmallState> {


    constructor(props: any) {
        super(props);
        this.state = {
            followed: (this.props.maker.followed != null && this.props.maker.followed === 1),  // 1ならお気に入り
        };
    };

    render() {

        let _width = AppG.window.width;
        if (ScreenSizeUtil.isWebSize()) {
            _width = appS.webMainContentsSize.width;
        }
        _width = _width - appS.margins.side * 2;

        const {navigation, maker, noRightButton} = this.props;

        let source = require('../../resources/images/no_image.png');
        if (!ValidateUtil.isEmptyExact(maker.logo) && isURL(maker.logo)) {
            source = {uri: maker.logo};
        }

        let _makerNameWidth = 0;
        if (ScreenSizeUtil.isWebSize()) {
            _makerNameWidth = noRightButton? AppG.window.width/2 - 270 : AppG.window.width/2 - 170;
        } else {
            _makerNameWidth = noRightButton? AppG.window.width - 200 : AppG.window.width - 110;
        }

        return (
            <View>
                <ATagLinkComponent
                    href={`/MakerDetailScreen/${maker.id}`}
                    style={{
                        height: 40,
                        flexDirection: "row",
                        marginBottom: 0,
                        marginLeft: appS.margin.size,
                        marginRight: appS.margin.size}}
                    onPress={() => {
                        if (this.props.onPress == null) {
                            // メーカー詳細画面へ遷移
                            let makerDetailScreenModel: MakerDetailScreenModel = new MakerDetailScreenModel();
                            makerDetailScreenModel.makerId = maker.id.toString();
                            if (Platform.OS == 'web') {
                                navigation.navigate('MakerDetailScreen', makerDetailScreenModel);
                            } else {
                                navigation.push('MakerDetailScreen', makerDetailScreenModel);
                            }
                        } else {
                            this.props.onPress(maker.id.toString(), maker.name);
                        }
                    }}
                view={
                    <View style={{flexDirection: "row", flex: 1}}>
                        <Image source={source}
                               style={[{
                                   backgroundColor: 'white',
                                   borderRadius: 5,
                                   width: 45,
                                   height: 45,
                               }]}
                               resizeMode={'cover'}
                               accessibilityLabel={maker.name}
                        />
                        <Text
                            style={{
                                width: _makerNameWidth,
                                fontFamily: appFont.family.default,
                                fontSize: appFont.size.list,
                                color: appColors.black,
                                marginLeft: 8,
                                marginTop: Platform.OS == 'android' ? 0 : 8,
                            }}
                            numberOfLines={1}
                            ellipsizeMode="tail"
                        >
                            {maker.name}</Text>

                        {/*>アイコン*/}
                        {!noRightButton && (
                            <View style={{flexDirection: "row", flex: 1, justifyContent: 'flex-end'}}>
                                <Image source={require('../../resources/images/02.menu_general/arrow_B_right.png')}
                                       style={[{
                                           marginTop: 15,
                                           backgroundColor: 'white',
                                           width: 14,
                                           height: 14,
                                           marginRight: ScreenSizeUtil.isWebSize() ? 32 : (ExpoUtil.isIPad() ? 32 : 0),
                                       }]}
                                       resizeMode={'cover'}
                                />
                            </View>
                        )}

                    </View>
                }
                />
                {ScreenSizeUtil.isWebSize() && (
                    <View style={{
                        backgroundColor: appColors.spacerGray,
                        height: 1,
                        width: noRightButton? AppG.window.width/2 - appS.margin.size : AppG.window.width/2 - (appS.margin.size * 2),
                        marginTop: 10,
                        marginBottom: 10,
                        marginLeft: noRightButton ? 0 : appS.margin.size,
                        marginRight: appS.margin.size,
                    }}/>
                )}
                {!ScreenSizeUtil.isWebSize() && (<BorderComponent borderColor={appColors.spacerGray} width={_width}/>)}
            </View>
        );
    }
}
