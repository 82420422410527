export class AnalyticsUserJpModel {
    /**
     * 会員属性（一般ユーザー(normal)/飲食人(cook)）
     * @type {string}
     * @memberof User
     */
    userAttribute?: string | null = null;
    /**
     * 性別
     * @type {string}
     * @memberof User
     */
    gender?: string | null = null;
    /**
     * 年齢
     * @type {string}
     * @memberof User
     */
    age?: string | null = null;

    /**
     * 年代
     * @type {string}
     * @memberof User
     */
    ages?: string | null = null;
    /**
     * 店舗所在地（大）
     * @memberof User
     */
    restaurantLargeLocation?: string | null = null;
    /**
     * 店舗所在地（中）
     *
     * @memberof User
     */
    restaurantMediumLocation?: string | null = null;
    /**
     * 店舗業態（大）
     *
     * @memberof User
     */
    restaurantLargeFormat?: string | null = null;
    /**
     * 店舗業態（中）
     *
     * @memberof User
     */
    restaurantMediumFormat?: string | null = null;
    /**
     * 店舗規模
     *
     * @memberof User
     */
    numOfRestaurant?: string | null = null;
    /**
     * 主要店舗展開エリア
     *
     * @memberof User
     */
    majorRestaurantArea?: string | null = null;
    /**
     * 客単価のマスター
     *
     * @memberof User
     */
    customerUnitPrice?: string | null = null;
}
