export class SampleProductRequestDataEntityModel {
    /**
     * 最後に依頼したサンプルの一番上の既存仕入先の種類
     */
    existingBusinessPartnersType: string | undefined = undefined;

    /**
     * 最後に依頼したサンプルの一番上の既存仕入先
     */
    existingBusinessPartners: string | undefined = undefined;

}
