import React from 'react'
import {Dimensions, Platform, SafeAreaView, ScrollView, View} from 'react-native'
import {appColors, appS} from '../../resources/styles/style'
import {BaseScreen} from "./BaseScreen";
import LoginUtil from "../util/LoginUtil";
import {IndicatorComponent} from "../components/IndicatorComponent";
// @ts-ignore
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scrollview';
import LoginDataDao from "../data/dao/local/LoginDataDao";
import {LoginDataEntityModel} from "../data/entityModels/LoginDataEntityModel";
import AppG from "../util/AppG";
import {CustomHeaderComponentSmall} from "../components/small/CustomHeaderComponentSmall";
import UrlUtil from "../util/UrlUtil";
import {SpacerComponent} from "../components/SpacerComponent";
// @ts-ignore
import isURL from 'validator/lib/isURL';
import ValidateUtil from "../util/ValidateUtil";
// @ts-ignore
import {log} from '../../../environment';
import {SearchScreenModel} from "../data/models/screen/SearchScreenModel";
import ExpoUtil from "../util/ExpoUtil";
import {RecipeCategoryListComponent} from "../components/RecipeCategoryListComponent";
import ScreenSizeUtil from "../util/ScreenSizeUtil";
import {CustomHeaderComponentWide} from "../components/wide/CustomHeaderComponentWide";
import {WideScreenAdComponent} from "../components/wide/WideScreenAdComponent";
import AnalyticsUtil, {AnalyticsEventName, DOpenEventName} from "../util/firebase/AnalyticsUtil";

import {TopicTitleComponent} from "../components/TopicTitleComponent";
import {WideScreenSNSComponent} from "../components/wide/WideScreenSNSComponent";
import LinkUtil from "../util/LinkUtil";

interface Props {
    navigation: any, // ナビゲーション用
}

interface State {
    resizeWindow: boolean,  // 値に意味はなし。windowサイズの拡大縮小をrenderに送るためのもの
    isLoaded: boolean,

    // コンテンツ
    headerTitle: string,
}

export class SearchMenuCategoryScreen extends BaseScreen<Props, State> {
    _resizeTimer = setInterval(() => {
        // window 拡大縮小を反映させる
        AppG.window = Dimensions.get('window');
        if (AppG.window.width != AppG.windowBefore.width || AppG.window.height != AppG.windowBefore.height) {
            this.setState({'resizeWindow': true});
            AppG.windowBefore = Dimensions.get('window');
        }
        LoginUtil.setRefreshedScreenFlg(false);
    }, 100);

    _loginData = new LoginDataEntityModel();

    _flameSize = ScreenSizeUtil.isWebSize()? AppG.window.width / 10 : 16;    // 左右の margin
    _marginSize = ScreenSizeUtil.isWebSize()? 0 : appS.margin.size;

    // このScreenへ渡すパラム
    _param: SearchScreenModel = new SearchScreenModel();
    _contentsLoadCount = 20; // コンテンツの一度の読み込み数
    _searchText = ''; // 検索文字

    constructor(props: any) {
        super(props);
        this.state = {
            resizeWindow: true,  // 値に意味はなし。windowサイズの拡大縮小をrenderに送るためのもの
            isLoaded: false,
            headerTitle: '',
        };
    }

    /**
     * 画面開いたときのアナリティクス送信
     */
    _sendPvAnalytics = () => {
        AnalyticsUtil.sendAnalytics(
            DOpenEventName.openPage,
            AnalyticsEventName.openPage,  // アナリティクスのイベント名
            'SearchMenuCategoryScreen',
            null,
            null,
            null,
            null,
        );
    }

    componentDidMount() {
        this._param = this._getParams();

        LoginUtil.interruptOpenScreen(this.props.navigation,
            () => {
                LoginDataDao.get().then(loginData => {
                    // Web 戻るでも呼ばれる
                    if (Platform.OS == 'web') {
                        // Analytics
                        this._sendPvAnalytics();
                    }
                    AppG.getFooterBadge();

                    // URL Scheme で外部リンクから起動したときの処理と、画面遷移でこの画面を開いたときの処理
                    LoginUtil.setDefaultListener(this.props.navigation, () => {
                        // Webはここにこない
                        if (Platform.OS != 'web') {
                            // Analytics
                            this._sendPvAnalytics();
                        }
                    });
                    if (loginData != null) {
                        this._loginData = loginData;
                    }
                    this._loadMasters().then(() => {this._loadContents().then(() => this.setState({'isLoaded': true}))});
                    this.setState({headerTitle: !ValidateUtil.isEmptyExact(this._param.headerTitle) ? this._param.headerTitle! : ''});
                });
            });
    }

    componentWillUnmount () {
        clearTimeout(this._resizeTimer);
        LoginUtil.removeDefaultListener(this.props.navigation);
    };

    componentDidUpdate () {
        // レンダー完了時に呼ばれる
    }

    /**
     * パラムの取得
     * @private
     */
    _getParams = () => {
        let searchScreenModel: SearchScreenModel = UrlUtil.getParamModel(new SearchScreenModel(), this.props.navigation);
        return searchScreenModel;
    };

    /**
     * マスターのロード
     * @private
     */
    _loadMasters = async () => {
    }

    /**
     * コンテンツのロード
     * @private
     */
    _loadContents = async () => {
    }

    /**
     * メニューカテゴリーの検索
     */
    _renderMenuCategorySearch = () => {
        const {navigation} = this.props;

        return (
            <KeyboardAwareScrollView>
                <View style={[{flex: 1, flexDirection: ScreenSizeUtil.isWebSize()? 'row': 'column', justifyContent: 'center'}]}>
                    <View style={[{flexDirection: 'column'}]}>
                        <View style={{width: ScreenSizeUtil.isWebSize()? appS.webMainContentsSize.width : AppG.window.width}}>
                            {ScreenSizeUtil.isWebSize() && !ValidateUtil.isEmptyExact(this.state.headerTitle) && (
                                <TopicTitleComponent title={this.state.headerTitle}/>
                            )}

                            <RecipeCategoryListComponent navigation={navigation} fullFlg={true} onPress={(id: string, name: string) => {
                                let searchScreenModel: SearchScreenModel = new SearchScreenModel();
                                searchScreenModel.headerTitle = name;
                                searchScreenModel.menuCategoryId = id;
                                searchScreenModel.searchType = 'recipe';
                                searchScreenModel.tabHidden = true;
                                this.props.navigation.navigate('SearchResultScreen', searchScreenModel);
                            }} />
                        </View>
                    </View>
                    {ScreenSizeUtil.isWebSize() && (
                        <View style={{marginLeft: appS.margins.webBetweenMargin, width: appS.webSideContentsSize.width}}>
                            <WideScreenAdComponent navigation={this.props.navigation}/>
                            <WideScreenSNSComponent navigation={this.props.navigation}/>
                        </View>
                    )}
                </View>
            </KeyboardAwareScrollView>
        );
    }

    _renderWide = () => {
        const {navigation} = this.props;
        return (
            <SafeAreaView
                style={[{height: AppG.window.height, flex: 1, flexDirection: 'column', backgroundColor: appColors.white}]}
                onLayout={(event) => {}}
            >
                <CustomHeaderComponentWide
                    navigation={navigation}
                    searchBar={true}
                />

                <View
                    style={{
                        // flex: 1,
                        flexDirection: 'row',
                        backgroundColor: appColors.backBaseColor,
                        alignSelf: 'center',
                    }}>
                    <ScrollView
                        horizontal  // 下階層のスクロールイベントを拾えるようにしている
                        style={{
                            width: AppG.window.width,
                            height: AppG.window.height - appS.header.webHeight,
                        }}
                    >
                        <View style={[{flex: 1, flexDirection: 'column', width: AppG.window.width}]}>
                            <KeyboardAwareScrollView
                                style={[{flex: 1, flexDirection: 'column'}]}
                            >
                                <View
                                    style={{
                                        flex: 1,
                                        alignSelf: 'center',
                                        width: appS.webAllContentsSize.width,
                                        backgroundColor: appColors.backBaseColor,
                                    }}
                                >
                                    {/*メニューカテゴリー検索の表示*/}
                                    {this._renderMenuCategorySearch()}
                                </View>
                            </KeyboardAwareScrollView>
                        </View>

                    </ScrollView>
                </View>
            </SafeAreaView>
        );
    }


    _renderSmall = () => {
        return (
            // appStyles.statusBarHiddenSafeAreaでステータスバー分上に上げる
            <SafeAreaView
                style={[{height: AppG.window.height, flex: 1, flexDirection: 'column', backgroundColor: appColors.white}]}
                onLayout={(event) => {}}
            >
                <View style={{zIndex: 99,}}>
                    <CustomHeaderComponentSmall
                        navigation={this.props.navigation}
                        leftButton={'back'}
                        rightButton={null}
                        title={this.state.headerTitle}
                        textColor={appColors.recipeHeader}
                        barColor={appColors.white}
                        borderBottomColor={appColors.recipeHeader}
                        statusBarType={"dark-content"}
                    />
                    {/*Border*/}
                    <View style={{flexDirection: "row"}}>
                        <View style={{flex: 1, height: 1, backgroundColor: appColors.spacerGray}}/>
                    </View>
                </View>

                <View style={[{flex: 1, flexDirection: 'column', backgroundColor: appColors.backBaseColor}]}>
                    <SpacerComponent height={appS.header.bottomMargin}/>
                    {/*<TopicTitleComponent title={'全カテゴリ一覧'} />*/}
                    {/*メニューカテゴリー検索の表示*/}
                    {this._renderMenuCategorySearch()}
                </View>
            </SafeAreaView>
        );
    }


    /** 描画 */
    render() {
        const {navigation} = this.props;
        if (!this.state.isLoaded) {
            return (
                <View style={{flex: 1}}>
                    <IndicatorComponent
                        failTime={60}
                        isLoading={true}
                        reloadFunc={() => {
                            this.componentWillUnmount();
                            this.componentDidMount();
                        }}/>
                </View>
            );
        }

        LinkUtil.addReturnFalseToATag();
        if (ScreenSizeUtil.isWebSize()) {
            // WEBサイズ
            return this._renderWide();
        } else {
            // SPサイズ
            return this._renderSmall();
        }
    }

}
