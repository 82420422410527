export class FooterDataEntityModel {
    /**
     * 0, 1 => 1 ならバッジ表示
     */
    footer_home_badge: string | undefined = undefined;
    /**
     * 0, 1 => 1 ならバッジ表示
     */
    footer_follow_badge: string | undefined = undefined;
    /**
     * 0, 1 => 1 ならバッジ表示
     */
    footer_cart_badge: string | undefined = undefined;
    /**
     * 0, 1 => 1 ならバッジ表示
     */
    footer_chat_badge: string | undefined = undefined;
    /**
     * 0, 1 => 1 ならバッジ表示
     */
    footer_history_badge: string | undefined = undefined;
}
