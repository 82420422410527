export class WhatIsMemberShipModalDataEntityModel {
    /**
     * 0, 1 => 1 なら会員訴求を表示しない
     */
    hide_flg_what_is_member_ship: string | undefined = undefined;
    /**
     * 最終表示時
     */
    last_show_date_what_is_member_ship: string | undefined = undefined;

}
