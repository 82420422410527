import React from 'react'
import {Dimensions, Image, Platform, SafeAreaView, Text, TextInput, TouchableOpacity, View} from 'react-native'
import {appColors, appFont, appS} from '../../resources/styles/style'
import {BaseScreen} from "./BaseScreen";
import LoginUtil from "../util/LoginUtil";
import {IndicatorComponent} from "../components/IndicatorComponent";
// @ts-ignore
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scrollview';
import LoginDataDao from "../data/dao/local/LoginDataDao";
import {LoginDataEntityModel} from "../data/entityModels/LoginDataEntityModel";
import {CustomHeaderComponentSmall} from "../components/small/CustomHeaderComponentSmall";
import UrlUtil from "../util/UrlUtil";
// @ts-ignore
import {log} from '../../../environment';
import ValidateUtil from "../util/ValidateUtil";
import {ValidationErrorMessageComponent} from "../components/ValidattionErrorMessageComponent";
import I18n from '../../resources/language/i18n';
import {
    Product,
    ProductApiFactory,
    ProductReviewApiFactory,
    ReviewAnswer,
    SampleProductReview,
    SampleProductReviewContentsInner,
    SampleProductReviewContentsInnerTypeEnum,
    SampleProductReviewContentsInnerValueInner
} from "../data/network/swagger-gen";
import {ProductReviewScreenModel} from "../data/models/screen/ProductReviewScreenModel";
import AppGlobalDataUtil from "../util/AppG";
import AppG from "../util/AppG";
import {AxiosResponse} from "axios";
import {CheckBox} from "react-native-elements";
import AlertUtil from "../util/AlertUtil";
import ExpoUtil from "../util/ExpoUtil";
import AnalyticsUtil, {AnalyticsEventName, DOpenEventName} from "../util/firebase/AnalyticsUtil";
import ScreenSizeUtil from "../util/ScreenSizeUtil";
import {CustomHeaderComponentWide} from "../components/wide/CustomHeaderComponentWide";
import {LoadingFullPageComponent} from "../components/LoadingFullPageComponent";
import {ImageModalComponent} from "../components/ImageModalComponent";
import {SpacerComponent} from "../components/SpacerComponent";
import DateUtil, {ViewDateFormatEnum} from "../util/DateUtil";
import LinkUtil from "../util/LinkUtil";

interface Props {
    navigation: any, // ナビゲーション用
}

interface State {
    resizeWindow: boolean,  // 値に意味はなし。windowサイズの拡大縮小をrenderに送るためのもの
    isLoaded: boolean,
    isShowSentModal: boolean,
    isSending: boolean,

    // コンテンツ
    product: Product | null,

    // input
    productId: number | null,
    reviewSetId: number | null,
    reviews: {
        id: number | undefined, // 質問ID
        title: string | undefined,
        type: 'text'| 'radio'| 'checkbox' | 'special' | undefined,
        check: {    // チェックボックスかラジオの時
            value: {
                index: number,  // ボタンの位置。一番左が 0
                paintIcon: boolean, // チェックとは別に、見た目が塗りつぶしになっているかどうか
                valueText: string | undefined,
                value: number | undefined,
                isChecked: boolean,
            }[],
        },
        freeWord: { // フリーワードの時
            text: string | undefined,
            errorText: string,
            isError: boolean,
        }
    }[],
    isSendError: boolean, // 送信に1買い以上失敗した
}

export class ProductReviewScreen extends BaseScreen<Props, State> {
    _resizeTimer = setInterval(() => {
        // window 拡大縮小を反映させる
        AppG.window = Dimensions.get('window');
        if (AppG.window.width != AppG.windowBefore.width || AppG.window.height != AppG.windowBefore.height) {
            this.setState({'resizeWindow': true});
            AppG.windowBefore = Dimensions.get('window');
        }
        LoginUtil.setRefreshedScreenFlg(false);
    }, 100);

    _loginData = new LoginDataEntityModel();

    _flameSize = appS.flame.size;    // 左右の margin
    _marginSize = appS.margin.size;

    // このScreenへ渡すパラム
    _param: ProductReviewScreenModel = new ProductReviewScreenModel();

    _browserBackListener = (e: any) => {

    };

    _date: string | undefined = ''; // 依頼日

    _scrollView: any = null;

    constructor(props: any) {
        super(props);
        this.state = {
            resizeWindow: true,  // 値に意味はなし。windowサイズの拡大縮小をrenderに送るためのもの
            isLoaded: false,
            isShowSentModal: false,
            // isShowSentModal: true,  // テスト用
            isSending: false,
            product: null,
            productId: null,
            reviewSetId: null,
            reviews: [],
            isSendError: false,
        };
    }

    /**
     * 画面開いたときのアナリティクス送信
     */
    _sendPvAnalytics = () => {
        AnalyticsUtil.sendAnalytics(
            DOpenEventName.openPage,
            AnalyticsEventName.openPage,  // アナリティクスのイベント名
            'ProductReviewScreen',
            null,
            null,
            null,
            null,
        );
    }

    componentDidMount() {
        if (Platform.OS == 'web') {
            window.addEventListener('popstate', this._browserBackListener);
        }

        // URL Scheme で外部リンクから起動したときの処理
        // LoginUtil.setDefaultListener(this.props.navigation);

        LoginUtil.interruptOpenScreen(this.props.navigation,
            () => {
                LoginDataDao.get().then(loginData => {
                    // Web 戻るでも呼ばれる
                    if (Platform.OS == 'web') {
                        // Analytics
                        this._sendPvAnalytics();
                    }

                    // URL Scheme で外部リンクから起動したときの処理と、画面遷移でこの画面を開いたときの処理
                    LoginUtil.setDefaultListener(
                        this.props.navigation,
                        () => {
                            // Webはここにこない
                            if (Platform.OS != 'web') {
                                // Analytics
                                this._sendPvAnalytics();
                            }

                            ExpoUtil.doReloadAppTab(() => {

                            });
                        });
                    if (loginData != null) {
                        this._loginData = loginData;
                        this._loadMasters().then(() => {});
                        this._loadContents().then(() => {
                            this.setState({isLoaded: true});
                        });
                    }
                });
            });
    }

    componentWillUnmount () {
        clearTimeout(this._resizeTimer);
        if (Platform.OS === 'web') {
            window.removeEventListener('popstate', this._browserBackListener);
        }
        LoginUtil.removeDefaultListener(this.props.navigation);
    };

    componentDidUpdate () {
        // レンダー完了時に呼ばれる
    }

    /**
     * パラムの取得
     * @private
     */
    _getParams = () => {
        let productReviewScreenModel: ProductReviewScreenModel = UrlUtil.getParamModel(new ProductReviewScreenModel(), this.props.navigation);
        return productReviewScreenModel;
    };

    /**
     * マスターのロード
     * @private
     */
    _loadMasters = async () => {
    };

    /**
     * コンテンツのロード
     * @private
     */
    _loadContents = async () => {
        let _param = this._getParams();

        if (_param == null || _param.productId == null || _param.reviewSetId == null) {
            return;
        }

        // 商品
        const getProduct = ProductApiFactory(AppGlobalDataUtil.getConfiguration(), AppGlobalDataUtil.getBasePath())
            .getProduct(
                _param.productId!,
                0,
                4,
                undefined
            ).then((productRes: AxiosResponse<Product>) => {
                if (productRes != null && productRes.data != null) {
                    this.setState({product: productRes.data})
                }
            });

        // 質問取得
        const getReviews = ProductReviewApiFactory(AppGlobalDataUtil.getConfiguration(), AppGlobalDataUtil.getBasePath())
            .getSampleProductReview(parseInt(_param.productId), parseInt(_param.reviewSetId)).then((reviewsRes: AxiosResponse<SampleProductReview>) => {
                if (reviewsRes != null && reviewsRes.data != null) {

                    if (reviewsRes.data.reviewed == 1) {
                        AlertUtil.ok('レビュー済み', '既に回答頂いております。アプリを再起動してもレビュー依頼一覧から消えない場合はメーカーにお問い合わせください。', () => {ExpoUtil.goBack(this.props.navigation)});
                        return;
                    }

                    this._date = reviewsRes.data.date;

                    this.setState({productId: reviewsRes.data.productId!});
                    this.setState({reviewSetId: reviewsRes.data.reviewSetId!});

                    let _spReview : {
                            id: number | undefined, // 質問ID
                            title: string | undefined,
                            type: 'text'| 'radio'| 'checkbox' | 'special' | undefined,
                            check: {    // チェックボックスかラジオの時
                                value: {
                                    index: number,  // ボタンの位置。一番左が 0
                                    paintIcon: boolean, // チェックとは別に、見た目が塗りつぶしになっているかどうか
                                    valueText: string | undefined,
                                    value: number | undefined,
                                    isChecked: boolean,
                                }[],
                            },
                            freeWord: { // フリーワードの時
                                text: string | undefined,
                                errorText: string,
                                isError: boolean,
                            }
                        } | null = null
                    reviewsRes.data.contents.forEach((sampleProductReviewContents: SampleProductReviewContentsInner) => {
                        let cvs: {
                            index: number,  // ボタンの位置。一番左が 0
                            paintIcon: boolean, // チェックとは別に、見た目が塗りつぶしになっているかどうか
                            valueText: string | undefined,
                            value: number | undefined,
                            isChecked: boolean,
                        }[] = [];
                        let _sp: boolean = false;
                        sampleProductReviewContents.value?.forEach((sampleProductReviewValue: SampleProductReviewContentsInnerValueInner, index) => {
                            if (sampleProductReviewContents.type == 'special') {
                                _sp = true;
                            }
                            cvs.push({
                                index: index,  // ボタンの位置。一番左が 0
                                paintIcon: false, // チェックとは別に、見た目が塗りつぶしになっているかどうか
                                valueText: sampleProductReviewValue.valueText,
                                value: sampleProductReviewValue.value,
                                isChecked: (sampleProductReviewContents.type == 'special' && index == 1),    // 必須項目のすべて可の場合チェック
                            });
                        });

                        let _review = {
                            id: sampleProductReviewContents.id, // 質問ID
                            title: sampleProductReviewContents.text,
                            type: sampleProductReviewContents.type,
                            check: {    // チェックボックスかラジオの時
                                value: cvs,
                            },
                            freeWord: { // フリーワードの時
                                text: '',
                                errorText: '',
                                isError: false,
                            }
                        }

                        if (_sp) {
                            _spReview = _review;
                        } else {
                            this.state.reviews.push(_review);
                        }
                    });

                    if (_spReview != null) {
                        this.state.reviews.push(_spReview);
                    }

                    this.setState({reviews: this.state.reviews});
                }
            });

        // 実行
        await Promise.all([getProduct, getReviews]);
    };

    /**
     * レビューの送信
     */
    _sendAnswer = () => {
        let _answer: ReviewAnswer = {};
        _answer.productId = this.state.productId!;
        _answer.reviewSetId = this.state.reviewSetId!;
        _answer.answers = [];

        this.state.reviews.forEach((review) => {
            let _type: any = SampleProductReviewContentsInnerTypeEnum.Radio;
            if (review.type == "text") {
                _type = SampleProductReviewContentsInnerTypeEnum.Text;
            }

            let _checkboxValue = 0;
            review.check.value.forEach((v) => {
                if (v.isChecked) {
                    _checkboxValue = v.value!;
                }
            });

            _answer.answers!.push({
                id: review.id,
                type: _type,
                checkboxValue: _checkboxValue,
                text: review.freeWord.text,
            })
        });

        const _json = JSON.stringify(_answer);
        // console.log(_json)
        // return;
        this.setState({isSending: true});

        if (this._scrollView != null) {
            this._scrollView.scrollTo({x: 0, y: 0, animated: false});
        }

        // 送信
        ProductReviewApiFactory(AppGlobalDataUtil.getConfiguration(), AppGlobalDataUtil.getBasePath())
            .answerSampleProductReview(_json)
            .then(() => {
                this.setState({isSending: false});
                this.setState({isShowSentModal: true});
            })
            .catch(() => {
                AlertUtil.alert(`${I18n.t('errorNetworkTitle')}`, `${I18n.t('errorNetwork')}`);
                this.setState({isSending: false});
            });
    };

    /**
     * エラーなら true
     */
    _isError = () => {
        let _error: boolean = false;

        this.state.reviews.forEach((review) => {
            if (review.type === 'text') {
                review.freeWord.isError = false;

                // フリーワードは必須回答
                if (ValidateUtil.isEmptyExact(review.freeWord.text)) {
                    _error = true;
                    review.freeWord.isError = true;
                    review.freeWord.errorText = `${I18n.t('pleasEnterAnItem', {item: `回答`})}`;
                }
            } else {
                let _selected = false;
                review.check.value.forEach((v) => {
                    if (v.isChecked) {
                        _selected = true;
                    }
                });
                if (!_selected) {
                    _error = true;
                }
            }

        });

        return _error;
    };

    /**
     * 質問の描画
     * @param review
     */
    _renderReview = (
        review: {
            id: number | undefined, // 質問ID
            title: string | undefined,
            type: 'text'| 'radio'| 'checkbox' | 'special' | undefined,
            check: {    // チェックボックスかラジオの時
                value: {
                    index: number,  // ボタンの位置。一番左が 0
                    paintIcon: boolean, // チェックとは別に、見た目が塗りつぶしになっているかどうか
                    valueText: string | undefined,
                    value: number | undefined,
                    isChecked: boolean,
                }[],
            },
            freeWord: { // フリーワードの時
                text: string | undefined,
                errorText: string,
                isError: boolean,
            }
        },
        index: number) =>
    {

        let _width = AppG.window.width;
        if (ScreenSizeUtil.isWebSize()) {
            _width = appS.webMainContentsSize.width;
        }
        _width = _width - appS.margins.side * 2;

        const _isErrorCheckBox = (review: {
            id: number | undefined, // 質問ID
            title: string | undefined,
            type: 'text'| 'radio'| 'checkbox' | 'special' | undefined,
            check: {    // チェックボックスかラジオの時
                value: {
                    valueText: string | undefined,
                    value: number | undefined,
                    isChecked: boolean,
                }[],
            },
            freeWord: { // フリーワードの時
                text: string | undefined,
                errorText: string,
                isError: boolean,
            }
        }): boolean => {
            let _selected = false;
            review.check.value.forEach((v) => {
                if (v.isChecked) {
                    _selected = true;
                }
            });

            if (this.state.isSendError && !_selected) {
                return true;
            }

            return false;
        };

        const _getTitle = (title: string) => {
            return ExpoUtil._wrap(
                <View>
                    <Text style={{
                        fontFamily: appFont.family.bold,
                        marginBottom: appS.margins.side,
                        fontSize: appFont.size.subTopic,
                        color: appColors.fontBlack,
                        fontWeight: "bold"
                    }}>{title}</Text>
                </View>
            )
        }

        const _touchAreaSize = 60;
        const _iconSize = 30;
        const _padding = 20;

        if (review.type == 'special') {
            // 必須の質問
            return (
                <View style={{marginTop: appS.margins.betweenTopic}}>
                    <View style={{marginLeft: appS.margins.side}}>
                        {_getTitle(`${index+1}.${review.title!}`)}
                    </View>
                    <View style={{flex: 1, flexDirection: 'row', width: _width, backgroundColor: appColors.white, padding: _padding}}>
                        <SpacerComponent height={appS.margins.betweenContentSmall} />
                        <View>
                            {!ValidateUtil.isEmptyArray(review.check.value) && review.check.value.map((value, index) => {
                                if (index == 0) {
                                    return null;
                                }

                                return (
                                    <View key={`checkbox_view_${index}`} style={{flexDirection: "row"}}>
                                        <CheckBox
                                            key={`checkbox_${index}`}
                                            center
                                            containerStyle={{height: 30}}
                                            checkedIcon={(
                                                <Image source={require('../../resources/images/radio_icon_checked.png')}
                                                       style={[{
                                                           marginTop: -10,
                                                           width: _iconSize,
                                                           height: _iconSize,
                                                       }]}
                                                       resizeMode={'cover'}
                                                />
                                            )}
                                            uncheckedIcon={(
                                                <Image source={require('../../resources/images/radio_icon_unchecked.png')}
                                                       style={[{
                                                           marginTop: -10,
                                                           width: _iconSize,
                                                           height: _iconSize,
                                                       }]}
                                                       resizeMode={'cover'}
                                                />
                                            )}
                                            checked={value.isChecked}
                                            onPress={() => {
                                                review.check.value.forEach((v) => {
                                                    v.isChecked = false;
                                                });
                                                value.isChecked = true;
                                                this.setState({reviews: this.state.reviews});
                                            }}
                                        />
                                        <Text style={{fontFamily: appFont.family.bold, marginLeft: -10, marginTop: 10, fontSize: 14, color: appColors.fontBlack, fontWeight: "bold"}}>{value.valueText}</Text>
                                    </View>
                                )})}
                        </View>
                    </View>
                    <ValidationErrorMessageComponent
                        text={'選択してください'}
                        isError={_isErrorCheckBox(review)}
                    />

                </View>
            );
        }

        if (review.type == "text") {
            return (
                <View style={{marginTop: appS.margins.betweenTopic, margin: appS.margins.side}}>
                    {_getTitle(`${index+1}.${review.title!}`)}
                    <View style={[]}>
                        <TextInput
                            style={[appS.inputText, {borderWidth: 1, borderColor: appColors.black, borderRadius: 2}]}
                            autoCapitalize={"none"}
                            placeholder={''}
                            returnKeyType={'done'}
                            placeholderTextColor={appColors.fontGray}
                            value={review.freeWord.text}
                            onBlur={() => {this.setState({reviews: this.state.reviews});}}
                            onChangeText={text => {
                                if (ValidateUtil.isEmptyExact(review.freeWord.text)) {
                                    review.freeWord.isError = true;
                                } else {
                                    review.freeWord.isError = false;
                                }
                                review.freeWord.text = text;
                                this.setState({reviews: this.state.reviews});   // 描画更新
                            }}
                        />
                        <ValidationErrorMessageComponent
                            text={review.freeWord.errorText}
                            isError={this.state.isSendError && review.freeWord.isError}
                        />
                    </View>
                </View>
            );
        }



        return (
            <View style={{marginTop: appS.margins.betweenTopic, padding: appS.margins.side}}>
                {_getTitle(`${index+1}.${review.title!}`)}
                <View style={{flex: 1, flexDirection: 'row', width: _width, alignSelf: 'center', backgroundColor: appColors.white, padding: _padding}} >
                    {!ValidateUtil.isEmptyArray(review.check.value) && review.check.value.map((value, index) => {
                        return (
                            <View key={`checkbox_view_${index}`} style={{flex:1, alignSelf: 'center', alignItems: 'center', alignContent: 'center'}} >
                                <TouchableOpacity
                                    style={{
                                        width: _touchAreaSize,
                                        alignSelf: 'center',
                                        alignItems: 'center',
                                        alignContent: 'center',
                                    }}
                                    onPress={() => {
                                        review.check.value.forEach((v) => {
                                            v.isChecked = false;
                                            if (v.index <= value.index) {
                                                v.paintIcon = true;
                                            } else {
                                                v.paintIcon = false;
                                            }
                                        });
                                        value.isChecked = true;
                                        value.paintIcon = true;
                                        this.setState({reviews: this.state.reviews});
                                    }}
                                >
                                    <View>
                                        {value.paintIcon && (
                                            <Image source={require('../../resources/images/08.review/i_review_star_on.png')}
                                                   style={[{
                                                       width: _iconSize,
                                                       height: _iconSize,
                                                       alignSelf: 'center',
                                                       alignItems: 'center',
                                                       alignContent: 'center',
                                                   }]}
                                                   resizeMode={'cover'}
                                            />
                                        )}
                                        {!value.paintIcon && (
                                            <Image source={require('../../resources/images/08.review/i_review_star_off.png')}
                                                   style={[{
                                                       width: _iconSize,
                                                       height: _iconSize,
                                                       alignSelf: 'center',
                                                       alignItems: 'center',
                                                       alignContent: 'center',
                                                   }]}
                                                   resizeMode={'cover'}
                                            />
                                        )}
                                        <Text style={{
                                            textAlign: 'center',
                                            fontFamily: appFont.family.bold,
                                            marginTop: 4,
                                            fontSize: 10,
                                            color: appColors.actionColor,
                                            fontWeight: "bold"
                                        }}>{value.valueText}</Text>
                                    </View>
                                </TouchableOpacity>
                            </View>
                        )})}
                </View>
                <ValidationErrorMessageComponent
                    text={'選択してください'}
                    isError={_isErrorCheckBox(review)}
                />
            </View>
        );
    }

    _renderMain = () => {

        let _productWidth = AppG.window.width;
        if (ScreenSizeUtil.isWebSize()) {
            _productWidth = appS.webMainContentsSize.width;
        }
        _productWidth = _productWidth - appS.margins.side * 8;

        const _productImageSize = 60;

        return (
            <View style={{flex: 1, margin: appS.margins.side, backgroundColor: appColors.reviewBackground}}>
                <KeyboardAwareScrollView
                    ref={(scrollView: any) => { this._scrollView = scrollView; }}
                    style={[{flex: 1, flexDirection: 'column'}]}
                >
                    <SpacerComponent height={appS.header.bottomMargin} />
                    <Text style={{
                        fontFamily: appFont.family.default,
                        color: appColors.fontBlack,
                        fontSize: appFont.size.default,
                        alignSelf: 'center',
                    }}>{`下記質問にお答えください。（全${this.state.reviews.length}問）`}</Text>

                    {this.state.product != null && (
                        <View style={{margin: appS.margins.side * 2, padding: appS.margins.side, backgroundColor: appColors.white, width: _productWidth, alignSelf: 'center'}}>
                            <View style={{flexDirection: "row", flex: 1}}>
                                <Image source={{uri: this.state.product.images[0].url}}
                                       style={[{
                                           backgroundColor: 'white',
                                           borderRadius: 5,
                                           borderColor: appColors.transparent,
                                           width: _productImageSize,
                                           height: _productImageSize,
                                       }]}
                                       resizeMode={'cover'}
                                />
                                <View style={{flexDirection: "column", margin: appS.margins.side, width: _productWidth - _productImageSize - appS.margins.side * 4}}>
                                    <Text
                                        style={{
                                            marginTop: Platform.OS == 'android'? -15 : 0,
                                            fontFamily: appFont.family.default,
                                            fontSize: appFont.size.default,
                                            color: appColors.black,
                                        }}
                                    >
                                        {this.state.product.name}</Text>
                                    <View style={{flexDirection: 'row-reverse'}}>
                                        <Text
                                            style={{
                                                fontFamily: appFont.family.default,
                                                fontSize: appFont.size.small,
                                                color: appColors.fontGray,
                                                marginTop: Platform.OS == 'android'? -15 : 2,
                                            }}
                                            numberOfLines={1}
                                            ellipsizeMode='tail'
                                        >
                                            {`依頼日：${DateUtil.getViewDateFromApiDateStr(this._date!, ViewDateFormatEnum.YYYY_MM_DD)}`}</Text>
                                    </View>
                                    <View style={{flexDirection: 'column'}}>
                                        <Text
                                            style={{
                                                fontFamily: appFont.family.default,
                                                fontSize: appFont.size.small,
                                                color: appColors.fontGray,
                                                marginTop: Platform.OS == 'android'? -15 : 2,
                                            }}
                                            numberOfLines={1}
                                            ellipsizeMode='tail'
                                        >
                                            {`内容量：${this.state.product.capacity}`}</Text>
                                    </View>
                                    <View style={{flexDirection: 'column'}}>
                                        <Text
                                            style={{
                                                fontFamily: appFont.family.default,
                                                fontSize: appFont.size.small,
                                                color: appColors.fontGray,
                                                marginTop: Platform.OS == 'android'? -15 : 2,
                                            }}
                                            numberOfLines={1}
                                            ellipsizeMode='tail'
                                        >
                                            {`容器（包装形態）：${this.state.product.container}`}</Text>
                                    </View>
                                    <View style={{flexDirection: 'column'}}>
                                        <Text
                                            style={{
                                                fontFamily: appFont.family.default,
                                                fontSize: appFont.size.small,
                                                color: appColors.fontGray,
                                                marginTop: Platform.OS == 'android'? -15 : 2,
                                            }}
                                            numberOfLines={1}
                                            ellipsizeMode='tail'
                                        >
                                            {`温度帯：${this.state.product.temperature}`}</Text>
                                    </View>
                                    <View style={{flexDirection: 'column'}}>
                                        <Text
                                            style={{
                                                fontFamily: appFont.family.default,
                                                fontSize: appFont.size.small,
                                                color: appColors.fontGray,
                                                marginTop: Platform.OS == 'android'? -15 : 2,
                                            }}
                                            numberOfLines={1}
                                            ellipsizeMode='tail'
                                        >
                                            {`規格一覧：${this.state.product.standards}`}</Text>
                                    </View>
                                    <View style={{flexDirection: 'column'}}>
                                        <Text
                                            style={{
                                                fontFamily: appFont.family.default,
                                                fontSize: appFont.size.small,
                                                color: appColors.fontGray,
                                                marginTop: Platform.OS == 'android'? -15 : 2,
                                            }}
                                            numberOfLines={1}
                                            ellipsizeMode='tail'
                                        >
                                            {`その他情報：${this.state.product.otherInformation}`}</Text>
                                    </View>
                                </View>
                            </View>
                        </View>
                    )}

                    {!ValidateUtil.isEmptyArray(this.state.reviews) && this.state.reviews.map((review, index) => {
                        return (
                            <View key={`review_view_${index}`}>
                                {this._renderReview(review, index)}
                                <SpacerComponent height={appS.margins.side} />
                            </View>
                        );
                    })}

                    {/*送信する*/}
                    <TouchableOpacity
                        style={[
                            appS.marginCenter,
                            {
                                marginTop: 32,
                                marginBottom: 40,
                                height: appS.buttonSize.height,
                                width: appS.buttonSize.width,
                                borderWidth: 1,
                                borderColor: appColors.transparent,
                                backgroundColor:  this._isError() ? appColors.gray : appColors.buttonOrange,
                                alignItems: 'center',
                                justifyContent: 'center',
                                alignSelf: 'center',
                                borderRadius: appS.buttonSize.radius
                            }
                        ]}
                        disabled={this._isError() || this.state.isSending || this.state.isShowSentModal}
                        onPress={() => {
                            // レビュー送信
                            if (this._isError()) {
                                this.setState({isSendError: true});
                                this.setState({reviews: this.state.reviews});
                            } else {
                                this._sendAnswer();
                            }
                        }}
                    >
                        <Text style={{
                            fontFamily: appFont.family.bold,
                            color: appColors.white,
                            fontSize: appFont.size.buttonText,
                            fontWeight: "bold"
                        }}>送信する</Text>
                    </TouchableOpacity>
                </KeyboardAwareScrollView>
            </View>
        );

    };

    _renderWide = () => {
        return (
            <SafeAreaView
                style={[{height: AppG.window.height, flex: 1, flexDirection: 'column', backgroundColor: appColors.white}]}
                onLayout={(event) => {}}
            >
                <CustomHeaderComponentWide logoOnly={true} navigation={this.props.navigation}/>

                <View style={{flex: 1, flexDirection: 'row'}}>
                    <KeyboardAwareScrollView style={[{flex: 1, flexDirection: 'column', backgroundColor: appColors.reviewBackground}]}>
                        <View style={[{flex: 1, width: 700, alignSelf: 'center'}]}>
                            {/*メイン*/}
                            {this._renderMain()}
                        </View>
                    </KeyboardAwareScrollView>

                    {/*Modal*/}
                    <ImageModalComponent
                        imageSource={require('../../resources/images/thx_review.png')}
                        marginTop={(AppG.window.height - ((AppG.window.height / 2) * 1.3)) / 2}
                        width={(AppG.window.height / 2) * 1.3}
                        height={(AppG.window.height / 2)}
                        isShowModal={this.state.isShowSentModal}
                        onClosePress={() => {
                            // 閉じて戻る
                            this.setState({'isShowSentModal': false});
                            ExpoUtil.goBack(this.props.navigation);
                        }}/>
                </View>

                {this.state.isSending && (
                    <LoadingFullPageComponent/>
                )}
            </SafeAreaView>
        );
    }

    _renderSmall = () => {
        return (
            <SafeAreaView
                style={[{flex: 1, backgroundColor: appColors.white}]}
                onLayout={(event) => {}}
            >
                <CustomHeaderComponentSmall
                    navigation={this.props.navigation}
                    title={'レビュー'}
                    textColor={appColors.black}
                    barColor={appColors.white}
                    leftButton={'back'}
                    rightButton={'none'}
                    shadow={true}
                />
                <View style={{flex: 1, backgroundColor: appColors.reviewBackground}}>
                    {/*メイン*/}
                    {this._renderMain()}
                </View>
                {/*Modal*/}
                <ImageModalComponent
                    imageSource={require('../../resources/images/thx_review.png')}
                    isShowModal={this.state.isShowSentModal}
                    marginTop={(AppG.window.height - ((AppG.window.width - 40) * 0.75)) / 2}
                    width={AppG.window.width - 40}
                    height={(AppG.window.width - 40) * 0.75}
                    onClosePress={() => {
                        // 閉じて戻る
                        this.setState({'isShowSentModal': false});
                        ExpoUtil.goBack(this.props.navigation);
                    }}/>

                {this.state.isSending && (
                    <LoadingFullPageComponent/>
                )}
            </SafeAreaView>
        );
    }


    /** 描画 */
    render() {
        // const {navigation} = this.props;
        if (!this.state.isLoaded) {
            return (
                <View style={{flex: 1}}>
                    <IndicatorComponent
                        failTime={60}
                        isLoading={true}
                        reloadFunc={() => {
                            this.componentWillUnmount();
                            this.componentDidMount();
                        }}/>
                </View>
            );
        }

        LinkUtil.addReturnFalseToATag();
        if (ScreenSizeUtil.isWebSize()) {
            // WEBサイズ
            return this._renderWide();
        } else {
            // SPサイズ
            return this._renderSmall();
        }



    };


}
