export class ChatScreenModel {


    /** チャットのハッシュ */
    chatId: string | null = null;
    /** 相手メーカーID */
    makerId: string | null = null;

    /** お問い合わせの種類 */
    inquiryStatus: InquiryStatusEnum | null = null;
    /** お問い合わせ対象のID */
    inquiryRecipeId: string | null = null;
    /** お問い合わせ対象のID */
    inquiryProductId: string | null = null;
    /** お問い合わせ対象のID */
    inquiryMakerId: string | null = null;
}

export enum InquiryStatusEnum {
    RECIPE = "recipe",
    PRODUCT = "product",
    MAKER = "maker"
}

export enum InquiryWordsEnum {
    RECIPE = "こちらのレシピについて質問です。\n",
    PRODUCT = "こちらの商品について質問です。\n",
    MAKER = "",
}
