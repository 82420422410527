import React from 'react'
import {Dimensions, Image, Platform, SafeAreaView, ScrollView, Text, TouchableOpacity, View} from 'react-native'
import {appColors, appFont, appS} from '../../resources/styles/style'
import {BaseScreen} from "./BaseScreen";
import LoginUtil from "../util/LoginUtil";
import {IndicatorComponent} from "../components/IndicatorComponent";
// @ts-ignore
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scrollview';
import LoginDataDao from "../data/dao/local/LoginDataDao";
import {LoginDataEntityModel} from "../data/entityModels/LoginDataEntityModel";
import AppG from "../util/AppG";
import AppGlobalDataUtil from "../util/AppG";
import {CustomHeaderComponentSmall} from "../components/small/CustomHeaderComponentSmall";
import UrlUtil from "../util/UrlUtil";
import {SpacerComponent} from "../components/SpacerComponent";
// @ts-ignore
import isURL from 'validator/lib/isURL';
import ValidateUtil from "../util/ValidateUtil";
// @ts-ignore
import {log} from '../../../environment';
import {SearchScreenModel} from "../data/models/screen/SearchScreenModel";
import {ListCellComponent} from "../components/ListCellComponent";
import ScreenSizeUtil from "../util/ScreenSizeUtil";
import {CustomHeaderComponentWide} from "../components/wide/CustomHeaderComponentWide";
import AnalyticsUtil, {AnalyticsEventName, DOpenEventName} from "../util/firebase/AnalyticsUtil";
import {TopicTitleComponent} from "../components/TopicTitleComponent";
import * as Notifications from "expo-notifications";
import {SearchBar} from "react-native-elements";
import CustomHeaderFunc from "../components/func/CustomHeaderFunc";
import {
    Keyword,
    Maker,
    MakerApiFactory,
    MasterApiFactory,
    ProductCategory,
    RecipeCategory,
    RestaurantFormat
} from "../data/network/swagger-gen";

import {AxiosResponse} from "axios";
import {CheckBoxComponent} from "../components/CheckBoxComponent";
import {CostComponent} from "../components/CostComponent";
import StrUtil from "../util/StrUtil";
import {TabBorderComponent} from "../components/TabBorderComponent";
import SearchDataDao from "../data/dao/local/SearchDataDao";
import {WideScreenAdComponent} from "../components/wide/WideScreenAdComponent";
import {WideSubTitleComponent} from "../components/wide/WideSubTitleComponent";
import {NoUserImageComponent} from "../components/NoUserImageComponent";
import {WideScreenSNSComponent} from "../components/wide/WideScreenSNSComponent";
import MyAppUtil from "../util/MyAppUtil";
import LinkUtil from "../util/LinkUtil";

interface Props {
    navigation: any, // ナビゲーション用
}

interface State {
    resizeWindow: boolean,  // 値に意味はなし。windowサイズの拡大縮小をrenderに送るためのもの
    isLoaded: boolean,

    // コンテンツ
    headerTitle: string,
    searchValue: string,
    keywords: Keyword[],
    onSearchSubmit: (text: string) => void,
    // isLoadRankingRecipes: boolean,  // ランキングレシピ（ランキングタブ）
    // rankingRecipes: Recipe[],   // レシピランキング

    // マスター
    recipeCategories: RecipeCategory[], // レシピカテゴリのマスター
    restaurantFormatMasters: RestaurantFormat[],    // 業態
    productCategories: ProductCategory[], // 商品カテゴリのマスター
    makers: Maker[][],    // メーカータブのメーカー一覧
}

export class SearchScreen extends BaseScreen<Props, State> {
    _resizeTimer = setInterval(() => {
        // window 拡大縮小を反映させる
        AppG.window = Dimensions.get('window');
        if (AppG.window.width != AppG.windowBefore.width || AppG.window.height != AppG.windowBefore.height) {
            this.setState({'resizeWindow': true});
            AppG.windowBefore = Dimensions.get('window');
        }
        LoginUtil.setRefreshedScreenFlg(false);
    }, 100);

    _loginData = new LoginDataEntityModel();

    _flameSize = ScreenSizeUtil.isWebSize()? AppG.window.width / 10 : 16;    // 左右の margin
    _marginSize = ScreenSizeUtil.isWebSize()? 0 : appS.margin.size;

    // このScreenへ渡すパラム
    _param: SearchScreenModel = new SearchScreenModel();
    _contentsLoadCount = 20; // コンテンツの一度の読み込み数
    _searchText = ''; // 検索文字

    /**
     * どっちの検索か
     */
    _searchType: 'recipe' | 'product' | 'all' | null = 'recipe';

    // Maker タブの区分け
    _titlesOfMaker = ['あ', 'か', 'さ', 'た', 'な', 'は', 'ま', 'や', 'ら', 'わ', 'A-Z'];
    _regRexOfMaker = [
        'あアいイうウえエおオ',
        'かカがガきキぎギくクぐグけケげゲこコごゴ',
        'さサざザしシじジすスずズせセぜゼそソぞゾ',
        'たタだダちチぢヂつツづヅてテでデとトどド',
        'なナにニぬヌねネのノ',
        'はハばバぱパひヒびビぴピふフぶブぷプへヘべベぺペほホぼボぽポ',
        'まマみミむムめメもモ',
        'やヤゆユよヨ',
        'らラりリるルれレろロ',
        'わワをヲ',
        '\[a-zA-Z\]'];

    // input
    _filterRecipeCategory: number | undefined = undefined;   // 絞り込みレシピカテゴリ（ランキングタブ）
    _filterCostFrom: number | undefined = undefined; // 絞り込み原価（ランキングタブ）
    _filterCostTo: number | undefined = undefined; // 絞り込み原価（ランキングタブ）

    // picker
    _costPickerRef: any = null;

    // タブ
    _tabPage: any = null;
    _tabClicked: boolean = false;
    _tabIndex: number = 0;
    /** tab 本体 */
    _tabRef: any = null;
    _tabPress = (tabNumber: number) => {
        if (this._tabIndex == tabNumber) {
            return;
        }
        this._tabClicked = true;
        if (tabNumber == 0) {
            this._searchType = 'recipe';
            this._tabIndex = 0;
            AppG.screenHistory.unshift(`【詳細検索画面】レシピタブ`);
        } else {
            this._searchType = 'product';
            this._tabIndex = 1;
            AppG.screenHistory.unshift(`【詳細検索画面】商品タブ`);
        }

        if (this._tabRef != null) {
            this._tabRef.redrawView(tabNumber);
        }

        this.setState({'resizeWindow': true});
        // this.setState({tabIndex: tabNumber});
    };
    _tabs: Tab[] = [
        {
            index: 0,    // 0 〜
            title: 'レシピから探す',
            onPress: () => {this._tabPress(0)},
            color: appColors.recipeHeader,
            unselectColor: appColors.tabGray,
            backgroundColor: appColors.white,
            unselectBackgroundColor: appColors.white,
            badge: 0,
        },
        {
            index: 1,    // 0 〜
            title: '商品から探す',
            onPress: () => {this._tabPress(1)},
            color: appColors.productHeader,
            unselectColor: appColors.tabGray,
            backgroundColor: appColors.white,
            unselectBackgroundColor: appColors.white,
            badge: 0,
        },
    ];

    _checkAreaMargin = 15;

    // 季節マスタ
    _seasonMaster = MyAppUtil.getSeason();

    // セレクトボックスの選択
    _selectedRecipeCategoryIds: number[] = []; // レシピカテゴリのマスター
    _selectedRestaurantFormatIds: number[] = [];    // 業態
    _selectedSeasonIds: number[] = [];    // 季節
    _selectedProductCategoryIds: number[] = []; // 商品カテゴリのマスター
    _selectedMakerIds: number[] = [];   // メーカーマスター

    constructor(props: any) {
        super(props);
        this.state = {
            resizeWindow: true,  // 値に意味はなし。windowサイズの拡大縮小をrenderに送るためのもの
            isLoaded: true,
            // tabIndex: 0,
            headerTitle: '',
            searchValue: '',
            keywords: [],
            onSearchSubmit: () => {},
            recipeCategories: [],
            productCategories: [],
            restaurantFormatMasters: [],
            // isLoadRankingRecipes: false,
            // rankingRecipes: [],
            makers: [],    // メーカータブのメーカー一覧
        };
    }

    /**
     * 画面開いたときのアナリティクス送信
     */
    _sendPvAnalytics = () => {
        AnalyticsUtil.sendAnalytics(
            DOpenEventName.openPage,
            AnalyticsEventName.openPage,  // アナリティクスのイベント名
            'SearchScreen',
            null,
            null,
            null,
            null,
        );
    }

    componentDidMount() {
        this._param = this._getParams();
        this._searchType = this._param.searchType;

        setTimeout(() => {
            if (this._searchType == 'product') {
                this._tabPress(1);
            } else {
                this._tabPress(0);
            }
        }, 100);

        LoginUtil.interruptOpenScreen(this.props.navigation,
            () => {
                LoginDataDao.get().then(loginData => {
                    // Web 戻るでも呼ばれる (Footerにあるやつはappでも実行するようにする)
                    // Analytics
                    this._sendPvAnalytics();

                    if (Platform.OS != 'web') {
                        // アイコンバッヂの更新
                        Notifications.setBadgeCountAsync((AppG.badgeChat == null? 0 : AppG.badgeChat) + (AppG.badgeHistory == null? 0 : AppG.badgeHistory));
                    }
                    AppG.getFooterBadge();

                    // URL Scheme で外部リンクから起動したときの処理と、画面遷移でこの画面を開いたときの処理
                    LoginUtil.setDefaultListener(this.props.navigation, () => {
                        // Webはここにこない
                        if (Platform.OS != 'web') {
                            // Analytics
                            this._sendPvAnalytics();
                        }
                    });
                    if (loginData != null) {
                        this._loginData = loginData;
                    }
                    this._loadMasters().then(() => {
                        this._setConditionByParams().then(() => {
                            this._loadContents().then(
                                () => this.setState({'isLoaded': true})
                            );
                        });
                    });
                    this.setState({headerTitle: !ValidateUtil.isEmptyExact(this._param.headerTitle) ? this._param.headerTitle! : ''});
                });
            });
    }

    componentWillUnmount () {
        clearTimeout(this._resizeTimer);
        LoginUtil.removeDefaultListener(this.props.navigation);
    };

    componentDidUpdate () {
        // レンダー完了時に呼ばれる
    }

    /**
     * パラムの取得
     * @private
     */
    _getParams = () => {
        let searchScreenModel: SearchScreenModel = UrlUtil.getParamModel(new SearchScreenModel(), this.props.navigation);
        return searchScreenModel;
    };

    /**
     * 検索条件をセット
     */
    _setConditionByParams = async () => {
        if (!ValidateUtil.isEmptyExact(this._param.searchValue)) {
            this.setState({searchValue: this._param.searchValue!});
        }

        this._selectedRestaurantFormatIds = [];
        this._param.recipeRestaurantLargeFormatIds?.split('|').forEach(id => {
            if (!ValidateUtil.isEmptyExact(id)) {
                this._selectedRestaurantFormatIds.push(parseInt(id));
            }
        });

        this._selectedSeasonIds = [];
        this._param.seasonIds?.split('|').forEach(id => {
            if (!ValidateUtil.isEmptyExact(id)) {
                this._selectedSeasonIds.push(parseInt(id));
            }
        });

        this._selectedRecipeCategoryIds = [];
        this._param.recipeCategoryIds?.split('|').forEach(id => {
            if (!ValidateUtil.isEmptyExact(id)) {
                this._selectedRecipeCategoryIds.push(parseInt(id));
            }
        });

        if (!ValidateUtil.isEmptyExact(this._param.costFrom)) {
            this._filterCostFrom = parseInt(this._param.costFrom!);
        }

        if (!ValidateUtil.isEmptyExact(this._param.costTo)) {
            this._filterCostTo = parseInt(this._param.costTo!);
        }

        this._selectedMakerIds = [];
        this._param.makerIds?.split('|').forEach(id => {
            if (!ValidateUtil.isEmptyExact(id)) {
                this._selectedMakerIds.push(parseInt(id));
            }
        });

        this._selectedProductCategoryIds = [];
        this._param.productLargeCategoryIds?.split('|').forEach(id => {
            if (!ValidateUtil.isEmptyExact(id)) {
                this._selectedProductCategoryIds.push(parseInt(id));
            }
        });
    }

    /**
     * マスターのロード
     * @private
     */
    _loadMasters = async () => {
        // レシピカテゴリー
        const getAllRecipeCategories = MasterApiFactory(AppG.getConfiguration(), AppG.getBasePath())
            .getAllRecipeCategoriesMaster().then((recipeCategoriesRes: AxiosResponse<RecipeCategory[]>) => {
                if (recipeCategoriesRes != null && recipeCategoriesRes.data != null) {
                    let _other : RecipeCategory | null = null;
                    recipeCategoriesRes.data.forEach((recipeCategory: RecipeCategory) => {
                        if (recipeCategory.name == 'その他') {
                            _other = recipeCategory;
                        } else {
                            this.state.recipeCategories.push(recipeCategory);
                        }
                    })
                    if (_other != null) {
                        this.state.recipeCategories.push(_other);
                    }
                    this.setState({recipeCategories: this.state.recipeCategories});
                }
            });

        // 店舗業態
        const getAllRestaurantFormatMaster = MasterApiFactory(AppGlobalDataUtil.getConfiguration(), AppGlobalDataUtil.getBasePath())
            .getAllRestaurantFormatMaster().then((restaurantFormatRes: AxiosResponse<RestaurantFormat[]>) => {
                if (restaurantFormatRes != null && restaurantFormatRes.data != null) {
                    this.setState({restaurantFormatMasters: restaurantFormatRes.data});
                }
            });

        // レシピカテゴリー
        const getAllProductCategories = MasterApiFactory(AppG.getConfiguration(), AppG.getBasePath())
            .getAllProductCategories().then((productCategoriesRes: AxiosResponse<ProductCategory[]>) => {
                if (productCategoriesRes != null && productCategoriesRes.data != null) {
                    let _other : ProductCategory | null = null;
                    productCategoriesRes.data.forEach((productCategory: ProductCategory) => {
                        if (productCategory.large.name == 'その他') {
                            _other = productCategory;
                        } else {
                            this.state.productCategories.push(productCategory);
                        }
                    })
                    if (_other != null) {
                        this.state.productCategories.push(_other);
                    }
                    this.setState({productCategories: this.state.productCategories});
                }
            });

        // メーカー一覧
        const getMakers = MakerApiFactory(AppG.getConfiguration(), AppG.getBasePath())
            .findMakers(
                0,
                1000,
                undefined,
                1,
            ).then((resultMakersRes: AxiosResponse<Maker[]>) => {
                if (resultMakersRes !== null) {
                    this._regRexOfMaker.forEach((title: string) => {
                        // ソート
                        let _makers: Maker[] | undefined = resultMakersRes.data.filter((m: Maker) => ValidateUtil.isStartBy(m.nameKana, title));
                        this.state.makers.push(_makers);
                    });
                    this.setState({makers: this.state.makers});
                }
            });

        // 実行
        await Promise.all([
            getAllRecipeCategories,
            getAllRestaurantFormatMaster,
            getAllProductCategories,
            getMakers]);
    }

    /**
     * コンテンツのロード
     * @private
     */
    _loadContents = async () => {
    }

    /**
     * 検索できるかどうか
     */
    _isEnableSearchBtn = () => {
        // レシピ
        let _recipeEnable = false;
        if (this._searchType == 'recipe' || this._searchType == 'all' || this._searchType == null) {
            // 入力があったら true
            if (StrUtil.arrayToParamStr(this._selectedRestaurantFormatIds) != '||') {
                _recipeEnable = true;
            }

            if (StrUtil.arrayToParamStr(this._selectedSeasonIds) != '||') {
                _recipeEnable = true;
            }

            if (StrUtil.arrayToParamStr(this._selectedRecipeCategoryIds) != '||') {
                _recipeEnable = true;
            }

            // if (!(this._filterCostFrom == null && this._filterCostTo == null)) {
            //     _recipeEnable = true;
            // }
        }

        if (_recipeEnable) {
            // レシピに入力があったら
            return true;
        }

        // 商品
        let _productEnable = false;
        if (this._searchType == 'product' || this._searchType == 'all') {
            if (StrUtil.arrayToParamStr(this._selectedMakerIds) != '||') {
                _productEnable = true;
            }

            if (StrUtil.arrayToParamStr(this._selectedProductCategoryIds) != '||') {
                _productEnable = true;
            }
        }

        if (_productEnable) {
            // 商品に入力があったら
            return true;
        }

        // 共通（レシピ、商品共に検索条件が無い場合）
        if (ValidateUtil.isEmptyExact(this.state.searchValue)) {
            // 検索ワードが無いなら false
            return false;
        }

        // 検索ワードあるなら
        return true;

    }

    /**
     * 検索結果ページへ
     */
    _goToResultPage = () => {
        const { routeName } = this.props.navigation.state;

        // 検索履歴に追加
        SearchDataDao.addNew(this.state.searchValue);

        let searchScreenModel: SearchScreenModel = new SearchScreenModel();

        searchScreenModel.tabHidden = this._param.tabHidden;
        searchScreenModel.menuCategoryId = this._param.menuCategoryId;
        searchScreenModel.menuCategoryName = this._param.menuCategoryName;
        searchScreenModel.productLargeCategoryId = this._param.productLargeCategoryId;
        searchScreenModel.productMediumCategoryId = this._param.productMediumCategoryId;
        searchScreenModel.productLargeCategoryName = this._param.productLargeCategoryName;
        searchScreenModel.productMediumCategoryName = this._param.productMediumCategoryName;
        searchScreenModel.makerId = this._param.makerId;
        searchScreenModel.keyword = this._param.keyword;
        searchScreenModel.productId = this._param.productId;
        searchScreenModel.recipeSortType = this._param.recipeSortType;
        searchScreenModel.productSortType = this._param.productSortType;
        searchScreenModel.cookingTime = this._param.cookingTime;

        if (ValidateUtil.isEmptyExact(this.state.searchValue)) {
            searchScreenModel.headerTitle = '検索結果';
        } else {
            searchScreenModel.headerTitle = this.state.searchValue;
        }
        searchScreenModel.searchValue = this.state.searchValue;
        searchScreenModel.searchType = this._searchType;
        searchScreenModel.screenNameFrom = routeName;

        searchScreenModel.recipeRestaurantLargeFormatIds = StrUtil.arrayToParamStr(this._selectedRestaurantFormatIds);
        searchScreenModel.seasonIds = StrUtil.arrayToParamStr(this._selectedSeasonIds);
        searchScreenModel.recipeCategoryIds = StrUtil.arrayToParamStr(this._selectedRecipeCategoryIds);
        searchScreenModel.costFrom = this._filterCostFrom == null? null : `${this._filterCostFrom}`;
        searchScreenModel.costTo = this._filterCostTo == null? null : `${this._filterCostTo}`;

        searchScreenModel.makerIds = StrUtil.arrayToParamStr(this._selectedMakerIds);
        searchScreenModel.productLargeCategoryIds = StrUtil.arrayToParamStr(this._selectedProductCategoryIds);

        if (this._searchType == 'product') {
            // Analytics
            AnalyticsUtil.sendAnalytics(
                DOpenEventName.searchProduct,
                AnalyticsEventName.searchProduct,  // アナリティクスのイベント名
                'SearchProductResultScreen',
                null,
                null,
                null,
                `メーカー=${searchScreenModel.makerIds}&食材カテゴリ=${searchScreenModel.productLargeCategoryIds}&freeWord=${this.state.searchValue}`,
            );
        } else {
            // Analytics
            AnalyticsUtil.sendAnalytics(
                DOpenEventName.searchRecipe,
                AnalyticsEventName.searchRecipe,  // アナリティクスのイベント名
                'SearchRecipeResultScreen',
                null,
                null,
                null,
                `costFrom=${this._filterCostFrom}&costTo=${this._filterCostTo}&業態=${searchScreenModel.recipeRestaurantLargeFormatIds}&季節=${searchScreenModel.seasonIds}&メニューカテゴリ=${searchScreenModel.recipeCategoryIds}&freeWord=${this.state.searchValue}`,
            );
        }

        if (Platform.OS == 'web') {
            this.props.navigation.navigate('SearchResultScreen', searchScreenModel);
        } else {
            this.props.navigation.push('SearchResultScreen', searchScreenModel);
        }
    }

    /**
     * メーカー
     */
    _renderMakers = () => {
        let _width = AppG.window.width;
        if (ScreenSizeUtil.isWebSize()) {
            _width = appS.webMainContentsSize.width;
        }

        const _renderMakerList = (makers: Maker[], index: number) => {
            return (
                <View>
                    {makers.map((maker: Maker, index: number) => {
                        return (
                            <CheckBoxComponent
                                key={`maker_cell_${index}`}
                                title={maker.name}
                                selected={this._selectedMakerIds.some(id => id == maker.id)}
                                onPress={() => {
                                    if (this._selectedMakerIds.some(id => id == maker.id)) {
                                        let smi = this._selectedMakerIds;
                                        this._selectedMakerIds.some(
                                            function (list: number, index: number) {
                                                if (list == maker.id) {
                                                    smi.splice(index, 1);
                                                }
                                            });
                                    } else {
                                        this._selectedMakerIds.push(maker.id);
                                    }
                                    this.setState({resizeWindow: true});

                                }}
                            />
                        );
                    })}
                </View>
            )
            // return (
            //     <MakerListComponent makers={makers} navigation={this.props.navigation} key={`maker_list_${index}`} />
            // );
        };

        return (
            <View style={[{flex:1, flexDirection: 'column'}]}>
                {this._titlesOfMaker.map((title: string, index: number) => {
                    if (this.state.makers[index] != null && this.state.makers[index].length != 0) {
                        return (
                            <View key={`maker_view_${index}`}>
                                <TopicTitleComponent title={`${title}行`} fontSize={16} key={`maker_title_${index}`}/>
                                {this.state.makers.length > index && this.state.makers[index] != null && _renderMakerList(this.state.makers[index], index)}
                                <SpacerComponent height={appS.margins.side}/>
                            </View>
                        );
                    }
                })}
            </View>
        );
    }

    // /**
    //  * タブ
    //  */
    // _renderTabArea = () => {
    //     const _tabHeight = 37;
    //     let _width = AppG.window.width;
    //     if (ScreenSizeUtil.isWebSize()) {
    //         _width = appS.webMainContentsSize.width;
    //     }
    //     let widthStyle: any = {width: _width / this._tabs.length - 1};
    //
    //     return (
    //         <View style={{height: _tabHeight + 8, marginTop: 4,  flexDirection: 'row', backgroundColor: appColors.white}}>
    //             {this._tabs.map(map => (
    //                 <View key={`tab_button_view_${map.index}`} style={{flexDirection: 'row'}}>
    //                     <TouchableOpacity
    //                         key={`tab_button_button_${map.index}`}
    //                         style={[
    //                             {
    //                                 height: _tabHeight,
    //                                 alignItems: 'center',
    //                                 justifyContent: 'center',
    //                             },
    //                             widthStyle
    //                         ]}
    //                         onPress={() => {
    //                             map.onPress();
    //                         }}
    //                     >
    //                         {(map.badge == null || map.badge == 0) && (
    //                             <View
    //                                 key={`tab_button_button_badge_${map.index}`}
    //                                 style={{
    //                                     flexDirection: "row",
    //                                     backgroundColor: this._getTabColor(map.index, map.backgroundColor, map.unselectBackgroundColor),
    //                                     borderRadius: 50,
    //                                     borderWidth: 1,
    //                                     borderColor: appColors.transparent,
    //                                     height: _tabHeight,
    //                                     alignItems: 'center',
    //                                     justifyContent: 'center'
    //                                 }}>
    //                                 <Text key={`tab_button_button_text_${map.index}`}
    //                                       style={{
    //                                           // paddingTop: 16,
    //                                           // paddingBottom: 7,
    //                                           padding: 4,
    //                                           // fontWeight: this.state.tabIndex == map.index? 'bold': 'normal',
    //                                           fontWeight: 'bold',
    //                                           color: this._getTextColor(map.index, map.color, map.unselectColor),
    //                                           fontSize: appFont.size.tab}}>{map.title}</Text>
    //                             </View>
    //                         )}
    //                         {(map.badge != null && map.badge != 0) && (
    //                             <View style={{
    //                                 flexDirection: "row",
    //                                 backgroundColor: this._getTabColor(map.index, map.backgroundColor, map.unselectBackgroundColor),
    //                                 borderRadius: 50,
    //                                 borderWidth: 1,
    //                                 borderColor: appColors.transparent,
    //                                 height: _tabHeight,
    //                                 alignItems: 'center',
    //                                 justifyContent: 'center'
    //                             }}>
    //                                 <Text key={`tab_button_button_text_${map.index}`}
    //                                       style={{
    //                                           // paddingTop: 16,
    //                                           // paddingBottom: 7,
    //                                           fontWeight: this.state.tabIndex == map.index? 'bold': 'normal',
    //                                           color: this._getTextColor(map.index, map.color, map.unselectColor),
    //                                           fontSize: appFont.size.tab,
    //                                       }}>{`${map.title}`}</Text>
    //                                 <View style={{marginLeft: 0, marginTop: 8}}>
    //                                     <IconWithBadgeComponent
    //                                         iconSize={12}
    //                                         isShow={true}
    //                                         isWrittenNumber={false}
    //                                         badgeCount={2}/>
    //                                 </View>
    //                             </View>
    //                         )}
    //                         {this._drawBorder(map, this._tabs.length, this._getTextColor(map.index, map.color, map.unselectColor))}
    //                     </TouchableOpacity>
    //                 </View>
    //             ))}
    //         </View>
    //     );
    // };

    /**
     * 予測変換キーワードエリア
     */
    _renderKeywords = () => {
        if (ValidateUtil.isEmptyArray(this.state.keywords)) {
            return null;
        }

        let _headerHeight = 50;
        if (Platform.OS == 'web') {
            _headerHeight = 50 + ((UrlUtil.isIosByUa() || UrlUtil.isAndroidByUa())? 40:0);
        }
        const _searchBoxHeight = 40;
        return (
            <ScrollView
                style={{height: AppG.window.height - (_headerHeight + _searchBoxHeight + 90), backgroundColor: appColors.white, marginTop: ScreenSizeUtil.isWebSize()? 30 : 0}}
            >
                <View style={{margin: appS.margins.side}}>
                    {this.state.keywords.map((keyword: Keyword, index: number) => {
                        return (
                            <View>

                                <ListCellComponent
                                    key={`keyword_list_${index}`}
                                    navigation={this.props.navigation}
                                    title={keyword.value}
                                    borderWidth={0}
                                    onPress={() => {
                                        const _onSearchSubmit = (value: string) => {
                                            // 何もしない
                                        }
                                        CustomHeaderFunc.selectKeyword(this, keyword, this.state.searchValue, _onSearchSubmit);
                                    }}/>
                            </View>
                        )
                    })}
                </View>
            </ScrollView>
        );
    }

    /**
     * フリーワード検索のパーツ
     */
    _renderFreeWordSearch = () => {
        let _searchBoxWidth = AppG.window.width;
        if (ScreenSizeUtil.isWebSize()) {
            _searchBoxWidth = appS.webMainContentsSize.width;
        }
        _searchBoxWidth = _searchBoxWidth - appS.margins.side * 2;

        return (
            <View style={{flex: 1}}>
                <View style={{flexDirection: 'row', flex: 1}}>
                    <SearchBar
                        placeholder="キーワードで検索"
                        autoCapitalize={"none"}
                        onChangeText={(text?: string) => {
                            if (text == null) {
                                return;
                            }
                            this.setState({searchValue: text});
                            setTimeout(() => {
                                CustomHeaderFunc.getKeyword(this, text);
                            }, 300);
                        }}
                        value={this.state.searchValue}
                        containerStyle={{
                            width: _searchBoxWidth,
                            backgroundColor: appColors.white,
                            borderBottomColor: appColors.transparent,
                            borderTopColor: appColors.transparent,
                        }}
                        // returnKeyType={'search'}
                        // onSubmitEditing={() => {
                        //     this.setState({keywords: []});
                        //     const { routeName } = this.props.navigation.state;
                        //     if (routeName != 'SearchFreeWordResultScreen') {
                        //         let searchScreenModel: SearchScreenModel = new SearchScreenModel();
                        //         searchScreenModel.headerTitle = this.state.searchValue;
                        //         searchScreenModel.searchValue = this.state.searchValue;
                        //         this.props.navigation.navigate('SearchFreeWordResultScreen', searchScreenModel);
                        //     } else {
                        //         this.state.onSearchSubmit(this.state.searchValue);
                        //     }
                        //
                        //     // setTimeout(() => {
                        //     //     this.setState({searchValue: ''});
                        //     // }, 100);
                        // }}
                        inputContainerStyle={{
                            backgroundColor: appColors.searchBarBackground,
                            width: _searchBoxWidth,
                            height: 40,
                            borderRadius: 10,
                            marginTop: 0,
                            borderWidth: 1,
                            borderBottomWidth: 1,
                            borderColor: appColors.lightGray,
                        }}
                        // @ts-ignore
                        inputStyle={{fontSize: appFont.size.searchArea, outline: 'none'}}
                        //@ts-ignore
                        clearIcon={
                            <TouchableOpacity
                                onPress={() => {
                                    this.setState({searchValue: ''});
                                    setTimeout(() => {
                                        this.setState({keywords: []});
                                    }, 300);
                                }}
                            >
                                <Image source={require('../../resources/images/05.search/i_search_cancel.png')}
                                       style={[{
                                           marginLeft: 8,
                                           width: 16,
                                           height: 16,
                                       }]}
                                       resizeMode='contain'
                                />
                            </TouchableOpacity>
                        }
                        //@ts-ignore
                        searchIcon={
                            <Image source={require('../../resources/images/search_bar_icon.png')}
                                   style={[{
                                       marginRight: 8,
                                       width: 16,
                                       height: 16,
                                   }]}
                                   resizeMode='contain'
                            />
                        }
                    />
                </View>
            </View>
        );
    }

    /**
     * 〇〇から探すのとこ
     */
    _renderSearchConditions = () => {
        let _width = AppG.window.width;
        if (ScreenSizeUtil.isWebSize()) {
            _width = appS.webMainContentsSize.width;
        }
        _width = _width - appS.margins.side * 2;

        {/*未ログイン*/}
        if (AppG.user == null) {
            return (
                <View style={{backgroundColor: appColors.backBaseColor}}>
                    <NoUserImageComponent navigation={this.props.navigation} />
                </View>
            );
        }

        return (
            <View>
                <View
                    style={{
                        flexDirection: 'row'
                    }}
                >
                    <View style={{flex: 1}}/>
                    <TouchableOpacity
                        style={{
                            marginRight: appS.margins.side,
                        }}
                        onPress={() => {
                            if (this._searchType == 'recipe') {
                                this._selectedRecipeCategoryIds = []; // レシピカテゴリのマスター
                                this._selectedRestaurantFormatIds = [];    // 業態
                                this._selectedSeasonIds = [];    // 季節
                                this._filterCostFrom = undefined;
                                this._filterCostTo = undefined;
                                this._costPickerRef.reset();    // picker リセット
                            } else {
                                this._selectedProductCategoryIds = []; // 商品カテゴリのマスター
                                this._selectedMakerIds = [];   // メーカーマスター
                            }
                            this.setState({resizeWindow: true});
                        }}
                    >
                        <View style={{flexDirection: 'row'}}>
                            <Text style={{fontFamily: appFont.family.bold, fontSize: 14, fontWeight: "bold"}}>選択した項目をクリア</Text>
                            <Image source={require('../../resources/images/05.search/i_search_cancel.png')}
                                   style={[{
                                       marginTop: 4,
                                       marginLeft: 4,
                                       marginRight: ScreenSizeUtil.isWebSize()? 20 : 0,
                                       width: 16,
                                       height: 16,
                                   }]}
                                   resizeMode='contain'
                            />
                        </View>
                    </TouchableOpacity>
                </View>
                <View style={{
                    margin: appS.margins.side,
                    marginBottom: 0,
                    borderColor: appColors.searchMenuBorder,
                    borderWidth: 1,
                    width: _width,
                }}>
                    {this._searchType == 'recipe' && (
                        <View>
                            <TopicTitleComponent title={'季節から探す'} subTopic={true} backgroundColor={appColors.subTopicBackground} />
                            <View style={{flexDirection: 'row'}}>
                                <View style={{flex: 1, flexDirection: 'column', marginLeft: appS.margins.side}}>
                                    {this._seasonMaster.map((map, index) => {
                                        if (index % 2 == 1) {
                                            return null;
                                        }
                                        return (
                                            <CheckBoxComponent
                                                key={`season_check_${map.id}`}
                                                title={map.name}
                                                selected={this._selectedSeasonIds.some(id => id == map.id)}
                                                onPress={() => {
                                                    if (this._selectedSeasonIds.some(id => id == map.id)) {
                                                        let ssi = this._selectedSeasonIds;
                                                        this._selectedSeasonIds.some(
                                                            function (list: number, index: number) {
                                                                if (list == map.id) {
                                                                    ssi.splice(index, 1);
                                                                }
                                                            });
                                                    } else {
                                                        this._selectedSeasonIds.push(map.id);
                                                    }
                                                    this.setState({resizeWindow: true});

                                                }}
                                            />

                                        )
                                    })}
                                </View>
                                <View style={{flex: 1, flexDirection: 'column'}}>
                                    {this._seasonMaster.map((map, index) => {
                                        if (index % 2 == 0) {
                                            return null;
                                        }
                                        return (
                                            <CheckBoxComponent
                                                key={`season_check_${map.id}`}
                                                title={map.name}
                                                selected={this._selectedSeasonIds.some(id => id == map.id)}
                                                onPress={() => {
                                                    if (this._selectedSeasonIds.some(id => id == map.id)) {
                                                        let ssi = this._selectedSeasonIds;
                                                        this._selectedSeasonIds.some(
                                                            function (list: number, index: number) {
                                                                if (list == map.id) {
                                                                    ssi.splice(index, 1);
                                                                }
                                                            });
                                                    } else {
                                                        this._selectedSeasonIds.push(map.id);
                                                    }
                                                    this.setState({resizeWindow: true});

                                                }}
                                            />

                                        )
                                    })}
                                </View>
                            </View>
                            <SpacerComponent height={this._checkAreaMargin} />

                            <TopicTitleComponent title={'カテゴリから探す'} subTopic={true} backgroundColor={appColors.subTopicBackground} />
                            <View style={{flexDirection: 'row'}}>
                                <View style={{flex: 1, flexDirection: 'column', marginLeft: appS.margins.side}}>
                                    {this.state.recipeCategories.map((map, index) => {
                                        if (index % 2 == 1) {
                                            return null;
                                        }
                                        return (
                                            <CheckBoxComponent
                                                key={`recipecategory_check_${map.id}`}
                                                title={map.name}
                                                selected={this._selectedRecipeCategoryIds.some(id => id == map.id)}
                                                onPress={() => {
                                                    if (this._selectedRecipeCategoryIds.some(id => id == map.id)) {
                                                        let srci = this._selectedRecipeCategoryIds;
                                                        this._selectedRecipeCategoryIds.some(
                                                            function (list: number, index: number) {
                                                                if (list == map.id) {
                                                                    srci.splice(index, 1);
                                                                }
                                                            });
                                                    } else {
                                                        this._selectedRecipeCategoryIds.push(map.id);
                                                    }
                                                    this.setState({resizeWindow: true});

                                                }}
                                            />

                                        )
                                    })}
                                </View>
                                <View style={{flex: 1, flexDirection: 'column'}}>
                                    {this.state.recipeCategories.map((map, index) => {
                                        if (index % 2 == 0) {
                                            return null;
                                        }
                                        return (
                                            <CheckBoxComponent
                                                key={`recipecategory_check_${map.id}`}
                                                title={map.name}
                                                selected={this._selectedRecipeCategoryIds.some(id => id == map.id)}
                                                onPress={() => {
                                                    if (this._selectedRecipeCategoryIds.some(id => id == map.id)) {
                                                        let srci = this._selectedRecipeCategoryIds;
                                                        this._selectedRecipeCategoryIds.some(
                                                            function (list: number, index: number) {
                                                                if (list == map.id) {
                                                                    srci.splice(index, 1);
                                                                }
                                                            });
                                                    } else {
                                                        this._selectedRecipeCategoryIds.push(map.id);
                                                    }
                                                    this.setState({resizeWindow: true});

                                                }}
                                            />

                                        )
                                    })}
                                </View>
                            </View>
                            <SpacerComponent height={this._checkAreaMargin} />



                            <TopicTitleComponent title={'原価から探す'} subTopic={true} backgroundColor={appColors.subTopicBackground} />
                            <View>
                                <CostComponent
                                    ref={(costPickerRef: any) => { this._costPickerRef = costPickerRef; }}
                                    showTitle={true}
                                    pickChangeLeft={(value) => {
                                        let _value = value;
                                        if (value == 0) {
                                            _value = undefined;
                                        }
                                        this._filterCostFrom = _value;
                                    }}
                                    pickChangeRight={(value) => {
                                        let _value = value;
                                        if (value == '上限無し') {
                                            _value = undefined;
                                        }
                                        this._filterCostTo = _value;
                                    }}
                                />
                            </View>
                            <SpacerComponent height={this._checkAreaMargin} />

                            <TopicTitleComponent title={'業態から探す'} subTopic={true} backgroundColor={appColors.subTopicBackground} />
                            <View style={{flexDirection: 'row'}}>
                                <View style={{flex: 1, flexDirection: 'column', marginLeft: appS.margins.side}}>
                                    {this.state.restaurantFormatMasters.map((map, index) => {
                                        if (index % 2 == 1) {
                                            return null;
                                        }
                                        return (
                                            <CheckBoxComponent
                                                key={`restaurantFormat_check_${map.large.id}`}
                                                title={map.large.name!}
                                                selected={this._selectedRestaurantFormatIds.some(id => id == map.large.id)}
                                                onPress={() => {
                                                    if (this._selectedRestaurantFormatIds.some(id => id == map.large.id)) {
                                                        let srci = this._selectedRestaurantFormatIds;
                                                        this._selectedRestaurantFormatIds.some(
                                                            function (list: number, index: number) {
                                                                if (list == map.large.id) {
                                                                    srci.splice(index, 1);
                                                                }
                                                            });
                                                    } else {
                                                        this._selectedRestaurantFormatIds.push(map.large.id!);
                                                    }
                                                    this.setState({resizeWindow: true});

                                                }}
                                            />

                                        )
                                    })}
                                </View>
                                <View style={{flex: 1, flexDirection: 'column'}}>
                                    {this.state.restaurantFormatMasters.map((map, index) => {
                                        if (index % 2 == 0) {
                                            return null;
                                        }
                                        return (
                                            <CheckBoxComponent
                                                key={`restaurantFormat_check_${map.large.id}`}
                                                title={map.large.name!}
                                                selected={this._selectedRestaurantFormatIds.some(id => id == map.large.id)}
                                                onPress={() => {
                                                    if (this._selectedRestaurantFormatIds.some(id => id == map.large.id)) {
                                                        let srfi = this._selectedRestaurantFormatIds;
                                                        this._selectedRestaurantFormatIds.some(
                                                            function (list: number, index: number) {
                                                                if (list == map.large.id) {
                                                                    srfi.splice(index, 1);
                                                                }
                                                            });
                                                    } else {
                                                        this._selectedRestaurantFormatIds.push(map.large.id!);
                                                    }
                                                    this.setState({resizeWindow: true});

                                                }}
                                            />

                                        )
                                    })}
                                </View>
                            </View>
                            <SpacerComponent height={this._checkAreaMargin} />
                        </View>
                    )}

                    {this._searchType == 'product' && (
                        <View>

                            <TopicTitleComponent title={'食材カテゴリから探す'} subTopic={true} backgroundColor={appColors.subTopicBackground} />
                            <View style={{flexDirection: 'row'}}>
                                <View style={{flex: 1, flexDirection: 'column', marginLeft: appS.margins.side}}>
                                    {this.state.productCategories.map((map, index) => {
                                        if (index % 2 == 1) {
                                            return null;
                                        }
                                        return (
                                            <CheckBoxComponent
                                                key={`productcategory_check_${map.large.id}`}
                                                title={map.large.name!}
                                                selected={this._selectedProductCategoryIds.some(id => id == map.large.id)}
                                                onPress={() => {
                                                    if (this._selectedProductCategoryIds.some(id => id == map.large.id)) {
                                                        let srci = this._selectedProductCategoryIds;
                                                        this._selectedProductCategoryIds.some(
                                                            function (list: number, index: number) {
                                                                if (list == map.large.id) {
                                                                    srci.splice(index, 1);
                                                                }
                                                            });
                                                    } else {
                                                        this._selectedProductCategoryIds.push(map.large.id!);
                                                    }
                                                    this.setState({resizeWindow: true});
                                                }}
                                            />

                                        )
                                    })}
                                </View>
                                <View style={{flex: 1, flexDirection: 'column'}}>
                                    {this.state.productCategories.map((map, index) => {
                                        if (index % 2 == 0) {
                                            return null;
                                        }
                                        return (
                                            <CheckBoxComponent
                                                key={`productcategory_check_${map.large.id}`}
                                                title={map.large.name!}
                                                selected={this._selectedProductCategoryIds.some(id => id == map.large.id)}
                                                onPress={() => {
                                                    if (this._selectedProductCategoryIds.some(id => id == map.large.id)) {
                                                        let srci = this._selectedProductCategoryIds;
                                                        this._selectedProductCategoryIds.some(
                                                            function (list: number, index: number) {
                                                                if (list == map.large.id) {
                                                                    srci.splice(index, 1);
                                                                }
                                                            });
                                                    } else {
                                                        this._selectedProductCategoryIds.push(map.large.id!);
                                                    }
                                                    this.setState({resizeWindow: true});
                                                }}
                                            />

                                        )
                                    })}
                                </View>
                            </View>
                            <SpacerComponent height={this._checkAreaMargin} />

                            <TopicTitleComponent title={'メーカーから探す'} subTopic={true} backgroundColor={appColors.subTopicBackground} />
                            <View style={{margin: appS.margins.side}}>
                                {this._renderMakers()}
                            </View>
                            <SpacerComponent height={this._checkAreaMargin} />
                        </View>
                    )}

                </View>
            </View>
        );
    }

    /**
     * 検索ボタン
     */
    _renderSearchButton = () => {
        let _width = AppG.window.width;
        if (ScreenSizeUtil.isWebSize()) {
            _width = appS.webMainContentsSize.width;
        }

        return (
            <View style={{
                width: _width,
            }}>
                <TouchableOpacity
                    style={{
                        width: _width,
                        height: appS.buttonSize.height,
                        backgroundColor: this._isEnableSearchBtn()? appColors.buttonOrange: appColors.gray,
                        alignItems: 'center',
                        alignSelf: 'center',
                        borderRadius: appS.buttonSize.radius
                    }}
                    disabled={!this._isEnableSearchBtn()}
                    onPress={() => {
                        this._goToResultPage();
                    }}
                >
                    <View style={{flexDirection: 'row', alignSelf: 'center'}}>
                        <Text
                            style={{
                                fontFamily: appFont.family.bold,
                                color: appColors.white,
                                fontSize: appFont.size.buttonText,
                                textAlignVertical: 'center',
                                lineHeight: appS.buttonSize.height,
                                fontWeight: "bold"
                            }}
                        >{`${'この条件で検索する'}`}</Text>
                        <Image
                            source={require('../../resources/images/02.menu_general/arrow_W_right.png')}
                            style={{
                                marginTop: Platform.OS == 'android'? 1 : 3,
                                alignSelf: 'center',
                                width: 12,
                                height: 12,
                            }}
                        />
                    </View>
                </TouchableOpacity>
            </View>
        );

        // return (
        //     <View style={{
        //         width: _width,
        //     }}>
        //         <TouchableOpacity
        //             style={{
        //                 width: _width,
        //                 height: appS.buttonSize.height,
        //                 backgroundColor: appColors.buttonOrange,
        //                 alignItems: 'center',
        //                 alignSelf: 'center',
        //             }}
        //             onPress={() => {
        //                 this._goToResultPage();
        //             }}
        //         >
        //             <Text
        //                 style={{
        //                     fontFamily: appFont.family.default,
        //                     fontWeight: "bold",
        //                     color: appColors.white,
        //                     fontSize: appFont.size.buttonText,
        //                     textAlignVertical: 'center',
        //                     lineHeight: appS.buttonSize.height,
        //                 }}
        //             >{`${'この条件で検索する >'}`}</Text>
        //         </TouchableOpacity>
        //     </View>
        // );
    }

    /**
     * 検索メニューの表示
     */
    _renderSearchMenu = () => {
        let _width = AppG.window.width;
        if (ScreenSizeUtil.isWebSize()) {
            _width = appS.webMainContentsSize.width;
        }

        return (
            <KeyboardAwareScrollView style={[{flex: 1, flexDirection: 'column'}]}>

                <View style={{flexDirection: "row", width: AppG.window.width}}>
                    <View style={{width: _width}}>
                        {/*タブ*/}
                        <TabBorderComponent
                            ref={(tabRef: any) => { this._tabRef = tabRef; }}
                            tabs={this._tabs}
                            focusTabNumber={this._tabIndex}
                            unselectBorder={true}
                            button={true}
                        />
                        {ScreenSizeUtil.isWebSize() && <WideSubTitleComponent title={'詳細検索'} width={_width} />}
                        {/*フリーワード検索*/}
                        {this._renderFreeWordSearch()}
                        {/*予測変換*/}
                        {this._renderKeywords()}
                        {/*検索条件*/}
                        {this._renderSearchConditions()}
                    </View>
                    {ScreenSizeUtil.isWebSize() && (
                        <View style={{marginLeft: appS.margins.webBetweenMargin, width: appS.webSideContentsSize.width}}>
                            <WideScreenAdComponent navigation={this.props.navigation}/>
                            <WideScreenSNSComponent navigation={this.props.navigation}/>
                        </View>
                    )}
                </View>
                <SpacerComponent height={appS.footer.scrollBottom}/>
            </KeyboardAwareScrollView>
        );
    }

    _renderWide = () => {
        const {navigation} = this.props;
        return (
            <SafeAreaView
                style={[{height: AppG.window.height, flex: 1, flexDirection: 'column', backgroundColor: appColors.white}]}
                onLayout={(event) => {}}
            >
                <CustomHeaderComponentWide
                    navigation={navigation}
                    searchBar={true}
                />

                <View style={{
                    flex: 1,
                    flexDirection: 'row',
                    alignSelf: 'center',
                    backgroundColor: appColors.backBaseColor,
                }}>
                    <ScrollView
                        horizontal  // 下階層のスクロールイベントを拾えるようにしている
                        style={{
                            width: AppG.window.width,
                            height: AppG.window.height - appS.header.webHeight,
                        }}
                    >
                        <View style={[{flex: 1, flexDirection: 'column', width: AppG.window.width}]}>
                            <KeyboardAwareScrollView
                                style={[{flex: 1, flexDirection: 'column'}]}
                            >
                                <View
                                    style={{
                                        flex: 1,
                                        alignSelf: 'center',
                                        width: appS.webAllContentsSize.width,
                                        backgroundColor: appColors.backBaseColor,
                                    }}
                                >
                                    {/*メインメニュー*/}
                                    {this._renderSearchMenu()}

                                    {/*検索ボタン*/}
                                    {this._renderSearchButton()}
                                    <SpacerComponent height={appS.buttonSize.height} />
                                </View>

                            </KeyboardAwareScrollView>
                        </View>
                    </ScrollView>
                </View>
            </SafeAreaView>
        );
    }


    _renderSmall = () => {
        return (
            // appStyles.statusBarHiddenSafeAreaでステータスバー分上に上げる
            <SafeAreaView
                style={[{height: AppG.window.height, flex: 1, flexDirection: 'column', backgroundColor: appColors.white}]}
                onLayout={(event) => {}}
            >
                <View style={{zIndex: 99,}}>
                    <CustomHeaderComponentSmall
                        navigation={this.props.navigation}
                        leftButton={'back'}
                        rightButton={'none'}
                        title={'詳細検索'}
                        searchBar={false}
                        textColor={appColors.black}
                        barColor={appColors.white}
                        borderBottomColor={appColors.transparent}
                        statusBarType={"dark-content"}
                    />
                    {/*Border*/}
                    <View style={{flexDirection: "row"}}>
                        <View style={{flex: 1, height: 1, backgroundColor: appColors.spacerGray}}/>
                    </View>
                </View>

                <View style={{flex: 1, backgroundColor: appColors.backBaseColor, marginBottom: appS.buttonSize.height}}>
                    {/*メインメニュー*/}
                    {this._renderSearchMenu()}
                </View>
                <View style={{position: 'absolute', bottom: 0}}>
                    {/*検索ボタン*/}
                    {this._renderSearchButton()}
                </View>

            </SafeAreaView>
        );
    }


    /** 描画 */
    render() {
        const {navigation} = this.props;
        if (!this.state.isLoaded) {
            return (
                <View style={{flex: 1}}>
                    <IndicatorComponent
                        failTime={60}
                        isLoading={true}
                        reloadFunc={() => {
                            this.componentWillUnmount();
                            this.componentDidMount();
                        }}/>
                </View>
            );
        }

        LinkUtil.addReturnFalseToATag();
        if (ScreenSizeUtil.isWebSize()) {
            // WEBサイズ
            return this._renderWide();
        } else {
            // SPサイズ
            return this._renderSmall();
        }
    }

}
