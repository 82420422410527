import {Platform} from "react-native";
import {getEnvVars} from "../../../environment";
import axios from "axios";

class MailErrorInformationUtil {

    /**
     * メールエラー文言の取得
     * @param func 表示するための処理
     */
    get = (func: (main:string, modal: string) => void) => {
        // @ts-ignore
        const {REACT_APP_DOMAIN_URL} = getEnvVars();

        axios.get(`${REACT_APP_DOMAIN_URL}/latest_app_version.json`, {
            headers: {
                'Accept': '*/*',
                'Access-Control-Allow-Origin': '*',
                // 'Content-Type': 'application/json'
            }
        })
            .then(
                response => {
                    if (response.data != null && response.data.mail_error != null && response.data.mail_error.show != null) {
                        if (!response.data.mail_error.show) {
                            // 表示しない
                            return;
                        } else {
                            // 表示するための処理
                            func(response.data.mail_error.main, response.data.mail_error.modal);
                            return;
                        }
                    }
                })
            .catch(
                err => {
                    // log.debug(err)
                }
            )
    }
}

export default new MailErrorInformationUtil();
