import {Platform} from "react-native";
// @ts-ignore
import {log} from '../../../environment';

class WebTagUtil {
    /**
     * og:type を変更する
     */
    setOgType = (type: string) => {
        if (Platform.OS == 'web') {
            let ogType = document.getElementById('og-type');
            if (ogType != null) {
                ogType.setAttribute("content", type);
            }
        }
    }

    setTags = (title: string, description: string, image: string | null = null) => {
        if (Platform.OS == 'web') {
            document.title = `${title}`;
            let descriptionTag = document.getElementsByName('description')[0];
            descriptionTag.setAttribute("content", `${description}`);

            let ogUrl = document.getElementById('og-url');
            if (ogUrl != null) {
                ogUrl.setAttribute("content", location.href);
            }

            let ogTitle = document.getElementById('og-title');
            if (ogTitle != null) {
                ogTitle.setAttribute("content", `${title}`);
            }
            let twitterTitle = document.getElementById('twitter-title');
            if (twitterTitle != null) {
                twitterTitle.setAttribute("content", `${title}`);
            }

            let ogDescription = document.getElementById('og-description');
            if (ogDescription != null) {
                ogDescription.setAttribute("content", `${description}`);
            }
            let twitterDescription = document.getElementById('twitter-description');
            if (twitterDescription != null) {
                twitterDescription.setAttribute("content", `${description}`);
            }

            if (image != null) {
                let ogImage = document.getElementById('og-image');
                if (ogImage != null) {
                    ogImage.setAttribute("content", `${image}`);
                }
                let twitterImage = document.getElementById('twitter-image');
                if (twitterImage != null) {
                    twitterImage.setAttribute("content", `${image}`);
                }
            }

        }
    }
}

export default new WebTagUtil();
