import 'expo-dev-client';
import React from 'react';
import {Platform,} from 'react-native';
import {Provider} from 'unstated';
import {MenuProvider} from 'react-native-popup-menu';
import {createAppContainer, createSwitchNavigator} from 'react-navigation';
import {createStackNavigator} from 'react-navigation-stack';
import {createBottomTabNavigator} from 'react-navigation-tabs';
// import { createDrawerNavigator } from 'react-navigation-drawer';
import * as Font from "expo-font";
// import { useFonts, Inter_900Black } from '@expo-google-fonts/inter';
// import { useFonts } from 'expo-font';
// @ts-ignore
import createBrowserApp from '@react-navigation/web/dist/createBrowserApp';
import {getTabBarIcon, getTabBarLabel} from "./src/tsx/components/TabComponent";
import {HomeScreen} from "./src/tsx/screens/HomeScreen";
// @ts-ignore
import {getEnvVars, log} from './environment';

// for firebase ------------------------------
//@ts-ignore
import {decode, encode} from 'base-64';
import {LoginScreen} from "./src/tsx/screens/LoginScreen";
import {SignUpScreen} from "./src/tsx/screens/SignUpScreen";
import {RecipeDetailScreen} from "./src/tsx/screens/RecipeDetailScreen";
import {ProductDetailScreen} from "./src/tsx/screens/ProductDetailScreen";
import {MakerDetailScreen} from "./src/tsx/screens/MakerDetailScreen";
import {SearchScreen} from "./src/tsx/screens/SearchScreen";
import {SampleRequestScreen} from "./src/tsx/screens/SampleRequestScreen";
import {ProfileInit1Screen} from "./src/tsx/screens/ProfileInit1Screen";
import {ProfileInit2Screen} from "./src/tsx/screens/ProfileInit2Screen";
import {ProfileInit5Screen} from "./src/tsx/screens/ProfileInit5Screen";
import {FollowScreen} from "./src/tsx/screens/FollowScreen";
import {CartScreen} from "./src/tsx/screens/CartScreen";
import {ProductReviewScreen} from "./src/tsx/screens/ProductReviewScreen";
import {ChatsScreen} from "./src/tsx/screens/ChatsScreen";
import {ChatScreen} from "./src/tsx/screens/ChatScreen";
import AppG from "./src/tsx/util/AppG";
import {SelectSampleScreen} from "./src/tsx/screens/SelectSampleScreen";
import {RecipeDownloadScreen} from "./src/tsx/screens/web/RecipeDownloadScreen";
import {BuySubscriptionScreenApp} from "./src/tsx/screens/app/BuySubscriptionScreenApp";
import {ProfileScreen} from "./src/tsx/screens/ProfileScreen";
import {ChangePasswordScreen} from "./src/tsx/screens/ChangePasswordScreen";
import {ResetPasswordScreen} from "./src/tsx/screens/ResetPasswordScreen";
import {WhatIsRecipeDownloadScreen} from "./src/tsx/screens/WhatIsRecipeDownloadScreen";
import {ReportScreen} from "./src/tsx/screens/ReportScreen";
import {NewsScreen} from "./src/tsx/screens/NewsScreen";
import {NewsDetailScreen} from "./src/tsx/screens/NewsDetailScreen";
import {WebViewScreen} from "./src/tsx/screens/WebViewScreen";
import {SettingMenuScreen} from "./src/tsx/screens/SettingMenuScreen";
import {WithdrawScreen} from "./src/tsx/screens/WithdrawScreen";
import ExpoUtil from "./src/tsx/util/ExpoUtil";
import {appS} from "./src/resources/styles/style";
import {SearchKeywordScreen} from "./src/tsx/screens/SearchKeywordScreen";
import {ProfileInit6Screen} from "./src/tsx/screens/ProfileInit6Screen";
import {ReferralCodeScreen} from "./src/tsx/screens/ReferralCodeScreen";
import {TestScreen} from "./src/tsx/screens/TestScreen";
import {IndicatorComponent} from "./src/tsx/components/IndicatorComponent";
import {SearchResultScreen} from "./src/tsx/screens/SearchResultScreen";
import {SampleRequestHistoryScreen} from "./src/tsx/screens/SampleRequestHistoryScreen";
import {SampleRequestReviewsScreen} from "./src/tsx/screens/SampleRequestReviewsScreen";
import {SearchMenuCategoryScreen} from "./src/tsx/screens/SearchMenuCategoryScreen";
import {SearchJobCategoryScreen} from "./src/tsx/screens/SearchJobCategoryScreen";
import LoginUtil from "./src/tsx/util/LoginUtil";
import FooterDataDao from "./src/tsx/data/dao/local/FooterDataDao";

//@ts-ignore
if (!global.btoa) {  global.btoa = encode }
//@ts-ignore
if (!global.atob) { global.atob = decode }

// 各種 追加 web タグ
// if (Platform.OS == 'web') {
if (Platform.OS == 'web' && ExpoUtil.isProduction()) {
    const head = document.getElementsByTagName('head')[0];
    const body = document.getElementsByTagName("body")[0];

    // share の <script> は index.html に移動
    // facebook share
    const scriptFb = document.createElement("script");
    scriptFb.setAttribute("type","text/javascript");
    scriptFb.setAttribute("crossorigin","anonymous");
    scriptFb.setAttribute("src","https://connect.facebook.net/ja_JP/sdk.js");
    head.appendChild(scriptFb);

    // pinterest share
    const scriptPint = document.createElement("script");
    scriptPint.setAttribute("type","text/javascript");
    scriptPint.setAttribute("src","//assets.pinterest.com/js/pinit.js");
    head.appendChild(scriptPint);

    // @ts-ignore
    const {REACT_APP_FACEBOOK_APP_ID} = getEnvVars();
    // @ts-ignore
    window.fbAsyncInit = function() {
        // @ts-ignore
        FB.init({
            appId            : REACT_APP_FACEBOOK_APP_ID,
            autoLogAppEvents : true,
            xfbml            : true,
            version          : 'v13.0'
        });
    };
}

// SEO 系はココ（全画面共通）
if (Platform.OS == 'web' && !ExpoUtil.isProduction()) {
    const head = document.getElementsByTagName("head")[0];
    const meta = document.createElement("meta");
    meta.setAttribute("name","robots");
    meta.setAttribute("content","noindex,follow");
    if (head != null) {
        head.appendChild(meta);
    }
}
// URL から #無くしたので削除
// if (Platform.OS == 'web' && ExpoUtil.isProduction()) {
//     const head = document.getElementsByTagName("head")[0];
//     const link = document.createElement("link");
//     link.setAttribute("rel","canonical");
//     link.setAttribute("href","https://app.recibase.net/");
//     if (head != null) {
//         head.appendChild(link);
//     }
// }

// -------------------------------------------

/**
 * スタックナビゲーションのルート
 */
const StackRoutesMain = {
    // TestScreen: TestScreen,
    RecipeDetailScreen: RecipeDetailScreen,
    ProductDetailScreen: ProductDetailScreen,
    MakerDetailScreen: MakerDetailScreen,
    NewsScreen: NewsScreen,
    NewsDetailScreen: NewsDetailScreen,
    SearchScreen: SearchScreen,
    SearchMenuCategoryScreen: SearchMenuCategoryScreen,
    // SearchProductCategoryScreen: SearchProductCategoryScreen,
    SearchKeywordScreen: SearchKeywordScreen,
    WebViewScreen: WebViewScreen,
    SettingMenuScreen: SettingMenuScreen,
    SearchResultScreen: SearchResultScreen,
};

const StackRoutesHome = {
    ...StackRoutesMain,
    HomeScreen: HomeScreen,
};

// const StackRoutesSearch = {
//     ...StackRoutesMain,
// };

const StackRoutesFollow = {
    ...StackRoutesMain,
    FollowScreen: FollowScreen,
};

const StackRoutesCart = {
    ...StackRoutesMain,
    CartScreen: CartScreen,
};

const StackRoutesChats = {
    ...StackRoutesMain,
    ChatsScreen: ChatsScreen,
};

const StackRoutesSampleRequestHistoryScreen = {
    ...StackRoutesMain,
    SampleRequestHistoryScreen: SampleRequestHistoryScreen,
};

/**
 * Tabのボタン毎に作成
 * Tabごとのナビゲーション
 */
const HomeTabNav = createStackNavigator(
    StackRoutesHome,
    {
        initialRouteName: 'HomeScreen', // このTabで初期表示する画面
        headerMode: 'none',
    }
);

/**
 * Tabのボタン毎に作成
 * Tabごとのナビゲーション
 */
// const SearchTabNav = createStackNavigator(
//     StackRoutesSearch,
//     {
//         initialRouteName: 'SearchScreen', // このTabで初期表示する画面
//         headerMode: 'none',
//     }
// );

/**
 * Tabのボタン毎に作成
 * Tabごとのナビゲーション
 */
const FollowTabNav = createStackNavigator(
    StackRoutesFollow,
    {
        initialRouteName: 'FollowScreen', // このTabで初期表示する画面
        headerMode: 'none',
    }
);

/**
 * Tabのボタン毎に作成
 * Tabごとのナビゲーション
 */
const ChatTabNav = createStackNavigator(
    StackRoutesChats,
    {
        initialRouteName: 'ChatsScreen', // このTabで初期表示する画面
        headerMode: 'none',
    }
);

const CartTabNav = createStackNavigator(
    StackRoutesCart,
    {
        initialRouteName: 'CartScreen', // このTabで初期表示する画面
        headerMode: 'none',
    }
);

const SampleRequestHistoryNav = createStackNavigator(
    StackRoutesSampleRequestHistoryScreen,
    {
        initialRouteName: 'SampleRequestHistoryScreen', // このTabで初期表示する画面
        headerMode: 'none',
    }
);


/**
 * タブナビゲーションの画面設定
 */
const TabStack = createBottomTabNavigator(
    {
        HomeTabNav: HomeTabNav, // メイン遷移
        // SearchTabNav: SearchTabNav,
        FollowTabNav: FollowTabNav,
        CartTabNav: CartTabNav,
        ChatTabNav: ChatTabNav,
        SampleRequestHistoryNav,
    },
    {
        // initialRouteName: 'HomeScreen',
        defaultNavigationOptions: ({navigation }) => ({
            //@ts-ignore
            tabBarIcon: ({ focused, tintColor }) => getTabBarIcon(navigation, focused, tintColor),
            tabBarLabel: () => getTabBarLabel(navigation),
            tabBarOnPress: ({ navigation, defaultHandler }) => {
                ExpoUtil.tabName = navigation.state.routeName;

                defaultHandler()

                switch (navigation.state.routeName) {
                    case 'HomeTabNav':
                        break;
                    case 'SearchTabNav':
                        break;
                    case 'FollowTabNav':
                        break;
                    case 'ChatTabNav':
                        break;
                    case 'CartTabNav':
                        break;
                    default:
                        break;
                }

                // スクロール
                if (navigation.state.index === 0) {
                    // index == 0 -> タブバーから直接行けるページの場合

                    // @ts-ignore
                    const navigationInRoute = navigation.getChildNavigation(navigation.state.routes[0].key);
                    if (!!navigationInRoute && navigationInRoute.isFocused() && !!navigationInRoute.state.params && !!navigationInRoute.state.params.scrollToTop) {
                        navigationInRoute.state.params.scrollToTop();
                    }
                    // else {
                    //     navigation.navigate(navigation.state.key)
                    // }
                }
            },
        }),
        tabBarOptions: {
            activeTintColor: 'activeTintColor',
            inactiveTintColor: 'inactiveTintColor',
            showLabel: true,
            showIcon: true,
            keyboardHidesTabBar: true,
            style: {
                height: appS.footer.height,
                // paddingVertical: ExpoUtil.isIPad()? -50 : 0,
            },
            tabStyle: {
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
            },
            // labelStyle: {
            //     // marginLeft: 0
            //     marginTop: ExpoUtil.isIPad()? -50 : 0,
            // }
        },
    }
);

// @ts-ignore
TabStack.navigationOptions = ({ navigation }) => {
    return {
        headerShown: false,   // header を隠す
        headerTitle: '',
        headerLeft: () => null,
        headerRight: () => null,
        // headerTitle: HeaderUtil.getHeaderTitle(navigation),
        // headerLeft: () => Platform.OS === 'web' ? null : HeaderUtil.getHeaderLeftButton(navigation),
        // headerRight: () => Platform.OS === 'web' ? null : HeaderUtil.getHeaderRightButton(navigation),
    };
};

/**
 * タブに表示せず、Modalでも無いがNaviしたいものでフッターなしのもの
 */
const MainNav = createStackNavigator(
    {
        TAB: TabStack,   // Home画面含むタブNavigator
        // RecipeDetailScreen: RecipeDetailScreen,
        // ProductDetailScreen: ProductDetailScreen,
        // MakerDetailScreen: MakerDetailScreen,
        // NewsScreen: NewsScreen,
        // NewsDetailScreen: NewsDetailScreen,
        // ProductReviewScreen: ProductReviewScreen,
        // SelectSampleScreen: SelectSampleScreen,

        ProfileInit1Screen: ProfileInit1Screen,
        ProfileInit2Screen: ProfileInit2Screen,
        ProfileInit5Screen: ProfileInit5Screen,
        ProfileInit6Screen: ProfileInit6Screen,
        // TutorialScreen: TutorialScreen,
        ProfileScreen: ProfileScreen,
        SampleRequestScreen: SampleRequestScreen,
        SelectSampleScreen: SelectSampleScreen,
        ProductReviewScreen: ProductReviewScreen,
        SampleRequestHistoryScreen: SampleRequestHistoryScreen,
        SampleRequestReviewsScreen: SampleRequestReviewsScreen,
        SearchJobCategoryScreen: SearchJobCategoryScreen,
    },
    {
        // initialRouteName: 'HomeScreen', // このTabで初期表示する画面
        headerMode: 'none',
    }
);

/**
 * モーダルの画面設定
 */
const ModalStack = createStackNavigator(
    {
        Mains: MainNav, // メイン遷移
        // TAB: TabStack,
        // Drawers: DrawerStack, // メニューによる遷移

        // こっからモーダル画面
        TestScreen: TestScreen,
        LoginScreen: LoginScreen,
        SignUpScreen: SignUpScreen,
        ChatScreen: ChatScreen,
        RecipeDownloadScreen: RecipeDownloadScreen,
        BuySubscriptionScreen: BuySubscriptionScreenApp,
        ChangePasswordScreen: ChangePasswordScreen,
        ResetPasswordScreen: ResetPasswordScreen,
        WhatIsRecipeDownloadScreen: WhatIsRecipeDownloadScreen,
        ReportScreen: ReportScreen,
        WithdrawScreen: WithdrawScreen,
        ReferralCodeScreen: ReferralCodeScreen,
    },
    {
        mode: 'modal',
        headerMode: 'none',
    }
);

// /**
//  * メニューの画面遷移
//  */
// const DrawerStack = createDrawerNavigator(
//     {
//             ModalStack: {
//                 screen: ModalStack,
//                 navigationOptions: {
//                     drawerLabel: () => {return null}, // 余計なitemをメニューから消す方法
//                 },
//             },
//             // Home: HomeScreen,
//         },
//         {
//         // drawerWidth: 300,
//     },
// );

const WebConfig = createSwitchNavigator({
    TestScreen: {screen: TestScreen, path: 'TestScreen/:test/:ttt'},
    LoginScreen : {screen: LoginScreen, path: 'LoginScreen'},
    SignUpScreen : {screen: SignUpScreen, path: 'SignUpScreen'},
    HomeScreen : {screen: HomeScreen, path: ''},
    SearchScreen : {screen: SearchScreen, path: 'SearchScreen'},
    FollowScreen : {screen: FollowScreen, path: 'FollowScreen'},
    ChatsScreen : {screen: ChatsScreen, path: 'ChatsScreen'},
    CartScreen : {screen: CartScreen, path: 'CartScreen'},
    RecipeDetailScreen : {screen: RecipeDetailScreen, path: 'RecipeDetailScreen/:recipeId'},
    ProductDetailScreen : {screen: ProductDetailScreen, path: 'ProductDetailScreen/:productId'},
    MakerDetailScreen : {screen: MakerDetailScreen, path: 'MakerDetailScreen/:makerId'},
    SelectSampleScreen : {screen: SelectSampleScreen, path: 'SelectSampleScreen'},
    SampleRequestScreen : {screen: SampleRequestScreen, path: 'SampleRequestScreen'},
    ChatScreen : {screen: ChatScreen, path: 'ChatScreen'},
    ProfileInit1Screen : {screen: ProfileInit1Screen, path: 'ProfileInit1Screen'},
    ProfileInit2Screen : {screen: ProfileInit2Screen, path: 'ProfileInit2Screen'},
    ProfileInit5Screen : {screen: ProfileInit5Screen, path: 'ProfileInit5Screen'},
    ProfileInit6Screen : {screen: ProfileInit6Screen, path: 'ProfileInit6Screen'},
    ProfileScreen : {screen: ProfileScreen, path: 'ProfileScreen'},
    ProductReviewScreen: {screen: ProductReviewScreen, path: 'ProductReviewScreen'},
    RecipeDownloadScreen: {screen: RecipeDownloadScreen, path: 'RecipeDownloadScreen/:recipeId'},
    BuySubscriptionScreen: {screen: BuySubscriptionScreenApp, path: 'BuySubscriptionScreen'},
    ChangePasswordScreen: {screen: ChangePasswordScreen, path: 'ChangePasswordScreen'},
    ResetPasswordScreen: {screen: ResetPasswordScreen, path: 'ResetPasswordScreen'},
    WhatIsRecipeDownloadScreen: {screen: WhatIsRecipeDownloadScreen, path: 'WhatIsRecipeDownloadScreen'},
    ReportScreen: {screen: ReportScreen, path: 'ReportScreen'},
    NewsScreen: {screen: NewsScreen, path: 'NewsScreen'},
    NewsDetailScreen: {screen: NewsDetailScreen, path: 'NewsDetailScreen'},
    WebViewScreen: {screen: WebViewScreen, path: 'WebViewScreen'},
    SettingMenuScreen: {screen: SettingMenuScreen, path: 'SettingMenuScreen'},
    WithdrawScreen: {screen: WithdrawScreen, path: 'WithdrawScreen'},
    // SearchProductCategoryScreen : {screen: SearchProductCategoryScreen, path: 'SearchProductCategoryScreen'},
    SearchKeywordScreen : {screen: SearchKeywordScreen, path: 'SearchKeywordScreen'},
    ReferralCodeScreen: {screen: ReferralCodeScreen, path: 'ReferralCodeScreen'},
    SearchResultScreen: {screen: SearchResultScreen, path: 'SearchResultScreen'},
    SampleRequestHistoryScreen: {screen: SampleRequestHistoryScreen, path: 'SampleRequestHistoryScreen'},
    SampleRequestReviewsScreen: {screen: SampleRequestReviewsScreen, path: 'SampleRequestReviewsScreen'},
    SearchMenuCategoryScreen: {screen: SearchMenuCategoryScreen, path: 'SearchMenuCategoryScreen'},
    SearchJobCategoryScreen: {screen: SearchJobCategoryScreen, path: 'SearchJobCategoryScreen'},
},{
    initialRouteName:'HomeScreen',
});

const AppContainer = Platform.OS === 'web' ? createBrowserApp(WebConfig, {history: 'browser'}) : createAppContainer(ModalStack);
// const AppContainer = Platform.OS === 'web' ? createBrowserApp(WebConfig, {history: 'hash'}) : createAppContainer(ModalStack);


interface Props {
}

interface State {
    fontsLoaded: boolean
}

export default class App extends React.Component<Props, State> {

    constructor(props: any) {
        super(props)

        this.state = {
            fontsLoaded: false,
        }

        LoginUtil.loginFromStartApp();
        FooterDataDao.get().then(footerData => {
            if (footerData != null) {
                if (footerData.footer_chat_badge != null && footerData.footer_chat_badge == '1') {
                    AppG.badgeChat = 1;
                }
                if (footerData.footer_history_badge != null && footerData.footer_history_badge == '1') {
                    AppG.badgeHistory = 1;
                }
            }
        });
        this.loadFont();
    }

    /**
     * カスタムフォントのロード
     */
    async loadFont() {
        // web では sans-serif 指定（style.tsx）
        Font.loadAsync({
            'NotoSansJP-Black' : require('./src/resources/fonts/NotoSansJP-Black.otf'),
            'NotoSansJP-Bold' : require('./src/resources/fonts/NotoSansJP-Bold.otf'),
            'NotoSansJP-Light' : require('./src/resources/fonts/NotoSansJP-Light.otf'),
            'NotoSansJP-Medium' : require('./src/resources/fonts/NotoSansJP-Medium.otf'),
            'NotoSansJP-Regular' : require('./src/resources/fonts/NotoSansJP-Regular.otf'),
            'NotoSansJP-Thin' : require('./src/resources/fonts/NotoSansJP-Thin.otf'),
        }).then(() => {
            this.setState({fontsLoaded: true});
        });

        setTimeout(
            () => {
                this.setState({fontsLoaded: true});
            }
            ,2000);
    }

    componentDidMount(){
    }

    componentWillUnmount() {
    }

    render() {
        if (!this.state.fontsLoaded) {
            return (
                <IndicatorComponent
                    failTime={600}
                    isLoading={true}
                    reloadFunc={() => {
                        this.componentWillUnmount();
                        this.componentDidMount();
                    }}/>
            );
        }
        return (
            <Provider>
                <MenuProvider>
                    <AppContainer />
                </MenuProvider>
            </Provider>
        )
    }
}
