import Constants from "expo-constants";
import {Platform, PlatformIOSStatic, TouchableOpacity, View} from "react-native";
import React from "react";

class ExpoUtil {
    private _appState: any = null;

    /**
     * 開いてるタブ名
     */
    tabName: string = 'HomeTabNav';

    /** 画面をリロードさせるフラグ */
    private _shouldReloadTabs: {tabName: string, reload: boolean}[]
        = [{tabName: 'HomeTabNav', reload: false},
        {tabName: 'SearchTabNav', reload: false},
        {tabName: 'FollowTabNav', reload: false},
        {tabName: 'ChatTabNav', reload: false},
        {tabName: 'CartTabNav', reload: false}, ];

    /** productionかどうか */
    private _isProduction: boolean = true;

    private _contextMenu: any = null;

    /**
     * Expo か React Native を判断
     */
    isExpo = (): boolean => {
        // react native だと manifest が null になる
        return Constants.manifest !== null;
    }

    /**
     * iPad か否か
     */
    isIPad = (): boolean => {
        if (Platform.OS == "ios") {
            const platformIOS = Platform as PlatformIOSStatic;
            return platformIOS.isPad;
        }
        return false;
    }

    /**
     * 右クリックを格納
     * @private
     */
    _setContextMenu = () => {
        this._contextMenu = document.oncontextmenu;
    };

    /**
     * リロードさせるフラグをONにする
     * 引数でリロード対象を設定する
     *
     * @param status all の時は全タブをリロード対象にする
     * @param status self の時は今いるタブをリロード対象にする
     */
    setShouldReloadAppTab = (status: 'all' | 'self' | '' = '' ) => {
        if (Platform.OS == 'web') {
            return;
        }

        let _tabs: {tabName: string, reload: boolean}[] = [];
        this._shouldReloadTabs.forEach((tab: {tabName: string, reload: boolean}) => {
            if (
                status ==''
                && tab.tabName == this.tabName
                && tab.tabName != 'FollowTabNav'
                && tab.tabName != 'ChatTabNav'
                && tab.tabName != 'CartTabNav'
                ) {
                tab = {tabName: tab.tabName, reload: false};
            } else if(
                status == 'self'
                && tab.tabName == this.tabName
            ) {
                tab = {tabName: tab.tabName, reload: true};
            } else {
                tab = {tabName: tab.tabName, reload: true};
            }
            _tabs.push(tab);
        });
        this._shouldReloadTabs = _tabs;
    }

    /**
     * 渡した引数でリロードフラグONのタブをリロードさせる用にセット（リロードはそのリロードするタブを次に選択したとき）
     * @param reloadFunc
     */
    doReloadAppTab = (reloadFunc: () => void) => {
        if (Platform.OS == 'web') {
            return;
        }

        let _tab = this._shouldReloadTabs.find((t: {tabName: string, reload: boolean}) => t.tabName == this.tabName);
        if (_tab != null && _tab.reload) {
            reloadFunc();
        }

        // 更新して詰め直し
        let _tabs: {tabName: string, reload: boolean}[] = [];
        this._shouldReloadTabs.forEach((tab: {tabName: string, reload: boolean}) => {
            if (tab.tabName == this.tabName) {
                tab = {tabName: tab.tabName, reload: false};
            } else {
                tab = {tabName: tab.tabName, reload: tab.reload};
            }
            _tabs.push(tab);
        });
        this._shouldReloadTabs = _tabs;
    };

    /**
     * プロダクションかどうかセット
     * @param produciton
     * @private
     */
    setProduction = (produciton: boolean) => {
        this._isProduction = produciton;
    };

    /**
     * プロダクションかどうかゲット
     * @private
     */
    isProduction = () => {
        return this._isProduction;
    }

    /**
     * Webの右クリック復活(componentWillUnmount)
     */
    useRightClick = () => {
        if (Platform.OS == 'web') {
            document.oncontextmenu = this._contextMenu;
        }
    };

    /**
     * Webの時右クリック禁止(componentDidMount)
     */
    unUseRightClick = () => {
        if (Platform.OS == 'web') {
            this._setContextMenu();
            document.oncontextmenu = function () {return false;}
        }
    };

    /**
     * 戻るボタンの処理
     * @param navigation
     */
    goBack = (navigation: any) => {
        if (Platform.OS === 'web') {
            history.go(-1);
        } else {
            navigation.goBack();
        }
    }

    /**
     * フォアグラウンドとバックグラウンドのハンドラーで呼ぶ関数
     * @param nextAppState
     */
    handleAppStateChange = (nextAppState: any) => {
        // 使うときはこれを HomeScreen の componentDidMount に書く
        // if (Platform.OS != 'web') {
        //     // フォアグラウンドとバックグラウンドのハンドラー
        //     AppState.removeEventListener('change', ExpoUtil.handleAppStateChange);
        //     AppState.addEventListener('change', (nextAppState) => {
        //         ExpoUtil.handleAppStateChange(nextAppState)
        //     });
        // }
        // これを HomeScreen の componentWillUnmount に書く
        // if (Platform.OS != 'web') {
        //     AppState.removeEventListener('change', ExpoUtil.handleAppStateChange);
        // }

        // JSリロードしないとデバック時にハンドラーが複数登録されてしまうことに注意
        if (this._appState != null && this._appState.match(/inactive|background/) && nextAppState === 'active') {
            // console.log('App has come to the foreground!');
        } else if (
            (this._appState == null && nextAppState === 'inactive')
            || (this._appState != null && this._appState.match(/active|foreground/) && nextAppState === 'inactive')) {
            // console.log('App has gone to the background!');
        }
        this._appState = nextAppState;
    };

    // Pagesの中でスクロールを安定させるため
    _wrap= (contents: any) => {
        if (Platform.OS == 'web') {
            return (
                <View>
                    {contents}
                </View>
            );
        }

        return (
            <TouchableOpacity activeOpacity={1}>
                {contents}
            </TouchableOpacity>
        )
    }

}

export default new ExpoUtil();
