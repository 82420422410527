import {BaseDao} from "./BaseDao";
// @ts-ignore
import {log} from '../../../../../environment';
import {SampleProductRequestDataEntityModel} from "../../entityModels/SampleProductRequestDataEntityModel";

class SampleProductRequestDataDao extends BaseDao<SampleProductRequestDataEntityModel> {
    _getModel(): SampleProductRequestDataEntityModel {
        return new SampleProductRequestDataEntityModel();
    }
}

// new 付なのでシングルトン
export default new SampleProductRequestDataDao();
