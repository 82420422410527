import Constants from "expo-constants";
import AlertUtil from "./AlertUtil";
import {Platform} from "react-native";
import {getEnvVars} from "../../../environment";
import axios from "axios";
import LinkUtil from "./LinkUtil";

class VersionUtil {

    /**
     * アップデートバージョンをチェック
     */
    updateVersionAlert = () => {
        if (Platform.OS == 'web') {
            return;
        }

        // @ts-ignore
        const {REACT_APP_DOMAIN_URL, REACT_APP_STORE_URL_IOS, REACT_APP_STORE_URL_ANDROID} = getEnvVars();
        let storeUrl: string = '';
        let version: string = '';
        if (Platform.OS == 'ios') {
            // @ts-ignore
            version = Constants.expoConfig.ios.buildNumber.replaceAll('.', '');
            storeUrl = `${REACT_APP_STORE_URL_IOS}`;
        }
        if (Platform.OS == 'android') {
            // @ts-ignore
            version = Constants.expoConfig.android.versionCode.toString();
            storeUrl = `${REACT_APP_STORE_URL_ANDROID}`;
        }

        axios.get(`${REACT_APP_DOMAIN_URL}/latest_app_version.json`, {
            headers: {
                'Accept': '*/*',
                'Access-Control-Allow-Origin': '*',
                // 'Content-Type': 'application/json'
            }
        })
            .then(
                response => {
                    let latestVersion: string = '';
                    if (Platform.OS == 'ios') {
                        if (response.data != null && response.data.ios != null && response.data.ios.updateAble != null) {
                            if (!response.data.ios.updateAble) {
                                // アップデート不可（何らかの理由で最新版を公開停止したとかの場合）
                                return;
                            }
                        }
                        if (response.data != null && response.data.ios != null && response.data.ios.version != null) {
                            latestVersion = response.data.ios.version.replaceAll('.', '');
                        }
                    }
                    if (Platform.OS == 'android') {
                        if (response.data != null && response.data.android != null && response.data.android.updateAble != null) {
                            if (!response.data.android.updateAble) {
                                // アップデート不可（何らかの理由で最新版を公開停止したとかの場合）
                                return;
                            }
                        }
                        if (response.data != null && response.data.android != null && response.data.android.version != null) {
                            latestVersion = response.data.android.version;
                        }
                    }

                    // 最新バージョンの方が新しかったら
                    if (parseInt(version) < parseInt(latestVersion)) {
                        AlertUtil.ok('アプリのアップデート', '最新版に更新してご利用ください', () => {
                            LinkUtil.openLinkWithDefaultBrowser(storeUrl);
                        });
                    }
                })
            .catch(
                err => {
                    // log.debug(err)
                }
            )
    }
}

export default new VersionUtil();
