import React, {Component} from 'react';
import {Platform, Text, View} from 'react-native';
import {appColors, appFont, appS} from "../../resources/styles/style";
import AppG from "../util/AppG";
import ScreenSizeUtil from "../util/ScreenSizeUtil";
import {PullDownIconComponent} from "./PullDownIconComponent";
import RNPickerSelect from "react-native-picker-select";

interface Props {
    showTitle: boolean, // 参考価格の文言の表示
    pickChangeLeft: (value: any) => void
    pickChangeRight: (value: any) => void
}

interface State {
    cost: {from: number, to: number}
}

export class CostComponent extends Component<Props, State> {

    // Propsのデフォルト値
    static defaultProps = {
        showTitle: false,
    };

    constructor(props: any) {
        super(props);
        this.state = {
            cost: {from: 0, to: 99999999},
        };
    }

    /**
     * リセット処理
     */
    reset = () => {
        this.setState({cost: {from: 0, to: 99999999}});
    }

    render() {
        let _width = AppG.window.width;
        if (ScreenSizeUtil.isWebSize()) {
            _width = appS.webMainContentsSize.width;
        }
        _width = _width - appS.margins.side * 2;

        const _betweenWidth = 30;
        const _pickerHeight = 40;
        const _pickerWidth = (_width - appS.margins.side * 2 - _betweenWidth) / 2;




        return (
            <View style={{width: _width, margin: appS.margins.side}}>
                {this.props.showTitle && (
                    <View style={{flex: 1, flexDirection: "column"}}>
                        <Text
                            style={{
                                color: appColors.fontBlack,
                                fontFamily: appFont.family.default,
                                fontSize: appFont.size.checkboxText,
                                marginTop: appS.margins.side,
                                marginBottom: appS.margins.side
                            }}>参考価格</Text>
                    </View>
                )}

                <View style={{flexDirection: "row", flex: 1}}>
                    <View style={{
                        width: _pickerWidth,
                        height: _pickerHeight,
                    }}>
                        <RNPickerSelect
                            style={appS.picker}
                            // placeholder={{ label: '¥0', value: 0, key: 0 }}
                            placeholder={{}}
                            onValueChange={(value) => {
                                this.state.cost.from = value;
                                this.setState({cost: this.state.cost});
                                this.props.pickChangeLeft(value);
                                // let _value = value;
                                // if (value == 0) {
                                //     _value = undefined;
                                // }
                                // this.setState({filterCostFrom: _value});
                                // this._loadRankingRecipes(0, this._recipeRankingNumForTab, true);
                            }}
                            // value={this.state.cost.from}
                            items={[
                                { label: '¥0', value: 0, key: 0 },
                                { label: '¥50', value: 50, key: 50 },
                                { label: '¥100', value: 100, key: 100 },
                                { label: '¥200', value: 200, key: 200 },
                                { label: '¥300', value: 300, key: 300 },
                                { label: '¥400', value: 400, key: 400 },
                                { label: '¥500', value: 500, key: 500 },
                                { label: '¥600', value: 600, key: 600 },
                                { label: '¥700', value: 700, key: 700 },
                                { label: '¥800', value: 800, key: 800 },
                                { label: '¥900', value: 900, key: 900 },
                                { label: '¥1000', value: 1000, key: 1000 },
                                { label: '¥2000', value: 2000, key: 2000 },
                                { label: '¥3000', value: 3000, key: 3000 },
                                { label: '¥4000', value: 4000, key: 4000 },
                                { label: '¥5000', value: 5000, key: 5000 },
                            ]}
                            Icon={() => (<PullDownIconComponent/>)}
                        />
                    </View>
                    <Text style={{
                        width: _betweenWidth,
                        fontSize: 14,
                        color: appColors.fontBlack,
                        fontFamily: appFont.family.default,
                        // alignSelf: 'center',
                        textAlignVertical: 'center',
                        textAlign: "center",
                        marginTop: 8,
                    }}>〜</Text>
                    <View style={{width: _pickerWidth, height: _pickerHeight}}>
                        <RNPickerSelect
                            style={appS.picker}
                            // placeholder={{ label: '上限無し', value: undefined }}
                            placeholder={{}}
                            // value={this.state.cost.to}
                            onValueChange={(value) => {
                                this.state.cost.to = value;
                                this.setState({cost: this.state.cost});
                                this.props.pickChangeRight(value);
                                // let _value = value;
                                // if (value == '上限無し') {
                                //     _value = undefined;
                                // }
                                // this.setState({filterCostTo: _value});
                                // this._loadRankingRecipes(0, this._recipeRankingNumForTab, true);
                            }}
                            items={[
                                { label: '上限無し', value: 99999999, key: 99999999 },
                                { label: '¥50', value: 50, key: 50 },
                                { label: '¥100', value: 100, key: 100 },
                                { label: '¥200', value: 200, key: 200 },
                                { label: '¥300', value: 300, key: 300 },
                                { label: '¥400', value: 400, key: 400 },
                                { label: '¥500', value: 500, key: 500 },
                                { label: '¥600', value: 600, key: 600 },
                                { label: '¥700', value: 700, key: 700 },
                                { label: '¥800', value: 800, key: 800 },
                                { label: '¥900', value: 900, key: 900 },
                                { label: '¥1000', value: 1000, key: 1000 },
                                { label: '¥2000', value: 2000, key: 2000 },
                                { label: '¥3000', value: 3000, key: 3000 },
                                { label: '¥4000', value: 4000, key: 4000 },
                                { label: '¥5000', value: 5000, key: 5000 },
                            ]}
                            Icon={() => (<PullDownIconComponent/>)}
                        />
                    </View>
                </View>
            </View>
        );
    }
}
