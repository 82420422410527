import React from 'react'
import {Dimensions, Platform, SafeAreaView, ScrollView, Text, View} from 'react-native'
// @ts-ignore
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scrollview';
import {BaseScreen} from "./BaseScreen";
import LoginUtil from "../util/LoginUtil";
import moment from "moment";
import LoginDataDao from "../data/dao/local/LoginDataDao";
import {CustomHeaderComponentSmall} from "../components/small/CustomHeaderComponentSmall";
import {ChatViewComponent} from "../components/ChatViewComponent";
import ChatConstant from "../constants/ChatConstant";
import {ChatScreenModel} from "../data/models/screen/ChatScreenModel";
import {ChatsDao} from "../data/dao/firestore/ChatsDao";
import {Chats} from "../data/firestoreEntities/Chats";
import {LoginDataEntityModel} from "../data/entityModels/LoginDataEntityModel";
import {MessagesDao} from "../data/dao/firestore/MessagesDao";
import {IndicatorComponent} from "../components/IndicatorComponent";
import AlertUtil from "../util/AlertUtil";
import {appColors, appFont, appS} from "../../resources/styles/style";
import {Chat, ChatApiFactory, UserUserAttributeEnum} from "../data/network/swagger-gen";
import AppGlobalDataUtil from "../util/AppG";
import AppG from "../util/AppG";
import {AxiosResponse} from "axios";
import ValidateUtil from "../util/ValidateUtil";
// @ts-ignore
import {log} from '../../../environment';
import ExpoUtil from "../util/ExpoUtil";
import AnalyticsUtil, {AnalyticsEventName, DOpenEventName} from "../util/firebase/AnalyticsUtil";
import ScreenSizeUtil from "../util/ScreenSizeUtil";
import {CustomHeaderComponentWide} from "../components/wide/CustomHeaderComponentWide";
import {WideScreenAdComponent} from "../components/wide/WideScreenAdComponent";
import {AuthUtil} from "../util/firebase/AuthUtil";
import {SpacerComponent} from "../components/SpacerComponent";
import * as Notifications from "expo-notifications";

import {WideSubTitleComponent} from "../components/wide/WideSubTitleComponent";
import {NoUserImageForMessageComponent} from "../components/NoUserImageForMessageComponent";
import MyAppUtil from "../util/MyAppUtil";
import {WideScreenSNSComponent} from "../components/wide/WideScreenSNSComponent";
import LinkUtil from "../util/LinkUtil";
import {NothingMessageImageComponent} from "../components/NothingMessageImageComponent";


interface Props {
    navigation: any, // ナビゲーション用
}

interface State {
    resizeWindow: boolean,  // 値に意味はなし。windowサイズの拡大縮小をrenderに送るためのもの
    isLoaded: boolean,
    isChatsLoaded: boolean,
    chats: {
        chatId: string,
        companyName: string,
        image: string,  // url
        lastDate: Date,
        messageType: string, // ChatConstant.MESSAGE_TYPE()
        text: string,
        unread: number,
        onPress: () => void,
    }[],
}

export class ChatsScreen extends BaseScreen<Props, State> {
    _resizeTimer = setInterval(() => {
        // window 拡大縮小を反映させる
        AppG.window = Dimensions.get('window');
        if (AppG.window.width != AppG.windowBefore.width || AppG.window.height != AppG.windowBefore.height) {
            this.setState({'resizeWindow': true});
            AppG.windowBefore = Dimensions.get('window');
        }
        LoginUtil.setRefreshedScreenFlg(false);
    }, 100);

    _flameSize = ScreenSizeUtil.isWebSize()? AppG.window.width / 10 : 16;    // 左右の margin
    _marginSize = ScreenSizeUtil.isWebSize()? 0 : appS.margin.size;

    _loginData = new LoginDataEntityModel();

    // スクロール用
    _noLoginScrollTab: any = null;
    _scrollTab: any = null;

    // _testChatHash = 'GxpCVELPJoC7hVgXq4xx';
    // _testUserId =  '1';
    _chats: {
        chatId: string,
        companyName: string,
        image: string,  // url
        lastDate: Date,
        messageType: string, // ChatConstant.MESSAGE_TYPE()
        text: string,
        unread: number,
        onPress: () => void,
    }[] = [];

    _browserBackListener = (e: any) => {

    };

    // @ts-ignore
    constructor(props) {
        super(props);
        this.state = {
            resizeWindow: true,  // 値に意味はなし。windowサイズの拡大縮小をrenderに送るためのもの
            isLoaded: false,
            isChatsLoaded: false,
            chats: [],
        };
    }

    /**
     * 画面開いたときのアナリティクス送信
     */
    _sendPvAnalytics = () => {
        AnalyticsUtil.sendAnalytics(
            DOpenEventName.openPage,
            AnalyticsEventName.openPage,  // アナリティクスのイベント名
            'ChatsScreen',
            null,
            null,
            null,
            null,
        );
    }

    componentDidMount() {

        if (Platform.OS == 'web') {
            window.addEventListener('popstate', this._browserBackListener);
        }

        if (Platform.OS != 'web') {
            // スクロールviewのセット
            this.props.navigation.setParams({
                scrollToTop: () => {
                    if (this._noLoginScrollTab != null) {
                        this._noLoginScrollTab.scrollTo({ x: 0, y: 0, animated: true });
                    } else if (this._scrollTab != null) {
                        this._scrollTab.scrollTo({x: 0, y: 0, animated: true});
                    }
                }
            })
        }

        LoginUtil.interruptOpenScreen(this.props.navigation,
            () => {
                LoginDataDao.get().then(loginData => {
                    // Web 戻るでも呼ばれる (Footerにあるやつはappでも実行するようにする)
                    // Analytics
                    this._sendPvAnalytics();

                    if (Platform.OS != 'web') {
                        // アイコンバッヂの更新
                        Notifications.setBadgeCountAsync((AppG.badgeChat == null? 0 : AppG.badgeChat) + (AppG.badgeHistory == null? 0 : AppG.badgeHistory));
                    }
                    AppG.getFooterBadge();

                    // URL Scheme で外部リンクから起動したときの処理と、画面遷移でこの画面を開いたときの処理
                    LoginUtil.setDefaultListener(
                        this.props.navigation,
                        () => {
                            // Webはここにこない
                            if (Platform.OS != 'web') {
                                // Analytics
                                this._sendPvAnalytics();
                            }

                            ExpoUtil.doReloadAppTab(() => {
                                this._chats = [];
                                this._loadChats();
                                AppG.getFooterBadge();
                            });
                        });
                    if (loginData != null) {
                        this._loginData = loginData;
                        this._loadChats();
                        this.setState({'isLoaded': true});
                    }
                });
            });
    }

    componentWillUnmount () {
        clearTimeout(this._resizeTimer);
        if (Platform.OS === 'web') {
            window.removeEventListener('popstate', this._browserBackListener);
        }
        LoginUtil.removeDefaultListener(this.props.navigation);
    };

    componentDidUpdate () {
        // レンダー完了時に呼ばれる
    }

    /**
     * パラムの取得
     * @private
     */
    _getParams = () => {
        // let searchEventQuery: EventSearchModel = UrlUtil.getParamModel(new EventSearchModel(), this.props.navigation);
        // // 応募締め切りが今日まで
        // searchEventQuery.apply_end_to_dt = moment(new Date()).format('YYYY-MM-DD');
        // return searchEventQuery;
    };

    /**
     * チャットのロード
     * @private
     */
    _loadChats = async () => {
        this.setState({'isChatsLoaded': false});

        // チャット一覧を取得
        const getChats = ChatApiFactory(AppGlobalDataUtil.getConfiguration(), AppGlobalDataUtil.getBasePath())
            .getChats(0, 1000).then((chatsRes: AxiosResponse<Chat[]>) => {
                log.debug(`--------------- chatsRes --------------`);
                log.debug(chatsRes.data);

                if (chatsRes == null || ValidateUtil.isEmptyArray(chatsRes.data)) {
                    this.setState({'isChatsLoaded': true});
                    return;
                }

                const _chats = chatsRes.data;

                _chats?.forEach(chatModel => {
                    log.debug(`---------------- chatModel --------------`);
                    log.debug(chatModel);

                    // チャットの取得 from FireStore
                    let successChatsFunc = (chat: Chats) => {
                        log.debug(`---------------- chat --------------`);
                        log.debug(chat);

                        if (chatModel.userId == null) {
                            // 退会ユーザーの場合
                            this._chats.push({
                                chatId: chatModel.id!,
                                companyName: 'XX会社',
                                image: '',  // url
                                // @ts-ignore
                                lastDate: new Date(),
                                messageType: '',
                                text: '該当の企業は退会しました',
                                // @ts-ignore
                                unread: 0,
                                onPress: () => {AlertUtil.alert('該当企業は退会されました', 'メッセージを確認することは出来ません。')},
                            });
                            this.setState({'chats': this._chats});
                            this.setState({'isChatsLoaded': true});
                        } else {
                            // メッセージの取得 from FireStore Message.data 型
                            let successMessageFunc = (message: any) => {
                                log.debug(`---------------- message --------------`);
                                log.debug(message);

                                if (this._chats.some(chat => chat.chatId == chatModel.id)) {
                                    // 既に一覧に表示しているチャットは無視
                                    return;
                                }

                                let text = 'メッセージは空です。';
                                let messageType = ChatConstant.MESSAGE_TYPE().TEXT;
                                //@ts-ignore
                                let lastDate = moment(chat['createdAt']).toDate();
                                if (message == null) {
                                    // まだやりとりが一回も無いとき
                                    this.setState({'isChatsLoaded': true});
                                } else {
                                    // 既にメッセージがあるとき
                                    text = message.text;
                                    messageType = message.messageType;
                                    lastDate = moment(parseInt(message.updatedAt)).toDate();
                                    switch (message.messageType) {
                                        case ChatConstant.MESSAGE_TYPE().IMAGE:
                                            text = message.url;
                                            break;
                                        // case ChatConstant.MESSAGE_TYPE().PDF:
                                        //     text = message.url;
                                        //     break;
                                    }
                                }

                                const _userHash = AuthUtil.getUid();

                                this._chats.push({
                                    chatId: chatModel.id!,
                                    companyName: chatModel.makerName == null ? '' : chatModel.makerName,
                                    image: chatModel.makerImage == null ? '' : chatModel.makerImage,  // url
                                    // @ts-ignore
                                    lastDate: lastDate,
                                    messageType: messageType,
                                    text: text,
                                    // @ts-ignore
                                    unread: chat[`unreadMessages${_userHash!}`] == null ? 0 : chat[`unreadMessages${_userHash!}`].length,
                                    onPress: () => {
                                        let chatScreenModel = new ChatScreenModel();
                                        chatScreenModel.chatId = chatModel.id!;
                                        chatScreenModel.makerId = chatModel.makerId.toString();
                                        this.props.navigation.navigate('ChatScreen', chatScreenModel);
                                    },
                                });

                                log.debug(`---------------- _chats --------------`);
                                log.debug(this._chats);

                                this.setState({'chats': this._chats});
                                this.setState({'isChatsLoaded': true});
                            };

                            // 最後のメッセージを取得
                            MessagesDao.instance.getLast(successMessageFunc, {chat: chatModel.id!});
                        }
                    }
                    // チャットの取得
                    ChatsDao.instance.get(successChatsFunc, chatModel.id!)

                });

            });

        // 実行
        await Promise.all([getChats]);
    }

    /**
     * 一般ユーザーの場合
     */
    _renderNormalUser = () => {
        // 飲食人の方の場合
        return (
            <KeyboardAwareScrollView
                ref={(noLoginScrollTab: any) => { this._noLoginScrollTab = noLoginScrollTab; }}
                style={[{flex: 1, flexDirection: 'column'}]}
            >
                    <View
                        style={[{flex: 1, flexDirection: 'column', justifyContent: 'center'}]}>
                        <View style={[{flexDirection: 'column'}]}>
                            {/*未ログイン*/}
                            {AppG.user == null && (
                                <View style={{flex: 1, backgroundColor: AppG.user == null ? appColors.noUserBackground : appColors.white}}>
                                    <NoUserImageForMessageComponent navigation={this.props.navigation} />
                                </View>
                            )}
                            {(AppG.user != null && AppG.user.userAttribute == UserUserAttributeEnum.Normal) && (
                                <View style={{flex: 1, backgroundColor: AppG.user == null ? appColors.noUserBackground : appColors.transparent}}>
                                    <SpacerComponent height={appS.header.bottomMargin}/>
                                    <Text style={{
                                        marginLeft: appS.margins.side*2,
                                        fontFamily: appFont.family.bold,
                                        fontSize: appFont.size.subTopic,
                                        color: appColors.fontBlack,
                                        fontWeight: "bold"
                                    }}>飲食関係者向けサービスとなります。</Text>
                                    <Text style={{
                                        marginLeft: appS.margins.side*2,
                                        fontFamily: appFont.family.bold,
                                        fontSize: appFont.size.subTopic,
                                        color: appColors.fontBlack,
                                        fontWeight: "bold"
                                    }}> 一般の方はご利用になれません。</Text>
                                </View>
                            )}
                        </View>
                    </View>
            </KeyboardAwareScrollView>
        );
    }

    _renderMain = () => {
        if (AppG.user == null || AppG.user.userAttribute == UserUserAttributeEnum.Normal) {
            return this._renderNormalUser();
        }

        return (
            <KeyboardAwareScrollView
                // refreshControl={
                //     <RefreshControl
                //         refreshing={this.state.isEventLoading}
                //         onRefresh={() => {this._loadEvents(true)}}
                //     />
                // }
                ref={(scrollTab: any) => { this._scrollTab = scrollTab; }}
                contentContainerStyle={{ flexGrow: 1 }}>
                {!this.state.isChatsLoaded && (
                    <IndicatorComponent
                        failTime={60}
                        isLoading={true}
                        reloadFunc={() => {
                            this.componentWillUnmount();
                            this.componentDidMount();
                        }}/>
                )}
                {(this.state.isChatsLoaded && (this.state.chats == null || this.state.chats.length == 0)) && (
                    <View key={`chat_view_no_map`}>
                        <ScrollView style={{backgroundColor: appColors.noUserBackground, height: AppG.window.height}}>
                            <NothingMessageImageComponent navigation={this.props.navigation} />
                        </ScrollView>
                    </View>
                )}
                {ScreenSizeUtil.isWebSize() && (<SpacerComponent height={16}/>)}
                {this.state.isChatsLoaded && this.state.chats && this.state.chats.map((map, index) => (
                        <View key={`chat_view_${index}`}>
                            <ChatViewComponent
                                key={`chat_view_component_${index}`}
                                companyName={map.companyName}
                                image={map.image}
                                lastDate={map.lastDate}
                                messageType={map.messageType}
                                text={map.text}
                                unread={map.unread}
                                onPress={map.onPress}
                            />
                        </View>
                    )
                )}
                <SpacerComponent height={96}/>
            </KeyboardAwareScrollView>
        );
    };

    /**
     * SPサイズ
     */
    _renderSmall = () => {
        const {navigation} = this.props;
        return (
            <SafeAreaView
                style={[{height: AppG.window.height, flex: 1, flexDirection: 'column', backgroundColor: appColors.white}]}
                onLayout={(event) => {
                    // window 拡大縮小を反映させる
                    AppG.window = Dimensions.get('window');
                    this.setState({'resizeWindow': true});
                }}
            >
                <CustomHeaderComponentSmall
                    navigation={this.props.navigation}
                    title={'メッセージ一覧'}
                    textColor={appColors.black}
                    barColor={appColors.white}
                    borderBottomColor={appColors.borderGray}
                    leftButton={MyAppUtil.selectWebLogoSp()}
                    rightButton={MyAppUtil.selectWebMenuSp()}
                />
                <View style={{flex: 1, backgroundColor: (this.state.isChatsLoaded && (this.state.chats == null || this.state.chats.length == 0))? appColors.noUserBackground : appColors.backBaseColor}}>
                    {this._renderMain()}
                </View>
            </SafeAreaView>
        );
    }

    /**
     * WEBサイズ
     */
    _renderWide = () => {
        const {navigation} = this.props;
        return (
            <SafeAreaView
                style={[{height: AppG.window.height, flex: 1, flexDirection: 'column', backgroundColor: appColors.white}]}
                onLayout={(event) => {
                    // window 拡大縮小を反映させる
                    AppG.window = Dimensions.get('window');
                    this.setState({'resizeWindow': true});
                }}
            >
                <CustomHeaderComponentWide
                    navigation={navigation}
                    searchBar={true}
                />

                <View style={{
                    flex: 1,
                    flexDirection: 'row',
                    alignSelf: 'center',
                    backgroundColor: appColors.backBaseColor,
                }}>
                    <ScrollView
                        horizontal  // 下階層のスクロールイベントを拾えるようにしている
                        style={{
                            width: AppG.window.width,
                            height: AppG.window.height - appS.header.webHeight,
                        }}
                    >
                        <View style={[{flex: 1, flexDirection: 'column', width: AppG.window.width}]}>
                            <KeyboardAwareScrollView style={[{flex: 1, flexDirection: 'column'}]}>
                                <View style={[{flex: 1, flexDirection: ScreenSizeUtil.isWebSize()? 'row': 'column', justifyContent: 'center'}]}>
                                    <View style={[{flexDirection: 'column', backgroundColor: AppG.user == null ? appColors.noUserBackground : (this.state.isChatsLoaded && (this.state.chats == null || this.state.chats.length == 0))? appColors.noUserBackground : appColors.backBaseColor}]}>
                                        <View style={{width: ScreenSizeUtil.isWebSize()? appS.webMainContentsSize.width : AppG.window.width}}>
                                            {ScreenSizeUtil.isWebSize() && <WideSubTitleComponent title={'メッセージ'} width={appS.webMainContentsSize.width} />}
                                            {this._renderMain()}
                                        </View>
                                    </View>
                                    {ScreenSizeUtil.isWebSize() && (
                                        <View style={{marginLeft: appS.margins.webBetweenMargin, width: appS.webSideContentsSize.width}}>
                                            <WideScreenAdComponent navigation={this.props.navigation}/>
                                            <WideScreenSNSComponent navigation={this.props.navigation}/>
                                        </View>
                                    )}
                                </View>
                            </KeyboardAwareScrollView>
                        </View>
                    </ScrollView>
                </View>
            </SafeAreaView>
        );
    }


    /** 描画 */
    render() {
        if (!this.state.isLoaded) {
            return (
                <View style={{flex: 1}}>
                    <IndicatorComponent
                        failTime={60}
                        isLoading={true}
                        reloadFunc={() => {
                            this.componentWillUnmount();
                            this.componentDidMount();
                        }}/>
                </View>
            );
        }

        LinkUtil.addReturnFalseToATag();
        if (ScreenSizeUtil.isWebSize()) {
            // WEBサイズ
            return this._renderWide();
        } else {
            // SPサイズ
            return this._renderSmall();
        }

    }


}
