import React from 'react'
import {
    Dimensions,
    Image,
    Keyboard,
    Platform,
    SafeAreaView,
    Text,
    TextInput,
    TouchableOpacity,
    TouchableWithoutFeedback,
    View
} from 'react-native'
import {appColors, appFont, appS} from '../../resources/styles/style'
import {BaseScreen} from "./BaseScreen";
import LoginUtil from "../util/LoginUtil";
import {IndicatorComponent} from "../components/IndicatorComponent";
// @ts-ignore
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scrollview';
import LoginDataDao from "../data/dao/local/LoginDataDao";
import {LoginDataEntityModel} from "../data/entityModels/LoginDataEntityModel";
import {CustomHeaderComponentSmall} from "../components/small/CustomHeaderComponentSmall";
import UrlUtil from "../util/UrlUtil";
// @ts-ignore
import {log} from '../../../environment';
import {ProfileInitScreenModel} from "../data/models/screen/ProfileInitScreenModel";
import {TopicTitleComponent} from "../components/TopicTitleComponent";
import ValidateUtil from "../util/ValidateUtil";
import {ValidationErrorMessageComponent} from "../components/ValidattionErrorMessageComponent";
import AppG from "../util/AppG";
import AppGlobalDataUtil from "../util/AppG";
import ScreenSizeUtil from "../util/ScreenSizeUtil";
import {CustomHeaderComponentWide} from "../components/wide/CustomHeaderComponentWide";
import {SpacerComponent} from "../components/SpacerComponent";
import AnalyticsUtil, {AnalyticsEventName, DOpenEventName} from "../util/firebase/AnalyticsUtil";
import {User, UserApiFactory, UserUserPlanEnum} from "../data/network/swagger-gen";
import {AxiosResponse} from "axios";
import AlertUtil from "../util/AlertUtil";
import I18n from "../../resources/language/i18n";
import LinkUtil from "../util/LinkUtil";

interface Props {
    navigation: any, // ナビゲーション用
}

interface State {
    resizeWindow: boolean,  // 値に意味はなし。windowサイズの拡大縮小をrenderに送るためのもの
    isLoaded: boolean,

    // コンテンツ
    user: User | null,
    profileScreenModel: ProfileInitScreenModel,

    // input
    // ログイン用メールアドレス
    name: {
        text: string,
        errorText: string,
        isError: boolean,
    },
    // パスワード
    nameKana: {
        text: string,
        errorText: string,
        isError: boolean,
    },
}

export class ProfileInit2Screen extends BaseScreen<Props, State> {
    _resizeTimer = setInterval(() => {
        // window 拡大縮小を反映させる
        AppG.window = Dimensions.get('window');
        if (AppG.window.width != AppG.windowBefore.width || AppG.window.height != AppG.windowBefore.height) {
            this.setState({'resizeWindow': true});
            AppG.windowBefore = Dimensions.get('window');
        }
        LoginUtil.setRefreshedScreenFlg(false);
    }, 100);

    _loginData = new LoginDataEntityModel();

    _flameSize = appS.flame.size;    // 左右の margin

    // このScreenへ渡すパラム
    _param: ProfileInitScreenModel = new ProfileInitScreenModel();

    _browserBackListener = (e: any) => {

    };

    constructor(props: any) {
        super(props);
        this.state = {
            resizeWindow: true,  // 値に意味はなし。windowサイズの拡大縮小をrenderに送るためのもの
            isLoaded: false,
            user: null,
            profileScreenModel: new ProfileInitScreenModel(),
            name: {
                text: '',
                errorText: '',
                isError: false,
            },
            nameKana: {
                text: '',
                errorText: '',
                isError: false,
            },
        };
    }

    /**
     * 画面開いたときのアナリティクス送信
     */
    _sendPvAnalytics = () => {
        AnalyticsUtil.sendAnalytics(
            DOpenEventName.openPage,
            AnalyticsEventName.openPage,  // アナリティクスのイベント名
            'ProfileInit2Screen',
            null,
            null,
            null,
            null,
        );
    }

    componentDidMount() {
        if (Platform.OS == 'web') {
            window.addEventListener('popstate', this._browserBackListener);
        }

        // URL Scheme で外部リンクから起動したときの処理
        // LoginUtil.setDefaultListener(this.props.navigation);

        LoginUtil.interruptOpenScreen(this.props.navigation,
            () => {
                LoginDataDao.get().then(loginData => {
                    // Web 戻るでも呼ばれる
                    if (Platform.OS == 'web') {
                        // Analytics
                        this._sendPvAnalytics();
                    }

                    // URL Scheme で外部リンクから起動したときの処理と、画面遷移でこの画面を開いたときの処理
                    LoginUtil.setDefaultListener(this.props.navigation, () => {
                        // Webはここにこない
                        if (Platform.OS != 'web') {
                            // Analytics
                            this._sendPvAnalytics();
                        }
                    });
                    if (loginData != null) {
                        this._loginData = loginData;
                        this._loadMasters().then(() => {});
                        this._loadContents().then(() => {});
                        this.setState({'isLoaded': true});
                    }
                });
            });

        this.setState({isLoaded: true});
    }

    componentWillUnmount () {
        clearTimeout(this._resizeTimer);
        if (Platform.OS === 'web') {
            window.removeEventListener('popstate', this._browserBackListener);
        }
        LoginUtil.removeDefaultListener(this.props.navigation);
    };

    componentDidUpdate () {
        // レンダー完了時に呼ばれる
    }

    /**
      * パラムの取得
      * @private
      */
    _getParams = () => {
        let profileScreenModel: ProfileInitScreenModel = UrlUtil.getParamModel(new ProfileInitScreenModel(), this.props.navigation);
        return profileScreenModel;
    };

    /**
     * マスターのロード
     * @private
     */
    _loadMasters = async () => {
    };

    /**
     * コンテンツのロード
     * @private
     */
    _loadContents = async () => {
        this.setState({profileScreenModel: this._getParams()});

        // Userの取得
        const getUser = await UserApiFactory(AppGlobalDataUtil.getConfiguration(), AppGlobalDataUtil.getBasePath())
            .getUser()
            .then((userRes: AxiosResponse<User>) => {
                if (userRes.data != null && userRes.data.id != null && userRes.data.userHash != null) {
                    let _user: User = userRes.data;
                    if (ValidateUtil.isEmptyExact(_user.mail) && !ValidateUtil.isEmptyExact(this._loginData.mail)) {
                        _user.mail = this._loginData.mail!;
                    }
                    this.setState({user: _user});
                }
            });

        // 実行
        await Promise.all([getUser]);
    };

    /**
     * エラーなら true
     */
    _isError = () => {
        let _error: boolean = false;
        if (ValidateUtil.isErrorUserName(this.state.profileScreenModel.name).isError) {
            _error = true;
        }
        if (ValidateUtil.isErrorUserNameKana(this.state.profileScreenModel.nameKana).isError) {
            _error = true;
        }
        return _error;
    };

    /**
     * 次のページへ移動
     */
    _goNextPage = () => {
        if (!this._isError()) {
            if (this.state.profileScreenModel.userAttribute === 'cook') {
                this._goNextPageByCook();
            } else {
                this._goNextPageByNormal();
            }
        }
    };

    /**
     * 次のページへ移動（料理人）
     */
    _goNextPageByCook = async () => {
        this.props.navigation.navigate('ProfileInit5Screen', this.state.profileScreenModel);
    };

    /**
     * 保存（一般人）
     */
    _goNextPageByNormal = async () => {
        if (!this._isError()) {
            this.setState({profileScreenModel: this.state.profileScreenModel});

            const addOrUpdateUser = UserApiFactory(AppGlobalDataUtil.getConfiguration(), AppGlobalDataUtil.getBasePath())
                .addOrUpdateUser(
                    this.state.user!.id,    // id: number,
                    this.state.user!.mail,  // mail: string,
                    this.state.profileScreenModel.userAttribute!,    // userAttribute: string,
                    this.state.profileScreenModel.name!, // name: string,
                    this.state.profileScreenModel.nameKana!, // nameKana: string,
                    1,   // notificationMail: number,
                    UserUserPlanEnum.Free, //userPlan: string,
                    undefined,  //userHash?: string,
                    undefined,   // gender: string,
                    undefined,  // birthday: string,
                    undefined,   //nameRestaurant?: string,
                    undefined,   // postalCode?: string,
                    undefined, // restaurantLargeLocationId?: number,
                    undefined,    // restaurantMediumLocationId?: number,
                    undefined,  // restaurantState?: string,
                    undefined,   // restaurantCity?: string,
                    undefined,  // restaurantStreetAddress?: string,
                    undefined, // tell?: string,
                    undefined,  // restaurantLargeFormatId?: number,
                    undefined,     /// restaurantMediumFormatId?: number,
                    undefined, // numOfRestaurantId?: number,
                    undefined, // majorRestaurantAreaId?: number,
                    undefined,  // lunchService?: number,
                    undefined,  // customerUnitPriceId?: number,
                    undefined, // numberOfSeats?: number,
                    undefined,  // site?: string,
                    undefined,// userFreeCoin?: number,
                    undefined,// image?: string
                ).then(() => {
                    // Analytics
                    AnalyticsUtil.sendAnalytics(
                        DOpenEventName.signUp,
                        AnalyticsEventName.signUp,  // アナリティクスのイベント名
                        null,
                        null,
                        null,
                        null,
                        null,
                    );

                    // 保存したUserにAppGのUserを更新
                    UserApiFactory(AppGlobalDataUtil.getConfiguration(), AppGlobalDataUtil.getBasePath())
                        .getUser()
                        .then((userRes: AxiosResponse<User>) => {
                            if (userRes.data != null && userRes.data.id != null && userRes.data.userHash != null) {
                                let _user: User = userRes.data;
                                if (ValidateUtil.isEmptyExact(_user.mail) && !ValidateUtil.isEmptyExact(this._loginData.mail)) {
                                    _user.mail = this._loginData.mail!;
                                }
                                AppG.user = _user;
                                AppG.setAnalyticJpData();

                                AlertUtil.alert('登録完了', 'ユーザー情報を登録しました');
                                this.props.navigation.navigate('HomeScreen');
                            } else {
                                // 通信エラー
                                AlertUtil.alert(`${I18n.t('errorNetworkTitle')}`, `${I18n.t('errorNetwork')}`);
                                log.debug(`---------------Network Error (User.add.update.appG)--------------------`);
                            }
                        });

                })
                .catch((e) => {
                    // 通信エラー
                    AlertUtil.alert(`${I18n.t('errorNetworkTitle')}`, `${I18n.t('errorNetwork')}`);
                    log.debug(`---------------Network Error (User.add.init)--------------------`);
                    log.debug(e);
                });

            // 実行
            await Promise.all([addOrUpdateUser]);
        }
    };

    _renderMain = () => {
        return (
            <View style={[{flex: 1, flexDirection: 'column'}]}>

                <View style={[{flex: 1, flexDirection: 'column'}]}>
                    <SpacerComponent height={appS.header.bottomMargin} />
                    {ScreenSizeUtil.isWebSize() && (<Text style={{fontFamily: appFont.family.bold, flex: 1, margin: 32, color: appColors.black, fontSize: appFont.size.large, textAlign: 'center', fontWeight: "bold"}}>プロフィール登録</Text>)}
                    <View style={{alignItems: 'center', justifyContent: 'center'}}>
                        <Image source={this.state.profileScreenModel.userAttribute == "normal"?
                            require('../../resources/images/profile_init_dots_2_2.png') : require('../../resources/images/profile_init_dots_2.png')
                        }
                               style={[{
                                   marginTop: 5,
                                   width: 104,
                                   height: 8,
                               }]}
                               resizeMode='contain'/>
                        <SpacerComponent height={appS.margins.side} />
                    </View>
                    <TopicTitleComponent title={'お名前は？'} />
                    <SpacerComponent height={appS.margins.side} />
                    {/*氏名*/}
                    <TopicTitleComponent title={'氏名'} />
                    <View style={[appS.marginCenter]}>
                        <TextInput
                            style={[appS.inputText, {}]}
                            autoCapitalize={"none"}
                            placeholder={'　氏名'}
                            returnKeyType={'done'}
                            placeholderTextColor={appColors.gray}
                            value={this.state.name.text}
                            onChangeText={text => {
                                this.setState({name: ValidateUtil.isErrorUserName(text)});
                                this.state.profileScreenModel.name = text;
                                this.setState({profileScreenModel: this.state.profileScreenModel});
                            }}
                        />
                        <ValidationErrorMessageComponent
                            text={this.state.name.errorText}
                            isError={this.state.name.isError}
                        />
                    </View>

                    <SpacerComponent height={appS.margins.side} />
                    {/*氏名（ふりがな）*/}
                    <TopicTitleComponent title={'氏名（ふりがな）'} />
                    <View style={[appS.marginCenter]}>
                        <TextInput
                            style={[appS.inputText, {}]}
                            autoCapitalize={"none"}
                            placeholder={'　氏名（ふりがな）'}
                            returnKeyType={'done'}
                            placeholderTextColor={appColors.gray}
                            value={this.state.nameKana.text}
                            onChangeText={text => {
                                this.setState({nameKana: ValidateUtil.isErrorUserNameKana(text)});
                                this.state.profileScreenModel.nameKana = text;
                                this.setState({profileScreenModel: this.state.profileScreenModel});
                            }}
                        />
                        <ValidationErrorMessageComponent
                            text={this.state.nameKana.errorText}
                            isError={this.state.nameKana.isError}
                        />
                    </View>

                </View>

                {ScreenSizeUtil.isWebSize() && (<SpacerComponent height={32}/>)}

                {/*次へ*/}
                <TouchableOpacity
                    style={[
                        appS.marginCenter,
                        {
                            width: appS.buttonSize.width,
                            height: appS.buttonSize.height,
                            marginBottom: 40,
                            borderWidth: 1,
                            borderColor: appColors.transparent,
                            backgroundColor:  this._isError() ? appColors.gray : appColors.buttonOrange,
                            alignItems: 'center',
                            justifyContent: 'center',
                            alignSelf: 'center',
                        }
                    ]}
                    disabled={this._isError()}
                    onPress={() => {
                        this._goNextPage();
                    }}
                >
                    <Text style={{
                        fontFamily: appFont.family.bold,
                        color: appColors.white,
                        fontSize: appFont.size.buttonText,
                        fontWeight: "bold"
                    }}>次へ</Text>
                </TouchableOpacity>
            </View>
        );
    };

    _renderWide = () => {
        return (
            <SafeAreaView
                style={[{height: AppG.window.height, flex: 1, flexDirection: 'column', backgroundColor: appColors.white}]}
                onLayout={(event) => {}}
            >
                <CustomHeaderComponentWide logoOnly={true} navigation={this.props.navigation}/>

                <View style={{flex: 1, flexDirection: 'row'}}>
                    <KeyboardAwareScrollView style={[{flex: 1, flexDirection: 'column'}]}>
                        <View style={[{flex: 1, width: 700, alignSelf: 'center'}]}>
                            {this._renderMain()}
                        </View>
                    </KeyboardAwareScrollView>
                </View>

            </SafeAreaView>
        );
    }

    _renderSmall = () => {
        const _render = () => {
            return (
                <SafeAreaView
                    style={[{flex: 1, backgroundColor: appColors.white}]}
                    onLayout={(event) => {}}
                >
                    <CustomHeaderComponentSmall
                        navigation={this.props.navigation}
                        title={'プロフィール登録'}
                        textColor={appColors.black}
                        barColor={appColors.white}
                        borderBottomColor={appColors.white}
                        leftButton={'none'}
                        rightButton={'none'}
                    />
                    <View style={{flex: 1, backgroundColor: appColors.backBaseColor}}>
                        {this._renderMain()}
                    </View>
                </SafeAreaView>
            );
        }

        if (Platform.OS == 'web') {
            return _render();
        }

        return (
            <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
                {_render()}
            </TouchableWithoutFeedback>
        );
    }

    /** 描画 */
    render() {
        const {navigation} = this.props;
        if (!this.state.isLoaded) {
            return (
                <View style={{flex: 1}}>
                    <IndicatorComponent
                        failTime={60}
                        isLoading={true}
                        reloadFunc={() => {
                            this.componentWillUnmount();
                            this.componentDidMount();
                        }}/>
                </View>
            );
        }

        LinkUtil.addReturnFalseToATag();
        if (ScreenSizeUtil.isWebSize()) {
            // WEBサイズ
            return this._renderWide();
        } else {
            // SPサイズ
            return this._renderSmall();
        }

    };


}
