import React, {Component} from 'react';
import {ActivityIndicator, View} from 'react-native';
import {appColors} from '../../resources/styles/style'
import AppG from "../util/AppG";

interface Props {
}

interface State {
}

export class LoadingFullPageComponent extends Component<Props, State> {


    render() {
        return (
            <View style={{zIndex: 101, position: 'absolute', backgroundColor: appColors.opacityGray, flexDirection: 'row'}}>
                <View
                    style={{width: AppG.window.width, height: AppG.window.height + 60}}
                >
                    <ActivityIndicator
                        animating = {true}
                        color = {appColors.indicatorWhite}
                        size = "large"
                        style={[{zIndex: 103, marginTop: AppG.window.height / 2}]}/>
                </View>
            </View>
        );
    }
}
