import {Image, Platform} from "react-native";
// @ts-ignore
import isURL from 'validator/lib/isURL';
import axios from "axios";

// @ts-ignore
import {getEnvVars, log} from '../../../environment';

class ImageUtil {

    /**
     * 画像のサイズを取得
     * @param url
     * @param callback
     * @return {width: img.width, height: img.height}
     */
    getSize = (url: string, callback: (width: number, height: number) => void) => {
        Image.getSize(url, callback);
        // const img = new Image();
        // img.src = url;//高さと幅を取得したいURLを入力
        //
        // return {width: img.width, height: img.height};
    }

    /**
     * 画像ダウンロード(WEBのみ)
     * @param name
     * @param url
     * @param index
     */
    downloadImage = (name: string, url: string, index: number) => {
        if (Platform.OS != 'web') {
            return;
        }

        axios.get(url, {
                responseType: 'arraybuffer',
                headers: {
                    'Accept': '*/*',
                    'Access-Control-Allow-Origin': '*',
                    // 'Content-Type': 'application/json'
                }
            })
            .then(
                response => {
                    // 疑似ダウンロードリンク作成
                    // let mimeType = 'image/png'
                    // 変換
                    // let blobData = new Blob([response.data], { type: mimeType })
                    let blobData = new Blob([response.data], { type: response.headers['content-type'] })
                    const url = URL.createObjectURL(blobData)

                    // const url = URL.createObjectURL(blob);
                    const a = document.createElement(`a`);
                    document.body.appendChild(a);
                    a.download = `${name}_recipe_${index+1}.jpg`;
                    // a.target='_blank';
                    a.href = url;
                    setTimeout(() => {
                        a.click();
                        a.remove();
                        setTimeout(() => {
                            window.URL.revokeObjectURL(url);
                        }, 300);
                    }, 300);

                    // ファイルダウンロードさせたり
                })
            .catch(
                err => {
                    log.debug(err)
                }
            )
    }

    /**
     * Image のセット
     * @param result カメラやライブラリーからの result
     * @return {viewImage: viewImage, postImage: postImage}
     */
    setImage = ({result}: { result: any }): {viewImage: string, postImage: string, imageType: string} | null => {
        if (Platform.OS == 'web') {
            // Webブラウザ
            if (!result.cancelled) {
                // result
                // {cancelled: false, uri: "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEASABIAAD…AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA//9k=", width: 0, height: 0}
                const splitFileUri = result.uri.split('/');
                const imageType = splitFileUri[1].split(';')[0];  // png
                const viewImage: string = result.uri; // View用
                const postImage: string = result.uri;   // POST用

                return {viewImage: viewImage, postImage: postImage, imageType: imageType};

                // blob にしたい場合こうする(使わない)
                // fetch(result.uri).then(res => {
                //     res.blob().then(blob => {
                //         this.state.member.img_url = blob;
                //     })
                // });
            }
        } else {
            // スマホ
            if (!result.cancelled) {
                const splitUri = result.uri.split('/');
                const splitFileName = splitUri[splitUri.length - 1].split('.'); // fileName.png.split
                const imageType = splitFileName[splitFileName.length - 1];  // png
                const base64StartStr = `data:image/${imageType};base64,`;
                const viewImage: string = result.uri; // View用
                const postImage: string = `${base64StartStr}${result.base64}`;    // POST用

                return {viewImage: viewImage, postImage: postImage, imageType: imageType};

                // blob にしたい場合こうする(使わない)
                // fetch(result.uri).then(res => {
                //     res.blob().then(blob => {
                //         this.state.member.img_url = blob;
                //     })
                // });
            }
        }
        return null;
    };


    /**
     * イメージが空文字 or Http の時、送信除去する
     * @private
     */
    setSendImageForSave = (image: string | null) => {
        if (
            image != null
            && image != ''
            && isURL(image)
        ) {
            // http なら null
            image = null;
        }
        if (image == '') {
            // 空文字 なら null
            image = null;
        }
        return image;
    };

}

export default new ImageUtil();
