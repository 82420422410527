import {Linking, Platform} from 'react-native';
import ValidateUtil from "./ValidateUtil";
import url from "url";
import {ProductDetailScreenModel} from "../data/models/screen/ProductDetailScreenModel";
// @ts-ignore
import {log} from '../../../environment';
import {RecipeDetailScreenModel} from "../data/models/screen/RecipeDetailScreenModel";
import {MakerDetailScreenModel} from "../data/models/screen/MakerDetailScreenModel";
import {Special, SpecialApiFactory} from "../data/network/swagger-gen";
import AppG from "./AppG";
import {AxiosResponse} from "axios";
import AnalyticsUtil, {AnalyticsEventName, DOpenEventName} from "./firebase/AnalyticsUtil";
import SpecialUtil from "./SpecialUtil";
import NavigationUtil from "./NavigationUtil";

class RedirectUtil {

    /**
     * (Webのみ)シェアボタンなどエンコーディングされたURLを叩いて開いた場合のリダイレクト
     */
    redirectEncodeUrl = () => {
        if (Platform.OS == 'web' && location.href.match(/%23/)) {
            // LINEのシェア(web)でエンコーディングした#を元に戻してリダイレクト処理
            let newUrl = location.href.replace(/%23/g, '#');
            newUrl = newUrl.replace(/%3F/g, '?');
            newUrl = newUrl.replace(/%3D/g, '=');
            Linking.openURL(newUrl.slice(0,-1));
        }
        if (Platform.OS == 'web' && location.href.match(/\/#/) && location.href.match(/recipeId/)) {
            // LINEのシェア(web)でエンコーディングした#を元に戻してリダイレクト処理
            let newUrl = location.href.replace(/\/#/g, '');
            newUrl = newUrl.replace(/\?recipeId=/g, '\/');
            Linking.openURL(newUrl);
        }
        if (Platform.OS == 'web' && location.href.match(/\/#/) && location.href.match(/productId/)) {
            // LINEのシェア(web)でエンコーディングした#を元に戻してリダイレクト処理
            let newUrl = location.href.replace(/\/#/g, '');
            newUrl = newUrl.replace(/\?productId=/g, '\/');
            Linking.openURL(newUrl);
        }
        if (Platform.OS == 'web' && location.href.match(/\/#/) && location.href.match(/makerId/)) {
            // LINEのシェア(web)でエンコーディングした#を元に戻してリダイレクト処理
            let newUrl = location.href.replace(/\/#/g, '');
            newUrl = newUrl.replace(/\?makerId=/g, '\/');
            Linking.openURL(newUrl);
        }
    }

    /**
     * URL スキームから開いた時の処理（Web View のリダイレクト）
     * @param urlScheme
     * @param navigation
     * @private
     */
    // @ts-ignore
    _openFromUrlScheme = (urlScheme, navigation) => {
        if (Platform.OS == 'web') {
            // https://app.recibase.net/#/ProductDetailScreen?productId=65
            // WEBの場合URLそのまま開くが、リダイレクトが必要な場合有
            if (ValidateUtil.isEmptyExact(location.href)) {
                return;
            }
            // # が %23 になってる場合がありその場合リダイレクトが必要  (Linking.openURL(url))で開いた場合
            this.redirectEncodeUrl();
            return;
        } else {
            // アプリ
            // app.json
            // {
            //     "expo": {
            //         "scheme": "myapp"
            //     }
            // }
            // https://tech.maricuru.com/entry/2018/11/13/000000#%E3%83%87%E3%82%A3%E3%83%BC%E3%83%97%E3%83%AA%E3%83%B3%E3%82%AF%E3%81%A8%E3%81%AF
            const parsedUrl = url.parse(urlScheme, true);
            // protocol == exp: or recibase: -> app
            // exp://exp.host/@recibase2020/ReciBASE?release-channel=adhoc  // adhocのダウンロード（使わない）
            // exp://localhost:19000/#/ProductDetailScreen?productId=65 // シミュレーター時
            // recibase://app.recibase.net/#/ProductDetailScreen?productId=65   // 本番
            if (parsedUrl.protocol == 'exp:' || parsedUrl.protocol == 'recibase:' || parsedUrl.protocol == 'recibase-dev:') {
                // log.debug('------------------ DeepLink ----------------');
                // log.debug(parsedUrl);
                //  {"protocol":"exp:", or "recibase"
                //  "slashes":true,
                //  "auth":null,
                //  "host":"localhost:19000", or "app.recibase.net"
                //  "port":"19000", or null
                //  "hostname":"localhost", or "app.recibase.net"
                //  "hash": null,
                //  "search":"","query":{},
                //  "pathname":"/",
                //  "path":"/",
                //  "href":"exp://localhost:19000/ProductDetailScreen/65"}

                // 任意の画面を開く処理
                let _str = parsedUrl.href.replace(`${parsedUrl.protocol}//${parsedUrl.host}/`, ""); // ProductDetailScreen/65
                let sArray = _str.split("/");
                if (ValidateUtil.isEmptyArray(sArray)) {
                    // スクリーン無ければ
                    return;
                }
                let _param = '';
                if (sArray.length > 0) {
                    _param = sArray[1];
                }
                let screen = sArray[0];  // screen = ['ProductDetailScreen', '65']
                if (typeof screen == 'undefined' || screen == null || screen == '') {
                    navigation.navigate('HomeScreen');
                    return;
                }
                switch (screen) {
                    case 'RecipeDetailScreen':
                        // exp://localhost:19000/RecipeDetailScreen/65
                        // recibase://app.recibase.net/RecipeDetailScreen/65
                        let recipeDetailScreenModel: RecipeDetailScreenModel = new RecipeDetailScreenModel();
                        recipeDetailScreenModel.recipeId = _param;
                        // @ts-ignore
                        if (Platform.OS == 'web') {
                            navigation.navigate('RecipeDetailScreen', recipeDetailScreenModel);
                        } else {
                            navigation.push('RecipeDetailScreen', recipeDetailScreenModel);
                        }
                        return;
                    case 'ProductDetailScreen':
                        // exp://localhost:19000/ProductDetailScreen/65
                        // recibase://app.recibase.net/ProductDetailScreen/65
                        let productDetailScreenModel: ProductDetailScreenModel = new ProductDetailScreenModel();
                        productDetailScreenModel.productId = _param;
                        // @ts-ignore
                        if (Platform.OS == 'web') {
                            navigation.navigate('ProductDetailScreen', productDetailScreenModel);
                        } else {
                            navigation.push('ProductDetailScreen', productDetailScreenModel);
                        }
                        return;
                    case 'MakerDetailScreen':
                        // exp://localhost:19000/MakerDetailScreen/65
                        // recibase://app.recibase.net/MakerDetailScreen/65
                        let makerDetailScreenModel: MakerDetailScreenModel = new MakerDetailScreenModel();
                        makerDetailScreenModel.makerId = _param;
                        // @ts-ignore
                        if (Platform.OS == 'web') {
                            navigation.navigate('MakerDetailScreen', makerDetailScreenModel);
                        } else {
                            navigation.push('MakerDetailScreen', makerDetailScreenModel);
                        }
                        return;
                    case 'HomeScreen':
                        navigation.navigate('HomeScreen');
                        return;
                    case 'LoginScreen':
                        navigation.navigate('LoginScreen');
                        return;
                    case 'SignUpScreen':
                        navigation.navigate('SignUpScreen');
                        return;
                    case '#':
                        const _screen = sArray[1].split('?');
                        switch (_screen[0]) {
                            case 'HomeScreen_Ranking':
                                navigation.navigate('HomeScreen');
                                AppG.tabIndex = 4;
                                NavigationUtil.homeRefresh();
                                return;
                            case 'SpecialDetailScreen':
                                const _params = _screen[1].split('=');
                                _param = _params[1];
                                SpecialApiFactory(AppG.getConfiguration(), AppG.getBasePath())
                                    .getSpecialFindAll().then((specialsRes: AxiosResponse<Special[]>) => {
                                    if (specialsRes != null && !ValidateUtil.isEmptyArray(specialsRes.data)) {
                                        let special: Special | undefined = specialsRes.data.find(s => `${s.id}` == _param);
                                        if (special != undefined) {
                                            AnalyticsUtil.sendAnalytics(
                                                DOpenEventName.notification,
                                                `${AnalyticsEventName.notification}_special_${special == null? '' : special.id}`,  // アナリティクスのイベント名
                                                'SpecialScreen',
                                                null,
                                                null,
                                                null,
                                                special == null ? 'バックナンバー' : null,
                                                special == null ? 'https://wp.recibase.net/' : null,
                                                special
                                            );
                                            // アナリティクスを送信したにする
                                            AppG.sentAnalyticsFromNotificationAlready = true;
                                        } else {
                                            // アナリティクスを送信したにする
                                            AppG.sentAnalyticsFromNotificationAlready = true;
                                        }

                                    }
                                });

                                SpecialApiFactory(AppG.getConfiguration(), AppG.getBasePath())
                                    .getSpecialFindAll().then((specialsRes: AxiosResponse<Special[]>) => {
                                    if (specialsRes != null && !ValidateUtil.isEmptyArray(specialsRes.data)) {
                                        let special: Special | undefined = specialsRes.data.find(s => `${s.id}` == _param);
                                        if (special == undefined) {
                                            navigation.navigate('HomeScreen');
                                            return;
                                        }

                                        SpecialUtil.openSpecial(special, navigation);
                                    }
                                });
                                return;
                        }
                    default:
                        return;
                }
            }
            return;
        }

    };
}

export default new RedirectUtil();
