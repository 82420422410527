import React from 'react'
import {
    ActivityIndicator,
    Dimensions,
    Image,
    Platform,
    SafeAreaView,
    ScrollView,
    Text,
    TouchableOpacity,
    View
} from 'react-native'
import {appColors, appFont, appS} from '../../resources/styles/style'
import {BaseScreen} from "./BaseScreen";
import LoginUtil from "../util/LoginUtil";
import {IndicatorComponent} from "../components/IndicatorComponent";
// @ts-ignore
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scrollview';
import LoginDataDao from "../data/dao/local/LoginDataDao";
import {LoginDataEntityModel} from "../data/entityModels/LoginDataEntityModel";
import {
    ChatApiFactory,
    Maker,
    MakerApiFactory,
    Product,
    ProductApiFactory,
    Recipe,
    RecipeApiFactory,
} from "../data/network/swagger-gen";
import AppGlobalDataUtil from "../util/AppG";
import AppG from "../util/AppG";
import {AxiosResponse} from "axios";
import {CustomHeaderComponentSmall} from "../components/small/CustomHeaderComponentSmall";
import UrlUtil from "../util/UrlUtil";
// @ts-ignore
import isURL from 'validator/lib/isURL';
import ValidateUtil from "../util/ValidateUtil";
import {MakerDetailScreenModel} from "../data/models/screen/MakerDetailScreenModel";
import {LinkComponent} from "../components/LinkComponent";
import LinkUtil from "../util/LinkUtil";
// @ts-ignore
import {getEnvVars, log} from '../../../environment';
import ExpoUtil from "../util/ExpoUtil";
import ScreenSizeUtil from "../util/ScreenSizeUtil";
import {CustomHeaderComponentWide} from "../components/wide/CustomHeaderComponentWide";
import {SpacerComponent} from "../components/SpacerComponent";
import {WideScreenAdComponent} from "../components/wide/WideScreenAdComponent";
import AnalyticsUtil, {AnalyticsEventName, DOpenEventName, ShareBtnName} from "../util/firebase/AnalyticsUtil";
import {ChatScreenModel, InquiryStatusEnum} from "../data/models/screen/ChatScreenModel";

import {ShareComponent} from "../components/ShareComponent";
import WebTagUtil from "../util/WebTagUtil";
import {TabBorderComponent} from "../components/TabBorderComponent";
import {RecipeSearchResultListComponent} from "../components/RecipeSearchResultListComponent";
import {ProductSearchResultListComponent} from "../components/ProductSearchResultListComponent";
import SearchDataDao from "../data/dao/local/SearchDataDao";
import {SearchScreenModel} from "../data/models/screen/SearchScreenModel";
import {WideSubTitleComponent} from "../components/wide/WideSubTitleComponent";
import MyAppUtil from "../util/MyAppUtil";
import {WideScreenSNSComponent} from "../components/wide/WideScreenSNSComponent";

interface Props {
    navigation: any, // ナビゲーション用
}

interface State {
    resizeWindow: boolean,  // 値に意味はなし。windowサイズの拡大縮小をrenderに送るためのもの
    isLoaded: boolean,

    listType: 'recipe' | 'product', // 画面下に表示してる項目

    // コンテンツ
    maker: Maker | null,   // メーカー
    recipes: Recipe[], // このメーカーのレシピ一覧
    products: Product[],    // このメーカーの商品一覧
    isLoadResultRecipes: boolean,
    isLoadResultProducts: boolean,
}

export class MakerDetailScreen extends BaseScreen<Props, State> {
    _resizeTimer = setInterval(() => {
        // window 拡大縮小を反映させる
        AppG.window = Dimensions.get('window');
        if (AppG.window.width != AppG.windowBefore.width || AppG.window.height != AppG.windowBefore.height) {
            this.setState({'resizeWindow': true});
            AppG.windowBefore = Dimensions.get('window');
        }
        LoginUtil.setRefreshedScreenFlg(false);
    }, 100);

    _loginData = new LoginDataEntityModel();

    _flameSize = ScreenSizeUtil.isWebSize()? AppG.window.width / 10 : 16;    // 左右の margin
    _marginSize = ScreenSizeUtil.isWebSize()? 0 : appS.margin.size;

    _contentsLoadCount = 20;

    // このScreenへ渡すパラム
    _param: MakerDetailScreenModel = new MakerDetailScreenModel();

    // タブ
    _tabPage: any = null;
    _tabClicked: boolean = false;
    _tabIndex: number = 0;
    /** tab 本体 */
    _tabRef: any = null;
    _tabPress = (tabNumber: number) => {
        if (this._tabIndex == tabNumber) {
            return;
        }
        if (tabNumber == 0) {
            this.setState({listType: 'recipe'});
        } else {
            this.setState({listType: 'product'});
        }
        this._tabClicked = true;
        this._tabIndex = tabNumber;

        if (this._tabRef != null) {
            this._tabRef.redrawView(tabNumber);
        }

        this.setState({'resizeWindow': true});
    };
    _tabs: Tab[] = [
        {
            index: 0,    // 0 〜
            title: 'レシピ',
            onPress: () => {this._tabPress(0)},
            color: appColors.recipeHeader,
            unselectColor: appColors.tabGray,
            backgroundColor: appColors.white,
            unselectBackgroundColor: appColors.white,
            badge: 0,
        },
        {
            index: 1,    // 0 〜
            title: '商品',
            onPress: () => {this._tabPress(1)},
            color: appColors.productHeader,
            unselectColor: appColors.tabGray,
            backgroundColor: appColors.white,
            unselectBackgroundColor: appColors.white,
            badge: 0,
        },
    ];

    _browserBackListener = (e: any) => {

    }

    constructor(props: any) {
        super(props);
        this.state = {
            resizeWindow: true,  // 値に意味はなし。windowサイズの拡大縮小をrenderに送るためのもの
            isLoaded: false,
            listType: 'recipe',
            maker: null,
            recipes: [],
            products: [],
            isLoadResultRecipes: true,
            isLoadResultProducts: true,
        };
    }

    /** データロードされるまで再送信を繰り返すPvAnalytics */
    _sentPvAnalyticsTimer: any;
    /**
     * 画面開いたときのアナリティクス送信
     */
    _sendPvAnalytics = () => {
        this._sentPvAnalyticsTimer = setInterval(() => {
            if (this.state.maker != null) {
                clearInterval(this._sentPvAnalyticsTimer);
                AnalyticsUtil.sendAnalytics(
                    DOpenEventName.openPage,
                    AnalyticsEventName.openPage,  // アナリティクスのイベント名
                    'MakerDetailScreen',
                    null,
                    null,
                    this.state.maker,
                    null,
                );
            }
        },  100);
    }

    /** データロードされるまで再送信を繰り返すPvAnalytics */
    _sentMakerAnalyticsTimer: any;
    /**
     * メーカーのアナリティクス送信
     */
    _sendMakerAnalytics = () => {
        this._sentMakerAnalyticsTimer = setInterval(() => {
            if (this.state.maker != null) {
                clearInterval(this._sentMakerAnalyticsTimer);
                AnalyticsUtil.sendAnalytics(
                    null,
                    `maker_${this.state.maker.id}`,  // アナリティクスのイベント名
                    'MakerDetailScreen',
                    null,
                    null,
                    this.state.maker,
                    null,
                );
            }
        },  100);
    }

    componentDidMount() {
        /** 初回表示フラグ */
        let _initLoadFlg = true;
        if (Platform.OS == 'web') {
            window.addEventListener('popstate', this._browserBackListener);
        }

        // URL Scheme で外部リンクから起動したときの処理
        // LoginUtil.setDefaultListener(this.props.navigation);

        LoginUtil.interruptOpenScreen(this.props.navigation,
            () => {
                LoginDataDao.get().then(loginData => {
                    // Web 戻るでも呼ばれる
                    if (Platform.OS == 'web') {
                        // Analytics
                        this._sendPvAnalytics();
                        this._sendMakerAnalytics();
                    }
                    AppG.getFooterBadge();

                    // URL Scheme で外部リンクから起動したときの処理と、画面遷移でこの画面を開いたときの処理
                    LoginUtil.setDefaultListener(
                        this.props.navigation,
                        () => {
                            if (Platform.OS != 'web') {
                                // Analytics
                                this._sendPvAnalytics();
                                this._sendMakerAnalytics();
                            }
                            //
                            // if (_initLoadFlg) {
                            //     _initLoadFlg = false;
                            //     return;
                            // }
                            // this.setState({'isLoaded': false});
                            // this._loadContents().then(() => {
                            //     this.setState({'isLoaded': true});
                            // });
                            //
                            ExpoUtil.doReloadAppTab(() => {
                                AppG.getFooterBadge();
                            });
                        },
                        () => {
                            // this.setState({'isLoaded': false});
                        });
                    if (loginData != null) {
                        this._loginData = loginData;
                    }
                    this._loadMasters().then(() => {});
                    this._loadContents().then(() => {
                        this.setState({'isLoaded': true});
                    });
                });
            });
    }

    componentWillUnmount () {
        clearTimeout(this._resizeTimer);
        if (Platform.OS === 'web') {
            window.removeEventListener('popstate', this._browserBackListener);
        }
        LoginUtil.removeDefaultListener(this.props.navigation);
    };

    componentDidUpdate () {
        // レンダー完了時に呼ばれる
    }

    /**
     * パラムの取得
     * @private
     */
    _getParams = () => {
        let makerDetailScreenModel: MakerDetailScreenModel = UrlUtil.getParamModel(new MakerDetailScreenModel(), this.props.navigation);
        return makerDetailScreenModel;
    };

    /**
     * マスターのロード
     * @private
     */
    _loadMasters = async () => {

    };

    /**
     * コンテンツのロード
     * @private
     */
    _loadContents = async () => {
        this._param = this._getParams();
        if (this._param.makerId == null) {
            return;
        }

        const getMaker = MakerApiFactory(AppGlobalDataUtil.getConfiguration(), AppGlobalDataUtil.getBasePath())
            .getMaker(this._param.makerId, 2).then((makerRes: AxiosResponse<Maker>) => {
                if (makerRes != null && makerRes.data != null) {

                    // meta 更新
                    WebTagUtil.setOgType('article');
                    WebTagUtil.setTags(`${makerRes.data.name}|Reci BASE|メーカーから探す`, `レシピ数:${makerRes.data.numOfRecipes},商品数:${makerRes.data.numOfProduct},${makerRes.data.description}`, makerRes.data.logo);

                    this.setState({maker: makerRes.data});
                }
            });

        this._makerRecipeSearch(0, 20, true);
        this._makerProductSearch(0, 20, true);

        // 実行
        await Promise.all([getMaker]);
    };

    /**
     * お問い合わせチャット開設（既にある場合はIDを取得）
     */
    _createInquiryChat = async () => {

        if (this.state.maker == null) {
            return null;
        }

        AnalyticsUtil.sendAnalytics(
            DOpenEventName.inquiry,
            AnalyticsEventName.inquiry,  // アナリティクスのイベント名
            'MakerDetailScreen',
            null,
            null,
            this.state.maker,
            null,
            null,
            null,
            null,
            null,
        );

        // チャット開設（既にある場合はIDを取得）
        const getChats = ChatApiFactory(AppGlobalDataUtil.getConfiguration(), AppGlobalDataUtil.getBasePath())
            .createChat(`${this.state.maker.id}`).then((chatsRes: AxiosResponse) => {
                log.debug(`--------------- chatsRes --------------`);
                log.debug(chatsRes.data);

                if (chatsRes == null || chatsRes.data != null) {
                    let chatScreenModel = new ChatScreenModel();
                    chatScreenModel.chatId = chatsRes.data;
                    chatScreenModel.makerId = this.state!.maker!.id.toString();
                    chatScreenModel.inquiryStatus = InquiryStatusEnum.MAKER;
                    chatScreenModel.inquiryMakerId = this.state.maker!.id.toString();
                    this.props.navigation.navigate('ChatScreen', chatScreenModel);
                    return;
                }

            });

        // 実行
        await Promise.all([getChats]);
    }

    /**
     * メーカーIDでレシピを検索
     * @param from
     * @param to
     * @param resetFlg
     */
    _makerRecipeSearch = async (from: number, to: number, resetFlg: boolean) => {
        if (this._param.makerId == null) {
            return;
        }

        this.setState({isLoadResultRecipes: true});
        // レシピ検索
        RecipeApiFactory(AppGlobalDataUtil.getConfiguration(), AppGlobalDataUtil.getBasePath())
            .findRecipes(
                from,
                to,
                undefined,
                parseInt(this._param.makerId),
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                4,
            ).then((resultRecipesRes: AxiosResponse<Recipe[]>) => {
            if (resultRecipesRes != null && !ValidateUtil.isEmptyArray(resultRecipesRes.data)) {
                let _resultRecipes: Recipe[] = [];
                if (!resetFlg) {
                    _resultRecipes = this.state.recipes;
                }
                resultRecipesRes.data.forEach((recipe: Recipe) => {
                    _resultRecipes.push(recipe);
                });
                this.setState({recipes: _resultRecipes});
            } else if (resetFlg) {
                this.setState({recipes: []});
            }
            this.setState({isLoadResultRecipes: false});
        });
    }

    _makerProductSearch = async (from: number, to: number, resetFlg: boolean) => {
        if (this._param.makerId == null) {
            return;
        }

        this.setState({isLoadResultProducts: true});
        // 商品検索
        ProductApiFactory(AppGlobalDataUtil.getConfiguration(), AppGlobalDataUtil.getBasePath())
            .findProducts(
                from,
                to,
                undefined,
                undefined,
                undefined,
                parseInt(this._param.makerId),
                undefined,
                4,
            ).then((resultProductsRes: AxiosResponse<Product[]>) => {
            if (resultProductsRes != null && !ValidateUtil.isEmptyArray(resultProductsRes.data)) {
                let _resultProducts: Product[] = [];
                if (!resetFlg) {
                    _resultProducts = this.state.products;
                }
                resultProductsRes.data.forEach((product: Product) => {
                    _resultProducts.push(product);
                });
                this.setState({products: _resultProducts});
            } else if (resetFlg) {
                this.setState({products: []});
            }
            this.setState({isLoadResultProducts: false});
        });
    }

    /**
     * メーカーに質問ボタン
     */
    _renderInquiry = () => {
        if (this.state.maker == null) {
            return null;
        }

        if (this.state.maker.id === 3) {
            // メーカーIDが3（本番ならテーオー食品）なら、メーカーに質問ボタンは表示しない
            return null;
        }

        return (
            <View style={{marginLeft: this._marginSize, marginRight: this._marginSize, marginBottom: appS.line.space}}>
                <SpacerComponent height={32}/>
                {/*お問い合わせボタン*/}
                <TouchableOpacity
                    style={[
                        {
                            height: appS.button.height,
                            backgroundColor: appColors.recipeDownloadButton,
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginBottom: 10,
                            borderRadius: appS.buttonSize.radius
                        }
                    ]}
                    onPress={() => {
                        if (AppG.user == null) {
                            ValidateUtil.showNoUserAlertAtMakerChat(this.props.navigation);
                            return;
                        }

                        this._createInquiryChat();
                    }}
                >
                    <Text style={{
                        fontFamily: appFont.family.bold,
                        color: appColors.white,
                        fontWeight: 'bold',
                        fontSize: appS.button.text
                    }}>お問い合わせ</Text>
                </TouchableOpacity>
            </View>
        );
    }

    /**
     * シェアボタン用のURL作成
     */
    _getShareUrl = (clipboard: boolean): string => {
        // @ts-ignore
        const {REACT_APP_DOMAIN_URL} = getEnvVars();
        const { routeName } = this.props.navigation.state;
        if (clipboard) {
            return `${REACT_APP_DOMAIN_URL}/${routeName}/${this.state.maker?.id}`;
        } else {
            return encodeURI(`${REACT_APP_DOMAIN_URL}/${routeName}/${this.state.maker?.id}`);
        }
    }

    /**
     * シェアボタン
     */
    _renderShareBtn = () => {
        if (Platform.OS != 'web') {
            return null;
        }

        return (
            <ShareComponent
                shareUrl={this._getShareUrl(false)}
                copyUrl={this._getShareUrl(true)}
                hashtags={'ReciBASE,レシベース,メニュー開発,レシピ,業務用,商品,飲食店,料理人'}
                image_url={encodeURIComponent(this.state.maker?.logo!)}
                description={this.state.maker?.name!}
                showPinterest={false}
                screenName={'MakerDetailScreen'}
                recipe={null}
                product={null}
                maker={this.state.maker}
            />
        )
    }

    /**
     * ロゴの描画
     */
    _renderLogo =() => {
        if (this.state.maker == null) {
            return null;
        }

        let source = require('../../resources/images/no_image.png');
        if (!ValidateUtil.isEmptyExact(this.state.maker?.logo) && isURL(this.state.maker?.logo)) {
            source = {uri: this.state.maker.logo}
        }
        return (
            <View style={{flexDirection: "row"}}>
                <View style={{flex:1}}/>
                <Image source={source}
                       style={[{
                           backgroundColor: 'white',
                           width: 80,
                           height: 80,
                           borderRadius: 5,
                           marginTop: 0,
                           marginBottom: 0,
                           marginRight: 0,
                           marginLeft: 0,
                       }]}
                       resizeMode={'cover'}
                       accessibilityLabel={this.state.maker.name}
                />
                <View style={{flex:1}}/>
            </View>

        );
    };

    /**
     * コンテンツ全部
     */
    _renderMakerScreen = () => {

        if (this.state.maker == null) {
            return null;
        }

        const {navigation} = this.props;

        let _widthDescription = AppG.window.width;
        if (ScreenSizeUtil.isWebSize()) {
            _widthDescription = appS.webMainContentsSize.width;
        }
        _widthDescription = _widthDescription - appS.margins.side * 4;

        let _width = AppG.window.width;
        if (ScreenSizeUtil.isWebSize()) {
            _width = appS.webMainContentsSize.width;
        }
        _width = _width - appS.margins.side * 2;

        return (
            <View style={{width: _width}}>
                <View style={{}}>
                    <SpacerComponent height={15}/>
                    {/*メーカーアイコン*/}
                    {this._renderLogo()}
                </View>

                {ScreenSizeUtil.isWebSize()&&(<SpacerComponent height={16}/>)}

                <View style={{width: _widthDescription, margin: appS.margins.side * 2}}>
                    {/*メーカー説明*/}
                    <Text
                        style={{
                            fontFamily: appFont.family.default,
                            margin: this._marginSize,
                            fontSize: 14,
                            color: appColors.fontGray,
                        }}>
                        {this.state.maker.description}
                    </Text>

                    {/*URL*/}
                    {!ValidateUtil.isEmptyExact(this.state.maker.site) && isURL(this.state.maker.site) && (
                        <View style={{marginLeft: appS.margins.side * 2}}>
                            <Text style={{fontFamily: appFont.family.bold, fontSize: appFont.size.subTopic, color: appColors.fontBlack, fontWeight: "bold"}}>公式HP</Text>
                            <View style={{flexDirection: "row"}}>
                                {/*<Image source={require('../../resources/images/03.item_recipe/i_link.png')}*/}
                                {/*       style={[{*/}
                                {/*           marginTop: Platform.OS == 'android'? 14 : 6,*/}
                                {/*           width: 12,*/}
                                {/*           height: 12,*/}
                                {/*       }]}*/}
                                {/*       resizeMode={'cover'}*/}
                                {/*/>*/}
                                <View style={{marginLeft: 4, marginTop: 3, flexDirection: 'row'}}>
                                    <LinkComponent
                                        title={this.state.maker.site!}
                                        textDecorationLine={'underline'}
                                        color={appColors.linkBlue}
                                        onPress={() => LinkUtil.openLinkWithDefaultBrowser(this.state.maker?.site!)}
                                    />
                                    <Text style={{marginTop: Platform.OS == 'android' ? -10 : 0, fontFamily: appFont.family.default, fontSize: appFont.size.link, color: appColors.fontGray, marginLeft: 8}}>(外部サイト)</Text>
                                </View>
                            </View>
                        </View>
                    )}

                    {/*メーカーに質問ボタン*/}
                    {this._renderInquiry()}
                </View>

                {/*シェアボタン*/}
                {!ScreenSizeUtil.isWebSize() && this._renderShareBtn()}

                {/*ボーダー*/}
                <View style={{
                    backgroundColor: appColors.spacerGray,
                    height: 1,
                    width: _width,
                    margin: appS.margins.side,
                    marginTop: 10,
                    marginBottom: 10,
                }}/>

                <View style={{backgroundColor: appColors.white, paddingTop: 4, width: _width + appS.margins.side * 2}}>
                    <TabBorderComponent
                        ref={(tabRef: any) => { this._tabRef = tabRef; }}
                        tabs={this._tabs}
                        subTitles={[`${this.state.maker.numOfRecipes}`, `${this.state.maker.numOfProduct}`]}
                        focusTabNumber={this._tabIndex}
                        unselectBorder={true}
                        button={true}
                    />
                </View>


                <View>
                    <View style={[{flexDirection: 'column'}]}>
                        {this._tabIndex == 0 && (
                            <RecipeSearchResultListComponent
                                navigation={this.props.navigation}
                                recipes={this.state.recipes}
                            />
                        )}

                        {this._tabIndex == 1 && (
                            <ProductSearchResultListComponent
                                navigation={this.props.navigation}
                                products={this.state.products}
                            />
                        )}
                    </View>
                </View>

                {/*ローディングインジケーター*/}
                <View style={{flex: 1, marginTop: 32, height: appS.activityIndicator.height}}>
                    <ActivityIndicator
                        animating={(this.state.isLoadResultRecipes || this.state.isLoadResultProducts)}
                        color = {appColors.indicator}
                        size = "large"
                        style={[{flex: 1}]}/>
                </View>


            </View>
        );
    }

    /**
     * レシピ or 商品のスクロールロード
     * @param event
     */
    _loadList = (event: any) => {
        // スクロールViewの高さ
        const scrollViewHeight = event.nativeEvent.contentSize.height - event.nativeEvent.layoutMeasurement.height;
        // スクロールView上の現在位置
        const nowPositionHeight = event.nativeEvent.contentOffset.y;
        // 現在の位置がスクロールView + activityIndicator の高さよりも下の場合
        const indicatorHeight = Platform.OS == 'web'? -10: -appS.activityIndicator.height;
        if (this.state.listType == 'recipe') {
            if (!this.state.isLoadResultRecipes && this.state.recipes.length >= this._contentsLoadCount - 1 && nowPositionHeight - scrollViewHeight >  indicatorHeight) {
                this._makerRecipeSearch(this.state.recipes.length, this.state.recipes.length + this._contentsLoadCount, false);
            }
        } else {
            if (!this.state.isLoadResultProducts && this.state.products.length >= this._contentsLoadCount - 1 && nowPositionHeight - scrollViewHeight >  indicatorHeight) {
                this._makerProductSearch(this.state.products.length, this.state.products.length + this._contentsLoadCount, false);
            }
        }
    }

    _renderWide = () => {
        const {navigation} = this.props;

        let _width = AppG.window.width;
        if (ScreenSizeUtil.isWebSize()) {
            _width = appS.webMainContentsSize.width;
        }

        return (
            <SafeAreaView
                style={[{height: AppG.window.height, flex: 1, flexDirection: 'column', backgroundColor: appColors.white}]}
                onLayout={(event) => {}}
            >
                <CustomHeaderComponentWide
                    navigation={navigation}
                    searchBar={true}
                />
                <View style={{
                    flex: 1,
                    flexDirection: 'row',
                    alignSelf: 'center',
                    backgroundColor: appColors.backBaseColor,
                }}>
                    <ScrollView
                        horizontal  // 下階層のスクロールイベントを拾えるようにしている
                        style={{width: AppG.window.width, height: AppG.window.height - appS.header.webHeight}}
                    >
                        <View style={[{flex: 1, flexDirection: 'column', width: AppG.window.width}]}>
                        <KeyboardAwareScrollView
                            style={[{flex: 1, flexDirection: 'column'}]}
                            onScroll={(event: any) => {
                                // 無限スクロール
                                this._loadList(event);
                            }}
                        >
                            <View style={[{flex: 1, flexDirection: ScreenSizeUtil.isWebSize()? 'row': 'column', justifyContent: 'center'}]}>
                                <View style={[{flexDirection: 'column'}]}>
                                    <View style={{width: _width}}>
                                        {this.state.maker != null && (
                                            <View>
                                                {ScreenSizeUtil.isWebSize() && <WideSubTitleComponent title={this.state.maker.name} width={_width} />}
                                                {this._renderMakerScreen()}
                                                <SpacerComponent height={appS.footer.height} />
                                            </View>
                                        )}
                                    </View>
                                </View>
                                <View style={{marginLeft: appS.margins.webBetweenMargin, width: appS.webSideContentsSize.width}}>
                                    {ScreenSizeUtil.isWebSize() && (
                                        <View style={{}}>
                                            <WideScreenAdComponent navigation={this.props.navigation}/>
                                            <WideScreenSNSComponent navigation={this.props.navigation}/>
                                            {/*シェアボタン*/}
                                            {this._renderShareBtn()}
                                        </View>
                                    )}
                                </View>
                            </View>
                        </KeyboardAwareScrollView>
                    </View>
                    </ScrollView>
                </View>
            </SafeAreaView>
        );
    }

    _renderSmall = () => {
        const {navigation} = this.props;

        // @ts-ignore
        const {REACT_APP_DOMAIN_URL} = getEnvVars();
        const { routeName } = this.props.navigation.state;

        return (
            // appStyles.statusBarHiddenSafeAreaでステータスバー分上に上げる
            <SafeAreaView
                // style={[{flex: 1, backgroundColor: appColors.white}, appS.statusBarHiddenSafeArea]}
                style={[{height: AppG.window.height, flex: 1, flexDirection: 'column', backgroundColor: appColors.white}]}
                onLayout={(event) => {}}
            >
                <CustomHeaderComponentSmall
                    navigation={navigation}
                    leftButton={MyAppUtil.selectWebLogoSp()}
                    rightButton={MyAppUtil.selectWebMenuSp()}
                    title={'MAKER'}
                    statusBarHidden={false}
                    searchBar={true}
                    textColor={appColors.black}
                    barColor={appColors.white}
                    borderBottomColor={appColors.transparent}
                    statusBarType={"dark-content"}
                    shadow={false}
                    onSearchSubmit={(text: string) => {
                        // 検索
                        if (!ValidateUtil.isEmptyExact(text)) {
                            // 検索履歴に追加
                            SearchDataDao.addNew(text);

                            let searchScreenModel: SearchScreenModel = new SearchScreenModel();
                            searchScreenModel.searchType = 'all';
                            searchScreenModel.searchValue = text;

                            if (Platform.OS == 'web') {
                                navigation.navigate('SearchResultScreen', searchScreenModel);
                            } else {
                                navigation.push('SearchResultScreen', searchScreenModel);
                            }
                        }
                    }}
                />
                <SpacerComponent height={2}/>
                <CustomHeaderComponentSmall
                    zIndex={50}
                    navigation={this.props.navigation}
                    title={this.state.maker == null? '':this.state.maker.name}
                    textColor={appColors.fontBlack}
                    barColor={appColors.white}
                    statusBarHidden={false}
                    statusBarType={"light-content"}
                    backButtonColor={'gray'}
                    leftButton={'back'}
                    rightButton={'share'}
                    shareMessage={`${REACT_APP_DOMAIN_URL}/${routeName}/${this.state.maker?.id}`}
                    secondHeader={true}
                    shareAnalytics={() => {
                        AnalyticsUtil.sendAnalytics(
                            DOpenEventName.share,
                            AnalyticsEventName.share,  // アナリティクスのイベント名
                            'MakerDetailScreen',
                            null,
                            null,
                            this.state.maker,
                            null,
                            null,
                            null,
                            null,
                            ShareBtnName.appShare,
                        );
                    }}
                />
                <ScrollView
                    horizontal  // 下階層のスクロールイベントを拾えるようにしている
                    style={{width: AppG.window.width, height: AppG.window.height - appS.header.webHeight}}
                >
                    <KeyboardAwareScrollView
                        style={[{flex: 1, flexDirection: 'column', backgroundColor: appColors.backBaseColor, width: AppG.window.width}]}
                        onScroll={(event: any) => {
                            // 無限スクロール
                            this._loadList(event);
                        }}
                    >
                        {this._renderMakerScreen()}
                    </KeyboardAwareScrollView>
                </ScrollView>
            </SafeAreaView>
        );
    }


    /** 描画 */
    render() {
        const {navigation} = this.props;
        if (!this.state.isLoaded) {
            return (
                <View style={{flex: 1}}>
                    <IndicatorComponent
                        failTime={60}
                        isLoading={true}
                        reloadFunc={() => {
                            this.componentWillUnmount();
                            this.componentDidMount();
                        }}/>
                </View>
            );
        }

        LinkUtil.addReturnFalseToATag();
        if (ScreenSizeUtil.isWebSize()) {
            // WEBサイズ
            return this._renderWide();
        } else {
            // SPサイズ
            return this._renderSmall();
        }

    };


}
