import React from 'react'
import {Dimensions, Platform, SafeAreaView, Text, View} from 'react-native'
import {appColors, appFont, appS} from '../../resources/styles/style'
import {BaseScreen} from "./BaseScreen";
import LoginUtil from "../util/LoginUtil";
// @ts-ignore
import isEmpty from 'validator/lib/isEmpty';
// @ts-ignore
import isEmail from 'validator/lib/isEmail';
// @ts-ignore
import isMultibyte from 'validator/lib/isMultibyte';
import {SpacerComponent} from "../components/SpacerComponent";
import {IndicatorComponent} from "../components/IndicatorComponent";
// @ts-ignore
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scrollview';
// @ts-ignore
import {getEnvVars, log} from '../../../environment';
import {CustomHeaderComponentSmall} from "../components/small/CustomHeaderComponentSmall";
import {TopicTitleComponent} from "../components/TopicTitleComponent";
import ExpoUtil from "../util/ExpoUtil";
import AppG from "../util/AppG";
import ScreenSizeUtil from "../util/ScreenSizeUtil";
import {CustomHeaderComponentWide} from "../components/wide/CustomHeaderComponentWide";
import AnalyticsUtil, {AnalyticsEventName, DOpenEventName} from "../util/firebase/AnalyticsUtil";
import {News, NewsApiFactory} from "../data/network/swagger-gen";
import DateUtil from "../util/DateUtil";
import Hyperlink from 'react-native-hyperlink';
import LinkUtil from "../util/LinkUtil";
import ValidateUtil from "../util/ValidateUtil";

interface Props {
    navigation: any, // ナビゲーション用
}

interface State {
    resizeWindow: boolean,  // 値に意味はなし。windowサイズの拡大縮小をrenderに送るためのもの
    isLoaded: boolean,

    // コンテンツ
    news: News | undefined,

}

export class NewsDetailScreen extends BaseScreen<Props, State> {
    _resizeTimer = setInterval(() => {
        // window 拡大縮小を反映させる
        AppG.window = Dimensions.get('window');
        if (AppG.window.width != AppG.windowBefore.width || AppG.window.height != AppG.windowBefore.height) {
            this.setState({'resizeWindow': true});
            AppG.windowBefore = Dimensions.get('window');
        }
        LoginUtil.setRefreshedScreenFlg(false);
    }, 100);

    _flameSize = appS.flame.size;    // 左右の margin
    _marginSize = ScreenSizeUtil.isWebSize()? 0 : appS.margin.size;

    _news = AppG.news;

    // _param = new NewsDetailScreenModel();

    _browserBackListener = (e: any) => {

    };

    constructor(props: any) {
        super(props);
        this.state = {
            resizeWindow: true,  // 値に意味はなし。windowサイズの拡大縮小をrenderに送るためのもの
            isLoaded: false,
            news: undefined,
        };
    }

    /**
     * 画面開いたときのアナリティクス送信
     */
    _sendPvAnalytics = () => {
        AnalyticsUtil.sendAnalytics(
            DOpenEventName.openPage,
            AnalyticsEventName.openPage,  // アナリティクスのイベント名
            'NewsDetailScreen',
            null,
            null,
            null,
            null,
        );
    }

    componentDidMount() {
        if (Platform.OS == 'web') {
            window.addEventListener('popstate', this._browserBackListener);
        }

        // Web 戻るでも呼ばれる
        if (Platform.OS == 'web') {
            // Analytics
            this._sendPvAnalytics();
        }

        // URL Scheme で外部リンクから起動したときの処理
        LoginUtil.setDefaultListener(
            this.props.navigation,
            () => {
                // Webはここにこない
                if (Platform.OS != 'web') {
                    // Analytics
                    this._sendPvAnalytics();
                }

                this._loadContents()

                ExpoUtil.doReloadAppTab(() => {

                });
            });

    }

    componentWillUnmount () {
        clearTimeout(this._resizeTimer);
        if (Platform.OS === 'web') {
            window.removeEventListener('popstate', this._browserBackListener);
        }
        LoginUtil.removeDefaultListener(this.props.navigation);
    };

    componentDidUpdate () {
        // レンダー完了時に呼ばれる
    }

    /**
     * パラムの取得
     * @private
     */
    _getParams = () => {
        // let newsDetailScreenModel: NewsDetailScreenModel = UrlUtil.getParamModel(new NewsDetailScreenModel(), this.props.navigation);
        // return newsDetailScreenModel;
    };


    /**
     * お知らせの取得
     */
    _loadContents = async () => {
        // this._param = this._getParams();

        if (this._news == null) {
            this.setState({isLoaded: true});
            return;
        }

        // 既読にする
        NewsApiFactory(AppG.getConfiguration(), AppG.getBasePath())
            .readNews(this._news.id!).then(()=>{})

        // お知らせ
        this.setState({news: this._news});
        this.setState({isLoaded: true});
    }




    _renderMain = () => {
        if (this.state.news == null) {
            return null;
        }

        return (
            <View style={[{flex: 1}]}>
                <KeyboardAwareScrollView style={[{flex: 1, flexDirection: 'column'}]}>
                    {ScreenSizeUtil.isWebSize() && (
                        <View style={{flex:1, alignSelf: 'center', marginTop: 8}}>
                            <Text style={{fontFamily: appFont.family.default, fontSize: 24, margin: 24, fontWeight: 'bold', textAlign: 'center', color: appColors.fontBlack}} numberOfLines={1}>お知らせ詳細</Text>
                        </View>
                    )}

                    <View style={[{}]}>
                        <TopicTitleComponent title={`${this.state.news.title}`}/>
                        <SpacerComponent height={appS.spacer.height.small}/>

                        <Text style={{fontFamily: appFont.family.default, color: appColors.fontGray, margin: this._marginSize}}>{DateUtil.getViewDateFromApiDateStr(this.state.news.date)}</Text>

                        <SpacerComponent height={appS.spacer.height.small*2}/>

                        <Hyperlink
                            linkStyle={{color: appColors.linkBlue, fontWeight: 'bold'}}
                            onPress={(url: string, text: string) => {
                                if (ValidateUtil.isMyDomain(url)) {
                                    LinkUtil.openLink(url, this.props.navigation);
                                } else {
                                    LinkUtil.openLinkWithDefaultBrowser(url);
                                }
                            }}>
                            <Text style={{fontFamily: appFont.family.default, color: appColors.fontBlack, margin: this._marginSize}}>{this.state.news.text}</Text>
                        </Hyperlink>
                    </View>

                </KeyboardAwareScrollView>

                {ScreenSizeUtil.isWebSize() && (<SpacerComponent height={64}/>)}

            </View>
        );
    }

    _renderWide = () => {
        return (
            <SafeAreaView
                style={[{height: AppG.window.height, flex: 1, flexDirection: 'column', backgroundColor: appColors.white}]}
                onLayout={(event) => {}}
            >
                <CustomHeaderComponentWide logoOnly={true} navigation={this.props.navigation}/>

                <View style={{flex: 1, flexDirection: 'row'}}>
                    <KeyboardAwareScrollView style={[{flex: 1, flexDirection: 'column'}]}>
                        <View style={[{flex: 1, width: 700, alignSelf: 'center'}]}>
                            {/*メイン*/}
                            {this._renderMain()}
                        </View>
                    </KeyboardAwareScrollView>
                </View>
            </SafeAreaView>
        );
    }

    _renderSmall = () => {
        return (
            <SafeAreaView
                style={[{flex: 1, backgroundColor: appColors.white}]}
                onLayout={(event) => {}}
            >
                <CustomHeaderComponentSmall
                    navigation={this.props.navigation}
                    title={'お知らせ詳細'}
                    textColor={appColors.black}
                    barColor={appColors.white}
                    leftButton={'back'}
                />
                <View style={{flex: 1, backgroundColor: appColors.backBaseColor}}>
                    <SpacerComponent height={appS.header.bottomMargin} />
                    {/*メイン*/}
                    {this._renderMain()}
                </View>
            </SafeAreaView>
        );
    }

    /** 描画 */
    render() {
        // const {navigation} = this.props;
        if (!this.state.isLoaded) {
            return (
                <View style={{flex: 1}}>
                    <IndicatorComponent
                        failTime={60}
                        isLoading={true}
                        reloadFunc={() => {
                            this.componentWillUnmount();
                            this.componentDidMount();
                        }}/>
                </View>
            );
        }

        LinkUtil.addReturnFalseToATag();
        if (ScreenSizeUtil.isWebSize()) {
            // WEBサイズ
            return this._renderWide();
        } else {
            // SPサイズ
            return this._renderSmall();
        }



    };


}
