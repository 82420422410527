import React from 'react'
import {Dimensions, Image, Platform, SafeAreaView, ScrollView, Text, TouchableOpacity, View} from 'react-native'
import {appColors, appFont, appS} from '../../resources/styles/style'
import {BaseScreen} from "./BaseScreen";
import LoginUtil from "../util/LoginUtil";
import {IndicatorComponent} from "../components/IndicatorComponent";
// @ts-ignore
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scrollview';
import LoginDataDao from "../data/dao/local/LoginDataDao";
import {LoginDataEntityModel} from "../data/entityModels/LoginDataEntityModel";
import {ProductApiFactory, User, UserApiFactory, UserUserAttributeEnum,} from "../data/network/swagger-gen";
import AppGlobalDataUtil from "../util/AppG";
import AppG from "../util/AppG";
import UrlUtil from "../util/UrlUtil";
// @ts-ignore
import isURL from 'validator/lib/isURL';
// @ts-ignore
import {log} from '../../../environment';
import {AxiosResponse} from "axios";
import {CartScreenModel} from "../data/models/screen/CartScreenModel";
import ValidateUtil from "../util/ValidateUtil";
import ExpoUtil from "../util/ExpoUtil";
import ScreenSizeUtil from "../util/ScreenSizeUtil";
import {CustomHeaderComponentWide} from "../components/wide/CustomHeaderComponentWide";
import {NoItemsIconComponent} from "../components/NoItemsIconComponent";
import {WideScreenAdComponent} from "../components/wide/WideScreenAdComponent";
import AnalyticsUtil, {AnalyticsEventName, DOpenEventName} from "../util/firebase/AnalyticsUtil";
import {CustomHeaderComponentSmall} from "../components/small/CustomHeaderComponentSmall";
import {SpacerComponent} from "../components/SpacerComponent";
import * as Notifications from "expo-notifications";

import {RequestHistoryListComponent} from "../components/RequestHistoryListComponent";
import MyAppUtil from "../util/MyAppUtil";
import {WideSubTitleComponent} from "../components/wide/WideSubTitleComponent";
import {NoUserImageForHistoryComponent} from "../components/NoUserImageForHistoryComponent";
import {WideScreenSNSComponent} from "../components/wide/WideScreenSNSComponent";
import LinkUtil from "../util/LinkUtil";
import {NothingHistoryImageComponent} from "../components/NothingHistoryImageComponent";

interface Props {
    navigation: any, // ナビゲーション用
}

interface State {
    resizeWindow: boolean,  // 値に意味はなし。windowサイズの拡大縮小をrenderに送るためのもの
    isLoaded: boolean,
    isShowErrorModal: boolean,

    // コンテンツ
    user: User | null,
    productsInHistory: any[], // 依頼履歴の商品
}

export class SampleRequestHistoryScreen extends BaseScreen<Props, State> {
    _resizeTimer = setInterval(() => {
        // window 拡大縮小を反映させる
        AppG.window = Dimensions.get('window');
        if (AppG.window.width != AppG.windowBefore.width || AppG.window.height != AppG.windowBefore.height) {
            this.setState({'resizeWindow': true});
            AppG.windowBefore = Dimensions.get('window');
        }
        LoginUtil.setRefreshedScreenFlg(false);
    }, 100);

    _loginData = new LoginDataEntityModel();

    _flameSize = ScreenSizeUtil.isWebSize()? AppG.window.width / 10 : 16;    // 左右の margin
    _marginSize = ScreenSizeUtil.isWebSize()? 0 : appS.margin.size;

    // このScreenへ渡すパラム
    _param: CartScreenModel = new CartScreenModel();
    _contentsLoadCount = 9; // コンテンツの一度の読み込み数

    _needReviewCount = 0;   // レビューするの数

    // スクロール用
    _scrollTab: any = null;

    _browserBackListener = (e: any) => {

    };

    constructor(props: any) {
        super(props);
        this.state = {
            resizeWindow: true,  // 値に意味はなし。windowサイズの拡大縮小をrenderに送るためのもの
            isLoaded: false,
            isShowErrorModal: false,
            user: null,
            productsInHistory: [], // 依頼履歴の商品
        };
    }

    /**
     * 画面開いたときのアナリティクス送信
     */
    _sendPvAnalytics = () => {
        AnalyticsUtil.sendAnalytics(
            DOpenEventName.openPage,
            AnalyticsEventName.openPage,  // アナリティクスのイベント名
            'SampleRequestHistoryScreen',
            null,
            null,
            null,
            null,
        );
    }

    componentDidMount() {
        if (Platform.OS == 'web') {
            window.addEventListener('popstate', this._browserBackListener);
        }

        if (Platform.OS != 'web') {
            // スクロールviewのセット
            this.props.navigation.setParams({
                scrollToTop: () => {
                    if (this._scrollTab != null) {
                        this._scrollTab.scrollTo({x: 0, y: 0, animated: true});
                    }
                }
            })
        }

        // URL Scheme で外部リンクから起動したときの処理
        // LoginUtil.setDefaultListener(this.props.navigation);

        LoginUtil.interruptOpenScreen(this.props.navigation,
            () => {
                LoginDataDao.get().then(loginData => {
                    // Web 戻るでも呼ばれる (Footerにあるやつはappでも実行するようにする)
                    // Analytics
                    this._sendPvAnalytics();
                    AppG.getFooterBadge();

                    if (Platform.OS != 'web') {
                        // アイコンバッヂの更新
                        Notifications.setBadgeCountAsync((AppG.badgeChat == null? 0 : AppG.badgeChat) + (AppG.badgeHistory == null? 0 : AppG.badgeHistory));
                    }

                    // URL Scheme で外部リンクから起動したときの処理と、画面遷移でこの画面を開いたときの処理
                    LoginUtil.setDefaultListener(
                        this.props.navigation,
                        () => {
                            // Webはここにこない
                            if (Platform.OS != 'web') {
                                // Analytics
                                this._sendPvAnalytics();
                            }

                            this._loadContents().then(() => {});
                            AppG.getFooterBadge();

                            ExpoUtil.doReloadAppTab(() => {
                            });
                        });
                    if (loginData != null && !ValidateUtil.isEmptyExact(loginData?.user_id)) {
                        this._loginData = loginData;
                        this._loadMasters().then(() => {});
                    }
                    this._loadContents().then(() => {});
                });
            });
    }

    componentWillUnmount () {
        clearTimeout(this._resizeTimer);
        if (Platform.OS === 'web') {
            window.removeEventListener('popstate', this._browserBackListener);
        }
        LoginUtil.removeDefaultListener(this.props.navigation);
    };

    componentDidUpdate () {
        // レンダー完了時に呼ばれる
    }

    /**
     * パラムの取得
     * @private
     */
    _getParams = () => {
        let cartScreenModel: CartScreenModel = UrlUtil.getParamModel(new CartScreenModel(), this.props.navigation);
        return cartScreenModel;
    };

    /**
     * マスターのロード
     * @private
     */
    _loadMasters = async () => {

    };

    /**
     * コンテンツのロード
     * @private
     */
    _loadContents = async () => {
        this._param = this._getParams();

        if (AppG.user == null || AppG.user.userAttribute == UserUserAttributeEnum.Normal) {
            this.setState({isLoaded: true});
            return;
        }

        // 依頼履歴
        this.setState({isLoaded: false});
        const getProductsInHistory = ProductApiFactory(AppGlobalDataUtil.getConfiguration(), AppGlobalDataUtil.getBasePath())
            .requeetedProductReviews(4).then((reviewsRes) => {
                if (reviewsRes != null && !ValidateUtil.isEmptyArray(reviewsRes.data)) {
                    this.setState({productsInHistory: reviewsRes.data!});
                    this._countNeedReviewProduct(reviewsRes.data);
                }
                this.setState({isLoaded: true});
            })

        // ユーザー
        const getUser = UserApiFactory(AppGlobalDataUtil.getConfiguration(), AppGlobalDataUtil.getBasePath())
            .getUser().then((userRes: AxiosResponse<User>) => {
                if (userRes != null && userRes.data != null) {
                    AppG.user = userRes.data;
                    this.setState({user: userRes.data});
                }
            });

        // 実行
        await Promise.all([getProductsInHistory, getUser]);
    };

    /**
     * レビューするの件数をカウント
     * @param reviews
     */
    _countNeedReviewProduct = (reviews: any[]) => {
        this._needReviewCount = 0;
        reviews.forEach((review => {
            if (MyAppUtil._getReviewStatusLabel(review) == 'レビューする') {
                this._needReviewCount = this._needReviewCount + 1;
            }
        }))
    }







    /**
     * 一般ユーザーの場合
     */
    _renderNormalUser = () => {
        // 飲食人の方の場合
        return (
            <View>
                {/*未ログイン*/}
                {AppG.user == null && (
                    <View style={{flex: 1, backgroundColor: AppG.user == null ? appColors.noUserBackground : appColors.white}}>
                        <NoUserImageForHistoryComponent navigation={this.props.navigation} />
                    </View>
                )}

                {(AppG.user != null && AppG.user.userAttribute == UserUserAttributeEnum.Normal) && (
                    <View style={{flex: 1, backgroundColor: AppG.user == null ? appColors.noUserBackground : appColors.transparent}}>
                        <Text style={{
                            marginLeft: appS.margins.side*2,
                            fontFamily: appFont.family.bold,
                            fontSize: appFont.size.subTopic,
                            color: appColors.fontBlack,
                            fontWeight: "bold"
                        }}>飲食関係者向けサービスとなります。</Text>
                        <Text style={{
                            marginLeft: appS.margins.side*2,
                            fontFamily: appFont.family.bold,
                            fontSize: appFont.size.subTopic,
                            color: appColors.fontBlack,
                            fontWeight: "bold"
                        }}> 一般の方はご利用になれません。</Text>
                    </View>
                )}
            </View>
        );
    }

    /**
     * 依頼履歴
     */
    _renderHistory = () => {
        let _width = AppG.window.width;
        if (ScreenSizeUtil.isWebSize()) {
            _width = appS.webMainContentsSize.width;
        }
        _width = _width - appS.margins.side * 2;

        let noItems = false;
        if (this.state.isLoaded && ValidateUtil.isEmptyArray(this.state.productsInHistory)) {
            noItems = true;
        }

        return (
            <View style={[{flex: 1, flexDirection: 'column', backgroundColor: (AppG.user == null || noItems) ? appColors.noUserBackground : appColors.backBaseColor}]}>
                <KeyboardAwareScrollView
                    ref={(scrollTab: any) => { this._scrollTab = scrollTab; }}
                    style={[{flex: 1, flexDirection: 'column'}]}
                >
                    <TouchableOpacity
                        activeOpacity={1}
                        style={[{flex: 1, flexDirection: 'column', justifyContent: 'center'}]}>
                        <View style={[{flexDirection: 'column'}]}>
                            {(AppG.user == null || AppG.user.userAttribute == UserUserAttributeEnum.Normal) && this._renderNormalUser()}
                            {(AppG.user != null && AppG.user.userAttribute == UserUserAttributeEnum.Cook) && (
                                <View style={{width: _width, margin: appS.margins.side}}>
                                    {!noItems && (
                                        <View style={{alignSelf: 'center', alignContent: 'center'}}>
                                            <View style={{flexDirection: 'row', alignSelf: 'center', alignContent: 'center'}}>
                                                <Text
                                                    style={{
                                                        fontFamily: appFont.family.bold,
                                                        fontSize: appFont.size.subTopic,
                                                        color: appColors.fontBlack,
                                                        fontWeight: "bold"
                                                    }}
                                                >レビュー依頼が</Text>
                                                <Text
                                                    style={{
                                                        textAlignVertical: 'center',
                                                        fontFamily: appFont.family.bold,
                                                        fontSize: 24,
                                                        color: appColors.red,
                                                        marginTop: -9,
                                                        fontWeight: "bold"
                                                    }}
                                                >{this._needReviewCount}件</Text>
                                                <Text
                                                    style={{
                                                        fontFamily: appFont.family.bold,
                                                        fontSize: appFont.size.subTopic,
                                                        color: appColors.fontBlack,
                                                        fontWeight: "bold"
                                                    }}>あります</Text>
                                            </View>
                                            <SpacerComponent height={appS.margins.side} />
                                            <TouchableOpacity
                                                style={{
                                                    alignSelf: 'center',
                                                    height: appS.buttonSize.height,
                                                    width: appS.buttonSize.width,
                                                    backgroundColor: appColors.buttonOrange,
                                                    borderRadius: appS.buttonSize.radius
                                                }}
                                                onPress={() => {
                                                    if (Platform.OS == 'web') {
                                                        this.props.navigation.navigate('SampleRequestReviewsScreen');
                                                    } else {
                                                        this.props.navigation.push('SampleRequestReviewsScreen');
                                                    }
                                                }}
                                            >
                                                <View style={{flexDirection: 'row', alignSelf: 'center'}}>
                                                    <Text
                                                        style={{
                                                            lineHeight: appS.buttonSize.height,
                                                            textAlignVertical: 'center',
                                                            textAlign: 'center',
                                                            fontFamily: appFont.family.bold,
                                                            fontSize: appFont.size.buttonText,
                                                            color: appColors.white,
                                                            fontWeight: "bold"
                                                        }}
                                                    >レビューする</Text>
                                                    <Image
                                                        source={require('../../resources/images/02.menu_general/arrow_W_right.png')}
                                                        style={{
                                                            marginTop: 20,
                                                            width: 12,
                                                            height: 12,
                                                        }}
                                                    />
                                                </View>

                                            </TouchableOpacity>
                                            <SpacerComponent height={appS.margins.side} />
                                            <Text style={{fontFamily: appFont.family.default, fontSize: appFont.size.default, color: appColors.fontGray}}>サンプルお試し後は、商品レビューをご提出ください。</Text>
                                            <SpacerComponent height={appS.margins.side} />
                                        </View>
                                    )}

                                    {!noItems && (
                                        <RequestHistoryListComponent navigation={this.props.navigation} productsInHistory={this.state.productsInHistory} />
                                    )}

                                    {/*商品無し*/}
                                    {noItems && (
                                        <ScrollView style={{backgroundColor: appColors.noUserBackground, height: AppG.window.height}}>
                                            <NothingHistoryImageComponent navigation={this.props.navigation} />
                                        </ScrollView>
                                    )}
                                    <SpacerComponent height={appS.margins.side} />
                                </View>
                            )}
                        </View>
                    </TouchableOpacity>
                    <SpacerComponent height={appS.header.webUpsideHeight + appS.header.webBottomHeight}/>
                </KeyboardAwareScrollView>
            </View>
        );
    }

    _renderSmall = () => {
        return (
            <SafeAreaView
                style={[{height: AppG.window.height, flex: 1, flexDirection: 'column', backgroundColor: appColors.white}]}
                onLayout={(event) => {}}
            >
                <CustomHeaderComponentSmall
                    navigation={this.props.navigation}
                    leftButton={(this._param.enableBack != null && this._param.enableBack == '1')? 'back' : MyAppUtil.selectWebLogoSp()}
                    rightButton={(this._param.enableBack != null && this._param.enableBack == '1')? 'none' : MyAppUtil.selectWebMenuSp()}
                    title={'サンプル依頼履歴'}
                    searchBar={false}
                    textColor={appColors.fontBlack}
                    barColor={appColors.white}
                    borderBottomColor={appColors.transparent}
                    shadow={true}
                    statusBarType={"dark-content"}
                />
                {AppG.user != null &&  (
                    <View style={{backgroundColor: AppG.user.userAttribute == UserUserAttributeEnum.Normal ? appColors.noUserBackground : appColors.backBaseColor}}>
                        <SpacerComponent height={appS.header.bottomMargin}/>
                    </View>
                )}

                {/*依頼履歴タブ*/}
                {this._renderHistory()}

            </SafeAreaView>
        );
    }

    _renderWide = () => {
        const {navigation} = this.props;

        return (
            <SafeAreaView
                style={[{height: AppG.window.height, flex: 1, flexDirection: 'column', backgroundColor: appColors.white}]}
                onLayout={(event) => {}}
            >
                <CustomHeaderComponentWide
                    navigation={navigation}
                    searchBar={true}
                />

                <View style={{
                    flex: 1,
                    flexDirection: 'row',
                    alignSelf: 'center',
                    backgroundColor: appColors.backBaseColor,
                }}>
                    <ScrollView
                        horizontal  // 下階層のスクロールイベントを拾えるようにしている
                        style={{
                            width: AppG.window.width,
                            height: AppG.window.height - appS.header.webHeight,
                        }}
                    >
                        <View style={[{flex: 1, flexDirection: 'column', width: AppG.window.width}]}>
                            <KeyboardAwareScrollView style={[{flex: 1, flexDirection: 'column'}]}>
                                <View style={[{flex: 1, flexDirection: ScreenSizeUtil.isWebSize()? 'row': 'column', justifyContent: 'center'}]}>
                                    <View style={[{flexDirection: 'column', backgroundColor: AppG.user == null ? appColors.noUserBackground : appColors.backBaseColor}]}>
                                        <View style={{width: ScreenSizeUtil.isWebSize()? appS.webMainContentsSize.width : AppG.window.width}}>
                                            {ScreenSizeUtil.isWebSize() && <WideSubTitleComponent title={'サンプル依頼履歴'} width={appS.webMainContentsSize.width} />}
                                            {this._renderHistory()}
                                        </View>
                                    </View>
                                    {ScreenSizeUtil.isWebSize() && (
                                        <View style={{marginLeft: appS.margins.webBetweenMargin, width: appS.webSideContentsSize.width}}>
                                            <WideScreenAdComponent navigation={this.props.navigation}/>
                                            <WideScreenSNSComponent navigation={this.props.navigation}/>
                                        </View>
                                    )}
                                </View>
                            </KeyboardAwareScrollView>
                        </View>
                    </ScrollView>
                </View>

                {/*<View style={{*/}
                {/*    flex: 1,*/}
                {/*    flexDirection: 'row',*/}
                {/*    marginTop: this._marginSize*/}
                {/*}}>*/}
                {/*    <View style={{flex:1}}>*/}

                {/*        /!*タブコンテンツ*!/*/}
                {/*        {AppG.user != null && (*/}
                {/*            <View style={{flex:1}}>*/}
                {/*                /!*履歴タブ*!/*/}
                {/*                {this._renderHistory()}*/}
                {/*            </View>*/}
                {/*        )}*/}
                {/*    </View>*/}
                {/*</View>*/}

            </SafeAreaView>
        );
    }



    /** 描画 */
    render() {
        const {navigation} = this.props;
        if (!this.state.isLoaded) {
            return (
                <View style={{flex: 1}}>
                    <IndicatorComponent
                        failTime={60}
                        isLoading={true}
                        reloadFunc={() => {
                            this.componentWillUnmount();
                            this.componentDidMount();
                        }}/>
                </View>
            );
        }

        LinkUtil.addReturnFalseToATag();
        if (ScreenSizeUtil.isWebSize()) {
            // WEBサイズ
            return this._renderWide();
        } else {
            // SPサイズ
            return this._renderSmall();
        }


    };


}
