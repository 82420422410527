import * as Facebook from 'expo-facebook';
// @ts-ignore
import {getEnvVars, log} from '../../../environment';

class FacebookUtil {
    /**
     * facebook SDKの初期化
     * */
    facebookInit = () => {
        try {
            // @ts-ignore
            const {REACT_APP_FACEBOOK_APP_ID} = getEnvVars();
            Facebook.initializeAsync({
            appId: REACT_APP_FACEBOOK_APP_ID,
            });
        } catch ({ message }) {
            log.debug(`Facebook SDK Init Error: ${message}`);
        }
    }

    /**
     * facebook SDKでの広告追跡可能に設定
     * */
    facebookAds = () => {
        try{
            Facebook.setAutoLogAppEventsEnabledAsync(true);
        }catch({ message }) {
            log.debug(`Facebook SDK Load App Event Error: ${message}`);
        }
    }
}

export default new FacebookUtil();
