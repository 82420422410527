import React, {Component} from 'react';
import {Image, ImageStyle, Platform, StyleProp, Text, TouchableOpacity, View} from 'react-native';
import {appColors, appFont, appS} from "../../resources/styles/style";
import {Recipe} from "../data/network/swagger-gen";
// @ts-ignore
import isURL from 'validator/lib/isURL';
import ValidateUtil from "../util/ValidateUtil";
import {RecipeDetailScreenModel} from "../data/models/screen/RecipeDetailScreenModel";
import ExpoUtil from "../util/ExpoUtil";
import AppG from "../util/AppG";
import ScreenSizeUtil from "../util/ScreenSizeUtil";
import LinkUtil from "../util/LinkUtil";
import {ATagLinkComponent} from "./ATagLinkComponent";

interface Props {
    navigation: any,
    recipes: Recipe[],
    analyticsBtnName: string | null,
    top3: boolean,  // トップ3だけ表示
}

interface State {}

export class RecipeListRankingComponent extends Component<Props, State> {


    // Propsのデフォルト値
    static defaultProps = {
        analyticsBtnName: null,
        top3: false,
    };

    _renderMain = () => {
        let {navigation, recipes} = this.props;
        let _width = AppG.window.width;
        if (ScreenSizeUtil.isWebSize()) {
            _width = appS.webMainContentsSize.width;
        }

        return (
            <View style={{
                flex: 1,
                flexDirection: "column",
                width: _width,
                marginLeft: 0,
                marginRight: 0,
                margin: appS.margins.side,
                backgroundColor: appColors.recipeSearchResultBackground,
            }}>
                {!ValidateUtil.isEmptyArray(recipes) &&
                    recipes.map((recipe, index) => {
                        if (this.props.top3 && index > 2) {
                            return null;
                        }
                        return (
                            <RecipeCellSearchResultComponent
                                navigation={navigation}
                                recipe={recipe}
                                key={`recipe_${index}`}
                                index={index}
                                analyticsBtnName={this.props.analyticsBtnName}
                            />
                        );
                    })}
            </View>
        );
    }


    render() {
        return this._renderMain();
    }
}







interface RecipeCellRankingProps {
    navigation: any,
    recipe: Recipe,
    index: number,
    analyticsBtnName: string | null,
}

interface RecipeCellRankingState {
    followed: boolean,
}


class RecipeCellSearchResultComponent extends Component<RecipeCellRankingProps, RecipeCellRankingState> {
    _productIconSize = 37;

    constructor(props: any) {
        super(props);
        this.state = {
            followed: this.props.recipe.followed == 1,  // 1ならお気に入り
        };
    };

    _goToRecipe = (recipe: Recipe) => {
        if (this.props.analyticsBtnName != null) {
            AppG.screenHistory.unshift(`${this.props.analyticsBtnName}`);
        }

        // 画面遷移前にリロード対象にセットする
        ExpoUtil.setShouldReloadAppTab('self');
        let recipeDetailScreenModel = new RecipeDetailScreenModel();
        recipeDetailScreenModel.recipeId = recipe.id.toString();
        if (Platform.OS == 'web') {
            this.props.navigation.navigate('RecipeDetailScreen', recipeDetailScreenModel);
        } else {
            this.props.navigation.push('RecipeDetailScreen', recipeDetailScreenModel);
        }
    }

    /**
     * 商品アイコンの作成
     * @param recipe
     * @param imageSize {width: number, height: number}
     * @private
     */
    _renderProductIcon = (recipe: Recipe, imageSize: {width: number, height: number}, index: number) => {

        let _userPlanOnly = recipe.userPlanOnly;
        // _userPlanOnly = 1;   // テスト用

        const _userPlanHeight = 16;
        const _productIconMargin = 4;
        const _style: StyleProp<ImageStyle> = {
            zIndex: 1,
            position: 'relative',
            top: 0,
            left: index == 0? -imageSize.width / 2 - 27 : -imageSize.width - 10,
            width: this._productIconSize,
            height: this._productIconSize,
            backgroundColor: appColors.transparent,
        };
        // 画像有り
        if (recipe.productMain != null && !ValidateUtil.isEmpty(recipe.productMain.image) && isURL(recipe.productMain.image)) {
            return (
                <View style={_style}>
                    <View style={{flexDirection: 'row'}}>
                        <View style={{width: 30}}/>
                        <View
                            style={{
                                flexDirection: 'row-reverse',
                                height: _userPlanHeight,
                                borderRadius: 5,
                                marginLeft: 4,
                                backgroundColor: _userPlanOnly == 1? appColors.limeGreen: appColors.transparent,
                            }}
                        >
                            <Text style={{marginTop: Platform.OS == 'android'? -6 : 0, fontFamily: appFont.family.default, fontSize: appFont.size.iconFontSize, color: _userPlanOnly == 1? appColors.white: appColors.transparent, marginLeft: 8, marginRight: 8, width: Platform.OS == 'android'? 43: 40}}>会員限定</Text>
                        </View>
                    </View>

                    <View style={{
                        zIndex: 1,
                        position: 'relative',
                        top: imageSize.height - this._productIconSize - _userPlanHeight,
                        width: imageSize.width,
                        height: this._productIconSize,
                        backgroundColor: appColors.transparent,
                        flexDirection: "row",
                    }}>
                        <View style={{flex:1}}/>
                        {/*画像無し（スペース確保）*/}
                        <View
                            style={_style}
                        />
                        <Image source={require('../../resources/images/skelton.png')}
                               style={{
                                   width: this._productIconSize,
                                   height: this._productIconSize,
                                   borderRadius: 50,
                                   alignItems: 'center',
                                   justifyContent: 'center',
                                   marginRight: _productIconMargin,
                                   marginTop: -_productIconMargin,
                                   // borderColor: appColors.borderGray,
                                   // borderWidth: 1,
                               }}
                               resizeMode={'cover'}
                        />
                    </View>
                </View>
            );
        }

        // 画像無し（スペース確保）
        if (recipe.productMain == null || ValidateUtil.isEmpty(recipe.productMain.image) || !isURL(recipe.productMain.image)) {
            return (
                <View
                    style={_style}
                />
            );
        }
    }

    _renderMain = () => {
        const {recipe, index} = this.props;

        let _lineNumTitle = 2;
        let _lineNumDescription = 1;
        let _margin = appS.margins.side;
        let _imageSize = 80;
        let _ingredient = '';
        recipe.ingredients.forEach(ingredient => {
            _ingredient = `${_ingredient}・${ingredient.name}`
        });
        if (_ingredient != '') {
            _ingredient = _ingredient.slice(1);
        }

        let _textWidth = AppG.window.width;
        if (ScreenSizeUtil.isWebSize()) {
            _textWidth = appS.webMainContentsSize.width;
        }

        if (index == 0) {
            _imageSize = 126;
            _lineNumTitle = 3;
            _lineNumDescription = 2;
            _margin = _margin * 2;
        }

        _textWidth = _textWidth - appS.margins.side * 7 - _imageSize;

        let _source = require('../../resources/images/06.ranking/i_rank_1.png');
        switch (index) {
            case 0:
                _source = require('../../resources/images/06.ranking/i_rank_1.png');
                break;
            case 1:
                _source = require('../../resources/images/06.ranking/i_rank_2.png');
                break;
            case 2:
                _source = require('../../resources/images/06.ranking/i_rank_3.png');
                break;
            default:
                break;
        }

        let _nameMarginLeft = 0;
        if (index == 0) {
            _nameMarginLeft = -_imageSize / 2 + appS.margins.side;
        } else if (index < 3) {
            _nameMarginLeft = -_imageSize + appS.rankingIconSize.normal
        } else {
            _nameMarginLeft = -_imageSize + appS.rankingIconSize.top
        }

        return (
            <View style={{
                backgroundColor: appColors.white,
                margin: appS.margins.side,
                marginTop: index == 0 ? appS.margins.side : appS.margins.side / 2,
                marginBottom: appS.margins.side / 2
            }}>
                <ATagLinkComponent
                    href={`/RecipeDetailScreen/${recipe.id}`}
                    onPress={() => this._goToRecipe(recipe)}
                    view={<View style={{flexDirection: 'row'}}>
                        <Image
                            style={{
                                borderRadius: 5,
                                width: _imageSize,
                                height: _imageSize,
                            }}
                            source={{uri: recipe.images[0].url}}
                        />

                        {/*有償、会員限定アイコン*/}
                        {recipe.productMain != null && this._renderProductIcon(recipe, {width: _imageSize, height: _imageSize}, index)}

                        {index < 3 && (
                            <View style={{
                                position: 'relative',
                                top: -10,
                                left: -_imageSize - appS.rankingIconSize.top / 2 - this._productIconSize,
                                borderRadius: 50,
                                width: appS.rankingIconSize.top,
                                height: appS.rankingIconSize.top,
                            }}>
                                <Image
                                    source={_source}
                                    style={{
                                        width: appS.rankingIconSize.top,
                                        height: appS.rankingIconSize.top,
                                    }}
                                />
                            </View>
                        )}
                        {index >=3 && (
                            <View style={{
                                position: 'relative',
                                top: -10,
                                left: -_imageSize - appS.rankingIconSize.normal / 2 - this._productIconSize,
                                borderRadius: 50,
                                backgroundColor: appColors.rankingIconGray,
                                width: appS.rankingIconSize.normal,
                                height: appS.rankingIconSize.normal,
                            }}>
                                <Text
                                    style={{
                                        marginTop: Platform.OS == 'android'? 3: 2,
                                        alignSelf: 'center',
                                        fontFamily: appFont.family.bold,
                                        fontSize: appFont.size.rankingIcon,
                                        color: appColors.white,
                                        fontWeight: "bold"
                                    }}
                                >{index + 1}</Text>
                            </View>
                        )}

                        <View style={{flexDirection: 'column', marginLeft: _nameMarginLeft, marginTop: _margin}}>
                            <Text
                                style={{
                                    lineHeight: appFont.size.searchResultLineHeight,
                                    height: appFont.size.searchResultLineHeight * _lineNumTitle,
                                    width: _textWidth,
                                    fontFamily: appFont.family.bold,
                                    fontSize: appFont.size.searchResultCellTitle,
                                    color: appColors.fontBlack,
                                    fontWeight: "bold"
                                }}
                                numberOfLines={_lineNumTitle}
                            >{recipe.name}</Text>
                            <Text
                                style={{
                                    marginTop: _margin / 2,
                                    lineHeight: appFont.size.searchResultLineHeight,
                                    height: appFont.size.searchResultLineHeight * _lineNumDescription,
                                    width: _textWidth,
                                    fontFamily: appFont.family.default,
                                    fontSize: appFont.size.searchResultCellDescription,
                                    color: appColors.fontBlack,
                                }}
                                numberOfLines={_lineNumDescription}
                            >材料：{_ingredient}</Text>
                        </View>
                    </View>
                    }
                    style={{flexDirection: 'row', margin: appS.margins.side}}
                />

            </View>
        );
    }

    render() {
        return this._renderMain();
    }
}






