import React from 'react'
import {Dimensions, Image, Platform, SafeAreaView, Text, TouchableOpacity, View} from 'react-native'
import {appColors, appFont, appS} from '../../resources/styles/style'
import {BaseScreen} from "./BaseScreen";
import LoginUtil from "../util/LoginUtil";
import {IndicatorComponent} from "../components/IndicatorComponent";
// @ts-ignore
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scrollview';
import LoginDataDao from "../data/dao/local/LoginDataDao";
import {LoginDataEntityModel} from "../data/entityModels/LoginDataEntityModel";
import {CustomHeaderComponentSmall} from "../components/small/CustomHeaderComponentSmall";
import UrlUtil from "../util/UrlUtil";
// @ts-ignore
import {log} from '../../../environment';
import {ProfileInitScreenModel} from "../data/models/screen/ProfileInitScreenModel";
import {TopicTitleComponent} from "../components/TopicTitleComponent";
import ValidateUtil from "../util/ValidateUtil";
import AppG from "../util/AppG";
import {CheckBoxComponent} from "../components/CheckBoxComponent";
import {SpacerComponent} from "../components/SpacerComponent";
import ScreenSizeUtil from "../util/ScreenSizeUtil";
import {CustomHeaderComponentWide} from "../components/wide/CustomHeaderComponentWide";
import AnalyticsUtil, {AnalyticsEventName, DOpenEventName} from "../util/firebase/AnalyticsUtil";
import LinkUtil from "../util/LinkUtil";

interface Props {
    navigation: any, // ナビゲーション用
}

interface State {
    resizeWindow: boolean,  // 値に意味はなし。windowサイズの拡大縮小をrenderに送るためのもの
    isLoaded: boolean,

    // コンテンツ
    profileScreenModel: ProfileInitScreenModel,
}

export class ProfileInit1Screen extends BaseScreen<Props, State> {
    _resizeTimer = setInterval(() => {
        // window 拡大縮小を反映させる
        AppG.window = Dimensions.get('window');
        if (AppG.window.width != AppG.windowBefore.width || AppG.window.height != AppG.windowBefore.height) {
            this.setState({'resizeWindow': true});
            AppG.windowBefore = Dimensions.get('window');
        }
        LoginUtil.setRefreshedScreenFlg(false);
    }, 100);

    _loginData = new LoginDataEntityModel();

    _flameSize = appS.flame.size;    // 左右の margin
    _marginSize = ScreenSizeUtil.isWebSize()? 0 : appS.margin.size;

    // このScreenへ渡すパラム
    _param: ProfileInitScreenModel = new ProfileInitScreenModel();

    _browserBackListener = (e: any) => {

    };

    constructor(props: any) {
        super(props);
        this.state = {
            resizeWindow: true,  // 値に意味はなし。windowサイズの拡大縮小をrenderに送るためのもの
            isLoaded: false,
            profileScreenModel: new ProfileInitScreenModel(),
        };
    }

    /**
     * 画面開いたときのアナリティクス送信
     */
    _sendPvAnalytics = () => {
        AnalyticsUtil.sendAnalytics(
            DOpenEventName.openPage,
            AnalyticsEventName.openPage,  // アナリティクスのイベント名
            'ProfileInit1Screen',
            null,
            null,
            null,
            null,
        );
    }

    componentDidMount() {
        if (Platform.OS == 'web') {
            window.addEventListener('popstate', this._browserBackListener);
        }

        LoginUtil.interruptOpenScreen(this.props.navigation,
            () => {
                LoginDataDao.get().then(loginData => {
                    // Web 戻るでも呼ばれる
                    if (Platform.OS == 'web') {
                        // Analytics
                        this._sendPvAnalytics();
                    }

                    // URL Scheme で外部リンクから起動したときの処理と、画面遷移でこの画面を開いたときの処理
                    LoginUtil.setDefaultListener(this.props.navigation, () => {
                        // Webはここにこない
                        if (Platform.OS != 'web') {
                            // Analytics
                            this._sendPvAnalytics();
                        }
                    });
                    if (loginData != null) {
                        this._loginData = loginData;
                        this._loadMasters().then(() => {});
                        this._loadContents().then(() => {});
                        this.setState({'isLoaded': true});
                    }
                });
            });

        this.setState({isLoaded: true});
    }

    componentWillUnmount () {
        clearTimeout(this._resizeTimer);

        if (Platform.OS === 'web') {
            window.removeEventListener('popstate', this._browserBackListener);
        }
        LoginUtil.removeDefaultListener(this.props.navigation);
    };

    componentDidUpdate () {
        // レンダー完了時に呼ばれる
    }

    /**
     * パラムの取得
     * @private
     */
    _getParams = () => {
        let profileScreenModel: ProfileInitScreenModel = UrlUtil.getParamModel(new ProfileInitScreenModel(), this.props.navigation);
        return profileScreenModel;
    };

    /**
     * マスターのロード
     * @private
     */
    _loadMasters = async () => {
    };

    /**
     * コンテンツのロード
     * @private
     */
    _loadContents = async () => {
        this.setState({profileScreenModel: this._getParams()});
    };

    /**
     * 選択、未選択時のカラー取得
     * @param selected
     */
    _getButtonBackgroundColor = (selected: boolean) => {
        if (selected) {
            return appColors.black;
        } else {
            return appColors.white;
        }
    };

    /**
     * 選択、未選択時のカラー取得
     * @param selected
     */
    _getButtonTextColor = (selected: boolean) => {
        if (selected) {
            return appColors.white;
        } else {
            return appColors.black;
        }
    };

    /**
     * エラーなら true
     */
    _isError = () => {
        return ValidateUtil.isErrorUserAttribute(this.state.profileScreenModel.userAttribute);
    };

    /**
     * 次のページへ移動
     */
    _goNextPage = () => {
        if (!this._isError()) {
            this.setState({profileScreenModel: this.state.profileScreenModel});
            this.props.navigation.navigate('ProfileInit2Screen', this.state.profileScreenModel);
        }
    };

    _renderMain = () => {
        return (
            <View style={[{flex: 1, flexDirection: 'column'}]}>
                <View style={[{flex: 1, flexDirection: 'column', margin: appS.margins.side}]}>
                    <SpacerComponent height={appS.header.bottomMargin} />
                    {ScreenSizeUtil.isWebSize() && (<Text style={{fontFamily: appFont.family.default, flex: 1, margin: 32, fontWeight: 'bold', color: appColors.black, fontSize: appFont.size.large, textAlign: 'center'}}>プロフィール登録</Text>)}
                    <View style={{alignItems: 'center', justifyContent: 'center'}}>
                        <Image source={require('../../resources/images/profile_init_dots_1.png')}
                               style={[{
                                   marginTop: 5,
                                   width: 104,
                                   height: 8,
                               }]}
                               resizeMode='contain'/>
                    </View>
                    <SpacerComponent height={appS.margins.side} />
                    <TopicTitleComponent title={'飲食関係者ですか？'} />
                    <SpacerComponent height={appS.margins.side} />
                    <Text style={{fontFamily: appFont.family.default, margin: appS.margins.side, color: appColors.fontBlack}}>{`※料理を提供する飲食従事者のこと \n飲食関係者はサンプル依頼機能を利用することができます。`}</Text>

                    <SpacerComponent height={32} />

                    <View style={{flex: 1, margin: appS.margins.sideBigMargin}} >
                        {/*はい*/}
                        <CheckBoxComponent
                            title={'はい'}
                            selected={this.state.profileScreenModel.userAttribute == 'cook'}
                            onPress={() => {
                                this.state.profileScreenModel.userAttribute = 'cook';
                                this.setState({profileScreenModel: this.state.profileScreenModel});
                            }}/>

                        {/*いいえ*/}
                        <CheckBoxComponent
                            title={'いいえ'}
                            selected={this.state.profileScreenModel.userAttribute == 'normal'}
                            onPress={() => {
                                this.state.profileScreenModel.userAttribute = 'normal';
                                this.setState({profileScreenModel: this.state.profileScreenModel});
                            }}/>
                    </View>
                </View>

                {ScreenSizeUtil.isWebSize() && (<SpacerComponent height={32}/>)}

                {/*次へ*/}
                <TouchableOpacity
                    style={[
                        {
                            width: appS.buttonSize.width,
                            height: appS.buttonSize.height,
                            marginBottom: 40,
                            borderWidth: 1,
                            borderColor: appColors.transparent,
                            backgroundColor:  ValidateUtil.isEmptyExact(this.state.profileScreenModel.userAttribute) ? appColors.gray : appColors.buttonOrange,
                            alignItems: 'center',
                            justifyContent: 'center',
                            alignSelf: 'center',
                        }
                    ]}
                    disabled={ValidateUtil.isEmptyExact(this.state.profileScreenModel.userAttribute)}
                    onPress={() => {
                        this._goNextPage();
                    }}
                >
                    <Text style={{
                        fontFamily: appFont.family.bold,
                        color: appColors.white,
                        fontSize: appFont.size.buttonText,
                        fontWeight: "bold"
                    }}>次へ</Text>
                </TouchableOpacity>
            </View>
        );
    }

    _renderWide = () => {
        return (
            <SafeAreaView
                style={[{height: AppG.window.height, flex: 1, flexDirection: 'column', backgroundColor: appColors.white}]}
                onLayout={(event) => {}}
            >
                <CustomHeaderComponentWide logoOnly={true} navigation={this.props.navigation}/>

                <View style={{flex: 1, flexDirection: 'row'}}>
                    <KeyboardAwareScrollView style={[{flex: 1, flexDirection: 'column'}]}>
                        <View style={[{flex: 1, width: 700, alignSelf: 'center'}]}>
                            {this._renderMain()}
                        </View>
                    </KeyboardAwareScrollView>
                </View>

            </SafeAreaView>
        );
    }

    _renderSmall = () => {
        return (
            // appStyles.statusBarHiddenSafeAreaでステータスバー分上に上げる
            <SafeAreaView
                style={[{flex: 1, backgroundColor: appColors.white}]}
                onLayout={(event) => {}}
            >
                <CustomHeaderComponentSmall
                    navigation={this.props.navigation}
                    title={'プロフィール登録'}
                    textColor={appColors.black}
                    barColor={appColors.white}
                    borderBottomColor={appColors.white}
                    leftButton={'none'}
                    rightButton={'none'}
                />
                <View style={{flex: 1, backgroundColor: appColors.backBaseColor}}>
                    {this._renderMain()}
                </View>
            </SafeAreaView>
        );
    }


    /** 描画 */
    render() {
        const {navigation} = this.props;
        if (!this.state.isLoaded) {
            return (
                <View style={{flex: 1}}>
                    <IndicatorComponent
                        failTime={60}
                        isLoading={true}
                        reloadFunc={() => {
                            this.componentWillUnmount();
                            this.componentDidMount();
                        }}/>
                </View>
            );
        }

        LinkUtil.addReturnFalseToATag();
        if (ScreenSizeUtil.isWebSize()) {
            // WEBサイズ
            return this._renderWide();
        } else {
            // SPサイズ
            return this._renderSmall();
        }

    };


}

