import React, {Component} from 'react';
import {Image, Text, TouchableOpacity, View} from 'react-native';
import {appColors, appFont, appS} from "../../resources/styles/style";
import ChatConstant from '../constants/ChatConstant';
// @ts-ignore
import isURL from 'validator/lib/isURL';
import LinkUtil from "../util/LinkUtil";
import ScreenSizeUtil from "../util/ScreenSizeUtil";
import AppG from "../util/AppG";
import DateUtil from "../util/DateUtil";
import Hyperlink from "react-native-hyperlink";

interface Props {
    image: string,
    name: string,
    date: Date,
    text: string,
    file: string,
    messageType: string,    // ChatConstant.MESSAGE_TYPE
    unread: boolean, // 未読
    position: 'left'| 'right',
}

interface State {
}

export class MessageViewComponent extends Component<Props, State> {
    _height = 200;
    _messageAreaWidth = ScreenSizeUtil.isWebSize() ? AppG.window.width - this._height - 300: AppG.window.width - 150;
    _imageHeight = 36;
    _flameSize = appS.flame.size * 2;    // 左右の margin
    _marginSize = ScreenSizeUtil.isWebSize()? 0 : appS.flame.size * 2;

    // @ts-ignore
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
    }

    /**
     * チャットの最後のメッセージを表示
     * @param message
     * @param messageType ChatConstant.MESSAGE_TYPE
     * @param file 画像 or PDF のURL
     * @private
     */
    _writeMessageText = (message: string, messageType: string, file: string) => {

        let _width = AppG.window.width;
        if (ScreenSizeUtil.isWebSize()) {
            _width = appS.webMainContentsSize.width;
        }
        _width = _width - appS.margins.side * 2;

        switch (messageType) {
            case ChatConstant.MESSAGE_TYPE().TEXT:
                if (this.props.position === 'left') {
                    return (
                        <View style={[{flexDirection: 'row', marginLeft: 0}]}>
                            <Image
                                source={(this.props.image != null && this.props.image != '' && isURL(this.props.image, []))?
                                    {uri: this.props.image}: require('../../resources/images/no_image.png')
                                }
                                style={[{
                                    borderRadius: 5,
                                    marginLeft: 0,
                                    width: this._imageHeight,
                                    height: this._imageHeight,
                                    flexDirection: 'row',
                                }]}
                                resizeMode='contain'/>
                            <View style={{marginLeft: 8, flexDirection: "row"}}>
                                <Image
                                    source={require('../../resources/images/07.chat/chat_tail_you.png')}
                                    style={[{
                                        marginTop: 8,
                                        marginRight: -2,
                                        width: 10,
                                        height: 16,
                                        borderColor: appColors.transparent,
                                        zIndex: 1,
                                    }]}
                                    resizeMode='contain'/>
                                <View style={[{
                                    width: _width - appS.chatBubbleSize.marginSizeLeftBubble,
                                    borderRadius: 10,
                                    flexDirection: 'row',
                                    backgroundColor: appColors.chatBubbleLeft,
                                }]}>
                                    <Hyperlink
                                        linkStyle={{
                                            fontFamily: appFont.family.bold,
                                            color: appColors.linkBlue,
                                            fontWeight: "bold",
                                            // @ts-ignore
                                            textDecorationLine: 'underline',
                                    }}
                                        onPress={(url: string, text: string) => {
                                            LinkUtil.openLinkWithDefaultBrowser(url);
                                        }}>
                                        <Text
                                            style={{fontFamily: appFont.family.default, fontSize: appFont.size.chat, color: appColors.fontBlack, width: _width - appS.chatBubbleSize.marginSizeLeftBubble, padding: 8}}
                                        >{message}</Text>
                                    </Hyperlink>
                                    {/*<Text style={{fontFamily: appFont.family.default, fontSize: appFont.size.chat, color: appColors.fontBlack, width: _width - appS.chatBubbleSize.marginSizeLeftBubble, padding: 8}}>{message}</Text>*/}
                                </View>
                            </View>
                            <View style={{flexDirection: 'column-reverse'}}>
                                <Text style={{fontFamily: appFont.family.default, fontSize: appFont.size.chatDate, color: appColors.fontGray, marginLeft: 4, width: appS.chatBubbleSize.dateWidth}}>{this._writeDate(this.props.date)}</Text>
                            </View>
                        </View>
                    );
                }
                return (
                    <View style={[{flexDirection: 'row'}]}>
                        <View style={{flexDirection: 'column-reverse'}}>
                            <Text style={{fontFamily: appFont.family.default, fontSize: appFont.size.chatDate, color: appColors.fontGray, marginLeft: appS.chatBubbleSize.dateWidth + 12 + this._imageHeight, width: appS.chatBubbleSize.dateWidth}}>{this._writeDate(this.props.date)}</Text>
                        </View>
                        <View style={[{
                            width: _width - appS.chatBubbleSize.marginSizeRightBubble,
                            borderRadius: 10,
                            flexDirection: 'row',
                            backgroundColor: appColors.chatBubbleRight,
                        }]}>
                            <Hyperlink
                                linkStyle={{
                                    fontFamily: appFont.family.bold,
                                    color: appColors.white,
                                    fontWeight: "bold",
                                    // @ts-ignore
                                    textDecorationLine: 'underline',
                                }}
                                onPress={(url: string, text: string) => {
                                    LinkUtil.openLinkWithDefaultBrowser(url);
                                }}>
                                <Text
                                    style={{fontFamily: appFont.family.default, fontSize: appFont.size.chat, color: appColors.white, width: _width - appS.chatBubbleSize.marginSizeRightBubble, padding: 8}}
                                >{message}</Text>
                            </Hyperlink>
                            {/*<Text style={{fontFamily: appFont.family.default, fontSize: appFont.size.chat, color: appColors.white, width: _width - appS.chatBubbleSize.marginSizeRightBubble, padding: 8}}>{message}</Text>*/}
                        </View>
                        <Image
                            source={require('../../resources/images/07.chat/chat_tail_me.png')
                            }
                            style={[{
                                marginTop: 8,
                                marginLeft: -2,
                                width: 10,
                                height: 16,
                                borderColor: appColors.transparent,
                            }]}
                            resizeMode='contain'/>
                    </View>
                );
            case ChatConstant.MESSAGE_TYPE().IMAGE:
                const _imageIconWidth = ScreenSizeUtil.isWebSize()? AppG.window.width /3 : AppG.window.width * 2/3;
                if (this.props.position === 'left') {
                    return (
                        <View>
                            <TouchableOpacity
                                style={{width: _imageIconWidth}}
                                onPress={() => {
                                    LinkUtil.openLinkWithDefaultBrowser(file);
                                }}
                            >
                                <Image source={{uri: file}} style={{
                                    width: _imageIconWidth,
                                    height: _imageIconWidth,
                                    borderRadius: 10,
                                    borderWidth: 1,
                                    borderColor: appColors.transparent
                                }}/>
                            </TouchableOpacity>
                        </View>
                    );
                }
                return (
                    <View style={{
                        flex: 1,
                        flexDirection: 'row',
                        alignItems: 'flex-end',
                        marginLeft: ScreenSizeUtil.isWebSize()? - 32 : -64,
                        width: ScreenSizeUtil.isWebSize()? AppG.window.width / 2 : AppG.window.width
                    }}>
                        <View style={{flex: 1}} />
                        <TouchableOpacity
                            style={{width: _imageIconWidth}}
                            onPress={() => {
                                LinkUtil.openLinkWithDefaultBrowser(file);
                            }}
                        >
                            <Image source={{uri: file}} style={{
                                width: _imageIconWidth,
                                height: _imageIconWidth,
                                borderRadius: 10,
                                borderWidth: 1,
                                borderColor: appColors.transparent
                            }}/>
                        </TouchableOpacity>
                    </View>
                );
            // case ChatConstant.MESSAGE_TYPE().PDF:
            //     const _pdfIconWidth = 100;
            //     return (
            //         <View>
            //             <TouchableOpacity
            //                 style={{width: _pdfIconWidth}}
            //                 onPress={() => {
            //                     LinkUtil.openLinkWithDefaultBrowser(file);
            //                 }}
            //             >
            //                 <Image source={require('../../resources/images/pdf_icon_black.png')} style={{width: _pdfIconWidth, height: _pdfIconWidth}}/>
            //                 <Text style={{
            //                     color: appColors.linkGray,
            //                     borderBottomColor: appColors.linkGray,
            //                     fontWeight: 'bold'}}>
            //                     {message}
            //                 </Text>
            //             </TouchableOpacity>
            //         </View>
            //     );
            default:
                return null;
        }
    };

    /**
     * 投稿日時をTEXTに
     * @param lastDate
     * @private
     */
    _writeDate = (lastDate: Date) => {
        return DateUtil.getChatDate(lastDate);
    };

    render() {
        // WEB
        return (
            <View>
                <View style={{flexDirection: 'row', alignItems: 'center', marginTop: 16, marginBottom: 8, width: AppG.window.width, backgroundColor: appColors.white}}>
                    <View style={{flex: 1, flexDirection: 'column'}}>
                        <View style={{flex: 1, flexDirection: 'column', width: this._messageAreaWidth, marginLeft: 8}}>
                            {this._writeMessageText(this.props.text, this.props.messageType, this.props.file)}
                        </View>
                    </View>
                </View>
            </View>
        );
    }
}
