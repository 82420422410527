import React from 'react'
import {Dimensions, Image, Platform, SafeAreaView, Text, TextInput, TouchableOpacity, View} from 'react-native'
import {appColors, appFont, appS} from '../../resources/styles/style'
import {BaseScreen} from "./BaseScreen";
import LoginUtil from "../util/LoginUtil";
// @ts-ignore
import isEmpty from 'validator/lib/isEmpty';
// @ts-ignore
import isEmail from 'validator/lib/isEmail';
// @ts-ignore
import isMultibyte from 'validator/lib/isMultibyte';
import {SpacerComponent} from "../components/SpacerComponent";
import I18n from '../../resources/language/i18n';
import {IndicatorComponent} from "../components/IndicatorComponent";
// @ts-ignore
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scrollview';
// @ts-ignore
import {getEnvVars, log} from '../../../environment';
import ValidateUtil from "../util/ValidateUtil";
import {ValidationErrorMessageComponent} from "../components/ValidattionErrorMessageComponent";
import {CustomHeaderComponentSmall} from "../components/small/CustomHeaderComponentSmall";
import {TopicTitleComponent} from "../components/TopicTitleComponent";
import ExpoUtil from "../util/ExpoUtil";
import AppG from "../util/AppG";
import ScreenSizeUtil from "../util/ScreenSizeUtil";
import {CustomHeaderComponentWide} from "../components/wide/CustomHeaderComponentWide";
import {ModalComponent} from "../components/ModalComponent";
import AnalyticsUtil, {AnalyticsEventName, DOpenEventName} from "../util/firebase/AnalyticsUtil";
import {LoadingFullPageComponent} from "../components/LoadingFullPageComponent";
import {LinkComponent} from '../components/LinkComponent';
import {TutorialModalComponent} from "../components/TutorialModalComponent";
import LinkUtil from "../util/LinkUtil";
import MailErrorInformationUtil from "../util/MailErrorInformationUtil";

interface Props {
    navigation: any, // ナビゲーション用
}

interface State {
    resizeWindow: boolean,  // 値に意味はなし。windowサイズの拡大縮小をrenderに送るためのもの
    isLoaded: boolean,
    isSending: boolean,

    // コンテンツ
    isShowSentModal: boolean,
    isShowPassword: boolean,
    isShowPasswordConfirm: boolean,

    //-------------- Input
    // ログイン用メールアドレス
    mail: {
        text: string,
        errorText: string,
        isError: boolean,
    },
    // パスワード
    password: {
        text: string,
        errorText: string,
        isError: boolean,
    },
    // 確認パスワード
    passwordConfirm: {
        text: string,
        errorText: string,
        isError: boolean,
    },
    // メールの注意文言
    mailInformationMainText: string,
    mailInformationModalText: string,
}

export class SignUpScreen extends BaseScreen<Props, State> {
    _resizeTimer = setInterval(() => {
        // window 拡大縮小を反映させる
        AppG.window = Dimensions.get('window');
        if (AppG.window.width != AppG.windowBefore.width || AppG.window.height != AppG.windowBefore.height) {
            this.setState({'resizeWindow': true});
            AppG.windowBefore = Dimensions.get('window');
        }
        LoginUtil.setRefreshedScreenFlg(false);
    }, 100);

    _flameSize = appS.flame.size;    // 左右の margin
    _marginSize = ScreenSizeUtil.isWebSize()? 0 : appS.margin.size;

    // @ts-ignore
    _env: {
        REACT_APP_PRIVACY_URL: string,
        REACT_APP_TERMS_OF_SERVICE_URL: string,
    } = getEnvVars();

    _browserBackListener = (e: any) => {

    };

    constructor(props: any) {
        super(props);
        this.state = {
            resizeWindow: true,  // 値に意味はなし。windowサイズの拡大縮小をrenderに送るためのもの
            isLoaded: false,
            isSending: false,
            isShowSentModal: false,
            isShowPassword: false,
            isShowPasswordConfirm: false,
            mail: {
                text: '',
                errorText: '',
                isError: false,
            },
            password: {
                text: '',
                errorText: '',
                isError: false,
            },
            passwordConfirm: {
                text: '',
                errorText: '',
                isError: false,
            },
            mailInformationMainText: '',
            mailInformationModalText: ''
        };
    }

    /**
     * 画面開いたときのアナリティクス送信
     */
    _sendPvAnalytics = () => {
        AnalyticsUtil.sendAnalytics(
            DOpenEventName.openPage,
            AnalyticsEventName.openPage,  // アナリティクスのイベント名
            'SignUpScreen',
            null,
            null,
            null,
            null,
        );
    }

    componentDidMount() {
        this._getMailInformation();

        if (Platform.OS == 'web') {
            window.addEventListener('popstate', this._browserBackListener);
        }

        // Web 戻るでも呼ばれる
        if (Platform.OS == 'web') {
            // Analytics
            this._sendPvAnalytics();
        }

        // URL Scheme で外部リンクから起動したときの処理
        LoginUtil.setDefaultListener(
            this.props.navigation,
            () => {
                // Webはここにこない
                if (Platform.OS != 'web') {
                    // Analytics
                    this._sendPvAnalytics();
                }

                ExpoUtil.doReloadAppTab(() => {

                });
            });

        this.setState({mail: {text: '', errorText: '', isError: false}});
        this.setState({password: {text: '', errorText: '', isError: false}});

        this.setState({isLoaded: true});
    }

    componentWillUnmount () {
        clearTimeout(this._resizeTimer);
        if (Platform.OS === 'web') {
            window.removeEventListener('popstate', this._browserBackListener);
        }
        LoginUtil.removeDefaultListener(this.props.navigation);
    };

    componentDidUpdate () {
        // レンダー完了時に呼ばれる
    }

    /**
     * メール注意文言の取得
     */
    _getMailInformation = () => {
        const func = (main: string, modal: string) => {
            this.setState({mailInformationMainText: main});
            this.setState({mailInformationModalText: modal});
        }
        MailErrorInformationUtil.get(func);
    }

    /**
     * ログイン処理
     * @param mail  メールアドレス
     * @param password  パスワード
     * @param passwordConfirm   確認パスワード
     * @private
     */
    _sendSignUp = (mail: string, password: string, passwordConfirm: string) => {
        let hasError: boolean = false;
        // Mail
        if(ValidateUtil.isEmptyExact(mail)) {
            this.setState({mail: {text: this.state.mail.text, errorText: `${I18n.t('pleasEnterAnItem', {item: `${I18n.t('mail')}`})}`, isError: true}});
            hasError = true;
        }
        if(!isEmail(mail)) {
            this.setState({mail: {text: this.state.mail.text, errorText: `${I18n.t('errorMail')}`, isError: true}});
            hasError = true;
        }
        // Password
        if (ValidateUtil.isEmptyExact(password)) {
            this.setState({password: {text: this.state.password.text, errorText: `${I18n.t('pleasEnterAnItem', {item: `${I18n.t('password')}`})}`, isError: true}});
            hasError = true;
        }
        if (isMultibyte(password) || !ValidateUtil.isPassword(password)) {
            this.setState({password: {text: this.state.password.text, errorText: `${I18n.t('incorrectPassword')}`, isError: true}});
            hasError = true;
        }

        // PasswordConfirm
        if (password != passwordConfirm) {
            this.setState({passwordConfirm: {text: this.state.passwordConfirm.text, errorText: `パスワードと確認パスワードが違います`, isError: true}});
            hasError = true;
        }

        if (hasError) {
            // エラー
            return;
        }

        this.setState({isSending: true});
        const successFunc = () => {
            //アナリティクス送信
            AnalyticsUtil.sendAnalytics(
                DOpenEventName.sentMailBeforeSignUp,
                AnalyticsEventName.sentMailBeforeSignUp,  // アナリティクスのイベント名
                'SignUpScreen',
                null,
                null,
                null,
                null,
            );
            this.setState({'isShowSentModal': true});
            this.setState({isSending: false});
        };

        LoginUtil.signUp(mail, password, passwordConfirm, successFunc, () => {this.setState({'isSending': false})});
    };

    _renderMain = () => {
        return (
            <View style={[{flex: 1}]}>
                <KeyboardAwareScrollView style={[{flex: 1, flexDirection: 'column'}]}>
                    {Platform.OS == 'web' && (
                        <View style={{alignSelf: 'center', marginTop: 8}}>
                            <Text style={{fontFamily: appFont.family.default, fontSize: 24, margin: 24, textAlign: 'center', color: appColors.black}} numberOfLines={1}>新規登録</Text>
                        </View>
                    )}

                    <SpacerComponent height={20} />
                    <View style={{}}>
                        <Text style={{fontFamily: appFont.family.default, fontSize: 14, textAlign: 'center', color: appColors.red}}>{`${this.state.mailInformationMainText}`}</Text>
                    </View>
                    <SpacerComponent height={20} />

                    {/*メルアドログイン*/}
                    <View style={[{flex: 1}]}>
                        {/*メールアドレス*/}
                        <TopicTitleComponent title={'メールアドレス'}/>
                        <View style={[appS.marginCenter]}>
                            <TextInput
                                style={[appS.inputText, {}]}
                                autoCapitalize={"none"}
                                placeholder={'　example@mail.com'}
                                placeholderTextColor={appColors.gray}
                                value={this.state.mail.text}
                                onChangeText={text => this.setState({mail: {text: text, errorText: this.state.mail.errorText, isError: this.state.mail.isError}})}
                            />
                            <ValidationErrorMessageComponent
                                text={this.state.mail.errorText}
                                isError={this.state.mail.isError}
                            />
                        </View>
                        <SpacerComponent height={30}/>
                        {/*パスワード*/}
                        <TopicTitleComponent title={'パスワード'}/>
                        <View style={[appS.marginCenter]}>
                            <TextInput
                                style={[appS.inputText, {}]}
                                secureTextEntry={!this.state.isShowPassword}
                                autoCapitalize={"none"}
                                placeholder={'　パスワード（半角英数字と長さ6文字以上）'}
                                placeholderTextColor={appColors.gray}
                                value={this.state.password.text}
                                onChangeText={text => this.setState({password: {text: text, errorText: this.state.password.errorText, isError: this.state.password.isError}})}
                            />
                            <TouchableOpacity
                                style={[{
                                    alignSelf: "flex-end",
                                    position: "relative",
                                    top: -32,
                                    left: -8,
                                    zIndex: 1,
                                    marginBottom: -32,
                                }]}
                                onPress={() => {
                                    this.setState({isShowPassword: !this.state.isShowPassword});
                                }}
                            >
                                {this.state.isShowPassword && (
                                    <Image source={require('../../resources/images/eye_hide_icon.png')}
                                           style={[{
                                               height: 24,
                                               width: 24,
                                           }]}
                                           resizeMode={'cover'}
                                    />
                                )}
                                {!this.state.isShowPassword && (
                                    <Image source={require('../../resources/images/eye_show_icon.png')}
                                           style={[{
                                               height: 24,
                                               width: 24,
                                           }]}
                                           resizeMode={'cover'}
                                    />
                                )}
                            </TouchableOpacity>
                            <ValidationErrorMessageComponent
                                text={this.state.password.errorText}
                                isError={this.state.password.isError}
                            />
                        </View>
                        <SpacerComponent height={appS.spacer.height.small * 2}/>
                        {/*確認パスワード*/}
                        <View style={[appS.marginCenter]}>
                            <TextInput
                                style={[appS.inputText, {}]}
                                secureTextEntry={!this.state.isShowPasswordConfirm}
                                autoCapitalize={"none"}
                                placeholder={'　確認パスワード'}
                                placeholderTextColor={appColors.gray}
                                value={this.state.passwordConfirm.text}
                                onChangeText={text => this.setState({passwordConfirm: {text: text, errorText: this.state.passwordConfirm.errorText, isError: this.state.passwordConfirm.isError}})}
                            />
                            <TouchableOpacity
                                style={[{
                                    alignSelf: "flex-end",
                                    position: "relative",
                                    top: -32,
                                    left: -8,
                                    zIndex: 1,
                                    marginBottom: -32,
                                }]}
                                onPress={() => {
                                    this.setState({isShowPasswordConfirm: !this.state.isShowPasswordConfirm});
                                }}
                            >
                                {this.state.isShowPasswordConfirm && (
                                    <Image source={require('../../resources/images/eye_hide_icon.png')}
                                           style={[{
                                               height: 24,
                                               width: 24,
                                           }]}
                                           resizeMode={'cover'}
                                    />
                                )}
                                {!this.state.isShowPasswordConfirm && (
                                    <Image source={require('../../resources/images/eye_show_icon.png')}
                                           style={[{
                                               height: 24,
                                               width: 24,
                                           }]}
                                           resizeMode={'cover'}
                                    />
                                )}
                            </TouchableOpacity>
                            <ValidationErrorMessageComponent
                                text={this.state.passwordConfirm.errorText}
                                isError={this.state.passwordConfirm.isError}
                            />
                        </View>
                        <SpacerComponent height={appS.spacer.height.small*2}/>
                    </View>

                </KeyboardAwareScrollView>

                {ScreenSizeUtil.isWebSize() && (<SpacerComponent height={appS.spacer.height.small * 4}/>)}

                {/*無料会員登録ボタン*/}
                <TouchableOpacity
                    style={[
                        {
                            height: appS.buttonSize.height,
                            width: appS.buttonSize.width,
                            backgroundColor:  (ValidateUtil.isEmptyExact(this.state.mail.text) || ValidateUtil.isEmptyExact(this.state.password.text) || ValidateUtil.isEmptyExact(this.state.passwordConfirm.text)) ? appColors.gray : appColors.buttonOrange,
                            alignItems: 'center',
                            justifyContent: 'center',
                            alignSelf: 'center',
                        }
                    ]}
                    disabled={(ValidateUtil.isEmptyExact(this.state.mail.text) || ValidateUtil.isEmptyExact(this.state.password.text) || ValidateUtil.isEmptyExact(this.state.passwordConfirm.text))}
                    onPress={() => {
                        // 無料会員登録
                        this._sendSignUp(this.state.mail.text, this.state.password.text, this.state.passwordConfirm.text);
                    }}
                >
                    <Text style={{
                        fontFamily: appFont.family.bold,
                        color: appColors.white,
                        fontSize: appFont.size.buttonText,
                        fontWeight: "bold"
                    }}>送信する</Text>
                </TouchableOpacity>

                {/*無料会員登録でできることは？*/}
                <View style={{flexDirection: 'row', marginTop: 8, marginBottom: this._marginSize, marginRight: ScreenSizeUtil.isWebSize()? 0 : this._marginSize}}>
                    <View style={{flex: 1}}/>
                    <LinkComponent
                        title={'無料会員登録でできることは？'}
                        color={appColors.linkBlue}
                        textDecorationLine={'underline'}
                        fontSize={14}
                        onPress={() => {
                            LinkUtil.openUrlInWebView(
                                'https://wp.recibase.net/introduction-member/',
                                this.props.navigation,
                                '無料会員登録でできることは？'
                                );
                        }}/>
                </View>

                <SpacerComponent height={appS.footer.height}/>
            </View>
        );
    }

    _renderWide = () => {
        return (
            <SafeAreaView
                style={[{height: AppG.window.height, flex: 1, flexDirection: 'column', backgroundColor: appColors.white}]}
                onLayout={(event) => {}}
            >
                <CustomHeaderComponentWide logoOnly={true} navigation={this.props.navigation}/>

                <View style={{flex: 1, flexDirection: 'row'}}>
                    <KeyboardAwareScrollView style={[{flex: 1, flexDirection: 'column'}]}>
                        <View style={[{flex: 1, width: 700, alignSelf: 'center'}]}>
                            {/*メイン*/}
                            {this._renderMain()}
                        </View>
                    </KeyboardAwareScrollView>

                    {/*登録完了Modal*/}
                    <ModalComponent
                        title={'登録確認メールを送信しました'}
                        text={`${this.state.mailInformationModalText}`}
                        buttonTitle={'ログイン画面へ'}
                        isShowModal={this.state.isShowSentModal}
                        onButtonPress={() => {
                            // 閉じてログイン画面に戻る
                            this.setState({'isShowSentModal': false});
                            this.props.navigation.navigate('LoginScreen');
                        }}/>

                    {/*閉じるボタン*/}
                    {Platform.OS == 'web' && (
                        <View style={{zIndex: 2, position: 'absolute', right: 0, flexDirection: "row", marginTop: 32, marginRight: 32}}>
                            <View style={{flex: 1}}/>
                            <TouchableOpacity
                                onPress={() => {
                                    // ログインせずにHomeへ
                                    // this.props.navigation.navigate('HomeScreen');
                                    ExpoUtil.goBack(this.props.navigation);
                                }}
                            >
                                <Image source={require('../../resources/images/close_icon.png')}
                                       style={[{
                                           backgroundColor: 'white',
                                           width: 24,
                                           height: 24,
                                       }]}
                                       resizeMode={'cover'}
                                />
                            </TouchableOpacity>
                        </View>
                    )}
                </View>

                {this.state.isSending && (
                    <LoadingFullPageComponent/>
                )}
            </SafeAreaView>
        );
    }

    _renderSmall = () => {
        return (
            <SafeAreaView
                style={[{flex: 1, backgroundColor: appColors.white}]}
                onLayout={(event) => {}}
            >
                <CustomHeaderComponentSmall
                    navigation={this.props.navigation}
                    title={'新規登録'}
                    textColor={appColors.fontBlack}
                    barColor={appColors.white}
                    leftButton={'back'}
                />
                <View style={{flex: 1, backgroundColor: appColors.backBaseColor}}>
                    {/*メイン*/}
                    {this._renderMain()}
                </View>
                {/*登録完了Modal*/}
                <ModalComponent
                    height={300}
                    title={'登録確認メールを送信しました'}
                    text={`${this.state.mailInformationModalText}`}
                    buttonTitle={'ログイン画面へ'}
                    isShowModal={this.state.isShowSentModal}
                    onButtonPress={() => {
                        // 閉じてログイン画面に戻る
                        this.setState({'isShowSentModal': false});
                        this.props.navigation.navigate('LoginScreen');
                    }}/>

                {this.state.isSending && (
                    <LoadingFullPageComponent/>
                )}
            </SafeAreaView>
        );
    }

    /** 描画 */
    render() {
        // const {navigation} = this.props;
        if (!this.state.isLoaded) {
            return (
                <View style={{flex: 1}}>
                    <IndicatorComponent
                        failTime={60}
                        isLoading={true}
                        reloadFunc={() => {
                            this.componentWillUnmount();
                            this.componentDidMount();
                        }}/>
                </View>
            );
        }

        LinkUtil.addReturnFalseToATag();
        if (ScreenSizeUtil.isWebSize()) {
            // WEBサイズ
            return this._renderWide();
        } else {
            // SPサイズ
            return this._renderSmall();
        }



    };


}
