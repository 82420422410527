import {Platform} from "react-native";

class CookieUtil {

    /**
     * 指定したkeyのcookieを取得、有効期限切れや見つからないときは null を返す
     * @param key
     */
    getCookie = (key:string) => {
        if (Platform.OS != 'web') {
            return null;
        }
        const cookies = document.cookie; //全てのcookieを取り出して
        const cookiesArray = cookies.split(';'); // ;で分割し配列に
        let cookie = "";

        for(const c of cookiesArray) { //一つ一つ取り出して
            let cArray = c.split('='); //さらに=で分割して配列に
            if(cArray[0].trim() == key){ // 取り出したいkeyと合致したら
                if(cArray[1] != null) {
                    cookie = cArray[1];
                    return cookie;
                }
            }
        }

        return null;
    }

}

export default new CookieUtil();
