// @ts-ignore
import { createStyles, maxWidth } from 'react-native-media-queries';

import {
    Platform, Dimensions, ViewStyle, TextStyle,
} from 'react-native';
import AppG from "../../tsx/util/AppG";
import ExpoUtil from "../../tsx/util/ExpoUtil";

const _statusBarHeight: number = Platform.OS === 'ios' ? 20 : 0;
// const textInput = {height: 40};

export const appFont = {
    size: {
        list: 16,
        default: 14,
        touch: 16,
        small: 12,
        tab: 11,    // タブのサイズ
        tabButton: 16,  // ボタン型のタブサイズ
        topic: 22,  // トピックの大きさ
        subTopic: 18,   // トピックの次の大きさ
        iconFontSize: 10,   // 有償・会員限定
        listDescription: 12, // レシピ一覧の description サイズ
        moreLink: 14,   // もっと見る のサイズ
        buttonText: 16, // ボタンのサイズ
        buttonSmallText: 12, // ボタンのサイズ small
        checkboxText: Platform.OS == 'web'? 13: 14,
        searchArea: 14, // フリーワード検索エリアのサイズ
        keywordSize: 12,    // キーワード一覧のサイズ
        headerTitle: 18, // ヘッダーのタイトルサイズ
        headerIconFontSize: 8, // ヘッダーアイコンのフォントサイズ
        searchResultCellTitle: 16,  // 検索結果Cellのタイトル
        searchResultCellDescription: 12,    // 検索結果Cellの説明
        searchProductCellDescription: 10,    // 検索結果Cellの説明（内容量）
        searchResultLineHeight: 20,     // 検索結果Cellの文字の高さ
        recipeName: 18,
        productName: 18,
        recipeDescription: 14,
        productDescription: 14,
        recipeProductName: 16,  // レシピ関連商品
        recipeProductDescription: 12,   // レシピ関連商品の説明
        productCode: 12,
        recipeSeason: 14,
        chat: 14,
        chatDate: 12,
        sampleReviewStatusLabel: 11,    // サンプル依頼履歴の右上のラベル
        coin: 14,
        popupText: 16,
        rankingIconTop: 16,
        rankingIcon: 12,
        menuProfileName: 16,
        menuRestaurant: 10,
        menu: 13,
        popupTitle: 18,  // ポップアップのタイトル
        searchCount: 10,    // 検索件数
        popupAnswerBtn: 14,    // ポップアップの回答
        popupAnswer: 12,    // ポップアップの回答





        middle: 20,
        large: 24,
        xlarge: 26,
        link: 12,
        error: 14,
    },
    family : {
        default: Platform.OS === 'web' ? 'sans-serif' : 'NotoSansJP-Regular',
        bold: Platform.OS === 'web' ? 'sans-serif' : 'NotoSansJP-Bold',
    }
};

export const appColors = {
    white: '#FFFFFF',
    black: '#3B4043',   // 文字以外の黒
    gray: '#CFCCC7',
    red: '#C20027',
    coin: '#E4C00E',
    tabGray: '#AAAAAA',
    borderGray: '#F0EEEB',
    backBaseColor: '#FCFCFC',   // 背景色
    actionColor: '#FF8C41', // ボタンとかアクションできるカラー
    fontBlack: '#3B4043',
    fontGray: '#818180',
    creamYellow: '#FCEFCF',  // 注目のキーワードのバックグラウンドなど
    limeGreen: '#83A700',   // 会員限定マーク
    goldYellow: '#DEA300',    // 有償マーク
    // contentBackgroundGray: '#EEECE9',  // 新着レシピなどの背景色
    recipeHeader: '#DEA300',
    productHeader: '#A59B1B',
    buttonOrange: '#FF8C41',
    keywordBackground: '#EEECE9',   // キーワード一覧のバックグラウンド
    recipeSearchResultBackground: '#F8F1ED',    // レシピ一覧のバックグラウンド
    productSearchResultBackground: '#F8F1ED',    // 商品一覧のバックグラウンド
    pickerBorderGray: '#D1D8DE',    // pickerの枠
    searchMenuBorder: '#D1D8DE',    // 検索条件のboxの枠
    searchBarBackground: '#F2F2F2',  // 検索バーのバックグラウンド
    recipeRequestLight: '#DEA300',  // 今すぐサンプル依頼のレシピ画面のボタン
    recipeRequestDark: '#977518',   // サンプルカゴに入れるレシピ画面のボタン
    productRequestLight: '#A59B1B',  // 今すぐサンプル依頼の商品画面のボタン
    productRequestDark: '#5B7400',   // サンプルカゴに入れる商品画面のボタン
    chatBubbleLeft: '#EEECE9',
    chatBubbleRight: '#FF8C41',
    chatInputMessageBackGround: '#EEECE9',  // チャットメッセージ入力欄
    sampleReviewFinishedLabel: '#CCCCCC',   // レビュー済みラベルの色
    sampleReviewCanceledLabel: '#CCCCCC',   // 出荷キャンセルの色
    sampleReviewRequestedLabel: '#FFE600',   // 出荷中ラベルの色
    reviewBackground: '#F2F2F2',    // レビュー画面の背景色
    subTopicBackground: '#F2F2F2',  // レシピ検索条件などのサブトピックの背景色
    rankingIconGray: '#999999', // ランキングの4位以下のアイコンの色
    spacerGray: '#CCCCCC',
    borderWithKeywordGray: '#F0EEEB',   // 注目のキーワードのボーダー
    noUserBackground: '#FEF7D0',    // 未ログインユーザー画像出したときの背景色
    dotsGray: '#999999',    // 特集のdotsのgray





    pink: '#EE8383',
    backgroundGray: '#FBFBFB',
    linkBlue: '#4FADEA',
    linkGray: '#7F7F7F',
    lightGray: '#CED6DC',
    rejectGray: '#A6A6A6',
    keywordButtonGray: '#F4F5F0', // RecipeDetailScreen内、「材料」の偶数行の色と同じ
    orange: '#D96010',
    darkGray: '#7F7F7F',
    menuGray: '#F2F4F7',
    opacityGray: 'rgba(0,0,0,0.4)',
    selectBoxGray: 'rgba(0,0,0,0.1)',
    tomato: '#ff6347',
    yellow: '#F6C343',
    gold: '#B89230',
    apple: '#000000',
    facebook: '#3A5998',
    twitter: '#1DA1F2',
    google: '#DB4E40',
    transparent: 'transparent', // 透明
    bubbleBorder: '#E2E8ED',
    indicator: '#808080',
    indicatorWhite: '#FFFFFF',
    predictiveConversion: '#00A34B',    // 予測変換
    recipeScreenLight0: '#748D1A',
    recipeScreenDark0: '#4C5C12',
    recipeDownloadButton: '#3B4043',
    recipeScreenLight1: '#CB662C',
    recipeScreenDark1: '#8E3400',
    recipeScreenLight2: '#A79B1B',
    recipeScreenDark2: '#655D00',
    productScreenLight: '#AE062A',
    productScreenDark: '#88001B',
    makerScreenLight: '#FFFFFF',
    makerScreenDark: '#FFFFFF',
    sampleRequestScreenLight: '#AE062A',
    sampleRequestScreenDark: '#AE062A',
    switchBackgroundTrue: '#C87486',
    switchBackgroundFalse: '#8E8E8E',
    switchCircleTrue: '#AE062A',
    switchCircleFalse: '#FFFFFF',
    ecButton: '#D5324D',
};

const base = {
    // スクリーンサイズのTypeを取得
    screenSize: {
        borderLine: 1024, // スマホ縦向き <= 1024 px
        fullHeight: Platform.OS === 'web' ? '100vh' : '100%',
    },
    webMainContentsSize: {  // Webのメインコンテンツエリアのサイズ
        width: 750,
    },
    webSideContentsSize: {
        width: 300,
    },
    webAllContentsSize: {   // 左右とマージンの合計
        width: 1080,
    },
    // 画面左右のマージン
    margins: {
        side: 10,
        sideBigMargin: 38,
        betweenContentSmall: 10,
        betweenTopic: 20,
        webBetweenMargin: 30, // webのサイドとメインの間のmargin
    },
    keywordAreaSize: {  // Webのメインコンテンツエリアのサイズ
        width: 300
    },
    buttonSize: {
        height: 50,
        width: 240,
        radius: 10,
    },
    header: {
        statusBarHeight: _statusBarHeight,
        wideMenuIconSize: 40,
        menuIconSize: 20,
        bellIconSize: 24,
        shareIconSize: 20,
        backIconSize: 14,
        bottomMargin: 24,
        height: 50,
        // height: 70, // 60
        tabHeight: 40,
        webUpsideHeight: 66,    // web ヘッダー上
        webBottomHeight: 80,   // web ヘッダー下
        webHeight: 66 + 80, // 上2つの合計
    },
    footer: {
        width: AppG.window.width,
        height: 70, // 60
        scrollBottom: 50,
    },
    chatBubbleSize: {
        dateWidth: 40,
        marginSizeLeftBubble: 136,
        marginSizeRightBubble: 136,
    },
    sampleReviewStatusLabel: {
        width: 90,
        height: 18,
    },
    checkBox: {
        bottomMargin: 17,
    },
    rankingIconSize: {
        top: 27,
        normal: 23,
    },
    coin: {
        iconSize: 16,
    },
    menu: {
        iconSize: 16,
    },
    logo: {
        width: 67,
        height: 20
    },

    // こっから普通のstyle
    buttonAllow: {
        marginTop: Platform.OS == 'android'? 5 : 7,
        width: 12,
        height: 12,
    },
    moreAllow: {
        marginTop: Platform.OS == 'android'? 4 : 5,
        marginRight: 10,
        width: 12,
        height: 12,
    },
    popup: {
        width: 500,
        height: 600,
        answerWidth: 420,
    },





    mainArea: {
        scrollAreaSize: {
            height: AppG.window.height,
        },
        adSize : {
            height: 300,
        },
    },
    activityIndicator: {
        height: 40,
    },
    margin: {
        size: 32,   // コンテンツ毎のmargin
    },
    flame: {
        size: 138,   // 上下左右のmargin
    },
    line: {
        space: 0,
        titleSpace: 4,
    },
    spacer: {
        height: {
            small: 8,
            row: 6, // 行間
        },
        width: {
            small: 8,
            row: 6, // 行間
        }
    },
    keyword: {
        height: 40,
        text: 16,
    },
    button: {
        height: 48,
        text: 16,
        followIconSizeSmall: 20,
        followIconSizeLarge: 30,
    },

    noItemIcon: {
        width: 96,
        height: 96,
    },

    // こっから普通のstyle
    inputText: {
        fontFamily: appFont.family.default,
        color: appColors.fontBlack,
        height: 40,
        // borderRadius: 10,
        backgroundColor: appColors.white,
        borderWidth: 1,
        borderColor: appColors.borderGray,
        padding: 0,
        paddingLeft: 8,
    },
    marginCenter: {
        marginLeft: 16,
        marginRight: 16,
    },
    picker: {
        inputIOS: {
            textAlign: 'center',
            fontSize: 16,
            fontFamily: appFont.family.default,
            paddingVertical: 12,
            paddingHorizontal: 10,
            borderWidth: 1,
            borderColor: appColors.pickerBorderGray,
            // borderRadius: 4,
            color: appColors.fontBlack,
            paddingRight: 30, // to ensure the text is never behind the icon
        },
        inputAndroid: {
            textAlign: 'center',
            fontSize: 16,
            fontFamily: appFont.family.default,
            paddingHorizontal: 10,
            paddingVertical: 8,
            borderWidth: 1,
            borderColor: appColors.pickerBorderGray,
            // borderRadius: 8,
            color: appColors.fontBlack,
            paddingRight: 30, // to ensure the text is never behind the icon
            backgroundColor:appColors.selectBoxGray,
        },
        inputWeb: {
            textAlign: 'center',
            fontSize: 16,
            fontFamily: appFont.family.default,
            paddingVertical: 12,
            paddingHorizontal: 10,
            borderWidth: 1,
            borderColor: appColors.pickerBorderGray,
            // borderRadius: 4,
            color: appColors.fontBlack,
            paddingRight: 30, // to ensure the text is never behind the icon
        },
    },
    sortPicker: {
        inputIOS: {
            textAlign: 'center',
            fontSize: 11,
            fontFamily: appFont.family.default,
            paddingVertical: 5,
            paddingHorizontal: 10,
            borderWidth: 1,
            borderColor: appColors.pickerBorderGray,
            // borderRadius: 4,
            color: appColors.fontBlack,
            paddingRight: 30, // to ensure the text is never behind the icon
        },
        inputAndroid: {
            textAlign: 'center',
            fontSize: 11,
            fontFamily: appFont.family.default,
            paddingHorizontal: 10,
            paddingVertical: 8,
            borderWidth: 1,
            borderColor: appColors.pickerBorderGray,
            // borderRadius: 8,
            color: appColors.fontBlack,
            paddingRight: 30, // to ensure the text is never behind the icon
            backgroundColor:appColors.selectBoxGray,
        },
        inputWeb: {
            textAlign: 'center',
            fontSize: 11,
            fontFamily: appFont.family.default,
            paddingVertical: 12,
            paddingHorizontal: 10,
            borderWidth: 1,
            borderColor: appColors.pickerBorderGray,
            // borderRadius: 4,
            color: appColors.fontBlack,
            paddingRight: 30, // to ensure the text is never behind the icon
        },
    },
    statusBarHidden: {
        height: _statusBarHeight,
    },
    statusBarHiddenSafeArea: {
        marginTop: -_statusBarHeight,
    },
};

const small = {
    keywordAreaSize: {  // Webのメインコンテンツエリアのサイズ
        width: 300,
    },
    buttonSize: {
        height: 50,
        width: 240,
        radius: 10,
    },
    footer: {
        width: AppG.window.width,
        height: 50, // 44
        scrollBottom: 180, // Platform.OS == 'web'? 0: 80,
    },
    popup: {
        width: 320,
        height: 500,
        answerWidth: 240,
    },
    logo: {
        width: 67,
        height: 20
    },





    mainArea: {
        scrollAreaSize: {
            height: AppG.window.height,
        },
        adSize : {
            height: 50,
        },
    },

    line: {
        space: 0,
        titleSpace: 4,
    },
    margin: {
        size: 16,   // コンテンツ毎のmargin
    },
    flame: {
        size: 16,   // 上下左右のmargin
    },

    // こっから普通のstyle
    sideMenu: {
        display: "none",
    },
};

export const appS = createStyles(
    base,
    // スマホ縦向き <= 1024 px
    maxWidth(1024, small),
);
