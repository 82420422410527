import {FirestoreBaseModel} from "./FirestoreBaseModel";

export class Messages implements FirestoreBaseModel {
    // @ts-ignore
    data : {
        userId: string, // 送信ユーザーのID array
        messageType: string, // ChatConstant.MESSAGE_TYPE()
        text: string,
        url: string, // image や pdf の時利用
        isShow: boolean,
    };
}
