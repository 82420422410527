import React, {Component} from 'react';
import {Image, Platform, Text, TouchableOpacity, View} from 'react-native';
import {appColors, appFont, appS} from "../../resources/styles/style";
import AppG from "../util/AppG";
import ScreenSizeUtil from "../util/ScreenSizeUtil";
import ValidateUtil from "../util/ValidateUtil";
import {SampleRequestScreenModel} from "../data/models/screen/SampleRequestScreenModel";
import AlertUtil from "../util/AlertUtil";
import {SpacerComponent} from "./SpacerComponent";

interface Props {
    navigation: any,
    noButton: boolean,
}

interface State { }

export class NoUserImageComponent extends Component<Props, State> {

    // Propsのデフォルト値
    static defaultProps = {
        noButton: false,
    };

    render() {
        const { routeName } = this.props.navigation.state;
        let _width = AppG.window.width;
        if (ScreenSizeUtil.isWebSize()) {
            _width = appS.webMainContentsSize.width;
        }
        _width = _width - appS.margins.side * 2;

        let noUserImage = require('../../resources/images/blur/no_user_service.jpg');
        let imageHeight = _width*0.67;

        if (this.props.noButton) {
            return (
                <TouchableOpacity
                    style={{alignItems: "center"}}
                    onPress={() => {
                        this.props.navigation.navigate('SignUpScreen');
                    }}
                >
                    <Image source={require('../../resources/images/blur/no_user_service.jpg')}
                           style={[{
                               backgroundColor: 'white',
                               width: _width,
                               height: _width,
                           }]}
                           resizeMode={'contain'}
                    />
                </TouchableOpacity>
            );
        }

        // 画面ごとのSPでの画像出しわけ用
        if (!ScreenSizeUtil.isWebSize()) {
            switch( routeName ) {
                case 'HomeScreen':
                    noUserImage = require('../../resources/images/blur/no_user_ranking.jpg');
                    imageHeight = _width*0.85;
                    break;
                // case 'FollowScreen':
                //     noUserImage = require('../../resources/images/blur/no_user_favorite.jpg');
                //     imageHeight = _width*1.69;
                //     break;
                // case 'CartScreen':
                //     noUserImage = require('../../resources/images/blur/no_user_cart.jpg');
                //     imageHeight = _width*1.69;
                //     break;
                // case 'ChatsScreen':
                //     noUserImage = require('../../resources/images/blur/no_user_chats.jpg');
                //     imageHeight = _width*1.69;
                //     break;
                // case 'SampleRequestHistoryScreen':
                //     noUserImage = require('../../resources/images/blur/no_user_sample_request_history.jpg');
                //     imageHeight = _width*1.69;
                //     break;
                default:
                    noUserImage = require('../../resources/images/blur/no_user_service_old.png');
                    imageHeight = _width;
                    break;
            }
        }

        if (ScreenSizeUtil.isWebSize()) {
            return (
                <View style={{alignItems: "center"}}>
                    <TouchableOpacity
                        onPress={() => {
                            this.props.navigation.navigate('SignUpScreen');
                        }}
                    >
                        <Image source={ noUserImage }
                            style={[{
                                backgroundColor: 'white',
                                width: _width,
                                height: imageHeight,
                            }]}
                            resizeMode={'contain'}
                        />
                    </TouchableOpacity>

                    <SpacerComponent height={10}/>

                    {/* <TouchableOpacity
                        style={[
                            {
                                width: appS.buttonSize.width,
                                height: appS.buttonSize.height,
                                backgroundColor: appColors.buttonOrange,
                                alignItems: 'center',
                                alignSelf: 'center',
                                justifyContent: 'center',
                            }
                        ]}
                        onPress={() => {
                            this.props.navigation.navigate('SignUpScreen');
                        }}
                    >
                        <View style={{flexDirection: 'row'}}>
                            <Text style={{fontFamily: appFont.family.bold, color: appColors.white, fontSize: appS.button.text}}>{'会員登録はこちら'}</Text>
                            <Image
                                source={require('../../resources/images/02.menu_general/arrow_W_right.png')}
                                style={{
                                    marginTop: Platform.OS == 'android'? 17 : 7,
                                    width: 12,
                                    height: 12,
                                }}
                            />
                        </View>
                    </TouchableOpacity> */}

                    <SpacerComponent height={60}/>
                    <Text style={{fontFamily: appFont.family.bold, color: appColors.fontBlack, fontSize: 15, fontWeight: "bold"}}>{'既に会員の方はこちら'}</Text>
                    <SpacerComponent height={15}/>
                    <TouchableOpacity
                        style={[
                            {
                                width: appS.buttonSize.width,
                                height: appS.buttonSize.height,
                                backgroundColor: appColors.buttonOrange,
                                alignItems: 'center',
                                alignSelf: 'center',
                                justifyContent: 'center',
                                borderRadius: 50
                            }
                        ]}
                        onPress={() => {
                            this.props.navigation.navigate('LoginScreen');
                        }}
                    >
                        <View style={{flexDirection: 'row'}}>
                            <Text style={{fontFamily: appFont.family.bold, color: appColors.white, fontSize: appS.button.text, fontWeight: "bold"}}>{'ログイン'}</Text>
                            <Image
                                source={require('../../resources/images/02.menu_general/arrow_W_right.png')}
                                style={{
                                    marginTop: Platform.OS == 'android'? 5 : 7,
                                    width: 12,
                                    height: 12,
                                }}
                            />
                        </View>
                    </TouchableOpacity>
                </View>
            );
        } else {
            return (
                <View style={{alignItems: "center"}}>
                    <TouchableOpacity
                        onPress={() => {
                            this.props.navigation.navigate('SignUpScreen');
                        }}
                    >
                        <Image source={ noUserImage }
                            style={[{
                                backgroundColor: 'white',
                                width: _width,
                                height: imageHeight,
                            }]}
                            resizeMode={'contain'}
                        />
                    </TouchableOpacity>

                    <SpacerComponent height={10}/>

                    {/* <TouchableOpacity
                        style={[
                            {
                                width: appS.buttonSize.width,
                                height: appS.buttonSize.height,
                                backgroundColor: appColors.buttonOrange,
                                alignItems: 'center',
                                alignSelf: 'center',
                                justifyContent: 'center',
                            }
                        ]}
                        onPress={() => {
                            this.props.navigation.navigate('SignUpScreen');
                        }}
                    >
                        <View style={{flexDirection: 'row'}}>
                            <Text style={{fontFamily: appFont.family.bold, color: appColors.white, fontSize: appS.button.text}}>{'会員登録はこちら'}</Text>
                            <Image
                                source={require('../../resources/images/02.menu_general/arrow_W_right.png')}
                                style={{
                                    marginTop: Platform.OS == 'android'? 17 : 7,
                                    width: 12,
                                    height: 12,
                                }}
                            />
                        </View>
                    </TouchableOpacity> */}

                    <SpacerComponent height={60}/>
                    <Text style={{fontFamily: appFont.family.bold, color: appColors.fontBlack, fontSize: 15, fontWeight: "bold"}}>{'既に会員の方はこちら'}</Text>
                    <SpacerComponent height={15}/>
                    <TouchableOpacity
                        style={[
                            {
                                width: appS.buttonSize.width,
                                height: appS.buttonSize.height,
                                backgroundColor: appColors.buttonOrange,
                                alignItems: 'center',
                                alignSelf: 'center',
                                justifyContent: 'center',
                                borderRadius: 50
                            }
                        ]}
                        onPress={() => {
                            this.props.navigation.navigate('LoginScreen');
                        }}
                    >
                        <View style={{flexDirection: 'row'}}>
                            <Text style={{fontFamily: appFont.family.bold, color: appColors.white, fontSize: appS.button.text, fontWeight: "bold"}}>{'ログイン'}</Text>
                            <Image
                                source={require('../../resources/images/02.menu_general/arrow_W_right.png')}
                                style={{
                                    marginTop: Platform.OS == 'android'? 5 : 7,
                                    width: 12,
                                    height: 12,
                                }}
                            />
                        </View>
                    </TouchableOpacity>
                </View>
            );
        }
    }
}
