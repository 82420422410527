import React, {Component} from 'react';
import {Image, ImageStyle, Platform, StyleProp, Text, TouchableOpacity, View} from 'react-native';
import {appColors, appFont, appS} from "../../resources/styles/style";
import {FollowApiFactory, Product} from "../data/network/swagger-gen";
// @ts-ignore
import isURL from 'validator/lib/isURL';
import ValidateUtil from "../util/ValidateUtil";
import ExpoUtil from "../util/ExpoUtil";
import AppG from "../util/AppG";
import ScreenSizeUtil from "../util/ScreenSizeUtil";
import {ProductDetailScreenModel} from "../data/models/screen/ProductDetailScreenModel";
import AnalyticsUtil, {AnalyticsEventName, DOpenEventName} from "../util/firebase/AnalyticsUtil";
import AppGlobalDataUtil from "../util/AppG";
import {ATagLinkComponent} from "./ATagLinkComponent";

interface Props {
    navigation: any,
    products: Product[],
    analyticsBtnName: string | null,
    analyticsFollowScreenName: string,
    follow: boolean,
    enableATag: boolean,
    countProducts: number | null,
}

interface State {}

export class ProductSearchResultListComponent extends Component<Props, State> {


    // Propsのデフォルト値
    static defaultProps = {
        analyticsBtnName: null,
        follow: false,
        analyticsFollowScreenName: '',
        enableATag: true,
        countProducts: null,
    };

    _renderMain = () => {
        let {navigation, products} = this.props;
        let _width = AppG.window.width;
        if (ScreenSizeUtil.isWebSize()) {
            _width = appS.webMainContentsSize.width;
        }

        return (
            <View style={{
                flex: 1,
                flexDirection: "column",
                width: _width
            }}>
                {this.props.countProducts != null && (
                    <View style={{flexDirection: 'row', height: 20}}>
                        <View style={{flex: 1}}/>
                        <Text style={{
                            fontFamily: appFont.family.default,
                            fontSize: appFont.size.searchCount,
                            marginRight: appS.margins.side,
                            backgroundColor: appColors.transparent,
                            marginTop: Platform.OS == 'android'? 0 : 4,
                            marginBottom: -appS.margins.side - 2,
                        }}>{this.props.countProducts}件</Text>
                    </View>
                )}
                <View style={{
                    flex: 1,
                    flexDirection: "column",
                    width: _width,
                    // margin: appS.margins.side,
                    marginLeft: 0,
                    marginRight: 0,
                    backgroundColor: (this.props.countProducts == null || this.props.countProducts == 0)? appColors.transparent : appColors.productSearchResultBackground,
                }}>

                    {!ValidateUtil.isEmptyArray(products) &&
                        products.map((recipe, index) => {
                            return (
                                <ProductCellSearchResultComponent
                                    navigation={navigation}
                                    product={recipe}
                                    key={`recipe_${index}`}
                                    index={index}
                                    analyticsBtnName={this.props.analyticsBtnName}
                                    follow={this.props.follow}
                                    analyticsFollowScreenName={this.props.analyticsFollowScreenName}
                                    enableATag={this.props.enableATag}
                                />
                            );
                        })}
                </View>
            </View>
        );
    }


    render() {
        return this._renderMain();
    }
}







interface ProductCellRankingProps {
    navigation: any,
    product: Product,
    index: number,
    analyticsBtnName: string | null,
    follow: boolean,
    analyticsFollowScreenName: string,
    enableATag: boolean,
}

interface ProductCellRankingState {
    followed: boolean,
}


class ProductCellSearchResultComponent extends Component<ProductCellRankingProps, ProductCellRankingState> {
    _productIconSize = 37;

    constructor(props: any) {
        super(props);
        this.state = {
            followed: this.props.product.followed == 1,  // 1ならお気に入り
        };
    };

    _goToProduct = (product: Product) => {
        if (this.props.analyticsBtnName != null) {
            AppG.screenHistory.unshift(`${this.props.analyticsBtnName}`);
        }

        // 画面遷移前にリロード対象にセットする
        ExpoUtil.setShouldReloadAppTab('self');
        let productDetailScreenModel = new ProductDetailScreenModel();
        productDetailScreenModel.productId = product.id.toString();
        if (Platform.OS == 'web') {
            this.props.navigation.navigate('ProductDetailScreen', productDetailScreenModel);
        } else {
            this.props.navigation.push('ProductDetailScreen', productDetailScreenModel);
        }
    }

    /**
     * 商品アイコンの作成
     * @param product
     * @param imageSize {width: number, height: number}
     * @private
     */
    _renderProductIcon = (product: Product, imageSize: {width: number, height: number}) => {

        let _userPlanOnly = product.userPlanOnly;
        let _samplePaid = product.samplePaid;
        // _userPlanOnly = 1;   // テスト用
        // _samplePaid = 1;    // テスト用

        const _userPlanHeight = 16;
        const _productIconMargin = 4;
        const _style: StyleProp<ImageStyle> = {
            zIndex: 1,
            position: 'relative',
            top: 0,
            left: -imageSize.width,
            width: imageSize.width,
            height: this._productIconSize,
            backgroundColor: appColors.transparent,
        };

        return (
            <View style={_style}>
                <View style={{flexDirection: 'row'}}>
                    <View style={{width: imageSize.width - (Platform.OS == 'web'? 60 : 57)}}/>
                    <View
                        style={{
                            flexDirection: 'row-reverse',
                            height: _userPlanHeight,
                            borderRadius: 5,
                            marginLeft: 4,
                            backgroundColor: _userPlanOnly == 1? appColors.limeGreen: appColors.transparent,
                        }}
                    >
                        <Text style={{fontFamily: appFont.family.default, fontSize: appFont.size.iconFontSize, color: _userPlanOnly == 1? appColors.white: appColors.transparent, marginLeft: 8, marginRight: 8, marginTop: Platform.OS == 'android'? -6 : 0}}>会員限定</Text>
                    </View>
                </View>

                <View style={{
                    zIndex: 1,
                    position: 'relative',
                    top: imageSize.height - this._productIconSize - _userPlanHeight,
                    width: imageSize.width,
                    height: this._productIconSize,
                    backgroundColor: appColors.transparent,
                    flexDirection: "row",
                }}>
                    <View
                        style={{
                            marginRight: 8,
                            marginTop: imageSize.height - 70,
                            width: 30,
                            height: _userPlanHeight,
                            borderRadius: 5,
                            backgroundColor: _samplePaid == 1? appColors.goldYellow: appColors.transparent,
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: "row"
                        }}
                    >
                        <Text style={{fontFamily: appFont.family.bold, fontSize: 9, color: _samplePaid == 1? appColors.white: appColors.transparent, fontWeight: "bold"}}>有償</Text>
                    </View>
                    <View style={{flex:1}}/>
                    <Image source={require('../../resources/images/skelton.png')}
                           style={{
                               width: this._productIconSize,
                               height: this._productIconSize,
                               borderRadius: 50,
                               alignItems: 'center',
                               justifyContent: 'center',
                               marginRight: _productIconMargin,
                               marginTop: -_productIconMargin,
                               borderColor: appColors.transparent,
                               backgroundColor: appColors.transparent,
                           }}
                           resizeMode={'cover'}
                    />
                </View>
            </View>
        );
    }

    _renderMain = () => {
        const {product, index, follow, analyticsFollowScreenName, enableATag} = this.props;

        const _imageSize = 90;
        let _textWidth = AppG.window.width;
        if (ScreenSizeUtil.isWebSize()) {
            _textWidth = appS.webMainContentsSize.width;
        }
        _textWidth = _textWidth - appS.margins.side * 7 - _imageSize;

        const _followIconSize = appS.button.followIconSizeSmall;

        return (
            <View style={{
                margin: appS.margins.side,
                backgroundColor: appColors.white,
                marginTop: index == 0 ? appS.margins.side * 2 : appS.margins.side,
                marginBottom: appS.margins.side,
            }}>
                <ATagLinkComponent
                    enableATag={enableATag}
                    href={`/ProductDetailScreen/${product.id}`}
                    style={{flexDirection: 'row', margin: appS.margins.side}}
                    onPress={() => this._goToProduct(product)}
                    view={
                        <View style={{flexDirection: 'row'}}>
                            <Image
                                style={{
                                    borderRadius: 5,
                                    width: _imageSize,
                                    height: _imageSize,
                                }}
                                source={{uri: product.images[0].url}}
                            />

                            {/*有償、会員限定アイコン*/}
                            {this._renderProductIcon(product, {width: _imageSize, height: _imageSize})}

                            {/*お気に入りボタン*/}
                            {follow && (
                                <TouchableOpacity
                                    style={{
                                        zIndex: 1,
                                        position: 'relative',
                                        top: -10,
                                        left: -_imageSize * 2 - appS.rankingIconSize.top / 2 + 5,
                                        borderRadius: 50,
                                        width: appS.rankingIconSize.top,
                                        height: appS.rankingIconSize.top,
                                    }}
                                    disabled={AppG.user == null}
                                    onPress={() => {
                                        if (AppG.user == null) {
                                            return;
                                        }

                                        let type = 'follow';
                                        if (this.state.followed) {
                                            type = 'unfollow'
                                        }
                                        FollowApiFactory(AppGlobalDataUtil.getConfiguration(), AppGlobalDataUtil.getBasePath())
                                            .followProduct(product!.id.toString(), type).then(() => {
                                            // Analytics
                                            AnalyticsUtil.sendAnalytics(
                                                type == 'follow'? DOpenEventName.follow : DOpenEventName.unfollow,
                                                type == 'follow'? AnalyticsEventName.follow : AnalyticsEventName.unfollow,  // アナリティクスのイベント名
                                                analyticsFollowScreenName,
                                                null,
                                                product,
                                                product?.maker,
                                                null,
                                            );

                                            ExpoUtil.setShouldReloadAppTab();   // 別タブで必要ならリロードするようにセット
                                            this.setState({followed: !this.state.followed});
                                        });
                                    }}
                                >
                                    {/*フォロー中*/}
                                    {this.state.followed && (
                                        <Image source={AppG.user == null ? require('../../resources/images/skelton.png') : require('../../resources/images/03.item_recipe/i_item_like_on.png')}
                                               style={[{
                                                   backgroundColor: appColors.transparent,
                                                   width: _followIconSize,
                                                   height: _followIconSize,
                                               }]}
                                               resizeMode={'cover'}
                                        />
                                    )}
                                    {/*フォローしてない*/}
                                    {!this.state.followed && (
                                        <Image source={AppG.user == null ? require('../../resources/images/skelton.png') : require('../../resources/images/03.item_recipe/i_item_like_off.png')}
                                               style={[{
                                                   backgroundColor: appColors.transparent,
                                                   width: _followIconSize,
                                                   height: _followIconSize,
                                               }]}
                                               resizeMode={'cover'}
                                        />
                                    )}

                                </TouchableOpacity>
                            )}
                            <View style={{flexDirection: 'column', marginLeft: appS.margins.side - _imageSize}}>
                                <Text
                                    style={{
                                        lineHeight: appFont.size.searchResultLineHeight,
                                        height: appFont.size.searchResultLineHeight,
                                        width: _textWidth,
                                        fontFamily: appFont.family.bold,
                                        fontSize: appFont.size.searchResultCellTitle,
                                        color: appColors.fontBlack,
                                        fontWeight: "bold"
                                    }}
                                    numberOfLines={1}
                                >{product.name}</Text>
                                <Text
                                    style={{
                                        marginTop: 4,
                                        lineHeight: appFont.size.searchResultLineHeight,
                                        height: appFont.size.searchResultLineHeight,
                                        width: _textWidth,
                                        fontFamily: appFont.family.default,
                                        fontSize: appFont.size.searchProductCellDescription,
                                        color: appColors.fontGray,
                                    }}
                                    numberOfLines={1}
                                >{`内容量：${product.capacity}`}</Text>
                                <Text
                                    style={{
                                        marginTop: 4,
                                        lineHeight: appFont.size.searchResultLineHeight,
                                        height: appFont.size.searchResultLineHeight * 2,
                                        width: _textWidth,
                                        fontFamily: appFont.family.default,
                                        fontSize: appFont.size.searchResultCellDescription,
                                        color: appColors.fontBlack,
                                    }}
                                    numberOfLines={2}
                                >{product.description}</Text>
                            </View>
                        </View>
                    }
                />

            </View>
        );
    }

    render() {
        return this._renderMain();
    }
}






