import {Special} from "../data/network/swagger-gen";
import AnalyticsUtil, {AnalyticsEventName, DOpenEventName} from "./firebase/AnalyticsUtil";
import {Platform} from "react-native";
import AppG from "./AppG";
import LinkUtil from "./LinkUtil";

class SpecialUtil {

    /**
     * 特集を開くアナリティクスを送信
     * @param special
     */
    analyticsSpecial = (special: Special | null = null) => {
        // 履歴追加
        AppG.screenHistory.unshift(special == null? '【特集】バックナンバー' : '【特集】' + special.url);
        AnalyticsUtil.sendAnalytics(
            DOpenEventName.openPage,
            AnalyticsEventName.openPage,  // アナリティクスのイベント名
            'SpecialScreen',
            null,
            null,
            null,
            special == null? 'バックナンバー' : null,
            special == null? 'https://wp.recibase.net/' : null,
            special
        );
    };

    /**
     * 特集を開く
     * @param special
     * @param navigation
     */
    openSpecial = (special: Special, navigation: any) => {
        let _url = special.url.trim();
        if (Platform.OS != 'web') {
            _url = `${_url}?header=1`;
        }
        // if (special.type == SpecialTypeEnum.Premium) {
        //     if (AppG.user != null && AppG.user.userPlan == UserUserPlanEnum.Premium) {
        //         this.analyticsSpecial(special);
        //         LinkUtil.openUrlInWebView(_url, navigation, special.name);
        //     }
        // } else {
            this.analyticsSpecial(special);
            LinkUtil.openUrlInWebView(_url, navigation, special.name);
        // }
    }
}

export default new SpecialUtil();
