import React from 'react'
import {
    Dimensions,
    Image,
    Keyboard,
    Platform,
    SafeAreaView,
    Text,
    TextInput,
    TouchableOpacity,
    TouchableWithoutFeedback,
    View
} from 'react-native'
import {appColors, appFont, appS} from '../../resources/styles/style'
import {BaseScreen} from "./BaseScreen";
import LoginUtil from "../util/LoginUtil";
import {IndicatorComponent} from "../components/IndicatorComponent";
// @ts-ignore
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scrollview';
import {LoginDataEntityModel} from "../data/entityModels/LoginDataEntityModel";
import {CustomHeaderComponentSmall} from "../components/small/CustomHeaderComponentSmall";
// @ts-ignore
import {log} from '../../../environment';
import {TopicTitleComponent} from "../components/TopicTitleComponent";
import ValidateUtil from "../util/ValidateUtil";
import {ValidationErrorMessageComponent} from "../components/ValidattionErrorMessageComponent";
import AppG from "../util/AppG";
import ScreenSizeUtil from "../util/ScreenSizeUtil";
import {CustomHeaderComponentWide} from "../components/wide/CustomHeaderComponentWide";
import {SpacerComponent} from "../components/SpacerComponent";
import AnalyticsUtil, {AnalyticsEventName, DOpenEventName} from "../util/firebase/AnalyticsUtil";
import I18n from "../../resources/language/i18n";
// @ts-ignore
import isEmail from 'validator/lib/isEmail';
import {LoadingFullPageComponent} from "../components/LoadingFullPageComponent";
import LinkUtil from "../util/LinkUtil";

interface Props {
    navigation: any, // ナビゲーション用
}

interface State {
    resizeWindow: boolean,  // 値に意味はなし。windowサイズの拡大縮小をrenderに送るためのもの
    isLoaded: boolean,
    isSendTapped: boolean,
    isShowModal: boolean,

    // input
    // 現在のパスワード
    mail: {
        text: string,
        errorText: string,
        isError: boolean,
    },
}

export class ResetPasswordScreen extends BaseScreen<Props, State> {
    _resizeTimer = setInterval(() => {
        // window 拡大縮小を反映させる
        AppG.window = Dimensions.get('window');
        if (AppG.window.width != AppG.windowBefore.width || AppG.window.height != AppG.windowBefore.height) {
            this.setState({'resizeWindow': true});
            AppG.windowBefore = Dimensions.get('window');
        }
        LoginUtil.setRefreshedScreenFlg(false);
    }, 100);

    _loginData = new LoginDataEntityModel();

    _flameSize = appS.flame.size;    // 左右の margin

    _browserBackListener = (e: any) => {

    };

    constructor(props: any) {
        super(props);
        this.state = {
            resizeWindow: true,  // 値に意味はなし。windowサイズの拡大縮小をrenderに送るためのもの
            isLoaded: true,
            isSendTapped: false,
            isShowModal: false,
            mail: {
                text: '',
                errorText: '',
                isError: false,
            },
        };
    }

    /**
     * 画面開いたときのアナリティクス送信
     */
    _sendPvAnalytics = () => {
        AnalyticsUtil.sendAnalytics(
            DOpenEventName.openPage,
            AnalyticsEventName.openPage,  // アナリティクスのイベント名
            'ResetPasswordScreen',
            null,
            null,
            null,
            null,
        );
    }

    componentDidMount() {
        if (Platform.OS == 'web') {
            window.addEventListener('popstate', this._browserBackListener);
        }

        this._sendPvAnalytics();


        this.setState({mail: {text: '', errorText: '', isError: false}});
        this.setState({isLoaded: true});
    }

    componentWillUnmount () {
        clearTimeout(this._resizeTimer);
        if (Platform.OS === 'web') {
            window.removeEventListener('popstate', this._browserBackListener);
        }
        LoginUtil.removeDefaultListener(this.props.navigation);
    };

    componentDidUpdate () {
        // レンダー完了時に呼ばれる
    }

    /**
      * パラムの取得
      * @private
      */
    _getParams = () => {
    };

    /**
     * マスターのロード
     * @private
     */
    _loadMasters = async () => {
    };

    /**
     * コンテンツのロード
     * @private
     */
    _loadContents = async () => {
    };

    /**
     * エラーなら true
     */
    _isError = () => {
        let _error: boolean = false;

        if (ValidateUtil.isEmptyExact(this.state.mail.text)) {
            this.setState({mail: {text: this.state.mail.text, errorText: `${I18n.t('pleasEnterAnItem', {item: `${I18n.t('password')}`})}`, isError: true}});
            _error = true;
        }
        if (!isEmail(this.state.mail.text)) {
            this.setState({mail: {text: this.state.mail.text, errorText: `${I18n.t('incorrectMail')}`, isError: true}});
            _error = true;
        }

        return _error;
    };

    /**
     * 次のページへ移動
     */
    _goNextPage = () => {
        if (!this._isError()) {
            this.setState({isSendTapped: true});
            LoginUtil.resetPassword(
                this.state.mail.text,
                () => {
                    this.setState({isSendTapped: false});
                    this.setState({isShowModal: true});
                },
                () => {
                    this.setState({isSendTapped: false});
                })
        }
    };

    _renderModal = () => {

        const _modal = () => {
            let _width = ScreenSizeUtil.isWebSize()? AppG.window.width *2 / 5 : AppG.window.width *4 / 5;

            if (Platform.OS == 'web' && !ScreenSizeUtil.isWebSize()) {
                // Webのスマホサイズ
                _width = AppG.window.width * 4 / 5;
            }

            return (
                <View style={{
                    height: 300,
                    width: _width,
                    backgroundColor: appColors.white,
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    <SpacerComponent height={40}/>
                    <View style={{alignItems: 'center'}}>
                        <Text style={{fontFamily: appFont.family.bold, fontSize: appFont.size.middle, textAlign: 'center', color: appColors.fontBlack, fontWeight: "bold"}}>{`メールアドレスに\nパスワード再設定通知\nを送信しました`}</Text>
                        <Image source={require('../../resources/images/complete_alert_icon.png')}
                               style={[{
                                   width: 129,
                                   height: 129,
                               }]}
                               resizeMode='cover'/>
                    </View>
                    <SpacerComponent height={40}/>
                </View>
            );
        }

        return (
            <View style={{zIndex: 99, position: 'absolute', backgroundColor: appColors.opacityGray, flexDirection: 'row'}}>
                <TouchableOpacity
                    style={{
                        zIndex: 99,
                        position: 'absolute',
                        backgroundColor: appColors.opacityGray,
                        height: AppG.window.height,
                        width: AppG.window.width
                    }}
                    onPress={() => {
                        this.setState({isShowModal: false});
                        this.props.navigation.navigate('LoginScreen');
                    }}
                />
                <View style={{
                    position: 'absolute',
                    zIndex: 100,
                    padding: 32,
                    width: AppG.window.width * 2 / 5 + 64,
                    top: AppG.window.height / 2 - 150,
                    left: AppG.window.width * 1.5 / 5 - 32,
                    backgroundColor: appColors.transparent,
                    alignItems: 'center',
                }}>
                    {_modal()}
                </View>
            </View>
        )
    }

    _renderMain = () => {
        return (
            <View style={[{flex: 1, flexDirection: 'column'}]}>

                <View style={[{flex: 1, flexDirection: 'column'}]}>
                    {ScreenSizeUtil.isWebSize() && (<Text style={{flex: 1, margin: 32, fontFamily: appFont.family.bold,  color: appColors.fontBlack, fontSize: appFont.size.buttonText, textAlign: 'center', fontWeight: "bold"}}>パスワード再送信</Text>)}

                    <SpacerComponent height={40} />

                    {/*メールアドレス*/}
                    <TopicTitleComponent title={'メールアドレス'} />
                    {/*パスワード*/}
                    <View style={[appS.marginCenter]}>
                        <TextInput
                            style={[appS.inputText, {}]}
                            autoCapitalize={"none"}
                            placeholder={'　メールアドレス'}
                            placeholderTextColor={appColors.gray}
                            value={this.state.mail.text}
                            onChangeText={text =>
                                this.setState({mail: {text: text, errorText: this.state.mail.errorText, isError: this.state.mail.isError}})
                            }
                        />
                        <ValidationErrorMessageComponent
                            text={this.state.mail.errorText}
                            isError={this.state.mail.isError}
                        />
                    </View>

                    <SpacerComponent height={appS.spacer.height.small * 4}/>

                </View>

                {ScreenSizeUtil.isWebSize() && (<SpacerComponent height={64}/>)}

                {/*送信する*/}
                <TouchableOpacity
                    style={[
                        appS.marginCenter,
                        {
                            marginBottom: 40,
                            height: appS.buttonSize.height,
                            width: appS.buttonSize.width,
                            borderWidth: 1,
                            borderColor: appColors.transparent,
                            backgroundColor:  appColors.buttonOrange,
                            alignItems: 'center',
                            justifyContent: 'center',
                            alignSelf: 'center',
                        }
                    ]}
                    onPress={() => {
                        this._goNextPage();
                    }}
                >
                    <Text style={{
                        fontFamily: appFont.family.bold,
                        color: appColors.white,
                        fontSize: appFont.size.buttonText,
                        fontWeight: "bold"
                    }}>送信する</Text>
                </TouchableOpacity>
            </View>
        );
    };

    _renderWide = () => {
        return (
            <SafeAreaView
                style={[{height: AppG.window.height, flex: 1, flexDirection: 'column', backgroundColor: appColors.white}]}
                onLayout={(event) => {}}
            >
                <CustomHeaderComponentWide logoOnly={true} navigation={this.props.navigation}/>

                <View style={{flex: 1, flexDirection: 'row'}}>
                    <KeyboardAwareScrollView style={[{flex: 1, flexDirection: 'column'}]}>
                        <View style={[{flex: 1, width: 700, alignSelf: 'center'}]}>
                            {this._renderMain()}
                        </View>
                    </KeyboardAwareScrollView>
                </View>
                {this.state.isSendTapped && (
                    <LoadingFullPageComponent/>
                )}
                {this.state.isShowModal && this._renderModal()}

            </SafeAreaView>
        );
    }

    _renderSmall = () => {
        return (
            <SafeAreaView
                style={[{flex: 1, backgroundColor: appColors.white}]}
                onLayout={(event) => {}}
            >
                <CustomHeaderComponentSmall
                    navigation={this.props.navigation}
                    title={'パスワード再送信'}
                    textColor={appColors.black}
                    barColor={appColors.white}
                    borderBottomColor={appColors.white}
                    leftButton={'back'}
                    rightButton={'none'}
                />
                <View style={{flex: 1, backgroundColor: appColors.backBaseColor}}>
                    {this._renderMain()}
                    {this.state.isSendTapped && (
                        <LoadingFullPageComponent/>
                    )}
                </View>
                {this.state.isShowModal && this._renderModal()}
            </SafeAreaView>
        );
    }

    /** 描画 */
    render() {
        const {navigation} = this.props;
        if (!this.state.isLoaded) {
            return (
                <View style={{flex: 1}}>
                    <IndicatorComponent
                        failTime={60}
                        isLoading={true}
                        reloadFunc={() => {
                            this.componentWillUnmount();
                            this.componentDidMount();
                        }}/>
                </View>
            );
        }

        LinkUtil.addReturnFalseToATag();
        if (ScreenSizeUtil.isWebSize()) {
            // WEBサイズ
            return this._renderWide();
        } else {
            // SPサイズ

            if (Platform.OS == 'web') {
                return this._renderSmall();
            }

            return (
                <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
                    {this._renderSmall()}
                </TouchableWithoutFeedback>
            );
        }

    };


}
