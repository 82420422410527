import React from 'react'
import {
    Alert,
    Dimensions,
    Image,
    Platform,
    SafeAreaView,
    Text,
    TextInput,
    TextStyle,
    TouchableOpacity,
    View
} from 'react-native'
import {appColors, appFont, appS} from '../../resources/styles/style'
import {BaseScreen} from "./BaseScreen";
import LoginUtil from "../util/LoginUtil";
import {IndicatorComponent} from "../components/IndicatorComponent";
// @ts-ignore
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scrollview';
import LoginDataDao from "../data/dao/local/LoginDataDao";
import {LoginDataEntityModel} from "../data/entityModels/LoginDataEntityModel";
import {CustomHeaderComponentSmall} from "../components/small/CustomHeaderComponentSmall";
// @ts-ignore
import {log} from '../../../environment';
import {ProfileInitScreenModel} from "../data/models/screen/ProfileInitScreenModel";
import ValidateUtil from "../util/ValidateUtil";
import RNPickerSelect, {PickerStyle} from "react-native-picker-select";
import PickerUtil from "../util/PickerUtil";
import {PullDownIconComponent} from "../components/PullDownIconComponent";
import {ValidationErrorMessageComponent} from "../components/ValidattionErrorMessageComponent";
import I18n from "../../resources/language/i18n";
import AddressApiNetwork from "../data/network/vendor/AddressApiNetwork";
import {AddressModel} from "../data/models/vender/AddressModel";
import {
    CustomerUnitPriceId,
    ImageApiFactory,
    ImageFile,
    MajorRestaurantAreaId,
    MasterApiFactory,
    NumOfRestaurantId,
    RestaurantFormat,
    RestaurantLocation,
    User,
    UserApiFactory,
    UserGenderEnum,
    UserUserAttributeEnum,
    UserUserPlanEnum
} from "../data/network/swagger-gen";
import AppGlobalDataUtil from "../util/AppG";
import AppG from "../util/AppG";
import {AxiosResponse} from "axios";
import moment from "moment";
import AlertUtil from "../util/AlertUtil";
import ScreenSizeUtil from "../util/ScreenSizeUtil";
import {CustomHeaderComponentWide} from "../components/wide/CustomHeaderComponentWide";
import {Switch} from 'react-native-switch';
// @ts-ignore
import isURL from 'validator/lib/isURL';
import ImageUtil from "../util/ImageUtil";
import * as ImagePicker from "expo-image-picker";
// @ts-ignore
import Modal, {ModalContent, SlideAnimation} from 'react-native-modals';
import {SpacerComponent} from "../components/SpacerComponent";
import Constants from 'expo-constants';
import * as MediaLibrary from 'expo-media-library';
import AnalyticsUtil, {AnalyticsEventName, DOpenEventName} from "../util/firebase/AnalyticsUtil";
import ExpoUtil from "../util/ExpoUtil";
import * as Notifications from "expo-notifications";
import {Camera} from "expo-camera";
import LinkUtil from "../util/LinkUtil";


interface Props {
    navigation: any, // ナビゲーション用
}

interface State {
    resizeWindow: boolean,  // 値に意味はなし。windowサイズの拡大縮小をrenderに送るためのもの
    isLoaded: boolean,
    isShowPhotoBottomModal: boolean,

    // コンテンツ
    user: User | null,
    pickerGender: {label: string, value: any, key: string}[], // pickerに表示する性別
    restaurantLocationMasters: RestaurantFormat[],   // 店舗所在地マスター
    pickerRestaurantLargeLocationInitKey: string | undefined, // 店舗業態（大）選択用key
    pickerRestaurantMediumLocationInitKey: string | undefined, // 店舗業態（中）選択用key
    pickerRestaurantLargeLocationId: {label: string, value: any, key: string}[], // pickerに表示する店舗業態（大）
    pickerRestaurantMediumLocationId: {label: string, value: any, key: string}[], // pickerに表示する店舗業態（中）
    pickerState: {label: string, value: any, key: string}[], // pickerに表示する都道府県
    stateInitKey: string | undefined, // 都道府県のコード選択用key
    restaurantFormatLargeInitKey: string | undefined, // 店舗業態（大）選択用key
    restaurantFormatMediumInitKey: string | undefined, // 店舗業態（中）選択用key
    customerUnitPriceInitKey: string | undefined, // 客単価選択用key
    numOfRestaurantInitKey: string | undefined, // 店舗規模（店舗数）選択用key
    majorRestaurantAreaInitKey: string | undefined, // 店舗展開エリア用key
    restaurantFormatMasters: RestaurantFormat[],   // 店舗業態マスター
    pickerRestaurantFormatLarge: {label: string, value: any, key: string}[], // pickerに表示する店舗業態（大）
    pickerRestaurantFormatMedium: {label: string, value: any, key: string}[], // pickerに表示する店舗業態（中）
    customerUnitPriceIdMasters: CustomerUnitPriceId[],  // 客単価マスター
    pickerCustomerUnitPriceId: {label: string, value: any, key: string}[], // pickerに表示する客単価
    numOfRestaurantIdMasters: NumOfRestaurantId[],  // 店舗規模マスター
    pickerNumOfRestaurantId: {label: string, value: any, key: string}[], // pickerに表示する店舗規模
    majorRestaurantAreaIdMasters: MajorRestaurantAreaId[],    // 店舗展開エリアマスター
    pickerMajorRestaurantAreaIds: {label: string, value: any, key: string}[], // pickerに表示する店舗展開エリア

    // input
    mail: string | null,   // メールアドレス
    userPlan: UserUserPlanEnum, // ユーザープラン
    userAttribute: UserUserAttributeEnum,   // ユーザー属性
    // 名前
    name: {
        text: string,
        errorText: string,
        isError: boolean,
    },
    // 名前(かな)
    nameKana: {
        text: string,
        errorText: string,
        isError: boolean,
    },
    gender: '' | UserGenderEnum | undefined, // 性別
    // 生年月日
    // yyyy
    yyyy: string,
    // mm
    mm: string,
    // dd
    dd: string,
    // 店舗名
    nameRestaurant: {
        text: string,
        errorText: string,
        isError: boolean,
    },
    // 郵便番号上3桁
    postalCode1: {
        text: string,
        errorText: string,
        isError: boolean,
    },
    // 郵便番号下4桁
    postalCode2: {
        text: string,
        errorText: string,
        isError: boolean,
    },
    restaurantLargeLocationId: number | null, // 店舗所在地(大)
    // restaurantMediumLocationId: number | null, // 店舗所在地(中)
    // 都道府県
    state: string | null,
    // 市町村
    city: {
        text: string,
        errorText: string,
        isError: boolean,
    },
    // 番地以降
    streetAddress: {
        text: string,
        errorText: string,
        isError: boolean,
    },
    // 電話番号
    tell: {
        text: string,
        errorText: string,
        isError: boolean,
    },
    // 席数
    numberOfSeats: {
        text: string,
        errorText: string,
        isError: boolean,
    },
    // WebUrl
    site: {
        text: string,
        errorText: string,
        isError: boolean,
    },
    restaurantLargeFormatId: number | null, // 店舗業態(大)
    restaurantMediumFormatId: number | null, // 店舗業態(中)
    customerUnitPriceId?: number | null,    // 客単価
    numOfRestaurantId?: number | null,    // 店舗規模（店舗数）
    majorRestaurantAreaId?: number | null,    // 店舗展開エリア
    lunchService: number,  // ランチ営業
    notificationMail: number,   // メール通知
}

export class ProfileScreen extends BaseScreen<Props, State> {
    _resizeTimer = setInterval(() => {
        // window 拡大縮小を反映させる
        AppG.window = Dimensions.get('window');
        if (AppG.window.width != AppG.windowBefore.width || AppG.window.height != AppG.windowBefore.height) {
            this.setState({'resizeWindow': true});
            AppG.windowBefore = Dimensions.get('window');
        }
        LoginUtil.setRefreshedScreenFlg(false);
    }, 100);

    _loginData = new LoginDataEntityModel();

    _flameSize = appS.flame.size;    // 左右の margin
    _marginSize = ScreenSizeUtil.isWebSize()? 0 : appS.margin.size;

    _titleStyle: TextStyle = Platform.OS == 'ios'? {
        fontWeight: "bold",
        fontSize: appFont.size.default,
        color: appColors.black,
        marginTop: 6,
    } : {
        fontWeight: "bold",
        fontSize: appFont.size.default,
        color: appColors.black,
        marginTop: 0,
    };

    _inputStyle = Platform.OS == 'ios'? {
        flex: 1,
        height: 30,
        // borderRadius: 10,
        backgroundColor: appColors.white,
        padding: 8,
    } : {
        flex: 1,
        height: 24,
        // borderRadius: 10,
        backgroundColor: appColors.white,
        paddingLeft: 8,
    };

    // このScreenへ渡すパラム
    _param: ProfileInitScreenModel = new ProfileInitScreenModel();

    _campaign = true;

    // 選択一回はしたよフラグ
    _selectedState = false; // 都道府県
    _selectedRestaurantLargeLocationId = false;   // 出店エリア（店舗所在地（大分類））
    // _selectedRestaurantMediumLocationId = false;   // 店舗所在地（中分類）
    _selectedRestaurantLargeFormatId = false;   // 店舗業態（大分類）
    _selectedRestaurantMediumFormatId = false;   // 店舗業態（中分類）
    _selectedCustomerUnitPriceId = false;  // 客単価
    _selectedNumOfRestaurantId = false; // 店舗規模（店舗数）

    _browserBackListener = (e: any) => {

    };

    constructor(props: any) {
        super(props);
        this.state = {
            resizeWindow: true,  // 値に意味はなし。windowサイズの拡大縮小をrenderに送るためのもの
            isLoaded: false,
            isShowPhotoBottomModal: false,
            user: null,
            pickerGender: [],
            pickerState: [],
            stateInitKey: undefined,
            pickerRestaurantLargeLocationInitKey: undefined, // 店舗業態（大）選択用key
            pickerRestaurantMediumLocationInitKey: undefined, // 店舗業態（中）選択用key
            restaurantFormatLargeInitKey: undefined, // 店舗業態（大）選択用key
            restaurantFormatMediumInitKey: undefined, // 店舗業態（中）選択用key
            customerUnitPriceInitKey: undefined,
            numOfRestaurantInitKey: undefined,
            majorRestaurantAreaInitKey: undefined,
            restaurantFormatMasters: [],   // 店舗業態マスター
            pickerRestaurantFormatLarge: [], // pickerに表示する店舗業態（大）
            pickerRestaurantFormatMedium: [],
            customerUnitPriceIdMasters: [],  // 客単価マスター
            pickerCustomerUnitPriceId: [],
            numOfRestaurantIdMasters: [],  // 店舗規模マスター
            pickerNumOfRestaurantId: [], // pickerに表示する店舗規模
            majorRestaurantAreaIdMasters: [],    // 店舗展開エリア
            pickerMajorRestaurantAreaIds: [], // pickerに表示する店舗展開エリア
            restaurantLocationMasters: [],   // 店舗所在地マスター
            pickerRestaurantLargeLocationId: [], // pickerに表示する店舗業態（大）
            pickerRestaurantMediumLocationId: [], // pickerに表示する店舗業態（中）

            mail: null,   // メールアドレス
            userPlan: UserUserPlanEnum.Free, // ユーザープラン
            userAttribute: UserUserAttributeEnum.Normal,
            // 名前
            name: {
                text: '',
                errorText: '',
                isError: false,
            },
            // 名前(かな)
            nameKana: {
                text: '',
                errorText: '',
                isError: false,
            },
            gender: '',
            yyyy: '',
            mm: '',
            dd: '',
            nameRestaurant: {
                text: '',
                errorText: '',
                isError: false,
            },
            // 郵便番号上3桁
            postalCode1: {
                text: '',
                errorText: '',
                isError: false,
            },
            // 郵便番号下4桁
            postalCode2: {
                text: '',
                errorText: '',
                isError: false,
            },
            restaurantLargeLocationId: null, // 店舗所在地(大)
            // restaurantMediumLocationId: null, // 店舗所在地(中)
            state: '',  // 都道府県
            // 市町村
            city: {
                text: '',
                errorText: '',
                isError: false,
            },
            // 番地以降
            streetAddress: {
                text: '',
                errorText: '',
                isError: false,
            },
            // 電話番号
            tell: {
                text: '',
                errorText: '',
                isError: false,
            },
            // 席数
            numberOfSeats: {
                text: '',
                errorText: '',
                isError: false,
            },
            // WebUrl
            site: {
                text: '',
                errorText: '',
                isError: false,
            },
            restaurantLargeFormatId: null, // 店舗業態(大)
            restaurantMediumFormatId: null, // 店舗業態(中)
            customerUnitPriceId: null,    // 客単価
            numOfRestaurantId: null,    // 店舗規模（店舗数）
            majorRestaurantAreaId: null,
            lunchService: 0,
            notificationMail: 1,   // メール通知
        };
    }

    /**
     * 画面開いたときのアナリティクス送信
     */
    _sendPvAnalytics = () => {
        AnalyticsUtil.sendAnalytics(
            DOpenEventName.openPage,
            AnalyticsEventName.openPage,  // アナリティクスのイベント名
            'ProfileScreen',
            null,
            null,
            null,
            null,
        );
    }

    componentDidMount() {
        if (Platform.OS == 'web') {
            window.addEventListener('popstate', this._browserBackListener);
        }

        const _campaignDate = moment('2021-05-31 23:59:59');
        const _now = moment();

        this._campaign = _now.isBefore(_campaignDate);

        LoginUtil.interruptOpenScreen(this.props.navigation,
            () => {
                LoginDataDao.get().then(loginData => {
                    // Web 戻るでも呼ばれる
                    if (Platform.OS == 'web') {
                        // Analytics
                        this._sendPvAnalytics();
                    }

                    // URL Scheme で外部リンクから起動したときの処理と、画面遷移でこの画面を開いたときの処理
                    LoginUtil.setDefaultListener(this.props.navigation, () => {
                        // Webはここにこない
                        if (Platform.OS != 'web') {
                            // Analytics
                            this._sendPvAnalytics();
                        }
                    });
                    if (loginData != null) {
                        this._loginData = loginData;
                        this._loadMasters().then(() => {
                            this._loadContents().then(() => {});
                        });
                    }
                });
            });
    }

    componentWillUnmount () {
        clearTimeout(this._resizeTimer);
        if (Platform.OS === 'web') {
            window.removeEventListener('popstate', this._browserBackListener);
        }
        LoginUtil.removeDefaultListener(this.props.navigation);
    };

    componentDidUpdate () {
        // レンダー完了時に呼ばれる
    }

    /**
     * パラムの取得
     * @private
     */
    _getParams = () => {
        // let profileScreenModel: ProfileInitScreenModel = UrlUtil.getParamModel(new ProfileInitScreenModel(), this.props.navigation);
        // return profileScreenModel;
    };

    /**
     *
     * @param isEmpty 入力がない時
     */
    _getPickerStyle = (isEmpty: boolean) : PickerStyle => {
        return {
            // @ts-ignore
            inputIOS: {
                height: 30,
                fontSize: 16,
                // paddingVertical: 12,
                // paddingHorizontal: 10,
                borderWidth: 1,
                borderColor: appColors.white,
                // borderRadius: 4,
                color: isEmpty? appColors.fontGray : appColors.black,
                marginLeft: 8,
                // paddingRight: 30, // to ensure the text is never behind the icon
            },
            inputAndroid: {
                height: 30,
                fontSize: 16,
                // paddingHorizontal: 10,
                // paddingVertical: 8,
                borderWidth: 0.5,
                borderColor: appColors.white,
                // borderRadius: 8,
                color: isEmpty? appColors.fontGray : appColors.black,
                marginLeft: 8,
                // paddingRight: 30, // to ensure the text is never behind the icon
                backgroundColor:appColors.white,
                marginTop: -15,
                paddingBottom: 15,
            },
            inputWeb: {
                height: 30,
                fontSize: 16,
                // paddingVertical: 12,
                // paddingHorizontal: 10,
                borderWidth: 1,
                borderColor: appColors.gray,
                // borderRadius: 4,
                color: isEmpty? appColors.fontGray : appColors.black,
                marginLeft: 8,
                // paddingRight: 30, // to ensure the text is never behind the icon
            },
        };
    }

    /**
     * カメラかフォトライブラリを選択するモーダルの描画
     * @private
     */
    _renderImagePickerModal = () => {
        if (!this.state.isShowPhotoBottomModal) {
            return null;
        }

        const list = [
            {
                title: 'カメラ',
                onPress: () => {
                    this._getPermissionCameraAsync();
                    this.setState({'isShowPhotoBottomModal': false});
                },
                titleStyle: {
                    textAlign:'center',
                },
            },
            {
                title: 'フォトライブラリ',
                onPress: () => {
                    this._getPermissionPhotoAsync();
                    this.setState({'isShowPhotoBottomModal': false});
                },
                titleStyle: {
                    textAlign:'center',
                },
            },
            {
                title: `キャンセル`,
                onPress: () => {
                    this.setState({'isShowPhotoBottomModal': false});
                },
                titleStyle: {
                    textAlign:'center',
                    color: 'red',
                },
            }
        ];

        let _width = ScreenSizeUtil.isWebSize()? AppG.window.width *2 / 5 : AppG.window.width *4 / 5;

        if (Platform.OS == 'web' && !ScreenSizeUtil.isWebSize()) {
            // Webのスマホサイズ
            _width = AppG.window.width * 2 / 5;
        }

        return (
            <TouchableOpacity
                style={{zIndex: 99, position: 'absolute', backgroundColor: appColors.opacityGray, height: AppG.window.height, width: AppG.window.width}}
                onPress={() => {
                    this.setState({'isShowPhotoBottomModal': false});
                }}
            >
                <View style={{
                    position: 'relative',
                    height: 230,
                    width: _width,
                    top: AppG.window.height / 2 -165,
                    left: AppG.window.width - _width - (AppG.window.width - _width)/2,
                    backgroundColor: appColors.red,
                    alignItems: 'center',
                }}>
                    <View style={{
                        height: 230,
                        width: _width,
                        marginRight: -this._marginSize,
                        marginLeft: -this._marginSize,
                        backgroundColor: appColors.white,
                    }}>
                        <SpacerComponent height={20}/>

                        {list.map((l, index) => {
                            return (
                                <TouchableOpacity
                                    key={`camera_button_${index}`}
                                    style={[
                                        {
                                            marginLeft: ScreenSizeUtil.isWebSize()? 0 : 16,
                                            marginRight: ScreenSizeUtil.isWebSize()? 0 : 16,
                                            marginBottom: index == 1? 32 : 16,
                                            height: appS.button.height,
                                            backgroundColor: index == 2? appColors.gray: appColors.black,
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }
                                    ]}
                                    onPress={() => {
                                        l.onPress();
                                    }}
                                >
                                    <Text
                                        key={`camera_button_text_${index}`}
                                        style={{
                                            color: appColors.white,
                                            fontWeight: 'bold',
                                            fontSize: appS.button.text}}>{l.title}</Text>
                                </TouchableOpacity>
                            );
                        })}

                    </View>
                </View>
            </TouchableOpacity>
        );
    };

    /**
     * カメラパーミッション取得
     */
    _getPermissionCameraAsync = async () => {
        if (Platform.OS != 'web') {
            const permission = await Camera.getCameraPermissionsAsync();
            const _status = permission.status;
            let finalStatus = _status;
            if (permission.canAskAgain && _status !== 'granted') {
                // 通知不許可で再確認OKなら
                const { status } = await Camera.requestCameraPermissionsAsync();
                finalStatus = status;
            }
            if (finalStatus !== 'granted') {
                // 通知不許可なら
                Alert.alert(I18n.t('permissionDeniedCameraRoll'));
                return null;
            }
            this._takePhoto().then();
        } else {
            this._takePhoto().then();
        }
    };

    /**
     * フォトライブラリパーミッション取得
     */
    _getPermissionPhotoAsync = async () => {
        if (Platform.OS != 'web') {
            const permission = await MediaLibrary.getPermissionsAsync();
            const _status = permission.status;
            let finalStatus = _status;
            if (permission.canAskAgain && _status !== 'granted') {
                // 通知不許可で再確認OKなら
                const { status } = await MediaLibrary.requestPermissionsAsync();
                finalStatus = status;
            }
            if (finalStatus !== 'granted') {
                // 通知不許可なら
                Alert.alert(I18n.t('permissionDeniedCameraRoll'));
                return null;
            }
            this._pickImage().then();
        } else {
            this._pickImage().then();
        }
    };

    /**
     * 画像保存
     * @param imageFile
     */
    _saveImage = (imageFile: {viewImage: string, postImage: string, imageType: string}) => {
        switch (imageFile.imageType) {
            case 'jpg':
                imageFile.imageType = 'jpeg';
                break;
            case 'jpeg':
                break;
            case 'png':
                break;
            case 'pdf':
                AlertUtil.alert('エラー', '画像ファイル(jpeg/png)を選択してください。');
                return;
            default:
                AlertUtil.alert('エラー', '画像ファイル(jpeg/png)を選択してください。');
                return;
        }

        // 画像を保存して画像URLを取得
        ImageApiFactory(AppGlobalDataUtil.getConfiguration(), AppGlobalDataUtil.getBasePath())
            .uploadImage(imageFile.postImage).then((imageFileRes: AxiosResponse<ImageFile>) => {
            if (imageFileRes != null
                && imageFileRes.data != null
                && !ValidateUtil.isEmptyExact(imageFileRes.data.url)
                && isURL(imageFileRes.data.url)
                && this.state.user != null
            ) {
                // プロフィールに保存
                this.state.user.image = imageFileRes.data.url;
                this.setState({user: this.state.user});
            }
        });
    }

    /**
     * カメラの撮影
     * @private
     */
    _takePhoto = async () => {
        let result = await ImagePicker.launchCameraAsync({
            base64: true,
            quality: 0.8,
            allowsEditing: true,
        });

        const imageFile = ImageUtil.setImage({ result: result });

        if (imageFile == null) {
            return;
        }

        this._saveImage(imageFile);
    };

    /**
     * ピッカーからイメージの選択
     * @private
     */
    _pickImage = async () => {
        let result = await ImagePicker.launchImageLibraryAsync({
            quality: 0.8,
            base64: true,
            mediaTypes: ImagePicker.MediaTypeOptions.Images,
            allowsEditing: true,
            // mediaTypes: ImagePicker.MediaTypeOptions.All,
            // aspect: [4, 3],
        });

        const imageFile = ImageUtil.setImage({ result: result });

        if (imageFile == null) {
            return;
        }

        this._saveImage(imageFile);
    };

    /**
     * マスターのロード
     * @private
     */
    _loadMasters = async () => {
        this.setState({pickerState: PickerUtil.getPrefCodeMaster()});

        // 性別
        this.state.pickerGender.push({label: '男性', value: UserGenderEnum.Male, key: UserGenderEnum.Male});
        this.state.pickerGender.push({label: '女性', value: UserGenderEnum.Female, key: UserGenderEnum.Female});
        this.setState({pickerGender: this.state.pickerGender});

        // 店舗所在地
        const getAllRestaurantLocationMaster = MasterApiFactory(AppGlobalDataUtil.getConfiguration(), AppGlobalDataUtil.getBasePath())
            .getAllRestaurantLocationMaster().then((restaurantLocationRes: AxiosResponse<RestaurantLocation[]>) => {
                if (restaurantLocationRes != null && restaurantLocationRes.data != null) {
                    this.setState({restaurantLocationMasters: restaurantLocationRes.data});
                    let pickerItems: {label: string, value: any, key: string}[] = [];
                    restaurantLocationRes.data.forEach((restaurantLocation: RestaurantLocation) => {
                        pickerItems.push({label: restaurantLocation.large.name!, value: restaurantLocation.large.id, key: `${restaurantLocation.large.id}`});
                    });
                    this.setState({pickerRestaurantLargeLocationId: pickerItems});
                }
            });

        // 店舗業態
        const getAllRestaurantFormatMaster = MasterApiFactory(AppGlobalDataUtil.getConfiguration(), AppGlobalDataUtil.getBasePath())
            .getAllRestaurantFormatMaster().then((restaurantFormatRes: AxiosResponse<RestaurantFormat[]>) => {
                if (restaurantFormatRes != null && restaurantFormatRes.data != null) {
                    this.setState({restaurantFormatMasters: restaurantFormatRes.data});
                    let pickerItems: {label: string, value: any, key: string}[] = [];
                    restaurantFormatRes.data.forEach((restaurantFormat: RestaurantFormat) => {
                        pickerItems.push({label: restaurantFormat.large.name!, value: restaurantFormat.large.id, key: `${restaurantFormat.large.id}`});
                    });
                    this.setState({pickerRestaurantFormatLarge: pickerItems});
                }
            });

        // 客単価
        const getAllCustomerUnitPriceIdsMaster = MasterApiFactory(AppGlobalDataUtil.getConfiguration(), AppGlobalDataUtil.getBasePath())
            .getAllCustomerUnitPriceIdsMaster().then((customerUnitPriceIdRes: AxiosResponse<CustomerUnitPriceId[]>) => {
                if (customerUnitPriceIdRes != null && customerUnitPriceIdRes.data != null) {
                    this.setState({customerUnitPriceIdMasters: customerUnitPriceIdRes.data});
                    let pickerItems: {label: string, value: any, key: string}[] = [];
                    customerUnitPriceIdRes.data.forEach((customerUnitPriceId: CustomerUnitPriceId) => {
                        pickerItems.push({label: customerUnitPriceId.name, value: customerUnitPriceId.id, key: customerUnitPriceId.id!.toString()});
                    });
                    this.setState({pickerCustomerUnitPriceId: pickerItems});
                }
            });

        // 店舗規模
        const getAllNumOfRestaurantIdMaster = MasterApiFactory(AppGlobalDataUtil.getConfiguration(), AppGlobalDataUtil.getBasePath())
            .getAllNumOfRestaurantIdMaster().then((numOfRestaurantIdRes: AxiosResponse<NumOfRestaurantId[]>) => {
                if (numOfRestaurantIdRes != null && numOfRestaurantIdRes.data != null) {
                    this.setState({numOfRestaurantIdMasters: numOfRestaurantIdRes.data});
                    let pickerItems: {label: string, value: any, key: string}[] = [];
                    numOfRestaurantIdRes.data.forEach((numOfRestaurantId: NumOfRestaurantId) => {
                        pickerItems.push({label: numOfRestaurantId.name, value: numOfRestaurantId.id, key: numOfRestaurantId.id!.toString()});
                    });
                    this.setState({pickerNumOfRestaurantId: pickerItems});
                }
            });

        // 店舗展開エリア
        const getAllMajorRestaurantAreaIdMaster = MasterApiFactory(AppGlobalDataUtil.getConfiguration(), AppGlobalDataUtil.getBasePath())
            .getAllMajorRestaurantAreaIdMaster().then((majorRestaurantAreaIdRes: AxiosResponse<MajorRestaurantAreaId[]>) => {
                if (majorRestaurantAreaIdRes != null && majorRestaurantAreaIdRes.data != null) {
                    this.setState({majorRestaurantAreaIdMasters: majorRestaurantAreaIdRes.data});
                    let pickerItems: {label: string, value: any, key: string}[] = [];
                    majorRestaurantAreaIdRes.data.forEach((majorRestaurantAreaId: MajorRestaurantAreaId) => {
                        pickerItems.push({label: majorRestaurantAreaId.name, value: majorRestaurantAreaId.id, key: majorRestaurantAreaId.id!.toString()});
                    });
                    this.setState({pickerMajorRestaurantAreaIds: pickerItems});
                }
            });

        await Promise.all([
            getAllRestaurantLocationMaster,
            getAllRestaurantFormatMaster,
            getAllCustomerUnitPriceIdsMaster,
            getAllNumOfRestaurantIdMaster,
            getAllMajorRestaurantAreaIdMaster,
        ]);

    };

    /**
     * コンテンツのロード
     * @private
     */
    _loadContents = async () => {
        // Userの取得
        const getUser = await UserApiFactory(AppGlobalDataUtil.getConfiguration(), AppGlobalDataUtil.getBasePath())
            .getUser()
            .then((userRes: AxiosResponse<User>) => {
                if (userRes.data != null && userRes.data.id != null && userRes.data.userHash != null) {
                    let _user: User = userRes.data;
                    if (ValidateUtil.isEmptyExact(_user.mail) && !ValidateUtil.isEmptyExact(this._loginData.mail)) {
                        _user.mail = this._loginData.mail!;
                    }
                    this._setField(_user);
                    this.setState({user: _user});
                    this.setState({isLoaded: true});
                }
            });

        // 実行
        await Promise.all([getUser]);

    };

    /**
     * User データを画面に反映
     * @param user
     */
    _setField = (user: User) => {
        // メールアドレス
        this.setState({mail: user.mail});
        // ユーザープラン
        this.setState({userPlan: user.userPlan});
        // ユーザー属性
        this.setState({userAttribute: user.userAttribute});
        // 名前
        this.setState({name: {text: ValidateUtil.isEmptyExact(user.name)? '' : user.name!, errorText: '', isError: false}});
        // 名前（かな）
        this.setState({nameKana: {text: ValidateUtil.isEmptyExact(user.nameKana)? '' : user.nameKana!, errorText: '', isError: false}});
        // 性別
        this.setState({gender: user.gender});
        // 生年月日
        if (!ValidateUtil.isEmptyExact(user.birthday)) {
            let _birthday = user.birthday!.split('-');
            this.setState({yyyy: _birthday[0]});
            let _mm: string = `${_birthday[1]}`;
            let _dd: string = `${_birthday[2]}`;
            const _mmResult = _mm.match(/^0\d$/);
            if (_mmResult) {
                _mm = `${_birthday[1]}`.slice(1);
            }
            const _ddResult = _dd.match(/^0\d$/);
            if (_ddResult) {
                _dd = `${_birthday[2]}`.slice(1);
            }
            this.setState({mm: _mm});
            this.setState({dd: _dd});
        }
        // 店舗名
        this.setState({nameRestaurant: {text: ValidateUtil.isEmptyExact(user.nameRestaurant)? '' : user.nameRestaurant!, errorText: '', isError: false}});
        // 郵便番号
        let _postalCode: string[] = ['', ''];
        if (!ValidateUtil.isEmptyExact(user.postalCode)){
            _postalCode[0] = user.postalCode!.toString().slice(0, 3);
            _postalCode[1] = user.postalCode!.toString().slice(3, 7);
        }
        this.setState({postalCode1: {text: _postalCode[0], errorText: '', isError: false}});
        this.setState({postalCode2: {text: _postalCode[1], errorText: '', isError: false}});
        // 店舗所在地
        if (!ValidateUtil.isEmptyArray(this.state.restaurantLocationMasters) && !ValidateUtil.isEmptyExact(user.restaurantLargeLocationId)) {
            // 店舗所在地(大)
            const _restaurantLocation =  this.state.restaurantLocationMasters.find(restaurantLocationMaster => restaurantLocationMaster.large.id == user.restaurantLargeLocationId!);
            if (_restaurantLocation != null) {
                this._createPickerRestaurantLocationMedium(_restaurantLocation.large.id!);
                this.setState({pickerRestaurantLargeLocationInitKey: _restaurantLocation.large.id!.toString()});
                // 店舗所在地(中)
                if (!ValidateUtil.isEmptyExact(user.restaurantMediumFormatId)) {
                    const _medium = _restaurantLocation.large.medium?.find(medium => medium.id == user.restaurantMediumLocationId);
                    if (_medium != null) {
                        this.setState({pickerRestaurantMediumLocationInitKey: _medium.id!.toString()});
                    }
                }
            }
        }
        this.setState({restaurantLargeFormatId: ValidateUtil.isEmptyExact(user.restaurantLargeFormatId)? null : user.restaurantLargeFormatId!});
        this.setState({restaurantMediumFormatId: ValidateUtil.isEmptyExact(user.restaurantMediumFormatId)? null : user.restaurantMediumFormatId!});

        // 都道府県
        const _prefCode = PickerUtil.getPrefCodeMaster().find(prefCode => prefCode.value == user.restaurantState);
        if (_prefCode != null) {
            this.setState({stateInitKey: _prefCode.key}); // 都道府県のコード選択用key
        }
        this.setState({state: ValidateUtil.isEmptyExact(user.restaurantState)? '' : user.restaurantState!});
        // 市区町村
        this.setState({city: {text: ValidateUtil.isEmptyExact(user.restaurantCity)? '' : user.restaurantCity!, errorText: '', isError: false}});
        // 番地以降
        this.setState({streetAddress: {text: ValidateUtil.isEmptyExact(user.restaurantStreetAddress)? '' : user.restaurantStreetAddress!, errorText: '', isError: false}});
        // 電話番号
        this.setState({tell: {text: ValidateUtil.isEmptyExact(user.tell)? '' : user.tell!, errorText: '', isError: false}});
        // 店舗業態
        if (!ValidateUtil.isEmptyArray(this.state.restaurantFormatMasters) && !ValidateUtil.isEmptyExact(user.restaurantLargeFormatId)) {
            // 店舗業態(大)
            const _restaurantFormat =  this.state.restaurantFormatMasters.find(restaurantFormatMaster => restaurantFormatMaster.large.id == user.restaurantLargeFormatId!);
            if (_restaurantFormat != null) {
                this._createPickerRestaurantFormatMedium(_restaurantFormat.large.id!);
                this.setState({restaurantFormatLargeInitKey: _restaurantFormat.large.id!.toString()});
                // 店舗業態(中)
                if (!ValidateUtil.isEmptyExact(user.restaurantMediumFormatId)) {
                    const _medium = _restaurantFormat.large.medium?.find(medium => medium.id == user.restaurantMediumFormatId);
                    if (_medium != null) {
                        this.setState({restaurantFormatMediumInitKey: _medium.id!.toString()});
                    }
                }
            }
        }
        this.setState({restaurantLargeFormatId: ValidateUtil.isEmptyExact(user.restaurantLargeFormatId)? null : user.restaurantLargeFormatId!});
        this.setState({restaurantMediumFormatId: ValidateUtil.isEmptyExact(user.restaurantMediumFormatId)? null : user.restaurantMediumFormatId!});

        // 客単価
        if (!ValidateUtil.isEmptyArray(this.state.customerUnitPriceIdMasters) && !ValidateUtil.isEmptyExact(user.customerUnitPriceId)) {
            const _customerUnitPriceId =  this.state.customerUnitPriceIdMasters.find(customerUnitPriceIdMaster => customerUnitPriceIdMaster.id == user.customerUnitPriceId!);
            if (_customerUnitPriceId != null) {
                this.setState({customerUnitPriceInitKey: _customerUnitPriceId.id!.toString()});
            }
        }
        this.setState({customerUnitPriceId: ValidateUtil.isEmptyExact(user.customerUnitPriceId)? null : user.customerUnitPriceId!});

        // 店舗規模（店舗数）
        if (!ValidateUtil.isEmptyArray(this.state.numOfRestaurantIdMasters) && !ValidateUtil.isEmptyExact(user.numOfRestaurantId)) {
            const _numOfRestaurantId =  this.state.numOfRestaurantIdMasters.find(numOfRestaurantIdMaster => numOfRestaurantIdMaster.id == user.numOfRestaurantId!);
            if (_numOfRestaurantId != null) {
                this.setState({numOfRestaurantInitKey: _numOfRestaurantId.id!.toString()});
            }
        }
        this.setState({numOfRestaurantId: ValidateUtil.isEmptyExact(user.numOfRestaurantId)? null : user.numOfRestaurantId!});
        // 主要店舗展開エリア
        if (!ValidateUtil.isEmptyArray(this.state.majorRestaurantAreaIdMasters) && !ValidateUtil.isEmptyExact(user.majorRestaurantAreaId)) {
            const _majorRestaurantAreaId =  this.state.customerUnitPriceIdMasters.find(majorRestaurantAreaIdMaster => majorRestaurantAreaIdMaster.id == user.majorRestaurantAreaId!);
            if (_majorRestaurantAreaId != null) {
                this.setState({majorRestaurantAreaInitKey: _majorRestaurantAreaId.id!.toString()});
            }
        }
        this.setState({majorRestaurantAreaId: ValidateUtil.isEmptyExact(user.majorRestaurantAreaId)? null : user.majorRestaurantAreaId!});
        // ランチ営業
        this.setState({lunchService: ValidateUtil.isEmptyExact(user.lunchService)? 1 : user.lunchService!});
        // 席数
        this.setState({numberOfSeats: {text: ValidateUtil.isEmptyExact(user.numberOfSeats)? '' : user.numberOfSeats!.toString(), errorText: '', isError: false}});
        // WebUrl
        this.setState({site: {text: ValidateUtil.isEmptyExact(user.site)? '' : user.site!, errorText: '', isError: false}});
        // メール通知
        this.setState({notificationMail: ValidateUtil.isEmptyExact(user.notificationMail)? 1 : user.notificationMail!});
    }

    /**
     * 誕生日のセット
     */
    _setBirthday = () => {
        if (
            ValidateUtil.isEmptyExact(this.state.yyyy)
            && ValidateUtil.isEmptyExact(this.state.mm)
            && ValidateUtil.isEmptyExact(this.state.dd)
        ) {
            // 誕生日がセットされていない
            this.state.user!.birthday = null;
            this.setState({user: this.state.user});
            return;
        }

        if (this.state.user != null && ValidateUtil.isRightDate(this.state.yyyy, this.state.mm, this.state.dd)) {
            let _mm: string = `${this.state.mm}`;
            let _dd: string = `${this.state.dd}`;
            if (_mm.length === 1) {
                _mm = `0${this.state.mm}`;
            }
            if (_dd.length === 1) {
                _dd = `0${this.state.dd}`;
            }
            this.state.user!.birthday = `${this.state.yyyy}-${_mm}-${_dd}`;
            this.setState({user: this.state.user});
        }
    }

    /**
     * エラーなら true
     */
    _isError = () => {
        let _error = false;

        // User
        if (this.state.user == null) {
            // log.debug('User Error');
            return true;
        }

        // プロフィール画像
        if (!ValidateUtil.isEmptyExact(this.state.user.image) && !isURL(this.state.user.image)) {
            // log.debug('飲食関係者 Error');
            _error = true;
        }

        // 飲食関係者ですか？
        if (ValidateUtil.isErrorUserAttribute(this.state.user.userAttribute)) {
            // log.debug('飲食関係者 Error');
            _error = true;
        }
        // 名前
        if (ValidateUtil.isErrorUserName(this.state.user.name).isError) {
            // log.debug('名前 Error');
            _error = true;
        }
        // 名前（かな）
        if (ValidateUtil.isErrorUserNameKana(this.state.user.nameKana).isError) {
            // log.debug('名前（かな） Error');
            _error = true;
        }
        // 性別 必須ではない
        // if (ValidateUtil.isErrorUserGender(this.state.user.gender)) {
        //     // log.debug('性別 Error');
        //     _error = true;
        // }
        // 誕生日
        if (
            !ValidateUtil.isEmptyExact(this.state.yyyy)
            || !ValidateUtil.isEmptyExact(this.state.mm)
            || !ValidateUtil.isEmptyExact(this.state.dd)
        ) {
            // 生年月日のどれかが入力済み
            if (!ValidateUtil.isRightDate(this.state.yyyy, this.state.mm, this.state.dd)
                || !moment(this.state.user.birthday, 'YYYY-MM-DD').isValid()) {
                // log.debug('誕生日 Error');
                _error = true;
            }
        }

        if (this.state.user.userAttribute == UserUserAttributeEnum.Cook) {
            // 飲食人の追加バリデーション

            // // 店舗名 必須でない
            // if (ValidateUtil.isErrorNameRestaurant(this.state.user.nameRestaurant)) {
            //     log.debug('店舗名 Error');
            //     _error = true;
            // }
            // // 郵便番号 必須でない
            // if (ValidateUtil.isErrorPostalCode(this.state.user.postalCode, 'both').isError) {
            //     log.debug('郵便番号 Error');
            //     _error = true;
            // }

            // 出店エリア（店舗所在地（大分類））
            if (ValidateUtil.isEmptySelectBox(this.state.user.restaurantLargeLocationId)) {
                // log.debug('店舗所在地（大） Error');
                _error = true;
            }
            // // 店舗所在地（中分類）削除
            // if (ValidateUtil.isEmptySelectBox(this.state.user.restaurantMediumLocationId)) {
            //     // log.debug('店舗所在地（中） Error');
            //     _error = true;
            // }

            // // 都道府県 必須でない
            // if (ValidateUtil.isEmptySelectBox(this.state.user.restaurantState)) {
            //     _error = true;
            // }
            // // 市区町村 必須でない
            // if (ValidateUtil.isErrorRestaurantCity(this.state.user.restaurantCity).isError) {
            //     // log.debug('市区町村 Error');
            //     _error = true;
            // }
            // // 番地以降 必須でない
            // if (ValidateUtil.isErrorRestaurantStreetAddress(this.state.user.restaurantStreetAddress).isError) {
            //     _error = true;
            // }
            // // 電話番号 必須でない
            // if (ValidateUtil.isErrorTell(this.state.user.tell).isError) {
            //     _error = true;
            // }

            // 店舗業態（大分類）
            if (ValidateUtil.isEmptySelectBox(this.state.user.restaurantLargeFormatId)) {
                // log.debug('店舗業態（大） Error');
                _error = true;
            }
            // 店舗業態（中分類）
            if (ValidateUtil.isEmptySelectBox(this.state.user.restaurantMediumFormatId)) {
                // log.debug('店舗業態（中） Error');
                _error = true;
            }
            // 店舗規模（店舗数）
            if (ValidateUtil.isEmptySelectBox(this.state.user.numOfRestaurantId)) {
                // log.debug('店舗規模（店舗数） Error');
                _error = true;
            }
            // 客単価
            if (ValidateUtil.isEmptySelectBox(this.state.user.customerUnitPriceId)) {
                // log.debug('客単価 Error');
                _error = true;
            }
            // 席数
            if (!ValidateUtil.isEmptyExact(this.state.user!.numberOfSeats) && ValidateUtil.isErrorNumOfSeats(this.state.user!.numberOfSeats!.toString()).isError) {
                // log.debug('席数 Error');
                _error = true;
            }
            // WebURL
            if (!ValidateUtil.isEmptyExact(this.state.user!.site) && ValidateUtil.isErrorWebUrl(this.state.user!.site).isError) {
                // log.debug('WebbURL Error');
                _error = true;
            }


        }

        // if (_error) {
        //     log.debug('isError Error');
        // }

        return _error;
    };

    /**
     * 次のページへ移動（料理人）
     */
    _goNextPageByCook = async () => {
        if (!this._isError()) {
            this.setState({user: this.state.user});

            setTimeout(() => {
                const addOrUpdateUser = UserApiFactory(AppGlobalDataUtil.getConfiguration(), AppGlobalDataUtil.getBasePath())
                    .addOrUpdateUser(
                        this.state.user!.id,    // id: number,
                        this.state.user!.mail,  // mail: string,
                        this.state.user!.userAttribute!,    // userAttribute: string,
                        this.state.user!.name!, // name: string,
                        this.state.user!.nameKana!, // nameKana: string,
                        this.state.user!.notificationMail,  // notificationMail: number,
                        this.state.user!.userPlan,   //userPlan: string,
                        undefined,  //userHash?: string,
                        ValidateUtil.isEmptyExact(this.state.user!.gender) ? undefined : this.state.user!.gender!,   // gender: string,
                        ValidateUtil.isEmptyExact(this.state.user!.birthday) ? undefined : this.state.user!.birthday!, // birthday: string,
                        ValidateUtil.isEmptyExact(this.state.user!.nameRestaurant) ? undefined : this.state.user!.nameRestaurant!,   //nameRestaurant?: string,
                        this.state.user!.postalCode!,   // postalCode?: string,
                        ValidateUtil.isEmptySelectBox(this.state.user!.restaurantLargeLocationId)? undefined: this.state.user!.restaurantLargeLocationId!, // restaurantLargeLocationId?: number,
                        undefined,    // restaurantMediumLocationId?: number,
                        ValidateUtil.isEmptySelectBox(this.state.user!.restaurantState)? '': this.state.user!.restaurantState!,  // restaurantState?: string,
                        ValidateUtil.isEmptyExact(this.state.user!.restaurantCity)? '': this.state.user!.restaurantCity!,   // restaurantCity?: string,
                        ValidateUtil.isEmptyExact(this.state.user!.restaurantStreetAddress)? '': this.state.user!.restaurantStreetAddress!,  // restaurantStreetAddress?: string,
                        this.state.user!.tell!, // tell?: string,
                        this.state.user!.restaurantLargeFormatId!,  // restaurantLargeFormatId?: number,
                        this.state.user!.restaurantMediumFormatId!,     /// restaurantMediumFormatId?: number,
                        this.state.user!.numOfRestaurantId!, // numOfRestaurantId?: number,
                        ValidateUtil.isEmptySelectBox(this.state.user!.majorRestaurantAreaId)? undefined : this.state.user!.majorRestaurantAreaId!, // majorRestaurantAreaId?: number,
                        ValidateUtil.isEmptyExact(this.state.user!.lunchService)? undefined : this.state.user!.lunchService!,  // lunchService?: number,
                        this.state.user!.customerUnitPriceId!,  // customerUnitPriceId?: number,
                        ValidateUtil.isEmptyExact(this.state.user!.numberOfSeats)? undefined : this.state.user!.numberOfSeats!, // numberOfSeats?: number,
                        ValidateUtil.isEmptyExact(this.state.user!.site)? undefined : this.state.user!.site!,  // site?: string,
                        undefined,// userFreeCoin?: number,
                        (ValidateUtil.isEmptyExact(this.state.user?.image) || !isURL(this.state.user?.image))? undefined : this.state.user?.image,// image?: string
                    ).then(() => {
                        // 保存したUserにAppGのUserを更新
                        UserApiFactory(AppGlobalDataUtil.getConfiguration(), AppGlobalDataUtil.getBasePath())
                            .getUser()
                            .then((userRes: AxiosResponse<User>) => {
                                if (userRes.data != null && userRes.data.id != null && userRes.data.userHash != null) {
                                    let _user: User = userRes.data;
                                    if (ValidateUtil.isEmptyExact(_user.mail) && !ValidateUtil.isEmptyExact(this._loginData.mail)) {
                                        _user.mail = this._loginData.mail!;
                                    }
                                    AppG.user = _user;
                                    AppG.setAnalyticJpData();

                                    AlertUtil.alert('保存完了', 'ユーザー情報を更新しました');
                                    ExpoUtil.goBack(this.props.navigation);
                                } else {
                                    // 通信エラー
                                    AlertUtil.alert(`${I18n.t('errorNetworkTitle')}`, `${I18n.t('errorNetwork')}`);
                                    log.debug(`---------------Network Error (User.add.update.appG)--------------------`);
                                }
                            });
                    })
                    .catch((e) => {
                        // 通信エラー
                        AlertUtil.alert(`${I18n.t('errorNetworkTitle')}`, `${I18n.t('errorNetwork')}`);
                        log.debug(`---------------Network Error (User.add.update)--------------------`);
                        log.debug(e);
                    });

                // 実行
                Promise.all([addOrUpdateUser]);
            }, 100);
        }
    };

    /**
     * 次のページへ移動（一般人）
     */
    _goNextPageByNormal = async () => {
        if (!this._isError()) {
            this.setState({user: this.state.user});

            setTimeout(() => {
                const addOrUpdateUser = UserApiFactory(AppGlobalDataUtil.getConfiguration(), AppGlobalDataUtil.getBasePath())
                    .addOrUpdateUser(
                        this.state.user!.id,    // id: number,
                        this.state.user!.mail,  // mail: string,
                        this.state.user!.userAttribute!,    // userAttribute: string,
                        this.state.user!.name!, // name: string,
                        this.state.user!.nameKana!, // nameKana: string,
                        this.state.user!.notificationMail,  // notificationMail: number,
                        this.state.user!.userPlan,   //userPlan: string,
                        undefined,  //userHash?: string,
                        ValidateUtil.isEmptyExact(this.state.user!.gender) ? undefined : this.state.user!.gender!,   // gender: string,
                        ValidateUtil.isEmptyExact(this.state.user!.birthday) ? undefined : this.state.user!.birthday!, // birthday: string,
                        undefined,   //nameRestaurant?: string,
                        undefined,   // postalCode?: string,
                        undefined, // restaurantLargeLocationId?: number,
                        undefined,    // restaurantMediumLocationId?: number,
                        undefined,  // restaurantState?: string,
                        undefined,   // restaurantCity?: string,
                        undefined,  // restaurantStreetAddress?: string,
                        undefined, // tell?: string,
                        undefined,  // restaurantLargeFormatId?: number,
                        undefined,     /// restaurantMediumFormatId?: number,
                        undefined, // numOfRestaurantId?: number,
                        undefined, // majorRestaurantAreaId?: number,
                        undefined,  // lunchService?: number,
                        undefined,  // customerUnitPriceId?: number,
                        undefined, // numberOfSeats?: number,
                        undefined,  // site?: string,
                        undefined,// userFreeCoin?: number,
                        undefined,// image?: string
                    ).then(() => {
                        AlertUtil.alert('保存完了', 'ユーザー情報を更新しました');
                        ExpoUtil.goBack(this.props.navigation);
                    })
                    .catch((e) => {
                        // 通信エラー
                        AlertUtil.alert(`${I18n.t('errorNetworkTitle')}`, `${I18n.t('errorNetwork')}`);
                        log.debug(`---------------Network Error (User.add.init)--------------------`);
                        log.debug(e);
                    });

                // 実行
                Promise.all([addOrUpdateUser]);
            }, 100);
        }
    };



    /**
     * 郵便番号から住所取得
     */
    _getAddressByPostalCode = () => {
        if (!ValidateUtil.isEmptyExact(this.state.user!.postalCode)
            && !ValidateUtil.isErrorPostalCode(this.state.user!.postalCode!, 'both').isError) {
            AddressApiNetwork.getAddressByZipcode(this.state.user!.postalCode!).then((addressModels: AddressModel[] | null) => {
                if (!ValidateUtil.isEmptyArray(addressModels)) {
                    this.setState({pickerState: []});
                    let stateCode = addressModels![0].prefcode!;
                    this.setState({stateInitKey: stateCode});

                    this.setState({state: addressModels![0].address1});
                    this.setState({pickerState: PickerUtil.getPrefCodeMaster()});
                    this.state.city.text = addressModels![0].address2!;
                    this.setState({city: this.state.city});

                    this.state.user!.restaurantState = addressModels![0].address1!;
                    this.state.user!.restaurantCity = addressModels![0].address2!;

                    this.setState({user: this.state.user});
                }
            })
        }
    };

    /**
     * 店舗所在地（中）のピッカーを作成
     * @param value
     */
    _createPickerRestaurantLocationMedium = (value: number) => {
        let rf: RestaurantLocation | undefined = this.state.restaurantLocationMasters.find((v: RestaurantLocation) => v.large.id == value);
        if (rf != null && rf.large != null && !ValidateUtil.isEmptyArray(rf.large.medium)) {
            let pickerItems: {label: string, value: any, key: string}[] = [];
            rf.large.medium!.forEach((restaurantLocation) => {
                pickerItems.push({label: restaurantLocation.name!, value: restaurantLocation.id, key: `${restaurantLocation.id}`});
            });
            this.setState({pickerRestaurantMediumLocationId: pickerItems});
        }
    }

    /**
     * 店舗業態（中）のピッカーを作成
     * @param value
     */
    _createPickerRestaurantFormatMedium = (value: number) => {
        let rf: RestaurantFormat | undefined = this.state.restaurantFormatMasters.find((v: RestaurantFormat) => v.large.id == value);
        if (rf != null && rf.large != null && !ValidateUtil.isEmptyArray(rf.large.medium)) {
            let pickerItems: {label: string, value: any, key: string}[] = [];
            rf.large.medium!.forEach((restaurantFormat) => {
                pickerItems.push({label: restaurantFormat.name!, value: restaurantFormat.id, key: `${restaurantFormat.id}`});
            });
            this.setState({pickerRestaurantFormatMedium: pickerItems});
        }
    }

    /**
     * 線の描画
     */
    _renderBorder = () => {
        if (Platform.OS == 'web') {
            return (
                <View style={{
                    backgroundColor: appColors.spacerGray,
                    height: 1,
                    width: ScreenSizeUtil.isWebSize()? 700 : AppG.window.width - (appS.margin.size * 2),
                    marginTop: 10,
                    marginBottom: 10,
                    marginLeft: ScreenSizeUtil.isWebSize()? 0 : 16,
                    marginRight: ScreenSizeUtil.isWebSize()? 0 : 16,
                }}/>
            );
        } else {
            return (
                <View
                    style={{
                        flex: 1,
                        height: 1,
                        backgroundColor: appColors.spacerGray,
                        marginTop: Platform.OS == 'android' ? 15 : 10,
                        marginBottom: Platform.OS == 'android' ? 15 : 10,
                        marginLeft: ScreenSizeUtil.isWebSize() ? 0 : 16,
                        marginRight: ScreenSizeUtil.isWebSize() ? 0 : 16,
                    }}/>
            );
        }
    }

    /**
     * サンプル依頼に必要となります
     */
    _renderInfo = () => {
        return (
            <View>
                <SpacerComponent height={4}/>
                <View style={{flexDirection: "row"}}>
                    <Text style={{
                        color: appColors.red,
                        fontSize: appFont.size.error,
                        marginTop: 2,
                        marginBottom: 0,
                        marginRight: 0,
                        marginLeft: 4,
                    }}>※サンプル依頼に必要となります</Text>
                </View>
            </View>
        )
    }

    _renderMain = () => {
        if (this.state.user == null) {
            return null;
        }

        let profileImageSource = require('../../resources/images/no_image.png');
        if (this.state.user != null && this.state.user.image != null && this.state.user.image != '' && isURL(this.state.user.image)) {
            profileImageSource = {uri: this.state.user.image};
        }

        return (
            <KeyboardAwareScrollView style={[{flex: 1, flexDirection: 'column'}]}>
                <View style={{marginTop: this._marginSize, alignItems: 'center', justifyContent: 'center'}}>
                    {ScreenSizeUtil.isWebSize() && (
                        <Text style={{
                            flex: 1,
                            margin: 32,
                            fontWeight: 'bold',
                            color: appColors.black,
                            fontSize: appFont.size.large,
                            textAlign: 'center'
                        }}>{'プロフィール編集'}</Text>
                    )}
                </View>




                <View style={[{flex: 1, flexDirection: 'column', marginTop: this._marginSize}]}>

                    {/*ユーザー画像*/}
                    <TouchableOpacity
                        style={{alignItems: 'center', justifyContent: 'center', marginLeft: this._marginSize, marginRight: this._marginSize}}
                        onPress={() => {
                            if (Platform.OS == 'web') {
                                this._pickImage();
                            } else {
                                this.setState({isShowPhotoBottomModal: true});
                            }
                        }}
                    >
                        <Image source={profileImageSource}
                               style={[{
                                   backgroundColor: 'white',
                                   width: 120,
                                   height: 120,
                                   borderWidth: 1,
                                   borderRadius: 80,
                                   borderColor: appColors.transparent,
                               }]}
                               resizeMode={'cover'}
                        />
                        <Image source={require('../../resources/images/edit_profile_image_icon.png')}
                               style={[{
                                   position: 'relative',
                                   bottom: 44,
                                   right: -44,
                                   backgroundColor: appColors.transparent,
                                   width: 44,
                                   height: 44,
                                   borderWidth: 1,
                                   borderRadius: 50,
                                   borderColor: appColors.transparent,
                               }]}
                               resizeMode={'cover'}
                        />
                    </TouchableOpacity>

                    {/*メールアドレス*/}
                    <View style={[{marginBottom: 8, marginLeft: ScreenSizeUtil.isWebSize()? 0 : 16, marginRight: ScreenSizeUtil.isWebSize()? 0 : 16,}]}>
                        <View style={{flexDirection: 'row'}}>
                            <Text style={[this._titleStyle]}>メールアドレス</Text>
                            <View style={{flex:1}}/>
                            <Text style={{color: appColors.black, marginTop: Platform.OS == 'web' ? 2 : 8,}}>{this.state.mail}</Text>
                        </View>
                    </View>

                    {/*会員状態*/}
                    {/*<View style={[{marginBottom: 8, marginLeft: ScreenSizeUtil.isWebSize()? 0 : 16, marginRight: ScreenSizeUtil.isWebSize()? 0 : 16,}]}>*/}
                    {/*    <View style={{flexDirection: 'row'}}>*/}
                    {/*        <Text style={[this._titleStyle]}>会員状態</Text>*/}
                    {/*        <View style={{flex:1}}/>*/}
                    {/*        <Text style={{color: appColors.black, marginTop: Platform.OS == 'web' ? 2 : 8,}}>{this.state.userPlan == UserUserPlanEnum.Free? '無料会員' : (this._campaign? 'プレミアムキャンペーン会員' : 'プレミアム会員')}</Text>*/}
                    {/*    </View>*/}
                    {/*</View>*/}

                    {/*会員属性*/}
                    <View style={[{marginBottom: 16, marginLeft: ScreenSizeUtil.isWebSize()? 0 : 16, marginRight: ScreenSizeUtil.isWebSize()? 0 : 16,}]}>
                        <View style={{flexDirection: 'row'}}>
                            <Text style={[this._titleStyle]}>会員属性</Text>
                            <View style={{flex:1}}/>
                            <Text style={{
                                color: appColors.black,
                                marginTop: Platform.OS == 'web' ? 4 : 8,
                                marginLeft: 16,}}>
                                {this.state.userAttribute == UserUserAttributeEnum.Cook? '飲食人': '一般ユーザー'}</Text>
                        </View>
                    </View>

                    {this._renderBorder()}

                    {/*名前*/}
                    <View style={[{marginBottom: 0, marginLeft: ScreenSizeUtil.isWebSize()? 0 : 16, marginRight: ScreenSizeUtil.isWebSize()? 0 : 16,}]}>
                        <View style={{flexDirection: 'row'}}>
                            <Text style={[this._titleStyle]}>名前</Text>
                            <TextInput
                                style={[this._inputStyle, {}]}
                                autoCapitalize={"none"}
                                placeholder={'　名前'}
                                returnKeyType={'done'}
                                placeholderTextColor={appColors.gray}
                                value={this.state.name.text}
                                onChangeText={text => {
                                    this.setState({name: ValidateUtil.isErrorUserName(text)});
                                    this.state.user!.name = text;
                                    this.setState({user: this.state.user});
                                }}
                            />
                        </View>
                        {this._renderInfo()}
                        <ValidationErrorMessageComponent
                            text={this.state.name.errorText}
                            isError={this.state.name.isError}
                        />
                    </View>

                    {this._renderBorder()}

                    {/*名前（かな）*/}
                    <View style={[{marginBottom: 0, marginLeft: ScreenSizeUtil.isWebSize()? 0 : 16, marginRight: ScreenSizeUtil.isWebSize()? 0 : 16,}]}>
                        <View style={{flexDirection: 'row'}}>
                            <Text style={[this._titleStyle]}>名前（かな）</Text>
                            <TextInput
                                style={[this._inputStyle, {}]}
                                autoCapitalize={"none"}
                                placeholder={'　名前（かな）'}
                                returnKeyType={'done'}
                                placeholderTextColor={appColors.gray}
                                value={this.state.nameKana.text}
                                onChangeText={text => {
                                    this.setState({nameKana: ValidateUtil.isErrorUserNameKana(text)});
                                    this.state.user!.nameKana = text;
                                    this.setState({user: this.state.user});
                                }}
                            />
                        </View>
                        {this._renderInfo()}
                        <ValidationErrorMessageComponent
                            text={this.state.nameKana.errorText}
                            isError={this.state.nameKana.isError}
                        />
                    </View>

                    {this._renderBorder()}

                    {/*性別*/}
                    <View style={[{marginBottom: 0, marginLeft: ScreenSizeUtil.isWebSize()? 0 : 16, marginRight: ScreenSizeUtil.isWebSize()? 0 : 16,}]}>
                        <View style={{flexDirection: 'row'}}>
                            <Text style={[this._titleStyle]}>性別</Text>
                            <View style={{width: 170}}>
                                {/*選択済みver*/}
                                {!ValidateUtil.isEmptyArray(this.state.pickerGender) && (
                                    <RNPickerSelect
                                        style={this._getPickerStyle(ValidateUtil.isEmptyExact(this.state.user!.gender))}
                                        placeholder={{ label: '未選択', value: '', key: '' }}
                                        onValueChange={(value) => {
                                            this.setState({state: value});
                                            this.state.user!.gender = value;
                                            this.setState({user: this.state.user});
                                        }}
                                        items={this.state.pickerGender}
                                        itemKey={this.state.gender == null? '' : this.state.gender}
                                        Icon={() => (<PullDownIconComponent right={50} top={12}/>)}
                                    />
                                )}
                            </View>
                        </View>
                    </View>

                    {this._renderBorder()}

                    {/*生年月日*/}
                    <View style={[{flexDirection: "row", marginBottom: 0, marginLeft: ScreenSizeUtil.isWebSize()? 0 : 16, marginRight: ScreenSizeUtil.isWebSize()? 0 : 16,}]}>
                        <Text style={[this._titleStyle]}>生年月日</Text>
                        {/*年*/}
                        <View style={{flexDirection: "row", marginRight: 0}}>
                            <View style={{width: Platform.OS == 'web'? 90 : (Platform.OS == 'android'? 120 : 55)}}>
                                <RNPickerSelect
                                    style={this._getPickerStyle(ValidateUtil.isEmpty(this.state.yyyy))}
                                    placeholder={{ label: 'xxxx', value: '', key: '' }}
                                    onValueChange={(value) => {
                                        this.setState({yyyy: value});
                                        setTimeout(() => this._setBirthday(), 100);
                                    }}
                                    items={PickerUtil.getYyyyMaster()}
                                    itemKey={this.state.yyyy}
                                    // Icon={() => (<PullDownIconComponent right={0} top={12}/>)}
                                />
                            </View>
                            <Text style={{marginTop: Platform.OS == 'web'? 0 : 4, marginLeft: 0, fontSize: appFont.size.middle, color: appColors.black}}>.</Text>
                        </View>
                        {/*月*/}
                        <View style={{flexDirection: "row", marginRight: 0}}>
                            <View style={{width: Platform.OS == 'web'? 70 : (Platform.OS == 'android'? 100 : 30)}}>
                                <RNPickerSelect
                                    style={this._getPickerStyle(ValidateUtil.isEmpty(this.state.mm))}
                                    placeholder={{ label: 'xx', value: '', key: '' }}
                                    onValueChange={(value) => {
                                        this.setState({mm: value});
                                        setTimeout(() => this._setBirthday(), 100);
                                    }}
                                    items={PickerUtil.getMmMaster()}
                                    itemKey={this.state.mm}
                                    // Icon={() => (<PullDownIconComponent right={0} top={12}/>)}
                                />
                            </View>
                            <Text style={{marginTop: Platform.OS == 'web'? 0 : 4, marginLeft: 0, fontSize: appFont.size.middle, color: appColors.black}}>.</Text>
                        </View>
                        {/*日*/}
                        <View style={{flexDirection: "row"}}>
                            <View style={{width: Platform.OS == 'web'? 70 : (Platform.OS == 'android'? 100 : 30)}}>
                                <RNPickerSelect
                                    style={this._getPickerStyle(ValidateUtil.isEmpty(this.state.dd))}
                                    placeholder={{ label: 'xx', value: '', key: '' }}
                                    onValueChange={(value) => {
                                        this.setState({dd: value});
                                        setTimeout(() => this._setBirthday(), 100);
                                    }}
                                    items={PickerUtil.getDdMaster(this.state.yyyy, this.state.mm)}
                                    itemKey={this.state.dd}
                                    // Icon={() => (<PullDownIconComponent right={0} top={12}/>)}
                                />
                            </View>
                            {/*<Text style={{marginTop: Platform.OS == 'web'? 0 : 4, marginLeft: 8, fontSize: appFont.size.middle, color: appColors.black, fontWeight: "bold"}}>日</Text>*/}
                        </View>
                    </View>
                    <View style={{marginLeft: this._marginSize}}>
                        <ValidationErrorMessageComponent
                            text={`${I18n.t('errorDate')}`}
                            isError={
                                (
                                    !ValidateUtil.isEmptyExact(this.state.yyyy)
                                    || !ValidateUtil.isEmptyExact(this.state.mm)
                                    || !ValidateUtil.isEmptyExact(this.state.dd)
                                )
                                && (!ValidateUtil.isRightDate(this.state.yyyy, this.state.mm, this.state.dd)
                                    || !moment(this.state.user.birthday, 'YYYY-MM-DD').isValid())
                            }
                        />
                    </View>

                    {this._renderBorder()}





                    {this.state.user!.userAttribute == UserUserAttributeEnum.Cook && (
                        <View>
                            <View style={[{marginBottom: 0, marginLeft: ScreenSizeUtil.isWebSize()? 0 : 16, marginRight: ScreenSizeUtil.isWebSize()? 0 : 16,}]}>
                                {/*店舗名*/}
                                <View style={{flexDirection: 'row'}}>
                                    <Text style={[this._titleStyle]}>店舗名</Text>
                                    <TextInput
                                        style={[this._inputStyle, {}]}
                                        autoCapitalize={"none"}
                                        placeholder={'　店舗名'}
                                        returnKeyType={'done'}
                                        placeholderTextColor={appColors.gray}
                                        value={this.state.nameRestaurant.text}
                                        onChangeText={text => {
                                            this.setState({nameRestaurant: ValidateUtil.isErrorNameRestaurant(text)});
                                            this.state.user!.nameRestaurant = text;
                                            this.setState({user: this.state.user});
                                        }}
                                    />
                                </View>
                                {this._renderInfo()}
                                <ValidationErrorMessageComponent
                                    text={this.state.nameRestaurant.errorText}
                                    isError={this.state.nameRestaurant.isError}
                                />
                            </View>

                            {this._renderBorder()}

                            {/*出店エリア（店舗所在地（大分類））*/}
                            <View style={[{marginBottom: 0, marginLeft: ScreenSizeUtil.isWebSize()? 0 : 16, marginRight: ScreenSizeUtil.isWebSize()? 0 : 16,}]}>
                                <View style={{flexDirection: 'row'}}>
                                    <Text style={[this._titleStyle]}>出店エリア</Text>
                                    <View style={{width: 170}}>
                                        {!ValidateUtil.isEmptyArray(this.state.pickerRestaurantLargeLocationId)
                                            && ValidateUtil.isEmptyExact(this.state.pickerRestaurantLargeLocationInitKey) && (
                                                <RNPickerSelect
                                                    style={this._getPickerStyle(ValidateUtil.isEmptySelectBox(this.state.user!.restaurantLargeLocationId))}
                                                    // placeholder={{}}
                                                    onValueChange={(value) => {
                                                        this._selectedRestaurantLargeLocationId = true;
                                                        this.setState({restaurantLargeLocationId: value});
                                                        this.state.user!.restaurantLargeLocationId = value;
                                                        this.setState({user: this.state.user});
                                                        this._createPickerRestaurantLocationMedium(value);
                                                    }}
                                                    items={this.state.pickerRestaurantLargeLocationId}
                                                    Icon={() => (<PullDownIconComponent right={0} top={12}/>)}
                                                />
                                            )}
                                        {/*選択済みver*/}
                                        {!ValidateUtil.isEmptyArray(this.state.pickerRestaurantLargeLocationId)
                                            && !ValidateUtil.isEmptyExact(this.state.pickerRestaurantLargeLocationInitKey) && (
                                                <RNPickerSelect
                                                    style={this._getPickerStyle(ValidateUtil.isEmptySelectBox(this.state.user!.restaurantLargeLocationId))}
                                                    // placeholder={{}}
                                                    onValueChange={(value) => {
                                                        this._selectedRestaurantLargeLocationId = true;
                                                        this.setState({restaurantLargeLocationId: value});
                                                        this.state.user!.restaurantLargeLocationId = value;
                                                        this.setState({user: this.state.user});
                                                        this._createPickerRestaurantLocationMedium(value);
                                                    }}
                                                    items={this.state.pickerRestaurantLargeLocationId}
                                                    itemKey={this.state.pickerRestaurantLargeLocationInitKey}
                                                    Icon={() => (<PullDownIconComponent right={0} top={12}/>)}
                                                />
                                            )}
                                    </View>
                                </View>
                                <ValidationErrorMessageComponent
                                    text={`${I18n.t('pleasEnterAnItem', {item: `出店エリア`})}`}
                                    isError={this._selectedRestaurantLargeLocationId && ValidateUtil.isEmptySelectBox(this.state.user!.restaurantLargeLocationId)}
                                />
                            </View>

                            {this._renderBorder()}

                            {/*/!*店舗所在地（中分類）削除*!/*/}
                            {/*<View style={[{marginBottom: 0, marginLeft: ScreenSizeUtil.isWebSize()? 0 : 16, marginRight: ScreenSizeUtil.isWebSize()? 0 : 16,}]}>*/}
                            {/*    <View style={{flexDirection: 'row'}}>*/}
                            {/*        <Text style={[this._titleStyle]}>店舗所在地（中分類）</Text>*/}
                            {/*        <View style={{width: 300}}>*/}
                            {/*            {!ValidateUtil.isEmptyArray(this.state.pickerRestaurantMediumLocationId)*/}
                            {/*            && ValidateUtil.isEmptyExact(this.state.pickerRestaurantMediumLocationInitKey) && (*/}
                            {/*                <RNPickerSelect*/}
                            {/*                    style={this._getPickerStyle()}*/}
                            {/*                    // placeholder={{}}*/}
                            {/*                    onValueChange={(value) => {*/}
                            {/*                        this._selectedRestaurantMediumLocationId = true;*/}
                            {/*                        this.setState({restaurantMediumLocationId: value});*/}
                            {/*                        this.state.user!.restaurantMediumLocationId = value;*/}
                            {/*                        this.setState({user: this.state.user});*/}
                            {/*                    }}*/}
                            {/*                    items={this.state.pickerRestaurantMediumLocationId}*/}
                            {/*                    Icon={() => (<PullDownIconComponent right={0} top={12}/>)}*/}
                            {/*                />*/}
                            {/*            )}*/}
                            {/*            /!*選択済みver*!/*/}
                            {/*            {!ValidateUtil.isEmptyArray(this.state.pickerRestaurantMediumLocationId)*/}
                            {/*            && !ValidateUtil.isEmptyExact(this.state.pickerRestaurantMediumLocationInitKey) && (*/}
                            {/*                <RNPickerSelect*/}
                            {/*                    style={this._getPickerStyle()}*/}
                            {/*                    // placeholder={{}}*/}
                            {/*                    onValueChange={(value) => {*/}
                            {/*                        this._selectedRestaurantMediumLocationId = true;*/}
                            {/*                        this.setState({restaurantMediumLocationId: value});*/}
                            {/*                        this.state.user!.restaurantMediumLocationId = value;*/}
                            {/*                        this.setState({user: this.state.user});*/}
                            {/*                    }}*/}
                            {/*                    items={this.state.pickerRestaurantMediumLocationId}*/}
                            {/*                    itemKey={this.state.pickerRestaurantMediumLocationInitKey}*/}
                            {/*                    Icon={() => (<PullDownIconComponent right={0} top={12}/>)}*/}
                            {/*                />*/}
                            {/*            )}*/}
                            {/*        </View>*/}
                            {/*    </View>*/}
                            {/*    <ValidationErrorMessageComponent*/}
                            {/*        text={`${I18n.t('pleasEnterAnItem', {item: `店舗所在地（中分類）`})}`}*/}
                            {/*        isError={this._selectedRestaurantMediumLocationId && ValidateUtil.isEmptySelectBox(this.state.user!.restaurantMediumLocationId)}*/}
                            {/*    />*/}
                            {/*</View>*/}

                            {/*{this._renderBorder()}*/}

                            {/*郵便番号*/}
                            <View style={[{marginBottom: 0, marginLeft: ScreenSizeUtil.isWebSize()? 0 : 16, marginRight: ScreenSizeUtil.isWebSize()? 0 : 16,}]}>
                                <View style={{flexDirection: 'row'}}>
                                    <Text style={[this._titleStyle]}>郵便番号</Text>
                                    <View style={{flexDirection: "row"}}>
                                        <TextInput
                                            style={[{
                                                height: 30,
                                                backgroundColor: appColors.white,
                                                padding: 8,
                                                width: 80
                                            }]}
                                            autoCapitalize={"none"}
                                            placeholder={'123'}
                                            returnKeyType={'done'}
                                            keyboardType={"number-pad"}
                                            placeholderTextColor={appColors.gray}
                                            value={this.state.postalCode1.text}
                                            onChangeText={text => {
                                                this.setState({postalCode1: ValidateUtil.isErrorPostalCode(text, 'left')});
                                                this.state.user!.postalCode = `${text}${this.state.postalCode2.text}`;
                                                this.setState({user: this.state.user});
                                                this._getAddressByPostalCode();
                                            }}
                                        />
                                        <Text style={{marginLeft: -36, marginRight: 2, marginTop: 7, color: appColors.black}}>-</Text>
                                        <TextInput
                                            style={[{
                                                height: 30,
                                                backgroundColor: appColors.white,
                                                padding: 8,
                                                width: 80
                                            }]}
                                            autoCapitalize={"none"}
                                            placeholder={'5678'}
                                            returnKeyType={'done'}
                                            keyboardType={"number-pad"}
                                            placeholderTextColor={appColors.gray}
                                            value={this.state.postalCode2.text}
                                            onChangeText={text => {
                                                this.setState({postalCode2: ValidateUtil.isErrorPostalCode(text, 'right')});
                                                this.state.user!.postalCode = `${this.state.postalCode1.text}${text}`;
                                                this.setState({user: this.state.user});
                                                this._getAddressByPostalCode();
                                            }}
                                        />
                                    </View>
                                </View>
                                {this._renderInfo()}
                                <ValidationErrorMessageComponent
                                    text={this.state.postalCode1.errorText}
                                    isError={this.state.postalCode1.isError}
                                />
                                <ValidationErrorMessageComponent
                                    text={this.state.postalCode2.errorText}
                                    isError={this.state.postalCode2.isError}
                                />
                            </View>

                            {this._renderBorder()}

                            {/*都道府県*/}
                            <View style={[{marginBottom: 0, marginLeft: ScreenSizeUtil.isWebSize()? 0 : 16, marginRight: ScreenSizeUtil.isWebSize()? 0 : 16,}]}>
                                <View style={{flexDirection: 'row'}}>
                                    <Text style={[this._titleStyle]}>都道府県</Text>
                                    <View style={{width: 170}}>
                                        {/*未選択ver*/}
                                        {!ValidateUtil.isEmptyArray(this.state.pickerState)
                                            && ValidateUtil.isEmptyExact(this.state.stateInitKey)&& (
                                                <RNPickerSelect
                                                    style={this._getPickerStyle(ValidateUtil.isEmptySelectBox(this.state.user!.restaurantState))}
                                                    // placeholder={{}}
                                                    onValueChange={(value) => {
                                                        this._selectedState = true;
                                                        this.setState({state: value});
                                                        this.state.user!.restaurantState = value;
                                                        this.setState({user: this.state.user});
                                                    }}
                                                    items={this.state.pickerState}
                                                    Icon={() => (<PullDownIconComponent right={50} top={12}/>)}
                                                />
                                            )}
                                        {/*選択済みver*/}
                                        {!ValidateUtil.isEmptyArray(this.state.pickerState)
                                            && !ValidateUtil.isEmptyExact(this.state.stateInitKey) && (
                                                <RNPickerSelect
                                                    style={this._getPickerStyle(ValidateUtil.isEmptySelectBox(this.state.user!.restaurantState))}
                                                    // placeholder={{}}
                                                    onValueChange={(value) => {
                                                        this._selectedState = true;
                                                        this.setState({state: value});
                                                        this.state.user!.restaurantState = value;
                                                        this.setState({user: this.state.user});
                                                    }}
                                                    items={this.state.pickerState}
                                                    itemKey={this.state.stateInitKey}
                                                    Icon={() => (<PullDownIconComponent right={50} top={12}/>)}
                                                />
                                            )}
                                    </View>
                                </View>
                                {this._renderInfo()}
                                {/*<ValidationErrorMessageComponent*/}
                                {/*    text={`${I18n.t('pleasEnterAnItem', {item: `都道府県`})}`}*/}
                                {/*    isError={this._selectedState && ValidateUtil.isEmptySelectBox(this.state.user!.restaurantState)}*/}
                                {/*/>*/}
                            </View>

                            {this._renderBorder()}

                            {/*市区町村*/}
                            <View style={[{marginBottom: 0, marginLeft: ScreenSizeUtil.isWebSize()? 0 : 16, marginRight: ScreenSizeUtil.isWebSize()? 0 : 16,}]}>
                                <View style={{flexDirection: 'row'}}>
                                    <Text style={[this._titleStyle]}>市区町村</Text>
                                    <TextInput
                                        style={[this._inputStyle, {}]}
                                        autoCapitalize={"none"}
                                        placeholder={'　〇〇市□□区'}
                                        returnKeyType={'done'}
                                        placeholderTextColor={appColors.gray}
                                        value={this.state.city.text}
                                        onChangeText={text => {
                                            this.setState({city: ValidateUtil.isErrorRestaurantCity(text)});
                                            this.state.user!.restaurantCity = text;
                                            this.setState({user: this.state.user});
                                        }}
                                    />
                                </View>
                                {this._renderInfo()}
                                {/*<ValidationErrorMessageComponent*/}
                                {/*    text={this.state.city.errorText}*/}
                                {/*    isError={this.state.city.isError}*/}
                                {/*/>*/}
                            </View>

                            {this._renderBorder()}

                            {/*番地以降*/}
                            <View style={[{marginBottom: 0, marginLeft: ScreenSizeUtil.isWebSize()? 0 : 16, marginRight: ScreenSizeUtil.isWebSize()? 0 : 16,}]}>
                                <View style={{flexDirection: 'row'}}>
                                    <Text style={[this._titleStyle]}>番地以降</Text>
                                    <TextInput
                                        style={[this._inputStyle, {}]}
                                        autoCapitalize={"none"}
                                        placeholder={'　□□町0-0-0、xxビル3F'}
                                        returnKeyType={'done'}
                                        placeholderTextColor={appColors.gray}
                                        value={this.state.streetAddress.text}
                                        onChangeText={text => {
                                            this.setState({streetAddress: ValidateUtil.isErrorRestaurantStreetAddress(text)});
                                            this.state.user!.restaurantStreetAddress = text;
                                            this.setState({user: this.state.user});
                                        }}
                                    />
                                </View>
                                {this._renderInfo()}
                                <ValidationErrorMessageComponent
                                    text={this.state.streetAddress.errorText}
                                    isError={this.state.streetAddress.isError}
                                />
                            </View>

                            {this._renderBorder()}

                            {/*電話番号*/}
                            <View style={[{marginBottom: 0, marginLeft: ScreenSizeUtil.isWebSize()? 0 : 16, marginRight: ScreenSizeUtil.isWebSize()? 0 : 16,}]}>
                                <View style={{flexDirection: 'row'}}>
                                    <Text style={[this._titleStyle]}>電話番号</Text>
                                    <TextInput
                                        style={[this._inputStyle, {marginTop: Platform.OS == 'web'?-3:0}]}
                                        autoCapitalize={"none"}
                                        placeholder={'　0801234567'}
                                        returnKeyType={'done'}
                                        keyboardType={"number-pad"}
                                        placeholderTextColor={appColors.gray}
                                        value={this.state.tell.text}
                                        onChangeText={text => {
                                            this.setState({tell: ValidateUtil.isErrorTell(text)});
                                            this.state.user!.tell = text;
                                            this.setState({user: this.state.user});
                                        }}
                                    />
                                </View>
                                {this._renderInfo()}
                                <ValidationErrorMessageComponent
                                    text={this.state.tell.errorText}
                                    isError={this.state.tell.isError}
                                />
                            </View>

                            {this._renderBorder()}

                            {/*店舗業態（大分類）*/}
                            <View style={[{marginBottom: 0, marginLeft: ScreenSizeUtil.isWebSize()? 0 : 16, marginRight: ScreenSizeUtil.isWebSize()? 0 : 16,}]}>
                                <View style={{flexDirection: 'row'}}>
                                    <Text style={[this._titleStyle]}>店舗業態（大分類）</Text>
                                    <View style={{width: 200}}>
                                        {!ValidateUtil.isEmptyArray(this.state.pickerRestaurantFormatLarge)
                                            && ValidateUtil.isEmptyExact(this.state.restaurantFormatLargeInitKey) && (
                                                <RNPickerSelect
                                                    style={this._getPickerStyle(ValidateUtil.isEmptySelectBox(this.state.user!.restaurantLargeFormatId))}
                                                    // placeholder={{}}
                                                    onValueChange={(value) => {
                                                        this._selectedRestaurantLargeFormatId = true;
                                                        this.setState({restaurantLargeFormatId: value});
                                                        this.state.user!.restaurantLargeFormatId = value;
                                                        this.setState({user: this.state.user});
                                                        this._createPickerRestaurantFormatMedium(value);
                                                    }}
                                                    items={this.state.pickerRestaurantFormatLarge}
                                                    Icon={() => (<PullDownIconComponent right={0} top={12}/>)}
                                                />
                                            )}
                                        {/*選択済みver*/}
                                        {!ValidateUtil.isEmptyArray(this.state.pickerRestaurantFormatLarge)
                                            && !ValidateUtil.isEmptyExact(this.state.restaurantFormatLargeInitKey) && (
                                                <RNPickerSelect
                                                    style={this._getPickerStyle(ValidateUtil.isEmptySelectBox(this.state.user!.restaurantLargeFormatId))}
                                                    // placeholder={{}}
                                                    onValueChange={(value) => {
                                                        this._selectedRestaurantLargeFormatId = true;
                                                        this.setState({restaurantLargeFormatId: value});
                                                        this.state.user!.restaurantLargeFormatId = value;
                                                        this.setState({user: this.state.user});
                                                        this._createPickerRestaurantFormatMedium(value);
                                                    }}
                                                    items={this.state.pickerRestaurantFormatLarge}
                                                    itemKey={this.state.restaurantFormatLargeInitKey}
                                                    Icon={() => (<PullDownIconComponent right={0} top={12}/>)}
                                                />
                                            )}
                                    </View>
                                </View>
                                <ValidationErrorMessageComponent
                                    text={`${I18n.t('pleasEnterAnItem', {item: `店舗業態（大分類）`})}`}
                                    isError={this._selectedRestaurantLargeFormatId && ValidateUtil.isEmptySelectBox(this.state.user!.restaurantLargeFormatId)}
                                />
                            </View>

                            {this._renderBorder()}

                            {/*店舗業態（中分類）*/}
                            <View style={[{marginBottom: 0, marginLeft: ScreenSizeUtil.isWebSize()? 0 : 16, marginRight: ScreenSizeUtil.isWebSize()? 0 : 16,}]}>
                                <View style={{flexDirection: 'row'}}>
                                    <Text style={[this._titleStyle]}>店舗業態（中分類）</Text>
                                    <View style={{width: 300}}>
                                        {!ValidateUtil.isEmptyArray(this.state.pickerRestaurantFormatMedium)
                                            && ValidateUtil.isEmptyExact(this.state.restaurantFormatMediumInitKey) && (
                                                <RNPickerSelect
                                                    style={this._getPickerStyle(ValidateUtil.isEmptySelectBox(this.state.user!.restaurantMediumFormatId))}
                                                    // placeholder={{}}
                                                    onValueChange={(value) => {
                                                        this._selectedRestaurantMediumFormatId = true;
                                                        this.setState({restaurantMediumFormatId: value});
                                                        this.state.user!.restaurantMediumFormatId = value;
                                                        this.setState({user: this.state.user});
                                                    }}
                                                    items={this.state.pickerRestaurantFormatMedium}
                                                    Icon={() => (<PullDownIconComponent right={0} top={12}/>)}
                                                />
                                            )}
                                        {/*選択済みver*/}
                                        {!ValidateUtil.isEmptyArray(this.state.pickerRestaurantFormatMedium)
                                            && !ValidateUtil.isEmptyExact(this.state.restaurantFormatMediumInitKey) && (
                                                <RNPickerSelect
                                                    style={this._getPickerStyle(ValidateUtil.isEmptySelectBox(this.state.user!.restaurantMediumFormatId))}
                                                    // placeholder={{}}
                                                    onValueChange={(value) => {
                                                        this._selectedRestaurantMediumFormatId = true;
                                                        this.setState({restaurantMediumFormatId: value});
                                                        this.state.user!.restaurantMediumFormatId = value;
                                                        this.setState({user: this.state.user});
                                                    }}
                                                    items={this.state.pickerRestaurantFormatMedium}
                                                    itemKey={this.state.restaurantFormatMediumInitKey}
                                                    Icon={() => (<PullDownIconComponent right={0} top={12}/>)}
                                                />
                                            )}
                                    </View>
                                </View>
                                <ValidationErrorMessageComponent
                                    text={`${I18n.t('pleasEnterAnItem', {item: `店舗業態（中分類）`})}`}
                                    isError={this._selectedRestaurantMediumFormatId && ValidateUtil.isEmptySelectBox(this.state.user!.restaurantMediumFormatId)}
                                />
                            </View>

                            {this._renderBorder()}

                            {/*店舗規模（店舗数）*/}
                            <View style={[{marginBottom: 0, marginLeft: ScreenSizeUtil.isWebSize()? 0 : 16, marginRight: ScreenSizeUtil.isWebSize()? 0 : 16,}]}>
                                <View style={{flexDirection: 'row'}}>
                                    <Text style={[this._titleStyle]}>店舗規模（店舗数）</Text>
                                    <View style={{width: 170}}>
                                        {!ValidateUtil.isEmptyArray(this.state.pickerNumOfRestaurantId)
                                            && ValidateUtil.isEmptyExact(this.state.numOfRestaurantInitKey) && (
                                                <RNPickerSelect
                                                    style={this._getPickerStyle(ValidateUtil.isEmptySelectBox(this.state.user!.numOfRestaurantId))}
                                                    // placeholder={{}}
                                                    onValueChange={(value) => {
                                                        this._selectedNumOfRestaurantId = true;
                                                        this.setState({numOfRestaurantId: value});
                                                        this.state.user!.numOfRestaurantId = value;
                                                        this.setState({user: this.state.user});
                                                    }}
                                                    items={this.state.pickerNumOfRestaurantId}
                                                    Icon={() => (<PullDownIconComponent right={50} top={12}/>)}
                                                />
                                            )}
                                        {/*選択済みver*/}
                                        {!ValidateUtil.isEmptyArray(this.state.pickerNumOfRestaurantId)
                                            && !ValidateUtil.isEmptyExact(this.state.numOfRestaurantInitKey) && (
                                                <RNPickerSelect
                                                    style={this._getPickerStyle(ValidateUtil.isEmptySelectBox(this.state.user!.numOfRestaurantId))}
                                                    // placeholder={{}}
                                                    onValueChange={(value) => {
                                                        this._selectedNumOfRestaurantId = true;
                                                        this.setState({numOfRestaurantId: value});
                                                        this.state.user!.numOfRestaurantId = value;
                                                        this.setState({user: this.state.user});
                                                    }}
                                                    items={this.state.pickerNumOfRestaurantId}
                                                    itemKey={this.state.numOfRestaurantInitKey}
                                                    Icon={() => (<PullDownIconComponent right={50} top={12}/>)}
                                                />
                                            )}
                                    </View>
                                </View>
                                <ValidationErrorMessageComponent
                                    text={`${I18n.t('pleasEnterAnItem', {item: `店舗規模（店舗数）`})}`}
                                    isError={this._selectedNumOfRestaurantId && ValidateUtil.isEmptySelectBox(this.state.user.numOfRestaurantId)}
                                />
                            </View>

                            {this._renderBorder()}

                            {/*主要店舗展開エリア*/}
                            <View style={[{marginBottom: 0, marginLeft: ScreenSizeUtil.isWebSize()? 0 : 16, marginRight: ScreenSizeUtil.isWebSize()? 0 : 16,}]}>
                                <View style={{flexDirection: 'row'}}>
                                    <Text style={[this._titleStyle]}>主要店舗展開エリア</Text>
                                    <View style={{width: 170}}>
                                        {!ValidateUtil.isEmptyArray(this.state.pickerMajorRestaurantAreaIds)
                                            && ValidateUtil.isEmptyExact(this.state.majorRestaurantAreaInitKey) && (
                                                <RNPickerSelect
                                                    style={this._getPickerStyle(ValidateUtil.isEmptySelectBox(this.state.user!.majorRestaurantAreaId))}
                                                    // placeholder={{}}
                                                    onValueChange={(value) => {
                                                        this.setState({majorRestaurantAreaId: value});
                                                        this.state.user!.majorRestaurantAreaId = value;
                                                        this.setState({user: this.state.user});
                                                    }}
                                                    items={this.state.pickerMajorRestaurantAreaIds}
                                                    Icon={() => (<PullDownIconComponent right={50} top={12}/>)}
                                                />
                                            )}
                                        {/*選択済みver*/}
                                        {!ValidateUtil.isEmptyArray(this.state.pickerMajorRestaurantAreaIds)
                                            && !ValidateUtil.isEmptyExact(this.state.majorRestaurantAreaInitKey) && (
                                                <RNPickerSelect
                                                    style={this._getPickerStyle(ValidateUtil.isEmptySelectBox(this.state.user!.majorRestaurantAreaId))}
                                                    // placeholder={{}}
                                                    onValueChange={(value) => {
                                                        this.setState({majorRestaurantAreaId: value});
                                                        this.state.user!.majorRestaurantAreaId = value;
                                                        this.setState({user: this.state.user});
                                                    }}
                                                    items={this.state.pickerMajorRestaurantAreaIds}
                                                    itemKey={this.state.majorRestaurantAreaInitKey}
                                                    Icon={() => (<PullDownIconComponent right={50} top={12}/>)}
                                                />
                                            )}
                                    </View>
                                </View>
                            </View>

                            {this._renderBorder()}

                            {/*ランチ営業*/}
                            <View style={[{marginBottom: 0, marginLeft: ScreenSizeUtil.isWebSize()? 0 : 16, marginRight: ScreenSizeUtil.isWebSize()? 0 : 16,}]}>
                                <View style={{flexDirection: 'row'}}>
                                    <Text style={[this._titleStyle]}>ランチ営業</Text>
                                    <Text style={{
                                        color: appColors.black,
                                        marginTop: Platform.OS == 'web' ? 4 : 8,
                                        marginLeft: 16,}}>
                                        {this.state.lunchService == 1? '有り': '無し'}</Text>
                                    <View style={{marginLeft: 16, marginTop: 6}}>
                                        <Switch
                                            value={this.state.lunchService == 1}
                                            onValueChange={(val) => {
                                                this.setState({lunchService: val? 1:0});
                                                this.state.user!.lunchService = val? 1:0;
                                                this.setState({user: this.state.user});
                                            }}
                                            disabled={false}
                                            circleSize={30}
                                            barHeight={20}
                                            circleBorderWidth={0}
                                            backgroundActive={appColors.switchBackgroundTrue}
                                            backgroundInactive={appColors.switchBackgroundFalse}
                                            circleActiveColor={appColors.switchCircleTrue}
                                            circleInActiveColor={appColors.switchCircleFalse}
                                            changeValueImmediately={true}
                                            innerCircleStyle={{
                                                shadowColor: 'black',
                                                shadowOffset: {
                                                    width: 0,
                                                    height: 2,
                                                },
                                                shadowOpacity: 0.25,
                                                shadowRadius: 4,
                                                elevation: 1,
                                                alignItems: "center",
                                                justifyContent: "center" }} // style for inner animated circle for what you (may) be rendering inside the circle
                                            outerCircleStyle={{}} // style for outer animated circle
                                            renderActiveText={false}
                                            renderInActiveText={false}
                                            switchBorderRadius={30} // Sets the border Radius of the switch slider. If unset, it remains the circleSize.
                                        />
                                    </View>
                                </View>
                            </View>

                            {this._renderBorder()}

                            {/*客単価*/}
                            <View style={[{marginBottom: 0, marginLeft: ScreenSizeUtil.isWebSize()? 0 : 16, marginRight: ScreenSizeUtil.isWebSize()? 0 : 16,}]}>
                                <View style={{flexDirection: 'row'}}>
                                    <Text style={[this._titleStyle]}>客単価（ディナー）</Text>
                                    <View style={{width: 170}}>
                                        {!ValidateUtil.isEmptyArray(this.state.pickerCustomerUnitPriceId)
                                            && ValidateUtil.isEmptyExact(this.state.customerUnitPriceInitKey) && (
                                                <RNPickerSelect
                                                    style={this._getPickerStyle(ValidateUtil.isEmptySelectBox(this.state.user!.customerUnitPriceId))}
                                                    // placeholder={{}}
                                                    onValueChange={(value) => {
                                                        this._selectedCustomerUnitPriceId = true;
                                                        this.setState({customerUnitPriceId: value});
                                                        this.state.user!.customerUnitPriceId = value;
                                                        this.setState({user: this.state.user});
                                                    }}
                                                    items={this.state.pickerCustomerUnitPriceId}
                                                    Icon={() => (<PullDownIconComponent right={50} top={12}/>)}
                                                />
                                            )}
                                        {/*選択済みver*/}
                                        {!ValidateUtil.isEmptyArray(this.state.pickerCustomerUnitPriceId)
                                            && !ValidateUtil.isEmptyExact(this.state.customerUnitPriceInitKey) && (
                                                <RNPickerSelect
                                                    style={this._getPickerStyle(ValidateUtil.isEmptySelectBox(this.state.user!.customerUnitPriceId))}
                                                    // placeholder={{}}
                                                    onValueChange={(value) => {
                                                        this._selectedCustomerUnitPriceId = true;
                                                        this.setState({customerUnitPriceId: value});
                                                        this.state.user!.customerUnitPriceId = value;
                                                        this.setState({user: this.state.user});
                                                    }}
                                                    items={this.state.pickerCustomerUnitPriceId}
                                                    itemKey={this.state.customerUnitPriceInitKey}
                                                    Icon={() => (<PullDownIconComponent right={0} top={12}/>)}
                                                />
                                            )}
                                    </View>
                                </View>
                                <ValidationErrorMessageComponent
                                    text={`${I18n.t('pleasEnterAnItem', {item: `客単価`})}`}
                                    isError={this._selectedCustomerUnitPriceId && ValidateUtil.isEmptySelectBox(this.state.user!.customerUnitPriceId)}
                                />
                            </View>

                            {this._renderBorder()}

                            {/*席数*/}
                            <View style={[{marginBottom: 0, marginLeft: ScreenSizeUtil.isWebSize()? 0 : 16, marginRight: ScreenSizeUtil.isWebSize()? 0 : 16,}]}>
                                <View style={{flexDirection: 'row'}}>
                                    <Text style={[this._titleStyle]}>席数</Text>
                                    <TextInput
                                        style={[this._inputStyle, {marginTop: Platform.OS == 'web'?-7:0}]}
                                        autoCapitalize={"none"}
                                        placeholder={'　20'}
                                        returnKeyType={'done'}
                                        placeholderTextColor={appColors.gray}
                                        value={this.state.numberOfSeats.text}
                                        onChangeText={text => {
                                            this.setState({numberOfSeats: ValidateUtil.isErrorNumOfSeats(text)});
                                            if (ValidateUtil.isNumber(text)) {
                                                this.state.user!.numberOfSeats = parseInt(text);
                                                this.setState({user: this.state.user});
                                            }
                                        }}
                                    />
                                </View>
                                <ValidationErrorMessageComponent
                                    text={this.state.numberOfSeats.errorText}
                                    isError={this.state.numberOfSeats.isError}
                                />
                            </View>

                            {this._renderBorder()}

                            {/*WebUrl*/}
                            <View style={[{marginBottom: 0, marginLeft: ScreenSizeUtil.isWebSize()? 0 : 16, marginRight: ScreenSizeUtil.isWebSize()? 0 : 16,}]}>
                                <View style={{flexDirection: 'row'}}>
                                    <Text style={[this._titleStyle]}>WebURL</Text>
                                    <TextInput
                                        style={[this._inputStyle, {marginTop: Platform.OS == 'web'?-7:0}]}
                                        autoCapitalize={"none"}
                                        placeholder={'　https://xxx.xxx.xxx/xxx'}
                                        returnKeyType={'done'}
                                        placeholderTextColor={appColors.gray}
                                        value={this.state.site.text}
                                        onChangeText={text => {
                                            this.setState({site: ValidateUtil.isErrorWebUrl(text)});
                                            this.state.user!.site = text;
                                            this.setState({user: this.state.user});
                                        }}
                                    />
                                </View>
                                <ValidationErrorMessageComponent
                                    text={this.state.site.errorText}
                                    isError={this.state.site.isError}
                                />
                            </View>

                            {this._renderBorder()}


                        </View>
                    )}


                    {/*通知メール*/}
                    <View style={[{marginBottom: 0, marginLeft: ScreenSizeUtil.isWebSize()? 0 : 16, marginRight: ScreenSizeUtil.isWebSize()? 0 : 16,}]}>
                        <View style={{flexDirection: 'row'}}>
                            <Text style={[this._titleStyle]}>通知メールを希望する/しない</Text>
                            <View style={{marginLeft: 16, marginTop: 6}}>
                                <Switch
                                    value={this.state.notificationMail == 1}
                                    onValueChange={(val) => {
                                        this.setState({notificationMail: val? 1:0});
                                        this.state.user!.notificationMail = val? 1:0;
                                        this.setState({user: this.state.user});
                                    }}
                                    disabled={false}
                                    circleSize={30}
                                    barHeight={20}
                                    circleBorderWidth={0}
                                    backgroundActive={appColors.switchBackgroundTrue}
                                    backgroundInactive={appColors.switchBackgroundFalse}
                                    circleActiveColor={appColors.switchCircleTrue}
                                    circleInActiveColor={appColors.switchCircleFalse}
                                    changeValueImmediately={true}
                                    innerCircleStyle={{
                                        shadowColor: 'black',
                                        shadowOffset: {
                                            width: 0,
                                            height: 2,
                                        },
                                        shadowOpacity: 0.25,
                                        shadowRadius: 4,
                                        elevation: 1,
                                        alignItems: "center",
                                        justifyContent: "center" }} // style for inner animated circle for what you (may) be rendering inside the circle
                                    outerCircleStyle={{}} // style for outer animated circle
                                    renderActiveText={false}
                                    renderInActiveText={false}
                                    switchBorderRadius={30} // Sets the border Radius of the switch slider. If unset, it remains the circleSize.
                                />
                            </View>
                        </View>
                    </View>

                    {this._renderBorder()}




                </View>

                {/*次へ*/}
                <TouchableOpacity
                    style={[
                        {
                            marginTop: 20,
                            marginBottom: 40,
                            width: appS.buttonSize.width,
                            height: appS.buttonSize.height,
                            borderColor: appColors.transparent,
                            backgroundColor:  this._isError() ? appColors.gray : appColors.buttonOrange,
                            alignItems: 'center',
                            justifyContent: 'center',
                            alignSelf: 'center'
                        }
                    ]}
                    disabled={this._isError()}
                    onPress={() => {
                        if (this.state.userAttribute == UserUserAttributeEnum.Cook) {
                            this._goNextPageByCook();
                        } else {
                            this._goNextPageByNormal()
                        }
                    }}
                >
                    <Text style={{
                        color: appColors.white,
                        fontWeight: 'bold',
                        fontSize: appS.button.text
                    }}>保存する</Text>
                </TouchableOpacity>
                {/*image 選択ピッカー*/}
                {this._renderImagePickerModal()}
            </KeyboardAwareScrollView>
        )
    }

    _renderWide = () => {
        return (
            <SafeAreaView
                style={[{height: AppG.window.height, flex: 1, flexDirection: 'column', backgroundColor: appColors.white}]}
                onLayout={(event) => {}}
            >
                <CustomHeaderComponentWide logoOnly={true} navigation={this.props.navigation}/>

                <View style={{flex: 1, flexDirection: 'row'}}>
                    <KeyboardAwareScrollView style={[{flex: 1, flexDirection: 'column'}]}>
                        <View style={[{flex: 1, width: 700, alignSelf: 'center'}]}>
                            {this._renderMain()}
                        </View>
                    </KeyboardAwareScrollView>
                </View>

            </SafeAreaView>
        );
    }

    _renderSmall = () => {
        return (
            // appStyles.statusBarHiddenSafeAreaでステータスバー分上に上げる
            <SafeAreaView
                style={[{flex: 1, backgroundColor: appColors.white}]}
                onLayout={(event) => {}}
            >
                <CustomHeaderComponentSmall
                    navigation={this.props.navigation}
                    title={'プロフィール編集'}
                    textColor={appColors.black}
                    barColor={appColors.white}
                    borderBottomColor={appColors.white}
                    leftButton={'back'}
                    rightButton={null}
                />
                <View style={{flex: 1, backgroundColor: appColors.backBaseColor}}>
                    {this._renderMain()}
                </View>
            </SafeAreaView>
        );
    }


    /** 描画 */
    render() {
        const {navigation} = this.props;
        if (!this.state.isLoaded) {
            return (
                <View style={{flex: 1}}>
                    <IndicatorComponent
                        failTime={60}
                        isLoading={true}
                        reloadFunc={() => {
                            this.componentWillUnmount();
                            this.componentDidMount();
                        }}/>
                </View>
            );
        }

        LinkUtil.addReturnFalseToATag();
        if (ScreenSizeUtil.isWebSize()) {
            // WEBサイズ
            return this._renderWide();
        } else {
            // SPサイズ
            return this._renderSmall();
        }


        return (
            // appStyles.statusBarHiddenSafeAreaでステータスバー分上に上げる
            <SafeAreaView
                style={[{flex: 1, backgroundColor: appColors.white}]}
                onLayout={(event) => {}}
            >
                <CustomHeaderComponentSmall
                    navigation={this.props.navigation}
                    title={'プロフィール編集'}
                    textColor={appColors.black}
                    barColor={appColors.white}
                    borderBottomColor={appColors.gray}
                    leftButton={'back'}
                    rightButton={null}
                />

            </SafeAreaView>
        );
    };


}

