import React from 'react'
import {Dimensions, Image, Platform, SafeAreaView, Text, TouchableOpacity, View} from 'react-native'
import {appColors, appFont, appS} from '../../resources/styles/style'
import {BaseScreen} from "./BaseScreen";
import LoginUtil from "../util/LoginUtil";
import {IndicatorComponent} from "../components/IndicatorComponent";
// @ts-ignore
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scrollview';
import LoginDataDao from "../data/dao/local/LoginDataDao";
import {LoginDataEntityModel} from "../data/entityModels/LoginDataEntityModel";
import {CustomHeaderComponentSmall} from "../components/small/CustomHeaderComponentSmall";
import UrlUtil from "../util/UrlUtil";
// @ts-ignore
import {log} from '../../../environment';
import {ProfileInitScreenModel} from "../data/models/screen/ProfileInitScreenModel";
import {TopicTitleComponent} from "../components/TopicTitleComponent";
import ValidateUtil from "../util/ValidateUtil";
import RNPickerSelect, {PickerStyle} from "react-native-picker-select";
import PickerUtil from "../util/PickerUtil";
import {PullDownIconComponent} from "../components/PullDownIconComponent";
import {ValidationErrorMessageComponent} from "../components/ValidattionErrorMessageComponent";
import I18n from "../../resources/language/i18n";
import AddressApiNetwork from "../data/network/vendor/AddressApiNetwork";
import {AddressModel} from "../data/models/vender/AddressModel";
import {
    CustomerUnitPriceId,
    MasterApiFactory,
    NumOfRestaurantId,
    ProductCategoryLargeMediumInner,
    RestaurantFormat,
    RestaurantLocation,
    User,
    UserApiFactory
} from "../data/network/swagger-gen";
import AppGlobalDataUtil from "../util/AppG";
import AppG from "../util/AppG";
import {AxiosResponse} from "axios";
import ScreenSizeUtil from "../util/ScreenSizeUtil";
import {CustomHeaderComponentWide} from "../components/wide/CustomHeaderComponentWide";
import AnalyticsUtil, {AnalyticsEventName, DOpenEventName} from "../util/firebase/AnalyticsUtil";
import {SpacerComponent} from "../components/SpacerComponent";
import LinkUtil from "../util/LinkUtil";

interface Props {
    navigation: any, // ナビゲーション用
}

interface State {
    resizeWindow: boolean,  // 値に意味はなし。windowサイズの拡大縮小をrenderに送るためのもの
    isLoaded: boolean,

    // コンテンツ
    user: User | null,
    profileScreenModel: ProfileInitScreenModel,
    restaurantLocationMasters: RestaurantFormat[],   // 店舗所在地マスター
    pickerRestaurantLargeLocationId: {label: string, value: any, key: string}[], // pickerに表示する店舗業態（大）
    pickerRestaurantMediumLocationId: {label: string, value: any, key: string}[], // pickerに表示する店舗業態（中）
    pickerState: {label: string, value: any, key: string}[], // pickerに表示する都道府県
    stateInitKey: string | undefined, // 都道府県のコード選択用key
    restaurantFormatMasters: RestaurantFormat[],   // 店舗業態マスター
    pickerRestaurantFormatLarge: {label: string, value: any, key: string}[], // pickerに表示する店舗業態（大）
    pickerRestaurantFormatMedium: {label: string, value: any, key: string}[], // pickerに表示する店舗業態（中）
    customerUnitPriceIdMasters: CustomerUnitPriceId[],  // 客単価マスター
    pickerCustomerUnitPriceId: {label: string, value: any, key: string}[], // pickerに表示する客単価
    numOfRestaurantIdMasters: NumOfRestaurantId[],  // 店舗規模マスター
    pickerNumOfRestaurantId: {label: string, value: any, key: string}[], // pickerに表示する店舗規模

    // input
    // 店舗名
    nameRestaurant: {
        text: string,
        errorText: string,
        isError: boolean,
    },
    // 郵便番号上3桁
    postalCode1: {
        text: string,
        errorText: string,
        isError: boolean,
    },
    // 郵便番号下4桁
    postalCode2: {
        text: string,
        errorText: string,
        isError: boolean,
    },
    restaurantLargeLocationId: number | null, // 店舗所在地(大)
    // restaurantMediumLocationId: number | null, // 店舗所在地(中)
    state: string | null,  // 都道府県
    // 市町村
    city: {
        text: string,
        errorText: string,
        isError: boolean,
    },
    // 番地以降
    streetAddress: {
        text: string,
        errorText: string,
        isError: boolean,
    },
    // 電話番号
    tell: {
        text: string,
        errorText: string,
        isError: boolean,
    },
    restaurantLargeFormatId: number | null, // 店舗業態(大)
    restaurantMediumFormatId: number | null, // 店舗業態(中)
    customerUnitPriceId?: number | null,    // 客単価
    numOfRestaurantId?: number | null,    // 企業規模
}

export class ProfileInit5Screen extends BaseScreen<Props, State> {
    _resizeTimer = setInterval(() => {
        // window 拡大縮小を反映させる
        AppG.window = Dimensions.get('window');
        if (AppG.window.width != AppG.windowBefore.width || AppG.window.height != AppG.windowBefore.height) {
            this.setState({'resizeWindow': true});
            AppG.windowBefore = Dimensions.get('window');
        }
        LoginUtil.setRefreshedScreenFlg(false);
    }, 100);

    _loginData = new LoginDataEntityModel();

    _flameSize = appS.flame.size;    // 左右の margin
    _marginSize = ScreenSizeUtil.isWebSize()? 0 : appS.margin.size;

    // このScreenへ渡すパラム
    _param: ProfileInitScreenModel = new ProfileInitScreenModel();

    // 選択一回はしたよフラグ
    _selectedState = false; // 都道府県
    _selectedRestaurantLargeLocationId = false;   // 店舗所在地（大分類）
    // _selectedRestaurantMediumLocationId = false;   // 店舗所在地（中分類）
    _selectedRestaurantLargeFormatId = false;   // 店舗業態（大分類）
    _selectedRestaurantMediumFormatId = false;   // 店舗業態（大分類）
    _selectedCustomerUnitPriceId = false;  // 客単価
    _selectedNumOfRestaurantId = false;  // 企業規模

    _browserBackListener = (e: any) => {

    };

    constructor(props: any) {
        super(props);
        this.state = {
            resizeWindow: true,  // 値に意味はなし。windowサイズの拡大縮小をrenderに送るためのもの
            isLoaded: false,
            user: null,
            profileScreenModel: new ProfileInitScreenModel(),
            restaurantLocationMasters: [],   // 店舗所在地マスター
            pickerRestaurantLargeLocationId: [], // pickerに表示する店舗業態（大）
            pickerRestaurantMediumLocationId: [], // pickerに表示する店舗業態（中）
            pickerState: [],
            stateInitKey: undefined,
            restaurantFormatMasters: [],   // 店舗業態マスター
            pickerRestaurantFormatLarge: [], // pickerに表示する店舗業態（大）
            pickerRestaurantFormatMedium: [],
            customerUnitPriceIdMasters: [],  // 客単価マスター
            pickerCustomerUnitPriceId: [],
            numOfRestaurantIdMasters: [],  // 店舗規模マスター
            pickerNumOfRestaurantId: [], // pickerに表示する店舗規模
            nameRestaurant: {
                text: '',
                errorText: '',
                isError: false,
            },
            // 郵便番号上3桁
            postalCode1: {
                text: '',
                errorText: '',
                isError: false,
            },
            // 郵便番号下4桁
            postalCode2: {
                text: '',
                errorText: '',
                isError: false,
            },
            restaurantLargeLocationId: null, // 店舗所在地(大)
            // restaurantMediumLocationId: null, // 店舗所在地(中)
            state: '',  // 都道府県
            // 市町村
            city: {
                text: '',
                errorText: '',
                isError: false,
            },
            // 番地以降
            streetAddress: {
                text: '',
                errorText: '',
                isError: false,
            },
            // 電話番号
            tell: {
                text: '',
                errorText: '',
                isError: false,
            },
            restaurantLargeFormatId: null, // 店舗業態(大)
            restaurantMediumFormatId: null, // 店舗業態(中)
            customerUnitPriceId: null,    // 客単価
            numOfRestaurantId: null,    // 企業規模
        };
    }

    /**
     * 画面開いたときのアナリティクス送信
     */
    _sendPvAnalytics = () => {
        AnalyticsUtil.sendAnalytics(
            DOpenEventName.openPage,
            AnalyticsEventName.openPage,  // アナリティクスのイベント名
            'ProfileInit5Screen',
            null,
            null,
            null,
            null,
        );
    }

    componentDidMount() {
        if (Platform.OS == 'web') {
            window.addEventListener('popstate', this._browserBackListener);
        }

        LoginUtil.interruptOpenScreen(this.props.navigation,
            () => {
                LoginDataDao.get().then(loginData => {
                    // Web 戻るでも呼ばれる
                    if (Platform.OS == 'web') {
                        // Analytics
                        this._sendPvAnalytics();
                    }

                    // URL Scheme で外部リンクから起動したときの処理と、画面遷移でこの画面を開いたときの処理
                    LoginUtil.setDefaultListener(this.props.navigation, () => {
                        // Webはここにこない
                        if (Platform.OS != 'web') {
                            // Analytics
                            this._sendPvAnalytics();
                        }
                    });
                    if (loginData != null) {
                        this._loginData = loginData;
                        this._loadMasters().then(() => {
                            this._loadContents().then(() => {});
                        });
                    }
                });
            });

            this.setState({isLoaded: true});
    }

    componentWillUnmount () {
        clearTimeout(this._resizeTimer);
        if (Platform.OS === 'web') {
            window.removeEventListener('popstate', this._browserBackListener);
        }
        LoginUtil.removeDefaultListener(this.props.navigation);
    };

    componentDidUpdate () {
        // レンダー完了時に呼ばれる
    }

    /**
      * パラムの取得
      * @private
      */
    _getParams = () => {
        let profileScreenModel: ProfileInitScreenModel = UrlUtil.getParamModel(new ProfileInitScreenModel(), this.props.navigation);
        // 店舗業態（大分類）
        this.state.profileScreenModel.restaurantLargeFormatId = null;
        // 店舗業態（中分類）
        this.state.profileScreenModel.restaurantMediumFormatId = null;
        // 客単価
        this.state.profileScreenModel.customerUnitPriceId = null;
        return profileScreenModel;
    };

    /**
     * マスターのロード
     * @private
     */
    _loadMasters = async () => {
        this.setState({pickerState: PickerUtil.getPrefCodeMaster()});

        // 店舗所在地
        const getAllRestaurantLocationMaster = MasterApiFactory(AppGlobalDataUtil.getConfiguration(), AppGlobalDataUtil.getBasePath())
            .getAllRestaurantLocationMaster().then((restaurantLocationRes: AxiosResponse<RestaurantLocation[]>) => {
                if (restaurantLocationRes != null && restaurantLocationRes.data != null) {
                    this.setState({restaurantLocationMasters: restaurantLocationRes.data});
                    let pickerItems: {label: string, value: any, key: string}[] = [];
                    restaurantLocationRes.data.forEach((restaurantLocation: RestaurantLocation) => {
                        pickerItems.push({label: restaurantLocation.large.name!, value: restaurantLocation.large.id, key: `${restaurantLocation.large.id}`});
                    });
                    this.setState({pickerRestaurantLargeLocationId: pickerItems});
                }
            });

        // 店舗業態
        const getAllRestaurantFormatMaster = MasterApiFactory(AppGlobalDataUtil.getConfiguration(), AppGlobalDataUtil.getBasePath())
            .getAllRestaurantFormatMaster().then((restaurantFormatRes: AxiosResponse<RestaurantFormat[]>) => {
                if (restaurantFormatRes != null && restaurantFormatRes.data != null) {
                    this.setState({restaurantFormatMasters: restaurantFormatRes.data});
                    let pickerItems: {label: string, value: any, key: string}[] = [];
                    restaurantFormatRes.data.forEach((restaurantFormat: RestaurantFormat) => {
                        pickerItems.push({label: restaurantFormat.large.name!, value: restaurantFormat.large.id, key: `${restaurantFormat.large.id}`});
                    });
                    this.setState({pickerRestaurantFormatLarge: pickerItems});
                }
            });

        // 客単価
        const getAllCustomerUnitPriceIdsMaster = MasterApiFactory(AppGlobalDataUtil.getConfiguration(), AppGlobalDataUtil.getBasePath())
            .getAllCustomerUnitPriceIdsMaster().then((customerUnitPriceIdRes: AxiosResponse<CustomerUnitPriceId[]>) => {
                if (customerUnitPriceIdRes != null && customerUnitPriceIdRes.data != null) {
                    this.setState({customerUnitPriceIdMasters: customerUnitPriceIdRes.data});
                    let pickerItems: {label: string, value: any, key: string}[] = [];
                    customerUnitPriceIdRes.data.forEach((customerUnitPriceId: CustomerUnitPriceId) => {
                        pickerItems.push({label: customerUnitPriceId.name, value: customerUnitPriceId.id, key: customerUnitPriceId.id!.toString()});
                    });
                    this.setState({pickerCustomerUnitPriceId: pickerItems});
                }
            });

        // 店舗規模
        const getAllNumOfRestaurantIdMaster = MasterApiFactory(AppGlobalDataUtil.getConfiguration(), AppGlobalDataUtil.getBasePath())
            .getAllNumOfRestaurantIdMaster().then((numOfRestaurantIdRes: AxiosResponse<NumOfRestaurantId[]>) => {
                if (numOfRestaurantIdRes != null && numOfRestaurantIdRes.data != null) {
                    this.setState({numOfRestaurantIdMasters: numOfRestaurantIdRes.data});
                    let pickerItems: {label: string, value: any, key: string}[] = [];
                    numOfRestaurantIdRes.data.forEach((numOfRestaurantId: NumOfRestaurantId) => {
                        pickerItems.push({label: numOfRestaurantId.name, value: numOfRestaurantId.id, key: numOfRestaurantId.id!.toString()});
                    });
                    this.setState({pickerNumOfRestaurantId: pickerItems});
                }
            });

        await Promise.all([getAllRestaurantLocationMaster, getAllRestaurantFormatMaster, getAllCustomerUnitPriceIdsMaster, getAllNumOfRestaurantIdMaster]);

    };

    /**
     * コンテンツのロード
     * @private
     */
    _loadContents = async () => {
        log.debug(this._getParams());

        this.setState({profileScreenModel: this._getParams()});

        // Userの取得
        const getUser = await UserApiFactory(AppGlobalDataUtil.getConfiguration(), AppGlobalDataUtil.getBasePath())
            .getUser()
            .then((userRes: AxiosResponse<User>) => {
                if (userRes.data != null && userRes.data.id != null && userRes.data.userHash != null) {
                    let _user: User = userRes.data;
                    if (ValidateUtil.isEmptyExact(_user.mail) && !ValidateUtil.isEmptyExact(this._loginData.mail)) {
                        _user.mail = this._loginData.mail!;
                    }
                    this.setState({user: _user});
                    this.setState({isLoaded: true});
                }
            });

        // 実行
        await Promise.all([getUser]);

    }

    /**
     *
     * @param isEmpty 入力がない時
     */
    _getPickerStyle = (isEmpty: boolean) : PickerStyle => {
        return {
            inputIOS: {
                fontSize: 16,
                paddingVertical: 12,
                paddingHorizontal: 10,
                borderWidth: 1,
                borderColor: appColors.gray,
                // borderRadius: 4,
                color: isEmpty? appColors.gray : appColors.black,
                paddingRight: 30, // to ensure the text is never behind the icon
            },
            inputAndroid: {
                fontSize: 16,
                paddingHorizontal: 10,
                paddingVertical: 8,
                borderWidth: 1,
                borderColor: appColors.gray,
                // borderRadius: 8,
                color: isEmpty? appColors.gray : appColors.black,
                paddingRight: 30, // to ensure the text is never behind the icon
                backgroundColor:appColors.selectBoxGray,
            },
            inputWeb: {
                fontSize: 16,
                paddingVertical: 12,
                paddingHorizontal: 10,
                borderWidth: 1,
                borderColor: appColors.gray,
                // borderRadius: 4,
                color: isEmpty? appColors.gray : appColors.black,
                paddingRight: 30, // to ensure the text is never behind the icon
            },
        };
    }

    /**
     * エラーなら true
     */
    _isError = () => {
        let _error = false;
        // 飲食関係者ですか？
        if (ValidateUtil.isErrorUserAttribute(this.state.profileScreenModel.userAttribute)) {
            // log.debug('飲食関係者 Error');
            _error = true;
        }
        // 氏名
        if (ValidateUtil.isErrorUserName(this.state.profileScreenModel.name).isError) {
            // log.debug('氏名 Error');
            _error = true;
        }
        // 氏名（ふりがな）
        if (ValidateUtil.isErrorUserNameKana(this.state.profileScreenModel.nameKana).isError) {
            // log.debug('氏名（ふりがな） Error');
            _error = true;
        }

        // 出店エリア（店舗所在地（大分類））
        if (ValidateUtil.isEmptySelectBox(this.state.profileScreenModel.restaurantLargeLocationId)) {
            // log.debug('店舗所在地（大） Error');
            _error = true;
        }

        // 店舗業態（大分類）
        if (ValidateUtil.isEmptySelectBox(this.state.profileScreenModel.restaurantLargeFormatId)) {
            // log.debug('店舗業態（大） Error');
            _error = true;
        }

        // 客単価
        if (ValidateUtil.isEmptySelectBox(this.state.profileScreenModel.customerUnitPriceId)) {
            // log.debug('客単価 Error');
            _error = true;
        }
        // 企業規模
        if (ValidateUtil.isEmptySelectBox(this.state.profileScreenModel.numOfRestaurantId)) {
            // log.debug('企業規模 Error');
            _error = true;
        }

        // User
        if (this.state.user == null) {
            // log.debug('User Error');
            _error = true;
        }

        // if (_error) {
        //     log.debug('isError Error');
        // }

        return _error;
    };

    /**
     * 次のページへ移動（料理人）
     */
    _goNextPageByCook = async () => {
        if (!this._isError()) {
            this.setState({profileScreenModel: this.state.profileScreenModel});

            setTimeout(() => {
                log.debug(this.state.profileScreenModel)
                this.props.navigation.navigate('ProfileInit6Screen', this.state.profileScreenModel);
            }, 300);

            // const addOrUpdateUser = UserApiFactory(AppGlobalDataUtil.getConfiguration(), AppGlobalDataUtil.getBasePath())
            //     .addOrUpdateUser(
            //         this.state.user!.id,    // id: number,
            //         this.state.user!.mail,  // mail: string,
            //         this.state.profileScreenModel.userAttribute!,    // userAttribute: string,
            //         this.state.profileScreenModel.name!, // name: string,
            //         this.state.profileScreenModel.nameKana!, // nameKana: string,
            //         1,   // notificationMail: number,
            //         this.state.user!.userPlan,  //userPlan: string,
            //         undefined,
            //         undefined,   // gender: string,
            //         undefined, // birthday: string,
            //         undefined,   //nameRestaurant?: string,
            //         undefined,   // postalCode?: string,
            //         parseInt(this.state.profileScreenModel!.restaurantLargeLocationId!), // restaurantLargeLocationId?: number,
            //         undefined,    // restaurantMediumLocationId?: number,
            //         undefined,  // restaurantState?: string,
            //         undefined,   // restaurantCity?: string,
            //         undefined,  // restaurantStreetAddress?: string,
            //         undefined, // tell?: string,
            //         parseInt(this.state.profileScreenModel.restaurantLargeFormatId!),  // restaurantLargeFormatId?: number,
            //         parseInt(this.state.profileScreenModel.restaurantMediumFormatId!),     /// restaurantMediumFormatId?: number,
            //         parseInt(this.state.profileScreenModel.numOfRestaurantId!), // numOfRestaurantId?: number,
            //         undefined, // majorRestaurantAreaId?: number,
            //         0,  // lunchService?: number,
            //         parseInt(this.state.profileScreenModel.customerUnitPriceId!),  // customerUnitPriceId?: number,
            //         undefined, // numberOfSeats?: number,
            //         undefined,  // site?: string,
            //         undefined,// userFreeCoin?: number,
            //         undefined,// image?: string
            //     ).then(() => {
            //         // Analytics
            //         AnalyticsUtil.sendAnalytics(
            //             AnalyticsEventName.signUp,  // アナリティクスのイベント名
            //             'ProfileInit5Screen',
            //             null,
            //             null,
            //             null,
            //             null,
            //         );
            //
            //         // 保存したUserにAppGのUserを更新
            //         UserApiFactory(AppGlobalDataUtil.getConfiguration(), AppGlobalDataUtil.getBasePath())
            //             .getUser()
            //             .then((userRes: AxiosResponse<User>) => {
            //                 if (userRes.data != null && userRes.data.id != null && userRes.data.userHash != null) {
            //                     let _user: User = userRes.data;
            //                     if (ValidateUtil.isEmptyExact(_user.mail) && !ValidateUtil.isEmptyExact(this._loginData.mail)) {
            //                         _user.mail = this._loginData.mail!;
            //                     }
            //                     AppG.user = _user;
            //                     AppG.setAnalyticJpData();
            //
            //                     AlertUtil.alert('登録完了', 'ユーザー情報を登録しました');
            //                     this.props.navigation.navigate('HomeScreen');
            //                 } else {
            //                     // 通信エラー
            //                     AlertUtil.alert(`${I18n.t('errorNetworkTitle')}`, `${I18n.t('errorNetwork')}`);
            //                     log.debug(`---------------Network Error (User.add.update.appG)--------------------`);
            //                 }
            //             });
            //
            //     })
            //     .catch((e) => {
            //         // 通信エラー
            //         AlertUtil.alert(`${I18n.t('errorNetworkTitle')}`, `${I18n.t('errorNetwork')}`);
            //         log.debug(`---------------Network Error (User.add.init)--------------------`);
            //         log.debug(e);
            //     });
            //
            // // 実行
            // await Promise.all([addOrUpdateUser]);
        }
    };

    /**
     * 次のページへ移動（一般人）
     */
    _goNextPageByNormal = async () => {

    };

    /**
     * 送信選択
     */
    _goNextPage = () => {
        if (this.state.profileScreenModel.userAttribute === 'cook') {
            this._goNextPageByCook();
        } else {
            this._goNextPageByNormal();
        }
    };

    /**
     * 郵便番号から住所取得
     && ValidateUtil.isEmptyExact(this.state.stateInitKey)&& (
     */
    _getAddressByPostalCode = () => {
        if (!ValidateUtil.isEmptyExact(this.state.profileScreenModel.postalCode)
            && !ValidateUtil.isErrorPostalCode(this.state.profileScreenModel.postalCode!, 'both').isError) {
            AddressApiNetwork.getAddressByZipcode(this.state.profileScreenModel.postalCode!).then((addressModels: AddressModel[] | null) => {
                if (!ValidateUtil.isEmptyArray(addressModels)) {
                    this.setState({pickerState: []});
                    let stateCode = addressModels![0].prefcode!;
                    this.setState({stateInitKey: stateCode});

                    this.setState({state: addressModels![0].address1});
                    this.setState({pickerState: PickerUtil.getPrefCodeMaster()});
                    this.state.city.text = addressModels![0].address2!;
                    this.setState({city: this.state.city});

                    this.state.profileScreenModel.restaurantState = addressModels![0].address1!;
                    this.state.profileScreenModel.restaurantCity = addressModels![0].address2!;

                    this.setState({profileScreenModel: this.state.profileScreenModel});
                }
            })
        }
    };

    /**
     * 店舗所在地（中）のピッカーを作成
     * @param value
     */
    _createPickerRestaurantLocationMedium = (value: number) => {
        let rf: RestaurantLocation | undefined = this.state.restaurantLocationMasters.find((v: RestaurantLocation) => v.large.id == value);
        if (rf != null && rf.large != null && !ValidateUtil.isEmptyArray(rf.large.medium)) {
            let pickerItems: {label: string, value: any, key: string}[] = [];
            rf.large.medium!.forEach((restaurantLocation) => {
                pickerItems.push({label: restaurantLocation.name!, value: restaurantLocation.id, key: `${restaurantLocation.id}`});
            });
            this.setState({pickerRestaurantMediumLocationId: pickerItems});
        }
    }

    /**
     * 店舗業態（中）のピッカーを作成
     * @param value
     */
    _createPickerRestaurantFormatMedium = (value: number) => {
        let rf: RestaurantFormat | undefined = this.state.restaurantFormatMasters.find((v: RestaurantFormat) => v.large.id == value);
        if (rf != null && rf.large != null && !ValidateUtil.isEmptyArray(rf.large.medium)) {
            let pickerItems: {label: string, value: any, key: string}[] = [];
            rf.large.medium!.forEach((restaurantFormat: ProductCategoryLargeMediumInner) => {
                pickerItems.push({label: restaurantFormat.name!, value: restaurantFormat.id, key: `${restaurantFormat.id}`});
            });
            this.setState({pickerRestaurantFormatMedium: pickerItems});
        }
    }

    _renderMain = () => {
        return (
            <KeyboardAwareScrollView style={[{flex: 1, flexDirection: 'column'}]}>
                <View style={{alignItems: 'center', justifyContent: 'center', marginBottom: 16}}>
                    <SpacerComponent height={appS.header.bottomMargin} />
                    {ScreenSizeUtil.isWebSize() && (
                        <Text style={{
                            fontFamily: appFont.family.bold,
                            flex: 1,
                            margin: 32,
                            color: appColors.black,
                            fontSize: appFont.size.large,
                            textAlign: 'center',
                            fontWeight: "bold"
                        }}>{'プロフィール登録'}</Text>
                    )}
                    <Image source={require('../../resources/images/profile_init_dots_5.png')}
                           style={[{
                               marginTop: 5,
                               width: 104,
                               height: 8,
                           }]}
                           resizeMode='contain'/>
                </View>

                {/*<TopicTitleComponent title={'店舗について教えてください'} />*/}
                <View style={{flexDirection: "row", marginLeft: ScreenSizeUtil.isWebSize() ? 0: 16, marginTop: appS.line.titleSpace, marginBottom: appS.line.titleSpace}}>
                    <Text style={{fontFamily: appFont.family.bold, fontSize: appFont.size.large, color: appColors.black, lineHeight: 26, fontWeight: "bold"}}>店舗について教えてください</Text>
                </View>

                <View style={[{flex: 1, flexDirection: 'column', marginTop: this._marginSize}]}>
                    {/*出店エリア（店舗所在地（大分類））*/}
                    <TopicTitleComponent title={'出店エリア'} require={true} />
                    <View style={[{marginBottom: this._marginSize}, appS.marginCenter]}>
                        <View style={{width: 170}}>
                            {!ValidateUtil.isEmptyArray(this.state.pickerRestaurantLargeLocationId) && (
                                <RNPickerSelect
                                    style={this._getPickerStyle(ValidateUtil.isEmptySelectBox(this.state.profileScreenModel!.restaurantLargeLocationId))}
                                    // placeholder={{}}
                                    onValueChange={(value) => {
                                        this._selectedRestaurantLargeLocationId = true;
                                        this.setState({restaurantLargeLocationId: value});
                                        this.state.profileScreenModel!.restaurantLargeLocationId = `${value}`;
                                        this.setState({profileScreenModel: this.state.profileScreenModel});
                                        this._createPickerRestaurantLocationMedium(value);
                                    }}
                                    items={this.state.pickerRestaurantLargeLocationId}
                                    Icon={() => (<PullDownIconComponent/>)}
                                />
                            )}
                        </View>
                        <ValidationErrorMessageComponent
                            text={`${I18n.t('pleasEnterAnItem', {item: `店舗所在地（大分類）`})}`}
                            isError={this._selectedRestaurantLargeLocationId && ValidateUtil.isEmptySelectBox(this.state.profileScreenModel!.restaurantLargeLocationId)}
                        />
                    </View>

                    {/*店舗業態（大分類）*/}
                    <TopicTitleComponent title={'店舗業態（大分類）'} require={true} />
                    <View style={[{marginBottom: this._marginSize}, appS.marginCenter]}>
                        <View style={{width: 350}}>
                            {!ValidateUtil.isEmptyArray(this.state.pickerRestaurantFormatLarge) && (
                                <RNPickerSelect
                                    style={this._getPickerStyle(ValidateUtil.isEmptySelectBox(this.state.profileScreenModel!.restaurantLargeFormatId))}
                                    // placeholder={{}}
                                    onValueChange={(value) => {
                                        this._selectedRestaurantLargeFormatId = true;
                                        this.setState({restaurantLargeFormatId: value});
                                        this.state.profileScreenModel.restaurantLargeFormatId = `${value}`;
                                        this.setState({profileScreenModel: this.state.profileScreenModel});
                                        this._createPickerRestaurantFormatMedium(value);
                                    }}
                                    items={this.state.pickerRestaurantFormatLarge}
                                    Icon={() => (<PullDownIconComponent/>)}
                                />
                            )}
                        </View>
                        <ValidationErrorMessageComponent
                            text={`${I18n.t('pleasEnterAnItem', {item: `店舗業態（大分類）`})}`}
                            isError={this._selectedRestaurantLargeFormatId && ValidateUtil.isEmptySelectBox(this.state.profileScreenModel.restaurantLargeFormatId)}
                        />
                    </View>

                    {/*店舗業態（中分類）*/}
                    {!ValidateUtil.isEmptyArray(this.state.pickerRestaurantFormatMedium) && (
                        <View>
                            <TopicTitleComponent title={'店舗業態（中分類）'} require={true} />
                            <View style={[{marginBottom: this._marginSize}, appS.marginCenter]}>
                                <View style={{width: 350}}>
                                    {!ValidateUtil.isEmptyArray(this.state.pickerRestaurantFormatMedium) && (
                                        <RNPickerSelect
                                            style={this._getPickerStyle(ValidateUtil.isEmptySelectBox(this.state.profileScreenModel!.restaurantMediumFormatId))}
                                            // placeholder={{}}
                                            onValueChange={(value) => {
                                                this._selectedRestaurantMediumFormatId = true;
                                                this.setState({restaurantMediumFormatId: value});
                                                this.state.profileScreenModel.restaurantMediumFormatId = `${value}`;
                                                this.setState({profileScreenModel: this.state.profileScreenModel});
                                            }}
                                            items={this.state.pickerRestaurantFormatMedium}
                                            Icon={() => (<PullDownIconComponent/>)}
                                        />
                                    )}
                                </View>
                                <ValidationErrorMessageComponent
                                    text={`${I18n.t('pleasEnterAnItem', {item: `店舗業態（中分類）`})}`}
                                    isError={this._selectedRestaurantMediumFormatId && ValidateUtil.isEmptySelectBox(this.state.profileScreenModel.restaurantMediumFormatId)}
                                />
                            </View>
                        </View>
                    )}

                    {/*客単価*/}
                    <View>
                        <TopicTitleComponent title={'客単価（ディナー）'} require={true} />
                        <View style={[{marginBottom: this._marginSize}, appS.marginCenter]}>
                            <View style={{width: 170}}>
                                {!ValidateUtil.isEmptyArray(this.state.pickerCustomerUnitPriceId) && (
                                    <RNPickerSelect
                                        style={this._getPickerStyle(ValidateUtil.isEmptySelectBox(this.state.profileScreenModel!.customerUnitPriceId))}
                                        // placeholder={{}}
                                        onValueChange={(value) => {
                                            this._selectedCustomerUnitPriceId = true;
                                            this.setState({customerUnitPriceId: value});
                                            this.state.profileScreenModel.customerUnitPriceId = `${value}`;
                                            this.setState({profileScreenModel: this.state.profileScreenModel});
                                        }}
                                        items={this.state.pickerCustomerUnitPriceId}
                                        Icon={() => (<PullDownIconComponent/>)}
                                    />
                                )}
                            </View>
                            <ValidationErrorMessageComponent
                                text={`${I18n.t('pleasEnterAnItem', {item: `客単価`})}`}
                                isError={this._selectedCustomerUnitPriceId && ValidateUtil.isEmptySelectBox(this.state.profileScreenModel.customerUnitPriceId)}
                            />
                        </View>
                    </View>

                    {/*企業規模（店舗数）*/}
                    <View>
                        <TopicTitleComponent title={'企業規模（店舗数）'} require={true} />
                        <View style={[{marginBottom: this._marginSize}, appS.marginCenter]}>
                            <View style={{width: 170}}>
                                {!ValidateUtil.isEmptyArray(this.state.pickerNumOfRestaurantId) && (
                                    <RNPickerSelect
                                        style={this._getPickerStyle(ValidateUtil.isEmptySelectBox(this.state.profileScreenModel!.numOfRestaurantId))}
                                        // placeholder={{}}
                                        onValueChange={(value) => {
                                            this._selectedCustomerUnitPriceId = true;
                                            this.setState({numOfRestaurantId: value});
                                            this.state.profileScreenModel.numOfRestaurantId = `${value}`;
                                            this.setState({profileScreenModel: this.state.profileScreenModel});
                                        }}
                                        items={this.state.pickerNumOfRestaurantId}
                                        Icon={() => (<PullDownIconComponent/>)}
                                    />
                                )}
                            </View>
                            <ValidationErrorMessageComponent
                                text={`${I18n.t('pleasEnterAnItem', {item: `企業規模（店舗数）`})}`}
                                isError={this._selectedNumOfRestaurantId && ValidateUtil.isEmptySelectBox(this.state.profileScreenModel.numOfRestaurantId)}
                            />
                        </View>
                    </View>


                </View>

                {/*次へ*/}
                <TouchableOpacity
                    style={[
                        appS.marginCenter,
                        {
                            width: appS.buttonSize.width,
                            height: appS.buttonSize.height,
                            marginTop: 20,
                            marginBottom: 40,
                            borderWidth: 1,
                            borderColor: appColors.transparent,
                            backgroundColor:  this._isError() ? appColors.gray : appColors.buttonOrange,
                            alignItems: 'center',
                            justifyContent: 'center',
                            alignSelf: 'center',
                        }
                    ]}
                    disabled={this._isError()}
                    onPress={() => {
                        this._goNextPageByCook();
                    }}
                >
                    <Text style={{
                        fontFamily: appFont.family.bold,
                        color: appColors.white,
                        fontSize: appFont.size.buttonText,
                        fontWeight: "bold"
                    }}>次へ</Text>
                </TouchableOpacity>
            </KeyboardAwareScrollView>
        )
    }

    _renderWide = () => {
        return (
            <SafeAreaView
                style={[{height: AppG.window.height, flex: 1, flexDirection: 'column', backgroundColor: appColors.white}]}
                onLayout={(event) => {}}
            >
                <CustomHeaderComponentWide logoOnly={true} navigation={this.props.navigation}/>

                <View style={{flex: 1, flexDirection: 'row'}}>
                    <KeyboardAwareScrollView style={[{flex: 1, flexDirection: 'column'}]}>
                        <View style={[{flex: 1, width: 700, alignSelf: 'center'}]}>
                            {this._renderMain()}
                        </View>
                    </KeyboardAwareScrollView>
                </View>

            </SafeAreaView>
        );
    }

    _renderSmall = () => {
        return (
            // appStyles.statusBarHiddenSafeAreaでステータスバー分上に上げる
            <SafeAreaView
                style={[{flex: 1, backgroundColor: appColors.white}]}
                onLayout={(event) => {}}
            >
                <CustomHeaderComponentSmall
                    navigation={this.props.navigation}
                    title={'プロフィール登録'}
                    textColor={appColors.black}
                    barColor={appColors.white}
                    borderBottomColor={appColors.white}
                    leftButton={'none'}
                    rightButton={'none'}
                />
                <View style={{flex: 1, backgroundColor: appColors.backBaseColor}}>
                    {this._renderMain()}
                </View>
            </SafeAreaView>
        );
    }


    /** 描画 */
    render() {
        const {navigation} = this.props;
        if (!this.state.isLoaded) {
            return (
                <View style={{flex: 1}}>
                    <IndicatorComponent
                        failTime={60}
                        isLoading={true}
                        reloadFunc={() => {
                            this.componentWillUnmount();
                            this.componentDidMount();
                        }}/>
                </View>
            );
        }

        LinkUtil.addReturnFalseToATag();
        if (ScreenSizeUtil.isWebSize()) {
            // WEBサイズ
            return this._renderWide();
        } else {
            // SPサイズ
            return this._renderSmall();
        }


        return (
            // appStyles.statusBarHiddenSafeAreaでステータスバー分上に上げる
            <SafeAreaView
                style={[{flex: 1, backgroundColor: appColors.white}]}
                onLayout={(event) => {}}
            >
                <CustomHeaderComponentSmall
                    navigation={this.props.navigation}
                    title={'プロフィール登録'}
                    textColor={appColors.black}
                    barColor={appColors.white}
                    borderBottomColor={appColors.gray}
                    leftButton={'none'}
                    rightButton={'none'}
                />

            </SafeAreaView>
        );
    };


}

