import {BaseDao} from "./BaseDao";
// @ts-ignore
import {log} from '../../../../../environment';
import {AnalyticsDataEntityModel} from "../../entityModels/AnalyticsDataEntityModel";

class AnalyticsDataDao extends BaseDao<AnalyticsDataEntityModel> {
    _getModel(): AnalyticsDataEntityModel {
        return new AnalyticsDataEntityModel();
    }
}

// new 付なのでシングルトン
export default new AnalyticsDataDao();
