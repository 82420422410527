import React, {Component} from 'react';
import {Image, Platform, Text, TouchableOpacity, View} from 'react-native';
import {getEnvVars} from "../../../../environment";
import LinkUtil from "../../util/LinkUtil";
import {appColors, appFont, appS} from "../../../resources/styles/style";

interface Props {
    navigation: any,
}

interface State { }

export class WideScreenSNSComponent extends Component<Props, State> {

    _snsIconSize = 60;

    // addAd = () => {
    //     const amp = document.getElementById('amp-ad');
    //     if (amp != null) {
    //         // 既にあるなら離脱
    //         return;
    //     }
    //
    //     const adWide = document.getElementById('ad_wide');
    //     if (adWide != null) {
    //         const ampAd = document.createElement("amp-ad");
    //         ampAd.setAttribute("id","amp-ad");
    //         ampAd.setAttribute("width","300");
    //         ampAd.setAttribute("height","250");
    //         ampAd.setAttribute("type","nend");
    //         ampAd.setAttribute("data-nend_params",'{"media":68200,"site":345528,"spot":1025743,"type":1,"oriented":1}');
    //         ampAd.style.width = '300px';
    //         ampAd.style.height = '250px';
    //
    //         adWide.appendChild(ampAd);
    //     }
    // }

    render() {

        if (Platform.OS != 'web') {
            return null;
        }

        // this.addAd();

        // @ts-ignore
        const {REACT_APP_FACEBOOK_URL, REACT_APP_INSTAGRAM_URL, REACT_APP_NOTE_URL} = getEnvVars();

        const _width = appS.webSideContentsSize.width;

        return (
            <View
                style={{
                    width: _width,
                }}
                //   nativeID={'ad_wide'}
            >
                <View>
                    <Text style={{fontFamily: appFont.family.bold, fontSize: 18, color: appColors.black, marginTop: 16, fontWeight: "bold"}}>ReciBACE　公式SNS</Text>
                </View>
                <View style={{margin: appS.margins.side}}>
                    <View style={{flexDirection: "row", marginTop: 16}}>
                        <TouchableOpacity
                            style={{flexDirection: "row"}}
                            onPress={() => {LinkUtil.openUrlInWebView(REACT_APP_INSTAGRAM_URL, this.props.navigation)}}>
                            <Image source={require('../../../resources/images/glyph-logo_May2016.png')}
                                   style={[{
                                       width: this._snsIconSize,
                                       height: this._snsIconSize,
                                   }]}
                                   resizeMode={'contain'}
                            />
                            <View style={{
                                height: this._snsIconSize,
                            }}>
                                <Text style={{fontFamily: appFont.family.default, fontSize: 16, marginLeft: 10, marginTop: 17,}}>reci_base</Text>
                            </View>
                        </TouchableOpacity>
                    </View>
                    <View style={{flexDirection: "row", marginTop: 16}}>
                        <TouchableOpacity
                            style={{flexDirection: "row"}}
                            onPress={() => {LinkUtil.openUrlInWebView(REACT_APP_FACEBOOK_URL, this.props.navigation)}}>
                            <Image source={require('../../../resources/images/f_logo_RGB-Black_512.png')}
                                   style={[{
                                       width: this._snsIconSize,
                                       height: this._snsIconSize,
                                   }]}
                                   resizeMode={'contain'}
                            />
                            <View style={{
                                height: this._snsIconSize,
                            }}>
                                <Text style={{fontFamily: appFont.family.default, fontSize: 16, marginLeft: 10, marginTop: 17,}}>Reci BASE</Text>
                            </View>
                        </TouchableOpacity>
                    </View>
                </View>

            </View>
        )

    }
}
