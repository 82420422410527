import StrUtil from "../../util/StrUtil";
import {Component} from "react";
import ValidateUtil from "../../util/ValidateUtil";
import {Keyword, KeywordApiFactory} from "../../data/network/swagger-gen";
import AppGlobalDataUtil from "../../util/AppG";
import {AxiosResponse} from "axios";
import AnalyticsUtil, {AnalyticsEventName} from "../../util/firebase/AnalyticsUtil";

class CustomHeaderFunc {

    /**
     * キーワードの取得
     * @param component (thisを入力)
     * @param searchValue 検索ワード
     */
    getKeyword = (component: Component, searchValue: string) => {
        // 全角スペースを半角スペースに
        let str = StrUtil.largeSpaceToSmallSpace(searchValue);

        // 最後が半角スペース
        if (str.length == 0 || str[str.length - 1] == ' ') {
            component.setState({keywords: []});
            return;
        }
        // 前後の空白を取り除く
        str = str.trim();

        // 半角スペースで分割
        let strArray = str.split(' ');

        if (strArray.length == 0) {
            return;
        }

        if (ValidateUtil.isEmptyExact(strArray[strArray.length - 1])) {
            component.setState({keywords: []});
            return;
        }

        KeywordApiFactory(AppGlobalDataUtil.getConfiguration(), AppGlobalDataUtil.getBasePath())
            .getFuzzyKeywords(strArray[strArray.length - 1])
            .then((keywordRes: AxiosResponse<Keyword[]>) => {
                if (keywordRes != null && !ValidateUtil.isEmptyArray(keywordRes.data)) {
                    component.setState({keywords: keywordRes.data});
                } else {
                    component.setState({keywords: []});
                }
        });
    };

    /**
     * キーワード選択
     * @param component (thisを入力)
     * @param keyword   キーワード
     * @param searchValue   検索ワード
     * @param onSearchSubmit    検索開始
     */
    selectKeyword = (component: Component, keyword: Keyword, searchValue: string, onSearchSubmit: (text: string) => void) => {
        // 全角スペースを半角スペースに
        let str = StrUtil.largeSpaceToSmallSpace(searchValue);

        // 未入力時
        if (str.length == 0) {
            component.setState({searchValue: `${keyword.value}`})
            component.setState({keywords: []});
            return;
        }

        // 最後が半角スペース
        if (str[str.length - 1] == ' ') {
            component.setState({searchValue: `${searchValue} ${keyword.value}`})
            component.setState({keywords: []});
            return;
        }

        // 前後の空白を取り除く
        str = str.trim();

        // 半角スペースで分割
        let strArray = str.split(' ');

        // 半角スペースのみ
        if (strArray.length == 0) {
            component.setState({searchValue: `${keyword.value}`})
            component.setState({keywords: []});
            return;
        }

        // 最後の単語をキーワードに置き換え
        // 最後のワード以外を結合
        let newSearchValue = '';
        for (let i = 0; i < strArray.length - 1; i++) {
            newSearchValue = `${newSearchValue} ${strArray[i]}`;
        }
        // 最後にキーワードを追加
        newSearchValue = `${newSearchValue} ${keyword.value} `;

        component.setState({searchValue: newSearchValue})
        component.setState({keywords: []});

        onSearchSubmit(newSearchValue);

        return;

    };

}


export default new CustomHeaderFunc();
