import React from 'react'
import {Dimensions, Platform, SafeAreaView, Text, TouchableOpacity, View,} from 'react-native'
import AsyncStorage from '@react-native-async-storage/async-storage';
import {BaseScreen} from "../BaseScreen";
import LoginUtil from "../../util/LoginUtil";
// @ts-ignore
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scrollview';
// @ts-ignore
import {log} from '../../../../environment';
import {RecipeDownloadScreenModel} from "../../data/models/screen/RecipeDownloadScreenModel";
import ScreenSizeUtil from "../../util/ScreenSizeUtil";
import {appColors, appS} from "../../../resources/styles/style";
import AppG from "../../util/AppG";
import {UserUserPlanEnum} from "../../data/network/swagger-gen";
import {CustomHeaderComponentSmall} from "../../components/small/CustomHeaderComponentSmall";
import {SpacerComponent} from "../../components/SpacerComponent";
import LinkUtil from "../../util/LinkUtil";
import ExpoUtil from "../../util/ExpoUtil";
import {TestScreenModel} from "../../data/models/screen/TestScreenModel";
import NotificationUtil from "../../util/NotificationUtil";
import {TutorialDataEntityModel} from "../../data/entityModels/TutorialDataEntityModel";
import TutorialDataDao from "../../data/dao/local/TutorialDataDao";
import {TutorialModalComponent} from "../../components/TutorialModalComponent";
import WhatIsMemberShipModalDataDao from "../../data/dao/local/WhatIsMemberShipModalDataDao";
import WhatIsSampleRequestModalDataDao from "../../data/dao/local/WhatIsSampleRequestModalDataDao";
import ReceivedNewMessageModalDataDao from "../../data/dao/local/ReceivedNewMessageModalDataDao";
import ProductReviewReminderModalDataDao from "../../data/dao/local/ProductReviewReminderModalDataDao";

interface Props {
    navigation: any, // ナビゲーション用
}

interface State {
    resizeWindow: boolean,  // 値に意味はなし。windowサイズの拡大縮小をrenderに送るためのもの
    pushToken: string,
}

export class BuySubscriptionScreenApp extends BaseScreen<Props, State> {

    _resizeTimer = setInterval(() => {
        // window 拡大縮小を反映させる
        AppG.window = Dimensions.get('window');
        this.setState({'resizeWindow': true});
        LoginUtil.setRefreshedScreenFlg(false);
    }, 100);

    _flameSize = appS.flame.size;    // 左右の margin
    _marginSize = ScreenSizeUtil.isWebSize()? 0 : appS.margin.size;

    // このScreenへ渡すパラム
    _param: RecipeDownloadScreenModel = new RecipeDownloadScreenModel();

    _browserBackListener = (e: any) => {

    };

    constructor(props: any) {
        super(props);
        this.state = {
            resizeWindow: true,  // 値に意味はなし。windowサイズの拡大縮小をrenderに送るためのもの
            pushToken: '',
        };
    }

    componentDidMount() {
        log.debug('------------- Buy Subscription Screen App Start! -----------------');

        if (Platform.OS == 'web') {
            window.addEventListener('popstate', this._browserBackListener);
        }

        if (Platform.OS != 'web') {
            NotificationUtil.registerForPushNotificationsAsync().then(token => {
                if (token != null) {
                    this.setState({pushToken: token});
                }
            });
        }

        // URL Scheme で外部リンクから起動したときの処理
        LoginUtil.setDefaultListener(this.props.navigation);
    }

    componentWillUnmount () {
        clearTimeout(this._resizeTimer);
        if (Platform.OS === 'web') {
            window.removeEventListener('popstate', this._browserBackListener);
        }
        LoginUtil.removeDefaultListener(this.props.navigation);
    };

    componentDidUpdate () {
        // レンダー完了時に呼ばれる
    }

    /**
      * パラムの取得
      * @private
      */
    _getParams = () => {
    };

    _loadContents = async () => {

    };

    _renderMain = () => {
        if (AppG.user == null) {
            return (
                <View style={{marginLeft: this._flameSize, marginRight: this._flameSize, marginBottom: appS.line.space}}>
                    <Text style={{color: appColors.black, fontWeight: 'bold', fontSize: appS.button.text}}>{`ログインしてください`}</Text>

                    <SpacerComponent height={32}/>

                    {/*ログイン画面へ*/}
                    <TouchableOpacity
                        style={[
                            {
                                height: appS.button.height,
                                backgroundColor: appColors.black,
                                alignItems: 'center',
                                justifyContent: 'center',
                            }
                        ]}
                        onPress={() => {
                            this.props.navigation.navigate('LoginScreen');
                        }}
                    >
                        <Text style={{color: appColors.white, fontWeight: 'bold', fontSize: appS.button.text}}>ログイン画面へ</Text>
                    </TouchableOpacity>
                </View>
            );
        }
        return (
            <View style={{marginLeft: this._flameSize, marginRight: this._flameSize, marginBottom: appS.line.space}}>
                <Text style={{color: appColors.black, fontWeight: 'bold', fontSize: appS.button.text}}>{`現在は：${AppG.user.userPlan == UserUserPlanEnum.Free? '無料会員' : '有料会員'}です`}</Text>

                {/*通知トークン*/}
                <Text style={{color: appColors.black, fontWeight: 'bold', fontSize: appS.button.text}}>{`通知トークン： ${this.state.pushToken}`}</Text>

                <SpacerComponent height={32}/>

                {/*課金プラン変更*/}
                {/*<TouchableOpacity*/}
                {/*    style={[*/}
                {/*        {*/}
                {/*            height: appStyles.button.height,*/}
                {/*            backgroundColor: appColors.black,*/}
                {/*            alignItems: 'center',*/}
                {/*            justifyContent: 'center',*/}
                {/*        }*/}
                {/*    ]}*/}
                {/*    onPress={() => {*/}
                {/*        if (AppG.user == null) {*/}
                {/*            return;*/}
                {/*        }*/}

                {/*        switch (AppG.user.userPlan) {*/}
                {/*            case UserUserPlanEnum.Free:*/}
                {/*                AppG.user.userPlan = UserUserPlanEnum.Premium;*/}
                {/*                break;*/}
                {/*            case UserUserPlanEnum.Premium:*/}
                {/*                AppG.user.userPlan = UserUserPlanEnum.Free;*/}
                {/*                break;*/}
                {/*        }*/}
                {/*    }}*/}
                {/*>*/}
                {/*    <Text style={{color: appColors.white, fontWeight: 'bold', fontSize: appStyles.button.text}}>契約プランの変更</Text>*/}
                {/*</TouchableOpacity>*/}

                <SpacerComponent height={32}/>

                {/*初回登録の画面へ移動*/}
                <TouchableOpacity
                    style={[
                        {
                            height: appS.button.height,
                            backgroundColor: appColors.black,
                            alignItems: 'center',
                            justifyContent: 'center',
                        }
                    ]}
                    onPress={() => {
                        this.props.navigation.navigate('ProfileInit1Screen');
                    }}
                >
                    <Text style={{color: appColors.white, fontWeight: 'bold', fontSize: appS.button.text}}>初回登録画面をテストする</Text>
                </TouchableOpacity>

                <SpacerComponent height={32}/>

                {/*チュートリアルの画面へ移動*/}
                <TouchableOpacity
                    style={[
                        {
                            height: appS.button.height,
                            backgroundColor: appColors.black,
                            alignItems: 'center',
                            justifyContent: 'center',
                        }
                    ]}
                    onPress={() => {
                        const tutorialData = new TutorialDataEntityModel();
                        tutorialData.hide_flg_tutorial = '0';
                        TutorialDataDao.addOrUpdate(tutorialData);
                        this.props.navigation.navigate('HomeScreen');
                    }}
                >
                    <Text style={{color: appColors.white, fontWeight: 'bold', fontSize: appS.button.text}}>チュートリアルを見る</Text>
                </TouchableOpacity>

                <SpacerComponent height={32}/>

                {/*WebViewテスト画面へ移動*/}
                <TouchableOpacity
                    style={[
                        {
                            height: appS.button.height,
                            backgroundColor: appColors.black,
                            alignItems: 'center',
                            justifyContent: 'center',
                        }
                    ]}
                    onPress={() => {
                        LinkUtil.openUrlInWebView('https://www.google.com', this.props.navigation);
                        // LinkUtil.openUrlInWebView('http://localhost:19006/NewsScreen', this.props.navigation);
                    }}
                >
                    <Text style={{color: appColors.white, fontWeight: 'bold', fontSize: appS.button.text}}>WebViewをテストする</Text>
                </TouchableOpacity>

                <SpacerComponent height={32}/>

                {/*Popupをリセット*/}
                <TouchableOpacity
                    style={[
                        {
                            height: appS.button.height,
                            backgroundColor: appColors.black,
                            alignItems: 'center',
                            justifyContent: 'center',
                        }
                    ]}
                    onPress={() => {
                        TutorialDataDao.delete();
                        WhatIsMemberShipModalDataDao.delete();
                        WhatIsSampleRequestModalDataDao.delete();
                        ReceivedNewMessageModalDataDao.delete();
                        ProductReviewReminderModalDataDao.delete();
                        ExpoUtil.goBack(this.props.navigation);
                    }}
                >
                    <Text style={{color: appColors.white, fontWeight: 'bold', fontSize: appS.button.text}}>Popupをリセット</Text>
                </TouchableOpacity>

                <SpacerComponent height={32}/>

                {/*Testページ*/}
                <TouchableOpacity
                    style={[
                        {
                            height: appS.button.height,
                            backgroundColor: appColors.black,
                            alignItems: 'center',
                            justifyContent: 'center',
                        }
                    ]}
                    onPress={() => {
                        let testScreenModel: TestScreenModel = new TestScreenModel();
                        testScreenModel.test = '184';
                        testScreenModel.ttt = `${Platform.OS}`;
                        testScreenModel.noPath = 'none';
                        this.props.navigation.navigate('TestScreen', testScreenModel);
                    }}
                >
                    <Text style={{color: appColors.white, fontWeight: 'bold', fontSize: appS.button.text}}>Testページへ</Text>
                </TouchableOpacity>
            </View>
        )
    }

    /** 描画 */
    render() {
        LinkUtil.addReturnFalseToATag();

        return (
            <SafeAreaView
                style={[{height: AppG.window.height, flex: 1, flexDirection: 'column', backgroundColor: appColors.white}]}
                onLayout={(event) => {}}
            >
                <CustomHeaderComponentSmall
                    navigation={this.props.navigation}
                    leftButton={'back'}
                    rightButton={null}
                    title={'作成中'}
                    searchBar={false}
                    textColor={appColors.black}
                    barColor={appColors.white}
                    borderBottomColor={appColors.transparent}
                    statusBarType={"dark-content"}
                />

                <View style={{flex: 1, flexDirection: 'row'}}>
                    {this._renderMain()}
                </View>

            </SafeAreaView>
        );
    };


}
