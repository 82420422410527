import I18n from "../../resources/language/i18n";
import AlertUtil from "./AlertUtil";
// @ts-ignore
import isInt from 'validator/lib/isInt';
// @ts-ignore
import isURL from 'validator/lib/isURL';
import moment from "moment";
// @ts-ignore
import {getEnvVars, log} from '../../../environment';
import AppG from "./AppG";

class ValidateUtil {

    /**
     * 半角 => 0.5, 全角 => 1 で文字数取得
     * @param str
     * @private
     */
    _get2byteLength = (str: string): number => {
        let count: number = 0
        for (let i = 0, len = str.length; i < len; i++) {
            let c = str.charCodeAt(i)
            if (!str[i].match(/\r?\n/g)) { // 改行コード判定
                if (c >= 0x0 && c <= 0x7f) { // 全角半角判定
                    count += 0.5
                } else {
                    count += 1
                }
            }
        }
        return count;
    }

    /**
     * 指定より文字数が長かったら(true)を返す
     * @param str
     * @param lessThanOrEqual
     */
    isOverLength = (str: string, lessThanOrEqual: number) => {
        const length = this._get2byteLength(str);
        // 半角1個分補正
        if ((length + 0.5) > lessThanOrEqual) {
            return true;
        }
        return false;
    }

    /**
     * コンマを削除する
     * @param str
     */
    removeComma = (str: string | null | undefined) => {
        if (str == null) {
            return str;
        }
        return str.toString().replace(/,/g, '');
    }

    /**
     * コンマ区切りにして返す
     * 数字じゃ無かったらそのまま返す
     * @param str
     */
    addComma = (str: string) => {
        let noCommaStr = this.removeComma(str);
        if (noCommaStr != null && noCommaStr != '' && isInt(noCommaStr)) {
            return parseInt(noCommaStr).toLocaleString();
        }
        return str;
    }

    /**
     * undefined, null, 空文字 なら true
     * @param value
     */
    isEmpty = (value: string | number | null | undefined) => {
        if (value === undefined || value === null || value === '') {
            return true;
        }
        return false;
    }

    /**
     * undefined, null, 空文字, 全角 or 半角スペースのみ なら true
     * @param value
     */
    isEmptyExact = (value: string | number | null | undefined) => {
        if (this.isEmpty(value)) {
            return true;
        }

        if (typeof value === 'string') {
            // 半角or全角のスペースのみ
            const result = value!.match(/^[\s|　]+$/);
            return result != null;
        }

        return false;
    }

    /**
     * セレクトボックスのデフォルトを使ってるとき未選択かどうかの判別
     * @param value
     * @param noSelectMessage   未選択時のセレクトボックスの値
     */
    isEmptySelectBox = (value: string | number | null | undefined, noSelectMessage: string = 'Select an item...') => {
        if (this.isEmptyExact(value)) {
            return true;
        }

        if (typeof value === 'string') {
            // セレクトボックスのデフォルトの未選択
            return value == noSelectMessage;
        }

        return false;
    }

    /**
     * undefined, null, length == 0 なら true
     * @param value
     */
    isEmptyArray = (value: any[] | undefined | null) => {
        if (value === undefined || value === null || value.length === 0) {
            return true;
        }
        return false;
    }

    /**
     * 性別かどうか
     */
    isGender = (value: string | null) => {
        if (value == null) {
            return false;
        }
        return value == 'female' || value == 'male';
    }

    /**
     * 電話番号かどうか
     * @param value
     */
    isTel = (value: string) => {
        // 国際番号は使えるようにする
        const result = value.match(/^[\+\d][\+-\d]+$/);
        return result != null;
    }

    /**
     * 半角数字かどうか
     * @param value
     */
    isNumber = (value: string) => {
        // 半角英数字かどうか
        const result = value.match(/^[\d]+$/);
        return result != null;
    }

    /**
     * 半角英数字6文字以上100文字以下かどうか
     * @param value
     */
    isPassword = (value: string) => {
        // 半角英数字6文字以上100文字以下かどうか
        const result = value.match(/^[a-z\d]{6,100}$/i);
        return result != null;
    }

    /**
     * 全角カタカナかどうか(スペースOK)
     * @param value
     */
    isKana = (value: string) => {
        // 全角カタカナ
        const result = value.match(/^([ァ-ン\s　]|ー)+$/);
        return result != null;
    }

    /**
     * 全角ひらがなかどうか(スペースOK)
     * @param value
     */
    isHiragana = (value: string) => {
        // 全角ひらがな
        const result = value.match(/^([ぁ-ん\s　]|ー)+$/);
        return result != null;
    }

    /**
     * 全角ひらがな、カタカナかどうか(スペースOK)
     * @param value
     */
    isHiraganaKatakana = (value: string) => {
        // 全角ひらがな、カタカナ
        const result = value.match(/^[ぁ-んァ-ン\s　]+$/);
        return result != null;
    }

    /**
     * 半角アルファベットかどうか(半角スペースOK)
     * @param value
     */
    isAlphabet = (value: string) => {
        // 半角アルファベット
        const result = value.match(/^[a-zA-Z\s]+$/);
        return result != null;
    }

    /**
     * YYYY-MM-DDかどうか
     * @param value
     */
    isYYYYMMDD = (value: string) => {
        const result = value.match(/^\d\d\d\d-\d\d-\d\d$/);
        return result != null;
    }

    /**
     * 日付が正しいか（正しければ true）
     * yyyy-MM-dd
     */
    isRightDate = (year: string, month: string, day: string) => {
        if (year == '' || month == '' || day == '') {
            return false;
        }

        let date = moment(`${year}-${month}-${day}`, 'YYYY-MM-DD');
        return date.isValid();
    }

    /**
     * My Domain かどうか
     * @param value
     */
    isMyDomain = (value: string) => {
        if (this.isEmpty(value)) {
            return false;
        }

        // @ts-ignore
        const {REACT_APP_DOMAIN_URL} = getEnvVars();

        const regexp = new RegExp( '^' + REACT_APP_DOMAIN_URL + '.*');
        const result = value!.match(regexp);
        return result != null;
    }

    /**
     * Wp Domain かどうか
     * @param value
     */
    isWpDomain = (value: string) => {
        if (this.isEmpty(value)) {
            return false;
        }

        // @ts-ignore
        const {REACT_APP_WP_URL} = getEnvVars();

        const regexp = new RegExp( '^' + REACT_APP_WP_URL + '.*');
        const result = value!.match(regexp);
        return result != null;
    }

    isSpecialUrl = (value: string) => {
        if (this.isEmpty(value)) {
            return false;
        }

        const regexp = new RegExp( '.*SpecialDetailScreen.*');
        const result = value!.match(regexp);
        return result != null;
    }

    isRankingUrl = (value: string) => {
        if (this.isEmpty(value)) {
            return false;
        }

        const regexp = new RegExp( '.*HomeScreen_Ranking.*');
        const result = value!.match(regexp);
        return result != null;
    }

    /**
     * メーカー問い合わせアラート表示(No User)
     * @param navigation
     */
    showNoUserAlertAtMakerChat = (navigation: any) => {
        AlertUtil.ok(
            'メーカー問い合わせについて',
            'メーカー問い合わせには無料会員登録が必要になります',
            () => {navigation.navigate('SignUpScreen')},
            '登録に進む'
        );
    }

    /**
     * サンプル依頼可能かチェック(No User)
     */
    showNoUserAlertAtSampleRequest = (navigation: any) => {
        AlertUtil.ok(
            'サンプル依頼について',
            'サンプル依頼には無料会員登録が必要になります',
            () => {navigation.navigate('SignUpScreen')},
            '登録に進む'
        );
    }

    /**
     * サンプル依頼可能かチェック
     */
    isEnableSampleRequest = () => {
        if (
            AppG.user == null
            || this.isEmptyExact(AppG.user.name)
            || this.isEmptyExact(AppG.user.nameKana)
            || this.isEmptyExact(AppG.user.postalCode)
            || this.isEmptyExact(AppG.user.restaurantState)
            || this.isEmptyExact(AppG.user.restaurantCity)
            || this.isEmptyExact(AppG.user.restaurantStreetAddress)
            || this.isEmptyExact(AppG.user.tell)
            || this.isEmptyExact(AppG.user.nameRestaurant)
        ) {
            // 依頼不可
            return false;
        }

        return true;
    }

    /**
     * エラーアラート
     * @param message
     * @param showAlert アラートするかしないか
     */
    showErrorAlert = (message: string, showAlert: boolean = true) => {
        if (showAlert) {
            AlertUtil.alert(`${I18n.t('error')}`, message);
        }
    }

    /**
     * 指定した文字列で始まる文字列ならtrue
     * @param value
     * @param startRegex
     */
    isStartBy = (value: string | null | undefined, startRegex: string) => {
        if (this.isEmpty(value)) {
            return false;
        }

        const regexp = new RegExp( '^[' + startRegex + '].*');
        const result = value!.match(regexp);
        return result != null;
    }


    ///////////////////
    // プロフィール
    ///////////////////



    /**
     * ユーザー属性のエラーチェック
     * @param userAttribute
     */
    isErrorUserAttribute = (userAttribute: string | null) => {
        if (this.isEmptyExact(userAttribute)) {
            return true;
        }
        switch (userAttribute) {
            case 'normal':
                return false;
            case 'cook':
                return false;
            default:
                return true;
        }
    }

    /**
     * ユーザー名のエラーチェック
     * @param userName
     */
    isErrorUserName = (userName: string | null): {
        text: string,
        errorText: string,
        isError: boolean,
    } => {
        if (this.isEmptyExact(userName)) {
            return {
                text: '',
                errorText: `${I18n.t('pleasEnterAnItem', {item: `名前`})}`,
                isError: true,
            }
        }

        return {
            text: userName!,
            errorText: ``,
            isError: false,
        }
    }

    /**
     * ユーザー名（かな）のエラーチェック
     * @param userNameKana
     */
    isErrorUserNameKana = (userNameKana: string | null): {
        text: string,
        errorText: string,
        isError: boolean,
    } => {
        if (this.isEmptyExact(userNameKana)) {
            return {
                text: '',
                errorText: `${I18n.t('pleasEnterAnItem', {item: `名前（かな）`})}`,
                isError: true,
            }
        }
        if (!this.isHiragana(userNameKana!)) {
            return {
                text: userNameKana!,
                errorText: `ひらがなのみで入力してください`,
                isError: true,
            }
        }
        return {
            text: userNameKana!,
            errorText: ``,
            isError: false,
        }
    }

    /**
     * 性別のエラーチェック
     * @param userGender
     */
    isErrorUserGender = (userGender: string | null) => {
        return !this.isGender(userGender)
    }

    /**
     * レストラン名のエラーチェック
     * @param nameRestaurant
     */
    isErrorNameRestaurant = (nameRestaurant: string | null | undefined) : {
        text: string,
        errorText: string,
        isError: boolean,
    } => {
        // 必須
        if (this.isEmptyExact(nameRestaurant)) {
            return {
                text: '',
                errorText: `${I18n.t('pleasEnterAnItem', {item: `店舗名`})}`,
                isError: true,
            }
        }

        return {
            text: nameRestaurant!,
            errorText: ``,
            isError: false,
        }
    }

    /**
     * 郵便番号のエラーチェック
     * @param postalCode
     * @param position  郵便番号の左右
     */
    isErrorPostalCode = (postalCode: string | null | undefined, position: 'left' | 'right' | 'both') : {
        text: string,
        errorText: string,
        isError: boolean,
    } => {
        // 必須
        if (this.isEmptyExact(postalCode)) {
            return {
                text: '',
                errorText: `${I18n.t('pleasEnterAnItem', {item: `郵便番号`})}`,
                isError: true,
            }
        }

        if (!this.isNumber(postalCode!)) {
            return {
                text: postalCode!,
                errorText: `${I18n.t('pleasEnterInt', {item: `郵便番号`})}`,
                isError: true,
            }
        }

        let postCodeLength = 7;
        switch (position) {
            case 'left':
                postCodeLength = 3;
                break;
            case 'right':
                postCodeLength = 4;
                break;
            default:
                postCodeLength = 7;
                if (postalCode!.length != postCodeLength) {
                    return {
                        text: postalCode!,
                        errorText: `${I18n.t('errorPostalCode')}`,
                        isError: true,
                    }
                }
                break;
        }

        if (postalCode!.length > postCodeLength) {
            return {
                text: postalCode!,
                errorText: `${I18n.t('errorPostalCode')}`,
                isError: true,
            }
        }

        return {
            text: postalCode!,
            errorText: ``,
            isError: false,
        }
    }

    /**
     * 市区町村のエラーチェック
     * @param city
     */
    isErrorRestaurantCity = (city: string | null | undefined) : {
        text: string,
        errorText: string,
        isError: boolean,
    } => {
        // if (this.isEmptyExact(city)) {
        //     return {
        //         text: '',
        //         errorText: `${I18n.t('pleasEnterAnItem', {item: `市区町村`})}`,
        //         isError: true,
        //     }
        // }

        return {
            text: city!,
            errorText: ``,
            isError: false,
        }
    }

    /**
     * 番地以降のエラーチェック
     * @param streetAddress
     */
    isErrorRestaurantStreetAddress = (streetAddress: string | null | undefined) : {
        text: string,
        errorText: string,
        isError: boolean,
    } => {
        // 必須ではない
        // if (this.isEmptyExact(streetAddress)) {
        //     return {
        //         text: '',
        //         errorText: `${I18n.t('pleasEnterAnItem', {item: `番地以降`})}`,
        //         isError: true,
        //     }
        // }

        return {
            text: streetAddress!,
            errorText: ``,
            isError: false,
        }
    }

    /**
     * ユーザーの電話番号
     * @param tell
     */
    isErrorTell = (tell: string | null | undefined) : {
        text: string,
        errorText: string,
        isError: boolean,
    } => {
        // 必須
        if (this.isEmptyExact(tell)) {
            return {
                text: '',
                errorText: `${I18n.t('pleasEnterAnItem', {item: `電話番号`})}`,
                isError: true,
            }
        }

        if (!this.isNumber(tell!)) {
            return {
                text: tell!,
                errorText: `${I18n.t('pleasEnterInt', {item: `電話番号`})}`,
                isError: true,
            }
        }

        return {
            text: tell!,
            errorText: ``,
            isError: false,
        }
    }

    /**
     * 席数
     * @param numOfSeats
     */
    isErrorNumOfSeats = (numOfSeats: string | null | undefined): {
        text: string,
        errorText: string,
        isError: boolean,
    } => {
        if (this.isEmptyExact(numOfSeats)) {
            return {
                text: '',
                errorText: ``,
                isError: false,
            }
        }

        if (!this.isNumber(numOfSeats!)) {
            return {
                text: '',
                errorText: `${I18n.t('pleasEnterInt', {item: `席数`})}`,
                isError: true,
            }
        }

        return {
            text: numOfSeats!,
            errorText: ``,
            isError: false,
        }
    }

    /**
     * URL
     * @param site
     */
    isErrorWebUrl = (site: string | null | undefined): {
        text: string,
        errorText: string,
        isError: boolean,
    } => {
        if (this.isEmptyExact(site)) {
            return {
                text: '',
                errorText: ``,
                isError: false,
            }
        }

        if (!isURL(site!)) {
            return {
                text: site!,
                errorText: `アクセス可能なURLを入力してください`,
                isError: true,
            }
        }

        return {
            text: site!,
            errorText: ``,
            isError: false,
        }
    }


}

export default new ValidateUtil();
