import {Linking, Platform, TouchableOpacity} from "react-native";
// @ts-ignore
import {log} from '../../../environment';
import RedirectUtil from "./RedirectUtil";
import StrUtil from "./StrUtil";
import {WebViewScreenModel} from "../data/models/screen/WebViewScreenModel";
// @ts-ignore
import isURL from 'validator/lib/isURL';
import ValidateUtil from "./ValidateUtil";
import AlertUtil from "./AlertUtil";
import {Link} from "@react-navigation/native";


class LinkUtil {

    /**
     * URLをWebViewで開く（Webの場合は同じタブで開く）
     * @param url   特集でヘッダを付けたかったら、header=1を付ける。
     * app=1で window.ReactNativeWebView.postMessage('https://app.recibase.net/...');
     * app=0 or undefined で location.href = 'https://app.recibase.net/...' で特集画面内のリンク実行
     * @param navigation
     * @param title
     */
    openUrlInWebView = (url: string, navigation: any, title: string = 'Reci BASE') => {
        if (ValidateUtil.isEmptyExact(url)) {
            AlertUtil.alert('エラー', '指定したURLは開けません。');
            return;
        }

        if (Platform.OS == 'web') {
            Linking.openURL(url);
            return;
        }

        let webViewScreenModel: WebViewScreenModel = new WebViewScreenModel();
        webViewScreenModel.url = url;
        webViewScreenModel.title = title;
        navigation.navigate('WebViewScreen', webViewScreenModel);
    }

    /**
     * リンクをアプリで開く（Webなら同じタブ）
     */
    openLink = (url: string, navigation: any) => {
        let _url = url;
        if (Platform.OS == 'web') {
            Linking.openURL(url);
        } else {
            _url = StrUtil.changeHttpToExp(url);
        }
        RedirectUtil._openFromUrlScheme(_url, navigation);
    };

    /**
     * ブラウザでリンクを開く
     */
    openLinkWithDefaultBrowser = (url: string) => {
        if (Platform.OS == 'web') {
            window.open(url);
        } else {
            Linking.openURL(url).catch(err => log.debug(err));
        }
    };

    /**
     * 設定アプリを開く
     */
    openSetting = () => {
        if (Platform.OS === 'web') {
            return;
        }
        if (Platform.OS === 'ios') {
            Linking.openURL(`app-settings:`);
        } else if (Platform.OS === 'android') {
            Linking.openSettings();
        }
    };

    /**
     * ATagLinkComponent を使ってリンクを開くと画面遷移時にアプリがリセットされる（AppGもリセット）ので、
     * リンクを return false して、onPress の挙動のみにする
     * render 内で使う
     */
    addReturnFalseToATag = () => {
        if (Platform.OS == 'web') {
            // @ts-ignore
            $('a').each(function(index, element){
                // @ts-ignore
                if ($(element).attr('href')) {
                    // @ts-ignore
                    const result = $(element).attr('href').match(/^\/.*$/);
                    if (result != null) {
                        // @ts-ignore
                        $(element).attr('onclick', 'return false;');
                    }
                }
            })
        }
    }


}

export default new LinkUtil();
