import {appS} from "../../resources/styles/style";
import AppG from "./AppG";
import {Platform} from "react-native";

class ScreenSizeUtil {

    /**
     * Webサイズかどうか（Web でないならサイズにかかわらず false）
     */
    isWebSize = () => {
        if (AppG.window.width == null) {
            return;
        }

        if (AppG.window.width <= appS.screenSize.borderLine) {
            // スマホ
            return false;
        } else {
            if (Platform.OS == "web") {
                // Web
                return true;
            } else {
                return false;
            }
        }
    }

    /**
     * 中間サイズかどうか
     */
    isMiddleSize = () => {
        return this.isWebSize();
    }

}

export default new ScreenSizeUtil();
