import {BaseDao} from "./BaseDao";
// @ts-ignore
import {log} from '../../../../../environment';
import {WhatIsSampleRequestModalDataEntityModel} from "../../entityModels/WhatIsSampleRequestModalDataEntityModel";

class WhatIsSampleRequestModalDataDao extends BaseDao<WhatIsSampleRequestModalDataEntityModel> {
    _getModel(): WhatIsSampleRequestModalDataEntityModel {
        return new WhatIsSampleRequestModalDataEntityModel();
    }
}

// new 付なのでシングルトン
export default new WhatIsSampleRequestModalDataDao();
