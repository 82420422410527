import React from 'react'
import {
    Dimensions,
    Image,
    Keyboard,
    Platform,
    SafeAreaView,
    Text,
    TextInput,
    TouchableOpacity,
    TouchableWithoutFeedback,
    View
} from 'react-native'
import {appColors, appFont, appS} from '../../resources/styles/style'
import {BaseScreen} from "./BaseScreen";
import LoginUtil from "../util/LoginUtil";
import {IndicatorComponent} from "../components/IndicatorComponent";
// @ts-ignore
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scrollview';
import LoginDataDao from "../data/dao/local/LoginDataDao";
import {LoginDataEntityModel} from "../data/entityModels/LoginDataEntityModel";
import {CustomHeaderComponentSmall} from "../components/small/CustomHeaderComponentSmall";
// @ts-ignore
import {log} from '../../../environment';
import {TopicTitleComponent} from "../components/TopicTitleComponent";
import ValidateUtil from "../util/ValidateUtil";
import {ValidationErrorMessageComponent} from "../components/ValidattionErrorMessageComponent";
import AppG from "../util/AppG";
import ScreenSizeUtil from "../util/ScreenSizeUtil";
import {CustomHeaderComponentWide} from "../components/wide/CustomHeaderComponentWide";
import {SpacerComponent} from "../components/SpacerComponent";
import AnalyticsUtil, {AnalyticsEventName, DOpenEventName} from "../util/firebase/AnalyticsUtil";
import I18n from "../../resources/language/i18n";
// @ts-ignore
import isMultibyte from 'validator/lib/isMultibyte';
import {LoadingFullPageComponent} from "../components/LoadingFullPageComponent";
import LinkUtil from "../util/LinkUtil";

interface Props {
    navigation: any, // ナビゲーション用
}

interface State {
    resizeWindow: boolean,  // 値に意味はなし。windowサイズの拡大縮小をrenderに送るためのもの
    isLoaded: boolean,
    isSendTapped: boolean,
    isShowModal: boolean,

    // input
    // 現在のパスワード
    nowPassword: {
        text: string,
        errorText: string,
        isError: boolean,
    },
    // 新しいパスワード
    newPassword: {
        text: string,
        errorText: string,
        isError: boolean,
    },
    isShowNowPassword: boolean,
    isShowNewPassword: boolean,
}

export class ChangePasswordScreen extends BaseScreen<Props, State> {
    _resizeTimer = setInterval(() => {
        // window 拡大縮小を反映させる
        AppG.window = Dimensions.get('window');
        if (AppG.window.width != AppG.windowBefore.width || AppG.window.height != AppG.windowBefore.height) {
            this.setState({'resizeWindow': true});
            AppG.windowBefore = Dimensions.get('window');
        }
        LoginUtil.setRefreshedScreenFlg(false);
    }, 100);

    _loginData = new LoginDataEntityModel();

    _flameSize = appS.flame.size;    // 左右の margin

    _browserBackListener = (e: any) => {

    };

    constructor(props: any) {
        super(props);
        this.state = {
            resizeWindow: true,  // 値に意味はなし。windowサイズの拡大縮小をrenderに送るためのもの
            isLoaded: false,
            isSendTapped: false,
            isShowModal: false,
            nowPassword: {
                text: '',
                errorText: '',
                isError: false,
            },
            newPassword: {
                text: '',
                errorText: '',
                isError: false,
            },
            isShowNowPassword: false,
            isShowNewPassword: false,
        };
    }

    /**
     * 画面開いたときのアナリティクス送信
     */
    _sendPvAnalytics = () => {
        AnalyticsUtil.sendAnalytics(
            DOpenEventName.openPage,
            AnalyticsEventName.openPage,  // アナリティクスのイベント名
            'ChangePasswordScreen',
            null,
            null,
            null,
            null,
        );
    }

    componentDidMount() {
        if (Platform.OS == 'web') {
            window.addEventListener('popstate', this._browserBackListener);
        }

        // URL Scheme で外部リンクから起動したときの処理
        // LoginUtil.setDefaultListener(this.props.navigation);

        LoginUtil.interruptOpenScreen(this.props.navigation,
            () => {
                LoginDataDao.get().then(loginData => {
                    // Web 戻るでも呼ばれる
                    if (Platform.OS == 'web') {
                        // Analytics
                        this._sendPvAnalytics();
                    }

                    // URL Scheme で外部リンクから起動したときの処理と、画面遷移でこの画面を開いたときの処理
                    LoginUtil.setDefaultListener(this.props.navigation, () => {
                        // Webはここにこない
                        if (Platform.OS != 'web') {
                            // Analytics
                            this._sendPvAnalytics();
                        }
                    });
                    if (loginData != null) {
                        this._loginData = loginData;
                        this._loadMasters().then(() => {});
                        this._loadContents().then(() => {});
                        this.setState({nowPassword: {text: ValidateUtil.isEmptyExact(this._loginData.password)? '' : this._loginData.password!, errorText: '', isError: false}});
                        this.setState({newPassword: {text: '', errorText: '', isError: false}});
                        this.setState({'isLoaded': true});
                    }
                });
            });
    }

    componentWillUnmount () {
        clearTimeout(this._resizeTimer);
        if (Platform.OS === 'web') {
            window.removeEventListener('popstate', this._browserBackListener);
        }
        LoginUtil.removeDefaultListener(this.props.navigation);
    };

    componentDidUpdate () {
        // レンダー完了時に呼ばれる
    }

    /**
      * パラムの取得
      * @private
      */
    _getParams = () => {
    };

    /**
     * マスターのロード
     * @private
     */
    _loadMasters = async () => {
    };

    /**
     * コンテンツのロード
     * @private
     */
    _loadContents = async () => {
    };

    /**
     * エラーなら true
     */
    _isError = () => {
        let _error: boolean = false;

        if (ValidateUtil.isEmptyExact(this.state.nowPassword.text)) {
            this.setState({nowPassword: {text: this.state.nowPassword.text, errorText: `${I18n.t('pleasEnterAnItem', {item: `${I18n.t('password')}`})}`, isError: true}});
            _error = true;
        }
        if (isMultibyte(this.state.nowPassword.text) || !ValidateUtil.isPassword(this.state.nowPassword.text)) {
            this.setState({nowPassword: {text: this.state.nowPassword.text, errorText: `${I18n.t('incorrectPassword')}`, isError: true}});
            _error = true;
        }

        if (ValidateUtil.isEmptyExact(this.state.newPassword.text)) {
            this.setState({newPassword: {text: this.state.newPassword.text, errorText: `${I18n.t('pleasEnterAnItem', {item: `${I18n.t('password')}`})}`, isError: true}});
            _error = true;
        }
        if (isMultibyte(this.state.newPassword.text) || !ValidateUtil.isPassword(this.state.newPassword.text)) {
            this.setState({newPassword: {text: this.state.newPassword.text, errorText: `${I18n.t('incorrectPassword')}`, isError: true}});
            _error = true;
        }

        return _error;
    };

    /**
     * 次のページへ移動
     */
    _goNextPage = () => {
        if (!this._isError()) {
            LoginDataDao.get().then(loginData => {
                if (this.state.nowPassword.text != loginData?.password) {
                    this.setState({nowPassword: {text: this.state.nowPassword.text, errorText: `現在のパスワードが違います。`, isError: true}});
                    return;
                } else {
                    this.setState({isSendTapped: true});
                    LoginUtil.changePassword(
                        this.state.nowPassword.text,
                        this.state.newPassword.text,
                        () => {
                            loginData.password = this.state.newPassword.text;
                            LoginDataDao.addOrUpdate(loginData);
                            this.setState({isSendTapped: false});
                            this.setState({isShowModal: true});
                        },
                        () => {
                            this.setState({isSendTapped: false});
                        })
                }


            });


        }
    };

    _renderModal = () => {

        const _modal = () => {
            let _width = ScreenSizeUtil.isWebSize()? AppG.window.width *2 / 5 : AppG.window.width *4 / 5;

            return (
                <View style={{
                    height: 200,
                    width: _width,
                    backgroundColor: appColors.white,
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    <SpacerComponent height={40}/>
                    <View style={{alignItems: 'center'}}>
                        <Text style={{fontFamily: appFont.family.bold, fontSize: appFont.size.middle, color: appColors.black, fontWeight: "bold"}}>変更が完了しました</Text>
                        <Image source={require('../../resources/images/complete_alert_icon.png')}
                               style={[{
                                   width: 129,
                                   height: 129,
                               }]}
                               resizeMode='cover'/>
                    </View>
                    <SpacerComponent height={40}/>
                </View>
            );
        }

        return (
            <View style={{zIndex: 99, position: 'absolute', backgroundColor: appColors.opacityGray, flexDirection: 'row'}}>
                <TouchableOpacity
                    style={{
                        zIndex: 99,
                        position: 'absolute',
                        backgroundColor: appColors.opacityGray,
                        height: AppG.window.height,
                        width: AppG.window.width
                    }}
                    onPress={() => {
                        this.setState({isShowModal: false});
                        this.props.navigation.navigate('HomeScreen');
                    }}
                />
                <View style={{
                    position: 'absolute',
                    zIndex: 100,
                    padding: 32,
                    width: AppG.window.width * 2 / 5 + 64,
                    top: AppG.window.height / 2 - 150,
                    left: AppG.window.width * 1.5 / 5 - 32,
                    backgroundColor: appColors.transparent,
                    alignItems: 'center',
                }}>
                    {_modal()}
                </View>
            </View>
        )
    }

    _renderMain = () => {
        return (
            <View style={[{flex: 1, flexDirection: 'column'}]}>

                <View style={[{flex: 1, flexDirection: 'column'}]}>
                    {ScreenSizeUtil.isWebSize() && (<Text style={{fontFamily: appFont.family.bold, flex: 1, margin: 32, color: appColors.black, fontSize: appFont.size.large, textAlign: 'center', fontWeight: "bold"}}>パスワード変更</Text>)}

                    <SpacerComponent height={appS.header.bottomMargin} />

                    {/*現在のパスワード*/}
                    <TopicTitleComponent title={'現在のパスワード'} />
                    {/*パスワード*/}
                    <View style={[appS.marginCenter]}>
                        <TextInput
                            style={[appS.inputText, {}]}
                            secureTextEntry={!this.state.isShowNowPassword}
                            autoCapitalize={"none"}
                            placeholder={'　現在のパスワード（半角英数字と長さ6文字以上）'}
                            placeholderTextColor={appColors.gray}
                            value={this.state.nowPassword.text}
                            onChangeText={text =>
                                this.setState({nowPassword: {text: text, errorText: this.state.nowPassword.errorText, isError: this.state.nowPassword.isError}})
                            }
                        />
                        <TouchableOpacity
                            style={[{
                                alignSelf: "flex-end",
                                position: "relative",
                                top: -32,
                                left: -8,
                                zIndex: 1,
                                marginBottom: -32,
                            }]}
                            onPress={() => {
                                this.setState({isShowNowPassword: !this.state.isShowNowPassword});
                            }}
                        >
                            {this.state.isShowNowPassword && (
                                <Image source={require('../../resources/images/eye_hide_icon.png')}
                                       style={[{
                                           height: 24,
                                           width: 24,
                                       }]}
                                       resizeMode={'cover'}
                                />
                            )}
                            {!this.state.isShowNowPassword && (
                                <Image source={require('../../resources/images/eye_show_icon.png')}
                                       style={[{
                                           height: 24,
                                           width: 24,
                                       }]}
                                       resizeMode={'cover'}
                                />
                            )}
                        </TouchableOpacity>
                        <ValidationErrorMessageComponent
                            text={this.state.nowPassword.errorText}
                            isError={this.state.nowPassword.isError}
                        />
                    </View>

                    <SpacerComponent height={appS.spacer.height.small * 4}/>

                    {/*変更後のパスワード*/}
                    <TopicTitleComponent title={'変更後のパスワード'} />
                    <View style={[appS.marginCenter]}>
                        <TextInput
                            style={[appS.inputText, {}]}
                            secureTextEntry={!this.state.isShowNewPassword}
                            autoCapitalize={"none"}
                            placeholder={'　変更後のパスワード（半角英数字と長さ6文字以上）'}
                            placeholderTextColor={appColors.gray}
                            value={this.state.newPassword.text}
                            onChangeText={text =>
                                this.setState({newPassword: {text: text, errorText: this.state.newPassword.errorText, isError: this.state.newPassword.isError}})
                            }
                        />
                        <TouchableOpacity
                            style={[{
                                alignSelf: "flex-end",
                                position: "relative",
                                top: -32,
                                left: -8,
                                zIndex: 1,
                                marginBottom: -32,
                            }]}
                            onPress={() => {
                                this.setState({isShowNewPassword: !this.state.isShowNewPassword});
                            }}
                        >
                            {this.state.isShowNewPassword && (
                                <Image source={require('../../resources/images/eye_hide_icon.png')}
                                       style={[{
                                           height: 24,
                                           width: 24,
                                       }]}
                                       resizeMode={'cover'}
                                />
                            )}
                            {!this.state.isShowNewPassword && (
                                <Image source={require('../../resources/images/eye_show_icon.png')}
                                       style={[{
                                           height: 24,
                                           width: 24,
                                       }]}
                                       resizeMode={'cover'}
                                />
                            )}
                        </TouchableOpacity>
                        <ValidationErrorMessageComponent
                            text={this.state.newPassword.errorText}
                            isError={this.state.newPassword.isError}
                        />
                    </View>

                </View>

                {ScreenSizeUtil.isWebSize() && (<SpacerComponent height={64}/>)}

                {/*変更する*/}
                <TouchableOpacity
                    style={[
                        {

                            width: appS.buttonSize.width,
                            height: appS.buttonSize.height,
                            marginBottom: 40,
                            borderWidth: 1,
                            borderColor: appColors.transparent,
                            backgroundColor:  appColors.buttonOrange,
                            alignItems: 'center',
                            justifyContent: 'center',
                            alignSelf: 'center',
                        }
                    ]}
                    onPress={() => {
                        this._goNextPage();
                    }}
                >
                    <Text style={{
                        color: appColors.white,
                        fontWeight: 'bold',
                        fontSize: appFont.size.buttonText
                    }}>変更する</Text>
                </TouchableOpacity>
            </View>
        );
    };

    _renderWide = () => {
        return (
            <SafeAreaView
                style={[{height: AppG.window.height, flex: 1, flexDirection: 'column', backgroundColor: appColors.white}]}
                onLayout={(event) => {}}
            >
                <CustomHeaderComponentWide logoOnly={true} navigation={this.props.navigation}/>

                <View style={{flex: 1, flexDirection: 'row'}}>
                    <KeyboardAwareScrollView style={[{flex: 1, flexDirection: 'column'}]}>
                        <View style={[{flex: 1, width: 700, alignSelf: 'center'}]}>
                            {this._renderMain()}
                        </View>
                    </KeyboardAwareScrollView>
                </View>
                {this.state.isSendTapped && (
                    <LoadingFullPageComponent/>
                )}
                {this.state.isShowModal && this._renderModal()}

            </SafeAreaView>
        );
    }

    _renderSmall = () => {
        return (
            <SafeAreaView
                style={[{flex: 1, backgroundColor: appColors.white}]}
                onLayout={(event) => {}}
            >
                <CustomHeaderComponentSmall
                    navigation={this.props.navigation}
                    title={'パスワード変更'}
                    textColor={appColors.black}
                    barColor={appColors.white}
                    borderBottomColor={appColors.white}
                    leftButton={'back'}
                    rightButton={null}
                />
                <View style={{flex: 1, backgroundColor: appColors.backBaseColor}}>
                    {this._renderMain()}
                </View>
                {this.state.isSendTapped && (
                    <LoadingFullPageComponent/>
                )}
                {this.state.isShowModal && this._renderModal()}
            </SafeAreaView>
        );
    }

    /** 描画 */
    render() {
        const {navigation} = this.props;
        if (!this.state.isLoaded) {
            return (
                <View style={{flex: 1}}>
                    <IndicatorComponent
                        failTime={60}
                        isLoading={true}
                        reloadFunc={() => {
                            this.componentWillUnmount();
                            this.componentDidMount();
                        }}/>
                </View>
            );
        }

        LinkUtil.addReturnFalseToATag();
        if (ScreenSizeUtil.isWebSize()) {
            // WEBサイズ
            return this._renderWide();
        } else {
            // SPサイズ
            if (Platform.OS == 'web') {
                return this._renderSmall();
            }

            return (
                <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
                    {this._renderSmall()}
                </TouchableWithoutFeedback>
            );
        }

    };


}
