import React, {Component} from 'react';
import {Image, Text, TouchableOpacity, View} from 'react-native';
import {appColors, appFont, appS} from "../../resources/styles/style";
import {BorderComponent} from "./BorderComponent";
import AppG from "../util/AppG";
import ScreenSizeUtil from "../util/ScreenSizeUtil";

interface Props {
    navigation: any,
    title: string,
    onPress: () => void,
    borderWidth: number,
}

interface State { }

export class ListCellComponent extends Component<Props, State> {

    // Propsのデフォルト値
    static defaultProps = {
        borderWidth: 1,
    };

    render() {
        const {title, onPress, borderWidth} = this.props;

        let _width = AppG.window.width;
        if (ScreenSizeUtil.isWebSize()) {
            _width = appS.webMainContentsSize.width;
        }
        _width = _width - appS.margins.side * 2;

        return (
            <View>
                <TouchableOpacity
                    style={{
                        height: 40,
                        flexDirection: "row",
                        marginBottom: 0,
                        marginLeft: appS.margin.size,
                        marginRight: appS.margin.size,
                    }}
                    onPress={() => {onPress()}}
                >
                    <View style={{flexDirection: "row", flex: 1}}>
                        <Text
                            style={{
                                fontSize: appFont.size.default,
                                color: appColors.black,
                                marginLeft: 8,
                                marginTop: 14,
                            }}
                        >
                            {title}</Text>
                        <View style={{flexDirection: "row", flex: 1, justifyContent: 'flex-end'}}>
                            <Image source={require('../../resources/images/next_icon_gray.png')}
                                   style={[{
                                       marginTop: 11,
                                       backgroundColor: 'white',
                                       width: 24,
                                       height: 24,
                                   }]}
                                   resizeMode={'cover'}
                            />
                        </View>
                    </View>
                </TouchableOpacity>
                <BorderComponent width={_width} borderWidth={borderWidth} />
            </View>
        );
    }
}
