import React, {Component} from 'react';
import {ActivityIndicator, Dimensions, Text, TouchableOpacity, View} from 'react-native';
import {appColors} from '../../resources/styles/style'

interface Props {
    reloadFunc: any,    // reload用 Func
    isLoading: boolean,
    failTime: number,   // ロード失敗までの秒数
}

interface State {
    timerCount: number, // Timer のカウント1sで1増える
}

export class IndicatorComponent extends Component<Props, State> {
    timer: any = null; // 録音の無音タイマー

    // @ts-ignore
    constructor(props) {
        super(props);
        this.state = {
            timerCount: 0,
        };
    }

    componentDidMount() {
        this._timer();
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    _timer = () => {
        this.timer = setInterval(() => {
            this.setState({'timerCount': this.state.timerCount + 1});
            if (this.state.timerCount > this.props.failTime) {
                clearInterval(this.timer);
            }
        },  1000);
    };

    _timerReset = () => {
        if (this.timer != null) {
            clearInterval(this.timer);
            this.setState({'timerCount': 0});
            this._timer();
        }
    };

    render() {
        const window: { width: number, height: any }  = Dimensions.get('window');
        return (
            <View style={{flex: 1}}>
                {this.props.isLoading && this.state.timerCount <= this.props.failTime && (
                    // ロード中
                    <ActivityIndicator
                        animating = {this.props.isLoading}
                        color = {appColors.indicator}
                        size = "large"
                        style={[{flex: 1}]}/>
                )}
                {this.props.isLoading && this.state.timerCount > this.props.failTime && (
                    // ロード失敗
                    <View style={{alignItems: 'center',}}>
                        <TouchableOpacity
                            style={{
                                alignItems: 'center',
                                marginTop: window.height / 3
                            }}
                            onPress={() => {
                                this.props.reloadFunc();
                                this._timerReset();
                            }}
                        >
                            {/*<Image source={require('../../resources/images/FontAwesome_f01e_256_gray.png')}*/}
                            {/*       style={[{*/}
                            {/*           width: 35,*/}
                            {/*           height: 35,*/}
                            {/*       }]}*/}
                            {/*       resizeMode='cover'/>*/}
                            <Text>RELOAD</Text>
                        </TouchableOpacity>
                    </View>
                )}
            </View>
        );
    }
}
