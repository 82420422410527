import {Alert, Platform} from "react-native";
// import Swal from 'sweetalert2'
import {appColors} from "../../resources/styles/style";

class AlertUtil {

    /**
     * アラート
     * @param title
     * @param message
     */
    alert = (title: string, message: string) => {
        if (Platform.OS == 'web') {
            alert([title, message].filter(Boolean).join('\n'))
        } else {
            Alert.alert(title, message);
        }
    };

    /**
     * OKを押したらアクションが進むアラート
     * @param title
     * @param message
     * @param agreeFunc
     * @param agreeText
     */
    ok = (title: string, message: string, agreeFunc: () => void, agreeText: string = 'OK') => {
        if (Platform.OS == 'web') {
            const result = window.confirm([title, message].filter(Boolean).join('\n'))

            if (result) {
                const confirmOption = [
                    {text: agreeText, onPress: agreeFunc, style: 'default'},
                ].find(({ style }) => style !== 'cancel')
                confirmOption && confirmOption.onPress()
            } else {
                const cancelOption = [
                    {text: agreeText, onPress: agreeFunc, style: 'default'},
                ].find(({ style }) => style === 'cancel')
                cancelOption && cancelOption.onPress()
            }
        } else {
            Alert.alert(
                title,
                message,
                [
                    {text: agreeText, onPress: agreeFunc, style: 'default'},
                ],
                { cancelable: false }
            );
        }
    };

    /**
     * はい、いいえの確認アラート
     * @param title
     * @param message
     * @param agreeFunc
     * @param disagreeFunc
     * @param agreeText
     * @param disagreeText
     */
    confirm = (title: string, message: string, agreeFunc: () => void, disagreeFunc: () => void, agreeText: string = 'はい', disagreeText: string = 'いいえ') => {
        if (Platform.OS == 'web') {
            const result = window.confirm([title, message].filter(Boolean).join('\n'))

            if (result) {
                const confirmOption = [
                    {text: agreeText, onPress: agreeFunc, style: 'default'},
                    {text: disagreeText, onPress: disagreeFunc, style: 'cancel'},
                ].find(({ style }) => style !== 'cancel')
                confirmOption && confirmOption.onPress()
            } else {
                const cancelOption = [
                    {text: agreeText, onPress: agreeFunc, style: 'default'},
                    {text: disagreeText, onPress: disagreeFunc, style: 'cancel'},
                ].find(({ style }) => style === 'cancel')
                cancelOption && cancelOption.onPress()
            }
        } else {
            Alert.alert(
                title,
                message,
                [
                    {text: agreeText, onPress: agreeFunc, style: 'default'},
                    {text: disagreeText, onPress: disagreeFunc, style: 'cancel'},
                ],
                { cancelable: false }
            );
        }
    };

}

export default new AlertUtil();
