
export class ProfileInitScreenModel {
    /**
     * 会員属性（一般ユーザー(normal)/飲食人(cook)）
     * @type {string}
     * @memberof User
     */
    userAttribute: 'normal' | 'cook' | null = null;
    /**
     *
     * @type {string}
     * @memberof User
     */
    name: string | null = null;
    /**
     *
     * @type {string}
     * @memberof User
     */
    nameKana: string | null = null;
    /**
     *
     * @type {string}
     * @memberof User
     */
    gender: 'male' | 'female' | null = null;
    /**
     * yyyy-MM-dd
     * @type {string}
     * @memberof User
     */
    birthday: string | null = null;

    /**
     * 店舗名
     */
    nameRestaurant?: string | null = null;
    /**
     * 郵便番号(数字のみ、数字以外は送信・受信・DB保存前に削除)
     */
    postalCode?: string | null = null;

    /**
     * 都道府県
     */
    restaurantState?: string | null = null;

    /**
     * 市区町村
     */
    restaurantCity?: string | null = null;

    /**
     * 番地以降
     */
    restaurantStreetAddress?: string | null = null;

    /**
     * 電話番号(数字のみ、数字以外は送信・受信・DB保存前に削除)
     */
    tell?: string | null = null;

    /**
     * 店舗所在地（大）のマスターID
     */
    restaurantLargeLocationId?: string | null = null;

    /**
     * 店舗所在地（中）のマスターID
     */
    restaurantMediumLocationId?: string | null = null;

    /**
     * 店舗業態（大）のマスターID
     */
    restaurantLargeFormatId?: string | null = null;

    /**
     * 店舗業態（中）のマスターID
     */
    restaurantMediumFormatId?: string | null = null;

    /**
     * 客単価のマスターID
     */
    customerUnitPriceId?: string | null = null;

    /**
     * 店舗規模マスターID
     */
    numOfRestaurantId?: string | null = null;
}
