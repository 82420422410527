import React from 'react'
import {
    ActivityIndicator,
    Dimensions,
    Image,
    Platform,
    SafeAreaView,
    ScrollView,
    Text,
    TouchableOpacity,
    View
} from 'react-native'
import {appColors, appFont, appS} from '../../resources/styles/style'
import {BaseScreen} from "./BaseScreen";
import LoginUtil from "../util/LoginUtil";
import {IndicatorComponent} from "../components/IndicatorComponent";
// @ts-ignore
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scrollview';
import LoginDataDao from "../data/dao/local/LoginDataDao";
import {LoginDataEntityModel} from "../data/entityModels/LoginDataEntityModel";
import {Product, ProductApiFactory, User, UserApiFactory, UserUserAttributeEnum,} from "../data/network/swagger-gen";
import AppGlobalDataUtil from "../util/AppG";
import AppG from "../util/AppG";
import UrlUtil from "../util/UrlUtil";
// @ts-ignore
import isURL from 'validator/lib/isURL';
// @ts-ignore
import {log} from '../../../environment';
import {AxiosResponse} from "axios";
import {CartScreenModel} from "../data/models/screen/CartScreenModel";
import ValidateUtil from "../util/ValidateUtil";
import {CheckBox} from "react-native-elements";
import AlertUtil from "../util/AlertUtil";
import I18n from "../../resources/language/i18n";
import ExpoUtil from "../util/ExpoUtil";
import ScreenSizeUtil from "../util/ScreenSizeUtil";
import {CustomHeaderComponentWide} from "../components/wide/CustomHeaderComponentWide";
import {NoItemsIconComponent} from "../components/NoItemsIconComponent";
import {WideScreenAdComponent} from "../components/wide/WideScreenAdComponent";
import {SampleRequestScreenModel} from "../data/models/screen/SampleRequestScreenModel";
import AnalyticsUtil, {AnalyticsEventName, DOpenEventName} from "../util/firebase/AnalyticsUtil";
import {ProductDetailScreenModel} from "../data/models/screen/ProductDetailScreenModel";
import {CustomHeaderComponentSmall} from "../components/small/CustomHeaderComponentSmall";
import {SpacerComponent} from "../components/SpacerComponent";
import {ModalComponent} from "../components/ModalComponent";
import * as Notifications from "expo-notifications";

import {ATagLinkComponent} from "../components/ATagLinkComponent";
import {WideSubTitleComponent} from "../components/wide/WideSubTitleComponent";
import {NoUserImageForCartComponent} from "../components/NoUserImageForCartComponent";
import MyAppUtil from "../util/MyAppUtil";
import {WideScreenSNSComponent} from "../components/wide/WideScreenSNSComponent";
import LinkUtil from "../util/LinkUtil";
import {NothingCartImageComponent} from "../components/NothingCartImageComponent";

interface Props {
    navigation: any, // ナビゲーション用
}

interface State {
    resizeWindow: boolean,  // 値に意味はなし。windowサイズの拡大縮小をrenderに送るためのもの
    isLoaded: boolean,
    isShowErrorModal: boolean,

    // コンテンツ
    user: User | null,
    productsInCart: Product[], // サンプルかごの商品
    productsInCartCheckList: {
        product: Product
        isChecked: boolean
    }[] // 商品の SelectBox
}

export class CartScreen extends BaseScreen<Props, State> {
    _resizeTimer = setInterval(() => {
        // window 拡大縮小を反映させる
        AppG.window = Dimensions.get('window');
        if (AppG.window.width != AppG.windowBefore.width || AppG.window.height != AppG.windowBefore.height) {
            this.setState({'resizeWindow': true});
            AppG.windowBefore = Dimensions.get('window');
        }
        LoginUtil.setRefreshedScreenFlg(false);
    }, 100);

    _loginData = new LoginDataEntityModel();

    _flameSize = ScreenSizeUtil.isWebSize()? AppG.window.width / 10 : 16;    // 左右の margin
    _marginSize = ScreenSizeUtil.isWebSize()? 0 : appS.margin.size;

    // このScreenへ渡すパラム
    _param: CartScreenModel = new CartScreenModel();
    _contentsLoadCount = 9; // コンテンツの一度の読み込み数

    // スクロール用
    _scrollTab: any = null;

    _browserBackListener = (e: any) => {

    };

    constructor(props: any) {
        super(props);
        this.state = {
            resizeWindow: true,  // 値に意味はなし。windowサイズの拡大縮小をrenderに送るためのもの
            isLoaded: false,
            isShowErrorModal: false,
            user: null,
            productsInCart: [], // サンプルかごの商品
            productsInCartCheckList: [],
        };
    }

    /**
     * 画面開いたときのアナリティクス送信
     */
    _sendPvAnalytics = () => {
        AnalyticsUtil.sendAnalytics(
            DOpenEventName.openPage,
            AnalyticsEventName.openPage,  // アナリティクスのイベント名
            'CartScreen',
            null,
            null,
            null,
            null,
        );
    }

    componentDidMount() {
        if (Platform.OS == 'web') {
            window.addEventListener('popstate', this._browserBackListener);
        }

        if (Platform.OS != 'web') {
            // スクロールviewのセット
            this.props.navigation.setParams({
                scrollToTop: () => {
                    if (this._scrollTab != null) {
                        this._scrollTab.scrollTo({ x: 0, y: 0, animated: true });
                    }
                }
            })
        }

        // URL Scheme で外部リンクから起動したときの処理
        // LoginUtil.setDefaultListener(this.props.navigation);

        LoginUtil.interruptOpenScreen(this.props.navigation,
            () => {
                LoginDataDao.get().then(loginData => {
                    // Web 戻るでも呼ばれる (Footerにあるやつはappでも実行するようにする)
                    // Analytics
                    this._sendPvAnalytics();
                    AppG.getFooterBadge();

                    if (Platform.OS != 'web') {
                        // アイコンバッヂの更新
                        Notifications.setBadgeCountAsync((AppG.badgeChat == null? 0 : AppG.badgeChat) + (AppG.badgeHistory == null? 0 : AppG.badgeHistory));
                    }

                    // URL Scheme で外部リンクから起動したときの処理と、画面遷移でこの画面を開いたときの処理
                    LoginUtil.setDefaultListener(
                        this.props.navigation,
                        () => {
                            // Webはここにこない
                            if (Platform.OS != 'web') {
                                // Analytics
                                this._sendPvAnalytics();
                            }

                            this.setState({productsInCartCheckList: []});
                            this._loadContents().then(() => {});
                            AppG.getFooterBadge();

                            ExpoUtil.doReloadAppTab(() => {
                            });
                        });
                    if (loginData != null && !ValidateUtil.isEmptyExact(loginData?.user_id)) {
                        this._loginData = loginData;
                        this._loadMasters().then(() => {});
                        this._loadContents().then(() => {});
                    }
                    this.setState({'isLoaded': true});
                });
            });
    }

    componentWillUnmount () {
        clearTimeout(this._resizeTimer);
        if (Platform.OS === 'web') {
            window.removeEventListener('popstate', this._browserBackListener);
        }
        LoginUtil.removeDefaultListener(this.props.navigation);
    };

    componentDidUpdate () {
        // レンダー完了時に呼ばれる
    }

    /**
     * パラムの取得
     * @private
     */
    _getParams = () => {
        let cartScreenModel: CartScreenModel = UrlUtil.getParamModel(new CartScreenModel(), this.props.navigation);
        return cartScreenModel;
    };

    /**
     * マスターのロード
     * @private
     */
    _loadMasters = async () => {

    };

    /**
     * コンテンツのロード
     * @private
     */
    _loadContents = async () => {
        this._param = this._getParams();


        // カート内商品
        this.setState({isLoaded: false});
        const getProductsInCart = ProductApiFactory(AppGlobalDataUtil.getConfiguration(), AppGlobalDataUtil.getBasePath())
            .findProducts(
                0,
                100,
                undefined,
                1,
                undefined,
                undefined,
                undefined,
                4,
            ).then((resultProductsRes: AxiosResponse<Product[]>) => {
                if (resultProductsRes != null && !ValidateUtil.isEmptyArray(resultProductsRes.data)) {
                    this.setState({productsInCart: resultProductsRes.data});

                    let _productIdsInCartCheckList: {
                        product: Product
                        isChecked: boolean
                    }[] = [];

                    resultProductsRes.data.forEach((product: Product) => {
                        _productIdsInCartCheckList.push(
                            {
                                product: product,
                                isChecked: true,
                            }
                        )
                    });
                    this.setState({productsInCartCheckList: _productIdsInCartCheckList});
                }
                this.setState({isLoaded: true});
            });

        // ユーザー
        const getUser = UserApiFactory(AppGlobalDataUtil.getConfiguration(), AppGlobalDataUtil.getBasePath())
            .getUser().then((userRes: AxiosResponse<User>) => {
                if (userRes != null && userRes.data != null) {
                    AppG.user = userRes.data;
                    this.setState({user: userRes.data});
                }
            });

        // 実行
        await Promise.all([getProductsInCart, getUser]);
    };

    /**
     * 選択されてるかどうかチェック
     */
    _checkSelected = () => {
        let _checked = false;
        this.state.productsInCartCheckList.forEach((value) => {
            if (value.isChecked) {
                _checked = true;
            }
        })

        return _checked;
    }


    /**
     * かごの中のセル
     * @param checkList
     * @param index
     */
    _renderProductInCartCell = (checkList: {product: Product, isChecked: boolean}, index: number) => {
        let source = require('../../resources/images/no_image.png');
        if (!ValidateUtil.isEmptyArray(checkList.product.images) && isURL(checkList.product.images[0].url)) {
            source = {uri: checkList.product.images[0].url};
        }

        let _width = AppG.window.width;
        if (ScreenSizeUtil.isWebSize()) {
            _width = appS.webMainContentsSize.width;
        }
        _width = _width - appS.margins.side * 2;

        const _imageSize = 80;
        const _checkBoxSize = 15;

        return (
            <View style={{
                flexDirection: "row",
                flex: 1,
                margin: Platform.OS == 'web'? 0 : appS.margins.side,
            }}>
                <View style={{alignSelf: 'center'}}>
                    <CheckBox
                        center
                        containerStyle={{height: 30, marginLeft:0}}
                        checkedIcon={(
                            <Image source={require('../../resources/images/02.menu_general/checkbox_on.png')}
                                   style={[{
                                       marginTop: -5,
                                       width: _checkBoxSize,
                                       height: _checkBoxSize,
                                   }]}
                                   resizeMode={'cover'}
                            />
                        )}
                        uncheckedIcon={(
                            <Image source={require('../../resources/images/02.menu_general/checkbox_off.png')}
                                   style={[{
                                       marginTop: -5,
                                       width: _checkBoxSize,
                                       height: _checkBoxSize,
                                   }]}
                                   resizeMode={'cover'}
                            />
                        )}
                        checked={checkList.isChecked}
                        onPress={() => {
                            checkList.isChecked = !checkList.isChecked;
                            this.setState({productsInCartCheckList: this.state.productsInCartCheckList});
                        }}
                    />
                </View>
                <ATagLinkComponent
                    href={`/ProductDetailScreen/${checkList.product!.id}`}
                    style={{
                        flexDirection: "row",
                        flex: 1,
                    }}
                    onPress={() => {
                        // 商品詳細画面へ遷移
                        let productDetailScreenModel: ProductDetailScreenModel = new ProductDetailScreenModel();
                        productDetailScreenModel.productId = checkList.product!.id.toString();
                        if (Platform.OS == 'web') {
                            this.props.navigation.navigate('ProductDetailScreen', productDetailScreenModel);
                        } else {
                            this.props.navigation.push('ProductDetailScreen', productDetailScreenModel);
                        }
                    }}
                    view={
                        <View style={{flexDirection: 'row'}}>
                            <Image source={source}
                                   style={[{
                                       backgroundColor: 'white',
                                       borderRadius: 5,
                                       borderColor: appColors.transparent,
                                       width: _imageSize,
                                       height: _imageSize,
                                   }]}
                                   resizeMode={'cover'}
                            />

                            <View style={{flexDirection: "column", height: _imageSize, marginLeft: appS.margins.side}}>
                                <Text
                                    style={{
                                        marginTop: 0,
                                        fontFamily: appFont.family.bold,
                                        width: _width - _imageSize - _checkBoxSize * 2 - appS.margins.side * 3,
                                        fontSize: appFont.size.listDescription,
                                        color: appColors.fontBlack,
                                        fontWeight: "bold"
                                    }}
                                    numberOfLines={1}
                                    ellipsizeMode='tail'
                                >
                                    {checkList.product.name}</Text>

                                <Text
                                    style={{
                                        fontFamily: appFont.family.default,
                                        width: _width - _imageSize - _checkBoxSize * 2 - appS.margins.side * 3,
                                        fontSize: appFont.size.listDescription,
                                        color: appColors.fontGray,
                                        marginTop: Platform.OS == 'android'? 0 : appS.margins.side / 2,
                                    }}
                                    numberOfLines={1}
                                    ellipsizeMode='tail'
                                >
                                    {checkList.product.maker?.name}</Text>
                                <Text
                                    style={{
                                        fontFamily: appFont.family.default,
                                        width: _width - _imageSize - _checkBoxSize * 2 - appS.margins.side * 3,
                                        fontSize: appFont.size.listDescription,
                                        color: appColors.fontGray,
                                        marginTop: Platform.OS == 'android'? -appS.margins.side : appS.margins.side / 2,
                                    }}
                                    numberOfLines={1}
                                    ellipsizeMode='tail'
                                >出荷単位　{checkList.product.capacity}</Text>
                                {ValidateUtil.isEmptyExact(checkList.product.sampleAmount) && (
                                    <Text
                                        style={{
                                            fontFamily: appFont.family.bold,
                                            width: _width - _imageSize - _checkBoxSize * 2 - appS.margins.side * 3,
                                            fontSize: appFont.size.listDescription,
                                            color: appColors.red,
                                            marginTop: Platform.OS == 'android'? -appS.margins.side : appS.margins.side / 2,
                                            fontWeight: "bold"
                                        }}
                                        numberOfLines={1}
                                        ellipsizeMode='tail'
                                    >サンプル配布を終了しました</Text>
                                )}
                            </View>
                        </View>
                    }
                />

                <View style={{
                    flexDirection: "row",
                    flex: 1,
                }}>
                    {/*削除*/}
                    <View style={{
                        flexDirection: "row",
                        flex: 1,
                        justifyContent: 'flex-end'
                    }}>
                        <TouchableOpacity
                            style={{
                                alignSelf: 'center',
                                height: 40,
                                flexDirection: "row",
                                marginBottom: 0,
                            }}
                            onPress={() => {
                                // 削除と更新
                                ProductApiFactory(AppGlobalDataUtil.getConfiguration(), AppGlobalDataUtil.getBasePath())
                                    .requestProductIntoCart(checkList.product.id.toString(), 'out')
                                    .then(() => {
                                        // Analytics
                                        AnalyticsUtil.sendAnalytics(
                                            DOpenEventName.cartOut,
                                            AnalyticsEventName.cartOut,  // アナリティクスのイベント名
                                            'CartScreen',
                                            null,
                                            checkList.product,
                                            null,
                                            null,
                                        );

                                        let l = this.state.productsInCartCheckList;
                                        l.some(
                                            function (list: {product: Product, isChecked: boolean}, index: number) {
                                                if (list.product.id === checkList.product.id) {
                                                    l.splice(index, 1);
                                                }
                                            });
                                        this.setState({productsInCartCheckList: l});
                                    })
                                    .catch(() => {
                                        // エラー
                                        AlertUtil.alert(`${I18n.t('errorNetworkTitle')}`, `${I18n.t('errorNetwork')}`);
                                    });
                            }}
                        >

                            <Image source={require('../../resources/images/05.search/i_search_cancel.png')}
                                   style={[{
                                       marginTop: Platform.OS == 'web'? 14 :11,
                                       backgroundColor: 'white',
                                       width: _checkBoxSize,
                                       height: _checkBoxSize,
                                   }]}
                                   resizeMode={'cover'}
                            />

                        </TouchableOpacity>
                    </View>
                </View>
            </View>

        );
    };

    /**
     * 一般ユーザーの場合
     */
    _renderNormalUser = () => {
        // 飲食人の方の場合
        return (
            <View>
                <SpacerComponent height={100} />
                <NoItemsIconComponent text={'飲食関係者専用のコンテンツになります\n※無料会員登録時に設定が必要'}/>
            </View>
        );
    }

    /**
     *
     * かご
     */
    _renderCart = () => {

        let _width = AppG.window.width;
        if (ScreenSizeUtil.isWebSize()) {
            _width = appS.webMainContentsSize.width;
        }
        _width = _width - appS.margins.side * 2;

        return (
            <View style={[{flex: 1, flexDirection: 'column', backgroundColor: (AppG.user == null || (this.state.isLoaded && ValidateUtil.isEmptyArray(this.state.productsInCartCheckList))) ? appColors.noUserBackground : appColors.backBaseColor}]}>
                <KeyboardAwareScrollView
                    ref={(scrollTab: any) => { this._scrollTab = scrollTab; }}
                    style={[{flex: 1, flexDirection: 'column'}]}
                >
                    <View
                        style={[{flex: 1, flexDirection: 'column', justifyContent: 'center'}]}>
                        <View style={[{flexDirection: 'column'}]}>

                            {/*未ログイン*/}
                            {AppG.user == null && (
                                <View style={{flex: 1, backgroundColor: AppG.user == null ? appColors.noUserBackground : appColors.backBaseColor}}>
                                    <NoUserImageForCartComponent navigation={this.props.navigation} />
                                </View>
                            )}

                            {(AppG.user != null && AppG.user.userAttribute == UserUserAttributeEnum.Normal) && (
                                <View style={{width: _width}}>
                                    <Text style={{
                                        marginLeft: appS.margins.side*2,
                                        fontFamily: appFont.family.bold,
                                        fontSize: appFont.size.subTopic,
                                        color: appColors.fontBlack,
                                        fontWeight: "bold"
                                    }}>飲食関係者向けサービスとなります。</Text>
                                    <Text style={{
                                        marginLeft: appS.margins.side*2,
                                        fontFamily: appFont.family.bold,
                                        fontSize: appFont.size.subTopic,
                                        color: appColors.fontBlack,
                                        fontWeight: "bold"
                                    }}> 一般の方はご利用になれません。</Text>
                                </View>
                            )}

                            {(AppG.user != null && AppG.user.userAttribute == UserUserAttributeEnum.Cook) && (
                                <View style={{width: _width}}>

                                    {/*所持コイン*/}
                                    <View style={{flex: 1, flexDirection: "row"}}>
                                        <Text style={{
                                            marginLeft: appS.margins.side*2,
                                            fontFamily: appFont.family.bold,
                                            fontSize: appFont.size.subTopic,
                                            color: appColors.fontBlack,
                                            fontWeight: "bold"
                                        }}>
                                            商品を選択してください。
                                        </Text>
                                        {this.state.user?.userFreeCoin != null && (
                                            <View style={{flex: 1, flexDirection: 'column-reverse'}}>
                                                <View style={{flexDirection: 'row', alignContent: 'flex-end'}}>
                                                    <View style={{flex: 1}}/>
                                                    <Text style={{fontFamily: appFont.family.bold, color: appColors.coin, fontSize: appFont.size.coin, fontWeight: "bold"}}>所持コイン</Text>
                                                    <Image
                                                        source={require('../../resources/images/02.menu_general/i_coin.png')}
                                                        style={{
                                                            margin: 2,
                                                            width: appS.coin.iconSize,
                                                            height: appS.coin.iconSize,
                                                        }}
                                                    />
                                                    <Text
                                                        style={{
                                                            fontFamily: appFont.family.bold,
                                                            fontSize: appFont.size.coin,
                                                            color: appColors.coin,
                                                            fontWeight: "bold"
                                                        }}>
                                                        {`${this.state.user?.userFreeCoin}`}
                                                    </Text>
                                                </View>
                                            </View>
                                        )}
                                    </View>

                                    <SpacerComponent height={appS.margins.betweenTopic} />

                                    {/*商品*/}
                                    {!ValidateUtil.isEmptyArray(this.state.productsInCartCheckList) && this.state.productsInCartCheckList.map((checkList: {product: Product, isChecked: boolean}, index: number) => {
                                        return (
                                            <View key={`cart_view_${index}`}>
                                                {this._renderProductInCartCell(checkList, index)}
                                                <SpacerComponent height={appS.margins.side} />
                                            </View>
                                        );
                                    })}

                                    {/*ローディングインジケーター*/}
                                    {!this.state.isLoaded && (
                                        <View style={{flex: 1, height: appS.activityIndicator.height}}>
                                            <SpacerComponent height={appS.margins.betweenTopic} />
                                            <ActivityIndicator
                                                animating={this.state.isLoaded}
                                                color = {appColors.indicator}
                                                size = "large"
                                                style={[{flex: 1}]}/>
                                        </View>
                                    )}

                                    {/*商品無し*/}
                                    {this.state.isLoaded && ValidateUtil.isEmptyArray(this.state.productsInCartCheckList) && (
                                        <View>
                                            <ScrollView style={{backgroundColor: appColors.noUserBackground, height: AppG.window.height}}>
                                                <NothingCartImageComponent navigation={this.props.navigation} />
                                            </ScrollView>
                                        </View>
                                    )}

                                    <SpacerComponent height={appS.margins.betweenTopic} />

                                    {/*サンプル依頼ボタン*/}
                                    {!ValidateUtil.isEmptyArray(this.state.productsInCartCheckList) && (
                                        <TouchableOpacity
                                            style={[
                                                {
                                                    width: appS.buttonSize.width,
                                                    height: appS.buttonSize.height,
                                                    backgroundColor: this._checkSelected()? appColors.buttonOrange : appColors.gray,
                                                    alignItems: 'center',
                                                    alignSelf: 'center',
                                                    justifyContent: 'center',
                                                    borderRadius: appS.buttonSize.radius
                                                }
                                            ]}
                                            disabled={!this._checkSelected()}
                                            onPress={() => {
                                                if (AppG.user == null) {
                                                    ValidateUtil.showNoUserAlertAtSampleRequest(this.props.navigation);
                                                    return;
                                                }

                                                if (!ValidateUtil.isEnableSampleRequest()) {
                                                    this.setState({isShowErrorModal: true});
                                                    return;
                                                }

                                                let sampleRequestScreenModel: SampleRequestScreenModel = new SampleRequestScreenModel();
                                                let _ids = '|';
                                                let _checked = false;
                                                this.state.productsInCartCheckList.forEach((value) => {
                                                    if (value.isChecked) {
                                                        _checked = true;
                                                        _ids = `${_ids}${value.product.id}|`;
                                                    }
                                                })

                                                if (!_checked) {
                                                    AlertUtil.alert('エラー', 'サンプルを選択してください');
                                                }
                                                sampleRequestScreenModel.productIds = `${_ids}|`;
                                                this.props.navigation.navigate('SampleRequestScreen', sampleRequestScreenModel);
                                            }}
                                        >
                                            <View style={{flexDirection: 'row', alignSelf: 'center'}}>
                                                <Text style={{
                                                    lineHeight: appS.buttonSize.height,
                                                    textAlignVertical: 'center',
                                                    textAlign: 'center',
                                                    fontFamily: appFont.family.bold,
                                                    fontSize: appFont.size.buttonText,
                                                    color: appColors.white,
                                                    fontWeight: "bold"
                                                }}>サンプル依頼に進む</Text>
                                                <Image
                                                    source={require('../../resources/images/02.menu_general/arrow_W_right.png')}
                                                    style={{
                                                        marginTop: 20,
                                                        width: 12,
                                                        height: 12,
                                                    }}
                                                />
                                            </View>

                                        </TouchableOpacity>
                                    )}
                                </View>
                            )}
                        </View>
                    </View>
                    <SpacerComponent height={appS.header.webUpsideHeight + appS.header.webBottomHeight}/>
                </KeyboardAwareScrollView>
            </View>
        );
    }

    _renderSmall = () => {
        return (
            <SafeAreaView
                style={[{height: AppG.window.height, flex: 1, flexDirection: 'column', backgroundColor: appColors.white}]}
                onLayout={(event) => {}}
            >
                <CustomHeaderComponentSmall
                    navigation={this.props.navigation}
                    leftButton={MyAppUtil.selectWebLogoSp()}
                    rightButton={MyAppUtil.selectWebMenuSp()}
                    title={'サンプルかご'}
                    searchBar={false}
                    textColor={appColors.fontBlack}
                    barColor={appColors.white}
                    borderBottomColor={appColors.transparent}
                    shadow={true}
                    statusBarType={"dark-content"}
                />

                <View style={{backgroundColor: ValidateUtil.isEmptyArray(this.state.productsInCartCheckList)? appColors.noUserBackground : appColors.transparent}}>
                    <SpacerComponent height={appS.header.bottomMargin}/>
                </View>


                {/*サンプルかご一覧タブ*/}
                {this._renderCart()}

                <ModalComponent
                    title={'必要な情報が未登録です'}
                    isShowModal={this.state.isShowErrorModal}
                    onButtonPress={() => {
                        this.setState({isShowErrorModal: false});
                        this.props.navigation.navigate('ProfileScreen');
                    }}
                    height={250}
                    buttonTitle={'プロフィールを編集'}
                    text={'プロフィール画面よりサンプル依頼に必要な情報を入力してください'}
                />
            </SafeAreaView>
        );
    }

    _renderWide = () => {
        const {navigation} = this.props;

        return (
            <SafeAreaView
                style={[{height: AppG.window.height, flex: 1, flexDirection: 'column', backgroundColor: appColors.white}]}
                onLayout={(event) => {}}
            >
                <CustomHeaderComponentWide
                    navigation={navigation}
                    searchBar={true}
                />

                <View style={{
                    flex: 1,
                    flexDirection: 'row',
                    alignSelf: 'center',
                    backgroundColor: appColors.backBaseColor,
                }}>
                    <ScrollView
                        horizontal  // 下階層のスクロールイベントを拾えるようにしている
                        style={{
                            width: AppG.window.width,
                            height: AppG.window.height - appS.header.webHeight,
                        }}
                    >
                        <View style={[{flex: 1, flexDirection: 'column', width: AppG.window.width}]}>
                            <KeyboardAwareScrollView style={[{flex: 1, flexDirection: 'column'}]}>
                                <View style={[{flex: 1, flexDirection: ScreenSizeUtil.isWebSize()? 'row': 'column', justifyContent: 'center'}]}>
                                    <View style={[{flexDirection: 'column', backgroundColor: AppG.user == null ? appColors.noUserBackground : appColors.backBaseColor}]}>
                                        <View style={{width: ScreenSizeUtil.isWebSize()? appS.webMainContentsSize.width : AppG.window.width}}>
                                            {ScreenSizeUtil.isWebSize() && <WideSubTitleComponent title={'サンプルかご'} width={appS.webMainContentsSize.width} />}
                                            {this._renderCart()}
                                        </View>
                                    </View>
                                    {ScreenSizeUtil.isWebSize() && (
                                        <View style={{marginLeft: appS.margins.webBetweenMargin, width: appS.webSideContentsSize.width}}>
                                            <WideScreenAdComponent navigation={this.props.navigation}/>
                                            <WideScreenSNSComponent navigation={this.props.navigation}/>
                                        </View>
                                    )}
                                </View>
                            </KeyboardAwareScrollView>
                        </View>
                    </ScrollView>
                </View>
                <ModalComponent
                    title={'必要な情報が未登録です'}
                    isShowModal={this.state.isShowErrorModal}
                    onButtonPress={() => {
                        this.setState({isShowErrorModal: false});
                        this.props.navigation.navigate('ProfileScreen');
                    }}
                    height={180}
                    buttonTitle={'プロフィールを編集'}
                    text={'　プロフィール画面よりサンプル依頼に必要な情報を入力してください'}
                />
            </SafeAreaView>
        );
    }



    /** 描画 */
    render() {
        const {navigation} = this.props;
        if (!this.state.isLoaded) {
            return (
                <View style={{flex: 1}}>
                    <IndicatorComponent
                        failTime={60}
                        isLoading={true}
                        reloadFunc={() => {
                            this.componentWillUnmount();
                            this.componentDidMount();
                        }}/>
                </View>
            );
        }

        LinkUtil.addReturnFalseToATag();
        if (ScreenSizeUtil.isWebSize()) {
            // WEBサイズ
            return this._renderWide();
        } else {
            // SPサイズ
            return this._renderSmall();
        }


    };


}
