import {
    ImageFile,
    ProductCategory,
    ProductCategoryLargeMediumInner,
    Recipe,
    RecipeIngredientsInner, RestaurantFormat
} from "../data/network/swagger-gen";
import ValidateUtil from "./ValidateUtil";
import CsvUtil from "./CsvUtil";
import {Image, Platform} from "react-native";
import LinkUtil from "./LinkUtil";
// @ts-ignore
import {getEnvVars, log} from '../../../environment';
// @ts-ignore
import isURL from 'validator/lib/isURL';
import ImageUtil from "./ImageUtil";
import StrUtil from "./StrUtil";
import {Link} from "@react-navigation/native";
import React from "react";
import {appS} from "../../resources/styles/style";

/**
 * このアプリ専用のUtil
 */
class MyAppUtil {

    _downloadRecipeImage = (recipeName: string, image: ImageFile, index: number) => {
        if (Platform.OS != 'web') {
            return;
        }

        if (!ValidateUtil.isEmptyExact(image.url) && isURL(image.url)) {
            ImageUtil.downloadImage(recipeName, `${StrUtil.changeP20ToPlus(StrUtil.fixS3Url(image.url))}`, index);

            // ImageUtil.downloadImage(recipe.name,
            //     encodeURI('https://resibase-stajing.s3.ap-northeast-1.amazonaws.com/release1/recipes/import/1612517278/recipe/images/sidewinder.jpg'),
            //     index);
            // ImageUtil.downloadImage(recipe.name, 'https://resibase-stajing.s3-ap-northeast-1.amazonaws.com/release1/recipes/import/1612517278/recipe/images/sidewinder.jpg', index);
            // const _url = 'https://resibase-stajing.s3-ap-northeast-1.amazonaws.com/release1/recipes/import/1612517278/recipe/images/image.png';
            // const _url = 'https://resibase-stajing.s3-ap-northeast-1.amazonaws.com/release1/recipes/import/1612517278/recipe/images/sidewinder.jpg';
            // const _url = 'https://resibase-stajing.s3-ap-northeast-1.amazonaws.com/release1/recipes/import/1612517278/recipe/images/smoke+chicken+tulip+01.jpg';
            // const _url = 'https://resibase-stajing.s3.ap-northeast-1.amazonaws.com/release1/recipes/import/1612517278/recipe/images/smoke%20chicken%20tulip%2001.jpg';
            // ImageUtil.downloadImage(recipeName, StrUtil.changeP20ToPlus(_url), index);
        }
    }

    downloadRecipeImages = (recipe: Recipe) => {
        if (Platform.OS != 'web') {
            return;
        }

        // 画像ダウンロード
        if (!ValidateUtil.isEmptyArray(recipe.images)) {
            recipe.images.forEach((image: ImageFile, index: number) => {
                this._downloadRecipeImage(recipe.name, image, index);
            })
        }
    }

    /**
     *
     * @param navigation
     * @param recipe
     */
    downloadRecipeCsv = (navigation: any, recipe: Recipe) => {
        if (Platform.OS == 'web') {
            // 材料10こ空文字セット
            let _ingredient: RecipeIngredientsInner[] = [];
            for (let i = 0; i < 10; i++) {
                _ingredient.push({
                    name: '',
                    amount: '',
                    unit: ''
                });
            }
            let count = 0;
            recipe.ingredients.forEach((ingredient: RecipeIngredientsInner) => {
                _ingredient[count] = ingredient;
                count++;
            });

            // 手順10こ空文字セット
            let _way: string[] = [];
            for (let i = 0; i < 10; i++) {
                _way.push('');
            }
            count = 0;
            recipe.wayOfCooking.forEach((way: string) => {
                _way[count] = way;
                count++;
            });

            // 材料の所を作る
            const _writeIngredients = () => {
                let str: string = '';
                _ingredient.forEach((ing: RecipeIngredientsInner) => {
                    if (
                        !ValidateUtil.isEmptyExact(ing.name)
                        && !ValidateUtil.isEmptyExact(ing.amount)
                        && !ValidateUtil.isEmptyExact(ing.unit)
                    ) {
                        str = `${str}${ing.name},${ing.amount},${ing.unit},\n`;
                    }
                })
                return str;
            }

            // 手順の所を作る
            const _writeWay = () => {
                let str: string = '';
                _way.forEach((w: string, index: number) => {
                    if (!ValidateUtil.isEmptyExact(w)) {
                        str = `${str}${index+1},${w},,\n`;
                    }
                })
                return str;
            }

            const arr: string[][] = [
                ['＜料理名＞', '', '', ''],
                [`${recipe.name}`, '', '', ''],
                ['＜推定調理時間＞', '', '', ''],
                [`${recipe.cookingTime}`, '分', '※仕込み時間抜き', ''],
                ['＜材料＞', '＜使用量＞', '＜単位＞', ''],
                [`${_writeIngredients()}＜手順＞`, '', '', ''],
                [`${_writeWay()}＜コツ・ポイント＞`, '', '', ''],
                [`${recipe.point}`, '', '', ''],
                ['＜メモ＞', '', '', ''],
            ]
            let _csvData: string = 'data:text/csv;charset=utf-8,'; /// 最初にcsvDataに出力方法を追加しておく
            arr.forEach(a => {
                const row = a.join(',');
                _csvData += row + '\r\n';
            });

            CsvUtil.createCsv(
                navigation,
                `${recipe.name}_recipe.csv`,
                _csvData
            );
        } else {
            // アプリ
            // @ts-ignore
            const {REACT_APP_DOMAIN_URL} = getEnvVars();
            LinkUtil.openLinkWithDefaultBrowser(
                `${REACT_APP_DOMAIN_URL}/RecipeDownloadScreen/${recipe.id}`
            );
        }

    }

    /**
     * カテゴリーを連結して返す
     */
    lineProductCategory = (productCategory: ProductCategory[] | undefined) => {
        let str = '';
        if (ValidateUtil.isEmptyArray(productCategory)) {
            return '';
        }

        productCategory!.forEach((category: ProductCategory) => {
            if (str === '') {
                str = `${category.large.name}`;
            } else {
                str = `${str}、${category.large.name}`;
            }

            // if (Array.isArray(category.large.medium)) {
            //     if (!ValidateUtil.isEmptyArray(category.large.medium)) {
            //         category.large.medium!.forEach((medium: ProductCategoryLargeMediumInner) => {
            //             str = `${str}、${medium.name}`;
            //         });
            //     }
            // } else {
            //     // TODO 修正依頼
            //     // @ts-ignore
            //     str = `${str}、${category.large.medium!.name}`;
            // }

        });
        return str;
    };

    /**
     * レビューする、レビュー済み、サンプル出荷待ちの文言を review.responseStatus から取得
     */
    _getReviewStatusLabel = (review: any): '依頼中' | '出荷キャンセル' | 'レビューする' | 'レビュー済み' | null => {

        if (review.responseStatus == 1) {
            return '依頼中';
        }

        if (review.responseStatus == 4) {
            return '出荷キャンセル';
        }

        // responseStatus = 1.未対応 2. 出荷完了 3. レビュー受領 4. 出荷キャンセル）
        if (review.reviewed == 0 && review.responseStatus == 2) {
            // レビューする
            return 'レビューする';
        }

        if (review.responseStatus == 3) {
            return 'レビュー済み';
        }

        return null;
    }

    /**
     * Headerメニューかアラートかの選択
     */
    selectWebMenuSp = () => {
        // WEBの時は menu
        if (Platform.OS == 'web') {
            return 'menu';
        }

        // そうで無いときはアラート
        return 'alert';
    }

    /**
     * SPのHeaderのロゴ
     */
    selectWebLogoSp = () => {

        // WEBの時は logo
        if (Platform.OS == 'web') {
            return 'logo';
        }

        // そうで無いときはアラート
        return 'menu';
    }

    /**
     * 業態名の取得
     */
    getRestaurantFormatName = (id: number | null | undefined, restaurantFormatMasters: RestaurantFormat[]) => {
        if (id == null) {
            return null;
        }

        let r = restaurantFormatMasters.find(rfm => rfm.large.id == id);
        if (r != null) {
            return r.large.name;
        }
        return null;
    }

    /**
     * 季節の取得
     */
    getSeason = () => {
        return [
            {id: 1 , name: '春'},
            {id: 2 , name: '夏'},
            {id: 3 , name: '秋'},
            {id: 4 , name: '冬'},
            {id: 5 , name: 'オールシーズン'},
        ];
    }

    /**
     * 季節名の取得
     */
    getSeasonById = (id: number | null | undefined) => {
        if (id == null) {
            return null;
        }

        let r = this.getSeason().find(s => s.id == id);
        if (r != null) {
            return r.name;
        }
        return null;
    }

}

export default new MyAppUtil();
