import React, {Component} from 'react';
import {Platform, Text, TextProps, TouchableOpacity, View} from 'react-native';
import ExpoUtil from "../util/ExpoUtil";
import {Keyword} from "../data/network/swagger-gen";
import {appColors, appFont, appS} from "../../resources/styles/style";
import AppG from "../util/AppG";
import ScreenSizeUtil from "../util/ScreenSizeUtil";
import ValidateUtil from "../util/ValidateUtil";
import SearchDataDao from "../data/dao/local/SearchDataDao";

interface Props {
    keywords: Keyword[] | null
    onPress: ((keyword: Keyword) => void) | null
    showCount: number | null    // 最大表示数
    width: number | null
    noMargin: boolean
    showIndexName: boolean  // カテゴリの表示
}

interface State { }

export class SearchWordsComponent extends Component<Props, State> {
    // Propsのデフォルト値
    static defaultProps = {
        showCount: null,
        width: null,
        noMargin: false,
        showIndexName: false
    };

    _indexNames: string[] = []; // 既に使ったカテゴリ名
    _keys : Keyword[][] = [];

    componentDidMount() {
        this._keys = [];
        let _k : { [key: string]: Keyword[]; } = {};
        this.props.keywords?.forEach(k => {
            if (k.indexName != null) {
                if (_k[k.indexName] == null) {
                    _k[k.indexName] = [];
                }
                _k[k.indexName].push(k);
            }
        })

        // kKey -> カテゴリ名
        for (let kKey in _k) {
            // keyword をカテゴリ毎に
            this._keys.push(_k[kKey]);
        }
    }


    render() {
        if (ValidateUtil.isEmptyArray(this.props.keywords)) {
            return null;
        }

        let _width = AppG.window.width;
        if (ScreenSizeUtil.isWebSize()) {
            _width = appS.webMainContentsSize.width;
        }
        _width = _width - appS.margins.side * 2;

        if (this.props.width != null) {
            _width = this.props.width - appS.margins.side * 2;
        }

        let _keywordStyle: TextProps = {
            // @ts-ignore
            fontFamily: appFont.family.default,
            fontSize: appFont.size.keywordSize,
            color: appColors.fontBlack,
            textAlign: 'center',
            textAlignVertical: 'center',
            marginTop: Platform.OS == 'android'? -4 : 4,
        };

        let _buttonStyle = {
            height: 27,
            borderRadius: 5,
            margin: appS.margins.side / 2,
            backgroundColor: appColors.keywordBackground,
            width: (_width / 3) - appS.margins.side,
        }

        if (this.props.showIndexName) {



            // カテゴリ表示バージョン
            return ExpoUtil._wrap(
                <View style={{
                    flexDirection: 'row',
                    width: _width,
                    marginLeft: this.props.noMargin? 0 : appS.margins.side,
                    marginRight: this.props.noMargin? 0 : appS.margins.side
                }}>
                    <View style={{flexDirection: 'column'}}>
                        {this._keys?.map((kmap, kindex) => {
                            // カテゴリの表示
                            let _cat = '';
                            if (kmap[0] != null && kmap[0].indexName != null) {
                                _cat = kmap[0].indexName;
                            }

                            return (
                                <View
                                    key={`kmap_${_cat}_${kindex}`}
                                    style={{
                                        flexDirection: 'column',
                                    }}>
                                    {_cat != '' && (
                                        <Text
                                            key={`kmap_text_${_cat}_${kindex}`}
                                            style={{
                                                margin: appS.margins.side,
                                                marginTop: kindex != 0 ? 35 : 0,
                                                marginBottom: 17,
                                                fontFamily: appFont.family.bold,
                                                fontSize: 17,
                                                color: appColors.fontBlack,
                                                fontWeight: "bold"
                                            }}
                                        >{`${_cat}`}</Text>
                                    )}
                                    <View
                                        key={`kmap_view_${_cat}_${kindex}`}
                                        style={{
                                            flexDirection: 'row',
                                        }}>
                                        <View
                                            key={`kmap_view_view_${_cat}_${kindex}`}
                                            style={{flexDirection: 'column'}}>
                                            {kmap?.map((map, index) => {
                                                if (index % 3 != 0) {
                                                    return null;
                                                }
                                                if (this.props.showCount != null && index >= this.props.showCount) {
                                                    return null;
                                                }
                                                return (
                                                    <TouchableOpacity
                                                        key={`kmap_${_cat}_${kindex}_${map.value}_${index}`}
                                                        style={_buttonStyle}
                                                        onPress={() => {
                                                            if (this.props.onPress == null) {
                                                                return null;
                                                            }
                                                            SearchDataDao.addNew(map.value);
                                                            this.props.onPress(map);
                                                        }}
                                                    >
                                                        <Text
                                                            key={`kmap_${_cat}_${kindex}_text_${map.value}_${index}`}
                                                            style={_keywordStyle}
                                                            numberOfLines={1}
                                                            ellipsizeMode='tail'
                                                        >
                                                            {`${map.value}`}
                                                        </Text>
                                                    </TouchableOpacity>
                                                );
                                            })}
                                        </View>
                                        <View
                                            key={`kmap_${_cat}_${kindex}`}
                                            style={{flexDirection: 'column'}}
                                        >
                                            {kmap?.map((map, index) => {
                                                if (index % 3 != 1) {
                                                    return null;
                                                }
                                                if (this.props.showCount != null && index >= this.props.showCount) {
                                                    return null;
                                                }
                                                return (
                                                    <TouchableOpacity
                                                        key={`kmap_${_cat}_${kindex}_${map.value}_${index}`}
                                                        style={_buttonStyle}
                                                        onPress={() => {
                                                            if (this.props.onPress == null) {
                                                                return null;
                                                            }
                                                            SearchDataDao.addNew(map.value);
                                                            this.props.onPress(map);
                                                        }}
                                                    >
                                                        <Text
                                                            key={`kmap_${_cat}_${kindex}_text_${map.value}_${index}`}
                                                            style={_keywordStyle}
                                                            numberOfLines={1}
                                                            ellipsizeMode='tail'
                                                        >
                                                            {`${map.value}`}
                                                        </Text>
                                                    </TouchableOpacity>
                                                );
                                            })}
                                        </View>
                                        <View
                                            key={`kmap_${_cat}_${kindex}`}
                                            style={{flexDirection: 'column'}}
                                        >
                                            {kmap?.map((map, index) => {
                                                if (index % 3 != 2) {
                                                    return null;
                                                }
                                                if (this.props.showCount != null && index >= this.props.showCount) {
                                                    return null;
                                                }
                                                return (
                                                    <TouchableOpacity
                                                        key={`kmap_${_cat}_${kindex}_${map.value}_${index}`}
                                                        style={_buttonStyle}
                                                        onPress={() => {
                                                            if (this.props.onPress == null) {
                                                                return null;
                                                            }
                                                            SearchDataDao.addNew(map.value);
                                                            this.props.onPress(map);
                                                        }}
                                                    >
                                                        <Text
                                                            key={`kmap_${_cat}_${kindex}_text_${map.value}_${index}`}
                                                            style={_keywordStyle}
                                                            numberOfLines={1}
                                                            ellipsizeMode='tail'
                                                        >
                                                            {`${map.value}`}
                                                        </Text>
                                                    </TouchableOpacity>
                                                );
                                            })}
                                        </View>
                                    </View>
                                </View>
                            );
                        })}
                    </View>
                </View>
            );
        }


        return ExpoUtil._wrap(
            <View style={{
                flexDirection: 'row',
                width: _width,
                marginLeft: this.props.noMargin? 0 : appS.margins.side,
                marginRight: this.props.noMargin? 0 : appS.margins.side
            }}>
                <View style={{flexDirection: 'column'}}>
                    {this.props.keywords?.map((map, index) => {
                        if (index % 3 != 0) {
                            return null;
                        }
                        if (this.props.showCount != null && index >= this.props.showCount) {
                            return null;
                        }
                        return (
                            <TouchableOpacity
                                key={`tag_kmap_1_${map.value}_${index}`}
                                style={_buttonStyle}
                                onPress={() => {
                                    if (this.props.onPress == null) {
                                        return null;
                                    }
                                    SearchDataDao.addNew(map.value);
                                    this.props.onPress(map);
                                }}
                            >
                                <Text
                                    key={`tag_kmap_1_text_${map.value}_${index}`}
                                    style={_keywordStyle}
                                    numberOfLines={1}
                                    ellipsizeMode='tail'
                                >
                                    {`${map.value}`}
                                </Text>
                            </TouchableOpacity>
                        );
                    })}
                </View>
                <View style={{flexDirection: 'column'}}>
                    {this.props.keywords?.map((map, index) => {
                        if (index % 3 != 1) {
                            return null;
                        }
                        if (this.props.showCount != null && index >= this.props.showCount) {
                            return null;
                        }
                        return (
                            <TouchableOpacity
                                key={`tag_kmap_2_${map.value}_${index}`}
                                style={_buttonStyle}
                                onPress={() => {
                                    if (this.props.onPress == null) {
                                        return null;
                                    }
                                    SearchDataDao.addNew(map.value);
                                    this.props.onPress(map);
                                }}
                            >
                                <Text
                                    key={`tag_kmap_2_text_${map.value}_${index}`}
                                    style={_keywordStyle}
                                    numberOfLines={1}
                                    ellipsizeMode='tail'
                                >
                                    {`${map.value}`}
                                </Text>
                            </TouchableOpacity>
                        );
                    })}
                </View>
                <View style={{flexDirection: 'column'}}>
                    {this.props.keywords?.map((map, index) => {
                        if (index % 3 != 2) {
                            return null;
                        }
                        if (this.props.showCount != null && index >= this.props.showCount) {
                            return null;
                        }
                        return (
                            <TouchableOpacity
                                key={`tag_kmap_3_${map.value}_${index}`}
                                style={_buttonStyle}
                                onPress={() => {
                                    if (this.props.onPress == null) {
                                        return null;
                                    }
                                    SearchDataDao.addNew(map.value);
                                    this.props.onPress(map);
                                }}
                            >
                                <Text
                                    key={`tag_kmap_3_text_${map.value}_${index}`}
                                    style={_keywordStyle}
                                    numberOfLines={1}
                                    ellipsizeMode='tail'
                                >
                                    {`${map.value}`}
                                </Text>
                            </TouchableOpacity>
                        );
                    })}
                </View>
            </View>
        );
    }
}
