import React, {Component} from 'react';
import {Image, Platform, Text, TouchableOpacity, View} from 'react-native';
import {appColors, appFont, appS} from "../../resources/styles/style";
import AppG from "../util/AppG";
import ExpoUtil from "../util/ExpoUtil";
import ScreenSizeUtil from "../util/ScreenSizeUtil";

interface Props {
    title: string,
    subTitle: string | null,
    require: boolean,
    more: boolean,
    moreText: string,
    onPress: (() => void) | null,
    subTopic: boolean,
    backgroundColor: string,
    notRequire: boolean,
    fontSize: number | null,
    width: number | null,
}


interface State { }

export class TopicTitleComponent extends Component<Props, State> {

    // Propsのデフォルト値
    static defaultProps = {
        require: false,
        more: false,
        moreText: '',
        onPress: null,
        subTopic: false,
        fontSize: null,
        backgroundColor: appColors.transparent,
        subTitle: null,
        notRequire: false,
        width: null,
    };



    render() {
        const {title, more, moreText, onPress, subTopic, backgroundColor, subTitle, notRequire, fontSize, width} = this.props;

        let _margin = appS.margins.side;
        let _width = AppG.window.width;
        if (ScreenSizeUtil.isWebSize()) {
            _width = appS.webMainContentsSize.width;
        }
        if (backgroundColor != appColors.transparent) {
            _width = _width - appS.margins.side * 2;
        }

        if (width != null) {
            _width = width;
        }

        const _height = 40;

        let _fontSize = subTopic? appFont.size.subTopic : appFont.size.topic;
        if (fontSize != null) {
            _fontSize = fontSize
        }

        return ExpoUtil._wrap(
            <View style={{
                flexDirection: "row",
                marginTop: 0,
                // marginBottom: appS.line.titleSpace,
                width: _width,
                height: _height,
                backgroundColor: backgroundColor,
            }}>
                <Text style={{
                    fontFamily: appFont.family.bold,
                    fontWeight: "bold",
                    marginLeft: _margin,
                    fontSize: _fontSize,
                    color: appColors.fontBlack,
                    lineHeight: _height
                }}>{title}</Text>
                {subTitle != null && (
                    <Text style={{
                        fontFamily: appFont.family.bold,
                        fontWeight: "bold",
                        fontSize: 12,
                        color: appColors.fontBlack,
                        lineHeight: _height
                    }}>{subTitle}</Text>
                )}
                <View style={{flex: 1}} />
                {more && (
                    <TouchableOpacity
                        onPress={() => {
                            if (onPress != null) {
                                onPress();
                            }
                        }}
                        disabled={onPress == null}
                    >
                        <View style={{flexDirection: 'row', marginTop: 10}}>
                            <Text style={{
                                fontFamily: appFont.family.bold,
                                fontSize: appFont.size.moreLink,
                                color: moreText == '' ? appColors.actionColor: appColors.fontGray,
                                fontWeight: "bold"
                            }}>{moreText == '' ? `もっと見る`: `${moreText}`}</Text>
                            <Image source={require('../../resources/images/02.menu_general/arrow_O_right.png')}
                                   style={[appS.moreAllow]}
                                   resizeMode='contain'/>
                        </View>
                    </TouchableOpacity>

                )}
                {this.props.require && (
                    <Text style={{marginRight: _margin, fontFamily: appFont.family.default, fontSize: appFont.size.moreLink, color: appColors.red}}>※必須</Text>
                )}
                {notRequire && (
                    <Text style={{
                        marginTop: Platform.OS == 'android'? 2: 13,
                        marginRight: _margin + 45,
                        fontFamily: appFont.family.default,
                        fontSize: appFont.size.moreLink,
                        color: appColors.fontGray
                    }}>※任意入力/複数入力可</Text>
                )}
            </View>
        );
    }
}
