import React, {Component} from 'react';
import {Image, TouchableOpacity, View} from 'react-native';
import {appS} from "../../resources/styles/style";
import {SearchScreenModel} from "../data/models/screen/SearchScreenModel";
import StrUtil from "../util/StrUtil";
import ScreenSizeUtil from "../util/ScreenSizeUtil";
import AppG from "../util/AppG";
import ExpoUtil from "../util/ExpoUtil";

interface Props {
    navigation: any,
    fullFlg: boolean,
    onPress: ((id: string, name: string) => void) | null,
}

interface State { }

export class JobCategoryListComponent extends Component<Props, State> {

    // Propsのデフォルト値
    static defaultProps = {
        onPress: null,
    };

    // 和食、洋食・西洋料理、居酒屋・ダイニングバー、カフェ・喫茶店、焼肉・ホルモン、中華料理、ファミレス、アジア・エスニック、ホテル・旅館、中食
    // , ,
    // メニューカテゴリから探すのデータ
    _recipeCategoryIdsRow1 = [{id: 1, name: '和食', image: require('../../resources/images/job_category/job_category_1.png')}, {id: 2, name: '洋食・西洋料理', image: require('../../resources/images/job_category/job_category_2.png')}, {id: 8, name: '居酒屋・ダイニングバー', image: require('../../resources/images/job_category/job_category_8.png')}];   // 画像を表示するレシピカテゴリ一覧1行目
    _recipeCategoryIdsRow2 = [{id: 13, name: 'カフェ・喫茶店', image: require('../../resources/images/job_category/job_category_13.png')}, {id: 6, name: '焼肉・ホルモン', image: require('../../resources/images/job_category/job_category_6.png')}, {id: 3, name: '中華料理', image: require('../../resources/images/job_category/job_category_3.png')}];
    _recipeCategoryIdsRow3 = [{id: 10, name: 'ファミレス', image: require('../../resources/images/job_category/job_category_10.png')}, {id: 4, name: 'アジア・エスニック', image: require('../../resources/images/job_category/job_category_4.png')}, {id: 22, name: 'ホテル・旅館', image: require('../../resources/images/job_category/job_category_22.png')}];
    _recipeCategoryIdsRow4 = [{id: 26, name: '中食', image: require('../../resources/images/job_category/job_category_26.png')}, {id: 5, name: 'カレーライス', image: require('../../resources/images/job_category/job_category_5.png')}, {id: 7, name: '鍋', image: require('../../resources/images/job_category/job_category_7.png')}];
    _recipeCategoryIdsRow5 = [{id: 9, name: '創作料理・無国籍料理', image: require('../../resources/images/job_category/job_category_9.png')}, {id: 11, name: 'レストランその他', image: require('../../resources/images/job_category/job_category_11.png')}, {id: 12, name: 'ラーメン', image: require('../../resources/images/job_category/job_category_12.png')}];
    _recipeCategoryIdsRow6 = [{id: 14, name: '飲料専門店', image: require('../../resources/images/job_category/job_category_14.png')}, {id: 15, name: 'パン・サンドイッチ', image: require('../../resources/images/job_category/job_category_15.png')}, {id: 16, name: 'スイーツ', image: require('../../resources/images/job_category/job_category_16.png')}];
    _recipeCategoryIdsRow7 = [{id: 17, name: 'バー', image: require('../../resources/images/job_category/job_category_17.png')}, {id: 18, name: 'パブ', image: require('../../resources/images/job_category/job_category_18.png')}, {id: 19, name: 'ラウンジ', image: require('../../resources/images/job_category/job_category_19.png')}];
    _recipeCategoryIdsRow8 = [{id: 20, name: 'ワインバー', image: require('../../resources/images/job_category/job_category_20.png')}, {id: 21, name: 'ビアガーデン', image: require('../../resources/images/job_category/job_category_21.png')}, {id: 23, name: '給食', image: require('../../resources/images/job_category/job_category_23.png')}];
    _recipeCategoryIdsRow9 = [{id: 24, name: '介護老人保健施設', image: require('../../resources/images/job_category/job_category_24.png')}, {id: 25, name: 'ブライダル', image: require('../../resources/images/job_category/job_category_25.png')}, {id: 27, name: 'その他', image: require('../../resources/images/job_category/job_category_27.png')}];

    render() {
        const {navigation, fullFlg} = this.props;

        let _width = AppG.window.width - appS.margins.side * 2;
        let _noUserImageSource = require('../../resources/images/blur/blur_job_category_small.jpg');

        const _rowMargin = appS.margins.side / 3;

        let _cellSizeWidth = (AppG.window.width - appS.margins.side) / 3;
        let _areaHeight = _cellSizeWidth / 1.7 * 3;

        if (fullFlg) {
            _areaHeight = _cellSizeWidth / 1.7 * 9;
            _noUserImageSource = require('../../resources/images/blur/blur_job_category_all.jpg');
        }

        let _style : any = {
            width: AppG.window.width,
            height: _areaHeight,
        };
        if (ScreenSizeUtil.isWebSize()) {
            _width = appS.webMainContentsSize.width -  - appS.margins.side * 2;
            _cellSizeWidth = (appS.webMainContentsSize.width - appS.margins.side) / 3;
            if (fullFlg) {
                _areaHeight = _cellSizeWidth / 1.7 * 6;
            }
            _style = {
                flex: 1,
                width: appS.webMainContentsSize.width,
                height: _areaHeight / 2,
            };
        }

        // 未会員
        if (AppG.user == null) {
            return (
                <TouchableOpacity
                    style={_style}
                    onPress={() => {
                        this.props.navigation.navigate('SignUpScreen');
                    }}
                >
                    <Image
                        source={_noUserImageSource}
                        style={{
                            alignSelf: 'center',
                            width: fullFlg? _width : _width,
                            height: fullFlg? ScreenSizeUtil.isWebSize() ? _width * 1.6 : _width * 1.9 : _width * 0.58,
                        }}
                        resizeMode={'contain'}
                    />
                </TouchableOpacity>
            )
        }

        /**
         * メニューカテゴリから探すのセルを作成
         * @param cellWidth
         * @param recipeCategory: {id: number, name: string, image: any} 画像を表示するカテゴリを渡す
         */
        const _renderRecipeSearchCell = (cellWidth: number, recipeCategory: {id: number, name: string, image: any}) => {
            const _width = cellWidth;
            const _height = cellWidth / 1.7;

            return (
                <View>
                    <TouchableOpacity
                        onPress={() => {
                            if (this.props.onPress == null) {
                                // 画面遷移前にリロード対象にセットする
                                ExpoUtil.setShouldReloadAppTab('self');
                                const { routeName } = navigation.state;
                                let searchScreenModel: SearchScreenModel = new SearchScreenModel();
                                searchScreenModel.menuCategoryId = `${recipeCategory.id}`;
                                searchScreenModel.menuCategoryName = `${StrUtil.removeNewLine(recipeCategory.name)}`;
                                searchScreenModel.headerTitle = `${StrUtil.removeNewLine(recipeCategory.name)}`;
                                searchScreenModel.screenNameFrom = routeName;
                                searchScreenModel.searchType = 'recipe';
                                this.props.navigation.navigate('SearchResultScreen', searchScreenModel);
                            } else {
                                this.props.onPress(`${recipeCategory.id}`, `${StrUtil.removeNewLine(recipeCategory.name)}`);
                            }
                        }}
                    >
                        <Image
                            source={recipeCategory.image}
                            style={[{
                                backgroundColor: 'white',
                                width: _width - appS.margins.side,
                                height: _height - appS.margins.side,
                                margin: appS.margins.side / 2,
                            }]}
                            resizeMode={'cover'}
                            accessibilityLabel={recipeCategory.name}
                        />
                    </TouchableOpacity>
                </View>
            );
        };


        // レシピセルを並べる
        return (
            <View style={_style}>
                <View style={{flexDirection: 'row', marginLeft: _rowMargin, marginRight: _rowMargin}}>
                    {this._recipeCategoryIdsRow1.map((recipeCategory: {id: number, name: string, image: any}, index: number) => {
                        return (<View key={`recipeSearchCell_${index}`}>{_renderRecipeSearchCell(_cellSizeWidth, recipeCategory)}</View>);
                    })}
                </View>
                <View style={{flexDirection: 'row', marginLeft: _rowMargin, marginRight: _rowMargin}}>
                    {this._recipeCategoryIdsRow2.map((recipeCategory: {id: number, name: string, image: any}, index: number) => {
                        return (<View key={`recipeSearchCell_${index}`}>{_renderRecipeSearchCell(_cellSizeWidth, recipeCategory)}</View>);
                    })}
                </View>
                <View style={{flexDirection: 'row', marginLeft: _rowMargin, marginRight: _rowMargin}}>
                    {this._recipeCategoryIdsRow3.map((recipeCategory: {id: number, name: string, image: any}, index: number) => {
                        return (<View key={`recipeSearchCell_${index}`}>{_renderRecipeSearchCell(_cellSizeWidth, recipeCategory)}</View>);
                    })}
                </View>
                {fullFlg && (
                    <View style={{flexDirection: 'row', marginLeft: _rowMargin, marginRight: _rowMargin}}>
                        {this._recipeCategoryIdsRow4.map((recipeCategory: {id: number, name: string, image: any}, index: number) => {
                            return (<View key={`recipeSearchCell_${index}`}>{_renderRecipeSearchCell(_cellSizeWidth, recipeCategory)}</View>);
                        })}
                    </View>
                )}
                {fullFlg && (
                    <View style={{flexDirection: 'row', marginLeft: _rowMargin, marginRight: _rowMargin}}>
                        {this._recipeCategoryIdsRow5.map((recipeCategory: {id: number, name: string, image: any}, index: number) => {
                            return (<View key={`recipeSearchCell_${index}`}>{_renderRecipeSearchCell(_cellSizeWidth, recipeCategory)}</View>);
                        })}
                    </View>
                )}
                {fullFlg && (
                    <View style={{flexDirection: 'row', marginLeft: _rowMargin, marginRight: _rowMargin}}>
                        {this._recipeCategoryIdsRow6.map((recipeCategory: {id: number, name: string, image: any}, index: number) => {
                            return (<View key={`recipeSearchCell_${index}`}>{_renderRecipeSearchCell(_cellSizeWidth, recipeCategory)}</View>);
                        })}
                    </View>
                )}
                {fullFlg && (
                    <View style={{flexDirection: 'row', marginLeft: _rowMargin, marginRight: _rowMargin}}>
                        {this._recipeCategoryIdsRow7.map((recipeCategory: {id: number, name: string, image: any}, index: number) => {
                            return (<View key={`recipeSearchCell_${index}`}>{_renderRecipeSearchCell(_cellSizeWidth, recipeCategory)}</View>);
                        })}
                    </View>
                )}
                {fullFlg && (
                    <View style={{flexDirection: 'row', marginLeft: _rowMargin, marginRight: _rowMargin}}>
                        {this._recipeCategoryIdsRow8.map((recipeCategory: {id: number, name: string, image: any}, index: number) => {
                            return (<View key={`recipeSearchCell_${index}`}>{_renderRecipeSearchCell(_cellSizeWidth, recipeCategory)}</View>);
                        })}
                    </View>
                )}
                {fullFlg && (
                    <View style={{flexDirection: 'row', marginLeft: _rowMargin, marginRight: _rowMargin}}>
                        {this._recipeCategoryIdsRow9.map((recipeCategory: {id: number, name: string, image: any}, index: number) => {
                            return (<View key={`recipeSearchCell_${index}`}>{_renderRecipeSearchCell(_cellSizeWidth, recipeCategory)}</View>);
                        })}
                    </View>
                )}
            </View>
        );
    }
}
