class StrUtil {

    /**
     * param用に連結
     * 「|」区切り、空の時は「||」
     */
    arrayToParamStr = (strArray: string[] | number[] | null | undefined) => {
        let result = '';

        if (strArray == null) {
            return '||';
        }

        strArray.forEach((str: number | string) => {
            result = `${result}|${str}`;
        });

        if (result == '') {
            return '||';
        }

        return result + '|';
    };

    /**
     * 文字のの連結
     */
    arrayToStr = (strArray: string[] | null | undefined) => {
        let result = '';

        if (strArray == null) {
            return '';
        }

        strArray.forEach((str: string) => {
            if (result === '') {
                result = `${str}`;
            } else {
                result = `${result}、${str}`;
            }
        });
        return result;
    };

    /**
     * 全角スペースを半角スペースに変換
     * @param str
     */
    largeSpaceToSmallSpace = (str: string) => {
        return str.replace(/　/g," ");
    };

    /**
     * 改行コードの削除
     * @param str
     */
    removeNewLine = (str: string) => {
        return str.replace(/[\r\n]+/g,"");
    };

    /**
     * http or https を exp に変換する
     * @param url
     */
    changeHttpToExp = (url: string) => {
        return url.replace(/https|http/,"exp");
    }

    /**
     * %20 を + に変換
     * @param url
     */
    changeP20ToPlus = (url: string) => {
        return url.replace(/%20/g,"+");
    }

    /**
     * S3のサブドメインをダウンロード出来る奴に変換
     * @param url
     */
    fixS3Url = (url: string) => {
        return url.replace(/s3\.ap-northeast-1/, "s3-ap-northeast-1");
    }

}

export default new StrUtil();
