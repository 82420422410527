import React from 'react'
import {
    ActivityIndicator,
    Dimensions,
    Image,
    Platform,
    SafeAreaView,
    ScrollView,
    Text,
    TouchableOpacity,
    View
} from 'react-native'
import {appColors, appFont, appS} from '../../resources/styles/style'
import {BaseScreen} from "./BaseScreen";
import LoginUtil from "../util/LoginUtil";
import {IndicatorComponent} from "../components/IndicatorComponent";
// @ts-ignore
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scrollview';
import LoginDataDao from "../data/dao/local/LoginDataDao";
import {LoginDataEntityModel} from "../data/entityModels/LoginDataEntityModel";
import {Product, ProductApiFactory, Recipe, RecipeApiFactory,} from "../data/network/swagger-gen";
import AppG from "../util/AppG";
import {AxiosResponse} from "axios";
import {CustomHeaderComponentSmall} from "../components/small/CustomHeaderComponentSmall";
import UrlUtil from "../util/UrlUtil";
import {SpacerComponent} from "../components/SpacerComponent";
// @ts-ignore
import isURL from 'validator/lib/isURL';
import ValidateUtil from "../util/ValidateUtil";
// @ts-ignore
import {log} from '../../../environment';
import {SearchScreenModel} from "../data/models/screen/SearchScreenModel";
import ExpoUtil from "../util/ExpoUtil";
import ScreenSizeUtil from "../util/ScreenSizeUtil";
import {CustomHeaderComponentWide} from "../components/wide/CustomHeaderComponentWide";
import {WideScreenAdComponent} from "../components/wide/WideScreenAdComponent";
import {NoItemsIconComponent} from "../components/NoItemsIconComponent";
import AnalyticsUtil, {AnalyticsEventName, DOpenEventName} from "../util/firebase/AnalyticsUtil";
import {TabBorderComponent} from "../components/TabBorderComponent";
import {RecipeSearchResultListComponent} from "../components/RecipeSearchResultListComponent";
import {ProductSearchResultListComponent} from "../components/ProductSearchResultListComponent";
import RNPickerSelect from "react-native-picker-select";
import {PullDownIconComponent} from "../components/PullDownIconComponent";
import {WideScreenSNSComponent} from "../components/wide/WideScreenSNSComponent";
import LinkUtil from "../util/LinkUtil";
import Carousel from "react-native-reanimated-carousel";

interface Props {
    navigation: any, // ナビゲーション用
}

interface State {
    resizeWindow: boolean,  // 値に意味はなし。windowサイズの拡大縮小をrenderに送るためのもの
    isLoaded: boolean,

    // コンテンツ
    headerTitle: string,
    isLoadResultRecipes: boolean,    // 検索結果レシピロード中
    resultRecipes: Recipe[],   // 検索結果レシピ
    countRecipes: number,

    isLoadResultProducts: boolean,    // 検索結果レシピロード中
    resultProducts: Product[],   // 検索結果商品
    countProducts: number,

    // 並び替え
    recipeSortType: 'recommend' | 'new' | 'pv' | null,
    productSortType: 'recommend' | 'new' | 'pv' | 'update' | null,
}

export class SearchResultScreen extends BaseScreen<Props, State> {
    _resizeTimer = setInterval(() => {
        // window 拡大縮小を反映させる
        AppG.window = Dimensions.get('window');
        if (AppG.window.width != AppG.windowBefore.width || AppG.window.height != AppG.windowBefore.height) {
            this.setState({'resizeWindow': true});
            AppG.windowBefore = Dimensions.get('window');
        }
        LoginUtil.setRefreshedScreenFlg(false);
    }, 100);

    _loginData = new LoginDataEntityModel();

    _flameSize = ScreenSizeUtil.isWebSize()? AppG.window.width / 10 : 16;    // 左右の margin
    _marginSize = ScreenSizeUtil.isWebSize()? 0 : appS.margin.size;

    // このScreenへ渡すパラム
    _param: SearchScreenModel = new SearchScreenModel();
    _contentsLoadCount = 20; // コンテンツの一度の読み込み数
    _searchText = ''; // 検索文字

    // タブ
    _tabClicked = false;
    _tabIndex = 0;
    _searchType: 'recipe' | 'product' | 'all' = 'all';
    _tabPage: any = null;
    /** tab 本体 */
    _tabRef: any = null;
    _tabPress = (tabNumber: number) => {
        if (tabNumber == 0) {
            // レシピ
            AppG.screenHistory.unshift(`【検索結果画面】${this._tabs[tabNumber].title}タブ`);
            this._searchType = 'recipe';
            if (ValidateUtil.isEmptyArray(this.state.resultRecipes)) {
                this._tabChangeSearch(true);
            }
        } else if (tabNumber == 1) {
            // 商品
            AppG.screenHistory.unshift(`【検索結果画面】${this._tabs[tabNumber].title}タブ`);
            this._searchType = 'product';
            if (ValidateUtil.isEmptyArray(this.state.resultProducts)) {
                this._tabChangeSearch(true);
            }
        }

        if (this._tabIndex == tabNumber) {
            return;
        }

        this._tabIndex = tabNumber;

        this._tabClicked = true;
        if (this._tabPage != null) {
            if (this._tabRef != null) {
                this._tabRef.redrawView(tabNumber);
            }
            this._tabPage.scrollTo({index: tabNumber, animated: false});
        }

        setTimeout(() => {this.setState({resizeWindow: true});}, 10);
        setTimeout(() => {this.setState({resizeWindow: true});}, 10);
    };
    _tabs: Tab[] = [
        {
            index: 0,    // 0 〜
            title: 'レシピ',
            onPress: () => {this._tabPress(0)},
            color: appColors.recipeHeader,
            unselectColor: appColors.tabGray,
            backgroundColor: appColors.white,
            unselectBackgroundColor: appColors.white,
            badge: 0,
        },
        {
            index: 1,    // 0 〜
            title: '商品',
            onPress: () => {this._tabPress(1)},
            color: appColors.productHeader,
            unselectColor: appColors.tabGray,
            backgroundColor: appColors.white,
            unselectBackgroundColor: appColors.white,
            badge: 0,
        },
    ];

    constructor(props: any) {
        super(props);
        this.state = {
            resizeWindow: true,  // 値に意味はなし。windowサイズの拡大縮小をrenderに送るためのもの
            isLoaded: false,
            headerTitle: '',
            isLoadResultRecipes: false,
            resultRecipes: [],
            countRecipes: 0,
            isLoadResultProducts: false,
            resultProducts: [],
            countProducts: 0,
            recipeSortType: 'pv',
            productSortType: 'recommend',
        };
    }

    /**
     * 画面開いたときのアナリティクス送信（レシピ）
     */
    _sendPvAnalyticsRecipe = () => {
        AnalyticsUtil.sendAnalytics(
            DOpenEventName.openPage,
            AnalyticsEventName.openPage,  // アナリティクスのイベント名
            'SearchRecipeResultScreen',
            null,
            null,
            null,
            null,
        );
    }

    /**
     * タブ切り替え時のアナリティクス送信（商品）
     */
    _sendPvAnalyticsProduct = () => {
        AnalyticsUtil.sendAnalytics(
            DOpenEventName.openPage,
            AnalyticsEventName.openPage,  // アナリティクスのイベント名
            'SearchProductResultScreen',
            null,
            null,
            null,
            null,
        );
    }


    componentDidMount() {
        this._param = this._getParams();
        this._searchType = this._param.searchType == null ? 'all' : this._param.searchType;

        if (!ValidateUtil.isEmptyExact(this._param.recipeSortType)) {
            this.setState({recipeSortType: this._param.recipeSortType});
        }

        if (!ValidateUtil.isEmptyExact(this._param.productSortType)) {
            this.setState({productSortType: this._param.productSortType});
        }

        if (Platform.OS == 'web') {
            setTimeout(() => {
                if (this._searchType == 'product') {
                    this._tabPress(1);
                } else {
                    this._tabPress(0);
                }
            }, 100);
        }

        LoginUtil.interruptOpenScreen(this.props.navigation,
            () => {
                LoginDataDao.get().then(loginData => {
                    // Web 戻るでも呼ばれる
                    if (Platform.OS == 'web') {
                        // Analytics
                        if (this._searchType == 'product') {
                            this._sendPvAnalyticsProduct();
                        } else {
                            this._sendPvAnalyticsRecipe();
                        }
                    }
                    AppG.getFooterBadge();


                    // URL Scheme で外部リンクから起動したときの処理と、画面遷移でこの画面を開いたときの処理
                    LoginUtil.setDefaultListener(this.props.navigation, () => {
                        // Webはここにこない
                        if (Platform.OS != 'web') {
                            // Analytics
                            if (this._searchType == 'product') {
                                this._sendPvAnalyticsProduct();
                            } else {
                                this._sendPvAnalyticsRecipe();
                            }

                            setTimeout(() => {
                                if (this._searchType == 'product') {
                                    this._tabPress(1);
                                } else {
                                    this._tabPress(0);
                                }
                            }, 100);
                        }
                    });
                    if (loginData != null) {
                        this._loginData = loginData;
                    }
                    this._loadMasters().then(() => {this._loadContents().then(() => {
                        this._tabChangeSearch(true);
                        this.setState({'isLoaded': true});
                        setTimeout(() => {this.setState({resizeWindow: true});}, 100);
                        setTimeout(() => {this.setState({resizeWindow: true});}, 100);
                    })});
                    this.setState({headerTitle: !ValidateUtil.isEmptyExact(this._param.headerTitle) ? this._param.headerTitle! : ''});
                });
            });
    }

    componentWillUnmount () {
        clearTimeout(this._resizeTimer);
        LoginUtil.removeDefaultListener(this.props.navigation);
    };

    componentDidUpdate () {
        // レンダー完了時に呼ばれる
    }

    /**
     * パラムの取得
     * @private
     */
    _getParams = () => {
        let searchScreenModel: SearchScreenModel = UrlUtil.getParamModel(new SearchScreenModel(), this.props.navigation);
        return searchScreenModel;
    };

    /**
     * マスターのロード
     * @private
     */
    _loadMasters = async () => {
    };

    /**
     * コンテンツのロード
     * @private
     */
    _loadContents = async () => {
    }

    /**
     * タブ変更
     * @param resetFlg
     */
    _tabChangeSearch = (resetFlg: boolean) => {
        if (!ValidateUtil.isEmptyExact(this._param.searchValue)) {
            // フリーワード検索する
            this._searchText = this._param.searchValue!;
            this.setState({headerTitle: this._param.searchValue!});
        }

        if (!this.state.isLoadResultRecipes && (this._searchType == 'recipe' || this._searchType == 'all')) {
            this._searchRecipe(0, this._contentsLoadCount, resetFlg);
        }

        if (!this.state.isLoadResultProducts && (this._searchType == 'product' || this._searchType == 'all')) {
            this._searchProduct(0, this._contentsLoadCount, resetFlg);
        }
    }

    /**
     * 検索
     * @param text
     */
    _searchSubmit = (text: string) => {
        this._searchText = text;
        let _headerTitle = '検索結果';
        if (!ValidateUtil.isEmptyExact(this._searchText)) {
            _headerTitle = this._searchText;
        }
        this.setState({headerTitle: this._searchText});
        this._param.headerTitle = this._searchText;
        if (this._searchType == 'product') {
            this._searchProduct(0, this._contentsLoadCount, true);
        } else {
            this._searchRecipe(0, this._contentsLoadCount, true);
        }
    };

    /**
     * レシピ検索
     * @param from
     * @param to
     * @param resetFlg
     * @param sortChangeFlg
     */
    _searchRecipe = async (from: number, to: number, resetFlg: boolean, sortChangeFlg: boolean = false) => {
        if (this.state.isLoadResultRecipes) {
            return;
        }
        this.setState({isLoadResultRecipes: true});

        const _freeWord = this._searchText.trim();

        const _makerId: string | null = this._param.makerId;
        const _menuCategoryId: string | null = this._param.menuCategoryId;
        const _keyword: string | null = this._param.keyword;
        const _costFrom: string | null = this._param.costFrom;
        const _costTo: string | null = this._param.costTo;
        const _productId: string | null = this._param.productId;
        const _sortType: 'recommend' | 'new' | 'pv' | 'update' | null = this.state.recipeSortType;
        const _cookingTime: string | null = this._param.cookingTime;
        const _recipeRestaurantLargeFormatIds: string | null = this._param.recipeRestaurantLargeFormatIds;
        const _seasonIds: string | null = this._param.seasonIds;
        const _recipeCategoryIds: string | null = this._param.recipeCategoryIds;

        if (!sortChangeFlg) {
            // ソート時はアナリティクス送らない

            if (resetFlg && this._searchType == 'all') {
                // all はフリーワードだけ
                // Analytics
                AnalyticsUtil.sendAnalytics(
                    DOpenEventName.searchRecipe,
                    AnalyticsEventName.searchRecipe,  // アナリティクスのイベント名
                    'SearchRecipeResultScreen',
                    null,
                    null,
                    null,
                    `freeWord=${_freeWord}`,
                );
            } else if (resetFlg) {
                // Analytics
                AnalyticsUtil.sendAnalytics(
                    DOpenEventName.searchRecipe,
                    AnalyticsEventName.searchRecipe,  // アナリティクスのイベント名
                    'SearchRecipeResultScreen',
                    null,
                    null,
                    null,
                    `freeWord=${_freeWord}&keyword=${_keyword}&costFrom=${_costFrom}&costTo=${_costTo}&productId=${_productId}&recipeRestaurantLargeFormatIds=${_recipeRestaurantLargeFormatIds}&seasonIds=${_seasonIds}&recipeCategoryIds=${_recipeCategoryIds}&sortType=${_sortType}&makerId=${_makerId}&menuCategoryId=${_menuCategoryId}`,
                );
            }
        }

        // レシピ検索
        let _resultRecipes: Recipe[] = [];
        if (!resetFlg) {
            _resultRecipes = this.state.resultRecipes;
        } else {
            this.setState({resultRecipes: []});
        }

        const countResultRecipe = RecipeApiFactory(AppG.getConfiguration(), AppG.getBasePath())
            .countRecipes(
                _freeWord,
                _makerId == null? undefined : parseInt(_makerId),
                undefined,
                _menuCategoryId == null? undefined : parseInt(_menuCategoryId), // レシピカテゴリー（1個）
                _keyword == null? undefined : _keyword,
                _costFrom == null? undefined : _costFrom,
                _costTo == null? undefined : _costTo,
                _productId == null? undefined : parseInt(_productId),
                _sortType == 'recommend'? 1 :undefined,
                _recipeRestaurantLargeFormatIds == null ? undefined : _recipeRestaurantLargeFormatIds,  // 業態
                _seasonIds == null ? undefined : _seasonIds,    // 季節
                _recipeCategoryIds == null ? undefined : _recipeCategoryIds,    // レシピカテゴリー（複数）
            ).then((countRes) => {
                if (countRes != null) {
                    if (countRes.data != null && countRes.data != null) {
                        this.setState({countRecipes: countRes.data});
                    }
                }
            });

        const getResultRecipe = RecipeApiFactory(AppG.getConfiguration(), AppG.getBasePath())
            .findRecipes(
                from,
                to,
                _freeWord,
                _makerId == null? undefined : parseInt(_makerId),
                undefined,
                _menuCategoryId == null? undefined : parseInt(_menuCategoryId), // レシピカテゴリー（1個）
                _keyword == null? undefined : _keyword,
                _costFrom == null? undefined : _costFrom,
                _costTo == null? undefined : _costTo,
                _productId == null? undefined : parseInt(_productId),
                undefined,
                4,
                undefined,
                _sortType == null? undefined : _sortType,
                _cookingTime == null? undefined : parseInt(_cookingTime),
                _recipeRestaurantLargeFormatIds == null ? undefined : _recipeRestaurantLargeFormatIds,  // 業態
                _seasonIds == null ? undefined : _seasonIds,    // 季節
                _recipeCategoryIds == null ? undefined : _recipeCategoryIds,    // レシピカテゴリー（複数）
            ).then((resultRecipesRes: AxiosResponse<Recipe[]>) => {
                if (resultRecipesRes != null) {
                    if (!ValidateUtil.isEmptyArray(resultRecipesRes.data)) {
                        resultRecipesRes.data.forEach((recipe: Recipe) => {
                            _resultRecipes.push(recipe);
                        });
                        this.setState({resultRecipes: _resultRecipes});
                    }
                    this.setState({isLoadResultRecipes: false});
                }
            });

        // 実行
        await Promise.all([countResultRecipe, getResultRecipe]);
    }

    /**
     * 商品検索
     * @param from
     * @param to
     * @param resetFlg
     * @param sortChangeFlg
     */
    _searchProduct = async (from: number, to: number, resetFlg: boolean, sortChangeFlg: boolean = false) => {
        if (this.state.isLoadResultProducts) {
            return;
        }
        // 商品検索
        this.setState({isLoadResultProducts: true});

        const _freeWord = this._searchText.trim();

        const _mediumCategoryId: string | null = this._param.productMediumCategoryId;
        const _makerId: string | null = this._param.makerId;
        const _sortType: 'recommend' | 'new' | 'pv' | 'update' | null = this.state.productSortType;
        const _keyword: string | null = this._param.keyword;
        const _makerIds: string | null = this._param.makerIds;
        const _productLargeCategoryIds: string | null = this._param.productLargeCategoryIds;

        if (!sortChangeFlg) {
            // ソート時はアナリティクス送らない

            if (resetFlg && this._searchType == 'all') {
                // Analytics
                AnalyticsUtil.sendAnalytics(
                    DOpenEventName.searchProduct,
                    AnalyticsEventName.searchProduct,  // アナリティクスのイベント名
                    'SearchProductResultScreen',
                    null,
                    null,
                    null,
                    `freeWord=${_freeWord}`,
                );
            } else if (resetFlg) {
                // all 以外
                // Analytics
                AnalyticsUtil.sendAnalytics(
                    DOpenEventName.searchProduct,
                    AnalyticsEventName.searchProduct,  // アナリティクスのイベント名
                    'SearchProductResultScreen',
                    null,
                    null,
                    null,
                    `freeWord=${_freeWord}&keyword=${_keyword}&makerIds=${_makerIds}&productLargeCategoryIds=${_productLargeCategoryIds}&sortType=${_sortType}&makerId=${_makerId}&mediumCategoryId=${_mediumCategoryId}`,
                );
            }
        }

        let _resultProducts: Product[] = [];
        if (!resetFlg) {
            _resultProducts = this.state.resultProducts;
        } else {
            this.setState({resultProducts: []});
        }

        const countResultProduct = ProductApiFactory(AppG.getConfiguration(), AppG.getBasePath())
            .countProducts(
                _mediumCategoryId == null? undefined : parseInt(_mediumCategoryId),
                _makerId == null? undefined : parseInt(_makerId),
                _freeWord,
                _sortType == 'recommend'? 1 :undefined,
                _makerIds == null? undefined : _makerIds,
                _productLargeCategoryIds == null? undefined : _productLargeCategoryIds,
                _keyword == null? undefined : _keyword,
            ).then((countRes) => {
                if (countRes != null) {
                    if (countRes.data != null && countRes.data != null) {
                        this.setState({countProducts: countRes.data});
                    }
                }
            });

        const getResultProduct = ProductApiFactory(AppG.getConfiguration(), AppG.getBasePath())
            .findProducts(
                from,
                to,
                undefined,
                undefined,
                _mediumCategoryId == null? undefined : parseInt(_mediumCategoryId),
                _makerId == null? undefined : parseInt(_makerId),
                _freeWord,
                4,
                undefined,
                _sortType == null? undefined : _sortType,
                _keyword == null? undefined : _keyword,
                _makerIds == null? undefined : _makerIds,
                _productLargeCategoryIds == null? undefined : _productLargeCategoryIds,
            ).then((resultProductsRes: AxiosResponse<Product[]>) => {
                if (resultProductsRes != null) {
                    if (!ValidateUtil.isEmptyArray(resultProductsRes.data)) {
                        resultProductsRes.data.forEach((product: Product) => {
                            _resultProducts.push(product);
                        });
                        this.setState({resultProducts: _resultProducts});
                    }
                    this.setState({isLoadResultProducts: false});
                }
            })

        // 実行
        await Promise.all([countResultProduct, getResultProduct]);
    }

    /**
     * 検索画面に戻る
     */
    _goSearchScreen = () => {
        let searchScreenModel: SearchScreenModel = new SearchScreenModel();

        searchScreenModel.searchType = this._searchType;
        searchScreenModel.tabHidden = this._param.tabHidden;
        searchScreenModel.searchValue = this._param.searchValue;
        searchScreenModel.headerTitle = this._param.headerTitle;
        searchScreenModel.menuCategoryId = this._param.menuCategoryId;
        searchScreenModel.menuCategoryName = this._param.menuCategoryName;
        searchScreenModel.productLargeCategoryId = this._param.productLargeCategoryId;
        searchScreenModel.productMediumCategoryId = this._param.productMediumCategoryId;
        searchScreenModel.productLargeCategoryName = this._param.productLargeCategoryName;
        searchScreenModel.productMediumCategoryName = this._param.productMediumCategoryName;
        searchScreenModel.makerId = this._param.makerId;
        searchScreenModel.keyword = this._param.keyword;
        searchScreenModel.costFrom = this._param.costFrom;
        searchScreenModel.costTo = this._param.costTo;
        searchScreenModel.productId = this._param.productId;
        searchScreenModel.recipeRestaurantLargeFormatIds = this._param.recipeRestaurantLargeFormatIds;
        searchScreenModel.seasonIds = this._param.seasonIds;
        searchScreenModel.recipeCategoryIds = this._param.recipeCategoryIds;
        searchScreenModel.makerIds = this._param.makerIds;
        searchScreenModel.productLargeCategoryIds = this._param.productLargeCategoryIds;
        searchScreenModel.recipeSortType = this.state.recipeSortType;
        searchScreenModel.productSortType = this.state.productSortType;
        searchScreenModel.cookingTime = this._param.cookingTime;

        if (Platform.OS != 'web' && this._param.screenNameFrom == 'SearchScreen') {
            ExpoUtil.goBack(this.props.navigation);
            return;
        }

        if (Platform.OS == 'web') {
            this.props.navigation.navigate('SearchScreen', searchScreenModel);
        } else {
            this.props.navigation.push('SearchScreen', searchScreenModel);
        }

    }

    /**
     * フリーワード検索
     */
    _freeWordSearch = async (from: number, to: number, resetFlg: boolean, activeTabSlide: number) => {
        if (activeTabSlide == 0) {
            this._searchRecipe(from, to, resetFlg);
            return;
        }

        this._searchProduct(from, to, resetFlg);
    }

    /**
     * タブページの中身
     * @param index
     */
        // @ts-ignore
    _renderPageItems = ({item}) => {
        let index: number = item;
        switch (index) {
            case 0:
                return this._renderSearchResultRecipe();
            case 1:
                return this._renderSearchResultProduct();
            default:
                return this._renderSearchResultRecipe()
        }
    }

    /**
     * フリーワード検索結果（レシピ）の表示
     */
    _renderSearchResultRecipe = () => {
        let _width = AppG.window.width;
        if (ScreenSizeUtil.isWebSize()) {
            _width = appS.webMainContentsSize.width;
        }

        let _pickerWidth = Platform.OS == 'android'? 150 : 130;
        const _pickerHeight = Platform.OS == 'android'? 30 : 22;

        if (ScreenSizeUtil.isWebSize()) {
            _pickerWidth = 180;
        }


        const _renderHeader = () => {
            return (
                <View>
                    {ScreenSizeUtil.isWebSize() && !this._param.tabHidden && (
                        <TabBorderComponent
                            ref={(tabRef: any) => { this._tabRef = tabRef; }}
                            tabs={this._tabs}
                            focusTabNumber={this._tabIndex}
                            unselectBorder={true}
                            button={true}
                        />
                    )}
                    <View style={{flexDirection: "row", width: _width, margin: appS.margins.side, marginBottom: Platform.OS == 'android'? 0 : 8}}>
                        <View style={{width: _pickerWidth, height: _pickerHeight, borderWidth: Platform.OS == 'android'? 1 : 0, borderColor: appColors.borderGray}}>
                            <View style={{marginTop: Platform.OS == 'android'? -13 : 0}}>
                                <RNPickerSelect
                                    style={appS.sortPicker}
                                    // placeholder={{ label: '¥0', value: 0, key: 0 }}
                                    placeholder={{}}
                                    onValueChange={(value) => {
                                        this.setState({recipeSortType: value});
                                        setTimeout(() => {
                                            this._searchRecipe(0, this._contentsLoadCount, true, true);
                                        }, 100);
                                    }}
                                    value={this.state.recipeSortType}
                                    // sortType: 'recommend' | 'new' | 'pv' | 'update' | null,
                                    items={[
                                        { label: 'おすすめ順', value: 'recommend', key: 0 },
                                        { label: '新着順', value: 'new', key: 1 },
                                        { label: 'PV数順', value: 'pv', key: 2 },
                                    ]}
                                    Icon={() => (<View style={{marginTop: Platform.OS == 'android'? -8 : -10}}><PullDownIconComponent/></View>)}
                                />
                            </View>
                        </View>
                        <Text style={{fontFamily: appFont.family.default, fontSize: 11, marginTop: ScreenSizeUtil.isWebSize()? 15 : (Platform.OS == 'android'? 0 : 5)}}>で並び替え</Text>
                        <View style={{flex: 1}} />
                        <Image source={require('../../resources/images/05.search/i_search_filter.png')}
                               style={{
                                   alignSelf: 'center',
                                   width: 11,
                                   height: 11,
                                   marginTop: Platform.OS == 'android'? 0 : 4
                               }}
                        />
                        <TouchableOpacity
                            onPress={() => {
                                this._searchType = 'recipe';
                                this._goSearchScreen();
                            }}
                        >
                            <Text style={{
                                fontFamily: appFont.family.bold,
                                fontSize: 12,
                                marginTop: ScreenSizeUtil.isWebSize()? 8 : (Platform.OS == 'android'? 8 : 4),
                                marginRight: appS.margins.side * 2,
                                fontWeight: "bold"
                            }}>絞り込み条件を変更</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            )
        }

        return (
            <View style={[{flex: 1, flexDirection: 'column', width: AppG.window.width}]}>

                {!ScreenSizeUtil.isWebSize() && _renderHeader()}

                <KeyboardAwareScrollView
                    style={[{flex: 1, flexDirection: 'column'}]}
                    onScroll={(event: any) => {
                        // 無限スクロール
                        // スクロールViewの高さ
                        const scrollViewHeight = event.nativeEvent.contentSize.height - event.nativeEvent.layoutMeasurement.height;
                        // スクロールView上の現在位置
                        const nowPositionHeight = event.nativeEvent.contentOffset.y;
                        // 現在の位置がスクロールView + activityIndicator の高さよりも下の場合
                        const indicatorHeight = Platform.OS == 'web'? -10: -appS.activityIndicator.height;
                        if (!this.state.isLoadResultRecipes && this.state.resultRecipes.length >= this._contentsLoadCount - 1 && nowPositionHeight - scrollViewHeight >  indicatorHeight) {
                            this._freeWordSearch(this.state.resultRecipes.length, this.state.resultRecipes.length + this._contentsLoadCount, false, 0);
                        }
                    }}
                >
                    <View
                        // activeOpacity={1}
                        style={[{flex: 1, flexDirection: ScreenSizeUtil.isWebSize()? 'row': 'column', alignSelf: 'center'}]}>

                        <View style={[{flexDirection: 'column'}]}>
                            <View style={{width: _width}}>
                                {ScreenSizeUtil.isWebSize() && _renderHeader()}

                                {ScreenSizeUtil.isWebSize() && (<SpacerComponent height={appS.margins.side}/>)}

                                {/*一覧*/}
                                <RecipeSearchResultListComponent
                                    navigation={this.props.navigation}
                                    recipes={this.state.resultRecipes}
                                    countRecipes={this.state.countRecipes}
                                />


                                {!this.state.isLoadResultRecipes && ValidateUtil.isEmptyArray(this.state.resultRecipes) && (
                                    <NoItemsIconComponent text={'レシピが見つかりませんでした'}/>
                                )}
                            </View>
                            {/*ローディングインジケーター*/}
                            <View style={{marginTop: 32, height: appS.activityIndicator.height}}>
                                <ActivityIndicator
                                    animating={this.state.isLoadResultRecipes}
                                    color = {appColors.indicator}
                                    size = "large"
                                    style={[{flex: 1}]}/>
                            </View>
                            <SpacerComponent height={160}/>
                        </View>
                        {ScreenSizeUtil.isWebSize() && (
                            <View style={{marginLeft: appS.margins.webBetweenMargin, width: appS.webSideContentsSize.width}}>
                                <WideScreenAdComponent navigation={this.props.navigation}/>
                                <WideScreenSNSComponent navigation={this.props.navigation}/>
                            </View>
                        )}
                    </View>
                </KeyboardAwareScrollView>
            </View>
        );
    }

    /**
     * フリーワード検索結果（商品）の表示
     */
    _renderSearchResultProduct = () => {
        let _width = AppG.window.width;
        if (ScreenSizeUtil.isWebSize()) {
            _width = appS.webMainContentsSize.width;
        }

        let _pickerWidth = Platform.OS == 'android'? 150 : 130;
        const _pickerHeight = Platform.OS == 'android'? 30 : 22;

        if (ScreenSizeUtil.isWebSize()) {
            _pickerWidth = 180;
        }

        const _renderHeader = () => {
            return (
                <View>
                    {ScreenSizeUtil.isWebSize() && !this._param.tabHidden && (
                        <TabBorderComponent
                            ref={(tabRef: any) => { this._tabRef = tabRef; }}
                            tabs={this._tabs}
                            focusTabNumber={this._tabIndex}
                            unselectBorder={true}
                            button={true}
                        />
                    )}
                    <View style={{flexDirection: "row", width: _width, margin: appS.margins.side, marginBottom: Platform.OS == 'android'? 0 : 8}}>
                        <View style={{width: _pickerWidth, height: _pickerHeight, borderWidth: Platform.OS == 'android'? 1 : 0, borderColor: appColors.borderGray}}>
                            <View style={{marginTop: Platform.OS == 'android'? -13 : 0}}>
                                <RNPickerSelect
                                    style={appS.sortPicker}
                                    // placeholder={{ label: '¥0', value: 0, key: 0 }}
                                    placeholder={{}}
                                    onValueChange={(value) => {
                                        this.setState({productSortType: value});
                                        setTimeout(() => {
                                            this._searchProduct(0, this._contentsLoadCount, true, true);
                                        }, 100);
                                    }}
                                    value={this.state.productSortType}
                                    items={[
                                        { label: 'おすすめ順', value: 'recommend', key: 0 },
                                        { label: '新着順', value: 'new', key: 1 },
                                        { label: 'PV数順', value: 'pv', key: 2 },
                                        { label: '更新順', value: 'update', key: 3 },
                                    ]}
                                    Icon={() => (<View style={{marginTop: Platform.OS == 'android'? -8 : -10}}><PullDownIconComponent/></View>)}
                                />
                            </View>
                        </View>
                        <Text style={{fontFamily: appFont.family.default, fontSize: 11, marginTop: ScreenSizeUtil.isWebSize()? 15 : (Platform.OS == 'android'? 0 : 5)}}>で並び替え</Text>
                        <View style={{flex: 1}} />
                        <Image source={require('../../resources/images/05.search/i_search_filter.png')}
                               style={{
                                   alignSelf: 'center',
                                   width: 11,
                                   height: 11,
                                   marginTop: Platform.OS == 'android'? 0 : 4
                               }}
                        />
                        <TouchableOpacity
                            onPress={() => {
                                this._searchType = 'product';
                                this._goSearchScreen();
                            }}
                        >
                            <Text style={{
                                fontFamily: appFont.family.bold,
                                fontSize: 12,
                                marginTop: ScreenSizeUtil.isWebSize()? 8 : (Platform.OS == 'android'? 8 : 4),
                                marginRight: appS.margins.side * 2,
                                fontWeight: "bold"
                            }}>絞り込み条件を変更</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            )
        }

        return (
            <View style={[{flex: 1, flexDirection: 'column', width: AppG.window.width}]}>

                {!ScreenSizeUtil.isWebSize() && _renderHeader()}

                <KeyboardAwareScrollView
                    style={[{flex: 1, flexDirection: 'column'}]}
                    onScroll={(event: any) => {
                        // 無限スクロール
                        // スクロールViewの高さ
                        const scrollViewHeight = event.nativeEvent.contentSize.height - event.nativeEvent.layoutMeasurement.height;
                        // スクロールView上の現在位置
                        const nowPositionHeight = event.nativeEvent.contentOffset.y;
                        // 現在の位置がスクロールView + activityIndicator の高さよりも下の場合
                        const indicatorHeight = Platform.OS == 'web'? -10: -appS.activityIndicator.height;
                        if (!this.state.isLoadResultProducts && this.state.resultProducts.length >= this._contentsLoadCount - 1 && nowPositionHeight - scrollViewHeight >  indicatorHeight) {
                            this._freeWordSearch(this.state.resultProducts.length, this.state.resultProducts.length + this._contentsLoadCount, false, 1);
                        }
                    }}
                >
                    <View
                        // activeOpacity={1}
                        style={[{flex: 1, flexDirection: ScreenSizeUtil.isWebSize()? 'row': 'column', alignSelf: 'center'}]}>
                        <View style={[{flexDirection: 'column'}]}>
                            <View style={{width: _width}}>
                                {ScreenSizeUtil.isWebSize() && _renderHeader()}

                                {ScreenSizeUtil.isWebSize() && (<SpacerComponent height={appS.margins.side}/>)}

                                {/*一覧*/}
                                <ProductSearchResultListComponent
                                    navigation={this.props.navigation}
                                    products={this.state.resultProducts}
                                    countProducts={this.state.countProducts}
                                />

                                {!this.state.isLoadResultProducts && ValidateUtil.isEmptyArray(this.state.resultProducts) && (
                                    <NoItemsIconComponent text={'商品が見つかりませんでした'}/>
                                )}

                            </View>
                            {/*ローディングインジケーター*/}
                            <View style={{flex: 1, marginTop: 32, height: appS.activityIndicator.height}}>
                                <ActivityIndicator
                                    animating={this.state.isLoadResultProducts}
                                    color = {appColors.indicator}
                                    size = "large"
                                    style={[{flex: 1}]}/>
                            </View>
                            <SpacerComponent height={160}/>
                        </View>
                        {ScreenSizeUtil.isWebSize() && (
                            <View style={{marginLeft: appS.margins.webBetweenMargin, width: appS.webSideContentsSize.width}}>
                                <WideScreenAdComponent navigation={this.props.navigation}/>
                                <WideScreenSNSComponent navigation={this.props.navigation}/>
                            </View>
                        )}
                    </View>
                </KeyboardAwareScrollView>
            </View>
        );
    }

    _renderWide = () => {
        const {navigation} = this.props;
        return (
            <SafeAreaView
                style={[{height: AppG.window.height, flex: 1, flexDirection: 'column', backgroundColor: appColors.white}]}
                onLayout={(event) => {}}
            >
                <CustomHeaderComponentWide
                    navigation={navigation}
                    searchBar={true}
                    onSearchSubmit={(text: string) => {this._searchSubmit(text)}}
                    searchValue={ValidateUtil.isEmptyExact(this._param.searchValue)? '': this._param.searchValue!}
                />

                <View
                    style={{
                        flex: 1,
                        flexDirection: 'row',
                        alignSelf: 'center',
                        backgroundColor: appColors.backBaseColor,
                    }}>

                    {/*タブコンテンツ*/}
                    <ScrollView
                        horizontal
                        style={{width: AppG.window.width, height: AppG.window.height - appS.header.webHeight}}
                    >
                        {/*フリーワード検索結果レシピの表示*/}
                        {this._tabIndex == 0 && this._renderSearchResultRecipe()}
                        {/*フリーワード検索結果商品の表示*/}
                        {this._tabIndex == 1 && this._renderSearchResultProduct()}
                    </ScrollView>
                </View>
            </SafeAreaView>
        );
    }


    _renderSmall = () => {
        return (
            // appStyles.statusBarHiddenSafeAreaでステータスバー分上に上げる
            <SafeAreaView
                style={[{height: AppG.window.height, flex: 1, flexDirection: 'column', backgroundColor: appColors.white}]}
                onLayout={(event) => {}}
            >
                <View style={{zIndex: 99,}}>
                    <CustomHeaderComponentSmall
                        navigation={this.props.navigation}
                        leftButton={'back'}
                        rightButton={'none'}
                        title={this.state.headerTitle}
                        searchBar={false}
                        textColor={appColors.black}
                        barColor={appColors.white}
                        borderBottomColor={appColors.transparent}
                        statusBarType={"dark-content"}
                    />
                    {/*Border*/}
                    <View style={{flexDirection: "row"}}>
                        <View style={{flex: 1, height: 1, backgroundColor: appColors.spacerGray}}/>
                    </View>
                </View>

                <View style={{flex: 1, backgroundColor: appColors.backBaseColor}}>
                    <SpacerComponent height={4}/>

                    {!this._param.tabHidden && (
                        <TabBorderComponent
                            ref={(tabRef: any) => { this._tabRef = tabRef; }}
                            tabs={this._tabs}
                            focusTabNumber={this._tabIndex}
                            unselectBorder={true}
                            button={true}
                        />
                    )}

                    {/*タブコンテンツ*/}
                    <Carousel
                        ref={(pageRef: any) => { this._tabPage = pageRef; }}
                        enabled={(Platform.OS == 'ios' && !this._param.tabHidden)}
                        data={[0, 1]}
                        renderItem={this._renderPageItems}
                        onSnapToItem={(index: number) => {
                            this._tabPress(index);
                        }}
                        width={AppG.window.width}
                        autoPlay={false}
                        loop={false}
                        style={{height: appS.screenSize.fullHeight}}
                    />
                </View>
            </SafeAreaView>
        );
    }


    /** 描画 */
    render() {
        const {navigation} = this.props;
        if (!this.state.isLoaded) {
            return (
                <View style={{flex: 1}}>
                    <IndicatorComponent
                        failTime={60}
                        isLoading={true}
                        reloadFunc={() => {
                            this.componentWillUnmount();
                            this.componentDidMount();
                        }}/>
                </View>
            );
        }

        LinkUtil.addReturnFalseToATag();
        if (ScreenSizeUtil.isWebSize()) {
            // WEBサイズ
            return this._renderWide();
        } else {
            // SPサイズ
            return this._renderSmall();
        }
    }

}
