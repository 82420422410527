// import "firebase/firestore";
// import firebase from "firebase";
import { collection, getFirestore, getDoc, setDoc, updateDoc, doc, addDoc } from 'firebase/firestore';
import {FirestoreBaseDao} from "./FirestoreBaseDao";
import {Chats} from "../../firestoreEntities/Chats";
// @ts-ignore
import {log} from '../../../../../environment';

export class ChatsDao extends FirestoreBaseDao {
    /** インスタンス */
    private static _instance: ChatsDao;

    /** コレクションパス */
    private _collectionPath: string = "Chats";

    /** プライベートコンストラクタ */
    private constructor() {
        super();
    }

    /** インスタンスの取得 */
    // @ts-ignore
    public static get instance(): ChatsDao {
        if (!this._instance) {
            this._instance = new ChatsDao();
        }

        // 生成済みのインスタンスを返す
        return this._instance;
    }

    /**
     * collection Helper
     * @param docs 未使用
     */
    collection = (docs: {} | null = null) => {
        const firestore = getFirestore();
        return collection(firestore, this._collectionPath);
    };

    /**
     * document Helper
     * @param docs 対象のdoc
     */
    document = (docs: {chat: string | null}) => {
        if (docs == null || docs.chat == null) {
            return doc(this.collection());
        }
        return doc(this.collection(), docs.chat);
    };

    /**
     * 登録
     * @param model chats
     * @param docs 対象のdoc
     * @param successFunc 追加 or 修正後に実行する function
     */
    addOrUpdate = (
        model: Chats,
        docs: {chat: string | null},
        successFunc: ((result: any) => void) | null = null
    ) => {
        if (docs == null || docs.chat == null || docs.chat == '') {
            // 追加
            addDoc(
                ChatsDao.instance.collection(docs),
                this.margeModelForAdd(model.data))
                .then(result => {
                    log.debug(`added Chats is  ${result}`);
                    if (successFunc != null) {
                        successFunc(result);
                    }
                }).catch(err => {
                log.debug(`add error: ${err}`);
            });
        } else {
            // 更新
            updateDoc(ChatsDao.instance.document(docs), this.margeModelForUpdate(model.data))
                // @ts-ignore
                .then(result => {
                    log.debug("updated Chats is " + result);
                    if (successFunc != null) {
                        // result is undefined
                        successFunc(result);
                    }
                    // @ts-ignore
                }).catch(err => {
                log.debug(`update error: ${err}`);
            });
        }
    };

    /**
     * 削除
     * @param docs 対象のdoc
     */
    delete = async (docs: {chat: string}) => {
        await updateDoc(ChatsDao.instance.document(docs), this.getModelForDelete())
            .then(result => {
                log.debug(`deleted Chats is: ${result}`);
            }).catch(err => {
                log.debug(`delete error: ${err}`);
            });
    };

    // /**
    //  * スナップショットを設定
    //  * @param addFunc スナップショットに追加イベントが発火したときのFunc
    //  * @param updateFunc スナップショットに更新イベントが発火したときのFunc
    //  * @param deleteFunc スナップショットに削除イベントが発火したときのFunc
    //  * @param docs 未使用
    //  */
    // snapshot = (
    //     userIds: number[],
    //     addFunc: (data: any) => void,
    //     updateFunc: (data: any) => void,
    //     deleteFunc: (data: any) => void,
    //     docs: {} | null = null,
    // ) => {
    //     if (userIds.length <= 1) {
    //         return null;
    //     }
    //     return ChatsDao.instance.collection(docs)
    //         .where('userIds', 'array-contains', userIds[0])
    //         .where('userIds', 'array-contains', userIds[1])
    //         .where(this.deleteFlgField, "==", false)
    //         .onSnapshot(snapshot => {
    //             snapshot.docChanges().forEach(change => {
    //                 if (change.type === 'added') {
    //                     log.debug('Added Chat');
    //                     addFunc(snapshot);
    //                 }
    //                 if (change.type === 'modified') {
    //                     log.debug('Modified Chats: ', change.doc.data());
    //                     updateFunc(snapshot);
    //                 }
    //                 if (change.type === 'removed') {
    //                     log.debug('Removed Chats: ', change.doc.data());
    //                     deleteFunc(snapshot);
    //                 }
    //             },  (err: any) => {
    //                 log.debug(`Encountered error: ${err}`);
    //             });
    //         });
    // };


    // /**
    //  * チャンネル
    //  * @param userIds
    //  * @param successFunc
    //  */
    // getList = (
    //     userIds: number[],  // index 0 => 自分, 1 => 相手
    //     successFunc: (data: any) => void,
    // ) => {
    //     ChatsDao.instance.collection()
    //         .where('userIds', 'array-contains', userIds[0])
    //         .where('userIds', 'array-contains', userIds[1])
    //         .where(this.deleteFlgField, "==", false).get()
    //         .then(snapshot => {
    //             snapshot.forEach(doc => {
    //                 log.debug(doc.id, " => ", doc.data());
    //                 successFunc(doc.data());
    //             });
    //         })
    //         .catch(err => {
    //             log.debug('Error getting document', err);
    //         });
    // };

    /**
     * 取得
     * @param successFunc 取得成功時の処理
     * @param doc ID
     */
    get = (
        successFunc: (data: any) => void,
        doc: string,
    ) => {
        getDoc(ChatsDao.instance.document({chat: doc}))
            .then(doc => {
                if (!doc.exists) {
                    log.debug('No such document!');
                } else {
                    successFunc(doc.data());
                }
            })
            .catch(err => {
                log.debug(err);
            });
    };

    /**
     * 未読の message doc array をマージ
     * @param data
     * @param unreadMessages message doc array、既読にする場合は []
     * @param userId 未読の場合は送る相手のuserId、既読にする場合は自分のID
     */
    margeModelForUnreadMessages(data: {}, unreadMessages: string[], userId: string) {
        if(data != null && `unreadMessages${userId}` in data && unreadMessages.length > 0) {
            // `unreadMessages${userId}` property が存在しているときの処理
            // 未読メッセージが残っていて未読メッセージを追加する場合
            // @ts-ignore
            data[`unreadMessages${userId}`] = data[`unreadMessages${userId}`].concat(unreadMessages);
        } else {
            // 未読メッセージが残っていない場合 or 全て既読にする場合
            // @ts-ignore
            data[`unreadMessages${userId}`] = unreadMessages;
        }
        return data;
    }

}
