import React from 'react'
import {Dimensions, Image, Platform, SafeAreaView, Text, TouchableOpacity, View} from 'react-native'
import {appColors, appFont, appS} from '../../resources/styles/style'
import {BaseScreen} from "./BaseScreen";
import LoginUtil from "../util/LoginUtil";
import {IndicatorComponent} from "../components/IndicatorComponent";
// @ts-ignore
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scrollview';
import LoginDataDao from "../data/dao/local/LoginDataDao";
import {LoginDataEntityModel} from "../data/entityModels/LoginDataEntityModel";
import {CustomHeaderComponentSmall} from "../components/small/CustomHeaderComponentSmall";
import UrlUtil from "../util/UrlUtil";
// @ts-ignore
import {log} from '../../../environment';
import {ProfileInitScreenModel} from "../data/models/screen/ProfileInitScreenModel";
import {TopicTitleComponent} from "../components/TopicTitleComponent";
import AppG from "../util/AppG";
import {SpacerComponent} from "../components/SpacerComponent";
import ScreenSizeUtil from "../util/ScreenSizeUtil";
import {CustomHeaderComponentWide} from "../components/wide/CustomHeaderComponentWide";
import AnalyticsUtil, {AnalyticsEventName, DOpenEventName} from "../util/firebase/AnalyticsUtil";
import {AccountApiFactory, UserUserPlanEnum} from "../data/network/swagger-gen";
import AlertUtil from "../util/AlertUtil";
import I18n from "../../resources/language/i18n";
import moment from "moment";
import ExpoUtil from "../util/ExpoUtil";
import LinkUtil from "../util/LinkUtil";

interface Props {
    navigation: any, // ナビゲーション用
}

interface State {
    resizeWindow: boolean,  // 値に意味はなし。windowサイズの拡大縮小をrenderに送るためのもの
    isLoaded: boolean,
    isSending: boolean,

    // コンテンツ
    isShowYesNoModal: boolean,
    isShowSentModal: boolean,
}

export class WithdrawScreen extends BaseScreen<Props, State> {
    _resizeTimer = setInterval(() => {
        // window 拡大縮小を反映させる
        AppG.window = Dimensions.get('window');
        if (AppG.window.width != AppG.windowBefore.width || AppG.window.height != AppG.windowBefore.height) {
            this.setState({'resizeWindow': true});
            AppG.windowBefore = Dimensions.get('window');
        }
        LoginUtil.setRefreshedScreenFlg(false);
    }, 100);

    _loginData = new LoginDataEntityModel();

    _flameSize = appS.flame.size;    // 左右の margin
    _marginSize = ScreenSizeUtil.isWebSize()? 0 : appS.margin.size;

    _campaign = false;

    _browserBackListener = (e: any) => {

    };

    constructor(props: any) {
        super(props);
        this.state = {
            resizeWindow: true,  // 値に意味はなし。windowサイズの拡大縮小をrenderに送るためのもの
            isLoaded: false,
            isSending: false,
            isShowYesNoModal: false,
            isShowSentModal: false,
        };
    }

    /**
     * 画面開いたときのアナリティクス送信
     */
    _sendPvAnalytics = () => {
        AnalyticsUtil.sendAnalytics(
            DOpenEventName.openPage,
            AnalyticsEventName.openPage,  // アナリティクスのイベント名
            'WithdrawScreen',
            null,
            null,
            null,
            null,
        );
    }

    componentDidMount() {

        const _campaignDate = moment('2021-05-31 23:59:59');
        const _now = moment();
        this._campaign = _now.isBefore(_campaignDate);


        if (Platform.OS == 'web') {
            window.addEventListener('popstate', this._browserBackListener);
        }

        LoginUtil.interruptOpenScreen(this.props.navigation,
            () => {
                LoginDataDao.get().then(loginData => {
                    // Web 戻るでも呼ばれる
                    if (Platform.OS == 'web') {
                        // Analytics
                        this._sendPvAnalytics();
                    }

                    // URL Scheme で外部リンクから起動したときの処理と、画面遷移でこの画面を開いたときの処理
                    LoginUtil.setDefaultListener(this.props.navigation, () => {
                        // Webはここにこない
                        if (Platform.OS != 'web') {
                            // Analytics
                            this._sendPvAnalytics();
                        }
                    });
                    if (loginData != null) {
                        this._loginData = loginData;
                        this._loadMasters().then(() => {});
                        this._loadContents().then(() => {});
                        this.setState({'isLoaded': true});
                    }
                });
            });

        this.setState({isLoaded: true});
    }

    componentWillUnmount () {
        clearTimeout(this._resizeTimer);

        if (Platform.OS === 'web') {
            window.removeEventListener('popstate', this._browserBackListener);
        }
        LoginUtil.removeDefaultListener(this.props.navigation);
    };

    componentDidUpdate () {
        // レンダー完了時に呼ばれる
    }

    /**
     * パラムの取得
     * @private
     */
    _getParams = () => {
        let profileScreenModel: ProfileInitScreenModel = UrlUtil.getParamModel(new ProfileInitScreenModel(), this.props.navigation);
        return profileScreenModel;
    };

    /**
     * マスターのロード
     * @private
     */
    _loadMasters = async () => {
    };

    /**
     * コンテンツのロード
     * @private
     */
    _loadContents = async () => {
    };

    _getUserPlanName = () => {
        if (AppG.user == null) {
            return '';
        }

        if (AppG.user?.userPlan == UserUserPlanEnum.Premium) {
            if (this._campaign) {
                return 'プレミアムキャンペーン会員';
            } else {
                return 'プレミアム会員';
            }
        } else {
            return '無料会員';
        }
    }

    /**
     * 退会
     */
    _withdraw = () => {
        this.setState({isSending: true});
        AccountApiFactory(AppG.getConfiguration(), AppG.getBasePath())
            .deleteAccount()
            .then(() => {
                LoginUtil.logout(() => {
                        //アナリティクス送信
                        AnalyticsUtil.sendAnalytics(
                            DOpenEventName.cancelTheMembership,
                            AnalyticsEventName.cancelTheMembership,  // アナリティクスのイベント名
                            'WithdrawScreen',
                            null,
                            null,
                            null,
                            null,
                        );

                        this.setState({isShowYesNoModal: false});
                        this.setState({isSending: false});
                        this.setState({isShowSentModal: true});
                    },
                    () => {
                        this.setState({isShowYesNoModal: false});
                        this.setState({isSending: false});
                        this.setState({isShowSentModal: false});
                        // エラー
                        AlertUtil.alert(`${I18n.t('errorNetworkTitle')}`, `${I18n.t('errorNetwork')}`);
                    });
            })
            .catch((e) => {
                this.setState({isShowYesNoModal: false});
                this.setState({isSending: false});
                this.setState({isShowSentModal: false});
                // エラー
                AlertUtil.alert(`${I18n.t('errorNetworkTitle')}`, `${I18n.t('errorNetwork')}`);
                log.debug(e);
            })
    }

    /**
     * 本当に退会しますかModal
     */
    _renderModal = () => {
        const _modal = () => {
            let _width = ScreenSizeUtil.isWebSize()? AppG.window.width *2 / 5 : AppG.window.width *4 / 5;
            let _height = 180;

            if (Platform.OS == 'web' && !ScreenSizeUtil.isWebSize()) {
                // Webのスマホサイズ
                _width = AppG.window.width * 2 / 5;
            }

            return (
                <View style={{
                    height: _height,
                    width: _width,
                    marginRight: this._marginSize,
                    marginLeft: this._marginSize,
                    backgroundColor: appColors.white,
                }}>
                    <SpacerComponent height={20}/>
                    <View style={{alignSelf: 'center', alignItems: 'center', alignContent: 'center'}}>
                        <View style={{alignSelf: 'center', alignItems: 'center', alignContent: 'center'}}>
                            <Text
                                style={{
                                    fontFamily: appFont.family.bold,
                                    color: appColors.fontBlack,
                                    fontWeight: 'bold',
                                    fontSize: appFont.size.topic
                                }}
                            >本当に退会しますか？</Text>
                        </View>
                        <SpacerComponent height={40}/>
                        <View style={{flexDirection: 'row'}}>

                            <TouchableOpacity
                                style={[
                                    {
                                        width: 110,
                                        marginLeft: 16,
                                        marginRight: 4,
                                        height: appS.button.height,
                                        backgroundColor: appColors.black,
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }
                                ]}
                                onPress={() => {
                                    this._withdraw();
                                }}
                            >
                                <Text style={{
                                    fontFamily: appFont.family.bold,
                                    color: appColors.white,
                                    fontWeight: 'bold',
                                    fontSize: appS.button.text
                                }}>退会する</Text>
                            </TouchableOpacity>

                            <TouchableOpacity
                                style={[
                                    {
                                        width: 110,
                                        marginLeft: 4,
                                        marginRight: 16,
                                        height: appS.button.height,
                                        backgroundColor: appColors.white,
                                        borderWidth: 1,
                                        borderColor: appColors.black,
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }
                                ]}
                                onPress={() => {
                                    this.setState({isShowYesNoModal: false});
                                }}
                            >
                                <Text style={{fontFamily: appFont.family.bold, color: appColors.fontBlack, fontSize: appS.button.text, fontWeight: "bold"}}>キャンセル</Text>
                            </TouchableOpacity>

                        </View>
                    </View>
                </View>
            );
        }

        if (this.state.isShowYesNoModal) {
            return (
                <View style={{zIndex: 101, position: 'absolute', backgroundColor: appColors.opacityGray, height: AppG.window.height + 60, width: AppG.window.width}}>
                    <View style={{
                        position: 'relative',
                        padding: 32,
                        width: AppG.window.width * 2 / 5 + 64,
                        top: AppG.window.height / 2 - 230,
                        left: AppG.window.width * 1.5 / 5 - 32,
                        backgroundColor: appColors.transparent,
                        alignItems: 'center',
                    }}>
                        {_modal()}
                    </View>
                </View>
            );
        }

        return null;

    }


    /**
     * ありがとうございましたModal
     */
    _renderSentModal = (onClosePress: () => void) => {
        const _modal = () => {
            let _width = ScreenSizeUtil.isWebSize()? AppG.window.width *2 / 5 : AppG.window.width *4 / 5;

            if (Platform.OS == 'web' && !ScreenSizeUtil.isWebSize()) {
                // Webのスマホサイズ
                _width = AppG.window.width * 2 / 5;
            }

            return (
                <View style={{
                    height: 150,
                    width: _width,
                    backgroundColor: appColors.white,
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    <SpacerComponent height={40}/>
                    <View style={{alignItems: 'center'}}>
                        <Text style={
                            {
                                fontFamily: appFont.family.bold,
                                textAlign: 'center',
                                fontSize: appFont.size.popupText,
                                color: appColors.fontBlack,
                                fontWeight: "bold"
                            }}
                        >{'退会完了しました。\n\nこれまでありがとうございました。'}</Text>
                    </View>
                    <SpacerComponent height={40}/>
                </View>
            );
        }

        if (this.state.isShowSentModal) {
            return (
                <View
                    style={{
                        zIndex: 101,
                        position: 'absolute',
                        height: AppG.window.height,
                        width: AppG.window.width
                    }}
                >
                    <TouchableOpacity
                        style={{
                            zIndex: 102,
                            position: 'absolute',
                            backgroundColor: appColors.opacityGray,
                            height: AppG.window.height + 60,
                            width: AppG.window.width
                        }}
                        onPress={() => {
                            onClosePress();
                        }}
                    />
                    <View style={{
                        position: 'absolute',
                        zIndex: 103,
                        padding: 32,
                        width: AppG.window.width * 2 / 5 + 64,
                        top: AppG.window.height / 2 - 150,
                        left: AppG.window.width * 1.5 / 5 - 32,
                        backgroundColor: appColors.transparent,
                        alignItems: 'center',
                    }}>
                        {_modal()}
                    </View>
                </View>
            );
        }

        return null;

    }


    _renderMain = () => {
        let _width = AppG.window.width;
        if (ScreenSizeUtil.isWebSize()) {
            _width = appS.webMainContentsSize.width;
        }
        _width = _width - appS.margins.side * 2;

        return (
            <View style={[{flex: 1, flexDirection: 'column', width: _width, margin: appS.margins.side}]}>
                <SpacerComponent height={32}/>

                <TopicTitleComponent title={'退会に伴う注意事項'}/>
                <View style={[{margin: appS.margins.side}]}>
                    <Text
                        style={{
                            fontFamily: appFont.family.default,
                            fontSize: appFont.size.default,
                            color: appColors.fontBlack,
                        }}>退会時に全ての個人情報及び保持コイン情報を削除するため、再度無料会員登録していただく場合、新規アカウント へ情報 を引き継ぐことはできません。</Text>
                </View>


                <SpacerComponent height={32}/>

                <SpacerComponent height={32}/>

                {/*退会ボタン*/}
                <TouchableOpacity
                    style={[
                        {
                            marginBottom: 40,
                            height: appS.buttonSize.height,
                            width: appS.buttonSize.width,
                            borderWidth: 1,
                            borderColor: appColors.transparent,
                            backgroundColor: appColors.black,
                            alignItems: 'center',
                            justifyContent: 'center',
                            alignSelf: 'center'
                        }
                    ]}
                    disabled={this.state.isSending || this.state.isShowSentModal}
                    onPress={() => {
                        this.setState({isShowYesNoModal: true});
                    }}
                >
                    <Text style={{
                        fontFamily: appFont.family.bold,
                        color: appColors.white,
                        fontSize: appFont.size.buttonText,
                        fontWeight: "bold"
                    }}>退会する</Text>
                </TouchableOpacity>
            </View>
        );
    }

    _renderWide = () => {
        return (
            <SafeAreaView
                style={[{height: AppG.window.height, flex: 1, flexDirection: 'column', backgroundColor: appColors.white}]}
                onLayout={(event) => {}}
            >
                <CustomHeaderComponentWide logoOnly={true} navigation={this.props.navigation}/>

                <View style={{flexDirection: "row", marginTop: 32, marginRight: 32}}>
                    <View style={{flex: 1}}/>
                    <TouchableOpacity
                        onPress={() => {
                            ExpoUtil.goBack(this.props.navigation);
                        }}
                    >
                        <Image source={require('../../resources/images/close_icon.png')}
                               style={[{
                                   backgroundColor: 'white',
                                   width: 24,
                                   height: 24,
                               }]}
                               resizeMode={'cover'}
                        />
                    </TouchableOpacity>
                </View>

                <Text style={{
                    fontFamily: appFont.family.bold,
                    fontSize: appFont.size.xlarge,
                    color: appColors.black,
                    marginTop: 32,
                    textAlign: 'center',
                    fontWeight: "bold"
                }}>退会手続き</Text>

                <View style={{flex: 1, flexDirection: 'row'}}>
                    <KeyboardAwareScrollView style={[{flex: 1, flexDirection: 'column'}]}>
                        <View style={[{flex: 1, width: 700, alignSelf: 'center'}]}>
                            {this._renderMain()}
                        </View>
                    </KeyboardAwareScrollView>
                </View>
                {this._renderModal()}
                {this._renderSentModal(
                    () =>{
                        this.setState({isShowSentModal: false});

                        // TODO プレミアム会員が出来たらプレミアム会員の退会後の表示を出す
                        if (this._campaign) {
                            if (Platform.OS == 'web') {
                                this.props.navigation.navigate('HomeScreen');
                            } else {
                                this.props.navigation.navigate('LoginScreen');
                            }
                        } else {
                            // TODO 本当のプレミアム会員
                        }
                    }
                )}
            </SafeAreaView>
        );
    }

    _renderSmall = () => {
        return (
            <SafeAreaView
                style={[{flex: 1, backgroundColor: appColors.white}]}
                onLayout={(event) => {}}
            >
                <CustomHeaderComponentSmall
                    navigation={this.props.navigation}
                    title={'退会手続き'}
                    textColor={appColors.black}
                    barColor={appColors.white}
                    borderBottomColor={appColors.white}
                    leftButton={'back'}
                />
                <View style={{flex: 1, backgroundColor: appColors.backBaseColor}}>
                    {this._renderMain()}
                </View>
                {this._renderModal()}
                {this._renderSentModal(
                    () =>{
                        this.setState({isShowSentModal: false});

                        // TODO プレミアム会員が出来たらプレミアム会員の退会後の表示を出す
                        if (this._campaign) {
                            if (Platform.OS == 'web') {
                                this.props.navigation.navigate('HomeScreen');
                            } else {
                                this.props.navigation.navigate('LoginScreen');
                            }
                        } else {
                            // TODO 本当のプレミアム会員
                        }
                    }
                )}
            </SafeAreaView>
        );
    }


    /** 描画 */
    render() {
        const {navigation} = this.props;
        if (!this.state.isLoaded) {
            return (
                <View style={{flex: 1}}>
                    <IndicatorComponent
                        failTime={60}
                        isLoading={true}
                        reloadFunc={() => {
                            this.componentWillUnmount();
                            this.componentDidMount();
                        }}/>
                </View>
            );
        }

        LinkUtil.addReturnFalseToATag();
        if (ScreenSizeUtil.isWebSize()) {
            // WEBサイズ
            return this._renderWide();
        } else {
            // SPサイズ
            return this._renderSmall();
        }

    }


}

