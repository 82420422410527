import React from 'react'
import {Dimensions, Image, Platform, SafeAreaView, Text, TouchableOpacity, View,} from 'react-native'
import {BaseScreen} from "../BaseScreen";
import LoginUtil from "../../util/LoginUtil";
// @ts-ignore
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scrollview';
import UrlUtil from "../../util/UrlUtil";
// @ts-ignore
import {log} from '../../../../environment';
import {RecipeDownloadScreenModel} from "../../data/models/screen/RecipeDownloadScreenModel";
import ScreenSizeUtil from "../../util/ScreenSizeUtil";
import {appColors, appFont, appS} from "../../../resources/styles/style";
import AppG from "../../util/AppG";
import AppGlobalDataUtil from "../../util/AppG";
import {CustomHeaderComponentWide} from "../../components/wide/CustomHeaderComponentWide";
import AlertUtil from "../../util/AlertUtil";
import {ImageFile, Recipe, RecipeApiFactory} from "../../data/network/swagger-gen";
import {AxiosResponse} from "axios";
import MyAppUtil from "../../util/MyAppUtil";
// @ts-ignore
import isURL from 'validator/lib/isURL';
import Carousel from 'react-native-reanimated-carousel';
import AnimatedDotsCarousel from 'react-native-animated-dots-carousel';
import ValidateUtil from "../../util/ValidateUtil";
import {SpacerComponent} from "../../components/SpacerComponent";
import LinkUtil from "../../util/LinkUtil";


///////////////////////////////////
/// ログイン無しのページ（WEB VIEW）
///////////////////////////////////

interface Props {
    navigation: any, // ナビゲーション用
}

interface State {
    resizeWindow: boolean,  // 値に意味はなし。windowサイズの拡大縮小をrenderに送るためのもの
    recipe: Recipe | null,
    activeRecipeSlide: number,    // レシピSlideShowの現在のSlide
}

export class RecipeDownloadScreen extends BaseScreen<Props, State> {

    _resizeTimer = setInterval(() => {
        // window 拡大縮小を反映させる
        AppG.window = Dimensions.get('window');
        this.setState({'resizeWindow': true});
        LoginUtil.setRefreshedScreenFlg(false);
    }, 100);

    _flameSize = appS.flame.size;    // 左右の margin
    _marginSize = ScreenSizeUtil.isWebSize()? 0 : appS.margin.size;

    // このScreenへ渡すパラム
    _param: RecipeDownloadScreenModel = new RecipeDownloadScreenModel();

    /** 画像の dots の Index */
    _dotsProgressIndex: number = 0;

    _buttonStyle = `-moz-appearance: none;
     -webkit-appearance: none;
     appearance: none;
     margin: 1em 0;
     padding: 0.6em 1em;
     font-size: 1em;
     font-weight: bold;
     width: ${AppG.window.width * 8 / 10};
     background-color: ${appColors.black};
     color: ${appColors.white};
     cursor: pointer;
     border: 0;
     transition: 0.3s;`;

    _browserBackListener = (e: any) => {

    };

    constructor(props: any) {
        super(props);
        this.state = {
            resizeWindow: true,  // 値に意味はなし。windowサイズの拡大縮小をrenderに送るためのもの
            recipe: null,
            activeRecipeSlide: 0,
        };
    }

    componentDidMount() {
        log.debug('------------- RecipeDownload Screen Start! -----------------');

        if (Platform.OS == 'web') {
            window.addEventListener('popstate', this._browserBackListener);
        }

        // URL Scheme で外部リンクから起動したときの処理
        LoginUtil.setDefaultListener(this.props.navigation);

        this._loadContents();
    }

    componentWillUnmount () {
        clearTimeout(this._resizeTimer);
        if (Platform.OS === 'web') {
            window.removeEventListener('popstate', this._browserBackListener);
        }
        LoginUtil.removeDefaultListener(this.props.navigation);
    };

    componentDidUpdate () {
        // レンダー完了時に呼ばれる
    }

    /**
      * パラムの取得
      * @private
      */
    _getParams = () => {
        let recipeDownloadScreenModel: RecipeDownloadScreenModel = UrlUtil.getParamModel(new RecipeDownloadScreenModel(), this.props.navigation);
        return recipeDownloadScreenModel;
    };

    _loadContents = async () => {
        this._param = this._getParams();
        if (this._param.recipeId == null) {
            return;
        }

        // レシピ取得
        const getRecipe = RecipeApiFactory(AppGlobalDataUtil.getConfiguration(), AppGlobalDataUtil.getBasePath())
            .getRecipe(
                this._param.recipeId,
                0,
                4,
                ).then((recipeRes: AxiosResponse<Recipe>) => {
                if (recipeRes != null && recipeRes.data != null) {
                    this.setState({recipe: recipeRes.data});
                }
            });

        // 実行
        await Promise.all([getRecipe]);
    };

    /**
     * CSV ダウンロード
     */
    _downloadCsv = () => {

        if (Platform.OS != 'web') {
            AlertUtil.alert('エラー', 'レシピCSVダウンロードはブラウザからのみ利用できます');
            return;
        }

        if (this.state.recipe == null) {
            return;
        }

        MyAppUtil.downloadRecipeCsv(
            this.props.navigation,
            this.state.recipe
        );
    }

    _downloadImage = (index: number) => {
        if (Platform.OS != 'web') {
            AlertUtil.alert('エラー', 'レシピCSVダウンロードはブラウザからのみ利用できます');
            return;
        }

        if (this.state.recipe == null) {
            return;
        }

        if (this.state.recipe.images.length <= index) {
            AlertUtil.alert('エラー', '予期せぬエラー');
            return;
        }

        MyAppUtil._downloadRecipeImage(this.state.recipe.name, this.state.recipe.images[index], index);
    }

    /**
     * レシピ画像のスライドショーのItem作成
     * @param recipeImage
     * @private
     */
    // @ts-ignore
    _renderRecipeImage = ({item}) => {
        let recipeImage: ImageFile = item;
        let source = require('../../../resources/images/no_image.png');
        if (recipeImage != null && recipeImage.url != null && recipeImage.url != '' && isURL(recipeImage.url)) {
            source = {uri: recipeImage.url};
        }

        const _imageSize = {
            width: ScreenSizeUtil.isWebSize()? AppG.window.width / 2 - 32 : AppG.window.width,
            height: ScreenSizeUtil.isWebSize()? (AppG.window.width / 2 - 32) / 1.8 : AppG.window.width / 1.8};

        return (
            <View>
                <Image
                    source={source}
                    style={[{
                        backgroundColor: appColors.white,
                        width: _imageSize.width,
                        height: _imageSize.height,
                    }]}
                    resizeMode={'cover'}
                />
            </View>
        );
    }

    _renderMain = () => {
        if (this.state.recipe == null) {
            return null;
        }

        return (
            <KeyboardAwareScrollView style={[{flex: 1, flexDirection: 'column', backgroundColor: appColors.white}]}>
                <View style={{alignItems: 'center', justifyContent: 'center',}}>
                    {/*レシピ画像*/}
                    {this._renderRecipeImages()}
                </View>
                <View style={{flex: 1, alignSelf: 'center', marginTop: 64, marginLeft: this._flameSize, marginRight: this._flameSize, marginBottom: appS.line.space}}>
                    <View style={{flex: 1}}>
                        <Text style={{color: appColors.black, fontWeight: 'bold', fontSize: appFont.size.large}}>{this.state.recipe!.name}</Text>
                    </View>
                    {/*レシピのダウンロードボタン*/}
                    <TouchableOpacity
                        style={[
                            {
                                width: 300,
                                height: appS.button.height,
                                backgroundColor: this.state.recipe == null ? appColors.gray : appColors.black,
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginBottom: 16,
                                marginTop: 16,
                            }
                        ]}
                        disabled={this.state.recipe == null}
                        onPress={() => {
                            this._downloadCsv();
                        }}
                    >
                        <Text style={{color: appColors.white, fontWeight: 'bold', fontSize: appS.button.text}}>テキストダウンロード</Text>
                    </TouchableOpacity>
                    {/*画像ダウンロードボタン*/}
                    {/*{this.state.recipe && !ValidateUtil.isEmptyArray(this.state.recipe.images) && this.state.recipe.images.map((image: ImageFile, index: number) => {*/}
                    {/*    return (*/}
                    {/*        <TouchableOpacity*/}
                    {/*            style={[*/}
                    {/*                {*/}
                    {/*                    width: 300,*/}
                    {/*                    height: appS.button.height,*/}
                    {/*                    backgroundColor: this.state.recipe == null ? appColors.gray : appColors.black,*/}
                    {/*                    alignItems: 'center',*/}
                    {/*                    justifyContent: 'center',*/}
                    {/*                    marginBottom: 16,*/}
                    {/*                }*/}
                    {/*            ]}*/}
                    {/*            disabled={this.state.recipe == null}*/}
                    {/*            onPress={() => {*/}
                    {/*                this._downloadImage(index);*/}
                    {/*            }}*/}
                    {/*        >*/}
                    {/*            <Text style={{color: appColors.white, fontWeight: 'bold', fontSize: appS.button.text}}>*/}
                    {/*                {this.state.recipe!.images.length >= 2? `画像(${index+1})ダウンロード` : `画像ダウンロード`}*/}
                    {/*            </Text>*/}
                    {/*        </TouchableOpacity>*/}
                    {/*    )*/}
                    {/*})}*/}
                </View>
                <SpacerComponent height={32}/>
            </KeyboardAwareScrollView>
        )
    }

    /**
     * レシピの画像
     */
    _renderRecipeImages = () => {
        if (this.state.recipe == null) {
            return null;
        }

        return (
            <View>
                {/* 画像カルーセル */}
                <Carousel
                    data={this.state.recipe?.images}
                    renderItem={this._renderRecipeImage}
                    onSnapToItem={(index: number) => {
                        this.setState({activeRecipeSlide: index})
                    }}
                    width={ScreenSizeUtil.isWebSize()? AppG.window.width/2 : AppG.window.width}
                    height={(ScreenSizeUtil.isWebSize()? AppG.window.width/2 : AppG.window.width) / 1.8}
                    autoPlay={true}
                    autoPlayInterval={7000}
                    loop={true}
                    style={{margin: 0}}
                    onProgressChange={(_, absoluteProgress) => {
                        if (absoluteProgress % 1 === 0) {
                            this._dotsProgressIndex = absoluteProgress;
                        }
                    }}
                />
                {this.state.recipe?.images.length >= 2 && (
                    <View style={{
                        marginTop: 8,
                        marginBottom: 8,
                        alignItems: 'center'
                    }}>
                        <AnimatedDotsCarousel
                            length={this.state.recipe?.images.length}
                            currentIndex={this._dotsProgressIndex}
                            maxIndicators={this.state.recipe?.images.length}
                            interpolateOpacityAndColor={false}
                            activeIndicatorConfig={{
                                color: appColors.black,
                                margin: 3,
                                opacity: 1,
                                size: 8,
                            }}
                            inactiveIndicatorConfig={{
                                color: appColors.dotsGray,
                                margin: 3,
                                opacity: 0.5,
                                size: 8,
                            }}
                            decreasingDots={[
                                {
                                    config: { color: appColors.dotsGray, margin: 3, opacity: 0.5, size: 8 },
                                    quantity: 1,
                                },
                            ]}
                        />
                    </View>
                )}
            </View>
        );
    }


    /** 描画 */
    render() {
        LinkUtil.addReturnFalseToATag();
        return (
            <SafeAreaView
                style={[{height: AppG.window.height, flex: 1, flexDirection: 'column', backgroundColor: appColors.white}]}
                onLayout={(event) => {}}
            >
                <CustomHeaderComponentWide logoOnly={true} navigation={this.props.navigation}/>

                <View style={{flex: 1, flexDirection: 'column'}}>
                    {this._renderMain()}
                </View>

            </SafeAreaView>
        );
    };


}
