export default {
    welcome: "Hello",
    success: 'Success',
    error: 'ERROR',
    errorNetworkTitle: 'Communication Error',
    errorNetwork: 'Please try again in a good communication environment.',
    errorMail: 'Please input mail.',
    errorNoUser: 'The mail is not registered.',
    errorDuplication: 'This is an already registered account.',
    errorNothingAttendance: 'The work status input is not prepared. Please contact the administrator.',
    loginFailed: 'Login failed',
    logoutFailed: 'Logout failed',
    incorrectAuth: 'The email address or password is incorrect.',
    incorrectMail: 'It is not a valid email address. Please check your email address.',
    incorrectPassword: 'Please enter half-width alphanumeric characters more than 6 characters for the password.',
    incorrectPasswordCheck: 'Please enter half-width alphanumeric characters more than 6 characters for the confirmation password.',
    differentPassword: 'Password and confirmation password are different.',
    mail: 'Email',
    password: 'Password',
    newPassword: 'New Password',
    confirmationPassword: 'Confirmation password',
    login: 'Login',
    logout: 'Logout',
    loggedOut: 'Logged Out',
    signUp: 'Sign up',
    forgotPassword: 'Forgot password?',
    sendTemporaryPassword: 'Send temporary password',
    sendTemporaryPasswordText: 'Temporary password is sent.\nPlease check your e-mail.',
    changeNewPassword: 'Change Password',
    changedNewPassword: 'Changed password',
    changePassword: 'Reset Password',
    changedPassword: 'Reset password',
    changePasswordText: 'Enter a valid e-mail to receive to reset your password.',
    pleasEnterAnItem: 'Pleas enter %{item}',
    pleasEnterInt: '%{item} must be number',
    pleasEnterDate: '%{item} must be date（e.g. 2019/May/21 => 2019-05-21）',
    errorDate: 'Not correct date.',
    errorPostalCode: 'Not correct post code.',
    next: 'Next',
    register: 'Register',
    cancel: 'Cancel',
    correct: 'Correct',
    update: 'Update',
    updated: 'Updated',
    goBack: 'Go back',
    close: 'Close',
    edit: 'Edit',
    ok: 'OK',
    blaBlaBla: 'xx',
    month: 'Month',
    jobType: 'Job type',
    withdrawal: 'Canceling your membership',
    withdraw: 'Cancel your membership',
    wantToWithdraw: 'Do you really want to cancel your membership?',
    withdrawn: 'You have canceled your membership.',
    send: 'Send',
    permissionDeniedCameraRoll: 'Please allow  access your camera or Camera Roll.',

    // Screens(アナリティクス用)
    SpecialScreen: '特集画面',
    CartScreen: 'サンプルかご画面',
    ChangePasswordScreen: 'パスワード変更画面',
    ChatScreen: 'メッセージ画面',
    ChatsScreen: 'チャット一覧画面',
    FollowScreen: 'お気に入り画面',
    HomeScreen: 'ホーム画面',
    HomeScreen_Ranking: 'ホーム画面（ランキングタブ）',
    LoginScreen: 'ログイン画面',
    MakerDetailScreen: 'メーカー詳細画面',
    NewsDetailScreen: 'お知らせ詳細画面',
    NewsScreen: 'お知らせ一覧画面',
    ProductDetailScreen: '商品詳細画面',
    ProductReviewScreen: '商品レビュー画面',
    ProfileInit1Screen: 'ユーザー登録画面1',
    ProfileInit2Screen: 'ユーザー登録画面2',
    ProfileInit5Screen: 'ユーザー登録画面5',
    ProfileInit6Screen: 'ユーザー登録画面6',
    ProfileScreen: 'プロフィール編集画面',
    RecipeDetailScreen: 'レシピ詳細画面',
    ReportScreen: '通報画面',
    ResetPasswordScreen: 'パスワード再送信画面',
    SampleRequestScreen: 'サンプル依頼画面',
    SampleRequestHistoryScreen: 'サンプル依頼履歴画面',
    SampleRequestReviewsScreen: 'サンプルレビュー依頼画面',
    SearchFreeWordProductResultScreen: 'フリーワード検索結果画面（商品）',
    SearchJobCategoryScreen: '業態カテゴリ検索画面（レシピ）',
    SearchKeywordScreen: 'キーワード検索画面',
    SearchKeywordRecipeScreen: 'キーワード検索画面（レシピ）',
    SearchKeywordProductScreen: 'キーワード検索画面（商品）',
    SearchMenuCategoryScreen: 'メニューカテゴリ検索画面（レシピ）',
    SearchResultScreen: '検索結果画面',
    SearchRecipeResultScreen: '検索結果画面（レシピ）',
    SearchProductResultScreen: '検索結果画面（商品）',
    SearchScreen: '詳細検索画面',
    SelectSampleScreen: 'サンプル依頼・かご追加商品の選択画面',
    SettingMenuScreen: 'メニュー・その他画面',
    SignUpScreen: '新規登録画面',
    WebViewScreen: 'ウェブ表示画面',
    WhatIsRecipeDownloadScreen: 'レシピダウンロードとは画面',
    WhatIsSampleRequestScreen: 'サンプル依頼とは画面',
    WhatIsMemberShipScreen: '会員登録でできることは画面',
    WithdrawScreen: '退会画面',
    ReferralCodeScreen: '紹介コード表示画面',
}
