import React from 'react'
import {Dimensions, Image, Platform, SafeAreaView, ScrollView, Text, TouchableOpacity, View} from 'react-native'
import {appColors, appFont, appS} from '../../resources/styles/style'
import {BaseScreen} from "./BaseScreen";
import LoginUtil from "../util/LoginUtil";
import {IndicatorComponent} from "../components/IndicatorComponent";
// @ts-ignore
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scrollview';
import LoginDataDao from "../data/dao/local/LoginDataDao";
import {LoginDataEntityModel} from "../data/entityModels/LoginDataEntityModel";
import {
    FollowApiFactory,
    ImageFile,
    Keyword, MasterApiFactory,
    Product,
    ProductApiFactory,
    Recipe,
    RecipeApiFactory,
    RecipeIngredientsInner, RestaurantFormat,
    UserUserAttributeEnum,
} from "../data/network/swagger-gen";
import AppG from "../util/AppG";
import {AxiosResponse} from "axios";
import {CustomHeaderComponentSmall} from "../components/small/CustomHeaderComponentSmall";
import UrlUtil from "../util/UrlUtil";
import {RecipeDetailScreenModel} from "../data/models/screen/RecipeDetailScreenModel";
// @ts-ignore
import isURL from 'validator/lib/isURL';
import Carousel from 'react-native-reanimated-carousel';
import AnimatedDotsCarousel from 'react-native-animated-dots-carousel';
import {BorderComponent} from "../components/BorderComponent";
import {TopicTitleComponent} from "../components/TopicTitleComponent";
import {ProductDetailScreenModel} from "../data/models/screen/ProductDetailScreenModel";
import ValidateUtil from "../util/ValidateUtil";
import {MakerDetailScreenModel} from "../data/models/screen/MakerDetailScreenModel";
// @ts-ignore
import {getEnvVars, log} from '../../../environment';
import Collapsible from "react-native-collapsible";
import {LinkComponent} from "../components/LinkComponent";
import ExpoUtil from "../util/ExpoUtil";
import ScreenSizeUtil from "../util/ScreenSizeUtil";
import {CustomHeaderComponentWide} from "../components/wide/CustomHeaderComponentWide";
import {WideScreenAdComponent} from "../components/wide/WideScreenAdComponent";
import {SpacerComponent} from "../components/SpacerComponent";
import {SelectSampleScreenModel} from "../data/models/screen/SelectSampleScreenModel";
import MyAppUtil from "../util/MyAppUtil";
import AnalyticsUtil, {AnalyticsEventName, DOpenEventName, ShareBtnName} from "../util/firebase/AnalyticsUtil";
import {SearchScreenModel} from "../data/models/screen/SearchScreenModel";
import {ModalComponent} from "../components/ModalComponent";
import {Modal2ButtonComponent} from "../components/Modal2ButtonComponent";

import {ShareComponent} from "../components/ShareComponent";
import WebTagUtil from "../util/WebTagUtil";
import SearchDataDao from "../data/dao/local/SearchDataDao";
import {SearchWordsComponent} from "../components/SearchWordsComponent";
import {
    RecipeSameProductListHorizontalScrollComponent
} from "../components/RecipeSameProductListHorizontalScrollComponent";
import {ATagLinkComponent} from "../components/ATagLinkComponent";
import {WideSubTitleComponent} from "../components/wide/WideSubTitleComponent";
import {TutorialModalComponent} from "../components/TutorialModalComponent";
import {WhatIsSampleRequestModalComponent} from "../components/WhatIsSampleRequestModalComponent";
import {WhatIsRecipeDownloadModalComponent} from "../components/WhatIsRecipeDownloadModalComponent";
import {NoUserImageComponent} from "../components/NoUserImageComponent";
import {WideScreenSNSComponent} from "../components/wide/WideScreenSNSComponent";
import StrUtil from "../util/StrUtil";
import AppGlobalDataUtil from "../util/AppG";
import moment from "moment";
import PopupUtil from "../util/PopupUtil";
import LinkUtil from "../util/LinkUtil";

interface Props {
    navigation: any, // ナビゲーション用
}

interface State {
    resizeWindow: boolean,  // 値に意味はなし。windowサイズの拡大縮小をrenderに送るためのもの
    isLoaded: boolean,
    isShowErrorModal: boolean,
    isShowNotEnableDownload: boolean,

    // View
    isCloseAreaWayOfCooking: boolean,   // 調理手順アコーディオン

    // コンテンツ
    recipe: Recipe | null, // レシピ
    product: Product | null, // このレシピに紐付くメイン商品
    products: Product[],    // このレシピに紐付くメイン以外の商品
    sameProductRecipes: Recipe[] | undefined, // この商品を使った他のレシピ
    activeRecipeSlide: number,    // レシピSlideShowの現在のSlide
    followed: boolean,  // レシピをフォローしてるかどうか
    keywords: Keyword[], // レシピのキーワード

    showBigImage: boolean,  // 画像の拡大表示
    recipeBigImage: ImageFile | null,  // 拡大表示される画像
}

export class RecipeDetailScreen extends BaseScreen<Props, State> {
    _resizeTimer = setInterval(() => {
        // window 拡大縮小を反映させる
        AppG.window = Dimensions.get('window');
        if (AppG.window.width != AppG.windowBefore.width || AppG.window.height != AppG.windowBefore.height) {
            this.setState({'resizeWindow': true});
            AppG.windowBefore = Dimensions.get('window');
        }
        LoginUtil.setRefreshedScreenFlg(false);
    }, 100);

    _loginData = new LoginDataEntityModel();

    _flameSize = ScreenSizeUtil.isWebSize()? AppG.window.width / 10 : 16;    // 左右の margin
    _marginSize = ScreenSizeUtil.isWebSize()? 0 : appS.margin.size;

    // このScreenへ渡すパラム
    _param: RecipeDetailScreenModel = new RecipeDetailScreenModel();

    // 調理手順を閉じてるかどうか
    _collapsedHowToCook = true;

    // 業態
    _restaurantFormatMasters: RestaurantFormat[] = [];    // 業態

    /** 画像の dots の Index */
    _dotsProgressIndex: number = 0;

    _browserBackListener = (e: any) => {

    };

    constructor(props: any) {
        super(props);
        this.state = {
            resizeWindow: true,  // 値に意味はなし。windowサイズの拡大縮小をrenderに送るためのもの
            isLoaded: false,
            isShowErrorModal: false,
            isShowNotEnableDownload: false,
            isCloseAreaWayOfCooking: true,
            recipe: null,
            product: null,
            products: [],
            sameProductRecipes: [],
            activeRecipeSlide: 0,
            followed: false,
            keywords: [],
            showBigImage: false,
            recipeBigImage: null,
        };
    }

    _whatIsSampleRequestModalRef: any = null;
    _whatIsRecipeDownloadModalRef: any = null;
    /** SPのスクロール*/
    _spScrollView: any = null;

    /** データロードされるまで再送信を繰り返すPvAnalytics */
    _sentPvAnalyticsTimer: any;
    /**
     * 画面開いたときのアナリティクス送信
     */
    _sendPvAnalytics = () => {

        if (!ValidateUtil.isEmptyExact(this._param.isPreview) && this._param.isPreview == '1') {
            // isPreview だったら送信しない
            return;
        }

        this._sentPvAnalyticsTimer = setInterval(() => {
            if (this.state.recipe != null) {
                clearInterval(this._sentPvAnalyticsTimer);
                AnalyticsUtil.sendAnalytics(
                    DOpenEventName.openPage,
                    AnalyticsEventName.openPage,  // アナリティクスのイベント名
                    'RecipeDetailScreen',
                    this.state.recipe,
                    null,
                    this.state.recipe.maker != null ? this.state.recipe.maker: null,
                    null,
                );
            }
        },  100);
    }

    /** データロードされるまで再送信を繰り返すPvAnalytics */
    _sentRecipeAnalyticsTimer: any;
    /**
     * レシピ用アナリティクス
     */
    _sendRecipeAnalytics = () => {

        if (!ValidateUtil.isEmptyExact(this._param.isPreview) && this._param.isPreview == '1') {
            // isPreview だったら送信しない
            return;
        }

        this._sentRecipeAnalyticsTimer = setInterval(() => {
            if (this.state.recipe != null) {
                clearInterval(this._sentRecipeAnalyticsTimer);
                AnalyticsUtil.sendAnalytics(
                    null,
                    `maker_${this.state.recipe.maker?.id}_recipe_${this.state.recipe.id}`,  // アナリティクスのイベント名
                    'RecipeDetailScreen',
                    this.state.recipe,
                    null,
                    this.state.recipe.maker != null ? this.state.recipe.maker: null,
                    null,
                );
            }
        },  100);
    }

    componentDidMount() {
        this._param = this._getParams();

        if (Platform.OS == 'web') {
            window.addEventListener('popstate', this._browserBackListener);
        }

        LoginUtil.interruptOpenScreen(this.props.navigation,
            () => {
                LoginDataDao.get().then(loginData => {
                    // Web 戻るでも呼ばれる
                    if (Platform.OS == 'web') {
                        // Analytics
                        this._sendPvAnalytics();
                        this._sendRecipeAnalytics();
                    }
                    AppG.getFooterBadge();

                    // URL Scheme で外部リンクから起動したときの処理と、画面遷移でこの画面を開いたときの処理
                    LoginUtil.setDefaultListener(
                        this.props.navigation,
                        () => {
                            // Webはここにこない
                            if (Platform.OS != 'web') {
                                // Analytics
                                this._sendPvAnalytics();
                                this._sendRecipeAnalytics();
                            }
                            //
                            // if (_initLoadFlg) {
                            //     _initLoadFlg = false;
                            //     return;
                            // }
                            // this._loadContents().then(() => {});
                            //
                            ExpoUtil.doReloadAppTab(() => {
                                AppG.getFooterBadge();
                            });
                        },
                        () => {
                            // this.setState({'isLoaded': false});
                        });

                    if (loginData != null) {
                        this._loginData = loginData;
                    }

                    this._loadMasters().then(() => {});
                    this._loadContents().then(() => {
                        this.setState({'isLoaded': true});
                    });
                });
            });
    }

    componentWillUnmount () {
        clearTimeout(this._resizeTimer);
        if (Platform.OS === 'web') {
            window.removeEventListener('popstate', this._browserBackListener);
        }
        LoginUtil.removeDefaultListener(this.props.navigation);
    };

    componentDidUpdate () {
        // レンダー完了時に呼ばれる
    }

    /**
     * パラムの取得
     * @private
     */
    _getParams = () => {
        let recipeDetailScreenModel: RecipeDetailScreenModel = UrlUtil.getParamModel(new RecipeDetailScreenModel(), this.props.navigation);
        return recipeDetailScreenModel;
    };

    /**
     * マスターのロード
     * @private
     */
    _loadMasters = async () => {
        // 店舗業態
        MasterApiFactory(AppGlobalDataUtil.getConfiguration(), AppGlobalDataUtil.getBasePath())
            .getAllRestaurantFormatMaster().then((restaurantFormatRes: AxiosResponse<RestaurantFormat[]>) => {
            if (restaurantFormatRes != null && restaurantFormatRes.data != null) {
                this._restaurantFormatMasters = restaurantFormatRes.data;
            }
        });
    };

    /**
     * コンテンツのロード
     * @private
     */
    _loadContents = async () => {
        this._param = this._getParams();

        // レシピ取得
        this._getRecipe(1);
    };



    /**
     * レシピ取得
     */
    _getRecipe = async (pv: number) => {
        if (this._param.recipeId == null) {
            return;
        }

        let _pv = pv;
        if (!ValidateUtil.isEmptyExact(this._param.isPreview) && this._param.isPreview == '1') {
            // プレビューでpv除外
            _pv = 0;
        }

        if (AppG.isTestAccounts()) {
            // テストアカウントでpv除外
            _pv = 0;
        }

        const getRecipe = RecipeApiFactory(AppG.getConfiguration(), AppG.getBasePath())
            .getRecipe(
                this._param.recipeId,
                _pv,
                5,
                !ValidateUtil.isEmptyExact(this._param.isPreview)? parseInt(this._param.isPreview!) : undefined,
            ).then((recipeRes: AxiosResponse<Recipe>) => {
                if (recipeRes != null && recipeRes.data != null) {
                    let _ingredients = '';
                    if (!ValidateUtil.isEmptyArray(recipeRes.data.ingredients)) {
                        recipeRes.data.ingredients.forEach(ingredient => {
                            _ingredients = `${_ingredients}${ingredient.name},`;
                        })
                    }
                    let _wayOfCookings = '';
                    if (!ValidateUtil.isEmptyArray(recipeRes.data.wayOfCooking)) {
                        recipeRes.data.wayOfCooking.forEach(wayOfCooking => {
                            _wayOfCookings = `${_wayOfCookings}${wayOfCooking},`;
                        })
                    }

                    // meta 更新
                    WebTagUtil.setOgType('article');
                    WebTagUtil.setTags(`${recipeRes.data.name}|Reci BASE`, `${recipeRes.data.shortDescription},${_ingredients}${_wayOfCookings}`, recipeRes.data.images[0].url);

                    this.setState({recipe: recipeRes.data});

                    if (recipeRes.data.productMain != null && recipeRes.data.productMain.id != null) {
                        // メイン商品の取得
                        ProductApiFactory(AppG.getConfiguration(), AppG.getBasePath())
                            .getProduct(
                                recipeRes.data.productMain.id.toString(),
                                0,
                                3,
                            ).then((productRes: AxiosResponse<Product>) => {
                            this.setState({product: productRes.data});

                            if (productRes.data != null && productRes.data.id !== null) {

                                // 他のレシピ、よく一緒に見られているレシピの表示個数
                                const numOfRecipe = 10;

                                // この商品を使った他のレシピ取得
                                RecipeApiFactory(AppG.getConfiguration(), AppG.getBasePath())
                                    .findRecipes(
                                        0,
                                        numOfRecipe,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        productRes.data.id,
                                        undefined,
                                        4,
                                    )
                                    .then((sameProductRecipeRes: AxiosResponse<Recipe[]>) => {
                                        if (sameProductRecipeRes != null && !ValidateUtil.isEmptyArray(sameProductRecipeRes.data)) {
                                            // 自分自身を削除
                                            const _recipeId = this._param.recipeId;
                                            let _sameProductRecipes: Recipe[] = sameProductRecipeRes.data;
                                            _sameProductRecipes.some(
                                                function (list: Recipe, index: number) {
                                                    if (list.id.toString() == _recipeId) {
                                                        _sameProductRecipes.splice(index, 1);
                                                    }
                                                });
                                            this.setState({sameProductRecipes: _sameProductRecipes});
                                        } else {
                                            this.setState({sameProductRecipes: []});
                                        }
                                    });

                                // TODO よく一緒に見られているレシピ
                            } else {
                                this.setState({sameProductRecipes: []});
                            }
                        }).finally(() => {
                            this.setState({'isLoaded': true});
                        });

                        // 関連商品の取得
                        if (!ValidateUtil.isEmptyArray(recipeRes.data.productIds)) {
                            this.setState({products: []});
                            let _productsForMeta: Product[] = [];  // meta 用
                            recipeRes.data.productIds.forEach((value) => {
                                if (value != null) {
                                    ProductApiFactory(AppG.getConfiguration(), AppG.getBasePath())
                                        .getProduct(
                                            value.toString(),
                                            0,
                                            3,
                                        ).then((productRes: AxiosResponse<Product>) => {
                                        if (productRes.data != null && productRes.data.id !== null && productRes.data.id != recipeRes.data.productMain!.id) {
                                            if (!this.state.products.some(value => value.id == productRes.data.id)) {
                                                this.state.products.push(productRes.data);
                                                setTimeout(
                                                    () => {
                                                        this.setState({products: this.state.products});
                                                    },
                                                    100);
                                            }
                                        }

                                        _productsForMeta.push(productRes.data);

                                        let _productMeta = '';
                                        _productsForMeta.forEach(p => {
                                            _productMeta = `${_productMeta}${p.description}:${p.name},`;
                                        });

                                        // 商品付きで meta 更新
                                        WebTagUtil.setOgType('article');
                                        WebTagUtil.setTags(`${recipeRes.data.name}|Reci BASE`, `${recipeRes.data.shortDescription},${_ingredients}${_wayOfCookings}${_productMeta}`, recipeRes.data.images[0].url);
                                    });
                                }
                            });
                        }

                    } else {
                        this.setState({'isLoaded': true});
                        this.setState({product: null});
                    }

                } else {
                    this.setState({'isLoaded': true});
                }
            });

        const getFollow = RecipeApiFactory(AppG.getConfiguration(), AppG.getBasePath())
            .getRecipeFollow(
                parseInt(this._param.recipeId)
            ).then((recipeFollowRes) => {
                // お気に入り
                if (recipeFollowRes != null && recipeFollowRes.data != null && recipeFollowRes.data.followed != null && recipeFollowRes.data.followed === 1) {
                    this.setState({followed: true});
                } else {
                    this.setState({followed: false});
                }
            })

        // 実行
        await Promise.all([getRecipe, getFollow]);
    }

    /**
     * サンプル依頼ボタンの表示
     */
    _showSampleButton = () => {
        if (AppG.user != null && AppG.user.userAttribute == UserUserAttributeEnum.Normal) {
            return false;
        }

        if (this.state.product == null && ValidateUtil.isEmptyArray(this.state.products)) {
            return false;
        }

        if (this.state.product != null && !ValidateUtil.isEmptyExact(this.state.product.sampleAmount)) {
            return true;
        }

        let _show = false;
        if (!ValidateUtil.isEmptyArray(this.state.products)) {
            this.state.products.forEach(value => {
                if (!ValidateUtil.isEmptyExact(value.sampleAmount)) {
                    _show = true;
                }
            })
        }
        return _show;
    }

    /**
     * サンプルを選ぶ画面に移動
     */
    _goToSelectSampleScreen = (type: 'request' | 'cart') => {
        // サンプル依頼やサンプルかごに入れる商品のIDを生成
        let selectSampleScreenModel: SelectSampleScreenModel = new SelectSampleScreenModel();
        selectSampleScreenModel.type = type;
        if (this.state.product == null && ValidateUtil.isEmptyArray(this.state.products)) {
            selectSampleScreenModel.productIds = `||`;
        } else {
            if (this.state.product != null) {
                selectSampleScreenModel.productIds = `|${this.state.product!.id.toString()}|`;
            }

            if (!ValidateUtil.isEmptyArray(this.state.products)) {
                if (this.state.product == null) {
                    selectSampleScreenModel.productIds = `|`;
                }
                this.state.products.forEach(value => {
                    if (!ValidateUtil.isEmptyExact(value.sampleAmount)) {
                        selectSampleScreenModel.productIds = `|${selectSampleScreenModel.productIds}|${value.id.toString()}|`;
                    }
                })
            }
        }
        this.props.navigation.navigate('SelectSampleScreen', selectSampleScreenModel);
    }

    /**
     * レシピダウンロード
     */
    _downloadRecipe = () => {
        if (this.state.recipe == null) {
            return;
        }

        if (AppG.user == null) {
            this.setState({isShowNotEnableDownload: true});

            if (!ScreenSizeUtil.isWebSize() && this._spScrollView != null) {
                this._spScrollView.scrollTo({x: 0, y: 0, animated: false});
            }

            return;
        }

        // MyAppUtil.downloadRecipeImages(this.state.recipe);

        MyAppUtil.downloadRecipeCsv(
            this.props.navigation,
            this.state.recipe,
        );
    }

    /**
     * 調理手順番号
     * @param num
     */
    _writeWayOfCookingNumber = (num: number) => {
        switch (num) {
            case 1:
                return '①';
            case 2:
                return '②';
            case 3:
                return '③';
            case 4:
                return '④';
            case 5:
                return '⑤';
            case 6:
                return '⑥';
            case 7:
                return '⑦';
            case 8:
                return '⑧';
            case 9:
                return '⑨';
            case 10:
                return '⑩';
            default:
                return '';
        }
    }

    /**
     * 未ログイン時の表示
     */
    _renderNoLogin = () => {
        return (
            <View style={{flex: 1, backgroundColor: appColors.backBaseColor}}>
                <NoUserImageComponent navigation={this.props.navigation} />
            </View>
        );
    }

    /**
     * 画像の拡大表示
     */
    _renderBigImages = () => {
        if (!this.state.showBigImage) {
            return null;
        }

        if (this.state.recipeBigImage == null) {
            return null;
        }

        let source: any = null;
        if (this.state.recipeBigImage != null && this.state.recipeBigImage.url != null && this.state.recipeBigImage.url != '' && isURL(this.state.recipeBigImage.url)) {
            source = {uri: this.state.recipeBigImage.url};
        }

        if (source == null) {
            return null;
        }

        if (ScreenSizeUtil.isWebSize()) {
            let _imageSize = {width: 200, height: 200/1.8};

            return (
                <TouchableOpacity
                    style={{zIndex: 101, position: 'absolute', backgroundColor: appColors.opacityGray, height: AppG.window.height + 60, width: AppG.window.width,}}
                    onPress={() => {
                        this.setState({showBigImage: false});
                        this.setState({recipeBigImage: null});
                    }}>
                    <View style={{
                        position: 'relative',
                        padding: 32,
                        width: AppG.window.width * 3 / 5,
                        top: 0,
                        left: AppG.window.width / 5,
                        backgroundColor: appColors.transparent,
                        alignItems: 'center',
                    }}>
                        <TouchableOpacity
                            style={{marginBottom: 8, backgroundColor: appColors.white, paddingTop: 8, paddingBottom: 8, paddingLeft: 8, paddingRight: 8}}
                            onPress={() => {
                                // press を吸収
                            }}>
                            <Image
                                source={source}
                                style={[{
                                    backgroundColor: appColors.white,
                                    width: 800,
                                    height: 800 / 1.8,
                                }]}
                                resizeMode={'cover'}
                                accessibilityLabel={this.state.recipe?.name}
                            />
                        </TouchableOpacity>
                        {!ValidateUtil.isEmptyArray(this.state.recipe?.images) && this.state.recipe!.images!.length > 1 && (
                            <ScrollView
                                style={{width: 816, backgroundColor: appColors.white}}
                                horizontal={true}
                            >
                                <TouchableOpacity style={{flex: 1, flexDirection: 'row', padding: 16, backgroundColor: appColors.white}}>
                                    {!ValidateUtil.isEmptyArray(this.state.recipe?.images) && this.state.recipe?.images.map( (_image: ImageFile) => {
                                        let _source = null;
                                        if (_image != null && _image.url != null && _image.url != '' && isURL(_image.url)) {
                                            _source = {uri: _image.url};
                                        }

                                        if (_source == null) {
                                            return null;
                                        }
                                        return (
                                            <TouchableOpacity
                                                style={{marginLeft: 4, marginRight: 4}}
                                                onPress={() => {
                                                    this.setState({recipeBigImage: _image});
                                                }}
                                            >
                                                <Image source={_source}
                                                       style={[{
                                                           backgroundColor: 'white',
                                                           width: _imageSize.width,
                                                           height: _imageSize.height,
                                                       }]}
                                                       resizeMode={'cover'}
                                                       accessibilityLabel={this.state.recipe?.name}
                                                />
                                            </TouchableOpacity>
                                        );
                                    })}
                                </TouchableOpacity>
                            </ScrollView>
                        )}
                    </View>
                </TouchableOpacity>
            );
        } else {
            // スマホ
            let _imageSize = {width: AppG.window.width/1.8, height: (AppG.window.width/1.8)/1.8};
            let _topHeight = Platform.OS == 'web'? 150: 250;

            return (
                <View
                    style={{zIndex: 101, position: 'absolute', backgroundColor: appColors.opacityGray, height: AppG.window.height + 60, width: AppG.window.width,}}
                >
                    <ScrollView
                        minimumZoomScale={1}
                        maximumZoomScale={2}
                    >
                        <TouchableOpacity
                            style={{
                                height: _topHeight,
                                width: AppG.window.width,
                                backgroundColor: appColors.transparent,
                                alignItems: 'center',
                            }}
                            onPress={() => {
                                this.setState({showBigImage: false});
                                this.setState({recipeBigImage: null});
                            }}>
                        </TouchableOpacity>

                        <View
                            style={{
                                marginBottom: 8,
                                backgroundColor: appColors.white,
                                paddingTop: 8,
                                paddingBottom: 8,
                                paddingLeft: 0,
                                paddingRight: 0,
                            }}
                        >
                            <Image
                                source={source}
                                style={[{
                                    backgroundColor: appColors.white,
                                    width: AppG.window.width,
                                    height: AppG.window.width / 1.8,
                                }]}
                                resizeMode={'cover'}
                                accessibilityLabel={this.state.recipe?.name}
                            />
                        </View>
                        {!ValidateUtil.isEmptyArray(this.state.recipe?.images) && this.state.recipe!.images!.length > 1 && (
                            <ScrollView
                                style={{width: AppG.window.width, backgroundColor: appColors.white}}
                                horizontal={true}
                            >
                                <TouchableOpacity style={{flex: 1, flexDirection: 'row', padding: 16, backgroundColor: appColors.white}}>
                                    {!ValidateUtil.isEmptyArray(this.state.recipe?.images) && this.state.recipe?.images.map( (_image: ImageFile, index) => {
                                        let _source = null;
                                        if (_image != null && _image.url != null && _image.url != '' && isURL(_image.url)) {
                                            _source = {uri: _image.url};
                                        }

                                        if (_source == null) {
                                            return null;
                                        }
                                        return (
                                            <TouchableOpacity
                                                style={{marginLeft: 4, marginRight: 4}}
                                                onPress={() => {
                                                    this.setState({recipeBigImage: _image});
                                                }}
                                                key={`image_btn_${index}`}
                                            >
                                                <Image source={_source}
                                                       style={[{
                                                           backgroundColor: 'white',
                                                           width: _imageSize.width,
                                                           height: _imageSize.height,
                                                       }]}
                                                       resizeMode={'cover'}
                                                       key={`image_${index}`}
                                                       accessibilityLabel={this.state.recipe?.name}
                                                />
                                            </TouchableOpacity>
                                        );
                                    })}
                                </TouchableOpacity>
                            </ScrollView>
                        )}
                        <TouchableOpacity
                            style={{
                                height: AppG.window.height - (AppG.window.width / 1.8 + _topHeight + 100),
                                width: AppG.window.width,
                                backgroundColor: appColors.transparent,
                                alignItems: 'center',
                            }}
                            onPress={() => {
                                this.setState({showBigImage: false});
                                this.setState({recipeBigImage: null});
                            }}>
                        </TouchableOpacity>
                    </ScrollView>
                </View>
            );
        }


    }

    /**
     * レシピ画像のスライドショーのItem作成
     * @param recipeImage
     * @private
     */
        // @ts-ignore
    _renderRecipeImage = ({item}) => {
        let recipeImage: ImageFile = item;
        let source = require('../../resources/images/no_image.png');
        if (recipeImage != null && recipeImage.url != null && recipeImage.url != '' && isURL(recipeImage.url)) {
            source = {uri: recipeImage.url};
        }

        let _width = AppG.window.width;
        if (ScreenSizeUtil.isWebSize()) {
            _width = appS.webMainContentsSize.width;
        }

        const _imageSize = {
            width: _width,
            height: _width / 1.8
        };
        const _followIconSize = appS.button.followIconSizeLarge;

        return (
            <View>
                <TouchableOpacity
                    onPress={() => {
                        this.setState({recipeBigImage: recipeImage});
                        this.setState({showBigImage: true});
                    }}>
                    <Image
                        source={source}
                        style={[{
                            backgroundColor: appColors.white,
                            width: _imageSize.width,
                            height: _imageSize.height,
                        }]}
                        resizeMode={'cover'}
                        accessibilityLabel={this.state.recipe?.name}
                    />
                </TouchableOpacity>
                {/*お気に入りボタン*/}
                <TouchableOpacity
                    style={{
                        zIndex: 1,
                        position: 'relative',
                        top: -_imageSize.height + 20,
                        left: 25,
                        width: _followIconSize,
                        height: _followIconSize,
                        borderRadius: 50,
                        backgroundColor: appColors.transparent,
                        alignItems: 'center',
                        justifyContent: 'center',
                        margin: 0
                    }}
                    disabled={AppG.user == null}
                    onPress={() => {
                        if (AppG.user == null) {
                            return;
                        }

                        if (!ValidateUtil.isEmptyExact(this._param.isPreview) && this._param.isPreview == '1') {
                            // isPreview だったら送信しない
                            return;
                        }

                        let type = 'follow';
                        if (this.state.followed) {
                            type = 'unfollow'
                        }
                        FollowApiFactory(AppG.getConfiguration(), AppG.getBasePath())
                            .followRecipe(this.state.recipe!.id.toString(), type).then(() => {
                            // Analytics
                            AnalyticsUtil.sendAnalytics(
                                type == 'follow'? DOpenEventName.follow : DOpenEventName.unfollow,
                                type == 'follow'? AnalyticsEventName.follow : AnalyticsEventName.unfollow ,  // アナリティクスのイベント名
                                'RecipeDetailScreen',
                                this.state.recipe,
                                null,
                                this.state.recipe?.maker,
                                null,
                            );

                            ExpoUtil.setShouldReloadAppTab();   // 別タブで必要ならリロードするようにセット
                            this.setState({followed: !this.state.followed});
                        });
                    }}
                >
                    {/*フォロー中*/}
                    {this.state.followed && (
                        <Image source={AppG.user == null ? require('../../resources/images/skelton.png') : require('../../resources/images/03.item_recipe/i_item_like_on.png')}
                               style={[{
                                   backgroundColor: appColors.transparent,
                                   width: _followIconSize,
                                   height: _followIconSize,
                               }]}
                               resizeMode={'cover'}
                        />
                    )}
                    {/*フォローしてない*/}
                    {!this.state.followed && (
                        <Image source={AppG.user == null ? require('../../resources/images/skelton.png') : require('../../resources/images/03.item_recipe/i_item_like_off.png')}
                               style={[{
                                   backgroundColor: appColors.transparent,
                                   width: _followIconSize,
                                   height: _followIconSize,
                               }]}
                               resizeMode={'cover'}
                        />
                    )}

                </TouchableOpacity>
            </View>
        );
    };

    /**
     * レシピの画像
     */
    _renderRecipeImages = () => {
        if (this.state.recipe == null) {
            return null;
        }

        let _width = AppG.window.width;
        if (ScreenSizeUtil.isWebSize()) {
            _width = appS.webMainContentsSize.width;
        }

        return (
            <View>
                <Carousel
                    data={this.state.recipe?.images}
                    renderItem={this._renderRecipeImage}
                    onSnapToItem={(index: number) => {
                        this.setState({activeRecipeSlide: index})
                    }}
                    width={_width}
                    height={_width / 1.8}
                    autoPlay={this.state.recipe?.images != null && this.state.recipe?.images.length > 1}
                    autoPlayInterval={7000}
                    loop={true}
                    style={{margin: 0}}
                    onProgressChange={(_, absoluteProgress) => {
                        if (absoluteProgress % 1 === 0) {
                            this._dotsProgressIndex = absoluteProgress;
                        }
                    }}
                    enabled={this.state.recipe?.images != null && this.state.recipe?.images.length > 1}
                />
                {this.state.recipe?.images.length >= 2 && (
                    <View style={{
                        marginTop: 8,
                        marginBottom: 8,
                        alignItems: 'center'
                    }}>
                        <AnimatedDotsCarousel
                            length={this.state.recipe?.images.length}
                            currentIndex={this._dotsProgressIndex}
                            maxIndicators={this.state.recipe?.images.length}
                            interpolateOpacityAndColor={false}
                            activeIndicatorConfig={{
                                color: appColors.black,
                                margin: 3,
                                opacity: 1,
                                size: 8,
                            }}
                            inactiveIndicatorConfig={{
                                color: appColors.dotsGray,
                                margin: 3,
                                opacity: 0.5,
                                size: 8,
                            }}
                            decreasingDots={[
                                {
                                    config: { color: appColors.dotsGray, margin: 3, opacity: 0.5, size: 8 },
                                    quantity: 1,
                                },
                            ]}
                        />
                    </View>
                )}
            </View>
        );
    }

    /**
     * レシピ名と一言説明と調理時間
     */
    _renderRecipeNameAndDescription = () => {
        if (this.state.recipe == null) {
            return null;
        }

        let _width = AppG.window.width;
        if (ScreenSizeUtil.isWebSize()) {
            _width = appS.webMainContentsSize.width;
        }
        _width = _width - appS.margins.side * 2;

        return (
            <View style={{margin: appS.margins.side}}>
                <SpacerComponent height={appS.margins.side} />
                {/*レシピ名*/}
                <Text
                    style={{
                        fontFamily: appFont.family.bold,
                        marginTop: 0,
                        fontSize: appFont.size.recipeName,
                        color: appColors.black,
                        fontWeight: "bold"
                    }}
                >
                    {this.state.recipe?.name}
                </Text>
                <SpacerComponent height={appS.margins.betweenContentSmall} />

                {/*一言説明*/}
                <Text
                    style={{
                        fontFamily: appFont.family.default,
                        fontSize: appFont.size.recipeDescription,
                        color: appColors.fontBlack,
                        marginBottom: 0,
                    }}
                >
                    {this.state.recipe?.shortDescription}
                </Text>

                {/*業態*/}
                {!ValidateUtil.isEmptyExact(this.state.recipe?.restaurantLargeFormatId) && (
                    <View style={{
                        flexDirection: "row",
                        marginTop: appS.margins.betweenContentSmall
                    }}>
                        <Text
                            style={{
                                fontFamily: appFont.family.bold,
                                marginTop: 0,
                                fontSize: appFont.size.recipeSeason,
                                color: appColors.fontBlack,
                                width: Platform.OS == 'web'? 60 : 50,
                                fontWeight: "bold"
                            }}>
                            {`業態　`}
                        </Text>
                        <View style={{width: _width - 50, marginTop: 1}}>
                            <LinkComponent
                                title={`${MyAppUtil.getRestaurantFormatName(this.state.recipe?.restaurantLargeFormatId, this._restaurantFormatMasters)}`}
                                // ellipsizeMode={'tail'}
                                // numberOfLines={1}
                                fontSize={appFont.size.recipeSeason}
                                color={appColors.linkBlue}
                                textDecorationLine={'underline'}
                                onPress={() => {
                                    let ids: string[] = [];
                                    this.state.product?.productCategory.forEach(cat => {
                                        ids.push(cat.large.id!.toString());
                                    });
                                    let searchScreenModel: SearchScreenModel = new SearchScreenModel();
                                    searchScreenModel.headerTitle = `${MyAppUtil.getRestaurantFormatName(this.state.recipe?.restaurantLargeFormatId, this._restaurantFormatMasters)}`;
                                    searchScreenModel.recipeRestaurantLargeFormatIds = `|${this.state.recipe?.restaurantLargeFormatId}|`;
                                    searchScreenModel.searchType = 'recipe';
                                    searchScreenModel.tabHidden = true;
                                    if (Platform.OS == 'web') {
                                        this.props.navigation.navigate('SearchResultScreen', searchScreenModel);
                                    } else {
                                        this.props.navigation.push('SearchResultScreen', searchScreenModel);
                                    }
                                }}
                            />
                        </View>

                    </View>
                )}

                {/*季節*/}
                {!ValidateUtil.isEmptyExact(this.state.recipe?.seasonId) && (
                    <View style={{
                        flexDirection: "row",
                        marginTop: appS.margins.betweenContentSmall
                    }}>
                        <Text
                            style={{
                                fontFamily: appFont.family.bold,
                                marginTop: 0,
                                fontSize: appFont.size.recipeSeason,
                                color: appColors.fontBlack,
                                width: Platform.OS == 'web'? 60 : 50,
                                fontWeight: "bold"
                            }}>
                            {`季節　`}
                        </Text>
                        <View style={{width: _width - 50, marginTop: 1}}>
                            <LinkComponent
                                title={`${MyAppUtil.getSeasonById(this.state.recipe?.seasonId)}`}
                                // ellipsizeMode={'tail'}
                                // numberOfLines={1}
                                fontSize={appFont.size.recipeSeason}
                                color={appColors.linkBlue}
                                textDecorationLine={'underline'}
                                onPress={() => {
                                    let ids: string[] = [];
                                    this.state.product?.productCategory.forEach(cat => {
                                        ids.push(cat.large.id!.toString());
                                    });
                                    let searchScreenModel: SearchScreenModel = new SearchScreenModel();
                                    searchScreenModel.headerTitle = `${MyAppUtil.getSeasonById(this.state.recipe?.seasonId)}`;
                                    searchScreenModel.seasonIds = `|${this.state.recipe?.seasonId}|`;
                                    searchScreenModel.searchType = 'recipe';
                                    searchScreenModel.tabHidden = true;
                                    if (Platform.OS == 'web') {
                                        this.props.navigation.navigate('SearchResultScreen', searchScreenModel);
                                    } else {
                                        this.props.navigation.push('SearchResultScreen', searchScreenModel);
                                    }
                                }}
                            />
                        </View>

                    </View>
                )}

                <SpacerComponent height={10}/>

                {/*調理時間など*/}
                <View style={{flexDirection: "row"}}>
                    <Image
                        source={require('../../resources/images/03.item_recipe/i_item_time.png')}
                        style={[{
                            marginTop: 0,
                            width: 25,
                            height: 25,
                        }]}
                        resizeMode={'cover'}
                    />
                    <View style={{flexDirection: "row", marginTop: 3}}>
                        <Text style={{marginTop: Platform.OS == 'android'? -8 : 0, fontFamily: appFont.family.default, marginLeft: 8, fontSize: appFont.size.recipeProductDescription}}>このレシピは</Text>
                        <Text style={{fontFamily: appFont.family.bold, marginLeft: 2, marginTop: -2, fontSize: appFont.size.recipeProductDescription + 2, fontWeight: "bold"}}>約{this.state.recipe.cookingTime}分</Text>
                        <Text style={{marginTop: Platform.OS == 'android'? -8 : 0, fontFamily: appFont.family.default, marginLeft: 2, fontSize: appFont.size.recipeProductDescription}}>で調理できます</Text>
                    </View>
                </View>

                <BorderComponent borderWidth={1} borderColor={appColors.spacerGray} width={_width}/>
            </View>
        )
    }

    /**
     * メーカーと商品
     */
    _renderMakerAndProduct = () => {

        if (this.state.recipe == null) {
            return null;
        }

        // 未ログインで会員限定(PCサイズ)
        if (AppG.user == null && this.state.recipe.userPlanOnly == 1 && ScreenSizeUtil.isWebSize()) {
            return (
                <View>
                    <SpacerComponent height={32}/>
                    <Image
                        source={require('../../resources/images/recipe_userOnly_image_right_pc.png')}
                        style={[{
                            backgroundColor: appColors.white,
                            width: 365,
                            height: 583,
                        }]}
                        resizeMode={'cover'}
                    />
                </View>
            );
        }

        let _width = AppG.window.width;
        if (ScreenSizeUtil.isWebSize()) {
            _width = appS.webSideContentsSize.width;
        }
        _width = _width - appS.margins.side * 2;

        const {navigation} = this.props;
        return (
            <View>
                {this.state.product != null && (
                    <View>
                        {ScreenSizeUtil.isWebSize() && (
                            <SpacerComponent height={32}/>
                        )}
                        {/*メイン商品*/}
                        <TopicTitleComponent title={'このレシピに使われている商品'} subTopic={true} />
                        <SpacerComponent height={appS.margins.betweenContentSmall}/>
                        <View style={{margin: appS.margins.side * 2, marginTop: appS.margins.side}}>
                            <View style={{flexDirection: 'column'}}>
                                <ATagLinkComponent
                                    href={`/ProductDetailScreen/${this.state.product!.id}`}
                                    onPress={() => {
                                        let productDetailScreenModel = new ProductDetailScreenModel();
                                        productDetailScreenModel.productId = this.state.product!.id.toString();
                                        if (Platform.OS == 'web') {
                                            navigation.navigate('ProductDetailScreen', productDetailScreenModel);
                                        } else {
                                            navigation.push('ProductDetailScreen', productDetailScreenModel);
                                        }
                                    }}
                                    view={
                                        <View style={{flexDirection: "row"}}>
                                            {!ValidateUtil.isEmptyArray(this.state.product.images)
                                                && isURL(this.state.product.images[0].url) && (
                                                    <Image
                                                        source={{uri: this.state.product.images[0].url}}
                                                        style={[{
                                                            marginTop: 0,
                                                            borderRadius: 50,
                                                            borderColor: appColors.transparent,
                                                            width: 40,
                                                            height: 40,
                                                        }]}
                                                        resizeMode={'cover'}
                                                        accessibilityLabel={this.state.product?.name}
                                                    />
                                                )}
                                            <View
                                                style={{
                                                    flex: 1,
                                                    marginLeft: 8,
                                                    marginTop: 9,
                                                }}
                                            >
                                                <LinkComponent
                                                    title={this.state.product?.name}
                                                    color={appColors.linkBlue}
                                                    textDecorationLine={'underline'}
                                                    fontSize={appFont.size.recipeProductName}
                                                    ellipsizeMode={"tail"}
                                                    numberOfLines={1}
                                                    onPress={() => {
                                                        let productDetailScreenModel = new ProductDetailScreenModel();
                                                        productDetailScreenModel.productId = this.state.product!.id.toString();
                                                        if (Platform.OS == 'web') {
                                                            navigation.navigate('ProductDetailScreen', productDetailScreenModel);
                                                        } else {
                                                            navigation.push('ProductDetailScreen', productDetailScreenModel);
                                                        }
                                                    }}/>
                                            </View>
                                            {this.state.product?.userPlanOnly == 1 && (
                                                <View
                                                    style={{
                                                        height: 20,
                                                        borderRadius: 50,
                                                        marginLeft: 4,
                                                        marginTop: 10,
                                                        backgroundColor: this.state.product?.userPlanOnly == 1 ? appColors.limeGreen : appColors.transparent,
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                    }}
                                                >
                                                    <Text style={{
                                                        fontFamily: appFont.family.default,
                                                        fontSize: 12,
                                                        color: this.state.product?.userPlanOnly == 1 ? appColors.white : appColors.transparent,
                                                        fontWeight: "bold"
                                                    }}> 会員限定 </Text>
                                                </View>
                                            )}
                                            {this.state.product?.samplePaid == 1 && (
                                                <View
                                                    style={{
                                                        height: 20,
                                                        borderRadius: 50,
                                                        marginLeft: 4,
                                                        marginTop: 10,
                                                        backgroundColor: this.state.product.samplePaid == 1 ? appColors.goldYellow : appColors.transparent,
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                    }}
                                                >
                                                    <Text style={{
                                                        fontFamily: appFont.family.default,
                                                        fontSize: 12,
                                                        color: this.state.product.samplePaid == 1 ? appColors.white : appColors.transparent,
                                                        fontWeight: "bold"
                                                    }}>  有償  </Text>
                                                </View>
                                            )}
                                        </View>
                                    }
                                />
                                <SpacerComponent height={appS.margins.betweenContentSmall}/>
                                <Text
                                    style={{
                                        fontFamily: appFont.family.default,
                                        fontSize: appFont.size.recipeDescription,
                                        color: appColors.fontBlack,
                                    }}
                                >
                                    {this.state.product.description}
                                </Text>
                            </View>

                            {/*メイン以外の商品*/}
                            {!ValidateUtil.isEmptyArray(this.state.products) && this.state.products.map((product: Product, index: number) => {
                                return (
                                    <View key={`products_view_${index}`}>
                                        <SpacerComponent height={16}/>
                                        <View style={{flexDirection: 'column'}}>
                                            <ATagLinkComponent
                                                key={`products_button_${index}`}
                                                href={`/ProductDetailScreen/${product.id}`}
                                                onPress={() => {
                                                    let productDetailScreenModel = new ProductDetailScreenModel();
                                                    productDetailScreenModel.productId = product.id.toString();
                                                    if (Platform.OS == 'web') {
                                                        navigation.navigate('ProductDetailScreen', productDetailScreenModel);
                                                    } else {
                                                        navigation.push('ProductDetailScreen', productDetailScreenModel);
                                                    }
                                                }}
                                                view={
                                                    <View
                                                        key={`products_button_view_${index}`}
                                                        style={{flexDirection: "row"}}>
                                                        {!ValidateUtil.isEmptyArray(product.images)
                                                            && isURL(product.images[0].url) && (
                                                                <Image
                                                                    source={{uri: product.images[0].url}}
                                                                    style={[{
                                                                        marginTop: 0,
                                                                        borderRadius: 50,
                                                                        borderColor: appColors.transparent,
                                                                        width: 40,
                                                                        height: 40,
                                                                    }]}
                                                                    resizeMode={'cover'}
                                                                    accessibilityLabel={product?.name}
                                                                />
                                                            )}
                                                        <View
                                                            style={{
                                                                flex: 1,
                                                                marginLeft: 8,
                                                                marginTop: 9,
                                                            }}
                                                        >
                                                            <LinkComponent
                                                                key={`products_button_text_${index}`}
                                                                title={product?.name}
                                                                color={appColors.linkBlue}
                                                                textDecorationLine={'underline'}
                                                                fontSize={appFont.size.recipeProductName}
                                                                ellipsizeMode={"tail"}
                                                                numberOfLines={1}
                                                                onPress={() => {
                                                                    let productDetailScreenModel = new ProductDetailScreenModel();
                                                                    productDetailScreenModel.productId = product.id.toString();
                                                                    if (Platform.OS == 'web') {
                                                                        navigation.navigate('ProductDetailScreen', productDetailScreenModel);
                                                                    } else {
                                                                        navigation.push('ProductDetailScreen', productDetailScreenModel);
                                                                    }
                                                                }}/>
                                                        </View>
                                                        {product?.userPlanOnly == 1 && (
                                                            <View
                                                                style={{
                                                                    height: 20,
                                                                    borderRadius: 50,
                                                                    marginLeft: 4,
                                                                    marginTop: 10,
                                                                    backgroundColor: product?.userPlanOnly == 1 ? appColors.limeGreen : appColors.transparent,
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                }}
                                                            >
                                                                <Text style={{
                                                                    fontFamily: appFont.family.default,
                                                                    fontSize: 12,
                                                                    color: product?.userPlanOnly == 1 ? appColors.white : appColors.transparent,
                                                                    fontWeight: "bold"
                                                                }}> 会員限定 </Text>
                                                            </View>
                                                        )}
                                                        {product?.samplePaid == 1 && (
                                                            <View
                                                                style={{
                                                                    height: 20,
                                                                    borderRadius: 50,
                                                                    marginLeft: 4,
                                                                    marginTop: 10,
                                                                    backgroundColor: product.samplePaid == 1 ? appColors.goldYellow : appColors.transparent,
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                }}
                                                            >
                                                                <Text style={{
                                                                    fontFamily: appFont.family.default,
                                                                    fontSize: 12,
                                                                    color: product.samplePaid == 1 ? appColors.white : appColors.transparent,
                                                                    fontWeight: "bold"
                                                                }}>  有償  </Text>
                                                            </View>
                                                        )}
                                                    </View>
                                                }
                                            />
                                            <SpacerComponent height={appS.margins.betweenContentSmall}/>
                                            <Text
                                                style={{
                                                    fontFamily: appFont.family.default,
                                                    fontSize: appFont.size.recipeDescription,
                                                    color: appColors.fontBlack,
                                                }}
                                            >
                                                {product.description}
                                            </Text>
                                        </View>
                                    </View>
                                );
                            })}

                        </View>

                        <View style={{margin: appS.margins.side}}>
                            {!ScreenSizeUtil.isWebSize() && <BorderComponent width={_width}/>}
                        </View>
                    </View>

                )}
                {/*提供メーカー*/}
                {this.state.recipe?.maker != null && (
                    <View>
                        <TopicTitleComponent title={'提供メーカー'} subTopic={true} />
                        <ATagLinkComponent
                            href={`/MakerDetailScreen/${this.state.recipe.maker.id}`}
                            onPress={() => {
                                // メーカー詳細画面へ遷移
                                let makerDetailScreenModel: MakerDetailScreenModel = new MakerDetailScreenModel();
                                makerDetailScreenModel.makerId = this.state.recipe!.maker.id.toString();
                                if (Platform.OS == 'web') {
                                    navigation.navigate('MakerDetailScreen', makerDetailScreenModel);
                                } else {
                                    navigation.push('MakerDetailScreen', makerDetailScreenModel);
                                }
                            }}
                            view={
                                <View style={{flexDirection: "row", marginLeft: this._marginSize, marginRight: this._marginSize}}>
                                    {!ValidateUtil.isEmptyExact(this.state.recipe?.maker.logo)
                                        && isURL(this.state.recipe?.maker.logo) && (
                                            <Image
                                                source={{uri: this.state.recipe?.maker.logo}}
                                                style={[{
                                                    marginTop: 0,
                                                    borderRadius: 5,
                                                    borderColor: appColors.transparent,
                                                    width: 40,
                                                    height: 40,
                                                }]}
                                                resizeMode={'cover'}
                                                accessibilityLabel={this.state.recipe?.maker.name}
                                            />
                                        )}

                                    <View
                                        style={{
                                            flex: 1,
                                            marginLeft: 8,
                                            marginTop: 9,
                                        }}
                                    >
                                        <LinkComponent
                                            title={this.state.recipe?.maker.name}
                                            color={appColors.linkBlue}
                                            textDecorationLine={'underline'}
                                            fontSize={appFont.size.recipeProductName}
                                            ellipsizeMode={"tail"}
                                            onPress={() => {
                                                let makerDetailScreenModel: MakerDetailScreenModel = new MakerDetailScreenModel();
                                                makerDetailScreenModel.makerId = this.state.recipe!.maker.id.toString();
                                                if (Platform.OS == 'web') {
                                                    navigation.navigate('MakerDetailScreen', makerDetailScreenModel);
                                                } else {
                                                    navigation.push('MakerDetailScreen', makerDetailScreenModel);
                                                }
                                            }}/>
                                    </View>
                                </View>
                            }
                        />
                        <SpacerComponent height={32}/>
                    </View>
                )}
                {this.state.product != null && (
                    <View style={{margin: appS.margins.side * 2, marginBottom: appS.line.space}}>
                        {/*サンプル依頼ボタン*/}
                        {this._showSampleButton() && (
                            <TouchableOpacity
                                style={[
                                    {
                                        height: appS.button.height,
                                        backgroundColor: appColors.recipeRequestLight,
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        marginBottom: 10,
                                        borderRadius: appS.buttonSize.radius
                                    }
                                ]}
                                onPress={() => {
                                    if (AppG.user == null) {
                                        ValidateUtil.showNoUserAlertAtSampleRequest(this.props.navigation);
                                        return;
                                    }

                                    PopupUtil.showWhatIsSampleRequestModal(
                                        () => {
                                            // サンプル依頼とはの表示
                                            if (this._whatIsSampleRequestModalRef != null) {
                                                this._whatIsSampleRequestModalRef.showModal();
                                            }
                                        },
                                        () => {
                                            // 通常の処理
                                            if (!ValidateUtil.isEnableSampleRequest()) {
                                                this.setState({isShowErrorModal: true});
                                                return;
                                            }

                                            this._goToSelectSampleScreen('request');
                                        }
                                    );
                                }}
                            >
                                <View style={{flexDirection: 'row'}}>
                                    <Text style={{fontFamily: appFont.family.default, color: appColors.white, fontWeight: 'bold', fontSize: appS.button.text}}>今すぐサンプル依頼</Text>
                                    <Image
                                        source={require('../../resources/images/02.menu_general/arrow_W_right.png')}
                                        style={[appS.buttonAllow]}
                                    />
                                </View>
                            </TouchableOpacity>
                        )}

                        {/*サンプルかごに入れるボタン*/}
                        {this._showSampleButton() && (
                            <TouchableOpacity
                                style={[
                                    {
                                        height: appS.button.height,
                                        backgroundColor: appColors.recipeRequestDark,
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        borderRadius: appS.buttonSize.radius
                                    }
                                ]}
                                onPress={() => {
                                    if (AppG.user == null) {
                                        ValidateUtil.showNoUserAlertAtSampleRequest(this.props.navigation);
                                        return;
                                    }

                                    PopupUtil.showWhatIsSampleRequestModal(
                                        () => {
                                            // サンプル依頼とはの表示
                                            if (this._whatIsSampleRequestModalRef != null) {
                                                this._whatIsSampleRequestModalRef.showModal();
                                            }
                                        },
                                        () => {
                                            // 通常の処理
                                            this._goToSelectSampleScreen('cart');
                                        }
                                    );

                                }}
                            >
                                <View style={{flexDirection: 'row'}}>
                                    <Text style={{fontFamily: appFont.family.default, color: appColors.white, fontWeight: 'bold', fontSize: appS.button.text}}>サンプルかごに入れる</Text>
                                    <Image
                                        source={require('../../resources/images/02.menu_general/arrow_W_right.png')}
                                        style={[appS.buttonAllow]}
                                    />
                                </View>
                            </TouchableOpacity>
                        )}

                        {/*サンプル依頼とは*/}
                        {this._showSampleButton() && (
                            <View style={{flex: 1, flexDirection: 'row', marginTop: 8, marginBottom: this._marginSize}}>
                                <View style={{flex: 1}}/>
                                <LinkComponent
                                    title={'サンプル依頼とは？'}
                                    color={appColors.linkBlue}
                                    textDecorationLine={'underline'}
                                    fontSize={14}
                                    onPress={() => {
                                        if (this._whatIsSampleRequestModalRef != null) {
                                            this._whatIsSampleRequestModalRef.showModal();
                                        }
                                    }}/>
                            </View>
                        )}
                    </View>
                )}

                <View style={{margin: appS.margins.side}}>
                    {!ScreenSizeUtil.isWebSize() && (<BorderComponent width={_width}/>)}
                </View>
            </View>
        );
    }

    /**
     * キーワードの表示
     */
    _renderKeywords = () => {

        if (this.state.recipe == null) {
            return null;
        }

        if (ValidateUtil.isEmptyArray(this.state.recipe!.keywords)) {
            return null;
        }

        let _width = AppG.window.width;
        if (ScreenSizeUtil.isWebSize()) {
            _width = appS.webSideContentsSize.width;
        }
        _width = _width - appS.margins.side * 2;

        //キーワードを6個までにして変数で渡す
        let recipeKeywords = this.state.recipe!.keywords!
        if(recipeKeywords.length <= 6) {
            recipeKeywords;
        } else {
            recipeKeywords = this.state.recipe!.keywords!.slice( 0, 6);
        }

        return (
            <View style={{margin: appS.margins.side}}>
                {/*{ScreenSizeUtil.isWebSize() && (<BorderComponent borderWidth={1} borderColor={appColors.spacerGray} width={_width}/>)}*/}
                <TopicTitleComponent title={'キーワード'} subTopic={true}/>
                <SpacerComponent height={appS.margins.betweenContentSmall}/>
                <View>
                    <SearchWordsComponent
                        width={ScreenSizeUtil.isWebSize()? appS.webSideContentsSize.width : null}
                        noMargin={ScreenSizeUtil.isWebSize()}
                        keywords={recipeKeywords}
                        onPress={(keyword) => {
                            const { routeName } = this.props.navigation.state;
                            let searchScreenModel: SearchScreenModel = new SearchScreenModel();
                            searchScreenModel.headerTitle = `${keyword.value}`;
                            searchScreenModel.searchType = 'recipe';
                            searchScreenModel.keyword = `${keyword.value}`;
                            searchScreenModel.searchValue = `${keyword.value}`;
                            searchScreenModel.screenNameFrom = routeName;
                            if (Platform.OS == 'web') {
                                this.props.navigation.navigate('SearchResultScreen', searchScreenModel);
                            } else {
                                this.props.navigation.push('SearchResultScreen', searchScreenModel);
                            }
                        }}/>
                </View>
                {!ScreenSizeUtil.isWebSize() && <BorderComponent borderWidth={1} borderColor={appColors.spacerGray} width={_width}/>}
            </View>
        );
    }

    /**
     * シェアボタン用のURL作成
     */
    _getShareUrl = (clipboard: boolean): string => {
        // return 'https://app.recibase.net/RecipeDetailScreen/1361';
        // @ts-ignore
        const {REACT_APP_DOMAIN_URL} = getEnvVars();
        const { routeName } = this.props.navigation.state;
        if (clipboard) {
            return `${REACT_APP_DOMAIN_URL}/${routeName}/${this.state.recipe?.id}`
        } else {
            return  encodeURI(`${REACT_APP_DOMAIN_URL}/${routeName}/${this.state.recipe?.id}`);
        }
    }

    /**
     * シェアボタン
     */
    _renderShareBtn = () => {
        if (Platform.OS != 'web') {
            return null;
        }

        let _width = AppG.window.width;
        if (ScreenSizeUtil.isWebSize()) {
            _width = appS.webMainContentsSize.width;
        }
        _width = _width - appS.margins.side * 2;

        return (
            <View>
                <ShareComponent
                    shareUrl={this._getShareUrl(false)}
                    copyUrl={this._getShareUrl(true)}
                    hashtags={'ReciBASE,レシベース,メニュー開発,レシピ,業務用,商品,飲食店,料理人'}
                    image_url={encodeURIComponent(this.state.recipe?.images[0].url!)}
                    description={this.state.recipe?.name!}
                    screenName={'RecipeDetailScreen'}
                    recipe={this.state.recipe}
                    product={null}
                    maker={this.state.recipe?.maker != null ? this.state.recipe.maker : null}
                />
                <View style={{marginRight: appS.margins.side, marginBottom: appS.margins.side * 2}}>
                    <BorderComponent width={_width}/>
                </View>
            </View>
        )
    }

    /**
     * カロリーと原価
     */
    _renderIconItems = () => {
        if (this.state.recipe == null) {
            return null;
        }

        let _width = AppG.window.width;
        if (ScreenSizeUtil.isWebSize()) {
            _width = appS.webMainContentsSize.width;
        }
        _width = _width - appS.margins.side * 2;

        const _between = 4;

        return (
            <View  style={{marginLeft: appS.margins.side}}>
                <View style={{marginLeft: appS.margins.side}}>
                    {this.state.recipe.cost != null && (
                        <View style={{flexDirection: "row", marginLeft: -4}}>
                            <Image
                                source={require('../../resources/images/cost_icon.png')}
                                style={[{
                                    marginTop: Platform.OS == 'android'? 3 : -4,
                                    width: 34,
                                    height: 36,
                                }]}
                                resizeMode={'cover'}
                            />
                            <Text style={{fontFamily: appFont.family.default, marginLeft: 4, marginTop: 6, fontSize: appFont.size.recipeProductDescription}}>参考原価　{this.state.recipe.cost}円/1人前</Text>
                        </View>
                    )}

                    <SpacerComponent height={_between} />

                    {this.state.recipe.calorie != null && (
                        <View style={{flexDirection: "row"}}>
                            <Image
                                source={require('../../resources/images/calorie_icon.png')}
                                style={[{
                                    marginTop: Platform.OS == 'android'? 3 : -4,
                                    width: 26,
                                    height: 26,
                                }]}
                                resizeMode={'cover'}
                            />
                            <Text style={{fontFamily: appFont.family.default, marginLeft: 8, marginTop: 3, fontSize: appFont.size.recipeProductDescription}}>カロリー　{this.state.recipe.calorie == 0? 0 : this.state.recipe.calorie}cal</Text>
                        </View>
                    )}
                </View>
                {this.state.recipe != null && this.state.recipe.cost != null && this.state.recipe.calorie != null && (
                    <View style={{marginRight: appS.margins.side}}>
                        <BorderComponent width={_width}/>
                    </View>
                )}
                {!ValidateUtil.isEmptyArray(this.state.recipe!.keywords) && (<SpacerComponent height={appS.margins.betweenTopic}/>)}
            </View>
        );
    }

    /**
     * 材料
     */
    _renderIngredients = () => {
        if (this.state.recipe == null) {
            return null;
        }

        let _width = AppG.window.width;
        if (ScreenSizeUtil.isWebSize()) {
            _width = appS.webMainContentsSize.width;
        }
        _width = _width - appS.margins.side * 2;

        return (
            <View>
                {ValidateUtil.isEmptyExact(this.state.recipe.portionOfFood) && (
                    <TopicTitleComponent title={'材料'} subTopic={true}/>
                )}

                {!ValidateUtil.isEmptyExact(this.state.recipe.portionOfFood) && (
                    <TopicTitleComponent title={`材料（${this.state.recipe.portionOfFood}人前）`} subTopic={true}/>
                )}

                <View style={{
                    margin: appS.margins.side,
                    borderColor: appColors.borderGray,
                    borderWidth: 1,
                }}>
                    {!ValidateUtil.isEmptyArray(this.state.recipe.ingredients) && this.state.recipe.ingredients.map((ingredient: RecipeIngredientsInner, index: number) => {
                        return (
                            <View
                                style={{
                                    flexDirection: 'row',
                                    // height: 40,
                                    borderTopWidth: (index != 0)? 1 : 0,
                                    borderColor: appColors.borderGray,
                                }}
                                key={`ingredients_${index}`}
                            >
                                <View
                                    style={{
                                        width: _width / 2,
                                        backgroundColor: appColors.searchBarBackground,
                                    }}
                                >
                                    <Text
                                        style={{
                                            padding: 10,
                                            width: _width / 2,
                                            fontFamily: appFont.family.bold,
                                            color: appColors.fontBlack,
                                            textAlign: 'center',
                                            fontSize: appFont.size.recipeProductDescription + 1,
                                            fontWeight: "bold"
                                        }}
                                    >{ingredient.name}</Text>
                                </View>
                                <View
                                    style={{
                                        width: _width / 2,
                                    }}
                                >
                                    <View style={{flex:1}}/>
                                    <Text
                                        style={{
                                            padding: Platform.OS == 'android'? 0 : 10,
                                            width: _width / 2,
                                            fontFamily: appFont.family.default,
                                            color: appColors.fontBlack,
                                            textAlign: 'center',
                                            fontSize: appFont.size.recipeProductDescription,
                                        }}
                                    >{ingredient.amount != '0'? ingredient.amount : ''}{ingredient.unit}</Text>
                                    <View style={{flex:1}}/>
                                </View>
                            </View>
                        )
                    })}
                </View>
                <View style={{margin: appS.margins.side}}>
                    <BorderComponent width={_width}/>
                </View>
            </View>
        );
    }

    /**
     * コツ・ポイント
     */
    _renderPoint = () => {
        if (this.state.recipe == null) {
            return null;
        }

        let _width = AppG.window.width;
        if (ScreenSizeUtil.isWebSize()) {
            _width = appS.webMainContentsSize.width;
        }
        _width = _width - appS.margins.side * 2;

        return (
            <View>
                <TopicTitleComponent title={'コツ・ポイント'} subTopic={true}/>
                <View style={{margin: appS.margins.side}}>
                    <View style={{margin: appS.margins.side}}>
                        <Text
                            style={{
                                fontFamily: appFont.family.default,
                                fontSize: appFont.size.recipeDescription,
                                color: appColors.fontBlack,
                            }}
                        >
                            {this.state.recipe.point}
                        </Text>
                    </View>
                    <BorderComponent width={_width}/>
                </View>
            </View>
        );
    }

    /**
     * 調理手順とレシピダウンロードボタン
     */
    _renderWayOfCooking = () => {
        if (this.state.recipe == null) {
            return null;
        }

        let _width = AppG.window.width;
        if (ScreenSizeUtil.isWebSize()) {
            _width = appS.webMainContentsSize.width;
        }
        _width = _width - appS.margins.side * 2;

        if (this.state.recipe.wayOfCooking == null) {
            return null;
        }

        let _collapse = (wayOfCooking: string, index: number) => {
            return (
                <View
                    style={{
                        borderWidth: 1,
                        borderTopColor: index == 0? appColors.borderGray : appColors.transparent,
                        borderColor: appColors.borderGray,
                        width: _width - appS.margins.side * 2,
                    }}
                    key={`wayOfCooking_view_${index}`}
                >
                    <Text
                        style={{
                            padding: 4,
                            fontFamily: appFont.family.default,
                            fontSize: 13,
                            color: appColors.fontBlack,
                        }}
                        key={`wayOfCooking_view_text_${index}`}
                    >
                        {this._writeWayOfCookingNumber(index+1)} {wayOfCooking}
                    </Text>
                </View>
            );
        }

        return (
            <View>
                {/* 調理手順*/}
                <TopicTitleComponent title={'調理手順'} subTopic={true}/>
                <View style={{margin: appS.margins.side}}>
                    <View style={{margin: appS.margins.side}}>

                        {_collapse(this.state.recipe.wayOfCooking[0], 0)}

                        {this.state.recipe.wayOfCooking.length > 0 && (
                            <TouchableOpacity
                                style={{}}
                                onPress={() => {
                                    this._collapsedHowToCook = !this._collapsedHowToCook;
                                    this.setState({isCloseAreaWayOfCooking: !this.state.isCloseAreaWayOfCooking})
                                }}
                            >
                                {!ScreenSizeUtil.isWebSize() && this._collapsedHowToCook && (
                                    <Image source={require('../../resources/images/blur/blur_how_to_cook_sp.png')}
                                           style={{
                                               width: _width - appS.margins.side * 2,
                                               height: ScreenSizeUtil.isWebSize()? 120 : 80,
                                           }}
                                           resizeMode={'stretch'}
                                    />
                                )}
                                {ScreenSizeUtil.isWebSize() && this._collapsedHowToCook && (
                                    <Image source={require('../../resources/images/blur/blur_how_to_cook_pc.png')}
                                           style={{
                                               width: _width - appS.margins.side * 2,
                                               height: ScreenSizeUtil.isWebSize()? 120 : 80,
                                           }}
                                           resizeMode={'stretch'}
                                    />
                                )}

                                {!this._collapsedHowToCook && _collapse(this.state.recipe.wayOfCooking[1], 1)}
                            </TouchableOpacity>
                        )}

                        <Collapsible collapsed={this.state.isCloseAreaWayOfCooking} duration={100}>
                            {!ValidateUtil.isEmptyArray(this.state.recipe.wayOfCooking) && this.state.recipe.wayOfCooking.map((wayOfCooking: string, index: number) => {
                                if (index <= 1) {
                                    return null;
                                }
                                return _collapse(wayOfCooking, index);
                            })}
                        </Collapsible>
                    </View>

                    <View style={{}}>
                        {/*レシピのダウンロードボタン*/}
                        <TouchableOpacity
                            style={[
                                {
                                    margin: appS.margins.side,
                                    height: appS.button.height,
                                    backgroundColor: appColors.recipeDownloadButton,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    borderRadius: appS.buttonSize.radius
                                }
                            ]}
                            onPress={() => {
                                this._downloadRecipe();
                            }}
                        >
                            <View style={{flexDirection: 'row'}}>
                                <Text style={{fontFamily: appFont.family.default, color: appColors.white, fontWeight: 'bold', fontSize: appS.button.text}}>{'レシピをダウンロードする'}</Text>
                                <Image
                                    source={require('../../resources/images/02.menu_general/arrow_W_right.png')}
                                    style={[appS.buttonAllow]}
                                />
                            </View>
                        </TouchableOpacity>

                        {/*<Text style={{color: appColors.gray, fontSize: appStyles.button.small, marginTop: appStyles.line.space}}>※レシピのダウンロードにはプレミアム会員登録が必要です</Text>*/}
                        {!(Platform.OS == 'web' && ScreenSizeUtil.isWebSize()) && (
                            <Text style={{fontFamily: appFont.family.default, color: appColors.fontGray, fontSize: appS.button.small, marginTop: appS.line.space}}>※ダウンロードする際はパソコンからのご利用を推奨いたします</Text>

                        )}
                        <View style={{flex: 1, flexDirection: 'row', marginTop: 8}}>
                            <View style={{flex: 1}}/>
                            <LinkComponent
                                title={'レシピのダウンロードとは？'}
                                color={appColors.linkBlue}
                                fontSize={14}
                                textDecorationLine={'underline'}
                                onPress={() => {
                                    if (this._whatIsRecipeDownloadModalRef != null) {
                                        this._whatIsRecipeDownloadModalRef.showModal();
                                    }
                                }}/>
                        </View>
                    </View>

                    <BorderComponent width={_width} />
                </View>
            </View>
        );
    }

    /**
     * 他のレシピ
     */
    _renderOtherRecipes = () => {
        const {navigation} = this.props;
        if (this.state.recipe == null) {
            return null;
        }

        return (
            <View>
                {/*この商品を使った他のレシピ*/}
                {!ValidateUtil.isEmptyArray(this.state.sameProductRecipes) && (
                    <View>
                        <View style={{flexDirection: "row"}}>
                            <TopicTitleComponent
                                subTopic={true}
                                title={'同じ商品を使った他のレシピ'}
                                more={true}
                                onPress={() => {
                                    if (this.state.product != null && this.state.product.id != null) {
                                        let searchScreenModel: SearchScreenModel = new SearchScreenModel();
                                        searchScreenModel.screenNameFrom = RecipeDetailScreen.name;
                                        searchScreenModel.headerTitle = this.state.product.name;
                                        searchScreenModel.productId = this.state.product.id.toString();
                                        searchScreenModel.searchType = 'recipe';
                                        searchScreenModel.tabHidden = true;
                                        if (Platform.OS == 'web') {
                                            navigation.navigate('SearchResultScreen', searchScreenModel);
                                        } else {
                                            navigation.push('SearchResultScreen', searchScreenModel);
                                        }
                                    }
                                }}
                            />
                        </View>
                        <View style={{margin: appS.margins.side / 2, marginTop: -appS.margins.side / 2}}>
                            <RecipeSameProductListHorizontalScrollComponent
                                navigation={this.props.navigation}
                                recipes={this.state.sameProductRecipes!}
                                border={false}
                                analyticsBtnName={'同じ商品を使った他のレシピ_ボタン'}
                                onPress={null}
                            />
                        </View>

                    </View>
                )}


                {/*TODO よく一緒に見られているレシピ*/}
                {/*<TopicTitleComponent title={'よく一緒に見られているレシピ'}/>*/}
            </View>
        );
    }

    _renderWide = () => {
        const {navigation} = this.props;

        let _width = AppG.window.width;
        if (ScreenSizeUtil.isWebSize()) {
            _width = appS.webMainContentsSize.width;
        }

        return (
            <SafeAreaView
                style={[{height: AppG.window.height, flex: 1, flexDirection: 'column', backgroundColor: appColors.white}]}
                onLayout={(event) => {}}
            >
                <CustomHeaderComponentWide
                    navigation={navigation}
                    searchBar={true}
                />

                <View style={{
                    flex: 1,
                    flexDirection: 'row',
                    alignSelf: 'center',
                    backgroundColor: appColors.backBaseColor,
                }}>
                    <ScrollView
                        horizontal  // 下階層のスクロールイベントを拾えるようにしている
                        style={{
                            width: AppG.window.width,
                            height: AppG.window.height - appS.header.webHeight,
                        }}
                    >
                        <View style={[{flex: 1, flexDirection: 'column', width: AppG.window.width}]}>
                            <KeyboardAwareScrollView style={[{flex: 1, flexDirection: 'column'}]}>
                                <View style={[{flex: 1, flexDirection: ScreenSizeUtil.isWebSize()? 'row': 'column', justifyContent: 'center'}]}>
                                    <View style={[{flexDirection: 'column'}]}>
                                        <View style={{width: _width}}>
                                            {/*未ログイン*/}
                                            {AppG.user == null && this.state.recipe != null && this.state.recipe.userPlanOnly == 1 && this._renderNoLogin()}

                                            {!(AppG.user == null && this.state.recipe != null && this.state.recipe.userPlanOnly == 1) && (
                                                <View style={{flex: 1}}>
                                                    {ScreenSizeUtil.isWebSize() && <WideSubTitleComponent title={'レシピ詳細'} width={_width} shadow={false} type={'recipe'} />}
                                                    {/*レシピ画像*/}
                                                    {this._renderRecipeImages()}
                                                    {/*レシピ名と説明*/}
                                                    {this._renderRecipeNameAndDescription()}
                                                    {/*調理時間とカロリーと原価*/}
                                                    {this._renderIconItems()}
                                                    {/*材料*/}
                                                    {this._renderIngredients()}
                                                    {/*コツ・ポイント*/}
                                                    {this._renderPoint()}
                                                    {/*調理手順とレシピダウンロードボタン*/}
                                                    {this._renderWayOfCooking()}
                                                    {/*他のレシピ*/}
                                                    {this._renderOtherRecipes()}
                                                    <SpacerComponent height={appS.footer.height} />
                                                </View>
                                            )}
                                        </View>
                                    </View>
                                    <View style={{marginLeft: appS.margins.webBetweenMargin, width: appS.webSideContentsSize.width}}>
                                        {/*メーカーと商品*/}
                                        {this._renderMakerAndProduct()}
                                        {/*キーワード*/}
                                        {this._renderKeywords()}
                                        {ScreenSizeUtil.isWebSize() && (
                                            <View style={{}}>
                                                <WideScreenAdComponent navigation={this.props.navigation}/>
                                                <WideScreenSNSComponent navigation={this.props.navigation}/>
                                                {/*シェアボタン*/}
                                                {this._renderShareBtn()}
                                            </View>
                                        )}
                                    </View>
                                </View>
                            </KeyboardAwareScrollView>
                        </View>
                    </ScrollView>
                </View>
                <ModalComponent
                    title={'必要な情報が未登録です'}
                    isShowModal={this.state.isShowErrorModal}
                    onButtonPress={() => {
                        this.setState({isShowErrorModal: false});
                        this.props.navigation.navigate('ProfileScreen');
                    }}
                    height={180}
                    buttonTitle={'プロフィールを編集'}
                    text={'プロフィール画面よりサンプル依頼に必要な情報を入力してください'}
                />
                <Modal2ButtonComponent
                    title={'会員限定機能'}
                    isShowModal={this.state.isShowNotEnableDownload}
                    onButton2Press={() => {
                        this.setState({'isShowNotEnableDownload': false});
                    }}
                    buttonTitle={'無料会員登録'}
                    text={'こちらは会員限定の機能になります'}
                    onButtonPress={() => {
                        this.setState({'isShowNotEnableDownload': false});
                        this.props.navigation.navigate('SignUpScreen');
                    }}
                    button2Title={'閉じる'}/>
                {this._renderBigImages()}
                {/*サンプル依頼とは？*/}
                <WhatIsSampleRequestModalComponent
                    ref={(whatIsSampleRequestModalRef: any) => { this._whatIsSampleRequestModalRef = whatIsSampleRequestModalRef; }}
                    navigation={this.props.navigation}
                />

                {/*レシピのダウンロードとは？*/}
                <WhatIsRecipeDownloadModalComponent
                    ref={(whatIsRecipeDownloadModalRef: any) => { this._whatIsRecipeDownloadModalRef = whatIsRecipeDownloadModalRef; }}
                    navigation={this.props.navigation}
                />
            </SafeAreaView>
        );
    }

    _renderSmall = () => {
        const {navigation} = this.props;

        // @ts-ignore
        const {REACT_APP_DOMAIN_URL} = getEnvVars();
        const { routeName } = this.props.navigation.state;

        return (
            // appStyles.statusBarHiddenSafeAreaでステータスバー分上に上げる
            <SafeAreaView
                // style={[{flex: 1, backgroundColor: appColors.white}, appS.statusBarHiddenSafeArea]}
                style={[{height: AppG.window.height, flex: 1, flexDirection: 'column', backgroundColor: appColors.white}]}
                onLayout={(event) => {}}
            >
                <CustomHeaderComponentSmall
                    navigation={navigation}
                    leftButton={MyAppUtil.selectWebLogoSp()}
                    rightButton={MyAppUtil.selectWebMenuSp()}
                    title={'RECIPE'}
                    statusBarHidden={false}
                    searchBar={true}
                    textColor={appColors.black}
                    barColor={appColors.white}
                    borderBottomColor={appColors.transparent}
                    statusBarType={"dark-content"}
                    onSearchSubmit={(text: string) => {
                        // 検索
                        if (!ValidateUtil.isEmptyExact(text)) {
                            // 検索履歴に追加
                            SearchDataDao.addNew(text);

                            let searchScreenModel: SearchScreenModel = new SearchScreenModel();
                            searchScreenModel.searchType = 'all';
                            searchScreenModel.searchValue = text;

                            if (Platform.OS == 'web') {
                                navigation.navigate('SearchResultScreen', searchScreenModel);
                            } else {
                                navigation.push('SearchResultScreen', searchScreenModel);
                            }
                        }
                    }}
                />
                <SpacerComponent height={2}/>
                <CustomHeaderComponentSmall
                    zIndex={50}
                    navigation={this.props.navigation}
                    title={'レシピ詳細'}
                    textColor={appColors.recipeHeader}
                    barColor={appColors.white}
                    borderBottomColor={appColors.recipeHeader}
                    statusBarHidden={false}
                    leftButton={'back'}
                    rightButton={'share'}
                    shareMessage={`${REACT_APP_DOMAIN_URL}/${routeName}/${this.state.recipe?.id}`}
                    shadow={false}
                    secondHeader={true}
                    shareAnalytics={() => {
                        AnalyticsUtil.sendAnalytics(
                            DOpenEventName.share,
                            AnalyticsEventName.share,  // アナリティクスのイベント名
                            'RecipeDetailScreen',
                            this.state.recipe,
                            null,
                            this.state.recipe?.maker != null ? this.state.recipe.maker : null,
                            null,
                            null,
                            null,
                            null,
                            ShareBtnName.appShare,
                        );
                    }}
                />
                <KeyboardAwareScrollView
                    ref={(spScrollView: any) => { this._spScrollView = spScrollView; }}
                    style={[{flex: 1, flexDirection: 'column', backgroundColor: appColors.backBaseColor}]}
                >
                    {/*未ログイン*/}
                    {AppG.user == null && this.state.recipe != null && this.state.recipe.userPlanOnly == 1 && this._renderNoLogin()}

                    {!(AppG.user == null && this.state.recipe != null && this.state.recipe.userPlanOnly == 1) && (
                        <View>
                            {/*レシピ画像*/}
                            {this._renderRecipeImages()}
                            {/*レシピ名と説明*/}
                            {this._renderRecipeNameAndDescription()}
                            {/*メーカーと商品*/}
                            {this._renderMakerAndProduct()}

                            {/*カロリーと原価*/}
                            {this._renderIconItems()}
                            {/*材料*/}
                            {this._renderIngredients()}
                            {/*調理手順とレシピダウンロードボタン*/}
                            {this._renderWayOfCooking()}
                            {/*コツ・ポイント*/}
                            {this._renderPoint()}
                            {/*キーワード*/}
                            {this._renderKeywords()}
                            {/*シェアボタン*/}
                            {this._renderShareBtn()}
                            {/*他のレシピ*/}
                            {this._renderOtherRecipes()}
                            <SpacerComponent height={appS.footer.height} />
                        </View>
                    )}
                </KeyboardAwareScrollView>
                <ModalComponent
                    title={'必要な情報が未登録です'}
                    isShowModal={this.state.isShowErrorModal}
                    onButtonPress={() => {
                        this.setState({isShowErrorModal: false});
                        this.props.navigation.navigate('ProfileScreen');
                    }}
                    height={250}
                    buttonTitle={'プロフィールを編集'}
                    text={'プロフィール画面よりサンプル依頼に必要な情報を入力してください'}
                />
                <Modal2ButtonComponent
                    title={'会員限定機能'}
                    isShowModal={this.state.isShowNotEnableDownload}
                    onButton2Press={() => {
                        this.setState({'isShowNotEnableDownload': false});
                    }}
                    buttonTitle={'無料会員登録'}
                    text={'こちらは会員限定の機能になります。'}
                    onButtonPress={() => {
                        this.setState({'isShowNotEnableDownload': false});
                        this.props.navigation.navigate('SignUpScreen');
                    }}
                    button2Title={'閉じる'}/>
                {this._renderBigImages()}
                {/*サンプル依頼とは？*/}
                <WhatIsSampleRequestModalComponent
                    ref={(whatIsSampleRequestModalRef: any) => { this._whatIsSampleRequestModalRef = whatIsSampleRequestModalRef; }}
                    navigation={this.props.navigation}
                />

                {/*レシピのダウンロードとは？*/}
                <WhatIsRecipeDownloadModalComponent
                    ref={(whatIsRecipeDownloadModalRef: any) => { this._whatIsRecipeDownloadModalRef = whatIsRecipeDownloadModalRef; }}
                    navigation={this.props.navigation}
                />
            </SafeAreaView>
        );
    }


    /** 描画 */
    render() {
        if (!this.state.isLoaded) {
            return (
                <View style={{flex: 1}}>
                    <IndicatorComponent
                        failTime={60}
                        isLoading={true}
                        reloadFunc={() => {
                            this.componentWillUnmount();
                            this.componentDidMount();
                        }}/>
                </View>
            );
        }

        LinkUtil.addReturnFalseToATag();
        if (ScreenSizeUtil.isWebSize()) {
            // WEBサイズ
            return this._renderWide();
        } else {
            // SPサイズ
            return this._renderSmall();
        }
    }


}
