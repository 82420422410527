import React, {Component} from 'react';
import {Image, Platform, Text, View} from 'react-native';
import {appColors, appFont, appS} from "../../resources/styles/style";
import {SpacerComponent} from "./SpacerComponent";

interface Props {
    text: string,
    fontSize: number,
    isError: boolean,
}

interface State { }

export class ValidationErrorMessageComponent extends Component<Props, State> {
    // Propsのデフォルト値
    static defaultProps = {
        fontSize: appFont.size.error,
    };

    render() {
        const {text, fontSize, isError} = this.props;

        if (!isError) {
            return null;
        }

        return (
            <View>
                <SpacerComponent height={appS.spacer.height.small}/>
                <View style={{flexDirection: "row"}}>
                    <Image source={require('../../resources/images/error_icon.png')}
                           style={[{
                               backgroundColor: 'white',
                               width: 16,
                               height: 16,
                               borderRadius: 50,
                               borderColor: appColors.transparent,
                               marginTop: Platform.OS == 'web'? 4 : 2,
                               marginBottom: 0,
                               marginRight: 0,
                               marginLeft: 0,
                           }]}
                           resizeMode={'cover'}
                    />
                    <Text style={{
                        color: appColors.red,
                        fontSize: fontSize,
                        marginTop: 2,
                        marginBottom: 0,
                        marginRight: 0,
                        marginLeft: 4,
                    }}>
                        {text}
                    </Text>
                </View>
            </View>
        );
    }
}
