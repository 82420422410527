import React, {Component} from 'react';
import {Image, Text, TouchableOpacity, View, Platform} from 'react-native';
import {appColors, appFont} from "../../resources/styles/style";
import ChatConstant from '../constants/ChatConstant';
// @ts-ignore
import isURL from 'validator/lib/isURL';
import DateUtil from "../util/DateUtil";

interface Props {
    image: string,
    companyName: string,
    lastDate: Date,
    text: string,
    messageType: string,    // ChatConstant.MESSAGE_TYPE
    unread: number, // 未読数
    onPress: () => void,    // メッセージ履歴ボタンを押した時の動作
}

interface State {
}

export class ChatViewComponent extends Component<Props, State> {


    // @ts-ignore
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
    }

    /**
     * チャットの最後のメッセージを表示
     * @param message
     * @param messageType ChatConstant.MESSAGE_TYPE
     * @private
     */
    _writeLastMessageText = (message: string, messageType: string) => {
        switch (messageType) {
            case ChatConstant.MESSAGE_TYPE().TEXT:
                return (
                    <View>
                        <Text style={{fontFamily: appFont.family.default, color: appColors.fontGray}} numberOfLines={1} ellipsizeMode='tail'>{message}</Text>
                    </View>
                );
            case ChatConstant.MESSAGE_TYPE().IMAGE:
                return (
                    <View>
                        <Text style={{fontFamily: appFont.family.default, color: appColors.fontGray}}>画像が投稿されました</Text>
                    </View>
                );
            // case ChatConstant.MESSAGE_TYPE().PDF:
            //     return (
            //         <View>
            //             <Text>PDFが投稿されました</Text>
            //         </View>
            //     );
            default:
                return null;
        }
    };

    /**
     * 最終投稿日をTEXTに
     * @param lastDate
     * @private
     */
    _writeLastDate = (lastDate: Date) => {
        return DateUtil.getChatDate(lastDate);
    };

    render() {
        return (
            <View style={{flexDirection: 'row', alignItems: 'center', flex: 1, backgroundColor: appColors.white, marginTop: 15, marginBottom: 15}}>
                <TouchableOpacity
                    style={{flexDirection: 'row', alignItems: 'center', flex: 1}}
                    onPress={this.props.onPress}
                >
                    <Image
                        source={(this.props.image != null && this.props.image != '' && isURL(this.props.image, []))?
                            {uri: this.props.image}: require('../../resources/images/no_image.png')
                        }
                        style={[{
                            marginLeft: 15,
                            width: 44,
                            height: 44,
                            borderRadius: 5,
                        }]}
                        resizeMode='contain'/>
                    <View style={{flex: 1, flexDirection: 'column', marginLeft: 12}}>
                        <View style={{flex: 1, flexDirection: 'row'}}>
                            <View style={{flex: 1}}>
                                <Text style={{fontFamily: appFont.family.default, color: appColors.fontBlack, flex: 1, marginTop: 2, fontSize: appFont.size.default, fontWeight: 'bold'}} numberOfLines={1}>{this.props.companyName}</Text>
                            </View>
                            <View style={{flexDirection: 'row-reverse', width: 90}}>
                                {this.props.unread != null && this.props.unread != 0 && (
                                    <View style={{marginTop: 6, marginRight: 15, backgroundColor: appColors.red, width: 35, height: 15, borderRadius: 50, borderWidth: 1, borderColor: appColors.red, alignItems: 'center', justifyContent: 'center',}}>
                                        <Text style={{fontFamily: appFont.family.default, color: appColors.white, fontSize: 10}}>{this.props.unread}</Text>
                                    </View>
                                )}
                                <Text style={{fontFamily: appFont.family.default, color: appColors.fontGray, textAlign: 'center', marginRight: 8, marginTop: 4, fontSize: appFont.size.small}}>{this._writeLastDate(this.props.lastDate)}</Text>
                            </View>
                        </View>

                        <View style={{flex:1, marginTop: Platform.OS == 'android'? -20 : 0}}>
                            {this._writeLastMessageText(this.props.text, this.props.messageType)}
                        </View>
                    </View>

                </TouchableOpacity>
            </View>
        );
    }
}
