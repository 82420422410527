import React, {Component} from 'react';
import {Text, TouchableOpacity, View} from 'react-native';
import {appColors, appFont, appS} from "../../resources/styles/style";
import ScreenSizeUtil from "../util/ScreenSizeUtil";
import AppG from "../util/AppG";
import {SpacerComponent} from "./SpacerComponent";
import {TopicTitleComponent} from "./TopicTitleComponent";

interface Props {
    title: string,
    text: string,
    buttonTitle: string,
    isShowModal: boolean,
    onButtonPress: () => void,
    height: number,
}

interface State { }

export class ModalComponent extends Component<Props, State> {
    _flameSize = appS.flame.size;    // 左右の margin
    _marginSize = ScreenSizeUtil.isWebSize()? 0 : appS.margin.size;

    // Propsのデフォルト値
    static defaultProps = {
        height: 230,
    };

    /**
     * ボタン一個ありModal
     */
    _renderModal = () => {
        const _modal = () => {
            let _width = ScreenSizeUtil.isWebSize()? AppG.window.width *2 / 5 : AppG.window.width *4 / 5;

            return (
                <View style={{
                    height: this.props.height,
                    width: _width,
                    marginRight: this._marginSize,
                    marginLeft: this._marginSize,
                    backgroundColor: appColors.white,
                }}>
                    <SpacerComponent height={20}/>
                    {!ScreenSizeUtil.isWebSize() && (
                        <View>
                            <View style={{marginLeft: appS.margins.side}}>
                                <TopicTitleComponent title={this.props.title} subTopic={true}/>
                            </View>

                            <Text style={{
                                fontFamily: appFont.family.default,
                                color: appColors.fontGray,
                                fontSize: appFont.size.popupText,
                                marginTop: this._marginSize,
                                marginLeft: this._marginSize,
                                marginRight: this._marginSize
                            }}>{this.props.text}</Text>
                        </View>
                    )}
                    {ScreenSizeUtil.isWebSize() && (
                        <View style={{alignItems: 'center'}}>
                            <Text style={{
                                fontFamily: appFont.family.bold,
                                color: appColors.fontBlack,
                                fontSize: appFont.size.topic,
                                marginTop: this._marginSize,
                                marginLeft: this._marginSize,
                                marginRight: this._marginSize,
                                marginBottom: 10,
                                fontWeight: "bold"
                            }}>{this.props.title}</Text>
                            <Text style={{
                                fontFamily: appFont.family.default,
                                color: appColors.fontGray,
                                fontSize: appFont.size.default,
                                marginTop: this._marginSize,
                                marginLeft: this._marginSize,
                                marginRight: this._marginSize
                            }}>{this.props.text}</Text>
                        </View>
                    )}
                    <SpacerComponent height={ScreenSizeUtil.isWebSize()? 20 :40}/>
                    <TouchableOpacity
                        style={[
                            {
                                height: appS.buttonSize.height,
                                width: appS.buttonSize.width,
                                backgroundColor: appColors.black,
                                alignItems: 'center',
                                justifyContent: 'center',
                                alignSelf: 'center',
                            }
                        ]}
                        onPress={() => {
                            this.props.onButtonPress();
                        }}
                    >
                        <Text style={{
                            fontFamily: appFont.family.bold,
                            color: appColors.white,
                            fontWeight: 'bold',
                            fontSize: appFont.size.buttonText
                        }}>{this.props.buttonTitle}</Text>
                    </TouchableOpacity>
                </View>
            );
        }

        if (this.props.isShowModal) {
            return (
                <View style={{zIndex: 101, position: 'absolute', backgroundColor: appColors.opacityGray, height: AppG.window.height + 60, width: AppG.window.width}}>
                    <View style={{
                        position: 'relative',
                        padding: 32,
                        width: AppG.window.width * 3 / 5,
                        top: AppG.window.height / 2 - 230,
                        left: AppG.window.width / 5,
                        backgroundColor: appColors.transparent,
                        alignItems: 'center',
                    }}>
                        {_modal()}
                    </View>
                </View>
            );
        }

        return null;

    }

    render() {
        return this._renderModal();
    }
}
