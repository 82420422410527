
export default {
    welcome: 'こんにちは',
    success: '成功',
    error: 'エラー',
    errorNetworkTitle: '通信エラー',
    errorNetwork: '通信環境の良いところでもう一度お試しください。',
    errorMail: 'メールアドレスを入力してください',
    errorNoUser: 'そのメールアドレスは登録されていません。',
    errorDuplication: '既に登録済みのアカウントです。',
    errorNothingAttendance: '勤務状況入力が準備されていません。管理者にお問い合わせください。',
    loginFailed: 'ログインに失敗しました。',
    logoutFailed: 'ログアウトに失敗しました。',
    incorrectAuth: 'メールアドレスかパスワードが間違っています。',
    incorrectMail: '正しいメールアドレスではありません。メールアドレスをご確認ください。',
    incorrectPassword: 'パスワードは6文字以上の半角英数字を入力してください',
    incorrectPasswordCheck: '確認用パスワードは6文字以上の半角英数字を入力してください',
    differentPassword: 'パスワードと確認用パスワードが違います',
    mail: 'メールアドレス',
    password: 'パスワード',
    newPassword: '新しいパスワード',
    confirmationPassword: '確認用パスワード',
    login: 'ログイン',
    logout: 'ログアウト',
    loggedOut: 'ログアウトしました',
    signUp: '新規登録',
    forgotPassword: 'パスワードを忘れた方はこちら',
    sendTemporaryPassword: '仮パスワード送信',
    sendTemporaryPasswordText: '仮パスワードを送信しました。\nメールを確認してログインしてください。',
    changeNewPassword: 'パスワード変更',
    changedNewPassword: 'パスワードを変更しました',
    changePassword: 'パスワード変更',
    changedPassword: 'パスワードを変更しました',
    changePasswordText: '登録しているメールアドレスを入力してください。',
    pleasEnterAnItem: '%{item}を入力してください',
    pleasEnterInt: '%{item}は数字を入力してください',
    pleasEnterDate: '%{item}はYYYYMMDDの形式で入力してください（例、2019/05/21 => 2019-05-21）',
    errorDate: '日付が間違っています',
    errorPostalCode: '郵便番号が間違っています',
    next: '次へ',
    register: '登録する',
    cancel: 'キャンセル',
    correct: '訂正する',
    update: '更新する',
    updated: '更新しました',
    goBack: '戻る',
    close: '閉じる',
    edit: '編集する',
    ok: 'OK',
    blaBlaBla: '〇〇',
    month: '月',
    jobType: '職種',
    withdrawal: '退会',
    withdraw: '退会する',
    wantToWithdraw: '本当に退会しますか？',
    withdrawn: '退会しました',
    send: '送信',
    permissionDeniedCameraRoll: '画像の選択やカメラ撮影をするためには、カメラロールへのアクセスを許可してください',


    // Screens(アナリティクス用)
    SpecialScreen: '特集画面',
    CartScreen: 'サンプルかご画面',
    ChangePasswordScreen: 'パスワード変更画面',
    ChatScreen: 'メッセージ画面',
    ChatsScreen: 'チャット一覧画面',
    FollowScreen: 'お気に入り画面',
    HomeScreen: 'ホーム画面',
    HomeScreen_Ranking: 'ホーム画面（ランキングタブ）',
    LoginScreen: 'ログイン画面',
    MakerDetailScreen: 'メーカー詳細画面',
    NewsDetailScreen: 'お知らせ詳細画面',
    NewsScreen: 'お知らせ一覧画面',
    ProductDetailScreen: '商品詳細画面',
    ProductReviewScreen: '商品レビュー画面',
    ProfileInit1Screen: 'ユーザー登録画面1',
    ProfileInit2Screen: 'ユーザー登録画面2',
    ProfileInit5Screen: 'ユーザー登録画面5',
    ProfileInit6Screen: 'ユーザー登録画面6',
    ProfileScreen: 'プロフィール編集画面',
    RecipeDetailScreen: 'レシピ詳細画面',
    ReportScreen: '通報画面',
    ResetPasswordScreen: 'パスワード再送信画面',
    SampleRequestScreen: 'サンプル依頼画面',
    SampleRequestHistoryScreen: 'サンプル依頼履歴画面',
    SampleRequestReviewsScreen: 'サンプルレビュー依頼画面',
    SearchFreeWordProductResultScreen: 'フリーワード検索結果画面（商品）',
    SearchJobCategoryScreen: '業態カテゴリ検索画面（レシピ）',
    SearchKeywordScreen: 'キーワード検索画面',
    SearchKeywordRecipeScreen: 'キーワード検索画面（レシピ）',
    SearchKeywordProductScreen: 'キーワード検索画面（商品）',
    SearchMenuCategoryScreen: 'メニューカテゴリ検索画面（レシピ）',
    SearchResultScreen: '検索結果画面',
    SearchRecipeResultScreen: '検索結果画面（レシピ）',
    SearchProductResultScreen: '検索結果画面（商品）',
    SearchScreen: '詳細検索画面',
    SelectSampleScreen: 'サンプル依頼・かご追加商品の選択画面',
    SettingMenuScreen: 'メニュー・その他画面',
    SignUpScreen: '新規登録画面',
    WebViewScreen: 'ウェブ表示画面',
    WhatIsRecipeDownloadScreen: 'レシピダウンロードとは画面',
    WhatIsSampleRequestScreen: 'サンプル依頼とは画面',
    WhatIsMemberShipScreen: '会員登録でできることは画面',
    WithdrawScreen: '退会画面',
    ReferralCodeScreen: '紹介コード表示画面',
}
