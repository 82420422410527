import React, {Component} from 'react';
import {Image, TouchableOpacity, View} from 'react-native';
import {appS} from "../../../resources/styles/style";

interface Props {
    navigation: any,
}

interface State { }

export class SmallScreenAdComponent extends Component<Props, State> {

    render() {
        return null;

        // const _width = appS.keywordAreaSize.width;
        //
        // return (
        //     <View style={{width: _width,
        //         alignItems: 'center', justifyContent: 'center',}}
        //     >
        //         <TouchableOpacity
        //             onPress={() => {
        //                 alert('TODO');  // TODO
        //             }}>
        //             <Image source={require('../../../resources/images/no_image.png')}
        //                    style={[{
        //                        width: _width,
        //                        height: 80,
        //                    }]}
        //                    resizeMode={'cover'}
        //             />
        //         </TouchableOpacity>
        //     </View>
        // )
    }
}
