import React from 'react'
import {
    Dimensions,
    Image,
    Platform,
    SafeAreaView,
    ScrollView,
    Text,
    TextInput,
    TouchableOpacity,
    View
} from 'react-native'
import {appColors, appFont, appS} from '../../resources/styles/style'
import {BaseScreen} from "./BaseScreen";
import LoginUtil from "../util/LoginUtil";
import {IndicatorComponent} from "../components/IndicatorComponent";
// @ts-ignore
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scrollview';
import LoginDataDao from "../data/dao/local/LoginDataDao";
import {LoginDataEntityModel} from "../data/entityModels/LoginDataEntityModel";
import {Product, ProductApiFactory, User, UserApiFactory, UserUserAttributeEnum,} from "../data/network/swagger-gen";
import AppG from "../util/AppG";
import {AxiosResponse} from "axios";
import {CustomHeaderComponentSmall} from "../components/small/CustomHeaderComponentSmall";
import UrlUtil from "../util/UrlUtil";
import AlertUtil from "../util/AlertUtil";
// @ts-ignore
import isURL from 'validator/lib/isURL';
import {TopicTitleComponent} from "../components/TopicTitleComponent";
import {ProductDetailScreenModel} from "../data/models/screen/ProductDetailScreenModel";
import ValidateUtil from "../util/ValidateUtil";
// @ts-ignore
import {log} from '../../../environment';
import {SampleRequestScreenModel} from "../data/models/screen/SampleRequestScreenModel";
import {ValidationErrorMessageComponent} from "../components/ValidattionErrorMessageComponent";
import ExpoUtil from "../util/ExpoUtil";
import ScreenSizeUtil from "../util/ScreenSizeUtil";
import {CustomHeaderComponentWide} from "../components/wide/CustomHeaderComponentWide";
import {SpacerComponent} from "../components/SpacerComponent";
import {WideScreenAdComponent} from "../components/wide/WideScreenAdComponent";
import AnalyticsUtil, {AnalyticsEventName, DOpenEventName} from "../util/firebase/AnalyticsUtil";
import {Modal2ButtonComponent} from "../components/Modal2ButtonComponent";
import {LoadingFullPageComponent} from "../components/LoadingFullPageComponent";
import I18n from "../../resources/language/i18n";
import {SampleProductRequestDataEntityModel} from "../data/entityModels/SampleProductRequestDataEntityModel";
import SampleProductRequestDataDao from "../data/dao/local/SampleProductRequestDataDao";
import {CartScreenModel} from "../data/models/screen/CartScreenModel";
import {LinkComponent} from "../components/LinkComponent";
import { ATagLinkComponent } from '../components/ATagLinkComponent';
import {WideScreenSNSComponent} from "../components/wide/WideScreenSNSComponent";
import LinkUtil from "../util/LinkUtil";
import {ImageModalComponent} from "../components/ImageModalComponent";
import RNPickerSelect from "react-native-picker-select";
import {PullDownIconComponent} from "../components/PullDownIconComponent";

interface Props {
    navigation: any, // ナビゲーション用
}

interface State {
    resizeWindow: boolean,  // 値に意味はなし。windowサイズの拡大縮小をrenderに送るためのもの
    isLoaded: boolean,
    isSending: boolean,

    // コンテンツ
    isShowSentModal: boolean,
    isShowErrorModal: boolean,
    isShowDisableModal: boolean,
    products: Product[],

    // input
    requestSampleNum: number,   // 依頼数
    existingBusinessPartners: { // 既存仕入先
        type: string,
        text: string,
        errorText: string,
        isError: boolean,
    }[],
}

export class SampleRequestScreen extends BaseScreen<Props, State> {
    _resizeTimer = setInterval(() => {
        // window 拡大縮小を反映させる
        AppG.window = Dimensions.get('window');
        if (AppG.window.width != AppG.windowBefore.width || AppG.window.height != AppG.windowBefore.height) {
            this.setState({'resizeWindow': true});
            AppG.windowBefore = Dimensions.get('window');
        }
        LoginUtil.setRefreshedScreenFlg(false);
    }, 100);

    _loginData = new LoginDataEntityModel();

    _flameSize = ScreenSizeUtil.isWebSize()? AppG.window.width / 10 : 16;    // 左右の margin
    _marginSize = ScreenSizeUtil.isWebSize()? 0 : appS.margin.size;

    // このScreenへ渡すパラム
    _param: SampleRequestScreenModel = new SampleRequestScreenModel();

    // サンプル依頼送信中
    _sendingRequest = false;

    _browserBackListener = (e: any) => {

    };

    constructor(props: any) {
        super(props);
        this.state = {
            resizeWindow: true,  // 値に意味はなし。windowサイズの拡大縮小をrenderに送るためのもの
            isLoaded: false,
            isSending: false,
            isShowSentModal: false,
            // isShowSentModal: true,  // テスト用
            isShowErrorModal: false,
            isShowDisableModal: false,
            products: [],
            requestSampleNum: 1,
            existingBusinessPartners: [],
        };
    }

    /**
     * 画面開いたときのアナリティクス送信
     */
    _sendPvAnalytics = () => {
        AnalyticsUtil.sendAnalytics(
            DOpenEventName.openPage,
            AnalyticsEventName.openPage,  // アナリティクスのイベント名
            'SampleRequestScreen',
            null,
            null,
            null,
            null,
        );
    }

    componentDidMount() {
        if (Platform.OS == 'web') {
            window.addEventListener('popstate', this._browserBackListener);
        }

        LoginUtil.interruptOpenScreen(this.props.navigation,
            () => {
                LoginDataDao.get().then(loginData => {
                    // Web 戻るでも呼ばれる
                    if (Platform.OS == 'web') {
                        // Analytics
                        this._sendPvAnalytics();
                    }

                    // URL Scheme で外部リンクから起動したときの処理と、画面遷移でこの画面を開いたときの処理
                    LoginUtil.setDefaultListener(
                        this.props.navigation,
                        () => {
                            // Webはここにこない
                            if (Platform.OS != 'web') {
                                // Analytics
                                this._sendPvAnalytics();
                            }
                            this._getUser();
                            ExpoUtil.doReloadAppTab(() => {
                            });
                        });
                    if (loginData != null) {
                        this._loginData = loginData;
                        this._loadMasters().then(() => {});
                        this._loadContents().then(() => {});
                        this.setState({'isLoaded': true});
                    }
                });
            });

        this.setState({isLoaded: true});
    }

    componentWillUnmount () {
        clearTimeout(this._resizeTimer);
        if (Platform.OS === 'web') {
            window.removeEventListener('popstate', this._browserBackListener);
        }
        LoginUtil.removeDefaultListener(this.props.navigation);
    };

    componentDidUpdate () {
        // レンダー完了時に呼ばれる
    }

    /**
     * パラムの取得
     * @private
     */
    _getParams = () => {
        let sampleRequestScreenModel: SampleRequestScreenModel = UrlUtil.getParamModel(new SampleRequestScreenModel(), this.props.navigation);
        return sampleRequestScreenModel;
    };

    /**
     * マスターのロード
     * @private
     */
    _loadMasters = async () => {

    };

    /**
     * コンテンツのロード
     * @private
     */
    _loadContents = async () => {

        // 実行
        await Promise.all([
            this._getProducts(),
        ]);
    };

    /**
     * サンプル依頼する商品一覧
     */
    _getProducts = async () => {
        this._param = this._getParams();
        if (ValidateUtil.isEmptyExact(this._param.productIds) || this._param.productIds == '||') {
            return;
        }

        this.setState({isLoaded: false});

        const _productIds = this._param.productIds?.split('|');
        const _idsLength = _productIds!.length;
        let _countIds = 0;
        _productIds!.forEach(value => {
            _countIds = _countIds+ 1;
            if (!ValidateUtil.isEmptyExact(value)) {
                let _existingBusinessPartnerText = '';
                let _existingBusinessPartnerTextType = '問屋';
                SampleProductRequestDataDao.get().then(sampleProductRequestData => {
                    // ローカル保存してある既存仕入先を読み込み
                    if (sampleProductRequestData != null && sampleProductRequestData.existingBusinessPartners != null && sampleProductRequestData.existingBusinessPartners != '' && sampleProductRequestData.existingBusinessPartners != 'null') {
                        _existingBusinessPartnerText = sampleProductRequestData.existingBusinessPartners!;
                    }
                    if (sampleProductRequestData != null && sampleProductRequestData.existingBusinessPartnersType != null && sampleProductRequestData.existingBusinessPartnersType != '' && sampleProductRequestData.existingBusinessPartnersType != 'null') {
                        _existingBusinessPartnerTextType = sampleProductRequestData.existingBusinessPartnersType!;
                    }

                    ProductApiFactory(AppG.getConfiguration(), AppG.getBasePath())
                        .getProduct(
                            value,
                            0,
                            4,
                        ).then((productRes: AxiosResponse<Product>) => {
                        if (productRes != null && productRes.data != null) {
                            this.state.products.push(productRes.data);
                            this.setState({products: this.state.products});

                            this.state.products.forEach(() => {
                                let _existingBusinessPartner = {
                                    type: _existingBusinessPartnerTextType,
                                    text: _existingBusinessPartnerText,
                                    errorText: '',
                                    isError: false,
                                };
                                this.state.existingBusinessPartners.push(_existingBusinessPartner);
                            })

                            this.setState({existingBusinessPartners: this.state.existingBusinessPartners});
                        }
                        if (_countIds >= _idsLength) {
                            this.setState({isLoaded: true});
                        }
                    });
                })

            } else {
                if (_countIds >= _idsLength) {
                    this.setState({isLoaded: true});
                }
            }
        });

    }


    /**
     * ユーザーの再取得
     */
    _getUser = async () => {
        // ユーザー
        const getUser = UserApiFactory(AppG.getConfiguration(), AppG.getBasePath())
            .getUser().then((userRes: AxiosResponse<User>) => {
                if (userRes != null && userRes.data != null) {
                    AppG.user = userRes.data;
                    this.setState({resizeWindow: true});
                }
            });

        // 実行
        await Promise.all([
            getUser
        ]);
    }

    /**
     * プロフィール画面へ
     */
    _goToProfileScreen = () => {
        // プロフィール画面へ
        this.props.navigation.navigate('ProfileScreen');
    }

    /**
     * 送信ボタンのenable
     */
    _disabledSendButton = () => {
        let disabled = false;
        this.state.products.forEach((product, index) => {
            if (this.state.existingBusinessPartners[index] == null || ValidateUtil.isEmptyExact(this.state.existingBusinessPartners[index].type)) {
                disabled = true;
            }
            if (this.state.existingBusinessPartners[index] != null
                && (this.state.existingBusinessPartners[index].type == '問屋' || this.state.existingBusinessPartners[index].type == 'その他')
                && ValidateUtil.isEmptyExact(this.state.existingBusinessPartners[index].text)) {
                disabled = true;
            }
        });
        return disabled;
    }

    /**
     * サンプルリクエストを送信
     */
    _sampleRequest = () => {

        if (this._errorType() != '') {
            this.setState({'isShowErrorModal': true});
            return;
        }

        // 二重送信を制御
        if (this._sendingRequest) {
            return;
        }

        this._sendingRequest = true;
        this.setState({isSending: true});

        let _sampleProductRequests: {
            productId: number
            existingBusinessPartners?: string;
            chatId?: string
        }[] = [];
        let _existingBusinessPartners = '';
        let disableSampleRequest = false;

        // サンプル依頼可能チェック
        this.state.products.forEach((product, index) => {
            if (ValidateUtil.isEmptyExact(product.sampleAmount)) {
                disableSampleRequest = true;
            }
        })

        // 依頼可能で無い場合
        if (disableSampleRequest) {
            this._sendingRequest = false;
            this.setState({isSending: false});
            this.setState({isShowDisableModal: true});
            return;
        }

        this.state.products.forEach((product, index) => {
            if (_existingBusinessPartners == '') {
                // 既存仕入先をローカルに保存
                _existingBusinessPartners = this.state.existingBusinessPartners[index].text;

                const sampleProductRequestDataEntityModel = new SampleProductRequestDataEntityModel();
                sampleProductRequestDataEntityModel.existingBusinessPartners = _existingBusinessPartners;
                sampleProductRequestDataEntityModel.existingBusinessPartnersType = this.state.existingBusinessPartners[index].type;
                SampleProductRequestDataDao.addOrUpdate(sampleProductRequestDataEntityModel);
            }

            let _sampleProductRequest: {
                productId: number
                existingBusinessPartners?: string;
                chatId?: string
            } = {
                productId: product.id,
                existingBusinessPartners: ValidateUtil.isEmptyExact(this.state.existingBusinessPartners[index].text)? this.state.existingBusinessPartners[index].type : this.state.existingBusinessPartners[index].type+'/'+this.state.existingBusinessPartners[index].text,
            }
            _sampleProductRequests.push(_sampleProductRequest);

            // アナリティクス送信
            AnalyticsUtil.sendAnalytics(
                DOpenEventName.requestSample,
                `${AnalyticsEventName.requestSample}_m${product.maker?.id}_p${product.id}`,  // アナリティクスのイベント名
                'SampleRequestScreen',
                null,
                product,
                product.maker,
                null,
            );
        })

        const _json = JSON.stringify(_sampleProductRequests);
        ProductApiFactory(AppG.getConfiguration(), AppG.getBasePath())
            .requestSampleProducts(_json)
            .then(() => {
                ExpoUtil.setShouldReloadAppTab();   // 別タブで必要ならリロードするようにセット
                this.setState({isSending: false});
                this.setState({isShowSentModal: true});
            })
            .catch(e => {
                this._sendingRequest = false;
                this.setState({isSending: false});
                this.setState({isShowSentModal: false});
                // エラー
                AlertUtil.alert(`${I18n.t('errorNetworkTitle')}`, `${I18n.t('errorNetwork')}`);
                log.debug(e);
            })
    }

    /**
     * エラータイプを取得
     */
    _errorType = (): 'noCoin' | 'noProfile' | '' => {
        if (
            AppG.user == null
            || ValidateUtil.isEmptyExact(AppG.user.name)
            || ValidateUtil.isEmptyExact(AppG.user.nameKana)
            || ValidateUtil.isEmptyExact(AppG.user.postalCode)
            || ValidateUtil.isEmptyExact(AppG.user.restaurantState)
            || ValidateUtil.isEmptyExact(AppG.user.restaurantCity)
            || ValidateUtil.isEmptyExact(AppG.user.restaurantStreetAddress)
            || ValidateUtil.isEmptyExact(AppG.user.tell)
            || ValidateUtil.isEmptyExact(AppG.user.nameRestaurant)
        ) {
            return 'noProfile';
        }

        if (AppG.user.userFreeCoin == null
            || this.state.products.length > AppG.user.userFreeCoin) {
            return 'noCoin';
        }

        return '';
    }

    /**
     * サンプル依頼する商品セル
     * @param product
     * @param index
     */
    _renderProductCell = (product: Product, index: number) => {

        let source = require('../../resources/images/no_image.png');
        if (!ValidateUtil.isEmptyArray(product.images) && isURL(product.images[0].url)) {
            source = {uri: product.images[0].url};
        }

        let _width = AppG.window.width;
        if (ScreenSizeUtil.isWebSize()) {
            _width = appS.webMainContentsSize.width;
        }
        _width = _width - appS.margins.side * 2;

        const _imageSize = 80;
        const _checkBoxSize = 20;

        return (
            <View
                key={`a_tag_link_component_view_${index}`}
                style={{
                    margin: appS.margins.side,
                }}>
                <View
                    key={`a_tag_link_component_view_view_${index}`}
                    style={{
                        flex: 1,
                        borderWidth: 1,
                        padding: appS.margins.side,
                        marginLeft: appS.margins.side,
                        marginRight: appS.margins.side,
                        borderColor: appColors.borderGray,
                    }}>

                    <ATagLinkComponent
                        key={`a_tag_link_component_${index}`}
                        href={`/ProductDetailScreen/${product!.id}`}
                        style={{
                            flexDirection: "row",
                            flex: 1,
                        }}
                        onPress={() => {
                            // 商品詳細画面へ遷移
                            let productDetailScreenModel: ProductDetailScreenModel = new ProductDetailScreenModel();
                            productDetailScreenModel.productId = product!.id.toString();
                            if (Platform.OS == 'web') {
                                this.props.navigation.navigate('ProductDetailScreen', productDetailScreenModel);
                            } else {
                                this.props.navigation.push('ProductDetailScreen', productDetailScreenModel);
                            }
                        }}
                        view={
                            <View style={{flexDirection: 'row'}}>
                                <Image source={source}
                                       style={[{
                                           backgroundColor: 'white',
                                           borderRadius: 5,
                                           borderColor: appColors.transparent,
                                           width: _imageSize,
                                           height: _imageSize,
                                       }]}
                                       resizeMode={'cover'}
                                />

                                <View style={{flexDirection: "column", height: _imageSize, marginLeft: appS.margins.side}}>
                                    <Text
                                        style={{
                                            marginTop: 0,
                                            fontFamily: appFont.family.bold,
                                            width: _width - _imageSize - _checkBoxSize * 2 - appS.margins.side * 3,
                                            fontSize: appFont.size.listDescription,
                                            color: appColors.fontBlack,
                                            fontWeight: "bold"
                                        }}
                                        numberOfLines={1}
                                        ellipsizeMode='tail'
                                    >
                                        {product.name}</Text>

                                    <Text
                                        style={{
                                            fontFamily: appFont.family.default,
                                            width: _width - _imageSize - _checkBoxSize * 2 - appS.margins.side * 3,
                                            fontSize: appFont.size.listDescription,
                                            color: appColors.fontGray,
                                            marginTop: Platform.OS == 'android'? 0 : appS.margins.side / 2,
                                        }}
                                        numberOfLines={1}
                                        ellipsizeMode='tail'
                                    >
                                        {product.maker?.name}</Text>
                                    <Text
                                        style={{
                                            fontFamily: appFont.family.default,
                                            width: _width - _imageSize - _checkBoxSize * 2 - appS.margins.side * 3,
                                            fontSize: appFont.size.listDescription,
                                            color: appColors.fontGray,
                                            marginTop: Platform.OS == 'android'? -appS.margins.side : appS.margins.side / 2,
                                        }}
                                        numberOfLines={1}
                                        ellipsizeMode='tail'
                                    >出荷単位 {product.capacity}</Text>
                                    {ValidateUtil.isEmptyExact(product.sampleAmount) && (
                                        <Text
                                            style={{
                                                fontFamily: appFont.family.bold,
                                                width: _width - _imageSize - _checkBoxSize * 2 - appS.margins.side * 3,
                                                fontSize: appFont.size.listDescription,
                                                color: appColors.red,
                                                marginTop: Platform.OS == 'android'? -appS.margins.side : appS.margins.side / 2,
                                                fontWeight: "bold"
                                            }}
                                            numberOfLines={1}
                                            ellipsizeMode='tail'
                                        >サンプル配布を終了しました</Text>
                                    )}

                                </View>
                            </View>
                        }
                    />

                    {/*既存仕入先*/}
                    {this.state.existingBusinessPartners[index] != null && (
                        <View>
                            <View style={{marginLeft: -appS.margins.side, marginRight: -appS.margins.side, marginBottom: Platform.OS == 'ios'? 0: 16}}>
                                <View style={{flex: 1, flexDirection: 'row'}}
                                      key={`product_cell_view_business_partners_view_topic_view_${index}`}>
                                    <View style={{flex: 1}}
                                          key={`product_cell_view_business_partners_view_topic_view_${index}`}>
                                        <TopicTitleComponent
                                            key={`product_cell_view_business_partners_view_topic_topic_${index}`}
                                            title={'既存仕入先'}
                                            subTopic={true}
                                            notRequire={false}
                                        />
                                    </View>
                                    <Text style={{
                                        marginTop: Platform.OS == 'android'? 2: 13,
                                        marginRight: appS.margins.side + 4,
                                        fontFamily: appFont.family.default,
                                        fontSize: appFont.size.moreLink,
                                        color: appColors.fontGray
                                    }}>※必須</Text>
                                </View>
                                <View key={`product_cell_view_business_partners_view_${index}`}
                                      style={{
                                          marginLeft: appS.margins.side,
                                          marginRight: appS.margins.side,
                                      }}>
                                    <View style={{
                                        height: 40,
                                    }}>
                                        <RNPickerSelect
                                            style={appS.picker}
                                            // placeholder={{ label: '¥0', value: 0, key: 0 }}
                                            placeholder={{ label: '選択してください', value: '', key: '' }}
                                            onValueChange={(value) => {
                                                this.state.existingBusinessPartners[index] = {
                                                    type: value,
                                                    text: this.state.existingBusinessPartners[index].text,
                                                    errorText: this.state.existingBusinessPartners[index].errorText,
                                                    isError: this.state.existingBusinessPartners[index].isError
                                                };
                                                this.setState({existingBusinessPartners: this.state.existingBusinessPartners})
                                            }}
                                            value={this.state.existingBusinessPartners[index].type}
                                            items={[
                                                { label: '問屋', value: '問屋', key: 0 },
                                                { label: 'スーパー', value: 'スーパー', key: 1 },
                                                { label: 'EC', value: 'EC', key: 2 },
                                                { label: 'その他', value: 'その他', key: 3 },
                                            ]}
                                            Icon={() => (<PullDownIconComponent/>)}
                                        />
                                    </View>
                                </View>
                            </View>
                            {this.state.existingBusinessPartners[index] != null && (this.state.existingBusinessPartners[index].type == '問屋' || this.state.existingBusinessPartners[index].type == 'その他') && (
                                <View style={{marginLeft: -appS.margins.side, marginRight: -appS.margins.side}}>
                                    <View style={{flex: 1, flexDirection: 'row'}}
                                          key={`product_cell_view_business_partners_view_topic_view_${index}`}>
                                        <View style={{flex: 1}}>
                                            <TopicTitleComponent
                                                key={`product_cell_view_business_partners_view_topic_topic_${index}`}
                                                title={'仕入先名'}
                                                subTopic={true}
                                                require={false}
                                                width={100}
                                            />
                                        </View>
                                        <Text style={{
                                            marginTop: Platform.OS == 'android'? 2: 13,
                                            marginRight: appS.margins.side + 4,
                                            fontFamily: appFont.family.default,
                                            fontSize: appFont.size.moreLink,
                                            color: appColors.fontGray
                                        }}>※必須</Text>
                                    </View>
                                    <View key={`product_cell_view_business_partners_view_${index}`}
                                          style={{
                                              marginLeft: appS.margins.side,
                                              marginRight: appS.margins.side,
                                          }}>
                                        <TextInput
                                            key={`product_cell_view_business_partners_input_${index}`}
                                            style={[appS.inputText, {}]}
                                            autoCapitalize={"none"}
                                            placeholder={'　○○問屋・○○スーパー等'}
                                            placeholderTextColor={appColors.gray}
                                            value={this.state.existingBusinessPartners[index].text}
                                            onChangeText={text => {
                                                this.state.existingBusinessPartners[index] = {
                                                    type: this.state.existingBusinessPartners[index].type,
                                                    text: text,
                                                    errorText: this.state.existingBusinessPartners[index].errorText,
                                                    isError: this.state.existingBusinessPartners[index].isError
                                                };
                                                this.setState({existingBusinessPartners: this.state.existingBusinessPartners})
                                            }}
                                        />
                                        <ValidationErrorMessageComponent
                                            key={`product_cell_view_business_partners_error_${index}`}
                                            text={this.state.existingBusinessPartners[index].errorText}
                                            isError={this.state.existingBusinessPartners[index].isError}
                                        />
                                    </View>
                                </View>
                            )}
                        </View>
                    )}
                </View>
            </View>

        );
    }

    /**
     * 店舗の描画
     */
    _renderRestaurant = () => {
        if (AppG.user == null) {
            return null;
        }

        return (
            <View style={{
                flex: 1,
                margin: appS.margins.side,
            }}>
                <View style={{
                    marginLeft: appS.margins.side,
                    marginRight: appS.margins.side,
                    padding: appS.margins.side,
                    borderWidth: 1,
                    borderColor: appColors.borderGray,
                    backgroundColor: appColors.backgroundGray
                }}>
                    <TopicTitleComponent title={'お届け先住所'} subTopic={true} />

                    {/*郵便番号*/}
                    {!ValidateUtil.isEmptyExact(AppG.user?.postalCode) && (
                        <Text
                            style={{
                                fontFamily: appFont.family.default,
                                marginTop: Platform.OS == 'android'? -10 : appS.line.space,
                                marginLeft: this._marginSize,
                                fontSize: appFont.size.default,
                                color: appColors.fontBlack,
                            }}>
                            {`〒 ${AppG.user.postalCode}`}
                        </Text>
                    )}
                    {ValidateUtil.isEmptyExact(AppG.user?.postalCode) && (
                        <Text
                            style={{
                                fontFamily: appFont.family.default,
                                marginTop: Platform.OS == 'android'? -18 : appS.line.space,
                                marginLeft: this._marginSize,
                                fontSize: appFont.size.default,
                                color: appColors.red,
                            }}>
                            {`郵便番号が未入力です`}
                        </Text>
                    )}

                    {/*住所*/}
                    {!ValidateUtil.isEmptyExact(AppG.user?.restaurantState)
                        && !ValidateUtil.isEmptyExact(AppG.user?.restaurantCity)
                        && !ValidateUtil.isEmptyExact(AppG.user?.restaurantStreetAddress) && (
                            <Text
                                style={{
                                    fontFamily: appFont.family.default,
                                    marginTop: Platform.OS == 'android'? -18 : appS.line.space,
                                    marginLeft: this._marginSize,
                                    fontSize: appFont.size.default,
                                    color: appColors.fontBlack,
                                }}>
                                {`${AppG.user.restaurantState}${AppG.user.restaurantCity}${AppG.user.restaurantStreetAddress}`}
                            </Text>
                        )}
                    {ValidateUtil.isEmptyExact(AppG.user?.restaurantState) && (
                        <Text
                            style={{
                                fontFamily: appFont.family.default,
                                marginTop: Platform.OS == 'android'? -18 : appS.line.space,
                                marginLeft: this._marginSize,
                                fontSize: appFont.size.default,
                                color: appColors.red,
                            }}>
                            {`お届け先住所（都道府県）が未入力です`}
                        </Text>
                    )}
                    {ValidateUtil.isEmptyExact(AppG.user?.restaurantCity) && (
                        <Text
                            style={{
                                fontFamily: appFont.family.default,
                                marginTop: Platform.OS == 'android'? -18 : appS.line.space,
                                marginLeft: this._marginSize,
                                fontSize: appFont.size.default,
                                color: appColors.red,
                            }}>
                            {`お届け先住所（市区町村）が未入力です`}
                        </Text>
                    )}
                    {ValidateUtil.isEmptyExact(AppG.user?.restaurantStreetAddress) && (
                        <Text
                            style={{
                                fontFamily: appFont.family.default,
                                marginTop: Platform.OS == 'android'? -18 : appS.line.space,
                                marginLeft: this._marginSize,
                                fontSize: appFont.size.default,
                                color: appColors.red,
                            }}>
                            {`お届け先住所（番地以降）が未入力です`}
                        </Text>
                    )}

                    {/*屋号*/}
                    {!ValidateUtil.isEmptyExact(AppG.user?.nameRestaurant) && (
                        <Text
                            style={{
                                fontFamily: appFont.family.default,
                                marginTop: Platform.OS == 'android'? -18 : appS.line.space * 2,
                                marginLeft: this._marginSize,
                                fontSize: appFont.size.default,
                                color: appColors.fontBlack,
                            }}>
                            {`${AppG.user.nameRestaurant}`}
                        </Text>
                    )}
                    {ValidateUtil.isEmptyExact(AppG.user?.nameRestaurant) && (
                        <Text
                            style={{
                                fontFamily: appFont.family.default,
                                marginTop: Platform.OS == 'android'? -18 : appS.line.space * 2,
                                marginLeft: this._marginSize,
                                fontSize: appFont.size.default,
                                color: appColors.red,
                            }}>
                            {`店舗名が未入力です`}
                        </Text>
                    )}

                    {/*電話番号*/}
                    {!ValidateUtil.isEmptyExact(AppG.user?.tell) && (
                        <Text
                            style={{
                                fontFamily: appFont.family.default,
                                marginTop: Platform.OS == 'android'? -18 : appS.line.space * 2,
                                marginLeft: this._marginSize,
                                fontSize: appFont.size.default,
                                color: appColors.fontBlack,
                            }}>
                            {`${AppG.user.tell}`}
                        </Text>
                    )}
                    {ValidateUtil.isEmptyExact(AppG.user?.tell) && (
                        <Text
                            style={{
                                fontFamily: appFont.family.default,
                                marginTop: Platform.OS == 'android'? -18 : appS.line.space * 2,
                                marginLeft: this._marginSize,
                                fontSize: appFont.size.default,
                                color: appColors.red,
                            }}>
                            {`電話番号が未入力です`}
                        </Text>
                    )}
                </View>
            </View>
        );
    }

    _renderCoinArea = () => {
        if (AppG.user == null) {
            return null;
        }

        return (
            <View style={{
                margin: appS.margins.side,
            }}>
                <View style={{
                    marginLeft: appS.margins.side,
                    marginRight: appS.margins.side,
                    padding: appS.margins.side,
                    borderWidth: 1,
                    borderColor: appColors.borderGray,
                    backgroundColor: appColors.backgroundGray
                }}>

                    {/*所持コイン*/}
                    {AppG.user?.userFreeCoin != null && (
                        <View style={{flex: 1}}>
                            <View style={{flexDirection: "row", marginTop: appS.line.space, alignSelf: 'center'}}>
                                <Text
                                    style={{
                                        fontFamily: appFont.family.bold,
                                        fontSize: appFont.size.coin,
                                        color: appColors.coin,
                                        fontWeight: "bold"
                                    }}>
                                    {`所持コイン`}
                                </Text>
                                <Image
                                    source={require('../../resources/images/02.menu_general/i_coin.png')}
                                    style={{
                                        margin: 2,
                                        width: appS.coin.iconSize,
                                        height: appS.coin.iconSize,
                                    }}
                                />
                                <Text
                                    style={{
                                        fontFamily: appFont.family.bold,
                                        fontSize: appFont.size.coin,
                                        color: appColors.coin,
                                        fontWeight: "bold"
                                    }}>
                                    {`${AppG.user?.userFreeCoin}`}
                                </Text>
                            </View>
                            <View style={{flexDirection: "row", marginTop: appS.line.space, alignSelf: 'center'}}>
                                <Text
                                    style={{
                                        fontFamily: appFont.family.bold,
                                        fontSize: 22,
                                        color: appColors.coin,
                                        fontWeight: "bold"
                                    }}>
                                    {`${this.state.products.length}コイン`}
                                </Text>
                                <Text
                                    style={{
                                        marginTop: 6,
                                        fontFamily: appFont.family.bold,
                                        fontSize: appFont.size.coin,
                                        color: appColors.fontBlack,
                                        fontWeight: "bold"
                                    }}>
                                    {`消費します`}
                                </Text>
                            </View>
                            {this._errorType() == 'noProfile' && (
                                <View style={{flexDirection: "row", marginTop: Platform.OS == 'android'? -20 : appS.line.space, alignSelf: 'center'}}>
                                    <Text
                                        style={{
                                            marginTop: Platform.OS == 'android'? 0 : 6,
                                            fontFamily: appFont.family.bold,
                                            fontSize: appFont.size.coin,
                                            color: appColors.red,
                                            fontWeight: "bold"
                                        }}>
                                        {`必要な情報が未登録です`}
                                    </Text>
                                </View>
                            )}
                            {this._errorType() == 'noCoin' && (
                                <View style={{flexDirection: "row", marginTop: Platform.OS == 'android'? -20 : appS.line.space, alignSelf: 'center'}}>
                                    <Text
                                        style={{
                                            marginTop: Platform.OS == 'android'? 0 : 6,
                                            fontFamily: appFont.family.bold,
                                            fontSize: appFont.size.coin,
                                            color: appColors.red,
                                            fontWeight: "bold"
                                        }}>
                                        {`コインが足りません`}
                                    </Text>
                                </View>
                            )}
                        </View>
                    )}
                </View>
            </View>
        );
    }

    /**
     * 依頼ボタンの描画
     */
    _renderSendButton = () =>{
        return (
            <View>
                <View style={{
                    margin: appS.margins.side
                }}>
                    {/*サンプル依頼ボタン*/}
                    <TouchableOpacity
                        style={[
                            {
                                width: appS.buttonSize.width,
                                height: appS.buttonSize.height,
                                backgroundColor: (this._errorType() != '' || this._disabledSendButton())? appColors.gray: appColors.buttonOrange,
                                alignItems: 'center',
                                justifyContent: 'center',
                                alignSelf: 'center',
                                borderRadius: appS.buttonSize.radius
                            }
                        ]}
                        disabled={this._errorType() != '' || this._disabledSendButton() || this.state.isSending || this.state.isShowSentModal}
                        onPress={() => {
                            this._sampleRequest();
                        }}
                    >
                        <View style={{flexDirection: 'row', alignSelf: 'center'}}>
                            <Text style={{
                                lineHeight: appS.buttonSize.height,
                                textAlignVertical: 'center',
                                textAlign: 'center',
                                fontFamily: appFont.family.bold,
                                fontSize: appFont.size.buttonText,
                                color: appColors.white,
                                fontWeight: "bold"
                            }}>依頼を確定する</Text>
                            <Image
                                source={require('../../resources/images/02.menu_general/arrow_W_right.png')}
                                style={{
                                    marginTop: 20,
                                    width: 12,
                                    height: 12,
                                }}
                            />
                        </View>
                    </TouchableOpacity>

                    {this._errorType() == 'noCoin' && (
                        <View style={{margin: appS.margins.side}}>
                            <View style={{flexDirection: "row"}}>
                                <Text
                                    style={{
                                        fontFamily: appFont.family.bold,
                                        fontSize: appFont.size.default,
                                        color: appColors.fontBlack,
                                        fontWeight: "bold"
                                    }}>
                                    {`コインを増やすには`}
                                </Text>
                            </View>
                            <View style={{flexDirection: "row"}}>
                                <LinkComponent
                                    title={'レビューを返信'}
                                    color={appColors.linkBlue}
                                    textDecorationLine={'underline'}
                                    fontSize={appFont.size.small}
                                    onPress={() => {
                                        let cartScreenModel: CartScreenModel = new CartScreenModel();
                                        cartScreenModel.enableBack = '1';
                                        if (Platform.OS == 'web') {
                                            this.props.navigation.navigate('SampleRequestHistoryScreen', cartScreenModel);
                                        } else {
                                            this.props.navigation.push('SampleRequestHistoryScreen', cartScreenModel);
                                        }

                                    }}/>
                                <Text
                                    style={{
                                        fontFamily: appFont.family.default,
                                        fontSize: appFont.size.small,
                                        color: appColors.fontBlack,
                                    }}>
                                    {`し、翌月までお待ちください。`}
                                </Text>
                            </View>
                        </View>
                    )}
                    {this._errorType() == 'noProfile' && (
                        <View style={{margin: appS.margins.side}}>
                            <View style={{flexDirection: "row"}}>
                                <Text
                                    style={{
                                        fontFamily: appFont.family.bold,
                                        fontSize: appFont.size.default,
                                        color: appColors.fontBlack,
                                        fontWeight: "bold"
                                    }}>
                                    {`サンプル依頼するには`}
                                </Text>
                            </View>
                            <View style={{flexDirection: "row"}}>
                                <LinkComponent
                                    title={'プロフィール画面'}
                                    color={appColors.linkBlue}
                                    textDecorationLine={'underline'}
                                    fontSize={appFont.size.small}
                                    onPress={() => {
                                        if (Platform.OS == 'web') {
                                            this.props.navigation.navigate('ProfileScreen');
                                        } else {
                                            this.props.navigation.push('ProfileScreen');
                                        }

                                    }}/>
                                <Text
                                    style={{
                                        fontFamily: appFont.family.default,
                                        fontSize: appFont.size.small,
                                        color: appColors.fontBlack,
                                    }}>
                                    {`よりサンプル依頼に必要な情報を入力してください。`}
                                </Text>
                            </View>
                        </View>
                    )}

                    <View style={{margin: appS.margins.side}}>
                        <Text style={{fontFamily: appFont.family.bold, color: appColors.red, fontSize: appFont.size.small, fontWeight: "bold"}}>有償サンプルは代引き対応となります。詳細は商品画面をご確認ください。</Text>
                        <Text style={{fontFamily: appFont.family.bold, color: appColors.red, fontSize: appFont.size.small, fontWeight: "bold"}}>サンプル送付時にカタログ等が同包される場合が御座います。</Text>
                    </View>
                </View>
            </View>
        );
    }

    _renderWide = () => {
        const {navigation} = this.props;

        return (
            <SafeAreaView
                style={[{height: AppG.window.height, flex: 1, flexDirection: 'column', backgroundColor: appColors.backBaseColor}]}
                onLayout={(event) => {}}
            >
                <CustomHeaderComponentWide
                    navigation={navigation}
                    searchBar={true}
                />

                <View style={{
                    flex: 1,
                    flexDirection: 'row',
                    alignSelf: 'center',
                    backgroundColor: appColors.backBaseColor,
                }}>
                    <ScrollView
                        horizontal  // 下階層のスクロールイベントを拾えるようにしている
                        style={{
                            width: AppG.window.width,
                            height: AppG.window.height - appS.header.webHeight,
                        }}
                    >
                        <View style={[{flex: 1, flexDirection: 'column', width: AppG.window.width}]}>
                            <KeyboardAwareScrollView style={[{flex: 1, flexDirection: 'column'}]}>
                                <View style={[{flex: 1, flexDirection: 'row', justifyContent: 'center', alignSelf: 'center',}]}>
                                    <View style={[{flexDirection: 'column'}]}>
                                        <View style={{width: appS.webMainContentsSize.width}}>
                                            {/*商品一覧*/}
                                            {this.state.products.map((product: Product, index: number) => {
                                                return this._renderProductCell(product, index);
                                            })}
                                        </View>
                                        <SpacerComponent height={appS.footer.height} />
                                    </View>
                                    <View style={{marginLeft: appS.margins.webBetweenMargin, width: appS.webSideContentsSize.width}}>
                                        {/*店舗情報*/}
                                        {this._renderRestaurant()}
                                        <SpacerComponent height={20} />
                                        {/*依頼ボタン*/}
                                        {this._renderSendButton()}
                                        {ScreenSizeUtil.isWebSize() && (
                                            <View style={{}}>
                                                <WideScreenAdComponent navigation={this.props.navigation}/>
                                                <WideScreenSNSComponent navigation={this.props.navigation}/>
                                            </View>
                                        )}
                                    </View>
                                </View>
                            </KeyboardAwareScrollView>
                        </View>
                    </ScrollView>
                </View>
                {/*送信したよModal*/}
                <Modal2ButtonComponent
                    title={'サンプル依頼が完了しました'}
                    isShowModal={this.state.isShowSentModal}
                    onButton2Press={() => {
                        this._sendingRequest = false;
                        this.setState({'isShowSentModal': false});
                        this.props.navigation.navigate('SampleRequestHistoryScreen');
                    }}
                    buttonTitle={'メッセージでやりとりする'}
                    text={'サンプル依頼が完了しました。別途メーカーよりご連絡させていただきます。'}
                    onButtonPress={() => {
                        this._sendingRequest = false;
                        this.setState({'isShowSentModal': false});
                        this.props.navigation.navigate('ChatsScreen');
                    }}
                    button2Title={'閉じる'}/>
                <ImageModalComponent
                    imageSource={require('../../resources/images/disable_sample_request.png')}
                    marginTop={(AppG.window.height - ((AppG.window.height / 2) * 1.3)) / 2}
                    width={(AppG.window.height / 2.3)}
                    height={(AppG.window.height / 2.3)}
                    isShowModal={this.state.isShowDisableModal}
                    onClosePress={() => {
                        // 閉じて戻る
                        this.setState({isShowDisableModal: false});
                    }}/>
                {this.state.isSending && (
                    <LoadingFullPageComponent/>
                )}
            </SafeAreaView>
        );
    }

    _renderSmall = () => {
        return (
            // appStyles.statusBarHiddenSafeAreaでステータスバー分上に上げる
            <SafeAreaView
                style={[{height: AppG.window.height, flex: 1, flexDirection: 'column', backgroundColor: appColors.white}]}
                onLayout={(event) => {}}
            >
                <CustomHeaderComponentSmall
                    navigation={this.props.navigation}
                    title={'サンプル依頼確認'}
                    textColor={appColors.fontBlack}
                    barColor={appColors.white}
                    shadow={true}
                    // statusBarHidden={true}
                    statusBarType={"dark-content"}
                    leftButton={'back'}
                />
                <KeyboardAwareScrollView style={[{flex: 1, flexDirection: 'column', backgroundColor: appColors.backBaseColor}]}>
                    {!ValidateUtil.isEmptyArray(this.state.products) && (
                        <View>
                            {/*商品一覧*/}
                            {this.state.products.map((product: Product, index: number) => {
                                return this._renderProductCell(product, index);
                            })}
                            {/*店舗情報*/}
                            {this._renderRestaurant()}
                            {/*コイン*/}
                            {this._renderCoinArea()}
                            {/*依頼ボタン*/}
                            {this._renderSendButton()}

                            <SpacerComponent height={32}/>
                        </View>
                    )}
                </KeyboardAwareScrollView>
                {/*送信したよModal*/}
                <Modal2ButtonComponent
                    title={'サンプル依頼が完了しました'}
                    isShowModal={this.state.isShowSentModal}
                    onButton2Press={() => {
                        this._sendingRequest = false;
                        this.setState({'isShowSentModal': false});
                        this.props.navigation.navigate('SampleRequestHistoryScreen');
                    }}
                    height={Platform.OS == 'web'? 350 : 300}
                    buttonTitle={'メッセージでやりとりする'}
                    text={'サンプル依頼が完了しました。別途メーカーよりご連絡させていただきます。'}
                    onButtonPress={() => {
                        this._sendingRequest = false;
                        this.setState({'isShowSentModal': false});
                        this.props.navigation.navigate('ChatsScreen');
                    }}
                    button2Title={'閉じる'}
                />
                <ImageModalComponent
                    imageSource={require('../../resources/images/disable_sample_request.png')}
                    marginTop={(AppG.window.height - ((AppG.window.height / 2) * 1.3)) / 2}
                    width={(AppG.window.height / 2.3)}
                    height={(AppG.window.height / 2.3)}
                    isShowModal={this.state.isShowDisableModal}
                    onClosePress={() => {
                        // 閉じて戻る
                        this.setState({isShowDisableModal: false});
                    }}/>
                {this.state.isSending && (
                    <LoadingFullPageComponent/>
                )}
            </SafeAreaView>
        );
    }


    /** 描画 */
    render() {
        if (!this.state.isLoaded) {
            return (
                <View style={{flex: 1}}>
                    <IndicatorComponent
                        failTime={60}
                        isLoading={true}
                        reloadFunc={() => {
                            this.componentWillUnmount();
                            this.componentDidMount();
                        }}/>
                </View>
            );
        }

        LinkUtil.addReturnFalseToATag();
        if (ScreenSizeUtil.isWebSize()) {
            // WEBサイズ
            return this._renderWide();
        } else {
            // SPサイズ
            return this._renderSmall();
        }

    };


}
