// import * as Analytics from 'expo-firebase-analytics';
import {
    DataApiFactory,
    Keyword,
    Maker,
    Product,
    ProductCategory, ProductCategoryLargeMediumInner,
    Recipe,
    RecipeCategory,
    RecipeIngredientsInner, Special,
} from "../../data/network/swagger-gen";
import AppG from "../AppG";
import ValidateUtil from "../ValidateUtil";
import I18n from "../../../resources/language/i18n";
// @ts-ignore
import {log} from '../../../../environment';
import ExpoUtil from "../ExpoUtil";
import {Platform} from "react-native";
import UrlUtil from "../UrlUtil";
import LoginDataDao from "../../data/dao/local/LoginDataDao";
import AnalyticsDataDao from "../../data/dao/local/AnalyticsDataDao";
import AlertUtil from "../AlertUtil";
import LoginUtil from "../LoginUtil";

/**
 * シェアボタンの種類
 */
export enum ShareBtnName {
    line = 'line',
    twitter = 'twitter',
    facebook = 'facebook',
    pinterest = 'pinterest',
    linkCopy = 'link_copy',
    appShare = 'app_share'  // アプリのシェアボタン
}

/**
 * GoogleAnalytics イベントの種類
 */
// https://developers.google.com/analytics/devguides/collection/gtagjs/events?hl=ja
export enum AnalyticsEventName {
    sentMailBeforeSignUp = 'sent_mail_before_sign_up',  // 仮登録メール送信
    signUp = 'sign_up',  // 本登録完了（初回登録フロー完了）
    openPage = 'screen_view',  // ページを開いたとき
    searchRecipe = 'search_recipe',  // レシピ検索
    searchProduct = 'search_product', // 商品検索
    searchMaker = 'search_maker',   // メーカー検索
    keyword = 'keyword',    // キーワードタップ
    follow = 'add_wis',    // フォローした
    unfollow = 'rem_wis',  // フォローを止めた
    cartIn = 'add_car',  // カートに入れた
    cartOut = 'rem_car',    // カートから取り除いた
    requestSample = 'rs',
    cancelTheMembership = 'can_mem',    // 退会
    category = 'cat',   // カテゴリ収集用（cat_maker_xxx_recipe_xxx）or（cat_maker_xxx_product_xxx）sendCategoryAnalyticsにて利用
    notification = 'n',  // 通知
    openSpecial = 'open_special',   // 特集を開いた
    fromSpecial = 'from_special',    // 特集から来た
    visit = 'visit', // 訪問
    login = 'login', // ログイン
    session = 'session', // セッション(アプリ=>メモリ解放まで、Web=>30分アクセス無かったら)
    inquiry = 'inquiry',    // メーカー問い合わせ
    share = 'share', // シェア
    modalShow = 'modal_show',   // モーダル表示
    modalTap = 'modal_tap',  // モーダルタップ
    openEc = 'open_ec'  // EC画面を開いた
}

/**
 * 自社Analytics イベントの種類
 */
export enum DOpenEventName {
    sentMailBeforeSignUp = 'sent_mail_before_sign_up',  // 仮登録メール送信
    signUp = 'sign_up',  // 本登録完了（初回登録フロー完了）
    openPage = 'screen_view',  // ページを開いたとき
    searchRecipe = 'search_recipe',  // レシピ検索
    searchProduct = 'search_product', // 商品検索
    searchMaker = 'search_maker',   // メーカー検索
    keyword = 'keyword',    // キーワードタップ
    follow = 'add_follow',    // フォローした
    unfollow = 'remove_follow',  // フォローを止めた
    cartIn = 'add_cart',  // カートに入れた
    cartOut = 'remove_cart',    // カートから取り除いた
    requestSample = 'request_sample',
    cancelTheMembership = 'cancel_membership',    // 退会
    category = 'category',   // カテゴリ収集用（cat_maker_xxx_recipe_xxx）or（cat_maker_xxx_product_xxx）sendCategoryAnalyticsにて利用
    notification = 'notification',  // 通知
    openSpecial = 'open_special',   // 特集を開いた
    fromSpecial = 'from_special',    // 特集から来た
    visit = 'visit', // 訪問(アプリ=>メモリ解放まで、Web=>別ドメインに移動するまで)
    login = 'login', // ログイン(アプリ=>メモリ解放まで、Web=>別ドメインに移動するまでの会員専用)
    session = 'session', // セッション(アプリ=>メモリ解放まで、Web=>30分アクセス無かったら)
    inquiry = 'inquiry',    // メーカー問い合わせ
    share = 'share', // シェア
    modalShow = 'modal_show',   // モーダル表示
    modalTap = 'modal_tap',  // モーダルタップ
    openEc = 'open_ec'  // EC画面を開いた
}

class AnalyticsUtil {

    sendAnalytics = (
        dOpenEventName: DOpenEventName | null,
        eventName: AnalyticsEventName | string,  // アナリティクスのイベント名、レシピ、商品、メーカーの場合は screen_view とは別に recipe_<recipe_id> の形で保存する
        screenName: string | null = null, // // `HomeScreen`
        recipe: Recipe | null = null,
        product: Product | null = null,
        maker: Maker | null = null,
        search: string | null = null,    // enum AnalyticsSearchType より 例） freeWord=hoge fuga&keyword=hoo foo
        keyword: string | null = null,
        special: Special | null = null,
        excludeHistory: boolean | null = null,  // このscreenName を履歴から除外
        btnName: string | null = null, // シェアボタンやモーダル名
    ) => {
        // alert(AppG.screenHistory[0])
        let _eventName: string = eventName;
        let _searchType: string = '';
        switch (eventName) {
            case AnalyticsEventName.searchRecipe:
                _eventName = 'search';
                _searchType = 'recipe';
                break;
            case AnalyticsEventName.searchProduct:
                _eventName = 'search';
                _searchType = 'product';
                break;
            case AnalyticsEventName.searchMaker:
                _eventName = 'search';
                _searchType = 'maker';
                break;
            case AnalyticsEventName.openPage:
                if (screenName != null) {
                    _eventName = `${_eventName}_${screenName}`;
                }
            default:
                break;
        }

        // 履歴の作成
        if (dOpenEventName == DOpenEventName.openPage && (excludeHistory == null || !excludeHistory)) {
            if (Platform.OS == 'web' && ValidateUtil.isWpDomain(document.referrer) && document.referrer != '' && AppG.isWriteReferrer) {
                // WEB で特集が遷移元の場合 history に先に特集を追加 (App の場合 WebView 内で追加)
                AppG.screenHistory.unshift(`【特集】${document.referrer}`);
                AppG.isWriteReferrer = false;   // ドメインが変わるまでは、他のページ行っても履歴にreferrerを書かない
            } else if (Platform.OS == 'web' && !ValidateUtil.isMyDomain(document.referrer) && document.referrer != '' && AppG.isWriteReferrer) {
                // WEB でReciBASE内が遷移元でない場合 history にreferrerを追加
                AppG.screenHistory.unshift(`${document.referrer}`);
                AppG.isWriteReferrer = false;   // ドメインが変わるまでは、他のページ行っても履歴にreferrerを書かない
            } else if (Platform.OS == 'web' && document.referrer == '') {
                // rb_sh で履歴に追加
                let result = location.href.match(/\?.*rb_sh=.+/);
                if (result != null) {
                    AppG.screenHistory.unshift(`${result}`);
                }
            } else {
                // アプリは何もしない
            }
            if (
                // screenName != 'HomeScreen'
                screenName != 'SpecialScreen'
                && screenName != 'WebViewScreen'
                // && screenName != 'SearchKeywordResultScreen'
                // && screenName != 'SearchFreeWordResultScreen'
            ) {
                let option = ``;
                if (screenName == 'RecipeDetailScreen' && recipe != null) {
                    option = `_${recipe.name}`;
                }
                if (screenName == 'ProductDetailScreen' && product != null) {
                    option = `_${product.name}`;
                }
                if (screenName == 'MakerDetailScreen' && maker != null) {
                    option = `_${maker.name}`;
                }
                if (screenName == 'HomeScreen') {
                    // ホーム画面の履歴はTOPタブに統一
                    AppG.screenHistory.unshift(`【ホーム画面】TOPタブ`);
                } else {
                    if (screenName == null) {
                        AppG.screenHistory.unshift(`${option}`);
                    } else {
                        AppG.screenHistory.unshift(`${I18n.t(screenName)}${option}`);
                    }
                }
            }
        }
        if (dOpenEventName == DOpenEventName.searchRecipe) {
            const option = `${search}`;
            // @ts-ignore
            AppG.screenHistory.unshift(`${I18n.t(screenName)}_${option}`);
        } else if (dOpenEventName == DOpenEventName.searchProduct) {
            const option = `${search}`;
            if (screenName == null) {
                AppG.screenHistory.unshift(`${option}`);
            } else {
                AppG.screenHistory.unshift(`${I18n.t(screenName)}_${option}`);
            }
        }
        if (dOpenEventName == DOpenEventName.modalShow) {
            const option = `${btnName}`;
            if (screenName == null) {
                AppG.screenHistory.unshift(`${option}`);
            } else {
                AppG.screenHistory.unshift(`${I18n.t(screenName)}_${option}`);
            }
        }

        if (AppG.isTestAccounts()) {
            // テストアカウントでpv除外
            return;
        }

        // レシピ
        let _ingredients: string = '';  // 材料
        let _recipeCategory: string = ''; // レシピカテゴリー
        let _recipeKeyword: string = '';    // レシピキーワード
        if (recipe != null ) {
            // 材料
            if (!ValidateUtil.isEmptyArray(recipe.ingredients)) {
                recipe.ingredients.forEach((ingredient: RecipeIngredientsInner) => {
                    if (_ingredients == '') {
                        _ingredients = `${ingredient.name}`
                    } else {
                        _ingredients = `${_ingredients},${ingredient.name}`
                    }
                })
            }

            // レシピカテゴリー
            if (!ValidateUtil.isEmptyArray(recipe.recipeCategory)) {
                recipe.recipeCategory!.forEach((recipeCategory: RecipeCategory) => {
                    if (_recipeCategory == '') {
                        _recipeCategory = `${recipeCategory.name}`
                    } else {
                        _recipeCategory = `${_recipeCategory},${recipeCategory.name}`
                    }
                })
            }

            // レシピキーワード
            if (!ValidateUtil.isEmptyArray(recipe.keywords)) {
                recipe.keywords!.forEach((keyword: Keyword) => {
                    if (_recipeKeyword == '') {
                        _recipeKeyword = `${keyword.value}`
                    } else {
                        _recipeKeyword = `${_recipeKeyword},${keyword.value}`
                    }
                })
            }
        }

        // 商品
        let _productLargeCategory: string = '';
        let _productMediumCategory: string = '';
        let _productStandards: string = ''; // 規格
        let _productAllergies: string = ''; // アレルギー
        let _productNutrition: string = ''; // 栄養成分
        let _productPlaceOfOrigin: string = ''; // 原料原産地
        if (product != null) {
            // 商品カテゴリー
            if (!ValidateUtil.isEmptyArray(product.productCategory)) {
                product.productCategory!.forEach((productCategory: ProductCategory) => {
                    // 大分類
                    if (_productLargeCategory == '') {
                        _productLargeCategory = `${productCategory.large.name}`
                    } else {
                        _productLargeCategory = `${_productLargeCategory},${productCategory.large.name}`
                    }
                    // 中分類
                    productCategory.large.medium?.forEach((medium: ProductCategoryLargeMediumInner) => {
                        if (_productMediumCategory == '') {
                            _productMediumCategory = `${medium.name}`
                        } else {
                            _productMediumCategory = `${_productMediumCategory},${medium.name}`
                        }
                    })
                })
            }

            // 規格
            if (!ValidateUtil.isEmptyArray(product.standards)) {
                product.standards!.forEach((standard) => {
                    if (_productStandards == '') {
                        _productStandards = `${standard}`
                    } else {
                        _productStandards = `${_productStandards},${standard}`
                    }
                })
            }

            // アレルギー
            if (!ValidateUtil.isEmptyArray(product.allergies)) {
                product.allergies!.forEach((allergy) => {
                    if (_productAllergies == '') {
                        _productAllergies = `${allergy}`
                    } else {
                        _productAllergies = `${_productAllergies},${allergy}`
                    }
                })
            }

            // 栄養成分
            if (!ValidateUtil.isEmptyArray(product.nutrition)) {
                product.nutrition!.forEach((nutrition) => {
                    if (_productNutrition == '') {
                        _productNutrition = `${nutrition}`
                    } else {
                        _productNutrition = `${_productNutrition},${nutrition}`
                    }
                })
            }

            // 原料原産地
            if (!ValidateUtil.isEmptyArray(product.placeOfOrigin)) {
                product.placeOfOrigin!.forEach((placeOfOrigin) => {
                    if (_productPlaceOfOrigin == '') {
                        _productPlaceOfOrigin = `${placeOfOrigin}`
                    } else {
                        _productPlaceOfOrigin = `${_productPlaceOfOrigin},${placeOfOrigin}`
                    }
                })
            }
        }

        // メーカー
        let _makerProductLargeCategory: string = '';
        let _makerProductMediumCategory: string = '';
        if (maker != null) {
            // 商品カテゴリー
            if (!ValidateUtil.isEmptyArray(maker.categoriesOfProduct)) {
                if (Array.isArray(maker.categoriesOfProduct)) {
                    maker.categoriesOfProduct!.forEach((categoriesOfProduct: ProductCategory) => {
                        // 大分類
                        if (_makerProductLargeCategory == '') {
                            _makerProductLargeCategory = `${categoriesOfProduct.large.name}`
                        } else {
                            _makerProductLargeCategory = `${_makerProductLargeCategory},${categoriesOfProduct.large.name}`
                        }
                        // 中分類
                        if (Array.isArray(categoriesOfProduct.large.medium)) {
                            categoriesOfProduct.large.medium?.forEach((medium: ProductCategoryLargeMediumInner) => {
                                if (_makerProductMediumCategory == '') {
                                    _makerProductMediumCategory = `${medium.name}`
                                } else {
                                    _makerProductMediumCategory = `${_makerProductMediumCategory},${medium.name}`
                                }
                            })
                        } else {
                            // TODO 修正依頼 categoriesOfProduct.large.medium が Array でないパターン
                            if (_makerProductMediumCategory == '') {
                                // @ts-ignore
                                _makerProductMediumCategory = `${categoriesOfProduct.large.medium.name}`
                            } else {
                                // @ts-ignore
                                _makerProductMediumCategory = `${_makerProductMediumCategory},${categoriesOfProduct.large.medium.name}`
                            }
                        }

                    })
                } else {
                    // TODO 修正依頼 maker.categoriesOfProduct が Array でないパターン
                    // 大分類
                    if (_makerProductLargeCategory == '') {
                        // @ts-ignore
                        _makerProductLargeCategory = `${maker.categoriesOfProduct.large.name}`
                    } else {
                        // @ts-ignore
                        _makerProductLargeCategory = `${_makerProductLargeCategory},${maker.categoriesOfProduct.large.name}`
                    }

                    // 中分類
                    // @ts-ignore
                    if (Array.isArray(maker.categoriesOfProduct.large.medium)) {
                        // @ts-ignore
                        maker.categoriesOfProduct.large.medium?.forEach((medium: ProductCategoryLargeMediumInner) => {
                            if (_makerProductMediumCategory == '') {
                                _makerProductMediumCategory = `${medium.name}`
                            } else {
                                _makerProductMediumCategory = `${_makerProductMediumCategory},${medium.name}`
                            }
                        })
                    } else {
                        // TODO 修正依頼 maker.categoriesOfProduct.large.medium が Array でないパターン
                        if (_makerProductMediumCategory == '') {
                            // @ts-ignore
                            _makerProductMediumCategory = `${maker.categoriesOfProduct.large.medium?.name}`
                        } else {
                            // @ts-ignore
                            _makerProductMediumCategory = `${_makerProductMediumCategory},${maker.categoriesOfProduct.large.medium?.name}`
                        }
                    }
                }

            }
        }

        let _properties: any = {};
        let _userProperties: any = {};
        if (AppG.user != null) {
            const getAreaId = (majorRestaurantAreaId: number) => {
                if (majorRestaurantAreaId <= 7) {
                    return '北海道・東北';
                }
                if (8 <= majorRestaurantAreaId && majorRestaurantAreaId <= 14) {
                    return '関東';
                }
                if (15 <= majorRestaurantAreaId && majorRestaurantAreaId <= 23) {
                    return '中部';
                }
                if (24 <= majorRestaurantAreaId && majorRestaurantAreaId <= 30) {
                    return '関西';
                }
                if (31 <= majorRestaurantAreaId && majorRestaurantAreaId <= 39) {
                    return '中国・四国';
                }
                if (40 <= majorRestaurantAreaId) {
                    return '沖縄・九州';
                }
            }
            _eventName = `${_eventName}_u${!ValidateUtil.isEmptyExact(AppG.user.id)? AppG.user.id : ''}`
            // user
            _userProperties['user_id'] = `${!ValidateUtil.isEmptyExact(AppG.user.id)? '_'+AppG.user.id : ''}`;
            _userProperties['user_attribute'] = `${!ValidateUtil.isEmptyExact(AppG.user.userAttribute)? AppG.user.userAttribute : ''}`;    // 会員属性（一般ユーザー(normal)/飲食人(cook)）
            _userProperties['user_gender'] = `${!ValidateUtil.isEmptyExact(AppG.user.gender)? AppG.user.gender : ''}`;  // 性別
            _userProperties['user_birthday'] = `${!ValidateUtil.isEmptyExact(AppG.user.birthday)? AppG.user.birthday : ''}`;  // 生年月日
            _userProperties['user_r_l_l_id'] = `${!ValidateUtil.isEmptyExact(AppG.user.restaurantLargeLocationId)? '_'+AppG.user.restaurantLargeLocationId : ''}`; // 店舗所在地（大）のマスターID
            _userProperties['user_r_m_l_id'] = `${!ValidateUtil.isEmptyExact(AppG.user.restaurantMediumLocationId)? '_'+AppG.user.restaurantMediumLocationId : ''}`; // 店舗所在地（中）のマスターID
            _userProperties['user_r_state'] = `${!ValidateUtil.isEmptyExact(AppG.user.restaurantState)? AppG.user.restaurantState : ''}`;  // 店舗住所（都道府県）
            _userProperties['user_r_city'] = `${!ValidateUtil.isEmptyExact(AppG.user.restaurantCity)? AppG.user.restaurantCity : ''}`;  // 店舗住所（市町村）
            _userProperties['user_r_l_f_id'] = `${!ValidateUtil.isEmptyExact(AppG.user.restaurantLargeFormatId)? '_'+AppG.user.restaurantLargeFormatId : ''}`; // 店舗業態（大）のマスターID
            _userProperties['user_r_m_f_id'] = `${!ValidateUtil.isEmptyExact(AppG.user.restaurantMediumFormatId)? '_'+AppG.user.restaurantMediumFormatId : ''}`; // 店舗業態（中）のマスターID
            _userProperties['user_n_r_id'] = `${!ValidateUtil.isEmptyExact(AppG.user.numOfRestaurantId)? '_'+AppG.user.numOfRestaurantId : ''}`;    // 店舗規模のマスターID
            _userProperties['user_m_r_a_id'] = `${!ValidateUtil.isEmptyExact(AppG.user.majorRestaurantAreaId)? '_'+AppG.user.majorRestaurantAreaId : ''}`; // 主要店舗展開エリアのマスターID
            _userProperties['m_m_r_area_id'] = `${!ValidateUtil.isEmptyExact(AppG.user.restaurantLargeLocationId)? getAreaId(AppG.user.restaurantLargeLocationId!) : ''}`; // 関東とか関西とか
            _userProperties['user_lunch_service'] = `${!ValidateUtil.isEmptyExact(AppG.user.lunchService)? '_'+AppG.user.lunchService : ''}`;  // ランチ営業有無
            _userProperties['lunch_service_jp'] = `${!ValidateUtil.isEmptyExact(AppG.user.lunchService)? (AppG.user.lunchService==1?'有':'無') : ''}`;  // ランチ営業有無
            _userProperties['user_c_u_p_id'] = `${!ValidateUtil.isEmptyExact(AppG.user.customerUnitPriceId)? '_'+AppG.user.customerUnitPriceId : ''}`;  // 客単価のマスターID
            _userProperties['user_n_seats'] = `${!ValidateUtil.isEmptyExact(AppG.user.numberOfSeats)? '_'+AppG.user.numberOfSeats : ''}`;    // 席数
            _userProperties['user_plan'] = `${!ValidateUtil.isEmptyExact(AppG.user.userPlan)? AppG.user.userPlan : ''}`;    // userPlan

            _properties['user_id'] = `${!ValidateUtil.isEmptyExact(AppG.user.id)? '_'+AppG.user.id : ''}`;
            _properties['user_plan'] = `${!ValidateUtil.isEmptyExact(AppG.user.userPlan)? AppG.user.userPlan : ''}`;    // userPlan

            // 落ちる原因？
            // Analytics.setUserId(`${!ValidateUtil.isEmptyExact(AppG.user.id)? AppG.user.id : ''}`);
        } else {
            _userProperties['user_plan'] = 'notLogin';    // userPlan
            _properties['user_id'] = '';
            _properties['user_plan'] = 'notLogin';    // userPlan
        }
        if (AppG.userAnalyticsJp != null) {
            _userProperties['attribute_jp'] = `${!ValidateUtil.isEmptyExact(AppG.userAnalyticsJp.userAttribute)? AppG.userAnalyticsJp.userAttribute : ''}`;    // 会員属性（一般ユーザー(normal)/飲食人(cook)）
            _userProperties['gender_jp'] = `${!ValidateUtil.isEmptyExact(AppG.userAnalyticsJp.gender)? AppG.userAnalyticsJp.gender : ''}`;  // 性別
            _userProperties['age'] = `${!ValidateUtil.isEmptyExact(AppG.userAnalyticsJp.age)? '_'+AppG.userAnalyticsJp.age : ''}`;  // 年齢
            _userProperties['ages'] = `${!ValidateUtil.isEmptyExact(AppG.userAnalyticsJp.ages)? '_'+AppG.userAnalyticsJp.ages : ''}`;  // 年代
            _userProperties['restaurant_l_location'] = `${!ValidateUtil.isEmptyExact(AppG.userAnalyticsJp.restaurantLargeLocation)? AppG.userAnalyticsJp.restaurantLargeLocation : ''}`; // 店舗所在地（大）のマスターID
            _userProperties['restaurant_m_location'] = `${!ValidateUtil.isEmptyExact(AppG.userAnalyticsJp.restaurantMediumLocation)? AppG.userAnalyticsJp.restaurantMediumLocation : ''}`; // 店舗所在地（中）のマスターID
            _userProperties['restaurant_l_format'] = `${!ValidateUtil.isEmptyExact(AppG.userAnalyticsJp.restaurantLargeFormat)? AppG.userAnalyticsJp.restaurantLargeFormat : ''}`; // 店舗業態（大）のマスターID
            _userProperties['restaurant_m_format'] = `${!ValidateUtil.isEmptyExact(AppG.userAnalyticsJp.restaurantMediumFormat)? AppG.userAnalyticsJp.restaurantMediumFormat : ''}`; // 店舗業態（中）のマスターID
            _userProperties['num_of_restaurant'] = `${!ValidateUtil.isEmptyExact(AppG.userAnalyticsJp.numOfRestaurant)? '_'+AppG.userAnalyticsJp.numOfRestaurant : ''}`;    // 店舗規模のマスターID
            _userProperties['m_restaurant_area'] = `${!ValidateUtil.isEmptyExact(AppG.userAnalyticsJp.majorRestaurantArea)? AppG.userAnalyticsJp.majorRestaurantArea : ''}`; // 主要店舗展開エリアのマスターID
            _userProperties['customer_unit_price'] = `${!ValidateUtil.isEmptyExact(AppG.userAnalyticsJp.customerUnitPrice)? '_'+AppG.userAnalyticsJp.customerUnitPrice : ''}`;  // 客単価のマスターID

        }
        _userProperties['user_os'] = Platform.OS;

        if (screenName != null) {
            // Screen
            _properties['screen_name'] =`${screenName}`;  // `${HomeScreen.name}`
            _properties['screen_name_jp'] =`${I18n.t(screenName)}`;    // スクリーン日本語名
            // Analytics.setCurrentScreen(`${screenName}`);
        }

        if (recipe != null) {
            // UrlUtil.getShortUrl(recipe.images[0].url);
            // Recipe
            _properties['recipe_id'] = `_${recipe.id}`;
            _properties['recipe_name'] = `${recipe.name}`;    // レシピ名
            _properties['recipe_image'] = `${(!ValidateUtil.isEmptyArray(recipe.images)? recipe.images[0].url: '')}`; // TODO イメージ画像（メインだけ）
            _properties['recipe_cooking_time'] = `${(!ValidateUtil.isEmptyExact(recipe.cookingTime)? '_'+recipe.cookingTime: '')}`;    // 調理時間
            _properties['recipe_calorie'] = `${(!ValidateUtil.isEmptyExact(recipe.calorie)? '_'+recipe.calorie: '')}`;    // カロリー
            _properties['recipe_cost'] = `${(!ValidateUtil.isEmptyExact(recipe.cost)? '_'+recipe.cost: '')}`;   // 原価
            _properties['recipe_ingredients'] = `${_ingredients}`;   // 材料名
            _properties['recipe_category'] = `${_recipeCategory}`;    // レシピカテゴリー（複数）
            _properties['recipe_keywords'] = `${_recipeKeyword}`;    // レシピキーワード
        }

        if (product != null) {
            // Product
            _properties['product_id'] = `_${product.id}`;
            _properties['product_name'] = `${product.name}`;
            _properties['product_image'] = `${!ValidateUtil.isEmptyArray(product.images)? product.images[0].url: ''}`; // TODO イメージ画像（メインだけ）
            _properties['product_large_category'] =`${_productLargeCategory}`;    // 商品カテゴリー（大）ID（複数）
            _properties['product_medium_category'] =`${_productMediumCategory}`;    // 商品カテゴリー（中）ID（複数）
            _properties['product_capacity'] = `${!ValidateUtil.isEmptyExact(product.capacity)? '_'+product.capacity: ''}`;   // 商品内容量
            _properties['product_container'] = `${!ValidateUtil.isEmptyExact(product.container)? '_'+product.container: ''}`;   // 容器
            _properties['product_b_b_date'] = `${!ValidateUtil.isEmptyExact(product.bestBeforeDate)? '_'+product.bestBeforeDate: ''}`;   // 賞味期限
            _properties['product_temperature'] = `${!ValidateUtil.isEmptyExact(product.temperature)? '_'+product.temperature: ''}`;   // 温度帯
            _properties['product_standards'] =`${_productStandards}`;    // 規格（複数）
            _properties['product_allergies'] =`${_productStandards}`;    // アレルギー（複数）
            _properties['product_nutrition'] =`${_productNutrition}`;    // 栄養成分（複数）
            _properties['product_place_origin'] =`${_productPlaceOfOrigin}`;    // 原料原産地（複数）
        }

        if (maker != null) {
            // Maker
            _properties['maker_id'] = `_${maker.id}`;
            _properties['maker_name'] = `${maker.name}`;
            _properties['maker_name_kana'] = `${maker.nameKana}`;
            _properties['maker_logo'] = `${!ValidateUtil.isEmptyExact(maker.logo)? maker.logo: ''}`;   // TODO メーカーロゴ画像
            _properties['maker_num_of_recipes'] = `${!ValidateUtil.isEmptyExact(maker.numOfRecipes)? '_'+maker.numOfRecipes: ''}`;   // メーカーレシピ数
            _properties['maker_num_of_products'] = `${!ValidateUtil.isEmptyExact(maker.numOfProduct)? '_'+maker.numOfProduct: ''}`;   // メーカー商品数
            _properties['maker_p_l_category'] = `${_makerProductLargeCategory}`;    // メーカーの商品カテゴリー（大）（複数）
            _properties['maker_p_m_category'] = `${_makerProductMediumCategory}`;    // メーカーの商品カテゴリー（中）（複数）
        }

        if (search != null) {
            // 検索
            _properties['search_type'] = `${_searchType}`;
            _properties['search'] = `${search}`;
        }

        if (!ValidateUtil.isEmptyExact(keyword)) {
            // キーワードタップ、入力
            _properties['keyword'] = `${keyword}`;
        }

        if (special != null) {
            // 検索
            _properties['special_id'] = `_${special.id}`;
            _properties['special_name'] = `${special.name}`;
            _properties['special_genre'] = `${special.genre}`;
            _properties['special_url'] = `${special.url}`;
            _properties['special_type'] = `${special.type}`;
            _properties['special_maker_name'] = `${special.makerName}`;
        }

        if (btnName != null) {
            _properties['share_b_name'] = `${btnName}`;
        }

        const _send = () => {
            // Analytics.setUserProperties(_userProperties).then(() => {
            //     // 送信
            //     Analytics.logEvent(_eventName, _properties).then(() => {
            //         if (AppG.user != null && AppG.user.id == AppG.testUserId && AppG.user.name == 'ああ') {
            //             // if (AppG.user != null && AppG.user.id == AppG.testUserId && AppG.user.name == AppG.testUserName) {
            //             // user id = 1, 名前 = あ の時のデバッグコード
            //             AlertUtil.alert('成功', `${_eventName}: ${_userProperties['user_id']} / ${_userProperties['user_os']}`);
            //         }
            //
            //         const result = _eventName.match(/^maker_.+$/);
            //         if (result != null && AppG.user != null && AppG.user.id == AppG.testUserId && AppG.user.name == 'あ') {
            //             // user id = 1, 名前 = あ の時のデバッグコード
            //             AlertUtil.alert('成功', `${_eventName}: ${_userProperties['user_id']} / ${_userProperties['user_os']}`);
            //         }
            //         log.debug(_eventName);
            //         log.debug(_properties);
            //         log.debug(_userProperties);
            //     }).catch(e => {
            //         if (AppG.user != null && AppG.user.id == AppG.testUserId && AppG.user.name == 'ああ') {
            //             // user id = 1, 名前 = あ の時のデバッグコード
            //             AlertUtil.alert('エラー', `${_eventName}: ${_userProperties['user_id']} / ${_userProperties['user_os']} \n ${e}`);
            //         }
            //         const result = _eventName.match(/^maker_.+$/);
            //         if (result != null && AppG.user != null && AppG.user.id == AppG.testUserId && AppG.user.name == 'あ') {
            //             // user id = 1, 名前 = あ の時のデバッグコード
            //             AlertUtil.alert('エラー', `${_eventName}: ${_userProperties['user_id']} / ${_userProperties['user_os']} \n ${e}`);
            //         }
            //         log.debug(_eventName);
            //         log.debug(_properties);
            //         log.debug(_userProperties);
            //         log.debug(e);
            //     });
            // });
        }

        /** 送信するとこ */
        const func = () => {
            _properties['analytics_hash'] = AppG.analyticsHash;
            _userProperties['analytics_hash'] = AppG.analyticsHash;

            let channel = 'reci-base-dev';
            if (ExpoUtil.isProduction()) {
                channel = 'reci-base';
            }

            if (dOpenEventName != null) {
                log.debug("################ d open #####################");

                // if (dOpenEventName == DOpenEventName.openPage) {
                //     alert(`screen_view / ${AppG.analyticsHash}`);
                // }
                // if (dOpenEventName == DOpenEventName.visit) {
                //     alert(`visit / ${AppG.analyticsHash}`);
                // }

                // log.debug('-----------------------AppG.screenHistory------------------------');
                // log.debug(AppG.screenHistory);

                // /api/d/open
                DataApiFactory(AppG.getAnalyticsConfiguration(), AppG.getAnalyticsBasePath())
                    .dOpen(
                        channel,
                        AppG.analyticsHash ?? undefined,
                        _userProperties['user_plan'],
                        dOpenEventName,
                        undefined,
                        undefined,
                        _properties['screen_name_jp'],
                        _properties['screen_name'],
                        btnName == null? undefined : btnName,  // shareで使う
                        AppG.screenHistory[0] == null ? undefined : AppG.screenHistory[0],
                        AppG.screenHistory[1] == null ? undefined : AppG.screenHistory[1],
                        AppG.screenHistory[2] == null ? undefined : AppG.screenHistory[2],
                        AppG.screenHistory[3] == null ? undefined : AppG.screenHistory[3],
                        AppG.screenHistory[4] == null ? undefined : AppG.screenHistory[4],
                        AppG.screenHistory[5] == null ? undefined : AppG.screenHistory[5],
                        AppG.screenHistory[6] == null ? undefined : AppG.screenHistory[6],
                        AppG.screenHistory[7] == null ? undefined : AppG.screenHistory[7],
                        AppG.screenHistory[8] == null ? undefined : AppG.screenHistory[8],
                        AppG.screenHistory[9] == null ? undefined : AppG.screenHistory[9],
                        recipe != null ? recipe.id : undefined,
                        product != null ? product.id : undefined,
                        maker != null ? maker.id : undefined,
                        undefined,  // 未使用になった
                        _properties['search_type'],
                        _properties['keyword'],
                        _properties['search'],
                        Platform.OS,
                        special != null ? special.id : undefined,
                    ).catch(e => {
                    log.debug(e);
                });
            }

            // google Analytics は下記の条件で送信しない
            if (Platform.OS == 'ios') {
                // トラッキングNGだったら送信しない
                if (!AppG.enableTrackingFlg) {
                    return;
                }
            }

            if (!ExpoUtil.isProduction() && Platform.OS != 'web') {
                // web じゃなくて production でもないなら エラーになるだけなので送信しない
                return;
            }

            // firebase Analytics 送信
            _send();
        }

        if (AppG.analyticsHash != null) {
            func();
        } else {
            LoginUtil.setAnalyticsHash(func);
        }
    }

    sendCategoryAnalytics = (
        eventName: AnalyticsEventName | string,  // アナリティクスのイベント名、レシピ、商品、メーカーの場合は screen_view とは別に recipe_<recipe_id> の形で保存する
        screenName: string | null = null, // // `HomeScreen`
        recipe: Recipe | null = null,
        product: Product | null = null,
        maker: Maker | null = null
    ) => {

    }

}

export default new AnalyticsUtil();
