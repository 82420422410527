/**
 * メッセージタイプ
 */
enum MESSAGE_TYPE {
    IMAGE = 'IMAGE',    // jpeg, png
    // PDF = 'PDF',    // pdf
    TEXT = 'TEXT',  // 文字だけ
}


class ChatConstant {
    /**
     * 採用状況の取得
     */
    MESSAGE_TYPE = () => {
        return MESSAGE_TYPE;
    };

}

export default new ChatConstant();
