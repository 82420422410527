import React from 'react'
import {Dimensions, Platform, SafeAreaView, Text, TouchableOpacity, View} from 'react-native'
import {appColors, appFont, appS} from '../../resources/styles/style'
import {BaseScreen} from "./BaseScreen";
import LoginUtil from "../util/LoginUtil";
import {IndicatorComponent} from "../components/IndicatorComponent";
// @ts-ignore
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scrollview';
import LoginDataDao from "../data/dao/local/LoginDataDao";
import {LoginDataEntityModel} from "../data/entityModels/LoginDataEntityModel";
import {CustomHeaderComponentSmall} from "../components/small/CustomHeaderComponentSmall";
// @ts-ignore
import {getEnvVars, log} from '../../../environment';
import {ProfileInitScreenModel} from "../data/models/screen/ProfileInitScreenModel";
import AppG from "../util/AppG";
import ScreenSizeUtil from "../util/ScreenSizeUtil";
import {CustomHeaderComponentWide} from "../components/wide/CustomHeaderComponentWide";
import AnalyticsUtil, {AnalyticsEventName, DOpenEventName} from "../util/firebase/AnalyticsUtil";
import LinkUtil from "../util/LinkUtil";


interface Props {
    navigation: any, // ナビゲーション用
}

interface State {
    resizeWindow: boolean,  // 値に意味はなし。windowサイズの拡大縮小をrenderに送るためのもの
    isLoaded: boolean,
}

export class SettingMenuScreen extends BaseScreen<Props, State> {
    _resizeTimer = setInterval(() => {
        // window 拡大縮小を反映させる
        AppG.window = Dimensions.get('window');
        if (AppG.window.width != AppG.windowBefore.width || AppG.window.height != AppG.windowBefore.height) {
            this.setState({'resizeWindow': true});
            AppG.windowBefore = Dimensions.get('window');
        }
        LoginUtil.setRefreshedScreenFlg(false);
    }, 100);

    _loginData = new LoginDataEntityModel();

    _flameSize = appS.flame.size;    // 左右の margin
    _marginSize = ScreenSizeUtil.isWebSize()? 0 : appS.margin.size;
    _menuCellHeight = 50;

    // このScreenへ渡すパラム
    _param: ProfileInitScreenModel = new ProfileInitScreenModel();

    _browserBackListener = (e: any) => {

    };

    constructor(props: any) {
        super(props);
        this.state = {
            resizeWindow: true,  // 値に意味はなし。windowサイズの拡大縮小をrenderに送るためのもの
            isLoaded: false,
        };
    }

    /**
     * 画面開いたときのアナリティクス送信
     */
    _sendPvAnalytics = () => {
        AnalyticsUtil.sendAnalytics(
            DOpenEventName.openPage,
            AnalyticsEventName.openPage,  // アナリティクスのイベント名
            'SettingMenuScreen',
            null,
            null,
            null,
            null,
        );
    }

    componentDidMount() {
        if (Platform.OS == 'web') {
            window.addEventListener('popstate', this._browserBackListener);
        }

        LoginUtil.interruptOpenScreen(this.props.navigation,
            () => {
                LoginDataDao.get().then(loginData => {
                    // Web 戻るでも呼ばれる
                    if (Platform.OS == 'web') {
                        // Analytics
                        this._sendPvAnalytics();
                    }

                    // URL Scheme で外部リンクから起動したときの処理と、画面遷移でこの画面を開いたときの処理
                    LoginUtil.setDefaultListener(this.props.navigation, () => {
                        // Webはここにこない
                        if (Platform.OS != 'web') {
                            // Analytics
                            this._sendPvAnalytics();
                        }
                    });
                    if (loginData != null) {
                        this._loginData = loginData;
                        this._loadMasters().then(() => {});
                        this._loadContents().then(() => {});
                        this.setState({'isLoaded': true});
                    }
                });
            });

        this.setState({isLoaded: true});
    }

    componentWillUnmount () {
        clearTimeout(this._resizeTimer);

        if (Platform.OS === 'web') {
            window.removeEventListener('popstate', this._browserBackListener);
        }
        LoginUtil.removeDefaultListener(this.props.navigation);
    };

    componentDidUpdate () {
        // レンダー完了時に呼ばれる
    }

    /**
      * パラムの取得
      * @private
      */
    _getParams = () => {
    };

    /**
     * マスターのロード
     * @private
     */
    _loadMasters = async () => {
    };

    /**
     * コンテンツのロード
     * @private
     */
    _loadContents = async () => {
    };

    _renderMain = () => {
        // @ts-ignore
        const {REACT_APP_FAQ_URL, REACT_APP_FAQ_IOS_URL, REACT_APP_PRIVACY_URL, REACT_APP_TERMS_OF_SERVICE_URL} = getEnvVars();
        const _borderWidth = ScreenSizeUtil.isWebSize()? 700 : AppG.window.width - this._marginSize * 2;

        return (
            <View style={[{flex: 1, flexDirection: 'column', margin: this._marginSize}]}>

                {/*プロフィール編集*/}
                <View style={{height: this._menuCellHeight}}>
                    <TouchableOpacity
                        style={{
                            height: this._menuCellHeight,
                            flexDirection: "row",
                            marginBottom: 0,
                        }}
                        onPress={() => {
                            // パスワード変更
                            this.props.navigation.navigate('ProfileScreen');
                        }}
                    >
                        <View style={{flexDirection: "row", height: this._menuCellHeight}}>
                            <Text style={{fontFamily: appFont.family.bold, marginTop: 17, color: appColors.fontGray, fontWeight: "bold", fontSize: appFont.size.default}}>プロフィール編集</Text>
                        </View>
                    </TouchableOpacity>
                    <View style={{backgroundColor: appColors.spacerGray, height: 1, width: _borderWidth}}/>
                </View>

                {/*パスワード変更*/}
                <View style={{height: this._menuCellHeight}}>
                    <TouchableOpacity
                        style={{
                            height: this._menuCellHeight,
                            flexDirection: "row",
                            marginBottom: 0,
                        }}
                        onPress={() => {
                            // パスワード変更
                            this.props.navigation.navigate('ChangePasswordScreen');
                        }}
                    >
                        <View style={{flexDirection: "row", height: this._menuCellHeight}}>
                            <Text style={{fontFamily: appFont.family.bold, marginTop: 17, color: appColors.fontGray, fontWeight: "bold", fontSize: appFont.size.default}}>パスワード変更</Text>
                        </View>
                    </TouchableOpacity>
                    <View style={{backgroundColor: appColors.spacerGray, height: 1, width: _borderWidth}}/>
                </View>

                {/*FAQ*/}
                <View style={{height: this._menuCellHeight}}>
                    <TouchableOpacity
                        style={{
                            height: this._menuCellHeight,
                            flexDirection: "row",
                            marginBottom: 0,
                        }}
                        onPress={() => {
                            LinkUtil.openUrlInWebView(Platform.OS == 'ios'? REACT_APP_FAQ_IOS_URL : REACT_APP_FAQ_URL, this.props.navigation, 'FAQ');
                        }}
                    >
                        <View style={{flexDirection: "row", height: this._menuCellHeight}}>
                            <Text style={{fontFamily: appFont.family.bold, marginTop: 17, color: appColors.fontGray, fontWeight: "bold", fontSize: appFont.size.default}}>FAQ</Text>
                        </View>
                    </TouchableOpacity>
                    <View style={{backgroundColor: appColors.spacerGray, height: 1, width: _borderWidth}}/>
                </View>

                {/*利用規約*/}
                <View style={{height: this._menuCellHeight}}>
                    <TouchableOpacity
                        style={{
                            height: this._menuCellHeight,
                            flexDirection: "row",
                            marginBottom: 0,
                        }}
                        onPress={() => {
                            LinkUtil.openUrlInWebView(REACT_APP_TERMS_OF_SERVICE_URL, this.props.navigation, '利用規約');
                        }}
                    >
                        <View style={{flexDirection: "row", height: this._menuCellHeight}}>
                            <Text style={{fontFamily: appFont.family.bold, marginTop: 17, color: appColors.fontGray, fontWeight: "bold", fontSize: appFont.size.default}}>利用規約</Text>
                        </View>
                    </TouchableOpacity>
                    <View style={{backgroundColor: appColors.spacerGray, height: 1, width: _borderWidth}}/>
                </View>

                {/*プライバシーポリシー*/}
                <View style={{height: this._menuCellHeight}}>
                    <TouchableOpacity
                        style={{
                            height: this._menuCellHeight,
                            flexDirection: "row",
                            marginBottom: 0,
                        }}
                        onPress={() => {
                            LinkUtil.openUrlInWebView(REACT_APP_PRIVACY_URL, this.props.navigation, 'プライバシーポリシー');
                        }}
                    >
                        <View style={{flexDirection: "row", height: this._menuCellHeight}}>
                            <Text style={{fontFamily: appFont.family.bold, marginTop: 17, color: appColors.fontGray, fontWeight: "bold", fontSize: appFont.size.default}}>プライバシーポリシー</Text>
                        </View>
                    </TouchableOpacity>
                    <View style={{backgroundColor: appColors.spacerGray, height: 1, width: _borderWidth}}/>
                </View>

                {/*退会*/}
                <View style={{height: this._menuCellHeight}}>
                    <TouchableOpacity
                        style={{
                            height: this._menuCellHeight,
                            flexDirection: "row",
                            marginBottom: 0,
                        }}
                        onPress={() => {
                            this.props.navigation.navigate('WithdrawScreen');
                        }}
                    >
                        <View style={{flexDirection: "row", height: this._menuCellHeight}}>
                            <Text style={{fontFamily: appFont.family.bold, marginTop: 17, color: appColors.fontGray, fontWeight: "bold", fontSize: appFont.size.default}}>退会</Text>
                        </View>
                    </TouchableOpacity>
                    <View style={{backgroundColor: appColors.spacerGray, height: 1, width: _borderWidth}}/>
                </View>
            </View>
        );
    }

    _renderWide = () => {
        return (
            <SafeAreaView
                style={[{height: AppG.window.height, flex: 1, flexDirection: 'column', backgroundColor: appColors.white}]}
                onLayout={(event) => {}}
            >
                <CustomHeaderComponentWide
                    navigation={this.props.navigation}
                    searchBar={true}
                />

                <Text style={{
                    fontFamily: appFont.family.bold,
                    fontWeight: 'bold',
                    fontSize: appFont.size.xlarge,
                    color: appColors.fontGray,
                    marginTop: 32,
                    textAlign: 'center',
                    marginBottom: 32,
                }}>設定・その他</Text>

                <View style={{flex: 1, flexDirection: 'row'}}>
                    <KeyboardAwareScrollView style={[{flex: 1, flexDirection: 'column'}]}>
                        <View style={[{flex: 1, width: 700, alignSelf: 'center'}]}>
                            {this._renderMain()}
                        </View>
                    </KeyboardAwareScrollView>
                </View>
            </SafeAreaView>
        );
    }

    _renderSmall = () => {
        return (
            // appStyles.statusBarHiddenSafeAreaでステータスバー分上に上げる
            <SafeAreaView
                style={[{flex: 1, backgroundColor: appColors.white}]}
                onLayout={(event) => {}}
            >
                <CustomHeaderComponentSmall
                    navigation={this.props.navigation}
                    title={'設定・その他'}
                    textColor={appColors.black}
                    barColor={appColors.white}
                    borderBottomColor={appColors.white}
                    leftButton={'back'}
                    rightButton={null}
                />
                <View style={{flex: 1, backgroundColor: appColors.backBaseColor}}>
                    {this._renderMain()}
                </View>
            </SafeAreaView>
        );
    }


    /** 描画 */
    render() {
        const {navigation} = this.props;
        if (!this.state.isLoaded) {
            return (
                <View style={{flex: 1}}>
                    <IndicatorComponent
                        failTime={60}
                        isLoading={true}
                        reloadFunc={() => {
                            this.componentWillUnmount();
                            this.componentDidMount();
                        }}/>
                </View>
            );
        }

        LinkUtil.addReturnFalseToATag();
        if (ScreenSizeUtil.isWebSize()) {
            // WEBサイズ
            return this._renderWide();
        } else {
            // SPサイズ
            return this._renderSmall();
        }

    };


}

