import React, {Component} from 'react';
import {Platform, Text, View} from 'react-native';
import {appColors, appFont} from "../../resources/styles/style";

interface Props {
    title: string,
    onPress: () => void,
    fontSize: number,
    // borderBottomWidth: number,
    color: string,
    backgroundColor: string,
    textDecorationLine: 'none' | 'underline',
    ellipsizeMode: 'head' | 'middle' | 'tail' | 'clip' | undefined,
    numberOfLines: number | undefined
}

interface State { }

export class LinkComponent extends Component<Props, State> {
    // Propsのデフォルト値
    static defaultProps = {
        fontSize: appFont.size.small,
        // borderBottomWidth: 1,
        color: appColors.black,
        backgroundColor: appColors.transparent,
        textDecorationLine: 'none',
        ellipsizeMode: undefined,
        numberOfLines: undefined,
    };

    render() {
        const {title, onPress, fontSize, color, backgroundColor, textDecorationLine, ellipsizeMode, numberOfLines} = this.props;
        return (
            <View>
                <Text
                    style={{
                        fontFamily: appFont.family.bold,
                        color: color,
                        fontSize: fontSize,
                        backgroundColor: backgroundColor,
                        fontWeight: "bold",
                        // @ts-ignore
                        textDecorationLine: textDecorationLine,
                    }}
                    ellipsizeMode={ellipsizeMode}
                    numberOfLines={numberOfLines}
                    onPress={onPress}
                >
                    {title}
                </Text>
                {/*{Platform.OS != 'web' && textDecorationLine == 'underline' && (*/}
                {/*    <View*/}
                {/*        style={{*/}
                {/*            height: borderBottomWidth,*/}
                {/*            backgroundColor: color,*/}
                {/*            marginTop: 1,*/}
                {/*            marginBottom: 1,*/}
                {/*        }}*/}
                {/*    />*/}
                {/*)}*/}

            </View>
        );
    }
}
