import {BaseDao} from "./BaseDao";
// @ts-ignore
import {log} from '../../../../../environment';
import {WhatIsMemberShipModalDataEntityModel} from "../../entityModels/WhatIsMemberShipModalDataEntityModel";

class WhatIsMemberShipModalDataDao extends BaseDao<WhatIsMemberShipModalDataEntityModel> {
    _getModel(): WhatIsMemberShipModalDataEntityModel {
        return new WhatIsMemberShipModalDataEntityModel();
    }
}

// new 付なのでシングルトン
export default new WhatIsMemberShipModalDataDao();
