import React, {Component} from 'react';
import {Image, Platform, Text, TouchableOpacity, View} from 'react-native';
import {appColors, appFont, appS} from "../../resources/styles/style";
import AppG from "../util/AppG";
import ScreenSizeUtil from "../util/ScreenSizeUtil";
import ValidateUtil from "../util/ValidateUtil";
import {SampleRequestScreenModel} from "../data/models/screen/SampleRequestScreenModel";
import AlertUtil from "../util/AlertUtil";
import {SpacerComponent} from "./SpacerComponent";
import LinkUtil from "../util/LinkUtil";

interface Props {
    navigation: any,
    noButton: boolean,
}

interface State { }

export class NoUserImageForFollowComponent extends Component<Props, State> {

    // Propsのデフォルト値
    static defaultProps = {
        noButton: false,
    };

    render() {
        const { routeName } = this.props.navigation.state;
        let _width = AppG.window.width;
        if (ScreenSizeUtil.isWebSize()) {
            _width = appS.webMainContentsSize.width;
        }
        _width = (_width - appS.margins.side * 2);
        if (ScreenSizeUtil.isWebSize()) {
            _width = _width / 2;
        }

        const noUserImage1 = require('../../resources/images/no_user/non_member_favorite_01.jpg');
        const noUserImage2 = require('../../resources/images/no_user/non_member_favorite_02.jpg');
        const noUserImage3 = require('../../resources/images/no_user/non_member_favorite_03.jpg');
        const noUserImage1Height = _width*1.1;
        const noUserImage2Height = _width*0.235;
        const noUserImage3Height = _width*0.273;

        return (
            <View style={{alignItems: "center", backgroundColor: appS.noUserBackground}}>
                <Image source={ noUserImage1 }
                       style={[{
                           backgroundColor: 'white',
                           width: _width,
                           height: noUserImage1Height,
                       }]}
                       resizeMode={'stretch'}
                />
                <TouchableOpacity
                    onPress={() => {
                        this.props.navigation.navigate('SignUpScreen');
                    }}
                >
                    <Image source={ noUserImage2 }
                           style={[{
                               backgroundColor: 'white',
                               width: _width,
                               height: noUserImage2Height,
                           }]}
                           resizeMode={'stretch'}
                    />
                </TouchableOpacity>
                <TouchableOpacity
                    onPress={() => {
                        LinkUtil.openUrlInWebView(
                            'https://wp.recibase.net/introduction-member/',
                            this.props.navigation,
                            '無料会員登録でできること'
                        );
                    }}
                >
                    <Image source={ noUserImage3 }
                           style={[{
                               backgroundColor: 'white',
                               width: _width,
                               height: noUserImage3Height,
                           }]}
                           resizeMode={'stretch'}
                    />
                </TouchableOpacity>

                {!this.props.noButton && (
                    <View>
                        <SpacerComponent height={10}/>

                        <Text style={{fontFamily: appFont.family.bold, color: appColors.fontBlack, fontSize: 15, textAlign: 'center', fontWeight: "bold"}}>{'既に会員の方はこちら'}</Text>
                        <SpacerComponent height={15}/>
                        <TouchableOpacity
                            style={[
                                {
                                    width: appS.buttonSize.width,
                                    height: appS.buttonSize.height,
                                    backgroundColor: appColors.buttonOrange,
                                    alignItems: 'center',
                                    alignSelf: 'center',
                                    justifyContent: 'center',
                                    borderRadius: 50
                                }
                            ]}
                            onPress={() => {
                                this.props.navigation.navigate('LoginScreen');
                            }}
                        >
                            <View style={{flexDirection: 'row'}}>
                                <Text style={{fontFamily: appFont.family.bold, color: appColors.white, fontSize: appS.button.text, fontWeight: "bold"}}>{'ログイン'}</Text>
                                <Image
                                    source={require('../../resources/images/02.menu_general/arrow_W_right.png')}
                                    style={{
                                        marginTop: Platform.OS == 'android'? 5 : 7,
                                        width: 12,
                                        height: 12,
                                    }}
                                />
                            </View>
                        </TouchableOpacity>
                        <SpacerComponent height={60}/>
                    </View>
                )}
            </View>
        );
    }
}
