export class ReceivedNewMessageModalDataEntityModel {
    /**
     * 0, 1 => 1 なら表示しない
     */
    hide_flg_received_new_message: string | undefined = undefined;
    /**
     * 最終表示時
     */
    last_show_date_received_new_message: string | undefined = undefined;

}
