export class AddressModel {
    /** 都道府県名 */
    address1: string | null = null;
    /** 市区町村名 */
    address2: string | null = null;
    /** 町域名 */
    address3: string | null = null;
    /** 都道府県名カナ	（半角カタカナ） */
    kana1: string | null = null;
    /** 市区町村名カナ（半角カタカナ） */
    kana2: string | null = null;
    /** 町域名カナ（半角カタカナ） */
    kana3: string | null = null;
    /** 都道府県コード */
    prefcode: string | null = null;
    /** 郵便番号 */
    zipcode: string | null = null;
}
