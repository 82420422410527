import React, {Component} from 'react';
import {Image, TouchableOpacity, View} from 'react-native';
import {appS} from "../../resources/styles/style";
// @ts-ignore
import isURL from 'validator/lib/isURL';
import AppG from "../util/AppG";
import ScreenSizeUtil from "../util/ScreenSizeUtil";
import ExpoUtil from "../util/ExpoUtil";
import {SearchScreenModel} from "../data/models/screen/SearchScreenModel";

interface Props {
    navigation: any,
    analyticsBtnName: string,
}

interface State {}

/**
 * 新着レシピで使う横スクロールのコンポーネント
 */
export class SeasonComponent extends Component<Props, State> {

    // Propsのデフォルト値
    static defaultProps = {
        onPress: null,
        analyticsBtnName: '',
    };

    _seasons = [
        {name: '春', image: require('../../resources/images/spring.png'), seasonId: 1},
        {name: '夏', image: require('../../resources/images/summer.png'), seasonId: 2},
        {name: '秋', image: require('../../resources/images/autumn.png'), seasonId: 3},
        {name: '冬', image: require('../../resources/images/winter.png'), seasonId: 4},
    ]

    render() {
        // 行の作成
        const {navigation, analyticsBtnName} = this.props;

        let _width = AppG.window.width - appS.margins.side * 2;

        let _cellSize = (AppG.window.width - appS.margins.side) / 4;

        let _style = {
            flex: 1,
            width: AppG.window.width,
            height: _cellSize,
        };
        if (ScreenSizeUtil.isWebSize()) {
            _width = appS.webMainContentsSize.width -  - appS.margins.side * 2;
            _cellSize = (appS.webMainContentsSize.width - appS.margins.side) / 4;
            _style = {
                flex: 1,
                width: appS.webMainContentsSize.width,
                height: _cellSize,
            };
        }

        // 未会員
        if (AppG.user == null) {
            return (
                <TouchableOpacity
                    style={_style}
                    onPress={() => {
                        this.props.navigation.navigate('SignUpScreen');
                    }}
                >
                    <Image
                        source={require('../../resources/images/blur/blur_season.jpg')}
                        style={{
                            alignSelf: 'center',
                            width: _width,
                            height: _width * 0.275,
                        }}
                    />
                </TouchableOpacity>
            )
        }

        return ExpoUtil._wrap(
            <View style={_style}>
                <View style={{margin: appS.margins.side / 2, flexDirection: 'row'}}>
                    {this._seasons.map((season, index) => {
                        return (
                            <TouchableOpacity
                                key={`season_btn_${index}`}
                                style={{
                                    width: _cellSize,
                                    height: _cellSize,
                                }}
                                onPress={() => {
                                    AppG.screenHistory.unshift(`${analyticsBtnName}_${season.name}`);

                                    const { routeName } = this.props.navigation.state;
                                    let searchScreenModel: SearchScreenModel = new SearchScreenModel();
                                    searchScreenModel.headerTitle = `${season.name}`;
                                    searchScreenModel.searchType = 'recipe';
                                    searchScreenModel.screenNameFrom = routeName;
                                    searchScreenModel.tabHidden = true;
                                    searchScreenModel.seasonIds = `|${season.seasonId}|`;
                                    this.props.navigation.navigate('SearchResultScreen', searchScreenModel);
                                }}
                            >
                                <Image
                                    key={`season_image_${index}`}
                                    source={season.image}
                                    style={[{
                                        backgroundColor: 'white',
                                        margin: appS.margins.side / 2,
                                        width: _cellSize - appS.margins.side,
                                        height: _cellSize - appS.margins.side,
                                    }]}
                                    resizeMode={'cover'}
                                    accessibilityLabel={season.name}
                                />
                            </TouchableOpacity>
                        );
                    })}
                </View>
            </View>
        );

    }
}
