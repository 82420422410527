import React, {Component} from 'react';
import {Image, ImageStyle, Platform, ScrollView, StyleProp, Text, TouchableOpacity, View} from 'react-native';
import {appColors, appFont, appS} from "../../resources/styles/style";
import {Product, Recipe} from "../data/network/swagger-gen";
// @ts-ignore
import isURL from 'validator/lib/isURL';
import ValidateUtil from "../util/ValidateUtil";
import {ProductDetailScreen} from "../screens/ProductDetailScreen";
import {ProductDetailScreenModel} from "../data/models/screen/ProductDetailScreenModel";
import AppG from "../util/AppG";
import ScreenSizeUtil from "../util/ScreenSizeUtil";
import ExpoUtil from "../util/ExpoUtil";
import {ATagLinkComponent} from "./ATagLinkComponent";

interface Props {
    navigation: any,
    products: Product[],
    onPress: (productId: string) => void | null,
    analyticsBtnName: string,
    border: boolean,
}

interface State {}

/**
 * 新着レシピで使う横スクロールのコンポーネント
 */
export class ProductNewListHorizontalScrollComponent extends Component<Props, State> {

    // Propsのデフォルト値
    static defaultProps = {
        onPress: null,
        analyticsBtnName: '',
        border: true,
    };

    render() {
        // 行の作成
        const {navigation, products, onPress, analyticsBtnName, border} = this.props;
        if (ValidateUtil.isEmptyArray(products)) {
            return null;
        }
        let _style = {
            // flex: 1,
            borderWidth: border? 1 : 0,
            borderColor: appColors.borderGray,
            backgroundColor: appColors.white,
            width: AppG.window.width,
            height: 200,
        };
        if (ScreenSizeUtil.isWebSize()) {
            _style = {
                // flex: 1,
                borderWidth: border? 1 : 0,
                borderColor: appColors.borderGray,
                backgroundColor: appColors.white,
                width: appS.webMainContentsSize.width,
                height: 200,
            };
        }

        const _marginVertical = 10;

        return ExpoUtil._wrap(
            <View style={_style}>
                <ScrollView
                    style={{flex: 1, marginTop: _marginVertical}}
                    horizontal={true}
                    showsVerticalScrollIndicator={false}
                    showsHorizontalScrollIndicator={false}
                >
                    <View style={{
                        flex: 1,
                        flexDirection: 'row',
                        marginRight: appS.margins.side,
                    }}>

                        {products.map((product, index) => {
                            return (
                                <ProductCellComponent
                                    navigation={navigation}
                                    product={product}
                                    onPress={onPress}
                                    analyticsBtnName={analyticsBtnName}
                                    key={`product_${analyticsBtnName}_${index}`}/>
                            );
                        })}
                    </View>
                </ScrollView>
            </View>
        );

    }
}












interface ProductCellSmallProps {
    navigation: any,
    product: Product,
    onPress: (productId: string) => void | null,
    analyticsBtnName: string
}

interface ProductCellSmallState {
}


class ProductCellComponent extends Component<ProductCellSmallProps, ProductCellSmallState> {

    _productIconSize = 37;

    constructor(props: any) {
        super(props);
        this.state = {
        };
    };

    /**
     * 商品アイコンの作成
     * @param product
     * @param imageSize {width: number, height: number}
     * @private
     */
    _renderProductIcon = (product: Product, imageSize: {width: number, height: number}) => {

        let _userPlanOnly = product.userPlanOnly;
        let _samplePaid = product.samplePaid;
        // _userPlanOnly = 1;   // テスト用
        // _samplePaid = 1;    // テスト用

        const _userPlanHeight = 16;
        const _productIconMargin = 4;
        const _style: StyleProp<ImageStyle> = {
            zIndex: 1,
            position: 'relative',
            top: -imageSize.width,
            width: imageSize.width,
            height: this._productIconSize,
            backgroundColor: appColors.transparent,
        };

        return (
            <View style={_style}>
                <View style={{flexDirection: 'row'}}>
                    <View style={{width: imageSize.width - (Platform.OS == 'web'? 60 : 57)}}/>
                    <View
                        style={{
                            flexDirection: 'row-reverse',
                            height: _userPlanHeight,
                            borderRadius: 5,
                            marginLeft: 4,
                            backgroundColor: _userPlanOnly == 1? appColors.limeGreen: appColors.transparent,
                        }}
                    >
                        <Text style={{fontFamily: appFont.family.default, fontSize: appFont.size.iconFontSize, color: _userPlanOnly == 1? appColors.white: appColors.transparent, marginLeft: 8, marginRight: 8, marginTop: Platform.OS == 'android'? -6 : 0}}>会員限定</Text>
                    </View>
                </View>

                <View style={{
                    zIndex: 1,
                    position: 'relative',
                    top: imageSize.height - this._productIconSize - _userPlanHeight,
                    width: imageSize.width,
                    height: this._productIconSize,
                    backgroundColor: appColors.transparent,
                    flexDirection: "row",
                }}>
                    <View
                        style={{
                            marginRight: 8,
                            marginTop: imageSize.height - 80,
                            width: 30,
                            height: _userPlanHeight,
                            borderRadius: 5,
                            backgroundColor: _samplePaid == 1? appColors.goldYellow: appColors.transparent,
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: "row"
                        }}
                    >
                        <Text style={{fontFamily: appFont.family.bold, fontSize: 9, color: _samplePaid == 1? appColors.white: appColors.transparent, fontWeight: "bold"}}>有償</Text>
                    </View>
                    <View style={{flex:1}}/>
                    <Image source={require('../../resources/images/skelton.png')}
                           style={{
                               width: this._productIconSize,
                               height: this._productIconSize,
                               borderRadius: 50,
                               alignItems: 'center',
                               justifyContent: 'center',
                               marginRight: _productIconMargin,
                               marginTop: -_productIconMargin,
                               borderColor: appColors.transparent,
                               backgroundColor: appColors.transparent,
                           }}
                           resizeMode={'cover'}
                    />
                </View>
            </View>
        );
    }

    render() {
        const {navigation, product, onPress, analyticsBtnName} = this.props;

        let _paddingCell = appS.margins.side;
        let _cellSize = ScreenSizeUtil.isWebSize()? {width: 108 + _paddingCell * 2, height: 160 + _paddingCell * 2}: {width: 98 + _paddingCell * 2, height: 130 + _paddingCell * 2};
        let _imageSize = ScreenSizeUtil.isWebSize()? {width: 100, height: 100}: {width: 100, height: 100};
        let image: string = '';
        let source = require('../../resources/images/no_image.png');
        if (product.images != null && product.images.length != 0) {
            image = product.images[0].url;
        }
        if (image != '' && isURL(image)) {
            source = {uri: image};
        }

        return (
            <View style={{width: _cellSize.width, height: _cellSize.height}}>
                <ATagLinkComponent
                    href={`/ProductDetailScreen/${product.id}`}
                    style={{marginLeft: 0, marginTop: 10, backgroundColor: appColors.white, padding: _paddingCell, borderRadius: 5}}
                    onPress={() => {
                        AppG.screenHistory.unshift(analyticsBtnName);
                        if (onPress == null) {
                            // 画面遷移前にリロード対象にセットする
                            // ExpoUtil.setShouldReloadAppTab('self');
                            let productDetailScreenModel = new ProductDetailScreenModel();
                            productDetailScreenModel.productId = product.id.toString();
                            if (Platform.OS == 'web') {
                                navigation.navigate('ProductDetailScreen', productDetailScreenModel);
                            } else {
                                // 戻る対策
                                navigation.push('ProductDetailScreen', productDetailScreenModel);
                            }
                        } else {
                            onPress(product.id.toString());
                        }
                    }}
                    view={
                        <View>
                            <Image source={source}
                                   style={[{
                                       backgroundColor: 'white',
                                       width: _imageSize.width,
                                       height: _imageSize.height,
                                       borderRadius: 8,
                                   }]}
                                   resizeMode={'cover'}
                                   accessibilityLabel={product.name}
                            />

                            {/*商品アイコン*/}
                            {this._renderProductIcon(product, _imageSize)}

                            {/*商品名*/}
                            <View style={{marginTop: -this._productIconSize}}>
                                <Text
                                    style={{
                                        marginTop: 4,
                                        color: appColors.fontBlack,
                                        fontFamily: appFont.family.bold,
                                        height: Platform.OS == 'android'? 45 : 36,
                                        fontSize: appFont.size.listDescription,
                                        fontWeight: "bold"
                                        // letterSpacing: appStyles.line.space
                                    }}
                                    numberOfLines={2}
                                >
                                    {product.name}
                                </Text>
                            </View>
                        </View>
                    }
                />
            </View>
        );

    }
}

