import {FirebaseConfig} from "../../../util/firebase/FirebaseConfig";

// firebase の初期化をしてなかったら初期化
FirebaseConfig.initializeFirebaseConfig();

export abstract class FirestoreBaseDao {
    /** like 検索するときに使う%の代わり */
    protected likeStr = '\uf8ff';
    protected deleteFlgField = "deleteFlg";
    protected createdAtField = "createdAt";
    /** collection を取得 */
    // @ts-ignore
    abstract collection(docs: {});
    /** document を取得 */
    // @ts-ignore
    abstract document(docs: {});
    /** 追加 */
    abstract addOrUpdate(data: {}, docs: {}): void;
    /** 削除 */
    abstract delete(docs: {}): void;
    // /** 条件無しスナップショットの追加・更新・削除時の実行funcを設定 */
    // abstract snapshot(
    //     addFunc: (data: any) => void,
    //     updateFunc: (data: any) => void,
    //     deleteFunc: (data: any) => void,
    //     docs: {},
    // ): void;

    /**
     * 追加用のカラムデータのマージ
     * @param data
     */
    protected margeModelForAdd(data: {}) {
        const createdAt = Date.now();
        const updatedAt = createdAt;
        const deleteFlg = false;
        return {...data, updatedAt, createdAt, deleteFlg};
    }

    /**
     * 更新用のカラムデータのマージ
     * @param data
     */
    protected margeModelForUpdate(data: {}) {
        const updatedAt = Date.now();
        return {...data, updatedAt};
    }

    /**
     * 削除用のカラムデータ作成
     */
    protected getModelForDelete() {
        const updatedAt = Date.now();
        const deleteFlg = true;
        return {updatedAt, deleteFlg};
    }

}
