import React, {Component} from 'react';
import {Image, Platform, ScrollView, Text, TouchableOpacity, View} from 'react-native';
import {appColors, appFont, appS} from "../../resources/styles/style";
import AppG from "../util/AppG";
import ScreenSizeUtil from "../util/ScreenSizeUtil";
import moment from "moment";
import PopupUtil from "../util/PopupUtil";
import Modal from "react-native-modal";
import AnalyticsUtil, {AnalyticsEventName, DOpenEventName} from "../util/firebase/AnalyticsUtil";
import {
    ProductReviewReminderModalDataEntityModel
} from "../data/entityModels/ProductReviewReminderModalDataEntityModel";
import ProductReviewReminderModalDataDao from "../data/dao/local/ProductReviewReminderModalDataDao";

interface Props {
    navigation: any,
}

interface State {
    showModal: boolean,
}

export class ProductReviewReminderModalComponent extends Component<Props, State> {

    _modalName = 'レビュー催促POPUP';
    _title = '';

    constructor(props: any) {
        super(props);
        this.state = {
            showModal: false
        };
    }

    // Propsのデフォルト値
    static defaultProps = {
    };

    _tutorialUrlSource = [
        require('../../resources/images/reminder_review.png'),
    ]

    componentDidMount() {
        const { routeName } = this.props.navigation.state;
        if (routeName != 'HomeScreen') {
            // Homeじゃなかったら読み込まない
            return;
        }

        PopupUtil.showModalWidthPriority(
            this.props.navigation,
            'ProductReviewReminderModalComponent',
            () => {
                this.showModal();
            }
        );
    }

    /**
     * 表示
     */
    showModal = () => {
        const { routeName } = this.props.navigation.state;
        AnalyticsUtil.sendAnalytics(
            DOpenEventName.modalShow,
            AnalyticsEventName.modalShow,  // アナリティクスのイベント名
            `${routeName}`,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            this._modalName,
        );
        this.setState({showModal: true});
    }

    /**
     * 閉じる
     */
    closeModal = () => {
        const productReviewReminderModalDataEntityModel = new ProductReviewReminderModalDataEntityModel();
        productReviewReminderModalDataEntityModel.hide_flg_product_review_reminder = '1';
        productReviewReminderModalDataEntityModel.last_show_date_product_review_reminder = moment().toString();
        ProductReviewReminderModalDataDao.addOrUpdate(productReviewReminderModalDataEntityModel);
        this.setState({showModal: false});
    }

    /**
     * Android用暫定対応
     */
    _renderModalForAndroid = () => {
        return (
            <Modal
                isVisible={this.state.showModal}
                deviceWidth={AppG.window.width}
                deviceHeight={AppG.window.height}
                style={{
                    marginTop: -AppG.window.height / 4,
                    alignSelf: 'center',
                }}
                onBackdropPress={() => {
                    this.closeModal();
                }}
            >
                <View style={{
                    position: 'relative',
                    width: appS.popup.width,
                    height: appS.popup.height,
                    top: ScreenSizeUtil.isWebSize()? 50 : (Platform.OS == 'web'? 40 : 0),
                    left: 0,
                    backgroundColor: appColors.transparent,
                    alignItems: 'center',
                    alignSelf: 'center',
                    alignContent: 'center',
                    marginTop: (AppG.window.height - appS.popup.height) / 2 - appS.footer.height + (ScreenSizeUtil.isWebSize()? 0 : appS.header.height),
                }}>


                    <View style={{
                        borderRadius: 10,
                        backgroundColor: appColors.white,
                        paddingLeft: appS.margins.side / 2,
                        width: appS.popup.width + appS.margins.side * 2,
                        height: Platform.OS == 'android'? appS.popup.width + 80 : appS.popup.width + 40,
                    }}>
                        <Text
                            style={{
                                alignSelf: 'center',
                                marginTop: 10,
                                marginBottom: 10,
                                fontFamily: appFont.family.bold,
                                fontSize: appFont.size.popupTitle,
                                fontWeight: "bold"
                            }}
                        >{this._title}</Text>
                        <ScrollView style={{
                            marginLeft: appS.margins.side,
                            height: appS.popup.height,
                            width: appS.popup.width - 1,
                            backgroundColor: appColors.white,
                        }}>
                            {this._tutorialUrlSource.map((source, index) => {
                                // 画像複数
                                return (
                                    <TouchableOpacity
                                        key={`popup_to_image_${index}`}
                                        onPress={() => {
                                            const { routeName } = this.props.navigation.state;
                                            if (routeName != 'SampleRequestReviewsScreen') {
                                                this.props.navigation.navigate('SampleRequestReviewsScreen');
                                            }
                                            AnalyticsUtil.sendAnalytics(
                                                DOpenEventName.modalTap,
                                                AnalyticsEventName.modalTap,  // アナリティクスのイベント名
                                                `${routeName}`,
                                                null,
                                                null,
                                                null,
                                                null,
                                                null,
                                                null,
                                                null,
                                                this._modalName,
                                            );
                                            this.closeModal();
                                        }}
                                        disabled={this.props.navigation.state.routeName == 'SampleRequestReviewsScreen'}
                                    >
                                        <Image source={source}
                                               key={`popup_image_${index}`}
                                               style={[{
                                                   width: appS.popup.width - 10,
                                                   height: appS.popup.width - 10,
                                               }]}
                                               resizeMode={'stretch'}
                                        />
                                    </TouchableOpacity>
                                )
                            })
                            }

                        </ScrollView>
                        {/*閉じるボタン*/}
                        <TouchableOpacity
                            style={{
                                zIndex: 202,
                                position: 'absolute',
                                backgroundColor: appColors.white,
                                right: 5,
                                top: 5,
                                borderRadius: 50,
                            }}
                            onPress={() => {
                                this.closeModal();
                            }}
                        >
                            <Image source={require('../../resources/images/close_icon.png')}
                                   style={[{
                                       backgroundColor: appColors.transparent,
                                       marginTop: 10,
                                       width: 40,
                                       height: 40,
                                   }]}
                                   resizeMode={'cover'}
                            />
                        </TouchableOpacity>
                    </View>


                </View>
            </Modal>
        );
    }

    /**
     * ボタン一個ありModal
     */
    _renderModal = () => {
        if (!this.state.showModal) {
            return null;
        }


        return (
            <View
                style={{zIndex: 201, position: 'absolute', backgroundColor: appColors.opacityGray, height: AppG.window.height + 60, width: AppG.window.width}}
            >
                <View style={{
                    position: 'relative',
                    width: appS.popup.width,
                    height: appS.popup.height,
                    top: ScreenSizeUtil.isWebSize()? 50 : (Platform.OS == 'web'? 40 : 0),
                    left: 0,
                    backgroundColor: appColors.transparent,
                    alignItems: 'center',
                    alignSelf: 'center',
                    alignContent: 'center',
                    marginTop: (AppG.window.height - appS.popup.height) / 2 - appS.footer.height + (ScreenSizeUtil.isWebSize()? 0 : appS.header.height),
                }}>


                    <View style={{
                        borderRadius: 10,
                        backgroundColor: appColors.white,
                        paddingLeft: appS.margins.side / 2,
                        width: appS.popup.width + appS.margins.side * 2,
                        height: Platform.OS == 'android'? appS.popup.width + 80 : appS.popup.width + 40,
                    }}>
                        <Text
                            style={{
                                alignSelf: 'center',
                                marginTop: 10,
                                marginBottom: 10,
                                fontFamily: appFont.family.bold,
                                fontSize: appFont.size.popupTitle,
                                fontWeight: "bold"
                            }}
                        >{this._title}</Text>
                        <ScrollView style={{
                            marginLeft: appS.margins.side,
                            height: appS.popup.height,
                            width: appS.popup.width - 1,
                            backgroundColor: appColors.white,
                        }}>
                            {this._tutorialUrlSource.map((source, index) => {
                                // 画像複数
                                return (
                                    <TouchableOpacity
                                        key={`popup_to_image_${index}`}
                                        onPress={() => {
                                            const { routeName } = this.props.navigation.state;
                                            if (routeName != 'SampleRequestReviewsScreen') {
                                                this.props.navigation.navigate('SampleRequestReviewsScreen');
                                            }
                                            AnalyticsUtil.sendAnalytics(
                                                DOpenEventName.modalTap,
                                                AnalyticsEventName.modalTap,  // アナリティクスのイベント名
                                                `${routeName}`,
                                                null,
                                                null,
                                                null,
                                                null,
                                                null,
                                                null,
                                                null,
                                                this._modalName,
                                            );
                                            this.closeModal();
                                        }}
                                        disabled={this.props.navigation.state.routeName == 'SampleRequestReviewsScreen'}
                                    >
                                        <Image source={source}
                                               key={`popup_image_${index}`}
                                               style={[{
                                                   width: appS.popup.width - 10,
                                                   height: appS.popup.width - 10,
                                               }]}
                                               resizeMode={'stretch'}
                                        />
                                    </TouchableOpacity>
                                )
                            })
                            }

                        </ScrollView>
                        {/*閉じるボタン*/}
                        <TouchableOpacity
                            style={{
                                zIndex: 202,
                                position: 'absolute',
                                backgroundColor: appColors.white,
                                right: 5,
                                top: 5,
                                borderRadius: 50,
                            }}
                            onPress={() => {
                                this.closeModal();
                            }}
                        >
                            <Image source={require('../../resources/images/close_icon.png')}
                                   style={[{
                                       backgroundColor: appColors.transparent,
                                       width: 40,
                                       height: 40,
                                   }]}
                                   resizeMode={'cover'}
                            />
                        </TouchableOpacity>
                    </View>


                </View>
            </View>
        );

    }

    render() {
        if (Platform.OS == 'android') {
            return this._renderModalForAndroid();
        }
        return this._renderModal();
    }
}
