import React, {Component} from 'react';
import {Platform, TouchableOpacity} from 'react-native';
import {Link} from "@react-navigation/native";

interface Props {
    href: string,
    onPress: () => void,
    view: any,
    style: any,
    enableATag: boolean,    // お気に入りボタンなど view の中にさらにボタンがあるときこの Component は使えないので、すべて TouchableOpacity にして href を無視する
}

interface State { }

/**
 * WEBの時、リンクをAタグのリンクに差し替える
 */
export class ATagLinkComponent extends Component<Props, State> {

    // Propsのデフォルト値
    static defaultProps = {
        style: {},
        enableATag: true,
    };

    /**
     * WEBの時、リンクをAタグのリンクに差し替える
     */
    _renderATagLink = (enableATag: boolean, href: string, onPress: () => void, view: any, style: any = {}) => {
        if (Platform.OS == 'web' && enableATag) {
            return (
                <Link
                    style={style}
                    onPress={() => {
                        onPress();
                    }}
                    to={`${href}`}
                >
                    {view}
                </Link>
            );
        }

        // アプリの時
        return (
            <TouchableOpacity
                style={style}
                onPress={() => {
                    onPress();
                }}
            >
                {view}
            </TouchableOpacity>
        );
    }

    render() {
        const {href, onPress, view, style, enableATag} = this.props;
        return this._renderATagLink(enableATag, href, onPress, view, style);
    }
}
