import React, {Component} from 'react';
import {Image, Platform, Text, TouchableOpacity} from 'react-native';
import {appColors, appFont, appS} from "../../resources/styles/style";

interface Props {
    title: string,
    selected: boolean,
    onPress: () => void,
}

interface State { }

export class CheckBoxComponent extends Component<Props, State> {

    render() {
        let source = require('../../resources/images/02.menu_general/checkbox_off.png');
        if (this.props.selected) {
            source = require('../../resources/images/02.menu_general/checkbox_on.png');
        }
        return (
            <TouchableOpacity
                style={[
                    {
                        flexDirection: 'row',
                        height: 30,
                        alignItems: 'center',
                        alignContent: 'center',
                        marginBottom: appS.checkBox.bottomMargin,
                    }
                ]}
                onPress={() => {
                    this.props.onPress();
                }}
            >
                <Image
                    source={source}
                    style={[{
                        marginTop: 2,
                        backgroundColor: 'white',
                        width: 15,
                        height: 15,
                    }]}
                    resizeMode={'cover'}
                />
                <Text style={{
                    marginTop: Platform.OS == 'android'? -4 : 0,
                    marginLeft: 6,
                    color: appColors.fontBlack,
                    fontSize: appFont.size.checkboxText,
                    fontFamily: appFont.family.default,
                }}>{this.props.title}</Text>
            </TouchableOpacity>
        );
    }
}
