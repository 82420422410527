import * as Notifications from 'expo-notifications'
import * as MediaLibrary from 'expo-media-library';
// @ts-ignore
import {getEnvVars, log} from '../../../environment';
import {RecipeDetailScreenModel} from "../data/models/screen/RecipeDetailScreenModel";
import {ProductDetailScreenModel} from "../data/models/screen/ProductDetailScreenModel";
import {MakerDetailScreenModel} from "../data/models/screen/MakerDetailScreenModel";
import LinkUtil from "./LinkUtil";
import {ProductReviewScreenModel} from "../data/models/screen/ProductReviewScreenModel";
import AppG from "./AppG";
import AppGlobalDataUtil from "./AppG";
import {
    Maker,
    MakerApiFactory,
    Product,
    ProductApiFactory,
    Recipe,
    RecipeApiFactory,
    Special,
    SpecialApiFactory
} from "../data/network/swagger-gen";
import {AxiosResponse} from "axios";
import ValidateUtil from "./ValidateUtil";
import SpecialUtil from "./SpecialUtil";
import NavigationUtil from "./NavigationUtil";
import AlertUtil from "./AlertUtil";
import AnalyticsUtil, {AnalyticsEventName, DOpenEventName} from "./firebase/AnalyticsUtil";
import LoginUtil from "./LoginUtil";
import {Platform} from "react-native";

class NotificationUtil {
    // Homeのタブを固定（通知タップパターンではなぜかタブが勝手に動く）
    _homeTabFix = false;

    /**
     * Push通知を受け取る・使用するためのコード
     */
    registerForPushNotificationsAsync = async (): Promise<string | null> => {

        let token = null;
        //warningがでるのを防ぐためにtry catchを入れる
        try {
            // 通知を使用しますかの許可アラート
            // const { status: existingStatus } = await Permissions.getAsync(
            //     Permissions.NOTIFICATIONS
            // );
            // let finalStatus = existingStatus;
            //
            // if (existingStatus !== 'granted') {
            //     // const { status } = await Permissions.askAsync(Permissions.NOTIFICATIONS);
            //     const permission = await MediaLibrary.getPermissionsAsync();
            //     finalStatus = permission.status;
            // }

            const permission = await Notifications.getPermissionsAsync();
            const _status = permission.status;
            let finalStatus = _status;
            if (permission.canAskAgain && _status !== 'granted') {
                // 通知不許可で再確認OKなら
                const { status } = await Notifications.requestPermissionsAsync();
                finalStatus = status;
            }
            if (finalStatus !== 'granted') {
                // 通知不許可なら
                return null;
            }

            token = await Notifications.getExpoPushTokenAsync();
            //コンソールに出力
            // log.debug('------------------------ Push Token -----------------------');
            // log.debug(token);

        } catch (error) {
            log.debug(error);
        }
        return token == null? null: token.data;
    }

    /**
     * スクリーン毎の遷移方法
     * @param screen
     * @param params
     * @param navigation
     */
    goToScreenFromPushNotification = (screen: string, params: any, navigation: any) => {
        if (screen == 'OpenUrl') {
            // デフォルトブラウザで開く
            LinkUtil.openLinkWithDefaultBrowser(params.url);
            return;
        }

        if (AppG.user != null && AppG.user.id == AppG.testUserId && AppG.user.name == AppG.testUserName) {
            // user id = 1, 名前 = て の時のデバッグコード
            AlertUtil.alert('screenName', `${screen}`);
        }

        // アナリティクスを送信していないにする
        AppG.sentAnalyticsFromNotificationAlready = false;
        AppG.retryNumLoginForAnalyticsNotification = 4;

        /** 通知するまで繰り返しログインチェック */
        let _notificationTimer = setInterval(() => {
            if (AppG.sentAnalyticsFromNotificationAlready && _notificationTimer != null) {
                // 送信してたら削除
                clearTimeout(_notificationTimer);
                return;
            }

            LoginUtil.loginFromNotification(() => {
                // ログインチェック後、ログインユーザーのデータを取得した後 Analytics 送信
                switch (screen) {
                    case 'RecipeDetailScreen':
                        this._sendRecipeAnalytics(params.recipeId);
                        break;
                    case 'ProductDetailScreen':
                        this._sendProductAnalytics(params.productId);
                        break;
                    case 'MakerDetailScreen':
                        this._sendMakerAnalytics(params.makerId);
                        break;
                    case 'ProductReviewScreen':
                        AnalyticsUtil.sendAnalytics(
                            DOpenEventName.openPage,
                            AnalyticsEventName.openPage,  // アナリティクスのイベント名
                            'ProductReviewScreen',
                            null,
                            null,
                            null,
                            null,
                        );
                        AnalyticsUtil.sendAnalytics(
                            DOpenEventName.notification,
                            AnalyticsEventName.notification,  // アナリティクスのイベント名
                            'ProductReviewScreen',
                            null,
                            null,
                            null,
                            null,
                        );
                        // アナリティクスを送信したにする
                        AppG.sentAnalyticsFromNotificationAlready = true;
                        break;
                    case 'SpecialScreen':
                        SpecialApiFactory(AppG.getConfiguration(), AppG.getBasePath())
                            .getSpecialFindAll().then((specialsRes: AxiosResponse<Special[]>) => {
                            if (specialsRes != null && !ValidateUtil.isEmptyArray(specialsRes.data)) {
                                let special: Special | undefined = specialsRes.data.find(s => s.id == params.specialId);
                                if (special != undefined) {
                                    AnalyticsUtil.sendAnalytics(
                                        DOpenEventName.notification,
                                        `${AnalyticsEventName.notification}_special_${special == null? '' : special.id}`,  // アナリティクスのイベント名
                                        'SpecialScreen',
                                        null,
                                        null,
                                        null,
                                        special == null ? 'バックナンバー' : null,
                                        special == null ? 'https://wp.recibase.net/' : null,
                                        special
                                    );
                                    // アナリティクスを送信したにする
                                    AppG.sentAnalyticsFromNotificationAlready = true;
                                } else {
                                    // アナリティクスを送信したにする
                                    AppG.sentAnalyticsFromNotificationAlready = true;
                                }

                            }
                        });

                        break;

                    ///////////////////
                    // params なし (params: {})
                    ///////////////////
                    case 'HomeScreen':
                        AnalyticsUtil.sendAnalytics(
                            DOpenEventName.openPage,
                            AnalyticsEventName.openPage,  // アナリティクスのイベント名
                            'HomeScreen',
                        );

                        AnalyticsUtil.sendAnalytics(
                            DOpenEventName.notification,
                            AnalyticsEventName.notification,  // アナリティクスのイベント名
                            'HomeScreen',
                        );
                        // アナリティクスを送信したにする
                        AppG.sentAnalyticsFromNotificationAlready = true;
                        break;
                    case 'HomeScreen_Ranking':  // ランキングタブ
                        AnalyticsUtil.sendAnalytics(
                            DOpenEventName.openPage,
                            AnalyticsEventName.openPage,  // アナリティクスのイベント名
                            'HomeScreen',
                        );
                        AnalyticsUtil.sendAnalytics(
                            DOpenEventName.notification,
                            AnalyticsEventName.notification,  // アナリティクスのイベント名
                            'HomeScreen_Ranking',
                        );
                        // アナリティクスを送信したにする
                        AppG.sentAnalyticsFromNotificationAlready = true;
                        break;
                    case 'LoginScreen':
                        AnalyticsUtil.sendAnalytics(
                            DOpenEventName.openPage,
                            AnalyticsEventName.openPage,  // アナリティクスのイベント名
                            'LoginScreen',
                        );
                        AnalyticsUtil.sendAnalytics(
                            DOpenEventName.notification,
                            AnalyticsEventName.notification,  // アナリティクスのイベント名
                            'LoginScreen',
                        );
                        // アナリティクスを送信したにする
                        AppG.sentAnalyticsFromNotificationAlready = true;
                        break;
                    case 'SignUpScreen':
                        AnalyticsUtil.sendAnalytics(
                            DOpenEventName.openPage,
                            AnalyticsEventName.openPage,  // アナリティクスのイベント名
                            'SignUpScreen',
                        );
                        AnalyticsUtil.sendAnalytics(
                            DOpenEventName.notification,
                            AnalyticsEventName.notification,  // アナリティクスのイベント名
                            'SignUpScreen',
                        );
                        // アナリティクスを送信したにする
                        AppG.sentAnalyticsFromNotificationAlready = true;
                        break;
                    default:
                        AnalyticsUtil.sendAnalytics(
                            DOpenEventName.openPage,
                            AnalyticsEventName.openPage,  // アナリティクスのイベント名
                            'HomeScreen',
                        );

                        AnalyticsUtil.sendAnalytics(
                            DOpenEventName.notification,
                            AnalyticsEventName.notification,  // アナリティクスのイベント名
                            'HomeScreen',
                        );
                        // アナリティクスを送信したにする
                        AppG.sentAnalyticsFromNotificationAlready = true;
                        break;
                }
            }); // LoginUtil.loginFromNotification
        }, 5000);

        // Push通知による遷移
        switch (screen) {
            case 'RecipeDetailScreen':
                // curl -H "Content-Type: application/json" -X POST "https://exp.host/--/api/v2/push/send" -d '{
                //         "to": "ExponentPushToken[PrQOUaJcHV6XmmD5OPbcYe...]",
                //         "title":"hello",
                //         "body": "world",
                //         "badge": "3",
                //         "data": {
                //         "title":"hello",
                //             "body": "world",
                //             "screen": "ProductDetailScreen",
                //             "params": {
                //             "productId": "65"
                //         }
                //     }
                // }'
                let recipeDetailScreenModel: RecipeDetailScreenModel = new RecipeDetailScreenModel();
                recipeDetailScreenModel.recipeId = params.recipeId;
                if (Platform.OS == 'web') {
                    navigation.navigate('RecipeDetailScreen', recipeDetailScreenModel);
                } else {
                    navigation.push('RecipeDetailScreen', recipeDetailScreenModel);
                }
                return;
            case 'ProductDetailScreen':
                let productDetailScreenModel: ProductDetailScreenModel = new ProductDetailScreenModel();
                productDetailScreenModel.productId = params.productId;
                if (Platform.OS == 'web') {
                    navigation.navigate('ProductDetailScreen', productDetailScreenModel);
                } else {
                    navigation.push('ProductDetailScreen', productDetailScreenModel);
                }
                return;
            case 'MakerDetailScreen':
                let makerDetailScreenModel: MakerDetailScreenModel = new MakerDetailScreenModel();
                makerDetailScreenModel.makerId = params.makerId;
                if (Platform.OS == 'web') {
                    navigation.navigate('MakerDetailScreen', makerDetailScreenModel);
                } else {
                    navigation.push('MakerDetailScreen', makerDetailScreenModel);
                }
                return;
            case 'ProductReviewScreen':
                let productReviewScreenModel: ProductReviewScreenModel = new ProductReviewScreenModel();
                productReviewScreenModel.productId = params.productId;
                productReviewScreenModel.reviewSetId = params.reviewSetId;
                navigation.navigate('ProductReviewScreen', productReviewScreenModel);
                return;
            case 'SpecialScreen':
                SpecialApiFactory(AppG.getConfiguration(), AppG.getBasePath())
                    .getSpecialFindAll().then((specialsRes: AxiosResponse<Special[]>) => {
                    if (specialsRes != null && !ValidateUtil.isEmptyArray(specialsRes.data)) {
                        let special: Special | undefined = specialsRes.data.find(s => s.id == params.specialId);
                        if (special == undefined) {
                            navigation.navigate('HomeScreen');
                            return;
                        }

                        SpecialUtil.openSpecial(special, navigation);
                    }
                });

                return;

            ///////////////////
            // params なし (params: {})
            ///////////////////
            case 'HomeScreen':
                navigation.navigate('HomeScreen');
                return;
            case 'HomeScreen_Ranking':  // ランキングタブ
                AppG.tabIndex = 4;
                this._homeTabFix = true;
                NavigationUtil.homeRefresh();
                navigation.navigate('HomeScreen');
                return;
            case 'LoginScreen':
                navigation.navigate('LoginScreen');
                return;
            case 'SignUpScreen':
                navigation.navigate('SignUpScreen');
                return;
            default:
                navigation.navigate(screen);
                return;
        }





    }

    /**
     * レシピ用アナリティクス
     */
    _sendRecipeAnalytics = (recipeId: string) => {

        if (ValidateUtil.isEmptyExact(recipeId)) {
            return;
        }

        RecipeApiFactory(AppG.getConfiguration(), AppG.getBasePath())
            .getRecipe(
                recipeId,
                1,
                5,
            ).then((recipeRes: AxiosResponse<Recipe>) => {
            if (recipeRes != null && recipeRes.data != null) {
                let recipe = recipeRes.data;
                AnalyticsUtil.sendAnalytics(
                    null,
                    `maker_${recipe.maker?.id}_recipe_${recipe.id}`,  // アナリティクスのイベント名
                    'RecipeDetailScreen',
                    recipe,
                    null,
                    recipe.maker != null ? recipe.maker : null,
                    null,
                );

                AnalyticsUtil.sendAnalytics(
                    DOpenEventName.openPage,
                    AnalyticsEventName.openPage,  // アナリティクスのイベント名
                    'RecipeDetailScreen',
                    recipe,
                    null,
                    recipe.maker != null ? recipe.maker : null,
                    null,
                );

                AnalyticsUtil.sendAnalytics(
                    DOpenEventName.notification,
                    `${AnalyticsEventName.notification}_maker_${recipe.maker?.id}_recipe_${recipe.id}`,  // アナリティクスのイベント名
                    'RecipeDetailScreen',
                    recipe,
                    null,
                    recipe.maker != null ? recipe.maker : null,
                    null,
                );

                // アナリティクスを送信したにする
                AppG.sentAnalyticsFromNotificationAlready = true;
            }
        });
    }


    /**
     * 商品のアナリティクス送信
     */
    _sendProductAnalytics = (productId: string) => {

        if (ValidateUtil.isEmptyExact(productId)) {
            return;
        }

        ProductApiFactory(AppGlobalDataUtil.getConfiguration(), AppGlobalDataUtil.getBasePath())
            .getProduct(
                productId,
                1,
                5,
            ).then((productRes: AxiosResponse<Product>) => {
            if (productRes != null && productRes.data != null) {
                let product = productRes.data;
                AnalyticsUtil.sendAnalytics(
                    null,
                    `maker_${product.maker?.id}_product_${product.id}`,  // アナリティクスのイベント名
                    'ProductDetailScreen',
                    null,
                    product,
                    product.maker != null ? product.maker: null,
                    null,
                );

                AnalyticsUtil.sendAnalytics(
                    DOpenEventName.openPage,
                    AnalyticsEventName.openPage,  // アナリティクスのイベント名
                    'ProductDetailScreen',
                    null,
                    product,
                    product.maker != null ? product.maker: null,
                    null,
                );

                AnalyticsUtil.sendAnalytics(
                    DOpenEventName.notification,
                    `${AnalyticsEventName.notification}_maker_${product.maker?.id}_product_${product.id}`,  // アナリティクスのイベント名
                    'ProductDetailScreen',
                    null,
                    product,
                    product.maker != null ? product.maker: null,
                    null,
                );
                // アナリティクスを送信したにする
                AppG.sentAnalyticsFromNotificationAlready = true;
            }
        });
    }


    /**
     * メーカーのアナリティクス送信
     */
    _sendMakerAnalytics = (makerId: string) => {

        if (ValidateUtil.isEmptyExact(makerId)) {
            return;
        }

        MakerApiFactory(AppGlobalDataUtil.getConfiguration(), AppGlobalDataUtil.getBasePath())
            .getMaker(makerId, 2).then((makerRes: AxiosResponse<Maker>) => {
            if (makerRes != null && makerRes.data != null) {
                let maker = makerRes.data;

                AnalyticsUtil.sendAnalytics(
                    null,
                    `maker_${maker.id}`,  // アナリティクスのイベント名
                    'MakerDetailScreen',
                    null,
                    null,
                    maker,
                    null,
                );

                AnalyticsUtil.sendAnalytics(
                    DOpenEventName.openPage,
                    AnalyticsEventName.openPage,  // アナリティクスのイベント名
                    'MakerDetailScreen',
                    null,
                    null,
                    maker,
                    null,
                );

                AnalyticsUtil.sendAnalytics(
                    DOpenEventName.notification,
                    `${AnalyticsEventName.notification}_maker_${maker.id}`,  // アナリティクスのイベント名
                    'MakerDetailScreen',
                    null,
                    null,
                    maker,
                    null,
                );
                // アナリティクスを送信したにする
                AppG.sentAnalyticsFromNotificationAlready = true;
            }
        });

    }






}

export default new NotificationUtil();
