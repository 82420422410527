import React, {Component} from 'react';
import {ColorValue, Image, Platform, View} from 'react-native';
import {appColors} from "../../resources/styles/style";

interface Props {
    height: number,
    borderWidth: number,
    borderColor: ColorValue,
    type: 'normal' | 'dashed',
    marginLeft: number,
    marginRight: number,
    width: number,
}

interface State { }

export class BorderComponent extends Component<Props, State> {

    // Propsのデフォルト値
    static defaultProps = {
        height: 40,
        borderWidth: 1,
        borderColor: appColors.spacerGray,
        type: "normal",
        marginLeft: 0,
        marginRight: 0,
    };

    render() {
        const {height, borderWidth, borderColor, type, marginLeft, marginRight, width} = this.props;

        // 破線
        if (type === 'dashed') {
            return (
                <View
                    style={{
                        flex: 1,
                        height: borderWidth,
                        marginTop: height / 2,
                        marginBottom: height / 2,
                        marginLeft: marginLeft,
                        marginRight: marginRight,
                    }} >
                    <Image
                        source={(require('../../resources/images/dashed_line.png'))}
                        style={[{
                            width: width,
                            height: 2,
                        }]}
                        resizeMode={'cover'}
                    />
                </View>
            );
        }



        // 通常
        if (Platform.OS == 'web') {
            return (
                <View style={{
                    backgroundColor: appColors.spacerGray,
                    height: borderWidth,
                    width: width,
                    marginTop: height / 2,
                    marginBottom: height / 2,
                    marginLeft: marginLeft,
                    marginRight: marginRight,
                }}/>
            );
        } else {
            return (
                <View
                    style={{
                        flex: 1,
                        height: borderWidth,
                        backgroundColor: borderColor,
                        marginTop: height / 2,
                        marginBottom: height / 2,
                        marginLeft: marginLeft,
                        marginRight: marginRight,
                    }} />
            );
        }

    }
}
