import React, {Component} from 'react';
import {Image, ImageStyle, Platform, StyleProp, Text, TouchableOpacity, View} from 'react-native';
import {appColors, appFont, appS} from "../../resources/styles/style";
import {FollowApiFactory, Product, Recipe} from "../data/network/swagger-gen";
// @ts-ignore
import isURL from 'validator/lib/isURL';
import ValidateUtil from "../util/ValidateUtil";
import {RecipeDetailScreenModel} from "../data/models/screen/RecipeDetailScreenModel";
import ExpoUtil from "../util/ExpoUtil";
import AppG from "../util/AppG";
import ScreenSizeUtil from "../util/ScreenSizeUtil";
import AnalyticsUtil, {AnalyticsEventName, DOpenEventName} from "../util/firebase/AnalyticsUtil";
import {ATagLinkComponent} from "./ATagLinkComponent";

interface Props {
    navigation: any,
    recipes: Recipe[],
    analyticsBtnName: string | null,
    analyticsFollowScreenName: string,
    follow: boolean,
    enableATag: boolean,
    countRecipes: number | null,
}

interface State {}

export class RecipeSearchResultListComponent extends Component<Props, State> {


    // Propsのデフォルト値
    static defaultProps = {
        analyticsBtnName: null,
        follow: false,
        analyticsFollowScreenName: '',
        enableATag: true,
        countRecipes: null,
    };

    _renderMain = () => {
        let {navigation, recipes} = this.props;
        let _width = AppG.window.width;
        if (ScreenSizeUtil.isWebSize()) {
            _width = appS.webMainContentsSize.width;
        }

        return (
            <View style={{
                flex: 1,
                flexDirection: "column",
                width: _width
            }}>
                {this.props.countRecipes != null && (
                    <View style={{flexDirection: 'row', height: 20}}>
                        <View style={{flex: 1}}/>
                        <Text style={{
                            fontFamily: appFont.family.default,
                            fontSize: appFont.size.searchCount,
                            marginRight: appS.margins.side,
                            backgroundColor: appColors.transparent,
                            marginTop: Platform.OS == 'android'? 0 : 4,
                            marginBottom: -appS.margins.side - 2,
                        }}>{this.props.countRecipes}件</Text>
                    </View>
                )}
                <View style={{
                    flex: 1,
                    flexDirection: "column",
                    width: _width,
                    // margin: appS.margins.side,
                    marginLeft: 0,
                    marginRight: 0,
                    backgroundColor: (this.props.countRecipes == null || this.props.countRecipes == 0)? appColors.transparent : appColors.recipeSearchResultBackground,
                    // backgroundColor: appColors.transparent,
                }}>
                    {!ValidateUtil.isEmptyArray(recipes) &&
                        recipes.map((recipe, index) => {
                            return (
                                <RecipeCellSearchResultComponent
                                    navigation={navigation}
                                    recipe={recipe}
                                    key={`recipe_${index}`}
                                    index={index}
                                    analyticsBtnName={this.props.analyticsBtnName}
                                    follow={this.props.follow}
                                    analyticsFollowScreenName={this.props.analyticsFollowScreenName}
                                    enableATag={this.props.enableATag}
                                />
                            );
                        })}
                </View>
            </View>
        );
    }


    render() {
        return this._renderMain();
    }
}







interface RecipeCellRankingProps {
    navigation: any,
    recipe: Recipe,
    index: number,
    analyticsBtnName: string | null,
    follow: boolean,
    analyticsFollowScreenName: string,
    enableATag: boolean,
}

interface RecipeCellRankingState {
    followed: boolean,
}


class RecipeCellSearchResultComponent extends Component<RecipeCellRankingProps, RecipeCellRankingState> {
    _productIconSize = 37;

    constructor(props: any) {
        super(props);
        this.state = {
            followed: this.props.recipe.followed == 1,  // 1ならお気に入り
        };
    };

    _goToRecipe = (recipe: Recipe) => {
        if (this.props.analyticsBtnName != null) {
            AppG.screenHistory.unshift(`${this.props.analyticsBtnName}`);
        }

        // 画面遷移前にリロード対象にセットする
        ExpoUtil.setShouldReloadAppTab('self');
        let recipeDetailScreenModel = new RecipeDetailScreenModel();
        recipeDetailScreenModel.recipeId = recipe.id.toString();
        if (Platform.OS == 'web') {
            this.props.navigation.navigate('RecipeDetailScreen', recipeDetailScreenModel);
        } else {
            this.props.navigation.push('RecipeDetailScreen', recipeDetailScreenModel);
        }
    }

    /**
     * 商品アイコンの作成
     * @param recipe
     * @param imageSize {width: number, height: number}
     * @private
     */
    _renderProductIcon = (recipe: Recipe, imageSize: {width: number, height: number}) => {

        let _userPlanOnly = recipe.userPlanOnly;
        // _userPlanOnly = 1;   // テスト用

        const _userPlanHeight = 16;
        const _productIconMargin = 4;
        const _style: StyleProp<ImageStyle> = {
            zIndex: 1,
            position: 'relative',
            top: 0,
            left: -imageSize.width - 10,
            width: imageSize.width,
            height: this._productIconSize,
            backgroundColor: appColors.transparent,
        };
        // 画像有り
        if (recipe.productMain != null && !ValidateUtil.isEmpty(recipe.productMain.image) && isURL(recipe.productMain.image)) {
            return (
                <View style={_style}>
                    <View style={{flexDirection: 'row'}}>
                        <View style={{width: imageSize.width - 47}}/>
                        <View
                            style={{
                                flexDirection: 'row-reverse',
                                height: _userPlanHeight,
                                borderRadius: 5,
                                marginLeft: 4,
                                backgroundColor: _userPlanOnly == 1? appColors.limeGreen: appColors.transparent,
                            }}
                        >
                            <Text style={{marginTop: Platform.OS == 'android'? -7 : 0,fontFamily: appFont.family.default, fontSize: appFont.size.iconFontSize, color: _userPlanOnly == 1? appColors.white: appColors.transparent, marginLeft: 8, marginRight: 8}}>会員限定</Text>
                        </View>
                    </View>

                    <View style={{
                        zIndex: 1,
                        position: 'relative',
                        top: imageSize.height - this._productIconSize - _userPlanHeight,
                        width: imageSize.width,
                        height: this._productIconSize,
                        backgroundColor: appColors.transparent,
                        flexDirection: "row",
                    }}>
                        <View style={{flex:1}}/>
                        <Image source={require('../../resources/images/skelton.png')}
                               style={{
                                   width: this._productIconSize,
                                   height: this._productIconSize,
                                   borderRadius: 50,
                                   alignItems: 'center',
                                   justifyContent: 'center',
                                   marginRight: _productIconMargin,
                                   marginTop: -_productIconMargin,
                                   // borderColor: appColors.borderGray,
                                   // borderWidth: 1,
                               }}
                               resizeMode={'cover'}
                        />
                    </View>
                </View>
            );
        }

        // 画像無し（スペース確保）
        if (recipe.productMain == null || ValidateUtil.isEmpty(recipe.productMain.image) || !isURL(recipe.productMain.image)) {
            return (
                <View
                    style={_style}
                />
            );
        }
    }

    _renderMain = () => {
        const {recipe, index, follow, analyticsFollowScreenName, enableATag} = this.props;

        const _imageSize = 80;
        let _ingredient = '';
        recipe.ingredients.forEach(ingredient => {
            _ingredient = `${_ingredient}・${ingredient.name}`
        });
        if (_ingredient != '') {
            _ingredient = _ingredient.slice(1);
        }

        let _textWidth = AppG.window.width;
        if (ScreenSizeUtil.isWebSize()) {
            _textWidth = appS.webMainContentsSize.width;
        }
        _textWidth = _textWidth - appS.margins.side * 7 - _imageSize;

        const _followIconSize = appS.button.followIconSizeSmall;

        return (
            <View style={{
                backgroundColor: appColors.white,
                margin: appS.margins.side,
                marginTop: index == 0 ? appS.margins.side * 2 : appS.margins.side,
                marginBottom: appS.margins.side
            }}>
                <ATagLinkComponent
                    enableATag={enableATag}
                    href={`/RecipeDetailScreen/${recipe.id}`}
                    onPress={() => this._goToRecipe(recipe)}
                    view={
                        <View style={{flexDirection: 'row'}}>
                            <Image
                                style={{
                                    borderRadius: 5,
                                    width: _imageSize,
                                    height: _imageSize,
                                }}
                                source={{uri: recipe.images[0].url}}
                            />

                            {/*有償、会員限定アイコン*/}
                            {recipe.productMain != null && this._renderProductIcon(recipe, {width: _imageSize, height: _imageSize})}

                            {/*お気に入りボタン*/}
                            {follow && (
                                <TouchableOpacity
                                    style={{
                                        zIndex: 1,
                                        position: 'relative',
                                        top: -10,
                                        left: -_imageSize * 2 - appS.rankingIconSize.top / 2 + 5,
                                        borderRadius: 50,
                                        width: appS.rankingIconSize.top,
                                        height: appS.rankingIconSize.top,
                                    }}
                                    disabled={AppG.user == null}
                                    onPress={() => {
                                        if (AppG.user == null) {
                                            return;
                                        }

                                        let type = 'follow';
                                        if (this.state.followed) {
                                            type = 'unfollow'
                                        }
                                        FollowApiFactory(AppG.getConfiguration(), AppG.getBasePath())
                                            .followRecipe(recipe!.id.toString(), type).then(() => {
                                            // Analytics
                                            AnalyticsUtil.sendAnalytics(
                                                type == 'follow'? DOpenEventName.follow : DOpenEventName.unfollow,
                                                type == 'follow'? AnalyticsEventName.follow : AnalyticsEventName.unfollow ,  // アナリティクスのイベント名
                                                analyticsFollowScreenName,
                                                recipe,
                                                null,
                                                recipe?.maker,
                                                null,
                                            );

                                            ExpoUtil.setShouldReloadAppTab();   // 別タブで必要ならリロードするようにセット
                                            this.setState({followed: !this.state.followed});
                                        });
                                    }}
                                >
                                    {/*フォロー中*/}
                                    {this.state.followed && (
                                        <Image source={AppG.user == null ? require('../../resources/images/skelton.png') : require('../../resources/images/03.item_recipe/i_item_like_on.png')}
                                               style={[{
                                                   backgroundColor: appColors.transparent,
                                                   width: _followIconSize,
                                                   height: _followIconSize,
                                               }]}
                                               resizeMode={'cover'}
                                        />
                                    )}
                                    {/*フォローしてない*/}
                                    {!this.state.followed && (
                                        <Image source={AppG.user == null ? require('../../resources/images/skelton.png') : require('../../resources/images/03.item_recipe/i_item_like_off.png')}
                                               style={[{
                                                   backgroundColor: appColors.transparent,
                                                   width: _followIconSize,
                                                   height: _followIconSize,
                                               }]}
                                               resizeMode={'cover'}
                                        />
                                    )}

                                </TouchableOpacity>
                            )}
                            <View style={{flexDirection: 'column', margin: appS.margins.side, marginLeft: appS.margins.side - _imageSize}}>
                                <Text
                                    style={{
                                        lineHeight: appFont.size.searchResultLineHeight,
                                        height: appFont.size.searchResultLineHeight * 2,
                                        width: _textWidth,
                                        fontFamily: appFont.family.bold,
                                        fontSize: appFont.size.searchResultCellTitle,
                                        color: appColors.fontBlack,
                                        fontWeight: "bold"
                                    }}
                                    numberOfLines={2}
                                >{recipe.name}</Text>
                                <Text
                                    style={{
                                        marginTop: 4,
                                        lineHeight: appFont.size.searchResultLineHeight,
                                        height: appFont.size.searchResultLineHeight,
                                        width: _textWidth,
                                        fontFamily: appFont.family.default,
                                        fontSize: appFont.size.searchResultCellDescription,
                                        color: appColors.fontBlack,
                                    }}
                                    numberOfLines={1}
                                >材料：{_ingredient}</Text>
                            </View>
                        </View>}
                    style={
                        {flexDirection: 'row', margin: appS.margins.side}
                    }
                />

            </View>
        );
    }

    render() {
        return this._renderMain();
    }
}






