import React, {Component} from 'react';
import {Image, ImageStyle, Platform, ScrollView, StyleProp, Text, TouchableOpacity, View} from 'react-native';
import {appColors, appFont, appS} from "../../resources/styles/style";
import {Product} from "../data/network/swagger-gen";
// @ts-ignore
import isURL from 'validator/lib/isURL';
import ValidateUtil from "../util/ValidateUtil";
import {ProductDetailScreen} from "../screens/ProductDetailScreen";
import {ProductDetailScreenModel} from "../data/models/screen/ProductDetailScreenModel";
import AppG from "../util/AppG";
import ScreenSizeUtil from "../util/ScreenSizeUtil";
import ExpoUtil from "../util/ExpoUtil";
import LinkUtil from "../util/LinkUtil";
import {ATagLinkComponent} from "./ATagLinkComponent";

interface Props {
    navigation: any,
    products: Product[],
    onPress: (productId: string) => void | null,
    analyticsBtnName: string,
    top: number | null,  // トップxだけ表示
}

interface State {}

/**
 * 注目のキーワードで使う横スクロールのコンポーネント
 */
export class ProductRankingListHorizontalScrollComponent extends Component<Props, State> {

    // Propsのデフォルト値
    static defaultProps = {
        onPress: null,
        analyticsBtnName: '',
        more: null,
        moreOnPress: null,
        top: null
    };

    render() {
        // 行の作成
        const {navigation, products, onPress, analyticsBtnName} = this.props;
        if (ValidateUtil.isEmptyArray(products)) {
            return null;
        }

        let _width = AppG.window.width;
        if (ScreenSizeUtil.isWebSize()) {
            _width = appS.webMainContentsSize.width;
        }

        let _style = {
            flex: 1,
            backgroundColor: appColors.creamYellow,
            width: AppG.window.width,
            borderWidth: 1,
            borderColor: appColors.borderGray,
            marginTop: 5,
        };

        let _noUserImageSource = require('../../resources/images/no_user/SP_top_ranking_product.jpg');
        let _paddingCell = appS.margins.side;
        let _cellSize = ScreenSizeUtil.isWebSize()? {width: 138 + _paddingCell * 2, height: 200 + _paddingCell * 2}: {width: 128 + _paddingCell * 2, height: 180 + _paddingCell * 2};
        let _noUserImageSize = {width: (_cellSize.height + 8) * 1.117, height: _cellSize.height + 8};

        if (ScreenSizeUtil.isWebSize()) {
            _style = {
                flex: 1,
                backgroundColor: appColors.creamYellow,
                width: appS.webMainContentsSize.width,
                borderWidth: 1,
                borderColor: appColors.borderGray,
                marginTop: 5,
            };

            _noUserImageSize = {width: (_cellSize.height + 16) * 2.37, height: _cellSize.height + 16};
            _noUserImageSource = require('../../resources/images/no_user/PC_top_ranking_product.jpg');
        }

        const _marginVertical = 15;
        let _keywordTopMargin = 20;
        return ExpoUtil._wrap(
            <View style={{width: _width}}>
                <View style={_style}>
                    <ScrollView
                        style={{
                            flex: 1,
                            marginTop: _marginVertical
                        }}
                        horizontal={true}
                        showsVerticalScrollIndicator={false}
                        showsHorizontalScrollIndicator={false}
                    >
                        <View style={{
                            flex: 1,
                            flexDirection: 'row',
                            marginRight: appS.margins.side,
                            paddingTop: ScreenSizeUtil.isWebSize()? 12 : 1
                        }}>

                            {products.map((product, index) => {
                                if (this.props.top != null && index >= this.props.top) {
                                    return null;
                                }

                                if (AppG.user == null && index != 0) {
                                    // ログインしてないときは一つ目以外は隠す
                                    return null;
                                }

                                return (
                                    <ProductCellComponent
                                        navigation={navigation}
                                        product={product}
                                        onPress={onPress}
                                        analyticsBtnName={analyticsBtnName}
                                        key={`product_rank_h_${analyticsBtnName}_${index}`}
                                        index={index}
                                        cellSize={_cellSize}
                                        paddingCell={_paddingCell}
                                    />
                                );
                            })}
                        </View>

                        {AppG.user == null && (
                            <TouchableOpacity
                                style={{
                                    margin: -appS.margins.side,
                                    marginLeft: 10,
                                    width: _noUserImageSize.width,
                                    height: _noUserImageSize.height,
                                }}
                                onPress={() => {
                                    this.props.navigation.navigate('SignUpScreen');
                                }}
                            >
                                <Image source={_noUserImageSource}
                                       style={{
                                           width: _noUserImageSize.width,
                                           height: _noUserImageSize.height,
                                       }}
                                       resizeMode={'stretch'}
                                />
                            </TouchableOpacity>
                        )}
                    </ScrollView>
                </View>
            </View>
        );

    }
}












interface ProductCellSmallProps {
    navigation: any,
    product: Product,
    onPress: (productId: string) => void | null,
    analyticsBtnName: string
    index: number
    cellSize: any
    paddingCell: any
}

interface ProductCellSmallState {
}


class ProductCellComponent extends Component<ProductCellSmallProps, ProductCellSmallState> {
    _productIconSize = 37;

    constructor(props: any) {
        super(props);
        this.state = {
        };
    };

    /**
     * 商品アイコンの作成
     * @param product
     * @param imageSize {width: number, height: number}
     * @private
     */
    _renderProductIcon = (product: Product, imageSize: {width: number, height: number}) => {

        let _userPlanOnly = product.userPlanOnly;
        let _samplePaid = product.samplePaid;
        // _userPlanOnly = 1;   // テスト用
        // _samplePaid = 1;    // テスト用

        const _userPlanHeight = 16;
        const _productIconMargin = 4;
        const _style: StyleProp<ImageStyle> = {
            zIndex: 1,
            position: 'relative',
            top: -imageSize.width,
            width: imageSize.width,
            height: this._productIconSize,
            backgroundColor: appColors.transparent,
        };
        // 画像有り
        return (
            <View style={_style}>
                <View style={{flexDirection: 'row'}}>
                    <View style={{width: imageSize.width - (Platform.OS == 'web'? 60 : 57)}}/>
                    <View
                        style={{
                            flexDirection: 'row-reverse',
                            height: _userPlanHeight,
                            borderRadius: 5,
                            marginLeft: 4,
                            marginRight: Platform.OS == 'web'? 0 : 4,
                            backgroundColor: _userPlanOnly == 1? appColors.limeGreen: appColors.transparent,
                        }}
                    >
                        <Text style={{marginTop: Platform.OS == 'android'? -6 : 0, fontFamily: appFont.family.default, fontSize: appFont.size.iconFontSize, color: _userPlanOnly == 1? appColors.white: appColors.transparent, marginLeft: 8, marginRight: 8}}>会員限定</Text>
                    </View>
                </View>

                <View style={{
                    zIndex: 1,
                    position: 'relative',
                    top: imageSize.height - this._productIconSize - _userPlanHeight,
                    width: imageSize.width,
                    height: this._productIconSize,
                    backgroundColor: appColors.transparent,
                    flexDirection: "row",
                }}>
                    <View
                        style={{
                            marginLeft: 0,
                            marginTop: 21,
                            width: 30,
                            height: _userPlanHeight,
                            borderRadius: 5,
                            backgroundColor: _samplePaid == 1? appColors.goldYellow: appColors.transparent,
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: "row"
                        }}
                    >
                        <Text style={{fontFamily: appFont.family.bold, fontSize: 9, color: _samplePaid == 1? appColors.white: appColors.transparent, fontWeight: "bold"}}>有償</Text>
                    </View>
                    <View style={{flex:1}}/>
                    <Image source={require('../../resources/images/skelton.png')}
                           style={{
                               width: this._productIconSize,
                               height: this._productIconSize,
                               borderRadius: 50,
                               alignItems: 'center',
                               justifyContent: 'center',
                               marginRight: _productIconMargin,
                               marginTop: -_productIconMargin,
                           }}
                           resizeMode={'cover'}
                    />
                </View>
            </View>
        );
    }

    render() {
        const {navigation, product, onPress, analyticsBtnName, index, cellSize, paddingCell} = this.props;

        // let _paddingCell = appS.margins.side;
        // let _cellSize = ScreenSizeUtil.isWebSize()? {width: 138 + _paddingCell * 2, height: 200 + _paddingCell * 2}: {width: 128 + _paddingCell * 2, height: 180 + _paddingCell * 2};
        let _imageSize = ScreenSizeUtil.isWebSize()? {width: 130, height: 130} : {width: 120, height: 120};
        let image: string = '';
        let source = require('../../resources/images/no_image.png');
        if (product.images != null && product.images.length != 0) {
            image = product.images[0].url;
        }
        if (image != '' && isURL(image)) {
            source = {uri: image};
        }

        let _source = require('../../resources/images/06.ranking/i_rank_1.png');
        switch (index) {
            case 0:
                _source = require('../../resources/images/06.ranking/i_rank_1.png');
                break;
            case 1:
                _source = require('../../resources/images/06.ranking/i_rank_2.png');
                break;
            case 2:
                _source = require('../../resources/images/06.ranking/i_rank_3.png');
                break;
            default:
                break;
        }

        let _nameMarginTop = -appS.rankingIconSize.normal;

        return (
            <View style={{width: cellSize.width, height: cellSize.height}}>
                <ATagLinkComponent
                    href={`/ProductDetailScreen/${product.id}`}
                    onPress={() => {
                        AppG.screenHistory.unshift(analyticsBtnName);
                        if (onPress == null) {
                            // 画面遷移前にリロード対象にセットする
                            // ExpoUtil.setShouldReloadAppTab('self');
                            let productDetailScreenModel = new ProductDetailScreenModel();
                            productDetailScreenModel.productId = product.id.toString();
                            if (Platform.OS == 'web') {
                                navigation.navigate('ProductDetailScreen', productDetailScreenModel);
                            } else {
                                navigation.push('ProductDetailScreen', productDetailScreenModel);
                            }
                        } else {
                            onPress(product.id.toString());
                        }
                    }}
                    view={
                        <View>
                            <Image source={source}
                                   style={[{
                                       backgroundColor: 'white',
                                       width: _imageSize.width,
                                       height: _imageSize.height,
                                       borderRadius: 8,
                                   }]}
                                   resizeMode={'cover'}
                                   accessibilityLabel={product.name}
                            />

                            {/*商品アイコン*/}
                            {this._renderProductIcon(product, _imageSize)}

                            {index < 3 && (
                                <View style={{
                                    position: 'relative',
                                    top: -_imageSize.height - this._productIconSize - appS.rankingIconSize.top / 2 + 2,
                                    left: -appS.rankingIconSize.top / 2,
                                    borderRadius: 50,
                                    width: appS.rankingIconSize.top,
                                    height: appS.rankingIconSize.top,
                                }}>
                                    <Image
                                        source={_source}
                                        style={{
                                            width: appS.rankingIconSize.top,
                                            height: appS.rankingIconSize.top,
                                        }}
                                    />
                                </View>
                            )}
                            {index >=3 && (
                                <View style={{
                                    position: 'relative',
                                    top: -_imageSize.height - this._productIconSize - appS.rankingIconSize.normal / 2 + 1,
                                    left: -appS.rankingIconSize.normal / 2,
                                    borderRadius: 50,
                                    backgroundColor: appColors.rankingIconGray,
                                    width: appS.rankingIconSize.normal,
                                    height: appS.rankingIconSize.normal,
                                }}>
                                    <Text
                                        style={{
                                            marginTop: Platform.OS == 'android'? 3: 2,
                                            alignSelf: 'center',
                                            fontFamily: appFont.family.bold,
                                            fontSize: appFont.size.rankingIcon,
                                            color: appColors.white,
                                            fontWeight: "bold"
                                        }}
                                    >{index + 1}</Text>
                                </View>
                            )}

                            {/*レシピ名*/}
                            <View style={{marginTop: -this._productIconSize}}>
                                <Text
                                    style={{
                                        marginTop: _nameMarginTop + 4,
                                        color: appColors.fontBlack,
                                        fontFamily: appFont.family.default,
                                        height: 36,
                                        fontSize: appFont.size.listDescription,
                                        fontWeight: "bold",
                                        // letterSpacing: appStyles.line.space
                                    }}
                                    numberOfLines={2}
                                >
                                    {product.name}
                                </Text>
                            </View>
                        </View>
                    }
                    style={
                        {marginLeft: appS.margins.side, backgroundColor: appColors.white, padding: paddingCell, borderRadius: 5}
                    }
                />
            </View>
        );

    }
}

