export class LoginDataEntityModel {
    /**
     * 自分のUserID(AsyncStorage使うのでstring)
     */
    user_id: string | undefined = undefined;

    /**
     * 自分のUserHash(FirebaseID)
     */
    user_hash: string | undefined = undefined;

    /**
     * 自分のmail
     */
    mail: string | undefined = undefined;

    /**
     * 自分のログインパスワード
     */
    password: string | undefined = undefined;

}
