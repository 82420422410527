
import {BaseDao} from "./BaseDao";
import AsyncStorage from '@react-native-async-storage/async-storage';
// @ts-ignore
import {log} from '../../../../../environment';
import {TutorialDataEntityModel} from "../../entityModels/TutorialDataEntityModel";
import {FooterDataEntityModel} from "../../entityModels/FooterDataEntityModel";

class FooterDataDao extends BaseDao<FooterDataEntityModel> {
    _getModel(): FooterDataEntityModel {
        return new FooterDataEntityModel();
    }
}

// new 付なのでシングルトン
export default new FooterDataDao();
