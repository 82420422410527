
class NavigationUtil {
    /**
     * Home 画面の componentDidMount をリフレッシュ用に格納
     */
    _homeRefresh: any = null;

    /**
     * wideHeader 画面の componentDidMount をリフレッシュ用に格納
     */
    _wideHeaderRefresh: any = null;

    /**
     * wideHeader 【wideHeader画面でのみ実行】画面のリフレッシュをセット
     * @param componentDidMount
     * @param thiz
     */
    setWideHeaderRefresh = (componentDidMount: any, thiz: any) => {
        this._wideHeaderRefresh = componentDidMount.bind(thiz);
    };

    /**
     * wideHeader 画面のリフレッシュ
     * 別画面から home 画面に遷移する直前に必要なら呼ぶ
     */
    wideHeaderRefresh = () => {
        if (this._wideHeaderRefresh != null) {
            this._wideHeaderRefresh();
        }
    };

    /**
     * home 【Home画面でのみ実行】画面のリフレッシュをセット
     * @param componentDidMount
     * @param thiz
     */
    setHomeRefresh = (componentDidMount: any, thiz: any) => {
        this._homeRefresh = componentDidMount.bind(thiz);
    };

    /**
     * home 画面のリフレッシュ
     * 別画面から home 画面に遷移する直前に必要なら呼ぶ
     */
    homeRefresh = () => {
        if (this._homeRefresh != null) {
            this._homeRefresh();
        }
    };



    // /**
    //  * リフレッシュ用に this.componentDidMount をバウンド
    //  * this.props.navigation.navigate('移動前の画面をリフレッシュさせたいスクリーン', {refresh: NavigationUtil.bindRefresh(this.componentDidMount, this)})
    //  * と書いて渡し、次の画面で
    //  * NavigationUtil.preScreenRefresh(navigation);
    //  * navigation.goBack()
    //  * と書いて一つ前の画面をリフレッシュさせてから戻る
    //  * @param componentDidMount this.componentDidMount
    //  * @param thiz this
    //  */
    // bindRefresh = (componentDidMount: any, thiz: any) => {
    //     return componentDidMount.bind(thiz)
    // }
    //
    // /**
    //  * 一つ前の画面のリフレッシュ
    //  * 一つ前の遷移で NavigationUtil.bindRefresh が必要
    //  * @param navigation
    //  */
    // preScreenRefresh = (navigation: any) => {
    //     if (navigation.state.params.refresh != null) {
    //         navigation.state.params.refresh();
    //     }
    // };
}

export default new NavigationUtil();
