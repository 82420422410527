import React from 'react'
import {ActivityIndicator, Dimensions, Platform, SafeAreaView, ScrollView, Text, View} from 'react-native'
import {appColors, appFont, appS} from '../../resources/styles/style'
import {BaseScreen} from "./BaseScreen";
import LoginUtil from "../util/LoginUtil";
import {IndicatorComponent} from "../components/IndicatorComponent";
// @ts-ignore
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scrollview';
import LoginDataDao from "../data/dao/local/LoginDataDao";
import {LoginDataEntityModel} from "../data/entityModels/LoginDataEntityModel";
import {Keyword, KeywordApiFactory,} from "../data/network/swagger-gen";
import AppG from "../util/AppG";
import {AxiosResponse} from "axios";
import {CustomHeaderComponentSmall} from "../components/small/CustomHeaderComponentSmall";
import UrlUtil from "../util/UrlUtil";
import {SpacerComponent} from "../components/SpacerComponent";
// @ts-ignore
import isURL from 'validator/lib/isURL';
import ValidateUtil from "../util/ValidateUtil";
// @ts-ignore
import {log} from '../../../environment';
import {SearchScreenModel} from "../data/models/screen/SearchScreenModel";
import ScreenSizeUtil from "../util/ScreenSizeUtil";
import {CustomHeaderComponentWide} from "../components/wide/CustomHeaderComponentWide";
import {WideScreenAdComponent} from "../components/wide/WideScreenAdComponent";
import AnalyticsUtil, {AnalyticsEventName, DOpenEventName} from "../util/firebase/AnalyticsUtil";

import {SearchWordsComponent} from "../components/SearchWordsComponent";
import {TabBorderComponent} from "../components/TabBorderComponent";
import {WideScreenSNSComponent} from "../components/wide/WideScreenSNSComponent";
import {WideSubTitleComponent} from "../components/wide/WideSubTitleComponent";
import LinkUtil from "../util/LinkUtil";

interface Props {
    navigation: any, // ナビゲーション用
}

interface State {
    resizeWindow: boolean,  // 値に意味はなし。windowサイズの拡大縮小をrenderに送るためのもの
    isLoaded: boolean,

    // コンテンツ
    headerTitle: string,
    isLoadRecipeKeywords: boolean,    // キーワード一覧ロード中
    isLoadProductKeywords: boolean,    // キーワード一覧ロード中
    recipeKeywords: Keyword[],    // レシピキーワード一覧
    productKeywords: Keyword[],    // 商品キーワード一覧
}

export class SearchKeywordScreen extends BaseScreen<Props, State> {
    _resizeTimer = setInterval(() => {
        // window 拡大縮小を反映させる
        AppG.window = Dimensions.get('window');
        if (AppG.window.width != AppG.windowBefore.width || AppG.window.height != AppG.windowBefore.height) {
            this.setState({'resizeWindow': true});
            AppG.windowBefore = Dimensions.get('window');
        }
        LoginUtil.setRefreshedScreenFlg(false);
    }, 100);

    _isWebReRendered = false;

    _loginData = new LoginDataEntityModel();

    _flameSize = ScreenSizeUtil.isWebSize()? AppG.window.width / 10 : 16;    // 左右の margin
    _marginSize = ScreenSizeUtil.isWebSize()? 0 : appS.margin.size;

    // このScreenへ渡すパラム
    _param: SearchScreenModel = new SearchScreenModel();
    _contentsLoadCount = 20; // コンテンツの一度の読み込み数
    _searchText = ''; // 検索文字

    /**
     * どっちの検索か
     */
    _searchType: 'recipe' | 'product' | null = 'recipe';

    // タブ
    _tabPage: any = null;
    _tabClicked: boolean = false;
    _tabIndex: number = 0;
    _tabPress = (tabNumber: number) => {
        if (this._tabIndex == tabNumber) {
            return;
        }
        this._tabClicked = true;
        if (this._tabPage != null) {
            this._tabPage.animateToPage(tabNumber);
        }
        if (tabNumber == 0) {
            this._searchType = 'recipe';
            this._tabIndex = 0;
            AppG.screenHistory.unshift(`【キーワード一覧検索画面】レシピタブ`);
        } else {
            this._searchType = 'product';
            this._tabIndex = 1;
            AppG.screenHistory.unshift(`【キーワード一覧画面】商品タブ`);
        }
        this.setState({'resizeWindow': true});
    };
    _tabs: Tab[] = [
        {
            index: 0,    // 0 〜
            title: 'レシピ',
            onPress: () => {this._tabPress(0)},
            color: appColors.recipeHeader,
            unselectColor: appColors.tabGray,
            backgroundColor: appColors.white,
            unselectBackgroundColor: appColors.white,
            badge: 0,
        },
        {
            index: 1,    // 0 〜
            title: '商品',
            onPress: () => {this._tabPress(1)},
            color: appColors.productHeader,
            unselectColor: appColors.tabGray,
            backgroundColor: appColors.white,
            unselectBackgroundColor: appColors.white,
            badge: 0,
        },
    ];

    constructor(props: any) {
        super(props);
        this.state = {
            resizeWindow: true,  // 値に意味はなし。windowサイズの拡大縮小をrenderに送るためのもの
            isLoaded: false,
            headerTitle: '',
            isLoadRecipeKeywords: false,
            isLoadProductKeywords: false,
            recipeKeywords: [],
            productKeywords: [],
        };
    }

    /**
     * 画面開いたときのアナリティクス送信
     */
    _sendPvAnalytics = () => {
        let _type: 'recipe' | 'product' | 'all' | null | undefined  = this._param.searchType;
        let screenName = 'SearchKeywordScreen';
        if (_type == 'recipe') {
            screenName = 'SearchKeywordRecipeScreen';
        } else if (_type == 'product') {
            screenName = 'SearchKeywordProductScreen';
        }

        AnalyticsUtil.sendAnalytics(
            DOpenEventName.openPage,
            AnalyticsEventName.openPage,  // アナリティクスのイベント名
            screenName,
            null,
            null,
            null,
            null,
        );
    }

    componentDidMount() {
        this._param = this._getParams();
        if (this._param.searchType == "recipe") {
            this._tabIndex = 0;
        } else {
            this._tabIndex = 1;
        }

        LoginUtil.interruptOpenScreen(this.props.navigation,
            () => {
                LoginDataDao.get().then(loginData => {
                    // Web 戻るでも呼ばれる
                    if (Platform.OS == 'web') {
                        // Analytics
                        this._sendPvAnalytics();
                    }
                    AppG.getFooterBadge();

                    // URL Scheme で外部リンクから起動したときの処理と、画面遷移でこの画面を開いたときの処理
                    LoginUtil.setDefaultListener(this.props.navigation, () => {
                        // Webはここにこない
                        if (Platform.OS != 'web') {
                            // Analytics
                            this._sendPvAnalytics();
                        }
                    });
                    if (loginData != null) {
                        this._loginData = loginData;
                    }
                    this._loadMasters().then(() => {this._loadContents().then(() => this.setState({'isLoaded': true}))});
                    this.setState({headerTitle: !ValidateUtil.isEmptyExact(this._param.headerTitle) ? this._param.headerTitle! : ''});
                });
            });
    }

    componentWillUnmount () {
        clearTimeout(this._resizeTimer);
        LoginUtil.removeDefaultListener(this.props.navigation);
    };

    componentDidUpdate () {
        // レンダー完了時に呼ばれる
        if (Platform.OS == 'web' && !this._isWebReRendered) {
            // web で初期表示で描画されないバグが発生したが、リサイズすると描画されるのでこれで対応
            this._isWebReRendered = true;
            setTimeout(() => {
                this.setState({'resizeWindow': true});
            }, 100);
        }
    }

    /**
     * パラムの取得
     * @private
     */
    _getParams = () => {
        let searchScreenModel: SearchScreenModel = UrlUtil.getParamModel(new SearchScreenModel(), this.props.navigation);
        return searchScreenModel;
    };

    /**
     * マスターのロード
     * @private
     */
    _loadMasters = async () => {
    };

    /**
     * コンテンツのロード
     * @private
     */
    _loadContents = async () => {
        let _type: 'recipe' | 'product' | 'all' | null | undefined  = this._param.searchType;
        if (_type == null) {
            _type = 'recipe';
        } else if (_type == 'all') {
            _type = 'recipe';
        }

        if (_type == 'recipe') {
            if (ValidateUtil.isEmptyArray(this.state.recipeKeywords)) {
                // キーワード取得
                this._getRecipeKeywords(true);
            }
        } else {
            if (ValidateUtil.isEmptyArray(this.state.productKeywords)) {
                // キーワード取得
                this._getProductKeywords(true);
            }
        }
    };

    /**
     * 検索
     * @param text
     */
    _searchSubmit = (text: string) => {
        this._searchText = text;
        this.setState({headerTitle: ''});
        this._param.headerTitle = '';
        // TODO
        // this._freeWordSearch(0, this._contentsLoadCount, true);
    };

    /**
     * 商品キーワードの取得
     */
    _getProductKeywords = async (resetFlg: boolean) => {
        // キーワード
        this.setState({isLoadProductKeywords: true});
        const getKeywords = KeywordApiFactory(AppG.getConfiguration(), AppG.getBasePath())
            .getKeywords(
                1000,
                'product',
            ).then((keywordsRes: AxiosResponse<Keyword[]>) => {
                if (keywordsRes != null && keywordsRes.data != null) {
                    let _keywords: Keyword[] = [];
                    if (!resetFlg) {
                        _keywords = this.state.productKeywords;
                    }
                    keywordsRes.data.forEach((keyword: Keyword) => {
                        _keywords.push(keyword);
                    });
                    this.setState({productKeywords: _keywords});
                }
                this.setState({isLoadProductKeywords: false});
            });

        // 実行
        await Promise.all([getKeywords]);
    }

    /**
     * レシピキーワードの取得
     */
    _getRecipeKeywords = async (resetFlg: boolean) => {
        // キーワード
        this.setState({isLoadRecipeKeywords: true});
        const getKeywords = KeywordApiFactory(AppG.getConfiguration(), AppG.getBasePath())
            .getKeywords(
                1000,
                'recipe'
            ).then((keywordsRes: AxiosResponse<Keyword[]>) => {
                if (keywordsRes != null && keywordsRes.data != null) {
                    let _keywords: Keyword[] = [];
                    if (!resetFlg) {
                        _keywords = this.state.recipeKeywords;
                    }
                    keywordsRes.data.forEach((keyword: Keyword) => {
                        _keywords.push(keyword);
                    });
                    this.setState({recipeKeywords: _keywords});
                }
                this.setState({isLoadRecipeKeywords: false});
            });

        // 実行
        await Promise.all([getKeywords]);
    }

    /**
     * 商品キーワード一覧表示
     */
    _renderProductKeywords = () => {
        let _width = AppG.window.width;
        if (ScreenSizeUtil.isWebSize()) {
            _width = appS.webMainContentsSize.width;
        }
        return (
            <View style={[{flex: 1, flexDirection: 'column'}]}>
                <SpacerComponent height={this._marginSize}/>
                <View style={[{flex: 1, flexDirection: ScreenSizeUtil.isWebSize()? 'row': 'column', justifyContent: 'center'}]}>
                    <View style={[{flexDirection: 'column'}]}>
                        <View style={{width: _width}}>
                            {ScreenSizeUtil.isWebSize() && <WideSubTitleComponent title={`${this._param.headerTitle}`} width={_width} />}

                            {!ValidateUtil.isEmptyArray(this.state.productKeywords) && (
                                <SearchWordsComponent
                                    showIndexName={true}
                                    keywords={this.state.productKeywords}
                                    onPress={(keyword) => {
                                        const { routeName } = this.props.navigation.state;
                                        let searchScreenModel: SearchScreenModel = new SearchScreenModel();
                                        searchScreenModel.headerTitle = `${keyword.value}`;
                                        searchScreenModel.searchType = 'product';
                                        searchScreenModel.keyword = `${keyword.value}`;
                                        searchScreenModel.searchValue = `${keyword.value}`;
                                        searchScreenModel.screenNameFrom = routeName;
                                        searchScreenModel.tabHidden = true;
                                        if (Platform.OS == 'web') {
                                            this.props.navigation.navigate('SearchResultScreen', searchScreenModel);
                                        } else {
                                            this.props.navigation.push('SearchResultScreen', searchScreenModel);
                                        }
                                    }}/>
                            )}
                        </View>
                        {/*ローディングインジケーター*/}
                        <View style={{marginTop: 32, height: appS.activityIndicator.height}}>
                            <ActivityIndicator
                                animating={this.state.isLoadProductKeywords}
                                color = {appColors.indicator}
                                size = "large"
                                style={[{flex: 1}]}/>
                        </View>
                        {/*<SpacerComponent height={200}/>*/}
                    </View>
                    {ScreenSizeUtil.isWebSize() && (
                        <View style={{marginLeft: appS.margins.webBetweenMargin, width: appS.webSideContentsSize.width}}>
                            <WideScreenAdComponent navigation={this.props.navigation}/>
                            <WideScreenSNSComponent navigation={this.props.navigation}/>
                        </View>
                    )}
                </View>
                {/*<SpacerComponent height={appS.footer.scrollBottom} />*/}
            </View>
        );
    }

    /**
     * レシピキーワード一覧表示
     */
    _renderRecipeKeywords = () => {
        let _width = AppG.window.width;
        if (ScreenSizeUtil.isWebSize()) {
            _width = appS.webMainContentsSize.width;
        }

        return (
            <View style={[{flex: 1, flexDirection: 'column'}]}>
                <SpacerComponent height={this._marginSize}/>
                <View style={[{flex: 1, flexDirection: ScreenSizeUtil.isWebSize()? 'row': 'column', justifyContent: 'center'}]}>
                    <View style={[{flexDirection: 'column'}]}>
                        <View style={{width: _width}}>
                            {ScreenSizeUtil.isWebSize() && <WideSubTitleComponent title={`${this._param.headerTitle}`} width={_width} />}

                            {!ValidateUtil.isEmptyArray(this.state.recipeKeywords) && (
                                <SearchWordsComponent
                                    showIndexName={true}
                                    keywords={this.state.recipeKeywords}
                                    onPress={(keyword) => {
                                        const { routeName } = this.props.navigation.state;
                                        let searchScreenModel: SearchScreenModel = new SearchScreenModel();
                                        searchScreenModel.headerTitle = `${keyword.value}`;
                                        searchScreenModel.searchType = 'recipe';
                                        searchScreenModel.keyword = `${keyword.value}`;
                                        searchScreenModel.searchValue = `${keyword.value}`;
                                        searchScreenModel.screenNameFrom = routeName;
                                        searchScreenModel.tabHidden = true;
                                        if (Platform.OS == 'web') {
                                            this.props.navigation.navigate('SearchResultScreen', searchScreenModel);
                                        } else {
                                            this.props.navigation.push('SearchResultScreen', searchScreenModel);
                                        }
                                    }}/>
                            )}
                        </View>
                        {/*ローディングインジケーター*/}
                        <View style={{marginTop: 32, height: appS.activityIndicator.height}}>
                            <ActivityIndicator
                                animating={this.state.isLoadRecipeKeywords}
                                color = {appColors.indicator}
                                size = "large"
                                style={[{flex: 1}]}/>
                        </View>
                    </View>
                    {ScreenSizeUtil.isWebSize() && (
                        <View style={{marginLeft: appS.margins.webBetweenMargin, width: appS.webSideContentsSize.width}}>
                            <WideScreenAdComponent navigation={this.props.navigation}/>
                            <WideScreenSNSComponent navigation={this.props.navigation}/>
                        </View>
                    )}
                </View>
            </View>
        );
    }

    _renderWide = () => {
        const {navigation} = this.props;
        return (
            <SafeAreaView
                style={[{height: AppG.window.height, flex: 1, flexDirection: 'column', backgroundColor: appColors.white}]}
                onLayout={(event) => {}}
            >
                <CustomHeaderComponentWide
                    navigation={navigation}
                    searchBar={true}
                    onSearchSubmit={(text: string) => {this._searchSubmit(text)}}
                />

                <View style={{
                    flex: 1,
                    flexDirection: 'row',
                    alignSelf: 'center',
                    backgroundColor: appColors.backBaseColor,
                }}>
                    <ScrollView
                        horizontal  // 下階層のスクロールイベントを拾えるようにしている
                        style={{
                            width: AppG.window.width,
                            height: AppG.window.height - appS.header.webHeight,
                        }}
                    >
                        <View style={[{flex: 1, flexDirection: 'column', width: AppG.window.width}]}>
                            <KeyboardAwareScrollView
                                style={[{flex: 1, flexDirection: 'column'}]}
                            >
                                <View
                                    style={{
                                        flex: 1,
                                        alignSelf: 'center',
                                        width: appS.webAllContentsSize.width,
                                        backgroundColor: appColors.backBaseColor,
                                    }}
                                >
                                    <View style={[{flex: 1, flexDirection: 'column'}]}>
                                        {/* キーワード一覧の表示*/}
                                        {this._tabIndex == 0 && this._renderRecipeKeywords()}
                                        {/*商品キーワード一覧の表示*/}
                                        {this._tabIndex == 1 && this._renderProductKeywords()}
                                    </View>
                                </View>
                            </KeyboardAwareScrollView>
                        </View>
                    </ScrollView>
                </View>
            </SafeAreaView>
        );
    }


    _renderSmall = () => {
        let _width = AppG.window.width;
        let _height = AppG.window.height - appS.footer.scrollBottom;
        if (ScreenSizeUtil.isWebSize()) {
            _width = appS.webMainContentsSize.width;
        }

        return (
            // appStyles.statusBarHiddenSafeAreaでステータスバー分上に上げる
            <SafeAreaView
                style={[{height: AppG.window.height, flex: 1, flexDirection: 'column', backgroundColor: appColors.white}]}
                onLayout={(event) => {}}
            >
                <View style={{zIndex: 99,}}>
                    <CustomHeaderComponentSmall
                        navigation={this.props.navigation}
                        leftButton={'back'}
                        rightButton={null}
                        title={this._param.headerTitle!}
                        onSearchSubmit={(text: string) => {this._searchSubmit(text)}}
                        textColor={appColors.black}
                        barColor={appColors.white}
                        borderBottomColor={appColors.transparent}
                        statusBarType={"dark-content"}
                        // onSearchLinkIcon={() => {
                        // }}
                    />
                    {/*Border*/}
                    <View style={{flexDirection: "row"}}>
                        <View style={{flex: 1, height: 1, backgroundColor: appColors.spacerGray}}/>
                    </View>
                </View>

                {!this._param.tabHidden && (
                    <TabBorderComponent
                        tabs={this._tabs}
                        focusTabNumber={this._tabIndex}
                        unselectBorder={true}
                        button={true}
                    />
                )}

                <View style={{flex:1, backgroundColor: appColors.backBaseColor}}>
                    <KeyboardAwareScrollView style={[{flex: 1, flexDirection: 'column'}]}>
                        {/* キーワード一覧の表示*/}
                        {this._tabIndex == 0 && this._renderRecipeKeywords()}
                        {/*商品キーワード一覧の表示*/}
                        {this._tabIndex == 1 && this._renderProductKeywords()}
                    </KeyboardAwareScrollView>
                </View>
            </SafeAreaView>
        );
    }


    /** 描画 */
    render() {
        const {navigation} = this.props;
        if (!this.state.isLoaded) {
            return (
                <View style={{flex: 1}}>
                    <IndicatorComponent
                        failTime={60}
                        isLoading={true}
                        reloadFunc={() => {
                            this.componentWillUnmount();
                            this.componentDidMount();
                        }}/>
                </View>
            );
        }

        LinkUtil.addReturnFalseToATag();
        if (ScreenSizeUtil.isWebSize()) {
            // WEBサイズ
            return this._renderWide();
        } else {
            // SPサイズ
            return this._renderSmall();
        }
    }




}
