import React from 'react'
import {
    ActivityIndicator,
    Dimensions,
    Platform,
    SafeAreaView,
    ScrollView,
    View
} from 'react-native'
import {appColors, appFont, appS} from '../../resources/styles/style'
import {BaseScreen} from "./BaseScreen";
import LoginUtil from "../util/LoginUtil";
import {IndicatorComponent} from "../components/IndicatorComponent";
// @ts-ignore
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scrollview';
import LoginDataDao from "../data/dao/local/LoginDataDao";
import {LoginDataEntityModel} from "../data/entityModels/LoginDataEntityModel";
import {Product, ProductApiFactory, Recipe, RecipeApiFactory,} from "../data/network/swagger-gen";
import AppGlobalDataUtil from "../util/AppG";
import AppG from "../util/AppG";
import UrlUtil from "../util/UrlUtil";
// @ts-ignore
import isURL from 'validator/lib/isURL';
// @ts-ignore
import {log} from '../../../environment';
import {FollowScreenModel} from "../data/models/screen/FollowScreenModel";
import {AxiosResponse} from "axios";
import {SpacerComponent} from "../components/SpacerComponent";
import ValidateUtil from "../util/ValidateUtil";
import ExpoUtil from "../util/ExpoUtil";
import ScreenSizeUtil from "../util/ScreenSizeUtil";
import {CustomHeaderComponentWide} from "../components/wide/CustomHeaderComponentWide";
import {WideScreenAdComponent} from "../components/wide/WideScreenAdComponent";
import AnalyticsUtil, {AnalyticsEventName, DOpenEventName} from "../util/firebase/AnalyticsUtil";
import {CustomHeaderComponentSmall} from "../components/small/CustomHeaderComponentSmall";
import * as Notifications from "expo-notifications";
import {TabBorderComponent} from "../components/TabBorderComponent";
import {RecipeSearchResultListComponent} from "../components/RecipeSearchResultListComponent";
import {ProductSearchResultListComponent} from "../components/ProductSearchResultListComponent";
import {WideSubTitleComponent} from "../components/wide/WideSubTitleComponent";
import {NoUserImageForFollowComponent} from "../components/NoUserImageForFollowComponent";
import MyAppUtil from "../util/MyAppUtil";
import {WideScreenSNSComponent} from "../components/wide/WideScreenSNSComponent";
import LinkUtil from "../util/LinkUtil";
import {NothingFollowImageComponent} from "../components/NothingFollowImageComponent";
import Carousel from "react-native-reanimated-carousel";

interface Props {
    navigation: any, // ナビゲーション用
}

interface State {
    resizeWindow: boolean,  // 値に意味はなし。windowサイズの拡大縮小をrenderに送るためのもの
    isLoaded: boolean,

    // コンテンツ
    isLoadRecipes: boolean,
    recipes: Recipe[], // レシピ
    isLoadProducts: boolean,
    products: Product[], // このレシピに紐付く商品
}

export class FollowScreen extends BaseScreen<Props, State> {
    _resizeTimer = setInterval(() => {
        // window 拡大縮小を反映させる
        AppG.window = Dimensions.get('window');
        if (AppG.window.width != AppG.windowBefore.width || AppG.window.height != AppG.windowBefore.height) {
            this.setState({'resizeWindow': true});
            AppG.windowBefore = Dimensions.get('window');
        }
        LoginUtil.setRefreshedScreenFlg(false);
    }, 100);

    _loginData = new LoginDataEntityModel();

    _flameSize = ScreenSizeUtil.isWebSize()? AppG.window.width / 10 : 16;    // 左右の margin
    _marginSize = ScreenSizeUtil.isWebSize()? 0 : appS.margin.size;

    // このScreenへ渡すパラム
    _param: FollowScreenModel = new FollowScreenModel();
    _contentsLoadCount = 30; // コンテンツの一度の読み込み数

    // スクロール用
    _noLoginScrollTab: any = null;
    _recipeScrollTab: any = null;
    _productScrollTab: any = null;

    // タブ
    _tabClicked = false;
    _tabIndex = 0;
    _tabPage: any = null;
    /** tab 本体 */
    _tabRef: any = null;
    _tabPress = (tabNumber: number) => {
        if (this._tabIndex == tabNumber) {
            return;
        }
        this._tabClicked = true;
        this._tabIndex = tabNumber;
        if (this._tabPage != null) {
            if (this._tabRef != null) {
                this._tabRef.redrawView(tabNumber);
            }
            this._tabPage.scrollTo({index: tabNumber, animated: false});
        }
        setTimeout(() => {this.setState({resizeWindow: true});}, 10);
        setTimeout(() => {this.setState({resizeWindow: true});}, 10);
    };
    _tabs: Tab[] = [
        {
            index: 0,    // 0 〜
            title: 'レシピ',
            onPress: () => {this._tabPress(0)},
            color: appColors.recipeHeader,
            unselectColor: appColors.tabGray,
            backgroundColor: appColors.white,
            unselectBackgroundColor: appColors.white,
            badge: 0,
        },
        {
            index: 1,    // 0 〜
            title: '商品',
            onPress: () => {this._tabPress(1)},
            color: appColors.productHeader,
            unselectColor: appColors.tabGray,
            backgroundColor: appColors.white,
            unselectBackgroundColor: appColors.white,
            badge: 0,
        },
    ];

    /**
     * タブページの中身
     * @param index
     */
        // @ts-ignore
    _renderPageItems = ({item}) => {
        let index: number = item;
        switch (index) {
            case 0:
                return this._renderFollowRecipes();
            case 1:
                return this._renderFollowProducts();
            default:
                return this._renderFollowRecipes()
        }
    }


    _browserBackListener = (e: any) => {

    };

    constructor(props: any) {
        super(props);
        this.state = {
            resizeWindow: true,  // 値に意味はなし。windowサイズの拡大縮小をrenderに送るためのもの
            isLoaded: false,
            isLoadRecipes: true,
            recipes: [],
            isLoadProducts: true,
            products: [],
        };
    }

    /**
     * 画面開いたときのアナリティクス送信
     */
    _sendPvAnalytics = () => {
        AnalyticsUtil.sendAnalytics(
            DOpenEventName.openPage,
            AnalyticsEventName.openPage,  // アナリティクスのイベント名
            'FollowScreen',
            null,
            null,
            null,
            null,
        );
    }

    componentDidMount() {
        if (Platform.OS == 'web') {
            window.addEventListener('popstate', this._browserBackListener);
        }

        if (Platform.OS != 'web') {
            // スクロールviewのセット
            this.props.navigation.setParams({
                scrollToTop: () => {
                    if (this._noLoginScrollTab != null) {
                        this._noLoginScrollTab.scrollTo({ x: 0, y: 0, animated: true });
                    } else {
                        switch (this._tabIndex) {
                            case 0:
                                if (this._recipeScrollTab != null) {
                                    this._recipeScrollTab.scrollTo({ x: 0, y: 0, animated: true });
                                }
                                break;
                            case 1:
                                if (this._productScrollTab != null) {
                                    this._productScrollTab.scrollTo({x: 0, y: 0, animated: true});
                                }
                                break;
                        }
                    }
                }
            })
        }

        LoginUtil.interruptOpenScreen(this.props.navigation,
            () => {
                LoginDataDao.get().then(loginData => {
                    // Web 戻るでも呼ばれる (Footerにあるやつはappでも実行するようにする)
                    // Analytics
                    this._sendPvAnalytics();

                    if (Platform.OS != 'web') {
                        // アイコンバッヂの更新
                        Notifications.setBadgeCountAsync((AppG.badgeChat == null? 0 : AppG.badgeChat) + (AppG.badgeHistory == null? 0 : AppG.badgeHistory));
                    }
                    AppG.getFooterBadge();

                    // URL Scheme で外部リンクから起動したときの処理と、画面遷移でこの画面を開いたときの処理
                    LoginUtil.setDefaultListener(
                        this.props.navigation,
                        () => {
                            // Webはここにこない
                            if (Platform.OS != 'web') {
                                // Analytics
                                this._sendPvAnalytics();
                            }

                            ExpoUtil.doReloadAppTab(() => {
                                this.setState({recipes: []});
                                this.setState({products: []});
                                this._loadContents().then(() => {});
                                AppG.getFooterBadge();
                            });
                        });
                    if (loginData != null && !ValidateUtil.isEmptyExact(loginData?.user_id)) {
                        this._loginData = loginData;
                        this._loadMasters().then(() => {});
                        this._loadContents().then(() => {});
                    }
                    this.setState({'isLoaded': true});
                });
            });
    }

    componentWillUnmount () {
        clearTimeout(this._resizeTimer);
        if (Platform.OS === 'web') {
            window.removeEventListener('popstate', this._browserBackListener);
        }
        LoginUtil.removeDefaultListener(this.props.navigation);
    };

    componentDidUpdate () {
        // レンダー完了時に呼ばれる
    }

    /**
     * パラムの取得
     * @private
     */
    _getParams = () => {
        let followScreenModel: FollowScreenModel = UrlUtil.getParamModel(new FollowScreenModel(), this.props.navigation);
        return followScreenModel;
    };

    /**
     * マスターのロード
     * @private
     */
    _loadMasters = async () => {

    };

    /**
     * コンテンツのロード
     * @private
     */
    _loadContents = async () => {
        this._param = this._getParams();

        //  お気に入りレシピ
        this._getFollowRecipes(0, this._contentsLoadCount);

        // お気に入り商品
        this._getFollowProducts(0, this._contentsLoadCount);

    };

    /**
     * お気に入りレシピ読み込み
     * @param from
     * @param to
     */
    _getFollowRecipes = async (from: number, to: number) => {
        this.setState({isLoadRecipes: true});
        const getFollowRecipes = RecipeApiFactory(AppGlobalDataUtil.getConfiguration(), AppGlobalDataUtil.getBasePath())
            .findRecipes(
                from,
                to,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                1,
                4,
            ).then((resultRecipesRes: AxiosResponse<Recipe[]>) => {
                if (resultRecipesRes != null && !ValidateUtil.isEmptyArray(resultRecipesRes.data)) {
                    let _recipes: Recipe[] = this.state.recipes;
                    resultRecipesRes.data.forEach((recipe: Recipe) => {
                        // log.debug(newRecipe);
                        _recipes.push(recipe);
                    });

                    this.setState({recipes: _recipes});
                }
                this.setState({isLoadRecipes: false});
            });

        // 実行
        await Promise.all([getFollowRecipes]);
    };

    /**
     * お気に入り商品読み込み
     * @param from
     * @param to
     */
    _getFollowProducts = async (from: number, to: number) => {
        this.setState({isLoadProducts: true});
        const getFollowProducts = ProductApiFactory(AppGlobalDataUtil.getConfiguration(), AppGlobalDataUtil.getBasePath())
            .findProducts(
                from,
                to,
                1,
                undefined,
                undefined,
                undefined,
                undefined,
                4,
            ).then((resultProductsRes: AxiosResponse<Product[]>) => {
                if (resultProductsRes != null && !ValidateUtil.isEmptyArray(resultProductsRes.data)) {
                    let _products: Product[] = this.state.products;
                    resultProductsRes.data.forEach((product: Product) => {
                        _products.push(product);
                    });

                    this.setState({products: _products});
                }
                this.setState({isLoadProducts: false});
            });

        // 実行
        await Promise.all([getFollowProducts]);
    };


    /**
     * お気に入りレシピの描画
     */
    _renderFollowRecipes = () => {
        let _width = AppG.window.width;
        if (ScreenSizeUtil.isWebSize()) {
            _width = appS.webMainContentsSize.width;
        }

        return (
            <View style={[{flex: 1, flexDirection: 'column', width: AppG.window.width}]}>
                <KeyboardAwareScrollView
                    ref={(recipeScrollTab: any) => { this._recipeScrollTab = recipeScrollTab; }}
                    style={[{flex: 1, flexDirection: 'column'}]}
                    onScroll={(event: any) => {
                        // 無限スクロール
                        // スクロールViewの高さ
                        const scrollViewHeight = event.nativeEvent.contentSize.height - event.nativeEvent.layoutMeasurement.height;
                        // スクロールView上の現在位置
                        const nowPositionHeight = event.nativeEvent.contentOffset.y;
                        // 現在の位置がスクロールView + activityIndicator の高さよりも下の場合
                        const indicatorHeight = Platform.OS == 'web'? -10: -appS.activityIndicator.height;
                        if (!this.state.isLoadRecipes && this.state.recipes.length >= this._contentsLoadCount - 1 && nowPositionHeight - scrollViewHeight >  indicatorHeight) {
                            this._getFollowRecipes(this.state.recipes.length, this.state.recipes.length + this._contentsLoadCount);
                        }
                    }}
                >
                    {ScreenSizeUtil.isWebSize() && (<SpacerComponent height={appS.margins.side}/>)}

                    <View
                        // activeOpacity={1}
                        style={[{flex: 1, flexDirection: ScreenSizeUtil.isWebSize()? 'row': 'column', alignSelf: 'center'}]}>
                        <View style={[{flexDirection: 'column', backgroundColor: (!this.state.isLoadRecipes && ValidateUtil.isEmptyArray(this.state.recipes))? appColors.noUserBackground : appColors.backBaseColor}]}>
                            <View style={{width: _width}}>
                                {ScreenSizeUtil.isWebSize() && <WideSubTitleComponent title={'お気に入り'} width={_width} />}
                                {ScreenSizeUtil.isWebSize() && (
                                    <TabBorderComponent
                                        ref={(tabRef: any) => { this._tabRef = tabRef; }}
                                        tabs={this._tabs}
                                        focusTabNumber={0}
                                        unselectBorder={true}
                                        button={true}
                                    />
                                )}

                                {ScreenSizeUtil.isWebSize() && (<SpacerComponent height={appS.margins.side}/>)}

                                <View style={{marginTop: -appS.margins.side}}>
                                    <RecipeSearchResultListComponent
                                        navigation={this.props.navigation}
                                        recipes={this.state.recipes}
                                        follow={true}
                                        analyticsFollowScreenName={'FollowScreen'}
                                        enableATag={false}
                                    />
                                </View>

                                {!this.state.isLoadRecipes && ValidateUtil.isEmptyArray(this.state.recipes) && (
                                    <ScrollView style={{flex: 1, backgroundColor: appColors.noUserBackground, height: AppG.window.height}}>
                                        <NothingFollowImageComponent navigation={this.props.navigation} />
                                    </ScrollView>
                                )}

                                {/*レシピローディングインジケーター*/}
                                <View style={{width: ScreenSizeUtil.isWebSize()? AppG.window.width / 2 : AppG.window.width, marginTop: 32, height: appS.activityIndicator.height}}>
                                    <ActivityIndicator
                                        animating={this.state.isLoadRecipes}
                                        color = {appColors.indicator}
                                        size = "large"
                                        style={[{flex: 1}]}/>
                                </View>

                                <SpacerComponent height={appS.footer.scrollBottom}/>
                            </View>
                        </View>
                        {ScreenSizeUtil.isWebSize() && (
                            <View style={{marginLeft: appS.margins.webBetweenMargin, width: appS.webSideContentsSize.width}}>
                                <WideScreenAdComponent navigation={this.props.navigation}/>
                                <WideScreenSNSComponent navigation={this.props.navigation}/>
                            </View>
                        )}
                    </View>
                </KeyboardAwareScrollView>
            </View>
        );
    };

    /**
     * お気に入り商品の描画
     */
    _renderFollowProducts = () => {
        let _width = AppG.window.width;
        if (ScreenSizeUtil.isWebSize()) {
            _width = appS.webMainContentsSize.width;
        }

        return (
            <View style={[{flex: 1, flexDirection: 'column', width: AppG.window.width}]}>
                <KeyboardAwareScrollView
                    ref={(productScrollTab: any) => { this._productScrollTab = productScrollTab; }}
                    style={[{flex: 1, flexDirection: 'column'}]}
                    onScroll={(event: any) => {
                        // 無限スクロール
                        // スクロールViewの高さ
                        const scrollViewHeight = event.nativeEvent.contentSize.height - event.nativeEvent.layoutMeasurement.height;
                        // スクロールView上の現在位置
                        const nowPositionHeight = event.nativeEvent.contentOffset.y;
                        // 現在の位置がスクロールView + activityIndicator の高さよりも下の場合
                        const indicatorHeight = Platform.OS == 'web'? -10: -appS.activityIndicator.height;
                        if (!this.state.isLoadProducts && this.state.products.length >= this._contentsLoadCount - 1 && nowPositionHeight - scrollViewHeight >  indicatorHeight) {
                            this._getFollowProducts(this.state.products.length, this.state.products.length + this._contentsLoadCount);
                        }
                    }}
                >
                    {ScreenSizeUtil.isWebSize() && (<SpacerComponent height={appS.margins.side}/>)}

                    <View
                        // activeOpacity={1}
                        style={[{flex: 1, flexDirection: ScreenSizeUtil.isWebSize()? 'row': 'column', justifyContent: 'center'}]}>
                        <View style={[{flexDirection: 'column', backgroundColor: (!this.state.isLoadProducts && ValidateUtil.isEmptyArray(this.state.products))? appColors.noUserBackground : appColors.backBaseColor}]}>
                            <View style={{width: _width}}>
                                {ScreenSizeUtil.isWebSize() && <WideSubTitleComponent title={'お気に入り'} width={_width} />}
                                {ScreenSizeUtil.isWebSize() && (
                                    <TabBorderComponent
                                        ref={(tabRef: any) => { this._tabRef = tabRef; }}
                                        tabs={this._tabs}
                                        focusTabNumber={1}
                                        unselectBorder={true}
                                        button={true}
                                    />
                                )}

                                {ScreenSizeUtil.isWebSize() && (<SpacerComponent height={appS.margins.side}/>)}

                                <View style={{marginTop: -appS.margins.side}}>
                                    <ProductSearchResultListComponent
                                        navigation={this.props.navigation}
                                        products={this.state.products}
                                        follow={true}
                                        analyticsFollowScreenName={'FollowScreen'}
                                        enableATag={false}
                                    />
                                </View>

                                {!this.state.isLoadProducts && ValidateUtil.isEmptyArray(this.state.products) && (
                                    <ScrollView style={{flex: 1, backgroundColor: appColors.noUserBackground, height: AppG.window.height}}>
                                        <NothingFollowImageComponent navigation={this.props.navigation} />
                                    </ScrollView>
                                )}

                                {/*商品ローディングインジケーター*/}
                                <View style={{width: ScreenSizeUtil.isWebSize()? AppG.window.width / 2 : AppG.window.width, marginTop: 32, height: appS.activityIndicator.height}}>
                                    <ActivityIndicator
                                        animating={this.state.isLoadProducts}
                                        color = {appColors.indicator}
                                        size = "large"
                                        style={[{flex: 1}]}/>
                                </View>

                                <SpacerComponent height={appS.footer.scrollBottom}/>
                            </View>
                        </View>
                        {ScreenSizeUtil.isWebSize() && (
                            <View style={{marginLeft: appS.margins.webBetweenMargin, width: appS.webSideContentsSize.width}}>
                                <WideScreenAdComponent navigation={this.props.navigation}/>
                                <WideScreenSNSComponent navigation={this.props.navigation}/>
                            </View>
                        )}
                    </View>
                </KeyboardAwareScrollView>
            </View>
        );
    };

    /**
     * 未ログイン時の表示
     */
    _renderNoLogin = () => {
        return (
            <ScrollView
                ref={(noLoginScrollTab: any) => { this._noLoginScrollTab = noLoginScrollTab; }}
                style={{flex: 1, backgroundColor: appColors.noUserBackground, height: AppG.window.height}}>
                <NoUserImageForFollowComponent navigation={this.props.navigation} />
            </ScrollView>
        );
    }

    /**
     * SPサイズ
     */
    _renderSmall = () => {
        const {navigation} = this.props;
        return (
            <SafeAreaView
                style={[{height: AppG.window.height, flex: 1, flexDirection: 'column', backgroundColor: appColors.white}]}
                onLayout={(event) => {
                    // window 拡大縮小を反映させる
                    // AppG.window = Dimensions.get('window');
                    // this.setState({'resizeWindow': true});
                }}
            >
                <CustomHeaderComponentSmall
                    navigation={this.props.navigation}
                    leftButton={MyAppUtil.selectWebLogoSp()}
                    rightButton={MyAppUtil.selectWebMenuSp()}
                    title={'お気に入り'}
                    searchBar={false}
                    textColor={appColors.black}
                    barColor={appColors.white}
                    borderBottomColor={appColors.transparent}
                    statusBarType={"dark-content"}
                />

                <View style={{flex: 1, backgroundColor: AppG.user == null ? appColors.noUserBackground : appColors.backBaseColor}}>
                    {/*未ログイン*/}
                    {AppG.user == null && this._renderNoLogin()}

                    {AppG.user != null && (
                        <TabBorderComponent
                            ref={(tabRef: any) => { this._tabRef = tabRef; }}
                            tabs={this._tabs}
                            focusTabNumber={this._tabIndex}
                            unselectBorder={true}
                            button={true}
                        />
                    )}

                    {/*タブコンテンツ*/}
                    {AppG.user != null && (
                        <Carousel
                            ref={(pageRef: any) => { this._tabPage = pageRef; }}
                            enabled={Platform.OS == 'ios'}
                            data={[0, 1]}
                            renderItem={this._renderPageItems}
                            onSnapToItem={(index: number) => {
                                this._tabPress(index);
                            }}
                            width={AppG.window.width}
                            autoPlay={false}
                            loop={false}
                            style={{height: appS.screenSize.fullHeight}}
                        />
                    )}
                </View>
            </SafeAreaView>
        );
    }

    /**
     * WEBサイズ
     */
    _renderWide = () => {
        const {navigation} = this.props;
        return (
            <SafeAreaView
                style={[{height: AppG.window.height, flex: 1, flexDirection: 'column', backgroundColor: appColors.white}]}
                onLayout={(event) => {
                    // window 拡大縮小を反映させる
                    // AppG.window = Dimensions.get('window');
                    // this.setState({'resizeWindow': true});
                }}
            >
                <CustomHeaderComponentWide
                    navigation={navigation}
                    searchBar={true}
                />

                <View style={{
                    flex: 1,
                    flexDirection: 'row',
                    alignSelf: 'center',
                    backgroundColor: AppG.user == null ? appColors.noUserBackground : appColors.backBaseColor,
                }}>
                    {/*未ログイン*/}
                    {AppG.user == null && this._renderNoLogin()}

                    {/*タブコンテンツ*/}
                    {AppG.user != null && (
                        <ScrollView
                            horizontal  // 下階層のスクロールイベントを拾えるようにしている
                            style={{width: AppG.window.width, height: AppG.window.height - appS.header.webHeight}}
                        >
                            {/*レシピタブ*/}
                            {this._tabIndex == 0 && this._renderFollowRecipes()}
                            {this._tabIndex == 1 && this._renderFollowProducts()}
                        </ScrollView>
                    )}
                </View>
            </SafeAreaView>
        );
    }


    /** 描画 */
    render() {
        if (!this.state.isLoaded) {
            return (
                <View style={{flex: 1}}>
                    <IndicatorComponent
                        failTime={60}
                        isLoading={true}
                        reloadFunc={() => {
                            this.componentWillUnmount();
                            this.componentDidMount();
                        }}/>
                </View>
            );
        }

        LinkUtil.addReturnFalseToATag();
        if (ScreenSizeUtil.isWebSize()) {
            // WEBサイズ
            return this._renderWide();
        } else {
            // SPサイズ
            return this._renderSmall();
        }

    };


}
