// import "firebase/firestore";
// import firebase from "firebase";
import { collection, getFirestore, getDoc, setDoc, updateDoc, doc, onSnapshot, query, where, orderBy, limit, addDoc } from 'firebase/firestore';
import {FirestoreBaseDao} from "./FirestoreBaseDao";
import {Messages} from "../../firestoreEntities/Messages";
import {ChatsDao} from "./ChatsDao";
import moment from "moment";
// @ts-ignore
import {log} from '../../../../../environment';

export class MessagesDao extends FirestoreBaseDao {
    /** インスタンス */
    private static _instance: MessagesDao;

    /** コレクションパス */
    private _collectionPath: string = "Messages";

    /** プライベートコンストラクタ */
    private constructor() {
        super();
    }

    /** インスタンスの取得 */
    // @ts-ignore
    public static get instance(): MessagesDao {
        if (!this._instance) {
            this._instance = new MessagesDao();
        }

        // 生成済みのインスタンスを返す
        return this._instance;
    }

    /**
     * collection Helper
     * @param docs チャット(一つ上の)のID
     */
    collection = (docs: {chat: string}) => {
        return collection(ChatsDao.instance.document({chat: docs.chat}),this._collectionPath);
    };

    /**
     * document Helper
     * @param docs 対象のdoc
     */
        // document = (docs: {chat: string, message: string}) => {
        //     if (docs == null || docs.message == null) {
        //         return this.collection(docs).doc();
        //     }
        //     return this.collection(docs).doc(docs.message);
        // };
    document = (docs: {chat: string, message: string}) => {
        if (docs == null || docs.chat == null) {
            return doc(this.collection(docs));
        }
        return doc(this.collection(docs), docs.chat);
    };

    /**
     * 登録
     * @param model Messages
     * @param docs 対象のdoc
     * @param successFunc 追加 or 修正後に実行する function
     */
    addOrUpdate = (
        model: Messages,
        docs: {chat: string, message: string | null},
        successFunc: ((result: any) => void) | null = null
    ) => {
        // console.log('+++++++++++++++++++++++++++++++++++++++++++')
        // console.log(docs)
        if (docs == null || docs.message == null || docs.message == '') {
            // 追加
            addDoc(
                // @ts-ignore
                MessagesDao.instance.collection(docs),
                this.margeModelForAdd(model.data))
                .then(result => {
                    log.debug(`added Chats is  ${result}`);
                    if (successFunc != null) {
                        successFunc(result);
                    }
                }).catch(err => {
                log.debug(`add error: ${err}`);
            });
        } else {
            // 更新
            updateDoc(
                // @ts-ignore
                MessagesDao.instance.document(docs),
                this.margeModelForUpdate(model.data))
                // @ts-ignore
                .then(result => {
                    log.debug("updated Chats is " + result);
                    if (successFunc != null) {
                        // result is undefined
                        successFunc(result);
                    }
                    // @ts-ignore
                }).catch(err => {
                log.debug(`update error: ${err}`);
            });
        }
    };

    /**
     * 削除
     * @param docs 対象のdoc
     */
    delete = async (docs: {chat: string, message: string}) => {
        updateDoc(
            // @ts-ignore
            MessagesDao.instance.document(docs),
            this.getModelForDelete())
            .then(result => {
                log.debug(`deleted Messages is: ${result}`);
            })
            .catch(err => {
                log.debug(`delete error: ${err}`);
            });
    };

    /**
     * スナップショットを設定
     * @param addFunc スナップショットに追加イベントが発火したときのFunc
     * @param updateFunc スナップショットに更新イベントが発火したときのFunc
     * @param deleteFunc スナップショットに削除イベントが発火したときのFunc
     * @param docs Chat ID
     * @param date この日時より後を取得
     */
    snapshot = (
        addFunc: (data: any) => void,
        updateFunc: (data: any) => void,
        deleteFunc: (data: any) => void,
        docs: {chat: string},
        date: Date,
    ) => {
        const q = query(
            MessagesDao.instance.collection(docs),
            where(this.deleteFlgField, "==", false),
            where(this.createdAtField, ">", moment(date).valueOf()),
            orderBy(this.createdAtField, "desc")
        );
        onSnapshot(q,
            (querySnapshot) => {
                querySnapshot.docChanges().forEach(change => {
                    if (change.type === 'added') {
                        addFunc(change.doc);
                    }
                    if (change.type === 'modified') {
                        log.debug('Modified Messages: ');
                        log.debug(change.doc.data());
                        updateFunc(change.doc);
                    }
                    if (change.type === 'removed') {
                        log.debug('Removed Messages: ');
                        log.debug(change.doc.data());
                        deleteFunc(change.doc);
                    }
                },  (err: any) => {
                    log.debug(`Encountered error: ${err}`);
                });
            });
    };

    /**
     * 取得
     * @param successFunc 取得成功時の処理
     * @param docs ID (必要なIDs)
     */
    get = (
        successFunc: (data: any) => void,
        docs: {chat: string, message: string}
    ) => {
        getDoc(MessagesDao.instance.document(docs))
            .then(doc => {
                if (!doc.exists) {
                    log.debug('No such document!');
                } else {
                    successFunc(doc.data());
                }
            })
            .catch(err => {
                log.debug(err);
            });
    };

    /**
     * 最後のメッセージを取得
     * @param successFunc
     * @param docs chatのID
     */
    getLast = (
        successFunc: (data: any) => void,
        docs: {chat: string}
    ) => {
        const q = query(
            // collection(ChatsDao.instance.document({chat: docs.chat}),'Messages'),
            // collection(firestore, 'Chats','Messages'),
            MessagesDao.instance.collection(docs),
            where(this.deleteFlgField, "==", false),
            orderBy(this.createdAtField, "desc"),
            limit(1)
        );
        onSnapshot(q,
            (querySnapshot) => {
                if (querySnapshot.empty) {
                    log.debug('No such document!');
                } else {
                    querySnapshot.forEach((doc) => {
                        successFunc(doc.data());
                    });
                }
            });
    };

    /**
     * 指定日時より前のメッセージを指定数取得
     * @param successFunc 成功時の処理
     * @param docs Chat ID
     * @param date 指定日時
     * @param num 取得数
     */
    getBeforeDate = (
        successFunc: (data: any) => void,
        docs: {chat: string},
        date: Date, // この日時より前を取得
        num: number, // limit
    ) => {
        const q = query(
            MessagesDao.instance.collection(docs),
            where(this.deleteFlgField, "==", false),
            where(this.createdAtField, "<", moment(date).valueOf()),
            orderBy(this.createdAtField, "desc"),
            limit(num)
        );
        onSnapshot(q,
            (querySnapshot) => {
                if (querySnapshot.empty) {
                    // 空だったら
                    successFunc(null);
                } else {
                    querySnapshot.forEach(doc => {
                        log.debug(`${doc.id} => ${doc.data()}`);
                        successFunc(doc);
                    });
                }
            });
    };

}
