import React from 'react'
import {
    ActivityIndicator,
    Dimensions,
    Image,
    Platform,
    SafeAreaView,
    ScrollView,
    Text,
    TouchableOpacity,
    View,
} from 'react-native'
import {appColors, appFont, appS} from '../../resources/styles/style'
import {BaseScreen} from "./BaseScreen";
import NavigationUtil from "../util/NavigationUtil";
import {LoginDataEntityModel} from "../data/entityModels/LoginDataEntityModel";
// @ts-ignore
import {getEnvVars, log} from '../../../environment';
import {
    Keyword,
    KeywordApiFactory,
    Maker,
    MakerApiFactory,
    MasterApiFactory,
    Product,
    ProductApiFactory,
    Recipe,
    RecipeApiFactory,
    RecipeCategory,
    Special,
    SpecialApiFactory,
    SpecialTypeEnum,
    UserApiFactory,
    UserUserPlanEnum
} from "../data/network/swagger-gen";
import AppG from "../util/AppG";
import LoginUtil from "../util/LoginUtil";
import LoginDataDao from "../data/dao/local/LoginDataDao";
// @ts-ignore
import isURL from 'validator/lib/isURL';
import {AxiosResponse} from "axios";
// @ts-ignore
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scrollview';
import Carousel from 'react-native-reanimated-carousel';
import AnimatedDotsCarousel from 'react-native-animated-dots-carousel';
import {SpacerComponent} from "../components/SpacerComponent";
import {CustomHeaderComponentSmall} from '../components/small/CustomHeaderComponentSmall';
import {WideScreenAdComponent} from "../components/wide/WideScreenAdComponent";
import {RecipeListRankingComponent} from "../components/RecipeListRankingComponent";
import RNPickerSelect from 'react-native-picker-select';
import {PullDownIconComponent} from "../components/PullDownIconComponent";
import PickerUtil from "../util/PickerUtil";
import {TopicTitleComponent} from "../components/TopicTitleComponent";
import {RecipeCategoryListComponent} from "../components/RecipeCategoryListComponent";
import {MakerListComponent} from "../components/MakerListComponent";
import ValidateUtil from "../util/ValidateUtil";
import ExpoUtil from "../util/ExpoUtil";
import {SearchScreenModel} from "../data/models/screen/SearchScreenModel";
import ScreenSizeUtil from "../util/ScreenSizeUtil";
import {CustomHeaderComponentWide} from "../components/wide/CustomHeaderComponentWide";
import AnalyticsUtil, {AnalyticsEventName, DOpenEventName} from "../util/firebase/AnalyticsUtil";
import NotificationUtil from "../util/NotificationUtil";
import * as Notifications from 'expo-notifications'
import LinkUtil from "../util/LinkUtil";
import {NoItemsIconComponent} from "../components/NoItemsIconComponent";
import SpecialUtil from "../util/SpecialUtil";
import {BorderComponent} from "../components/BorderComponent";
import FacebookUtil from "../util/FacebookUtil";
import RedirectUtil from "../util/RedirectUtil";
import WebTagUtil from "../util/WebTagUtil";
import {
    RecipeListWithKeywordHorizontalScrollComponent
} from "../components/RecipeListWithKeywordHorizontalScrollComponent";
import {RecipeNewListHorizontalScrollComponent} from "../components/RecipeNewListHorizontalScrollComponent";
import {SeasonComponent} from "../components/SeasonComponent";
import {IndicatorComponent} from "../components/IndicatorComponent";
import {TabBorderComponent} from "../components/TabBorderComponent";
import {SearchWordsComponent} from "../components/SearchWordsComponent";
import {RecipeSearchResultListComponent} from "../components/RecipeSearchResultListComponent";
import {CostComponent} from "../components/CostComponent";
import SearchDataDao from "../data/dao/local/SearchDataDao";
import {ProductListRankingComponent} from "../components/ProductListRankingComponent";
import {ProductNewListHorizontalScrollComponent} from "../components/ProductNewListHorizontalScrollComponent";
import {ProductSearchResultListComponent} from "../components/ProductSearchResultListComponent";
import {
    ProductListWithKeywordHorizontalScrollComponent
} from "../components/ProductListWithKeywordHorizontalScrollComponent";
import {ProductCategoryListComponent} from "../components/ProductCategoryListComponent";
import {NoUserImageComponent} from "../components/NoUserImageComponent";
import {JobCategoryListComponent} from "../components/JobCategoryListComponent";
import {RecipeRankingListHorizontalScrollComponent} from "../components/RecipeRankingListHorizontalScrollComponent";
import {ProductRankingListHorizontalScrollComponent} from "../components/ProductRankingListHorizontalScrollComponent";
import MyAppUtil from "../util/MyAppUtil";
import {WideScreenSNSComponent} from "../components/wide/WideScreenSNSComponent";
import {getTrackingPermissionsAsync, requestTrackingPermissionsAsync} from 'expo-tracking-transparency';
import PopupUtil from "../util/PopupUtil";
import {WhatIsSampleRequestModalComponent} from "../components/WhatIsSampleRequestModalComponent";
import {ReceivedNewMessageModalComponent} from "../components/ReceivedNewMessageModalComponent";

interface Props {
    navigation: any, // ナビゲーション用
}

interface State {
    // 共通
    resizeWindow: boolean,  // 値に意味はなし。windowサイズの拡大縮小をrenderに送るためのもの
    isLoaded: boolean,

    // view
    // activeTabSlide: number,    // 現在のTabSlide
    activeSpecialSlide: number,    // 特集SlideShowの現在のSlide
    isShowMenu: boolean,   // Menu の表示

    // コンテンツ
    clickedAiueoMakerButtonIndex: number,   // 押されたメーカーのあいうえおのボタンIndex 0 = あ、 99 = すべて

    // input
    filterRecipeCategory: number | undefined,   // 絞り込みレシピカテゴリ（ランキングタブ）
    filterCostFrom: number | undefined, // 絞り込み原価（ランキングタブ）
    filterCostTo: number | undefined, // 絞り込み原価（ランキングタブ）
}

export class HomeScreen extends BaseScreen<Props, State> {

    /** プッシュ通知フォアグラウンド用リスナー */
    _notificationListener: any = null;
    _notificationTappedListener: any = null;

    _resizeTimer = setInterval(() => {
        // window 拡大縮小を反映させる
        AppG.window = Dimensions.get('window');
        if (AppG.window.width != AppG.windowBefore.width || AppG.window.height != AppG.windowBefore.height) {
            this.setState({'resizeWindow': true});
            AppG.windowBefore = Dimensions.get('window');
        }
        LoginUtil.setRefreshedScreenFlg(false);
    }, 100);

    _loginData = new LoginDataEntityModel();
    _marginSize = appS.margin.size;

    _contentsLoadCount = 10; // コンテンツの一度の読み込み数

    // コンテンツ
    isLoadRecommendProducts: boolean = false;    // おすすめレシピロード中
    isLoadRecommendRecipes: boolean = false;    // おすすめ商品ロード中
    isLoadRankingRecipes: boolean = false;  // ランキングレシピ（ランキングタブ）
    isLoadRankingProducts: boolean = false;  // ランキング商品（ランキングタブ）
    specials: Special[] = [];    // 特集
    newRecipes: Recipe[] = [];   // 新着レシピ
    newRecipeRecipes: Recipe[] = [];   // 最近更新されたレシピ
    newProducts: Product[] = []; // 新着商品
    newProductProducts: Product[] = []; // 最近更新された商品
    popularKeywords: Keyword[] = []; // 人気のキーワード
    popularProductKeywords: Keyword[] = []; // 人気のキーワード
    rankingRecipesRecommendTab: Recipe[] = [];   // おすすめタブのレシピランキング
    rankingRecipes: Recipe[] = [];   // レシピランキング
    rankingProducts: Product[] = [];   // 商品ランキング
    recommendRecipes: Recipe[] = [];   // おすすめレシピ
    recommendProducts: Product[] = [];   // おすすめ商品
    rankingProductsRecommendTab: Product[] = [];   // おすすめタブの商品ランキング
    makers: Maker[][] = [];    // メーカータブのメーカー一覧

    // マスター
    recipeCategories: RecipeCategory[] = []; // レシピカテゴリのマスター

    /**
     * トピック毎のマージン
     */
    _topicMargin = appS.margins.betweenTopic;

    // サンプルリクエストとは？の表示
    _showWhatIsSampleRequest = () => {
        if (AppG.tabIndex == 2) {
            // 商品タブの時のPopup表示
            PopupUtil.showWhatIsSampleRequestModal(
                () => {
                    // サンプル依頼とはの表示
                    if (this._whatIsSampleRequestModalRef != null) {
                        this._whatIsSampleRequestModalRef.showModal();
                    }
                },
                () => {}
            );
        }
    }

    // タブ
    /** タブのページ */
    _tabPage: any = null;
    /** tab 本体 */
    _tabRef: any = null;
    _tabClicked: boolean = false;

    _tabPress = (tabNumber: number) => {

        if (AppG.tabIndex == tabNumber) {
            if (ScreenSizeUtil.isWebSize()) {
                setTimeout(() => {this.setState({resizeWindow: true});}, 10);
                setTimeout(() => {this.setState({resizeWindow: true});}, 10);
            }
            this._showWhatIsSampleRequest();
            return;
        }

        this._tabClicked = true;
        if (this._tabPage != null) {
            AppG.tabIndex = tabNumber;
            if (this._tabRef != null) {
                this._tabRef.redrawView(tabNumber);
            }
            this._tabPage.scrollTo({index: tabNumber, animated: false});
        }

        if (
            ValidateUtil.isEmptyArray(AppG.screenHistory)
            ||
            (!ValidateUtil.isEmptyArray(AppG.screenHistory) && AppG.screenHistory[0] != `【ホーム画面】${this._tabs[tabNumber].title}タブ`))
        {
            AppG.screenHistory.unshift(`【ホーム画面】${this._tabs[tabNumber].title}タブ`);
        }
        setTimeout(() => {this.setState({resizeWindow: true});}, 10);
        setTimeout(() => {this.setState({resizeWindow: true});}, 10);

        this._showWhatIsSampleRequest();
    };
    _tabs: Tab[] = [
        {
            index: 0,    // 0 〜
            title: 'TOP',
            onPress: () => {this._tabPress(0)},
            color: appColors.actionColor,
            unselectColor: appColors.black,
            backgroundColor: appColors.white,
            unselectBackgroundColor: appColors.white,
            badge: 0,
        },
        {
            index: 1,    // 0 〜
            title: 'レシピ',
            onPress: () => {this._tabPress(1)},
            color: appColors.actionColor,
            unselectColor: appColors.black,
            backgroundColor: appColors.white,
            unselectBackgroundColor: appColors.white,
            badge: 0,
        },
        {
            index: 2,    // 0 〜
            title: '商品',
            onPress: () => {this._tabPress(2)},
            color: appColors.actionColor,
            unselectColor: appColors.black,
            backgroundColor: appColors.white,
            unselectBackgroundColor: appColors.white,
            badge: 0,
        },
        {
            index: 3,    // 0 〜
            title: 'メーカー別',
            onPress: () => {this._tabPress(3)},
            color: appColors.actionColor,
            unselectColor: appColors.black,
            backgroundColor: appColors.white,
            unselectBackgroundColor: appColors.white,
            badge: 0,
        },
        {
            index: 4,    // 0 〜
            title: 'ランキング',
            onPress: () => {this._tabPress(4)},
            color: appColors.actionColor,
            unselectColor: appColors.black,
            backgroundColor: appColors.white,
            unselectBackgroundColor: appColors.white,
            badge: 0,
        },
    ];

    // ランキングタブ内タブ
    _rTabPage: any = null;
    _rTabClicked: boolean = false;
    _rTabIndex: number = 0;
    _rTabPress = (tabNumber: number) => {
        if (this._rTabIndex == tabNumber) {
            return;
        }
        this._tabClicked = true;
        if (tabNumber == 0) {
            this._rTabIndex = 0;
            AppG.screenHistory.unshift(`【ホーム画面】レシピランキングタブ`);
        } else {
            this._rTabIndex = 1;
            AppG.screenHistory.unshift(`【ホーム画面】商品ランキングタブ`);
        }
        setTimeout(() => {this.setState({resizeWindow: true});}, 10);
        setTimeout(() => {this.setState({resizeWindow: true});}, 10);
    };
    _rTabs: Tab[] = [
        {
            index: 0,    // 0 〜
            title: 'レシピ',
            onPress: () => {this._rTabPress(0)},
            color: appColors.recipeHeader,
            unselectColor: appColors.tabGray,
            backgroundColor: appColors.white,
            unselectBackgroundColor: appColors.white,
            badge: 0,
        },
        {
            index: 1,    // 0 〜
            title: '商品',
            onPress: () => {this._rTabPress(1)},
            color: appColors.productHeader,
            unselectColor: appColors.tabGray,
            backgroundColor: appColors.white,
            unselectBackgroundColor: appColors.white,
            badge: 0,
        },
    ];




    // Notificationから飛んできた後のタイマー
    _timer: any;
    _timer2: any;

    // 特集用のデータ
    _specialImageMargin = 10;

    // 新着レシピの表示数
    _newRecipeNum = 15;
    // 新着商品の表示数
    _newProductNum = 15;
    // おすすめレシピ表示数
    _recommendRecipeNum = 15;
    _recommendProductNum = 15;
    // ランキングタブで表示するレシピ数（初期値）
    _recipeRankingNumForTab = 10;
    _productRankingNumForTab = 10;

    // Maker タブの区分け
    // _titlesOfMaker = ['あ', 'か', 'さ', 'た', 'な', 'は', 'ま', 'や', 'ら', 'わ', 'A-Z'];
    // _regRexOfMaker = [
    //     'あアいイうウえエおオ',
    //     'かカがガきキぎギくクぐグけケげゲこコごゴ',
    //     'さサざザしシじジすスずズせセぜゼそソぞゾ',
    //     'たタだダちチぢヂつツづヅてテでデとトどド',
    //     'なナにニぬヌねネのノ',
    //     'はハばバぱパひヒびビぴピふフぶブぷプへヘべベぺペほホぼボぽポ',
    //     'まマみミむムめメもモ',
    //     'やヤゆユよヨ',
    //     'らラりリるルれレろロ',
    //     'わワをヲ',
    //     '\[a-zA-Z\]'];
    _titlesOfMaker = ['あ', 'か', 'さ', 'た', 'な', 'は', 'ま', 'や', 'ら', 'わ'];
    _regRexOfMaker = [
        'あアいイうウえエおオ',
        'かカがガきキぎギくクぐグけケげゲこコごゴ',
        'さサざザしシじジすスずズせセぜゼそソぞゾ',
        'たタだダちチぢヂつツづヅてテでデとトどド',
        'なナにニぬヌねネのノ',
        'はハばバぱパひヒびビぴピふフぶブぷプへヘべベぺペほホぼボぽポ',
        'まマみミむムめメもモ',
        'やヤゆユよヨ',
        'らラりリるルれレろロ',
        'わワをヲ'];

    // android でスクロールが不安定なため
    _pageScrollable = Platform.OS != 'android';
    // _pageScrollable = true; // テスト用

    _scrollBottomSpace = appS.footer.scrollBottom;

    // 注目のキーワード
    _recommendKeywordRecipes: {
        keyword?: string;
        recipes?: Array<Recipe>;
    }[] = [];   // 注目のキーワードレシピ index: [場所番号(0-3)], keyword, Recipe
    _recommendKeywordProducts: {
        keyword?: string;
        products?: Array<Product>;
    }[] = [];   // 注目のキーワード商品 index: [場所番号(0-1)], keyword, Product

    // サンプル依頼とは
    _whatIsSampleRequestModalRef: any = null;
    // 新着メッセージがあります
    _receivedNewMessageModalRef: any = null;

    // スクロール用
    _topScrollTab: any = null;
    _recipeScrollTab: any = null;
    _productScrollTab: any = null;
    _makerScrollTab: any = null;
    _rankingScrollTab: any = null;

    // 現在ポジション
    // _topScrollTabY: number = 0;
    _recipeScrollTabY: number = 0;
    _productScrollTabY: number = 0;
    _makerScrollTabY: number = 0;
    _rankingScrollTabY: number = 0;

    constructor(props: any) {
        super(props);
        this.state = {
            resizeWindow: true,  // 値に意味はなし。windowサイズの拡大縮小をrenderに送るためのもの
            isLoaded: false,
            activeSpecialSlide: 0,
            isShowMenu: false,
            // コンテンツ
            // isLoadRecommendRecipes: false,
            // isLoadRecommendProducts: false,
            // isLoadRankingRecipes: false,
            // isLoadRankingProducts: false,
            // specials: [],
            // newRecipes: [],
            // newRecipeRecipes: [],
            // newProducts: [],
            // newProductProducts: [],
            // popularKeywords: [],
            // popularProductKeywords: [],
            // rankingRecipesRecommendTab: [],
            // rankingProductsRecommendTab: [],
            // rankingRecipes: [],
            // rankingProducts: [],
            // recommendRecipes: [],
            // recommendProducts: [],
            // recipeCategories: [],
            // makers: [],
            filterRecipeCategory: undefined,
            filterCostFrom: undefined,
            filterCostTo: undefined,
            clickedAiueoMakerButtonIndex: 99,   // 押されたメーカーのあいうえおのボタンIndex 0 = あ、 99 = すべて
        };
    }

    /**
     * 画面開いたときのアナリティクス送信
     * @param excludeHistory 履歴の除外
     */
    _sendPvAnalytics = (excludeHistory: boolean | null = null) => {
        AnalyticsUtil.sendAnalytics(
            DOpenEventName.openPage,
            AnalyticsEventName.openPage,  // アナリティクスのイベント名
            'HomeScreen',
            null,
            null,
            null,
            null,
            null,
            null,
            excludeHistory
        );
    }

    /**
     * アナリティクスのトラッキングOKかアラートを出す
     */
    _getTrackingPermission = async () => {

        getTrackingPermissionsAsync().then(permission => {
            if (permission.status === 'granted') {
                // enable tracking features
                AppG.enableTrackingFlg = true;
            } else if (permission.status === 'undetermined') {
                requestTrackingPermissionsAsync().then(s => {
                    if (s.status === 'granted') {
                        // enable tracking features
                        AppG.enableTrackingFlg = true;
                    }
                });
            }
        });

    }

    componentDidMount() {
        // alert('mount')
        RedirectUtil.redirectEncodeUrl();
        if (Platform.OS == 'ios') {
            this._getTrackingPermission();
        }

        LoginUtil.setAnalyticsHash();

        if (Platform.OS != 'web') {
            // スクロールviewのセット
            this.props.navigation.setParams({
                scrollToTop: () => {
                    switch (AppG.tabIndex) {
                        case 0:
                            if (this._topScrollTab != null) {
                                this._topScrollTab.scrollTo({x: 0, y: 0, animated: true});
                            }
                            break;
                        case 1:
                            if (this._recipeScrollTabY == 0) {
                                this._tabPress(0);
                            } else if (this._recipeScrollTab != null) {
                                this._recipeScrollTab.scrollTo({ x: 0, y: 0, animated: true });
                            }
                            break;
                        case 2:
                            if (this._productScrollTabY == 0) {
                                this._tabPress(0);
                            } else if (this._productScrollTab != null) {
                                this._productScrollTab.scrollTo({x: 0, y: 0, animated: true});
                            }
                            break;
                        case 3:
                            if (this._makerScrollTabY == 0) {
                                this._tabPress(0);
                            } else if (this._makerScrollTab != null) {
                                this._makerScrollTab.scrollTo({x: 0, y: 0, animated: true});
                            }
                            break;
                        case 4:
                            if (this._rankingScrollTabY == 0) {
                                this._tabPress(0);
                            } else if (this._rankingScrollTab != null) {
                                this._rankingScrollTab.scrollTo({x: 0, y: 0, animated: true});
                            }
                            break;
                        default:
                            break;
                    }
                }
            })

            // Facebook SDK の読み込み
            FacebookUtil.facebookInit()
            FacebookUtil.facebookAds()
        }

        // リフレッシュ用 変数のセット
        NavigationUtil.setHomeRefresh(this.componentDidMount, this);
        // push 通知のフォアグラウンド
        if (Platform.OS !== 'web') {
            if (this._notificationListener != null) {
                this._notificationListener.remove();
            }
            if (this._notificationTappedListener != null) {
                this._notificationTappedListener.remove();
            }

            // // Push 通知受け取ったとき
            // this._notificationListener = Notifications.addNotificationReceivedListener(() => {alert('ReceivedListener')});
            // // 通知をタップしたとき (Push, Local 共に)
            // this._notificationTappedListener = Notifications.addNotificationResponseReceivedListener(() => {alert('ResponseReceivedListener')})
            // Push 通知受け取ったとき
            this._notificationListener = Notifications.addNotificationReceivedListener(this._handleNotification);
            // 通知をタップしたとき (Push, Local 共に)
            this._notificationTappedListener = Notifications.addNotificationResponseReceivedListener(this._goToPushScreen)
        }

        // 通知タップ時のタブ遷移
        this._timer = setInterval(
            () => {
                log.debug(this.state.isLoaded)
                if (this.state.isLoaded && this._tabPage != null) {
                    // タブを選択
                    setTimeout(() => {this.setState({resizeWindow: true});}, 10);
                    this._tabPage.scrollTo({index:AppG.tabIndex, animated: true});
                    clearTimeout(this._timer);
                    NotificationUtil._homeTabFix = false;
                }
            }
            ,300);

        this._loadMasters().then(() => {});
        this._loadContents().then(() => {});

        // @ts-ignore
        const {REACT_APP_DOMAIN_URL} = getEnvVars();
        WebTagUtil.setOgType('website');
        WebTagUtil.setTags(`Reci BASE-プロ向けレシピ検索サイト-ReciBASE(レシベース)`, `Reci BASE（レシベース）は食に関わる全ての人の為のレシピ情報サイトです。メーカーが想いを込めてつくった商品と料理人の本当に知りたいコトであったり知るべきコト,ReciBASE（レシベース）はそんなメーカーと料理人の『本当の想い』にリアルに応える商品カタログ付きレシピアプリです。`, `${REACT_APP_DOMAIN_URL}/logo_icon_og.png`);

        // ランキングのURLで遷移したらランキングを開く
        if (Platform.OS == 'web' && location.href == `${REACT_APP_DOMAIN_URL}/#/HomeScreen_Ranking`) {
            // if (Platform.OS == 'web' && location.href == `http://localhost:19006/#/HomeScreen_Ranking`) {
            AppG.tabIndex = 4;
            this._tabPress(4);  // ランキング
        }


        // init(containerがインスタンス作成後に走るので、containerのコンストラクタが先に走る)
        LoginUtil.interruptOpenScreen(this.props.navigation,
            () => {
                LoginDataDao.get().then(loginData => {
                    // Web 戻るでも呼ばれる
                    // Homeだけは特別にここでも web, app 両方でAnalyticswお呼ぶ
                    // Analytics
                    this._sendPvAnalytics();
                    AppG.getFooterBadge(() => {
                        this.setState({resizeWindow: true});
                    });


                    // URL Scheme で外部リンクから起動したときの処理と、画面遷移でこの画面を開いたときの処理
                    LoginUtil.setDefaultListener(
                        this.props.navigation,
                        () => {
                            // Webはここにこない
                            if (Platform.OS != 'web') {
                                // Analytics
                                this._sendPvAnalytics();
                                // タブ
                                if (AppG.tabIndex <= 3) {
                                    this._timer2 = setInterval(
                                        () => {
                                            if (this.state.isLoaded) {
                                                // タブを選択
                                                this._tabPress(AppG.tabIndex);
                                                clearTimeout(this._timer2);
                                                NotificationUtil._homeTabFix = false;
                                            }
                                        }
                                        ,300);
                                }

                                // 通知許可アラート
                                NotificationUtil.registerForPushNotificationsAsync().then(token => {
                                    //コンソールに出力
                                    log.debug('------------------------ Push Token -----------------------');
                                    log.debug(token);

                                    if (token != null) {
                                        // 通知用トークンの送信
                                        // e.g. token = ExponentPushToken[-fmdm5M1kKgf0WpCtz5Nc_]
                                        UserApiFactory(AppG.getConfiguration(), AppG.getBasePath())
                                            .addUserDevice(Platform.OS, token, token)
                                            .then((res) => {
                                                // log.debug(res)
                                            })
                                            .catch((e) => {log.debug(e)});
                                    }
                                });
                            }

                            AppG.getFooterBadge();

                            ExpoUtil.doReloadAppTab(() => {
                                //     this.setState({recommendRecipes: []});
                                //     this.setState({rankingRecipes: []});
                                //     this.setState({rankingRecipesRecommendTab: []});
                                //     this.setState({'isLoaded': false});
                                //     this._loadContents().then(() => {
                                //         this.setState({'isLoaded': true});
                                //     });
                                AppG.getFooterBadge();
                                //
                                //     // 実行
                                //     Promise.all([
                                //         this._loadUser()
                                //     ]);
                            });
                        });
                    if (loginData != null && !ValidateUtil.isEmptyExact(loginData?.user_id)) {
                        this._loginData = loginData;
                    }
                    this._loadUser();
                    // if (Platform.OS != 'android') {
                    //     this.setState({'isLoaded': true});
                    // }

                });
            });


    }

    componentWillUnmount () {
        clearTimeout(this._resizeTimer);
        LoginUtil.removeDefaultListener(this.props.navigation);
        if (Platform.OS != 'web') {
            if (this._notificationListener != null) {
                this._notificationListener.remove();
            }
            if (this._notificationTappedListener != null) {
                this._notificationTappedListener.remove();
            }
        }
    }

    componentDidUpdate () {
        // レンダー完了時に呼ばれる
    }

    /**
     * 通知をタップしたとき、指定された画面へ遷移
     */
    _goToPushScreen = (notification: any) => {
        if (notification == null
            || notification.notification == null
            || notification.notification.request == null
            || notification.notification.request.content == null) {
            return;
        }

        if (notification.notification.request.content.data != null && notification.notification.request.content.data.screen != null) {
            let _params = null;
            if (notification.notification.request.content.data.params != null && notification.notification.request.content.data.params != '') {
                _params = notification.notification.request.content.data.params
            }
            NotificationUtil.goToScreenFromPushNotification(notification.notification.request.content.data.screen, _params, this.props.navigation);
        }
    }

    /**
     * プッシュ通知のフォアグラウンド用
     * https://qiita.com/sei_sato/items/cb0bdb35a3eedc142219
     * @param notification
     * @private
     */
    _handleNotification = async (notification: any) => {
        if (notification == null || notification.request == null || notification.request.trigger == null || notification.request.content == null) {
            return;
        }

        /** 通知の状態による場合分け */
        if (notification.request.trigger.type == "push") {
            if (Platform.OS == 'ios') {
                // iOSなら
                // アプリ起動中に通知を受信したらローカル通知に変換
                if (notification.request.content.data != null && !ValidateUtil.isEmptyExact(notification.request.content.data.title) != null && !ValidateUtil.isEmptyExact(notification.request.content.data.body)) {
                    // 通知の描画
                    // content は push 通知のをそのまま使う
                    // Vibration.vibrate(0);
                    Notifications.setNotificationHandler({
                        handleNotification: async () => ({
                            shouldShowAlert: true,
                            shouldPlaySound: false,
                            shouldSetBadge: true,
                        }),
                    });
                }
            }
        }
    };

    /**
     * パラムの取得
     * @private
     */
    _getParams = () => {
    }

    /**
     * マスターのロード
     * @private
     */
    _loadMasters = async () => {
        // レシピカテゴリ
        const getAllRecipeCategories = MasterApiFactory(AppG.getConfiguration(), AppG.getBasePath())
            .getAllRecipeCategoriesMaster().then((recipeCategoriesRes: AxiosResponse<RecipeCategory[]>) => {
                if (recipeCategoriesRes != null && recipeCategoriesRes.data != null) {
                    let _other : RecipeCategory | null = null;
                    recipeCategoriesRes.data.forEach((recipeCategory: RecipeCategory) => {
                        if (recipeCategory.name == 'その他') {
                            _other = recipeCategory;
                        } else {
                            this.recipeCategories.push(recipeCategory);
                        }
                    })
                    if (_other != null) {
                        this.recipeCategories.push(_other);
                    }
                    // this.recipeCategories = this.recipeCategories;
                }
            });

        // 実行
        await Promise.all([getAllRecipeCategories]);
    }

    /**
     * コンテンツのロード
     * @private
     */
    _loadContents = async () => {
        // 特集
        const getSpecialFindAll = () => SpecialApiFactory(AppG.getConfiguration(), AppG.getBasePath())
            .getSpecialFindAll().then((specialsRes: AxiosResponse<Special[]>) => {
                if (specialsRes != null && !ValidateUtil.isEmptyArray(specialsRes.data)) {
                    this.specials = specialsRes.data;

                    specialsRes.data.forEach(special => {
                        // WEBで特集のリンクだったら特集へ（通知用から遷移したときの処理）
                        // @ts-ignore
                        const {REACT_APP_DOMAIN_URL} = getEnvVars();
                        if (Platform.OS == 'web' && location.href == `${REACT_APP_DOMAIN_URL}/#/SpecialDetailScreen?specialId=${special.id}`) {
                            SpecialUtil.openSpecial(special, this.props.navigation);
                        }
                    });
                }
            });

        // 人気のキーワード
        const getPopularKeywords = () => KeywordApiFactory(AppG.getConfiguration(), AppG.getBasePath())
            .getPopularKeyword(
                20,
                'recipe'
            ).then((popularKeywordsRes: AxiosResponse<Keyword[]>) => {
                if (popularKeywordsRes != null && popularKeywordsRes.data != null) {
                    this.popularKeywords = popularKeywordsRes.data;
                }
            });

        // 人気の商品キーワード
        const getPopularProductKeywords = () => KeywordApiFactory(AppG.getConfiguration(), AppG.getBasePath())
            .getPopularKeyword(
                20,
                'product'
            ).then((popularProductKeywordsRes: AxiosResponse<Keyword[]>) => {
                if (popularProductKeywordsRes != null && popularProductKeywordsRes.data != null) {
                    this.popularProductKeywords = popularProductKeywordsRes.data;
                }
            });

        // メーカー一覧
        const getMakers = () => MakerApiFactory(AppG.getConfiguration(), AppG.getBasePath())
            .findMakers(
                0,
                1000,
                undefined,
                1,
            ).then((resultMakersRes: AxiosResponse<Maker[]>) => {
                if (resultMakersRes !== null) {
                    this._regRexOfMaker.forEach((title: string) => {
                        // ソート
                        let _makers: Maker[] | undefined = resultMakersRes.data.filter((m: Maker) => ValidateUtil.isStartBy(m.nameKana, title));
                        this.makers.push(_makers);
                    });
                    // this.makers = this.makers;
                }
            });

        // 注目のキーワードレシピ
        const getRecipeRecommendKeyword1 = () => RecipeApiFactory(AppG.getConfiguration(), AppG.getBasePath())
            .getRecommendKeywordRecipe(
                10,
                4,
                1,
            ).then((recommendKeywordRecipesRes) => {
                if (recommendKeywordRecipesRes != null && recommendKeywordRecipesRes.data.keyword != null && !ValidateUtil.isEmptyArray(recommendKeywordRecipesRes.data.recipes)) {
                    this._recommendKeywordRecipes[0] = recommendKeywordRecipesRes.data;
                }
            });
        const getRecipeRecommendKeyword2 = () => RecipeApiFactory(AppG.getConfiguration(), AppG.getBasePath())
            .getRecommendKeywordRecipe(
                10,
                4,
                2,
            ).then((recommendKeywordRecipesRes) => {
                if (recommendKeywordRecipesRes != null && recommendKeywordRecipesRes.data.keyword != null && !ValidateUtil.isEmptyArray(recommendKeywordRecipesRes.data.recipes)) {
                    this._recommendKeywordRecipes[1] = recommendKeywordRecipesRes.data;
                }
            });
        const getRecipeRecommendKeyword3 = () => RecipeApiFactory(AppG.getConfiguration(), AppG.getBasePath())
            .getRecommendKeywordRecipe(
                10,
                4,
                3,
            ).then((recommendKeywordRecipesRes) => {
                if (recommendKeywordRecipesRes != null && recommendKeywordRecipesRes.data.keyword != null && !ValidateUtil.isEmptyArray(recommendKeywordRecipesRes.data.recipes)) {
                    this._recommendKeywordRecipes[2] = recommendKeywordRecipesRes.data;
                }
            });
        const getRecipeRecommendKeyword4 = () => RecipeApiFactory(AppG.getConfiguration(), AppG.getBasePath())
            .getRecommendKeywordRecipe(
                10,
                4,
                4,
            ).then((recommendKeywordRecipesRes) => {
                if (recommendKeywordRecipesRes != null && recommendKeywordRecipesRes.data.keyword != null && !ValidateUtil.isEmptyArray(recommendKeywordRecipesRes.data.recipes)) {
                    this._recommendKeywordRecipes[3] = recommendKeywordRecipesRes.data;
                }
            });

        // 注目のキーワード商品
        const getProductRecommendKeyword1 = () => ProductApiFactory(AppG.getConfiguration(), AppG.getBasePath())
            .getRecommendKeywordProduct(
                10,
                4,
                1,
            ).then((recommendKeywordProductsRes) => {
                if (recommendKeywordProductsRes != null && recommendKeywordProductsRes.data.keyword != null && !ValidateUtil.isEmptyArray(recommendKeywordProductsRes.data.products)) {
                    this._recommendKeywordProducts[0] = recommendKeywordProductsRes.data;
                }
            });
        const getProductRecommendKeyword2 = () => ProductApiFactory(AppG.getConfiguration(), AppG.getBasePath())
            .getRecommendKeywordProduct(
                10,
                4,
                2,
            ).then((recommendKeywordProductsRes) => {
                if (recommendKeywordProductsRes != null && recommendKeywordProductsRes.data.keyword != null && !ValidateUtil.isEmptyArray(recommendKeywordProductsRes.data.products)) {
                    this._recommendKeywordProducts[1] = recommendKeywordProductsRes.data;
                }
            });

        // 実行
        await Promise.all([
            getSpecialFindAll(),
            this._getNewRecipe(this._newRecipeNum),
            getPopularKeywords(),
            getPopularProductKeywords(),
            getMakers(),
            this._getNewProduct(this._newProductNum),
            this._loadRecommendRecipes(0, this._recommendRecipeNum),
            this._loadRecommendProducts(0, this._recommendProductNum),
            this._loadNewRecipeRecipes(),
            getRecipeRecommendKeyword1(),
            getRecipeRecommendKeyword2(),
            getRecipeRecommendKeyword3(),
            getRecipeRecommendKeyword4(),
            this._loadNewProductProducts(),
            getProductRecommendKeyword1(),
            getProductRecommendKeyword2(),
            this._loadRankingRecipes(0, this._recipeRankingNumForTab, true),
            this._loadRankingProduct(0, this._recipeRankingNumForTab, true),
        ]).finally(() => {
            this.setState({'isLoaded': true});
        });
    }

    /**
     * ユーザーのロード
     */
    _loadUser = async () => {
        if (AppG.user == null) {
            return;
        }

        if (ValidateUtil.isEmptyExact(AppG.user.name)) {
            // 名前がないなら初回登録画面へ
            this.props.navigation.navigate('ProfileInit1Screen');
        }
    }

    /**
     * 新着レシピのロード
     * @param num
     */
    _getNewRecipe = async (num: number) => {
        // 新着レシピ
        const getNewRecipe = () => RecipeApiFactory(AppG.getConfiguration(), AppG.getBasePath())
            .getNewRecipe(
                num,
                4,
            ).then((newRecipesRes: AxiosResponse<Recipe[]>) => {
                if (newRecipesRes != null && newRecipesRes.data != null) {
                    this.newRecipes = newRecipesRes.data;
                }
            });

        // 実行
        await Promise.all([getNewRecipe()]);
    }

    /**
     * 新着商品のロード
     * @param num
     */
    _getNewProduct = async (num: number) => {
        // 新着レシピ
        const getNewProduct = () => ProductApiFactory(AppG.getConfiguration(), AppG.getBasePath())
            .getNewProduct(
                num,
                4,
            ).then((newProductsRes: AxiosResponse<Product[]>) => {
                if (newProductsRes != null && newProductsRes.data != null) {
                    this.newProducts = newProductsRes.data;
                }
            });

        // 実行
        await Promise.all([getNewProduct()]);
    }

    /**
     * 商品ランキングの取得
     * @param from
     * @param to
     * @param reset 商品を全部消してから取得する
     */
    _loadRankingProduct = (from: number, to: number, reset: boolean = false) => {
        if (this.isLoadRankingProducts) {
            return;
        }
        // 商品ランキング
        setTimeout(() => {
            this.isLoadRankingProducts = true;
            this.setState({resizeWindow: true});
            let _rankingProducts: Product[] = [];
            if (!reset) {
                _rankingProducts = this.rankingProducts;
            } else {
                this.rankingProducts = [];
            }

            const getRankingProduct = () => ProductApiFactory(AppG.getConfiguration(), AppG.getBasePath())
                .getProductRanking(
                    'week',
                    from,
                    to,
                    4,
                )
                .then((rankingProductsRes: AxiosResponse<Product[]>) => {
                    if (rankingProductsRes != null && !ValidateUtil.isEmptyArray(rankingProductsRes.data)) {
                        rankingProductsRes.data.forEach((rankingProduct: Product) => {
                            _rankingProducts.push(rankingProduct);
                        });
                        this.rankingProducts = _rankingProducts;
                        if (ValidateUtil.isEmptyArray(this.rankingProductsRecommendTab)) {
                            // セットされてなかったらおすすめタブのもセット
                            this.rankingProductsRecommendTab = _rankingProducts;
                        }
                    }
                    this.isLoadRankingProducts = false;
                }).catch(() => {
                    this.isLoadRankingProducts = false;
                }).finally(() => {
                    this.setState({isLoaded: true});
                });

            // 実行
            Promise.all([getRankingProduct()]);
        }, 100);
    }

    /**
     * レシピランキングの取得
     * @param from
     * @param to
     * @param reset レシピを全部消してから取得する
     */
    _loadRankingRecipes = (from: number, to: number, reset: boolean = false) => {
        if (this.isLoadRankingRecipes) {
            return;
        }
        // レシピランキング
        setTimeout(() => {
            this.isLoadRankingRecipes = true;
            this.setState({resizeWindow: true});
            let _rankingRecipes: Recipe[] = [];
            if (!reset) {
                _rankingRecipes = this.rankingRecipes;
            } else {
                this.rankingRecipes = [];
            }
            const getRankingRecipe = () => RecipeApiFactory(AppG.getConfiguration(), AppG.getBasePath())
                .getRecipeRanking(
                    'week',
                    from,
                    to,
                    ValidateUtil.isEmptyExact(this.state.filterRecipeCategory)? undefined : this.state.filterRecipeCategory,
                    ValidateUtil.isEmptyExact(this.state.filterCostFrom)? undefined : this.state.filterCostFrom,
                    ValidateUtil.isEmptyExact(this.state.filterCostTo)? undefined : this.state.filterCostTo,
                    4,
                )
                .then((rankingRecipesRes: AxiosResponse<Recipe[]>) => {

                    if (rankingRecipesRes != null && !ValidateUtil.isEmptyArray(rankingRecipesRes.data)) {
                        rankingRecipesRes.data.forEach((rankingRecipe: Recipe) => {
                            _rankingRecipes.push(rankingRecipe);
                        });
                        this.rankingRecipes = _rankingRecipes;
                        if (ValidateUtil.isEmptyArray(this.rankingRecipesRecommendTab)) {
                            // セットされてなかったらおすすめタブのもセット
                            this.rankingRecipesRecommendTab = _rankingRecipes;
                        }
                    }
                    this.isLoadRankingRecipes = false;
                }).catch(() => {
                    this.isLoadRankingRecipes = false;
                }).finally(() => {
                    // if (Platform.OS == 'android') {
                    this.setState({isLoaded: true});
                    // }
                });

            // 実行
            Promise.all([getRankingRecipe()]);
        }, 100);
    }

    /**
     * おすすめレシピの読み込み
     * @param from
     * @param to
     */
    _loadRecommendRecipes = async (from: number, to: number) => {
        // おすすめレシピ
        this.isLoadRecommendRecipes = true;
        const getRecommendRecipe = () => RecipeApiFactory(AppG.getConfiguration(), AppG.getBasePath())
            .findRecipes(
                from,
                to,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                4,
                1,
            ).then((recommendRecipesRes: AxiosResponse<Recipe[]>) => {
                if (recommendRecipesRes !== null && !ValidateUtil.isEmptyArray(recommendRecipesRes.data)) {
                    let _recommendRecipes: Recipe[] = this.recommendRecipes;
                    recommendRecipesRes.data.forEach((recommendRecipe: Recipe) => {
                        _recommendRecipes.push(recommendRecipe);
                    });
                    this.recommendRecipes = _recommendRecipes;
                }
                this.isLoadRecommendRecipes = false;
                if (from != 0) {
                    this.setState({resizeWindow: true});
                }
            });

        // 実行
        await Promise.all([getRecommendRecipe()]);
    }

    /**
     * 最近更新されたレシピの読み込み
     */
    _loadNewRecipeRecipes = async () => {
        const getNewRecipeRecipes = RecipeApiFactory(AppG.getConfiguration(), AppG.getBasePath())
            .findRecipes(
                0,
                this._newRecipeNum,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                4,
                undefined,
                'update',
            ).then((newRecipeRecipes: AxiosResponse<Recipe[]>) => {
                if (newRecipeRecipes !== null && !ValidateUtil.isEmptyArray(newRecipeRecipes.data)) {
                    let _newRecipeRecipes: Recipe[] = [];
                    newRecipeRecipes.data.forEach((newRecipeRecipe: Recipe) => {
                        _newRecipeRecipes.push(newRecipeRecipe);
                    });
                    this.newRecipeRecipes = _newRecipeRecipes;
                }
            });

        // 実行
        await Promise.all([getNewRecipeRecipes]);
    }

    /**
     * おすすめ商品の読み込み
     * @param from
     * @param to
     */
    _loadRecommendProducts = async (from: number, to: number) => {
        // おすすめ商品
        this.isLoadRecommendProducts = true;
        const getRecommendProduct = () => ProductApiFactory(AppG.getConfiguration(), AppG.getBasePath())
            .findProducts(
                from,
                to,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                4,
                1,
            ).then((recommendProductsRes: AxiosResponse<Product[]>) => {
                if (recommendProductsRes !== null && !ValidateUtil.isEmptyArray(recommendProductsRes.data)) {
                    let _recommendProducts: Product[] = this.recommendProducts;
                    recommendProductsRes.data.forEach((recommendProduct: Product) => {
                        _recommendProducts.push(recommendProduct);
                    });
                    this.recommendProducts = _recommendProducts;
                }
                this.isLoadRecommendProducts = false;
                if (from != 0) {
                    this.setState({resizeWindow: true});
                }
            });

        // 実行
        await Promise.all([getRecommendProduct()]);
    }

    /**
     * 最近更新された商品の読み込み
     */
    _loadNewProductProducts = async () => {
        // おすすめ商品
        const getRecommendProduct = ProductApiFactory(AppG.getConfiguration(), AppG.getBasePath())
            .findProducts(
                0,
                this._newProductNum,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                4,
                undefined,
                'update'
            ).then((newProductProducts: AxiosResponse<Product[]>) => {
                if (newProductProducts !== null && !ValidateUtil.isEmptyArray(newProductProducts.data)) {
                    let _newProductProducts: Product[] = [];
                    newProductProducts.data.forEach((newProductProduct: Product) => {
                        _newProductProducts.push(newProductProduct);
                    });
                    this.newProductProducts = _newProductProducts;
                }
            });

        // 実行
        await Promise.all([getRecommendProduct]);
    }


    /**
     * 特集ページの画像幅を取得
     */
    _getSpecialWith = () => {
        let _imageWidth = AppG.window.width - this._specialImageMargin;
        if (ScreenSizeUtil.isWebSize()) {
            _imageWidth = appS.webMainContentsSize.width;
        }
        return _imageWidth;
    }

    /**
     * 特集スライドショーのItem作成
     * @param special
     * @private
     */
        // @ts-ignore
    _renderSpecial = ({item}) => {
        let special: Special = item;
        let source = require('../../resources/images/no_image.png');
        if (special.image != null && special.image != '' && isURL(special.image)) {
            source = {uri: special.image};
        }
        let alt = '';
        if (special.name != null && special.name != '') {
            alt = special.name;
        }

        const _marginLeft = -(this._specialImageMargin / 2);

        const _graySize = 75;

        return (
            <TouchableOpacity
                style={{
                    ...Platform.select({
                        ios: {
                            shadowColor: '#000',
                            shadowOffset: { width: 1, height: 2 },
                            shadowOpacity: 0.8,
                            shadowRadius: 2,
                        },
                        android: {
                            elevation: 5,
                        },
                    }),
                }}
                // onPressIn={() => {
                //     if (Platform.OS == 'android') {
                //         if (this._pageScrollable) {
                //             this._pageScrollable = false;
                //         }
                //     }
                // }}
                // onPressOut={() => {
                //     if (Platform.OS == 'android') {
                //         if (!this._pageScrollable) {
                //             setTimeout(() => {this._pageScrollable = true;}, 300);
                //         }
                //     }
                // }}
                onPress={() => {
                    SpecialUtil.openSpecial(special, this.props.navigation);
                }}
            >
                <Image source={source}
                       style={[{
                           backgroundColor: 'white',
                           width: this._getSpecialWith() - this._specialImageMargin * 2,
                           height: (this._getSpecialWith() - this._specialImageMargin * 2)/1.8,
                           marginLeft: _marginLeft,
                           ...Platform.select({
                               web: {
                                   boxShadow: `6px 4px 2px ${appColors.black}`,
                               },
                           }),
                       }]}
                       resizeMode={'cover'}
                       accessibilityLabel={alt}
                />
                <Image source={require('../../resources/images/special_gray.png')}
                       style={[{
                           zIndex: 1,
                           position: 'relative',
                           bottom: _graySize,
                           backgroundColor: appColors.transparent,
                           width: this._getSpecialWith() - this._specialImageMargin * 2,
                           height: _graySize,
                           marginLeft: _marginLeft,
                       }]}
                       resizeMode={'cover'}
                />
                {/*特集名*/}
                <View
                    style={{
                        zIndex: 2,
                        position: 'relative',
                        bottom: ScreenSizeUtil.isWebSize()? 70 + _graySize: (Platform.OS =='ios'? 40 + _graySize : 55 + _graySize),
                        left: 4,
                    }}
                >
                    {/*有料会員向けアイコン*/}
                    <View style={{flexDirection: 'row'}}>
                        {special.type == SpecialTypeEnum.Premium && (
                            <View style={{flexDirection: 'row'}}>
                                <Image source={require('../../resources/images/premium_icon.png')}
                                       style={[{
                                           marginTop: Platform.OS == 'web'? -3: -4,
                                           backgroundColor: appColors.transparent,
                                           width: 16,
                                           height: 20,
                                       }]}
                                       resizeMode={'cover'}/>
                                <Text style={{color: appColors.white, fontWeight: 'bold', fontSize: 10, marginLeft: 3, marginRight: 3}}>{'有料会員限定'}</Text>
                            </View>
                        )}

                        <View style={{
                            marginTop: Platform.OS == 'ios'? -4: 0,
                            paddingLeft: 10,
                            paddingRight: 10,
                            borderRadius: 50,
                            backgroundColor: appColors.transparent,
                            borderColor: appColors.white,
                            borderWidth: ValidateUtil.isEmptyExact(special.genre)?0:1,
                            alignItems: 'center',
                            justifyContent: 'center',
                            margin: 3
                        }}>
                            <Text style={{color: appColors.white, fontWeight: 'bold', fontSize: 10}}>{ValidateUtil.isEmptyExact(special.genre)?' ':special.genre}</Text>
                        </View>


                    </View>
                    <SpacerComponent height={5}/>
                    <Text
                        numberOfLines={1}
                        ellipsizeMode="tail"
                        style={{
                            width: ScreenSizeUtil.isWebSize()? this._getSpecialWith() - this._specialImageMargin * 10: this._getSpecialWith() - this._specialImageMargin * 3,
                            color: appColors.white,
                            fontWeight: 'bold',
                            fontSize: ScreenSizeUtil.isWebSize()?22:18,
                        }}
                    >{special.name}</Text>
                </View>
            </TouchableOpacity>

        );
    }

    /**
     * 注目のキーワードレシピ
     */
    _renderPickUpKeywordsRecipe = (recommendKeywordRecipes: Recipe[], keyword: string, analyticsBtnName: string) => {
        return (
            <View key={`recipe_with_keyword_view_${keyword}`}>
                <RecipeListWithKeywordHorizontalScrollComponent
                    key={`recipe_with_keyword_${keyword}`}
                    navigation={this.props.navigation}
                    recipes={recommendKeywordRecipes}
                    keyword={keyword}
                    analyticsBtnName={analyticsBtnName}
                    more={true}
                    moreOnPress={() => {
                        const { routeName } = this.props.navigation.state;
                        let searchScreenModel: SearchScreenModel = new SearchScreenModel();
                        searchScreenModel.headerTitle = keyword;
                        searchScreenModel.screenNameFrom = routeName;
                        searchScreenModel.searchType = 'recipe';
                        searchScreenModel.keyword = keyword;
                        searchScreenModel.searchValue = keyword;
                        searchScreenModel.tabHidden = true;
                        this.props.navigation.navigate('SearchResultScreen', searchScreenModel);
                    }}
                />
            </View>
        );
    }

    /**
     * 注目のキーワード商品
     */
    _renderPickUpKeywordsProduct = (recommendKeywordProducts: Product[], keyword: string, analyticsBtnName: string) => {
        return (
            <View key={`product_with_keyword_view_${keyword}`}>
                <ProductListWithKeywordHorizontalScrollComponent
                    key={`product_with_keyword_${keyword}`}
                    navigation={this.props.navigation}
                    products={recommendKeywordProducts}
                    keyword={keyword}
                    analyticsBtnName={analyticsBtnName}
                    more={true}
                    moreOnPress={() => {
                        const { routeName } = this.props.navigation.state;
                        let searchScreenModel: SearchScreenModel = new SearchScreenModel();
                        searchScreenModel.headerTitle = keyword;
                        searchScreenModel.screenNameFrom = routeName;
                        searchScreenModel.searchType = 'product';
                        searchScreenModel.keyword = keyword;
                        searchScreenModel.searchValue = keyword;
                        searchScreenModel.tabHidden = true;
                        this.props.navigation.navigate('SearchResultScreen', searchScreenModel);
                    }}
                />
            </View>
        );
    }

    /**
     * 新着レシピ
     */
    _renderNewRecipe = (analyticsBtnName: string) => {
        return (
            <View>
                <RecipeNewListHorizontalScrollComponent
                    navigation={this.props.navigation}
                    recipes={this.newRecipes}
                    analyticsBtnName={analyticsBtnName}
                />
            </View>
        );
    }

    /**
     * 最近更新されたレシピ
     */
    _renderNewRecipeRecipe = (analyticsBtnName: string) => {
        return (
            <View>
                <RecipeNewListHorizontalScrollComponent
                    navigation={this.props.navigation}
                    recipes={this.newRecipeRecipes}
                    analyticsBtnName={analyticsBtnName}
                />
            </View>
        );
    }

    /**
     * 新着商品
     */
    _renderNewProduct = (analyticsBtnName: string) => {
        return (
            <View>
                <ProductNewListHorizontalScrollComponent
                    navigation={this.props.navigation}
                    products={this.newProducts}
                    analyticsBtnName={analyticsBtnName}
                />
            </View>
        );
    }

    /**
     * 最近更新された商品
     */
    _renderNewProductProducts = (analyticsBtnName: string) => {
        return (
            <View>
                <ProductNewListHorizontalScrollComponent
                    navigation={this.props.navigation}
                    products={this.newProductProducts}
                    analyticsBtnName={analyticsBtnName}
                />
            </View>
        );
    }

    /**
     * Topタブのコンテンツ作成
     */
    _renderTopTab = () => {
        let _width = AppG.window.width;
        if (ScreenSizeUtil.isWebSize()) {
            _width = appS.webMainContentsSize.width;
        }

        return (
            <View style={[{flex:1, flexDirection: 'column'}]}>
                <KeyboardAwareScrollView
                    ref={(topScrollTab: any) => { this._topScrollTab = topScrollTab; }}
                    style={[{flex: 1, flexDirection: 'column', width: AppG.window.width}]}
                >
                    <View // TouchableOpacity
                        // activeOpacity={1}
                        style={[{flexDirection: ScreenSizeUtil.isWebSize()? 'row': 'column', alignSelf: 'center'}]}>
                        <ScrollView style={{flexDirection: 'column', width: _width}}>
                            {/* 特集カルーセル */}
                            <View style={{height:(this._getSpecialWith() - this._specialImageMargin * 2)/1.8}}>
                                {!ValidateUtil.isEmptyArray(this.specials) && (
                                    <Carousel
                                        data={this.specials}
                                        renderItem={this._renderSpecial}
                                        onSnapToItem={(index: number) => {
                                            this.setState({activeSpecialSlide: index})
                                        }}
                                        width={this._getSpecialWith()}
                                        height={(this._getSpecialWith() - this._specialImageMargin * 2)/1.8}
                                        autoPlay={true}
                                        autoPlayInterval={2500}
                                        loop={true}
                                        style={{margin: 0, zIndex: 2}}
                                    />
                                )}
                            </View>

                            {!ValidateUtil.isEmptyArray(this.specials) && this.specials.length != 1 && (
                                <View style={{
                                    marginTop: 8,
                                    marginBottom: 8,
                                    alignItems: 'center'
                                }}>
                                    <AnimatedDotsCarousel
                                        length={this.specials.length}
                                        currentIndex={this.state.activeSpecialSlide}
                                        maxIndicators={this.specials.length}
                                        interpolateOpacityAndColor={false}
                                        activeIndicatorConfig={{
                                            color: appColors.black,
                                            margin: 3,
                                            opacity: 1,
                                            size: 8,
                                        }}
                                        inactiveIndicatorConfig={{
                                            color: appColors.dotsGray,
                                            margin: 3,
                                            opacity: 0.5,
                                            size: 8,
                                        }}
                                        decreasingDots={[
                                            {
                                                config: { color: appColors.dotsGray, margin: 3, opacity: 0.5, size: 8 },
                                                quantity: 1,
                                            },
                                        ]}
                                    />
                                </View>
                            )}

                            <View style={{
                                marginTop: (ValidateUtil.isEmptyArray(this.specials) || this.specials.length == 1)? 16 : -32,
                                flexDirection: 'row'}}>
                                {/*バックナンバーを見るボタン*/}
                                <View style={{width: this._getSpecialWith() - 200}} />
                                <TouchableOpacity
                                    style={{
                                        zIndex: 10,
                                        position: 'relative',
                                        top: 15-(this._getSpecialWith() - this._specialImageMargin * 2)/1.8,
                                        width: 154,
                                        height: 22,
                                        borderRadius: 10,
                                        backgroundColor: appColors.opacityGray,
                                        alignItems: "center",
                                        justifyContent: 'center',
                                        margin: 3,
                                        marginRight: ScreenSizeUtil.isWebSize()? (ScreenSizeUtil.isMiddleSize()? 160: 70) : 16,
                                    }}
                                    onPress={() => {
                                        SpecialUtil.analyticsSpecial();
                                        let _url = 'https://wp.recibase.net/';
                                        if (Platform.OS != 'web') {
                                            _url = `${_url}?header=1`;
                                        }
                                        LinkUtil.openUrlInWebView(_url, this.props.navigation, '特集一覧');
                                    }}
                                >
                                    <Text style={{color: appColors.white, fontSize: 12}}>{'特集一覧を見る >'}</Text>
                                </TouchableOpacity>
                            </View>

                            {ValidateUtil.isEmptyArray(this.specials) && (
                                <SpacerComponent height={70}/>
                            )}

                            {/*クーポンバナー*/}
                            <TouchableOpacity
                                style={{
                                    marginTop: 10,
                                    marginLeft: 10,
                                    marginRight: 10
                                }}
                                onPress={() => {
                                    let special: Special = {
                                        genre: "",
                                        id: 6394,
                                        image: "",
                                        makerName: "",
                                        name: "ReciBASEとは？",
                                        type: SpecialTypeEnum.NotLogin,
                                        url: "https://wp.recibase.net/recibase-lp/"
                                    };
                                    SpecialUtil.openSpecial(special, this.props.navigation);
                                }}
                            >
                                <Image style={{
                                    width: _width - 20,
                                    height: (_width - 20) * 0.242,
                                    marginTop: appS.margins.side,
                                    marginBottom: appS.margins.side,
                                }}
                                       source={require('../../resources/images/top_banner/what_is_ReciBASE_banner.jpg')}/>
                            </TouchableOpacity>

                            {(this._recommendKeywordRecipes[0] || this._recommendKeywordRecipes[1]) && (<TopicTitleComponent title={'注目のキーワード'}/>)}

                            {/*注目のキーワード*/}
                            {this._recommendKeywordRecipes != null && this._recommendKeywordRecipes[0] && !ValidateUtil.isEmptyArray(this._recommendKeywordRecipes[0].recipes) && this._recommendKeywordRecipes[0].keyword != null
                                && this._renderPickUpKeywordsRecipe(
                                    this._recommendKeywordRecipes[0].recipes!,
                                    this._recommendKeywordRecipes[0].keyword!,
                                    `注目のキーワード_${this._recommendKeywordRecipes[0].keyword}_TOPタブ`
                                )}

                            {/*注目のキーワード*/}
                            {this._recommendKeywordRecipes != null && this._recommendKeywordRecipes[1] && !ValidateUtil.isEmptyArray(this._recommendKeywordRecipes[1].recipes) && this._recommendKeywordRecipes[1].keyword != null
                                && this._renderPickUpKeywordsRecipe(
                                    this._recommendKeywordRecipes[1].recipes!,
                                    this._recommendKeywordRecipes[1].keyword!,
                                    `注目のキーワード_${this._recommendKeywordRecipes[1].keyword}_TOPタブ`
                                )}
                            <SpacerComponent height={this._topicMargin}/>

                            {/*新着レシピ*/}
                            <TopicTitleComponent
                                title={'新着レシピ'}
                                more={true}
                                onPress={() => {
                                    const { routeName } = this.props.navigation.state;
                                    let searchScreenModel: SearchScreenModel = new SearchScreenModel();
                                    searchScreenModel.headerTitle = `新着レシピ`;
                                    searchScreenModel.screenNameFrom = routeName;
                                    searchScreenModel.searchType = 'recipe';
                                    searchScreenModel.recipeSortType = 'new';
                                    searchScreenModel.tabHidden = true;
                                    this.props.navigation.navigate('SearchResultScreen', searchScreenModel);
                                }} />
                            {this._renderNewRecipe('新着レシピ_TOPタブ')}
                            <SpacerComponent height={this._topicMargin}/>

                            <TopicTitleComponent
                                title={'レシピ人気キーワード'}
                                more={true}
                                onPress={() => {
                                    const { routeName } = this.props.navigation.state;
                                    let searchScreenModel: SearchScreenModel = new SearchScreenModel();
                                    searchScreenModel.searchType = 'recipe';
                                    searchScreenModel.screenNameFrom = routeName;
                                    searchScreenModel.headerTitle = 'レシピ人気キーワード';
                                    searchScreenModel.tabHidden = true;
                                    this.props.navigation.navigate('SearchKeywordScreen', searchScreenModel);
                                }}
                            />
                            <SearchWordsComponent
                                showCount={9}
                                keywords={this.popularKeywords}
                                onPress={(keyword) => {
                                    const { routeName } = this.props.navigation.state;
                                    let searchScreenModel: SearchScreenModel = new SearchScreenModel();
                                    searchScreenModel.headerTitle = `${keyword.value}`;
                                    searchScreenModel.searchType = 'recipe';
                                    searchScreenModel.keyword = `${keyword.value}`;
                                    searchScreenModel.searchValue = `${keyword.value}`;
                                    searchScreenModel.screenNameFrom = routeName;
                                    searchScreenModel.tabHidden = true;
                                    AppG.screenHistory.unshift(`レシピ人気キーワードボタン_TOPタブ`);
                                    if (Platform.OS == 'web') {
                                        this.props.navigation.navigate('SearchResultScreen', searchScreenModel);
                                    } else {
                                        this.props.navigation.push('SearchResultScreen', searchScreenModel);
                                    }
                                }}/>
                            <SpacerComponent height={this._topicMargin}/>

                            {/*クーポンバナー*/}
                            {/*<TouchableOpacity*/}
                            {/*    style={{*/}
                            {/*        marginTop: 10,*/}
                            {/*        marginLeft: 10,*/}
                            {/*        marginRight: 10*/}
                            {/*    }}*/}
                            {/*    onPress={() => {*/}
                            {/*        let special: Special = {*/}
                            {/*            genre: "",*/}
                            {/*            id: 115,*/}
                            {/*            image: "",*/}
                            {/*            makerName: "",*/}
                            {/*            name: "【FOODS FRIDGE 限定】スペシャルクーポンのご紹介",*/}
                            {/*            type: SpecialTypeEnum.NotLogin,*/}
                            {/*            url: "https://wp.recibase.net/special-coupon-03/"*/}
                            {/*        };*/}
                            {/*        SpecialUtil.openSpecial(special, this.props.navigation);*/}
                            {/*    }}*/}
                            {/*>*/}
                            {/*    <Image style={{*/}
                            {/*        width: _width - 20,*/}
                            {/*        height: (_width - 20) * 0.242,*/}
                            {/*        marginTop: appS.margins.side,*/}
                            {/*        marginBottom: appS.margins.side,*/}
                            {/*    }}*/}
                            {/*           source={require('../../resources/images/top_banner/coupon_banner.png')}/>*/}
                            {/*</TouchableOpacity>*/}

                            <SpacerComponent height={this._topicMargin}/>

                            {/*新着商品*/}
                            <TopicTitleComponent
                                title={'新着商品'}
                                more={true}
                                onPress={() => {
                                    const { routeName } = this.props.navigation.state;
                                    let searchScreenModel: SearchScreenModel = new SearchScreenModel();
                                    searchScreenModel.headerTitle = `新着商品`;
                                    searchScreenModel.screenNameFrom = routeName;
                                    searchScreenModel.searchType = 'product';
                                    searchScreenModel.productSortType = 'new';
                                    searchScreenModel.tabHidden = true;
                                    this.props.navigation.navigate('SearchResultScreen', searchScreenModel);
                                }}
                            />
                            {this._renderNewProduct('新着商品_TOPタブ')}
                            <SpacerComponent height={this._topicMargin}/>

                            {/*商品人気キーワード*/}
                            <TopicTitleComponent
                                title={'商品人気キーワード'}
                                more={true}
                                onPress={() => {
                                    const { routeName } = this.props.navigation.state;
                                    let searchScreenModel: SearchScreenModel = new SearchScreenModel();
                                    searchScreenModel.searchType = 'product';
                                    searchScreenModel.screenNameFrom = routeName;
                                    searchScreenModel.headerTitle = '商品人気キーワード';
                                    searchScreenModel.tabHidden = true;
                                    this.props.navigation.navigate('SearchKeywordScreen', searchScreenModel);
                                }}
                            />
                            <SearchWordsComponent
                                showCount={9}
                                keywords={this.popularProductKeywords}
                                onPress={(keyword) => {
                                    const { routeName } = this.props.navigation.state;
                                    let searchScreenModel: SearchScreenModel = new SearchScreenModel();
                                    searchScreenModel.headerTitle = `${keyword.value}`;
                                    searchScreenModel.searchType = 'product';
                                    searchScreenModel.keyword = `${keyword.value}`;
                                    searchScreenModel.searchValue = `${keyword.value}`;
                                    searchScreenModel.screenNameFrom = routeName;
                                    searchScreenModel.tabHidden = true;
                                    AppG.screenHistory.unshift(`商品人気キーワードボタン_TOPタブ`);
                                    if (Platform.OS == 'web') {
                                        this.props.navigation.navigate('SearchResultScreen', searchScreenModel);
                                    } else {
                                        this.props.navigation.push('SearchResultScreen', searchScreenModel);
                                    }
                                }}/>
                            <SpacerComponent height={this._topicMargin}/>

                            {/*週間レシピランキング*/}
                            <TopicTitleComponent title={'週間レシピランキング'}/>
                            {/*週間レシピランキング*/}
                            <View>
                                <RecipeRankingListHorizontalScrollComponent
                                    navigation={this.props.navigation}
                                    recipes={this.rankingRecipesRecommendTab}
                                    analyticsBtnName={'【ホーム画面-Topタブ】週間レシピランキングボタン'}
                                    top={5}
                                />
                            </View>
                            <SpacerComponent height={this._topicMargin}/>

                            {/*週間商品ランキング*/}
                            <TopicTitleComponent title={'週間商品ランキング'}/>

                            {/*週間商品ランキング*/}
                            <View>
                                <ProductRankingListHorizontalScrollComponent
                                    navigation={this.props.navigation}
                                    products={this.rankingProductsRecommendTab}
                                    analyticsBtnName={'【ホーム画面-Topタブ】週間商品ランキングボタン'}
                                    top={5}
                                />
                            </View>

                            {Platform.OS == 'web' && (<SpacerComponent height={150}/>)}
                            {Platform.OS != 'web' && (<SpacerComponent height={this._scrollBottomSpace + 80}/>)}

                        </ScrollView>

                        {ScreenSizeUtil.isWebSize() && (
                            <View>
                                <View style={{marginLeft: appS.margins.webBetweenMargin, width: appS.webSideContentsSize.width}}>
                                    <WideScreenAdComponent navigation={this.props.navigation}/>
                                    <WideScreenSNSComponent navigation={this.props.navigation}/>
                                </View>
                            </View>
                        )}
                    </View>
                </KeyboardAwareScrollView>
            </View>
        );
    }

    /**
     * レシピタブのコンテンツ作成
     */
    _renderRecipeTab = () => {
        const {navigation} = this.props;
        return (
            <View style={[{flex:1, flexDirection: 'column'}]}>

                <KeyboardAwareScrollView
                    ref={(recipeScrollTab: any) => { this._recipeScrollTab = recipeScrollTab; }}
                    style={[{flex: 1, flexDirection: 'column', width: AppG.window.width}]}
                    onScroll={(event: any) => {
                        // 無限スクロール
                        // スクロールViewの高さ
                        const scrollViewHeight = event.nativeEvent.contentSize.height - event.nativeEvent.layoutMeasurement.height;
                        // スクロールView上の現在位置
                        const nowPositionHeight = event.nativeEvent.contentOffset.y;
                        this._recipeScrollTabY = nowPositionHeight;
                        // 現在の位置がスクロールView + activityIndicator の高さよりも下の場合
                        const indicatorHeight = Platform.OS == 'web'? -10: -appS.activityIndicator.height;
                        if (!this.isLoadRecommendRecipes && this.recommendRecipes.length != 0 && nowPositionHeight - scrollViewHeight > indicatorHeight) {
                            this.isLoadRecommendRecipes = true;
                            this.setState({resizeWindow: true});
                            this._loadRecommendRecipes(this.recommendRecipes.length, this.recommendRecipes.length + this._contentsLoadCount);
                        }
                    }}
                >
                    <View // TouchableOpacity
                        // activeOpacity={1}
                        style={[{flexDirection: ScreenSizeUtil.isWebSize()? 'row': 'column', alignSelf: 'center'}]}>
                        <ScrollView style={{flexDirection: 'column'}}>
                            {/*<SpacerComponent height={25}/>*/}

                            {/*新着レシピ*/}
                            <TopicTitleComponent
                                title={'最近更新されたレシピ'}
                                more={true}
                                onPress={() => {
                                    const { routeName } = this.props.navigation.state;
                                    let searchScreenModel: SearchScreenModel = new SearchScreenModel();
                                    searchScreenModel.headerTitle = `最近更新されたレシピ`;
                                    searchScreenModel.screenNameFrom = routeName;
                                    searchScreenModel.searchType = 'recipe';
                                    searchScreenModel.recipeSortType = 'new';
                                    searchScreenModel.tabHidden = true;
                                    this.props.navigation.navigate('SearchResultScreen', searchScreenModel);
                                }}
                            />
                            {this._renderNewRecipeRecipe('最近更新されたレシピ_レシピタブ')}
                            <SpacerComponent height={this._topicMargin}/>

                            {(this._recommendKeywordRecipes[2] || this._recommendKeywordRecipes[3]) && (<TopicTitleComponent title={'注目のキーワード'}/>)}

                            {/*注目のキーワード*/}
                            {this._recommendKeywordRecipes != null && this._recommendKeywordRecipes[2] && !ValidateUtil.isEmptyArray(this._recommendKeywordRecipes[2].recipes) && this._recommendKeywordRecipes[2].keyword != null
                                && this._renderPickUpKeywordsRecipe(
                                    this._recommendKeywordRecipes[2].recipes!,
                                    this._recommendKeywordRecipes[2].keyword!,
                                    `注目のキーワード_${this._recommendKeywordRecipes[2].keyword}_レシピタブ`
                                )}

                            {/*注目のキーワード*/}
                            {this._recommendKeywordRecipes != null && this._recommendKeywordRecipes[3] && !ValidateUtil.isEmptyArray(this._recommendKeywordRecipes[3].recipes) && this._recommendKeywordRecipes[3].keyword != null
                                && this._renderPickUpKeywordsRecipe(
                                    this._recommendKeywordRecipes[3].recipes!,
                                    this._recommendKeywordRecipes[3].keyword!,
                                    `注目のキーワード_${this._recommendKeywordRecipes[3].keyword}_レシピタブ`
                                )}
                            <SpacerComponent height={this._topicMargin}/>

                            <TopicTitleComponent
                                title={'人気のキーワード'}
                                more={true}
                                onPress={() => {
                                    const { routeName } = this.props.navigation.state;
                                    let searchScreenModel: SearchScreenModel = new SearchScreenModel();
                                    searchScreenModel.searchType = 'recipe';
                                    searchScreenModel.screenNameFrom = routeName;
                                    searchScreenModel.headerTitle = 'レシピ人気キーワード';
                                    searchScreenModel.tabHidden = true;
                                    this.props.navigation.navigate('SearchKeywordScreen', searchScreenModel);
                                }}
                            />
                            <SearchWordsComponent
                                showCount={9}
                                keywords={this.popularKeywords}
                                onPress={(keyword) => {
                                    const { routeName } = this.props.navigation.state;
                                    let searchScreenModel: SearchScreenModel = new SearchScreenModel();
                                    searchScreenModel.headerTitle = `${keyword.value}`;
                                    searchScreenModel.searchType = 'recipe';
                                    searchScreenModel.keyword = `${keyword.value}`;
                                    searchScreenModel.searchValue = `${keyword.value}`;
                                    searchScreenModel.screenNameFrom = routeName;
                                    searchScreenModel.tabHidden = true;
                                    AppG.screenHistory.unshift(`レシピ人気キーワードボタン_レシピタブ`);
                                    if (Platform.OS == 'web') {
                                        this.props.navigation.navigate('SearchResultScreen', searchScreenModel);
                                    } else {
                                        this.props.navigation.push('SearchResultScreen', searchScreenModel);
                                    }
                                }}/>
                            <SpacerComponent height={this._topicMargin}/>
                            <SpacerComponent height={this._topicMargin}/>

                            <TopicTitleComponent title={'季節から探す'}/>
                            <View>
                                <SeasonComponent navigation={this.props.navigation} analyticsBtnName={`季節ボタン`} />
                            </View>
                            <SpacerComponent height={this._topicMargin}/>

                            <TopicTitleComponent title={'業態から探す'} more={true} onPress={() => {
                                navigation.navigate('SearchJobCategoryScreen');
                            }}/>
                            <View>
                                <JobCategoryListComponent navigation={navigation} fullFlg={false} onPress={(id: string, name: string) => {
                                    let searchScreenModel: SearchScreenModel = new SearchScreenModel();
                                    searchScreenModel.headerTitle = name;
                                    searchScreenModel.recipeRestaurantLargeFormatIds = `|${id}|`;
                                    searchScreenModel.searchType = 'recipe';
                                    searchScreenModel.tabHidden = true;
                                    this.props.navigation.navigate('SearchResultScreen', searchScreenModel);
                                }} />
                            </View>
                            <SpacerComponent height={this._topicMargin}/>

                            {/*メニューカテゴリから探す*/}
                            <TopicTitleComponent
                                title={'カテゴリから探す'}
                                more={true}
                                onPress={() => {
                                    const { routeName } = navigation.state;
                                    let searchScreenModel: SearchScreenModel = new SearchScreenModel();
                                    searchScreenModel.headerTitle = 'カテゴリから探す';
                                    searchScreenModel.screenNameFrom = routeName;
                                    searchScreenModel.tabHidden = true;
                                    navigation.navigate('SearchMenuCategoryScreen', searchScreenModel);
                                }}
                            />
                            <View>
                                <RecipeCategoryListComponent navigation={navigation} fullFlg={false} />
                            </View>

                            <SpacerComponent height={this._topicMargin}/>

                            {/*おすすめレシピ*/}
                            {!ValidateUtil.isEmptyArray(this.recommendRecipes) && (
                                <View>
                                    <TopicTitleComponent title={'おすすめレシピ'} />
                                    <RecipeSearchResultListComponent
                                        navigation={navigation}
                                        recipes={this.recommendRecipes}
                                        analyticsBtnName={'【ホーム画面・レシピタブ】おすすめレシピボタン'}
                                    />
                                </View>
                            )}

                            <SpacerComponent height={this._topicMargin}/>

                            {/*おすすめレシピローディングインジケーター*/}
                            <View style={{flex: 1, height: appS.activityIndicator.height}}>
                                <ActivityIndicator
                                    animating={this.isLoadRecommendRecipes}
                                    color = {appColors.indicator}
                                    size = "large"
                                    style={[{flex: 1}]}/>
                            </View>

                            {Platform.OS == 'web' && (<SpacerComponent height={150}/>)}
                            {Platform.OS != 'web' && (<SpacerComponent height={this._scrollBottomSpace}/>)}

                        </ScrollView>

                        {ScreenSizeUtil.isWebSize() && (
                            <View>
                                <View style={{marginLeft: appS.margins.webBetweenMargin, width: appS.webSideContentsSize.width}}>
                                    <WideScreenAdComponent navigation={this.props.navigation}/>
                                    <WideScreenSNSComponent navigation={this.props.navigation}/>
                                </View>
                            </View>

                        )}
                    </View>

                </KeyboardAwareScrollView>
            </View>
        );
    }


    /**
     * 商品タブ
     */
    _renderProductCategoryTab = () => {
        let _width = AppG.window.width;
        if (ScreenSizeUtil.isWebSize()) {
            _width = appS.webMainContentsSize.width;
        }
        _width = _width - appS.margins.side * 2;

        return (
            <View style={[{flex: 1, flexDirection: 'column'}]}>

                <KeyboardAwareScrollView
                    ref={(productScrollTab: any) => { this._productScrollTab = productScrollTab; }}
                    style={[{flex: 1, flexDirection: 'column', width: AppG.window.width}]}
                    onScroll={(event: any) => {
                        // 無限スクロール
                        // スクロールViewの高さ
                        const scrollViewHeight = event.nativeEvent.contentSize.height - event.nativeEvent.layoutMeasurement.height;
                        // スクロールView上の現在位置
                        const nowPositionHeight = event.nativeEvent.contentOffset.y;
                        this._productScrollTabY = nowPositionHeight;
                        // 現在の位置がスクロールView + activityIndicator の高さよりも下の場合
                        const indicatorHeight = Platform.OS == 'web'? -10: -appS.activityIndicator.height;
                        if (!this.isLoadRecommendProducts && this.recommendProducts.length != 0 && nowPositionHeight - scrollViewHeight > indicatorHeight) {
                            this.isLoadRecommendProducts = true;
                            this.setState({resizeWindow: true});
                            this._loadRecommendProducts(this.recommendProducts.length, this.recommendProducts.length + this._contentsLoadCount);
                        }
                    }}
                >
                    <View // TouchableOpacity
                        // activeOpacity={1}
                        style={[{flexDirection: ScreenSizeUtil.isWebSize()? 'row': 'column', alignSelf: 'center'}]}>
                        <ScrollView style={{flexDirection: 'column'}}>
                            <View style={[{flex: 1, flexDirection: 'column'}]}>
                                {/*New商品一覧*/}
                                {!ValidateUtil.isEmptyArray(this.newProducts) && (
                                    <View>
                                        <View style={{zIndex: 2, width: ScreenSizeUtil.isWebSize()? appS.webMainContentsSize.width : AppG.window.width}}>
                                            {/*<SpacerComponent height={25}/>*/}

                                            {/*最近更新された商品 */}
                                            <TopicTitleComponent
                                                title={'最近更新された商品'}
                                                more={true}
                                                onPress={() => {
                                                    const { routeName } = this.props.navigation.state;
                                                    let searchScreenModel: SearchScreenModel = new SearchScreenModel();
                                                    searchScreenModel.headerTitle = `最近更新された商品`;
                                                    searchScreenModel.screenNameFrom = routeName;
                                                    searchScreenModel.searchType = 'product';
                                                    searchScreenModel.productSortType = 'new';
                                                    searchScreenModel.tabHidden = true;
                                                    this.props.navigation.navigate('SearchResultScreen', searchScreenModel);
                                                }}
                                            />
                                            {this._renderNewProductProducts('最近更新された商品_商品タブ')}
                                            <SpacerComponent height={this._topicMargin}/>
                                        </View>
                                    </View>
                                )}

                                {(this._recommendKeywordProducts[0] || this._recommendKeywordProducts[1]) && (<TopicTitleComponent title={'注目のキーワード'}/>)}

                                {/*注目のキーワード*/}
                                {this._recommendKeywordProducts != null && this._recommendKeywordProducts[0] && !ValidateUtil.isEmptyArray(this._recommendKeywordProducts[0].products) && this._recommendKeywordProducts[0].keyword != null
                                    && this._renderPickUpKeywordsProduct(
                                        this._recommendKeywordProducts[0].products!,
                                        this._recommendKeywordProducts[0].keyword!,
                                        `注目のキーワード_${this._recommendKeywordProducts[0].keyword}_商品タブ`
                                    )}

                                {/*注目のキーワード*/}
                                {this._recommendKeywordProducts != null && this._recommendKeywordProducts[1] && !ValidateUtil.isEmptyArray(this._recommendKeywordProducts[1].products) && this._recommendKeywordProducts[1].keyword != null
                                    && this._renderPickUpKeywordsProduct(
                                        this._recommendKeywordProducts[1].products!,
                                        this._recommendKeywordProducts[1].keyword!,
                                        `注目のキーワード_${this._recommendKeywordProducts[1].keyword}_商品タブ`
                                    )}

                                <SpacerComponent height={this._topicMargin}/>

                                <TopicTitleComponent
                                    title={'人気のキーワード'}
                                    more={true}
                                    onPress={() => {
                                        const { routeName } = this.props.navigation.state;
                                        let searchScreenModel: SearchScreenModel = new SearchScreenModel();
                                        searchScreenModel.searchType = 'product';
                                        searchScreenModel.screenNameFrom = routeName;
                                        searchScreenModel.headerTitle = '商品人気キーワード';
                                        searchScreenModel.tabHidden = true;
                                        this.props.navigation.navigate('SearchKeywordScreen', searchScreenModel);
                                    }}
                                />
                                <SearchWordsComponent
                                    showCount={9}
                                    keywords={this.popularProductKeywords}
                                    onPress={(keyword) => {
                                        const { routeName } = this.props.navigation.state;
                                        let searchScreenModel: SearchScreenModel = new SearchScreenModel();
                                        searchScreenModel.headerTitle = `${keyword.value}`;
                                        searchScreenModel.searchType = 'product';
                                        searchScreenModel.keyword = `${keyword.value}`;
                                        searchScreenModel.searchValue = `${keyword.value}`;
                                        searchScreenModel.screenNameFrom = routeName;
                                        searchScreenModel.tabHidden = true;
                                        AppG.screenHistory.unshift(`商品人気キーワードボタン_商品タブ`);
                                        if (Platform.OS == 'web') {
                                            this.props.navigation.navigate('SearchResultScreen', searchScreenModel);
                                        } else {
                                            this.props.navigation.push('SearchResultScreen', searchScreenModel);
                                        }
                                    }}/>
                                <SpacerComponent height={this._topicMargin}/>

                                <TopicTitleComponent title={'食材カテゴリから探す'}/>
                                <View>
                                    <ProductCategoryListComponent navigation={this.props.navigation} />
                                </View>
                                <SpacerComponent height={this._topicMargin}/>


                                {/*<TopicTitleComponent title={'食材カテゴリから探す'}/>*/}
                                {/*<ProductCategoryListComponent*/}
                                {/*    navigation={this.props.navigation}*/}
                                {/*    fullFlg={true}*/}
                                {/*    productCategoryIdsWeb={null}*/}
                                {/*    onPress={(id: string, name: string) => {*/}
                                {/*        let searchScreenModal: SearchScreenModel = new SearchScreenModel();*/}
                                {/*        searchScreenModal.headerTitle = name;*/}
                                {/*        searchScreenModal.productLargeCategoryId = id;*/}
                                {/*        searchScreenModal.searchType = 'product';*/}
                                {/*        this.props.navigation.navigate('SearchResultScreen', searchScreenModal);*/}
                                {/*    }} />*/}
                                {/*<SpacerComponent height={appS.footer.height}/>*/}

                                {/*おすすめ商品*/}
                                {!ValidateUtil.isEmptyArray(this.recommendProducts) && (
                                    <View>
                                        <TopicTitleComponent title={'おすすめ商品'} />
                                        <ProductSearchResultListComponent
                                            navigation={this.props.navigation}
                                            products={this.recommendProducts}
                                            analyticsBtnName={'【ホーム画面・商品タブ】おすすめ商品ボタン'}
                                        />
                                    </View>
                                )}

                                <SpacerComponent height={this._topicMargin}/>

                                {/*おすすめレシピローディングインジケーター*/}
                                <View style={{flex: 1, height: appS.activityIndicator.height}}>
                                    <ActivityIndicator
                                        animating={this.isLoadRecommendProducts}
                                        color = {appColors.indicator}
                                        size = "large"
                                        style={[{flex: 1}]}/>
                                </View>

                                {Platform.OS == 'web' && (<SpacerComponent height={150}/>)}
                                {Platform.OS != 'web' && (<SpacerComponent height={this._scrollBottomSpace}/>)}
                            </View>
                        </ScrollView>

                        {ScreenSizeUtil.isWebSize() && (
                            <View>
                                <View style={{marginLeft: appS.margins.webBetweenMargin, width: appS.webSideContentsSize.width}}>
                                    <WideScreenAdComponent navigation={this.props.navigation}/>
                                    <WideScreenSNSComponent navigation={this.props.navigation}/>
                                </View>
                            </View>

                        )}
                    </View>
                </KeyboardAwareScrollView>
            </View>
        );
    }



    /**
     * メーカータブ
     */
    _renderMakerTab = () => {
        const _aiueoSize = 40;
        const _aioeoFontSize = 16;
        const _rowFontSize = 10;
        const _aiueoMargin= {top: -2, left: 5};

        let _width = AppG.window.width;
        if (ScreenSizeUtil.isWebSize()) {
            _width = appS.webMainContentsSize.width;
        }

        const _renderMakerList = (makers: Maker[], index: number) => {
            // 選択されていない or すべて選択でないは return null
            if (index != this.state.clickedAiueoMakerButtonIndex && 99 != this.state.clickedAiueoMakerButtonIndex) {
                return null;
            }
            return (
                <MakerListComponent makers={makers} navigation={this.props.navigation} key={`maker_list_${index}`} />
            );
        };

        const _button = (map: string, index: number) => {
            return (
                <TouchableOpacity
                    key={`aiueo_view_${index}`}
                    style={{
                        margin: appS.margins.side / 2,
                        flexDirection: 'row',
                        width: _aiueoSize,
                        height: _aiueoSize,
                        borderWidth: 1,
                        borderRadius: 5,
                        backgroundColor: this.state.clickedAiueoMakerButtonIndex == index? appColors.actionColor: appColors.transparent,
                        borderColor: appColors.actionColor
                    }}
                    onPress={() => {
                        this.setState({clickedAiueoMakerButtonIndex: index});
                    }}
                >
                    <Text
                        key={`aiueo_text_${index}`}
                        style={{
                            fontFamily: appFont.family.bold,
                            fontSize: _aioeoFontSize,
                            color: this.state.clickedAiueoMakerButtonIndex == index? appColors.white : appColors.actionColor,
                            lineHeight: _aiueoSize,
                            textAlignVertical: 'center',
                            marginTop: _aiueoMargin.top,
                            marginLeft: _aiueoMargin.left,
                            fontWeight: "bold"
                        }}
                    >{map}</Text>
                    <Text
                        key={`aiueo_row_text_${index}`}
                        style={{
                            fontFamily: appFont.family.default,
                            fontSize: _rowFontSize,
                            color: this.state.clickedAiueoMakerButtonIndex == index? appColors.white : appColors.actionColor,
                            lineHeight: _aiueoSize,
                            textAlignVertical: 'center'
                        }}
                    >行</Text>
                </TouchableOpacity>
            );
        }

        return (
            <View style={[{flex: 1, flexDirection: 'column'}]}>

                <KeyboardAwareScrollView
                    ref={(makerScrollTab: any) => { this._makerScrollTab = makerScrollTab; }}
                    style={[{flex: 1, flexDirection: 'column', width: AppG.window.width}]}
                    onScroll={(event: any) => {
                        // スクロールView上の現在位置
                        this._makerScrollTabY = event.nativeEvent.contentOffset.y;
                    }}
                >
                    <View // TouchableOpacity
                        // activeOpacity={1}
                        style={[{flexDirection: ScreenSizeUtil.isWebSize()? 'row': 'column', alignSelf: 'center'}]}>
                        <ScrollView style={{flexDirection: 'column'}}>
                            <View style={{width: _width}}>
                                <SpacerComponent height={25}/>
                                {ScreenSizeUtil.isWebSize() && (
                                    <View style={{alignSelf: 'center'}}>
                                        <View style={{flexDirection: 'row', alignSelf: 'center'}}>
                                            {this._titlesOfMaker.map((map, index) => {
                                                return _button(map, index);
                                            })}
                                        </View>
                                        <View style={{alignSelf: 'center'}}>
                                            {this.state.clickedAiueoMakerButtonIndex != 99 && (
                                                <TouchableOpacity
                                                    style={{margin: appS.margins.side / 2, borderWidth: 1, borderRadius: 5, borderColor: appColors.actionColor}}
                                                    onPress={() => {
                                                        this.setState({clickedAiueoMakerButtonIndex: 99});
                                                    }}
                                                >
                                                    <Text
                                                        style={{
                                                            fontFamily: appFont.family.bold,
                                                            fontSize: _aioeoFontSize,
                                                            color: appColors.actionColor,
                                                            lineHeight: _aiueoSize,
                                                            textAlignVertical: 'center',
                                                            marginTop: _aiueoMargin.top,
                                                            textAlign: 'center',
                                                            width: _aiueoSize * 5 + _aiueoMargin.left * 6,
                                                            fontWeight: "bold"
                                                        }}
                                                    >メーカーをすべて表示</Text>
                                                </TouchableOpacity>
                                            )}
                                        </View>
                                    </View>
                                )}
                                {!ScreenSizeUtil.isWebSize() && (
                                    <View style={{alignSelf: 'center'}}>
                                        <View style={{flexDirection: 'row', alignSelf: 'center'}}>
                                            {this._titlesOfMaker.map((map, index) => {
                                                if (index > 4) {
                                                    return null;
                                                }
                                                return _button(map, index);
                                            })}
                                        </View>
                                        <View style={{flexDirection: 'row', alignSelf: 'center'}}>
                                            {this._titlesOfMaker.map((map, index) => {
                                                if (index <= 4) {
                                                    return null;
                                                }
                                                return _button(map, index);
                                            })}
                                        </View>
                                        <View style={{alignSelf: 'center'}}>
                                            {this.state.clickedAiueoMakerButtonIndex != 99 && (
                                                <TouchableOpacity
                                                    style={{margin: appS.margins.side / 2, borderWidth: 1, borderRadius: 5, borderColor: appColors.actionColor}}
                                                    onPress={() => {
                                                        this.setState({clickedAiueoMakerButtonIndex: 99});
                                                    }}
                                                >
                                                    <Text
                                                        style={{
                                                            fontFamily: appFont.family.bold,
                                                            fontSize: _aioeoFontSize,
                                                            color: appColors.actionColor,
                                                            lineHeight: _aiueoSize,
                                                            textAlignVertical: 'center',
                                                            marginTop: _aiueoMargin.top,
                                                            textAlign: 'center',
                                                            width: _aiueoSize * 5 + _aiueoMargin.left * 6,
                                                            fontWeight: "bold"
                                                        }}
                                                    >メーカーをすべて表示</Text>
                                                </TouchableOpacity>
                                            )}
                                        </View>
                                    </View>
                                )}
                                <View>
                                    <View style={{marginLeft: appS.margins.side}} >
                                        {ExpoUtil._wrap(<BorderComponent borderColor={appColors.spacerGray} width={_width - appS.margins.side * 2}/>)}
                                        {this._titlesOfMaker.map((title: string, index: number) => {
                                            if (this.makers[index] != null && this.makers[index].length != 0) {
                                                return (
                                                    <View key={`maker_view_${index}`}>
                                                        {this.makers.length > index && this.makers[index] != null && _renderMakerList(this.makers[index], index)}
                                                    </View>
                                                );
                                            }
                                        })}
                                    </View>
                                </View>
                            </View>

                            {ScreenSizeUtil.isWebSize() && (<SpacerComponent height={150}/>)}
                            {!ScreenSizeUtil.isWebSize() && Platform.OS == 'web' && (<SpacerComponent height={130}/>)}
                            {Platform.OS == 'android' && (<SpacerComponent height={this._scrollBottomSpace}/>)}
                            {Platform.OS == 'ios' && (<SpacerComponent height={this._scrollBottomSpace + 40}/>)}
                        </ScrollView>

                        {ScreenSizeUtil.isWebSize() && (
                            <View>
                                <View style={{marginLeft: appS.margins.webBetweenMargin, width: appS.webSideContentsSize.width}}>
                                    <WideScreenAdComponent navigation={this.props.navigation}/>
                                    <WideScreenSNSComponent navigation={this.props.navigation}/>
                                </View>
                            </View>

                        )}
                    </View>
                </KeyboardAwareScrollView>
            </View>
        );
    }

    /**
     * レシピランキング
     */
    _renderRecipeRanking = () => {
        let _width = AppG.window.width;
        if (ScreenSizeUtil.isWebSize()) {
            _width = appS.webMainContentsSize.width;
        }
        _width = _width - appS.margins.side * 2;

        return (
            <View // TouchableOpacity
                // activeOpacity={1}
                style={[{flexDirection: ScreenSizeUtil.isWebSize()? 'row': 'column', alignSelf: 'center'}]}>
                <ScrollView style={{flexDirection: 'column'}}>
                    <TabBorderComponent
                        tabs={this._rTabs}
                        focusTabNumber={0}
                        unselectBorder={true}
                        button={true}
                    />
                    {/*週間レシピランキング(有料会員)*/}
                    {AppG.user != null && AppG.user.userPlan === UserUserPlanEnum.Premium && (
                        <TouchableOpacity activeOpacity={1}>
                            {/*しぼりこみ*/}
                            <View style={{
                                margin: appS.margins.side,
                                marginBottom: 0,
                                borderColor: appColors.searchMenuBorder,
                                borderWidth: 1,
                                width: _width + 2,
                            }}>
                                <TopicTitleComponent title={'メニューカテゴリ'} subTopic={true} backgroundColor={appColors.subTopicBackground} />
                                <View style={{width: _width - appS.margins.side * 2, margin: appS.margins.side}}>
                                    <RNPickerSelect
                                        style={appS.picker}
                                        // placeholder={{ label: 'すべてのメニューカテゴリ', value: undefined }}
                                        placeholder={{}}
                                        onValueChange={(value) => {
                                            let _value = value;
                                            if (value == 'すべてのメニューカテゴリ') {
                                                _value = undefined;
                                            }
                                            this.setState({filterRecipeCategory: _value});
                                            this._loadRankingRecipes(0, this._recipeRankingNumForTab, true);
                                        }}
                                        items={PickerUtil.addFirstMasterToPickerItem({label: 'すべてのメニューカテゴリ', value: '', key: 0}, PickerUtil.mastersToPickerItems(this.recipeCategories))}
                                        Icon={() => (<PullDownIconComponent/>)}
                                    />
                                </View>

                                <TopicTitleComponent title={'参考原価'} subTopic={true} backgroundColor={appColors.subTopicBackground} />
                                <View style={{flexDirection: "row"}}>
                                    <CostComponent
                                        pickChangeLeft={(value) => {
                                            let _value = value;
                                            if (value == 0) {
                                                _value = undefined;
                                            }
                                            this.setState({filterCostFrom: _value});
                                            this._loadRankingRecipes(0, this._recipeRankingNumForTab, true);
                                        }}
                                        pickChangeRight={(value) => {
                                            let _value = value;
                                            if (value == '上限無し') {
                                                _value = undefined;
                                            }
                                            this.setState({filterCostTo: _value});
                                            this._loadRankingRecipes(0, this._recipeRankingNumForTab, true);
                                        }}
                                    />
                                </View>
                                {ScreenSizeUtil.isWebSize() && (<SpacerComponent height={15} />)}
                            </View>

                            <RecipeListRankingComponent
                                navigation={this.props.navigation}
                                recipes={this.rankingRecipes}
                                analyticsBtnName={'【ホーム画面-ランキングタブ】週間レシピランキングボタン'}
                            />
                        </TouchableOpacity>
                    )}


                    {/*レシピランキングローディングインジケーター(有料会員)*/}
                    {AppG.user != null && AppG.user.userPlan === UserUserPlanEnum.Premium
                        && (
                            <View style={{flex: 1, height: appS.activityIndicator.height}}>
                                <ActivityIndicator
                                    animating={this.isLoadRankingRecipes}
                                    color = {appColors.indicator}
                                    size = "large"
                                    style={[{flex: 1, marginTop: 48}]}/>
                            </View>
                        )}

                    {AppG.user != null && AppG.user.userPlan === UserUserPlanEnum.Premium
                        && !this.isLoadRankingRecipes && ValidateUtil.isEmptyArray(this.rankingRecipes) && (
                            <NoItemsIconComponent text={'レシピが見つかりませんでした'}/>
                        )}

                    {/*週間レシピランキング(ログイン無し)*/}
                    {/*未ログイン*/}
                    {AppG.user == null && (
                        <View style={{flex: 1, backgroundColor: appColors.backBaseColor}}>
                            <NoUserImageComponent navigation={this.props.navigation} />
                        </View>
                    )}

                    <SpacerComponent height={250}/>
                </ScrollView>

                {ScreenSizeUtil.isWebSize() && (
                    <View>
                        <View style={{marginLeft: appS.margins.webBetweenMargin, width: appS.webSideContentsSize.width}}>
                            <WideScreenAdComponent navigation={this.props.navigation}/>
                            <WideScreenSNSComponent navigation={this.props.navigation}/>
                        </View>
                    </View>

                )}
            </View>
        );
    }

    /**
     * 商品ランキング
     */
    _renderProductRanking = () => {
        let _width = AppG.window.width;
        if (ScreenSizeUtil.isWebSize()) {
            _width = appS.webMainContentsSize.width;
        }
        _width = _width - appS.margins.side * 2;

        return (
            <View // TouchableOpacity
                // activeOpacity={1}
                style={[{flexDirection: ScreenSizeUtil.isWebSize()? 'row': 'column', alignSelf: 'center'}]}>
                <ScrollView style={{flexDirection: 'column'}}>
                    <TabBorderComponent
                        tabs={this._rTabs}
                        focusTabNumber={1}
                        unselectBorder={true}
                        button={true}
                    />
                    {/*週間レシピランキング(有料会員)*/}
                    {AppG.user != null && AppG.user.userPlan === UserUserPlanEnum.Premium && (
                        <TouchableOpacity activeOpacity={1}>
                            <ProductListRankingComponent
                                products={this.rankingProducts}
                                navigation={this.props.navigation}
                                analyticsBtnName={'【ホーム画面-ランキングタブ】週間商品ランキングボタン'}
                            />
                        </TouchableOpacity>
                    )}


                    {/*商品ランキングローディングインジケーター(有料会員)*/}
                    {AppG.user != null && AppG.user.userPlan === UserUserPlanEnum.Premium
                        && (
                            <View style={{flex: 1, height: appS.activityIndicator.height}}>
                                <ActivityIndicator
                                    animating={this.isLoadRankingProducts}
                                    color = {appColors.indicator}
                                    size = "large"
                                    style={[{flex: 1, marginTop: 48}]}/>
                            </View>
                        )}

                    {AppG.user != null && AppG.user.userPlan === UserUserPlanEnum.Premium
                        && !this.isLoadRankingProducts && ValidateUtil.isEmptyArray(this.rankingProducts) && (
                            <NoItemsIconComponent text={'商品が見つかりませんでした'}/>
                        )}

                    {/*週間レシピランキング(ログイン無し)*/}
                    {/*未ログイン*/}
                    {AppG.user == null && (
                        <View style={{flex: 1, backgroundColor: appColors.backBaseColor}}>
                            <NoUserImageComponent navigation={this.props.navigation} />
                        </View>
                    )}

                    <SpacerComponent height={250}/>
                </ScrollView>

                {ScreenSizeUtil.isWebSize() && (
                    <View>
                        <View style={{marginLeft: appS.margins.webBetweenMargin, width: appS.webSideContentsSize.width}}>
                            <WideScreenAdComponent navigation={this.props.navigation}/>
                            <WideScreenSNSComponent navigation={this.props.navigation}/>
                        </View>
                    </View>

                )}
            </View>
        );
    }

    /**
     * ランキングタブ
     */
    _renderRankingTab = () => {
        let _width = AppG.window.width;
        if (ScreenSizeUtil.isWebSize()) {
            _width = appS.webMainContentsSize.width;
        }

        return (
            <View style={[{flex: 1, flexDirection: 'column'}]}>

                <KeyboardAwareScrollView
                    ref={(rankingScrollTab: any) => { this._rankingScrollTab = rankingScrollTab; }}
                    style={[{flex: 1, flexDirection: 'column', width: AppG.window.width}]}
                    onScroll={(event: any) => {
                        // 無限スクロール
                        if (AppG.user != null && this._rTabIndex == 0 && AppG.user.userPlan === UserUserPlanEnum.Premium) {
                            // スクロールViewの高さ
                            const scrollViewHeight = event.nativeEvent.contentSize.height - event.nativeEvent.layoutMeasurement.height;
                            // スクロールView上の現在位置
                            const nowPositionHeight = event.nativeEvent.contentOffset.y;
                            this._rankingScrollTabY = nowPositionHeight;
                            // 現在の位置がスクロールView + activityIndicator の高さよりも下の場合
                            const indicatorHeight = Platform.OS == 'web'? -10: -appS.activityIndicator.height;
                            if (!this.isLoadRankingRecipes && this.rankingRecipes.length >= this._recipeRankingNumForTab - 1 && nowPositionHeight - scrollViewHeight >  indicatorHeight) {
                                this._loadRankingRecipes(this.rankingRecipes.length, this.rankingRecipes.length + this._recipeRankingNumForTab);
                            }
                        }

                        if (AppG.user != null && this._rTabIndex == 1 && AppG.user.userPlan === UserUserPlanEnum.Premium) {
                            // スクロールViewの高さ
                            const scrollViewHeight = event.nativeEvent.contentSize.height - event.nativeEvent.layoutMeasurement.height;
                            // スクロールView上の現在位置
                            const nowPositionHeight = event.nativeEvent.contentOffset.y;
                            this._rankingScrollTabY = nowPositionHeight;
                            // 現在の位置がスクロールView + activityIndicator の高さよりも下の場合
                            const indicatorHeight = Platform.OS == 'web'? -10: -appS.activityIndicator.height;
                            if (!this.isLoadRankingProducts && this.rankingProducts.length >= this._productRankingNumForTab - 1 && nowPositionHeight - scrollViewHeight >  indicatorHeight) {
                                this._loadRankingProduct(this.rankingProducts.length, this.rankingProducts.length + this._productRankingNumForTab);
                            }
                        }
                    }}
                >

                    {/*レシピタブ*/}
                    {this._rTabIndex == 0 && this._renderRecipeRanking()}

                    {/*商品タブ*/}
                    {this._rTabIndex == 1 && this._renderProductRanking()}


                </KeyboardAwareScrollView>
            </View>
        );
    }

    /**
     * タブページの中身
     * @param index
     */
        // @ts-ignore
    _renderPageItems = ({item}) => {
        let index: number = item;
        switch (index) {
            case 0:
                return this._renderTopTab();
            case 1:
                return this._renderRecipeTab();
            case 2:
                return this._renderProductCategoryTab();
            case 3:
                return this._renderMakerTab();
            case 4:
                return this._renderRankingTab();
            default:
                return this._renderTopTab();
        }
    }

    /**
     * SPサイズ
     */
    _renderSmall = () => {
        const {navigation} = this.props;
        return (
            <SafeAreaView
                style={[{height: AppG.window.height, flex: 1, flexDirection: 'column', backgroundColor: appColors.white}]}
                onLayout={(event) => {}}
            >
                <CustomHeaderComponentSmall
                    navigation={navigation}
                    leftButton={MyAppUtil.selectWebLogoSp()}
                    rightButton={MyAppUtil.selectWebMenuSp()}
                    title={'HOME'}
                    searchBar={true}
                    textColor={appColors.black}
                    barColor={appColors.white}
                    borderBottomColor={appColors.transparent}
                    statusBarType={"dark-content"}
                    onSearchSubmit={(text: string) => {
                        // 検索
                        if (!ValidateUtil.isEmptyExact(text)) {
                            // 検索履歴に追加
                            SearchDataDao.addNew(text);

                            let searchScreenModel: SearchScreenModel = new SearchScreenModel();
                            searchScreenModel.searchType = 'all';
                            searchScreenModel.searchValue = text;

                            if (Platform.OS == 'web') {
                                navigation.navigate('SearchResultScreen', searchScreenModel);
                            } else {
                                navigation.push('SearchResultScreen', searchScreenModel);
                            }
                        }
                    }}
                />

                <View style={{flex: 1, backgroundColor: appColors.backBaseColor}}>
                    {/*スマホタブ*/}
                    <TabBorderComponent
                        ref={(tabRef: any) => { this._tabRef = tabRef; }}
                        focusTabNumber={0}
                        tabs={this._tabs}
                    />

                    <Carousel
                        ref={(pageRef: any) => { this._tabPage = pageRef; }}
                        enabled={Platform.OS == 'ios'}
                        data={[0, 1, 2, 3, 4]}
                        renderItem={this._renderPageItems}
                        onSnapToItem={(index: number) => {
                            this._tabPress(index);
                        }}
                        width={AppG.window.width}
                        autoPlay={false}
                        loop={false}
                        style={{height: appS.screenSize.fullHeight}}
                    />

                </View>
                {/*サンプル依頼とは？*/}
                <WhatIsSampleRequestModalComponent
                    ref={(whatIsSampleRequestModalRef: any) => { this._whatIsSampleRequestModalRef = whatIsSampleRequestModalRef; }}
                    navigation={this.props.navigation}
                />
                {/*新着メッセージがあります*/}
                <ReceivedNewMessageModalComponent
                    ref={(receivedNewMessageModalRef: any) => { this._receivedNewMessageModalRef = receivedNewMessageModalRef; }}
                    navigation={this.props.navigation}
                />
            </SafeAreaView>
        );
    }

    /**
     * WEBサイズ
     */
    _renderWide = () => {
        const {navigation} = this.props;

        return (
            <SafeAreaView
                style={[{height: AppG.window.height, flex: 1, flexDirection: 'column', backgroundColor: appColors.white}]}
                onLayout={(event) => {}}
            >
                <CustomHeaderComponentWide
                    navigation={navigation}
                    searchBar={true}
                    onTabClicked={() => {
                        this._tabPress(AppG.tabIndex);
                    }}
                    isLoadingLogin={!this.state.isLoaded}
                />

                <View style={{
                    flex: 1,
                    flexDirection: 'row',
                    alignSelf: 'center',
                    backgroundColor: appColors.backBaseColor,
                }}>
                    <View style={[{flex: 1, flexDirection: 'column'}]}>
                        <ScrollView
                            horizontal  // 下階層のスクロールイベントを拾えるようにしている
                            style={{width: AppG.window.width, height: AppG.window.height - appS.header.webHeight}}
                        >
                            {/*おすすめタブ*/}
                            {AppG.tabIndex == 0 && this._renderTopTab()}

                            {/*レシピタブ*/}
                            {AppG.tabIndex == 1 && this._renderRecipeTab()}

                            {/*商品タブ*/}
                            {AppG.tabIndex == 2 && this._renderProductCategoryTab()}

                            {/*メーカー別タブ*/}
                            {AppG.tabIndex == 3 && this._renderMakerTab()}

                            {/*ランキングタブ*/}
                            {AppG.tabIndex == 4 && this._renderRankingTab()}
                        </ScrollView>
                    </View>
                </View>
                {/*サンプル依頼とは？*/}
                <WhatIsSampleRequestModalComponent
                    ref={(whatIsSampleRequestModalRef: any) => { this._whatIsSampleRequestModalRef = whatIsSampleRequestModalRef; }}
                    navigation={this.props.navigation}
                />
                {/*新着メッセージがあります*/}
                <ReceivedNewMessageModalComponent
                    ref={(receivedNewMessageModalRef: any) => { this._receivedNewMessageModalRef = receivedNewMessageModalRef; }}
                    navigation={this.props.navigation}
                />
            </SafeAreaView>
        );
    }

    /** 描画 */
    render = () => {
        ExpoUtil.setShouldReloadAppTab('all');

        // if (Platform.OS == 'android' && !this.state.isLoaded) {
        if (!this.state.isLoaded) {
            return (
                <View style={{flex: 1}}>
                    <IndicatorComponent
                        failTime={60}
                        isLoading={true}
                        reloadFunc={() => {
                            this.componentWillUnmount();
                            this.componentDidMount();
                        }}/>
                </View>
            );
        }

        if (Platform.OS == 'web') {
            // @ts-ignore
            $('a').attr('onclick', 'return false;');
        }

        if (ScreenSizeUtil.isWebSize()) {
            // WEBサイズ
            return this._renderWide();
        } else {
            // SPサイズ
            return this._renderSmall();
        }

    }


}
