import {BaseDao} from "./BaseDao";
// @ts-ignore
import {log} from '../../../../../environment';
import {ProductReviewReminderModalDataEntityModel} from "../../entityModels/ProductReviewReminderModalDataEntityModel";

class ProductReviewReminderModalDataDao extends BaseDao<ProductReviewReminderModalDataEntityModel> {
    _getModel(): ProductReviewReminderModalDataEntityModel {
        return new ProductReviewReminderModalDataEntityModel();
    }
}

// new 付なのでシングルトン
export default new ProductReviewReminderModalDataDao();
