export class SearchScreenModel {
    /** レシピと商品どちらを検索するか */
    searchType: 'recipe' | 'product' | 'all' | null = null;

    /** true でタブを非表示 */
    tabHidden: boolean | null = null;

    /** 検索文字列 */
    searchValue: string | null = null;

    /** 遷移後のヘッダータイトル */
    headerTitle: string | null = null;

    /** 遷移前の画面名 */
    screenNameFrom: string | null = null;

    /** 検索するメニューカテゴリーID */
    menuCategoryId: string | null = null;

    /** 検索するメニューカテゴリー名 */
    menuCategoryName: string | null = null;

    /** 検索する商品カテゴリーID（大分類） */
    productLargeCategoryId: string | null = null;

    /** 検索する商品カテゴリーID（中分類） */
    productMediumCategoryId: string | null = null;

    /** 検索する商品カテゴリー名（大分類） */
    productLargeCategoryName: string | null = null;

    /** 検索する商品カテゴリー名（中分類） */
    productMediumCategoryName: string | null = null;

    /** 検索するメーカーID*/
    makerId: string | null = null;

    /** 検索するキーワード*/
    keyword: string | null = null;
    /** 原価From */
    costFrom: string | null = null;
    /** 原価To */
    costTo: string | null = null;
    /** 商品ID */
    productId: string | null = null;



    /** 業態（大）IDs 「|」区切り、空の時は「||」 */
    recipeRestaurantLargeFormatIds: string | null = null;
    /** 季節IDs 「|」区切り、空の時は「||」 */
    seasonIds: string | null = null;
    /** レシピ（メニュー）カテゴリIDs 「|」区切り、空の時は「||」 */
    recipeCategoryIds: string | null = null;

    /** メーカーカテゴリIDs 「|」区切り、空の時は「||」 */
    makerIds: string | null = null;
    /** IDs 「|」区切り、空の時は「||」 */
    productLargeCategoryIds: string | null = null;
    /** レシピソートタイプ（おすすめ順とおすすめレシピは別物） */
    recipeSortType: 'recommend' | 'new' | 'pv' | null = null;
    /** 商品ソートタイプ（おすすめ順とおすすめレシピは別物） */
    productSortType: 'recommend' | 'new' | 'pv' | 'update' | null = null;
    /** 調理時間 */
    cookingTime: string | null = null;
}
