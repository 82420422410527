import React from 'react'
import {Dimensions, Image, Platform, SafeAreaView, Text, TextInput, TouchableOpacity, View} from 'react-native'
import {appColors, appFont, appS} from '../../resources/styles/style'
import {BaseScreen} from "./BaseScreen";
import LoginUtil from "../util/LoginUtil";
// @ts-ignore
import isEmpty from 'validator/lib/isEmpty';
// @ts-ignore
import isEmail from 'validator/lib/isEmail';
// @ts-ignore
import isMultibyte from 'validator/lib/isMultibyte';
import {SpacerComponent} from "../components/SpacerComponent";
import {LinkComponent} from "../components/LinkComponent";
import NavigationUtil from "../util/NavigationUtil";
import I18n from '../../resources/language/i18n';
import {IndicatorComponent} from "../components/IndicatorComponent";
// @ts-ignore
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scrollview';
// @ts-ignore
import {getEnvVars, log} from '../../../environment';
import LinkUtil from "../util/LinkUtil";
import ValidateUtil from "../util/ValidateUtil";
import {ValidationErrorMessageComponent} from "../components/ValidattionErrorMessageComponent";
import {CustomHeaderComponentSmall} from "../components/small/CustomHeaderComponentSmall";
import {User, UserApiFactory} from "../data/network/swagger-gen";
import AppGlobalDataUtil from "../util/AppG";
import AppG from "../util/AppG";
import {AxiosResponse} from "axios";
import ExpoUtil from "../util/ExpoUtil";
import ScreenSizeUtil from "../util/ScreenSizeUtil";
import {CustomHeaderComponentWide} from "../components/wide/CustomHeaderComponentWide";
import AnalyticsUtil, {AnalyticsEventName, DOpenEventName} from "../util/firebase/AnalyticsUtil";
import {LoadingFullPageComponent} from "../components/LoadingFullPageComponent";

interface Props {
    navigation: any, // ナビゲーション用
}

interface State {
    resizeWindow: boolean,  // 値に意味はなし。windowサイズの拡大縮小をrenderに送るためのもの
    isLoaded: boolean,
    isLoginTapped: boolean,


    // コンテンツ
    isShowPassword: boolean,

    // input
    // ログイン用メールアドレス
    mail: {
        text: string,
        errorText: string,
        isError: boolean,
    },
    // パスワード
    password: {
        text: string,
        errorText: string,
        isError: boolean,
    },

}

export class LoginScreen extends BaseScreen<Props, State> {
    _resizeTimer = setInterval(() => {
        // window 拡大縮小を反映させる
        AppG.window = Dimensions.get('window');
        if (AppG.window.width != AppG.windowBefore.width || AppG.window.height != AppG.windowBefore.height) {
            this.setState({'resizeWindow': true});
            AppG.windowBefore = Dimensions.get('window');
        }
        LoginUtil.setRefreshedScreenFlg(false);
    }, 100);

    _flameSize = appS.flame.size;    // 左右の margin
    _marginSize = ScreenSizeUtil.isWebSize()? 0 : appS.margin.size;

    // @ts-ignore
    _env: {
        REACT_APP_PRIVACY_URL: string,
        REACT_APP_TERMS_OF_SERVICE_URL: string,
    } = getEnvVars();

    _browserBackListener = (e: any) => {

    };

    constructor(props: any) {
        super(props);
        this.state = {
            resizeWindow: true,  // 値に意味はなし。windowサイズの拡大縮小をrenderに送るためのもの
            isLoaded: false,
            isLoginTapped: false,
            isShowPassword: false,
            mail: {
                text: '',
                errorText: '',
                isError: false,
            },
            password: {
                text: '',
                errorText: '',
                isError: false,
            },
        };
    }

    /**
     * 画面開いたときのアナリティクス送信
     */
    _sendPvAnalytics = () => {
        AnalyticsUtil.sendAnalytics(
            DOpenEventName.openPage,
            AnalyticsEventName.openPage,  // アナリティクスのイベント名
            'LoginScreen',
            null,
            null,
            null,
            null,
        );
    }

    componentDidMount() {
        if (Platform.OS == 'web') {
            window.addEventListener('popstate', this._browserBackListener);
        }

        // Web 戻るでも呼ばれる
        if (Platform.OS == 'web') {
            // Analytics
            this._sendPvAnalytics();
        }

        // URL Scheme で外部リンクから起動したときの処理
        LoginUtil.setDefaultListener(
            this.props.navigation,
            () => {
                // Webはここにこない
                if (Platform.OS != 'web') {
                    // Analytics
                    this._sendPvAnalytics();
                }
                ExpoUtil.doReloadAppTab(() => {

                });
            });

        this.setState({mail: {text: '', errorText: '', isError: false}});
        this.setState({password: {text: '', errorText: '', isError: false}});

        this.setState({isLoaded: true});
    }

    componentWillUnmount () {
        clearTimeout(this._resizeTimer);
        if (Platform.OS === 'web') {
            window.removeEventListener('popstate', this._browserBackListener);
        }
        LoginUtil.removeDefaultListener(this.props.navigation);
    };

    componentDidUpdate () {
        // レンダー完了時に呼ばれる
    }

    /**
     * ログイン処理
     * @param mail  メールアドレス
     * @param password  パスワード
     * @private
     */
    _sendLogin = (mail: string, password: string) => {
        let hasError: boolean = false;
        // Mail
        if(ValidateUtil.isEmptyExact(mail)) {
            this.setState({mail: {text: this.state.mail.text, errorText: `${I18n.t('pleasEnterAnItem', {item: `${I18n.t('mail')}`})}`, isError: true}});
            hasError = true;
        }
        if(!isEmail(mail)) {
            this.setState({mail: {text: this.state.mail.text, errorText: `${I18n.t('errorMail')}`, isError: true}});
            hasError = true;
        }
        // Password
        if (ValidateUtil.isEmptyExact(password)) {
            this.setState({password: {text: this.state.password.text, errorText: `${I18n.t('pleasEnterAnItem', {item: `${I18n.t('password')}`})}`, isError: true}});
            hasError = true;
        }
        if (isMultibyte(password) || !ValidateUtil.isPassword(password)) {
            this.setState({password: {text: this.state.password.text, errorText: `${I18n.t('incorrectPassword')}`, isError: true}});
            hasError = true;
        }

        if (hasError) {
            // エラー
            return;
        }

        this.setState({isLoginTapped: true});

        const successFunc = (data: any) => {
            // ユーザー
            UserApiFactory(AppGlobalDataUtil.getConfiguration(), AppGlobalDataUtil.getBasePath())
                .getUser().then((userRes: AxiosResponse<User>) => {
                if (userRes != null && userRes.data != null) {
                    if (ValidateUtil.isEmptyExact(userRes.data.name)) {
                        this.setState({isLoginTapped: false});
                        // 名前がないなら初回登録画面へ
                        this.props.navigation.navigate('ProfileInit1Screen');
                        return;
                    }
                }
                this.setState({isLoginTapped: false});
                // 名前があるなら Home 画面へ
                ExpoUtil.setShouldReloadAppTab('all');   // 全タブリロード対象
                NavigationUtil.homeRefresh();
                this.props.navigation.navigate('HomeScreen');
            });
        };

        const failFunc = () => {
            this.state.password.isError = true;
            this.state.password.errorText= 'Mailアドレスまたはパスワードが違います';
            this.setState({password: this.state.password});
            this.setState({isLoginTapped: false});
        };

        LoginUtil.login(mail, password, this.props.navigation, successFunc, failFunc).then(() => {});
    };

    /**
     * パスワード変更リンク
     */
    _renderForgotPassword = () =>{
        return (
            <View style={[{flexDirection: "row", alignSelf: ScreenSizeUtil.isWebSize()? 'center' : "flex-end", marginRight: this._marginSize}]}>
                <Text style={[{fontFamily: appFont.family.default, fontSize: appFont.size.link, color: appColors.fontGray}]}>パスワードをお忘れの方は</Text>
                {/*パスワード再送信*/}
                <View style={{marginTop: Platform.OS == 'android'? 9 : 0}}>
                    <LinkComponent
                        onPress={() => {
                            // パスワード変更画面
                            this.props.navigation.navigate('ResetPasswordScreen');
                        }}
                        fontSize={appFont.size.link}
                        title={`こちら`}
                    />
                </View>
            </View>
        );
    }

    /**
     * 利用規約とプライバシーポリシー
     */
    _renderPrivacyPolicy = () => {
        return (
            <View style={[{flexDirection: 'column'}]}>
                <View style={[{flexDirection: "row", alignSelf: "center"}]}>
                    <Text style={[{fontFamily: appFont.family.default, fontSize: appFont.size.link, color: appColors.fontGray}]}>ログインすることで、本サービスの</Text>
                </View>
                <SpacerComponent height={appS.spacer.height.row}/>
                <View style={[{flexDirection: "row", alignSelf: "center"}]}>
                    {/*利用規約*/}
                    <View style={{marginTop: Platform.OS == 'android'? 9 : 0}}>
                        <LinkComponent
                            onPress={() => {
                                LinkUtil.openUrlInWebView(this._env.REACT_APP_TERMS_OF_SERVICE_URL, this.props.navigation, '利用規約');
                            }}
                            fontSize={appFont.size.link}
                            title={`利用規約`}
                        />
                    </View>
                    <Text style={[{fontFamily: appFont.family.default, fontSize: appFont.size.link, color: appColors.fontGray}]}>と</Text>
                    {/*プライバシーポリシー*/}
                    <View style={{marginTop: Platform.OS == 'android'? 9 : 0}}>
                        <LinkComponent
                            onPress={() => {
                                LinkUtil.openUrlInWebView(this._env.REACT_APP_PRIVACY_URL, this.props.navigation, 'プライバシーポリシー');
                            }}
                            fontSize={appFont.size.link}
                            title={`プライバシーポリシー`}
                        />
                    </View>
                    <Text style={[{fontFamily: appFont.family.default, fontSize: appFont.size.link, color: appColors.fontGray}]}>に同意したことになります。</Text>
                </View>
            </View>
        );
    }

    /**
     * メイン部分
     */
    _renderMain = () => {
        const {navigation} = this.props;
        return (
            <View style={[{flex: 1, flexDirection: 'column'}]}>
                {Platform.OS == 'web' && (
                    <View style={{alignSelf: 'center', marginTop: 8}}>
                        <Text style={{fontFamily: appFont.family.bold, fontSize: 24, margin: 24, textAlign: 'center', color: appColors.fontBlack, fontWeight: "bold"}} numberOfLines={1}>ログイン</Text>
                    </View>
                )}
                {/*メルアドログイン*/}
                <View style={[{flex: 1}]}>
                    <SpacerComponent height={this._marginSize}/>
                    {/*メールアドレス*/}
                    <View style={[appS.marginCenter]}>
                        <TextInput
                            style={[appS.inputText, {}]}
                            autoCapitalize={"none"}
                            placeholder={'　メールアドレス'}
                            placeholderTextColor={appColors.fontGray}
                            value={this.state.mail.text}
                            onChangeText={text => this.setState({mail: {text: text, errorText: this.state.mail.errorText, isError: this.state.mail.isError}})}
                        />
                        <ValidationErrorMessageComponent
                            text={this.state.mail.errorText}
                            isError={this.state.mail.isError}
                        />
                    </View>
                    <SpacerComponent height={appS.spacer.height.small}/>
                    {/*パスワード*/}
                    <View style={[appS.marginCenter]}>
                        <TextInput
                            style={[appS.inputText, {}]}
                            secureTextEntry={!this.state.isShowPassword}
                            autoCapitalize={"none"}
                            placeholder={'　パスワード（半角英数字と長さ6文字以上）'}
                            placeholderTextColor={appColors.fontGray}
                            value={this.state.password.text}
                            onChangeText={text => this.setState({password: {text: text, errorText: this.state.password.errorText, isError: this.state.password.isError}})}
                        />
                        <TouchableOpacity
                            style={[{
                                alignSelf: "flex-end",
                                position: "relative",
                                top: -32,
                                left: -8,
                                zIndex: 1,
                                marginBottom: -32,
                            }]}
                            onPress={() => {
                                this.setState({isShowPassword: !this.state.isShowPassword});
                            }}
                        >
                            {this.state.isShowPassword && (
                                <Image source={require('../../resources/images/eye_hide_icon.png')}
                                       style={[{
                                           height: 24,
                                           width: 24,
                                       }]}
                                       resizeMode={'cover'}
                                />
                            )}
                            {!this.state.isShowPassword && (
                                <Image source={require('../../resources/images/eye_show_icon.png')}
                                       style={[{
                                           height: 24,
                                           width: 24,
                                       }]}
                                       resizeMode={'cover'}
                                />
                            )}
                        </TouchableOpacity>
                        <ValidationErrorMessageComponent
                            text={this.state.password.errorText}
                            isError={this.state.password.isError}
                        />
                    </View>
                    <SpacerComponent height={appS.spacer.height.small*2}/>

                    {/*パスワード変更リンク*/}
                    {!ScreenSizeUtil.isWebSize() && this._renderForgotPassword()}
                    {/*利用規約とプライバシーポリシー*/}
                    {ScreenSizeUtil.isWebSize() && this._renderPrivacyPolicy()}

                    <SpacerComponent height={appS.spacer.height.small*2}/>

                    {/*ログインボタン*/}
                    <TouchableOpacity
                        style={[
                            {
                                height: appS.buttonSize.height,
                                width: appS.buttonSize.width,
                                backgroundColor:  (ValidateUtil.isEmptyExact(this.state.mail.text) || ValidateUtil.isEmptyExact(this.state.password.text)) ? appColors.gray : appColors.buttonOrange,
                                alignItems: 'center',
                                justifyContent: 'center',
                                alignSelf: 'center',
                            }
                        ]}
                        disabled={(ValidateUtil.isEmptyExact(this.state.mail.text) || ValidateUtil.isEmptyExact(this.state.password.text))}
                        onPress={() => {
                            this._sendLogin(this.state.mail.text, this.state.password.text);
                        }}
                    >
                        <Text style={{
                            fontFamily: appFont.family.bold,
                            color: appColors.white,
                            fontWeight: 'bold',
                            fontSize: appFont.size.buttonText
                        }}>ログイン</Text>
                    </TouchableOpacity>

                    <SpacerComponent height={appS.spacer.height.small*2}/>

                    {/*パスワード変更リンク*/}
                    {ScreenSizeUtil.isWebSize() && this._renderForgotPassword()}
                    {/*利用規約とプライバシーポリシー*/}
                    {!ScreenSizeUtil.isWebSize() && this._renderPrivacyPolicy()}

                </View>

                <SpacerComponent height={appS.spacer.height.small * 4}/>

                {/*<View style={[{flexDirection: "row", alignSelf: "center"}]}>*/}
                {/*    <Image source={require('../../resources/images/line_gray.png')}*/}
                {/*           style={[{*/}
                {/*               backgroundColor: 'white',*/}
                {/*               flex: 1,*/}
                {/*               height: 1,*/}
                {/*               marginLeft: this._flameSize,*/}
                {/*               marginRight: this._flameSize,*/}
                {/*               marginTop: 4,*/}
                {/*           }]}*/}
                {/*           resizeMode={'cover'}*/}
                {/*    />*/}
                {/*    <Text style={[{fontFamily: appFont.family.default, fontSize: appFont.size.link, color: appColors.gray}]}>または</Text>*/}
                {/*    <Image source={require('../../resources/images/line_gray.png')}*/}
                {/*           style={[{*/}
                {/*               backgroundColor: 'white',*/}
                {/*               flex: 1,*/}
                {/*               height: 1,*/}
                {/*               marginLeft: this._flameSize,*/}
                {/*               marginRight: this._flameSize,*/}
                {/*               marginTop: 4,*/}
                {/*           }]}*/}
                {/*           resizeMode={'cover'}*/}
                {/*    />*/}
                {/*</View>*/}

                {/*{!ScreenSizeUtil.isWebSize() && (<SpacerComponent height={appStyles.spacer.height.small * 4}/>)}*/}
                {/*{ScreenSizeUtil.isWebSize() && (<SpacerComponent height={appStyles.spacer.height.small * 2}/>)}*/}

                {/*/!*Appleでログイン*!/*/}
                {/*<TouchableOpacity*/}
                {/*    style={[*/}
                {/*        appStyles.marginCenter,*/}
                {/*        {*/}
                {/*            height: appStyles.button.height,*/}
                {/*            backgroundColor: appColors.apple,*/}
                {/*            alignItems: 'center',*/}
                {/*            justifyContent: 'center',*/}
                {/*        }*/}
                {/*    ]}*/}
                {/*    onPress={() => {*/}
                {/*        // TODO Appleでログイン*/}

                {/*    }}*/}
                {/*>*/}
                {/*    <Text style={{fontFamily: appFont.family.default, color: appColors.white, fontWeight: 'bold', fontSize: 14}}>Appleでログイン</Text>*/}
                {/*</TouchableOpacity>*/}
                {/*<Image source={require('../../resources/images/apple_icon.png')}*/}
                {/*       style={[{*/}
                {/*           height: 24,*/}
                {/*           width: 24,*/}
                {/*           position: "relative",*/}
                {/*           zIndex: 1,*/}
                {/*           marginTop: -36,*/}
                {/*           marginLeft: ScreenSizeUtil.isWebSize()? 36 : (this._flameSize * 2) + 4,*/}
                {/*       }]}*/}
                {/*       resizeMode={'cover'}*/}
                {/*/>*/}

                {/*<SpacerComponent height={appStyles.spacer.height.small * 3}/>*/}

                {/*/!*FACEBOOKでログイン*!/*/}
                {/*<TouchableOpacity*/}
                {/*    style={[*/}
                {/*        appStyles.marginCenter,*/}
                {/*        {*/}
                {/*            height: appStyles.button.height,*/}
                {/*            backgroundColor: appColors.facebook,*/}
                {/*            alignItems: 'center',*/}
                {/*            justifyContent: 'center',*/}
                {/*        }*/}
                {/*    ]}*/}
                {/*    onPress={() => {*/}
                {/*        // TODO FACEBOOKでログイン*/}

                {/*    }}*/}
                {/*>*/}
                {/*    <Text style={{fontFamily: appFont.family.default, color: appColors.white, fontWeight: 'bold', fontSize: 14}}>Facebookでログイン</Text>*/}
                {/*</TouchableOpacity>*/}
                {/*<Image source={require('../../resources/images/facebook_icon.png')}*/}
                {/*       style={[{*/}
                {/*           height: 24,*/}
                {/*           width: 24,*/}
                {/*           position: "relative",*/}
                {/*           zIndex: 1,*/}
                {/*           marginTop: -36,*/}
                {/*           marginLeft: ScreenSizeUtil.isWebSize()? 32 : this._flameSize * 2,*/}
                {/*       }]}*/}
                {/*       resizeMode={'cover'}*/}
                {/*/>*/}

                {/*<SpacerComponent height={appStyles.spacer.height.small * 3}/>*/}

                {/*/!*TWITTERでログイン*!/*/}
                {/*<TouchableOpacity*/}
                {/*    style={[*/}
                {/*        appStyles.marginCenter,*/}
                {/*        {*/}
                {/*            height: appStyles.button.height,*/}
                {/*            backgroundColor: appColors.twitter,*/}
                {/*            alignItems: 'center',*/}
                {/*            justifyContent: 'center',*/}
                {/*        }*/}
                {/*    ]}*/}
                {/*    onPress={() => {*/}
                {/*        // TODO TWITTERでログイン*/}

                {/*    }}*/}
                {/*>*/}
                {/*    <Text style={{fontFamily: appFont.family.default, color: appColors.white, fontWeight: 'bold', fontSize: 14}}>Twitterでログイン</Text>*/}
                {/*</TouchableOpacity>*/}
                {/*<Image source={require('../../resources/images/twitter_icon.png')}*/}
                {/*       style={[{*/}
                {/*           height: 24,*/}
                {/*           width: 24,*/}
                {/*           position: "relative",*/}
                {/*           zIndex: 1,*/}
                {/*           marginTop: -36,*/}
                {/*           marginLeft: ScreenSizeUtil.isWebSize()? 32 : this._flameSize * 2,*/}
                {/*       }]}*/}
                {/*       resizeMode={'cover'}*/}
                {/*/>*/}

                {/*<SpacerComponent height={appStyles.spacer.height.small * 3}/>*/}

                {/*/!*GOOGLEでログイン*!/*/}
                {/*<TouchableOpacity*/}
                {/*    style={[*/}
                {/*        appStyles.marginCenter,*/}
                {/*        {*/}
                {/*            height: appStyles.button.height,*/}
                {/*            backgroundColor: appColors.google,*/}
                {/*            alignItems: 'center',*/}
                {/*            justifyContent: 'center',*/}
                {/*        }*/}
                {/*    ]}*/}
                {/*    onPress={() => {*/}
                {/*        // TODO GOOGLEでログイン*/}

                {/*    }}*/}
                {/*>*/}
                {/*    <Text style={{fontFamily: appFont.family.default, color: appColors.white, fontWeight: 'bold', fontSize: 14}}>Googleでログイン</Text>*/}
                {/*</TouchableOpacity>*/}
                {/*<Image source={require('../../resources/images/google_icon.png')}*/}
                {/*       style={[{*/}
                {/*           height: 24,*/}
                {/*           width: 24,*/}
                {/*           position: "relative",*/}
                {/*           zIndex: 1,*/}
                {/*           marginTop: -36,*/}
                {/*           marginLeft: ScreenSizeUtil.isWebSize()? 32 : this._flameSize * 2,*/}
                {/*       }]}*/}
                {/*       resizeMode={'cover'}*/}
                {/*/>*/}

                {/*<SpacerComponent height={appStyles.spacer.height.small*4}/>*/}

                <View style={[{flexDirection: "row", alignSelf: ScreenSizeUtil.isWebSize()? 'center':"flex-start", marginLeft: this._marginSize}]}>
                    <Text style={[{fontFamily: appFont.family.default, fontSize: appFont.size.link, color: appColors.fontGray}]}>アカウントをお持ちでない方は</Text>
                </View>

                <SpacerComponent height={appS.spacer.height.small * 2}/>

                {/*新規登録ボタン*/}
                <TouchableOpacity
                    style={[
                        appS.marginCenter,
                        {
                            height: appS.buttonSize.height,
                            width: appS.buttonSize.width,
                            backgroundColor: appColors.buttonOrange,
                            alignItems: 'center',
                            justifyContent: 'center',
                            alignSelf: 'center',
                        }
                    ]}
                    onPress={() => {
                        // 新規登録画面へ
                        navigation.navigate('SignUpScreen');
                    }}
                >
                    <Text style={{
                        fontFamily: appFont.family.default,
                        color: appColors.white,
                        fontWeight: 'bold',
                        fontSize: appFont.size.buttonText
                    }}>新規登録</Text>
                </TouchableOpacity>

                <SpacerComponent height={appS.footer.height}/>

            </View>
        );
    }

    _renderWide = () => {
        return (
            <SafeAreaView
                style={[{height: AppG.window.height, flex: 1, flexDirection: 'column', backgroundColor: appColors.white}]}
                onLayout={(event) => {}}
            >
                <CustomHeaderComponentWide logoOnly={true} navigation={this.props.navigation}/>

                <View style={{flex: 1, flexDirection: 'row'}}>
                    <KeyboardAwareScrollView style={[{flex: 1, flexDirection: 'column'}]}>
                        <View style={[{flex: 1, width: 700, alignSelf: 'center'}]}>
                            {this._renderMain()}
                        </View>
                    </KeyboardAwareScrollView>

                    {/*閉じるボタン*/}
                    <View style={{flexDirection: "row", marginTop: 32, marginRight: 32}}>
                        <View style={{flex: 1}}/>
                        <TouchableOpacity
                            onPress={() => {
                                // ログインしない
                                ExpoUtil.setShouldReloadAppTab('all');   // 全タブリロード対象
                                // NavigationUtil.homeRefresh();
                                // this.props.navigation.navigate('HomeScreen');
                                ExpoUtil.goBack(this.props.navigation);
                            }}
                        >
                            <Image source={require('../../resources/images/close_icon.png')}
                                   style={[{
                                       backgroundColor: 'white',
                                       width: 24,
                                       height: 24,
                                   }]}
                                   resizeMode={'cover'}
                            />
                        </TouchableOpacity>
                    </View>
                </View>

                {this.state.isLoginTapped && (
                    <LoadingFullPageComponent/>
                )}
            </SafeAreaView>
        );
    }

    _renderSmall = () => {
        return (
            <SafeAreaView
                style={[{flex: 1, backgroundColor: appColors.white}]}
                onLayout={(event) => {}}
            >
                <CustomHeaderComponentSmall
                    navigation={this.props.navigation}
                    title={'ログイン'}
                    textColor={appColors.fontBlack}
                    barColor={appColors.white}
                    leftButton={'none'}
                    rightButton={(
                        <View style={{flexDirection: "row", marginTop: 8, marginRight: 32}}>
                            <View style={{flex: 1}}/>
                            <TouchableOpacity
                                onPress={() => {
                                    // ログインしない
                                    ExpoUtil.setShouldReloadAppTab('all');   // 全タブリロード対象
                                    NavigationUtil.homeRefresh();
                                    this.props.navigation.navigate('HomeScreen');
                                }}
                            >
                                <Image source={require('../../resources/images/close_icon.png')}
                                       style={[{
                                           backgroundColor: 'white',
                                           width: 24,
                                           height: 24,
                                       }]}
                                       resizeMode={'cover'}
                                />
                            </TouchableOpacity>
                        </View>
                    )}

                />

                <KeyboardAwareScrollView style={[{flex: 1, flexDirection: 'column', backgroundColor: appColors.backBaseColor}]}>
                    {this._renderMain()}
                </KeyboardAwareScrollView>

                {this.state.isLoginTapped && (
                    <LoadingFullPageComponent/>
                )}
            </SafeAreaView>
        );
    }

    /** 描画 */
    render() {
        if (!this.state.isLoaded) {
            return (
                <View style={{flex: 1}}>
                    <IndicatorComponent
                        failTime={60}
                        isLoading={true}
                        reloadFunc={() => {
                            this.componentWillUnmount();
                            this.componentDidMount();
                        }}/>
                </View>
            );
        }

        LinkUtil.addReturnFalseToATag();
        if (ScreenSizeUtil.isWebSize()) {
            // WEBサイズ
            return this._renderWide();
        } else {
            // SPサイズ
            return this._renderSmall();
        }

    };


}
