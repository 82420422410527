export class TutorialDataEntityModel {
    /**
     * 0, 1 => 1 ならチュートリアルを表示しない
     */
    hide_flg_tutorial: string | undefined = undefined;

    /**
     * 最終表示時
     */
    last_show_date_tutorial: string | undefined = undefined;
}
