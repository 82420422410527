import React, {Component} from 'react';
import {Image, Platform,} from 'react-native';

interface Props {
    top: number,
    right: number,
}

interface State { }

export class PullDownIconComponent extends Component<Props, State> {

    // Propsのデフォルト値
    static defaultProps = {
        top: 20,
        right: 16,
    };

    render() {
        if (Platform.OS == 'android') {
            return null;
        }
        return (
            <Image style={{position: "relative", right: this.props.right, top: this.props.top}} source={require('../../resources/images/pull_down_icon.png')}/>
        );
    }
}
