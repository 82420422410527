import React, {Component} from 'react';
import {Platform, Text, View} from 'react-native';
import {appColors} from "../../resources/styles/style";

interface Props {
    isShow: boolean,
    isWrittenNumber: boolean,
    badgeCount: number | null,
    iconSize: number,
    multipleSize: number,
}

interface State { }

export class IconWithBadgeComponent extends Component<Props, State> {

    // Propsのデフォルト値
    static defaultProps = {
        isWrittenNumber: false,
        isShow: false,
        multipleSize: 1,
    }

    _getWidth = () => {
        const {badgeCount} = this.props;
        if (badgeCount == null) {
            return 10;
        }
        if (badgeCount < 10) {
            return 10;
        }
        if (badgeCount < 100) {
            return 16;
        }
        if (badgeCount < 1000) {
            return 20;
        }
        return 10;
    }

    render() {
        // const { name, badgeCount, color, size } = this.props;
        const {isShow, isWrittenNumber, badgeCount, iconSize, multipleSize} = this.props;
        return (
            <View>
                {badgeCount != null && badgeCount != 0 && (
                    <View
                        style={{
                            // /If you're using react-native < 0.57 overflow outside of the parent
                            // will not work on Android, see https://git.io/fhLJ8
                            position: 'relative',
                            right: -iconSize + 5,
                            top: -iconSize,
                            height: 10 * multipleSize,
                            width: this._getWidth() * multipleSize,
                            backgroundColor: isShow? appColors.red : appColors.transparent,
                            borderRadius: 50,
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginBottom: -iconSize,
                        }}>
                        {isShow && (
                            <Text style={{ color: appColors.white, fontSize: 8, fontWeight: 'bold', marginLeft: Platform.OS == 'web'? -2: 0}}>
                                {isWrittenNumber? ` ${badgeCount}` : ''}
                            </Text>
                        )}
                    </View>
                )}
                {(badgeCount == null || badgeCount == 0) && (
                    <View
                        style={{
                            // /If you're using react-native < 0.57 overflow outside of the parent
                            // will not work on Android, see https://git.io/fhLJ8
                            position: 'relative',
                            right: -iconSize + 5,
                            top: -iconSize,
                            height: 10 * multipleSize,
                            width: this._getWidth() * multipleSize,
                            backgroundColor: appColors.transparent,
                            borderRadius: 50,
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginBottom: -iconSize,
                        }}>
                            <Text style={{ color: appColors.transparent, fontSize: 8, fontWeight: 'bold' }}>
                                {''}
                            </Text>
                    </View>
                )}
            </View>
        );
    }
}
