import React from 'react'
import {Platform, SafeAreaView, Text, TouchableOpacity, View} from 'react-native'
import {appColors, appS} from '../../resources/styles/style'
import {BaseScreen} from "./BaseScreen";
// @ts-ignore
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scrollview';
// @ts-ignore
// @ts-ignore
import {getEnvVars, log} from '../../../environment';
import AppG from "../util/AppG";
import ScreenSizeUtil from "../util/ScreenSizeUtil";
import {SpacerComponent} from "../components/SpacerComponent";
import UrlUtil from "../util/UrlUtil";
import {TestScreenModel} from "../data/models/screen/TestScreenModel";
import LinkUtil from "../util/LinkUtil";
import {StatusBar} from "expo-status-bar";

interface Props {
    navigation: any, // ナビゲーション用
}

interface State {
    test: string,
    ttt: string,
    noPath: string,
}

export class TestScreen extends BaseScreen<Props, State> {
    // _flameSize = appS.flame.size;    // 左右の margin
    // _marginSize = ScreenSizeUtil.isWebSize()? 0 : appS.margin.size;

    // このScreenへ渡すパラム
    _param: TestScreenModel = new TestScreenModel();

    _browserBackListener = (e: any) => {

    };

    constructor(props: any) {
        super(props);
        this.state = {
            test: '',
            ttt: '',
            noPath: '',
        };
    }

    /**
     * 画面開いたときのアナリティクス送信
     */
    _sendPvAnalytics = () => {

    }

    componentDidMount() {
        this._param = this._getParams();
        this._loadContents();
    }

    componentWillUnmount () {
    };

    componentDidUpdate () {
        // レンダー完了時に呼ばれる
    }

    /**
     * パラムの取得
     * @private
     */
    _getParams = () => {
        let testScreenModel: TestScreenModel = UrlUtil.getParamModel(new TestScreenModel(), this.props.navigation);
        return testScreenModel;
    }

    /**
     * マスターのロード
     * @private
     */
    _loadMasters = async () => {
    };

    /**
     * コンテンツのロード
     * @private
     */
    _loadContents = async () => {
        // this.setState({test: this._param.test != null? this._param.test : ''});
        // this.setState({ttt: this._param.ttt != null? this._param.ttt : ''});
        // this.setState({noPath: this._param.noPath != null? this._param.noPath : ''});
        //
        // if (Platform.OS == 'web') {
        //     document.title = `${this._param.test}-ReciBASE(レシベース)`;
        //     let description = document.getElementsByName('description')[0];
        //     description.setAttribute("content", `Reci BASE（レシベース） ${this._param.ttt} - ${this._param.noPath}`);
        // }
    };

    render() {
        return (
            <View>
                <Text>test</Text>
                <StatusBar style="auto" />
            </View>
        );
    }


    // /** 描画 */
    // render() {
    //     LinkUtil.addReturnFalseToATag();
    //     const {navigation} = this.props;
    //     return (
    //         <SafeAreaView
    //             style={[{height: AppG.window.height, flex: 1, flexDirection: 'column', backgroundColor: appColors.white}]}
    //             onLayout={(event) => {}}
    //         >
    //             <View style={{marginLeft: this._flameSize, marginRight: this._flameSize, marginBottom: appS.line.space}}>
    //                 <SpacerComponent height={32}/>
    //
    //                 <Text>TestScreen path:test={navigation.getParam('test')} ttt={navigation.getParam('ttt')} noPath={navigation.getParam('noPath')}</Text>
    //                 <Text>TestScreen nav:test={this.state.test} ttt={this.state.ttt} noPath={this.state.noPath}</Text>
    //
    //                 {/*Testページ*/}
    //                 <TouchableOpacity
    //                     style={[
    //                         {
    //                             height: appS.button.height,
    //                             backgroundColor: appColors.black,
    //                             alignItems: 'center',
    //                             justifyContent: 'center',
    //                         }
    //                     ]}
    //                     onPress={() => {
    //                         this.props.navigation.goBack();
    //                     }}
    //                 >
    //                     <Text style={{color: appColors.white, fontWeight: 'bold', fontSize: appS.button.text}}>戻る</Text>
    //                 </TouchableOpacity>
    //             </View>
    //         </SafeAreaView>
    //     );
    //
    // };


}

