/* tslint:disable */
/* eslint-disable */
/**
 * Swagger Reci BASE
 * Bearer の token は firebase でログインチェック後にもらった ID Token を送信する。今回はこの token が userHash の役割もするため、POSTに userHash はないが、GET の userId を firebase の ID (Hash)で使うので、tokenからfirebaseに接続し、firebaseIDをuserHash としてサーバーで登録して貰う
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: apiteam@swagger.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import 'react-native-url-polyfill/auto';

import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface Chat
 */
export interface Chat {
    /**
     * チャットID(google firestore の ID と同じ)
     * @type {string}
     * @memberof Chat
     */
    'id': string;
    /**
     * メーカー担当者ID（ユーザーID）
     * @type {number}
     * @memberof Chat
     */
    'userId': number;
    /**
     * メーカーID
     * @type {number}
     * @memberof Chat
     */
    'makerId': number;
    /**
     * メーカー画像のURL
     * @type {string}
     * @memberof Chat
     */
    'makerImage'?: string;
    /**
     * メーカー名
     * @type {string}
     * @memberof Chat
     */
    'makerName': string;
    /**
     * 最新メッセージ（fileの場合は「画像が届きました」or「画像を送信しました」）
     * @type {string}
     * @memberof Chat
     */
    'latestMessage': string;
    /**
     * 最新メッセージ送信ユーザーがどっちか
     * @type {string}
     * @memberof Chat
     */
    'latestMessageUser': ChatLatestMessageUserEnum;
    /**
     * 最新メッセージ送信日付(yyyy-MM-dd)
     * @type {string}
     * @memberof Chat
     */
    'latestMessageDate': string;
    /**
     *
     * @type {number}
     * @memberof Chat
     */
    'isRead': number;
}

export const ChatLatestMessageUserEnum = {
    User: 'user',
    Maker: 'maker'
} as const;

export type ChatLatestMessageUserEnum = typeof ChatLatestMessageUserEnum[keyof typeof ChatLatestMessageUserEnum];

/**
 *
 * @export
 * @interface CheckFinishedQuestionnaire200Response
 */
export interface CheckFinishedQuestionnaire200Response {
    /**
     * 0/1、1なら回答済み
     * @type {number}
     * @memberof CheckFinishedQuestionnaire200Response
     */
    'finished': number;
}
/**
 *
 * @export
 * @interface CreateChat200Response
 */
export interface CreateChat200Response {
    /**
     * firebase の chat の hash 値 chat が既存だったときにも返す
     * @type {string}
     * @memberof CreateChat200Response
     */
    'chatId'?: string;
}
/**
 *
 * @export
 * @interface CustomerUnitPriceId
 */
export interface CustomerUnitPriceId {
    /**
     *
     * @type {number}
     * @memberof CustomerUnitPriceId
     */
    'id': number;
    /**
     *
     * @type {string}
     * @memberof CustomerUnitPriceId
     */
    'name': string;
}
/**
 *
 * @export
 * @interface Ec
 */
export interface Ec {
    /**
     * 購入先URL
     * @type {string}
     * @memberof Ec
     */
    'url'?: string;
    /**
     * 購入先の説明
     * @type {string}
     * @memberof Ec
     */
    'description'?: string;
    /**
     * ECサイト用画像URL
     * @type {string}
     * @memberof Ec
     */
    'shop_image'?: string;
    /**
     * 価格表示のラベル
     * @type {string}
     * @memberof Ec
     */
    'label'?: string;
    /**
     * 値段
     * @type {number}
     * @memberof Ec
     */
    'price'?: number;
    /**
     * 提供社(自社の場合は\'own\')
     * @type {string}
     * @memberof Ec
     */
    'provider'?: string;
    /**
     * クーポンコード（ない場合は空文字）
     * @type {Array<EcCouponCodeInner>}
     * @memberof Ec
     */
    'coupon_code'?: Array<EcCouponCodeInner>;
}
/**
 *
 * @export
 * @interface EcCouponCodeInner
 */
export interface EcCouponCodeInner {
    /**
     *
     * @type {string}
     * @memberof EcCouponCodeInner
     */
    'coupon_code'?: string;
    /**
     *
     * @type {string}
     * @memberof EcCouponCodeInner
     */
    'label'?: string;
}
/**
 *
 * @export
 * @interface Followed
 */
export interface Followed {
    /**
     * お気に入り登録済み1、していない0
     * @type {number}
     * @memberof Followed
     */
    'followed'?: number | null;
}
/**
 *
 * @export
 * @interface GetAmountOfUnreadNews200Response
 */
export interface GetAmountOfUnreadNews200Response {
    /**
     *
     * @type {number}
     * @memberof GetAmountOfUnreadNews200Response
     */
    'unread'?: number;
}
/**
 *
 * @export
 * @interface GetRecommendKeywordProduct200Response
 */
export interface GetRecommendKeywordProduct200Response {
    /**
     *
     * @type {string}
     * @memberof GetRecommendKeywordProduct200Response
     */
    'keyword'?: string;
    /**
     *
     * @type {Array<Product>}
     * @memberof GetRecommendKeywordProduct200Response
     */
    'products'?: Array<Product>;
}
/**
 *
 * @export
 * @interface GetRecommendKeywordRecipe200Response
 */
export interface GetRecommendKeywordRecipe200Response {
    /**
     *
     * @type {string}
     * @memberof GetRecommendKeywordRecipe200Response
     */
    'keyword'?: string;
    /**
     *
     * @type {Array<Recipe>}
     * @memberof GetRecommendKeywordRecipe200Response
     */
    'recipes'?: Array<Recipe>;
}
/**
 *
 * @export
 * @interface GetReferralCode200Response
 */
export interface GetReferralCode200Response {
    /**
     * このユーザーが発行する紹介コード
     * @type {string}
     * @memberof GetReferralCode200Response
     */
    'referralCode': string;
}
/**
 *
 * @export
 * @interface ImageFile
 */
export interface ImageFile {
    /**
     *
     * @type {string}
     * @memberof ImageFile
     */
    'url': string;
}
/**
 *
 * @export
 * @interface Keyword
 */
export interface Keyword {
    /**
     *
     * @type {string}
     * @memberof Keyword
     */
    'value': string;
    /**
     * キーワードのグルーピング。こちらでソートしてマスターを帰すこと
     * @type {string}
     * @memberof Keyword
     */
    'indexName'?: string;
}
/**
 *
 * @export
 * @interface LoginAccount200Response
 */
export interface LoginAccount200Response {
    /**
     * 0/1, メンテナンス中なら1。メンテナンス中ならログイン成功処理は無視
     * @type {number}
     * @memberof LoginAccount200Response
     */
    'maintenance': number;
}
/**
 *
 * @export
 * @interface MajorRestaurantAreaId
 */
export interface MajorRestaurantAreaId {
    /**
     *
     * @type {number}
     * @memberof MajorRestaurantAreaId
     */
    'id': number;
    /**
     *
     * @type {string}
     * @memberof MajorRestaurantAreaId
     */
    'name': string;
}
/**
 *
 * @export
 * @interface Maker
 */
export interface Maker {
    /**
     * PK
     * @type {number}
     * @memberof Maker
     */
    'id': number;
    /**
     *
     * @type {string}
     * @memberof Maker
     */
    'name': string;
    /**
     *
     * @type {string}
     * @memberof Maker
     */
    'nameKana': string;
    /**
     * URL
     * @type {string}
     * @memberof Maker
     */
    'logo': string;
    /**
     * 紹介文言
     * @type {string}
     * @memberof Maker
     */
    'description': string;
    /**
     * コーポレートサイトURL
     * @type {string}
     * @memberof Maker
     */
    'site'?: string;
    /**
     * レシピ数
     * @type {number}
     * @memberof Maker
     */
    'numOfRecipes': number;
    /**
     * 商品数
     * @type {number}
     * @memberof Maker
     */
    'numOfProduct': number;
    /**
     *
     * @type {Array<ProductCategory>}
     * @memberof Maker
     */
    'categoriesOfProduct'?: Array<ProductCategory>;
    /**
     * お気に入り登録済み1、していない0
     * @type {number}
     * @memberof Maker
     */
    'followed'?: number | null;
}
/**
 * お知らせ or レビュー・アンケートの催促
 * @export
 * @interface News
 */
export interface News {
    /**
     * お知らせID
     * @type {number}
     * @memberof News
     */
    'id'?: number;
    /**
     *
     * @type {string}
     * @memberof News
     */
    'title': string;
    /**
     * 本文(一覧取得時には空)
     * @type {string}
     * @memberof News
     */
    'text'?: string;
    /**
     * 0/1, 読んでたら1
     * @type {number}
     * @memberof News
     */
    'read': number;
    /**
     * アンケートID（アンケート有の場合）
     * @type {number}
     * @memberof News
     */
    'questionnaireId'?: number;
    /**
     * アンケートURL（アンケートの場合有）
     * @type {string}
     * @memberof News
     */
    'url'?: string;
    /**
     * 更新（作成）日時 yyyy-MM-dd hh:mm
     * @type {string}
     * @memberof News
     */
    'date': string;
}
/**
 *
 * @export
 * @interface NumOfRestaurantId
 */
export interface NumOfRestaurantId {
    /**
     *
     * @type {number}
     * @memberof NumOfRestaurantId
     */
    'id': number;
    /**
     *
     * @type {string}
     * @memberof NumOfRestaurantId
     */
    'name': string;
}
/**
 *
 * @export
 * @interface Popup
 */
export interface Popup {
    /**
     *
     * @type {number}
     * @memberof Popup
     */
    'id': number;
    /**
     *
     * @type {string}
     * @memberof Popup
     */
    'name': string;
    /**
     * 0:広告用ポップアップ, 1:画像なしのアンケートポップアップ,2:画像ありのアンケートポップアップ
     * @type {number}
     * @memberof Popup
     */
    'type_id'?: number;
    /**
     *
     * @type {Array<PopupContainerInner>}
     * @memberof Popup
     */
    'container': Array<PopupContainerInner>;
    /**
     *
     * @type {PopupQuestionnaires}
     * @memberof Popup
     */
    'questionnaires'?: PopupQuestionnaires;
}
/**
 *
 * @export
 * @interface PopupContainerInner
 */
export interface PopupContainerInner {
    /**
     * 無い場合は空文字
     * @type {string}
     * @memberof PopupContainerInner
     */
    'link'?: string;
    /**
     * 無い場合は空文字（画像は正方形にすること）
     * @type {string}
     * @memberof PopupContainerInner
     */
    'image'?: string;
}
/**
 *
 * @export
 * @interface PopupQuestionnaires
 */
export interface PopupQuestionnaires {
    /**
     *
     * @type {string}
     * @memberof PopupQuestionnaires
     */
    'image'?: string;
    /**
     *
     * @type {Array<PopupQuestionnairesQuestionInner>}
     * @memberof PopupQuestionnaires
     */
    'question'?: Array<PopupQuestionnairesQuestionInner>;
}
/**
 *
 * @export
 * @interface PopupQuestionnairesQuestionInner
 */
export interface PopupQuestionnairesQuestionInner {
    /**
     * アンケート項目のID
     * @type {number}
     * @memberof PopupQuestionnairesQuestionInner
     */
    'id'?: number;
    /**
     * アンケート項目の内容
     * @type {string}
     * @memberof PopupQuestionnairesQuestionInner
     */
    'question'?: string;
    /**
     * 質問項目の回答数
     * @type {number}
     * @memberof PopupQuestionnairesQuestionInner
     */
    'answer_count'?: number;
}
/**
 *
 * @export
 * @interface Product
 */
export interface Product {
    /**
     * PK
     * @type {number}
     * @memberof Product
     */
    'id': number;
    /**
     *
     * @type {string}
     * @memberof Product
     */
    'name': string;
    /**
     * 主にJANコード、MAX13文字
     * @type {string}
     * @memberof Product
     */
    'code'?: string;
    /**
     * 1〜3枚、レシピ検索の時はメイン1枚。詳細取得の時は全部
     * @type {Array<ImageFile>}
     * @memberof Product
     */
    'images': Array<ImageFile>;
    /**
     * 商品紹介
     * @type {string}
     * @memberof Product
     */
    'description': string;
    /**
     *
     * @type {Array<ProductCategory>}
     * @memberof Product
     */
    'productCategory': Array<ProductCategory>;
    /**
     * 内容量（基本情報）
     * @type {string}
     * @memberof Product
     */
    'capacity': string;
    /**
     * 容器（包装形態）（基本情報）
     * @type {string}
     * @memberof Product
     */
    'container': string;
    /**
     * 賞味期限（「製造後〇〇日」というフリーワード）（基本情報）
     * @type {string}
     * @memberof Product
     */
    'bestBeforeDate'?: string;
    /**
     * 温度帯（基本情報）
     * @type {string}
     * @memberof Product
     */
    'temperature': string;
    /**
     * 規格一覧（基本情報）
     * @type {Array<string>}
     * @memberof Product
     */
    'standards'?: Array<string>;
    /**
     * その他情報（基本情報）
     * @type {string}
     * @memberof Product
     */
    'otherInformation'?: string;
    /**
     * 地産品フラグ
     * @type {number}
     * @memberof Product
     */
    'isLocalProduct'?: number;
    /**
     * 旬の時期
     * @type {string}
     * @memberof Product
     */
    'bestSeason'?: string;
    /**
     * 季節のID
     * @type {number}
     * @memberof Product
     */
    'seasonId'?: number | null;
    /**
     * 都道府県
     * @type {string}
     * @memberof Product
     */
    'state'?: string;
    /**
     * 問い合わせ対応可否(1は可能、0は不可)
     * @type {number}
     * @memberof Product
     */
    'canInquiry'?: number;
    /**
     * アレルギー一覧（ない場合は無しと入力する）
     * @type {Array<string>}
     * @memberof Product
     */
    'allergies': Array<string>;
    /**
     * 栄養成分
     * @type {Array<string>}
     * @memberof Product
     */
    'nutrition'?: Array<string>;
    /**
     * 原料原産地
     * @type {Array<string>}
     * @memberof Product
     */
    'placeOfOrigin'?: Array<string>;
    /**
     *
     * @type {Maker}
     * @memberof Product
     */
    'maker'?: Maker;
    /**
     * お気に入り登録済み1、していない0
     * @type {number}
     * @memberof Product
     */
    'followed'?: number | null;
    /**
     * サンプル依頼時の依頼数量（メーカーが管理画面から登録（単位込みのフリーワード））
     * @type {string}
     * @memberof Product
     */
    'sampleAmount'?: string;
    /**
     * 現在設定されている商品レビューセットID
     * @type {number}
     * @memberof Product
     */
    'reviewSetId'?: number;
    /**
     * 有料なら1,無料なら0 or なし
     * @type {number}
     * @memberof Product
     */
    'samplePaid'?: number;
    /**
     * 無料なら0 or 無し
     * @type {number}
     * @memberof Product
     */
    'samplePrice'?: number | null;
    /**
     * 会員向けなら1、会員で無くても見れるなら0 or なし
     * @type {number}
     * @memberof Product
     */
    'userPlanOnly'?: number;
    /**
     * 公開日（yyyy-MM-dd hh:mm:ss）
     * @type {string}
     * @memberof Product
     */
    'published_at'?: string | null;
    /**
     *
     * @type {Array<WebSites>}
     * @memberof Product
     */
    'webSites'?: Array<WebSites>;
    /**
     *
     * @type {Array<Keyword>}
     * @memberof Product
     */
    'keywords'?: Array<Keyword>;
}
/**
 *
 * @export
 * @interface ProductCategory
 */
export interface ProductCategory {
    /**
     *
     * @type {ProductCategoryLarge}
     * @memberof ProductCategory
     */
    'large': ProductCategoryLarge;
}
/**
 *
 * @export
 * @interface ProductCategoryLarge
 */
export interface ProductCategoryLarge {
    /**
     *
     * @type {number}
     * @memberof ProductCategoryLarge
     */
    'id'?: number;
    /**
     *
     * @type {string}
     * @memberof ProductCategoryLarge
     */
    'name'?: string;
    /**
     *
     * @type {Array<ProductCategoryLargeMediumInner>}
     * @memberof ProductCategoryLarge
     */
    'medium'?: Array<ProductCategoryLargeMediumInner>;
}
/**
 *
 * @export
 * @interface ProductCategoryLargeMediumInner
 */
export interface ProductCategoryLargeMediumInner {
    /**
     *
     * @type {number}
     * @memberof ProductCategoryLargeMediumInner
     */
    'id'?: number;
    /**
     *
     * @type {string}
     * @memberof ProductCategoryLargeMediumInner
     */
    'name'?: string;
}
/**
 *
 * @export
 * @interface ProductMain
 */
export interface ProductMain {
    /**
     * 商品PK
     * @type {number}
     * @memberof ProductMain
     */
    'id'?: number;
    /**
     * メインイメージのURL
     * @type {string}
     * @memberof ProductMain
     */
    'image'?: string;
}
/**
 *
 * @export
 * @interface Recipe
 */
export interface Recipe {
    /**
     * PK
     * @type {number}
     * @memberof Recipe
     */
    'id': number;
    /**
     *
     * @type {string}
     * @memberof Recipe
     */
    'name': string;
    /**
     * 一言説明
     * @type {string}
     * @memberof Recipe
     */
    'shortDescription': string;
    /**
     * 1〜5枚、レシピ検索の時はメイン1枚。詳細取得の時は全部
     * @type {Array<ImageFile>}
     * @memberof Recipe
     */
    'images': Array<ImageFile>;
    /**
     *
     * @type {Maker}
     * @memberof Recipe
     */
    'maker': Maker;
    /**
     * 調理時間（仕込み時間抜き）[分]
     * @type {number}
     * @memberof Recipe
     */
    'cookingTime': number;
    /**
     * カロリー[cal]
     * @type {number}
     * @memberof Recipe
     */
    'calorie'?: number;
    /**
     * 原価[円]
     * @type {number}
     * @memberof Recipe
     */
    'cost'?: number;
    /**
     *
     * @type {string}
     * @memberof Recipe
     */
    'point': string;
    /**
     *
     * @type {Array<number>}
     * @memberof Recipe
     */
    'productIds': Array<number>;
    /**
     *
     * @type {ProductMain}
     * @memberof Recipe
     */
    'productMain'?: ProductMain;
    /**
     * 材料(名称/量/単位)
     * @type {Array<RecipeIngredientsInner>}
     * @memberof Recipe
     */
    'ingredients': Array<RecipeIngredientsInner>;
    /**
     * 調理手順 1〜10まで
     * @type {Array<string>}
     * @memberof Recipe
     */
    'wayOfCooking': Array<string>;
    /**
     *
     * @type {Array<RecipeCategory>}
     * @memberof Recipe
     */
    'recipeCategory'?: Array<RecipeCategory>;
    /**
     *
     * @type {Array<Keyword>}
     * @memberof Recipe
     */
    'keywords'?: Array<Keyword>;
    /**
     * お気に入り登録済み1、していない0
     * @type {number}
     * @memberof Recipe
     */
    'followed'?: number | null;
    /**
     * 会員向けなら1、会員で無くても見れるなら0 or なし
     * @type {number}
     * @memberof Recipe
     */
    'userPlanOnly'?: number;
    /**
     * 公開日（yyyy-MM-dd hh:mm:ss）
     * @type {string}
     * @memberof Recipe
     */
    'published_at'?: string | null;
    /**
     * 業態(大)のID
     * @type {number}
     * @memberof Recipe
     */
    'restaurantLargeFormatId'?: number | null;
    /**
     * 季節のID
     * @type {number}
     * @memberof Recipe
     */
    'seasonId'?: number | null;
    /**
     * 人前
     * @type {number}
     * @memberof Recipe
     */
    'portionOfFood'?: number | null;
}
/**
 *
 * @export
 * @interface RecipeCategory
 */
export interface RecipeCategory {
    /**
     *
     * @type {number}
     * @memberof RecipeCategory
     */
    'id': number;
    /**
     *
     * @type {string}
     * @memberof RecipeCategory
     */
    'name': string;
}
/**
 *
 * @export
 * @interface RecipeIngredientsInner
 */
export interface RecipeIngredientsInner {
    /**
     *
     * @type {string}
     * @memberof RecipeIngredientsInner
     */
    'name'?: string;
    /**
     *
     * @type {string}
     * @memberof RecipeIngredientsInner
     */
    'amount'?: string;
    /**
     *
     * @type {string}
     * @memberof RecipeIngredientsInner
     */
    'unit'?: string;
}
/**
 *
 * @export
 * @interface RequeetedProductReviews200ResponseInner
 */
export interface RequeetedProductReviews200ResponseInner {
    /**
     * 商品レビューセットID（サンプル依頼時）
     * @type {number}
     * @memberof RequeetedProductReviews200ResponseInner
     */
    'reviewSetId'?: number;
    /**
     * レビュー済みなら1, レビューしてないなら0
     * @type {number}
     * @memberof RequeetedProductReviews200ResponseInner
     */
    'reviewed'?: number;
    /**
     * 商品出荷ステータス（1.未対応 2. 出荷完了 3. レビュー受領 4. 出荷キャンセル）
     * @type {number}
     * @memberof RequeetedProductReviews200ResponseInner
     */
    'responseStatus'?: number;
    /**
     * サンプル依頼履歴（日時 yyyy-MM-dd hh:mm）
     * @type {string}
     * @memberof RequeetedProductReviews200ResponseInner
     */
    'date'?: string;
    /**
     *
     * @type {Product}
     * @memberof RequeetedProductReviews200ResponseInner
     */
    'product'?: Product;
}
/**
 *
 * @export
 * @interface RestaurantFormat
 */
export interface RestaurantFormat {
    /**
     *
     * @type {ProductCategoryLarge}
     * @memberof RestaurantFormat
     */
    'large': ProductCategoryLarge;
}
/**
 *
 * @export
 * @interface RestaurantLocation
 */
export interface RestaurantLocation {
    /**
     *
     * @type {ProductCategoryLarge}
     * @memberof RestaurantLocation
     */
    'large': ProductCategoryLarge;
}
/**
 * ReviewAnswerを参照
 * @export
 * @interface ReviewAnswer
 */
export interface ReviewAnswer {
    /**
     * 商品ID
     * @type {number}
     * @memberof ReviewAnswer
     */
    'productId'?: number;
    /**
     * 商品レビューセットID
     * @type {number}
     * @memberof ReviewAnswer
     */
    'reviewSetId'?: number;
    /**
     *
     * @type {Array<ReviewAnswerAnswersInner>}
     * @memberof ReviewAnswer
     */
    'answers'?: Array<ReviewAnswerAnswersInner>;
}
/**
 *
 * @export
 * @interface ReviewAnswerAnswersInner
 */
export interface ReviewAnswerAnswersInner {
    /**
     * 質問ID
     * @type {number}
     * @memberof ReviewAnswerAnswersInner
     */
    'id'?: number;
    /**
     * 質問タイプ text/radio/checkbox
     * @type {string}
     * @memberof ReviewAnswerAnswersInner
     */
    'type'?: ReviewAnswerAnswersInnerTypeEnum;
    /**
     * チェックボックスのvalue, 質問がtextの時は0
     * @type {number}
     * @memberof ReviewAnswerAnswersInner
     */
    'checkboxValue'?: number;
    /**
     * 質問がtextで無い場合空文字
     * @type {string}
     * @memberof ReviewAnswerAnswersInner
     */
    'text'?: string;
}

export const ReviewAnswerAnswersInnerTypeEnum = {
    Text: 'text',
    Radio: 'radio',
    Checkbox: 'checkbox'
} as const;

export type ReviewAnswerAnswersInnerTypeEnum = typeof ReviewAnswerAnswersInnerTypeEnum[keyof typeof ReviewAnswerAnswersInnerTypeEnum];

/**
 *
 * @export
 * @interface SampleProductRequest
 */
export interface SampleProductRequest {
    /**
     *
     * @type {number}
     * @memberof SampleProductRequest
     */
    'productId': number;
    /**
     * 既存取引先
     * @type {string}
     * @memberof SampleProductRequest
     */
    'existingBusinessPartners'?: string;
    /**
     * firebaseのチャットID(ハッシュ値)
     * @type {string}
     * @memberof SampleProductRequest
     */
    'chatId'?: string;
}
/**
 *
 * @export
 * @interface SampleProductReview
 */
export interface SampleProductReview {
    /**
     * 商品ID
     * @type {number}
     * @memberof SampleProductReview
     */
    'productId'?: number;
    /**
     * 商品レビューセットID
     * @type {number}
     * @memberof SampleProductReview
     */
    'reviewSetId'?: number;
    /**
     * 0/1, 回答済みの場合1
     * @type {number}
     * @memberof SampleProductReview
     */
    'reviewed'?: number;
    /**
     * アンケート項目（過去の回答は必要なし。設定情報だけ）
     * @type {Array<SampleProductReviewContentsInner>}
     * @memberof SampleProductReview
     */
    'contents': Array<SampleProductReviewContentsInner>;
    /**
     * サンプル依頼履歴（日時 yyyy-MM-dd hh:mm）
     * @type {string}
     * @memberof SampleProductReview
     */
    'date'?: string;
}
/**
 *
 * @export
 * @interface SampleProductReviewContentsInner
 */
export interface SampleProductReviewContentsInner {
    /**
     * 質問ID
     * @type {number}
     * @memberof SampleProductReviewContentsInner
     */
    'id'?: number;
    /**
     * 質問
     * @type {string}
     * @memberof SampleProductReviewContentsInner
     */
    'text'?: string;
    /**
     * 質問タイプ, special はメーカーに何で連絡して貰うかの質問
     * @type {string}
     * @memberof SampleProductReviewContentsInner
     */
    'type'?: SampleProductReviewContentsInnerTypeEnum;
    /**
     *
     * @type {Array<SampleProductReviewContentsInnerValueInner>}
     * @memberof SampleProductReviewContentsInner
     */
    'value'?: Array<SampleProductReviewContentsInnerValueInner>;
}

export const SampleProductReviewContentsInnerTypeEnum = {
    Text: 'text',
    Radio: 'radio',
    Checkbox: 'checkbox',
    Special: 'special'
} as const;

export type SampleProductReviewContentsInnerTypeEnum = typeof SampleProductReviewContentsInnerTypeEnum[keyof typeof SampleProductReviewContentsInnerTypeEnum];

/**
 *
 * @export
 * @interface SampleProductReviewContentsInnerValueInner
 */
export interface SampleProductReviewContentsInnerValueInner {
    /**
     * 「満足」とか「不満」とか
     * @type {string}
     * @memberof SampleProductReviewContentsInnerValueInner
     */
    'valueText'?: string;
    /**
     * htmlでいう所のvalue属性(1とか2とか)
     * @type {number}
     * @memberof SampleProductReviewContentsInnerValueInner
     */
    'value'?: number;
}
/**
 *
 * @export
 * @interface Special
 */
export interface Special {
    /**
     *
     * @type {number}
     * @memberof Special
     */
    'id': number;
    /**
     *
     * @type {string}
     * @memberof Special
     */
    'name': string;
    /**
     * 表示サムネイル画像URL
     * @type {string}
     * @memberof Special
     */
    'image': string;
    /**
     *
     * @type {string}
     * @memberof Special
     */
    'url': string;
    /**
     *
     * @type {string}
     * @memberof Special
     */
    'makerName': string;
    /**
     * ジャンル
     * @type {string}
     * @memberof Special
     */
    'genre'?: string;
    /**
     *
     * @type {string}
     * @memberof Special
     */
    'type': SpecialTypeEnum;
}

export const SpecialTypeEnum = {
    Free: 'free',
    Premium: 'premium',
    NotLogin: 'notLogin'
} as const;

export type SpecialTypeEnum = typeof SpecialTypeEnum[keyof typeof SpecialTypeEnum];

/**
 *
 * @export
 * @interface User
 */
export interface User {
    /**
     * 利用しないPK
     * @type {number}
     * @memberof User
     */
    'id': number;
    /**
     * firebaseのUIDと同じ。Get時はこれでUser検索する。
     * @type {string}
     * @memberof User
     */
    'userHash'?: string;
    /**
     *
     * @type {string}
     * @memberof User
     */
    'mail': string;
    /**
     * 会員属性（一般ユーザー(normal)/飲食人(cook)）
     * @type {string}
     * @memberof User
     */
    'userAttribute': UserUserAttributeEnum;
    /**
     * ユーザー名
     * @type {string}
     * @memberof User
     */
    'name': string;
    /**
     * ユーザー名（かな）
     * @type {string}
     * @memberof User
     */
    'nameKana': string;
    /**
     * 性別
     * @type {string}
     * @memberof User
     */
    'gender'?: UserGenderEnum;
    /**
     * 誕生日（yyyy-MM-dd）
     * @type {string}
     * @memberof User
     */
    'birthday'?: string | null;
    /**
     * 店舗名
     * @type {string}
     * @memberof User
     */
    'nameRestaurant'?: string | null;
    /**
     * 郵便番号(数字のみ、数字以外は送信・受信・DB保存前に削除)
     * @type {string}
     * @memberof User
     */
    'postalCode'?: string | null;
    /**
     * 店舗所在地（大）のマスターID
     * @type {number}
     * @memberof User
     */
    'restaurantLargeLocationId'?: number | null;
    /**
     * 店舗所在地（中）のマスターID
     * @type {number}
     * @memberof User
     */
    'restaurantMediumLocationId'?: number | null;
    /**
     * 店舗住所（都道府県）
     * @type {string}
     * @memberof User
     */
    'restaurantState'?: string | null;
    /**
     * 店舗住所（市町村）
     * @type {string}
     * @memberof User
     */
    'restaurantCity'?: string | null;
    /**
     * 店舗住所（番地以降）
     * @type {string}
     * @memberof User
     */
    'restaurantStreetAddress'?: string | null;
    /**
     * 電話番号(数字のみ、数字以外は送信・受信・DB保存前に削除)
     * @type {string}
     * @memberof User
     */
    'tell'?: string | null;
    /**
     * 店舗業態（大）のマスターID
     * @type {number}
     * @memberof User
     */
    'restaurantLargeFormatId'?: number | null;
    /**
     * 店舗業態（中）のマスターID
     * @type {number}
     * @memberof User
     */
    'restaurantMediumFormatId'?: number | null;
    /**
     * 店舗規模のマスターID
     * @type {number}
     * @memberof User
     */
    'numOfRestaurantId'?: number | null;
    /**
     * 主要店舗展開エリアのマスターID
     * @type {number}
     * @memberof User
     */
    'majorRestaurantAreaId'?: number | null;
    /**
     * ランチ営業有無
     * @type {number}
     * @memberof User
     */
    'lunchService'?: number | null;
    /**
     * 客単価のマスターID
     * @type {number}
     * @memberof User
     */
    'customerUnitPriceId'?: number | null;
    /**
     * 席数
     * @type {number}
     * @memberof User
     */
    'numberOfSeats'?: number | null;
    /**
     * ウェブサイトURL
     * @type {string}
     * @memberof User
     */
    'site'?: string;
    /**
     * メール通知ON/OFF (1/0)、デフォルトOFF
     * @type {number}
     * @memberof User
     */
    'notificationMail': number;
    /**
     * 課金プラン（DB作成時は無料プランで登録）
     * @type {string}
     * @memberof User
     */
    'userPlan': UserUserPlanEnum;
    /**
     * 所持無料コイン数
     * @type {number}
     * @memberof User
     */
    'userFreeCoin'?: number;
    /**
     * プロフィール画像URL
     * @type {string}
     * @memberof User
     */
    'image'?: string;
}

export const UserUserAttributeEnum = {
    Normal: 'normal',
    Cook: 'cook'
} as const;

export type UserUserAttributeEnum = typeof UserUserAttributeEnum[keyof typeof UserUserAttributeEnum];
export const UserGenderEnum = {
    Male: 'male',
    Female: 'female'
} as const;

export type UserGenderEnum = typeof UserGenderEnum[keyof typeof UserGenderEnum];
export const UserUserPlanEnum = {
    Free: 'free',
    Premium: 'premium'
} as const;

export type UserUserPlanEnum = typeof UserUserPlanEnum[keyof typeof UserUserPlanEnum];

/**
 *
 * @export
 * @interface WebSites
 */
export interface WebSites {
    /**
     * webサイトのURL
     * @type {string}
     * @memberof WebSites
     */
    'url'?: string | null;
    /**
     * URL用のラベル
     * @type {string}
     * @memberof WebSites
     */
    'name'?: string | null;
}

/**
 * AccountApi - axios parameter creator
 * @export
 */
export const AccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 退会を受け取ったサーバーは、ログイン確認後、firebaseのユーザー削除SDKを叩き、成功した後サーバーからも退会(個人情報削除)を行う
         * @summary 退会処理
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccount: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/account/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ページを移動するごとにログインチェックを行う
         * @summary ログイン処理
         * @param {string} os ログインしたOS
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginAccount: async (os: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'os' is not null or undefined
            assertParamExists('loginAccount', 'os', os)
            const localVarPath = `/account/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (os !== undefined) {
                localVarFormParams.append('os', os as any);
            }


            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * firebaseから受け取ったuserHashで新規アカウントを作る。userHashはheader、userHashは既にログイン済みのユーザーのため、サーバーでログインチェックを行ってからユーザー作成する
         * @summary 新規アカウント作成
         * @param {string} [deviceToken] デバイストークン
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signup: async (deviceToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/account/signup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (deviceToken !== undefined) {
                localVarFormParams.append('deviceToken', deviceToken as any);
            }


            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountApi - functional programming interface
 * @export
 */
export const AccountApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountApiAxiosParamCreator(configuration)
    return {
        /**
         * 退会を受け取ったサーバーは、ログイン確認後、firebaseのユーザー削除SDKを叩き、成功した後サーバーからも退会(個人情報削除)を行う
         * @summary 退会処理
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAccount(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAccount(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ページを移動するごとにログインチェックを行う
         * @summary ログイン処理
         * @param {string} os ログインしたOS
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginAccount(os: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginAccount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginAccount(os, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * firebaseから受け取ったuserHashで新規アカウントを作る。userHashはheader、userHashは既にログイン済みのユーザーのため、サーバーでログインチェックを行ってからユーザー作成する
         * @summary 新規アカウント作成
         * @param {string} [deviceToken] デバイストークン
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async signup(deviceToken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.signup(deviceToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AccountApi - factory interface
 * @export
 */
export const AccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountApiFp(configuration)
    return {
        /**
         * 退会を受け取ったサーバーは、ログイン確認後、firebaseのユーザー削除SDKを叩き、成功した後サーバーからも退会(個人情報削除)を行う
         * @summary 退会処理
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccount(options?: any): AxiosPromise<void> {
            return localVarFp.deleteAccount(options).then((request) => request(axios, basePath));
        },
        /**
         * ページを移動するごとにログインチェックを行う
         * @summary ログイン処理
         * @param {string} os ログインしたOS
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginAccount(os: string, options?: any): AxiosPromise<LoginAccount200Response> {
            return localVarFp.loginAccount(os, options).then((request) => request(axios, basePath));
        },
        /**
         * firebaseから受け取ったuserHashで新規アカウントを作る。userHashはheader、userHashは既にログイン済みのユーザーのため、サーバーでログインチェックを行ってからユーザー作成する
         * @summary 新規アカウント作成
         * @param {string} [deviceToken] デバイストークン
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signup(deviceToken?: string, options?: any): AxiosPromise<void> {
            return localVarFp.signup(deviceToken, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccountApi - object-oriented interface
 * @export
 * @class AccountApi
 * @extends {BaseAPI}
 */
export class AccountApi extends BaseAPI {
    /**
     * 退会を受け取ったサーバーは、ログイン確認後、firebaseのユーザー削除SDKを叩き、成功した後サーバーからも退会(個人情報削除)を行う
     * @summary 退会処理
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public deleteAccount(options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).deleteAccount(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ページを移動するごとにログインチェックを行う
     * @summary ログイン処理
     * @param {string} os ログインしたOS
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public loginAccount(os: string, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).loginAccount(os, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * firebaseから受け取ったuserHashで新規アカウントを作る。userHashはheader、userHashは既にログイン済みのユーザーのため、サーバーでログインチェックを行ってからユーザー作成する
     * @summary 新規アカウント作成
     * @param {string} [deviceToken] デバイストークン
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public signup(deviceToken?: string, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).signup(deviceToken, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AccountFirebaseApi - axios parameter creator
 * @export
 */
export const AccountFirebaseApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * メルアド認証以外は割愛（SNSログイン）。getIdTokenで後からtokenを取得。firebaseに合わせて、userHash = currentUser.uid でGETやりとりしたいので、userIdをkeyにはしない。 https://qiita.com/gagagaga_dev/items/a8dd490114c315329279
         * @summary Firebase向け、サーバー実装しない。APIのpathはダミー
         * @param {string} mail
         * @param {string} password 半角英数字と長さ6文字以上（英字、数字のみ可）
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        firebaseLogin: async (mail: string, password: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mail' is not null or undefined
            assertParamExists('firebaseLogin', 'mail', mail)
            // verify required parameter 'password' is not null or undefined
            assertParamExists('firebaseLogin', 'password', password)
            const localVarPath = `/firebase/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (mail !== undefined) {
                localVarQueryParameter['mail'] = mail;
            }

            if (password !== undefined) {
                localVarQueryParameter['password'] = password;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * firebaseのパスワード変更のSDKを叩く。メルアド認証時のみ
         * @summary Firebase向け、サーバー実装しない。APIのpathはダミー
         * @param {string} mail
         * @param {string} password 半角英数字と長さ6文字以上（英字、数字のみ可）
         * @param {string} newPassword 半角英数字と長さ6文字以上（英字、数字のみ可）
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        firebasePasswordChange: async (mail: string, password: string, newPassword: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mail' is not null or undefined
            assertParamExists('firebasePasswordChange', 'mail', mail)
            // verify required parameter 'password' is not null or undefined
            assertParamExists('firebasePasswordChange', 'password', password)
            // verify required parameter 'newPassword' is not null or undefined
            assertParamExists('firebasePasswordChange', 'newPassword', newPassword)
            const localVarPath = `/firebase/password/change`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (mail !== undefined) {
                localVarQueryParameter['mail'] = mail;
            }

            if (password !== undefined) {
                localVarQueryParameter['password'] = password;
            }

            if (newPassword !== undefined) {
                localVarQueryParameter['newPassword'] = newPassword;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * メルアド認証以外は割愛（SNSログイン）。getIdTokenで後からtokenを取得。firebaseに合わせて、userHash = currentUser.uid でGETやりとりしたいので、userIdをkeyにはしない。 https://qiita.com/gagagaga_dev/items/a8dd490114c315329279
         * @summary Firebase向け、サーバー実装しない。APIのpathはダミー
         * @param {string} mail
         * @param {string} password 半角英数字と長さ6文字以上（英字、数字のみ可）
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        firebaseSignup: async (mail: string, password: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mail' is not null or undefined
            assertParamExists('firebaseSignup', 'mail', mail)
            // verify required parameter 'password' is not null or undefined
            assertParamExists('firebaseSignup', 'password', password)
            const localVarPath = `/firebase/signup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (mail !== undefined) {
                localVarQueryParameter['mail'] = mail;
            }

            if (password !== undefined) {
                localVarQueryParameter['password'] = password;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountFirebaseApi - functional programming interface
 * @export
 */
export const AccountFirebaseApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountFirebaseApiAxiosParamCreator(configuration)
    return {
        /**
         * メルアド認証以外は割愛（SNSログイン）。getIdTokenで後からtokenを取得。firebaseに合わせて、userHash = currentUser.uid でGETやりとりしたいので、userIdをkeyにはしない。 https://qiita.com/gagagaga_dev/items/a8dd490114c315329279
         * @summary Firebase向け、サーバー実装しない。APIのpathはダミー
         * @param {string} mail
         * @param {string} password 半角英数字と長さ6文字以上（英字、数字のみ可）
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async firebaseLogin(mail: string, password: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.firebaseLogin(mail, password, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * firebaseのパスワード変更のSDKを叩く。メルアド認証時のみ
         * @summary Firebase向け、サーバー実装しない。APIのpathはダミー
         * @param {string} mail
         * @param {string} password 半角英数字と長さ6文字以上（英字、数字のみ可）
         * @param {string} newPassword 半角英数字と長さ6文字以上（英字、数字のみ可）
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async firebasePasswordChange(mail: string, password: string, newPassword: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.firebasePasswordChange(mail, password, newPassword, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * メルアド認証以外は割愛（SNSログイン）。getIdTokenで後からtokenを取得。firebaseに合わせて、userHash = currentUser.uid でGETやりとりしたいので、userIdをkeyにはしない。 https://qiita.com/gagagaga_dev/items/a8dd490114c315329279
         * @summary Firebase向け、サーバー実装しない。APIのpathはダミー
         * @param {string} mail
         * @param {string} password 半角英数字と長さ6文字以上（英字、数字のみ可）
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async firebaseSignup(mail: string, password: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.firebaseSignup(mail, password, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AccountFirebaseApi - factory interface
 * @export
 */
export const AccountFirebaseApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountFirebaseApiFp(configuration)
    return {
        /**
         * メルアド認証以外は割愛（SNSログイン）。getIdTokenで後からtokenを取得。firebaseに合わせて、userHash = currentUser.uid でGETやりとりしたいので、userIdをkeyにはしない。 https://qiita.com/gagagaga_dev/items/a8dd490114c315329279
         * @summary Firebase向け、サーバー実装しない。APIのpathはダミー
         * @param {string} mail
         * @param {string} password 半角英数字と長さ6文字以上（英字、数字のみ可）
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        firebaseLogin(mail: string, password: string, options?: any): AxiosPromise<void> {
            return localVarFp.firebaseLogin(mail, password, options).then((request) => request(axios, basePath));
        },
        /**
         * firebaseのパスワード変更のSDKを叩く。メルアド認証時のみ
         * @summary Firebase向け、サーバー実装しない。APIのpathはダミー
         * @param {string} mail
         * @param {string} password 半角英数字と長さ6文字以上（英字、数字のみ可）
         * @param {string} newPassword 半角英数字と長さ6文字以上（英字、数字のみ可）
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        firebasePasswordChange(mail: string, password: string, newPassword: string, options?: any): AxiosPromise<void> {
            return localVarFp.firebasePasswordChange(mail, password, newPassword, options).then((request) => request(axios, basePath));
        },
        /**
         * メルアド認証以外は割愛（SNSログイン）。getIdTokenで後からtokenを取得。firebaseに合わせて、userHash = currentUser.uid でGETやりとりしたいので、userIdをkeyにはしない。 https://qiita.com/gagagaga_dev/items/a8dd490114c315329279
         * @summary Firebase向け、サーバー実装しない。APIのpathはダミー
         * @param {string} mail
         * @param {string} password 半角英数字と長さ6文字以上（英字、数字のみ可）
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        firebaseSignup(mail: string, password: string, options?: any): AxiosPromise<void> {
            return localVarFp.firebaseSignup(mail, password, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccountFirebaseApi - object-oriented interface
 * @export
 * @class AccountFirebaseApi
 * @extends {BaseAPI}
 */
export class AccountFirebaseApi extends BaseAPI {
    /**
     * メルアド認証以外は割愛（SNSログイン）。getIdTokenで後からtokenを取得。firebaseに合わせて、userHash = currentUser.uid でGETやりとりしたいので、userIdをkeyにはしない。 https://qiita.com/gagagaga_dev/items/a8dd490114c315329279
     * @summary Firebase向け、サーバー実装しない。APIのpathはダミー
     * @param {string} mail
     * @param {string} password 半角英数字と長さ6文字以上（英字、数字のみ可）
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountFirebaseApi
     */
    public firebaseLogin(mail: string, password: string, options?: AxiosRequestConfig) {
        return AccountFirebaseApiFp(this.configuration).firebaseLogin(mail, password, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * firebaseのパスワード変更のSDKを叩く。メルアド認証時のみ
     * @summary Firebase向け、サーバー実装しない。APIのpathはダミー
     * @param {string} mail
     * @param {string} password 半角英数字と長さ6文字以上（英字、数字のみ可）
     * @param {string} newPassword 半角英数字と長さ6文字以上（英字、数字のみ可）
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountFirebaseApi
     */
    public firebasePasswordChange(mail: string, password: string, newPassword: string, options?: AxiosRequestConfig) {
        return AccountFirebaseApiFp(this.configuration).firebasePasswordChange(mail, password, newPassword, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * メルアド認証以外は割愛（SNSログイン）。getIdTokenで後からtokenを取得。firebaseに合わせて、userHash = currentUser.uid でGETやりとりしたいので、userIdをkeyにはしない。 https://qiita.com/gagagaga_dev/items/a8dd490114c315329279
     * @summary Firebase向け、サーバー実装しない。APIのpathはダミー
     * @param {string} mail
     * @param {string} password 半角英数字と長さ6文字以上（英字、数字のみ可）
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountFirebaseApi
     */
    public firebaseSignup(mail: string, password: string, options?: AxiosRequestConfig) {
        return AccountFirebaseApiFp(this.configuration).firebaseSignup(mail, password, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ChatApi - axios parameter creator
 * @export
 */
export const ChatApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 未読チャットがあるか確認する(ユーザーはHeaderから確認)
         * @summary Check to Have Unread Chats
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkHaveUnreadChats: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/chat/check/have/unread`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * チャットを新規作成する(既にある場合はレスポンスに値を入れて返すだけで、チャットを作成しない)、サンプル依頼時にはチャット作成まで行うので、こちらは利用しない
         * @summary Read a Create
         * @param {string} makerId このユーザーとのチャットを作るmakerのID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createChat: async (makerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'makerId' is not null or undefined
            assertParamExists('createChat', 'makerId', makerId)
            const localVarPath = `/chat/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (makerId !== undefined) {
                localVarFormParams.append('makerId', makerId as any);
            }


            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * チャット一覧を取得(新着メッセージの日付順でfrom番目〜to番目まで取得)、未読数はfirebaseから個別取得
         * @summary Finds Chats
         * @param {number} from 0スタート
         * @param {number} to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChats: async (from: number, to: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'from' is not null or undefined
            assertParamExists('getChats', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('getChats', 'to', to)
            const localVarPath = `/chat/find`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * チャットを既読にする(ユーザーはHeaderから確認する)
         * @summary Read a Chat
         * @param {string} chatId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readChat: async (chatId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'chatId' is not null or undefined
            assertParamExists('readChat', 'chatId', chatId)
            const localVarPath = `/chat/read`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (chatId !== undefined) {
                localVarFormParams.append('chatId', chatId as any);
            }


            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ChatApi - functional programming interface
 * @export
 */
export const ChatApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ChatApiAxiosParamCreator(configuration)
    return {
        /**
         * 未読チャットがあるか確認する(ユーザーはHeaderから確認)
         * @summary Check to Have Unread Chats
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkHaveUnreadChats(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkHaveUnreadChats(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * チャットを新規作成する(既にある場合はレスポンスに値を入れて返すだけで、チャットを作成しない)、サンプル依頼時にはチャット作成まで行うので、こちらは利用しない
         * @summary Read a Create
         * @param {string} makerId このユーザーとのチャットを作るmakerのID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createChat(makerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateChat200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createChat(makerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * チャット一覧を取得(新着メッセージの日付順でfrom番目〜to番目まで取得)、未読数はfirebaseから個別取得
         * @summary Finds Chats
         * @param {number} from 0スタート
         * @param {number} to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChats(from: number, to: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Chat>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChats(from, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * チャットを既読にする(ユーザーはHeaderから確認する)
         * @summary Read a Chat
         * @param {string} chatId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readChat(chatId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readChat(chatId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ChatApi - factory interface
 * @export
 */
export const ChatApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ChatApiFp(configuration)
    return {
        /**
         * 未読チャットがあるか確認する(ユーザーはHeaderから確認)
         * @summary Check to Have Unread Chats
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkHaveUnreadChats(options?: any): AxiosPromise<number> {
            return localVarFp.checkHaveUnreadChats(options).then((request) => request(axios, basePath));
        },
        /**
         * チャットを新規作成する(既にある場合はレスポンスに値を入れて返すだけで、チャットを作成しない)、サンプル依頼時にはチャット作成まで行うので、こちらは利用しない
         * @summary Read a Create
         * @param {string} makerId このユーザーとのチャットを作るmakerのID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createChat(makerId: string, options?: any): AxiosPromise<CreateChat200Response> {
            return localVarFp.createChat(makerId, options).then((request) => request(axios, basePath));
        },
        /**
         * チャット一覧を取得(新着メッセージの日付順でfrom番目〜to番目まで取得)、未読数はfirebaseから個別取得
         * @summary Finds Chats
         * @param {number} from 0スタート
         * @param {number} to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChats(from: number, to: number, options?: any): AxiosPromise<Array<Chat>> {
            return localVarFp.getChats(from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * チャットを既読にする(ユーザーはHeaderから確認する)
         * @summary Read a Chat
         * @param {string} chatId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readChat(chatId: string, options?: any): AxiosPromise<void> {
            return localVarFp.readChat(chatId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ChatApi - object-oriented interface
 * @export
 * @class ChatApi
 * @extends {BaseAPI}
 */
export class ChatApi extends BaseAPI {
    /**
     * 未読チャットがあるか確認する(ユーザーはHeaderから確認)
     * @summary Check to Have Unread Chats
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatApi
     */
    public checkHaveUnreadChats(options?: AxiosRequestConfig) {
        return ChatApiFp(this.configuration).checkHaveUnreadChats(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * チャットを新規作成する(既にある場合はレスポンスに値を入れて返すだけで、チャットを作成しない)、サンプル依頼時にはチャット作成まで行うので、こちらは利用しない
     * @summary Read a Create
     * @param {string} makerId このユーザーとのチャットを作るmakerのID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatApi
     */
    public createChat(makerId: string, options?: AxiosRequestConfig) {
        return ChatApiFp(this.configuration).createChat(makerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * チャット一覧を取得(新着メッセージの日付順でfrom番目〜to番目まで取得)、未読数はfirebaseから個別取得
     * @summary Finds Chats
     * @param {number} from 0スタート
     * @param {number} to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatApi
     */
    public getChats(from: number, to: number, options?: AxiosRequestConfig) {
        return ChatApiFp(this.configuration).getChats(from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * チャットを既読にする(ユーザーはHeaderから確認する)
     * @summary Read a Chat
     * @param {string} chatId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatApi
     */
    public readChat(chatId: string, options?: AxiosRequestConfig) {
        return ChatApiFp(this.configuration).readChat(chatId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DataApi - axios parameter creator
 * @export
 */
export const DataApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Analytics Data の送信。APIサーバーで BigQuery に転送する(ユーザーはHeaderから確認する)
         * @summary Send Analytics Data
         * @param {string} [channel] reci-base-dev &#x3D;&gt; 開発 or Staging (BQ: develop), reci-bas &#x3D;&gt; 本番(BQ: production)
         * @param {string} [aId] hash
         * @param {string} [loginStatus] notLogin/free/premium
         * @param {string} [event00]
         * @param {string} [event01]
         * @param {string} [event02]
         * @param {string} [screenNameJp]
         * @param {string} [screenName]
         * @param {string} [btnName]
         * @param {string} [screenHistory00]
         * @param {string} [screenHistory01]
         * @param {string} [screenHistory02]
         * @param {string} [screenHistory03]
         * @param {string} [screenHistory04]
         * @param {string} [screenHistory05]
         * @param {string} [screenHistory06]
         * @param {string} [screenHistory07]
         * @param {string} [screenHistory08]
         * @param {string} [screenHistory09]
         * @param {number} [recipeId]
         * @param {number} [productId]
         * @param {number} [makerId]
         * @param {number} [sampleRequestId]
         * @param {string} [searchType]
         * @param {string} [keyword]
         * @param {string} [searchWord] 複数の場合スペース区切り
         * @param {string} [accessedBy]
         * @param {number} [specialId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dOpen: async (channel?: string, aId?: string, loginStatus?: string, event00?: string, event01?: string, event02?: string, screenNameJp?: string, screenName?: string, btnName?: string, screenHistory00?: string, screenHistory01?: string, screenHistory02?: string, screenHistory03?: string, screenHistory04?: string, screenHistory05?: string, screenHistory06?: string, screenHistory07?: string, screenHistory08?: string, screenHistory09?: string, recipeId?: number, productId?: number, makerId?: number, sampleRequestId?: number, searchType?: string, keyword?: string, searchWord?: string, accessedBy?: string, specialId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/d/open`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (channel !== undefined) {
                localVarFormParams.append('channel', channel as any);
            }

            if (aId !== undefined) {
                localVarFormParams.append('a_id', aId as any);
            }

            if (loginStatus !== undefined) {
                localVarFormParams.append('login_status', loginStatus as any);
            }

            if (event00 !== undefined) {
                localVarFormParams.append('event_00', event00 as any);
            }

            if (event01 !== undefined) {
                localVarFormParams.append('event_01', event01 as any);
            }

            if (event02 !== undefined) {
                localVarFormParams.append('event_02', event02 as any);
            }

            if (screenNameJp !== undefined) {
                localVarFormParams.append('screen_name_jp', screenNameJp as any);
            }

            if (screenName !== undefined) {
                localVarFormParams.append('screen_name', screenName as any);
            }

            if (btnName !== undefined) {
                localVarFormParams.append('btn_name', btnName as any);
            }

            if (screenHistory00 !== undefined) {
                localVarFormParams.append('screen_history_00', screenHistory00 as any);
            }

            if (screenHistory01 !== undefined) {
                localVarFormParams.append('screen_history_01', screenHistory01 as any);
            }

            if (screenHistory02 !== undefined) {
                localVarFormParams.append('screen_history_02', screenHistory02 as any);
            }

            if (screenHistory03 !== undefined) {
                localVarFormParams.append('screen_history_03', screenHistory03 as any);
            }

            if (screenHistory04 !== undefined) {
                localVarFormParams.append('screen_history_04', screenHistory04 as any);
            }

            if (screenHistory05 !== undefined) {
                localVarFormParams.append('screen_history_05', screenHistory05 as any);
            }

            if (screenHistory06 !== undefined) {
                localVarFormParams.append('screen_history_06', screenHistory06 as any);
            }

            if (screenHistory07 !== undefined) {
                localVarFormParams.append('screen_history_07', screenHistory07 as any);
            }

            if (screenHistory08 !== undefined) {
                localVarFormParams.append('screen_history_08', screenHistory08 as any);
            }

            if (screenHistory09 !== undefined) {
                localVarFormParams.append('screen_history_09', screenHistory09 as any);
            }

            if (recipeId !== undefined) {
                localVarFormParams.append('recipe_id', recipeId as any);
            }

            if (productId !== undefined) {
                localVarFormParams.append('product_id', productId as any);
            }

            if (makerId !== undefined) {
                localVarFormParams.append('maker_id', makerId as any);
            }

            if (sampleRequestId !== undefined) {
                localVarFormParams.append('sample_request_id', sampleRequestId as any);
            }

            if (searchType !== undefined) {
                localVarFormParams.append('search_type', searchType as any);
            }

            if (keyword !== undefined) {
                localVarFormParams.append('keyword', keyword as any);
            }

            if (searchWord !== undefined) {
                localVarFormParams.append('search_word', searchWord as any);
            }

            if (accessedBy !== undefined) {
                localVarFormParams.append('accessed_by', accessedBy as any);
            }

            if (specialId !== undefined) {
                localVarFormParams.append('special_id', specialId as any);
            }


            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DataApi - functional programming interface
 * @export
 */
export const DataApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DataApiAxiosParamCreator(configuration)
    return {
        /**
         * Analytics Data の送信。APIサーバーで BigQuery に転送する(ユーザーはHeaderから確認する)
         * @summary Send Analytics Data
         * @param {string} [channel] reci-base-dev &#x3D;&gt; 開発 or Staging (BQ: develop), reci-bas &#x3D;&gt; 本番(BQ: production)
         * @param {string} [aId] hash
         * @param {string} [loginStatus] notLogin/free/premium
         * @param {string} [event00]
         * @param {string} [event01]
         * @param {string} [event02]
         * @param {string} [screenNameJp]
         * @param {string} [screenName]
         * @param {string} [btnName]
         * @param {string} [screenHistory00]
         * @param {string} [screenHistory01]
         * @param {string} [screenHistory02]
         * @param {string} [screenHistory03]
         * @param {string} [screenHistory04]
         * @param {string} [screenHistory05]
         * @param {string} [screenHistory06]
         * @param {string} [screenHistory07]
         * @param {string} [screenHistory08]
         * @param {string} [screenHistory09]
         * @param {number} [recipeId]
         * @param {number} [productId]
         * @param {number} [makerId]
         * @param {number} [sampleRequestId]
         * @param {string} [searchType]
         * @param {string} [keyword]
         * @param {string} [searchWord] 複数の場合スペース区切り
         * @param {string} [accessedBy]
         * @param {number} [specialId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dOpen(channel?: string, aId?: string, loginStatus?: string, event00?: string, event01?: string, event02?: string, screenNameJp?: string, screenName?: string, btnName?: string, screenHistory00?: string, screenHistory01?: string, screenHistory02?: string, screenHistory03?: string, screenHistory04?: string, screenHistory05?: string, screenHistory06?: string, screenHistory07?: string, screenHistory08?: string, screenHistory09?: string, recipeId?: number, productId?: number, makerId?: number, sampleRequestId?: number, searchType?: string, keyword?: string, searchWord?: string, accessedBy?: string, specialId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dOpen(channel, aId, loginStatus, event00, event01, event02, screenNameJp, screenName, btnName, screenHistory00, screenHistory01, screenHistory02, screenHistory03, screenHistory04, screenHistory05, screenHistory06, screenHistory07, screenHistory08, screenHistory09, recipeId, productId, makerId, sampleRequestId, searchType, keyword, searchWord, accessedBy, specialId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DataApi - factory interface
 * @export
 */
export const DataApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DataApiFp(configuration)
    return {
        /**
         * Analytics Data の送信。APIサーバーで BigQuery に転送する(ユーザーはHeaderから確認する)
         * @summary Send Analytics Data
         * @param {string} [channel] reci-base-dev &#x3D;&gt; 開発 or Staging (BQ: develop), reci-bas &#x3D;&gt; 本番(BQ: production)
         * @param {string} [aId] hash
         * @param {string} [loginStatus] notLogin/free/premium
         * @param {string} [event00]
         * @param {string} [event01]
         * @param {string} [event02]
         * @param {string} [screenNameJp]
         * @param {string} [screenName]
         * @param {string} [btnName]
         * @param {string} [screenHistory00]
         * @param {string} [screenHistory01]
         * @param {string} [screenHistory02]
         * @param {string} [screenHistory03]
         * @param {string} [screenHistory04]
         * @param {string} [screenHistory05]
         * @param {string} [screenHistory06]
         * @param {string} [screenHistory07]
         * @param {string} [screenHistory08]
         * @param {string} [screenHistory09]
         * @param {number} [recipeId]
         * @param {number} [productId]
         * @param {number} [makerId]
         * @param {number} [sampleRequestId]
         * @param {string} [searchType]
         * @param {string} [keyword]
         * @param {string} [searchWord] 複数の場合スペース区切り
         * @param {string} [accessedBy]
         * @param {number} [specialId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dOpen(channel?: string, aId?: string, loginStatus?: string, event00?: string, event01?: string, event02?: string, screenNameJp?: string, screenName?: string, btnName?: string, screenHistory00?: string, screenHistory01?: string, screenHistory02?: string, screenHistory03?: string, screenHistory04?: string, screenHistory05?: string, screenHistory06?: string, screenHistory07?: string, screenHistory08?: string, screenHistory09?: string, recipeId?: number, productId?: number, makerId?: number, sampleRequestId?: number, searchType?: string, keyword?: string, searchWord?: string, accessedBy?: string, specialId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.dOpen(channel, aId, loginStatus, event00, event01, event02, screenNameJp, screenName, btnName, screenHistory00, screenHistory01, screenHistory02, screenHistory03, screenHistory04, screenHistory05, screenHistory06, screenHistory07, screenHistory08, screenHistory09, recipeId, productId, makerId, sampleRequestId, searchType, keyword, searchWord, accessedBy, specialId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DataApi - object-oriented interface
 * @export
 * @class DataApi
 * @extends {BaseAPI}
 */
export class DataApi extends BaseAPI {
    /**
     * Analytics Data の送信。APIサーバーで BigQuery に転送する(ユーザーはHeaderから確認する)
     * @summary Send Analytics Data
     * @param {string} [channel] reci-base-dev &#x3D;&gt; 開発 or Staging (BQ: develop), reci-bas &#x3D;&gt; 本番(BQ: production)
     * @param {string} [aId] hash
     * @param {string} [loginStatus] notLogin/free/premium
     * @param {string} [event00]
     * @param {string} [event01]
     * @param {string} [event02]
     * @param {string} [screenNameJp]
     * @param {string} [screenName]
     * @param {string} [btnName]
     * @param {string} [screenHistory00]
     * @param {string} [screenHistory01]
     * @param {string} [screenHistory02]
     * @param {string} [screenHistory03]
     * @param {string} [screenHistory04]
     * @param {string} [screenHistory05]
     * @param {string} [screenHistory06]
     * @param {string} [screenHistory07]
     * @param {string} [screenHistory08]
     * @param {string} [screenHistory09]
     * @param {number} [recipeId]
     * @param {number} [productId]
     * @param {number} [makerId]
     * @param {number} [sampleRequestId]
     * @param {string} [searchType]
     * @param {string} [keyword]
     * @param {string} [searchWord] 複数の場合スペース区切り
     * @param {string} [accessedBy]
     * @param {number} [specialId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataApi
     */
    public dOpen(channel?: string, aId?: string, loginStatus?: string, event00?: string, event01?: string, event02?: string, screenNameJp?: string, screenName?: string, btnName?: string, screenHistory00?: string, screenHistory01?: string, screenHistory02?: string, screenHistory03?: string, screenHistory04?: string, screenHistory05?: string, screenHistory06?: string, screenHistory07?: string, screenHistory08?: string, screenHistory09?: string, recipeId?: number, productId?: number, makerId?: number, sampleRequestId?: number, searchType?: string, keyword?: string, searchWord?: string, accessedBy?: string, specialId?: number, options?: AxiosRequestConfig) {
        return DataApiFp(this.configuration).dOpen(channel, aId, loginStatus, event00, event01, event02, screenNameJp, screenName, btnName, screenHistory00, screenHistory01, screenHistory02, screenHistory03, screenHistory04, screenHistory05, screenHistory06, screenHistory07, screenHistory08, screenHistory09, recipeId, productId, makerId, sampleRequestId, searchType, keyword, searchWord, accessedBy, specialId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FollowApi - axios parameter creator
 * @export
 */
export const FollowApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * メーカーお気に入り追加または削除。追加ユーザーはheaderから確認する
         * @summary Follow a Maker
         * @param {string} makerId
         * @param {string} type follow &#x3D;&gt; フォロー, unfollow &#x3D;&gt; フォロー削除
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        followMaker: async (makerId: string, type: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'makerId' is not null or undefined
            assertParamExists('followMaker', 'makerId', makerId)
            // verify required parameter 'type' is not null or undefined
            assertParamExists('followMaker', 'type', type)
            const localVarPath = `/follow/maker`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (makerId !== undefined) {
                localVarFormParams.append('makerId', makerId as any);
            }

            if (type !== undefined) {
                localVarFormParams.append('type', type as any);
            }


            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 商品お気に入り追加または削除。追加ユーザーはheaderから確認する
         * @summary Follow a Product
         * @param {string} productId
         * @param {string} type follow &#x3D;&gt; フォロー, unfollow &#x3D;&gt; フォロー削除
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        followProduct: async (productId: string, type: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('followProduct', 'productId', productId)
            // verify required parameter 'type' is not null or undefined
            assertParamExists('followProduct', 'type', type)
            const localVarPath = `/follow/product`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (productId !== undefined) {
                localVarFormParams.append('productId', productId as any);
            }

            if (type !== undefined) {
                localVarFormParams.append('type', type as any);
            }


            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * レシピお気に入り追加または削除。追加ユーザーはheaderから確認する
         * @summary Follow a Recipe
         * @param {string} recipeId
         * @param {string} type follow &#x3D;&gt; フォロー, unfollow &#x3D;&gt; フォロー削除
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        followRecipe: async (recipeId: string, type: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'recipeId' is not null or undefined
            assertParamExists('followRecipe', 'recipeId', recipeId)
            // verify required parameter 'type' is not null or undefined
            assertParamExists('followRecipe', 'type', type)
            const localVarPath = `/follow/recipe`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (recipeId !== undefined) {
                localVarFormParams.append('recipeId', recipeId as any);
            }

            if (type !== undefined) {
                localVarFormParams.append('type', type as any);
            }


            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FollowApi - functional programming interface
 * @export
 */
export const FollowApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FollowApiAxiosParamCreator(configuration)
    return {
        /**
         * メーカーお気に入り追加または削除。追加ユーザーはheaderから確認する
         * @summary Follow a Maker
         * @param {string} makerId
         * @param {string} type follow &#x3D;&gt; フォロー, unfollow &#x3D;&gt; フォロー削除
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async followMaker(makerId: string, type: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.followMaker(makerId, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 商品お気に入り追加または削除。追加ユーザーはheaderから確認する
         * @summary Follow a Product
         * @param {string} productId
         * @param {string} type follow &#x3D;&gt; フォロー, unfollow &#x3D;&gt; フォロー削除
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async followProduct(productId: string, type: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.followProduct(productId, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * レシピお気に入り追加または削除。追加ユーザーはheaderから確認する
         * @summary Follow a Recipe
         * @param {string} recipeId
         * @param {string} type follow &#x3D;&gt; フォロー, unfollow &#x3D;&gt; フォロー削除
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async followRecipe(recipeId: string, type: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.followRecipe(recipeId, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FollowApi - factory interface
 * @export
 */
export const FollowApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FollowApiFp(configuration)
    return {
        /**
         * メーカーお気に入り追加または削除。追加ユーザーはheaderから確認する
         * @summary Follow a Maker
         * @param {string} makerId
         * @param {string} type follow &#x3D;&gt; フォロー, unfollow &#x3D;&gt; フォロー削除
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        followMaker(makerId: string, type: string, options?: any): AxiosPromise<void> {
            return localVarFp.followMaker(makerId, type, options).then((request) => request(axios, basePath));
        },
        /**
         * 商品お気に入り追加または削除。追加ユーザーはheaderから確認する
         * @summary Follow a Product
         * @param {string} productId
         * @param {string} type follow &#x3D;&gt; フォロー, unfollow &#x3D;&gt; フォロー削除
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        followProduct(productId: string, type: string, options?: any): AxiosPromise<void> {
            return localVarFp.followProduct(productId, type, options).then((request) => request(axios, basePath));
        },
        /**
         * レシピお気に入り追加または削除。追加ユーザーはheaderから確認する
         * @summary Follow a Recipe
         * @param {string} recipeId
         * @param {string} type follow &#x3D;&gt; フォロー, unfollow &#x3D;&gt; フォロー削除
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        followRecipe(recipeId: string, type: string, options?: any): AxiosPromise<void> {
            return localVarFp.followRecipe(recipeId, type, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FollowApi - object-oriented interface
 * @export
 * @class FollowApi
 * @extends {BaseAPI}
 */
export class FollowApi extends BaseAPI {
    /**
     * メーカーお気に入り追加または削除。追加ユーザーはheaderから確認する
     * @summary Follow a Maker
     * @param {string} makerId
     * @param {string} type follow &#x3D;&gt; フォロー, unfollow &#x3D;&gt; フォロー削除
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FollowApi
     */
    public followMaker(makerId: string, type: string, options?: AxiosRequestConfig) {
        return FollowApiFp(this.configuration).followMaker(makerId, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 商品お気に入り追加または削除。追加ユーザーはheaderから確認する
     * @summary Follow a Product
     * @param {string} productId
     * @param {string} type follow &#x3D;&gt; フォロー, unfollow &#x3D;&gt; フォロー削除
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FollowApi
     */
    public followProduct(productId: string, type: string, options?: AxiosRequestConfig) {
        return FollowApiFp(this.configuration).followProduct(productId, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * レシピお気に入り追加または削除。追加ユーザーはheaderから確認する
     * @summary Follow a Recipe
     * @param {string} recipeId
     * @param {string} type follow &#x3D;&gt; フォロー, unfollow &#x3D;&gt; フォロー削除
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FollowApi
     */
    public followRecipe(recipeId: string, type: string, options?: AxiosRequestConfig) {
        return FollowApiFp(this.configuration).followRecipe(recipeId, type, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ImageApi - axios parameter creator
 * @export
 */
export const ImageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * イメージのアップロード
         * @summary uploads an image
         * @param {string} file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadImage: async (file: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('uploadImage', 'file', file)
            const localVarPath = `/image/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (file !== undefined) {
                localVarFormParams.append('file', file as any);
            }


            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ImageApi - functional programming interface
 * @export
 */
export const ImageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ImageApiAxiosParamCreator(configuration)
    return {
        /**
         * イメージのアップロード
         * @summary uploads an image
         * @param {string} file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadImage(file: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImageFile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadImage(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ImageApi - factory interface
 * @export
 */
export const ImageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ImageApiFp(configuration)
    return {
        /**
         * イメージのアップロード
         * @summary uploads an image
         * @param {string} file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadImage(file: string, options?: any): AxiosPromise<ImageFile> {
            return localVarFp.uploadImage(file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ImageApi - object-oriented interface
 * @export
 * @class ImageApi
 * @extends {BaseAPI}
 */
export class ImageApi extends BaseAPI {
    /**
     * イメージのアップロード
     * @summary uploads an image
     * @param {string} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageApi
     */
    public uploadImage(file: string, options?: AxiosRequestConfig) {
        return ImageApiFp(this.configuration).uploadImage(file, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InquiryApi - axios parameter creator
 * @export
 */
export const InquiryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * お問い合わせ
         * @summary Inquiry
         * @param {string} type ・通報・システム障害・操作方法・契約の変更・サンプル関連・その他
         * @param {string} text お問い合わせ内容
         * @param {number} [makerId] 通報・サンプル関連の時、メーカーIDを記載(サンプル関連の時はない場合もあり)
         * @param {number} [productId] サンプル関連の時、商品IDを記載
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inquiry: async (type: string, text: string, makerId?: number, productId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('inquiry', 'type', type)
            // verify required parameter 'text' is not null or undefined
            assertParamExists('inquiry', 'text', text)
            const localVarPath = `/inquiry`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (type !== undefined) {
                localVarFormParams.append('type', type as any);
            }

            if (text !== undefined) {
                localVarFormParams.append('text', text as any);
            }

            if (makerId !== undefined) {
                localVarFormParams.append('makerId', makerId as any);
            }

            if (productId !== undefined) {
                localVarFormParams.append('productId', productId as any);
            }


            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InquiryApi - functional programming interface
 * @export
 */
export const InquiryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InquiryApiAxiosParamCreator(configuration)
    return {
        /**
         * お問い合わせ
         * @summary Inquiry
         * @param {string} type ・通報・システム障害・操作方法・契約の変更・サンプル関連・その他
         * @param {string} text お問い合わせ内容
         * @param {number} [makerId] 通報・サンプル関連の時、メーカーIDを記載(サンプル関連の時はない場合もあり)
         * @param {number} [productId] サンプル関連の時、商品IDを記載
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inquiry(type: string, text: string, makerId?: number, productId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inquiry(type, text, makerId, productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InquiryApi - factory interface
 * @export
 */
export const InquiryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InquiryApiFp(configuration)
    return {
        /**
         * お問い合わせ
         * @summary Inquiry
         * @param {string} type ・通報・システム障害・操作方法・契約の変更・サンプル関連・その他
         * @param {string} text お問い合わせ内容
         * @param {number} [makerId] 通報・サンプル関連の時、メーカーIDを記載(サンプル関連の時はない場合もあり)
         * @param {number} [productId] サンプル関連の時、商品IDを記載
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inquiry(type: string, text: string, makerId?: number, productId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.inquiry(type, text, makerId, productId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InquiryApi - object-oriented interface
 * @export
 * @class InquiryApi
 * @extends {BaseAPI}
 */
export class InquiryApi extends BaseAPI {
    /**
     * お問い合わせ
     * @summary Inquiry
     * @param {string} type ・通報・システム障害・操作方法・契約の変更・サンプル関連・その他
     * @param {string} text お問い合わせ内容
     * @param {number} [makerId] 通報・サンプル関連の時、メーカーIDを記載(サンプル関連の時はない場合もあり)
     * @param {number} [productId] サンプル関連の時、商品IDを記載
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InquiryApi
     */
    public inquiry(type: string, text: string, makerId?: number, productId?: number, options?: AxiosRequestConfig) {
        return InquiryApiFp(this.configuration).inquiry(type, text, makerId, productId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * KeywordApi - axios parameter creator
 * @export
 */
export const KeywordApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Authは無視する。曖昧検索でキーワードを取得（マスター管理画面のtype=keyword）このvalueを部分一致で検索し、ヒットしたレコードとグループが同じでrootが1のもののKeywordを返す。https://docs.google.com/spreadsheets/d/1DCvOREuysKN33Qq20tkSrIEaq4axX9aL7A8Zg4rvP5U/edit#gid=0
         * @summary Finds Fuzzy Keywords
         * @param {string} value
         * @param {'recipe' | 'product' | 'all'} [type] recipe, product, all どちらのキーワードを取得するか指定、項目が無い場合は recipe のキーワードを返す
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFuzzyKeywords: async (value: string, type?: 'recipe' | 'product' | 'all', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'value' is not null or undefined
            assertParamExists('getFuzzyKeywords', 'value', value)
            const localVarPath = `/keyword/find/fuzzy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (value !== undefined) {
                localVarQueryParameter['value'] = value;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Authは無視する。キーワード一覧を取得（マスター管理画面のtype=keyword && root=1）
         * @summary Finds all Keywords
         * @param {number} [num] 取得キーワード数。クエリに無い場合はデフォルトの数を取得
         * @param {'recipe' | 'product'} [type] recipe, product どちらのキーワードを取得するか指定、項目が無い場合は recipe のキーワードを返す
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKeywords: async (num?: number, type?: 'recipe' | 'product', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/keyword/findAll`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (num !== undefined) {
                localVarQueryParameter['num'] = num;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Authは無視する。人気のキーワードを取得（マスター管理画面のtype=keyword && root=1）
         * @summary Finds popular Recipe Categories
         * @param {number} [num] 取得カテゴリー数。クエリに無い場合はデフォルトの数を取得
         * @param {'recipe' | 'product'} [type] recipe, product どちらのキーワードを取得するか指定、項目が無い場合は recipe のキーワードを返す
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPopularKeyword: async (num?: number, type?: 'recipe' | 'product', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/keyword/findPopular`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (num !== undefined) {
                localVarQueryParameter['num'] = num;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KeywordApi - functional programming interface
 * @export
 */
export const KeywordApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = KeywordApiAxiosParamCreator(configuration)
    return {
        /**
         * Authは無視する。曖昧検索でキーワードを取得（マスター管理画面のtype=keyword）このvalueを部分一致で検索し、ヒットしたレコードとグループが同じでrootが1のもののKeywordを返す。https://docs.google.com/spreadsheets/d/1DCvOREuysKN33Qq20tkSrIEaq4axX9aL7A8Zg4rvP5U/edit#gid=0
         * @summary Finds Fuzzy Keywords
         * @param {string} value
         * @param {'recipe' | 'product' | 'all'} [type] recipe, product, all どちらのキーワードを取得するか指定、項目が無い場合は recipe のキーワードを返す
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFuzzyKeywords(value: string, type?: 'recipe' | 'product' | 'all', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Keyword>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFuzzyKeywords(value, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Authは無視する。キーワード一覧を取得（マスター管理画面のtype=keyword && root=1）
         * @summary Finds all Keywords
         * @param {number} [num] 取得キーワード数。クエリに無い場合はデフォルトの数を取得
         * @param {'recipe' | 'product'} [type] recipe, product どちらのキーワードを取得するか指定、項目が無い場合は recipe のキーワードを返す
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getKeywords(num?: number, type?: 'recipe' | 'product', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Keyword>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getKeywords(num, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Authは無視する。人気のキーワードを取得（マスター管理画面のtype=keyword && root=1）
         * @summary Finds popular Recipe Categories
         * @param {number} [num] 取得カテゴリー数。クエリに無い場合はデフォルトの数を取得
         * @param {'recipe' | 'product'} [type] recipe, product どちらのキーワードを取得するか指定、項目が無い場合は recipe のキーワードを返す
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPopularKeyword(num?: number, type?: 'recipe' | 'product', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Keyword>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPopularKeyword(num, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * KeywordApi - factory interface
 * @export
 */
export const KeywordApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = KeywordApiFp(configuration)
    return {
        /**
         * Authは無視する。曖昧検索でキーワードを取得（マスター管理画面のtype=keyword）このvalueを部分一致で検索し、ヒットしたレコードとグループが同じでrootが1のもののKeywordを返す。https://docs.google.com/spreadsheets/d/1DCvOREuysKN33Qq20tkSrIEaq4axX9aL7A8Zg4rvP5U/edit#gid=0
         * @summary Finds Fuzzy Keywords
         * @param {string} value
         * @param {'recipe' | 'product' | 'all'} [type] recipe, product, all どちらのキーワードを取得するか指定、項目が無い場合は recipe のキーワードを返す
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFuzzyKeywords(value: string, type?: 'recipe' | 'product' | 'all', options?: any): AxiosPromise<Array<Keyword>> {
            return localVarFp.getFuzzyKeywords(value, type, options).then((request) => request(axios, basePath));
        },
        /**
         * Authは無視する。キーワード一覧を取得（マスター管理画面のtype=keyword && root=1）
         * @summary Finds all Keywords
         * @param {number} [num] 取得キーワード数。クエリに無い場合はデフォルトの数を取得
         * @param {'recipe' | 'product'} [type] recipe, product どちらのキーワードを取得するか指定、項目が無い場合は recipe のキーワードを返す
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKeywords(num?: number, type?: 'recipe' | 'product', options?: any): AxiosPromise<Array<Keyword>> {
            return localVarFp.getKeywords(num, type, options).then((request) => request(axios, basePath));
        },
        /**
         * Authは無視する。人気のキーワードを取得（マスター管理画面のtype=keyword && root=1）
         * @summary Finds popular Recipe Categories
         * @param {number} [num] 取得カテゴリー数。クエリに無い場合はデフォルトの数を取得
         * @param {'recipe' | 'product'} [type] recipe, product どちらのキーワードを取得するか指定、項目が無い場合は recipe のキーワードを返す
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPopularKeyword(num?: number, type?: 'recipe' | 'product', options?: any): AxiosPromise<Array<Keyword>> {
            return localVarFp.getPopularKeyword(num, type, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * KeywordApi - object-oriented interface
 * @export
 * @class KeywordApi
 * @extends {BaseAPI}
 */
export class KeywordApi extends BaseAPI {
    /**
     * Authは無視する。曖昧検索でキーワードを取得（マスター管理画面のtype=keyword）このvalueを部分一致で検索し、ヒットしたレコードとグループが同じでrootが1のもののKeywordを返す。https://docs.google.com/spreadsheets/d/1DCvOREuysKN33Qq20tkSrIEaq4axX9aL7A8Zg4rvP5U/edit#gid=0
     * @summary Finds Fuzzy Keywords
     * @param {string} value
     * @param {'recipe' | 'product' | 'all'} [type] recipe, product, all どちらのキーワードを取得するか指定、項目が無い場合は recipe のキーワードを返す
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KeywordApi
     */
    public getFuzzyKeywords(value: string, type?: 'recipe' | 'product' | 'all', options?: AxiosRequestConfig) {
        return KeywordApiFp(this.configuration).getFuzzyKeywords(value, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Authは無視する。キーワード一覧を取得（マスター管理画面のtype=keyword && root=1）
     * @summary Finds all Keywords
     * @param {number} [num] 取得キーワード数。クエリに無い場合はデフォルトの数を取得
     * @param {'recipe' | 'product'} [type] recipe, product どちらのキーワードを取得するか指定、項目が無い場合は recipe のキーワードを返す
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KeywordApi
     */
    public getKeywords(num?: number, type?: 'recipe' | 'product', options?: AxiosRequestConfig) {
        return KeywordApiFp(this.configuration).getKeywords(num, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Authは無視する。人気のキーワードを取得（マスター管理画面のtype=keyword && root=1）
     * @summary Finds popular Recipe Categories
     * @param {number} [num] 取得カテゴリー数。クエリに無い場合はデフォルトの数を取得
     * @param {'recipe' | 'product'} [type] recipe, product どちらのキーワードを取得するか指定、項目が無い場合は recipe のキーワードを返す
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KeywordApi
     */
    public getPopularKeyword(num?: number, type?: 'recipe' | 'product', options?: AxiosRequestConfig) {
        return KeywordApiFp(this.configuration).getPopularKeyword(num, type, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MakerApi - axios parameter creator
 * @export
 */
export const MakerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Authは無視する。メーカー検索。あいおうえお順ソート、あいおうえおの次にアルファベット順(from番目〜to番目まで取得)
         * @summary Finds Makers
         * @param {number} from 0スタート
         * @param {number} to
         * @param {number} [followed] 0/1,立ってる場合フォローしているものを取得(headerからユーザーを取得)
         * @param {number} [imageSizeId] 画像サイズID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findMakers: async (from: number, to: number, followed?: number, imageSizeId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'from' is not null or undefined
            assertParamExists('findMakers', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('findMakers', 'to', to)
            const localVarPath = `/maker/find`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (followed !== undefined) {
                localVarQueryParameter['followed'] = followed;
            }

            if (imageSizeId !== undefined) {
                localVarQueryParameter['imageSizeId'] = imageSizeId;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Authは無視する。単一メーカー情報を取得
         * @summary Finds a Maker by id
         * @param {string} id メーカーID
         * @param {number} [imageSizeId] 画像サイズID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMaker: async (id: string, imageSizeId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getMaker', 'id', id)
            const localVarPath = `/maker/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (imageSizeId !== undefined) {
                localVarQueryParameter['imageSizeId'] = imageSizeId;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MakerApi - functional programming interface
 * @export
 */
export const MakerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MakerApiAxiosParamCreator(configuration)
    return {
        /**
         * Authは無視する。メーカー検索。あいおうえお順ソート、あいおうえおの次にアルファベット順(from番目〜to番目まで取得)
         * @summary Finds Makers
         * @param {number} from 0スタート
         * @param {number} to
         * @param {number} [followed] 0/1,立ってる場合フォローしているものを取得(headerからユーザーを取得)
         * @param {number} [imageSizeId] 画像サイズID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findMakers(from: number, to: number, followed?: number, imageSizeId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Maker>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findMakers(from, to, followed, imageSizeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Authは無視する。単一メーカー情報を取得
         * @summary Finds a Maker by id
         * @param {string} id メーカーID
         * @param {number} [imageSizeId] 画像サイズID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMaker(id: string, imageSizeId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Maker>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMaker(id, imageSizeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MakerApi - factory interface
 * @export
 */
export const MakerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MakerApiFp(configuration)
    return {
        /**
         * Authは無視する。メーカー検索。あいおうえお順ソート、あいおうえおの次にアルファベット順(from番目〜to番目まで取得)
         * @summary Finds Makers
         * @param {number} from 0スタート
         * @param {number} to
         * @param {number} [followed] 0/1,立ってる場合フォローしているものを取得(headerからユーザーを取得)
         * @param {number} [imageSizeId] 画像サイズID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findMakers(from: number, to: number, followed?: number, imageSizeId?: number, options?: any): AxiosPromise<Array<Maker>> {
            return localVarFp.findMakers(from, to, followed, imageSizeId, options).then((request) => request(axios, basePath));
        },
        /**
         * Authは無視する。単一メーカー情報を取得
         * @summary Finds a Maker by id
         * @param {string} id メーカーID
         * @param {number} [imageSizeId] 画像サイズID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMaker(id: string, imageSizeId?: number, options?: any): AxiosPromise<Maker> {
            return localVarFp.getMaker(id, imageSizeId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MakerApi - object-oriented interface
 * @export
 * @class MakerApi
 * @extends {BaseAPI}
 */
export class MakerApi extends BaseAPI {
    /**
     * Authは無視する。メーカー検索。あいおうえお順ソート、あいおうえおの次にアルファベット順(from番目〜to番目まで取得)
     * @summary Finds Makers
     * @param {number} from 0スタート
     * @param {number} to
     * @param {number} [followed] 0/1,立ってる場合フォローしているものを取得(headerからユーザーを取得)
     * @param {number} [imageSizeId] 画像サイズID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MakerApi
     */
    public findMakers(from: number, to: number, followed?: number, imageSizeId?: number, options?: AxiosRequestConfig) {
        return MakerApiFp(this.configuration).findMakers(from, to, followed, imageSizeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Authは無視する。単一メーカー情報を取得
     * @summary Finds a Maker by id
     * @param {string} id メーカーID
     * @param {number} [imageSizeId] 画像サイズID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MakerApi
     */
    public getMaker(id: string, imageSizeId?: number, options?: AxiosRequestConfig) {
        return MakerApiFp(this.configuration).getMaker(id, imageSizeId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MasterApi - axios parameter creator
 * @export
 */
export const MasterApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Authは無視する。客単価を取得
         * @summary Finds All CustomerUnitPriceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCustomerUnitPriceIdsMaster: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/master/customerUnitPriceId/findAll`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Authは無視する。主要店舗展開エリアを取得
         * @summary Finds All MajorRestaurantAreaId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllMajorRestaurantAreaIdMaster: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/master/majorRestaurantAreaId/findAll`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Authは無視する。店舗規模を取得
         * @summary Finds All NumOfRestaurantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllNumOfRestaurantIdMaster: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/master/numOfRestaurantId/findAll`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Authは無視する。商品カテゴリーを取得
         * @summary Finds All Product Categories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllProductCategories: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/master/category/product/findAll`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Authは無視する。レシピカテゴリーを取得
         * @summary Finds All Recipe Categories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllRecipeCategoriesMaster: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/master/category/recipe/findAll`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Authは無視する。店舗業態を取得
         * @summary Finds All RestauranteFormat
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllRestaurantFormatMaster: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/master/RestaurantFormat/findAll`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Authは無視する。店舗所在地を取得
         * @summary Finds All RestaurantLocation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllRestaurantLocationMaster: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/master/RestaurantLocation/findAll`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MasterApi - functional programming interface
 * @export
 */
export const MasterApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MasterApiAxiosParamCreator(configuration)
    return {
        /**
         * Authは無視する。客単価を取得
         * @summary Finds All CustomerUnitPriceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllCustomerUnitPriceIdsMaster(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CustomerUnitPriceId>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllCustomerUnitPriceIdsMaster(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Authは無視する。主要店舗展開エリアを取得
         * @summary Finds All MajorRestaurantAreaId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllMajorRestaurantAreaIdMaster(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MajorRestaurantAreaId>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllMajorRestaurantAreaIdMaster(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Authは無視する。店舗規模を取得
         * @summary Finds All NumOfRestaurantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllNumOfRestaurantIdMaster(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NumOfRestaurantId>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllNumOfRestaurantIdMaster(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Authは無視する。商品カテゴリーを取得
         * @summary Finds All Product Categories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllProductCategories(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductCategory>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllProductCategories(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Authは無視する。レシピカテゴリーを取得
         * @summary Finds All Recipe Categories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllRecipeCategoriesMaster(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RecipeCategory>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllRecipeCategoriesMaster(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Authは無視する。店舗業態を取得
         * @summary Finds All RestauranteFormat
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllRestaurantFormatMaster(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RestaurantFormat>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllRestaurantFormatMaster(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Authは無視する。店舗所在地を取得
         * @summary Finds All RestaurantLocation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllRestaurantLocationMaster(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RestaurantLocation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllRestaurantLocationMaster(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MasterApi - factory interface
 * @export
 */
export const MasterApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MasterApiFp(configuration)
    return {
        /**
         * Authは無視する。客単価を取得
         * @summary Finds All CustomerUnitPriceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCustomerUnitPriceIdsMaster(options?: any): AxiosPromise<Array<CustomerUnitPriceId>> {
            return localVarFp.getAllCustomerUnitPriceIdsMaster(options).then((request) => request(axios, basePath));
        },
        /**
         * Authは無視する。主要店舗展開エリアを取得
         * @summary Finds All MajorRestaurantAreaId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllMajorRestaurantAreaIdMaster(options?: any): AxiosPromise<Array<MajorRestaurantAreaId>> {
            return localVarFp.getAllMajorRestaurantAreaIdMaster(options).then((request) => request(axios, basePath));
        },
        /**
         * Authは無視する。店舗規模を取得
         * @summary Finds All NumOfRestaurantId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllNumOfRestaurantIdMaster(options?: any): AxiosPromise<Array<NumOfRestaurantId>> {
            return localVarFp.getAllNumOfRestaurantIdMaster(options).then((request) => request(axios, basePath));
        },
        /**
         * Authは無視する。商品カテゴリーを取得
         * @summary Finds All Product Categories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllProductCategories(options?: any): AxiosPromise<Array<ProductCategory>> {
            return localVarFp.getAllProductCategories(options).then((request) => request(axios, basePath));
        },
        /**
         * Authは無視する。レシピカテゴリーを取得
         * @summary Finds All Recipe Categories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllRecipeCategoriesMaster(options?: any): AxiosPromise<Array<RecipeCategory>> {
            return localVarFp.getAllRecipeCategoriesMaster(options).then((request) => request(axios, basePath));
        },
        /**
         * Authは無視する。店舗業態を取得
         * @summary Finds All RestauranteFormat
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllRestaurantFormatMaster(options?: any): AxiosPromise<Array<RestaurantFormat>> {
            return localVarFp.getAllRestaurantFormatMaster(options).then((request) => request(axios, basePath));
        },
        /**
         * Authは無視する。店舗所在地を取得
         * @summary Finds All RestaurantLocation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllRestaurantLocationMaster(options?: any): AxiosPromise<Array<RestaurantLocation>> {
            return localVarFp.getAllRestaurantLocationMaster(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MasterApi - object-oriented interface
 * @export
 * @class MasterApi
 * @extends {BaseAPI}
 */
export class MasterApi extends BaseAPI {
    /**
     * Authは無視する。客単価を取得
     * @summary Finds All CustomerUnitPriceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MasterApi
     */
    public getAllCustomerUnitPriceIdsMaster(options?: AxiosRequestConfig) {
        return MasterApiFp(this.configuration).getAllCustomerUnitPriceIdsMaster(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Authは無視する。主要店舗展開エリアを取得
     * @summary Finds All MajorRestaurantAreaId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MasterApi
     */
    public getAllMajorRestaurantAreaIdMaster(options?: AxiosRequestConfig) {
        return MasterApiFp(this.configuration).getAllMajorRestaurantAreaIdMaster(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Authは無視する。店舗規模を取得
     * @summary Finds All NumOfRestaurantId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MasterApi
     */
    public getAllNumOfRestaurantIdMaster(options?: AxiosRequestConfig) {
        return MasterApiFp(this.configuration).getAllNumOfRestaurantIdMaster(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Authは無視する。商品カテゴリーを取得
     * @summary Finds All Product Categories
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MasterApi
     */
    public getAllProductCategories(options?: AxiosRequestConfig) {
        return MasterApiFp(this.configuration).getAllProductCategories(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Authは無視する。レシピカテゴリーを取得
     * @summary Finds All Recipe Categories
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MasterApi
     */
    public getAllRecipeCategoriesMaster(options?: AxiosRequestConfig) {
        return MasterApiFp(this.configuration).getAllRecipeCategoriesMaster(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Authは無視する。店舗業態を取得
     * @summary Finds All RestauranteFormat
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MasterApi
     */
    public getAllRestaurantFormatMaster(options?: AxiosRequestConfig) {
        return MasterApiFp(this.configuration).getAllRestaurantFormatMaster(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Authは無視する。店舗所在地を取得
     * @summary Finds All RestaurantLocation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MasterApi
     */
    public getAllRestaurantLocationMaster(options?: AxiosRequestConfig) {
        return MasterApiFp(this.configuration).getAllRestaurantLocationMaster(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MessageApi - axios parameter creator
 * @export
 */
export const MessageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * メッセージを送信する(ユーザーはHeaderから確認する)、firebaseを基本使うが、最新のメッセージだけはサーバーでも管理する
         * @summary Send a Message
         * @param {string} chatId
         * @param {string} type
         * @param {string} [text] typeがimageの時は画像URL
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendMessage: async (chatId: string, type: string, text?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'chatId' is not null or undefined
            assertParamExists('sendMessage', 'chatId', chatId)
            // verify required parameter 'type' is not null or undefined
            assertParamExists('sendMessage', 'type', type)
            const localVarPath = `/message/send`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (chatId !== undefined) {
                localVarFormParams.append('chatId', chatId as any);
            }

            if (text !== undefined) {
                localVarFormParams.append('text', text as any);
            }

            if (type !== undefined) {
                localVarFormParams.append('type', type as any);
            }


            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MessageApi - functional programming interface
 * @export
 */
export const MessageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MessageApiAxiosParamCreator(configuration)
    return {
        /**
         * メッセージを送信する(ユーザーはHeaderから確認する)、firebaseを基本使うが、最新のメッセージだけはサーバーでも管理する
         * @summary Send a Message
         * @param {string} chatId
         * @param {string} type
         * @param {string} [text] typeがimageの時は画像URL
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendMessage(chatId: string, type: string, text?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendMessage(chatId, type, text, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MessageApi - factory interface
 * @export
 */
export const MessageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MessageApiFp(configuration)
    return {
        /**
         * メッセージを送信する(ユーザーはHeaderから確認する)、firebaseを基本使うが、最新のメッセージだけはサーバーでも管理する
         * @summary Send a Message
         * @param {string} chatId
         * @param {string} type
         * @param {string} [text] typeがimageの時は画像URL
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendMessage(chatId: string, type: string, text?: string, options?: any): AxiosPromise<void> {
            return localVarFp.sendMessage(chatId, type, text, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MessageApi - object-oriented interface
 * @export
 * @class MessageApi
 * @extends {BaseAPI}
 */
export class MessageApi extends BaseAPI {
    /**
     * メッセージを送信する(ユーザーはHeaderから確認する)、firebaseを基本使うが、最新のメッセージだけはサーバーでも管理する
     * @summary Send a Message
     * @param {string} chatId
     * @param {string} type
     * @param {string} [text] typeがimageの時は画像URL
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public sendMessage(chatId: string, type: string, text?: string, options?: AxiosRequestConfig) {
        return MessageApiFp(this.configuration).sendMessage(chatId, type, text, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NewsApi - axios parameter creator
 * @export
 */
export const NewsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * お知らせや催促の未読数を取得
         * @summary get Amount of Unread
         * @param {'news' | 'reminder' | 'all'} type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAmountOfUnreadNews: async (type: 'news' | 'reminder' | 'all', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('getAmountOfUnreadNews', 'type', type)
            const localVarPath = `/news/amount/unread`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Authはがない場合、共通のお知らせのみ返す。お知らせ・催促一覧を取得(新着の日付順でfrom番目〜to番目まで取得)
         * @summary Finds News
         * @param {number} from 0スタート
         * @param {number} to
         * @param {'news' | 'reminder' | 'all'} type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNews: async (from: number, to: number, type: 'news' | 'reminder' | 'all', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'from' is not null or undefined
            assertParamExists('getNews', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('getNews', 'to', to)
            // verify required parameter 'type' is not null or undefined
            assertParamExists('getNews', 'type', type)
            const localVarPath = `/news/find`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Authはがない場合、共通のお知らせのみ返す。お知らせ・催促を取得(新着の日付順でfrom番目〜to番目まで取得)
         * @summary get News
         * @param {string} id News ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewsContent: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getNewsContent', 'id', id)
            const localVarPath = `/news/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * お知らせ・催促を既読にする(ユーザーはHeaderから確認する)
         * @summary Read News
         * @param {number} newsId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readNews: async (newsId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'newsId' is not null or undefined
            assertParamExists('readNews', 'newsId', newsId)
            const localVarPath = `/news/read`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (newsId !== undefined) {
                localVarFormParams.append('newsId', newsId as any);
            }


            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NewsApi - functional programming interface
 * @export
 */
export const NewsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NewsApiAxiosParamCreator(configuration)
    return {
        /**
         * お知らせや催促の未読数を取得
         * @summary get Amount of Unread
         * @param {'news' | 'reminder' | 'all'} type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAmountOfUnreadNews(type: 'news' | 'reminder' | 'all', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAmountOfUnreadNews200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAmountOfUnreadNews(type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Authはがない場合、共通のお知らせのみ返す。お知らせ・催促一覧を取得(新着の日付順でfrom番目〜to番目まで取得)
         * @summary Finds News
         * @param {number} from 0スタート
         * @param {number} to
         * @param {'news' | 'reminder' | 'all'} type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNews(from: number, to: number, type: 'news' | 'reminder' | 'all', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<News>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNews(from, to, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Authはがない場合、共通のお知らせのみ返す。お知らせ・催促を取得(新着の日付順でfrom番目〜to番目まで取得)
         * @summary get News
         * @param {string} id News ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNewsContent(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<News>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNewsContent(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * お知らせ・催促を既読にする(ユーザーはHeaderから確認する)
         * @summary Read News
         * @param {number} newsId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readNews(newsId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readNews(newsId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NewsApi - factory interface
 * @export
 */
export const NewsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NewsApiFp(configuration)
    return {
        /**
         * お知らせや催促の未読数を取得
         * @summary get Amount of Unread
         * @param {'news' | 'reminder' | 'all'} type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAmountOfUnreadNews(type: 'news' | 'reminder' | 'all', options?: any): AxiosPromise<GetAmountOfUnreadNews200Response> {
            return localVarFp.getAmountOfUnreadNews(type, options).then((request) => request(axios, basePath));
        },
        /**
         * Authはがない場合、共通のお知らせのみ返す。お知らせ・催促一覧を取得(新着の日付順でfrom番目〜to番目まで取得)
         * @summary Finds News
         * @param {number} from 0スタート
         * @param {number} to
         * @param {'news' | 'reminder' | 'all'} type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNews(from: number, to: number, type: 'news' | 'reminder' | 'all', options?: any): AxiosPromise<Array<News>> {
            return localVarFp.getNews(from, to, type, options).then((request) => request(axios, basePath));
        },
        /**
         * Authはがない場合、共通のお知らせのみ返す。お知らせ・催促を取得(新着の日付順でfrom番目〜to番目まで取得)
         * @summary get News
         * @param {string} id News ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewsContent(id: string, options?: any): AxiosPromise<News> {
            return localVarFp.getNewsContent(id, options).then((request) => request(axios, basePath));
        },
        /**
         * お知らせ・催促を既読にする(ユーザーはHeaderから確認する)
         * @summary Read News
         * @param {number} newsId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readNews(newsId: number, options?: any): AxiosPromise<void> {
            return localVarFp.readNews(newsId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NewsApi - object-oriented interface
 * @export
 * @class NewsApi
 * @extends {BaseAPI}
 */
export class NewsApi extends BaseAPI {
    /**
     * お知らせや催促の未読数を取得
     * @summary get Amount of Unread
     * @param {'news' | 'reminder' | 'all'} type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApi
     */
    public getAmountOfUnreadNews(type: 'news' | 'reminder' | 'all', options?: AxiosRequestConfig) {
        return NewsApiFp(this.configuration).getAmountOfUnreadNews(type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Authはがない場合、共通のお知らせのみ返す。お知らせ・催促一覧を取得(新着の日付順でfrom番目〜to番目まで取得)
     * @summary Finds News
     * @param {number} from 0スタート
     * @param {number} to
     * @param {'news' | 'reminder' | 'all'} type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApi
     */
    public getNews(from: number, to: number, type: 'news' | 'reminder' | 'all', options?: AxiosRequestConfig) {
        return NewsApiFp(this.configuration).getNews(from, to, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Authはがない場合、共通のお知らせのみ返す。お知らせ・催促を取得(新着の日付順でfrom番目〜to番目まで取得)
     * @summary get News
     * @param {string} id News ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApi
     */
    public getNewsContent(id: string, options?: AxiosRequestConfig) {
        return NewsApiFp(this.configuration).getNewsContent(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * お知らせ・催促を既読にする(ユーザーはHeaderから確認する)
     * @summary Read News
     * @param {number} newsId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApi
     */
    public readNews(newsId: number, options?: AxiosRequestConfig) {
        return NewsApiFp(this.configuration).readNews(newsId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * POPUPApi - axios parameter creator
 * @export
 */
export const POPUPApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * POPUPのアンケートへの回答を行う
         * @summary Answer Popup Questionnaires
         * @param {number} id アンケート項目ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        answerPopup: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('answerPopup', 'id', id)
            const localVarPath = `/popup/answer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (id !== undefined) {
                localVarFormParams.append('id', id as any);
            }


            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ユーザー情報(a_id)から表示対象のPOPUPの最新1件を取得
         * @summary Popup Get
         * @param {string} displayScreen 表示したい画面名(トップ：HomeScreen,レシピ：HomeScreen_recipe,商品：HomeScreen_product,メーカー：HomeScreen_maker,ランキング：HomeScreen_ranking)
         * @param {string} aId hash
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPopups: async (displayScreen: string, aId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'displayScreen' is not null or undefined
            assertParamExists('getPopups', 'displayScreen', displayScreen)
            // verify required parameter 'aId' is not null or undefined
            assertParamExists('getPopups', 'aId', aId)
            const localVarPath = `/popup/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (displayScreen !== undefined) {
                localVarQueryParameter['displayScreen'] = displayScreen;
            }

            if (aId !== undefined) {
                localVarQueryParameter['a_id'] = aId;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * POPUPを既読にする(ユーザーはa_idから確認する)
         * @summary Read Popup
         * @param {number} popupId
         * @param {string} aId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPopup: async (popupId: number, aId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'popupId' is not null or undefined
            assertParamExists('readPopup', 'popupId', popupId)
            // verify required parameter 'aId' is not null or undefined
            assertParamExists('readPopup', 'aId', aId)
            const localVarPath = `/popup/read`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (popupId !== undefined) {
                localVarFormParams.append('popupId', popupId as any);
            }

            if (aId !== undefined) {
                localVarFormParams.append('a_id', aId as any);
            }


            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * POPUPApi - functional programming interface
 * @export
 */
export const POPUPApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = POPUPApiAxiosParamCreator(configuration)
    return {
        /**
         * POPUPのアンケートへの回答を行う
         * @summary Answer Popup Questionnaires
         * @param {number} id アンケート項目ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async answerPopup(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.answerPopup(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ユーザー情報(a_id)から表示対象のPOPUPの最新1件を取得
         * @summary Popup Get
         * @param {string} displayScreen 表示したい画面名(トップ：HomeScreen,レシピ：HomeScreen_recipe,商品：HomeScreen_product,メーカー：HomeScreen_maker,ランキング：HomeScreen_ranking)
         * @param {string} aId hash
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPopups(displayScreen: string, aId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Popup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPopups(displayScreen, aId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * POPUPを既読にする(ユーザーはa_idから確認する)
         * @summary Read Popup
         * @param {number} popupId
         * @param {string} aId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readPopup(popupId: number, aId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readPopup(popupId, aId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * POPUPApi - factory interface
 * @export
 */
export const POPUPApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = POPUPApiFp(configuration)
    return {
        /**
         * POPUPのアンケートへの回答を行う
         * @summary Answer Popup Questionnaires
         * @param {number} id アンケート項目ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        answerPopup(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.answerPopup(id, options).then((request) => request(axios, basePath));
        },
        /**
         * ユーザー情報(a_id)から表示対象のPOPUPの最新1件を取得
         * @summary Popup Get
         * @param {string} displayScreen 表示したい画面名(トップ：HomeScreen,レシピ：HomeScreen_recipe,商品：HomeScreen_product,メーカー：HomeScreen_maker,ランキング：HomeScreen_ranking)
         * @param {string} aId hash
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPopups(displayScreen: string, aId: string, options?: any): AxiosPromise<Popup> {
            return localVarFp.getPopups(displayScreen, aId, options).then((request) => request(axios, basePath));
        },
        /**
         * POPUPを既読にする(ユーザーはa_idから確認する)
         * @summary Read Popup
         * @param {number} popupId
         * @param {string} aId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPopup(popupId: number, aId: string, options?: any): AxiosPromise<void> {
            return localVarFp.readPopup(popupId, aId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * POPUPApi - object-oriented interface
 * @export
 * @class POPUPApi
 * @extends {BaseAPI}
 */
export class POPUPApi extends BaseAPI {
    /**
     * POPUPのアンケートへの回答を行う
     * @summary Answer Popup Questionnaires
     * @param {number} id アンケート項目ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof POPUPApi
     */
    public answerPopup(id: number, options?: AxiosRequestConfig) {
        return POPUPApiFp(this.configuration).answerPopup(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ユーザー情報(a_id)から表示対象のPOPUPの最新1件を取得
     * @summary Popup Get
     * @param {string} displayScreen 表示したい画面名(トップ：HomeScreen,レシピ：HomeScreen_recipe,商品：HomeScreen_product,メーカー：HomeScreen_maker,ランキング：HomeScreen_ranking)
     * @param {string} aId hash
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof POPUPApi
     */
    public getPopups(displayScreen: string, aId: string, options?: AxiosRequestConfig) {
        return POPUPApiFp(this.configuration).getPopups(displayScreen, aId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * POPUPを既読にする(ユーザーはa_idから確認する)
     * @summary Read Popup
     * @param {number} popupId
     * @param {string} aId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof POPUPApi
     */
    public readPopup(popupId: number, aId: string, options?: AxiosRequestConfig) {
        return POPUPApiFp(this.configuration).readPopup(popupId, aId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProductApi - axios parameter creator
 * @export
 */
export const ProductApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Authは無視する。この条件で商品検索した場合の商品数
         * @summary Counts Products
         * @param {number} [mediumCategoryId] 商品カテゴリの中分類
         * @param {number} [makerId] メーカーID
         * @param {string} [freeWord] フリーワード(メーカー名/商品名/商品名かな/商品紹介/商品カテゴリー（大）/商品カテゴリー（中）)
         * @param {number} [isRecommend] 0/1,立ってる場合おすすめ商品の取得
         * @param {string} [makerIds] メーカーIDの複数形、「|」区切り、空の時は「||」。
         * @param {string} [largeCategoryIds] 商品カテゴリの大分類複数形、「|」区切り、空の時は「||」。
         * @param {string} [keyword] キーワード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countProducts: async (mediumCategoryId?: number, makerId?: number, freeWord?: string, isRecommend?: number, makerIds?: string, largeCategoryIds?: string, keyword?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/product/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (mediumCategoryId !== undefined) {
                localVarQueryParameter['mediumCategoryId'] = mediumCategoryId;
            }

            if (makerId !== undefined) {
                localVarQueryParameter['makerId'] = makerId;
            }

            if (freeWord !== undefined) {
                localVarQueryParameter['freeWord'] = freeWord;
            }

            if (isRecommend !== undefined) {
                localVarQueryParameter['isRecommend'] = isRecommend;
            }

            if (makerIds !== undefined) {
                localVarQueryParameter['makerIds'] = makerIds;
            }

            if (largeCategoryIds !== undefined) {
                localVarQueryParameter['largeCategoryIds'] = largeCategoryIds;
            }

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Authは無視する。商品検索(from番目〜to番目まで取得)
         * @summary Finds Products
         * @param {number} from 0スタート
         * @param {number} to
         * @param {number} [followed] 0/1,立ってる場合フォローしているものを取得(headerからユーザーを取得)
         * @param {number} [cart] 0/1,立ってる場合カートに入れているものを取得(headerからユーザーを取得)
         * @param {number} [mediumCategoryId] 商品カテゴリの中分類
         * @param {number} [makerId] メーカーID
         * @param {string} [freeWord] フリーワード(メーカー名/商品名/商品名かな/商品紹介/商品カテゴリー（大）/商品カテゴリー（中）)
         * @param {number} [imageSizeId] 画像サイズID
         * @param {number} [isRecommend] 0/1,立ってる場合おすすめ商品の取得
         * @param {'recommend' | 'new' | 'pv' | 'update'} [sortType] ソートの種類パラムが無い場合デフォルト。「おすすめ順(デフォルト)、新着順、PV数順、最近更新された順」おすすめ順は同じ中分類カテゴリーの商品を近くに並べる
         * @param {string} [keyword] キーワード
         * @param {string} [makerIds] メーカーIDの複数形、「|」区切り、空の時は「||」。
         * @param {string} [largeCategoryIds] 商品カテゴリの大分類複数形、「|」区切り、空の時は「||」。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findProducts: async (from: number, to: number, followed?: number, cart?: number, mediumCategoryId?: number, makerId?: number, freeWord?: string, imageSizeId?: number, isRecommend?: number, sortType?: 'recommend' | 'new' | 'pv' | 'update', keyword?: string, makerIds?: string, largeCategoryIds?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'from' is not null or undefined
            assertParamExists('findProducts', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('findProducts', 'to', to)
            const localVarPath = `/product/find`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (followed !== undefined) {
                localVarQueryParameter['followed'] = followed;
            }

            if (cart !== undefined) {
                localVarQueryParameter['cart'] = cart;
            }

            if (mediumCategoryId !== undefined) {
                localVarQueryParameter['mediumCategoryId'] = mediumCategoryId;
            }

            if (makerId !== undefined) {
                localVarQueryParameter['makerId'] = makerId;
            }

            if (freeWord !== undefined) {
                localVarQueryParameter['freeWord'] = freeWord;
            }

            if (imageSizeId !== undefined) {
                localVarQueryParameter['imageSizeId'] = imageSizeId;
            }

            if (isRecommend !== undefined) {
                localVarQueryParameter['isRecommend'] = isRecommend;
            }

            if (sortType !== undefined) {
                localVarQueryParameter['sortType'] = sortType;
            }

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (makerIds !== undefined) {
                localVarQueryParameter['makerIds'] = makerIds;
            }

            if (largeCategoryIds !== undefined) {
                localVarQueryParameter['largeCategoryIds'] = largeCategoryIds;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Authは無視する。単一商品情報を取得
         * @summary get Product ec shops by id
         * @param {number} id 商品ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEc: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getEc', 'id', id)
            const localVarPath = `/product/ec/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Authは無視する。新着商品情報を取得
         * @summary Finds new Products
         * @param {number} [num] 取得レシピ数。クエリに無い場合はデフォルトの数を取得
         * @param {number} [imageSizeId] 画像サイズID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewProduct: async (num?: number, imageSizeId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/product/findNew`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (num !== undefined) {
                localVarQueryParameter['num'] = num;
            }

            if (imageSizeId !== undefined) {
                localVarQueryParameter['imageSizeId'] = imageSizeId;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Authは無視する。単一商品情報を取得
         * @summary Finds a Product by id
         * @param {string} id 商品ID
         * @param {number} pv PV立てるフラグ（これが1の時、商品PVをカウントする）
         * @param {number} [imageSizeId] 画像サイズID
         * @param {number} [isPreview] 下書き・非公開のものを見ることが出来るかどうか(0 or undefined &#x3D;&gt; 取得不可 / 1 &#x3D;&gt; 取得可)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProduct: async (id: string, pv: number, imageSizeId?: number, isPreview?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getProduct', 'id', id)
            // verify required parameter 'pv' is not null or undefined
            assertParamExists('getProduct', 'pv', pv)
            const localVarPath = `/product/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pv !== undefined) {
                localVarQueryParameter['pv'] = pv;
            }

            if (imageSizeId !== undefined) {
                localVarQueryParameter['imageSizeId'] = imageSizeId;
            }

            if (isPreview !== undefined) {
                localVarQueryParameter['isPreview'] = isPreview;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * お気に入り商品の判別
         * @summary Judge Product Follow Status by id
         * @param {number} id 商品ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductFollow: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getProductFollow', 'id', id)
            const localVarPath = `/product/follow/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Authは無視する。レシピランキングを取得
         * @summary Finds Products Ranking
         * @param {'week'} type 取得商品タイプ
         * @param {number} from 0スタート
         * @param {number} to
         * @param {number} [imageSizeId] 画像サイズID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductRanking: async (type: 'week', from: number, to: number, imageSizeId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('getProductRanking', 'type', type)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('getProductRanking', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('getProductRanking', 'to', to)
            const localVarPath = `/product/ranking/{type}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (imageSizeId !== undefined) {
                localVarQueryParameter['imageSizeId'] = imageSizeId;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Authは無視する。注目のキーワード商品情報を取得（指定されたキーワードでfindのおすすめ順と同じ結果になるようにする）また、管理画面から登録したデータがあればそちらを優先する
         * @summary Finds recommend keyword Products
         * @param {number} [num] 注目のキーワードの取得数
         * @param {number} [imageSizeId] 画像サイズID
         * @param {number} [key] キーワードの検索結果を複数保存しているので、そのどれを使うかの数字画面の上から1.2.3 と振るので、API先で選択する。注目のキーワードは作成バッチ毎にkeyを変えること
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecommendKeywordProduct: async (num?: number, imageSizeId?: number, key?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/product/findRecommendKeyword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (num !== undefined) {
                localVarQueryParameter['num'] = num;
            }

            if (imageSizeId !== undefined) {
                localVarQueryParameter['imageSizeId'] = imageSizeId;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * このユーザーがサンプル依頼した一覧を取得（サンプル依頼日でソートしてから返す）
         * @summary Requeeted Product Reviews
         * @param {number} [imageSizeId] 商品の画像サイズID
         * @param {number} [isSentMailFlg] 商品レビューの催促メールを送信済みかどうか 1 で送信済み
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requeetedProductReviews: async (imageSizeId?: number, isSentMailFlg?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/product/requested/reviews`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (imageSizeId !== undefined) {
                localVarQueryParameter['imageSizeId'] = imageSizeId;
            }

            if (isSentMailFlg !== undefined) {
                localVarQueryParameter['isSentMailFlg'] = isSentMailFlg;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 商品をカートに入れる
         * @summary Request Products into Cart
         * @param {string} productId
         * @param {string} inOut in&#x3D;&gt;カートに追加、out&#x3D;&gt;カートから削除
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestProductIntoCart: async (productId: string, inOut: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('requestProductIntoCart', 'productId', productId)
            // verify required parameter 'inOut' is not null or undefined
            assertParamExists('requestProductIntoCart', 'inOut', inOut)
            const localVarPath = `/product/request/cart`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (productId !== undefined) {
                localVarFormParams.append('productId', productId as any);
            }

            if (inOut !== undefined) {
                localVarFormParams.append('inOut', inOut as any);
            }


            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * サンプル商品のリクエスト。コインを消費。と同時に、チャットを登録する。また、カートから取り除く(カートにある場合)
         * @summary Request Sample Products
         * @param {string} [sampleProductRequest] SampleProductRequestのarray[{\\\&quot;productId\\\&quot;: \\\&quot;4\\\&quot;,\\\&quot;existingBusinessPartners\\\&quot;: \\\&quot;hoge\\\&quot;},{\\\&quot;productId\\\&quot;: \\\&quot;6\\\&quot;,\\\&quot;existingBusinessPartners\\\&quot;: \\\&quot;hoge\\\&quot;}]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestSampleProducts: async (sampleProductRequest?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/product/request/sample`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (sampleProductRequest !== undefined) {
                localVarFormParams.append('sampleProductRequest', sampleProductRequest as any);
            }


            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductApi - functional programming interface
 * @export
 */
export const ProductApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductApiAxiosParamCreator(configuration)
    return {
        /**
         * Authは無視する。この条件で商品検索した場合の商品数
         * @summary Counts Products
         * @param {number} [mediumCategoryId] 商品カテゴリの中分類
         * @param {number} [makerId] メーカーID
         * @param {string} [freeWord] フリーワード(メーカー名/商品名/商品名かな/商品紹介/商品カテゴリー（大）/商品カテゴリー（中）)
         * @param {number} [isRecommend] 0/1,立ってる場合おすすめ商品の取得
         * @param {string} [makerIds] メーカーIDの複数形、「|」区切り、空の時は「||」。
         * @param {string} [largeCategoryIds] 商品カテゴリの大分類複数形、「|」区切り、空の時は「||」。
         * @param {string} [keyword] キーワード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countProducts(mediumCategoryId?: number, makerId?: number, freeWord?: string, isRecommend?: number, makerIds?: string, largeCategoryIds?: string, keyword?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.countProducts(mediumCategoryId, makerId, freeWord, isRecommend, makerIds, largeCategoryIds, keyword, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Authは無視する。商品検索(from番目〜to番目まで取得)
         * @summary Finds Products
         * @param {number} from 0スタート
         * @param {number} to
         * @param {number} [followed] 0/1,立ってる場合フォローしているものを取得(headerからユーザーを取得)
         * @param {number} [cart] 0/1,立ってる場合カートに入れているものを取得(headerからユーザーを取得)
         * @param {number} [mediumCategoryId] 商品カテゴリの中分類
         * @param {number} [makerId] メーカーID
         * @param {string} [freeWord] フリーワード(メーカー名/商品名/商品名かな/商品紹介/商品カテゴリー（大）/商品カテゴリー（中）)
         * @param {number} [imageSizeId] 画像サイズID
         * @param {number} [isRecommend] 0/1,立ってる場合おすすめ商品の取得
         * @param {'recommend' | 'new' | 'pv' | 'update'} [sortType] ソートの種類パラムが無い場合デフォルト。「おすすめ順(デフォルト)、新着順、PV数順、最近更新された順」おすすめ順は同じ中分類カテゴリーの商品を近くに並べる
         * @param {string} [keyword] キーワード
         * @param {string} [makerIds] メーカーIDの複数形、「|」区切り、空の時は「||」。
         * @param {string} [largeCategoryIds] 商品カテゴリの大分類複数形、「|」区切り、空の時は「||」。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findProducts(from: number, to: number, followed?: number, cart?: number, mediumCategoryId?: number, makerId?: number, freeWord?: string, imageSizeId?: number, isRecommend?: number, sortType?: 'recommend' | 'new' | 'pv' | 'update', keyword?: string, makerIds?: string, largeCategoryIds?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Product>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findProducts(from, to, followed, cart, mediumCategoryId, makerId, freeWord, imageSizeId, isRecommend, sortType, keyword, makerIds, largeCategoryIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Authは無視する。単一商品情報を取得
         * @summary get Product ec shops by id
         * @param {number} id 商品ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEc(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Ec>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEc(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Authは無視する。新着商品情報を取得
         * @summary Finds new Products
         * @param {number} [num] 取得レシピ数。クエリに無い場合はデフォルトの数を取得
         * @param {number} [imageSizeId] 画像サイズID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNewProduct(num?: number, imageSizeId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Product>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNewProduct(num, imageSizeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Authは無視する。単一商品情報を取得
         * @summary Finds a Product by id
         * @param {string} id 商品ID
         * @param {number} pv PV立てるフラグ（これが1の時、商品PVをカウントする）
         * @param {number} [imageSizeId] 画像サイズID
         * @param {number} [isPreview] 下書き・非公開のものを見ることが出来るかどうか(0 or undefined &#x3D;&gt; 取得不可 / 1 &#x3D;&gt; 取得可)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProduct(id: string, pv: number, imageSizeId?: number, isPreview?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Product>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProduct(id, pv, imageSizeId, isPreview, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * お気に入り商品の判別
         * @summary Judge Product Follow Status by id
         * @param {number} id 商品ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductFollow(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Followed>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductFollow(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Authは無視する。レシピランキングを取得
         * @summary Finds Products Ranking
         * @param {'week'} type 取得商品タイプ
         * @param {number} from 0スタート
         * @param {number} to
         * @param {number} [imageSizeId] 画像サイズID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductRanking(type: 'week', from: number, to: number, imageSizeId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Product>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductRanking(type, from, to, imageSizeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Authは無視する。注目のキーワード商品情報を取得（指定されたキーワードでfindのおすすめ順と同じ結果になるようにする）また、管理画面から登録したデータがあればそちらを優先する
         * @summary Finds recommend keyword Products
         * @param {number} [num] 注目のキーワードの取得数
         * @param {number} [imageSizeId] 画像サイズID
         * @param {number} [key] キーワードの検索結果を複数保存しているので、そのどれを使うかの数字画面の上から1.2.3 と振るので、API先で選択する。注目のキーワードは作成バッチ毎にkeyを変えること
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRecommendKeywordProduct(num?: number, imageSizeId?: number, key?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRecommendKeywordProduct200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRecommendKeywordProduct(num, imageSizeId, key, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * このユーザーがサンプル依頼した一覧を取得（サンプル依頼日でソートしてから返す）
         * @summary Requeeted Product Reviews
         * @param {number} [imageSizeId] 商品の画像サイズID
         * @param {number} [isSentMailFlg] 商品レビューの催促メールを送信済みかどうか 1 で送信済み
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requeetedProductReviews(imageSizeId?: number, isSentMailFlg?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RequeetedProductReviews200ResponseInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requeetedProductReviews(imageSizeId, isSentMailFlg, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 商品をカートに入れる
         * @summary Request Products into Cart
         * @param {string} productId
         * @param {string} inOut in&#x3D;&gt;カートに追加、out&#x3D;&gt;カートから削除
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestProductIntoCart(productId: string, inOut: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestProductIntoCart(productId, inOut, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * サンプル商品のリクエスト。コインを消費。と同時に、チャットを登録する。また、カートから取り除く(カートにある場合)
         * @summary Request Sample Products
         * @param {string} [sampleProductRequest] SampleProductRequestのarray[{\\\&quot;productId\\\&quot;: \\\&quot;4\\\&quot;,\\\&quot;existingBusinessPartners\\\&quot;: \\\&quot;hoge\\\&quot;},{\\\&quot;productId\\\&quot;: \\\&quot;6\\\&quot;,\\\&quot;existingBusinessPartners\\\&quot;: \\\&quot;hoge\\\&quot;}]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestSampleProducts(sampleProductRequest?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestSampleProducts(sampleProductRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductApi - factory interface
 * @export
 */
export const ProductApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductApiFp(configuration)
    return {
        /**
         * Authは無視する。この条件で商品検索した場合の商品数
         * @summary Counts Products
         * @param {number} [mediumCategoryId] 商品カテゴリの中分類
         * @param {number} [makerId] メーカーID
         * @param {string} [freeWord] フリーワード(メーカー名/商品名/商品名かな/商品紹介/商品カテゴリー（大）/商品カテゴリー（中）)
         * @param {number} [isRecommend] 0/1,立ってる場合おすすめ商品の取得
         * @param {string} [makerIds] メーカーIDの複数形、「|」区切り、空の時は「||」。
         * @param {string} [largeCategoryIds] 商品カテゴリの大分類複数形、「|」区切り、空の時は「||」。
         * @param {string} [keyword] キーワード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countProducts(mediumCategoryId?: number, makerId?: number, freeWord?: string, isRecommend?: number, makerIds?: string, largeCategoryIds?: string, keyword?: string, options?: any): AxiosPromise<number> {
            return localVarFp.countProducts(mediumCategoryId, makerId, freeWord, isRecommend, makerIds, largeCategoryIds, keyword, options).then((request) => request(axios, basePath));
        },
        /**
         * Authは無視する。商品検索(from番目〜to番目まで取得)
         * @summary Finds Products
         * @param {number} from 0スタート
         * @param {number} to
         * @param {number} [followed] 0/1,立ってる場合フォローしているものを取得(headerからユーザーを取得)
         * @param {number} [cart] 0/1,立ってる場合カートに入れているものを取得(headerからユーザーを取得)
         * @param {number} [mediumCategoryId] 商品カテゴリの中分類
         * @param {number} [makerId] メーカーID
         * @param {string} [freeWord] フリーワード(メーカー名/商品名/商品名かな/商品紹介/商品カテゴリー（大）/商品カテゴリー（中）)
         * @param {number} [imageSizeId] 画像サイズID
         * @param {number} [isRecommend] 0/1,立ってる場合おすすめ商品の取得
         * @param {'recommend' | 'new' | 'pv' | 'update'} [sortType] ソートの種類パラムが無い場合デフォルト。「おすすめ順(デフォルト)、新着順、PV数順、最近更新された順」おすすめ順は同じ中分類カテゴリーの商品を近くに並べる
         * @param {string} [keyword] キーワード
         * @param {string} [makerIds] メーカーIDの複数形、「|」区切り、空の時は「||」。
         * @param {string} [largeCategoryIds] 商品カテゴリの大分類複数形、「|」区切り、空の時は「||」。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findProducts(from: number, to: number, followed?: number, cart?: number, mediumCategoryId?: number, makerId?: number, freeWord?: string, imageSizeId?: number, isRecommend?: number, sortType?: 'recommend' | 'new' | 'pv' | 'update', keyword?: string, makerIds?: string, largeCategoryIds?: string, options?: any): AxiosPromise<Array<Product>> {
            return localVarFp.findProducts(from, to, followed, cart, mediumCategoryId, makerId, freeWord, imageSizeId, isRecommend, sortType, keyword, makerIds, largeCategoryIds, options).then((request) => request(axios, basePath));
        },
        /**
         * Authは無視する。単一商品情報を取得
         * @summary get Product ec shops by id
         * @param {number} id 商品ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEc(id: number, options?: any): AxiosPromise<Array<Ec>> {
            return localVarFp.getEc(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Authは無視する。新着商品情報を取得
         * @summary Finds new Products
         * @param {number} [num] 取得レシピ数。クエリに無い場合はデフォルトの数を取得
         * @param {number} [imageSizeId] 画像サイズID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewProduct(num?: number, imageSizeId?: number, options?: any): AxiosPromise<Array<Product>> {
            return localVarFp.getNewProduct(num, imageSizeId, options).then((request) => request(axios, basePath));
        },
        /**
         * Authは無視する。単一商品情報を取得
         * @summary Finds a Product by id
         * @param {string} id 商品ID
         * @param {number} pv PV立てるフラグ（これが1の時、商品PVをカウントする）
         * @param {number} [imageSizeId] 画像サイズID
         * @param {number} [isPreview] 下書き・非公開のものを見ることが出来るかどうか(0 or undefined &#x3D;&gt; 取得不可 / 1 &#x3D;&gt; 取得可)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProduct(id: string, pv: number, imageSizeId?: number, isPreview?: number, options?: any): AxiosPromise<Product> {
            return localVarFp.getProduct(id, pv, imageSizeId, isPreview, options).then((request) => request(axios, basePath));
        },
        /**
         * お気に入り商品の判別
         * @summary Judge Product Follow Status by id
         * @param {number} id 商品ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductFollow(id: number, options?: any): AxiosPromise<Followed> {
            return localVarFp.getProductFollow(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Authは無視する。レシピランキングを取得
         * @summary Finds Products Ranking
         * @param {'week'} type 取得商品タイプ
         * @param {number} from 0スタート
         * @param {number} to
         * @param {number} [imageSizeId] 画像サイズID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductRanking(type: 'week', from: number, to: number, imageSizeId?: number, options?: any): AxiosPromise<Array<Product>> {
            return localVarFp.getProductRanking(type, from, to, imageSizeId, options).then((request) => request(axios, basePath));
        },
        /**
         * Authは無視する。注目のキーワード商品情報を取得（指定されたキーワードでfindのおすすめ順と同じ結果になるようにする）また、管理画面から登録したデータがあればそちらを優先する
         * @summary Finds recommend keyword Products
         * @param {number} [num] 注目のキーワードの取得数
         * @param {number} [imageSizeId] 画像サイズID
         * @param {number} [key] キーワードの検索結果を複数保存しているので、そのどれを使うかの数字画面の上から1.2.3 と振るので、API先で選択する。注目のキーワードは作成バッチ毎にkeyを変えること
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecommendKeywordProduct(num?: number, imageSizeId?: number, key?: number, options?: any): AxiosPromise<GetRecommendKeywordProduct200Response> {
            return localVarFp.getRecommendKeywordProduct(num, imageSizeId, key, options).then((request) => request(axios, basePath));
        },
        /**
         * このユーザーがサンプル依頼した一覧を取得（サンプル依頼日でソートしてから返す）
         * @summary Requeeted Product Reviews
         * @param {number} [imageSizeId] 商品の画像サイズID
         * @param {number} [isSentMailFlg] 商品レビューの催促メールを送信済みかどうか 1 で送信済み
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requeetedProductReviews(imageSizeId?: number, isSentMailFlg?: number, options?: any): AxiosPromise<Array<RequeetedProductReviews200ResponseInner>> {
            return localVarFp.requeetedProductReviews(imageSizeId, isSentMailFlg, options).then((request) => request(axios, basePath));
        },
        /**
         * 商品をカートに入れる
         * @summary Request Products into Cart
         * @param {string} productId
         * @param {string} inOut in&#x3D;&gt;カートに追加、out&#x3D;&gt;カートから削除
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestProductIntoCart(productId: string, inOut: string, options?: any): AxiosPromise<void> {
            return localVarFp.requestProductIntoCart(productId, inOut, options).then((request) => request(axios, basePath));
        },
        /**
         * サンプル商品のリクエスト。コインを消費。と同時に、チャットを登録する。また、カートから取り除く(カートにある場合)
         * @summary Request Sample Products
         * @param {string} [sampleProductRequest] SampleProductRequestのarray[{\\\&quot;productId\\\&quot;: \\\&quot;4\\\&quot;,\\\&quot;existingBusinessPartners\\\&quot;: \\\&quot;hoge\\\&quot;},{\\\&quot;productId\\\&quot;: \\\&quot;6\\\&quot;,\\\&quot;existingBusinessPartners\\\&quot;: \\\&quot;hoge\\\&quot;}]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestSampleProducts(sampleProductRequest?: string, options?: any): AxiosPromise<void> {
            return localVarFp.requestSampleProducts(sampleProductRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductApi - object-oriented interface
 * @export
 * @class ProductApi
 * @extends {BaseAPI}
 */
export class ProductApi extends BaseAPI {
    /**
     * Authは無視する。この条件で商品検索した場合の商品数
     * @summary Counts Products
     * @param {number} [mediumCategoryId] 商品カテゴリの中分類
     * @param {number} [makerId] メーカーID
     * @param {string} [freeWord] フリーワード(メーカー名/商品名/商品名かな/商品紹介/商品カテゴリー（大）/商品カテゴリー（中）)
     * @param {number} [isRecommend] 0/1,立ってる場合おすすめ商品の取得
     * @param {string} [makerIds] メーカーIDの複数形、「|」区切り、空の時は「||」。
     * @param {string} [largeCategoryIds] 商品カテゴリの大分類複数形、「|」区切り、空の時は「||」。
     * @param {string} [keyword] キーワード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public countProducts(mediumCategoryId?: number, makerId?: number, freeWord?: string, isRecommend?: number, makerIds?: string, largeCategoryIds?: string, keyword?: string, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).countProducts(mediumCategoryId, makerId, freeWord, isRecommend, makerIds, largeCategoryIds, keyword, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Authは無視する。商品検索(from番目〜to番目まで取得)
     * @summary Finds Products
     * @param {number} from 0スタート
     * @param {number} to
     * @param {number} [followed] 0/1,立ってる場合フォローしているものを取得(headerからユーザーを取得)
     * @param {number} [cart] 0/1,立ってる場合カートに入れているものを取得(headerからユーザーを取得)
     * @param {number} [mediumCategoryId] 商品カテゴリの中分類
     * @param {number} [makerId] メーカーID
     * @param {string} [freeWord] フリーワード(メーカー名/商品名/商品名かな/商品紹介/商品カテゴリー（大）/商品カテゴリー（中）)
     * @param {number} [imageSizeId] 画像サイズID
     * @param {number} [isRecommend] 0/1,立ってる場合おすすめ商品の取得
     * @param {'recommend' | 'new' | 'pv' | 'update'} [sortType] ソートの種類パラムが無い場合デフォルト。「おすすめ順(デフォルト)、新着順、PV数順、最近更新された順」おすすめ順は同じ中分類カテゴリーの商品を近くに並べる
     * @param {string} [keyword] キーワード
     * @param {string} [makerIds] メーカーIDの複数形、「|」区切り、空の時は「||」。
     * @param {string} [largeCategoryIds] 商品カテゴリの大分類複数形、「|」区切り、空の時は「||」。
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public findProducts(from: number, to: number, followed?: number, cart?: number, mediumCategoryId?: number, makerId?: number, freeWord?: string, imageSizeId?: number, isRecommend?: number, sortType?: 'recommend' | 'new' | 'pv' | 'update', keyword?: string, makerIds?: string, largeCategoryIds?: string, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).findProducts(from, to, followed, cart, mediumCategoryId, makerId, freeWord, imageSizeId, isRecommend, sortType, keyword, makerIds, largeCategoryIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Authは無視する。単一商品情報を取得
     * @summary get Product ec shops by id
     * @param {number} id 商品ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public getEc(id: number, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).getEc(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Authは無視する。新着商品情報を取得
     * @summary Finds new Products
     * @param {number} [num] 取得レシピ数。クエリに無い場合はデフォルトの数を取得
     * @param {number} [imageSizeId] 画像サイズID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public getNewProduct(num?: number, imageSizeId?: number, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).getNewProduct(num, imageSizeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Authは無視する。単一商品情報を取得
     * @summary Finds a Product by id
     * @param {string} id 商品ID
     * @param {number} pv PV立てるフラグ（これが1の時、商品PVをカウントする）
     * @param {number} [imageSizeId] 画像サイズID
     * @param {number} [isPreview] 下書き・非公開のものを見ることが出来るかどうか(0 or undefined &#x3D;&gt; 取得不可 / 1 &#x3D;&gt; 取得可)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public getProduct(id: string, pv: number, imageSizeId?: number, isPreview?: number, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).getProduct(id, pv, imageSizeId, isPreview, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * お気に入り商品の判別
     * @summary Judge Product Follow Status by id
     * @param {number} id 商品ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public getProductFollow(id: number, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).getProductFollow(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Authは無視する。レシピランキングを取得
     * @summary Finds Products Ranking
     * @param {'week'} type 取得商品タイプ
     * @param {number} from 0スタート
     * @param {number} to
     * @param {number} [imageSizeId] 画像サイズID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public getProductRanking(type: 'week', from: number, to: number, imageSizeId?: number, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).getProductRanking(type, from, to, imageSizeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Authは無視する。注目のキーワード商品情報を取得（指定されたキーワードでfindのおすすめ順と同じ結果になるようにする）また、管理画面から登録したデータがあればそちらを優先する
     * @summary Finds recommend keyword Products
     * @param {number} [num] 注目のキーワードの取得数
     * @param {number} [imageSizeId] 画像サイズID
     * @param {number} [key] キーワードの検索結果を複数保存しているので、そのどれを使うかの数字画面の上から1.2.3 と振るので、API先で選択する。注目のキーワードは作成バッチ毎にkeyを変えること
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public getRecommendKeywordProduct(num?: number, imageSizeId?: number, key?: number, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).getRecommendKeywordProduct(num, imageSizeId, key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * このユーザーがサンプル依頼した一覧を取得（サンプル依頼日でソートしてから返す）
     * @summary Requeeted Product Reviews
     * @param {number} [imageSizeId] 商品の画像サイズID
     * @param {number} [isSentMailFlg] 商品レビューの催促メールを送信済みかどうか 1 で送信済み
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public requeetedProductReviews(imageSizeId?: number, isSentMailFlg?: number, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).requeetedProductReviews(imageSizeId, isSentMailFlg, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 商品をカートに入れる
     * @summary Request Products into Cart
     * @param {string} productId
     * @param {string} inOut in&#x3D;&gt;カートに追加、out&#x3D;&gt;カートから削除
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public requestProductIntoCart(productId: string, inOut: string, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).requestProductIntoCart(productId, inOut, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * サンプル商品のリクエスト。コインを消費。と同時に、チャットを登録する。また、カートから取り除く(カートにある場合)
     * @summary Request Sample Products
     * @param {string} [sampleProductRequest] SampleProductRequestのarray[{\\\&quot;productId\\\&quot;: \\\&quot;4\\\&quot;,\\\&quot;existingBusinessPartners\\\&quot;: \\\&quot;hoge\\\&quot;},{\\\&quot;productId\\\&quot;: \\\&quot;6\\\&quot;,\\\&quot;existingBusinessPartners\\\&quot;: \\\&quot;hoge\\\&quot;}]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public requestSampleProducts(sampleProductRequest?: string, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).requestSampleProducts(sampleProductRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProductReviewApi - axios parameter creator
 * @export
 */
export const ProductReviewApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * サンプル商品レビューの回答、回答時に商品サンプル依頼管理画面のステータスをFB受領済みに変更する
         * @summary Answer a Sample Product Review
         * @param {string} [review] ReviewAnswerのarray{\\\&quot;productId\\\&quot;: 0,\\\&quot;reviewSetId\\\&quot;: 0,\\\&quot;answers\\\&quot;:[{\\\&quot;id\\\&quot;: 0,\\\&quot;type\\\&quot;: \\\&quot;text\\\&quot;,\\\&quot;checkboxValue\\\&quot;: 0,\\\&quot;text\\\&quot;: \\\&quot;string\\\&quot;}]}
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        answerSampleProductReview: async (review?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/review/product/answer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (review !== undefined) {
                localVarFormParams.append('review', review as any);
            }


            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 商品サンプルのレビューを取得
         * @summary Finds a Sample Product Review
         * @param {number} productId 商品ID
         * @param {number} reviewSetId 商品レビューセットID（サンプル依頼時の）
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSampleProductReview: async (productId: number, reviewSetId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('getSampleProductReview', 'productId', productId)
            // verify required parameter 'reviewSetId' is not null or undefined
            assertParamExists('getSampleProductReview', 'reviewSetId', reviewSetId)
            const localVarPath = `/review/product/sample/find`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (productId !== undefined) {
                localVarQueryParameter['productId'] = productId;
            }

            if (reviewSetId !== undefined) {
                localVarQueryParameter['reviewSetId'] = reviewSetId;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductReviewApi - functional programming interface
 * @export
 */
export const ProductReviewApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductReviewApiAxiosParamCreator(configuration)
    return {
        /**
         * サンプル商品レビューの回答、回答時に商品サンプル依頼管理画面のステータスをFB受領済みに変更する
         * @summary Answer a Sample Product Review
         * @param {string} [review] ReviewAnswerのarray{\\\&quot;productId\\\&quot;: 0,\\\&quot;reviewSetId\\\&quot;: 0,\\\&quot;answers\\\&quot;:[{\\\&quot;id\\\&quot;: 0,\\\&quot;type\\\&quot;: \\\&quot;text\\\&quot;,\\\&quot;checkboxValue\\\&quot;: 0,\\\&quot;text\\\&quot;: \\\&quot;string\\\&quot;}]}
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async answerSampleProductReview(review?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.answerSampleProductReview(review, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 商品サンプルのレビューを取得
         * @summary Finds a Sample Product Review
         * @param {number} productId 商品ID
         * @param {number} reviewSetId 商品レビューセットID（サンプル依頼時の）
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSampleProductReview(productId: number, reviewSetId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SampleProductReview>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSampleProductReview(productId, reviewSetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductReviewApi - factory interface
 * @export
 */
export const ProductReviewApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductReviewApiFp(configuration)
    return {
        /**
         * サンプル商品レビューの回答、回答時に商品サンプル依頼管理画面のステータスをFB受領済みに変更する
         * @summary Answer a Sample Product Review
         * @param {string} [review] ReviewAnswerのarray{\\\&quot;productId\\\&quot;: 0,\\\&quot;reviewSetId\\\&quot;: 0,\\\&quot;answers\\\&quot;:[{\\\&quot;id\\\&quot;: 0,\\\&quot;type\\\&quot;: \\\&quot;text\\\&quot;,\\\&quot;checkboxValue\\\&quot;: 0,\\\&quot;text\\\&quot;: \\\&quot;string\\\&quot;}]}
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        answerSampleProductReview(review?: string, options?: any): AxiosPromise<void> {
            return localVarFp.answerSampleProductReview(review, options).then((request) => request(axios, basePath));
        },
        /**
         * 商品サンプルのレビューを取得
         * @summary Finds a Sample Product Review
         * @param {number} productId 商品ID
         * @param {number} reviewSetId 商品レビューセットID（サンプル依頼時の）
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSampleProductReview(productId: number, reviewSetId: number, options?: any): AxiosPromise<SampleProductReview> {
            return localVarFp.getSampleProductReview(productId, reviewSetId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductReviewApi - object-oriented interface
 * @export
 * @class ProductReviewApi
 * @extends {BaseAPI}
 */
export class ProductReviewApi extends BaseAPI {
    /**
     * サンプル商品レビューの回答、回答時に商品サンプル依頼管理画面のステータスをFB受領済みに変更する
     * @summary Answer a Sample Product Review
     * @param {string} [review] ReviewAnswerのarray{\\\&quot;productId\\\&quot;: 0,\\\&quot;reviewSetId\\\&quot;: 0,\\\&quot;answers\\\&quot;:[{\\\&quot;id\\\&quot;: 0,\\\&quot;type\\\&quot;: \\\&quot;text\\\&quot;,\\\&quot;checkboxValue\\\&quot;: 0,\\\&quot;text\\\&quot;: \\\&quot;string\\\&quot;}]}
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductReviewApi
     */
    public answerSampleProductReview(review?: string, options?: AxiosRequestConfig) {
        return ProductReviewApiFp(this.configuration).answerSampleProductReview(review, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 商品サンプルのレビューを取得
     * @summary Finds a Sample Product Review
     * @param {number} productId 商品ID
     * @param {number} reviewSetId 商品レビューセットID（サンプル依頼時の）
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductReviewApi
     */
    public getSampleProductReview(productId: number, reviewSetId: number, options?: AxiosRequestConfig) {
        return ProductReviewApiFp(this.configuration).getSampleProductReview(productId, reviewSetId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PurchaseApi - axios parameter creator
 * @export
 */
export const PurchaseApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * サブスクリプションの解約
         * @summary Send Cancel
         * @param {string} os OS情報(MobileはOSのシステムから解約するのでwebだけ)
         * @param {string} type1 レシート送信を参照
         * @param {string} type2 レシート送信を参照
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendCancel: async (os: string, type1: string, type2: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'os' is not null or undefined
            assertParamExists('sendCancel', 'os', os)
            // verify required parameter 'type1' is not null or undefined
            assertParamExists('sendCancel', 'type1', type1)
            // verify required parameter 'type2' is not null or undefined
            assertParamExists('sendCancel', 'type2', type2)
            const localVarPath = `/purchase/cancel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (os !== undefined) {
                localVarFormParams.append('os', os as any);
            }

            if (type1 !== undefined) {
                localVarFormParams.append('type1', type1 as any);
            }

            if (type2 !== undefined) {
                localVarFormParams.append('type2', type2 as any);
            }


            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 購入したサブスクリプションのレシート(userはheaderから確認)を送信
         * @summary Send Receipt
         * @param {string} os OS情報(ios, android, web)
         * @param {string} receipt iOSならレシートデータ、Androidならaccess_token、webならクレジット契約のそれに該当するなにか
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendReceipt: async (os: string, receipt: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'os' is not null or undefined
            assertParamExists('sendReceipt', 'os', os)
            // verify required parameter 'receipt' is not null or undefined
            assertParamExists('sendReceipt', 'receipt', receipt)
            const localVarPath = `/purchase/send/receipt`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (os !== undefined) {
                localVarFormParams.append('os', os as any);
            }

            if (receipt !== undefined) {
                localVarFormParams.append('receipt', receipt as any);
            }


            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PurchaseApi - functional programming interface
 * @export
 */
export const PurchaseApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PurchaseApiAxiosParamCreator(configuration)
    return {
        /**
         * サブスクリプションの解約
         * @summary Send Cancel
         * @param {string} os OS情報(MobileはOSのシステムから解約するのでwebだけ)
         * @param {string} type1 レシート送信を参照
         * @param {string} type2 レシート送信を参照
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendCancel(os: string, type1: string, type2: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendCancel(os, type1, type2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 購入したサブスクリプションのレシート(userはheaderから確認)を送信
         * @summary Send Receipt
         * @param {string} os OS情報(ios, android, web)
         * @param {string} receipt iOSならレシートデータ、Androidならaccess_token、webならクレジット契約のそれに該当するなにか
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendReceipt(os: string, receipt: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendReceipt(os, receipt, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PurchaseApi - factory interface
 * @export
 */
export const PurchaseApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PurchaseApiFp(configuration)
    return {
        /**
         * サブスクリプションの解約
         * @summary Send Cancel
         * @param {string} os OS情報(MobileはOSのシステムから解約するのでwebだけ)
         * @param {string} type1 レシート送信を参照
         * @param {string} type2 レシート送信を参照
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendCancel(os: string, type1: string, type2: string, options?: any): AxiosPromise<void> {
            return localVarFp.sendCancel(os, type1, type2, options).then((request) => request(axios, basePath));
        },
        /**
         * 購入したサブスクリプションのレシート(userはheaderから確認)を送信
         * @summary Send Receipt
         * @param {string} os OS情報(ios, android, web)
         * @param {string} receipt iOSならレシートデータ、Androidならaccess_token、webならクレジット契約のそれに該当するなにか
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendReceipt(os: string, receipt: string, options?: any): AxiosPromise<void> {
            return localVarFp.sendReceipt(os, receipt, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PurchaseApi - object-oriented interface
 * @export
 * @class PurchaseApi
 * @extends {BaseAPI}
 */
export class PurchaseApi extends BaseAPI {
    /**
     * サブスクリプションの解約
     * @summary Send Cancel
     * @param {string} os OS情報(MobileはOSのシステムから解約するのでwebだけ)
     * @param {string} type1 レシート送信を参照
     * @param {string} type2 レシート送信を参照
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseApi
     */
    public sendCancel(os: string, type1: string, type2: string, options?: AxiosRequestConfig) {
        return PurchaseApiFp(this.configuration).sendCancel(os, type1, type2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 購入したサブスクリプションのレシート(userはheaderから確認)を送信
     * @summary Send Receipt
     * @param {string} os OS情報(ios, android, web)
     * @param {string} receipt iOSならレシートデータ、Androidならaccess_token、webならクレジット契約のそれに該当するなにか
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseApi
     */
    public sendReceipt(os: string, receipt: string, options?: AxiosRequestConfig) {
        return PurchaseApiFp(this.configuration).sendReceipt(os, receipt, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * QuestionnaireApi - axios parameter creator
 * @export
 */
export const QuestionnaireApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * ユーザーがアンケートに答えてるか確認(ユーザーはheaderから確認)
         * @summary Finished Check Questionnaire
         * @param {number} questionnaireId アンケートID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkFinishedQuestionnaire: async (questionnaireId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'questionnaireId' is not null or undefined
            assertParamExists('checkFinishedQuestionnaire', 'questionnaireId', questionnaireId)
            const localVarPath = `/questionnaire/check/finished`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (questionnaireId !== undefined) {
                localVarQueryParameter['questionnaireId'] = questionnaireId;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * アンケート回答した送信。Googleアンケートから送信するのでHeaderなし
         * @summary Send Finish Questionnaire
         * @param {number} userId 回答したuserID(headerないので)
         * @param {number} questionnaireId 回答したアンケートID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendQuestionnaire: async (userId: number, questionnaireId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('sendQuestionnaire', 'userId', userId)
            // verify required parameter 'questionnaireId' is not null or undefined
            assertParamExists('sendQuestionnaire', 'questionnaireId', questionnaireId)
            const localVarPath = `/questionnaire/finish`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (userId !== undefined) {
                localVarFormParams.append('userId', userId as any);
            }

            if (questionnaireId !== undefined) {
                localVarFormParams.append('questionnaireId', questionnaireId as any);
            }


            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * QuestionnaireApi - functional programming interface
 * @export
 */
export const QuestionnaireApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = QuestionnaireApiAxiosParamCreator(configuration)
    return {
        /**
         * ユーザーがアンケートに答えてるか確認(ユーザーはheaderから確認)
         * @summary Finished Check Questionnaire
         * @param {number} questionnaireId アンケートID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkFinishedQuestionnaire(questionnaireId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CheckFinishedQuestionnaire200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkFinishedQuestionnaire(questionnaireId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * アンケート回答した送信。Googleアンケートから送信するのでHeaderなし
         * @summary Send Finish Questionnaire
         * @param {number} userId 回答したuserID(headerないので)
         * @param {number} questionnaireId 回答したアンケートID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendQuestionnaire(userId: number, questionnaireId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendQuestionnaire(userId, questionnaireId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * QuestionnaireApi - factory interface
 * @export
 */
export const QuestionnaireApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = QuestionnaireApiFp(configuration)
    return {
        /**
         * ユーザーがアンケートに答えてるか確認(ユーザーはheaderから確認)
         * @summary Finished Check Questionnaire
         * @param {number} questionnaireId アンケートID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkFinishedQuestionnaire(questionnaireId: number, options?: any): AxiosPromise<CheckFinishedQuestionnaire200Response> {
            return localVarFp.checkFinishedQuestionnaire(questionnaireId, options).then((request) => request(axios, basePath));
        },
        /**
         * アンケート回答した送信。Googleアンケートから送信するのでHeaderなし
         * @summary Send Finish Questionnaire
         * @param {number} userId 回答したuserID(headerないので)
         * @param {number} questionnaireId 回答したアンケートID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendQuestionnaire(userId: number, questionnaireId: number, options?: any): AxiosPromise<void> {
            return localVarFp.sendQuestionnaire(userId, questionnaireId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * QuestionnaireApi - object-oriented interface
 * @export
 * @class QuestionnaireApi
 * @extends {BaseAPI}
 */
export class QuestionnaireApi extends BaseAPI {
    /**
     * ユーザーがアンケートに答えてるか確認(ユーザーはheaderから確認)
     * @summary Finished Check Questionnaire
     * @param {number} questionnaireId アンケートID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionnaireApi
     */
    public checkFinishedQuestionnaire(questionnaireId: number, options?: AxiosRequestConfig) {
        return QuestionnaireApiFp(this.configuration).checkFinishedQuestionnaire(questionnaireId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * アンケート回答した送信。Googleアンケートから送信するのでHeaderなし
     * @summary Send Finish Questionnaire
     * @param {number} userId 回答したuserID(headerないので)
     * @param {number} questionnaireId 回答したアンケートID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionnaireApi
     */
    public sendQuestionnaire(userId: number, questionnaireId: number, options?: AxiosRequestConfig) {
        return QuestionnaireApiFp(this.configuration).sendQuestionnaire(userId, questionnaireId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RecipeApi - axios parameter creator
 * @export
 */
export const RecipeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Authは無視する。レシピ検索検索条件で検索したときの結果のレシピ数
         * @summary Counts Recipes
         * @param {string} [freeWord] フリーワード(料理名/食材名/商品名/メーカー名/キーワード/レシピカテゴリー/商品カテゴリー)
         * @param {number} [makerId] メーカーID
         * @param {number} [productCategoryId] 商品カテゴリーID
         * @param {number} [recipeCategoryId] レシピカテゴリーID
         * @param {string} [keyword] キーワード
         * @param {string} [costFrom] 原価下限
         * @param {string} [costTo] 原価上限
         * @param {number} [productId] 同じ商品を使ったレシピを検索するのに利用
         * @param {number} [isRecommend] 0/1,立ってる場合おすすめレシピの取得
         * @param {string} [restaurantLargeFormatIds] 業態(大)のID、「|」区切り、空の時は「||」。
         * @param {string} [seasonIds] 季節のID、「|」区切り、空の時は「||」。春: 1、夏: 2、秋: 3、冬: 4、オールシーズン: 5
         * @param {string} [recipeCategoryIds] レシピカテゴリーID複数の場合、「|」区切り、空の時は「||」。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countRecipes: async (freeWord?: string, makerId?: number, productCategoryId?: number, recipeCategoryId?: number, keyword?: string, costFrom?: string, costTo?: string, productId?: number, isRecommend?: number, restaurantLargeFormatIds?: string, seasonIds?: string, recipeCategoryIds?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/recipe/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (freeWord !== undefined) {
                localVarQueryParameter['freeWord'] = freeWord;
            }

            if (makerId !== undefined) {
                localVarQueryParameter['makerId'] = makerId;
            }

            if (productCategoryId !== undefined) {
                localVarQueryParameter['productCategoryId'] = productCategoryId;
            }

            if (recipeCategoryId !== undefined) {
                localVarQueryParameter['recipeCategoryId'] = recipeCategoryId;
            }

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (costFrom !== undefined) {
                localVarQueryParameter['costFrom'] = costFrom;
            }

            if (costTo !== undefined) {
                localVarQueryParameter['costTo'] = costTo;
            }

            if (productId !== undefined) {
                localVarQueryParameter['productId'] = productId;
            }

            if (isRecommend !== undefined) {
                localVarQueryParameter['isRecommend'] = isRecommend;
            }

            if (restaurantLargeFormatIds !== undefined) {
                localVarQueryParameter['restaurantLargeFormatIds'] = restaurantLargeFormatIds;
            }

            if (seasonIds !== undefined) {
                localVarQueryParameter['seasonIds'] = seasonIds;
            }

            if (recipeCategoryIds !== undefined) {
                localVarQueryParameter['recipeCategoryIds'] = recipeCategoryIds;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Authは無視する。レシピ検索検索条件は全て任意、メーカーの課金金額（課金プラン）が高い順andPV数順(from番目〜to番目まで取得)
         * @summary Finds Recipes
         * @param {number} from 0スタート
         * @param {number} to
         * @param {string} [freeWord] フリーワード(料理名/食材名/商品名/メーカー名/キーワード/レシピカテゴリー/商品カテゴリー)
         * @param {number} [makerId] メーカーID
         * @param {number} [productCategoryId] 商品カテゴリーID
         * @param {number} [recipeCategoryId] レシピカテゴリーID
         * @param {string} [keyword] キーワード
         * @param {string} [costFrom] 原価下限
         * @param {string} [costTo] 原価上限
         * @param {number} [productId] 同じ商品を使ったレシピを検索するのに利用
         * @param {number} [followed] 0/1,立ってる場合フォローしているものを取得(headerからユーザーを取得)
         * @param {number} [imageSizeId] 画像サイズID
         * @param {number} [isRecommend] 0/1,立ってる場合おすすめレシピの取得
         * @param {'recommend' | 'new' | 'pv' | 'update'} [sortType] ソートの種類パラムが無い場合デフォルト。「おすすめ順、新着順、PV数順(デフォルト)、最近更新された順」
         * @param {number} [cookingTime] 調理時間（仕込み時間抜き）[分]
         * @param {string} [restaurantLargeFormatIds] 業態(大)のID、「|」区切り、空の時は「||」。
         * @param {string} [seasonIds] 季節のID、「|」区切り、空の時は「||」。春: 1、夏: 2、秋: 3、冬: 4、オールシーズン: 5
         * @param {string} [recipeCategoryIds] レシピカテゴリーID複数の場合、「|」区切り、空の時は「||」。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findRecipes: async (from: number, to: number, freeWord?: string, makerId?: number, productCategoryId?: number, recipeCategoryId?: number, keyword?: string, costFrom?: string, costTo?: string, productId?: number, followed?: number, imageSizeId?: number, isRecommend?: number, sortType?: 'recommend' | 'new' | 'pv' | 'update', cookingTime?: number, restaurantLargeFormatIds?: string, seasonIds?: string, recipeCategoryIds?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'from' is not null or undefined
            assertParamExists('findRecipes', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('findRecipes', 'to', to)
            const localVarPath = `/recipe/find`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (freeWord !== undefined) {
                localVarQueryParameter['freeWord'] = freeWord;
            }

            if (makerId !== undefined) {
                localVarQueryParameter['makerId'] = makerId;
            }

            if (productCategoryId !== undefined) {
                localVarQueryParameter['productCategoryId'] = productCategoryId;
            }

            if (recipeCategoryId !== undefined) {
                localVarQueryParameter['recipeCategoryId'] = recipeCategoryId;
            }

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (costFrom !== undefined) {
                localVarQueryParameter['costFrom'] = costFrom;
            }

            if (costTo !== undefined) {
                localVarQueryParameter['costTo'] = costTo;
            }

            if (productId !== undefined) {
                localVarQueryParameter['productId'] = productId;
            }

            if (followed !== undefined) {
                localVarQueryParameter['followed'] = followed;
            }

            if (imageSizeId !== undefined) {
                localVarQueryParameter['imageSizeId'] = imageSizeId;
            }

            if (isRecommend !== undefined) {
                localVarQueryParameter['isRecommend'] = isRecommend;
            }

            if (sortType !== undefined) {
                localVarQueryParameter['sortType'] = sortType;
            }

            if (cookingTime !== undefined) {
                localVarQueryParameter['cookingTime'] = cookingTime;
            }

            if (restaurantLargeFormatIds !== undefined) {
                localVarQueryParameter['restaurantLargeFormatIds'] = restaurantLargeFormatIds;
            }

            if (seasonIds !== undefined) {
                localVarQueryParameter['seasonIds'] = seasonIds;
            }

            if (recipeCategoryIds !== undefined) {
                localVarQueryParameter['recipeCategoryIds'] = recipeCategoryIds;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Authは無視する。新着レシピ情報を取得
         * @summary Finds new Recipes
         * @param {number} [num] 取得レシピ数。クエリに無い場合はデフォルトの数を取得
         * @param {number} [imageSizeId] 画像サイズID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewRecipe: async (num?: number, imageSizeId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/recipe/findNew`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (num !== undefined) {
                localVarQueryParameter['num'] = num;
            }

            if (imageSizeId !== undefined) {
                localVarQueryParameter['imageSizeId'] = imageSizeId;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Authは無視する。単一レシピ情報を取得
         * @summary Finds a Recipe by id
         * @param {string} id レシピID
         * @param {number} pv PV立てるフラグ（これが1の時、レシピPVをカウントする）
         * @param {number} [imageSizeId] 画像サイズID
         * @param {number} [isPreview] 下書き・非公開のものを見ることが出来るかどうか(0 or undefined &#x3D;&gt; 取得不可 / 1 &#x3D;&gt; 取得可)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecipe: async (id: string, pv: number, imageSizeId?: number, isPreview?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getRecipe', 'id', id)
            // verify required parameter 'pv' is not null or undefined
            assertParamExists('getRecipe', 'pv', pv)
            const localVarPath = `/recipe/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pv !== undefined) {
                localVarQueryParameter['pv'] = pv;
            }

            if (imageSizeId !== undefined) {
                localVarQueryParameter['imageSizeId'] = imageSizeId;
            }

            if (isPreview !== undefined) {
                localVarQueryParameter['isPreview'] = isPreview;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * お気に入りレシピの判別
         * @summary Judge Recipe Follow Status by id
         * @param {number} id レシピID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecipeFollow: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getRecipeFollow', 'id', id)
            const localVarPath = `/recipe/follow/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Authは無視する。レシピランキングを取得
         * @summary Finds Recipes Ranking
         * @param {'week'} type 取得レシピタイプ
         * @param {number} from 0スタート
         * @param {number} to
         * @param {number} [filterRecipeCategory] 絞り込み条件(レシピカテゴリーマスターのID)
         * @param {number} [filterCostFrom] 絞り込み条件(原価(円)From)
         * @param {number} [filterCostTo] 絞り込み条件(原価(円)From)
         * @param {number} [imageSizeId] 画像サイズID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecipeRanking: async (type: 'week', from: number, to: number, filterRecipeCategory?: number, filterCostFrom?: number, filterCostTo?: number, imageSizeId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('getRecipeRanking', 'type', type)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('getRecipeRanking', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('getRecipeRanking', 'to', to)
            const localVarPath = `/recipe/ranking/{type}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (filterRecipeCategory !== undefined) {
                localVarQueryParameter['filterRecipeCategory'] = filterRecipeCategory;
            }

            if (filterCostFrom !== undefined) {
                localVarQueryParameter['filterCostFrom'] = filterCostFrom;
            }

            if (filterCostTo !== undefined) {
                localVarQueryParameter['filterCostTo'] = filterCostTo;
            }

            if (imageSizeId !== undefined) {
                localVarQueryParameter['imageSizeId'] = imageSizeId;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Authは無視する。注目のキーワードレシピ情報を取得（指定されたキーワードでfindのおすすめ順と同じ結果になるようにする）また、管理画面から登録したデータがあればそちらを優先する
         * @summary Finds recommend keyword Recipes
         * @param {number} [num] 注目のキーワードの取得数
         * @param {number} [imageSizeId] 画像サイズID
         * @param {number} [key] キーワードの検索結果を複数保存しているので、そのどれを使うかの数字画面の上から1.2.3 と振るので、API先で選択する。注目のキーワードは作成バッチ毎にkeyを変えること
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecommendKeywordRecipe: async (num?: number, imageSizeId?: number, key?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/recipe/findRecommendKeyword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (num !== undefined) {
                localVarQueryParameter['num'] = num;
            }

            if (imageSizeId !== undefined) {
                localVarQueryParameter['imageSizeId'] = imageSizeId;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RecipeApi - functional programming interface
 * @export
 */
export const RecipeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RecipeApiAxiosParamCreator(configuration)
    return {
        /**
         * Authは無視する。レシピ検索検索条件で検索したときの結果のレシピ数
         * @summary Counts Recipes
         * @param {string} [freeWord] フリーワード(料理名/食材名/商品名/メーカー名/キーワード/レシピカテゴリー/商品カテゴリー)
         * @param {number} [makerId] メーカーID
         * @param {number} [productCategoryId] 商品カテゴリーID
         * @param {number} [recipeCategoryId] レシピカテゴリーID
         * @param {string} [keyword] キーワード
         * @param {string} [costFrom] 原価下限
         * @param {string} [costTo] 原価上限
         * @param {number} [productId] 同じ商品を使ったレシピを検索するのに利用
         * @param {number} [isRecommend] 0/1,立ってる場合おすすめレシピの取得
         * @param {string} [restaurantLargeFormatIds] 業態(大)のID、「|」区切り、空の時は「||」。
         * @param {string} [seasonIds] 季節のID、「|」区切り、空の時は「||」。春: 1、夏: 2、秋: 3、冬: 4、オールシーズン: 5
         * @param {string} [recipeCategoryIds] レシピカテゴリーID複数の場合、「|」区切り、空の時は「||」。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countRecipes(freeWord?: string, makerId?: number, productCategoryId?: number, recipeCategoryId?: number, keyword?: string, costFrom?: string, costTo?: string, productId?: number, isRecommend?: number, restaurantLargeFormatIds?: string, seasonIds?: string, recipeCategoryIds?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.countRecipes(freeWord, makerId, productCategoryId, recipeCategoryId, keyword, costFrom, costTo, productId, isRecommend, restaurantLargeFormatIds, seasonIds, recipeCategoryIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Authは無視する。レシピ検索検索条件は全て任意、メーカーの課金金額（課金プラン）が高い順andPV数順(from番目〜to番目まで取得)
         * @summary Finds Recipes
         * @param {number} from 0スタート
         * @param {number} to
         * @param {string} [freeWord] フリーワード(料理名/食材名/商品名/メーカー名/キーワード/レシピカテゴリー/商品カテゴリー)
         * @param {number} [makerId] メーカーID
         * @param {number} [productCategoryId] 商品カテゴリーID
         * @param {number} [recipeCategoryId] レシピカテゴリーID
         * @param {string} [keyword] キーワード
         * @param {string} [costFrom] 原価下限
         * @param {string} [costTo] 原価上限
         * @param {number} [productId] 同じ商品を使ったレシピを検索するのに利用
         * @param {number} [followed] 0/1,立ってる場合フォローしているものを取得(headerからユーザーを取得)
         * @param {number} [imageSizeId] 画像サイズID
         * @param {number} [isRecommend] 0/1,立ってる場合おすすめレシピの取得
         * @param {'recommend' | 'new' | 'pv' | 'update'} [sortType] ソートの種類パラムが無い場合デフォルト。「おすすめ順、新着順、PV数順(デフォルト)、最近更新された順」
         * @param {number} [cookingTime] 調理時間（仕込み時間抜き）[分]
         * @param {string} [restaurantLargeFormatIds] 業態(大)のID、「|」区切り、空の時は「||」。
         * @param {string} [seasonIds] 季節のID、「|」区切り、空の時は「||」。春: 1、夏: 2、秋: 3、冬: 4、オールシーズン: 5
         * @param {string} [recipeCategoryIds] レシピカテゴリーID複数の場合、「|」区切り、空の時は「||」。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findRecipes(from: number, to: number, freeWord?: string, makerId?: number, productCategoryId?: number, recipeCategoryId?: number, keyword?: string, costFrom?: string, costTo?: string, productId?: number, followed?: number, imageSizeId?: number, isRecommend?: number, sortType?: 'recommend' | 'new' | 'pv' | 'update', cookingTime?: number, restaurantLargeFormatIds?: string, seasonIds?: string, recipeCategoryIds?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Recipe>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findRecipes(from, to, freeWord, makerId, productCategoryId, recipeCategoryId, keyword, costFrom, costTo, productId, followed, imageSizeId, isRecommend, sortType, cookingTime, restaurantLargeFormatIds, seasonIds, recipeCategoryIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Authは無視する。新着レシピ情報を取得
         * @summary Finds new Recipes
         * @param {number} [num] 取得レシピ数。クエリに無い場合はデフォルトの数を取得
         * @param {number} [imageSizeId] 画像サイズID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNewRecipe(num?: number, imageSizeId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Recipe>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNewRecipe(num, imageSizeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Authは無視する。単一レシピ情報を取得
         * @summary Finds a Recipe by id
         * @param {string} id レシピID
         * @param {number} pv PV立てるフラグ（これが1の時、レシピPVをカウントする）
         * @param {number} [imageSizeId] 画像サイズID
         * @param {number} [isPreview] 下書き・非公開のものを見ることが出来るかどうか(0 or undefined &#x3D;&gt; 取得不可 / 1 &#x3D;&gt; 取得可)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRecipe(id: string, pv: number, imageSizeId?: number, isPreview?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Recipe>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRecipe(id, pv, imageSizeId, isPreview, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * お気に入りレシピの判別
         * @summary Judge Recipe Follow Status by id
         * @param {number} id レシピID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRecipeFollow(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Followed>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRecipeFollow(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Authは無視する。レシピランキングを取得
         * @summary Finds Recipes Ranking
         * @param {'week'} type 取得レシピタイプ
         * @param {number} from 0スタート
         * @param {number} to
         * @param {number} [filterRecipeCategory] 絞り込み条件(レシピカテゴリーマスターのID)
         * @param {number} [filterCostFrom] 絞り込み条件(原価(円)From)
         * @param {number} [filterCostTo] 絞り込み条件(原価(円)From)
         * @param {number} [imageSizeId] 画像サイズID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRecipeRanking(type: 'week', from: number, to: number, filterRecipeCategory?: number, filterCostFrom?: number, filterCostTo?: number, imageSizeId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Recipe>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRecipeRanking(type, from, to, filterRecipeCategory, filterCostFrom, filterCostTo, imageSizeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Authは無視する。注目のキーワードレシピ情報を取得（指定されたキーワードでfindのおすすめ順と同じ結果になるようにする）また、管理画面から登録したデータがあればそちらを優先する
         * @summary Finds recommend keyword Recipes
         * @param {number} [num] 注目のキーワードの取得数
         * @param {number} [imageSizeId] 画像サイズID
         * @param {number} [key] キーワードの検索結果を複数保存しているので、そのどれを使うかの数字画面の上から1.2.3 と振るので、API先で選択する。注目のキーワードは作成バッチ毎にkeyを変えること
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRecommendKeywordRecipe(num?: number, imageSizeId?: number, key?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRecommendKeywordRecipe200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRecommendKeywordRecipe(num, imageSizeId, key, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RecipeApi - factory interface
 * @export
 */
export const RecipeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RecipeApiFp(configuration)
    return {
        /**
         * Authは無視する。レシピ検索検索条件で検索したときの結果のレシピ数
         * @summary Counts Recipes
         * @param {string} [freeWord] フリーワード(料理名/食材名/商品名/メーカー名/キーワード/レシピカテゴリー/商品カテゴリー)
         * @param {number} [makerId] メーカーID
         * @param {number} [productCategoryId] 商品カテゴリーID
         * @param {number} [recipeCategoryId] レシピカテゴリーID
         * @param {string} [keyword] キーワード
         * @param {string} [costFrom] 原価下限
         * @param {string} [costTo] 原価上限
         * @param {number} [productId] 同じ商品を使ったレシピを検索するのに利用
         * @param {number} [isRecommend] 0/1,立ってる場合おすすめレシピの取得
         * @param {string} [restaurantLargeFormatIds] 業態(大)のID、「|」区切り、空の時は「||」。
         * @param {string} [seasonIds] 季節のID、「|」区切り、空の時は「||」。春: 1、夏: 2、秋: 3、冬: 4、オールシーズン: 5
         * @param {string} [recipeCategoryIds] レシピカテゴリーID複数の場合、「|」区切り、空の時は「||」。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countRecipes(freeWord?: string, makerId?: number, productCategoryId?: number, recipeCategoryId?: number, keyword?: string, costFrom?: string, costTo?: string, productId?: number, isRecommend?: number, restaurantLargeFormatIds?: string, seasonIds?: string, recipeCategoryIds?: string, options?: any): AxiosPromise<number> {
            return localVarFp.countRecipes(freeWord, makerId, productCategoryId, recipeCategoryId, keyword, costFrom, costTo, productId, isRecommend, restaurantLargeFormatIds, seasonIds, recipeCategoryIds, options).then((request) => request(axios, basePath));
        },
        /**
         * Authは無視する。レシピ検索検索条件は全て任意、メーカーの課金金額（課金プラン）が高い順andPV数順(from番目〜to番目まで取得)
         * @summary Finds Recipes
         * @param {number} from 0スタート
         * @param {number} to
         * @param {string} [freeWord] フリーワード(料理名/食材名/商品名/メーカー名/キーワード/レシピカテゴリー/商品カテゴリー)
         * @param {number} [makerId] メーカーID
         * @param {number} [productCategoryId] 商品カテゴリーID
         * @param {number} [recipeCategoryId] レシピカテゴリーID
         * @param {string} [keyword] キーワード
         * @param {string} [costFrom] 原価下限
         * @param {string} [costTo] 原価上限
         * @param {number} [productId] 同じ商品を使ったレシピを検索するのに利用
         * @param {number} [followed] 0/1,立ってる場合フォローしているものを取得(headerからユーザーを取得)
         * @param {number} [imageSizeId] 画像サイズID
         * @param {number} [isRecommend] 0/1,立ってる場合おすすめレシピの取得
         * @param {'recommend' | 'new' | 'pv' | 'update'} [sortType] ソートの種類パラムが無い場合デフォルト。「おすすめ順、新着順、PV数順(デフォルト)、最近更新された順」
         * @param {number} [cookingTime] 調理時間（仕込み時間抜き）[分]
         * @param {string} [restaurantLargeFormatIds] 業態(大)のID、「|」区切り、空の時は「||」。
         * @param {string} [seasonIds] 季節のID、「|」区切り、空の時は「||」。春: 1、夏: 2、秋: 3、冬: 4、オールシーズン: 5
         * @param {string} [recipeCategoryIds] レシピカテゴリーID複数の場合、「|」区切り、空の時は「||」。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findRecipes(from: number, to: number, freeWord?: string, makerId?: number, productCategoryId?: number, recipeCategoryId?: number, keyword?: string, costFrom?: string, costTo?: string, productId?: number, followed?: number, imageSizeId?: number, isRecommend?: number, sortType?: 'recommend' | 'new' | 'pv' | 'update', cookingTime?: number, restaurantLargeFormatIds?: string, seasonIds?: string, recipeCategoryIds?: string, options?: any): AxiosPromise<Array<Recipe>> {
            return localVarFp.findRecipes(from, to, freeWord, makerId, productCategoryId, recipeCategoryId, keyword, costFrom, costTo, productId, followed, imageSizeId, isRecommend, sortType, cookingTime, restaurantLargeFormatIds, seasonIds, recipeCategoryIds, options).then((request) => request(axios, basePath));
        },
        /**
         * Authは無視する。新着レシピ情報を取得
         * @summary Finds new Recipes
         * @param {number} [num] 取得レシピ数。クエリに無い場合はデフォルトの数を取得
         * @param {number} [imageSizeId] 画像サイズID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewRecipe(num?: number, imageSizeId?: number, options?: any): AxiosPromise<Array<Recipe>> {
            return localVarFp.getNewRecipe(num, imageSizeId, options).then((request) => request(axios, basePath));
        },
        /**
         * Authは無視する。単一レシピ情報を取得
         * @summary Finds a Recipe by id
         * @param {string} id レシピID
         * @param {number} pv PV立てるフラグ（これが1の時、レシピPVをカウントする）
         * @param {number} [imageSizeId] 画像サイズID
         * @param {number} [isPreview] 下書き・非公開のものを見ることが出来るかどうか(0 or undefined &#x3D;&gt; 取得不可 / 1 &#x3D;&gt; 取得可)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecipe(id: string, pv: number, imageSizeId?: number, isPreview?: number, options?: any): AxiosPromise<Recipe> {
            return localVarFp.getRecipe(id, pv, imageSizeId, isPreview, options).then((request) => request(axios, basePath));
        },
        /**
         * お気に入りレシピの判別
         * @summary Judge Recipe Follow Status by id
         * @param {number} id レシピID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecipeFollow(id: number, options?: any): AxiosPromise<Followed> {
            return localVarFp.getRecipeFollow(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Authは無視する。レシピランキングを取得
         * @summary Finds Recipes Ranking
         * @param {'week'} type 取得レシピタイプ
         * @param {number} from 0スタート
         * @param {number} to
         * @param {number} [filterRecipeCategory] 絞り込み条件(レシピカテゴリーマスターのID)
         * @param {number} [filterCostFrom] 絞り込み条件(原価(円)From)
         * @param {number} [filterCostTo] 絞り込み条件(原価(円)From)
         * @param {number} [imageSizeId] 画像サイズID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecipeRanking(type: 'week', from: number, to: number, filterRecipeCategory?: number, filterCostFrom?: number, filterCostTo?: number, imageSizeId?: number, options?: any): AxiosPromise<Array<Recipe>> {
            return localVarFp.getRecipeRanking(type, from, to, filterRecipeCategory, filterCostFrom, filterCostTo, imageSizeId, options).then((request) => request(axios, basePath));
        },
        /**
         * Authは無視する。注目のキーワードレシピ情報を取得（指定されたキーワードでfindのおすすめ順と同じ結果になるようにする）また、管理画面から登録したデータがあればそちらを優先する
         * @summary Finds recommend keyword Recipes
         * @param {number} [num] 注目のキーワードの取得数
         * @param {number} [imageSizeId] 画像サイズID
         * @param {number} [key] キーワードの検索結果を複数保存しているので、そのどれを使うかの数字画面の上から1.2.3 と振るので、API先で選択する。注目のキーワードは作成バッチ毎にkeyを変えること
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecommendKeywordRecipe(num?: number, imageSizeId?: number, key?: number, options?: any): AxiosPromise<GetRecommendKeywordRecipe200Response> {
            return localVarFp.getRecommendKeywordRecipe(num, imageSizeId, key, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RecipeApi - object-oriented interface
 * @export
 * @class RecipeApi
 * @extends {BaseAPI}
 */
export class RecipeApi extends BaseAPI {
    /**
     * Authは無視する。レシピ検索検索条件で検索したときの結果のレシピ数
     * @summary Counts Recipes
     * @param {string} [freeWord] フリーワード(料理名/食材名/商品名/メーカー名/キーワード/レシピカテゴリー/商品カテゴリー)
     * @param {number} [makerId] メーカーID
     * @param {number} [productCategoryId] 商品カテゴリーID
     * @param {number} [recipeCategoryId] レシピカテゴリーID
     * @param {string} [keyword] キーワード
     * @param {string} [costFrom] 原価下限
     * @param {string} [costTo] 原価上限
     * @param {number} [productId] 同じ商品を使ったレシピを検索するのに利用
     * @param {number} [isRecommend] 0/1,立ってる場合おすすめレシピの取得
     * @param {string} [restaurantLargeFormatIds] 業態(大)のID、「|」区切り、空の時は「||」。
     * @param {string} [seasonIds] 季節のID、「|」区切り、空の時は「||」。春: 1、夏: 2、秋: 3、冬: 4、オールシーズン: 5
     * @param {string} [recipeCategoryIds] レシピカテゴリーID複数の場合、「|」区切り、空の時は「||」。
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecipeApi
     */
    public countRecipes(freeWord?: string, makerId?: number, productCategoryId?: number, recipeCategoryId?: number, keyword?: string, costFrom?: string, costTo?: string, productId?: number, isRecommend?: number, restaurantLargeFormatIds?: string, seasonIds?: string, recipeCategoryIds?: string, options?: AxiosRequestConfig) {
        return RecipeApiFp(this.configuration).countRecipes(freeWord, makerId, productCategoryId, recipeCategoryId, keyword, costFrom, costTo, productId, isRecommend, restaurantLargeFormatIds, seasonIds, recipeCategoryIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Authは無視する。レシピ検索検索条件は全て任意、メーカーの課金金額（課金プラン）が高い順andPV数順(from番目〜to番目まで取得)
     * @summary Finds Recipes
     * @param {number} from 0スタート
     * @param {number} to
     * @param {string} [freeWord] フリーワード(料理名/食材名/商品名/メーカー名/キーワード/レシピカテゴリー/商品カテゴリー)
     * @param {number} [makerId] メーカーID
     * @param {number} [productCategoryId] 商品カテゴリーID
     * @param {number} [recipeCategoryId] レシピカテゴリーID
     * @param {string} [keyword] キーワード
     * @param {string} [costFrom] 原価下限
     * @param {string} [costTo] 原価上限
     * @param {number} [productId] 同じ商品を使ったレシピを検索するのに利用
     * @param {number} [followed] 0/1,立ってる場合フォローしているものを取得(headerからユーザーを取得)
     * @param {number} [imageSizeId] 画像サイズID
     * @param {number} [isRecommend] 0/1,立ってる場合おすすめレシピの取得
     * @param {'recommend' | 'new' | 'pv' | 'update'} [sortType] ソートの種類パラムが無い場合デフォルト。「おすすめ順、新着順、PV数順(デフォルト)、最近更新された順」
     * @param {number} [cookingTime] 調理時間（仕込み時間抜き）[分]
     * @param {string} [restaurantLargeFormatIds] 業態(大)のID、「|」区切り、空の時は「||」。
     * @param {string} [seasonIds] 季節のID、「|」区切り、空の時は「||」。春: 1、夏: 2、秋: 3、冬: 4、オールシーズン: 5
     * @param {string} [recipeCategoryIds] レシピカテゴリーID複数の場合、「|」区切り、空の時は「||」。
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecipeApi
     */
    public findRecipes(from: number, to: number, freeWord?: string, makerId?: number, productCategoryId?: number, recipeCategoryId?: number, keyword?: string, costFrom?: string, costTo?: string, productId?: number, followed?: number, imageSizeId?: number, isRecommend?: number, sortType?: 'recommend' | 'new' | 'pv' | 'update', cookingTime?: number, restaurantLargeFormatIds?: string, seasonIds?: string, recipeCategoryIds?: string, options?: AxiosRequestConfig) {
        return RecipeApiFp(this.configuration).findRecipes(from, to, freeWord, makerId, productCategoryId, recipeCategoryId, keyword, costFrom, costTo, productId, followed, imageSizeId, isRecommend, sortType, cookingTime, restaurantLargeFormatIds, seasonIds, recipeCategoryIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Authは無視する。新着レシピ情報を取得
     * @summary Finds new Recipes
     * @param {number} [num] 取得レシピ数。クエリに無い場合はデフォルトの数を取得
     * @param {number} [imageSizeId] 画像サイズID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecipeApi
     */
    public getNewRecipe(num?: number, imageSizeId?: number, options?: AxiosRequestConfig) {
        return RecipeApiFp(this.configuration).getNewRecipe(num, imageSizeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Authは無視する。単一レシピ情報を取得
     * @summary Finds a Recipe by id
     * @param {string} id レシピID
     * @param {number} pv PV立てるフラグ（これが1の時、レシピPVをカウントする）
     * @param {number} [imageSizeId] 画像サイズID
     * @param {number} [isPreview] 下書き・非公開のものを見ることが出来るかどうか(0 or undefined &#x3D;&gt; 取得不可 / 1 &#x3D;&gt; 取得可)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecipeApi
     */
    public getRecipe(id: string, pv: number, imageSizeId?: number, isPreview?: number, options?: AxiosRequestConfig) {
        return RecipeApiFp(this.configuration).getRecipe(id, pv, imageSizeId, isPreview, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * お気に入りレシピの判別
     * @summary Judge Recipe Follow Status by id
     * @param {number} id レシピID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecipeApi
     */
    public getRecipeFollow(id: number, options?: AxiosRequestConfig) {
        return RecipeApiFp(this.configuration).getRecipeFollow(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Authは無視する。レシピランキングを取得
     * @summary Finds Recipes Ranking
     * @param {'week'} type 取得レシピタイプ
     * @param {number} from 0スタート
     * @param {number} to
     * @param {number} [filterRecipeCategory] 絞り込み条件(レシピカテゴリーマスターのID)
     * @param {number} [filterCostFrom] 絞り込み条件(原価(円)From)
     * @param {number} [filterCostTo] 絞り込み条件(原価(円)From)
     * @param {number} [imageSizeId] 画像サイズID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecipeApi
     */
    public getRecipeRanking(type: 'week', from: number, to: number, filterRecipeCategory?: number, filterCostFrom?: number, filterCostTo?: number, imageSizeId?: number, options?: AxiosRequestConfig) {
        return RecipeApiFp(this.configuration).getRecipeRanking(type, from, to, filterRecipeCategory, filterCostFrom, filterCostTo, imageSizeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Authは無視する。注目のキーワードレシピ情報を取得（指定されたキーワードでfindのおすすめ順と同じ結果になるようにする）また、管理画面から登録したデータがあればそちらを優先する
     * @summary Finds recommend keyword Recipes
     * @param {number} [num] 注目のキーワードの取得数
     * @param {number} [imageSizeId] 画像サイズID
     * @param {number} [key] キーワードの検索結果を複数保存しているので、そのどれを使うかの数字画面の上から1.2.3 と振るので、API先で選択する。注目のキーワードは作成バッチ毎にkeyを変えること
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecipeApi
     */
    public getRecommendKeywordRecipe(num?: number, imageSizeId?: number, key?: number, options?: AxiosRequestConfig) {
        return RecipeApiFp(this.configuration).getRecommendKeywordRecipe(num, imageSizeId, key, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReferralApi - axios parameter creator
 * @export
 */
export const ReferralApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * ユーザー登録時に登録する処理エラーでない場合はそのまま登録するもし、既に登録済みだった場合上書きする(422は有効なコードでない時のエラー)
         * @summary 他の人の紹介コードを使う
         * @param {string} referralCode 他の人から教えて貰ったこのユーザーが使う紹介コード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addReferralCode: async (referralCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'referralCode' is not null or undefined
            assertParamExists('addReferralCode', 'referralCode', referralCode)
            const localVarPath = `/referral/code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (referralCode !== undefined) {
                localVarFormParams.append('referralCode', referralCode as any);
            }


            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 他人に紹介する用の紹介コード
         * @summary 紹介コードの発行
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReferralCode: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/referral/code/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReferralApi - functional programming interface
 * @export
 */
export const ReferralApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReferralApiAxiosParamCreator(configuration)
    return {
        /**
         * ユーザー登録時に登録する処理エラーでない場合はそのまま登録するもし、既に登録済みだった場合上書きする(422は有効なコードでない時のエラー)
         * @summary 他の人の紹介コードを使う
         * @param {string} referralCode 他の人から教えて貰ったこのユーザーが使う紹介コード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addReferralCode(referralCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addReferralCode(referralCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 他人に紹介する用の紹介コード
         * @summary 紹介コードの発行
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReferralCode(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetReferralCode200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReferralCode(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReferralApi - factory interface
 * @export
 */
export const ReferralApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReferralApiFp(configuration)
    return {
        /**
         * ユーザー登録時に登録する処理エラーでない場合はそのまま登録するもし、既に登録済みだった場合上書きする(422は有効なコードでない時のエラー)
         * @summary 他の人の紹介コードを使う
         * @param {string} referralCode 他の人から教えて貰ったこのユーザーが使う紹介コード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addReferralCode(referralCode: string, options?: any): AxiosPromise<void> {
            return localVarFp.addReferralCode(referralCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 他人に紹介する用の紹介コード
         * @summary 紹介コードの発行
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReferralCode(options?: any): AxiosPromise<GetReferralCode200Response> {
            return localVarFp.getReferralCode(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReferralApi - object-oriented interface
 * @export
 * @class ReferralApi
 * @extends {BaseAPI}
 */
export class ReferralApi extends BaseAPI {
    /**
     * ユーザー登録時に登録する処理エラーでない場合はそのまま登録するもし、既に登録済みだった場合上書きする(422は有効なコードでない時のエラー)
     * @summary 他の人の紹介コードを使う
     * @param {string} referralCode 他の人から教えて貰ったこのユーザーが使う紹介コード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferralApi
     */
    public addReferralCode(referralCode: string, options?: AxiosRequestConfig) {
        return ReferralApiFp(this.configuration).addReferralCode(referralCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 他人に紹介する用の紹介コード
     * @summary 紹介コードの発行
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferralApi
     */
    public getReferralCode(options?: AxiosRequestConfig) {
        return ReferralApiFp(this.configuration).getReferralCode(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SpecialApi - axios parameter creator
 * @export
 */
export const SpecialApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Authは無視する。表示フラグが立っており、表示期限切れの特集一覧を取得(from番目〜to番目まで取得)
         * @summary Finds all Products
         * @param {number} from 0スタート
         * @param {number} to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSpecialBackNumber: async (from: number, to: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'from' is not null or undefined
            assertParamExists('getSpecialBackNumber', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('getSpecialBackNumber', 'to', to)
            const localVarPath = `/special/backNumber`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Authは無視する。現在表示中の特集一覧を取得
         * @summary Finds all Products
         * @param {number} [full] 0/1,立ってる場合公開・非公開、期限内・外問わずすべてを取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSpecialFindAll: async (full?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/special/findAll`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (full !== undefined) {
                localVarQueryParameter['full'] = full;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SpecialApi - functional programming interface
 * @export
 */
export const SpecialApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SpecialApiAxiosParamCreator(configuration)
    return {
        /**
         * Authは無視する。表示フラグが立っており、表示期限切れの特集一覧を取得(from番目〜to番目まで取得)
         * @summary Finds all Products
         * @param {number} from 0スタート
         * @param {number} to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSpecialBackNumber(from: number, to: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Special>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSpecialBackNumber(from, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Authは無視する。現在表示中の特集一覧を取得
         * @summary Finds all Products
         * @param {number} [full] 0/1,立ってる場合公開・非公開、期限内・外問わずすべてを取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSpecialFindAll(full?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Special>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSpecialFindAll(full, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SpecialApi - factory interface
 * @export
 */
export const SpecialApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SpecialApiFp(configuration)
    return {
        /**
         * Authは無視する。表示フラグが立っており、表示期限切れの特集一覧を取得(from番目〜to番目まで取得)
         * @summary Finds all Products
         * @param {number} from 0スタート
         * @param {number} to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSpecialBackNumber(from: number, to: number, options?: any): AxiosPromise<Array<Special>> {
            return localVarFp.getSpecialBackNumber(from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * Authは無視する。現在表示中の特集一覧を取得
         * @summary Finds all Products
         * @param {number} [full] 0/1,立ってる場合公開・非公開、期限内・外問わずすべてを取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSpecialFindAll(full?: number, options?: any): AxiosPromise<Array<Special>> {
            return localVarFp.getSpecialFindAll(full, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SpecialApi - object-oriented interface
 * @export
 * @class SpecialApi
 * @extends {BaseAPI}
 */
export class SpecialApi extends BaseAPI {
    /**
     * Authは無視する。表示フラグが立っており、表示期限切れの特集一覧を取得(from番目〜to番目まで取得)
     * @summary Finds all Products
     * @param {number} from 0スタート
     * @param {number} to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpecialApi
     */
    public getSpecialBackNumber(from: number, to: number, options?: AxiosRequestConfig) {
        return SpecialApiFp(this.configuration).getSpecialBackNumber(from, to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Authは無視する。現在表示中の特集一覧を取得
     * @summary Finds all Products
     * @param {number} [full] 0/1,立ってる場合公開・非公開、期限内・外問わずすべてを取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpecialApi
     */
    public getSpecialFindAll(full?: number, options?: AxiosRequestConfig) {
        return SpecialApiFp(this.configuration).getSpecialFindAll(full, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * hash = \"\"の時 ADD, hash = \"xxx\"の時Update
         * @summary Add or update a User
         * @param {number} id 利用しないPK
         * @param {string} mail
         * @param {string} userAttribute 会員属性（一般ユーザー(normal)/飲食人(cook)）
         * @param {string} name ユーザー名
         * @param {string} nameKana ユーザー名（かな）
         * @param {number} notificationMail メール通知ON/OFF (1/0)、デフォルトOFF
         * @param {string} userPlan 課金プラン（DB作成時は無料プランで登録）
         * @param {string} [userHash] firebaseのUIDと同じ。Get時はこれでUser検索する。
         * @param {string} [gender] 性別
         * @param {string} [birthday] 誕生日（yyyy-MM-dd）
         * @param {string} [nameRestaurant] 店舗名
         * @param {string} [postalCode] 郵便番号(数字のみ、数字以外は送信・受信・DB保存前に削除)
         * @param {number} [restaurantLargeLocationId] 店舗所在地（大）のマスターID
         * @param {number} [restaurantMediumLocationId] 店舗所在地（中）のマスターID
         * @param {string} [restaurantState] 店舗住所（都道府県）
         * @param {string} [restaurantCity] 店舗住所（市町村）
         * @param {string} [restaurantStreetAddress] 店舗住所（番地以降）
         * @param {string} [tell] 電話番号(数字のみ、数字以外は送信・受信・DB保存前に削除)
         * @param {number} [restaurantLargeFormatId] 店舗業態（大）のマスターID
         * @param {number} [restaurantMediumFormatId] 店舗業態（中）のマスターID
         * @param {number} [numOfRestaurantId] 店舗規模のマスターID
         * @param {number} [majorRestaurantAreaId] 主要店舗展開エリアのマスターID
         * @param {number} [lunchService] ランチ営業有無
         * @param {number} [customerUnitPriceId] 客単価のマスターID
         * @param {number} [numberOfSeats] 席数
         * @param {string} [site] ウェブサイトURL
         * @param {number} [userFreeCoin] 所持無料コイン数
         * @param {string} [image] プロフィール画像URL
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOrUpdateUser: async (id: number, mail: string, userAttribute: string, name: string, nameKana: string, notificationMail: number, userPlan: string, userHash?: string, gender?: string, birthday?: string, nameRestaurant?: string, postalCode?: string, restaurantLargeLocationId?: number, restaurantMediumLocationId?: number, restaurantState?: string, restaurantCity?: string, restaurantStreetAddress?: string, tell?: string, restaurantLargeFormatId?: number, restaurantMediumFormatId?: number, numOfRestaurantId?: number, majorRestaurantAreaId?: number, lunchService?: number, customerUnitPriceId?: number, numberOfSeats?: number, site?: string, userFreeCoin?: number, image?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addOrUpdateUser', 'id', id)
            // verify required parameter 'mail' is not null or undefined
            assertParamExists('addOrUpdateUser', 'mail', mail)
            // verify required parameter 'userAttribute' is not null or undefined
            assertParamExists('addOrUpdateUser', 'userAttribute', userAttribute)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('addOrUpdateUser', 'name', name)
            // verify required parameter 'nameKana' is not null or undefined
            assertParamExists('addOrUpdateUser', 'nameKana', nameKana)
            // verify required parameter 'notificationMail' is not null or undefined
            assertParamExists('addOrUpdateUser', 'notificationMail', notificationMail)
            // verify required parameter 'userPlan' is not null or undefined
            assertParamExists('addOrUpdateUser', 'userPlan', userPlan)
            const localVarPath = `/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (id !== undefined) {
                localVarFormParams.append('id', id as any);
            }

            if (userHash !== undefined) {
                localVarFormParams.append('userHash', userHash as any);
            }

            if (mail !== undefined) {
                localVarFormParams.append('mail', mail as any);
            }

            if (userAttribute !== undefined) {
                localVarFormParams.append('userAttribute', userAttribute as any);
            }

            if (name !== undefined) {
                localVarFormParams.append('name', name as any);
            }

            if (nameKana !== undefined) {
                localVarFormParams.append('nameKana', nameKana as any);
            }

            if (gender !== undefined) {
                localVarFormParams.append('gender', gender as any);
            }

            if (birthday !== undefined) {
                localVarFormParams.append('birthday', birthday as any);
            }

            if (nameRestaurant !== undefined) {
                localVarFormParams.append('nameRestaurant', nameRestaurant as any);
            }

            if (postalCode !== undefined) {
                localVarFormParams.append('postalCode', postalCode as any);
            }

            if (restaurantLargeLocationId !== undefined) {
                localVarFormParams.append('restaurantLargeLocationId', restaurantLargeLocationId as any);
            }

            if (restaurantMediumLocationId !== undefined) {
                localVarFormParams.append('restaurantMediumLocationId', restaurantMediumLocationId as any);
            }

            if (restaurantState !== undefined) {
                localVarFormParams.append('restaurantState', restaurantState as any);
            }

            if (restaurantCity !== undefined) {
                localVarFormParams.append('restaurantCity', restaurantCity as any);
            }

            if (restaurantStreetAddress !== undefined) {
                localVarFormParams.append('restaurantStreetAddress', restaurantStreetAddress as any);
            }

            if (tell !== undefined) {
                localVarFormParams.append('tell', tell as any);
            }

            if (restaurantLargeFormatId !== undefined) {
                localVarFormParams.append('restaurantLargeFormatId', restaurantLargeFormatId as any);
            }

            if (restaurantMediumFormatId !== undefined) {
                localVarFormParams.append('restaurantMediumFormatId', restaurantMediumFormatId as any);
            }

            if (numOfRestaurantId !== undefined) {
                localVarFormParams.append('numOfRestaurantId', numOfRestaurantId as any);
            }

            if (majorRestaurantAreaId !== undefined) {
                localVarFormParams.append('majorRestaurantAreaId', majorRestaurantAreaId as any);
            }

            if (lunchService !== undefined) {
                localVarFormParams.append('lunchService', lunchService as any);
            }

            if (customerUnitPriceId !== undefined) {
                localVarFormParams.append('customerUnitPriceId', customerUnitPriceId as any);
            }

            if (numberOfSeats !== undefined) {
                localVarFormParams.append('numberOfSeats', numberOfSeats as any);
            }

            if (site !== undefined) {
                localVarFormParams.append('site', site as any);
            }

            if (notificationMail !== undefined) {
                localVarFormParams.append('notificationMail', notificationMail as any);
            }

            if (userPlan !== undefined) {
                localVarFormParams.append('userPlan', userPlan as any);
            }

            if (userFreeCoin !== undefined) {
                localVarFormParams.append('userFreeCoin', userFreeCoin as any);
            }

            if (image !== undefined) {
                localVarFormParams.append('image', image as any);
            }


            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 複数のデバイスの可能性有, userはヘッダーから
         * @summary 対象ユーザーのデバイストークンを追加
         * @param {string} os OSの種類
         * @param {string} deviceToken デバイストークン
         * @param {string} [deviceTokenForFind] 検索用デバイストークン(ここのデバイストークンがDBに登録されていたら更新。DBにないまたはパラムがないなら追加)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUserDevice: async (os: string, deviceToken: string, deviceTokenForFind?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'os' is not null or undefined
            assertParamExists('addUserDevice', 'os', os)
            // verify required parameter 'deviceToken' is not null or undefined
            assertParamExists('addUserDevice', 'deviceToken', deviceToken)
            const localVarPath = `/user/user_devices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (os !== undefined) {
                localVarFormParams.append('os', os as any);
            }

            if (deviceToken !== undefined) {
                localVarFormParams.append('deviceToken', deviceToken as any);
            }

            if (deviceTokenForFind !== undefined) {
                localVarFormParams.append('deviceTokenForFind', deviceTokenForFind as any);
            }


            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 単一ユーザー情報を取得(userHashをheaderから取得、userHashはfirebaseのuserIdに同じ)
         * @summary Finds a User by userHash
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * hash = \"\"の時 ADD, hash = \"xxx\"の時Update
         * @summary Add or update a User
         * @param {number} id 利用しないPK
         * @param {string} mail
         * @param {string} userAttribute 会員属性（一般ユーザー(normal)/飲食人(cook)）
         * @param {string} name ユーザー名
         * @param {string} nameKana ユーザー名（かな）
         * @param {number} notificationMail メール通知ON/OFF (1/0)、デフォルトOFF
         * @param {string} userPlan 課金プラン（DB作成時は無料プランで登録）
         * @param {string} [userHash] firebaseのUIDと同じ。Get時はこれでUser検索する。
         * @param {string} [gender] 性別
         * @param {string} [birthday] 誕生日（yyyy-MM-dd）
         * @param {string} [nameRestaurant] 店舗名
         * @param {string} [postalCode] 郵便番号(数字のみ、数字以外は送信・受信・DB保存前に削除)
         * @param {number} [restaurantLargeLocationId] 店舗所在地（大）のマスターID
         * @param {number} [restaurantMediumLocationId] 店舗所在地（中）のマスターID
         * @param {string} [restaurantState] 店舗住所（都道府県）
         * @param {string} [restaurantCity] 店舗住所（市町村）
         * @param {string} [restaurantStreetAddress] 店舗住所（番地以降）
         * @param {string} [tell] 電話番号(数字のみ、数字以外は送信・受信・DB保存前に削除)
         * @param {number} [restaurantLargeFormatId] 店舗業態（大）のマスターID
         * @param {number} [restaurantMediumFormatId] 店舗業態（中）のマスターID
         * @param {number} [numOfRestaurantId] 店舗規模のマスターID
         * @param {number} [majorRestaurantAreaId] 主要店舗展開エリアのマスターID
         * @param {number} [lunchService] ランチ営業有無
         * @param {number} [customerUnitPriceId] 客単価のマスターID
         * @param {number} [numberOfSeats] 席数
         * @param {string} [site] ウェブサイトURL
         * @param {number} [userFreeCoin] 所持無料コイン数
         * @param {string} [image] プロフィール画像URL
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addOrUpdateUser(id: number, mail: string, userAttribute: string, name: string, nameKana: string, notificationMail: number, userPlan: string, userHash?: string, gender?: string, birthday?: string, nameRestaurant?: string, postalCode?: string, restaurantLargeLocationId?: number, restaurantMediumLocationId?: number, restaurantState?: string, restaurantCity?: string, restaurantStreetAddress?: string, tell?: string, restaurantLargeFormatId?: number, restaurantMediumFormatId?: number, numOfRestaurantId?: number, majorRestaurantAreaId?: number, lunchService?: number, customerUnitPriceId?: number, numberOfSeats?: number, site?: string, userFreeCoin?: number, image?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addOrUpdateUser(id, mail, userAttribute, name, nameKana, notificationMail, userPlan, userHash, gender, birthday, nameRestaurant, postalCode, restaurantLargeLocationId, restaurantMediumLocationId, restaurantState, restaurantCity, restaurantStreetAddress, tell, restaurantLargeFormatId, restaurantMediumFormatId, numOfRestaurantId, majorRestaurantAreaId, lunchService, customerUnitPriceId, numberOfSeats, site, userFreeCoin, image, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 複数のデバイスの可能性有, userはヘッダーから
         * @summary 対象ユーザーのデバイストークンを追加
         * @param {string} os OSの種類
         * @param {string} deviceToken デバイストークン
         * @param {string} [deviceTokenForFind] 検索用デバイストークン(ここのデバイストークンがDBに登録されていたら更新。DBにないまたはパラムがないなら追加)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addUserDevice(os: string, deviceToken: string, deviceTokenForFind?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addUserDevice(os, deviceToken, deviceTokenForFind, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 単一ユーザー情報を取得(userHashをheaderから取得、userHashはfirebaseのuserIdに同じ)
         * @summary Finds a User by userHash
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUser(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * hash = \"\"の時 ADD, hash = \"xxx\"の時Update
         * @summary Add or update a User
         * @param {number} id 利用しないPK
         * @param {string} mail
         * @param {string} userAttribute 会員属性（一般ユーザー(normal)/飲食人(cook)）
         * @param {string} name ユーザー名
         * @param {string} nameKana ユーザー名（かな）
         * @param {number} notificationMail メール通知ON/OFF (1/0)、デフォルトOFF
         * @param {string} userPlan 課金プラン（DB作成時は無料プランで登録）
         * @param {string} [userHash] firebaseのUIDと同じ。Get時はこれでUser検索する。
         * @param {string} [gender] 性別
         * @param {string} [birthday] 誕生日（yyyy-MM-dd）
         * @param {string} [nameRestaurant] 店舗名
         * @param {string} [postalCode] 郵便番号(数字のみ、数字以外は送信・受信・DB保存前に削除)
         * @param {number} [restaurantLargeLocationId] 店舗所在地（大）のマスターID
         * @param {number} [restaurantMediumLocationId] 店舗所在地（中）のマスターID
         * @param {string} [restaurantState] 店舗住所（都道府県）
         * @param {string} [restaurantCity] 店舗住所（市町村）
         * @param {string} [restaurantStreetAddress] 店舗住所（番地以降）
         * @param {string} [tell] 電話番号(数字のみ、数字以外は送信・受信・DB保存前に削除)
         * @param {number} [restaurantLargeFormatId] 店舗業態（大）のマスターID
         * @param {number} [restaurantMediumFormatId] 店舗業態（中）のマスターID
         * @param {number} [numOfRestaurantId] 店舗規模のマスターID
         * @param {number} [majorRestaurantAreaId] 主要店舗展開エリアのマスターID
         * @param {number} [lunchService] ランチ営業有無
         * @param {number} [customerUnitPriceId] 客単価のマスターID
         * @param {number} [numberOfSeats] 席数
         * @param {string} [site] ウェブサイトURL
         * @param {number} [userFreeCoin] 所持無料コイン数
         * @param {string} [image] プロフィール画像URL
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOrUpdateUser(id: number, mail: string, userAttribute: string, name: string, nameKana: string, notificationMail: number, userPlan: string, userHash?: string, gender?: string, birthday?: string, nameRestaurant?: string, postalCode?: string, restaurantLargeLocationId?: number, restaurantMediumLocationId?: number, restaurantState?: string, restaurantCity?: string, restaurantStreetAddress?: string, tell?: string, restaurantLargeFormatId?: number, restaurantMediumFormatId?: number, numOfRestaurantId?: number, majorRestaurantAreaId?: number, lunchService?: number, customerUnitPriceId?: number, numberOfSeats?: number, site?: string, userFreeCoin?: number, image?: string, options?: any): AxiosPromise<void> {
            return localVarFp.addOrUpdateUser(id, mail, userAttribute, name, nameKana, notificationMail, userPlan, userHash, gender, birthday, nameRestaurant, postalCode, restaurantLargeLocationId, restaurantMediumLocationId, restaurantState, restaurantCity, restaurantStreetAddress, tell, restaurantLargeFormatId, restaurantMediumFormatId, numOfRestaurantId, majorRestaurantAreaId, lunchService, customerUnitPriceId, numberOfSeats, site, userFreeCoin, image, options).then((request) => request(axios, basePath));
        },
        /**
         * 複数のデバイスの可能性有, userはヘッダーから
         * @summary 対象ユーザーのデバイストークンを追加
         * @param {string} os OSの種類
         * @param {string} deviceToken デバイストークン
         * @param {string} [deviceTokenForFind] 検索用デバイストークン(ここのデバイストークンがDBに登録されていたら更新。DBにないまたはパラムがないなら追加)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUserDevice(os: string, deviceToken: string, deviceTokenForFind?: string, options?: any): AxiosPromise<void> {
            return localVarFp.addUserDevice(os, deviceToken, deviceTokenForFind, options).then((request) => request(axios, basePath));
        },
        /**
         * 単一ユーザー情報を取得(userHashをheaderから取得、userHashはfirebaseのuserIdに同じ)
         * @summary Finds a User by userHash
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(options?: any): AxiosPromise<User> {
            return localVarFp.getUser(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * hash = \"\"の時 ADD, hash = \"xxx\"の時Update
     * @summary Add or update a User
     * @param {number} id 利用しないPK
     * @param {string} mail
     * @param {string} userAttribute 会員属性（一般ユーザー(normal)/飲食人(cook)）
     * @param {string} name ユーザー名
     * @param {string} nameKana ユーザー名（かな）
     * @param {number} notificationMail メール通知ON/OFF (1/0)、デフォルトOFF
     * @param {string} userPlan 課金プラン（DB作成時は無料プランで登録）
     * @param {string} [userHash] firebaseのUIDと同じ。Get時はこれでUser検索する。
     * @param {string} [gender] 性別
     * @param {string} [birthday] 誕生日（yyyy-MM-dd）
     * @param {string} [nameRestaurant] 店舗名
     * @param {string} [postalCode] 郵便番号(数字のみ、数字以外は送信・受信・DB保存前に削除)
     * @param {number} [restaurantLargeLocationId] 店舗所在地（大）のマスターID
     * @param {number} [restaurantMediumLocationId] 店舗所在地（中）のマスターID
     * @param {string} [restaurantState] 店舗住所（都道府県）
     * @param {string} [restaurantCity] 店舗住所（市町村）
     * @param {string} [restaurantStreetAddress] 店舗住所（番地以降）
     * @param {string} [tell] 電話番号(数字のみ、数字以外は送信・受信・DB保存前に削除)
     * @param {number} [restaurantLargeFormatId] 店舗業態（大）のマスターID
     * @param {number} [restaurantMediumFormatId] 店舗業態（中）のマスターID
     * @param {number} [numOfRestaurantId] 店舗規模のマスターID
     * @param {number} [majorRestaurantAreaId] 主要店舗展開エリアのマスターID
     * @param {number} [lunchService] ランチ営業有無
     * @param {number} [customerUnitPriceId] 客単価のマスターID
     * @param {number} [numberOfSeats] 席数
     * @param {string} [site] ウェブサイトURL
     * @param {number} [userFreeCoin] 所持無料コイン数
     * @param {string} [image] プロフィール画像URL
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public addOrUpdateUser(id: number, mail: string, userAttribute: string, name: string, nameKana: string, notificationMail: number, userPlan: string, userHash?: string, gender?: string, birthday?: string, nameRestaurant?: string, postalCode?: string, restaurantLargeLocationId?: number, restaurantMediumLocationId?: number, restaurantState?: string, restaurantCity?: string, restaurantStreetAddress?: string, tell?: string, restaurantLargeFormatId?: number, restaurantMediumFormatId?: number, numOfRestaurantId?: number, majorRestaurantAreaId?: number, lunchService?: number, customerUnitPriceId?: number, numberOfSeats?: number, site?: string, userFreeCoin?: number, image?: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).addOrUpdateUser(id, mail, userAttribute, name, nameKana, notificationMail, userPlan, userHash, gender, birthday, nameRestaurant, postalCode, restaurantLargeLocationId, restaurantMediumLocationId, restaurantState, restaurantCity, restaurantStreetAddress, tell, restaurantLargeFormatId, restaurantMediumFormatId, numOfRestaurantId, majorRestaurantAreaId, lunchService, customerUnitPriceId, numberOfSeats, site, userFreeCoin, image, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 複数のデバイスの可能性有, userはヘッダーから
     * @summary 対象ユーザーのデバイストークンを追加
     * @param {string} os OSの種類
     * @param {string} deviceToken デバイストークン
     * @param {string} [deviceTokenForFind] 検索用デバイストークン(ここのデバイストークンがDBに登録されていたら更新。DBにないまたはパラムがないなら追加)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public addUserDevice(os: string, deviceToken: string, deviceTokenForFind?: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).addUserDevice(os, deviceToken, deviceTokenForFind, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 単一ユーザー情報を取得(userHashをheaderから取得、userHashはfirebaseのuserIdに同じ)
     * @summary Finds a User by userHash
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUser(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getUser(options).then((request) => request(this.axios, this.basePath));
    }
}


