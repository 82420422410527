import {BaseDao} from "./BaseDao";
// @ts-ignore
import {log} from '../../../../../environment';
import {SearchDataEntityModel} from "../../entityModels/SearchDataEntityModel";
import AppG from "../../../util/AppG";

class SearchDataDao extends BaseDao<SearchDataEntityModel> {
    _getModel(): SearchDataEntityModel {
        return new SearchDataEntityModel();
    }

    /**
     * 検索履歴に追加
     */
    addNew = (text: string) => {
        if (text == '') {
            return;
        }
        this.get().then(searchData => {
            if (searchData != null) {
                if (searchData.searchWord != null) {
                    searchData.searchWord = `${text}|${searchData.searchWord}`;
                } else {
                    searchData.searchWord = `${text}`;
                }
                this.addOrUpdate(searchData);
                AppG.searchHistory?.push({value: text, indexName: ''});
            }
        });
    }
}

// new 付なのでシングルトン
export default new SearchDataDao();
