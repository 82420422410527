import LoginDataDao from "../data/dao/local/LoginDataDao";
import {EmitterSubscription, Linking, Platform} from "react-native";
import AsyncStorage from '@react-native-async-storage/async-storage';
import url from 'url';
import I18n from "../../resources/language/i18n";
import LoginDataNetwork from "../data/network/LoginDataNetwork";
import AlertUtil from "./AlertUtil";
import {AuthUtil} from "./firebase/AuthUtil";
// @ts-ignore
import {getEnvVars, log} from '../../../environment';
import {AccountApiFactory, User, UserApiFactory} from "../data/network/swagger-gen";
import AppG from "./AppG";
import {AxiosResponse} from "axios";
import RedirectUtil from "./RedirectUtil";
import {TutorialDataEntityModel} from "../data/entityModels/TutorialDataEntityModel";
import TutorialDataDao from "../data/dao/local/TutorialDataDao";
import ExpoUtil from "./ExpoUtil";
import * as Random from 'expo-random';
import * as Crypto from 'expo-crypto';
import moment from "moment";
import AnalyticsDataDao from "../data/dao/local/AnalyticsDataDao";
import {AnalyticsDataEntityModel} from "../data/entityModels/AnalyticsDataEntityModel";
import VersionUtil from "./VersionUtil";
import NotificationUtil from "./NotificationUtil";
import ValidateUtil from "./ValidateUtil";
import * as Notifications from "expo-notifications";
import AnalyticsUtil, {AnalyticsEventName, DOpenEventName} from "./firebase/AnalyticsUtil";
import CookieUtil from "./CookieUtil";
import WhatIsMemberShipModalDataDao from "../data/dao/local/WhatIsMemberShipModalDataDao";
import {WhatIsMemberShipModalDataEntityModel} from "../data/entityModels/WhatIsMemberShipModalDataEntityModel";
import WhatIsSampleRequestModalDataDao from "../data/dao/local/WhatIsSampleRequestModalDataDao";
import {WhatIsSampleRequestModalDataEntityModel} from "../data/entityModels/WhatIsSampleRequestModalDataEntityModel";
import {
    ProductReviewReminderModalDataEntityModel
} from "../data/entityModels/ProductReviewReminderModalDataEntityModel";
import ProductReviewReminderModalDataDao from "../data/dao/local/ProductReviewReminderModalDataDao";


class LoginUtil {

    // @ts-ignore
    _env: {
        REACT_APP_COOKIE_DOMAIN: string,
    } = getEnvVars();

    _navigation: any = null;
    _focusListener: any[string] = [];
    /**
     * アプリの場合、画面遷移した直後にtrue
     */
    _refreshedScreenFlg: boolean = false;

    /**
     * iOS 用 URLイベントリスナー
     */
    _urlEventListener: EmitterSubscription | null = null;

    setRefreshedScreenFlg = (value: boolean) => {
        this._refreshedScreenFlg = value;
    }

    getRefreshedScreenFlg = () : boolean => {
        return this._refreshedScreenFlg;
    }

    /**
     * アナリティクス用のUUIDをセット、Webブラウザのデータを故意に消すかアプリアンインストールまで同じ値
     */
    setAnalyticsHash = (callback:(()=>void)| null = null) => {
        const func = () => {
            if (callback != null) {
                callback();
            }
        }

        // アナリティクス用のUUIDをセット、Webブラウザのデータを故意に消すかアプリアンインストールまで同じ値
        AnalyticsDataDao.get().then(analyticsData => {
            if (analyticsData != null) {
                if (analyticsData?.analytics_hash != null) {
                    if (AppG.analyticsHash == null) {
                        AppG.analyticsHash = analyticsData?.analytics_hash;
                    }
                    func();
                    return;
                } else {
                    Crypto.digestStringAsync(
                        Crypto.CryptoDigestAlgorithm.SHA256,
                        `${Random.getRandomBytes(8)}_${moment().date().toString()}`
                    ).then(digest => {
                        if (AppG.analyticsHash == null) {
                            AppG.analyticsHash = digest;
                            let _analyticsDataEntityModel = new AnalyticsDataEntityModel();
                            _analyticsDataEntityModel.analytics_hash = digest;
                            AnalyticsDataDao.addOrUpdate(_analyticsDataEntityModel);
                        }
                        func();
                        return;
                    });
                }
            } else {
                func();
                return;
            }
        })
    }

    /**
     * 各Screenの読み込みで行うログインチェック
     * @param navigation ナビゲーション
     * @param successFunc 成功時のFunc
     */
        // @ts-ignore
    interruptOpenScreen = (navigation, successFunc: (() => void) | null = null) => {

        // description
        if (Platform.OS == 'web') {
            document.title = 'Reci BASE-プロ向けレシピ検索サイト-ReciBASE(レシベース)';
            let description = document.getElementsByName('description')[0];
            description.setAttribute("content", "Reci BASE（レシベース）は食に関わる全ての人の為のレシピ情報サイトです。メーカーが想いを込めてつくった商品と料理人の本当に知りたいコトであったり知るべきコト,ReciBASE（レシベース）はそんなメーカーと料理人の『本当の想い』にリアルに応える商品カタログ付きレシピアプリです。");
        }

        // バージョンチェック
        VersionUtil.updateVersionAlert();

        const { routeName } = navigation.state;

        let func = () => {
            return;
        }
        if (!AppG.startedApp) {
            // 起動時に訪問数をカウントするためのアナリティクスを送信する
            func = () => {
                AnalyticsUtil.sendAnalytics(
                    DOpenEventName.visit,
                    AnalyticsEventName.visit,
                    null,
                    null,
                    null,
                    null,
                    null,
                );
            }
        }

        if (Platform.OS == 'web') {
            if (CookieUtil.getCookie('recibase_web_visit') == null) {
                // Webの場合はセッション会誌時に訪問数をカウントするためのアナリティクスを送信する
                func = () => {
                    // if (document.domain == 'localhost' || document.domain == '127.0.0.1') {
                    //     return;
                    // }
                    AnalyticsUtil.sendAnalytics(
                        DOpenEventName.visit,
                        AnalyticsEventName.visit,
                        null,
                        null,
                        null,
                        null,
                        null,
                    );
                }
            }
            this.setCookieWebVisit();
        }
        AppG.startedApp = true;
        this.setAnalyticsHash(func);

        LoginDataDao.get().then(loginData => {
            if (loginData != null
                && loginData?.user_id != null
                && loginData?.user_hash != null
                && loginData?.mail != null
                && loginData?.password != null
            ) {
                if (successFunc == null) {
                    successFunc = () => {
                        // ログイン成功時は移動しないように上書き
                    };
                }

                if (AuthUtil.getCurrentUser() != null) {
                    AuthUtil.getCurrentUser()?.getIdToken().then((firebaseToken: string) => {
                        // log.debug(`-------------------- Firebase Token -----------------`);
                        // log.debug(firebaseToken);

                        // FireBaseトークンの更新
                        if (firebaseToken != null) {
                            // アクセストークンをグローバル変数にセット
                            AppG.apiKey = firebaseToken;
                        } else {
                            // 通信エラー
                            log.debug(`---------------Network Error (Login.interruptOpenScreen)--------------------`);
                            log.debug(`firebase token is null`);
                        }
                    })


                    // firebaseログインしてたら
                    if (AppG.user == null) {
                        // ユーザー取得できてなかったら
                        const getUser = UserApiFactory(AppG.getConfiguration(), AppG.getBasePath())
                            .getUser().then((userRes: AxiosResponse<User>) => {
                                AppG.user = userRes.data;
                                AppG.setAnalyticJpData();
                                this.setCookieWordpress();
                                log.debug(`----------- User is ${AppG.user} ----------------`);
                                successFunc!();
                            });
                        Promise.all([getUser]);
                    } else {
                        AppG.setAnalyticJpData();
                        this.setCookieWordpress();
                        successFunc();
                    }
                    log.debug('---------------Already Login!---------------');
                    return;
                }

                const failFunc = () => {
                    if (navigation != null) {
                        navigation.navigate('LoginScreen');
                    }
                };
                // ログイン処理通信
                log.debug('---------------Send Login!---------------');
                if (navigation != null) {
                    this.login(loginData.mail, loginData.password, navigation, successFunc, failFunc).then(() => {});
                }
            } else {
                // ローカルStorageからデータ読み込みに失敗した場合(故意にログアウトしていた場合)
                if (successFunc == null) {
                    successFunc = () => {
                        // ログイン成功時は移動しないように上書き
                    };
                }
                successFunc();
            }
        });
    };

    loginFromStartApp = () => {
        this.loginFromNotification(() => {}, false);
    }

    /**
     * 通知からログインするとき
     * @param successFunc 成功時の func
     */
    loginFromNotification = (successFunc: (() => void), notification = true) => {

        const failFunc = () => {
            if (AppG.retryNumLoginForAnalyticsNotification <= 0 && notification) {
                // Analytics送信
                successFunc();
            }

            AppG.retryNumLoginForAnalyticsNotification--;   // リトライ回数を減らす
        };

        LoginDataDao.get().then(loginData => {
            if (loginData != null
                && loginData?.user_id != null
                && loginData?.user_hash != null
                && loginData?.mail != null
                && loginData?.password != null
            ) {
                if (AuthUtil.getCurrentUser() != null) {
                    AuthUtil.getCurrentUser()?.getIdToken().then((firebaseToken: string) => {
                        // log.debug(`-------------------- Firebase Token -----------------`);
                        // log.debug(firebaseToken);

                        // FireBaseトークンの更新
                        if (firebaseToken != null) {
                            // アクセストークンをグローバル変数にセット
                            AppG.apiKey = firebaseToken;
                        } else {
                            // 通信エラー
                            if (ValidateUtil.isEmptyExact(AppG.apiKey)) {
                                failFunc();
                                return;
                            }
                        }
                    })


                    // ログインしてたら
                    if (AppG.user == null) {
                        // ユーザー取得できてなかったら
                        const getUser = UserApiFactory(AppG.getConfiguration(), AppG.getBasePath())
                            .getUser().then((userRes: AxiosResponse<User>) => {
                                AppG.user = userRes.data;
                                AppG.setAnalyticJpData();
                                this.setCookieWordpress();
                                log.debug(`----------- User is ${AppG.user} ----------------`);
                                successFunc!();
                            });
                        Promise.all([getUser]);
                    } else {
                        AppG.setAnalyticJpData();
                        this.setCookieWordpress();
                        successFunc();
                    }
                    log.debug('---------------Already Login!---------------');
                    return;
                }

                // ログイン処理通信
                log.debug('---------------Send Login!---------------');

                const appLogin = async () => {
                    if (loginData == null || ValidateUtil.isEmptyExact(loginData.mail) || ValidateUtil.isEmptyExact(loginData.password)) {
                        failFunc();
                        return;
                    }

                    await LoginDataNetwork.login(loginData.mail!, loginData.password!).then(loginData => {
                        if (loginData != null
                            && loginData.user_id != null
                            && loginData.user_hash != null
                            && loginData.mail != null
                            && loginData.password != null) {
                            try {
                                // ローカルストレージに、mail, password, user_id, user_hash を保存
                                LoginDataDao.addOrUpdate(loginData);
                                // 成功時の処理
                                successFunc();
                            } catch (e) {
                                failFunc();
                            }
                        } else {
                            if (AuthUtil.getCurrentUser() != null && AppG.apiKey != null) {
                                // firebase login OK で API login Error なら API sign up がまだなのでなにもしない
                                return;
                            } else {
                                // firebaseのログインは成功しているので基本はここにはこない
                                failFunc();
                            }
                        }
                    }).catch(e => {
                        // firebaseのログインは成功しているので基本はここにはこない
                        failFunc();
                    });
                }

                // 先に Firebase Login
                AuthUtil.login(loginData.mail, loginData.password, appLogin, failFunc);

            } else {
                failFunc();
            }
        });
    }

    /**
     * 新規登録処理。
     * @param mail  メールアドレス
     * @param password  パスワード
     * @param passwordConfirm   確認用パスワード
     * @param sCallback 成功時のコールバック(確認メール送信しましたページへ遷移させる)
     * @param eCallback
     */
    signUp = (mail: string, password: string, passwordConfirm: string, sCallback: () => void, eCallback: () => void) => {
        LoginDataNetwork.signUp(mail, password, passwordConfirm, sCallback, eCallback)
            .catch((e: any) => {
                AlertUtil.alert(`${I18n.t('errorNetworkTitle')}`, `${I18n.t('errorNetwork')}`);
                log.debug(`---------------Network Error (LoginUtil.signIn)--------------------`);
                log.debug(e);
                eCallback();
            });
    };

    /**
     * Login 処理。Login チェックも兼ねる
     * @param mail  メールアドレス
     * @param password  パスワード
     * @param navigation
     * @param successFunc   ログイン成功後の処理
     * @param failFunc  ログイン失敗後の処理
     */
    login = async (mail: string, password: string, navigation: any, successFunc: (data: any) => void, failFunc: () => void) => {
        // if (AppG.getConfiguration() == null || AppG.getConfiguration().apiKey == null) {
        //     // アカウントの認証をまだしていない
        //     failFunc();
        // }
        const appLogin = async () => {
            await LoginDataNetwork.login(mail, password).then(loginData => {
                if (loginData != null
                    && loginData.user_id != null
                    && loginData.user_hash != null
                    && loginData.mail != null
                    && loginData.password != null) {
                    try {
                        // ローカルストレージに、mail, password, user_id, user_hash を保存
                        LoginDataDao.addOrUpdate(loginData);
                        this.setCookieWordpress();

                        // ログインアナリティクス送信
                        this.sendLoginAnalytics();


                        // ログイン処理成功時の処理
                        successFunc(loginData);
                    } catch (e) {
                        failFunc();
                        AlertUtil.alert(`${I18n.t('errorNetworkTitle')}`, `${I18n.t('loginFailed')}`);
                        log.debug(`---------------Network Error (LoginUtil.login.loginFailed)--------------------`);
                        log.debug(e);
                    }
                } else {
                    if (AuthUtil.getCurrentUser() != null && AppG.apiKey != null) {
                        // firebase login OK で API login Error なら API sign up がまだ
                        // 通知許可アラート
                        NotificationUtil.registerForPushNotificationsAsync().then(token => {
                            //コンソールに出力
                            log.debug('------------------------ Push Token -----------------------');
                            log.debug(token);

                            if (Platform.OS == 'android') {

                                let headers = {
                                    "Authorization": `Bearer ${AppG.apiKey}`
                                };

                                fetch(`${AppG.getBasePath()}/account/signup`, {
                                    method: "POST",
                                    headers: headers,
                                    credentials: 'include',
                                })
                                    .then(response => {
                                        if (response.ok) {

                                            // API で登録したので、もう一回ログイン処理
                                            LoginDataNetwork.login(mail, password).then(loginData => {
                                                if (loginData != null
                                                    && loginData.user_id != null
                                                    && loginData.user_hash != null
                                                    && loginData.mail != null
                                                    && loginData.password != null) {
                                                    try {
                                                        // ローカルストレージに、mail, password, user_id, user_hash を保存
                                                        LoginDataDao.addOrUpdate(loginData);
                                                        this.setCookieWordpress();
                                                        // 成功時の処理
                                                        successFunc(loginData);
                                                    } catch (e) {
                                                        failFunc();
                                                        AlertUtil.alert(`${I18n.t('errorNetworkTitle')}`, `${I18n.t('loginFailed')}`);
                                                        log.debug(`---------------Network Error (LoginUtil.login.loginFailed2) android--------------------`);
                                                        log.debug(e);
                                                    }
                                                }
                                            });

                                        }
                                    })
                                    .catch((e) => {
                                        // サインアップできなかったので、アカウントorパスワード間違い
                                        failFunc();
                                        AlertUtil.alert(`${I18n.t('loginFailed')}`, `${I18n.t('incorrectAuth')}`);
                                        log.debug(`---------------Network Error (LoginUtil.login.incorrectAuth2) android --------------------`);
                                        log.debug(`mail: ${mail}, password: ${password}`);
                                        log.debug(e.toString());
                                    })

                            } else {
                                // andriod 以外
                                AccountApiFactory(AppG.getConfiguration(), AppG.getBasePath())
                                    .signup(!ValidateUtil.isEmptyExact(token)?token!:undefined).then(() => {
                                    // API で登録したので、もう一回ログイン処理
                                    LoginDataNetwork.login(mail, password).then(loginData => {
                                        if (loginData != null
                                            && loginData.user_id != null
                                            && loginData.user_hash != null
                                            && loginData.mail != null
                                            && loginData.password != null) {
                                            try {
                                                // ローカルストレージに、mail, password, user_id, user_hash を保存
                                                LoginDataDao.addOrUpdate(loginData);
                                                this.setCookieWordpress();
                                                // 成功時の処理
                                                successFunc(loginData);
                                            } catch (e) {
                                                failFunc();
                                                AlertUtil.alert(`${I18n.t('errorNetworkTitle')}`, `${I18n.t('loginFailed')}`);
                                                log.debug(`---------------Network Error (LoginUtil.login.loginFailed2)--------------------`);
                                                log.debug(e);
                                            }
                                        }
                                    });
                                })
                                    .catch(e => {
                                        // サインアップできなかったので、アカウントorパスワード間違い
                                        failFunc();
                                        AlertUtil.alert(`${I18n.t('loginFailed')}`, `${I18n.t('incorrectAuth')}`);
                                        log.debug(`---------------Network Error (LoginUtil.login.incorrectAuth2)--------------------`);
                                        log.debug(`mail: ${mail}, password: ${password}`);
                                        log.debug(e.toString());
                                    })
                            }
                        });

                    } else {
                        // firebaseのログインは成功しているので基本はここにはこない
                        failFunc();
                        AlertUtil.alert(`${I18n.t('errorNetworkTitle')}`, `${I18n.t('incorrectAuth')}`);
                        log.debug(`---------------Network Error (LoginUtil.login.incorrectAuth)--------------------`);
                        log.debug(`mail: ${mail}, password: ${password}`);
                    }
                }
            }).catch(e => {
                // firebaseのログインは成功しているので基本はここにはこない
                failFunc();
                AlertUtil.alert(`${I18n.t('errorNetworkTitle')}`, `${I18n.t('errorNetwork')}`);
                log.debug(`---------------Network Error (LoginUtil.logIn)--------------------`);
                log.debug(e);
            });
        }

        // 先に Firebase Login
        AuthUtil.login(mail, password, appLogin, failFunc);
    };

    /**
     * ログアウト
     * @param successFunc
     * @param failFunc
     */
    logout = (successFunc: (data: any) => void, failFunc: () => void) => {
        try {
            LoginDataDao.delete();
            AuthUtil.logout(() => {});
            AppG.apiKey = null;
            AppG.user = null;
            AppG.userAnalyticsJp = null;
            AppG.apiKey = null;
            this.deleteCookieWordpress();
            AsyncStorage.clear();    // ローカルの保存データ全削除
            ExpoUtil.setShouldReloadAppTab('all');   // 全タブリロード対象

            // アナリティクス用ハッシュのセットし直し
            if (AppG.analyticsHash != null) {
                let _analyticsDataEntityModel = new AnalyticsDataEntityModel();
                _analyticsDataEntityModel.analytics_hash = AppG.analyticsHash;
                AnalyticsDataDao.addOrUpdate(_analyticsDataEntityModel);
            }

            // チュートリアル見たかどうかのセットし直し
            const tutorialData = new TutorialDataEntityModel();
            tutorialData.hide_flg_tutorial = '1';
            tutorialData.last_show_date_tutorial = moment().toString();
            TutorialDataDao.addOrUpdate(tutorialData);
            // 会員訴求
            const whatIsMemberShipModalDataEntityModel = new WhatIsMemberShipModalDataEntityModel();
            whatIsMemberShipModalDataEntityModel.hide_flg_what_is_member_ship = '1';
            whatIsMemberShipModalDataEntityModel.last_show_date_what_is_member_ship = moment().toString();
            WhatIsMemberShipModalDataDao.addOrUpdate(whatIsMemberShipModalDataEntityModel);
            // サンプル依頼とは
            const whatIsSampleRequestModalDataEntityModel = new WhatIsSampleRequestModalDataEntityModel();
            whatIsSampleRequestModalDataEntityModel.hide_flg_what_is_sample_request = '1';
            WhatIsSampleRequestModalDataDao.addOrUpdate(whatIsSampleRequestModalDataEntityModel);
            // レビュー催促
            const productReviewReminderModalDataEntityModel = new ProductReviewReminderModalDataEntityModel();
            productReviewReminderModalDataEntityModel.hide_flg_product_review_reminder = '1';
            productReviewReminderModalDataEntityModel.last_show_date_product_review_reminder = moment().toString();
            ProductReviewReminderModalDataDao.addOrUpdate(productReviewReminderModalDataEntityModel);
            // 成功時の処理
            successFunc({});
        } catch (e) {
            failFunc();
            AlertUtil.alert(`${I18n.t('errorNetworkTitle')}`, `${I18n.t('logoutFailed')}`);
            log.debug(`---------------Network Error (LoginUtil.logIn)--------------------`);
            log.debug(e);
        }
    };

    /**
     * パスワード変更
     * @param nowPassword   現在のパスワード
     * @param newPassword   新しいパスワード
     * @param callback
     */
    changePassword = (nowPassword: string, newPassword: string, callback: () => void, failCallBack: () => void) => {
        LoginDataNetwork.changePassword(nowPassword, newPassword, callback, failCallBack);
    };

    /**
     * パスワードリセットメールの送信
     * @param email 送信先メールアドレス
     */
    resetPassword = (email: string, callback: () => void, failCallBack: () => void) => {
        LoginDataNetwork.resetPassword(email, callback, failCallBack);
    };


    /**
     * リスナーの追加
     * @param navigation
     * @param didFocus 画面遷移で画面を開いたときの処理
     * @param didBlur 画面遷移で画面を離れたときの処理
     */
    // @ts-ignore
    setDefaultListener(navigation, didFocus: (() => void) | null = null, didBlur: (() => void) | null = null) {
        this._navigation = navigation;
        const { routeName } = navigation.state;

        this.removeDefaultListener(navigation);

        // 画面遷移時のイベント取得
        this._focusListener[`${routeName}`] = navigation.addListener("didFocus", () => {
            // The screen is focused
            // Call any action

            if (Platform.OS != 'web') {
                // アイコンバッヂの更新
                Notifications.setBadgeCountAsync((AppG.badgeChat == null? 0 : AppG.badgeChat) + (AppG.badgeHistory == null? 0 : AppG.badgeHistory));
                this.setRefreshedScreenFlg(true);
            }

            if (didFocus != null) {
                didFocus();
            }
        });

        // 画面離れるときのイベント
        this._focusListener[`${routeName}`] = navigation.addListener("didBlur", () => {
            if (didBlur != null) {
                didBlur();
            }
        });

        // 外部リンクから開いたときの処理
        // iosのアプリ起動していないときにURLで開いた時のハンドリングにURL取得 または androidの起動
        Linking.getInitialURL()
            .then(url => {
                if (url) {
                    RedirectUtil._openFromUrlScheme(url, this._navigation)
                }
            })
            .catch(e => {
                log.debug(e)
            });
        if (Platform.OS === 'ios' && this._urlEventListener == null) {
            // iosの場合はハンドラを経由する必要があるので追加
            // アプリ起動時（バックグラウンド）でURLから開いた時のハンドリング用にURL取得
            this._urlEventListener = Linking.addEventListener('url', this._handleOpenURL);
        }
    }

    /**
     * リスナーの削除
     */
    removeDefaultListener = (navigation: any) => {
        const { routeName } = navigation.state;
        if (this._focusListener[`${routeName}`] != null) {
            // 画面遷移時のイベントリスナーの削除
            this._focusListener[`${routeName}`].remove();
        }

        if (Platform.OS === 'ios' && this._urlEventListener != null) {
            //外部リンクから開いたときのリスナーの削除
            this._urlEventListener.remove();
            this._urlEventListener = null;
        }
    };

    /**
     * URL スキームを叩いたときのイベントハンドリング
     * @param event
     * @private
     */
        // @ts-ignore
    _handleOpenURL = (event) => {
        if (event.url) {
            RedirectUtil._openFromUrlScheme(event.url, this._navigation)
        }
    };

    /**
     * 特集用のクッキーをセット
     */
    setCookieWordpress = () => {
        if (AppG.user != null && Platform.OS == 'web') {
            // 特集のため30分有効なクッキーを作成
            // 有料会員かどうか
            document.cookie = `recibase_userPlan=` + AppG.user.userPlan + `;max-age=` + 60*30 + `;domain=${this._env.REACT_APP_COOKIE_DOMAIN}`;
        }
    }

    /**
     * 特集用のクッキーを削除
     */
    deleteCookieWordpress = () => {
        if (Platform.OS == 'web') {
            // 特集のため30分有効なクッキーを作成
            // 有料会員かどうか
            document.cookie = `recibase_userPlan=` + `;max-age=0;domain=${this._env.REACT_APP_COOKIE_DOMAIN}`;
        }
    }

    /**
     * ログインアナリティクスの送信
     */
    sendLoginAnalytics = () => {
        let func = () => {
            return;
        }
        if (Platform.OS == 'web' && AppG.user != null) {
            log.debug(`---------------login analytics check web----------------`);
            if (document.domain == 'localhost' || document.domain == '127.0.0.1') {
                return;
            }
            if (CookieUtil.getCookie('recibase_web_login') == null) {
                // セッション会誌時にカウントするためのアナリティクスを送信する
                func = () => {
                    AnalyticsUtil.sendAnalytics(
                        DOpenEventName.session,
                        AnalyticsEventName.session,
                        null,
                        null,
                        null,
                        null,
                        null,
                    );
                    log.debug(`---------------send login analytics web----------------`);
                }
            }
            this.setCookieWebLogin();
            this.setAnalyticsHash(func);
        }

        if (AppG.user != null) {
            log.debug(`---------------login analytics check app----------------`);
            if (!AppG.loggedIn) {
                // ログイン時にカウントするためのアナリティクスを送信する
                func = () => {
                    AnalyticsUtil.sendAnalytics(
                        DOpenEventName.login,
                        AnalyticsEventName.login,
                        null,
                        null,
                        null,
                        null,
                        null,
                    );

                    if (Platform.OS != 'web') {
                        // Webでないならセッション会誌時にカウントするためのアナリティクスを送信する
                        AnalyticsUtil.sendAnalytics(
                            DOpenEventName.session,
                            AnalyticsEventName.session,
                            null,
                            null,
                            null,
                            null,
                            null,
                        );
                    }
                }
                log.debug(`---------------send login analytics app----------------`);
            }
            AppG.loggedIn = true;
            this.setAnalyticsHash(func);
        }
    }

    /**
     * Webログインアナリティクスのクッキーをセット
     */
    setCookieWebLogin = () => {
        if (AppG.user != null && Platform.OS == 'web') {
            // 特集のため30分有効なクッキーを作成
            // 有料会員かどうか
            document.cookie = `recibase_web_login=1;max-age=` + 60*30 + `;domain=${this._env.REACT_APP_COOKIE_DOMAIN}`;
        }
    }

    /**
     * Webログインアナリティクスのクッキーを削除
     */
    deleteCookieWebLogin = () => {
        if (Platform.OS == 'web') {
            // 特集のため30分有効なクッキーを作成
            // 有料会員かどうか
            document.cookie = `recibase_web_login=` + `;max-age=0;domain=${this._env.REACT_APP_COOKIE_DOMAIN}`;
        }
    }

    /**
     * Web visit アナリティクスのクッキーをセット
     */
    setCookieWebVisit = () => {
        if (Platform.OS == 'web') {
            // 特集のため30分有効なクッキーを作成
            // 有料会員かどうか
            document.cookie = `recibase_web_visit=1;max-age=` + 60*30 + `;domain=${this._env.REACT_APP_COOKIE_DOMAIN}`;
        }
    }

    /**
     * Web visit アナリティクスのクッキーを削除
     */
    deleteCookieWebVisit = () => {
        if (Platform.OS == 'web') {
            // 特集のため30分有効なクッキーを作成
            // 有料会員かどうか
            document.cookie = `recibase_web_visit=` + `;max-age=0;domain=${this._env.REACT_APP_COOKIE_DOMAIN}`;
        }
    }

}

export default new LoginUtil();
