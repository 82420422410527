import React, {Component} from 'react';
import {View} from 'react-native';
import ExpoUtil from "../util/ExpoUtil";

interface Props {
    height: number,
}

interface State { }

export class SpacerComponent extends Component<Props, State> {

    render() {
        const {height} = this.props;
        return ExpoUtil._wrap(
            <View style={{flexDirection: "row"}}>
                <View style={{flex: 1, height: height}} />
            </View>
        );
    }
}
