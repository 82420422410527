import moment from "moment";


export enum ViewDateFormatEnum {
    YYYY_MM_DD_HH_MM = "YYYY/MM/DD HH:mm",
    YYYY_MM_DD = "YYYY/MM/DD",
    MM_DD_HH_MM = "MM/DD HH:mm",
    MM_DD = "MM/DD",
    HH_MM = "HH:mm",
}

/**
 * Moment前提
 */
class DateUtil {



    /**
     * 画面表示用フォーマット
     */
    private YYYY_MM_DD_HH_MM: string = "YYYY/MM/DD HH:mm";

    /**
     * 画面表示用フォーマット(年非表示)
     */
    private MM_DD_HH_MM: string = "MM/DD HH:mm";

    /**
     * API送信用フォーマット
     */
    private _apiDateFormat: string = "YYYY-MM-DD";

    /**
     * API取得フォーマットから表示用フォーマットに変換
     * @param dateStr
     * @param viewDateFormatEnum 表示フォーマット（デフォルト年〜分）
     */
    getViewDateFromApiDateStr = (dateStr: string, viewDateFormatEnum: ViewDateFormatEnum = ViewDateFormatEnum.YYYY_MM_DD_HH_MM) => {
        let str = '';
        const _dateStr = dateStr.replace(' ', 'T');
        try {
            str = moment(new Date(_dateStr)).format(viewDateFormatEnum);
            return str;
        } catch (err) {
            return '';
        }
    }

    /**
     * Dateから表示用フォーマットに変換
     * @param date
     * @param viewDateFormatEnum 表示フォーマット（デフォルト年〜分）
     */
    getViewDateFromDate = (date: Date, viewDateFormatEnum: ViewDateFormatEnum = ViewDateFormatEnum.YYYY_MM_DD_HH_MM) => {
        let str = '';
        try {
            str = moment(date).format(viewDateFormatEnum);
            return str;
        } catch (err) {
            return '';
        }
    }

    /**
     * 年齢の計算
     * @param date
     */
    calcAge = (date: string) => {
        const dateOfBirth = moment(new Date(date)); // 生年月日
        const today = moment(); // 今日の日付

        // 西暦を比較して年齢を算出
        let baseAge = today.year() - dateOfBirth.year(); // >> 今年 - 生年月日の年 = 25（歳）

        // 今年の誕生日を作成
        let birthday = moment(
            new Date(
                today.year() + "-" + (dateOfBirth.month() + 1) + "-" + dateOfBirth.date()
            )
        );

        // 今日が誕生日より前の日付である場合、算出した年齢から-1した値を返す
        if (today.isBefore(birthday)) {
            return baseAge - 1;
        }

        // 今日が誕生日 または 誕生日を過ぎている場合は算出した年齢をそのまま返す
        return baseAge;
    }

    /**
     * 当日なら時間
     * 前日以前なら日付を返す
     */
    getChatDate = (date: Date) => {
        const today = moment(); // 今日の日付
        if (moment(today).diff(date, 'days') == 0) {
            // 今日なら時間だけ
            return this.getViewDateFromDate(date, ViewDateFormatEnum.HH_MM);
        }
        // 前日以前なら日付だけ
        return this.getViewDateFromDate(date, ViewDateFormatEnum.MM_DD);
    }
}

export default new DateUtil();
